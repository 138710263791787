import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { louboutinBrandId } from '../../../../../config';
import { useTranslation } from '../../../../../i18n';
import { IMainState, IShoppingCartItem } from '../../../../../interfaces';
import { getIdentityId } from '../../../../../utils/identity';
import { generateV4UUID } from '../../../../../utils/identityGenerator';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import {
  isAllowedForName,
  isAllowedForPhone,
  isValidEmail
} from '../../../Appointment/inputValidator';
import { SessionContext } from '../../../SessionContextContainer';
import LJMiniPopupWrapper from '../LJMiniPopupWrapper';
import {
  DID_CLICK_PRIVACY_POLICY,
  DID_CLICK_SEND_RESERVATION_FORM,
  DID_FAILED_TO_SEND_RESERVATION,
  DID_SEND_RESERVATION,
  WILL_SEND_RESERVATION
} from './../../../../../utils/constants';
import { mapReservationsToPayload, sendReservations } from './sendReservations';

enum ButtonState {
  ERROR = 'INVALID CHARACTER',
  EMAIL_ERROR = 'ENTER VALID EMAIL ADDRESS',
  INITIAL = 'SEND',
  SENDING = 'SENDING...',
  SENT = 'SENT!',
  FAILED = 'PLEASE TRY AGAIN',
  REQUIRED = 'ALL FIELD REQUIRED'
}

export const defaultLoubiAirwaysWishlistPayload = (storeId: string) => ({
  id: generateV4UUID(),
  identityId: getIdentityId(),
  description: '',
  name: '',
  surname: '',
  store: storeId,
  brand: louboutinBrandId,
  wishlistItems: [],
  createdAt: new Date(),
  createdBy: getIdentityId()
});

const ReservationForm = ({
  onClose,
  items,
  onSuccess
}: {
  onClose: () => void;
  items: IShoppingCartItem[];
  onSuccess: () => void;
}) => {
  const router = useRouter();
  const isSession = (router.query.source as string) === 'session';
  const meetingId = router.query.meeting as string;

  const { session } = React.useContext(SessionContext);
  const sessionStoreId = session?.storeId;
  const currentSiteId = useSelector(
    (state: IMainState) => state.clientState.store?.id
  );

  const invitationState = useLoubiAirwaysState()?.invite;

  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [error, setError] = React.useState(false);
  const { t } = useTranslation();
  const [sendStatus, setSendStatus] = React.useState(ButtonState.INITIAL);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isAllowedForName(value)) {
      setName(value);
      setSendStatus(ButtonState.INITIAL);
    } else {
      setSendStatus(ButtonState.ERROR);
    }
  };

  const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isAllowedForName(value)) {
      setSurname(value);
      setSendStatus(ButtonState.INITIAL);
    } else {
      setSendStatus(ButtonState.ERROR);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    if (isValidEmail(value)) {
      setSendStatus(ButtonState.INITIAL);
    } else {
      setSendStatus(ButtonState.EMAIL_ERROR);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isAllowedForPhone(value)) {
      setPhone(value);
      setSendStatus(ButtonState.INITIAL);
    } else {
      setSendStatus(ButtonState.ERROR);
    }
  };

  const sendWishlist = () => {
    const hasError = !name || !surname || !phone || !isValidEmail(email);

    if (hasError) {
      setError(true);
    } else {
      setError(false);
      setSendStatus(ButtonState.SENDING);
      logEvent(DID_CLICK_SEND_RESERVATION_FORM);

      const payload = mapReservationsToPayload({
        items: items || [],
        invitation: invitationState,
        identityId: getIdentityId() || '',
        sessionId: isSession ? meetingId : '',
        name,
        surname,
        email,
        phone,
        sessionStoreId,
        currentSiteId
      });
      logEvent(WILL_SEND_RESERVATION, WILL_SEND_RESERVATION, {
        ...payload,
        customerInfo: undefined
      });
      sendReservations(payload)
        .then(() => {
          setSendStatus(ButtonState.SENT);
          logEvent(DID_SEND_RESERVATION, DID_SEND_RESERVATION, {
            id: payload.id
          });
          setTimeout(() => {
            onSuccess();
          }, 1000);
        })
        .catch((error) => {
          setSendStatus(ButtonState.FAILED);
          logEvent(
            DID_FAILED_TO_SEND_RESERVATION,
            DID_FAILED_TO_SEND_RESERVATION,
            { payload: { ...payload, customerInfo: undefined }, error }
          );
        });
    }
  };

  const renderStatus = () => {
    if (sendStatus === ButtonState.INITIAL) {
      return t('vb:reservation_send');
    }
    if (sendStatus === ButtonState.SENT) {
      return t('vb:reservation_sent');
    }
    return sendStatus;
  };

  React.useEffect(() => {
    if (sendStatus === ButtonState.ERROR) {
      setTimeout(() => {
        setSendStatus(ButtonState.INITIAL);
      }, 1000);
    }
  }, [sendStatus]);

  return (
    <LJMiniPopupWrapper
      onClose={onClose}
      title={t('vb:submit_reservation_request')}
      maxWidth={700}
    >
      {error && <p>PLEASE ENTER ALL FIELDS BELOW</p>}
      <input
        type="text"
        className="input-text input-name"
        placeholder={t('vb:reservation_name')}
        value={name}
        onChange={handleNameChange}
      />

      <input
        type="text"
        className="input-text  input-surname"
        placeholder={t('vb:reservation_surname_name')}
        value={surname}
        onChange={handleSurnameChange}
      />

      <input
        type="email"
        className="input-text input-email"
        placeholder={t('vb:reservation_email')}
        value={email}
        onChange={handleEmailChange}
      />

      <div className="input-container">
        <input
          type="tel"
          className="input-text input-country"
          value="+81"
          disabled={true}
        />
        <input
          type="tel"
          className="input-text input-phone"
          placeholder={t('vb:reservation_phone_number')}
          value={phone}
          onChange={handlePhoneChange}
        />
      </div>

      <p className="disclaimer">
        お客様からご提供頂きました個人情報は、受注オーダー以外の目的では利用いたしません。
        詳細につきましては、クリスチャン ルブタン ジャパン リミテッドの
        <a
          target="_blank"
          href="https://jp.christianlouboutin.com/jp_ja/policy"
          onClick={() => {
            logEvent(DID_CLICK_PRIVACY_POLICY, DID_CLICK_PRIVACY_POLICY, {
              url: 'https://jp.christianlouboutin.com/jp_ja/policy'
            });
          }}
        >
          プライバシーポリシー
        </a>
        へアクセスください。
      </p>

      <button className="btn btn-lg btn-send" onClick={sendWishlist}>
        {renderStatus()}
      </button>
      <style jsx>{`
        input,
        button {
          border-radius: 15px;
        }
        .input-text {
          margin: 0 auto 20px;
          background: #fff;
          border: none;
          padding: 15px 10px;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-size: 1.3em;
          text-align: center;
          width: 80%;
          color: #cf152d;
        }

        .input-container {
          width: 80%;
          margin: 0 auto 20px;
          display: flex;
          align-items: baseline;
        }

        .input-container .input-text {
          width: 100%;
        }

        .input-container .input-country {
          width: 100px;
          margin-right: 10px;
        }

        .input-name {
          margin: 30px auto 20px;
        }

        .input-text:active,
        .input-text:focus {
          box-shadow: none;
          outline: none;
        }
        .btn-send {
          display: block;
          background: #cf152d;
          color: #fff;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-style: italic;
          min-width: 200px;
          max-width: 100%;
          margin: auto;
        }
        .disclaimer {
          font-size: 12px;
        }
        @media (max-width: 768px) {
          .input-text {
            font-size: 1em;
            width: 100%;
          }
          .btn-send {
            font-size: 1em;
          }

          .input-container {
            width: 100%;
          }

          .input-container .input-country {
            width: 70px;
          }
        }
      `}</style>
    </LJMiniPopupWrapper>
  );
};

export default ReservationForm;

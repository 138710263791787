import * as THREE from 'three';
import {
  LoubiFutureScene,
  REMOTE_ACTION, ROOM_STATUS, touchType
} from '../../../interfaces/loubifuture';
import {
  isUserOnMobile,
  isUserOnMobileOnly
} from '../../../utils/deviceDetector';
import { LF_FADE_IN_SPEED } from '../../VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/LoubiFutureFullScreenVideo';
import {
  arpoadorImage, arpoadorProducts,
  backToVideoImage,
  christianLouboutinImage,
  continueImage,
  cylenderTrans as cylinderTrans,
  minigraineBaseTexture,
  xrayImage
} from './assets';
import LoubiFutureBase from './LoubiFutureBase';

let TWEEN = undefined;
const dist = 5.2;
const isMobileOrTablet = isUserOnMobile();
const isMobile = isUserOnMobileOnly();
const SCALE = 35;
class Arpoador {
  colorMetalness: number;
  hoverID: number;
  counter: number;
  lubiAnim: LoubiFutureBase;
  productBox = undefined;
  productObj = undefined;
  gateForCatchingClick = undefined;
  itemsChildForCatchingClick = undefined;
  backToA1CatchingClick = undefined;
  lowPolyObj = undefined;
  highPolyObj = undefined;
  lookatPosition = undefined;
  transitionTexture = undefined;
  mapShoesTexture = undefined;
  shoesPartsObj = undefined;
  shoesAnimModal = undefined;
  shoeAnimCounter: number;
  objectHeight: number;

  glow: number;
  lookAtno: number;
  roomStatus: ROOM_STATUS;
  loadingManager: THREE.LoadingManager;
  continueMesh = undefined;
  backToVideoMesh = undefined;
  christianLouboutin = undefined;
  arpoadorMesh = undefined;

  onSelectProduct: (productId?: string) => void;
  constructor(lubiAnim, tween, onSelectProduct) {
    this.roomStatus = ROOM_STATUS.NOT_START;
    this.lubiAnim = lubiAnim;
    this.productBox = [];
    this.productObj = [];
    this.itemsChildForCatchingClick = [];
    this.lowPolyObj = [];
    this.highPolyObj = [];
    this.shoesPartsObj = [];
    this.objectHeight = 0.1;
    TWEEN = tween;

    this.glow = 0;
    this.colorMetalness = 0.01;
    this.hoverID = undefined;
    this.counter = 0;
    this.lookAtno = 2;
    this.shoeAnimCounter = 0;
    this.loadingManager = new THREE.LoadingManager(() => {
      this.objectLoadCallback();
    });
    this.onSelectProduct = onSelectProduct;
  }

  objectLoadCallback() {
    this.setShoesAnimModal();
    this.roomStatus = ROOM_STATUS.READY;
    if (this.lubiAnim?.props?.viewOnly) {
      this.checkForViewOnly(this.lubiAnim?.props?.canvasState);
    }
  }

  checkForViewOnly(remoteState) {
    if (remoteState?.camera?.position && this.lubiAnim?.camera) {
      this.lubiAnim?.camera.position.set(
        remoteState.camera.position[0],
        remoteState.camera.position[1],
        remoteState.camera.position[2]
      );
      this.lubiAnim?.camera.rotation.set(
        remoteState.camera.rotation[0],
        remoteState.camera.rotation[1],
        remoteState.camera.rotation[2]
      );
    }
    if (remoteState?.focusItem?.position) {
      if (this.lubiAnim?.focusedModel === null) {
        if (remoteState?.currentScene === LoubiFutureScene.ArpoadorA2) {
          this.setFocusObject(remoteState?.focusItemID);
        }
      }
      if (this.lubiAnim?.focusedModel) {
        this.lubiAnim?.focusedModel.position.set(
          remoteState.focusItem.position[0],
          remoteState.focusItem.position[1],
          remoteState.focusItem.position[2]
        );
        this.lubiAnim?.focusedModel.rotation.set(
          remoteState.focusItem.rotation[0],
          remoteState.focusItem.rotation[1],
          remoteState.focusItem.rotation[2]
        );
        this.lubiAnim?.focusedModel.scale.set(
          remoteState.focusItem.scale[0],
          remoteState.focusItem.scale[1],
          remoteState.focusItem.scale[2]
        );
      }
    }
    if (remoteState?.flag) {
      if (remoteState?.flag === REMOTE_ACTION.FOCUS) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
          remoteState?.focusItemID !== undefined
        ) {
          this.focusProduct(remoteState?.focusItemID);
        }
      }
      if (remoteState?.flag === REMOTE_ACTION.UN_FOCUS) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
          remoteState?.focusItemID !== undefined
        ) {
          this.unfocusProduct(remoteState?.focusItemID);
        }
      }
    }
  }

  tweenForGlow(val) {
    new TWEEN.Tween(this)
      .to({ glow: val }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        this.tweenForGlow(val > 0.5 ? 0.3 : 0.6);
      })
      .start();
  }
  loadZoom = (id) => {
    if (isMobileOrTablet) return;
    const gltfLoader = this.lubiAnim.getGltfObjForZoom();
    const ItemId = id;
    const url = arpoadorProducts[ItemId].modelUrl;
    gltfLoader.load(url, (gltf) => {
      const root = gltf.scene;
      root.scale.set(SCALE, SCALE, SCALE);
      root.rotation.set(0, -Math.PI * 0.5, 0);
      this.productObj[ItemId].add(root);
      this.highPolyObj[ItemId] = root;
      if (this.lowPolyObj[ItemId]) this.lowPolyObj[ItemId].visible = false;
      this.productObj[ItemId].remove(this.lowPolyObj[ItemId]);
    });
  };

  cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material?.dispose();
    material = undefined;
  };
  cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
  };
  cleanObject = (model) => {
    model.traverse((object) => {
      if (!object['isMesh']) return;
      object['geometry']?.dispose();
      if (object['material']?.isMaterial) {
        this.cleanMaterial(object['material']);
      } else {
        for (const material of object['material']) this.cleanMaterial(material);
      }
      object['geometry'] = undefined;
      object = undefined;
    });
  };

  cleanUp = () => {
    if (!isMobileOrTablet) {
      return;
    }
    if (this.productObj != undefined) {
      this.productObj.forEach((model) => {
        this.cleanObject(model);
        this.lubiAnim.scene?.remove(model);
      });
      this.productObj = [];
    }
    if (this.productBox != undefined) {
      this.productBox.forEach((model) => {
        this.cleanObject(model);
        this.lubiAnim.scene?.remove(model);
      });
      this.productBox = [];
    }
    if (this.shoesAnimModal) this.cleanObject(this.shoesAnimModal);
    if (this.gateForCatchingClick) this.cleanObject(this.gateForCatchingClick);
    if (this.backToA1CatchingClick)
      this.cleanObject(this.backToA1CatchingClick);
    if (this.transitionTexture) this.cleanTexture(this.transitionTexture);
    if (this.mapShoesTexture) this.cleanTexture(this.mapShoesTexture);

    if (this.continueMesh) this.cleanObject(this.continueMesh);
    if (this.backToVideoMesh) this.cleanObject(this.backToVideoMesh);
    if (this.christianLouboutin) this.cleanObject(this.christianLouboutin);
    if (this.arpoadorMesh) this.cleanObject(this.arpoadorMesh);
    this.continueMesh = undefined;
    this.backToVideoMesh = undefined;
    this.christianLouboutin = undefined;
    this.arpoadorMesh = undefined;
    this.gateForCatchingClick = undefined;
    this.backToA1CatchingClick = undefined;
    this.shoesAnimModal = undefined;
    this.mapShoesTexture = undefined;
    this.transitionTexture = undefined;
    this.itemsChildForCatchingClick = [];
    this.shoesPartsObj = [];
    this.lowPolyObj = [];
    this.highPolyObj = [];
  };

  init() {
    this.roomStatus = ROOM_STATUS.LOADING;
    this.initObject();
    this.tweenForGlow(1);
  }

  setShoesAnimModal() {
    if (!this.shoesAnimModal || !this.mapShoesTexture) return;
    this.shoesAnimModal.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial && object.name !== 'Bottom1') {
        const met = object['material'].clone();
        if (met?.map) {
          const tempMap = met.map.clone();
          tempMap.image = this.mapShoesTexture.image;
          met.map = tempMap;
          met.map.needsUpdate = true;
          met.needsUpdate = true;
        }
        object['material'] = met.clone();
      }
    });
    this.shoesPartsObj.push(this.shoesAnimModal.getObjectByName('Bottom'));
    this.shoesPartsObj.push(this.shoesAnimModal.getObjectByName('Bottom1'));
    this.shoesPartsObj.push(this.shoesAnimModal.getObjectByName('Middle_1'));
    this.shoesPartsObj.push(this.shoesAnimModal.getObjectByName('Middle'));
    this.shoesPartsObj.push(this.shoesAnimModal.getObjectByName('Top'));
    this.shoesAnimModal.position.set(0, -5, isMobile ? 21 : 14);
    this.shoesAnimModal.visible = this.lubiAnim.isArpoador();
  }
  setShoesAnimation() {
    if (this.shoesPartsObj.length < 4) return;
    if (this.shoesPartsObj[4].position.y < 0.02) {
      this.shoeAnim(this.shoesPartsObj[0], -0.2 + 1 * 0.1, 2 * Math.PI, 4000);
      this.shoeAnim(this.shoesPartsObj[1], -0.2 + 1 * 0.1, 2 * Math.PI, 4000);
      this.shoeAnim(this.shoesPartsObj[2], -0.18 + 2 * 0.1, 2 * Math.PI, 4100);
      this.shoeAnim(this.shoesPartsObj[3], -0.2 + 3 * 0.1, 2 * Math.PI, 4200);
      this.shoeAnim(this.shoesPartsObj[4], -0.2 + 4 * 0.1, 2 * Math.PI, 4300);
    } else {
      this.shoeAnim(this.shoesPartsObj[0], 0, 0, 4000);
      this.shoeAnim(this.shoesPartsObj[1], 0, 0, 4000);
      this.shoeAnim(this.shoesPartsObj[2], 0, 0, 4000);
      this.shoeAnim(this.shoesPartsObj[3], 0, 0, 4000);
      this.shoeAnim(this.shoesPartsObj[4], 0, 0, 4000);
    }
  }
  shoeAnim(model, posy, roty, timerot) {
    const time = 5000;
    const obj = model;
    this.shoeAnimCounter = 0;
    new TWEEN.Tween(obj.position)
      .to({ y: posy }, time)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
    new TWEEN.Tween(obj.rotation)
      .to({ y: roty }, timerot)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        this.shoeAnimCounter++;
      })
      .start();
  }
  loadProduct(groupobj, trancCylinder, itemid) {
    const itemId = itemid;
    const group = groupobj;
    group['itemId'] = itemid;
    const textureLoader = new THREE.TextureLoader(this.loadingManager);
    this.transitionTexture = textureLoader.load(minigraineBaseTexture);
    const gltfLoader = this.lubiAnim.getGltfobj(this.loadingManager);

    const url = arpoadorProducts[itemId].lowpolyModelUrl;
    gltfLoader.load(url, (gltf) => {
      if(!this.lubiAnim?.scene) return;
      const root = gltf.scene;
      root.scale.set(SCALE, SCALE, SCALE);
      root.rotation.set(0, -Math.PI * 0.5, 0);
      root.name = 'lowPoly';
      this.lowPolyObj[itemId] = root;
      const box = new THREE.Box3().setFromObject(root);
      box.getSize(new THREE.Vector3());
      group.position.set(
        this.productBox[itemId].position.x,
        this.productBox[itemId].position.y,
        this.productBox[itemId].position.z
      );
      group.add(root);
      trancCylinder.itemId = itemId;
      group.add(trancCylinder);
      trancCylinder.visible = false;
      this.lubiAnim.scene.add(group);
      this.itemsChildForCatchingClick[itemId] = trancCylinder;
      group.visible = this.lubiAnim.isArpoador();
      if (itemId === 0) {
        this.shoesAnimModal = root.clone();
        this.shoesAnimModal.position.set(0, -5, isMobile ? 21 : 14);
        this.lubiAnim.scene.add(this.shoesAnimModal);
      }
      if (itemId === this.lubiAnim.props.canvasState?.focusItemID) {
        this.focusProduct(itemId);
      }
    });
  }
  initObject() {
    if(!this.lubiAnim?.scene) return;
    const textureLoader = new THREE.TextureLoader(this.loadingManager);
    const continueTexture = textureLoader.load(continueImage);
    this.continueMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(20, 10),
      new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        map: continueTexture,
        transparent: true
      })
    );
    this.continueMesh.position.set(-131 + 55, 37 - 14, 47 - 20);
    this.continueMesh.rotation.set(-0.16, 1.86, 0);
    this.lubiAnim.scene.add(this.continueMesh);
    this.continueMesh.visible = false;

    const backToVideoTexture = textureLoader.load(backToVideoImage);
    this.backToVideoMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(40, 10),
      new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        map: backToVideoTexture,
        transparent: true
      })
    );
    this.backToVideoMesh.position.set(168 - 53, 21 - 5, 76 - 23);
    this.backToVideoMesh.rotation.set(-0.2 - 0.28, -2.2 + 0.03, -0.5);
    this.lubiAnim.scene.add(this.backToVideoMesh);
    this.backToVideoMesh.visible = false;

    const christianLouboutinTexture = textureLoader.load(
      christianLouboutinImage
    );
    this.christianLouboutin = new THREE.Mesh(
      new THREE.PlaneGeometry(256, 64),
      new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        map: christianLouboutinTexture,
        transparent: true,
        opacity: 0.55
      })
    );
    this.christianLouboutin.position.set(0, 0, -150);
    this.christianLouboutin.rotation.set(0, 0, 0);
    this.christianLouboutin.scale.set(0.7, 0.7, 0.7);
    this.christianLouboutin.visible = false;
    this.lubiAnim.scene.add(this.christianLouboutin);

    const arpoadorTexture = textureLoader.load(arpoadorImage);
    this.arpoadorMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(256, 64),
      new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        map: arpoadorTexture,
        transparent: true,
        opacity: 0.15
      })
    );
    this.arpoadorMesh.position.set(0, 0, 150);
    this.arpoadorMesh.rotation.set(0, Math.PI, 0);
    this.arpoadorMesh.scale.set(0.7, 0.7, 0.7);
    this.arpoadorMesh.visible = false;
    this.lubiAnim.scene.add(this.arpoadorMesh);

    this.mapShoesTexture = textureLoader.load(xrayImage);
    const cube = new THREE.Mesh(
      new THREE.BoxGeometry(40, 80, 40),
      new THREE.MeshPhongMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: 0.23
      })
    );
    this.lubiAnim.scene.add(cube);
    this.gateForCatchingClick = cube;
    cube.visible = false;
    this.gateForCatchingClick.position.set(-128, -10, 32);
    const container = new THREE.Group();

    const cube2 = new THREE.Mesh(
      new THREE.BoxGeometry(40, 80, 40),
      new THREE.MeshPhongMaterial({
        color: 0x00ff00,
        transparent: false,
        opacity: 0.23
      })
    );

    this.backToA1CatchingClick = cube2;
    this.backToA1CatchingClick.visible = false;
    this.backToA1CatchingClick.position.set(175, 0, 75);
    this.lubiAnim.scene.add(cube2);

    const cylinderTransTexture = textureLoader.load(cylinderTrans);
    const material = new THREE.MeshBasicMaterial({
      map: cylinderTransTexture,
      color: 0xffffff,
      transparent: true,
      opacity: 0.2
    });

    const YELLOW = 0xf2e608;
    const ORANGE = 0xc30505;
    const REFLECTOR_COLOR = 0x150217
    material.depthWrite = false;
    const geometry = new THREE.CylinderGeometry(4.6, 4.6, 8, 16);
    const blackTopAMat = new THREE.MeshBasicMaterial({ color: YELLOW });
    const blackTopBMat = new THREE.MeshBasicMaterial({ color: 0xb602ca });
    const blackTopCMat = new THREE.MeshBasicMaterial({ color: YELLOW });
    const blackTopDMat = new THREE.MeshBasicMaterial({ color: YELLOW });
    const blackTopEMat = new THREE.MeshBasicMaterial({ color: ORANGE });
    const blackTopFMat = new THREE.MeshBasicMaterial({color: REFLECTOR_COLOR});
    const blackTop = new THREE.Mesh(
      new THREE.TorusGeometry(4.6, 1, 32, 32),
      new THREE.MeshStandardMaterial({
        color: 0x0d0120,
        roughness: 0.4,
        metalness: 0.0
      })
    );
    blackTop.scale.set(1, 1, 0.91);
    const blackTopA = new THREE.Mesh(
      new THREE.TorusGeometry(5.4, 0.1, 32, 32),
      blackTopAMat
    );
    const blackTopB = new THREE.Mesh(
      new THREE.TorusGeometry(5.6, 0.1, 32, 32),
      blackTopBMat
    );
    const blackTopC = new THREE.Mesh(
      new THREE.TorusGeometry(5.4, 0.08, 32, 32),
      blackTopCMat
    );
    const blackTopD = new THREE.Mesh(
      new THREE.TorusGeometry(4.6, 0.08, 32, 32),
      blackTopDMat
    );
    const blackTopE = new THREE.Mesh(
      new THREE.TorusGeometry(4.6, 0.1, 64, 64),
      blackTopEMat
    );
    const blackTopF = new THREE.Mesh(
      new THREE.CircleGeometry(4.6, 20, 0, Math.PI * 2),
      blackTopFMat
    );
    const blackBottomF = new THREE.Mesh(
      new THREE.CircleGeometry(4.6, 20, 0, Math.PI * 2),
      blackTopFMat
    );

    blackTopA.name = 'blackTopA';
    blackTopB.name = 'blackTopB';
    blackTopC.name = 'blackTopC';

    blackTopA.position.set(0, 0, -0.55);
    blackTopC.position.set(0, 0, 0.55);
    blackTopD.position.set(0, 0, 1.0);
    blackTopE.position.set(0, 0, 1.7);
    blackTopF.position.set(0, 4.0, 0);
    blackBottomF.position.set(0, -4.0, 0);

    blackTop.add(blackTopA);
    blackTop.add(blackTopB);
    blackTop.add(blackTopC);
    blackTop.add(blackTopD);
    blackTop.add(blackTopE);

    blackTop.rotation.set(Math.PI * 0.5, 0, 0);
    blackTopF.rotation.set(Math.PI * 0.5, 0, 0);
    blackBottomF.rotation.set(-Math.PI * 0.5, 0, 0);

    blackTop.position.set(0, 5.5, 0);
    const blackBottom = blackTop.clone();
    blackBottom.rotation.set(-Math.PI * 0.5, 0, 0);
    blackBottom.position.set(0, -5.5, 0);

    const trancCylinder = new THREE.Mesh(geometry, material);
    trancCylinder.name = 'trancCylinder';
    container.add(trancCylinder);
    container.add(blackTop);
    container.add(blackBottom);
    container.add(blackTopF);
    container.add(blackBottomF);
    container.scale.set(1.6, 1.5, 1.6);
    for (let i = 0; i < 5; i++) {
      const cylClon = i == 0 ? container : container.clone();
      this.productBox.push(cylClon);
      this.lubiAnim.scene.add(cylClon);
    }

    for (let i = 0; i < this.productBox.length; i++) {
      const obj = this.productBox[i].getObjectByName(trancCylinder.name);
      obj.material = material.clone();
      obj.material.opacity = 0.1;
    }

    for (let i = 0; i < this.productBox.length; i++) {
      const radian = 0.007 + Math.PI - 0.2 * dist + i * (0.1 * dist);
      const x = Math.sin(radian) * 48;
      const z = Math.cos(radian) * 48;
      this.productBox[i].position.set(x, 0.8, z);
      this.productBox[i].visible = false;
    }
    const touchCylinder = new THREE.Mesh(geometry, material);
    for (let i = 0; i < this.productBox.length; i++) {
      this.productObj.push(new THREE.Group());
      this.loadProduct(this.productObj[i], touchCylinder.clone(), i);
      const radian = Math.PI - 0.2 * dist + i * (0.1 * dist);
      this.productObj[i].rotation.set(0, Math.PI + radian + Math.PI, 0);
      this.productObj[i]['radian'] = Math.PI + radian + Math.PI;
      this.productObj[i]['itemId'] = i;
      this.productObj[i].visible = true;
    }
  }
  draw() {
    for (
      let i = 0;
      i < this.productObj?.length && !this.lubiAnim?.focusedModel;
      i++
    ) {
      const obj = this.productBox[i].getObjectByName('trancCylinder');
      if (this.hoverID === i) {
        obj.material.opacity = this.glow;
      } else {
        obj.material.opacity = 0.1;
      }
    }
    for (let i = 0; i < this.productObj?.length; i++) {
      if (this.lubiAnim?.focusedModel?.itemId !== i) {
        this.productObj[i].position.y =
          Math.sin(Math.PI * i * 0.4 + this.counter * 0.01) * 0.6;
      }
    }
    if (this.shoeAnimCounter > 3) {
      this.shoeAnimCounter++;
      if (this.shoeAnimCounter > 100) {
        this.setShoesAnimation();
      }
    }
    this.counter++;
  }

  focus(model, pos, rot) {
    if (!model) return;
    if (!pos) return;
    const time = 1000;
    this.lubiAnim.isTweening = true;
    if (this.lubiAnim.focusedModel) {
      this.lubiAnim.focusedModel.radian =
        this.lubiAnim.focusedModel?.rotation?.y;
    }
    const obj = model;
    new TWEEN.Tween(obj.position)
      .to(
        {
          x: pos.x,
          y: pos.y,
          z: pos.z
        },
        time
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(obj.scale)
      .to(
        {
          x: 1,
          y: 1,
          z: 1
        },
        time
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: rot.x,
          y: rot.y,
          z: rot.z
        },
        time
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.lubiAnim.isTweening = false;
        if (this.lubiAnim.focusedModel) {
          this.lubiAnim.setTransparent(0.9);
        }
      })
      .start();
  }
  focusProduct(index) {
    if (this.lubiAnim?.mp3Zoom) this.lubiAnim.mp3Zoom.play();
    const productId = arpoadorProducts[index]?.id;
    this.lubiAnim.focusedModel = this.productObj[index];
    this.onSelectProduct(productId);
    if (!this.lubiAnim?.props?.viewOnly) {
      this.lubiAnim.updateRemoteOnChange(REMOTE_ACTION.FOCUS, {
        itemId: index
      });
    }
    this.itemsChildForCatchingClick.forEach((element) => {
      element.visible = false;
    });

    this.updateLookAtPosition(this.productBox[index].position, index);
  }
  unfocusProduct(id) {
    if (!this.lubiAnim?.focusedModel) return;
    if (this.lubiAnim?.mp3Zoom) this.lubiAnim.mp3Zoom.play();
    this.onSelectProduct(undefined);
    let radian = this.productObj[id]?.radian;
    if (!radian) {
      radian = 0;
    }
    if (id === undefined) {
      id = this.lubiAnim.focusedModel.itemId;
    }

    this.lubiAnim.setTransparent(0.0);
    this.focus(this.productObj[id], this.productBox[id].position, {
      x: 0,
      y: radian,
      z: 0
    });
    if (this.lubiAnim.objectControls) {
      this.lubiAnim.RemoveObjectControl();
    }
    if (!this.lubiAnim?.props?.viewOnly) {
      this.lubiAnim.updateRemoteOnChange(REMOTE_ACTION.UN_FOCUS, {
        itemId: id
      });
    }
    if (this.lubiAnim.orbitControls) this.lubiAnim.setButton(false);
    this.lubiAnim.focusedModel = undefined;
    this.lubiAnim?.setOrbitControl();
  }
  updateLookAtPosition(pos, id) {
    this.lubiAnim.isTweening = true;
    const dist = 20;
    const cwd = new THREE.Vector3();
    this.lubiAnim.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.lubiAnim.camera.position);
    this.lookatPosition = cwd;
    new TWEEN.Tween(this.lookatPosition)
      .to(
        {
          x: pos.x,
          y: pos.y,
          z: pos.z
        },
        600
      )
      .onUpdate(() => {
        if (this.lookatPosition && this.lubiAnim?.camera)
          this.lubiAnim.camera.lookAt(this.lookatPosition);
      })
      .onComplete(() => {
        this.lubiAnim.camera.lookAt(pos);
        this.lubiAnim.isTweening = false;
        this.updatePositionForCamera(this.productObj[id]);
        this.lubiAnim.updateObjectControlForModel(this.productObj[id]);
        this.lubiAnim.setButton(true);

        this.lubiAnim.setLightIntensity(
          arpoadorProducts[id].ambientLight,
          arpoadorProducts[id].directionalLight,
          arpoadorProducts[id].hdrSavedVersion,30
        );
      })
      .start();
  }

  prepareViewForTransitionToViewingRoom() {
    if (this.gateForCatchingClick) this.gateForCatchingClick.visible = false;
    this.lubiAnim.setCameraPositionForTransitionToViewingRoomVideo(() => {
      this.lubiAnim.props.updateCanvasState({
        currentScene: LoubiFutureScene.ArpoadorToViewingroom
      });
      setTimeout(()=>{
        this.clearObjects()
      }, LF_FADE_IN_SPEED)
    });
  }

  changeToViewingRoom() {
    if (!this.lubiAnim?.props?.viewOnly)
      this.lubiAnim.props.updateCanvasState({
        currentScene: LoubiFutureScene.ArpoadorViewAdjustment
      });
  }

  touchEvent(e, type) {
    if (
      !this.gateForCatchingClick ||
      this.itemsChildForCatchingClick?.length <= 0
    )
      return;
    if (type === touchType.touchUp) {
      const clickedModel = this.lubiAnim.detectFirstTouchedObject(
        this.itemsChildForCatchingClick
      );
      const id = clickedModel?.object?.['itemId'];
      if (clickedModel?.object && !this.lubiAnim?.focusedModel) {
        this.focusProduct(id);
        if (!this.highPolyObj[id]) {
          this.loadZoom(id);
        }
      } else if (
        this.lubiAnim?.focusedModel &&
        this.lubiAnim?.isClicked(type)
      ) {
        this.unfocusProduct(this.lubiAnim?.focusedModel?.itemId);
      }
      const clickedGate = this.lubiAnim.detectFirstTouchedObject([
        this.gateForCatchingClick
      ]);
      if (!this.lubiAnim?.focusedModel && clickedGate?.object) {
        this.changeToViewingRoom();
      }
      const backToVideo = this.lubiAnim.detectFirstTouchedObject([
        this.backToA1CatchingClick
      ]);
      if (!this.lubiAnim?.focusedModel && backToVideo?.object) {
        this.lubiAnim?.props?.replayMusicVideo();
      }
    }
    if (this.itemsChildForCatchingClick && this.lubiAnim) {
      const clickedModel = this.lubiAnim.detectObjectForHover(
        this.itemsChildForCatchingClick
      );
      this.hoverID = clickedModel?.object?.['itemId'];
    }
    if (
      this.lubiAnim?.isPointerDown &&
      !this.lubiAnim?.focusedModel &&
      !this.lubiAnim?.isTweening &&
      isMobileOrTablet
    ) {
      const scl = window.innerWidth / window.innerHeight;
      const diff =
        this.lubiAnim.mouseDownPos.x * scl - this.lubiAnim.mouse.x * scl;
      if (diff > 0.2) {
        if (this.lookAtno < this.productBox.length - 1) {
          this.lookAtno++;
        }
      }
      if (diff < -0.2) {
        if (this.lookAtno > 0) {
          this.lookAtno--;
        }
      }
    }
  }
  updatePositionForCamera(myObject3D) {
    if (!myObject3D) return;
    const index = myObject3D.itemId;
    let dist = 15;
    if (isMobileOrTablet) {
      dist = 18;
    }
    const cwd = new THREE.Vector3();
    this.lubiAnim.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.lubiAnim.camera.position);
    this.focus(myObject3D, cwd, { x: 0, y: 2.2 + index * 0.5, z: 0 });
    if (myObject3D.children[2]) {
      myObject3D.children[2].visible = false;
    }
  }
  clearObjects() {
    return new Promise((resolve) => {
      if (this.backToVideoMesh) this.backToVideoMesh.visible = false;
      if (this.christianLouboutin) this.christianLouboutin.visible = false;
      if (this.continueMesh) this.continueMesh.visible = false;
      if (this.arpoadorMesh) this.arpoadorMesh.visible = false;
      this.productBox.forEach((element) => {
        element.visible = false;
      });
      this.productObj.forEach((element) => {
        element.visible = false;
      });
      this.itemsChildForCatchingClick.forEach((element) => {
        element.visible = false;
      });
      if (this.shoesAnimModal) this.shoesAnimModal.visible = false;
      setTimeout(() => {
        resolve({});
      }, 10);
    });
  }
  setScreen() {
    if (this.backToA1CatchingClick) this.backToA1CatchingClick.visible = false;
    if (this.backToVideoMesh) this.backToVideoMesh.visible = true;
    if (this.christianLouboutin) this.christianLouboutin.visible = true;
    if (this.continueMesh) this.continueMesh.visible = true;
    if (this.arpoadorMesh) this.arpoadorMesh.visible = true;
    this.productBox.forEach((element) => {
      element.visible = true;
    });
    this.productObj.forEach((element) => {
      element.visible = true;
    });
    this.itemsChildForCatchingClick.forEach((element) => {
      element.visible = false;
    });
    if (this.gateForCatchingClick) {
      this.gateForCatchingClick.visible = false;
    }
    if (this.productBox[2]?.position && this.lubiAnim?.camera) {
      this.lubiAnim.camera.lookAt(
        new THREE.Vector3(
          this.productBox[2].position.x,
          this.productBox[2].position.y,
          this.productBox[2].position.z
        )
      );
    }
    if (this.shoesAnimModal) this.shoesAnimModal.visible = true;
    this.hoverID = undefined;
    this.lookAtno = 2;
    this.shoeAnimCounter = 5;
  }
  setFocusObject(id) {
    if (id && this.productObj[id])
      this.lubiAnim.focusedModel = this.productObj[id];
  }
}

export default Arpoador;

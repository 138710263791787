import { useDispatch } from 'react-redux';
import React from 'react';
import { logEvent } from '../../../../../analytics';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';
import { LFPopupContent } from '../../../../../interfaces/loubifuture';
import {
  actionAddToWishlist,
  actionOpenLFPopup,
  actionRemoveFromWishlist
} from '../../../../../redux/customActions/loubiFuture';
import {
  DID_ADD_TO_WISHLIST,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';

import { useLoubiFutureState } from '../../../../hooks/loubiFuture';
import {
  actionAddToWishlistAsync,
  actionRemoveFromWishlistAsync
} from './LFFavoriteMiddleware';

const asset = `${LFAssetBaseUrl}/icons/v1`;

const FavButton = ({ productId }: { productId: string }) => {
  const wishlist = useLoubiFutureState()?.wishlist;
  const wishlistItems = wishlist?.wishlistItems || [];
  const dispatch = useDispatch();
  const alreadyInWishlist = wishlistItems.some((p) => p.itemId === productId);
  const shouldShowPopup = !wishlist?.dontShowPopup;
  const [animated, setAnimated] = React.useState(false);

  const handleAddWishlist = () => {
    if (!alreadyInWishlist) {
      logEvent(DID_ADD_TO_WISHLIST, DID_ADD_TO_WISHLIST, {
        id: productId
      });
      dispatch(actionAddToWishlist(productId));
    } else {
      logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
        id: productId
      });
      dispatch(actionRemoveFromWishlist(productId));
    }
  };

  const handleAddWishlistAsync = () => {
    if (!alreadyInWishlist) {
      dispatch(actionAddToWishlistAsync(productId, wishlist));
    } else {
      dispatch(actionRemoveFromWishlistAsync(productId, wishlist));
    }
  };

  const handleClick = () => {
    if (shouldShowPopup) {
      dispatch(actionOpenLFPopup(LFPopupContent.GET_NOTIFIED));
    }
    if (wishlist?.id) {
      handleAddWishlistAsync();
    } else {
      handleAddWishlist();
    }
    setAnimated(true);
    setTimeout(() => setAnimated(false), 900);
  };
  return (
    <button
      className={`btn favButton ${animated ? 'animated' : ''}`}
      onClick={handleClick}
    >
      <img
        className="glow"
        src={`${asset}/${
          alreadyInWishlist ? 'addedtofav_white.png' : 'addtofav_white.png'
        }`}
        alt=""
      />
      <style jsx>{`
        .favButton {
          outline: none !important;
          box-shadow: none !important;
        }

        .favButton img {
          height: 20vh;
          max-height: 170px;
          width: auto;
        }
        .animated {
          -webkit-animation: bounce-top 0.9s both;
          animation: bounce-top 0.9s both;
        }

        @media (min-width: 768px) {
          .favButton img {
            height: 16vh;
          }
        }

        @-webkit-keyframes bounce-top {
          0% {
            -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 1;
          }
          24% {
            opacity: 1;
          }
          40% {
            -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          65% {
            -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          82% {
            -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          93% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          25%,
          55%,
          75%,
          87% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            opacity: 1;
          }
        }
        @keyframes bounce-top {
          0% {
            -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            opacity: 1;
          }
          24% {
            opacity: 1;
          }
          40% {
            -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          65% {
            -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          82% {
            -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          93% {
            -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          25%,
          55%,
          75%,
          87% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            opacity: 1;
          }
        }
      `}</style>
    </button>
  );
};

export default FavButton;

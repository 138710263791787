import React from 'react';

const NestedMenuButton = ({
  title,
  top,
  right,
  children
}: {
  title: React.ReactNode;
  top: number;
  right: number;
  children: React.ReactNode;
}) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <div className="nested-container">
        <button
          className="btn"
          onClick={() => {
            setShow(!show);
          }}
        >
          {title}
        </button>
        {show && (
          <div
            className="nested-content"
            onClick={() => {
              setShow(false);
            }}
          >
            {children}
          </div>
        )}
      </div>

      <style jsx>
        {`
          .nested-container {
            position: relative;
            overflow: visible;
          }

          .nested-content {
            position: absolute;
            top: ${`${top}px`};
            right: ${`${right}px`};
            border-radius: 5px;
            overflow: hidden;
          }
        `}
      </style>
    </>
  );
};

export default NestedMenuButton;

import React from 'react';
import { logEvent } from '../../../../analytics';
import { DID_CLICK_TOGGLE_GYROSCOPE } from '../../../../utils/constants';
import { isUserOnAndroid } from '../../../../utils/deviceDetector';

const LFGyroControls = ({
  isOn,
  onToggle
}: {
  isOn: boolean;
  onToggle: (on: boolean) => void;
}) => {
  const [permissionGranted, setPermissionGranted] = React.useState(false);
  const requestPermission = () => {
    if (
      DeviceOrientationEvent &&
      typeof DeviceOrientationEvent['requestPermission'] === 'function'
    ) {
      DeviceOrientationEvent['requestPermission']()
        .then((response) => {
          if (response == 'granted') {
            setPermissionGranted(true);
            onToggle(true);
            logEvent(DID_CLICK_TOGGLE_GYROSCOPE, DID_CLICK_TOGGLE_GYROSCOPE, {
              gyroscopeOn: true
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
  return (
    <div className="LFGyroControls">
      <div
        onClick={() => {
          if (permissionGranted || isUserOnAndroid()) {
            logEvent(DID_CLICK_TOGGLE_GYROSCOPE, DID_CLICK_TOGGLE_GYROSCOPE, {
              gyroscopeOn: !isOn
            });
            onToggle(!isOn);
          } else {
            requestPermission();
          }
        }}
        className="control-btn on"
      >
        {isOn ? (
          <img src="/asset/loubifuture/gyrooff.png" />
        ) : (
          <img src="/asset/loubifuture/gyroon.png" />
        )}
      </div>

      <style jsx>{`
        .LFGyroControls {
          z-index: 11000;
          position: fixed;
          right: 0;
          top: 0;
        }
        .control-btn {
          cursor: pointer;
        }
        .control-btn img {
          width: 50px;
        }
      `}</style>
    </div>
  );
};

export default LFGyroControls;

import React from 'react';
import { BsArrowLeftRight } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../../interfaces';
import { actionAddProductToCompare } from '../../../redux/actions';
import { logEvent } from '../../../analytics';
import { DID_ADD_PRODUCT_TO_COMPARE } from '../../../utils/constants';
import { useTranslation } from '../../../i18n';
import Link from '../../Common/Link';

const CompareButton = ({
  product,
  brandId
}: {
  product: IProduct;
  brandId: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <p
      className="btn-compare"
      onClick={() => {
        dispatch(
          actionAddProductToCompare({
            id: product.id,
            type: product.type,
            brandId: brandId
          })
        );
        logEvent(DID_ADD_PRODUCT_TO_COMPARE, product.id);
      }}
    >
      <BsArrowLeftRight />
      <span>
        <Link href={`/compare/[id]`} as={`/compare/${product.brandId}`}>
          {t('compare_product')}
        </Link>
      </span>
      <style jsx>{`
        .btn-compare {
          margin-top: 20px;
          cursor: pointer;
        }
        span {
          vertical-align: middle;
          margin-left: 8px;
        }

        .btn-compare :global(a) {
          color: inherit;
        }
      `}</style>
    </p>
  );
};

export default CompareButton;

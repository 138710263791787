import React from 'react';
import { IProduct, IVirtualBoutiqueContact } from '../../../interfaces';
import { isChatAvailable } from '../../../utils/stores';
import { AppContext } from '../../AppContext';
import AppointmentButton from './AppointmentButton';
import ChatButton from './ChatButton';
import EnquireButton, { EnquireNowContact } from './EnquireButton';
import OrderNowButton from './OrderNowButton';

const JLCProductCallToActionButton = ({ product }: { product: IProduct }) => {
  const context = React.useContext(AppContext);
  const storeContacts: IVirtualBoutiqueContact[] =
    context.storeConfig?.contacts || [];

  const enquireNowContacts: EnquireNowContact[] = storeContacts.map((c) => ({
    email: c.email,
    name: c.name,
    nameKey: c.nameKey
  }));

  const renderButton = () => {
    return isChatAvailable(context.storeConfig) ? (
      <ChatButton contacts={storeContacts} />
    ) : (
      <EnquireButton contacts={enquireNowContacts} />
    );
  };

  return (
    <div className="action-buttons">
      {renderButton()}
      <AppointmentButton product={product} />
      {context.storeConfig?.entitlement?.shoppingCart && (
        <OrderNowButton product={product} />
      )}
    </div>
  );
};

export default JLCProductCallToActionButton;

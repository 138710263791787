import React from 'react';
import { Transition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import { IMainState } from './../../interfaces';
import { logEvent } from '../../analytics';
import {
  DID_CLICK_CART_CLOSE_BUTTON,
  DID_CLICK_CLOSE_BTN,
  DID_FAIL_TO_VERIFY_CHECKOUT_CKO_SESSION_ID,
  DID_SUBMIT_CHECKOUT_CKO_SESSION_ID,
  DID_VERIFY_CHECKOUT_CKO_SESSION_ID
} from '../../utils/constants';
import { actionMuteBgSound, actionUnmuteBgSound } from './../../redux/actions';
import CheckoutSummary from './Checkout/CheckoutSummary';
import { Spinner } from 'react-bootstrap';
import { getCkoSessionById } from './checkoutService';

const DECLINED = "Declined";

interface Props {
  ckoSessionId: string;
}

interface IDetails {
  orderId: string;
  customer?: {
    email?: string;
    id?: string;
    name?: string;
  };
}

const CheckoutSummaryPopup = ({ ckoSessionId }: Props) => {
  const dispatch = useDispatch();
  const animationDuration = 300;
  const vbState = useSelector((state: IMainState) => state.clientState?.vb);

  const isMute = vbState?.muteBackgroundSound || false;
  const [open, setOpen] = React.useState(false);
  const [cardDeclined, setCardDeclined] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(true);
  const [details, setDetails] = React.useState<IDetails>(null);

  const handleClose = () => {
    const url = document.location.href;
    const newUrl = url.split('?cko-session-id')[0];
    window.history.replaceState({}, '', newUrl);
    if (isMute) {
      dispatch(actionUnmuteBgSound());
    }
    logEvent(DID_CLICK_CLOSE_BTN, DID_CLICK_CLOSE_BTN, {
      page: 'Checkout CKO sessionId verify'
    });
    setOpen(false);
  };

  React.useEffect(() => {
    if (ckoSessionId) {
      setOpen(true);
      dispatch(actionMuteBgSound());
      setSubmitting(true);
      logEvent(DID_SUBMIT_CHECKOUT_CKO_SESSION_ID);
      getCkoSessionById(ckoSessionId)
        .then((result) => {
          const { approved, customer, orderId, status } = result.data;
          if (approved) {
            setDetails({
              orderId: orderId,
              customer: {
                id: customer?.id,
                name: customer?.name,
                email: customer?.email
              }
            });
            setSubmitting(false);
            logEvent(
              DID_VERIFY_CHECKOUT_CKO_SESSION_ID,
              DID_VERIFY_CHECKOUT_CKO_SESSION_ID,
              {
                orderId: orderId,
                customer: {
                  id: customer?.id,
                  name: customer?.name,
                  email: customer?.email
                }
              }
            );
            return;
          }

          if(!approved && status === DECLINED){
            setDetails({
              orderId: null
            });
            setCardDeclined(true);
            setSubmitting(false);
            logEvent(DID_FAIL_TO_VERIFY_CHECKOUT_CKO_SESSION_ID, DID_FAIL_TO_VERIFY_CHECKOUT_CKO_SESSION_ID, {
              paymentStatus: status
            });
            return;
          }

          setDetails({
            orderId: null
          });
          setSubmitting(false);
          logEvent(DID_FAIL_TO_VERIFY_CHECKOUT_CKO_SESSION_ID);
        })
        .catch((error) => {
          console.log(error)
          setDetails({
            orderId: null
          });
          setSubmitting(false);
        });
    }
  }, []);

  return (
    <div className="CheckoutSummaryPopup appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div className={`popup-container fadein ${state}`} id="ap-popup">
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => {
                  handleClose();
                  logEvent(DID_CLICK_CART_CLOSE_BUTTON);
                }}
              >
                &times;
              </button>
              {isSubmitting && (
                <div className="loading">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
              {!isSubmitting && details && (
                <CheckoutSummary
                  onClose={handleClose}
                  checkoutEmail={details?.customer?.email}
                  checkoutName={details?.customer?.name}
                  checkoutOrderId={details?.orderId}
                  cardDeclined={cardDeclined}
                />
              )}
            </div>
          </div>
        )}
      </Transition>
      <style jsx>{`
        .loading {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        @media (min-width: 1024px) {
          .appointment #ap-close {
            width: 95%;
            max-width: 1200px;
            transform: translateX(-50%);
            padding-right: 30px;
            margin-left: 0;
            text-align: right;
          }
          .appointment :global(.input-group) {
            margin-top: 0;
            margin-bottom: 40px;
          }
          .appointment :global(.CartForm) {
            padding-top: 30px;
          }
          .appointment .form-container {
            width: 95%;
            max-width: 1200px;
            padding: 70px 50px 30px;
          }
        }
      `}</style>
    </div>
  );
};

export default CheckoutSummaryPopup;

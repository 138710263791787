import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { saveWishlist } from '../../../../../clientSideServices/wishlist';
import { loubiFutureSiteId, louboutinBrandId } from '../../../../../config';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';
import { useTranslation } from '../../../../../i18n';

import {
  actionOpenLFPopup,
  actionUpdateLFWishlist
} from '../../../../../redux/customActions/loubiFuture';
import {
  DID_FAILED_TO_SEND_WISHLIST,
  DID_SEND_WISHLIST,
  DID_SUBMIT_GET_NOTIFIED
} from '../../../../../utils/constants';
import { getIdentityId } from '../../../../../utils/identity';
import { generateV4UUID } from '../../../../../utils/identityGenerator';
import { useLoubiFutureState } from '../../../../hooks/loubiFuture';
import { LFColor } from '../../../../ThreejsAnimation/loubiFuture/assets';
import {
  isAllowedForEmail,
  isValidEmail
} from '../../../Appointment/inputValidator';
import LFButton from '../Common/LFButton';
import LFTerms from '../Common/LFTerms';
import LFTextInput from '../Common/LFTextInput';
import { mapWishlistStateToPayload } from '../Favorites/LFFavoriteMiddleware';
import { Status, saveLabel } from './GetNotified';

const GetNotified2 = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const wishlist = useLoubiFutureState().wishlist;
  const region = encodeURIComponent(router.query.utm_campaign as string);
  const [email, setEmail] = React.useState('');
  const [saveStatus, setSaveStatus] = React.useState<Status>(Status.INITIAL);
  const [error, setError] = React.useState(false);
  const [agree, setAgree] = React.useState(false);

  const { t } = useTranslation();

  const handleSubmit = () => {
    if (error) {
      return;
    }

    if (!isValidEmail(email)) {
      setError(true);
      return;
    }

    if (!agree || !email.trim()) {
      setSaveStatus(Status.ERROR);
      return;
    }

    const payload = {
      ...mapWishlistStateToPayload(wishlist),
      email,
      region,
      acceptedTermsOfUse: agree,
      id: generateV4UUID(),
      identityId: getIdentityId(),
      description: 'User Journey 2',
      store: loubiFutureSiteId,
      brand: louboutinBrandId,
      status: 'INTEREST_SUBMITTED',
      createdAt: new Date(),
      createdBy: getIdentityId()
    };

    setSaveStatus(Status.LOADING);

    logEvent(DID_SUBMIT_GET_NOTIFIED, DID_SUBMIT_GET_NOTIFIED);

    saveWishlist(payload)
      .then(() => {
        logEvent(DID_SEND_WISHLIST, DID_SEND_WISHLIST, { id: payload.id });
        setSaveStatus(Status.SUCCESS);
        dispatch(actionUpdateLFWishlist(payload));
      })
      .catch((e) => {
        console.log(e);
        setSaveStatus(Status.FAILED);
        logEvent(DID_FAILED_TO_SEND_WISHLIST, DID_FAILED_TO_SEND_WISHLIST, {
          payload: {
            ...payload,
            name: undefined,
            surname: undefined,
            phone: undefined,
            email: undefined
          },
          error
        });
      });
  };

  const form = (
    <>
      <h3 className="LFTitle">{t('vb:receive_notification')?.toUpperCase()}</h3>
      <p>{t('vb:do_you_want_to_be_posted')}</p>
      <div className="form-container">
        <LFTextInput
          placeHolder={t('vb:email_address')?.toUpperCase()}
          value={email}
          name="email"
          error={error ? 'Invalid email address' : ''}
          onChange={(value) => {
            setEmail(value);
            setSaveStatus(Status.INITIAL);
            setError(!isAllowedForEmail(value));
          }}
        />
        <LFTerms
          checked={agree}
          onClick={() => {
            setAgree(!agree);
            setSaveStatus(Status.INITIAL);
          }}
        />

        <div className="text-center">
          {saveStatus === Status.ERROR && (
            <div className="error">
              {!email && 'email, '}
              {!agree && 'agreement approval '}required
            </div>
          )}
          <div className={`button-wrap`}>
            <LFButton
              disabledDefaultLog
              name="submitGetNotified2"
              onClick={handleSubmit}
            >
              {saveLabel(saveStatus)}
            </LFButton>
          </div>
        </div>
      </div>
    </>
  );

  const success = (
    <>
      <h3 className="LFTitle">{t('vb:receive_notification')?.toUpperCase()}</h3>
      <img src={`${LFAssetBaseUrl}/icons/v1/success.png`} />
      <p>{t('vb:we_will_send_email_when_available', { email })}</p>
      <div className="text-center">
        <LFButton
          name="closeGetNotified2"
          onClick={() => dispatch(actionOpenLFPopup(undefined))}
        >
          {t('vb:quit')?.toUpperCase()}
        </LFButton>
      </div>
      <style jsx>{`
        img {
          width: 60px;
          height: auto;
          margin: 20px auto;
        }
        p {
          color: ${LFColor.blue};
        }
      `}</style>
    </>
  );

  return (
    <div className="GetNotified2">
      {saveStatus === Status.SUCCESS ? success : form}
    </div>
  );
};

export default GetNotified2;

import React from 'react';

function ConnectionStatsTable({
  isLocal,
  connectionSummary,
  bandwidth,
  bitrate,
  bridgeCount,
  codec,
  framerate,
  packetLoss,
  participantId,
  resolution,
  maxEnabledResolution
}) {
  const [showMore, setShowMore] = React.useState(false);
  const renderAdditionalStats = () => (
    <table className="connection-additionals-stats">
      {isLocal && renderBandwidth()}
      {isLocal && renderBrigdeCount()}
      {renderCodecs()}
      {renderParticipantId()}
    </table>
  );
  const renderSummary = () => {
    return (
      <tr className="connection-info-status">
        <td>
          <span>Connection: </span>
        </td>
        <td>{connectionSummary}</td>
      </tr>
    );
  };
  const renderBitrate = () => {
    const { download, upload } = bitrate || {};
    return (
      <tr>
        <td>
          <span>Bitrate:</span>
        </td>
        <td>
          <span className="connection-info__download">&darr;</span>
          {download ? `${download} Kbps ` : 'N/A '}
          <span className="connection-info__upload">&uarr;</span>
          {upload ? `${upload} Kbps` : 'N/A'}
        </td>
      </tr>
    );
  };
  const renderPacketLoss = () => {
    let packetLossTableData;

    if (packetLoss) {
      const { download, upload } = packetLoss;

      packetLossTableData = (
        <td>
          <span className="connection-info__download">&darr;</span>
          {download === null ? 'N/A ' : `${download}% `}
          <span className="connection-info__upload">&uarr;</span>
          {upload === null ? 'N/A' : `${upload}%`}
        </td>
      );
    } else {
      packetLossTableData = <td>N/A</td>;
    }

    return (
      <tr>
        <td>
          <span>Packet loss:</span>
        </td>
        {packetLossTableData}
      </tr>
    );
  };

  const renderResolution = () => {
    let resolutionString =
      Object.keys(resolution || {})
        .map((ssrc) => {
          const { width, height } = resolution[ssrc];

          return `${width}x${height}`;
        })
        .join(', ') || 'N/A';
    if (maxEnabledResolution) {
      resolutionString += `, max: ${maxEnabledResolution}p`;
    }
    return (
      <tr>
        <td>
          <span>Resolution:</span>
        </td>
        <td>{resolutionString}</td>
      </tr>
    );
  };

  const renderFramerate = () => {
    const frameRateString =
      Object.keys(framerate || {})
        .map((ssrc) => framerate[ssrc])
        .join(', ') || 'N/A';

    return (
      <tr>
        <td>
          <span>Frame rate: </span>
        </td>
        <td>{frameRateString}</td>
      </tr>
    );
  };

  const renderCodecs = () => {
    if (!codec) {
      return;
    }

    let codecString;

    // Only report one codec, in case there are multiple for a user.
    Object.keys(codec || {}).forEach((ssrc) => {
      const { audio, video } = codec[ssrc];

      codecString = `${audio}, ${video}`;
    });

    if (!codecString) {
      codecString = 'N/A';
    }

    return (
      <tr>
        <td>
          <span>Codecs (A/V): </span>
        </td>
        <td>{codecString}</td>
      </tr>
    );
  };

  const renderBandwidth = () => {
    const { download, upload } = bandwidth || {};

    return (
      <tr>
        <td>Estimated bandwidth: </td>
        <td>
          <span className="connection-info__download">&darr;</span>
          {download ? `${download} Kbps ` : 'N/A '}
          <span className="connection-info__upload">&uarr;</span>
          {upload ? `${upload} Kbps` : 'N/A'}
        </td>
      </tr>
    );
  };
  const renderParticipantId = () => {
    return (
      <tr>
        <td>
          <span>Participant ID: </span>
        </td>
        <td>{participantId || 'N/A'}</td>
      </tr>
    );
  };

  const renderBrigdeCount = () => {
    // 0 is valid, but undefined/null/NaN aren't.
    if (!bridgeCount && bridgeCount !== 0) {
      return;
    }

    return (
      <tr>
        <td>
          <span>Bridge count: </span>
        </td>
        <td>{bridgeCount}</td>
      </tr>
    );
  };
  const renderShowMoreToggle = () => {
    return (
      <div className="show-more-wrapper">
        <span className="show-more" onClick={() => setShowMore(!showMore)}>
          {!showMore ? 'Show more...' : 'Show less...'}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="connection-info-container">
        <table className="connection-info-table">
          <tbody>
            {renderSummary()}
            {renderBitrate()}
            {renderPacketLoss()}
            {renderResolution()}
            {renderFramerate()}
          </tbody>
        </table>
        {renderShowMoreToggle()}
        {showMore && renderAdditionalStats()}
      </div>
      <style jsx>
        {`
          :global(.show-more-wrapper) {
            color: #b8b8ef;
            cursor: pointer;
            text-align: center;
            user-select: none;
            transition: 0.3s ease color;
          }
          :global(.show-more-wrapper:hover) {
            text-decoration: underline;
            color: #6c6c8b;
          }
          :global(.connection-info-container .connection-info-status) {
            font-weight: bold;
          }
        `}
      </style>
    </>
  );
}

export default ConnectionStatsTable;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IProduct, ProductGrouping } from '../../interfaces';
import ProductThumbnail from '../Product/ProductThumbnail';

const ShowcaseProducts = ({
  products,
  onClick,
  productGrouping
}: {
  products: IProduct[];
  onClick: () => void;
  productGrouping?: ProductGrouping[];
}) => {
  const [grouping, setGrouping] = React.useState('ALL');

  const renderGroupingHeader = () => {
    if (!productGrouping) {
      return null;
    }
    return (
      <div className="grouping-headers">
        {['ALL', ...productGrouping.map((g) => g.name)].map((g) => (
          <span
            key={g}
            onClick={() => {
              setGrouping(g);
            }}
            className={grouping === g ? 'selected' : ''}
          >
            {g}
          </span>
        ))}
        <style jsx>
          {`
            .grouping-headers {
              display: flex;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 30px;
              scrollbar-width: none;
              -ms-overflow-style: none;
            }

            .grouping-headers::-webkit-scrollbar {
              width: 0;
              height: 0;
            }

            .grouping-headers span {
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              position: relative;
              transition: color 0.2s ease-in;
            }
            .grouping-headers span:hover,
            .grouping-headers span.selected {
              color: #3a6e90;
            }

            .grouping-headers span.selected::before {
              content: ' ';
              position: absolute;
              left: calc(50% - 2.5px);
              top: 25px;
              width: 0;
              height: 0;
              border-color: #3a6e90 transparent transparent;
              border-style: solid;
              border-width: 4px 3px 0;
            }

            @media (max-width: 1200px) {
              .grouping-headers span {
                font-size: 12px;
              }
            }

            @media (max-width: 768px) {
              .grouping-headers {
                width: auto;
                height: 30px;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow: auto;
                align-items: center;
                margin-bottom: 0;
              }

              .grouping-headers span {
                padding: 0 20px 10px 20px;
                white-space: nowrap;
              }
            }
          `}
        </style>
      </div>
    );
  };
  return (
    <Row className="justify-content-center">
      {renderGroupingHeader()}
      {products
        .filter((p) => {
          if (!productGrouping) return true;
          if (grouping === 'ALL') return true;
          const match = productGrouping.find((g) => g.name === grouping);
          if (!match) return true;
          return match.products.includes(p.id);
        })
        .map((product: IProduct, index: number) => (
          <Col xs={6} lg={3} sm={4} key={index} id={`showcase-${index}`}>
            <ProductThumbnail product={product} onClick={() => onClick()} />
          </Col>
        ))}
    </Row>
  );
};

export default ShowcaseProducts;

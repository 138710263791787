import React from 'react';
import * as translation from '../../../i18n';
import { IProduct, IVirtualBoutiqueContact } from '../../../interfaces';
import {
  getProductDisplayName,
  getProductOriginalLink
} from '../../../utils/product';
import { isChatAvailable } from '../../../utils/stores';
import { AppContext } from '../../AppContext';
import AppointmentButton from './AppointmentButton';
import ChatAndEmailButton from './ChatAndEmailButton';
import EnquireButton, { EnquireNowContact } from './EnquireButton';
import LinkButton from './LinkButton';
import OrderNowButton from './OrderNowButton';

export const formatContact = (contact?: string) => {
  return contact?.replace('+', '')?.replace(/\s+/g, '');
};

const IWCDubaiProductCallToActionButton = ({
  product
}: {
  product: IProduct;
}) => {
  const context = React.useContext(AppContext);

  const productLink = getProductOriginalLink(
    product,
    context.store?.countryCode || 'AE'
  );

  const storeContacts: IVirtualBoutiqueContact[] =
    context.storeConfig?.contacts || [];

  const { t, i18n } = translation.useTranslation();

  const enquireNowContacts: EnquireNowContact[] = storeContacts.map((c) => ({
    email: c.email,
    cc: c.cc,
    bcc: c.bcc,
    name: c.name,
    nameKey: c.nameKey,
    subject: `${getProductDisplayName(product, i18n.language)} (Ref.${
      product.modelCode
    }) - ${c.nameKey ? t(`vb:${c.nameKey}`) : c.name}`
  }));

  const defaultMessage = t('iwc_dubai_chat_with_product_default_message', {
    watchInfo: `${getProductDisplayName(product, i18n.language)} (Ref.${
      product.modelCode
    })`
  });

  const renderButton = () => {
    if (context.storeConfig)
      return isChatAvailable(context.storeConfig) ? (
        <ChatAndEmailButton
          whatsappContacts={storeContacts}
          enquireNowContacts={enquireNowContacts.filter(
            (c) => c.nameKey === 'iwc_other_boutiques'
          )}
          defaultMessage={defaultMessage}
          labelTextKey={'contact_us_now'}
        />
      ) : (
        <EnquireButton
          contacts={enquireNowContacts}
          labelTextKey={'contact_us_now'}
        />
      );
    if (productLink) return <LinkButton url={productLink} />;
  };

  return (
    <div className="action-buttons">
      {renderButton()}
      <AppointmentButton product={product} />
      {context.storeConfig?.entitlement?.shoppingCart && (
        <OrderNowButton product={product} ctaText="add_to_bag" />
      )}
    </div>
  );
};

export default IWCDubaiProductCallToActionButton;

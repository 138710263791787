import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../../analytics';
import { createUser } from '../../../../clientSideServices/users';
import {
  IMainState,
  EnquiryType
} from '../../../../interfaces';
import { actionUpdateUserAttributesAsync } from '../../../../redux/asyncActions';
import {
  DID_FAIL_TO_SEND_ENQUIRY_EMAIL,
  DID_SEND_ENQUIRY_EMAIL
} from '../../../../utils/constants';
import { getIdentityId } from '../../../../utils/identity';
import PoweredByInspify from '../../../Legal/PoweredByInspify';
import PrivacyPolicy from '../../../Legal/PrivacyPolicy';
import TermsOfUse from '../../../Legal/TermsOfUse';

import {
  isValidCountryCode,
  isValidEmail,
  isValidMessage,
  isValidName,
  isValidPhoneNumber
} from '../../Appointment/inputValidator';

import { useTranslation } from '../../../../i18n';
import { iwcUaeDubaiBoutiqueId } from '../../../../config';
import { actionEnquiryPopup } from '../../../../redux/actions';
import { sendEnquiryFormEmail } from '../../Appointment/enquiryService';

export interface IEnquiryFormData {
  name: string;
  email: string;
  phone: string;
  countryCode: string;
  salutation: string;
  modelName: string;
  modelCode: string;
  enquiry: string;
  subscribeNews: boolean;
  acceptedTermsOfUse: boolean;
}

interface IEnquiryFormPristine {
  name: boolean;
  email: boolean;
  phone: boolean;
  salutation: boolean;
  modelName: boolean;
  modelCode: boolean;
  enquiry: boolean;
  acceptedTermsOfUse: boolean;
}

enum EmailSentState {
  INITIAL = 'SEND',
  SENDING = 'SENDING...',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

const AskWatchMaker = () => {
  const dispatch = useDispatch();
  const enquiryState = useSelector(
    (state: IMainState) => state.clientState.vb?.enquiryPopup
  );

  const open = enquiryState?.open;

  const [emailSent, setEmailSent] = React.useState(false);
  const [emailSentState, setEmailSentState] = React.useState<EmailSentState>(
    EmailSentState.INITIAL
  );
  const [pristine, setPristine] = React.useState<IEnquiryFormPristine>({
    name: true,
    email: true,
    phone: true,
    salutation: true,
    modelName: true,
    modelCode: true,
    enquiry: true,
    acceptedTermsOfUse: true
  });

  const [formData, setFormData] = React.useState<IEnquiryFormData>({
    name: undefined,
    email: undefined,
    phone: undefined,
    countryCode: '+971',
    salutation: undefined,
    modelName: undefined,
    modelCode: undefined,
    enquiry: undefined,
    subscribeNews: false,
    acceptedTermsOfUse: false
  });

  const mandatoryFields: (keyof IEnquiryFormData)[] = [
    'name',
    'email',
    'phone',
    'countryCode',
    'salutation',
    'modelName',
    'modelCode',
    'enquiry',
    'acceptedTermsOfUse'
  ];

  const salutations = ['Dr.', 'Mr.', 'Ms.', 'Mrs.'];

  const animationDuration = 300;
  const handleClose = () => {
    dispatch(actionEnquiryPopup({ open: false }));
    setTimeout(() => {
      setEmailSent(false);
    }, animationDuration);
  };

  const handleSalutation = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, salutation: false });
    setFormData({ ...formData, salutation: value });
  };

  const handleName = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, name: false });
    setFormData({ ...formData, name: value });
  };

  const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    setFormData({ ...formData, email: value });
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, countryCode: value });
  };

  const handlePhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });
    setFormData({ ...formData, phone: value });
  };
  const handleModelName = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, modelName: false });
    setFormData({ ...formData, modelName: value });
  };

  const handleModelCode = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, modelCode: false });
    setFormData({ ...formData, modelCode: value });
  };

  const handleEnquiry = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, enquiry: false });
    setFormData({ ...formData, enquiry: value });
  };

  const handleTC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
  };

  const handleNewsletterSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({ ...formData, subscribeNews: value });
  };

  const removePristine = () => {
    const nonPristine: IEnquiryFormPristine = {
      name: false,
      email: false,
      phone: false,
      salutation: false,
      modelName: false,
      modelCode: false,
      enquiry: false,
      acceptedTermsOfUse: false
    };
    setPristine(nonPristine);
  };

  const handleSend = () => {
    removePristine();

    if (mandatoryFields.some((field) => !formData[field])) {
      return;
    }
    if (
      !isValidEmail(formData.email) ||
      !isValidCountryCode(formData.countryCode) ||
      !isValidName(formData.name) ||
      !isValidPhoneNumber(formData.phone) ||
      !isValidMessage(formData.enquiry)
    ) {
      return;
    }
    setEmailSentState(EmailSentState.SENDING);
    sendEnquiryFormEmail(
      formData,
      EnquiryType.ASK_WATCHMAKER,
      iwcUaeDubaiBoutiqueId
    )
      .then(() => {
        setEmailSentState(EmailSentState.INITIAL);
        setEmailSent(true);
        logEvent(DID_SEND_ENQUIRY_EMAIL);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(
          DID_FAIL_TO_SEND_ENQUIRY_EMAIL,
          DID_FAIL_TO_SEND_ENQUIRY_EMAIL,
          { error }
        );
      })
      .finally(() => {
        const user = createUser(
          getIdentityId(),
          formData.name,
          formData.email,
          formData.countryCode,
          formData.phone
        );
        dispatch(
          actionUpdateUserAttributesAsync(user.id, {
            alias: user.alias,
            email: user.email,
            mobilePhoneCountryCode: user.mobilePhoneCountryCode,
            mobileNumber: user.mobileNumber
          })
        );
      });
  };

  const { t } = useTranslation();

  return (
    <div className="appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className={`popup-container fadein ${
              emailSent ? 'successful' : ''
            } ${state}`}
            id="ap-popup"
          >
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => handleClose()}
              >
                &times;
              </button>
              <div className="logo">
                <img
                  src="https://panos2.inspify.io/virtual_boutique/316261f4-fbf6-49e2-88cf-e4d166f79267/logo.png"
                  alt="LOGO"
                />
              </div>
              {!emailSent && (
                <form id="emailform">
                  <h2>{t('vb:talk_to_watchmaker')}</h2>
                  <h3>{t('vb:general_information')}</h3>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:salutation')}:</label>
                      <select
                        id="inputsalutation"
                        name="salutation"
                        required
                        onChange={handleSalutation}
                      >
                        <option value="">{t('vb:select')}</option>
                        {salutations.map((s) => (
                          <option key={s} value={s}>
                            {s}
                          </option>
                        ))}
                      </select>
                      {!pristine.salutation &&
                        !salutations.includes(formData.salutation) && (
                          <span className="error">
                            {t('vb:please_select_your_salutation')}
                          </span>
                        )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:name')}:</label>
                      <input
                        type="text"
                        id="inputname"
                        name="name"
                        placeholder={t('vb:enter_your_name')}
                        onBlur={handleName}
                      />
                      {!pristine.name && !isValidName(formData.name) && (
                        <span className="error">
                          {t('vb:please_enter_valid_name')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('email')}:</label>
                      <input
                        type="email"
                        id="inputemail"
                        name="email"
                        placeholder={t('vb:enter_your_email')}
                        onBlur={handleEmail}
                      />
                      {!pristine.email && !isValidEmail(formData.email) && (
                        <span className="error">
                          {t('vb:please_enter_valid_email')}
                        </span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:phone_number')}:</label>
                      <div className="phone-number-input">
                        <input
                          type="tel"
                          id="inputcountrycode"
                          name="countrycode"
                          placeholder="+00"
                          value={formData.countryCode}
                          onChange={handleCountryCode}
                        />
                        <input
                          type="tel"
                          id="inputphone"
                          name="phone"
                          placeholder={t('vb:enter_phone_number')}
                          onBlur={handlePhoneNumber}
                        />
                      </div>
                      {!pristine.phone &&
                        (!isValidCountryCode(formData.countryCode) ||
                          !isValidPhoneNumber(formData.phone)) && (
                          <span className="error">
                            {t('vb:please_enter_valid_phone_number')}
                          </span>
                        )}
                    </div>
                  </div>

                  <h3>{t('vb:enquiry_detail')}</h3>

                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:watch_name')}:</label>
                      <input
                        type="text"
                        id="inputmodelname"
                        name="modelname"
                        placeholder={t('vb:enter_watch_name')}
                        onBlur={handleModelName}
                      />

                      {!pristine.modelName && !formData.modelName && (
                        <span className="error">{t('vb:required')}</span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:watch_serial_number')}:</label>

                      <input
                        type="text"
                        id="inputmodelcode"
                        name="modelcode"
                        placeholder={t('vb:enter_serial_number')}
                        onBlur={handleModelCode}
                      />

                      {!pristine.modelCode && !formData.modelCode && (
                        <span className="error">{t('vb:required')}</span>
                      )}
                    </div>
                  </div>
                  <div className="input-group">
                    <label>{t(`vb:ask_your_question`)}</label>
                    <textarea
                      name="enquiry"
                      id="inputenquiry"
                      cols={30}
                      rows={10}
                      placeholder={t('vb:message')}
                      onBlur={handleEnquiry}
                    ></textarea>
                    {!pristine.enquiry &&
                      !(
                        formData.enquiry && isValidMessage(formData.enquiry)
                      ) && <span className="error">{t('vb:required')}</span>}
                  </div>

                  <div className="input-group tstc">
                    <label>
                      <input
                        type="checkbox"
                        id="inputtstc"
                        onChange={handleTC}
                      />
                      {!pristine.acceptedTermsOfUse &&
                        !formData.acceptedTermsOfUse && (
                          <span className="error">
                            {t('vb:please_accept_tc')}
                          </span>
                        )}
                      {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
                      <PrivacyPolicy />
                      {t('vb:tc_part_three')}
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="inputsubscribe"
                        onChange={handleNewsletterSubscription}
                      />
                      {t('vb:confirm_subscribe_newsletter')}
                    </label>
                  </div>

                  <div className="input-group">
                    <button
                      id="sendform"
                      type="button"
                      onClick={() => handleSend()}
                    >
                      {t(`vb:${emailSentState}`)}
                    </button>
                    <button
                      id="ap-cancel"
                      className="popup-close"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      {t('vb:close')}
                    </button>
                  </div>
                </form>
              )}
              {emailSent && (
                <div className="success">
                  <h2>{t('vb:thank_you_for_your_question')}</h2>
                  <p>{t('vb:question_in_contact_soon')}</p>
                  <button
                    className="popup-close"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('vb:close')}
                  </button>
                </div>
              )}
              <PoweredByInspify />
            </div>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
        `}
      </style>
      <style jsx>
        {`
          h2 {
            text-transform: uppercase;
          }
          .mobile {
            display: none;
          }

          .full {
            display: inline-block;
          }
          .appointment .error {
            font-size: 11px;
          }
          .tstc {
            margin-top: 20px;
          }
          .appointment .tstc .error {
            top: 0px;
          }
          #inputphone,
          #inputemail {
            direction: ltr !important;
            text-align: left !important;
          }

          @media (max-width: 768px) {
            .mobile {
              display: inline-block;
            }
            .appointment .error {
              top: 15px;
              font-size: 9px;
            }

            .full {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AskWatchMaker;

import httpClient from '../../../clientSideServices/http';
import { EnquiryType } from '../../../interfaces';
import { getIdentityId } from '../../../utils/identity';
import { getFormattedFullName } from '../../../utils/shoppingCart';
import { IEnquiryFormData } from '../CustomComponent/IWCDubaiCustomComponent/AskWatchmaker';
import { ISultanateOfOmanFormData } from '../CustomComponent/IWCDubaiCustomComponent/SultanateOfOman';
import {
  isValidCountryCode,
  isValidEmail,
  isValidMessage,
  isValidPhoneNumber
} from './inputValidator';

export interface IEnquiryEmailPayload {
  storeId: string;
  name: string;
  email: string;
  phone: string;
  countryCode: string;
  salutation: string;
  modelName: string;
  modelCode: string;
  cognitoId: string;
  enquiry: string;
  type: string;
  subscribeNews: boolean;
  acceptedTermsOfUse: boolean;
}

export const enquiryPayloadFromFormData = (
  formData: IEnquiryFormData,
  enquiryType: EnquiryType,
  storeId: string
): IEnquiryEmailPayload => {
  const cognitoId = getIdentityId();

  const email: IEnquiryEmailPayload = {
    storeId,
    name: formData.name || '',
    email: isValidEmail(formData.email) ? formData.email.trim() : undefined,
    phone: isValidPhoneNumber(formData.phone)
      ? formData.phone.trim()
      : undefined,
    countryCode: isValidCountryCode(formData.countryCode)
      ? formData.countryCode.trim()
      : undefined,
    salutation: formData.salutation,
    modelName: formData.modelName,
    modelCode: formData.modelCode,
    cognitoId,
    enquiry: isValidMessage(formData.enquiry)
      ? formData.enquiry.trim()
      : undefined,
    type: enquiryType,
    subscribeNews: formData.subscribeNews,
    acceptedTermsOfUse: formData.acceptedTermsOfUse
  };
  return email;
};

export const sultanateOfOmanPayloadFormData = (
  formData: ISultanateOfOmanFormData,
  enquiryType: EnquiryType,
  storeId: string
): IEnquiryEmailPayload => {
  const cognitoId = getIdentityId();

  const sultanateOfOmanData = {
    ownsIwcTimepiece: formData?.ownsIwcTimepiece,
    iwcTimepieceNote:
      formData?.iwcTimepieceNote && isValidMessage(formData?.iwcTimepieceNote)
        ? formData?.iwcTimepieceNote.trim()
        : undefined,
    likeAboutSultanateOfOman: formData?.likeAboutSultanateOfOman,
    country: formData?.country || '',
    city: formData?.city || ''
  };

  const email: IEnquiryEmailPayload = {
    storeId,
    name: getFormattedFullName(formData?.firstName, formData?.lastName),
    email: isValidEmail(formData.email) ? formData.email.trim() : undefined,
    phone: isValidPhoneNumber(formData.phone)
      ? formData.phone.trim()
      : undefined,
    countryCode: isValidCountryCode(formData.countryCode)
      ? formData.countryCode.trim()
      : undefined,
    salutation: formData?.salutation,
    cognitoId,
    modelName: '',
    modelCode: '',
    type: enquiryType,
    enquiry: JSON.stringify(sultanateOfOmanData),
    subscribeNews: formData?.subscribeNews,
    acceptedTermsOfUse: formData?.acceptedTermsOfUse
  };
  return email;
};

export const sendSultanateOfOmanEmail = (
  formData: ISultanateOfOmanFormData,
  enquiryType: EnquiryType,
  storeId: string
): Promise<any> => {
  const email: IEnquiryEmailPayload = sultanateOfOmanPayloadFormData(
    formData,
    enquiryType,
    storeId
  );
  return enquiryService(email);
};

export const sendEnquiryFormEmail = (
  formData: IEnquiryFormData,
  enquiryType: EnquiryType,
  storeId: string
): Promise<any> => {
  const email: IEnquiryEmailPayload = enquiryPayloadFromFormData(
    formData,
    enquiryType,
    storeId
  );
  return enquiryService(email);
};

const enquiryService = (email: IEnquiryEmailPayload) => {
  return httpClient.post('/api/sendEnquiry', email);
};

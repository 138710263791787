import React from 'react';
import { IVirtualBoutiqueContact } from '../../../interfaces';
import { logClickContact } from './common';
import { useDispatch } from 'react-redux';
import { actionClickContact } from '../../../redux/actions';

const CallNowButton = ({
  contact,
  children
}: {
  contact: IVirtualBoutiqueContact;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  return (
    <a
      key={contact.name}
      data-channel="call"
      href={`tel:${contact.callNumber}`}
      target="_blank"
      rel="noopener"
      aria-label="Call Now"
      onClick={() => {
        logClickContact('call', contact);
        dispatch(actionClickContact());
      }}
    >
      {children}
    </a>
  );
};

export default CallNowButton;

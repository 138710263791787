import React from 'react';
import { zIndex } from '../../../../config';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MapContext } from '../../MapWrapper';
import { butterflyEffectDuration } from './VCAMapButton';
import VCAMapLegend from './VCAMapLegend';
import VCAMapLevel1 from './VCAMapLevel1';
import VCAMapLevel2, { level2Scenes } from './VCAMapLevel2';

enum MapRoom {
  level1 = 'level1',
  level2 = 'level2'
}
const VCAMap = () => {
  const closingSpeed = 300;
  const bounceEnterSpeed = 2000;

  const {
    onSelectRoom,
    open,
    onSelectSpot,
    onToggleMap,
    selectedRoom,
    visitedSpot,
    currentPosition
  } = React.useContext(MapContext);

  const [closing, setClosing] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const isLevel2 = level2Scenes.includes(currentPosition);

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShow(true);
      }, butterflyEffectDuration);
    } else {
      setClosing(true);
      setTimeout(() => {
        setShow(false);
        setClosing(false);
      }, closingSpeed);
    }
  }, [open]);

  React.useEffect(() => {
    onSelectRoom(isLevel2 ? MapRoom.level2 : MapRoom.level1);
  }, [isLevel2]);
  React.useEffect(() => {
    onToggleMap(false);
    return () => {
      onToggleMap(false);
    };
  }, []);
  if (!show) return null;

  return (
    <>
      <div
        className={`VCAMap fixed-full ${closing && 'closing'} ${
          show && 'show'
        }`}
      >
        <div className="backdrop fixed-full" />
        <div className="map-wrapper">
          <VCAMapLevel2
            onSelectSpot={onSelectSpot}
            onSelectFloor={() => onSelectRoom(MapRoom.level1)}
            active={selectedRoom === MapRoom.level2}
            visitedSpot={visitedSpot}
            currentPosition={currentPosition}
          />
          <VCAMapLevel1
            onSelectSpot={onSelectSpot}
            onSelectFloor={() => onSelectRoom(MapRoom.level2)}
            active={!selectedRoom || selectedRoom === MapRoom.level1}
            visitedSpot={visitedSpot}
            currentPosition={currentPosition}
          />
        </div>
        <VCAMapLegend />
        <button
          onClick={() => onToggleMap(false)}
          className="btn btn-close hide-for-client-meeting"
        >
          &times;
        </button>

        <div className="map-veil fixed-full"></div>

        <style jsx global>{`
          .map-container {
            width: fit-content;
            block-size: fit-content;
            margin: auto;
            position: fixed;
            left: 50%;
            top: 50%;
            pointer-events: none;
            z-index: 11;
            transition: all 0.6s linear;
            opacity: 0.2;
            user-select: none;
            backface-visibility: hidden;
          }
          .map-container .inner {
            width: fit-content;
            block-size: fit-content;
            margin: auto;
            position: relative;
          }
          .map-container.true {
            pointer-events: all;
            opacity: 1;
          }
          .map-container .map {
            width: auto;
            height: auto;
          }
          .to-floor {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .map-label {
            color: #fff;
            font-size: 1.7em;
            margin-bottom: 20px;
          }
          .to-floor-label {
            position: absolute;
            color: #fff;
            line-height: 1.2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
          }
          .to-floor-label span {
            display: block;
          }
          @media (min-width: 768px) {
            .map-label {
              font-size: 2.5em;
            }
          }
          @media (min-width: 768px) and (min-height: 700px) {
            .to-floor-label {
              font-size: 20px;
            }
          }
          @media (min-width: 840px) {
            .PRESENTATION .map-container {
              margin-right: ${100 / 2}px;
              margin-bottom: 75px;
            }
            .PRESENTATION .map-legend {
              bottom: 160px !important;
            }
          }
        `}</style>
        <style jsx>{`
          .VCAMap {
            z-index: ${zIndex.popupPage - 1};
            overflow: hidden;
          }

          .map-veil {
            display:none
          }

          :global(.client.SMPortrait) .map-veil,
          :global(.client.SMPortrait) .map-veil{
            display:block;
            z-index:${zIndex.meetingControls + 3}
          }

          .map-wrapper {
            border-radius: 100%;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            overflow: hidden;
          }

          :global(.in-meeting.MDLandscape) .map-wrapper {
            margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
            margin-top: ${MDLandscapeNormalSpec.contentArea.top / 2}px;
          }

          :global(.in-meeting.SMLandscape) .map-wrapper {
            margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
            margin-top: ${SMLandscapeNormalSpec.contentArea.top / 2}px;
          }

          .map-wrapper :global(.map-container) {
            opacity: 0;
          }
          .show .map-wrapper :global(.map-container) {
            opacity: 1;
            animation: Visible ${bounceEnterSpeed / 2000}s linear;
          }

          .backdrop {
            opacity: 0;
            background: rgba(0, 0, 0, 0.8);
          }

          :global(.in-meeting.MDLandscape) .VCAMap,
          :global(.in-meeting.MDLandscape) .backdrop {
            left: ${MDLandscapeNormalSpec.contentArea.left}px;
          }

          :global(.in-meeting.SMPortrait) .VCAMap,
          :global(.in-meeting.MDPortrait) .VCAMap {
            z-index: ${zIndex.meetingControls + 2};
          }
  
          .show .backdrop {
            opacity: 1;
            animation: Visible 1.5s ease-in;
          }
          .closing .backdrop {
            opacity: 0;
            transition: all ${closingSpeed / 1000}s ease-in;
          }
          .btn-close {
            position: fixed;
            top: 10px;
            right: 15px;
            width: 40px;
            height: 40px;
            font-size: 40px;
            color: #fff;
            padding: 0;
            line-height: 1;
            outline: none !important;
            box-shadow: none !important;
          }

          :global(.in-meeting.SM) .btn-close{
            top:${SMLandscapeNormalSpec.contentArea.top + 10}px;
          }

          :global(.in-meeting.MD) .btn-close{
            top:${MDLandscapeNormalSpec.contentArea.top + 10}px;
          }


          @media (orientation: portrait) {
            .map-wrapper {
              width: 0;
              height: 0;
            }
            .show .map-wrapper {
              animation: BouncingPortrait ${bounceEnterSpeed / 1000}s both;
            }
            .closing .map-wrapper {
              animation: BouncingClosePortrait ${closingSpeed / 1000}s linear;
            }
          }

          @media (orientation: landscape) {
            .map-wrapper {
              width: 0;
              height: 0;
            }
            .show .map-wrapper {
              animation: BouncingLandscape ${bounceEnterSpeed / 1000}s linear
                both;
            }
            .closing .map-wrapper {
              animation: BouncingCloseLandscape ${closingSpeed / 1000}s linear;
            }
          }
          @keyframes Visible {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @keyframes BouncingClosePortrait {
            0% {
              width: 100vw;
              height: 100vw;
              border-radius: 0%;
            }
            100% {
              width: 0vw;
              height: 0vw;
              border-radius: 100%;
            }
          }
          @keyframes BouncingCloseLandscape {
            0% {
              width: 100vh;
              height: 100vh;
              border-radius: 0%;
            }
            100% {
              width: 0vh;
              height: 0vh;
              border-radius: 100%;
            }
          }
          @keyframes BouncingLandscape {
            0% {
              width: 0vw;
              height: 0vw;
              opacity: 0;
              border-radius: 100%;
              background: #fff;
            }
            20% {
              width: 10vw;
              height: 10vw;
              opacity: 1;
            }
            40% {
              width: 10vw;
              height: 10vw;
              background: #fff;
            }
            50% {
              background: none;
            }
            100% {
              width: 100vw;
              height: 100vw;
              border-radius: 0;
            }
          }

          @keyframes BouncingPortrait {
            0% {
              width: 0vw;
              height: 0vw;
              opacity: 0;
              border-radius: 100%;
              background: #fff;
            }
            20% {
              width: 20vh;
              height: 20vh;
              opacity: 1;
            }
            40% {
              width: 20vh;
              height: 20vh;
              background: #fff;
            }
            50% {
              background: none;
            }
            100% {
              width: 100vh;
              height: 100vh;
              border-radius: 0;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default VCAMap;

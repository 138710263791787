import React from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { isUserOniOS } from '../../../utils/deviceDetector';

const KakaotalkPrompt = () => {
  const [isIOS, setIsIOS] = React.useState(false);

  React.useEffect(() => {
    setIsIOS(isUserOniOS());
  }, []);

  const getEnglishMessage = () =>
    isIOS
      ? 'Please tap on the menu in bottom right corner, and select Open in Safari.'
      : 'Please tap on the menu in bottom right corner, and select Open in Chrome.';
  return (
    <div className="prompt-container">
      <p className="message">{getEnglishMessage()}</p>
      <BsArrowReturnRight />
      <style jsx>
        {`
          .prompt-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.8);
            z-index: 999;
          }

          .message {
            color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 50px;
            margin: 10px;
            font-size: 1.2rem;
            text-align: center;
          }

          .prompt-container :global(svg) {
            width: 50px;
            height: 50px;
            fill: #fff;
            position: absolute;
            right: 0;
            bottom: 0px;
            transform: rotate(90deg) scaleY(-1);
          }
        `}
      </style>
    </div>
  );
};

export default KakaotalkPrompt;

import { last } from 'lodash';
import React from 'react';
import { GoPlay } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { IMainState } from '../../../interfaces/index';
import {
  DID_CLICK_DISMISS_ZOOM, DID_CLICK_ZOOM_BUTTON
} from '../../../utils/constants';
import { tryGetLocalStorage, trySetLocalStorage } from '../../../utils/window';
const IndicatorPanel = ({
  zoomScene,
  open
}: {
  zoomScene: () => void;
  open: boolean;
}) => {
  const [render, setRender] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const sceneIds: string[] = useSelector(
    (state: IMainState) => state.clientState.vb?.sceneIds || []
  );

  const currentSceneId = last(sceneIds);

  const handleDismiss = () => {
    trySetLocalStorage('skipZoom', 'true');
    logEvent(DID_CLICK_DISMISS_ZOOM, null, { currentSceneId });
    setShow(false);
  };

  React.useEffect(() => {
    const skipZoom = !!tryGetLocalStorage('skipZoom');

    setShow(open);

    if (open && !skipZoom) {
      setRender(true);
    } else {
      setTimeout(() => setRender(false), 1000);
    }
  }, [open]);

  const { t } = useTranslation();

  if (render) {
    return (
      <div className="indicator-container">
        <div className={`indicatorpanel ${show ? 'show' : 'hide'}`}>
          <span
            className="box-title"
            onClick={() => {
              zoomScene();
              logEvent(DID_CLICK_ZOOM_BUTTON, null, { currentSceneId });
            }}
          >
            {t('vb:zoom_to_discover_more')} <GoPlay color="#000" />
          </span>
          <div className="box"></div>
          <div className="dismiss text-right" onClick={handleDismiss}>
            <u>{t('vb:do_not_show_me_again_about_zoom')}</u>
          </div>
        </div>
        <style jsx>{`
          .box-title {
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 0;
            padding: 8px 10px 5px 12px;
            border-radius: 5px 5px 0 0;
            display: inline-block;
            color: #000;
            pointer-events: auto;
            cursor: pointer;
            background: #fff;
            font-size: 12px;
          }
          .box-title :global(svg) {
            height: 18px;
            width: 15px;
            margin-top: -2px;
          }
          .indicator-container {
            display: flex;
            align-items: center;
            text-align: center;
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            pointer-events: none;
          }
          .dismiss {
            font-size: 11px;
            color: #fff;
            margin-top: 5px;
            pointer-events: auto;
            cursor: pointer;
          }
          .indicatorpanel {
            text-align: left;
            margin: auto;
            position: relative;
            height: 70%;
            width: 70%;
            min-height: 100px;
            max-width: 1100px;
            max-height: 700px;
          }
          .box {
            position: relative;
            width: 100%;
            height: 100%;
          }
          .box:after,
          .box:before {
            content: '';
            display: block;
            height: 40%;
            width: 100%;
            border: 3px solid #fff;
            position: absolute;
          }
          .box:after {
            top: 0;
            border-bottom: none;
          }
          .box:before {
            bottom: 0;
            border-top: none;
          }

          .indicatorpanel.show {
            -webkit-animation: scale-in-hor-center 0.6s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: scale-in-hor-center 0.6s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          }

          .indicatorpanel.hide {
            -webkit-animation: puff-out-center 1s
              cubic-bezier(0.165, 0.84, 0.44, 1) both;
            animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1)
              both;
          }

          @-webkit-keyframes scale-in-hor-center {
            0% {
              -webkit-transform: scale(0, 0.1);
              transform: scale(0, 0.1);
              opacity: 1;
            }
            30% {
              -webkit-transform: scale(1, 0.1);
              transform: scale(1, 0.1);
              opacity: 1;
            }
            60% {
              -webkit-transform: scale(1, 0.1);
              transform: scale(1, 0.1);
              opacity: 1;
            }
            100% {
              -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
              opacity: 1;
            }
          }
          @keyframes scale-in-hor-center {
            0% {
              -webkit-transform: scale(0, 0.1);
              transform: scale(0, 0.1);
              opacity: 1;
            }
            30% {
              -webkit-transform: scale(1, 0.1);
              transform: scale(1, 0.1);

              opacity: 1;
            }
            60% {
              -webkit-transform: scale(1, 0.1);
              transform: scale(1, 0.1);

              opacity: 1;
            }
            100% {
              -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
              opacity: 1;
            }
          }
          @-webkit-keyframes puff-out-center {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              opacity: 1;
            }
            100% {
              -webkit-transform: scale(2);
              transform: scale(2);
              -webkit-filter: blur(4px);
              filter: blur(4px);
              opacity: 0;
            }
          }
          @keyframes puff-out-center {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              opacity: 1;
            }
            100% {
              -webkit-transform: scale(2);
              transform: scale(2);
              -webkit-filter: blur(4px);
              filter: blur(4px);
              opacity: 0;
            }
          }
        `}</style>
      </div>
    );
  }
  return null;
};

export default IndicatorPanel;

import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { isMobile, isTablet } from 'react-device-detect';
import Carousel from 'react-multi-carousel';
import { useDispatch } from 'react-redux';
import { IInspiration } from '../../interfaces';
import { actionSetInspirationsList } from '../../redux/actions';
import InspirationThumbnail from './InspirationThumbnail';
import { CustomSliderArrow } from '../Product/Slider';
import InspirationList, { ListProps } from './InspirationList';
import smoothscroll from 'smoothscroll-polyfill';
import { jumpToAnchor, scrollElementIntoViewById } from '../../utils/window';
import FocusButton from '../Common/FocusButton';

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1023 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1023, min: 767 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

const MobileList = ({
  inspirations,
  onClickThumbnail,
  onFocusItem,
  focusedItem
}: ListProps) => {
  return (
    <Row className="inspirations-list mobile-list" id="scroller">
      <div className="horizontal-scroll" id="scroller-inner">
        <InspirationList
          inspirations={inspirations}
          onClickThumbnail={onClickThumbnail}
          onFocusItem={onFocusItem}
          focusedItem={focusedItem}
        />
      </div>
    </Row>
  );
};

const DesktopList = ({
  inspirations,
  onClickThumbnail,
  onFocusItem,
  focusedItem
}: ListProps) => {
  return (
    <Row className="inspirations-list desktop-list">
      <Col xs={12}>
        {inspirations && (
          <Carousel
            responsive={responsive}
            ssr={false}
            showDots={true}
            renderButtonGroupOutside={true}
            renderDotsOutside={true}
            infinite={true}
            customRightArrow={<CustomSliderArrow type="next" />}
            customLeftArrow={<CustomSliderArrow type="prev" />}
          >
            {inspirations.map((inspiration: IInspiration, index: number) => (
              <div key={inspiration.id}>
                <InspirationThumbnail
                  inspiration={inspiration}
                  index={index}
                  key={index}
                  onClickThumbnail={onClickThumbnail}
                />
                {onFocusItem && (
                  <FocusButton
                    onFocus={onFocusItem}
                    itemId={`${index}`}
                    focusedItem={focusedItem}
                    className="focus-btn-inspiration with-shadow"
                  />
                )}
              </div>
            ))}
          </Carousel>
        )}
      </Col>
    </Row>
  );
};

const inspirationList = (
  inspirations: IInspiration[] | null,
  onClickThumbnail: (inspirationId: string) => void | null,
  isMobile: boolean,
  onFocusItem?: (inspirationId: string) => void,
  focusedItem?: string
) => {
  return isMobile ? (
    <MobileList
      inspirations={inspirations || []}
      onClickThumbnail={onClickThumbnail}
      onFocusItem={onFocusItem}
      focusedItem={focusedItem}
    />
  ) : (
    <DesktopList
      inspirations={inspirations || []}
      onClickThumbnail={onClickThumbnail}
      onFocusItem={onFocusItem}
      focusedItem={focusedItem}
    />
  );
};

const InspirationsSlider = React.memo(
  ({
    sectionTitle,
    loading,
    inspirations,
    onClickThumbnail = null,
    onFocusItem,
    focusedItem
  }: {
    sectionTitle: string;
    loading: boolean;
    inspirations: IInspiration[] | null;
    onClickThumbnail?: (inspirationId: string) => void | null;
    onFocusItem?: (inspirationId: string) => void;
    focusedItem?: string;
  }) => {
    const spinner = loading && (
      <Spinner animation="grow" size="sm" className="title-spinner" />
    );

    const [isMobileDevice, setIsMobileDevice] = React.useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      if (!loading && focusedItem) {
        if (!isMobileDevice) {
          setTimeout(() => {
            const elem: HTMLElement = document.querySelector(
              ".product-inspirations .react-multi-carousel-dot-list [data-index='" +
                focusedItem +
                "'] button"
            ) as HTMLElement;
            if (elem) elem.click();
          }, 0);
        }
        if (!onFocusItem) {
          jumpToAnchor('#inspirationsslider');
          if (isMobileDevice) {
            smoothscroll.polyfill();
            scrollElementIntoViewById(`inspiration-${focusedItem}`);
          }
        }
      }
    }, [focusedItem, loading, isMobileDevice]);

    useEffect(() => {
      setIsMobileDevice(isMobile && !isTablet);

      dispatch(actionSetInspirationsList(inspirations));
      let inspirationIndex;
      try {
        inspirationIndex = parseInt(sessionStorage.getItem(location.pathname));
      } catch (e) {
        console.log(e);
      }
      if (isNaN(inspirationIndex)) {
        return;
      }
      setTimeout(() => {
        if (isMobile && !isTablet) {
          const container = document.getElementById('scroller');
          const inspiration = document.getElementById(
            'inspiration-' + inspirationIndex
          );
          if (container && inspiration)
            container.scrollLeft = inspiration.offsetLeft - 30;
        } else {
          const elem: HTMLElement = document.querySelector(
            ".product-inspirations .react-multi-carousel-dot-list [data-index='" +
              inspirationIndex +
              "'] button"
          ) as HTMLElement;
          if (elem) elem.click();
        }
      }, 0);
    }, []);

    return (
      <section
        className={`product-inspirations ${loading ? 'section-loading' : ''}`}
        id="inspirationsslider"
      >
        <>
          <div className="separator"></div>
          <div className="section-container ">
            <Container>
              <div className="light-text">
                {spinner}
                {sectionTitle}
                {spinner}
              </div>
              {inspirationList(
                inspirations,
                onClickThumbnail,
                isMobileDevice,
                onFocusItem,
                focusedItem
              )}
            </Container>
          </div>
        </>
        <style jsx global>{`
          .react-multi-carousel-track{
            margin: ${inspirations?.length === 1 ? 'auto' : 0}
          }
          `}</style>
        <style jsx>
          {`
            .product-inspirations .separator {
              display: block;
            }

            .product-inspirations :global(.focus-btn-inspiration) {
              position: absolute;
              background-color: rgba(255, 255, 255, 0.7);
              right: ${isMobileDevice ? '0' : '5px'};
              border-radius: 0 0 0 5px;
            }

            .product-inspirations
              :global(.desktop-list.inspirations-list .thumbnail) {
              width: 100%;
              display: block;
              background-color: #000;
              height: 0 !important;
              padding: 0;
              padding-bottom: 142%;
            }
            .product-inspirations
              :global(.desktop-list.inspirations-list .thumbnail .avatar) {
              width: 60px !important;
              height: 60px;
            }

            .product-inspirations .section-container {
              padding-bottom: 10px;
            }
            @media (min-width: 768px) {
              .product-inspirations .section-container {
                padding: 80px 0;
              }
            }

            @media (min-width: 768px) {
              .product-inspirations .light-text {
                text-align: center;
                font-size: 1.5em;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 50px;
              }
            }

            .product-inspirations
              :global(.react-multiple-carousel__arrow--right) {
              right: 10px;
            }

            .product-inspirations
              :global(.react-multiple-carousel__arrow--left) {
              left: 10px;
            }

            .product-inspirations :global(.react-multi-carousel-item) {
              padding: 0 5px;
            }
            .product-inspirations :global(.react-multi-carousel-item img) {
              width: 100%;
            }

            .product-inspirations :global(.inspirations-list) {
              margin-top: 20px;
              overflow-x: scroll;
              overflow-y: hidden;
            }
            .product-inspirations :global(.inspirations-list .thumbnail) {
              text-align: center;
              background-color: #000;
              background-size: cover;
              background-position: center center;
              width: 200px;
              height: calc(200px * 1.42);
              margin-right: 10px;
              color: #000;
              text-decoration: none;
              overflow: hidden;
              position: relative;

              cursor: pointer;
              box-shadow: 3px 3px 7px -4px rgba(0, 0, 0, 0.3);
            }

            .product-inspirations :global(.inspirations-list ul) {
              padding-bottom: 5px;
            }

            .product-inspirations
              :global(.inspirations-list .thumbnail:last-child) {
              margin-right: 0;
            }
            .product-inspirations
              :global(.inspirations-list .thumbnail.fullwidth) {
              width: 100vw;
              margin: 0;
            }
            .product-inspirations :global(.inspirations-list .thumbnail img) {
              height: auto;
              display: block;
            }
            .product-inspirations
              :global(.inspirations-list .thumbnail .avatar) {
              position: absolute;
              left: 10px;
              top: 10px;
              z-index: 2;
            }
            .product-inspirations :global(.inspirations-list .title-container) {
              padding: 20px;
              position: absolute;
              bottom: 0;
              width: 100%;
              text-align: left;
              background-color: rgba(0, 0, 0, 0.7);
              margin: 0;
            }
            .product-inspirations :global(.inspirations-list .title) {
              font-size: 0.8em;
              text-transform: uppercase;
              font-weight: 600;
              width: 100%;
              margin: 0;
              color: #fff;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            @media (min-width: 768px) {
              .product-inspirations :global(.inspirations-list .title) {
                font-size: 0.9em;
                font-weight: 300;
              }
            }
          `}
        </style>
      </section>
    );
  }
);

export default InspirationsSlider;

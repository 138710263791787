import { IBoutiqueCampaign } from '../../../interfaces/index';

export const campaignData: { [key: string]: IBoutiqueCampaign } = {
  iwcdubaicheckout: {
    imageCampaign: {
      desktopImageUrl:
        'https://panos2.inspify.io/virtual_boutique/844cc92c-8783-48a5-bb06-e37a093e0bbd/welcome_press_v2.jpg',
      mobileImageUrl:
        'https://panos2.inspify.io/virtual_boutique/844cc92c-8783-48a5-bb06-e37a093e0bbd/welcome_press_m_v2.jpg',
      tabletImageUrl:
        'https://panos2.inspify.io/virtual_boutique/844cc92c-8783-48a5-bb06-e37a093e0bbd/welcome_press_m_v2.jpg'
    }
  }
};

export default campaignData;

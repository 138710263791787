import { useRouter } from 'next/router';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import {
  DevicePermissionStatus,
  IDevicePermission,
  IMainState,
  IVirtualBoutiqueConfig
} from '../../../interfaces';
import { isUserOnMobileSafari15 } from '../../../utils/deviceDetector';
import { shouldHideLoungeInspirations } from '../../../utils/entitlements';
import { isUnsupportedBrowser, meetingColor } from '../../../utils/meeting';
import { getLoungeWelcomeMessage } from '../../storeComponentFactory';
import DeviceControl from '../Controls/DevicesControl';
import LoungeInspirations from './LoungeInspirations';
import LoungeNudge from './LoungeNudge';
import LoungeFooterActions from './LoungeFooterActions';
import LoungeNameInput from './LoungeNameInput';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import LoungeLocalVideo from './LoungeLocalVideo';
import { inspifyBrandId, zIndex } from '../../../config';
import { SessionContext } from '../../VirtualBoutique/SessionContextContainer';
import { useMeetingDebugLogger } from '../../hooks/meeting';
import { DID_LOAD_LOUNGE } from '../../../utils/events/meeting';
import BrowserPrompt from '../BrowserPrompt';
import LoungeParticipantsOverview from './LoungeParticipantsOverview';
import LoungeControls from './LoungeControls';

const Lounge = ({
  config,
  storeId,
  permissionStatus,
  isBrowserSupported = true,
  joinRoom
}: {
  config: IVirtualBoutiqueConfig;
  storeId: string;
  permissionStatus?: IDevicePermission;
  isBrowserSupported?: boolean;
  joinRoom: () => void;
}) => {
  const router = useRouter();
  const source = router.query?.source as string | undefined;
  const role = router.query?.role as string | undefined;

  const clientState = useSelector((state: IMainState) => state.clientState);

  const meeting = clientState?.meeting;
  const devices = meeting?.localUser?.availableDevices;
  const inspirations = clientState?.inspirationsList || [];
  const remoteUsers = meeting?.remoteUsers || {};
  const [showError, setShowError] = React.useState(true);
  const [isMobileSafari15, setIsMobileSafari15] = React.useState(false);
  const [btnClicked, setBtnClicked] = React.useState(false);
  const { shouldMuteVideo, shouldMuteAudio } = React.useContext(SessionContext);
  const meetingDebugLogger = useMeetingDebugLogger();
  const bgImg = config?.landingImageUrl;
  const permission = React.useMemo(() => {
    const isWaitingPermission = Object.values(permissionStatus).every(
      (p) => p === DevicePermissionStatus.WAITING
    );
    if (isWaitingPermission) {
      return DevicePermissionStatus.WAITING;
    }
    const isRejectedPermission = Object.values(permissionStatus).every(
      (p) => p === DevicePermissionStatus.REJECTED
    );
    if (isRejectedPermission) {
      return DevicePermissionStatus.REJECTED;
    }
    return DevicePermissionStatus.GRANTED;
  }, [permissionStatus]);
  const isRejectCamera = React.useMemo(
    () => permissionStatus.video === DevicePermissionStatus.REJECTED,
    [permissionStatus.video]
  );

  const muteVideo = meeting?.localUser?.videoMuted || isRejectCamera;
  const advisorName = meeting?.advisorName;

  React.useEffect(() => {
    setIsMobileSafari15(isUserOnMobileSafari15());
    meetingDebugLogger(DID_LOAD_LOUNGE);
  }, []);

  const { t } = useTranslation();

  const shouldRenderPrompt = React.useMemo(() => isUnsupportedBrowser(), []);

  return (
    <div
      className={`meeting-lounge ${meeting?.isPresenter && 'presenter'} store-${
        config.brandId
      }`}
    >
      {meeting?.isPresenter && (
        <LoungeParticipantsOverview remoteUsers={remoteUsers} />
      )}
      <div
        className={`content-container ${!inspirations.length && 'full-screen'}`}
      >
        <div className="content-inner">
          <img className="logo" src={config.logoUrl} />
          <h2>
            {getLoungeWelcomeMessage(
              storeId,
              config.brandName,
              config.loungeTagLine,
              source,
              t
            )}
            {meeting?.isPresenter && (
              <span className="host-info">{t('you_are_host')}</span>
            )}
            {!meeting?.isPresenter &&
              advisorName &&
              source !== 'medialaunch' && (
                <span className="host-info">
                  {t('your_host_today_is')} <strong>{advisorName}</strong>.
                </span>
              )}
          </h2>

          {shouldRenderPrompt ? (
            <BrowserPrompt />
          ) : (
            <>
              <p className="reminder with-y-padding">
                {t('ensure_mic_camera_working')}
              </p>

              {!!devices?.length && (
                <DeviceControl
                  controlSelectorPrefix={storeId}
                  devices={devices}
                />
              )}

              <div className="media-container">
                {!isBrowserSupported &&
                permission === DevicePermissionStatus.WAITING ? (
                  <p
                    className="checking-message text-center"
                    style={{ padding: '20px 40px' }}
                  >
                    <strong>{t('checking_devices')}</strong>
                    <span className="unsupported-message">
                      {' '}
                      {t('vb:walkthrough_browser_support.line1')}
                      {` `}
                      <a
                        style={{
                          color: 'inherit',
                          textDecoration: 'underline'
                        }}
                        href="https://www.google.com/chrome/"
                        target="_blank"
                      >
                        {t('vb:walkthrough_browser_support.line2')}
                      </a>
                    </span>
                  </p>
                ) : (
                  <>
                    {permission !== DevicePermissionStatus.WAITING && (
                      <>
                        <LoungeLocalVideo mute={muteVideo} />
                        <div>
                          <LoungeNameInput
                            source={source}
                            setShowError={setShowError}
                            role={role}
                          />
                          <LoungeControls
                            shouldMuteVideo={shouldMuteVideo}
                            shouldMuteAudio={shouldMuteAudio}
                          />
                        </div>
                      </>
                    )}
                    {permission === DevicePermissionStatus.WAITING && (
                      <>
                        <div
                          className="checking-message text-center"
                          style={{ padding: '20px 40px', width: '100%' }}
                        >
                          <Spinner animation="border" />
                          <br />
                          {t('waiting_devices_permission')}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              {showError &&
                permission === DevicePermissionStatus.GRANTED &&
                btnClicked && (
                  <div
                    className="text-center"
                    style={{
                      color: meetingColor.warning,
                      marginTop: '10px'
                    }}
                  >
                    {t('please_enter_your_name')}
                  </div>
                )}
              {isMobileSafari15 && (
                <p className="lounge-prompt mobile-safari-15-prompt">
                  {t('please_use_headset_ios15')}
                </p>
              )}
              {isRejectCamera && (
                <p className="lounge-prompt camera-is-not-accessible">
                  {t('your_camera_is_not_accessible')}
                </p>
              )}
              <LoungeFooterActions
                permissionStatus={permissionStatus}
                joinRoom={joinRoom}
                showError={showError}
                role={role}
                brandId={config.brandId}
                meetingVideo={config.meetingVideo}
                hasInspirations={!!inspirations?.length}
                overlay={config.overlay}
                setBtnClicked={setBtnClicked}
              />
            </>
          )}
        </div>
        {!inspirations.length && source !== 'nudge' && (
          <div className="powered-by-wrapper">
            <PoweredByInspify
              color="light"
              position="center center"
              style="shadow"
            />
          </div>
        )}
      </div>

      {!meeting?.isPresenter &&
        source !== 'nudge' &&
        source !== 'medialaunch' &&
        !shouldHideLoungeInspirations(storeId) && (
          <LoungeInspirations config={config} storeId={storeId} />
        )}

      {!meeting?.isPresenter && source === 'nudge' && (
        <LoungeNudge config={config} />
      )}

      {(!!inspirations?.length || source === 'nudge') && (
        <div className="powered-by-wrapper-outside">
          <PoweredByInspify
            color="light"
            position="center center"
            style="shadow"
          />
        </div>
      )}

      <style jsx>
        {`
          :global(body) {
            overflow: auto !important;
          }

          .unsupported-message {
            font-size: 0.9em;
            display: block;
            -webkit-animation: fade-in 1.2s linear 5s both;
            animation: fade-in 1.2s linear 5s both;
          }

          .meeting-lounge {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            ${config?.vbLite?.bannerImageUrlMobile
              ? 'background: #000 url(' +
                config?.vbLite?.bannerImageUrlMobile +
                ') top center no-repeat fixed;background-size: cover;'
              : ''}

            color: #333;
            font-weight: 200;
            z-index: ${zIndex.popupPage - 1};
            font-size: 16px;
          }
          .store-${inspifyBrandId}.meeting-lounge {
            font-size: 13px;
          }
          .meeting-lounge :global(.nudge-actions) {
            display: none;
          }

          .input-error a {
            color: inherit;
            text-decoration: underline;
          }

          .logo {
            height: 32px;
            width: auto;
          }

          .meeting-lounge :global(h2) {
            margin-top: 18px;
            margin-bottom: 6px;
            font-size: 0.9em;
            font-weight: bold;
            color: #000000;
          }
          .meeting-lounge :global(h2 .host-info) {
            font-weight: normal;
            display: block;
          }
          .media-container {
            display: block;
            box-shadow: 0 0 10px #ccc;
            overflow: hidden;
            background: #fff;
            border-radius: 10px;
            margin-top: 20px;
          }

          .media-container .inner {
            display: flex;
            align-items: center;
            text-align: left;
            flex-wrap: nowrap;
            overflow: hidden;
            background: #f8f8f8;
          }

          .media-container > .media-text p {
            display: none;
          }

          .media-container p {
            margin: 0;
          }

          .inner > :global(div:first-child) {
            width: 20%;
          }

          .inner .media-text {
            width: 80%;
          }

          .media-text {
            padding: 20px;
          }
          .form-group {
            margin: 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed #666;
            padding-bottom: 5px;
          }
          .input-name,
          .input-name:focus,
          .input-name:active {
            font-size: 16px;
            line-height: 20px;
            background: transparent;
            border: none;
            outline: none;
          }
          .btn-success {
            outline: none;
            box-shadow: none;
          }

          :global(.btn-client) {
            margin-top: 0 !important;
          }
          .content-container {
            position: relative;
            padding: 20px;
            max-width: 500px;
            margin: auto;
            display: flex;
          }

          .content-container.full-screen {
            min-height: 100vh;
            align-items: center;
          }

          .content-container.full-screen .content-inner {
            margin: 50px auto;
          }

          .content-inner {
            overflow: visible;
            color: #000;
            background: #fff;
            margin: auto;
            border-radius: 5px;
            padding: 25px 30px 15px;
            width: 100%;
          }

          p {
            font-size: 0.8em;
            line-height: 1.4;
          }

          .with-y-padding {
            color: #8d8d8d;
            padding: 0 10px;
            margin-bottom: 0;
          }

          :global(.powered-by-wrapper) {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
          }

          :global(
              .powered-by-wrapper .powered-by-inspify,
              .powered-by-wrapper-outside .powered-by-inspify
            ) {
            height: 35px;
          }

          .powered-by-wrapper-outside {
            margin-bottom: 20px;
          }

          @-webkit-keyframes fade-in {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
          @keyframes fade-in {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }

          @media (min-width: 540px) {
            .media-container {
              display: flex;
              align-items: center;
              text-align: left;
              flex-wrap: nowrap;
            }
            .meeting-lounge {
              background: #000 url(${bgImg}) center center no-repeat fixed;
              background-size: cover;
            }
          }

          @media screen and (orientation: landscape) and (max-height: 767px) {
            .meeting-lounge :global(h2) {
              margin-top: 12px;
              margin-bottom: 12px;
            }

            :global(.powered-by-wrapper) {
              bottom: 15px;
            }

            :global(.powered-by-wrapper .powered-by-inspify) {
              height: 30px;
            }

            .meeting-lounge.presenter {
              min-height: 100vh;
            }
            .content-container {
              max-width: none;
            }
            .meeting-lounge.presenter .content-container {
              width: calc(100vw - 136px);
              float: right;
            }
            .content-inner {
              padding: 15px 10%;
            }

            :global(.content-container .powered-by-wrapper) {
              display: none;
            }
          }

          @media (min-width: 768px) and (min-height: 520px) {
            .logo {
              height: 60px;
            }

            .meeting-lounge.presenter {
              min-height: 100vh;
            }
            .content-container {
              max-width: none;
              min-height: 80vh;
            }
            .meeting-lounge.presenter .content-container {
              width: calc(100vw - 268px);
              float: right;
            }

            .content-inner {
              width: 90%;
              max-width: 960px;
              padding: 48px 40px;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
              margin: auto !important;
            }

            .presenter .content-inner {
              padding-bottom: 40px;
            }
            .meeting-lounge :global(h2) {
              font-size: 1.25em;
              margin: 30px 0 20px 0;
            }

            p {
              font-size: 0.875em;
              line-height: 1.188em;
            }

            :global(.powered-by-wrapper) {
              bottom: 30px;
            }

            :global(
                .powered-by-wrapper .powered-by-inspify,
                .powered-by-wrapper-outside .powered-by-inspify
              ) {
              height: 54px;
            }
          }

          @media (min-width: 768px) {
            p {
              font-size: 1em;
              line-height: 1.375em;
            }
            .meeting-lounge :global(h2) {
              font-size: 1.375em;
              margin: 30px 0;
            }

            .media-container .inner {
              display: block;
            }
            .media-container .inner :global(div:first-child) {
              width: 100%;
            }
            .media-container .inner p {
              display: none;
            }
            .media-container {
              display: flex;
              align-items: center;
              text-align: left;
              flex-wrap: nowrap;
            }
            .media-container > .media-text p {
              display: block;
              margin-bottom: 20px;
            }
            .media-container > :global(div) {
              width: 50%;
            }

            .media-text {
              padding: 0 20px 0 30px;
            }

            .content-container {
              max-width: none;
            }
            .meeting-lounge.presenter .content-container {
              width: calc(100vw - 240px);
            }

            .content-inner {
              padding: 40px;
              max-width: 780px;
            }
          }
          @media (min-width: 1000px) {
            .content-inner {
              padding: 40px 100px;
            }
          }
          .lounge-prompt {
            margin: 15px 0 10px 0;
            font-weight: 400;
          }
        `}
      </style>
    </div>
  );
};

export default Lounge;

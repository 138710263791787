import React from 'react';
import { logEvent } from '../../../analytics';
import { DID_SELECT_LANGUAGE } from '../../../utils/constants';
import TermsOfUse from '../../Legal/TermsOfUse';
import PrivacyPolicy from '../../Legal/PrivacyPolicy';
import { ILanguageOption } from '../../../interfaces';
import { useTranslation } from '../../../i18n';
import {
  tryGetLocalStorage,
  tryRemoveFromLocalStorage,
  trySetLocalStorage
} from '../../../utils/window';

const IWCDubaiLanding = ({
  storeId,
  logoUrl,
  backgroundImageUrl,
  mobileBackgroundImageUrl,
  languages,
  onDismiss
}: {
  storeId: string;
  brandName: string;
  logoUrl: string;
  backgroundImageUrl: string;
  mobileBackgroundImageUrl: string;
  languages: ILanguageOption[];
  onDismiss: () => void;
}) => {
  const [showLanguageSelection, setShowLanguageSelection] = React.useState(
    false
  );
  const [showTerms, setShowTerms] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const agreementKey = `${storeId}-agreementChecked`;
  const languageKey = `${storeId}-languageSelected`;
  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setAcceptedTerms(value);
    if (value) {
      trySetLocalStorage(agreementKey, 'true');
    } else {
      tryRemoveFromLocalStorage(agreementKey);
    }
  };

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  React.useEffect(() => {
    if (
      !!tryGetLocalStorage(agreementKey) &&
      !!tryGetLocalStorage(languageKey)
    ) {
      onDismiss();
    } else {
      setShowLanguageSelection(!tryGetLocalStorage(languageKey));
      setShowTerms(!tryGetLocalStorage(agreementKey));
    }
  }, []);

  const languageSelection = (
    <div className="welcome-container language-selection">
      <div className="content">
        <h1>
          <span>{t('vb:welcome_message.line1')}</span>
          <span>{t('vb:iwc_e_boutique')}</span>
        </h1>
        <p>{t('vb:please_select_language')}</p>
      </div>
      <div className="btn-group">
        {languages.map((lang) => (
          <button
            key={lang.code}
            type="button"
            onClick={() => {
              setShowLanguageSelection(false);
              trySetLocalStorage(languageKey, 'true');
              i18n.changeLanguage(lang.code);

              logEvent(DID_SELECT_LANGUAGE, DID_SELECT_LANGUAGE, {
                language: lang.code,
                source: 'LANDING'
              });
            }}
          >
            {lang.display.toUpperCase()}
          </button>
        ))}
      </div>
      <style jsx>
        {`
          h1 {
            text-transform: uppercase;
          }
          h1 > span {
            display: block;
          }

          .content h1 span {
            font-size: ${isArabic ? '32px' : 'inherit'};
          }

          .welcome-container .btn-group button {
            font-size: ${isArabic ? '16px' : 'inherit'};
          }

          @media (min-width: 1024px) {
            h1 > span {
              display: inline;
            }
            h1 > span:last-child {
              display: block;
            }

            .content h1 span {
              font-size: ${isArabic ? '40px' : 'inherit'};
            }

            .content p {
              font-size: ${isArabic ? '18px' : 'inherit'};
            }

            .welcome-container .btn-group button {
              font-size: ${isArabic ? '19px' : 'inherit'};
            }
          }
        `}
      </style>
    </div>
  );

  const acceptTerms = (
    <div className="input-group tstc">
      <div className="checkbox-group">
        <label>
          <input
            type="checkbox"
            id="agreement"
            checked={acceptedTerms}
            onChange={handleAcceptTerms}
          />
          {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
          <PrivacyPolicy />
          {t('vb:tc_part_three')}
        </label>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="btn-back"
          onClick={() => setShowLanguageSelection(true)}
        >
          {t('vb:back')}
        </button>
        <button
          type="button"
          disabled={!acceptedTerms}
          className="btn-start"
          onClick={() => onDismiss()}
        >
          {t('vb:start_exploring')}
        </button>
      </div>
      <style jsx>{`
        .btn-group {
          width: 100%;
        }
      `}</style>
    </div>
  );

  const getComponent = () => {
    if (showLanguageSelection) {
      return languageSelection;
    }
    if (showTerms) {
      return acceptTerms;
    }
  };

  return (
    <>
      {!showLanguageSelection && !showTerms ? null : (
        <div className="landing-page">
          <div className="inner">
            {getComponent()}
            <img className="logo" src={logoUrl} />
          </div>
          <style jsx>
            {`
              .logo {
                margin-top: 140px;
              }
              .landing-page {
                background: url(${mobileBackgroundImageUrl}) center top
                  no-repeat;
                background-size: cover;
                background-color: #000000;
                display: block;
              }
              .landing-page .inner {
                margin-top: 28vh;
              }

              @media (min-width: 768px) {
                .landing-page {
                  background: url(${backgroundImageUrl}) center top no-repeat;
                  background-size: cover;
                  background-color: #000000;
                }
                .landing-page .inner {
                  margin-top: 38vh;
                }
              }
              @media (min-width: 1024px) {
                .landing-page .logo {
                  width: 200px;
                }
              }
              @media (min-width: 1200px) {
                .landing-page .inner {
                  margin-top: 41vh;
                }
              }

              @media (max-width: 768px) {
                .logo {
                  margin-top: 80px;
                }
                .landing-page .tstc .btn-group {
                  flex-direction: column-reverse;
                }
              }
            `}
          </style>
        </div>
      )}
    </>
  );
};

export default IWCDubaiLanding;

import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState, ParticipantMeetingState } from '../../../../interfaces';
import { useScrollListenerForVoyage } from '../../../hooks/scroll';

const LoubiFuturePageContainer = ({
  children,
  className = '',
  reference
}: {
  children: React.ReactNode;
  className?: string;
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  reference?: React.MutableRefObject<any>;
}) => {
  const [addScrollListenForVoyage, removeScrollListenForVoyage] =
    useScrollListenerForVoyage();

  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting?.state
  );
  const isInMeeting =
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;

  React.useEffect(() => {
    addScrollListenForVoyage();
    return () => {
      removeScrollListenForVoyage();
    };
  }, []);

  return (
    <div
      className={`loubiFuturePageContainer fixed-full ${className} ${
        isInMeeting ? 'meeting' : ''
      }`}
      id="voyage-scroll-listener"
    >
      <div className="header">
        <div className="header-inner">
          <img
            src={'/asset/loubifuture/Logo.png'}
            className="logo"
            alt="LOUBIAIRWAYS"
          />
        </div>
      </div>
      <div className="container">
        <div className="content" ref={reference}>
          {children}
        </div>
      </div>
      <style jsx>{`
        .loubiFuturePageContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: auto;
        }
        .header {
          width: 100%;
        }
        .header-inner {
          border-bottom: 2px solid #ff61ff;
          width: 94%;
          margin: auto;
        }
        .footer,
        .content {
          border-radius: 40px;
        }
        .footer {
          background-color: #cf152d;
          padding: 40px;
          text-align: center;
          position: relative;
        }
        .footer {
          padding: 20px;
        }

        .container {
          width: 100%;
          max-width: 1200px;
          padding: 20px;
          position: relative;
        }

        .content {
          padding: 60px 30px;
        }

        .header .logo {
          max-width: 200px;
          height: auto;
        }
        .footer img {
          height: 50px;
          width: auto;
        }

        @media (max-width: 1200px) {
          .header .logo {
            max-width: 280px;
          }
          .header,
          .footer,
          .content {
            border-radius: 4vw;
          }
          .content {
            margin: 15px 0;
          }
          .footer {
            padding: 10px;
          }
          .footer img {
            height: 30px;
          }
        }

        @media (min-width: 840px) {
          .loubiFuturePageContainer.meeting {
            padding-left: 220px;
          }
        }
        @media (orientation: landscape) and (max-height: 800px) {
          .header .logo {
            max-width: 180px;
          }
          .footer img {
            height: 25px;
          }
        }

        @media (orientation: landscape) and (max-height: 600px) {
          .header .logo {
            max-width: 100px;
          }
          .footer img {
            height: 20px;
          }
        }

        @media (min-width: 768px) {
          .header {
            z-index: 2;
          }
        }
        @media (max-width: 768px) {
          .container {
            padding: 10px;
          }
          .content {
            margin: 10px 0;
            padding: 40px 15px;
          }
          .header {
            top: 0;
            right: 10px;
            left: initial;
            border-radius: 0;
            z-index: 2;
          }

          .header-inner {
            width: 86%;
          }

          .header .logo {
            max-width: 150px;
          }
          .footer {
            padding: 10px;
          }
          .footer img {
            height: 40px;
          }
        }
      `}</style>
    </div>
  );
};

export default LoubiFuturePageContainer;

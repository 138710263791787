import React from 'react';
import { JitsiDevice } from '../../../interfaces';
import { BiMicrophone } from 'react-icons/bi';
import { useTranslation } from '../../../i18n';
import { logEvent } from '../../../analytics/index';
import { DID_SELECT_MICROPHONE } from '../../../utils/constants';
import {
  createLocalAudioTracks,
  disposeTrackIfNeeded,
  IAudioTrack
} from '../utils/track';
import Microphone from './Microphone';
import { useStateRef } from '../../hooks/common';
import { Spinner } from 'react-bootstrap';

export const Microphones = ({
  devices,
  onSelect,
  activeDevice,
  allowChange = false
}: {
  devices: JitsiDevice[];
  onSelect: (device: string) => void;
  activeDevice: string;
  allowChange: boolean;
}) => {
  const [audioTracks, setAudioTracks, audioTracksRef] = useStateRef([]);
  const didMountRef = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const setTracks = async () => {
    setIsLoading(true);
    const browser = JitsiMeetJS?.util?.browser;
    if (browser?.isWebKitBased()) {
      const defaultAudioTracks = devices.map((d) => {
        return {
          deviceId: d.deviceId,
          label: d.label,
          jitsiTrack: null,
          hasError: true
        };
      });
      setAudioTracks(defaultAudioTracks);
      setIsLoading(false);
      // It appears that at the time of this writing, creating audio tracks blocks the browser's main thread for
      // long time on safari. Wasn't able to confirm which part of track creation does the blocking exactly, but
      // not creating the tracks seems to help and makes the UI much more responsive.
      return;
    }
    const audioTracks = await createLocalAudioTracks(devices);
    if (!didMountRef.current) {
      setIsLoading(false);
      return disposeTracks(audioTracks);
    }
    setAudioTracks(audioTracks);
    setIsLoading(false);
  };

  const disposeTracks = (tracksToDispose?: IAudioTrack[]) => {
    const audioTracks = tracksToDispose || audioTracksRef.current;
    const promises = audioTracks.map((track) =>
      disposeTrackIfNeeded(track.jitsiTrack)
    );
    return Promise.all(promises);
  };

  React.useEffect(() => {
    didMountRef.current = true;
    return () => {
      didMountRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    setTracks();
    return () => {
      disposeTracks();
    };
  }, [devices]);
  const { t } = useTranslation();

  const renderMicrophone = (track: IAudioTrack, i: number) => {
    const { deviceId, hasError, jitsiTrack, label } = track;
    return (
      <Microphone
        key={i + deviceId}
        hasError={hasError}
        deviceId={deviceId}
        isActive={deviceId === activeDevice}
        label={label}
        jitsiTrack={jitsiTrack}
        onClick={() => {
          onSelect(deviceId);
          logEvent(DID_SELECT_MICROPHONE, DID_SELECT_MICROPHONE, {
            deviceName: label
          });
        }}
        disabled={!allowChange}
      />
    );
  };
  return (
    <>
      <li className="list-group-item device-title d-flex align-items-center">
        <BiMicrophone /> <strong>{t('microphones')}</strong>
      </li>
      {isLoading ? (
        <div className='d-flex justify-content-center'>
          <Spinner animation="border" size="sm" variant='light'/>
        </div>
      ) : (
        audioTracks.map((track, i) => renderMicrophone(track, i))
      )}
    </>
  );
};

export default Microphones;

import * as THREE from 'three';
import {
  LoubiFutureScene,
  REMOTE_ACTION,
  touchType,
  ROOM_STATUS
} from '../../../interfaces/loubifuture';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import { viewingroomProducts, viewingroomTranceTexture } from './assets';
import LoubiFutureBase from './LoubiFutureBase';

let TWEEN = undefined;
const isMobileOrTablet = isUserOnMobile();
export interface IProductTextures {
  map?: THREE.Texture;
  normal?: THREE.Texture;
  metallic?: THREE.Texture;
}
class ViewingRoom {
  lubiAnim: LoubiFutureBase;
  productsTexture: IProductTextures[];
  productBox = undefined;
  productObj = undefined;
  gateForCatchingClick = undefined;
  itemsChildForCatchingClick = undefined;
  lookatPosition = undefined;
  objectHeight: number;
  counter: number;
  mirrorCamera: THREE.CubeCamera;
  mirrorMesh: THREE.Mesh;
  onSelectProduct: (productId?: string) => void;
  roomStatus: ROOM_STATUS;
  loadingManager: THREE.LoadingManager;
  constructor(lubiAnim, tween, onSelectProduct) {
    this.lubiAnim = lubiAnim;
    this.productsTexture = [];
    this.productBox = [];
    this.productObj = [];
    this.itemsChildForCatchingClick = [];
    this.objectHeight = 2;
    this.counter = 0;
    this.onSelectProduct = onSelectProduct;
    TWEEN = tween;
    this.loadingManager = new THREE.LoadingManager(() => {
      this.objectLoadCallback();
    });
  }
  objectLoadCallback() {
    this.roomStatus = ROOM_STATUS.READY;
    if (isMobileOrTablet) {
      this.productObj.forEach((element, i) => {
        const rootObj = element?.children?.find(
          (obj) => obj?.name === 'Root_Scene'
        );
        this.setMaterialOnBaseObject(rootObj, this.productsTexture[i]);
      });
    }

    if (this.lubiAnim?.props?.viewOnly) {
      this.checkForViewOnly(this.lubiAnim?.props?.canvasState);
    }
  }
  setMaterialOnBaseObject = (model, textures: IProductTextures) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.map && textures.map) {
          met.map.image = textures.map.image;
          met.map.needsUpdate = true;
        }
        if (met.normalMap && textures.normal) {
          met.normalMap.image = textures.normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.metalnessMap && textures.metallic) {
          met.metalnessMap.image = textures.metallic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met.aoMap && textures.metallic) {
          met.aoMap.image = textures.metallic.image;
          met.aoMap.needsUpdate = true;
        }
        if (met.roughnessMap && textures.metallic) {
          met.roughnessMap.image = textures.metallic.image;
          met.roughnessMap.needsUpdate = true;
        }
      }
    });
  };
  checkForViewOnly(remoteState) {
    if (remoteState?.camera?.position && this.lubiAnim?.camera) {
      this.lubiAnim?.camera.position.set(
        remoteState.camera.position[0],
        remoteState.camera.position[1],
        remoteState.camera.position[2]
      );
      this.lubiAnim?.camera.rotation.set(
        remoteState.camera.rotation[0],
        remoteState.camera.rotation[1],
        remoteState.camera.rotation[2]
      );
    }
    if (remoteState?.focusItem?.position) {
      if (this.lubiAnim?.focusedModel === null) {
        if (remoteState?.currentScene === LoubiFutureScene.ViewingRoom) {
          this.setFocusObject(remoteState?.focusItemID);
        }
      }
      if (this.lubiAnim?.focusedModel) {
        this.lubiAnim?.focusedModel.position.set(
          remoteState.focusItem.position[0],
          remoteState.focusItem.position[1],
          remoteState.focusItem.position[2]
        );
        this.lubiAnim?.focusedModel.rotation.set(
          remoteState.focusItem.rotation[0],
          remoteState.focusItem.rotation[1],
          remoteState.focusItem.rotation[2]
        );
        this.lubiAnim?.focusedModel.scale.set(
          remoteState.focusItem.scale[0],
          remoteState.focusItem.scale[1],
          remoteState.focusItem.scale[2]
        );
      }
    }
    if (remoteState?.flag) {
      if (remoteState?.flag === REMOTE_ACTION.FOCUS) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
          remoteState?.focusItemID !== undefined
        ) {
          this.focusProduct(remoteState?.focusItemID);
        }
      }
      if (remoteState?.flag === REMOTE_ACTION.UN_FOCUS) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
          remoteState?.focusItemID !== undefined
        ) {
          this.unfocusProduct(remoteState?.focusItemID);
        }
      }
    }
  }
  init() {
    this.roomStatus = ROOM_STATUS.LOADING;
    this.initObject();
  }
  loadZoom = (id) => {
    if (isMobileOrTablet) return;
    const gltfLoader = this.lubiAnim.getGltfObjForZoom();
    const ItemId = id;
    const url = viewingroomProducts[ItemId].modelUrl;
    gltfLoader.load(url, (gltf) => {
      const root = gltf.scene;
      const lowPolyObj = this.productObj[ItemId].getObjectByName('lowPoly');
      if (lowPolyObj) {
        root.scale.set(20, 20, 20);
        root.rotation.set(0, Math.PI * 0.5, 0);
        this.productObj[ItemId].add(root);
        lowPolyObj.visible = false;
        lowPolyObj.name = 'highPoly';
        this.productObj[ItemId].remove(lowPolyObj);
      }
    });
  };

  cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material?.dispose();
    material = undefined;
  };
  cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
  };

  cleanObject = (model) => {
    model.traverse((object) => {
      if (!object['isMesh']) return;
      object['geometry']?.dispose();
      if (object['material']?.isMaterial) {
        this.cleanMaterial(object['material']);
      } else {
        for (const material of object['material']) this.cleanMaterial(material);
      }
      object['geometry'] = undefined;
      object = undefined;
    });
  };

  cleanUp = () => {
    if (!isMobileOrTablet) {
      return;
    }
    if (this.productObj != undefined) {
      this.productObj.forEach((model) => {
        this.cleanObject(model);
        this.lubiAnim.scene?.remove(model);
      });
      this.productObj = [];
    }
    if (this.productBox != undefined) {
      this.productBox.forEach((model) => {
        this.cleanObject(model);
        this.lubiAnim.scene?.remove(model);
      });
      this.productBox = [];
    }
    if (this.productsTexture) {
      this.productsTexture?.forEach((textures) => {
        this.cleanTexture(textures?.map);
        this.cleanTexture(textures?.normal);
        this.cleanTexture(textures?.metallic);
      });
      this.productBox = [];
    }
    if (this.gateForCatchingClick) this.cleanObject(this.gateForCatchingClick);

    this.itemsChildForCatchingClick = [];
  };

  setObjectPosition(obj, i) {
    const hieght = 6;
    switch (i) {
      case 0:
        obj.position.y = hieght + 0.0;
        break;
      case 1:
        obj.position.y = hieght - 3;
        break;
      case 3:
        obj.position.y = hieght - 1.7;
        break;
      case 2:
        obj.position.y = hieght - 3.4;
        break;
      case 5:
        obj.position.y = hieght - 2.7;
        break;
      case 4:
        obj.position.y = hieght + 1.7;
        break;
      case 6:
        obj.position.y = hieght - 1.7;
        break;
      case 7:
        obj.position.y = hieght - 2.7;
        break;
      case 8:
        obj.position.y = hieght - 3;
        break;
      case 9:
        obj.position.y = hieght + 0.0;
        break;
    }
  }
  createCylinder() {
    const YELLOW = 0xeece45;
    const blackTopAMat = new THREE.MeshBasicMaterial({ color: YELLOW });
    const blackTopBMat = new THREE.MeshBasicMaterial({ color: 0x980baa });
    const blackTopCMat = new THREE.MeshBasicMaterial({ color: YELLOW });
    const blackTopDMat = new THREE.MeshBasicMaterial({ color: 0xd6bca3 });
    const blackTopEMat = new THREE.MeshBasicMaterial({ color: 0xac121d });
    const blackTopFMat = new THREE.MeshBasicMaterial({
      color: 0x150217,
      side: THREE.DoubleSide
    });
    // this.lubiAnim.loadEnvForViewingRoom(blackTopFMat);
    const material = new THREE.MeshStandardMaterial({
      color: 0x330b65,
      roughness: 0.1,
      metalness: 0.3
    });
    this.lubiAnim.loadEnvForViewingRoom(material);
    const blackTop = new THREE.Mesh(
      new THREE.TorusGeometry(4.6, 1, 32, 32),
      material
    );
    blackTop.scale.set(1, 1, 0.91);
    const blackTopA = new THREE.Mesh(
      new THREE.TorusGeometry(5.4, 0.1, 32, 32),
      blackTopAMat
    );
    const blackTopB = new THREE.Mesh(
      new THREE.TorusGeometry(5.6, 0.1, 32, 32),
      blackTopBMat
    );
    const blackTopC = new THREE.Mesh(
      new THREE.TorusGeometry(5.4, 0.08, 32, 32),
      blackTopCMat
    );
    const blackTopD = new THREE.Mesh(
      new THREE.TorusGeometry(4.6, 0.08, 32, 32),
      blackTopDMat
    );
    const blackTopE = new THREE.Mesh(
      new THREE.TorusGeometry(4.6, 0.08, 32, 32),
      blackTopEMat
    );
    const blackTopF = new THREE.Mesh(
      new THREE.CircleGeometry(4.6, 20, 0, Math.PI * 2),
      blackTopFMat
    );
    blackTopA.position.set(0, 0, -0.55);
    blackTopC.position.set(0, 0, 0.55);
    blackTopD.position.set(0, 0, 1.0);
    blackTopE.position.set(0, 0, 1.7);
    blackTopF.position.set(0, -4.0, 0);
    blackTop.add(blackTopA);
    blackTop.add(blackTopB);
    blackTop.add(blackTopC);
    blackTop.add(blackTopD);
    blackTop.add(blackTopE);
    blackTop.rotation.set(Math.PI * 0.5, 0, 0);
    blackTopF.rotation.set(-Math.PI * 0.5, 0, 0);
    blackTop.rotation.set(-Math.PI * 0.5, 0, 0);
    blackTop.position.set(0, -5.5, 0);
    const container = new THREE.Group();
    container.add(blackTop);
    container.add(blackTopF);
    return container;
  }
  changeColorZoomIn(model, index) {
    const time = 400;
    this.lubiAnim.isTweening = true;
    const obj = model;
    obj.rotation.y %= Math.PI * 2;
    if (obj.rotation.y > Math.PI) {
      obj.rotation.y = 0;
    }
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: Math.PI * 0.0,
          y: Math.PI * 1,
          z: Math.PI * 0
        },
        time
      )
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        const temp = this.lubiAnim.focusedModel.position.y;
        this.lubiAnim.focusedModel.visible = false;
        this.setProductForColorChange(index);
        this.lubiAnim.focusedModel = this.productObj[index];
        this.lubiAnim.focusedModel.position.y = temp;
        this.onSelectProduct(viewingroomProducts[index]?.id);
        this.lubiAnim.focusedModel.visible = true;
        this.changeColorZoomOut(this.lubiAnim.focusedModel);
        this.lubiAnim.RemoveObjectControl();
        this.lubiAnim.updateObjectControlForModel(this.lubiAnim.focusedModel);
        this.lubiAnim.isTweening = false;
      })
      .start();
  }
  getIndex(itemId) {
    let index = itemId || this.lubiAnim?.focusedModel?.itemId;
    if (!itemId) {
      if (this.lubiAnim.focusedModel.itemId == 6) index = 3;
      if (this.lubiAnim.focusedModel.itemId == 7) index = 2;
      if (this.lubiAnim.focusedModel.itemId == 8) index = 1;
      if (this.lubiAnim.focusedModel.itemId == 9) index = 0;
      if (this.lubiAnim.focusedModel.itemId == 0) index = 9;
      if (this.lubiAnim.focusedModel.itemId == 1) index = 8;
      if (this.lubiAnim.focusedModel.itemId == 2) index = 7;
      if (this.lubiAnim.focusedModel.itemId == 3) index = 6;
    }
    return index;
  }
  setProductForColorChange(itemId?: string) {
    const valueY = this.lubiAnim.focusedModel.position.y;
    if (this.lubiAnim.focusedModel.itemId < 4) {
      this.lubiAnim.focusedModel.position.y = 10000;
    }
    const index = this.getIndex(itemId);
    this.productObj[index].scale.set(
      this.lubiAnim.focusedModel.scale.x,
      this.lubiAnim.focusedModel.scale.y,
      this.lubiAnim.focusedModel.scale.z
    );
    this.productObj[index].rotation.set(
      this.lubiAnim.focusedModel.rotation.x,
      this.lubiAnim.focusedModel.rotation.y,
      this.lubiAnim.focusedModel.rotation.z
    );
    this.productObj[index].position.set(
      this.lubiAnim.focusedModel.position.x,
      valueY,
      this.lubiAnim.focusedModel.position.z
    );
    return index;
  }

  changeColorZoomOut(model) {
    const time = 400;
    this.lubiAnim.isTweening = true;
    const obj = model;
    let rotationY = Math.PI * 2;
    if (
      this.lubiAnim?.focusedModel?.itemId === 9 ||
      this.lubiAnim?.focusedModel?.itemId === 0
    ) {
      rotationY += Math.PI * 0.3;
    }
    if (
      this.lubiAnim?.focusedModel?.itemId === 8 ||
      this.lubiAnim?.focusedModel?.itemId === 1
    ) {
      rotationY += Math.PI * 0.2;
    }
    if (
      this.lubiAnim?.focusedModel?.itemId === 7 ||
      this.lubiAnim?.focusedModel?.itemId === 2
    ) {
      rotationY += Math.PI * 0.1;
    }
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: Math.PI * 0.0,
          y: rotationY,
          z: Math.PI * 0
        },
        time
      )
      .easing(TWEEN.Easing.Linear.None)
      .onComplete(() => {
        this.lubiAnim.isTweening = false;
      })
      .start();
  }

  initObject() {
    if (!this.lubiAnim?.scene) return;
    this.roomStatus = ROOM_STATUS.LOADING;
    const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(1024, {
      format: THREE.RGBFormat,
      generateMipmaps: true,
      minFilter: THREE.NearestFilter,
      encoding: THREE.sRGBEncoding
    });
    this.mirrorCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);

    const materialPhongCube = new THREE.MeshPhongMaterial({
      shininess: 50,
      color: 0xffffff,
      specular: 0x999999,
      envMap: cubeRenderTarget.texture
    });

    this.mirrorMesh = new THREE.Mesh(
      new THREE.CircleGeometry(26, 64, 0, Math.PI * 2),
      materialPhongCube
    );
    this.lubiAnim.scene.add(this.mirrorCamera);
    this.lubiAnim.scene.add(this.mirrorMesh);

    this.mirrorMesh.position.set(0, -19.0, -2.2);
    this.mirrorMesh.rotation.set(-Math.PI * 0.5, 0, 0);
    this.mirrorCamera.position.set(0, -50, 0);
    this.mirrorCamera.rotation.set(0, 0, 0);

    const cube = new THREE.Mesh(
      new THREE.BoxGeometry(10, 62, 10),
      new THREE.MeshPhongMaterial({ color: 0x00ff00 })
    );
    this.lubiAnim.scene.add(cube);
    this.gateForCatchingClick = cube;
    this.gateForCatchingClick.visible = false;
    const cube2 = new THREE.Mesh(
      new THREE.BoxGeometry(160, 0.1, 60),
      new THREE.MeshPhongMaterial({ color: 0x00ff00 })
    );
    cube2.position.set(0, -4, -80);
    this.gateForCatchingClick.position.set(10, -20, 90);
    const textureLoader = new THREE.TextureLoader(this.loadingManager);
    const texture = textureLoader.load(viewingroomTranceTexture);
    const containerBox = new THREE.Group();
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: 0.0
    });
    material.depthWrite = false;
    const gometry = new THREE.PlaneGeometry(12, 16);
    const transMash = new THREE.Mesh(gometry, material);
    transMash.visible = false;
    transMash.position.y = 3;

    containerBox.add(transMash);
    containerBox.add(this.createCylinder());
    this.productsTexture = [];
    for (let i = 0; i < 10; i++) {
      const cylClon = i == 0 ? containerBox : containerBox.clone();
      if (i < 4) {
        cylClon.visible = false;
      }
      this.productBox.push(cylClon);
      this.lubiAnim.scene.add(cylClon);
      this.productBox[i].visible = false;
      this.productBox[i].children[0].material = material.clone();
      if (isMobileOrTablet) {
        const map = textureLoader.load(viewingroomProducts[i].mapTextureUrl);
        const normal = textureLoader.load(
          viewingroomProducts[i].normalTextureUrl
        );
        const metallic = textureLoader.load(
          viewingroomProducts[i].metallicTextureUrl
        );
        const productTextures: IProductTextures = { map, normal, metallic };
        this.productsTexture.push(productTextures);
      }
    }
    const dist = 3;
    const distanceFromCamera = 50;
    const NearZ = 17;
    const rotateLeft = -0.65;
    for (let i = 0; i < this.productBox.length; i++) {
      const radian = Math.PI + rotateLeft * dist + i * (0.1 * dist);
      const x = Math.sin(radian) * distanceFromCamera;
      const z = Math.cos(radian) * distanceFromCamera + NearZ;
      this.productBox[i].position.set(x, this.objectHeight, z);
      this.productBox[i]['default'] = { x: x, y: this.objectHeight, z: z };
      const trans = this.productBox[i].children[0];
      trans.visible = false;
      trans.rotation.y = radian;
      switch (i) {
        case 0:
          trans.scale.set(1.2, 1.2, 1.2);
          trans.position.y += 1.5;
          break;
        case 1:
          trans.scale.set(0.9, 0.9, 0.9);
          trans.position.y -= 0.2;
          break;
        case 2:
          trans.scale.set(0.7, 0.5, 0.7);
          trans.position.y -= 0.4;
          break;
        case 3:
          trans.scale.set(0.5, 0.5, 0.7);
          trans.position.y -= 0.4;
          break;
        case 4:
          trans.scale.set(0.5, 0.5, 0.7);
          trans.position.y -= 0.4;
          break;
        case 5:
          trans.scale.set(1.5, 1.5, 1.5);
          trans.position.y += 2.2;
          break;
        case 6:
          trans.scale.set(0.7, 0.5, 0.7);
          trans.position.y -= 0.4;
          break;
        case 7:
          trans.scale.set(0.5, 0.5, 0.7);
          trans.position.y -= 0.4;
          break;
        case 8:
          trans.scale.set(0.7, 0.5, 0.7);
          trans.position.y -= 0.4;
          break;
        case 9:
          trans.scale.set(1.0, 1.0, 1.0);
          trans.position.y += 1.5;
          break;
        default:
          break;
      }
    }

    const gometry1 = new THREE.BoxGeometry(12, 16, 12);
    const material1 = new THREE.MeshBasicMaterial({
      color: 0xff0000
    });
    for (let i = 0; i < this.productBox.length; i++) {
      const itemid = i;
      let radian = Math.PI + rotateLeft * dist + i * (0.1 * dist);
      const x = Math.sin(radian) * distanceFromCamera;
      const z = Math.cos(radian) * distanceFromCamera + NearZ;
      const transMash1 = new THREE.Mesh(gometry1, material1);
      const container1 = new THREE.Group();
      container1.add(transMash1);
      this.productObj.push(container1);
      this.lubiAnim.scene.add(container1);
      this.productObj[i].visible = true;
      this.productObj[i].position.set(x, this.productBox[i].position.y + 5, z);
      if (i === 9) {
        radian += 0.2;
      }
      if (i === 4) {
        radian -= 0.2;
      }
      this.productObj[i].rotation.y = radian + Math.PI;
      this.productObj[i]['radian'] = radian + Math.PI;
      transMash1.visible = false;
      this.itemsChildForCatchingClick.push(transMash1);
      this.itemsChildForCatchingClick[i].itemId = itemid;
      this.productObj[i].itemId = itemid;
    }
    for (let i = 0; i < this.productObj.length; i++) {
      const itemID = i;
      const gltfLoader = this.lubiAnim.getGltfobj(this.loadingManager);
      gltfLoader.load(viewingroomProducts[itemID].lowpolyModelUrl, (gltf) => {
        if (!this.lubiAnim?.scene) return;
        const root = gltf.scene;
        root.scale.set(20, 20, 20);
        if (
          itemID === 2 ||
          itemID === 3 ||
          itemID === 5 ||
          itemID === 6 ||
          itemID === 7
        ) {
          root.rotation.set(0, -Math.PI * 0.5, 0);
          root.scale.set(26, 26, 26);
        }
        if (itemID === 8 || itemID === 1) {
          root.scale.set(30, 30, 30);
        }
        this.productObj[itemID].add(root);
        this.setObjectPosition(this.productObj[itemID], itemID);
        const vec1 = {
          x: this.productObj[itemID].position.x,
          y: this.productObj[itemID].position.y,
          z: this.productObj[itemID].position.z
        };
        this.productObj[itemID]['default'] = vec1;
        this.productObj[itemID].visible =
          itemID > 3 &&
          this.lubiAnim?.props?.canvasState?.currentScene ===
            LoubiFutureScene.ViewingRoom;
        if (itemID === this.lubiAnim.props.canvasState?.focusItemID) {
          this.focusProduct(itemID);
        }
      });
    }
  }
  draw() {
    if (!this.lubiAnim?.focusedModel && !this.lubiAnim?.isTweening) {
      for (let i = 0; i < this.productObj?.length; i++) {
        if (this.productObj[i]?.default?.y) {
          this.productObj[i].position.y =
            this.productObj[i].default.y +
            Math.sin(Math.PI * i * 0.4 + this.counter * 0.01) * 0.9;
        }
      }
      this.counter++;
    }
    if (this.mirrorMesh && this.mirrorCamera && this.lubiAnim?.scene) {
      this.mirrorMesh.visible = false;
      this.mirrorCamera.update(this.lubiAnim.renderer, this.lubiAnim.scene);
      this.mirrorMesh.visible = true;
    }
  }
  focus(model, pos, rot, scale) {
    if (!model) return;
    if (!pos) return;
    const time = 1000;
    this.lubiAnim.isTweening = true;
    this.lubiAnim.focusedModel = model;
    const obj = model;
    new TWEEN.Tween(obj.position)
      .to(
        {
          x: pos.x,
          y: pos.y,
          z: pos.z
        },
        time
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(obj.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        time
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(obj.rotation)
      .to(
        {
          x: rot.x,
          y: rot.y,
          z: rot.z
        },
        time
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.lubiAnim.isTweening = false;
        if (this.lubiAnim.focusedModel) {
          this.lubiAnim.setTransparent(0.9);
        }
      })
      .start();
  }
  setMaterial = (model) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met?.name === 'Minigrane_mat' && met?.map) {
          const temp = met.map.image;
          met.map.image = model.secondTexture.image;
          met.map.needsUpdate = true;
          model.secondTexture.image = temp;
        }
      }
    });
  };

  updateLookAtPosition(pos, id) {
    this.lubiAnim.isTweening = true;
    const dist = 20;
    const cwd = new THREE.Vector3();
    this.lubiAnim.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.lubiAnim.camera.position);
    this.lookatPosition = cwd;
    new TWEEN.Tween(this.lookatPosition)
      .to(
        {
          x: pos.x,
          y: pos.y,
          z: pos.z
        },
        300
      )
      .onUpdate(() => {
        this.lubiAnim.camera.lookAt(this.lookatPosition);
      })
      .onComplete(() => {
        this.lubiAnim.camera.lookAt(pos);
        this.lubiAnim.isTweening = false;

        this.updatePositionForCamera(this.productObj[id]);
        this.lubiAnim.setLightIntensity(
          viewingroomProducts[id].ambientLight,
          viewingroomProducts[id].directionalLight,
          viewingroomProducts[id].hdrSavedVersion,
          15
        );
        if (!this.lubiAnim?.props?.viewOnly) {
          this.lubiAnim.updateObjectControlForModel(this.productObj[id]);
        }
        this.lubiAnim.setButton(true);
      })
      .start();
  }
  focusProduct(index) {
    if (index < 4) return;
    if (this.lubiAnim?.mp3Zoom) this.lubiAnim.mp3Zoom.play();
    const productId = viewingroomProducts[index]?.id;
    this.onSelectProduct(productId);
    if (!this.lubiAnim?.props?.viewOnly) {
      this.lubiAnim.updateRemoteOnChange(REMOTE_ACTION.FOCUS, {
        itemId: index
      });
    }
    this.updateLookAtPosition(this.productObj[index].position, index);
  }
  unfocusProduct(id) {
    if (!this.lubiAnim?.focusedModel) return;
    if (this.lubiAnim?.mp3Zoom) this.lubiAnim.mp3Zoom.play();
    this.onSelectProduct(undefined);
    if (!this.lubiAnim?.props?.viewOnly) {
      this.lubiAnim.updateRemoteOnChange(REMOTE_ACTION.UN_FOCUS, {
        itemId: id
      });
    }
    if (this.lubiAnim?.focusedModel?.itemId < 4) {
      this.lubiAnim.focusedModel.visible = false;
      id = this.setProductForColorChange();
      this.lubiAnim.focusedModel = this.productObj[id];
      this.lubiAnim.focusedModel.visible = true;
    }
    this.lubiAnim.setTransparent(0.0);
    this.focus(
      this.productObj[id],
      this.productObj[id].default,
      {
        x: 0,
        y: this.productObj[id].radian,
        z: 0
      },
      1
    );
    if (this.lubiAnim.objectControls) {
      this.lubiAnim.RemoveObjectControl();
    }
    this.lubiAnim.focusedModel = undefined;
    this.lubiAnim.setButton(false);
    this.lubiAnim?.setOrbitControl();
  }

  touchEvent(e, type) {
    if (type === touchType.touchUp) {
      const catchTouchArray = [];
      for (let i = 4; i < this.itemsChildForCatchingClick.length; i++) {
        catchTouchArray.push(this.itemsChildForCatchingClick[i]);
      }
      const clickedModel =
        this.lubiAnim.detectFirstTouchedObject(catchTouchArray);

      const id = clickedModel?.object?.['itemId'];
      if (clickedModel?.object && !this.lubiAnim.focusedModel) {
        this.focusProduct(id);
      } else if (
        this.lubiAnim?.focusedModel &&
        this.lubiAnim?.isClicked(type)
      ) {
        this.unfocusProduct(this.lubiAnim?.focusedModel?.itemId);
      }
    }
  }
  updatePositionForCamera(myObject3D) {
    // fixed distance from camera to the object
    let dist = 6;
    if (
      myObject3D.itemId === 4 ||
      myObject3D.itemId === 9 ||
      myObject3D.itemId === 0
    ) {
      dist = 7;
    }
    if (isMobileOrTablet) {
      dist *= 1.3;
    }
    const cwd = new THREE.Vector3();
    this.lubiAnim.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.lubiAnim.camera.position);
    const index = myObject3D.itemId - 4;
    this.focus(
      myObject3D,
      cwd,
      {
        x: 0,
        y: 3 * Math.PI + 2.2 + index * 0.4,
        z: 0
      },
      0.5
    );
  }
  clearObjects() {
    return new Promise((resolve) => {
      if (this.mirrorMesh) this.mirrorMesh.visible = false;
      this.productBox
        .filter((model) => model)
        .forEach((model) => {
          model.visible = false;
        });

      this.productObj
        .filter((model) => model)
        .forEach((model) => {
          model.visible = false;
        });

      this.itemsChildForCatchingClick
        .filter((model) => model)
        .forEach((model) => {
          model.visible = false;
        });
      if (this.gateForCatchingClick) this.gateForCatchingClick.visible = false;
      setTimeout(() => {
        resolve({});
      }, 10);
    });
  }
  setScreen() {
    this.productBox
      .filter((model) => model)
      .forEach((model) => {
        model.visible = true;
      });
    for (let i = 0; i < 4; i++) {
      if (this.productBox[i]) this.productBox[i].visible = false;
    }

    this.productObj
      .filter((model) => model)
      .forEach((model) => {
        model.visible = true;
      });
    for (let i = 0; i < 4; i++) {
      if (this.productObj[i]) this.productObj[i].visible = false;
    }

    this.itemsChildForCatchingClick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    if (this.gateForCatchingClick) {
      this.gateForCatchingClick.visible = false;
    }
    if (this.productBox[5]?.position && this.lubiAnim?.camera) {
      this.lubiAnim.camera.lookAt(
        new THREE.Vector3(
          this.productBox[5].position.x,
          this.objectHeight,
          this.productBox[5].position.z
        )
      );
    }
  }
  setFocusObject(id) {
    if (id && this.productObj[id])
      this.lubiAnim.focusedModel = this.productObj[id];
  }
}
export default ViewingRoom;

import React from 'react'
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture'
import { LFColor } from '../../../../ThreejsAnimation/loubiFuture/assets'

const ArpoadorInfo = () => (
    <div className="aproador-info">
        <img
          src={`${LFAssetBaseUrl}/product/arpoador.png`}
          alt=""
          className="title"
        />
        <p>
          The Arpoador sneaker is a tribute to Christian Louboutin’s love
          for Brazil, named after the rocky junction that lies between
          Ipanema and Copacabana. These all-terrain sneakers - featured in 5
          colourways - are inspired by Brazilian modernist architecture and
          its love for curves, embodied in the sneaker’s distinctive sole.
        </p>
        <p>
          Flexibility and versatility lies at the heart of its design with
          its structure is made of an extensible technical mesh sock
          assembled on a 2-part disruptive sole. A carved graphic line on
          the sole is encased in an entirely transparent outsole-
          emphasizing the fluid curves and a contrasting signature red sole.
        </p>
        <style jsx>{`
          img {
            width: 90%;
            height: auto;
            max-width: 400px;
          }
          p {
            color: ${LFColor.blue};
            text-transform: 0.1em;
            text-transform: uppercase;
          }
        `}</style>
      </div>
)

export default ArpoadorInfo

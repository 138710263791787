import React from 'react';

const ErrorMessage = () => {
  return (
    <>
      <div className="errorMessage">
        <h2 className="label">
          Please use a modern browser for support of virtual experience.
        </h2>
        <br />
        <p className="label">
          You may consider using the latest Chrome, FireFox, Edge or Safari
          browser for optimal experience.
        </p>
      </div>

      <style jsx>{`
        .errorMessage {
          width: 100%;
          position: absolute;
          top: 50%;
          margin-top: -100px;
          left: 0;
          right: 0;
        }
        h2 {
          font-size: 1.8em;
        }
        p {
          font-size: 1.25em;
        }
        .label {
          padding: 0 10px;
          margin: auto;
          letter-spacing: 1px;
          font-weight: bold;
          line-height: 1.2;
          color: #fff;
          text-shadow: 0 1px 2px #000;
        }
      `}</style>
    </>
  );
};

export default ErrorMessage;

import React from 'react';
import { logClickButton } from '../../analytics';
import { getPSDisconnectedComponent } from '../storeComponentFactory';

const PSDisconnected = ({
  onReconnect,
  storeId
}: {
  onReconnect: () => void;
  storeId: string;
}) => {
  const handleReconnect = () => {
    onReconnect();
    logClickButton('RECONNECT_BUTTON');
  };
  const disconnectComponent = getPSDisconnectedComponent(
    storeId,
    handleReconnect
  );
  return (
    <div className="PSDisconnected fixed-full d-flex">
      {disconnectComponent}
      <style jsx>{`
        .PSDisconnected {
          flex-direction: column;
          align-items: center;
          background: #000;
        }
      `}</style>
    </div>
  );
};

export default PSDisconnected;

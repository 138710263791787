import React from 'react';
import * as THREE from 'three';
import { assetBaseUrl } from '../../../config';
import {
  IAnimationProduct,
  ILoubiBeautyCartAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import LJBackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/Buttons/LJBackToAnimationButton';
import {
  beautyCartImg,
  fragranceProducts,
  lipstick,
  lipstick2D,
  lipstickColors,
  modelWorkshopEnvironmentImagePath
} from './assets';
import { lipstickLow, lipstickTexturesUrl } from '../louboutin/assets';
const lipstickJpColors = [
  [lipstickColors[0][1], lipstickColors[0][2]],
  [lipstickColors[1][1], lipstickColors[1][2], lipstickColors[1][3]],
  [],
  [
    lipstickColors[3][0],
    lipstickColors[3][1],
    lipstickColors[3][2],
    lipstickColors[3][3],
    lipstickColors[3][4],
    lipstickColors[3][6]
  ],
  [lipstickColors[4][5]],
  [lipstickColors[5][1], lipstickColors[5][5], lipstickColors[5][6]],
  [lipstickColors[6][7], lipstickColors[6][8]],
  [lipstickColors[7][0]]
];

let refBeautyCart = undefined;
let backDiv;
let perfumeDiv;
const isMobileOrTab = isUserOnMobile();

const STRZOOMRAYCASTER = 'zoomRaycaster';
const matName = [
  'Bullet',
  'Bullet',
  'Bulb',
  'Bullet',
  'Bullet',
  'Bulb',
  'Cap',
  'Cap'
];
const perfumeTextArray = [
  'FLORAL ROSE\nBLACKCURRANT\nTURKISH ROSE\nPATCHOULI',
  'FLORAL FRUITY\nSTRAWBERRIES\nROSE BOUQUET\nCEDAR WOOD',
  'FLORAL WOODY\nJASMINE\nTUBEROSE\nMUSK',
  'ORIENTAL SPICY\nCARDAMOME\nIRIS\nVANILLA',
  'LEATHER WOODY\nSUEDE LEATHER\nPINK PEPPER\nCEDAR WOOD',
  'WOODY ORIENTAL\nPATCHOULI\nCEDAR WOOD\nTONKABEAN',
  'SPICY ORIENTAL\nMYRRH\nCYPRIOL\nSANTAL WOOD'
];

const touchType = {
  touchDown: 'touchDown',
  touchMove: 'touchMove',
  touchUp: 'touchUp'
};
let GLTFLoader, RGBELoader, TWEEN, ObjectControls;
interface Props {
  remoteState?: ILoubiBeautyCartAnimationState;
  updateRemoteState: (state: ILoubiBeautyCartAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: (val) => void;
  viewOnly?: boolean;
}
interface IState {
  strPerfume: string;
}

class BeautyCart extends React.Component<Props, IState> {
  isDown: boolean;
  isLoading: boolean;
  isTweening: boolean;
  isModelLive: boolean;

  maxScale: number;
  minScale: number;
  incScale: number;
  initScale: number;
  ImgDiffX: number;
  loadingCount: number;
  initDistance: number;
  doubleTapClick: number;

  mouse: THREE.Vector2;
  touchPointer: THREE.Vector2;
  mouseDownPos: THREE.Vector2;

  rayCasterScale: THREE.Vector3;
  rayCasterModelScale: THREE.Vector3;
  focusItemPosition: THREE.Vector3;
  focusPerfumePosition: THREE.Vector3;

  zoomModel: any;
  controls: any;
  textureCart: any;
  transparent_Plane: any;

  imgPerfumes: any[];
  imgLipstick: any[];
  colorLipstick: any[];
  textureLipstick: any[];
  itemsChildForCatchingCartClick: any[];
  itemsChildForCatchingModelClick: any[];

  scene: THREE.Scene;
  raycaster: THREE.Raycaster;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  transparent_Mat: THREE.MeshBasicMaterial;

  blackMattePackMap: THREE.Texture;
  blackMattePackNormal: THREE.Texture;
  blackMattePackMetallic: THREE.Texture;
  blackShinyPackMap: THREE.Texture;
  blackShinyPackNormal: THREE.Texture;
  blackShinyPackMetallic: THREE.Texture;
  blackSnakeSkinPackMap: THREE.Texture;
  blackSnakeSkinPackNormal: THREE.Texture;
  blackSnakeSkinPackMetallic: THREE.Texture;
  goldenMattePackMap: THREE.Texture;
  goldenMattePackNormal: THREE.Texture;
  goldenMattePackMetallic: THREE.Texture;
  goldenSilkyPackMap: THREE.Texture;
  goldenSilkyPackNormal: THREE.Texture;
  goldenSilkyPackMetallic: THREE.Texture;
  goldenSnakeSkinPackMap: THREE.Texture;
  goldenSnakeSkinPackNormal: THREE.Texture;
  goldenSnakeSkinPackMetallic: THREE.Texture;
  matteFluidMap: THREE.Texture;
  matteFluidNormal: THREE.Texture;
  matteFluidMetallic: THREE.Texture;
  metalMatteFluidMap: THREE.Texture;
  metalMatteFluidNormal: THREE.Texture;
  metalMatteFluidMetallic: THREE.Texture;

  constructor(props) {
    super(props);
    this.state = { strPerfume: '' };
    refBeautyCart = this;

    this.isDown = false;
    this.isLoading = false;
    this.isTweening = false;
    this.isModelLive = false;

    const SCL = 1;
    this.initScale = 1;
    this.initDistance = 0;
    this.ImgDiffX = 5.5;
    this.loadingCount = 0;
    this.doubleTapClick = 0;
    this.minScale = 5 * SCL;
    this.maxScale = 16 * SCL;
    this.incScale = 0.5 * SCL;

    this.mouse = new THREE.Vector2();
    this.touchPointer = new THREE.Vector2();
    this.mouseDownPos = new THREE.Vector2();

    this.rayCasterScale = new THREE.Vector3(5, 4, 1);
    this.rayCasterModelScale = new THREE.Vector3(0.05, 0.14, 0.05);
    this.focusItemPosition = new THREE.Vector3(0, 0.25, 15);
    this.focusPerfumePosition = new THREE.Vector3(0, 0.3, 8);

    this.controls = undefined;
    this.zoomModel = undefined;
    this.transparent_Plane = undefined;

    this.imgPerfumes = [];
    this.imgLipstick = [];
    this.colorLipstick = [];
    this.textureLipstick = [];
    this.itemsChildForCatchingCartClick = [];
    this.itemsChildForCatchingModelClick = [];

    this.scene = new THREE.Scene();
    this.raycaster = new THREE.Raycaster();
    this.state = { strPerfume: perfumeTextArray[0] };
    this.transparent_Mat = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.71,
      color: 0x000000
    });
  }
  loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };

  componentDidMount() {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    perfumeDiv = document.getElementById('centertxt');
    perfumeDiv.style.display = 'none';
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.camera.position.set(0, 0, 20);
    if (isMobileOrTab) {
      document.addEventListener('gesturestart', this.zoomDisable);
      document.addEventListener('gesturechange', this.zoomDisable);
      document.addEventListener('gestureend', this.zoomDisable);
    }
    this.loadingCount = 0;
    this.loadThreeJsModulesAsync().then(() => {
      this.init();
    });
  }
  componentWillUnmount() {
    this.cleanUp();
  }
  componentDidUpdate() {
    if (this.props?.remoteState?.flag)
      this.remoteObjectUpdate(this.props?.remoteState);
  }
  loadingManager = new THREE.LoadingManager(() => {
    this.callback();
  });
  loadZoom = (id) => {
    this.isModelLive = true;
    this.isLoading = true;
    this.props.onReady(false);
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    this.transparent_Plane.visible = true;
    let url = '';
    if (ItemId < fragranceProducts.length) {
      url = fragranceProducts[ItemId].highPolyModelUrl;
    } else {
      url = isMobileOrTab
        ? lipstickLow[ItemId - fragranceProducts.length]
        : lipstick[ItemId - fragranceProducts.length];
    }
    gltfLoader.load(url, (gltf) => {
      if (!this.scene) return;
      const group = new THREE.Group();
      const root = gltf.scene;

      group.add(root);
      if (this.scene) this.scene.add(group);
      if (this.zoomModel) {
        this.loadNew(false, 0);
      }
      this.transparent_Plane.visible = true;
      const zoomRaycaster = this.createRayCasterCatcher(
        this.rayCasterModelScale
      );
      zoomRaycaster.visible = false;
      zoomRaycaster.name = STRZOOMRAYCASTER;
      group.add(zoomRaycaster);
      this.zoomModel = group;
      this.zoomModel.ItemId = ItemId;
      group.scale.set(8, 8, 8);
      if (ItemId < fragranceProducts.length) {
        this.zoomModel.name = fragranceProducts[ItemId].modelName;
        root.position.set(0, -0.07, 0);
      } else {
        this.updateMaterialForMobile(root, ItemId - fragranceProducts.length);
        this.zoomModel.name =
          lipstickJpColors[ItemId - fragranceProducts.length][0].modelName;
        root.scale.set(2, 2, 2);
        root.position.set(0, -0.0, 0);
        if (ItemId < 13)
          this.zoomModel.getObjectByName('Cap').position.y = 0.015;
        this.changeColor(ItemId - fragranceProducts.length, 0);
      }
      if (this.isModelLive === false) {
        this.loadNew(false, 0);
      } else if (this.zoomModel) {
        this.focusNew(this.zoomModel, this.focusItemPosition, 8);
      }
      this.props.onReady(true);
      this.isLoading = false;
    });
  };
  loadNew = (isLoad, id) => {
    if (isLoad) {
      if (this.zoomModel === undefined) this.loadZoom(id);
    } else {
      if (this.zoomModel === undefined) return;
      if (
        this.zoomModel.ItemId >=
        fragranceProducts.length + lipstick.length - 2
      ) {
        this.transparent_Plane.visible = false;
        backDiv.style.display = 'none';
      }
      this.transparent_Plane.position.set(0, 1.5, -30);
      this.props.didSelectModel(undefined);
      this.zoomModel.traverse((object) => {
        if (!object.isMesh) return;
        this.scene.remove(object);
        if (object?.geometry) {
          object.geometry.dispose();
          if (object.material.isMaterial) {
            this.cleanMaterial(object.material);
          } else {
            for (const material of object.material)
              this.cleanMaterial(material);
          }
        }
        object.geometry = undefined;
        object = undefined;
      });
      this.scene.remove(this.zoomModel);
      this.zoomModel = undefined;
    }
  };
  changeColor = (color, id) => {
    if (this.textureLipstick[color] === undefined) return;
    if (this.textureLipstick[color][id] === undefined) return;
    if (this.textureLipstick[color][id].color === undefined) return;
    if (this.zoomModel.getObjectByName(matName[color]) === undefined) return;
    this.setMaterialMap(
      this.zoomModel.getObjectByName(matName[color]),
      this.textureLipstick[color][id].color,
      undefined
    );
    if (color > 5) {
      if (this.zoomModel.getObjectByName('Body') === undefined) return;
      this.setMaterialMap(
        this.zoomModel.getObjectByName('Body'),
        this.textureLipstick[color][id].color,
        undefined
      );
    }
  };
  updateMaterialForMobile = (model, id) => {
    switch (id) {
      case 0:
        this.setMaterialOnBaseObject(
          model,
          this.blackMattePackMap,
          this.blackMattePackNormal,
          this.blackMattePackMetallic,
          this.blackMattePackMetallic,
          this.blackMattePackMetallic
        );
        return;
      case 1:
        this.setMaterialOnBaseObject(
          model,
          this.blackShinyPackMap,
          this.blackShinyPackNormal,
          this.blackShinyPackMetallic,
          this.blackShinyPackMetallic,
          this.blackShinyPackMetallic
        );
        return;
      case 2:
        this.setMaterialOnBaseObject(
          model,
          this.blackSnakeSkinPackMap,
          this.blackSnakeSkinPackNormal,
          this.blackSnakeSkinPackMetallic,
          this.blackSnakeSkinPackMetallic,
          this.blackSnakeSkinPackMetallic
        );
        return;
      case 3:
        this.setMaterialOnBaseObject(
          model,
          this.goldenMattePackMap,
          this.goldenMattePackNormal,
          this.goldenMattePackMetallic,
          this.goldenMattePackMetallic,
          this.goldenMattePackMetallic
        );
        return;
      case 4:
        this.setMaterialOnBaseObject(
          model,
          this.goldenSilkyPackMap,
          this.goldenSilkyPackNormal,
          this.goldenSilkyPackMetallic,
          this.goldenSilkyPackMetallic,
          this.goldenSilkyPackMetallic
        );
        return;
      case 5:
        this.setMaterialOnBaseObject(
          model,
          this.goldenSnakeSkinPackMap,
          this.goldenSnakeSkinPackNormal,
          this.goldenSnakeSkinPackMetallic,
          this.goldenSnakeSkinPackMetallic,
          this.goldenSnakeSkinPackMetallic
        );
        return;
      case 6:
        this.setMaterialOnBaseObject(
          model,
          this.matteFluidMap,
          this.matteFluidNormal,
          this.matteFluidMetallic,
          this.matteFluidMetallic,
          this.matteFluidMetallic
        );
        return;

      case 7:
        this.setMaterialOnBaseObject(
          model,
          this.metalMatteFluidMap,
          this.metalMatteFluidNormal,
          this.metalMatteFluidMetallic,
          this.metalMatteFluidMetallic,
          this.metalMatteFluidMetallic
        );
        return;
    }
  };

  setMaterialOnBaseObject = (
    model,
    map,
    normal,
    metallic,
    roughnessMap?,
    aoMap?
  ) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.map && map) {
          met.map.image = map.image;
          met.map.needsUpdate = true;
        }
        if (met.normalMap && normal) {
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.metalnessMap && metallic) {
          met.metalnessMap.image = metallic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met.aoMap && aoMap) {
          met.aoMap.image = aoMap.image;
          met.aoMap.needsUpdate = true;
        }
        if (met.roughnessMap && roughnessMap) {
          met.roughnessMap.image = roughnessMap.image;
          met.roughnessMap.needsUpdate = true;
        }
      }
    });
  };
  setMaterialMap = (model, texture, material) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        if (material == undefined) {
          const met = object['material'];
          material = met.clone();
          material.map = met.map.clone();
          material.map.image = texture.image;
          material.map.needsUpdate = true;
        }
        object['material'] = material;
      }
    });
  };

  cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      this.camera === undefined ||
      this.renderer === undefined ||
      this.props.viewOnly
    )
      return;
    if (!this.controls) {
      this.controls = new ObjectControls(
        this.camera,
        this.renderer.domElement,
        model
      );
    } else {
      this.controls.setObjectToMove(model);
    }
    this.controls.setDistance(2, 400); // set min - max distance for zoom
    this.controls.setZoomSpeed(0.5); // set zoom speed
    this.controls.enableVerticalRotation();
    this.controls.enableHorizontalRotation();
    // this.controls.enableZoom();
    this.controls.disableZoom();
    this.controls.setRotationSpeed(0.1);
  };

  selectColorAnim = (model, colorModel, colorObj) => {
    if (!model) return;
    this.props.didSelectModel(undefined);
    this.props.didSelectModel(colorObj);
    model.visible = true;
    this.isTweening = true;
    new TWEEN.Tween(model.rotation)
      .to({ y: model.rotation.y + Math.PI * 2 }, 500)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.isTweening = false;
      })
      .start();
    new TWEEN.Tween(colorModel.scale)
      .to({ x: 0.91, y: 0.91, z: 0.91 }, 100)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        new TWEEN.Tween(colorModel.scale)
          .to({ x: 1, y: 1, z: 1 }, 100)
          .easing(TWEEN.Easing.Exponential.InOut)
          .start();
      })
      .start();
  };
  focusNew = (model, pos, scale) => {
    if (!model) return;
    const object3d = model;
    object3d.visible = true;
    this.isTweening = true;
    this.props.didSelectModel(undefined);
    if (object3d?.ItemId < fragranceProducts.length) {
      this.props.didSelectModel(fragranceProducts[object3d.ItemId]);
      this.setState({ strPerfume: perfumeTextArray[object3d.ItemId] });
      perfumeDiv.style.display = 'none';
    } else {
      this.props.didSelectModel(
        lipstickJpColors[object3d.ItemId - fragranceProducts.length][0]
      );
    }
    new TWEEN.Tween(object3d.position)
      .to(
        {
          x: pos.x,
          y: pos.y,
          z: pos.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        if (
          object3d &&
          object3d?.ItemId >= fragranceProducts.length &&
          this.zoomModel
        ) {
          this.updateObjectControlForModel(object3d);
          const diff = 0.2;
          const color = object3d?.ItemId - fragranceProducts.length;
          const length = this.textureLipstick[color]?.length;
          let mobPosy = -0.6;
          if (window.innerHeight < window.innerWidth) {
            mobPosy = -0.3;
          }
          for (
            let i = 0;
            i < this.colorLipstick.length && length > 1 && length != undefined;
            i++
          ) {
            this.colorLipstick[i].position.set(
              -diff * 0.5 * (length - 1) + i * diff,
              isMobileOrTab ? mobPosy : -0.8,
              16
            );
            this.colorLipstick[i].visible = i < length;
            if (i < length) {
              this.colorLipstick[i].traverse(function (child) {
                if (child.isMesh) {
                  child.material.map =
                    refBeautyCart.textureLipstick[color][i].chooser;
                }
              });
            }
          }
        }
        this.isTweening = false;
      })
      .start();

    new TWEEN.Tween(object3d.scale)
      .to({ x: scale, y: scale, z: scale }, 1000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  focus2DPerfume = (model, scale, pos) => {
    if (!model) return;
    model.visible = true;
    this.isTweening = true;
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.isTweening = false;
      })
      .start();

    new TWEEN.Tween(model.position)
      .to({ x: pos.x, y: pos.y, z: pos.z }, 500)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  focus2D = (model, scale) => {
    if (!model) return;
    model.visible = true;
    this.isTweening = true;
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.isTweening = false;
      })
      .start();
  };
  close2D = (model, scale) => {
    if (!model) return;
    const obje = model;
    model.visible = true;
    this.isTweening = true;
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        obje.visible = false;
        this.isTweening = false;
      })
      .start();
    new TWEEN.Tween(model.position)
      .to(
        {
          x: 0,
          y: 3,
          z: -28
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        obje.visible = false;
        this.isTweening = false;
      })
      .start();
  };

  updateRemoteObjectControlForModel = () => {
    if (this.zoomModel) {
      this.props.updateRemoteState({
        flag: REMOTE_ACTION.MOUSE_CONTROL,
        focusItem: this.zoomModel.id,
        position: [
          this.zoomModel.position.x,
          this.zoomModel.position.y,
          this.zoomModel.position.z
        ],
        scale: [
          this.zoomModel.scale.x,
          this.zoomModel.scale.y,
          this.zoomModel.scale.z
        ],
        rotation: [
          this.zoomModel.rotation.x,
          this.zoomModel.rotation.y,
          this.zoomModel.rotation.z
        ]
      });
    }
  };
  detectFirstTouchedObject = (e, type, sys, objects) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        this.mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = this.renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      this.mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      this.mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(objects);
    if (intersects.length) {
      return intersects[0];
    }
  };

  onPinch = (ev) => {
    if (this.props.remoteState != undefined || this.isTweening == true) return;
    if (this.zoomModel === undefined && ev.type == 'double_tap') {
      const perfumesVisible = this.imgPerfumes.find((p) => p.visible === true);
      if (perfumesVisible) {
        const perfumesPos = this.imgPerfumes.find((p) => p.position.z > 1);
        if (perfumesPos) {
          this.selectedPerfumeBack(perfumesPos.ItemId); 
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.UN_FOCUS,
            focusItem: perfumesPos.ItemId
          });
        } else {
          this.closeGate();
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.CLOSE_GATE_1,
            focusItem: 1
          });
        }
      }
      const lipstickVisible = this.imgLipstick.find((p) => p.visible === true);
      if (lipstickVisible) {
        this.closeGate();
        this.props.updateRemoteState({
          flag: REMOTE_ACTION.CLOSE_GATE_1,
          focusItem: 1
        });
      }
    }
    if (
      this.zoomModel !== undefined &&
      (ev.type == 'pinchstart' || ev.type == 'pinchmove')
    ) {
      if (ev.type === 'pinchstart') {
        this.initScale = this.zoomModel.scale.x || 1;
      }
      this.zoomModel?.scale.set(
        this.initScale * ev.scale,
        this.initScale * ev.scale,
        this.initScale * ev.scale
      );
      if (this.zoomModel?.scale.x > this.maxScale)
        this.zoomModel?.scale.set(this.maxScale, this.maxScale, this.maxScale);
      if (this.zoomModel?.scale.x < this.minScale)
        this.zoomModel?.scale.set(this.minScale, this.minScale, this.minScale);
      this.updateRemoteObjectControlForModel();
    }
  };
  setTouchValues = (e, type) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        this.mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          this.touchPointer.x =
            (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          this.touchPointer.y =
            -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            this.initDistance = this.touchPointer.distanceTo(this.mouse);
            this.onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = this.touchPointer.distanceTo(this.mouse);
            this.onPinch({
              type: 'pinchmove',
              scale: currentDistance / this.initDistance
            });
          }
        }
      }
    } else {
      const elem = this.renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      this.mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      this.mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      this.mouseDownPos.x = this.mouse.x;
      this.mouseDownPos.y = this.mouse.y;
      const diff = new Date().getTime() - this.doubleTapClick;
      if (diff < 300) {
        if (e.touches !== null && e.touches !== undefined) {
          if (e.touches.length === 1) {
            this.onPinch({ type: 'doubletap', scale: 1 });
          }
        } else {
          this.onPinch({ type: 'doubletap', scale: 1 });
        }
      }
      this.doubleTapClick = new Date().getTime();
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
  };
  onwheelEvent = (event) => {
    if (this.props.remoteState != undefined || this.zoomModel == undefined)
      return;

    let modelScale = this.zoomModel.scale.x;
    if (event.deltaY > 0) {
      if (modelScale < this.maxScale) modelScale += this.incScale;
    } else {
      if (modelScale > this.minScale) modelScale -= this.incScale;
    }
    this.zoomModel.scale.set(modelScale, modelScale, modelScale);
    this.updateRemoteObjectControlForModel();
  };
  createRayCasterCatcher = (scl) =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(scl.x, scl.y, scl.z),
      new THREE.MeshBasicMaterial({})
    );
  callback = () => {
    this.loadingCount++;
    this.props.onReady(true);
    if (this.textureCart) {
      this.textureCart.generateMipmaps = false;
      this.textureCart.minFilter = THREE.LinearFilter;
      this.textureCart.needsUpdate = true;
    }
  };

  load2D = () => {
    const textureLoader = new THREE.TextureLoader(this.loadingManager);

    this.textureCart = textureLoader.load(beautyCartImg);
    this.textureCart.encoding = THREE.sRGBEncoding;

    let scl = 0.049;
    const trolleyPlan = new THREE.Mesh(
      new THREE.PlaneGeometry(2048 * scl, 1024 * scl),
      new THREE.MeshBasicMaterial({ map: this.textureCart })
    );
    trolleyPlan.position.set(0, 3, -30);
    this.scene.add(trolleyPlan);
    scl = 0.012;

    for (let i = 0; i < fragranceProducts.length; i++) {
      const texturePerfumes = textureLoader.load(
        fragranceProducts[i].lowPolyModelUrl
      );
      texturePerfumes.encoding = THREE.sRGBEncoding;
      this.imgPerfumes.push(
        new THREE.Mesh(
          new THREE.PlaneGeometry(1024 * scl, 2048 * scl),
          new THREE.MeshBasicMaterial({
            map: texturePerfumes,
            transparent: true
          })
        )
      );
      this.imgPerfumes[i].position.set(0, 3, -28);
      this.scene.add(this.imgPerfumes[i]);
      this.imgPerfumes[i].visible = false;
      this.imgPerfumes[i].ItemId = i;
    }

    for (let i = 0; i < 3; i++) {
      scl = 4;
      const texture_Lipstick = textureLoader.load(lipstick2D[i]);
      if (i == 0) {
        texture_Lipstick.wrapS = texture_Lipstick.wrapT = THREE.RepeatWrapping;
        texture_Lipstick.repeat.set(0.66, 1);
      }
      texture_Lipstick.encoding = THREE.sRGBEncoding;
      this.imgLipstick.push(
        new THREE.Mesh(
          new THREE.PlaneGeometry((i == 0 ? 2.64 : 4) * scl, 4 * scl),
          new THREE.MeshBasicMaterial({
            map: texture_Lipstick,
            transparent: true
          })
        )
      );
      this.imgLipstick[i].position.set(0, 3, -28);
      this.imgLipstick[i]['ItemId'] = i;
      this.scene.add(this.imgLipstick[i]);
      this.imgLipstick[i].visible = false;
    }
    this.imgLipstick.push(this.imgLipstick[2].clone());
    this.imgLipstick[3].visible = false;
    this.imgLipstick[3]['ItemId'] = 3;
    this.scene.add(this.imgLipstick[3]);
    for (let i = 0; i < 5; i++) {
      const ItemID = i;
      this.itemsChildForCatchingCartClick[i] = this.createRayCasterCatcher(
        this.rayCasterScale
      );
      this.itemsChildForCatchingCartClick[i].visible = false;
      this.itemsChildForCatchingCartClick[i].itemId = ItemID;
      this.scene.add(this.itemsChildForCatchingCartClick[i]);
    }
    this.itemsChildForCatchingCartClick[0].position.set(-2.3, 11.5, -30);
    this.itemsChildForCatchingCartClick[1].position.set(-2.3, 6.3, -30);
    this.itemsChildForCatchingCartClick[2].position.set(-2.3, 1.5, -30);
    this.itemsChildForCatchingCartClick[3].position.set(-2.3, -3.2, -30);
    this.itemsChildForCatchingCartClick[4].position.set(-2.3, -9.4, -30);

    for (let i = 0; i < 7; i++) {
      const ItemID = i;
      this.itemsChildForCatchingModelClick[i] = this.createRayCasterCatcher(
        this.rayCasterScale
      );
      this.itemsChildForCatchingModelClick[i].position.set(
        -this.ImgDiffX * 3 + i * this.ImgDiffX,
        2.8,
        -29
      );
      this.itemsChildForCatchingModelClick[i].scale.set(1, 2.6, 1);
      this.itemsChildForCatchingModelClick[i].visible = false;
      this.itemsChildForCatchingModelClick[i].itemId = ItemID;
      this.scene.add(this.itemsChildForCatchingModelClick[i]);
    }

    const diff = 0.2;

    lipstickJpColors.forEach((element) => {
      const textures = [];
      element.forEach((url) => {
        const textureChooser = textureLoader.load(url.highPolyModelUrl);
        textureChooser.encoding = THREE.sRGBEncoding;
        textures.push({
          color: textureLoader.load(url.lowPolyModelUrl),
          chooser: textureChooser
        });
      });
      this.textureLipstick.push(textures);
    });

    for (let i = 0; i < this.textureLipstick[3].length; i++) {
      this.colorLipstick.push(
        new THREE.Mesh(
          new THREE.PlaneGeometry(0.12, 0.24),
          new THREE.MeshBasicMaterial({
            map: this.textureLipstick[3][i].chooser,
            transparent: true
          })
        )
      );
      this.scene.add(this.colorLipstick[i]);
      this.colorLipstick[i].position.set(-diff * 3 + i * diff, -1.2, 16);
      this.colorLipstick[i].visible = false;
    }
  };
  loadEnvironment = () => {
    if (!this.scene || !this.renderer) return;
    this.transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(100, 50),
      this.transparent_Mat
    );
    this.transparent_Plane.position.set(0, 1.5, 1); //rotation.x=-Math.PI * 0.1346
    this.transparent_Plane.position.set(0, 1.5, -30);
    this.scene?.add(this.transparent_Plane);
    this.transparent_Plane.visible = false;

    if (isMobileOrTab) {
      const textureLoader = new THREE.TextureLoader(this.loadingManager);
      this.blackMattePackMap = textureLoader.load(
        lipstickTexturesUrl.blackMattePackMap
      );
      this.blackMattePackNormal = textureLoader.load(
        lipstickTexturesUrl.blackMattePackNormal
      );
      this.blackMattePackMetallic = textureLoader.load(
        lipstickTexturesUrl.blackMattePackMetallic
      );
      this.blackShinyPackMap = textureLoader.load(
        lipstickTexturesUrl.blackShinyPackMap
      );
      this.blackShinyPackNormal = textureLoader.load(
        lipstickTexturesUrl.blackShinyPackNormal
      );
      this.blackShinyPackMetallic = textureLoader.load(
        lipstickTexturesUrl.blackShinyPackMetallic
      );
      this.blackSnakeSkinPackMap = textureLoader.load(
        lipstickTexturesUrl.blackSnakeSkinPackMap
      );
      this.blackSnakeSkinPackNormal = textureLoader.load(
        lipstickTexturesUrl.blackSnakeSkinPackNormal
      );
      this.blackSnakeSkinPackMetallic = textureLoader.load(
        lipstickTexturesUrl.blackSnakeSkinPackMetallic
      );
      this.goldenMattePackMap = textureLoader.load(
        lipstickTexturesUrl.goldenMattePackMap
      );
      this.goldenMattePackNormal = textureLoader.load(
        lipstickTexturesUrl.goldenMattePackNormal
      );
      this.goldenMattePackMetallic = textureLoader.load(
        lipstickTexturesUrl.goldenMattePackMetallic
      );
      this.goldenSilkyPackMap = textureLoader.load(
        lipstickTexturesUrl.goldenSilkyPackMap
      );
      this.goldenSilkyPackNormal = textureLoader.load(
        lipstickTexturesUrl.goldenSilkyPackNormal
      );
      this.goldenSilkyPackMetallic = textureLoader.load(
        lipstickTexturesUrl.goldenSilkyPackMetallic
      );
      this.goldenSnakeSkinPackMap = textureLoader.load(
        lipstickTexturesUrl.goldenSnakeSkinPackMap
      );
      this.goldenSnakeSkinPackNormal = textureLoader.load(
        lipstickTexturesUrl.goldenSnakeSkinPackNormal
      );
      this.goldenSnakeSkinPackMetallic = textureLoader.load(
        lipstickTexturesUrl.goldenSnakeSkinPackMetallic
      );
      this.matteFluidMap = textureLoader.load(
        lipstickTexturesUrl.matteFluidMap
      );
      this.matteFluidNormal = textureLoader.load(
        lipstickTexturesUrl.matteFluidNormal
      );
      this.matteFluidMetallic = textureLoader.load(
        lipstickTexturesUrl.matteFluidMetallic
      );
      this.metalMatteFluidMap = textureLoader.load(
        lipstickTexturesUrl.metalMatteFluidMap
      );
      this.metalMatteFluidNormal = textureLoader.load(
        lipstickTexturesUrl.metalMatteFluidNormal
      );
      this.metalMatteFluidMetallic = textureLoader.load(
        lipstickTexturesUrl.metalMatteFluidMetallic
      );
    }

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = modelWorkshopEnvironmentImagePath;
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (this.renderer && this.scene) {
            let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            this.scene.environment = envMap;
          }
        },
        undefined,
        undefined,
      );
  };
  eventDown = (e) => {
    this.touchEvent(e, touchType.touchDown, 0);
  };
  eventMove = (e) => {
    this.touchEvent(e, touchType.touchMove, 0);
  };
  eventUp = (e) => {
    this.touchEvent(e, touchType.touchUp, 0);
  };
  zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  addEventListeners = () => {
    document.addEventListener('mousedown', this.eventDown);
    document.addEventListener('touchstart', this.eventDown);

    document.addEventListener('mousemove', this.eventMove);
    document.addEventListener('touchmove', this.eventMove);

    document.addEventListener('mouseup', this.eventUp);
    document.addEventListener('touchend', this.eventUp);

    document.addEventListener('wheel', this.onwheelEvent);
    window.addEventListener('resize', this.onWindowResize, false);
  };
  closeGate = () => {
    backDiv.style.display = 'none';
    perfumeDiv.style.display = 'none';
    this.props.didSelectModel(undefined);
    this.imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        if (model.visible) this.close2D(model, 0.01);
      });
    this.imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        if (model.visible) this.close2D(model, 0.01);
      });
    this.isModelLive = false;
    this.loadNew(false, 0);
    this.transparent_Plane.visible = false;
  };

  selectedPerfumeBack = (no) => {
    backDiv.style.display = 'block';
    perfumeDiv.style.display = 'none';
    this.props.didSelectModel(undefined);
    for (let i = 0; i < this.imgPerfumes.length; i++) {
      const pos = this.getPerfumePosition(i);
      this.imgPerfumes[i].scale.set(0.51, 0.51, 0.51);
      this.imgPerfumes[i].position.set(pos.x, pos.y, pos.z);
      this.imgPerfumes[i].visible = true;
    }
    this.imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    this.isModelLive = false;
    this.loadNew(false, 0);
    this.transparent_Plane.visible = true;
    this.transparent_Plane.position.set(0, 1.5, -30);
    this.focus2DPerfume(
      this.imgPerfumes[no],
      0.51,
      this.getPerfumePosition(no)
    );
  };
  getPerfumePosition = (i) => {
    if (isMobileOrTab) {
      if (i < 4)
        return {
          x: -this.ImgDiffX * 1.5 + i * this.ImgDiffX,
          y: 5,
          z: -28
        };
      else
        return {
          x: -this.ImgDiffX + (i - 4) * this.ImgDiffX,
          y: -5,
          z: -28
        };
    } else {
      return { x: -this.ImgDiffX * 3 + i * this.ImgDiffX, y: 4, z: -28 };
    }
  };
  updateFocus2D = (no) => {
    backDiv.style.display = 'none';
    this.imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    this.imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    this.isModelLive = false;
    this.loadNew(false, 0);
    this.transparent_Plane.visible = true;
    this.transparent_Plane.position.set(0, 1.5, -30);
    switch (no) {
      case 0:
        for (let i = 0; i < this.imgPerfumes.length; i++) {
          this.imgPerfumes[i].scale.set(0.01, 0.01, 0.01);
          this.focus2DPerfume(
            this.imgPerfumes[i],
            0.51,
            this.getPerfumePosition(i)
          );
        }
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        this.imgLipstick[no - 1].scale.set(0.01, 0.01, 0.01);
        this.focus2D(this.imgLipstick[no - 1], 1);
        break;
    }
  };
  openObject = (no) => {
    backDiv.style.display = 'block';
    this.transparent_Plane.position.set(0, 1.5, -25);
    this.imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    this.imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    if (no < fragranceProducts.length) {
      for (let i = 0; i < this.imgPerfumes.length; i++) {
        const pos = this.getPerfumePosition(i);
        this.imgPerfumes[i].scale.set(0.51, 0.51, 0.51);
        this.imgPerfumes[i].position.set(pos.x, pos.y, pos.z);
        this.imgPerfumes[i].visible = true;
      }
      this.focusNew(this.imgPerfumes[no], this.focusPerfumePosition, 0.31);
      this.transparent_Plane.position.set(0, 1.5, -30);
      new TWEEN.Tween(this.transparent_Plane.position)
        .to({ z: -27 }, 500)
        .easing(TWEEN.Easing.Exponential.In)
        .start();
    } else if (no < fragranceProducts.length + 3) {
      this.imgLipstick[0].visible = true;
    } else if (no < fragranceProducts.length + 6) {
      this.imgLipstick[1].visible = true;
    }

    if (no >= fragranceProducts.length) {
      this.loadNew(false, 0);
      this.isModelLive = true;
      this.loadNew(true, no);
    }
  };
  onClickColor = (lipID, color) => {
    if (lipID === undefined || color === undefined) return;

    if (
      this.colorLipstick[color] === undefined ||
      lipstickJpColors[lipID] === undefined
    )
      return;
    if (lipstickJpColors[lipID][color] === undefined) return;
    this.changeColor(lipID, color);
    this.selectColorAnim(
      this.zoomModel,
      this.colorLipstick[color],
      lipstickJpColors[lipID][color]
    );
  };
  touchEvent = (e, type, sys) => {
    if (
      this.props.remoteState != undefined ||
      this.isLoading ||
      this.loadingCount < 10 ||
      this.isTweening
    )
      return;
    this.setTouchValues(e, type);
    if (type === touchType.touchUp && !this.zoomModel) {
      const lipstickVisible = this.imgLipstick.find((p) => p.visible === true);
      const PerfumesVisible = this.imgPerfumes.find((p) => p.visible === true);
      if (!PerfumesVisible && !lipstickVisible) {
        const clickedFocus = this.detectFirstTouchedObject(
          e,
          type,
          sys,
          this.itemsChildForCatchingCartClick
        );
        for (
          let i = 0;
          i < this.itemsChildForCatchingCartClick.length && clickedFocus;
          i++
        ) {
          if (this.itemsChildForCatchingCartClick[i] == clickedFocus.object) {
            if (i > 2) {
              const itemId = fragranceProducts.length + 3 + i;
              if (itemId > 0) {
                this.openObject(itemId);
                this.props.updateRemoteState({
                  flag: REMOTE_ACTION.FOCUS,
                  focusItem: itemId
                });
              }
            } else {
              this.updateFocus2D(i);
              this.props.updateRemoteState({
                flag: REMOTE_ACTION.CLICK_GATE_1,
                focusItem: i
              });
            }
            return;
          }
        }
      } else if (PerfumesVisible) {
        const clickedFocus = this.detectFirstTouchedObject(
          e,
          type,
          sys,
          this.imgPerfumes
        );
        const perfumesPos = this.imgPerfumes.find((p) => p.position.z > 1);
        for (let i = 0; i < this.imgPerfumes.length; i++) {
          if (perfumesPos) {
            const xPos =
              this.mouse.x * (window.innerWidth / window.innerHeight);
            if (this.mouse.y < 0.56 && xPos > 0.24 && this.mouse.y > -0.675) {
              this.selectedPerfumeBack(perfumesPos.ItemId);
              this.props.updateRemoteState({
                flag: REMOTE_ACTION.UN_FOCUS,
                focusItem: i
              });
            }
            return;
          } else if (this.imgPerfumes[i] === clickedFocus?.object) {
            this.openObject(i);
            this.props.updateRemoteState({
              flag: REMOTE_ACTION.FOCUS,
              focusItem: i
            });
            return;
          }
        }
        if (!clickedFocus && !perfumesPos) {
          this.closeGate();
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.CLOSE_GATE_1,
            focusItem: 1
          });
        }
      } else if (lipstickVisible?.ItemId === 0) {
        const xPos = this.mouse.x * (window.innerWidth / window.innerHeight);
        let itemId = -1;
        if (
          xPos < 0 &&
          xPos > -0.25 &&
          this.mouse.y < 0.5 &&
          this.mouse.y > -0.25
        ) {
          itemId = fragranceProducts.length;
        }
        if (
          xPos > 0 &&
          xPos < 0.25 &&
          this.mouse.y < 0.5 &&
          this.mouse.y > -0.25
        ) {
          itemId = fragranceProducts.length + 1;
        }

        if (itemId > 0) {
          this.openObject(itemId);
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.FOCUS,
            focusItem: itemId
          });
        } else {
          this.closeGate();
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.CLOSE_GATE_1,
            focusItem: 1
          });
        }
      } else if (lipstickVisible?.ItemId === 1) {
        const clickedFocus = this.detectFirstTouchedObject(e, type, sys, [
          this.itemsChildForCatchingModelClick[2],
          this.itemsChildForCatchingModelClick[3],
          this.itemsChildForCatchingModelClick[4]
        ]);

        for (
          let i = 0;
          i < this.itemsChildForCatchingModelClick.length && clickedFocus;
          i++
        ) {
          if (this.itemsChildForCatchingModelClick[i] == clickedFocus.object) {
            let itemId = -1;
            if (i > 1 && i < 5) {
              itemId =
                lipstickVisible?.ItemId * 3 + fragranceProducts.length + (i - 2);
            }
            if (itemId > 0) {
              this.openObject(itemId);
              this.props.updateRemoteState({
                flag: REMOTE_ACTION.FOCUS,
                focusItem: itemId
              });
            }
            return;
          }
        }
        if (!clickedFocus) {
          this.closeGate();
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.CLOSE_GATE_1,
            focusItem: 1
          });
        }
      }
    }
    if (type === touchType.touchUp && this.zoomModel) {
      if (this.zoomModel.getObjectByName(STRZOOMRAYCASTER)) {
        const clickedZoom = this.detectFirstTouchedObject(e, type, sys, [
          this.zoomModel.getObjectByName(STRZOOMRAYCASTER)
        ]);
        let clickedChooser = undefined;
        if (
          this.zoomModel?.ItemId &&
          this.zoomModel?.ItemId >= fragranceProducts.length
        ) {
          clickedChooser = this.detectFirstTouchedObject(
            e,
            type,
            sys,
            this.colorLipstick
          );
          const lipID = this.zoomModel.ItemId - fragranceProducts.length;
          if (
            clickedChooser &&
            this.mouseDownPos.x === this.mouse.x &&
            this.mouseDownPos.y == this.mouse.y
          ) {
            for (
              let i = 0;
              i < this.colorLipstick.length && clickedChooser;
              i++
            ) {
              if (
                this.colorLipstick[i] == clickedChooser.object &&
                this.colorLipstick[i].visible
              ) {
                this.onClickColor(lipID, i);
                this.props.updateRemoteState({
                  flag: REMOTE_ACTION.SEL_COLOR,
                  focuscolor: i,
                  focusItem: this.zoomModel.ItemId
                });
              }
            }
          }
        }
        if (
          !clickedZoom &&
          !clickedChooser &&
          this.mouseDownPos.x === this.mouse.x &&
          this.mouseDownPos.y == this.mouse.y &&
          this.mouse.y < 0.56 &&
          this.mouse.y > -0.675
        ) {
          this.doubleTapClick = 0;
          this.isModelLive = false;
          this.loadNew(false, 0);
          this.props.updateRemoteState({ flag: REMOTE_ACTION.UN_FOCUS });
          this.colorLipstick.forEach((element) => {
            element.visible = false;
          });
        }
      }
    }
    if (touchType.touchDown === type) {
      this.isDown = true;
    }
    if (touchType.touchUp === type) {
      this.isDown = false;
    }
    if (this.zoomModel != undefined && this.isDown == true) {
      this.updateRemoteObjectControlForModel();
    }
  };
  remoteObjectUpdate = (remoteState: ILoubiBeautyCartAnimationState) => {
    if (remoteState?.flag && this.loadingCount > 10) {
      if (remoteState.flag == REMOTE_ACTION.CLICK_GATE_1) {
        this.updateFocus2D(remoteState?.focusItem);
      }
      if (remoteState.flag == REMOTE_ACTION.CLOSE_GATE_1) {
        this.closeGate();
      }
      if (remoteState.flag == REMOTE_ACTION.FOCUS) {
        this.openObject(remoteState.focusItem);
      }
      if (remoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        this.isModelLive = false;
        this.colorLipstick?.forEach((element) => {
          element.visible = false;
        });
        this.loadNew(false, 0);
        const perfumesPos = this.imgPerfumes.find((p) => p.position.z > 1);
        if (perfumesPos) {
          this.selectedPerfumeBack(perfumesPos.ItemId);
        }
      }
      if (remoteState.flag == REMOTE_ACTION.SEL_COLOR) {
        if (remoteState?.focusItem && !this.zoomModel && !this.isModelLive) {
          this.openObject(remoteState.focusItem);
        }

        if (this.zoomModel) {
          const lipID = this.zoomModel?.ItemId - fragranceProducts.length;
          if (this.zoomModel.ItemId === remoteState?.focusItem) {
            this.onClickColor(lipID, remoteState?.focuscolor);
          }
        }
      }
      if (remoteState.flag == REMOTE_ACTION.MOUSE_CONTROL) {
        if (this.zoomModel) {
          this.zoomModel.position.set(
            remoteState.position[0],
            remoteState.position[1],
            remoteState.position[2]
          );
          this.zoomModel.scale.set(
            remoteState.scale[0],
            remoteState.scale[1],
            remoteState.scale[2]
          );
          this.zoomModel.rotation.set(
            remoteState.rotation[0],
            remoteState.rotation[1],
            remoteState.rotation[2]
          );
        }
      }
      remoteState.flag = undefined;
      remoteState = undefined;
    }
  };
  animate = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }

    if (this.loadingCount > 0) {
      this.loadingCount++;
    }

    TWEEN.update();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.animate);
  };
  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    let mobPosy = -0.6;
    if (window.innerHeight < window.innerWidth) {
      mobPosy = -0.3;
    }
    for (let i = 0; i < this.colorLipstick?.length && this.zoomModel; i++) {
      this.colorLipstick[i].position.y = isMobileOrTab ? mobPosy : -0.8;
    }
  };
  cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };

  cleanUp = () => {
    if (this.scene != undefined) {
      this.scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          this.cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material'])
            this.cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      this.scene.children.forEach((model) => {
        this.scene.remove(model);
      });
    }

    this.blackMattePackMap = this.cleanTexture(this.blackMattePackMap);
    this.blackMattePackNormal = this.cleanTexture(this.blackMattePackNormal);
    this.blackMattePackMetallic = this.cleanTexture(
      this.blackMattePackMetallic
    );
    this.blackShinyPackMap = this.cleanTexture(this.blackShinyPackMap);
    this.blackShinyPackNormal = this.cleanTexture(this.blackShinyPackNormal);
    this.blackShinyPackMetallic = this.cleanTexture(
      this.blackShinyPackMetallic
    );
    this.blackSnakeSkinPackMap = this.cleanTexture(this.blackSnakeSkinPackMap);
    this.blackSnakeSkinPackNormal = this.cleanTexture(
      this.blackSnakeSkinPackNormal
    );
    this.blackSnakeSkinPackMetallic = this.cleanTexture(
      this.blackSnakeSkinPackMetallic
    );
    this.goldenMattePackMap = this.cleanTexture(this.goldenMattePackMap);
    this.goldenMattePackNormal = this.cleanTexture(this.goldenMattePackNormal);
    this.goldenMattePackMetallic = this.cleanTexture(
      this.goldenMattePackMetallic
    );
    this.goldenSilkyPackMap = this.cleanTexture(this.goldenSilkyPackMap);
    this.goldenSilkyPackNormal = this.cleanTexture(this.goldenSilkyPackNormal);
    this.goldenSilkyPackMetallic = this.cleanTexture(
      this.goldenSilkyPackMetallic
    );
    this.goldenSnakeSkinPackMap = this.cleanTexture(
      this.goldenSnakeSkinPackMap
    );
    this.goldenSnakeSkinPackNormal = this.cleanTexture(
      this.goldenSnakeSkinPackNormal
    );
    this.goldenSnakeSkinPackMetallic = this.cleanTexture(
      this.goldenSnakeSkinPackMetallic
    );
    this.matteFluidMap = this.cleanTexture(this.matteFluidMap);
    this.matteFluidNormal = this.cleanTexture(this.matteFluidNormal);
    this.matteFluidMetallic = this.cleanTexture(this.matteFluidMetallic);
    this.metalMatteFluidMap = this.cleanTexture(this.metalMatteFluidMap);
    this.metalMatteFluidNormal = this.cleanTexture(this.metalMatteFluidNormal);
    this.metalMatteFluidMetallic = this.cleanTexture(
      this.metalMatteFluidMetallic
    );

    this.textureLipstick = [];
    this.imgLipstick = [];
    this.colorLipstick = [];
    this.itemsChildForCatchingCartClick = [];
    this.itemsChildForCatchingModelClick = [];

    if (this.renderer != undefined) {
      this.renderer.dispose();
      this.renderer && this.renderer.renderLists.dispose();
    }
    if (this.controls) {
      this.controls.disableVerticalRotation();
      this.controls.disableHorizontalRotation();
      this.controls.disableZoom();
    }
    document.removeEventListener('touchstart', this.eventDown);
    document.removeEventListener('touchmove', this.eventMove);
    document.removeEventListener('touchend', this.eventUp);
    document.removeEventListener('mousedown', this.eventDown);
    document.removeEventListener('mousemove', this.eventMove);
    document.removeEventListener('mouseup', this.eventUp);
    document.removeEventListener('wheel', this.onwheelEvent);
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('gesturestart', this.zoomDisable);
    document.removeEventListener('gesturechange', this.zoomDisable);
    document.removeEventListener('gestureend', this.zoomDisable);
    this.textureCart = undefined;
    this.controls = undefined;
    this.scene = undefined;
    this.renderer = undefined;
    this.camera = undefined;
  };
  onClickBack = () => {
    if (!this.props.viewOnly) {
      if (this.zoomModel) {
        const itemid = this.zoomModel.ItemId;
        this.doubleTapClick = 0;
        this.isModelLive = false;
        this.loadNew(false, 0);
        this.props.updateRemoteState({
          flag: REMOTE_ACTION.UN_FOCUS,
          focusItem: itemid
        });
        this.colorLipstick.forEach((element) => {
          element.visible = false;
        });
      } else {
        const perfumesPos = this.imgPerfumes.find((p) => p.position.z > 1);
        if (perfumesPos) {
          this.selectedPerfumeBack(perfumesPos.ItemId);
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.UN_FOCUS,
            focusItem: perfumesPos.ItemId
          });
        } else {
          this.closeGate();
          this.props.updateRemoteState({
            flag: REMOTE_ACTION.CLOSE_GATE_1,
            focusItem: 1
          });
        }
      }
    }
  };
  init = () => {
    this.loadEnvironment();
    this.load2D();
    if (!this.props.viewOnly) {
      this.addEventListeners();
    }
    this.animate();
  };
  render() {
    return (
      <div>
        <canvas id="c"></canvas>
        <p id="topright">
          <LJBackToAnimationButton
            onClick={this.onClickBack}
            position="top-right"
          />
        </p>
        <span id="centertxt">
          <h1>
            {this.state.strPerfume?.split('\n').map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </h1>
        </span>
        <style jsx>
          {`
            div {
              background: url(${`${assetBaseUrl}/loubiairways/luggage-animation/beautycart/background/v2/beauty_cart_background.png`})
                no-repeat;
              background-position: center center;
              background-size: cover;
              background-attachment: fixed;
            }
            #c,
            div {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }
            a {
              position: fixed;
              top: 2vh;
              left: 2vh;
              font-size: 0.81em;
              text-align: left;
            }

            #centertxt {
              position: fixed;
              left: 50%;
              bottom: 145px;
              transform: translateX(-50%);
              margin: 0 auto;
            }
            #centertxt h1 {
              font-family: 'AntiqueOlive-Bold', Arial;
              color: white;
              font-size: 1em;
              text-align: center;
            }
            #centertxt h1 p {
              margin-bottom: 10px;
              font-size: 14px;
            }
            #centertxt h1 p:first-child {
              font-style: italic;
              font-size: 21px;
            }

            @media (max-width: 768px) {
              #centertxt {
                bottom: 70px;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

export default BeautyCart;

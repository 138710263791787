import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { IMainState } from '../../../../interfaces';
import {
  actionMuteBgSound,
  actionUnmuteBgSound
} from '../../../../redux/actions';
import {
  DID_MUTE_BACKGROUND_SOUND,
  DID_UNMUTE_BACKGROUND_SOUND
} from '../../../../utils/constants';
import { isUserOniOS } from '../../../../utils/deviceDetector';
import { useLoubiAirwaysState } from '../../../hooks/loubiAirways';
import MuteButton from './Buttons/MuteButton';

const LoubiAirwaysBackgroundMusic = () => {
  const audioRef = React.createRef<HTMLAudioElement>();
  const backgroundMusicUrl =
    'https://panos2.inspify.io/virtual_boutique/0e6ce77d-1e03-40cd-a88e-e0aaf2d00769/background_music/v1/loubiairways_background_music.mp3';

  const { muteBackgroundSound } = useSelector(
    (state: IMainState) => state.clientState.vb || {}
  );

  const hideLandingVideo = useLoubiAirwaysState()?.landingVideo?.hidden;
  const showSpotifyPlayer = useLoubiAirwaysState()?.showSpotifyPlayer;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isUserOniOS()) {
      dispatch(actionMuteBgSound());
    }
  }, []);

  React.useEffect(() => {
    if (!hideLandingVideo) {
      audioRef.current?.pause();
      return;
    }
    if (audioRef.current) {
      audioRef.current.volume = 0.1;
    }
    muteBackgroundSound ? audioRef.current?.pause() : audioRef.current?.play();
  }, [muteBackgroundSound, hideLandingVideo]);

  return (
    <>
      <audio ref={audioRef} loop autoPlay={false}>
        <source src={backgroundMusicUrl} type="audio/mpeg" />
      </audio>
      <MuteButton
        onClick={() => {
          if (!muteBackgroundSound) {
            audioRef.current?.pause();
            dispatch(actionMuteBgSound());
            logEvent(DID_MUTE_BACKGROUND_SOUND);
          } else {
            audioRef.current?.play();
            dispatch(actionUnmuteBgSound());
            logEvent(DID_UNMUTE_BACKGROUND_SOUND);
          }
        }}
        mute={muteBackgroundSound}
        showSpotifyPlayer={showSpotifyPlayer}
      />
    </>
  );
};

export default LoubiAirwaysBackgroundMusic;

import { handleCommandFromUE } from './../Chopard/CHCommandHandler';
import { chopardHappySportGenevaHQ, chopardHappySportKorea } from './../../../config/index';
import { AnyAction, Dispatch } from 'redux';

export const psToWebCommand = ({
  dispatch,
  message,
  storeId,
  lang,
  viewOnly
}: {
  dispatch: Dispatch<AnyAction>;
  message: string;
  storeId: string;
  lang: string;
  viewOnly?: boolean;
}) => {
  if (
    storeId === chopardHappySportKorea ||
    storeId === chopardHappySportGenevaHQ
  ) {
    handleCommandFromUE(dispatch, message, lang, viewOnly,storeId);
  }
};

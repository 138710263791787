import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import {
  ContactMethod,
  DeliveryMethod,
  IAddressForm,
  IMainState,
  ICheckoutFormData,
  IVirtualBoutiqueShopContact,
  CheckoutStep
} from '../../interfaces';
import {
  actionCartUpdateCheckoutStep,
  actionCartUpdateCheckoutFormData
} from '../../redux/actions';
import {
  DID_CHECK_CHECKOUT_TERMS,
  DID_CHECK_GIFT_WRAPPING,
  DID_CLICK_FORM_SUBMIT_BUTTON,
  DID_INPUT_FORM_FIELD,
  DID_OPEN_FORM,
  DID_SELECT_CONTACT_METHOD,
  DID_SELECT_DELIVERY_METHOD
} from '../../utils/constants';
import PoweredByInspify from '../Legal/PoweredByInspify';
import { getStoreTermsAndConditions } from '../storeComponentFactory';
import {
  isAllowedForEmail,
  isAllowedForName,
  isAllowedForPhone,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from '../VirtualBoutique/Appointment/inputValidator';
import IWCDubaiAddressForm from './IWCDubaiAddressForm';

interface IShoppingFormPristine {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phone: boolean;
  addressText: boolean;
  address: boolean;
  billingAddress: boolean;
  preferredDelivery: boolean;
  preferredContact: boolean;
  acceptedTermsOfUse: boolean;
}

export interface IShoppingFormProps {
  shopContacts: IVirtualBoutiqueShopContact[];
  phoneCountryCode: string;
  deliveryFee: number;
}

const IWCDubaiCheckoutForm = ({
  shopContacts,
  phoneCountryCode
}: IShoppingFormProps) => {
  const dispatch = useDispatch();
  const deliveryContact = shopContacts.find(
    (c) => c.deliveryMethod === DeliveryMethod.DELIVERY
  );
  const pickupContact = shopContacts.filter(
    (c) => c.deliveryMethod === DeliveryMethod.PICKUP
  );

  const { t } = useTranslation();
  const [pristine, setPristine] = React.useState<IShoppingFormPristine>({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    addressText: true,
    address: true,
    billingAddress: true,
    preferredDelivery: true,
    preferredContact: true,
    acceptedTermsOfUse: true
  });
  const [isAddressValid, setIsAddressValid] = React.useState(false);
  const [isBillingAddressValid, setIsBillingAddressValid] =
    React.useState(false);

  const shoppingCartState = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const formData = shoppingCartState?.checkoutFormData || {};

  const setFormData = (data: ICheckoutFormData) => {
    dispatch(actionCartUpdateCheckoutFormData(data));
  };

  const mandatoryFields: (keyof ICheckoutFormData)[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'preferredDelivery',
    'preferredContact',
    'acceptedTermsOfUse'
  ];

  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, firstName: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'firstName',
      form: 'IWCDubaiCheckoutForm'
    });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, firstName: value });
    }
  };

  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, lastName: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'lastName',
      form: 'IWCDubaiCheckoutForm'
    });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, lastName: value });
    }
  };

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'phone',
      form: 'IWCDubaiCheckoutForm'
    });
    if (isAllowedForPhone(value)) {
      setFormData({ ...formData, phone: value });
    }
  };

  const handleGiftNoteChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'giftNote',
      form: 'IWCDubaiCheckoutForm'
    });
    setFormData({ ...formData, giftNote: value });
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'email',
      form: 'IWCDubaiCheckoutForm'
    });
    if (isAllowedForEmail(value)) {
      setFormData({ ...formData, email: value });
    }
  };

  const handleContactMethod = (preferredContact: ContactMethod) => {
    setPristine({ ...pristine, preferredContact: false });
    setFormData({ ...formData, preferredContact });
    logEvent(DID_SELECT_CONTACT_METHOD, DID_SELECT_CONTACT_METHOD, {
      preferredContact
    });
  };

  const isDelivery = formData?.preferredDelivery === DeliveryMethod.DELIVERY;
  const isPickup = formData?.preferredDelivery === DeliveryMethod.PICKUP;

  const handleDeliveryMethod = (
    preferredDelivery: DeliveryMethod,
    id: string
  ) => {
    setPristine({ ...pristine, preferredDelivery: false });
    const contact = shopContacts.find((c) => c.id === id);

    logEvent(DID_SELECT_DELIVERY_METHOD, DID_SELECT_DELIVERY_METHOD, {
      preferredDelivery
    });

    if (preferredDelivery === DeliveryMethod.DELIVERY && !formData?.address) {
      setFormData({ ...formData, preferredDelivery, shopNowContact: null });
    } else {
      setFormData({ ...formData, preferredDelivery, shopNowContact: contact });
    }
  };

  const handleAddressForm = (address: IAddressForm) => {
    setPristine({ ...pristine, address: false });
    setFormData({
      ...formData,
      addressForm: address,
      shopNowContact: !isEmpty(address) ? deliveryContact : null
    });
  };

  const handleBillingAddressForm = (address: IAddressForm) => {
    setPristine({ ...pristine, billingAddress: false });
    setFormData({
      ...formData,
      billingAddressForm: address
    });
  };

  const handleGiftWrapping = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setFormData({ ...formData, giftWrapping: value });
    logEvent(DID_CHECK_GIFT_WRAPPING, DID_CHECK_GIFT_WRAPPING, {
      checked: value
    });
  };

  const handleBillingAddressCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({ ...formData, useShippingAsBillingAddress: value });
    logEvent(DID_CHECK_GIFT_WRAPPING, DID_CHECK_GIFT_WRAPPING, {
      checked: value
    });
  };

  const handleAcceptedTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
    logEvent(DID_CHECK_CHECKOUT_TERMS, DID_CHECK_CHECKOUT_TERMS, {
      checked: value
    });
  };

  const handleSalutionSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event?.target;
    setFormData({ ...formData, salutation: value });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'salutation',
      form: 'IWCDubaiCheckoutForm'
    });
  };

  const removePristine = () => {
    const nonPristine: IShoppingFormPristine = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      addressText: false,
      address: false,
      billingAddress: false,
      preferredDelivery: false,
      preferredContact: false,
      acceptedTermsOfUse: false
    };
    setPristine(nonPristine);
  };

  const handleCheckout = () => {
    removePristine();
    if (
      mandatoryFields.some((field) => !formData?.[field]) ||
      (formData.preferredDelivery === DeliveryMethod.DELIVERY &&
        !isAddressValid) ||
      (!formData?.useShippingAsBillingAddress && !isBillingAddressValid)
    ) {
      return;
    }

    if (
      !isValidName(formData?.firstName) ||
      !isValidName(formData?.lastName) ||
      !isValidEmail(formData?.email) ||
      !isValidPhoneNumber(formData?.phone)
    ) {
      return;
    }

    logEvent(DID_CLICK_FORM_SUBMIT_BUTTON, DID_CLICK_FORM_SUBMIT_BUTTON, {
      form: 'IWCDubaiCheckoutForm'
    });
    dispatch(actionCartUpdateCheckoutStep(CheckoutStep.PAYMENT));
  };

  const invalidEmail = !pristine.email && !isValidEmail(formData?.email);
  const invalidPhone = !pristine.phone && !isValidPhoneNumber(formData?.phone);
  const invalidFirstName =
    !pristine.firstName && !isValidName(formData?.firstName);
  const invalidLastName =
    !pristine.lastName && !isValidName(formData?.lastName);

  React.useEffect(() => {
    const currentPreferredContact = formData?.preferredContact;
    if (
      invalidPhone &&
      (currentPreferredContact === ContactMethod.TEXT ||
        currentPreferredContact === ContactMethod.CALL)
    ) {
      setFormData({ ...formData, preferredContact: null });
    }

    if (invalidEmail && currentPreferredContact === ContactMethod.EMAIL) {
      setFormData({ ...formData, preferredContact: null });
    }
  }, [formData?.phone, formData?.email]);

  React.useEffect(() => {
    logEvent(DID_OPEN_FORM, DID_OPEN_FORM, {
      form: 'IWCDubaiCheckoutForm'
    });
    if (!formData?.phoneCountryCode) {
      setFormData({
        ...formData,
        phoneCountryCode: phoneCountryCode
      });
    }
  }, []);

  return (
    <div className="CartForm">
      <h2 className="text-center">{t('vb:delivery_address')}</h2>
      <form id="emailform">
        <div className="two-col">
          <div className="input-group">
            <label>{t('vb:first_name')}:</label>
            <div className="phone-number-input no-ltr">
              <select
                className="salutation-select"
                value={formData?.salutation}
                onChange={handleSalutionSelect}
              >
                <option value="vb:mr">{t('vb:mr')}</option>
                <option value="vb:mrs">{t('vb:mrs')}</option>
                <option value="vb:ms">{t('vb:ms')}</option>
              </select>
              <input
                type="text"
                id="inputFirstName"
                name="firstName"
                value={formData?.firstName || ''}
                placeholder={t('vb:enter_your_first_name')}
                onChange={handleFirstName}
              />
            </div>
            {invalidFirstName && (
              <span className="error"> {t('vb:required')}</span>
            )}
          </div>{' '}
          <div className="input-group">
            <label>{t('vb:last_name')}:</label>
            <input
              type="text"
              id="inputLastName"
              name="lastName"
              value={formData?.lastName || ''}
              placeholder={t('vb:enter_your_last_name')}
              onChange={handleLastName}
            />
            {invalidLastName && (
              <span className="error"> {t('vb:required')}</span>
            )}
          </div>
        </div>
        <div className="two-col">
          <div className="input-group">
            <label>{t('vb:phone_number')}:</label>
            <div className="phone-number-input">
              <input
                type="tel"
                id="inputcountrycode"
                name="countrycode"
                placeholder={formData?.phoneCountryCode}
                disabled
              />
              <input
                type="tel"
                id="inputphone"
                name="phone"
                placeholder={t('vb:enter_phone_number')}
                value={formData?.phone || ''}
                onChange={handlePhoneNumber}
              />
            </div>

            {invalidPhone && (
              <span className="error">
                {' '}
                {t('vb:please_enter_valid_phone_number')}
              </span>
            )}
          </div>
          <div className="input-group">
            <label>{t('email')}:</label>
            <input
              type="email"
              id="inputemail"
              name="email"
              placeholder={t('vb:enter_your_email')}
              value={formData?.email || ''}
              onChange={handleEmail}
            />
            {invalidEmail && (
              <span className="error"> {t('vb:please_enter_valid_email')}</span>
            )}
          </div>
        </div>
        <div className="input-group preferred-contact">
          <label>{t('vb:preferred_contact')}:</label>
          <div className="btn-group btn-options">
            <button
              type="button"
              className={`btn ${
                formData?.preferredContact === ContactMethod.TEXT
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleContactMethod(ContactMethod.TEXT)}
              disabled={invalidPhone}
            >
              {t('vb:sms_whatsapp')}
            </button>
            <button
              type="button"
              className={`btn ${
                formData?.preferredContact === ContactMethod.CALL
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleContactMethod(ContactMethod.CALL)}
              disabled={invalidPhone}
            >
              {t('vb:call')}
            </button>
            <button
              type="button"
              className={`btn ${
                formData?.preferredContact === ContactMethod.EMAIL
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleContactMethod(ContactMethod.EMAIL)}
              disabled={invalidEmail}
            >
              {t('email')}
            </button>
          </div>
          {!pristine.preferredContact && !formData?.preferredContact && (
            <span className="error">{t('vb:required')}</span>
          )}
        </div>
        <div className="input-group ">
          <ul className="list-group delivery-method">
            <Fragment>
              <label>{t('vb:preferred_delivery')}:</label>
              {(isDelivery || !formData?.preferredDelivery) && (
                <li
                  className={`list-group-item btn btn-outline-secondary over-ride-secondary ${
                    isDelivery && 'disable-click'
                  }`}
                  onClick={() =>
                    handleDeliveryMethod(
                      DeliveryMethod.DELIVERY,
                      deliveryContact.id
                    )
                  }
                >
                  {formData?.preferredDelivery === DeliveryMethod.DELIVERY ? (
                    <IWCDubaiAddressForm
                      formData={formData?.addressForm}
                      setIsAddressValid={setIsAddressValid}
                      isPristine={pristine.address}
                      handleAddress={handleAddressForm}
                    />
                  ) : (
                    t('vb:delivery')
                  )}
                </li>
              )}
              {pickupContact?.length > 0 &&
                pickupContact.map((c) => {
                  const isSelected =
                    formData?.shopNowContact?.id === c.id && isPickup;
                  if (isSelected || !formData?.preferredDelivery) {
                    return (
                      <li
                        key={c.id}
                        className={`list-group-item btn ${
                          isSelected
                            ? 'btn-secondary active'
                            : ' btn-outline-secondary'
                        }`}
                        onClick={() =>
                          handleDeliveryMethod(DeliveryMethod.PICKUP, c.id)
                        }
                      >
                        {t('vb:pickup_at')}{' '}
                        {c.nameKey ? t(`vb:${c.nameKey}`) : c.name}
                      </li>
                    );
                  }
                })}
            </Fragment>
          </ul>
          {!pristine.preferredDelivery &&
            (!formData?.shopNowContact?.id || !formData?.preferredDelivery) && (
              <span className="error">
                {!formData?.preferredDelivery && t('vb:required')}
              </span>
            )}
        </div>
        {formData?.preferredDelivery === DeliveryMethod.DELIVERY && (
          <div
            className={`input-group tstc ${
              !formData.useShippingAsBillingAddress && 'mb-1'
            }`}
          >
            <label>
              <input
                type="checkbox"
                id="inputtstc"
                onChange={handleBillingAddressCheck}
                checked={formData.useShippingAsBillingAddress || false}
              />
              {t('vb:use_shipping_as_billing_address')}
            </label>
          </div>
        )}

        {!formData?.useShippingAsBillingAddress && (
          <div className="input-group">
            <ul className="list-group delivery-method">
              <label>{t('vb:billing_address')}:</label>
              <li className="list-group-item">
                <IWCDubaiAddressForm
                  formData={formData?.billingAddressForm}
                  setIsAddressValid={setIsBillingAddressValid}
                  isPristine={pristine.billingAddress}
                  handleAddress={handleBillingAddressForm}
                />
              </li>
            </ul>
          </div>
        )}

        <div className="input-group tstc">
          <label>
            <input
              type="checkbox"
              id="inputtstc"
              onChange={handleGiftWrapping}
              checked={formData?.giftWrapping || false}
            />
            {t('vb:gift_wrapping')}
          </label>
        </div>
        {formData?.giftWrapping && (
          <div className="input-group">
            <label>{t('vb:gift_note')}</label>
            <textarea
              name="giftNote"
              id="inputaddress"
              rows={5}
              placeholder={t('vb:gift_note')}
              value={formData?.giftNote || ''}
              onChange={handleGiftNoteChange}
            />
          </div>
        )}
        <div className="input-group tstc">
          <label>
            <input
              type="checkbox"
              id="inputtstc"
              onChange={handleAcceptedTerms}
              checked={formData?.acceptedTermsOfUse || false}
            />
            {!pristine.acceptedTermsOfUse && !formData?.acceptedTermsOfUse && (
              <span className="error" style={{ top: '-8px' }}>
                {t('vb:please_accept_tc')}
              </span>
            )}
            {getStoreTermsAndConditions(
              '316261f4-fbf6-49e2-88cf-e4d166f79267',
              t
            )}
          </label>
        </div>
        <div className="input-group">
          <button
            id="sendform"
            type="button"
            className="checkout-button"
            onClick={() => handleCheckout()}
          >
            {t('vb:checkout')}
          </button>
        </div>
      </form>

      <PoweredByInspify />

      <style jsx global>
        {`
          .isRtl .delivery-method {
            padding-inline-start: 0 !important;
          }
          .isRtl .delivery-method .btn,
          .isRtl .delivery-method label {
            text-align: right !important;
          }

          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }

          .salutation-select {
            width: 100px !important;
            margin-right: 10px;
          }
          .isRtl .no-ltr {
            direction: rtl;
          }

          #inputemail,
          #inputphone {
            text-align: left !important;
            direction: ltr !important;
          }
          .over-ride-secondary:hover {
            color: #6c757d;
            background-color: #fff;
            border-color: #ccc;
          }
          .over-ride-secondary.disable-click {
            cursor: default;
          }
          .preferred-contact .btn {
            text-transform: capitalize;
          }
          .payment-method p {
            font-size: 1em;
            margin-bottom: 0;
          }

          .delivery-note p {
            font-size: 0.7em;
            margin-top: 10px;
            margin-bottom: 0;
          }
          :global(.isRtl) .delivery-note p,
          :global(.isRtl) .payment-method p {
            text-align: right !important;
            display: block;
            width: 100%;
          }
          textarea {
            background: #fff;
          }
          .full {
            display: inline-block;
          }
          .btn-options,
          .delivery-method {
            width: 100%;
          }
          .delivery-method .btn {
            text-align: left;
          }
          .btn-outline-secondary {
            border-color: #ccc;
          }
          .btn {
            border-radius: 0;
          }
          :global(.sub-tc) {
            display: block;
            margin-left: 1em;
            text-indent: -0.6em;
            padding-left: 1em;
            margin-top: 8px;
          }
          :global(.isRtl .sub-tc) {
            margin-right: 1em;
            margin-left: 0;
          }
          .input-group {
            margin-bottom: 20px;
          }
          :global(.appointment label) {
            margin-top: 0;
          }
          .two-col .error {
            top: 0;
          }

          .checkout-button {
            width: 40% !important;
            align-self: center;
            padding: 0 30px;
            align-self: center;
            margin: auto;
          }

          @media (max-width: 768px) {
            .full {
              display: none;
            }

            .tstc {
              margin-top: 20px;
            }

            .checkout-button {
              width: 100% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default IWCDubaiCheckoutForm;

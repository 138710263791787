import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import { AppointmentType } from '../../../../../interfaces';
import { actionAppointmentPopup } from '../../../../../redux/actions';
import { DID_CLICK_APPOINTMENT } from '../../../../../utils/constants';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiButtonWrapper from '../../LouboutinCustomComponent/Buttons/LoubiButtonWrapper';

const LJCalendarButton = () => {
  const dispatch = useDispatch();
  const { showSpotifyPlayer } = useLoubiAirwaysState();
  return (
    <LoubiButtonWrapper>
      <img
        src={`${jpIconUrlBase}/appointment_white.svg`}
        alt="Appointment"
        onClick={() => {
          const payload = {
            open: true,
            type: AppointmentType.VIRTUAL_WALKTHROUGH
          };
          dispatch(actionAppointmentPopup(payload));
          logEvent(DID_CLICK_APPOINTMENT, DID_CLICK_APPOINTMENT, payload);
        }}
        className={`loubi-circle-button bottom-fourth-right ${
          showSpotifyPlayer ? 'spotify' : ''
        }`}
      />
    </LoubiButtonWrapper>
  );
};

export default LJCalendarButton;

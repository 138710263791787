import React from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { EmailIcon } from 'react-share';
import { logEvent } from '../../../analytics';
import { DID_CLICK_ENQUIRE_NOW } from '../../../utils/constants';
import * as translation from '../../../i18n';

export interface EnquireNowContact {
  email: string;
  name: string;
  nameKey?: string;
  subject?: string;
  cc?: string;
  bcc?: string;
}

interface IEnquireButton {
  contacts: EnquireNowContact[];
  labelTextKey?: string;
}

export const getHrefForContact = (contact: EnquireNowContact) =>
  `mailto:${contact.email}?${contact.cc ? `cc=${contact.cc}&` : ``}${
    contact.bcc ? `bcc=${contact.bcc}&` : ``
  }subject=${contact.subject ? encodeURIComponent(contact.subject) : ''}`;

const EnquireButton = ({ contacts, labelTextKey }: IEnquireButton) => {
  const logAnalytics = (contact: EnquireNowContact) => {
    logEvent(DID_CLICK_ENQUIRE_NOW, contact.email, contact);
  };
  const { t } = translation.useTranslation();

  if (contacts.length < 1) return null;

  const multiContactDropdown = (
    <DropdownButton
      className="btn-action"
      variant="outline-dark"
      id="boutique-dropdown-button"
      title={
        <>
          {' '}
          <EmailIcon
            bgStyle={{ fill: '#343740' }}
            size={26}
            round
            crossOrigin="anonymous"
          />
          {` `}
          {t(labelTextKey || 'email_enquiry')}
        </>
      }
    >
      {contacts.map((contact) => (
        <Dropdown.Item
          key={contact.name}
          target="_blank"
          href={getHrefForContact(contact)}
          onClick={() => logAnalytics(contact)}
        >
          {contact.nameKey ? t(`vb:${contact.nameKey}`) : contact.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );

  const singleContactButton = (
    <>
      <Button
        className="btn-action"
        variant="outline-dark"
        onClick={() => logAnalytics(contacts[0])}
      >
        <a target="_blank" rel="noopener" href={getHrefForContact(contacts[0])}>
          {' '}
          <EmailIcon
            bgStyle={{ fill: '#343740' }}
            size={26}
            round
            crossOrigin="anonymous"
          />{' '}
          {t(labelTextKey || 'email_enquiry')}
        </a>
      </Button>
      <style jsx>
        {`
          a {
            color: inherit;
          }
          a:hover {
            text-decoration: none;
          }
          p {
            margin: 10px 0;
            font-style: italic;
            color: #4c4c4c;
            font-size: 14px;
          }
        `}
      </style>
    </>
  );

  return contacts.length === 1 ? singleContactButton : multiContactDropdown;
};

export default EnquireButton;

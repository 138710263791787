import React from 'react';
import { JitsiLocalTrack } from '../../../interfaces';
import { useTranslation } from '../../../i18n';
import { transformDefaultDeviceLabel } from '../utils/track';
import { IconMeter } from '../MeetingControls/MeetingIcons';
export const Meter = ({
  className,
  isDisabled,
  level
}: {
  className: string;
  isDisabled: boolean;
  level: number;
}) => {
  let ownClassName;

  if (level > -1 && !isDisabled) {
    ownClassName = `metr unmuted`;
  } else {
    ownClassName = `metr muted`;
  }

  return (
    <>
      <div className={`${ownClassName} ${className}`}>
        <IconMeter />
      </div>
      <style jsx>{`
        ::global(.metr svg) {
          width: auto !important;
        }
        .meter-mic {
          color: #5e6d7a;
          margin-left: auto;
        }
        .metr.muted ::global(rect) {
          fill: currentColor;
        }
        .metr.unmuted ::global(rect) {
          fill: #31b76a;
        }
        .metr ::global(rect:nth-child(${level + 1}) ~ rect) {
          fill: currentColor;
        }
      `}</style>
    </>
  );
};

const Microphone = ({
  deviceId,
  isActive,
  hasError,
  jitsiTrack,
  label,
  onClick,
  disabled
}: {
  deviceId: string;
  isActive: boolean;
  label: string;
  jitsiTrack: JitsiLocalTrack;
  hasError: boolean;
  onClick: (deviceId: string) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const [level, setLevel] = React.useState(-1);
  const updateLevel = (num) => {
    const newLevel = Math.floor(num / 0.125);
    setLevel(newLevel);
  };
  const startListening = () => {
    if (jitsiTrack) {
      jitsiTrack.on(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        updateLevel
      );
    }
  };

  const stopListening = () => {
    if (jitsiTrack) {
      jitsiTrack.off(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        updateLevel
      );
      setLevel(-1);
    }
  };

  React.useEffect(() => {
    startListening();
    return stopListening;
  }, []);

  const transformedLabel = transformDefaultDeviceLabel(deviceId, label, t);
  const handleOnClick = () => {
    if (disabled) return;
    onClick(deviceId);
  };
  return (
    <div
      className={`list-group-item list-group-item-action ${
        isActive ? 'list-group-item-secondary' : ''
      }`}
      onClick={handleOnClick}
    >
      <span className="label">{transformedLabel}</span>
      {Boolean(jitsiTrack) && (
        <Meter level={level} className="meter-mic" isDisabled={hasError} />
      )}

      <style jsx>
        {`
          .list-group-item {
            gap: 5px;
          }
          .list-group-item-secondary {
            background-color: #efefef;
          }
        `}
      </style>
    </div>
  );
};

export default Microphone;

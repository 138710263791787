import React from 'react';
import { Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../analytics';
import { actionDidStartPlayingFullScreenVideo } from '../../redux/actions';
import {
  DID_START_FULL_SCREEN_VIDEO,
  VIDEO_ENDED,
  VIDEO_ERROR,
  VIDEO_PAUSED,
  VIDEO_PLAYED
} from '../../utils/constants';
import { useUpdateVideoUrlForIOSInMeeting } from '../hooks/meeting';
import { handleRetryPlayVideo, setAudioOutputDevice } from '../../utils/video';
import CSRComponentWrapper from '../CSRComponentWrapper';

export const PureFullScreenVideo = ({
  url,
  state,
  loop,
  playWithSound,
  setPlayWithSound,
  onEnded,
  children,
  className = '',
  activeAudioOutput,
  fullSize
}: {
  url: string;
  state: string;
  loop?: boolean;
  playWithSound?: boolean;
  setPlayWithSound?: (playWithSound: boolean) => void;
  onEnded?: () => void;
  children?: React.ReactNode;
  className?: string;
  activeAudioOutput?: string;
  fullSize?: boolean;
}) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);
  const [buffering, setBuffering] = React.useState(false);
  const [didStart, setDidStart] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const playerRef = React.useRef<ReactPlayer>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (state === 'entered' && ready) {
      setIsPlaying(true);
      dispatch(actionDidStartPlayingFullScreenVideo(url));
    }
  }, [state, url, ready]);

  const onStart = () => {
    logEvent(DID_START_FULL_SCREEN_VIDEO, DID_START_FULL_SCREEN_VIDEO, {
      videoUrl: url
    });
    const videoElement = playerRef?.current?.getInternalPlayer();
    setAudioOutputDevice(videoElement, activeAudioOutput);
  };

  const handleError = (e, data, hlsInstance, hlsGlobal) => {
    console.log('video error', e, data);
    setIsPlaying(false);
    setBuffering(false);
    if (e !== 'hlsError') {
      setPlayWithSound?.(false);
    }
    if (retryCount < 20) {
      const currentRetry = retryCount + 1;
      setTimeout(() => {
        setRetryCount(currentRetry);
        handleRetryPlayVideo({
          error: e,
          data,
          hlsInstance,
          hlsGlobal,
          videoUrl: url,
          retryCount: currentRetry,
          onRetry: () => {
            setIsPlaying(true);
          }
        });
      }, 100);
    } else {
      logEvent(VIDEO_ERROR, VIDEO_ERROR, {
        error: e,
        details: data,
        videoUrl: url,
        retryCount
      });
      onEnded();
    }
  };

  React.useEffect(() => {
    if (didStart && activeAudioOutput && playerRef?.current) {
      onStart();
    }
  }, [didStart, activeAudioOutput, playerRef?.current]);

  return (
    <>
      <div className={`full-screen-video ${state} ${className}`}>
        {url && (
          <CSRComponentWrapper>
            <ReactPlayer
              playing={isPlaying}
              ref={playerRef}
              width="100%"
              height="100%"
              autoPlay
              playsinline
              muted={
                !playWithSound || state === 'exited' || state === 'exiting'
              }
              url={url}
              loop={loop}
              onBuffer={() => setBuffering(true)}
              onBufferEnd={() => setBuffering(false)}
              onProgress={() => setBuffering(false)}
              onStart={() => setDidStart(true)}
              onPlay={() => {
                logEvent(VIDEO_PLAYED, VIDEO_PLAYED, {
                  videoUrl: url
                });
              }}
              onPause={() => {
                logEvent(VIDEO_PAUSED, VIDEO_PAUSED, {
                  videoUrl: url
                });
              }}
              onEnded={() => {
                logEvent(VIDEO_ENDED, VIDEO_ENDED, {
                  videoUrl: url
                });
                onEnded?.();
              }}
              onError={handleError}
              onDuration={() => {
                setReady(true);
              }}
              config={{
                file: {
                  hlsOptions: {
                    startFragPrefetch: true
                  }
                }
              }}
            />
          </CSRComponentWrapper>
        )}
        {children}
      </div>
      {buffering && (
        <div className="loading-placeholder">
          <Spinner animation="border" />
        </div>
      )}
      <style jsx>{`
        .full-screen-video {
          z-index: -1;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background: black;
          display: flex;
          justify-content: center;
          align-item: center;
          flex-direction: column;
          opacity: 1;
          transition: all 0.5s ease-in-out;
        }

        .full-screen-video.entering {
          z-index: 2002;
          opacity: 0;
        }
        .full-screen-video.entered {
          z-index: 2002;
          opacity: 1;
        }
        .full-screen-video.exiting {
          z-index: 2002;
          opacity: 0;
        }
        .full-screen-video.exited {
          z-index: -1;
          opacity: 0;
        }

        .full-screen-video :global(video) {
          width: 100%;
          object-fit: ${fullSize ? 'cover' : 'contain'};
        }

        .loading-placeholder {
          z-index: -1;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f1f1f1;
        }
        .entering + .loading-placeholder,
        .entered + .loading-placeholder {
          z-index: 2004;
        }
      `}</style>
    </>
  );
};

const FullScreenVideo = ({
  url,
  show,
  loop,
  playWithSound,
  setPlayWithSound,
  onEnded,
  children,
  className = '',
  activeAudioOutput,
  fullSize
}: {
  url: string;
  show: boolean;
  loop?: boolean;
  playWithSound?: boolean;
  setPlayWithSound?: (playWithSound: boolean) => void;
  onEnded?: () => void;
  children?: React.ReactNode;
  className?: string;
  activeAudioOutput?: string;
  fullSize?: boolean;
}) => {
  const updatedUrl = useUpdateVideoUrlForIOSInMeeting(url);
  return (
    <Transition in={show} timeout={500}>
      {(state) => (
        <PureFullScreenVideo
          url={updatedUrl}
          loop={loop}
          state={state}
          playWithSound={playWithSound}
          setPlayWithSound={setPlayWithSound}
          onEnded={onEnded}
          className={className}
          activeAudioOutput={activeAudioOutput}
          fullSize={fullSize}
        >
          <CSRComponentWrapper onRenderFailed={() => onEnded?.()}>
            {children}
          </CSRComponentWrapper>
        </PureFullScreenVideo>
      )}
    </Transition>
  );
};

export default FullScreenVideo;

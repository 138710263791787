import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  IAnimationProduct,
  ILoubiAirwaysTarotCardAnimationState,
  LoubiAirwaysAnimation
} from '../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../redux/customActions/loubiAirways';
import { DID_UPDATE_ANIMATION_STATE } from '../../../utils/constants';
import { useRemoteLoubiAirwaysAnimationState } from '../../hooks/loubiAirways';
import { canInteractWithAnimation } from '../../hooks/meeting';
import { animationVoyageThrottleDurationInMs } from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/animationUtils';
import LJContinueFlightButton from '../../VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/Buttons/LJContinueFlightButton';
import { AnimationContext } from '../louboutin/AnimationContext';
import TarotCard from './TarotCard';

const TarotCardWrapper = () => {
  const { setSelectedModel, setLoaded, onCloseAnimation } =
    React.useContext(AnimationContext);

  const remoteTarotCardState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.TAROT_CARD
  );
  const viewOnly = !canInteractWithAnimation();
  const dispatch = useDispatch();
  const logEventThrottled = throttle(
    (state: ILoubiAirwaysTarotCardAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: LoubiAirwaysAnimation.TAROT_CARD,
        state
      });
    },
    1000,
    { trailing: true }
  );

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirwaysTarotCardAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.TAROT_CARD,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );
  const updateRemoteState = (state: ILoubiAirwaysTarotCardAnimationState) => {
    throttledUpdateRemoteState(state);
    logEventThrottled(state);
  };

  return (
    <>
      <TarotCard
        remoteState={remoteTarotCardState}
        updateRemoteState={updateRemoteState}
        didSelectModel={(model: IAnimationProduct) => {
          // TODO: Fix creating IAnimationProduct on the spot
          if (model) {
            setSelectedModel(model);
          } else {
            setSelectedModel(undefined);
          }
        }}
        onReady={() => setLoaded(true)}
        viewOnly={viewOnly}
      />
      <LJContinueFlightButton onClick={onCloseAnimation} />
    </>
  );
};

export default TarotCardWrapper;

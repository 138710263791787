import React from 'react';
import { IVirtualBoutiqueContact } from '../../../interfaces';
import { logClickContact } from './common';
import { useDispatch } from 'react-redux';
import { actionClickContact } from '../../../redux/actions';
import { getZaloLink } from '../../../utils/zaloLink';
import { useChatMessage } from '../../hooks/common';
import { Spinner } from 'react-bootstrap';

const ZaloContactButton = ({
  contact,
  defaultMessage,
  children
}: {
  contact: IVirtualBoutiqueContact;
  defaultMessage: string;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const { loading, getMessage, message } = useChatMessage(defaultMessage);

  const handleClick = async () => {
    const url = getZaloLink(
      contact.zaloNumber,
      message || (await getMessage())
    );
    window.open(url, '_blank');
    logClickContact('zalo', contact);
    dispatch(actionClickContact());
  };

  return (
    <a
      key={contact.name}
      data-channel="zalo"
      aria-label="Chat Now"
      onClick={handleClick}
    >
      {loading ? <Spinner animation="border" size="sm" /> : children}
    </a>
  );
};

export default ZaloContactButton;

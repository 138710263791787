import React from 'react';
import { logEvent } from '../../../../../analytics';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';
import { LFPopupContent } from '../../../../../interfaces/loubifuture';
import {
  DID_CLOSE_POPUP,
  DID_OPEN_POPUP
} from '../../../../../utils/constants';
const asset = `${LFAssetBaseUrl}/icons/v1`;

const LFPopupWrapper = ({
  children,
  onClose,
  popup,
  maxWidth = 500,
  alignContent = 'center'
}: {
  children: React.ReactNode;
  onClose: () => void;
  maxWidth?: number;
  popup: LFPopupContent;
  alignContent?: 'center' | 'right' | 'left';
}) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(true);
    logEvent(DID_OPEN_POPUP, DID_OPEN_POPUP, { popup });
  }, []);

  return (
    <div
      className={`LFPopupWrapper fixed-full content-middle noselect ${
        show ? 'show-popup' : 'close-popup'
      }`}
    >
      <div className="popup-box">
        <div className="content-container">
          <div className="content">{children}</div>
        </div>
        <button
          className="btn btn-close"
          onClick={() => {
            setShow(false);
            setTimeout(onClose, 300);
            logEvent(DID_CLOSE_POPUP, DID_CLOSE_POPUP, { popup });
          }}
        >
          <img src={`${asset}/close.png`} alt="" />
        </button>
      </div>
      <style jsx>{`
        .LFPopupWrapper {
          transition: opacity 0.3s ease-in;
          opacity: 1;
          background: rgba(0, 0, 0, 0.3);
          z-index: 2001;
          opacity: 0;
        }
        .show-popup {
          opacity: 1;
        }
        .close-popup {
          opacity: 0;
        }
        .content-container {
          padding: 50px 40px 40px;
          font-family: 'LF-Regular', sans-serif;
          text-align: ${alignContent};
          color: #fff;
        }
        .content-container :global(p) {
          font-size: 0.9em;
          letter-spacing: 0.5px;
        }
        .popup-box {
          position: relative;
          margin: auto;
          width: 90%;
          max-width: ${maxWidth}px;
          border-radius: 20px;
          background: rgba(34, 0, 61, 0.85);
        }
        .btn-close {
          position: absolute;
          top: 10px;
          outline: none;
          box-shadow: none;
          width: 24px;
          height: 24px;
          padding: 0;
          font-size: 0;
          line-height: 1;
          right: 10px;
        }
        .btn-close img {
          width: 100%;
          height: auto;
        }
        .LFPopupWrapper :global(.LFTitle) {
          letter-spacing: 1px;
          font-size: 1.2em;
          margin-bottom: 0;
        }
        @media (min-width: 768px) {
          .LFPopupWrapper {
            font-size: 18px;
          }
          .LFPopupWrapper :global(.LFTitle) {
            font-size: 1.3em;
          }
        }
      `}</style>
    </div>
  );
};

export default LFPopupWrapper;

import React from 'react';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../../analytics';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { LoubiFutureScene } from '../../../../interfaces/loubifuture';
import { DID_CLICK_SPOT } from '../../../../utils/constants';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import { LFColor } from '../../../ThreejsAnimation/loubiFuture/assets';

const asset = `${LFAssetBaseUrl}/map/v5`;

const LFMap = ({
  open,
  onClose,
  currentScene,
  onClickMap,
  hasPassedGate
}: {
  open: boolean;
  onClose: () => void;
  currentScene: LoubiFutureScene;
  onClickMap: (scene: LoubiFutureScene) => void;
  hasPassedGate;
}) => {
  const handleClick = (scene: LoubiFutureScene) => {
    onClickMap(scene);
    logEvent(DID_CLICK_SPOT, DID_CLICK_SPOT, { mapSpot: scene });
  };

  return (
    <Transition in={open} timeout={300} mountOnEnter unmountOnExit>
      {(state) => (
        <div className={`LFMap fixed-full content-middle noselect ${state}`}>
          <div className="map-container noselect">
            <img src={`${asset}/map.png`} className="map map-d" alt="" />
            <img src={`${asset}/map_m.png`} className="map map-m" alt="" />
            <div
              onClick={() => {
                if (currentScene !== LoubiFutureScene.ArpoadorA2) {
                  handleClick(LoubiFutureScene.ArpoadorA2);
                }
              }}
              className={`map-veil ${
                currentScene !== LoubiFutureScene.ArpoadorA2
                  ? 'cursorPointer'
                  : ''
              }`}
              id="ap-map"
            />
            <div
              onClick={() => {
                if (currentScene !== LoubiFutureScene.ViewingRoom) {
                  handleClick(
                    hasPassedGate
                      ? LoubiFutureScene.ViewingRoom
                      : LoubiFutureScene.ArpoadorViewAdjustment
                  );
                }
              }}
              className={`map-veil ${
                currentScene !== LoubiFutureScene.ViewingRoom
                  ? 'cursorPointer'
                  : ''
              }`}
              id="dom-map"
            />
          </div>
          <img
            src={`${LFAssetBaseUrl}/icons/v1/back_with_cross_white.svg`}
            className="close-map glow"
            alt=""
            onClick={onClose}
          />
          <style jsx>{`
            .LFMap {
              transition: opacity 0.3s ease-in-out;
            }
            :global(.in-meeting.SMPortrait) .LFMap {
              bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
            }
            :global(.in-meeting.MDPortrait) .LFMap {
              bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
            }
            :global(.in-meeting.SMLandscape) .LFMap {
              left: ${SMLandscapeNormalSpec.contentArea.left}px;
            }
            :global(.in-meeting.MDLandscape) .LFMap {
              left: ${MDLandscapeNormalSpec.contentArea.left}px;
            }
            .LFMap.entering {
              opacity: 0;
            }
            .LFMap.entered {
              opacity: 1;
            }
            .LFMap.exiting {
              opacity: 0;
            }
            .LFMap.exited {
              opacity: 0;
            }
            .map-container {
              width: fit-content;
              block-size: fit-content;
              margin: auto;
              position: relative;
            }
            .map {
              width: 95%;
              max-width: 1600px;
            }
            .close-map {
              width: 60px;
              height: auto;
              position: fixed;
              cursor: pointer;
              right: 10px;
              top: 10px;
            }
            :global(.in-meeting.SM) .close-map {
              top: ${SMLandscapeNormalSpec.contentArea.top + 10}px;
            }
            :global(.in-meeting.MD) .close-map {
              top: ${MDLandscapeNormalSpec.contentArea.top + 10}px;
            }
            .map-veil {
              position: absolute;
            }
            .cursorPointer {
              cursor: pointer;
            }
            @media (min-width: 768px) {
              .close-map {
                width: 70px;
              }
            }
            @media (orientation: landscape) {
              .map-d {
                display: block;
              }
              .map-m {
                display: none;
              }
              .LFMap {
                background: ${LFColor.purpleDark} url('${asset}/bg.jpg');
                background-size: cover;
              }
              .map {
                width: 95%;
                max-width: 150vh;
              }
              .map-veil {
                width: 50%;
                height: 100%;
                top: 0;
                bottom: 0;
              }
              #ap-map {
                right: 0;
                left: 50%;
              }
              #dom-map {
                left: 0;
                right: 50%;
              }
            }
            @media (orientation: portrait) {
              .map-d {
                display: none;
              }
              .map-m {
                display: block;
              }
              .map {
                max-width: 50vh;
              }
              .LFMap {
                background: ${LFColor.purpleDark} url('${asset}/bg_m.jpg');
                background-size: cover;
              }
              .map-veil {
                width: 100%;
                height: 50%;
                left: 0;
                right: 0;
              }
              #ap-map {
                bottom: 0;
                top: 50%;
              }
              #dom-map {
                top: 0;
                bottom: 50%;
              }
            }
          `}</style>
        </div>
      )}
    </Transition>
  );
};

export default LFMap;

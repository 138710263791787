import { CHScenes } from '../../PSInterface';
import { CHMapSpot, mapAsset } from './CHMaps';

interface CHDiamondProps extends CHMapSpot {
  active?: boolean;
  isNotDiamond?: boolean;
  setActiveScene: (s: CHScenes) => void;
  onClickSpot?: (s: CHScenes) => void;
  isTouch?: boolean;
}

export const CHDiamond = ({
  active,
  isNotDiamond,
  diamondX = 0,
  diamondShadow,
  command,
  diamondY = 0,
  setActiveScene,
  onClickSpot,
  isTouch
}: CHDiamondProps) => {
  const handleClick = (scene: CHScenes) => {
    if (isTouch) {
      setActiveScene(scene);
    } else {
      onClickSpot(scene);
    }
  };
  return (
    <div
      className={`CHDiamond ${active ? 'active' : ''} ${command} ${
        isNotDiamond ? 'notDiamond' : ''
      }`}
      {...(isTouch
        ? {}
        : {
            onMouseEnter: () => {
              setActiveScene(command);
            },
            onMouseLeave: () => setActiveScene(null)
          })}
    >
      {!isNotDiamond ? (
        <>
          <img
            src={`${mapAsset}/diamond.png`}
            alt="Diamond Frame"
            onClick={() => {
              handleClick(command);
            }}
          />
          <img
            src={`${mapAsset}/diamond-center.png`}
            className="diamond"
            alt="Diamond"
            onClick={() => {
              handleClick(command);
            }}
          />
        </>
      ) : (
        <img
          src={`${mapAsset}/ratio-keeper.png`}
          className="diamond"
          alt="Diamond"
          onClick={() => {
            handleClick(command);
          }}
        />
      )}
      <style jsx>{`
        .CHDiamond {
          position: absolute;
          top: ${diamondY}%;
          left: ${diamondX}%;
          transform: translate(-50%, -50%);
          transition: all 0.5s ease-in-out;
          width: 7%;
          height: 7%;
          border-radius: 100%;
          overflow: hidden;
          box-shadow: ${active
            ? `0px 0px 0.8vh 0.32vh ${diamondShadow}`
            : 'none'};
          cursor: pointer;
        }
        .CHDiamond.notDiamond {
          width: 4vh;
          height: 4vh;
          background: ${active ? diamondShadow : 'none'};
          opacity: 0.8;
        }
        .CHDiamond.active img.diamond {
          animation: rotate-center 0.6s linear infinite both;
        }
        img {
          width: 100%;
          height: auto;
          user-select:none;
          display:block;
          margin:auto;
        }
        img.diamond {
          position: absolute;
          top: 0;
          left: 0;
        }
        @keyframes rotate-center {
          0% {
            transform: rotate(0);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default CHDiamond;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from '../../../interfaces';
import CartTable from './CartTable';
import { useTranslation } from '../../../i18n';
import {
  actionCartAddProductQty,
  actionCartReduceProductQty
} from '../../../redux/actions';
import { getShopNowDisclaimer } from '../../storeComponentFactory';

const Cart = ({
  deliveryFee,
  storeId
}: {
  deliveryFee: number;
  storeId: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart?.items || []
  );
  return (
    <>
      <div className="cart-heading">{t('vb:advisor_will_contact')}</div>
      <CartTable
        items={items}
        onIncreaseQty={(id) => dispatch(actionCartAddProductQty(id))}
        onReduceQty={(id) => dispatch(actionCartReduceProductQty(id))}
        deliveryFee={deliveryFee}
      />
      <div className="additional-disclaimer">
        <p>{t('vb:products_timeslot_confirmed')}</p>
        {getShopNowDisclaimer(storeId, t)}
      </div>
      <style jsx>{`
        .cart-heading {
          text-align: justify;
        }
        :global(.isRtl .cart-heading) {
          text-align: right !important;
        }
        @media (min-width: 992px) {
          .cart-heading {
            margin-bottom: 30px;
          }
          .additional-disclaimer {
            margin-top: 90px;
          }
        }
      `}</style>
    </>
  );
};

export default Cart;

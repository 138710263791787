import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  inspifyBrandId,
  inspifyTestBrandId,
  iwcWW2021Id
} from '../../../config';
import { useTranslation } from '../../../i18n';
import {
  IMainState,
  IStore,
  IVirtualBoutiqueConfig,
  MeetingLayoutMode,
  Source,
  VirtualBoutiqueMode
} from '../../../interfaces';
import {
  actionAppointmentPopup,
  actionCartOpenPopup,
  actionOpenVBPopup
} from '../../../redux/actions';
import {
  DID_CLICK_APPOINTMENT,
  DID_CLICK_PRODUCT_COMPARE_BUTTON,
  DID_CLICK_PRODUCT_SEARCH_BUTTON,
  DID_CLICK_STORY_SEARCH_BUTTON
} from '../../../utils/constants';
import { shouldShowSalesLoftAppointment } from '../../../utils/entitlements';
import { isEmbeddedInStreamingStudio } from '../../../utils/streamingstudio';
import { getCurrentHost } from '../../../utils/window';
import { logoShouldDark } from '../../BrandStyle';
import ShoppingCartPopup from '../../ShoppingCart/ShoppingCartPopup';
import {
  getAppointmentComponent,
  getVBCustomComponent,
  getWelcomeMessage
} from '../../storeComponentFactory';

const VirtualBoutiqueLite = ({
  config,
  store,
  vbMode
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
  vbMode: VirtualBoutiqueMode;
}) => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const language = i18n?.language || 'en';
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);
  const meetingLayout = meeting?.layout?.mode;
  const appointmentPopupState = useSelector(
    (state: IMainState) => state.clientState.vb?.appointmentPopup
  );
  const shoppingCartState = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const onProductSearch = () => {
    logEvent(DID_CLICK_PRODUCT_SEARCH_BUTTON);
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/productsearch/${config.brandId}?source=${
          Source.Showroom
        }&store=${store.id}&language=${language}`,
        true
      )
    );
  };

  const onCompare = () => {
    logEvent(DID_CLICK_PRODUCT_COMPARE_BUTTON);
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/productsearch/${
          config.brandId
        }?compare=true&source=${Source.Showroom}&store=${
          store.id
        }&language=${language}`,
        true
      )
    );
  };

  const onStorySearch = () => {
    logEvent(DID_CLICK_STORY_SEARCH_BUTTON);
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/storysearch/${config.brandId}?source=${
          Source.Showroom
        }&store=${store.id}&language=${language}`,
        true
      )
    );
  };

  const onBookAppointment = () => {
    const payload = {
      open: true
    };
    logEvent(DID_CLICK_APPOINTMENT, DID_CLICK_APPOINTMENT, payload);
    dispatch(actionAppointmentPopup(payload));
  };

  const renderAppointmentForm = () => {
    if (config.appointmentContacts.length === 0) return null;
    return getAppointmentComponent(
      config,
      store.id,
      appointmentPopupState?.open || false,
      () =>
        dispatch(
          actionAppointmentPopup({ ...appointmentPopupState, open: false })
        ),
      config.visitHours
    );
  };

  const isIWCWW2021InTile =
    store.id === iwcWW2021Id && meetingLayout === MeetingLayoutMode.TILE;

  const className = [
    meetingLayout === MeetingLayoutMode.PRESENTATION &&
    vbMode === VirtualBoutiqueMode.COCKPIT
      ? 'presentation-mode'
      : '',
    logoShouldDark(store?.id) ? 'dark-text' : 'light-text',
    `vb-lite vb-page-wrapper store-${store?.id}`
  ]
    .filter((name) => name.length > 0)
    .join(' ');
  const [shouldShow, setShouldShow] = React.useState<boolean>(undefined);
  const shouldHide =
    isEmbeddedInStreamingStudio() &&
    (config.brandId === inspifyBrandId ||
      config.brandId === inspifyTestBrandId);
  React.useEffect(() => {
    setShouldShow(!shouldHide);
  }, [shouldHide]);
  return (
    <>
      <div className={className}>
        <div className="options-container hide-in-meeting">
          <img
            className="logo-middle hide-in-meeting"
            src={
              logoShouldDark(store?.id) ? config.logoUrl : config.lightLogoUrl
            }
            alt="logo"
          />
          <h1>{getWelcomeMessage(store?.id, config.brandId)}</h1>
          {shouldShow && (
            <div className="options btn-group hide-in-meeting">
              <div className="option btn btn-dark" onClick={onProductSearch}>
                <img
                  src="https://inspify-public.s3-ap-southeast-1.amazonaws.com/virtual_boutique/search_icon.png"
                  alt="search"
                />
                <div className="option-button">
                  <span>{t('vb:product_search')}</span>
                </div>
              </div>
              <div className="option btn btn-dark" onClick={onCompare}>
                <img
                  src="https://inspify-public.s3-ap-southeast-1.amazonaws.com/virtual_boutique/compare_icon.png"
                  alt="compare"
                />
                <div className="option-button">
                  <span>{t('vb:product_compare')}</span>
                </div>
              </div>
              <div className="option btn btn-dark" onClick={onStorySearch}>
                <img
                  src="https://inspify-public.s3-ap-southeast-1.amazonaws.com/virtual_boutique/inspirations_icon.png"
                  alt="inspirations"
                />
                <div className="option-button">
                  <span>{t('story_search')}</span>
                </div>
              </div>
              {shouldShowSalesLoftAppointment(store?.id) &&
                config?.appointmentContacts?.length && (
                  <div
                    className="option btn btn-dark btn-appointment"
                    onClick={onBookAppointment}
                  >
                    <img
                      src="https://inspify-public.s3-ap-southeast-1.amazonaws.com/virtual_boutique/appointment_icon.png?v2"
                      alt="appointment"
                    />
                    <div className="option-button">
                      <span>{t('book_appointment')}</span>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
      {config.entitlement?.shoppingCart &&
        config.shopNowContacts?.length > 0 && (
          <ShoppingCartPopup
            onClose={() => dispatch(actionCartOpenPopup(false))}
            open={shoppingCartState?.open}
            onProductSearch={onProductSearch}
            storeId={store.id}
            shopContacts={config.shopNowContacts}
            phoneCountryCode={config.phoneCountryCode}
          />
        )}
      {renderAppointmentForm()}
      {getVBCustomComponent(store?.id)}
      <style jsx>
        {`
          .vb-lite {
            width: 100%;
            overflow: hidden;
            background: #000 url(${config.vbLite?.bannerImageUrlMobile}) top
              center no-repeat;
            background-size: 100% auto;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;
          }

          .settings-button {
            position: fixed;
            right: 10px;
            top: 10px;
            z-index: 1003;
          }

          .dark-text.vb-lite {
            background-color: #fff;
          }

          .action-groups {
            z-index: ${meetingLayout === MeetingLayoutMode.PRESENTATION ||
            isIWCWW2021InTile
              ? 2002
              : 'initial'};
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
          }

          .action-groups > :global(div) {
            margin-left: 16px;
          }

          .vb-lite :global(.qr-container .panel-container) {
            transform: none;
            right: 0;
            left: auto;
          }
          h1 {
            font-size: 1em;
            width: 330px;
            line-height: 1.4;
            letter-spacing: 3px;
            margin: auto auto 10px;
            font-weight: normal;
            text-shadow: 0 0 10px #000;
            color: #efefef;
            margin-bottom: 30px;
          }
          :global(.in-meeting) h1 {
            margin-bottom: 0 !important;
          }
          .dark-text h1 {
            color: #333;
            text-shadow: 0 0 10px #fff;
          }
          h1 span {
            display: block;
          }
          .options-container {
            margin: auto;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            width: 95vw;
            padding: 30px 10px;
            max-width: 800px;
          }
          .dark-text .options-container {
            background: rgba(255, 255, 255, 0.5);
          }
          .btn {
            border-radius: 10px;
          }

          .options {
            max-width: 450px;
          }
          .option {
            border-width: 2px;
            width: 33%;
            border-color: #000;
            padding-bottom: 20px;
            padding-top: 12px;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 1) 17%,
              rgba(47, 47, 47, 1) 100%
            );
            transform: translate(0, 0);
            transition: all 0.3s ease-in-out;
          }

          .dark-text .option {
            border: none;
            outline: none;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(255, 255, 255, 0.3) 100%
            );
          }
          .option img {
            width: 80px;
            transition: all 0.3s ease-in-out;
            opacity: 0.8;
          }

          .option span {
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 0.8em;
            font-weight: 200;
            line-height: 1.3;
            display: block;
            font-size: 0.7em;
          }
          .dark-text .option span {
            color: #000;
            font-weight: normal;
          }
          .option:hover {
            transform: translate(0, -20px);
          }
          .option:hover img {
            opacity: 1;
          }

          .logo-middle {
            height: 50px;
            width: auto;
            display: block;
            margin: 0 auto 35px;
          }

          @media (min-width: 768px) and (min-height: 600px) {
            h1 {
              font-size: 1.5em;
              width: 600px;
            }
          }
          @media (orientation: landscape) {
            .options-container {
              width: auto;
            }
          }

          @media (min-width: 1400px) {
            h1 {
              font-size: 1.7em;
              width: 600px;
            }
            .options {
              width: 500px;
              max-width: none;
              height: 160px;
            }
            .option img {
              width: 100px;
            }
            .logo-middle {
              width: 200px;
              height: auto;
            }
            .option {
              border: none;
              outline: none;
              margin: 2px !important;
              background: rgb(0, 0, 0);
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(255, 255, 255, 0.3) 100%
              );
            }
            .option span {
              width: 100px;
              display: block;
              margin: auto;
            }
            .dark-text.vb-lite {
              background-color: #fff;
            }
          }

          @media (orientation: landscape) and (min-width: 1024px),
            (min-width: 620px) and (max-height: 500px) {
            .vb-lite {
              background: #000 url(${config.vbLite?.bannerImageUrl}) top left
                no-repeat;
              background-size: auto 100%;
            }
            .options-container {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateY(-50%);
              background: none;
              margin-left: -120px;
            }
            .dark-text .options-container {
              background: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default VirtualBoutiqueLite;

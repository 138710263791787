import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { IMainState } from '../../../../interfaces';
import { actionShowOutro } from '../../../../redux/actions';
import { actionLFPlaySoundEffect } from '../../../../redux/customActions/loubiFuture';
import { DID_START_VIDEO, VIDEO_PAUSED } from '../../../../utils/constants';
import { getViewportSize } from '../../../../utils/window';
import PoweredByInspify from '../../../Legal/PoweredByInspify';
import {
  exitVideoLandscape,
  exitVideoPortrait
} from '../../../ThreejsAnimation/loubiFuture/assets';
import { setAudioOutputDevice } from '../../../../utils/video';
import CSRComponentWrapper from '../../../CSRComponentWrapper';

const LFExitVideo = () => {
  const dispatch = useDispatch();
  const [appear, setAppear] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState<string>('');
  const videoRef = React.useRef<ReactPlayer>();
  const [isPlaying, setIsPlaying] = React.useState(false);

  const showOutro = useSelector(
    (state: IMainState) => state.clientState.vb?.showOutro
  );

  const meeting = useSelector(
    (state: IMainState) => state?.clientState?.meeting
  );
  const advisorEndsMeeting = meeting?.advisorEndsMeeting;

  const activeAudioOutput = meeting?.localUser?.activeDevices?.speaker;
  React.useEffect(() => {
    if (advisorEndsMeeting) {
      dispatch(actionShowOutro(true));
    }
  }, [advisorEndsMeeting]);

  React.useEffect(() => {
    const { vh, vw } = getViewportSize();
    setIsPlaying(true);
    setVideoUrl(vh >= vw ? exitVideoPortrait : exitVideoLandscape);
  }, []);
  const onStart = () => {
    logEvent(DID_START_VIDEO, DID_START_VIDEO, {
      videoUrl
    });
    const videoElement = videoRef?.current?.getInternalPlayer();
    setAudioOutputDevice(videoElement, activeAudioOutput);
  };
  return (
    <>
      {videoUrl && showOutro && (
        <div className={`loubi ${appear ? 'showing' : ''}`}>
          <CSRComponentWrapper>
            <ReactPlayer
              ref={videoRef}
              playing={isPlaying}
              width="100%"
              height="100%"
              autoPlay
              playsinline
              muted
              url={videoUrl}
              onPause={() => {
                logEvent(VIDEO_PAUSED, VIDEO_PAUSED, {
                  second: videoRef.current?.getCurrentTime(),
                  videoUrl
                });
              }}
              onProgress={({ playedSeconds }) => {
                if (Math.floor(playedSeconds) === 6) {
                  dispatch(
                    actionLFPlaySoundEffect({ dome: false, water: false })
                  );
                }
              }}
              onDuration={() => {
                setAppear(true);
              }}
              onStart={onStart}
            />
          </CSRComponentWrapper>
          <div className="powered-container">
            <PoweredByInspify position="right bottom" color="light" />
          </div>
          <style jsx global>{`
            .mute-button {
              display: none !important;
            }
          `}</style>
          <style jsx>{`
            .loubi {
              z-index: 9999;
              position: fixed;
              left: 0;
              bottom: 0;
              right: 0;
              top: 0;
              background: black;
              display: flex;
              justify-content: center;
              align-item: center;
              flex-direction: column;
              opacity: 0;
              transition: opacity 0.3s ease-in;
            }
            .loubi.showing {
              opacity: 1;
            }
            video {
              width: 100%;
            }
            .loubi :global(video) {
              object-fit: cover !important;
            }

            .powered-container {
              position: fixed;
              bottom: 10px;
              right: 0;
              left: 0;
              z-index: 1;
            }
          `}</style>
        </div>
      )}
    </>
  );
};

export default LFExitVideo;

import React from 'react';
import { useDispatch } from 'react-redux';
import { JitsiDevice } from '../../../interfaces';
import { actionSetAudioOutput } from '../../../redux/actions';
import Speakers from './Speakers';
import DeviceDropdown from './DeviceDropdown';
import { IconSpeaker } from '../MeetingControls/MeetingIcons';

const SpeakerControl = ({
  devices,
  activeDevice,
  allowChange,
  controlSelectorPrefix
}: {
  devices: JitsiDevice[];
  activeDevice: string;
  allowChange: boolean;
  controlSelectorPrefix?: string;
}) => {
  const dispatch = useDispatch();
  const activeLabel = devices.find((d) => d.deviceId === activeDevice);
  const [show, setShow] = React.useState(false);
  const id = controlSelectorPrefix
    ? `${controlSelectorPrefix}-SpeakerControl`
    : `SpeakerControl`;
  return (
    <DeviceDropdown
      label={activeLabel?.label || 'Speaker'}
      icon={<IconSpeaker fill="#000" />}
      onToggle={setShow}
      show={show}
      id={id}
      showArrow={devices?.length > 1}
    >
      <Speakers
        devices={devices}
        onSelect={(deviceId: string) => {
          dispatch(actionSetAudioOutput(deviceId));
          setShow(false);
        }}
        activeDevice={activeDevice}
        allowChange={allowChange}
      />
    </DeviceDropdown>
  );
};

export default SpeakerControl;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../../i18n';
import { actionDidSelectProduct } from '../../../../../redux/actions';
import { LoubiAirFontFamily } from '../../../../BrandStyle/LouboutinStyle';
import { useProductState } from '../../../../hooks/product';

const LJProductDetailsButton = ({ productId }: { productId: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {loadedProducts} = useProductState();
  const isExist = loadedProducts?.some((p) => p.id === productId);
  const loubiFont = LoubiAirFontFamily();

  const openProductDetail = () => {
    dispatch(actionDidSelectProduct(productId));
  };

  return (
    <button
      className="btn btn-danger LJProductDetailsButton"
      disabled={!isExist}
      onClick={openProductDetail}
    >
      {t('vb:discover_more')}
      <style jsx>{`
        .btn {
          border-radius: 10px;
          background: #bf080e;
          color: #fff;
          font-family: ${loubiFont};
          min-width:200px;
        }
      `}</style>
    </button>
  );
};

export default LJProductDetailsButton;

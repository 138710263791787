import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { useTranslation } from '../../../../i18n';
import {
  actionDidAcceptCookies,
  actionDidRejectCookies
} from '../../../../redux/actions';
import {
  DID_ACCEPT_COOKIE_DISCLAIMER,
  DID_REJECT_COOKIE_DISCLAIMER
} from '../../../../utils/constants';

const LFCookieDisclaimer = ({
  onAccept,
  onReject
}: {
  onAccept: () => void;
  onReject: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="cookie-disclaimer">
      <p>{t('vb:loubi_cookies_disclaimer')}</p>
      <div className="buttons">
        <button
          className="btn reject"
          onClick={() => {
            onReject();
            dispatch(actionDidRejectCookies());
            logEvent(DID_REJECT_COOKIE_DISCLAIMER);
          }}
        >
          {t('vb:reject_all')}
        </button>
        <button
          className="btn accept"
          onClick={() => {
            onAccept();
            dispatch(actionDidAcceptCookies());
            logEvent(DID_ACCEPT_COOKIE_DISCLAIMER);
          }}
        >
          {t('vb:accept_all')}
        </button>
      </div>
      <style jsx>
        {`
          .cookie-disclaimer {
            font-family: 'LF-Regular', Arial;
            background: #7ffbf2;
            color: #12278c;
            position: fixed;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: row;
            padding: 15px 20px;
            z-index: 9999;
          }

          .cookie-disclaimer p {
            text-align: left;
            flex-grow: 1;
            font-size: 14px;
            margin: 0;
          }

          .cookie-disclaimer button {
            color: #7ffbf2;
            background: #12278c;
            border-radius: 0;
            width: 140px;
            font-size: 14px;
            border-radius: 20px;
            text-transform: uppercase;
            font-family: 'LF-Bold', Arial;
          }

          .cookie-disclaimer .reject {
            margin-right: 10px;
            background: #7ffbf2;
            color: #12278c;
            border: 2px solid #12278c;
          }

          .buttons {
            margin-left: 20px;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }

          @media (max-width: 1200px) {
            .cookie-disclaimer p {
              font-size: 12px;
            }

            .cookie-disclaimer button {
              width: 100px;
              font-size: 12px;
            }

            .buttons {
              margin-left: 10px;
            }
          }

          @media (max-width: 768px) {
            .cookie-disclaimer {
              flex-direction: column;
              padding: 10px 20px;
            }

            .cookie-disclaimer p {
              font-size: 0.8rem;
            }

            .buttons {
              margin: 10px 0 0 0;
              flex-direction: row;
            }

            .cookie-disclaimer button {
              width: 100%;
              font-size: 0.8rem;
              margin: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LFCookieDisclaimer;

import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import {
  adrenalineScenes,
  expressiveSingularityScenes
} from '../../../../../config/rduSg';
import { RDUHudMenu } from '../../../../../interfaces';
import {
  DID_CLICK_HUD_FLIP_BUTTON,
  DID_CLICK_HUD_MENU
} from '../../../../../utils/constants';
import { getElementPosition } from '../../../../../utils/window';
import {
  actionDidClickHudMenu,
  actionOpenHud,
  actionResetAndFlipPano
} from './../../../../../redux/actions';

const HudMain = ({ currentScene }: { currentScene: string }) => {
  const dispatch = useDispatch();
  const handleClickNav = (menu: RDUHudMenu) => {
    dispatch(actionOpenHud(false));
    dispatch(actionDidClickHudMenu(menu));
    logEvent(DID_CLICK_HUD_MENU, DID_CLICK_HUD_MENU, { menu });
  };

  const handleClickFlip = () => {
    dispatch(actionOpenHud(false));
    dispatch(actionResetAndFlipPano());
    logEvent(DID_CLICK_HUD_FLIP_BUTTON, DID_CLICK_HUD_FLIP_BUTTON);
  };

  const arrowRef = React.useRef<HTMLImageElement>();

  const getMobileRotateDeg = () => {
    if (adrenalineScenes.includes(currentScene)) return -90;
    if (expressiveSingularityScenes.includes(currentScene)) return 180;
    return 0;
  };

  React.useEffect(() => {
    if (
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ) < 1100
    )
      return;
    const mouseXY = { X: 0, Y: 0 };
    const prevXY = { X: null, Y: null };
    const hArrow = arrowRef.current;
    const elPosition = getElementPosition(hArrow);

    const cursorEventThrottled = (event) => {
      mouseXY.X = event.pageX;
      mouseXY.Y = event.pageY;
    };

    const cursorEvent = throttle(cursorEventThrottled, 10, { trailing: true });

    const rotate = () => {
      if (
        prevXY.Y != mouseXY.Y ||
        (prevXY.X != mouseXY.X && (prevXY.Y != null || prevXY.X != null))
      ) {
        const left = elPosition.left;
        const top = elPosition.top + 230;
        const diffX = left - mouseXY.X;
        const diffY = top - mouseXY.Y;
        const tan = diffY / diffX;

        let atan = (Math.atan(tan) * 180) / Math.PI;
        if (diffY >= 0 && diffX >= 0) {
          atan += 180;
        } else if (diffY <= 0 && diffX >= 0) {
          atan -= 180;
        }

        prevXY.X = mouseXY.X;
        prevXY.Y = mouseXY.Y;
        if (diffY > -80) {
          hArrow.style.transform = 'rotate(' + atan + 'deg)';
        }
      }
    };

    document.addEventListener('mousemove', cursorEvent);
    const rotateInterval = setInterval(rotate, 10);
    return () => {
      document.removeEventListener('mousemove', cursorEvent);
      clearInterval(rotateInterval);
    };
  }, []);

  return (
    <div className="HudMain">
      <div className="hud-nav-container">
        <div className="hud-nav hud-nav1">
          <img
            src="/asset/rdu/addrenaline.png"
            alt="ADRENALINE ZONE"
            onClick={() => handleClickNav(RDUHudMenu.ADRENALINE)}
          />
          <span>
            ADRENALINE
            <br />
            ZONE
          </span>
        </div>
        <div className="hud-nav hud-nav2">
          <img
            src="/asset/rdu/expressive.png"
            alt="EXPRESSIVE SINGULARITY</br> ZONE"
            onClick={() => handleClickNav(RDUHudMenu.EXPRESSIVE_SINGULARITY)}
          />

          <span>
            EXPRESSIVE SINGULARITY
            <br />
            ZONE
          </span>
        </div>

        <div className="hud-nav hud-nav3">
          <img
            src="/asset/rdu/manufacture.png"
            alt="THE MANUFACTURE ZONE"
            onClick={() => handleClickNav(RDUHudMenu.LOBBY)}
          />

          <span>
            THE MANUFACTURE
            <br />
            ZONE
          </span>
        </div>
        <img
          className="hud-arrow"
          ref={arrowRef}
          src="/asset/rdu/hud-arrow.png"
          alt=""
        />
      </div>
      <div className="flip-nav" onClick={() => handleClickFlip()}>
        <div>
          <img src="/asset/rdu/flip-arrow.png" className="flip-arrow" />
          <img src="/asset/rdu/flip-word.png" className="flip-word" />
          <img src="/asset/rdu/flip-arrow.png" className="flip-arrow" />
        </div>
      </div>
      <style jsx>{`
        .hud-nav-container {
          position: relative;
          width: 100%;
          max-width: 800px;
          height: 470px;
          margin: auto;
        }
        .hud-nav {
          color: #fff;
          width: 240px;
          position: absolute;
          z-index: 1;
        }
        .hud-nav img {
          width: 170px;
          height: 170px;
          transform: scale(0.9);
          border-radius: 180px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
          cursor: pointer;
          transition: all 0.1s ease;
        }
        .hud-nav img:hover {
          box-shadow: 0 0 20px #a51f25;
          border: 2px solid #a51f25;
          transform: scale(1);
        }
        .hud-nav span {
          display: block;
          letter-spacing: 1px;
          font-size: 1.1em;
          height: 30px;
        }
        .hud-nav1 {
          left: 50%;
          margin-left: -120px;
          top: 0;
        }
        .hud-nav2 {
          left: 0;
          bottom: 0;
        }
        .hud-nav3 {
          right: 0;
          bottom: 0;
        }

        .hud-arrow {
          width: 240px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          margin-left: -120px;
        }

        .flip-nav {
          background: url('/asset/rdu/flip-box-l.png') left top no-repeat;
          background-size: auto 100%;
          display: inline-block;
          padding-left: 66px;
          position: absolute;
          bottom: -103px;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          cursor: pointer;
        }
        .flip-nav div {
          background: url('/asset/rdu/flip-box-r.png') right top no-repeat;
          background-size: auto 100%;
          height: 70px;
          display: inline-block;
          min-width: 200px;
          padding-right: 66px;
          display: flex;
          justify-content: center;
          align-item: center;
        }
        .flip-nav img {
          display: inline-block;
          vertical-align: middle;
        }
        .flip-arrow {
          height: 20px;
          margin: auto 0;
          -webkit-animation: slide-in-left 1.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
          animation: slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            infinite both;
        }
        .flip-arrow:last-child {
          transform: rotate(180deg);
          -webkit-animation: slide-in-right 1.5s
            cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
          animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            infinite both;
        }
        .flip-word {
          height: 30px;
          margin: auto 10px;
          -webkit-animation: blink-1 8s ease-out 2s infinite both;
          animation: blink-1 8s ease-out 2s infinite both;
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        @-webkit-keyframes blink-1 {
          0%,
          50%,
          100% {
            opacity: 1;
          }
          25%,
          75% {
            opacity: 0.3;
          }
        }
        @keyframes blink-1 {
          0%,
          50%,
          100% {
            opacity: 1;
          }
          25%,
          75% {
            opacity: 0.3;
          }
        }

        @-webkit-keyframes slide-in-left {
          0% {
            -webkit-transform: translateX(-20);
            transform: translateX(-20);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-left {
          0% {
            -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
            opacity: 0;
          }
          100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
          }
        }

        @-webkit-keyframes slide-in-right {
          0% {
            -webkit-transform: rotate(180deg) translateX(-20px);
            transform: rotate(180deg) translateX(-20px);
            opacity: 0;
          }
          100% {
            -webkit-transform: rotate(180deg) translateX(0);
            transform: rotate(180deg) translateX(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-right {
          0% {
            -webkit-transform: rotate(180deg) translateX(-20px);
            transform: rotate(180deg) translateX(-20px);
            opacity: 0;
          }
          100% {
            -webkit-transform: rotate(180deg) translateX(0);
            transform: rotate(180deg) translateX(0);
            opacity: 1;
          }
        }

        @media (max-width: 1100px) {
          .hud-arrow {
            width: 100px;
            margin-left: -50px;
            bottom: 40px;
            transform: rotate(${getMobileRotateDeg()}deg);
          }
          .hud-nav-container {
            max-width: 600px;
            height: 350px;
          }
          .hud-nav img {
            width: 130px;
            height: 130px;
          }
        }

        @media (max-width: 724px) {
          .hud-arrow {
            bottom: 30px;
          }
          .hud-nav-container {
            height: 300px;
            width: 95%;
          }
          .hud-nav {
            width: 160px;
          }
          .hud-nav1 {
            margin-left: -80px;
          }
          .hud-nav span {
            font-size: 0.8em;
            font-weight: bold;
          }
          .hud-nav img {
            width: 100px;
            height: 100px;
          }
          .flip-nav {
            bottom: -43px;
            padding-left: 46px;
          }
          .flip-nav div {
            height: 50px;
            padding-right: 43px;
          }
          .flip-word {
            height: 20px;
          }
        }
        @media (max-width: 480px) {
          .hud-nav-container {
            width: 100%;
          }
          .flip-nav {
            bottom: -45px;
            padding-left: 34px;
          }
          .flip-nav div {
            height: 40px;
            padding-right: 34px;
            min-width: 100px;
            align-items: start;
          }
          .flip-word {
            height: 15px;
            margin: 10px 5px;
          }
          .flip-arrow {
            height: 10px;
          }
        }

        @media (max-height: 768px) and (orientation: landscape) {
          .hud-arrow {
            display: none;
          }
          .hud-nav-container {
            height: 200px;
          }
        }
      `}</style>

      <style jsx global>{`
        .isPresentation .hud-arrow {
          display: none !important;
        }
        .isPresentation .hud-nav-container {
          height: 200px !important;
        }
      `}</style>
    </div>
  );
};

export default HudMain;

import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { IBoutiqueImageCampaign } from '../../../interfaces';
import { actionDidShowVBCampaign } from '../../../redux/actions';
import {
  DID_CLICK_CAMPAIGN,
  DID_CLOSE_CAMPAIGN
} from '../../../utils/constants';
import { getPopupAction } from '../../../utils/pageView';

const ImageCampaign = ({
  campaign,
  onClose,
  brandId,
  storeId,
  secondsBeforeShow
}: {
  campaign: IBoutiqueImageCampaign;
  onClose: () => void;
  brandId: string;
  storeId: string;
  secondsBeforeShow: number;
}) => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { mobileImageUrl, tabletImageUrl, desktopImageUrl, popupPayload } =
    campaign;

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true);
    }, secondsBeforeShow);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleEmbeddedCampaignClick = () => {
    const pageView = popupPayload?.pageView;
    const pageId = popupPayload?.pageId;
    if (pageView && pageId) {
      const action = getPopupAction(
        `${location.protocol}//${location.host}`,
        pageView,
        pageId,
        brandId,
        storeId
      );
      if (action) {
        dispatch(action);
      }
    }

    if (!pageView && !pageId) {
      onClose();
    }
    logEvent(DID_CLICK_CAMPAIGN, '');
  };

  const getImage = (url: string, lang: string) => {
    if (lang === 'ar') {
      return url
        ?.replace('.jpg', '_ar.jpg')
        ?.replace('.png', '_ar.png')
        ?.replace('.jpeg', '_ar.jpeg')
        ?.replace('.gif', '_ar.gif');
    } else {
      return url;
    }
  };
  return (
    <>
      <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`campaign ${state} campaign-${popupPayload?.pageId}`}>
            <div className="campaign-content">
              <button
                className="close"
                type="button"
                onClick={() => {
                  setShow(false);
                  setTimeout(() => {
                    dispatch(actionDidShowVBCampaign());
                    onClose();
                  }, 500);
                  logEvent(DID_CLOSE_CAMPAIGN, DID_CLOSE_CAMPAIGN, {
                    campaign: {
                      linkUrl: campaign.linkUrl,
                      imageUrl: campaign.desktopImageUrl,
                      popup: campaign.popupPayload
                    }
                  });
                }}
              >
                &times;
              </button>
              <img
                src={getImage(mobileImageUrl, i18n.language)}
                className="content-m"
                onClick={handleEmbeddedCampaignClick}
              />
              <img
                src={getImage(tabletImageUrl, i18n.language)}
                className="content-t"
                onClick={handleEmbeddedCampaignClick}
              />
              <img
                src={getImage(desktopImageUrl, i18n.language)}
                className="content-d"
                onClick={handleEmbeddedCampaignClick}
              />
            </div>
          </div>
        )}
      </Transition>

      <style jsx>
        {`
          .campaign {
            position: fixed;
            z-index: 999;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            transition: all 0.3s ease-in-out;
          }

          .campaign-content {
            position: relative;
            margin: auto;
            display: block;
            text-align: center;
            overflow: hidden;
            border: 1px solid #8c8c8c;
            border-radius: 8px;
          }

          .campaign .close {
            position: absolute;
            right: 5px;
            top: 5px;
            color: #fff;
            background-color: transparent;
            padding: 0;
            width: 30px;
            height: 30px;
            font-size: 24px;
          }

          .campaign-9ff491c5-3fa8-44b6-967f-dbe12d4bfbca .close,
          .campaign-bd9839d7-5747-458f-ba47-8d3f8ee09d63 .close,
          .campaign-e0603ff4-2407-4416-b927-c4fc293a8e16 .close {
            right: auto;
            left: 5px;
            color: #000;
            border: 2px solid #000;
            border-radius: 100%;
          }

          .campaign :global(img) {
            display: block;
            width: auto;
            max-width: 80vw;
            max-height: 75vh;
            border-radius: 6px;
            margin: auto;
            cursor: pointer;
          }

          .campaign :global(.content-d) {
            display: none;
          }

          .campaign :global(.content-t) {
            display: none;
          }

          .campaign.entering {
            opacity: 0;
          }
          .campaign.entered {
            opacity: 1;
          }
          .campaign.exiting {
            opacity: 0;
          }
          .campaign.exited {
            opacity: 0;
          }

          @media (min-width: 768px) {
            .campaign :global(.content-m) {
              display: none;
            }
            .campaign :global(.content-t) {
              display: block;
            }
            .campaign :global(.content-d) {
              display: none;
            }
          }
          @media (min-width: 1024px) {
            .campaign :global(.content-m) {
              display: none;
            }
            .campaign :global(.content-t) {
              display: none;
            }
            .campaign :global(.content-d) {
              display: block;
            }
          }
        `}
      </style>
    </>
  );
};

export default ImageCampaign;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { actionShowOutro } from '../../../../../redux/actions';
import { actionOpenLFPopup } from '../../../../../redux/customActions/loubiFuture';
import {
  DID_CLICK_CONTINUE_TOUR,
  DID_CLICK_LEAVE_EXPERIENCE
} from '../../../../../utils/constants';
import LFButton from '../Common/LFButton';

const LeavePrompt = () => {
  const dispatch = useDispatch();
  return (
    <div className="LeavePrompt">
      <h3 className="LFTitle">ARE YOU SURE YOU WANT TO LEAVE LOUBI FUTURE?</h3>
      <div className="mt-4">
        <div className="text-center">
          <LFButton
            name="noContinueTour"
            className="lf-prompt-button"
            disabledDefaultLog
            onClick={() => {
              logEvent(DID_CLICK_CONTINUE_TOUR, DID_CLICK_CONTINUE_TOUR);
              dispatch(actionOpenLFPopup(undefined));
            }}
          >
            No, continue tour
          </LFButton>
        </div>
        <div className="text-center">
          <LFButton
            name="yesLeaveExperience"
            className="lf-prompt-button"
            disabledDefaultLog
            onClick={() => {
              logEvent(DID_CLICK_LEAVE_EXPERIENCE, DID_CLICK_LEAVE_EXPERIENCE);
              dispatch(actionShowOutro(true));
            }}
          >
            Yes, leave experience
          </LFButton>
        </div>
      </div>
      <style jsx>{`
        .LeavePrompt {
          text-align: center;
        }
        :global(.lf-prompt-button) {
          margin-top: 10px;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default LeavePrompt;

import { throttle } from 'lodash';
import { Dispatch } from 'react';
import { LogEvent } from '../../../analytics';
import {
  actionDidClickOnHotSpot,
  actionDidClickOnMainVideo,
  actionDidGoToScene,
  actionDidHideHotSpot,
  actionDidLookToView,
  actionDidShowHotSpot,
  actionDidStartPlayingVideo,
  actionFlipPano,
  actionShowVBZoomIndicator,
  actionStartGlitchPano,
  actionUpdateDiscoverMore,
  actionVideoDidComplete
} from '../../../redux/actions';
import {
  DID_CLICK_ON_HOT_SPOT,
  DID_OPEN_EXTERNAL_LINK,
  DID_START_VB_SCENE
} from '../../../utils/constants';
import { PageView } from '../../../utils/pageView';

export interface IKRPanoCallback {
  didStartScene: (sceneId: string) => void;
  openPopup: (type: PageView, id: string) => void;
  openNewPage: (url: string) => void;
  didLookTo: (toH: number, toV: number, fov: number) => void;
  showZoomIndicator: () => void;
  hideZoomIndicator: () => void;
  showDiscoverMore: (type: PageView, id: string) => void;
  hideDiscoverMore: () => void;
  didStartPlayingVideo: () => void;
  didClickOnGlobalVideo: () => void;
  didClickOnHotSpot: (hotSpotName: string) => void;
  startGlitch: () => void;
  performFlip: (name?: string) => void;
  onMouseOverHotspot: (name: string) => void;
  onMouseOutHotspot: (name: string) => void;
  videoDidComplete: (name: string) => void;
}

class KRPanoCallback implements IKRPanoCallback {
  private dispatch: Dispatch<any>;
  private logEvent: LogEvent;
  private openPopupHandler: (type: PageView, id: string) => void;

  private didLookToThrottled(toH: number, toV: number, fov: number) {
    this.dispatch(actionDidLookToView({ viewH: toH, viewV: toV, fov }));
  }

  constructor(
    dispatch: Dispatch<any>,
    logEvent: LogEvent,
    openPopupHandler: (type: PageView, id: string) => void
  ) {
    this.dispatch = dispatch;
    this.logEvent = logEvent;
    this.openPopupHandler = openPopupHandler;
  }

  didStartScene(sceneId: string) {
    console.log(`did start scene ${sceneId}`);
    this.logEvent(DID_START_VB_SCENE, null, { sceneId });
    this.dispatch(actionDidGoToScene(sceneId));
  }

  openPopup(type: PageView, id: string) {
    this.openPopupHandler(type, id);
  }

  openNewPage(url: string) {
    this.logEvent(DID_OPEN_EXTERNAL_LINK, DID_OPEN_EXTERNAL_LINK, {
      externalUrl: url
    });
    window.open(url, '_blank');
  }

  didLookTo = throttle(this.didLookToThrottled, 200, { trailing: true });

  showZoomIndicator() {
    console.log(`showIndicator`);
    this.dispatch(actionShowVBZoomIndicator(true));
  }

  hideZoomIndicator() {
    console.log(`hideIndicator`);
    this.dispatch(actionShowVBZoomIndicator(false));
  }

  showDiscoverMore(type: PageView, id: string) {
    console.log('show discover');
    this.dispatch(actionUpdateDiscoverMore({ type, id, open: true }));
  }

  hideDiscoverMore() {
    console.log('hide discover');
    this.dispatch(actionUpdateDiscoverMore({ open: false }));
  }

  didStartPlayingVideo() {
    console.log('didStartPlayingVideo');
    this.dispatch(actionDidStartPlayingVideo(true));
  }

  didClickOnGlobalVideo() {
    this.dispatch(actionDidClickOnMainVideo());
  }

  startGlitch() {
    this.dispatch(actionStartGlitchPano());
  }

  performFlip(sceneName?: string) {
    this.dispatch(actionFlipPano(sceneName));
  }

  didClickOnHotSpot(hotSpotName: string) {
    this.logEvent(DID_CLICK_ON_HOT_SPOT, DID_CLICK_ON_HOT_SPOT, {
      hotSpotName
    });
    this.dispatch(actionDidClickOnHotSpot(hotSpotName));
  }

  onMouseOverHotspot(name: string) {
    this.dispatch(actionDidShowHotSpot(name));
  }

  videoDidComplete(name: string) {
    this.dispatch(actionVideoDidComplete(name));
  }

  onMouseOutHotspot(name: string) {
    this.dispatch(actionDidHideHotSpot(name));
  }
}

export default KRPanoCallback;

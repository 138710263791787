import React from 'react';
import { generateVideoUrl } from '../../../utils/video';
import WelcomeVideo from '../../Meeting/WelcomeVideo';

const GenericWelcomeVideo = ({
  welcomeVideoUrl,
  allowSkip
}: {
  welcomeVideoUrl: string;
  allowSkip?: boolean;
}) => {
  const videoUrl = generateVideoUrl(welcomeVideoUrl)
  return <WelcomeVideo url={videoUrl} allowSkip={allowSkip} />;
};

export default GenericWelcomeVideo;

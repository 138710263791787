import { logEvent } from '../../analytics';
import { DID_CLICK_TERMS_OF_USE } from '../../utils/constants';
import { useTranslation } from '../../i18n';

const TermsOfUse = ({
  termsOfUseUrl,
  langKey
}: {
  termsOfUseUrl?: string;
  langKey?: string;
}) => {
  const { t } = useTranslation();
  const inspifyTermsOfUse = `https://www.inspify.com/terms-of-use/`;
  return (
    <a
      href={termsOfUseUrl || inspifyTermsOfUse}
      target="_blank"
      onClick={() => logEvent(DID_CLICK_TERMS_OF_USE)}
    >
      {t(langKey || 'terms_of_use')}
    </a>
  );
};

export default TermsOfUse;

import { IVirtualBoutiqueContact } from '../interfaces';
import { isUserOnMobile } from './deviceDetector';

export const getZaloLink = (
  contactNumber: string | undefined,
  _: string
) => {
  return isUserOnMobile()
    ? `https://zalo.me/${contactNumber || ''}`
    : `https://chat.zalo.me/?phone=${contactNumber || ''}`;
};

export const getContactForToday = (contacts: IVirtualBoutiqueContact[]=[]) => {
  const dayNumber = new Date().getDay();
  return contacts?.find(contact => contact?.activeDays.includes(dayNumber)) || contacts[0];
}
import { last } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { actionUpdateMap } from '../../redux/actions';
import {
  DID_SELECT_MAP_ROOM,
  DID_SELECT_MAP_SPOT,
  DID_TOGGLE_MAP
} from '../../utils/constants';
import { IMainState, IMapSpot, PanoView } from './../../interfaces';
import { lookToView, loadScene } from './../../utils/krpano';

export interface IMapContext {
  selectedRoom?: string;
  open?: boolean;
  visitedSpot?: string[];
  currentPosition?: string;
  onSelectSpot?: (spot: IMapSpot) => void;
  onSelectRoom?: (room: string) => void;
  onToggleMap?: (open: boolean) => void;
}

export const MapContext = React.createContext<IMapContext>(undefined);

const MapWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  const mapState = useSelector(
    (state: IMainState) => state.clientState?.vb?.map || {}
  );

  const sceneState = useSelector(
    (state: IMainState) => state.clientState?.vb?.sceneIds || []
  );

  const onToggleMap = (open: boolean) => {
    dispatch(actionUpdateMap({ open }));
    logEvent(DID_TOGGLE_MAP, DID_TOGGLE_MAP, { open });
  };

  const onSelectSpot = (spot: IMapSpot) => {
    dispatch(actionUpdateMap({ open: false }));
    const scene = spot?.scene;
    const view: PanoView = {
      viewH: spot?.viewH,
      viewV: spot?.viewV,
      fov: spot?.fov
    };
    if (scene) {
      loadScene(scene);
    }
    if (view.viewH || view.viewV || view.fov) {
      lookToView(view);
    }
    logEvent(DID_SELECT_MAP_SPOT, DID_SELECT_MAP_SPOT, { spot });
  };

  const onSelectRoom = (selectedRoom: string) => {
    dispatch(actionUpdateMap({ selectedRoom }));
    logEvent(DID_SELECT_MAP_ROOM, DID_SELECT_MAP_ROOM, { selectedRoom });
  };

  const contextValue: IMapContext = {
    selectedRoom: mapState.selectedRoom,
    open: mapState.open,
    visitedSpot: sceneState,
    currentPosition: last(sceneState),
    onToggleMap,
    onSelectSpot,
    onSelectRoom
  };
  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
};

export default MapWrapper;

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import {
  isLoubiAirways,
  iwcUaeDubaiBoutiqueId,
  loubiAirwaysJpBoutiqueId,
  vanCleefArpelsUsKingOfPrussiaSiteId,
  vanCleefArpelsUsSiliconValleyFairSiteId
} from '../../config';
import {
  AppointmentType,
  IMainState,
  IStore,
  IVirtualBoutiqueConfig,
  MeetingLayoutMode,
  ParticipantMeetingState,
  Source,
  VirtualBoutiqueMode
} from '../../interfaces';
import {
  actionAppointmentPopup,
  actionCartOpenPopup,
  actionCloseVBPopup,
  actionFeedbackPopup,
  actionOpenVBPopup,
  actionSultanateOfOmanPopup
} from '../../redux/actions';
import { isUserOnMobileOnly } from '../../utils/deviceDetector';
import { shouldMuteByDefault } from '../../utils/entitlements';
import generateMeetingId from '../../utils/meetingIdGenerator';
import { isInWalkThrough } from '../../utils/stores';
import { getCurrentHost, tryGetLocalStorage } from '../../utils/window';
import Feedback from '../Feedback';
import { useLocalUserMeetingEntitlement } from '../hooks/meeting';
import CartButton from '../ShoppingCart/CartButton';
import CheckoutSummaryPopup from '../ShoppingCart/CheckoutSummaryPopup';
import ShoppingCartPopup from '../ShoppingCart/ShoppingCartPopup';
import {
  getAppointmentButton,
  getAppointmentComponent,
  getChatButton,
  getChatPanel,
  getLandingComponent,
  getPano,
  getPresentationElements,
  getVBCustomComponent,
  getVBCustomVeil
} from '../storeComponentFactory';
import { getVBWelcomeToSceneComponent } from './../storeComponentFactory';
import Campaign from './Campaign';
import VanCleefArpelsValleyFairCampaign from './Campaign/VanCleefArpelsValleyFairCampaign';
import ExpressSearchButton from './ExpressSearchButton';
import MeetingContainer from './MeetingContainer';
import ToggleSoundButton from './Pano/ToggleSoundButton';
import ShowJourneyGuideButton from './ShowJourneyGuideButton';
import SlideNav from './SlideNav';
import TutorialDetails from './Tutorial/TutorialDetails';

const VirtualBoutiqueContent = ({
  config,
  store,
  vbMode,
  className
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
  vbMode?: VirtualBoutiqueMode;
  className?: string;
}) => {
  const [isInMeeting, setIsInMeeting] = React.useState(false);
  const [showMeetingControls, setShowMeetingControls] = React.useState(true);
  const [meetingId, setMeetingId] = React.useState(null);
  const [willShowTutorial, setWillShowTutorial] =
    React.useState<boolean>(false);
  const [showTutorial, setShowTutorial] = React.useState(false);
  const [showLanding, setShowLanding] = React.useState(
    vbMode !== VirtualBoutiqueMode.COCKPIT
  );

  const [showCampaign, setShowCampaign] = React.useState(true);
  const inWalkThrough = isInWalkThrough(vbMode);

  const dispatch = useDispatch();
  const [canSeeNavigation] = useLocalUserMeetingEntitlement();

  const meeting = useSelector((state: IMainState) => state.clientState.meeting);
  const meetingLayoutMode = meeting?.layout?.mode;
  const meetingState = meeting?.state;

  const appointmentPopupState = useSelector(
    (state: IMainState) => state.clientState.vb?.appointmentPopup
  );

  const shoppingCartState = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const { panoLoaded, backgroundVideoPlayed } = useSelector(
    (state: IMainState) => state.clientState.vb || {}
  );

  const startMeeting = (
    meetingId: string = generateMeetingId(4, config.brandId)
  ) => {
    console.log('start meeting');
    setIsInMeeting(true);
    setMeetingId(meetingId);
  };

  const frameClassName = [
    isInMeeting ? 'in-meeting' : '',
    showMeetingControls ? 'show-controls' : '',
    meetingLayoutMode === MeetingLayoutMode.PRESENTATION &&
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE
      ? 'presentation-mode'
      : '',
    'vb-page-wrapper'
  ]
    .filter((name) => name.length > 0)
    .join(' ');

  const handleMessageEvent = (event: MessageEvent) => {
    if (event.data === 'did_click_discover_more') {
      dispatch(actionCloseVBPopup());
    }
  };
  const { i18n } = useTranslation();
  const router = useRouter();
  const landing = router.query.landing as string | undefined;
  const go = router.query.go as string | undefined;
  const queryProduct = router.query.product as string | undefined;
  const hideLanding = landing === 'false' || !!go || !!queryProduct;
  const ckoSessionId = router.query['cko-session-id'] as string | undefined;
  const language = i18n?.language || 'en';
  const renderAppointmentForm = () => {
    if (config.appointmentContacts.length === 0) return null;
    return getAppointmentComponent(
      config,
      store.id,
      appointmentPopupState?.open || false,
      () =>
        dispatch(
          actionAppointmentPopup({ ...appointmentPopupState, open: false })
        ),
      config.visitHours
    );
  };

  const onProductSearch = () => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/productsearch/${config.brandId}?source=${
          Source.Showroom
        }&store=${store.id}&language=${language}`,
        true
      )
    );
  };

  const onWishlist = () => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/wishlist?source=${Source.Showroom}&store=${
          store.id
        }&language=${language}`,
        true
      )
    );
  };

  const onPickOfTheMonth = () => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/potm/${config.pickOfTheMonth}/?source=${
          Source.Showroom
        }&store=${store.id}&language=${language}`,
        true
      )
    );
  };

  const onCampaign = (pageId: string) => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/showcase/${pageId}?source=${
          Source.Showroom
        }&store=${store.id}&language=${language}`
      )
    );
  };

  const onProductCompare = () => {
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/compare/${config.brandId}?source=${
          Source.Showroom
        }&store=${store.id}&language=${language}`,
        true
      )
    );
  };

  const handleGoQuery = (go?: string) => {
    if (go === 'appointment') {
      dispatch(
        actionAppointmentPopup({
          open: true,
          type: AppointmentType.PHYSICAL
        })
      );
    } else if (go === 'search') {
      onProductSearch();
    } else if (go === 'sultanateofoman') {
      dispatch(
        actionSultanateOfOmanPopup({
          open: true
        })
      );
    }
  };

  React.useEffect(() => {
    const meetingId = router.query.meet;
    if (meetingId && config.entitlement?.friendsShopping) {
      setMeetingId(meetingId as string);
      setShowLanding(false);
      startMeeting(meetingId as string);
    }
    setWillShowTutorial(
      config.tutorials && !tryGetLocalStorage('skipIntroduction')
    );
    if (
      !tryGetLocalStorage('skipIntroduction') &&
      config.tutorials &&
      vbMode !== VirtualBoutiqueMode.COCKPIT
    ) {
      setTimeout(() => {
        setShowTutorial(true);
        setWillShowTutorial(false);
      }, 2000);
    }

    handleGoQuery(go);

    if (queryProduct) {
      const showDiscoverMore = store.id !== iwcUaeDubaiBoutiqueId;
      const url = `${getCurrentHost()}/products/${queryProduct}?source=${
        Source.Showroom
      }${showDiscoverMore ? '&discover_more=true' : ''}${
        router.query.store
          ? `&store=${router.query.store}`
          : `&store=${store.id}`
      }&language=${language}`;
      dispatch(actionOpenVBPopup(url));
      window.addEventListener('message', handleMessageEvent);
    }
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  return (
    <div className={className}>
      {getPano(config, store, frameClassName)}
      <div
        className={`express-icons-container ${
          vbMode === VirtualBoutiqueMode.PRESENTATION ? 'left-nav' : ''
        }`}
      >
        {!(inWalkThrough && !canSeeNavigation) && !isLoubiAirways(store.id) && (
          <SlideNav
            onTutorial={() => setShowTutorial(true)}
            onStartMeeting={startMeeting}
            onProductSearch={onProductSearch}
            onProductCompare={onProductCompare}
            onWishlist={onWishlist}
            onFeedback={() => dispatch(actionFeedbackPopup(true))}
            isInMeeting={isInMeeting}
            config={config}
            store={store}
            isInWalkThrough={inWalkThrough}
            onPickOfTheMonth={onPickOfTheMonth}
            onCampaign={onCampaign}
          />
        )}

        {!(inWalkThrough && !canSeeNavigation) &&
          config.entitlement?.productSearch && (
            <ExpressSearchButton onProductSearch={onProductSearch} />
          )}
        {config.entitlement?.shoppingCart && !inWalkThrough && (
          <CartButton
            itemsQuantity={shoppingCartState?.items?.length || 0}
            onClick={() => dispatch(actionCartOpenPopup(true))}
          />
        )}

        {panoLoaded &&
          config.entitlement?.toggleSound &&
          vbMode !== VirtualBoutiqueMode.COCKPIT &&
          backgroundVideoPlayed && (
            <ToggleSoundButton muteByDefault={shouldMuteByDefault(store.id)} />
          )}
        {vbMode === VirtualBoutiqueMode.PRESENTATION &&
          getPresentationElements(config)}
      </div>
      {!inWalkThrough && (
        <ShowJourneyGuideButton entitled={config.entitlement?.journey} />
      )}
      {!inWalkThrough && store.id !== loubiAirwaysJpBoutiqueId && (
        <div className="btn-action-group">
          {config.appointmentContacts.length > 0 &&
            getAppointmentButton(store.id)}
          {config.contacts.length > 0 && getChatButton(config, store.id)}
        </div>
      )}
      {!inWalkThrough && config.entitlement?.feedback && (
        <Feedback ratingType="stars" initialDelay={config.feedbackOpenDelay} />
      )}
      {!inWalkThrough &&
        config.contacts.length > 0 &&
        getChatPanel(config, store.id)}
      {isInMeeting && meetingId && config.entitlement?.friendsShopping ? (
        <MeetingContainer
          showControls={showMeetingControls}
          meetingSubject={config.boutiqueName}
          meetingId={meetingId}
          toggleControls={() => {
            setShowMeetingControls(!showMeetingControls);
          }}
          onMeetingEnd={() => {
            setIsInMeeting(false);
          }}
        />
      ) : null}
      {showTutorial && !showLanding && (
        <Transition in={showTutorial} timeout={300} mountOnEnter unmountOnExit>
          {(state) => (
            <TutorialDetails
              transitionState={state}
              onClose={() => setShowTutorial(false)}
              steps={
                isUserOnMobileOnly()
                  ? config.tutorials.mobile
                  : config.tutorials.desktop
              }
            />
          )}
        </Transition>
      )}
      {store.id === vanCleefArpelsUsSiliconValleyFairSiteId ||
        (store.id === vanCleefArpelsUsKingOfPrussiaSiteId && (
          <VanCleefArpelsValleyFairCampaign
            onClose={() => {
              setShowCampaign(false);
            }}
            inWalkThrough={inWalkThrough}
          />
        ))}
      {config.campaign &&
        !showTutorial &&
        !showLanding &&
        !willShowTutorial &&
        !inWalkThrough &&
        showCampaign && (
          <Campaign
            campaign={config.campaign}
            onClose={() => {
              setShowCampaign(false);
            }}
            storeId={store.id}
            brandId={config.brandId}
          />
        )}
      {config.entitlement?.shoppingCart &&
        config.shopNowContacts?.length > 0 && (
          <ShoppingCartPopup
            onClose={() => dispatch(actionCartOpenPopup(false))}
            open={shoppingCartState?.open}
            onProductSearch={onProductSearch}
            storeId={store.id}
            shopContacts={config.shopNowContacts}
            phoneCountryCode={config.phoneCountryCode}
          />
        )}
      {ckoSessionId && <CheckoutSummaryPopup ckoSessionId={ckoSessionId} />}
      {renderAppointmentForm()}
      {getVBCustomComponent(store?.id)}
      {getVBWelcomeToSceneComponent(store?.id)}
      {!showLanding &&
        panoLoaded &&
        !inWalkThrough &&
        getVBCustomVeil(store?.id)}
      {hideLanding ? null : (
        <Transition in={showLanding} timeout={300} mountOnEnter unmountOnExit>
          {(state) => (
            <div className={`landing-wrapper ${state}`}>
              {getLandingComponent(
                store.id,
                config.brandName,
                config.lightLogoUrl,
                config.logoUrl,
                config.landingImageUrl,
                config.mobileLandingImageUrl,
                config.languages || [],
                () =>
                  dispatch(
                    actionAppointmentPopup({
                      open: true,
                      type: AppointmentType.VIRTUAL_WALKTHROUGH
                    })
                  ),
                () => setShowLanding(false)
              )}

              <style jsx>
                {`
                  :global(body) {
                    overflow: hidden;
                  }

                  .landing-wrapper {
                    transition: all 0.3s ease-in-out;
                  }

                  .landing-wrapper.entering {
                    opacity: 1;
                    transition: none;
                  }
                  .landing-wrapper.entered {
                    opacity: 1;
                    transition: none;
                  }
                  .landing-wrapper.exiting {
                    opacity: 0;
                  }
                  .landing-wrapper.exited {
                    opacity: 0;
                  }
                `}
              </style>
            </div>
          )}
        </Transition>
      )}
    </div>
  );
};

export default VirtualBoutiqueContent;

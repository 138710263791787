import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import useSound from 'use-sound';
import { ReturnedValue } from 'use-sound/dist/types';
import { logEvent } from '../../../../analytics';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { ISoundEffect } from '../../../../interfaces/loubifuture';
import { actionLFPlaySoundEffect } from '../../../../redux/customActions/loubiFuture';
import {
  DID_MUTE_BACKGROUND_SOUND,
  DID_UNMUTE_BACKGROUND_SOUND,
  DID_PLAY_SOUND
} from '../../../../utils/constants';
import { usePageVisibility } from '../../../hooks/ux';

const asset = `${LFAssetBaseUrl}/sounds/v4`;
let timeOut;

const LFSoundsEffect = React.memo(
  ({ state, showButton }: { state: ISoundEffect; showButton?: boolean }) => {
    const isInCockpit = useRouter().query.mode === 'cockpit';
    const isPageVisible = usePageVisibility();

    const volume = state?.muted
      ? 0
      : isPageVisible
      ? isInCockpit
        ? 0.25
        : 0.5
      : 0;

    const {
      enteringSectionA,
      zoomingLightingStrips,
      water,
      rainbowLight,
      elevatorUp,
      dome,
      arClick,
      afterRainbow
    } = state;

    const dispatch = useDispatch();

    const enteringSectionASound = useSound(
      `${asset}/1_Entering_Section_A.mp3`,
      {
        loop: true,
        volume: volume * 0.8
      }
    );
    const zoomingLightingStripsSound = useSound(
      `${asset}/2_Zooming_lighting_Strips.mp3`,
      { volume }
    );
    const waterSound = useSound(`${asset}/3_water_sound_effects.mp3`, {
      loop: true,
      volume
    });

    const afterRainbowSound = useSound(`${asset}/6A_After_Rainbow_Tunnel.mp3`, {
      volume
    });

    const rainbowLightSound = useSound(`${asset}/6_Rainbow_Light_Tunnel.mp3`, {
      volume,
      playbackRate: 1.5
    });

    const arClickSound = useSound(`${asset}/5_AR_on_click.mp3`, { volume });

    const elevatorUpSound = useSound(
      `${asset}/7_Elevator_up_to_Collection_Dome.mp3`,
      { playbackRate: 1.6, volume: volume * 0.6 }
    );
    const domeSound = useSound(`${asset}/8_Section_D.mp3`, {
      loop: true,
      volume
    });

    const playSound = (play: boolean, sound: ReturnedValue, name: string) => {
      if (play) {
        sound[0]();
        logEvent(DID_PLAY_SOUND, DID_PLAY_SOUND, { name });
      } else {
        sound[1].stop();
      }
    };

    React.useEffect(() => {
      playSound(
        enteringSectionA,
        enteringSectionASound,
        'Entering section A background'
      );
    }, [enteringSectionA, enteringSectionASound[0]]);

    React.useEffect(() => {
      playSound(
        zoomingLightingStrips,
        zoomingLightingStripsSound,
        'Zooming lighting effect'
      );
    }, [zoomingLightingStrips, zoomingLightingStripsSound[0]]);

    React.useEffect(() => {
      playSound(water, waterSound, 'Water background');
    }, [water, waterSound[0]]);

    React.useEffect(() => {
      playSound(rainbowLight, rainbowLightSound, 'Rainbow Light ');
    }, [rainbowLight, rainbowLightSound[0]]);

    React.useEffect(() => {
      playSound(elevatorUp, elevatorUpSound, 'Elevator up');
    }, [elevatorUp, elevatorUpSound[0]]);

    React.useEffect(() => {
      playSound(dome, domeSound, 'Dome background');
    }, [dome, domeSound[0]]);

    React.useEffect(() => {
      playSound(afterRainbow, afterRainbowSound, 'afterRainbow background');
    }, [afterRainbow, afterRainbowSound[0]]);

    React.useEffect(() => {
      playSound(arClick, arClickSound, 'Click AR button');
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(() => {
        dispatch(actionLFPlaySoundEffect({ arClick: false }));
      }, 2500);
    }, [arClick, arClickSound[0]]);

    React.useEffect(() => {
      return () => {
        console.log(`unmount sound effect`)
        enteringSectionASound[1].stop();
        zoomingLightingStripsSound[1].stop();
        waterSound[1].stop();
        afterRainbowSound[1].stop();
        rainbowLightSound[1].stop();
        rainbowLightSound[1].stop();
        arClickSound[1].stop();
        elevatorUpSound[1].stop();
        domeSound[1].stop();
      };
    }, []);

    if (!showButton) return null;
    return (
      <img
        className="noselect mute-button"
        onClick={() => {
          dispatch(actionLFPlaySoundEffect({ muted: !state?.muted }));
          const event = state.muted
            ? DID_UNMUTE_BACKGROUND_SOUND
            : DID_MUTE_BACKGROUND_SOUND;
          logEvent(event, event);
        }}
        src={`${LFAssetBaseUrl}/icons/v1/${
          state?.muted ? 'mute_white' : 'unmuted_white'
        }.png`}
      />
    );
  }
);

export default LFSoundsEffect;

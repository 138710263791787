import { logEvent } from '../../../../../analytics';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import { DID_CLICK_OPEN_RESERVATIONS_BUTTON } from '../../../../../utils/constants';
import { useCartItemState } from '../../../../hooks/cart';
import LoubiButtonWrapper from '../../LouboutinCustomComponent/Buttons/LoubiButtonWrapper';

const LJShoppingBagButton = ({ onClick }: { onClick: () => void }) => {
  const items = useCartItemState();

  return (
    <LoubiButtonWrapper>
      <div className="loubi-circle-button top-second-right">
        <img
          src={`${jpIconUrlBase}/shopping_bag_white.svg`}
          alt="reservations"
          className="loubi-circle-button"
          onClick={() => {
            onClick();
            logEvent(
              DID_CLICK_OPEN_RESERVATIONS_BUTTON,
              DID_CLICK_OPEN_RESERVATIONS_BUTTON
            );
          }}
        />
        {items?.length ? <div className="qty">{items.length}</div> : null}
        <style jsx>{`
          img.loubi-circle-button {
            position: relative;
            top: 0;
            right: 0;
            filter: none;
          }
          .qty {
            position: relative;
            background: #bf080e;
            border: 1px solid #fff;
            color: #fff;
            border-radius: 20px;
            display: inline-block;
            padding: 0 5px;
            font-weight: bold;
            position: absolute;
            right: 10px;
            top: 15px;
            font-size: 11px;
          }
        `}</style>
      </div>
    </LoubiButtonWrapper>
  );
};

export default LJShoppingBagButton;

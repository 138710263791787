import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHNavArrowIcon } from './CHIcons';
import { chopardBrandId } from '../../../config';
import { getPopupAction, PageView } from '../../../utils/pageView';
import { IMainState, IProduct } from '../../../interfaces';
import { useTranslation } from '../../../i18n';
import { CHTranslation, objectSpotMap, strapBucklePairs } from './CHConfig';
import {
  actionSelectAccessoriesSection,
  actionSelectProductStrap
} from '../../../redux/actions';
import {
  DID_CLICK_BUTTON,
  DID_SELECT_PRODUCT_STRAP
} from '../../../utils/constants';
import { logClickButton, logEvent } from '../../../analytics';
import { sendCommandToPS } from '../CommandHandler/webToPSCommand';
import { getProductSpecifications } from '../../../utils/product';
import { scrollElementIntoViewById } from '../../../utils/window';
import { AnyAction } from 'redux';
import { wrapActionToBeMirroring } from '../../../utils/meeting';

const AccessoriesOptions = ({
  show,
  items,
  onSelect,
  selectedItem
}: {
  show: boolean;
  items: IProduct[];
  onSelect: (p: IProduct) => void;
  selectedItem: string;
}) => {
  React.useEffect(() => {
    scrollElementIntoViewById(`option-${selectedItem}`);
  }, [selectedItem]);
  return (
    <>
      <div className={`options ${show ? 'active' : ''}`}>
        <div className="horizontal-scroll">
          <div className="option-items">
            {items?.map((item) => (
              <div
                key={item.id}
                id={`option-${item.id}`}
                className={`option-item ${
                  item.id === selectedItem ? 'selected' : ''
                }`}
                onClick={() => onSelect(item)}
                style={{ backgroundImage: `url(${item.imageUrls[0]})` }}
              />
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        .options {
          overflow: auto;
          opacity: 0;
          transform: translateY(100%);
          transition: all 0.5s linear;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          pointer-events: none;
        }

        .options.active {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
        }
        :global(.client.in-meeting) .options.active {
          opacity: 0;
        }
        .option-item {
          width: 30px;
          height: 30px;
          display: inline-block;
          margin: 0 10px;
          overflow: hidden;
          background: #fff;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: auto 1000%;
          border: 1px solid #efefef;
          cursor: pointer;
        }
        .close-btn {
          color: #fff;
          cursor: pointer;
          position: absolute;
          right: -50px;
          bottom: 40px;
        }
        .option-item.selected {
          border: 3px solid #fff;
          box-shadow: 0 0 10px #fff;
        }

        @media (orientation: landscape) and (max-height: 500px) {
          .option-item {
            display: block;
            margin: 10px 0;
          }

          .options {
            padding: 20px 10px;
            top: 0;
            right: auto;
            overflow: auto;
            transform: translateX(-10%);
            display: none;
          }

          .options.active {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

const CHCustomizeOverlay = ({
  show,
  onBack,
  viewOnly
}: {
  show?: boolean;
  onBack: () => void;
  viewOnly?: boolean;
}) => {
  const dispatch = useDispatch();

  const mirrorAction = (action: AnyAction) => {
    wrapActionToBeMirroring(dispatch, action, { to: '' });
  };

  const acc = useSelector(
    (state: IMainState) => state.clientState?.productAccessories || {}
  );

  const { straps, selectedStrap, selectedDial, activeSection } = acc;

  const hotspot = useSelector(
    (state: IMainState) => state.clientState?.vb?.pixelStreaming?.focusedObject
  );

  const storeId = useSelector(
    (state: IMainState) => state.clientState?.store?.id
  );

  const shouldShowHotspotNav = !!hotspot;

  const { t, i18n } = useTranslation();

  const { language } = i18n;
  const strapSpecs = getProductSpecifications(selectedStrap, language);

  React.useEffect(() => {
    if (!straps?.length) {
      handleBack();
    }
  }, [straps]);

  React.useEffect(() => {
    if (selectedStrap?.modelCode) {
      let command = `STRAP_${selectedStrap.modelCode}`;
      const dialStrap = `${hotspot?.id?.toUpperCase()}_${
        selectedStrap.modelCode
      }`;
      if (strapBucklePairs[dialStrap]) {
        command += `_${strapBucklePairs[dialStrap]}`;
      }
      sendCommandToPS(command);
    }
  }, [selectedStrap?.modelCode]);

  const closeTab = () => {
    mirrorAction(actionSelectAccessoriesSection(undefined));
  };

  const resetStrap = () => {
    if (!selectedStrap?.id) return;
    handleSelectStrap(null);
    sendCommandToPS('RESET');
  };

  const handleClickResetStrap = () => {
    logClickButton('RESET_STRAP');
    resetStrap();
  };
  const handleBack = () => {
    resetStrap();
    setTimeout(() => {
      onBack();
      closeTab();
    }, 100);
  };

  const handleClickBack = () => {
    logClickButton('BACK_BUTTON');
    handleBack();
  };

  const handleClickDetail = (storeId: string) => {
    logClickButton('MORE_DETAILS');
    dispatch(
      getPopupAction(
        `${location.protocol}//${location.host}`,
        PageView.PRODUCT,
        selectedDial.id,
        chopardBrandId,
        storeId
      )
    );
  };

  const handleSelectStrap = (strap: IProduct) => {
    if (!strap) {
      mirrorAction(dispatch(actionSelectProductStrap(null)));
    } else {
      if (strap?.id !== selectedStrap?.id) {
        mirrorAction(dispatch(actionSelectProductStrap(strap)));
        logEvent(DID_SELECT_PRODUCT_STRAP, DID_SELECT_PRODUCT_STRAP, {
          product: objectSpotMap[hotspot?.id],
          selectedStrap: strap.id,
          modelCode: strap.modelCode,
          availableStraps: straps.map((s) => s.id)
        });
      }
    }
  };

  const selectStrapTab = () => {
    mirrorAction(dispatch(actionSelectAccessoriesSection(`#strapSection`)));
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      hotspot: hotspot?.id,
      button: 'strap'
    });
  };

  const showOption = (show?: boolean) => {
    if (show) {
      selectStrapTab();
    } else {
      closeTab();
    }
  };

  const specButtons = (
    <>
      <div className="sprtr" />
      <div
        className={`btn-spec chopard-sans ${!straps?.length ? 'disable' : ''}`}
      >
        {t('vb:strap')}
        <span>
          {strapSpecs?.name
            ? strapSpecs?.name
            : CHTranslation['pleaseSelectStrap']?.[language]}
        </span>
      </div>
      <div className="sprtr" />
    </>
  );

  return (
    <div
      className={`ps-nav-buttons noselect ${show ? 'show' : ''}`}
      onMouseEnter={() => showOption(true)}
      onMouseLeave={() => showOption(false)}
    >
      <div className="backdrop right-backdrop" />
      <div className="backdrop left-backdrop" />
      <div className="options-container">
        <AccessoriesOptions
          show={activeSection === '#strapSection' && show}
          items={straps}
          onSelect={handleSelectStrap}
          selectedItem={selectedStrap?.id}
        />
      </div>

      <div className="hotspot-nav xs-only">
        <div className="btn-spec chopard-sans">
          {t('vb:strap')}
          <span>
            {strapSpecs?.name
              ? strapSpecs?.name
              : CHTranslation['pleaseSelectStrap']?.[language]}
          </span>
        </div>
      </div>

      <div className="hotspot-nav md-only">
        <div className="spec-btns  md-only">{specButtons}</div>
      </div>
      <div className="hotspot-nav ls-only">
        <div className="spec-btns">{specButtons}</div>
      </div>
      <div className="nav-wrapper">
        <div className="hotspot-nav">
          {!viewOnly && (
            <div
              className={`btn-spec chopard-sans ${
                !selectedStrap?.id ? 'disabled' : ''
              }`}
              onClick={handleClickResetStrap}
            >
              {t('vb:reset')}
            </div>
          )}

          <div className="spec-btns lg-only">{specButtons}</div>

          {!viewOnly && (
            <div
              className="btn-spec chopard-sans"
              onClick={() => handleClickDetail(storeId)}
            >
              {t('more_details')}
            </div>
          )}
        </div>

        {!viewOnly && (
          <div className="nav-back">
            <button
              className="btn-back btn chopard-sans"
              onClick={handleClickBack}
            >
              <span>{t('back')}</span>
              <CHNavArrowIcon direction="down" animated={true} />
            </button>
          </div>
        )}
      </div>
      {!viewOnly && (
        <div className="nav-back ls-only">
          <div
            className="btn-spec chopard-sans"
            onClick={() => handleClickDetail(storeId)}
          >
            {t('more_details')}
          </div>
          <div
            className={`btn-spec chopard-sans ${
              !selectedStrap?.id ? 'disabled' : ''
            }`}
            onClick={resetStrap}
          >
            {t('vb:reset')}
          </div>

          <button
            className="btn-back btn chopard-sans"
            onClick={handleClickBack}
          >
            <span>{t('back')}</span>
            <CHNavArrowIcon direction="down" animated={true} />
          </button>
        </div>
      )}
      <style jsx>{`
        .hide-hotspot-nav .hotspot-nav {
          display: none;
        }
        .ps-nav-buttons {
          left: 0;
          position: absolute;
          opacity: 0;
          right: 0;
          bottom: 0;
          padding: 1.5em 0;
          z-index: 3;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          pointer-events: none;
          transition: all 0.2s linear;
        }

        .backdrop {
          display: none;
        }

        .ps-nav-buttons.show {
          pointer-events: ${viewOnly ? 'none' : 'auto'};
          opacity: 1;
        }

        :global(.in-meeting) .ps-nav-buttons {
          padding-bottom: ${viewOnly ? '2rem' : '4rem'};
        }
        @media (max-width: 940px) {
          :global(.in-meeting) .ps-nav-buttons {
            padding-bottom: ${viewOnly ? '3rem' : '1.5rem'};
            font-size: 14px;
          }
        }

        .ps-nav-buttons :global(.btn):focus {
          box-shadow: none;
        }
        .nav-wrapper {
          width: min(1300px, 90%);
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          opacity: 1;
          justify-content: center;
        }
        :global(.btn) {
          margin: 0 4px;
          border-radius: 0px;
          color: white;
          cursor: pointer;
        }

        .btn-back {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          gap: 7px;
          font-size: 14px;
          justify-content: center;
          color: #fff;
        }
        .hotspot-nav {
          display: ${shouldShowHotspotNav ? 'flex' : 'none'};
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          padding: 10px 0 20px;
          border-bottom: ${viewOnly ? 'none' : ' 1px solid #fff'};
        }
        .icon-arrow {
          order: 1;
          width: 100%;
          margin-bottom: 20px;
          cursor: pointer;
        }

        .hotspot-nav :global(.btn-next) {
          margin-left: 20px;
        }
        .hotspot-nav :global(.btn-previous) {
          margin-right: 20px;
        }
        .hide-prev-next :global(.btn-next),
        .hide-prev-next :global(.btn-previous) {
          display: none;
        }
        .options-container {
          position: relative;
          width: 100%;
          margin: 0 auto;
        }

        :global(.btn-spec) {
          color: #fff;
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
          font-size: 1rem;
          cursor: pointer;
        }

        :global(.btn-spec span) {
          display: block;
          font-size: 0.9em;
        }

        :global(.sprtr) {
          height: 40px;
          width: 1px;
          background-color: ${viewOnly ? `transparent` : '#fff'};
        }
        :global(.btn-spec.active) {
          text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
        }

        .hotspot-nav.xs-only {
          width: calc(100% - 40px);
          margin: auto;
          padding: 0 0 10px;
        }
        .ls-only {
          display: none;
        }
        @media (min-width: 939px) and (min-height: 500px) {
          .btn-spec {
            padding: 0 20px;
            width: 20%;
          }

          :global(.disabled) {
            pointer-events: none;
            opacity: 0.6;
          }

          :global(.spec-btns.lg-only) {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          :global(.spec-btns.lg-only .btn-spec) {
            width: 50%;
          }
          .md-only,
          .xs-only {
            display: none;
          }
        }
        @media (max-width: 940px) {
          .nav-wrapper .hotspot-nav {
            border: none;
            position: fixed;
            bottom: 0;
            left: 20px;
            right: 20px;
            justify-content: space-between;
          }
          .lg-only {
            display: none;
          }
          .hotspot-btns .btn {
            margin: 5px 0px !important;
          }
          .hotspot-nav :global(.btn-next) {
            margin-left: 5px;
          }
          .hotspot-nav :global(.btn-previous) {
            margin-right: 5px;
          }
          .md-only .spec-btns {
            display: flex;
            width: 100%;
          }
          .md-only.hotspot-nav {
            margin-left: 20px;
            margin-right: 20px;
          }
          :global(.sprtr:first-child),
          :global(.sprtr:last-child) {
            display: none;
          }
          .md-only .spec-btns :global(.btn-spec) {
            width: 100%;
          }
        }
        @media (min-width: 600px) {
          .xs-only {
            display: none;
          }
        }

        @media (max-width: 600px) {
          .md-only {
            display: none;
          }
        }

        @media (orientation: landscape) and (max-height: 500px) {
          .show .backdrop {
            position: fixed;
            pointer-events: none;
            top: 0;
            bottom: 0;
            display: block;
          }
          .right-backdrop {
            right: 0;
            background: linear-gradient(
              -90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            width: 150px;
          }
          .left-backdrop {
            left: 0;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            width: 200px;
          }
          .nav-wrapper,
          .nav-back,
          .md-only {
            display: none;
          }
          .options-container {
            position: fixed;
            bottom: 0;
            top: 0;
            left: 170px;
            right: auto;
            width: auto;
          }
          .nav-back.ls-only {
            display: flex;
            position: fixed;
            right: 20px;
            bottom: 50px;
            top: 50px;
            flex-direction: column;
            justify-content: space-between;
          }
          .ps-nav-buttons {
            padding: 0.9em 0;
            background: none;
          }
          .hotspot-btns {
            position: fixed;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            flex-direction: column;
            display: flex;
          }
          .hotspot-btns .btn {
            margin: 5px 24px !important;
            min-width: 120px;
            font-size: 14px;
          }

          .hotspot-nav {
            margin-bottom: 0;
          }
          .nav-wrapper {
            flex-direction: row;
            align-items: center;
            width: 100vw;
            padding: 0 20px;
            justify-content: space-between;
            background: linear-gradient(
              -90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
            );
          }
          .nav-back {
            order: 1;
          }
          .hotspot-nav {
            order: 2;
          }
          .ps-nav-buttons :global(.horizontal-scroll) {
            white-space: unset;
          }
          .spec-btns {
            flex-direction: column;
          }
          .hotspot-nav.ls-only {
            display: flex;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            width: 170px;
            padding: 20px;
            border: none;
          }
          .hotspot-nav.ls-only :global(.sprtr) {
            display: none;
          }
          .hotspot-nav.ls-only :global(.spec-btns) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
      `}</style>
    </div>
  );
};

export default CHCustomizeOverlay;

import React from 'react';
import { logEvent } from '../../../../analytics';
import { DID_CHANGE_PRODUCT_COLOR } from '../../../../utils/constants';
import { useLoubiFutureState } from '../../../hooks/loubiFuture';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';

interface ColorOption {
  colorUrl: string;
  itemId: number;
}

const ColorChooser = ({
  options,
  selectedColor,
  onSelectColor
}: {
  options?: ColorOption[];
  selectedColor?: any;
  onSelectColor?: (index: number) => void;
}) => {
  const productId = useLoubiFutureState().selectedProductId;
  if (!options.length) return null;
  return (
    <div className="color-container noselect">
      {options.map((opt) => (
        <div
          key={opt.itemId}
          className={`color ${selectedColor === opt.itemId ? 'active' : ''}`}
          onClick={() => {
            logEvent(DID_CHANGE_PRODUCT_COLOR, DID_CHANGE_PRODUCT_COLOR, {
              productId
            });
            onSelectColor(opt.itemId);
          }}
        >
          <div>
            <img src={opt.colorUrl} alt="" />
          </div>
        </div>
      ))}
      <style jsx>{`
        .color-container {
          position: fixed;
          bottom: 3vh;
          left: 0;
          right: 0;
        }
        :global(.in-meeting.MDLandscape) .color-container {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
          bottom: 60px;
        }
        :global(.in-meeting.MDPortrait) .color-container {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom + 60}px;
        }
        :global(.in-meeting.SMLandscape) .color-container {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
          bottom: 10px;
        }
        :global(.in-meeting.SMPortrait) .color-container {
          bottom: ${SMPortraitNormalSpec.contentArea.bottom + 60}px;
        }
        .color {
          width: auto;
          height: auto;
          display: inline-block;
          border-radius: 100%;
          padding: 2px;
          margin: 0 12px;
          border: 1px solid #fff;
        }
        .color.active {
          box-shadow: 0 0 5px 5px #fff;
        }
        .color > div {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
        }
        img {
          width: 100%;
          height: auto;
        }
      `}</style>
    </div>
  );
};

export default ColorChooser;

import React from 'react';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { LoubiFutureScene } from '../../../../interfaces/loubifuture';

const LF360Veil = ({ scene }: { scene: LoubiFutureScene }) => {
  const [show, setShow] = React.useState(false);
  const [render, setRender] = React.useState(0);
  React.useEffect(() => {
    if (
      scene === LoubiFutureScene.ArpoadorA2 ||
      scene === LoubiFutureScene.ViewingRoom
    ) {
      setTimeout(() => {
        setRender(render + 1);
      }, 1000);
    }
  }, [scene]);

  React.useEffect(() => {
    if (render && render <= 2) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [render]);

  if (!show) return null;

  return (
    <div className="fixed-full content-middle">
      <img src={`${LFAssetBaseUrl}/icons/v1/360_white.svg`} />

      <style jsx>{`
        .fixed-full {
          background: rgba(0, 0, 0, 0.5);
          -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          pointer-events: none;
        }
        img {
          width: 200px;
          height: auto;
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }
          20%,
          80% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          20%,
          80% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @media (min-width: 768px) {
          img {
            width: 300px;
          }
        }
      `}</style>
    </div>
  );
};

export default React.memo(LF360Veil);

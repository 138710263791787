import React from 'react';
import {
  CHScenes,
  StreamConnectionStatus,
  StreamPlayerStatus
} from './PSInterface';
import { useDispatch } from 'react-redux';
import { actionDidGoToScene } from '../../redux/actions';
import { getPSLandingComponent } from '../storeComponentFactory';

const PSLanding = ({
  onClose,
  streamStatus,
  entrance,
  playerStatus,
  storeId
}: {
  onClose: () => void;
  streamStatus?: StreamConnectionStatus;
  entrance: () => void;
  playerStatus?: StreamPlayerStatus;
  storeId?: string;
}) => {
  const dispatch = useDispatch();

  const [isExiting, setIsExiting] = React.useState(false);
  const isConnecting = streamStatus === StreamConnectionStatus.CONNECTING;
  const isStreamPlaying = playerStatus === StreamPlayerStatus.PLAYING;

  const handleExit = () => {
    entrance();
    setTimeout(() => {
      setIsExiting(true);
    }, 1000);
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  React.useEffect(() => {
    if (isStreamPlaying) {
      handleExit();
    }
  }, [isStreamPlaying]);

  const shouldShowPS =
    (streamStatus === StreamConnectionStatus.PAGE_LOADED ||
      playerStatus === StreamPlayerStatus.NEED_INTERACTION) &&
    !isConnecting &&
    !isExiting;

  React.useEffect(() => {
    if (shouldShowPS) {
      dispatch(actionDidGoToScene(CHScenes.SCENE_ENTER_TO_CONNECT));
    }
  }, [shouldShowPS]);

  React.useEffect(() => {
    dispatch(actionDidGoToScene(CHScenes.SCENE_LANDING));
  }, []);

  const loading =
    [
      StreamConnectionStatus.INITIAL,
      StreamConnectionStatus.FINDING_SERVER,
      StreamConnectionStatus.SERVER_FOUND,
      StreamConnectionStatus.CONNECTED
    ].includes(streamStatus) &&
    playerStatus !== StreamPlayerStatus.NEED_INTERACTION;

  const landingComponent = getPSLandingComponent(
    storeId,
    loading,
    isConnecting,
    isStreamPlaying
  );
  return (
    <div className="PSLanding d-flex">
      {landingComponent}

      <style jsx>{`
        .PSLanding {
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          pointer-events: none;
        }
        .btn {
          width: 200px;
        }

        :global(#pixelStreamWrapper) {
          background: #000;
        }

        :global(iframe.pixel-stream-layer) {
          z-index: ${shouldShowPS ? '2' : '0'};
          transition: opacity 0.3s ease;
        }
      `}</style>
    </div>
  );
};

export default PSLanding;

import React from 'react'
import { logEvent } from '../../../../../analytics';
import { IProduct } from '../../../../../interfaces';
import { DID_CLICK_PRODUCT_SIZE_BUTTON } from '../../../../../utils/constants';
import LJNestedButton from '../Buttons/LJNestedButton';
import { getLJProductOption } from '../LJProductDetails';


const LJSizeNestedOptions = ({
    product,
    selectedOption,
    onClickOption
  }: {
    product: IProduct;
    selectedOption: string;
    onClickOption: (id: string, option: string) => void;
  }) => {
    const options = getLJProductOption(product)?.options;
  
    return (
      <>
        {!!options?.length && (
          <div className="options-container">
            <LJNestedButton
              title={selectedOption ? 'SIZE: ' + selectedOption : 'SELECT SIZE'}
              inlineButton={true}
              buttons={options.map((option) => ({
                title: option,
                onClick: () => {
                  onClickOption(product?.id, option);
                  logEvent(
                    DID_CLICK_PRODUCT_SIZE_BUTTON,
                    DID_CLICK_PRODUCT_SIZE_BUTTON,
                    {
                      product: product.id,
                      size: option
                    }
                  );
                },
                active: selectedOption === option
              }))}
            />
          </div>
        )}
  
        <style jsx>{`
          .options-container {
            position: relative;
            height: 40px;
            z-index: 1;
          }
          .options-container :global(.button-container) {
            position: absolute;
            top: 0;
            left: 10px;
            right: 10px;
            border-radius: 1rem !important;
          }
          .btn-option {
            border-radius: 10px !important;
            padding-left: 5px !important;
            color: #cf152d !important;
            padding-right: 5px !important;
          }
          .btn-option.active {
            background: #bf080e !important;
            color: #fff !important;
          }
          .options-container :global(.nested-container) {
            padding-bottom: 10px !important;
          }
          .options-container :global(.nested-button),
          .options-container :global(.btn) {
            background: #f3f3d1;
            font-family: 'AntiqueOlive-Bold', Arial;
          }
          .options-container :global(.nested-button) {
            color: #cf152d !important;
          }
        `}</style>
      </>
    );
  };

export default LJSizeNestedOptions

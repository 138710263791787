import React from 'react';

const EmptyLanding = ({ onDismiss }: { onDismiss: () => void }) => {
  React.useEffect(() => {
    onDismiss();
  }, []);
  return null;
};

export default EmptyLanding;

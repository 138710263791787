import React from 'react';
import { IStore, IVirtualBoutiqueConfig } from '../../interfaces';

import {
  chopardHappySportGenevaHQ,
  chopardHappySportGenevaHQTraining,
  chopardHappySportKorea
} from '../../config';

import CHPixelStreaming from './Chopard/CHPixelStreaming';
import PixelStream from './PixelStream';

const PixelStreamExperience = ({
  store,
  config
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
}) => {
  if (
    [
      chopardHappySportKorea,
      chopardHappySportGenevaHQ,
      chopardHappySportGenevaHQTraining
    ].includes(store.id)
  ) {
    return <CHPixelStreaming store={store} config={config} />;
  }

  return <PixelStream store={store} config={config} />;
};

export default PixelStreamExperience;

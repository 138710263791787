import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { getInspirationsByBrand } from '../../../clientSideServices/inspirations';
import { useTranslation } from '../../../i18n';
import {
  IInspiration,
  IMainState,
  IVirtualBoutiqueConfig
} from '../../../interfaces';
import { DID_OPEN_POPUP_IN_LOUNGE } from '../../../utils/constants';
import { getPopupAction, PageView } from '../../../utils/pageView';
import InspirationsSlider from '../../Inspiration/InspirationsSlider';

const LoungeInspirations = ({
  config,
  storeId
}: {
  config: IVirtualBoutiqueConfig;
  storeId: string;
}) => {
  const dispatch = useDispatch();
  const [inspirations, setInspirations] = React.useState<IInspiration[]>(null);
  const [isLoadingInspirations, setIsLoadingInspirations] =
    React.useState<boolean>(false);

  const popupState = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );

  const brandId = config.brandId;

  const openPopup = (type: PageView, id: string) => {
    console.log(`will open popup for type ${type} with id ${id}`);
    logEvent(DID_OPEN_POPUP_IN_LOUNGE, type, { id });
    const action = getPopupAction(
      `${location.protocol}//${location.host}`,
      type,
      id,
      config.brandId,
      storeId
    );
    if (action) {
      dispatch(action);
    }
  };

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setIsLoadingInspirations(true);
    getInspirationsByBrand({
      id: brandId,
      language: i18n.language
    })
      .then((result) => {
        setInspirations(result.data);
      })
      .finally(() => {
        setIsLoadingInspirations(false);
      })
      .catch((e) => console.log(e));
  }, [brandId]);

  return (
    <div className="loungeInspirations" id="inspirationsSection">
      {isLoadingInspirations && (
        <div className="text-center placeholder">
          <Spinner animation="border" />
        </div>
      )}
      {inspirations && inspirations.length > 0 && (
        <InspirationsSlider
          loading={isLoadingInspirations}
          sectionTitle={t('inspirations').toUpperCase()}
          inspirations={inspirations}
          onClickThumbnail={(id: string) => openPopup(PageView.INSPIRATION, id)}
        />
      )}
      <style jsx>{`
        :global(body) {
          overflow: ${popupState?.open ? 'hidden' : 'auto'};
        }
        :global(.product-inspirations .separator) {
          display: none !important;
        }
        .placeholder {
          padding: 100px 0;
        }
        .loungeInspirations {
          background: #fff;
          position: relative;
        }
      `}</style>
    </div>
  );
};

export default LoungeInspirations;

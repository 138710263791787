import React from 'react';
import { logClickButton, logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { DID_GO_TO_SCENE } from '../../../utils/constants';
import CHStatusScreen from './CHStatusScreen';

const CHDisconnectScreen = ({
  isPortrait,
  onReconnect,
  isDisconnected
}: {
  isPortrait: boolean;
  onReconnect: () => void;
  isDisconnected: boolean;
}) => {
  const { t } = useTranslation();
  const description = isDisconnected
    ? 'experience_disconnected'
    : 'experience_not_available';
  const buttonText = isDisconnected ? t('reconnect') : t('retry');

  React.useEffect(() => {
    logEvent(DID_GO_TO_SCENE, DID_GO_TO_SCENE, {
      id: 'DISCONNECT_SCREEN',
      description: description
    });
  }, []);

  return (
    <CHStatusScreen
      useImage={true}
      show={true}
      isPortrait={isPortrait}
      actionElement={
        <>
          <p className="chopard-sans">{t(description)}</p>
          <button
            className="btn btn-action btn-outline-light"
            onClick={()=>{
              onReconnect();
              logClickButton('RECONNECT_BUTTON');
            }}
          >
            {buttonText}
          </button>
        </>
      }
    />
  );
};

export default CHDisconnectScreen;

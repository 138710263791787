import React from 'react';
import { logEvent } from '../../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../../utils/constants';
import { LFColor } from '../../../../ThreejsAnimation/loubiFuture/assets';
const LFButton = ({
  children,
  name,
  onClick,
  size = 'normal',
  className = '',
  style = {},
  fullWidth,
  disabledDefaultLog
}: {
  children: React.ReactNode;
  name: string;
  onClick: () => void;
  size?: 'sm' | 'normal' | 'lg';
  className?: string;
  style?: { [key: string]: any };
  fullWidth?: boolean;
  disabledDefaultLog?: boolean;
}) => {
  return (
    <button
      className={`btn btn-${size} ${fullWidth ? 'w-100' : ''} ${className}`}
      onClick={() => {
        if (!disabledDefaultLog) {
          logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: name });
        }
        onClick();
      }}
      style={style}
    >
      <div className="inner-line" />
      <span>{children}</span>
      <style jsx>{`
        .btn {
          outline: none;
          box-shadow: none;
          text-transform: uppercase;
          border: 2px solid #13fff1;
          box-shadow: 0px 1px 5px #13fff1, #13fff1 0px 0px 5px inset;
          padding: 0;
          border-radius: 5px;
          position: relative;
          border-radius: 100px;
        }
        .inner-line {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: -1.2px;
          z-index: 9999;
          border: 0.5px solid #b663e0;
          border-radius: inherit;
        }
        .btn span {
          display: block;
          padding: 0.4rem 0.8rem;
          font-size: 1rem;
          letter-spacing: 0.06em;
          font-family: 'LF-Regular', sans-serif;
          color: ${LFColor.blue};
          text-shadow: -1px -1px 0 ${LFColor.purple},
            1px -1px 0 ${LFColor.purple}, -1px 1px 0 ${LFColor.purple},
            1px 1px 0 ${LFColor.purple};
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        .btn-sm span {
          padding: 0.3rem 0.6rem;
          font-size: 0.8rem;
        }
        .btn-lg span {
          padding: 0.6rem 0.75rem;
          font-size: 1.2rem;
        }
      `}</style>
    </button>
  );
};
export default LFButton;

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { IMainState, ParticipantMeetingState } from '../../interfaces';
import { LoubiFutureScene } from '../../interfaces/loubifuture';

export const useLoubiFutureState = () =>
  useSelector((state: IMainState) => state.clientState.loubiFuture || {});

export const shouldRenderMenu = () => {
  const state = useLoubiFutureState();
  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const router = useRouter();
  const isInMeeting = router.query.mode === 'cockpit';

  return (
    !state?.popup &&
    !state?.map?.open &&
    !state?.selectedProductId &&
    !state?.wishlist?.open &&
    (!isInMeeting ||
      (meetingState?.isPresenter &&
        meetingState?.state === ParticipantMeetingState.ENTERED_FROM_LOUNGE)) &&
    (state.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2 ||
      state.canvasState?.currentScene === LoubiFutureScene.ViewingRoom)
  );
};

import React from 'react';
import { BsEye, BsEyeFill } from 'react-icons/bs';

const FocusButton = ({
  onFocus,
  focusedItem,
  itemId,
  className = ''
}: {
  onFocus: (id: string) => void;
  itemId: string;
  focusedItem?: string;
  className?:string;
}) => {
  return (
    <button className={`focus-btn ${className}`} onClick={() => onFocus(itemId)}>
      {focusedItem === itemId ? (
        <BsEyeFill className="filled-eye" />
      ) : (
        <BsEye />
      )}
      <style jsx>{`
        .focus-btn {
          -webkit-appearance: button;
          border: none;
          text-transform: uppercase;
          background: transparent;
          font-size: 30px;
        }
        .focus-btn :global(.filled-eye) {
          fill: #1a8824;
        }
      `}</style>
    </button>
  );
};

export default FocusButton;

import React from 'react';
import { logEvent } from '../../../../analytics';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { DID_CLICK_SHOW_APROADOR_INFO } from '../../../../utils/constants';

const ArpoadorInfoButton = ({
  higherButton,
  inMeeting,
  onClick
}: {
  higherButton?: boolean;
  inMeeting?: boolean;
  onClick?: () => void;
}) => {
  return (
    <>
      <img
        src={`${LFAssetBaseUrl}/icons/v1/cross_i_white.svg`}
        alt="Info"
        className="info-button"
        onClick={() => {
          logEvent(DID_CLICK_SHOW_APROADOR_INFO, DID_CLICK_SHOW_APROADOR_INFO);
          onClick();
        }}
      />
      <style jsx>{`
        .info-button {
          position: fixed;
          width: 54px;
          height: auto;
          left: 2px;
          bottom: ${higherButton ? '150px' : '70px'};
          cursor: pointer;
        }

        @media (min-width: 768px) {
          .info-button {
            width: 80px;
            left: auto;
            right: 0;
            bottom: ${inMeeting ? '100px' : '6px'};
          }
        }
      `}</style>
    </>
  );
};

export default ArpoadorInfoButton;

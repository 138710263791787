import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import {
  ILoubiFutureState,
  ISoundEffect,
  LoubiFutureScene
} from '../../../../interfaces/loubifuture';
import {
  actionLFPlaySoundEffect,
  actionUpdateLoubiFutureCanvasState
} from '../../../../redux/customActions/loubiFuture';
import { DID_START_FULL_SCREEN_VIDEO } from '../../../../utils/constants';
import { isUserOnWeChat } from '../../../../utils/deviceDetector';
import { useLoubiFutureState } from '../../../hooks/loubiFuture';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { urlVideo } from '../../../ThreejsAnimation/loubiFuture/assets';

export const LF_FADE_IN_SPEED = 1000;

const LoubiFutureFullScreenVideo = () => {
  const dispatch = useDispatch();
  const state: ILoubiFutureState = useLoubiFutureState();
  const currentScene = state?.canvasState?.currentScene;
  const soundEffect = state?.soundEffect || {};
  const [showVideo, setShowVideo] =
    React.useState<undefined | boolean>(undefined);
  const [playVideo, setPlayVideo] = React.useState(false);
  const [isWeChat, setIsWeChat] = React.useState(false);
  const [wrapperBackground, setWrappedBackground] = React.useState('black')

  const fadeOutDuration = 1000;


  const getNextScene = () => {
    switch (currentScene) {
      case LoubiFutureScene.ArpoadorA1:
        return LoubiFutureScene.ArpoadorA2;
      case LoubiFutureScene.ArpoadorToViewingroom:
        return LoubiFutureScene.ViewingRoom;
      case LoubiFutureScene.ViewingroomToArpoador:
        return LoubiFutureScene.ArpoadorA2;
      default:
        return;
    }
  };
  const onVideoEnded = () => {
    setShowVideo(false);
    setTimeout(() => {
      setPlayVideo(false);
      setShowVideo(undefined);
    }, fadeOutDuration);

    const nextScene = getNextScene();
    if (nextScene) {
      dispatch(
        actionUpdateLoubiFutureCanvasState({
          currentScene: nextScene
        })
      );
    }
  };

  const getVideoUrl = () => {
    switch (currentScene) {
      case LoubiFutureScene.ViewingRoom:
      case LoubiFutureScene.ArpoadorToViewingroom: {
        return urlVideo.arpoadorToViewingroom;
      }
      case LoubiFutureScene.ViewingroomToArpoador:
        return urlVideo.viewingroomToArpoador;
      default:
        return urlVideo.arpoadorA1;
    }
  };

  React.useEffect(() => {
    const statesToPlayVideo = [
      LoubiFutureScene.ArpoadorA1,
      LoubiFutureScene.ArpoadorToViewingroom,
      LoubiFutureScene.ViewingroomToArpoador
    ];

    if (statesToPlayVideo.includes(currentScene)) {
      setShowVideo(true);
      setTimeout(() => {
        setPlayVideo(true);
        if(wrapperBackground === 'black'){
          setWrappedBackground('transparent')
        }
      }, LF_FADE_IN_SPEED);
    }
  }, [currentScene]);

  React.useEffect(() => {
    setIsWeChat(isUserOnWeChat());
  }, []);

  const setSoundState = (payload: ISoundEffect) => {
    dispatch(actionLFPlaySoundEffect(payload));
  };

  const handleSetSoundEffect = (seconds: number) => {
    const roundedSeconds = Math.floor(seconds);
    switch (currentScene) {
      case LoubiFutureScene.ArpoadorA1: {
        if(roundedSeconds === 2){
          setSoundState({ enteringSectionA: true, water: true });
        }
        break;
      }
      case LoubiFutureScene.ArpoadorA2: {
        setSoundState({ enteringSectionA: false });
        if (!soundEffect.water) {
          setSoundState({ dome: false, water: true });
        }
        break;
      }
      case LoubiFutureScene.ArpoadorToViewingroom: {
        if (roundedSeconds === 3) {
          setSoundState({ water: false, rainbowLight: true });
        }
        if (roundedSeconds === 6) {
          setSoundState({ water: true, afterRainbow: true });
        }
        if (roundedSeconds === 18) {
          setSoundState({
            afterRainbow: false,
            elevatorUp: true,
            water: false
          });
        }
        break;
      }
      case LoubiFutureScene.ViewingroomToArpoador: {
        setSoundState({ dome: false });
        break;
      }
      default:
        return;
    }
  };
  const videoUrl = getVideoUrl();

  return (
    <div
      className={`video-wrapper  ${
        showVideo ? 'showing' : showVideo !== undefined ? 'hiding' : ''
      }`}
    >
      <div className="opacity-wrapper">
        <ReactPlayer
          key={videoUrl}
          className={`full-screen-video`}
          playing={playVideo}
          width="100%"
          height="100%"
          autoPlay
          playsinline
          controls={isWeChat}
          muted={true}
          url={videoUrl}
          loop={false}
          onProgress={({ playedSeconds }) => {
            handleSetSoundEffect(playedSeconds);
          }}
          onStart={() => {
            logEvent(DID_START_FULL_SCREEN_VIDEO, DID_START_FULL_SCREEN_VIDEO, {
              videoUrl
            });
          }}
          onEnded={onVideoEnded}
          onError={(e) => {
            console.log(e);
          }}
        />
      </div>
      <style jsx>
        {`
          .video-wrapper,
          :global(.full-screen-video) {
            pointer-events: none;
          }
          :global(.full-screen-video) {
            background: #000;
          }


          .opacity-wrapper {
            width: 100%;
            height: 100%;
          }
          .video-wrapper {
            position: fixed;
            z-index: 20;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            pointer-events: none;
            background:${wrapperBackground};
          }
        
          .video-wrapper.hiding {
            background: transparent;
          }

          .opacity-wrapper {
            opacity: 0;
          }

          .video-wrapper.showing .opacity-wrapper {
            opacity: 1;
            transition: opacity ${LF_FADE_IN_SPEED * 0.001}s ease-in;
          }

          .video-wrapper.hiding .opacity-wrapper {
            opacity: 0;
            transition: opacity ${fadeOutDuration * 0.001}s ease-in;
          }

          :global(video) {
            width: 100%;
            object-fit: cover;
          }
          :global(.in-meeting.SMLandscape .video-wrapper video) {
            margin-left:${SMLandscapeNormalSpec.contentArea.left/ 2}px;
          }
          :global(.in-meeting.MDLandscape .video-wrapper video) {
            margin-left:${MDLandscapeNormalSpec.contentArea.left/ 2}px;
          }
        `}
      </style>
    </div>
  );
};

export default LoubiFutureFullScreenVideo;

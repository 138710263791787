import React from 'react';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { IMainState, IWishlistItem, MeetingRole } from '../../interfaces';
import {
  actionAddToWishlistAsync,
  actionRemoveFromWishlistAsync
} from '../../redux/asyncActions';
import {
  DID_ADD_TO_WISHLIST,
  DID_REMOVE_FROM_WISHLIST
} from '../../utils/constants';
import { shouldShowWishlist } from '../../utils/entitlements';
import { AppContext } from '../AppContext';
import { Spinner } from 'react-bootstrap';
import {
  getParentWindowMeetingRole,
  isParentWindowInMeeting
} from '../../utils/window';
import { actionAdvisorAddToWishlist } from '../../redux/actions';
import { useTranslation } from '../../i18n';
import Link from '../Common/Link';

interface Props {
  addLabel?: string;
  successLabel?: string;
  item: IWishlistItem;
  showIcon?: boolean;
}

const AddToWishlist = ({ item, addLabel, successLabel, showIcon }: Props) => {
  const { storeConfig, store } = React.useContext(AppContext);
  const [inProgress, setInProgress] = React.useState(false);
  const [isAdvisorMeeting, setIsAdvisorMeeting] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const existingItems: IWishlistItem[] = useSelector(
    (state: IMainState) => state.clientState.wishlist?.wishlistItems || []
  );

  const hasAdded = existingItems.some((w) => w.itemId === item.itemId);
  const _showIcon = showIcon === undefined ? true : showIcon;
  React.useEffect(() => {
    setInProgress(false);
  }, [existingItems]);
  React.useEffect(() => {
    setIsAdvisorMeeting(
      isParentWindowInMeeting() &&
        getParentWindowMeetingRole() === MeetingRole.ADVISOR
    );
  });
  const handleAddToWishlist = () => {
    if (isAdvisorMeeting) {
      dispatch(
        actionAdvisorAddToWishlist({
          item,
          storeId: store?.id,
          brandId: storeConfig?.brandId
        })
      );
      return;
    }
    setInProgress(true);
    dispatch(actionAddToWishlistAsync(item, store?.id, storeConfig?.brandId));
    logEvent(DID_ADD_TO_WISHLIST, item.itemId, {
      itemType: item.itemType
    });
  };
  return (
    <>
      {hasAdded && !isAdvisorMeeting ? (
        <div className="wishlist-button remove hide-for-client-meeting">
          {inProgress ? (
            <Spinner animation="border" variant="danger" size="sm" />
          ) : (
            <BsHeartFill
              color="dc3545"
              onClick={() => {
                setInProgress(true);
                dispatch(
                  actionRemoveFromWishlistAsync(
                    item,
                    store?.id,
                    storeConfig?.brandId
                  )
                );
                logEvent(DID_REMOVE_FROM_WISHLIST, item.itemId, {
                  itemType: item.itemType
                });
              }}
            />
          )}
          {successLabel && (
            <span>
              <Link
                href={`/wishlist`}
                as={`/wishlist`}
              >
                {successLabel}
              </Link>
            </span>
          )}
        </div>
      ) : (
        <div
          className="wishlist-button hide-for-client-meeting"
          onClick={handleAddToWishlist}
        >
          {_showIcon &&
            (inProgress ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <BsHeart />
            ))}
          {addLabel && (
            <span>
              {isAdvisorMeeting ? t('add_to_client_wishlist') : addLabel}
            </span>
          )}
        </div>
      )}

      <style jsx>{`
        span {
          vertical-align: middle;
          margin: 0 8px;
        }
        .wishlist-button {
          cursor: pointer;
          margin-top: ${_showIcon ? 20 : 0}px;
        }
        .wishlist-button :global(a) {
          color: inherit;
        }
        .wishlist-button:hover :global(svg) {
          -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
          animation: heartbeat 1.5s ease-in-out infinite both;
        }
        .wishlist-button:hover span {
          text-decoration: underline;
          cursor: pointer;
        }

        @-webkit-keyframes heartbeat {
          from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
        }
        @keyframes heartbeat {
          from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          10% {
            -webkit-transform: scale(1.4);
            transform: scale(1.4);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          33% {
            -webkit-transform: scale(1.4);
            transform: scale(1.4);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
        }
      `}</style>
    </>
  );
};
const WishlistButton = ({ item, addLabel, successLabel, showIcon }: Props) => {
  const { storeConfig } = React.useContext(AppContext);

  return (
    <>
      {shouldShowWishlist(storeConfig?.entitlement) ? (
        <AddToWishlist
          item={item}
          addLabel={addLabel}
          successLabel={successLabel}
          showIcon={showIcon}
        />
      ) : null}
    </>
  );
};

export default WishlistButton;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { RDUHudMenu } from '../../../../../interfaces';
import { actionDidClickHudMenu } from '../../../../../redux/actions';
import { DID_CLICK_HUD_MENU } from '../../../../../utils/constants';

const HudPirelli = ({ activeMenu }: { activeMenu?: RDUHudMenu }) => {
  const dispatch = useDispatch();

  const handleClickNav = (menu: RDUHudMenu) => {
    dispatch(actionDidClickHudMenu(menu));
    logEvent(DID_CLICK_HUD_MENU, DID_CLICK_HUD_MENU, { menu });
  };

  const getRotateDeg = () => {
    if (activeMenu === RDUHudMenu.PIRELLI_YELLOW) return 22;
    if (activeMenu === RDUHudMenu.PIRELLI_WHITE) return 0;
    return -22;
  };

  return (
    <div className="HudPirelli">
      <div className="hud-nav-container">
        <img
          src="/asset/rdu/pirellired.png"
          alt="RED"
          className="hud-nav hud-nav1"
          onClick={() => handleClickNav(RDUHudMenu.PIRELLI_RED)}
        />

        <img
          src="/asset/rdu/pirelliwhite.png"
          alt="WHITE"
          className="hud-nav hud-nav2"
          onClick={() => handleClickNav(RDUHudMenu.PIRELLI_WHITE)}
        />

        <img
          src="/asset/rdu/pirelliyellow.png"
          alt="YELLOW"
          className="hud-nav hud-nav3"
          onClick={() => handleClickNav(RDUHudMenu.PIRELLI_YELLOW)}
        />

        <img
          className="pirelli-arrow"
          src="/asset/rdu/pirelli-arrow-bg.png"
          alt=""
        />
        <img
          className="pirelli-arrow nav-arrow"
          src="/asset/rdu/pirelli-arrow.png"
          alt=""
        />
      </div>

      <style jsx>{`
        .HudPirelli {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -108px;
        }
        .hud-nav-container {
          position: relative;
          height: 212px;
          margin: auto;
          width: 300px;
        }
        .hud-nav {
          color: #fff;
          width: 60px;
          transform: scale(0.9);
          height: auto;
          z-index: 1;
          transition: all 0.1s ease-in-out;
          cursor: pointer;
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -30px;
        }

        .hud-nav:hover {
          transform: scale(1);
        }

        .hud-nav1 {
          margin-left: -120px;
        }

        .hud-nav3 {
          margin-left: 60px;
        }

        .hud-nav1,
        .hud-nav3 {
          top: 17px;
        }

        .pirelli-arrow {
          width: 400px;
          position: absolute;
          bottom: -259px;
          left: 50%;
          margin-left: -200px;
        }

        .nav-arrow {
          transition: all 0.2s linear;
          transform: rotate(${getRotateDeg()}deg);
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        :global(.frame-pirelli) {
          pointer-events: none;
        }

        :global(.frame-pirelli button.close),
        :global(.frame-pirelli .HudPirelli) {
          pointer-events: auto;
        }

        @media (max-width: 724px) {
          .HudPirelli {
            bottom: -48px;
          }
        }
      `}</style>
    </div>
  );
};

export default HudPirelli;

import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import BackToAnimationButton from '../../LouboutinCustomComponent/Buttons/BackToAnimationButton';

const LJBackToAnimationButton = ({
  onClick,
  position
}: {
  onClick: () => void;
  position?: string;
}) => {
  return (
    <BackToAnimationButton
      onClick={onClick}
      position={position}
      imageUrl={`${jpIconUrlBase}/back_with_cross_white.svg`}
    />
  );
};

export default LJBackToAnimationButton;

import { logEvent } from '../../analytics';
import { DID_CLICK_PRIVACY_POLICY } from '../../utils/constants';
import { useTranslation } from '../../i18n';

const PrivacyPolicy = ({ privacyUrl }: { privacyUrl?: string }) => {
  const inspifyPrivacyUrl = 'https://www.inspify.com/privacy-policy/';
  const { t } = useTranslation();
  return (
    <a
      href={privacyUrl || inspifyPrivacyUrl}
      target="_blank"
      onClick={() => logEvent(DID_CLICK_PRIVACY_POLICY)}
    >
      {t('privacy_policy')}
    </a>
  );
};

export default PrivacyPolicy;

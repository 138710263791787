import React from 'react';
import { logEvent } from '../../../analytics';
import { inspifySiteId, panoBaseUrl } from '../../../config';
import {
  DID_CLICK_BUTTON,
  DID_CLICK_SEND_FEEDBACK
} from '../../../utils/constants';
import ReactStars from 'react-rating-stars-component';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import gsap from 'gsap';
import { isUserOnFirefox } from '../../../utils/deviceDetector';

export type FeedbackType = 'MEETING' | 'STORYBOOK';

const meetImageUrl = {
  darkUrl: `${panoBaseUrl}/virtual_boutique/${inspifySiteId}/meet_feedback_graphic_light.png`,
  lightUrl: `${panoBaseUrl}/virtual_boutique/${inspifySiteId}/meet_feedback_graphic.png`
};

const storybookImageUrl = {
  darkUrl: `${panoBaseUrl}/virtual_boutique/${inspifySiteId}/storybook-feedback.png`,
  lightUrl: `${panoBaseUrl}/virtual_boutique/${inspifySiteId}/storybook-feedback-light.png`
};

function InspifyFeedback({
  darkTheme = true,
  type,
  id
}: {
  darkTheme?: boolean;
  type?: FeedbackType;
  id: string;
}) {
  const [hasRated, setHasRated] = React.useState(false);
  const { darkUrl, lightUrl } =
    type === 'MEETING' ? meetImageUrl : storybookImageUrl;
  const logoUrl = darkTheme ? '/asset/logo.png' : '/asset/logo-dark.png';
  const heroVideoUrl = darkTheme ? darkUrl : lightUrl;
  const videoRef = React.useRef<HTMLVideoElement>(null);
  React.useEffect(() => {
    const timeline = gsap.timeline({ paused: true });
    const animate = timeline
      .fromTo(
        '.advertising .fade',
        { opacity: 0 },
        {
          opacity: 1,
          stagger: {
            from: 'start',
            each: 0.3
          },
          onStart: () => {
            videoRef?.current?.play();
          }
        }
      )
      .fromTo(
        '.hero-img',
        {
          opacity: 0,
          y: 20,
          duration: 1
        },
        {
          opacity: 1,
          y: 0
        },
        '<'
      )
      .fromTo(
        '.ratings > *:not(.thank)',
        { opacity: 0 },
        {
          opacity: 1,
          stagger: {
            from: 'start',
            each: 0.3
          }
        },
        '-=0.5'
      );
    animate.play();
  }, []);
  return (
    <>
      <div className="feedback">
        <div className="veil row">
          <div className="advertising col-12 col-sm-6 col-md-6 col-lg-6">
            <img src={logoUrl} alt="Logo" className="logo fade" />
            <p>
              <span className="fade">
                Want to elevate your brand and leave a lasting impression on
                your customers?
              </span>
            </p>
            <p>
              <strong className="fade">Try it for free!</strong>
            </p>
            <a
              href="https://storiez.inspify.com"
              target="_blank"
              onClick={() => {
                logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                  button: 'DISCOVER INSPIFY'
                });
              }}
              className="btn btn-lg fade"
            >
              SIGN UP NOW!
            </a>
          </div>
          <div className="rating col-12 col-sm-6 col-md-6 col-lg-6">
            <div className="hero-img">
              <div className="overlay"></div>
              <div className="img-wrapper">
                <img src={heroVideoUrl} alt="inspify-graphic" />
              </div>
            </div>

            <div className="ratings">
              <p className="title">
                How would you want to rate your experience with INSPIFY?
              </p>
              <ReactStars
                classNames="stars"
                size={40}
                value={0}
                isHalf={false}
                emptyIcon={<AiOutlineStar />}
                filledIcon={<AiFillStar />}
                color="#8f6599"
                activeColor="#8f6599"
                onChange={(val: number) => {
                  setHasRated(true);
                  logEvent(DID_CLICK_SEND_FEEDBACK, DID_CLICK_SEND_FEEDBACK, {
                    maxRating: 5,
                    userRating: val,
                    type,
                    id
                  });
                }}
              />
              <p className="thank">Thank you!</p>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .feedback {
          font-family: 'Montserrat', sans-serif;
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh;
          max-height: -webkit-fill-available;
          width: 100vw;
          background: ${darkTheme ? '#000' : '#fff'};
          color: ${darkTheme ? '#fff' : '#000'};
        }
        .veil {
          height: 100%;
          width: 100vw;
          max-width: min(1400px, 100vw);
          padding: 25px;
          margin: 0 auto;
          background: transparent;
          color: ${darkTheme ? '#fff' : '#000'};
        }

        :global(.storybook) .veil {
          padding: 25px 45px;
        }

        .advertising {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;
        }

        .thank {
          opacity: ${hasRated ? '1' : '0'};
          font-size: 2.4em;
          transition: opacity 0.5s ease-in-out;
        }

        .advertising p {
          font-size: 1.2em;
          max-width: min(500px, 100%);
          text-align: left;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        .rating {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
        .rating .img-wrapper {
          width: 100%;
          transform: translateZ(0);
          filter: ${isUserOnFirefox()
            ? 'none'
            : `drop-shadow(
            ${
              darkTheme
                ? '100px 50px 250px rgba(115, 101, 153, 0.25)'
                : '10px 25px 50px rgba(143, 101, 153, 0.5)'
            }
          )`};
        }

        .img-wrapper img {
          display: block;
          width: 100%;
          height: 100%;
        }
        .ratings {
          z-index: 2;
          position: relative;
          text-align: center;
        }
        .rating .title {
          font-size: 1.1em;
          margin-top: 10px;
        }
        .logo,
        .ratings,
        .btn {
          display: block;
        }

        .logo {
          width: 240px;
        }

        .btn {
          border-radius: 0;
          font-size: 1.1em;
          font-weight: 600;
          border: 1px solid #8f6599;
          color: #fff;
          width: 180px;
          line-height: 30px;
          background-color: #8f6599;
          margin-top: 10px;
        }

        .btn:hover {
          background-color: transparent;
          color: #8f6599;
        }
        .hero-img {
          position: relative;
          z-index: 1;
        }
        .overlay {
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
        }
        :global(.react-stars) {
          outline: none;
          width: 100%;
          display: flex;
          justify-content: center;
        }
        :global(.react-stars > span) {
          margin-left: 10px;
          margin-right: 10px;
        }

        :global(.react-stars > span:hover) {
          opacity: 0.7;
        }

        @keyframes fade {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @media (min-width: 1400px) {
          .advertising p {
            font-size: 26px;
            max-width: min(580px, 100%);
          }
          .logo {
            margin-top: -50px;
          }
        }

        @media (max-width: 768px) {
          .veil {
            font-size: 14px;
          }
          .advertising {
            align-items: center;
            gap: 10px;
            padding: 20px 0;
          }
          .advertising p {
            text-align: center;
          }
          .logo {
            width: 130px;
          }
          .thank {
            font-size: 1.2em;
          }
          p {
            font-size: 14px;
          }
          :global(.react-stars > span) {
            margin-left: 2px;
            margin-right: 2px;
          }
          .btn {
            font-size: 12px;
          }
          .rating .title {
            margin-top: 0px;
          }
          .rating div.img-wrapper {
            filter: ${isUserOnFirefox()
              ? 'none'
              : `drop-shadow(
              ${
                darkTheme
                  ? '200px 10px 250px rgba(115, 101, 153, 0.25)'
                  : '10px 25px 50px rgba(143, 101, 153, 0.5)'
              }
          )`};
          }
        }

        @media only screen and (orientation: landscape) {
          .feedback {
            max-height: 100vh;
          }
        }

        @media only screen and (max-width: 768px) {
          .img-wrapper {
            max-width: 365px;
          }
        }
        @media only screen (max-height: 700px) {
          p {
            background: transparent;
          }
          .rating .title {
            margin-bottom: 0;
            box-shadow: 0px -30px 59px -7px rgba(0, 0, 0, 0.75);
            font-size: 1em;
          }
          .rating .ratings {
            margin-top: -60px;
          }
          .thank {
            font-size: 1.5em;
          }

          .rating .hero-img .overlay {
            opacity: 1;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 20%,
              ${darkTheme ? '#000' : '#fff'} 90%
            );
          }
        }
        @media only screen and (orientation: landscape) and (max-height: 500px) {
          ::global(.vb-page-wrapper) {
            overflow: auto !important;
          }
          .advertising {
            align-items: flex-start;
          }
          .advertising p {
            text-align: left;
          }
          .advertising,
          .rating {
            padding: 0 20px;
            font-size: 12px;
          }
          .advertising {
            gap: 10px;
          }
          .rating .ratings {
            margin-top: -70px;
          }
          .feedback {
            min-height: 100vh;
            max-height: unset !important;
            overflow-y: auto;
          }
          .veil {
            height: auto;
            overflow: auto;
            margin-bottom: 40px;
          }
          .rating .hero-img .overlay {
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 20%,
              #000 80%
            );
            opacity: 1;
          }
        }

        @media only screen and (orientation: landscape) and (max-height: 300px) {
          .rating .ratings {
            margin-top: -100px;
          }
          .advertising .logo {
            margin-bottom: 10px;
          }
          .rating .hero-img .overlay {
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 20%,
              #000 61%
            );
          }
        }
        @media (max-height: 700px) {
          .logo {
            width: 130px;
            margin-bottom: 30px;
          }
          .btn {
            width: 150px;
          }
          .feedback {
            min-height: 100vh;
            max-height: unset !important;
            overflow-y: auto;
          }
          .veil {
            height: auto;
            overflow: auto;
            margin-bottom: 40px;
          }
        }
      `}</style>
    </>
  );
}

export default InspifyFeedback;

import React from 'react';
import { BsX } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { mobileBreakPoint } from '../../../../../advisorHub/components/BrandStyle';
import ChatBubbleContainer from '../../../../../advisorHub/components/ContactCenter/ChatBubbleContainer';
import { logEvent } from '../../../../../analytics';
import { useTranslation } from '../../../../../i18n';
import { LiveChatConfig } from '../../../../../interfaces';
import { actionDidSelectProduct } from '../../../../../redux/actions';
import { actionToggleChatPanel } from '../../../../../redux/customActions/loubiAirways';
import { decryptMessage } from '../../../../../utils/chat';
import {
  DID_HIDE_CHAT_PANEL,
  DID_SELECT_CHAT_OPTION,
  DID_SHOW_CHAT_PANEL
} from '../../../../../utils/constants';
import { isUserOnMobile } from '../../../../../utils/deviceDetector';
import { getBrandChatTheme } from '../../../../BrandStyle';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import { ClientChatContext } from './ClientChatContext';

const LiveChatPanel = ({
  config,
  brandId
}: {
  config: LiveChatConfig;
  brandId: string;
}) => {
  const dispatch = useDispatch();

  const { openChatPanel, showSpotifyPlayer } = useLoubiAirwaysState();
  const [open, setOpen] = React.useState(true);
  const [isUsingMobile, setIsUsingMobile] = React.useState(false);
  const [chatInput, setChatInput] = React.useState('');

  const el = React.useRef(null);
  const theme = getBrandChatTheme(brandId);
  const { t } = useTranslation();

  const {
    messages,
    conversationId,
    sendMessage,
    handleOption,
    availableAgents,
    hasMore,
    loadMoreMessages,
    chatIndex,
    agentId
  } = React.useContext(ClientChatContext);

  const [scrollTop, setScrollTop] = React.useState(false);
  const startChatRef = React.useRef(null);
  React.useEffect(() => {
    const scrollRef = scrollTop ? startChatRef : el;
    scrollRef.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    });
    setScrollTop(false);
  }, [messages]);

  React.useEffect(() => {
    setIsUsingMobile(isUserOnMobile());
  }, []);
  React.useEffect(() => {
    setOpen(isUsingMobile);
  }, [isUsingMobile]);

  React.useEffect(() => {
    if (openChatPanel) {
      setOpen(true);
    }
  }, [openChatPanel]);

  const renderMultiContent = (multiContent: {
    top: string;
    topLangKey?: string;
    center: string;
    centerLangKey?: string;
    bottom: string;
    bottomLangKey?: string;
  }) => {
    if (!multiContent) {
      return null;
    }
    return (
      <div className="multi-content">
        <p>
          {multiContent.topLangKey
            ? t(`vb:${multiContent.topLangKey}`)
            : multiContent.top}
        </p>
        <p className="main-message">
          {multiContent.centerLangKey
            ? t(`vb:${multiContent.centerLangKey}`)
            : multiContent.center}
        </p>
        <p>
          {multiContent.bottomLangKey
            ? t(`vb:${multiContent.bottomLangKey}`)
            : multiContent.bottom}
        </p>
      </div>
    );
  };

  const _handleChatInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      _handleSendMessage();
    }
  };
  const hanleLoadMoreMessage = () => {
    setScrollTop(true);
    loadMoreMessages();
  };
  const _handleSendMessage = () => {
    if (chatInput !== '') {
      sendMessage(chatInput);
      setChatInput('');
    }
  };

  const _handleChatInputChange = (e) => {
    setChatInput(e.target.value);
  };

  if (!openChatPanel) {
    return null;
  }
  const handleChatPanelToggle = () => {
    open ? logEvent(DID_HIDE_CHAT_PANEL) : logEvent(DID_SHOW_CHAT_PANEL);
    return isUsingMobile
      ? dispatch(actionToggleChatPanel(false))
      : setOpen(!open);
  };
  const getNoticedAgentStatus = () => {
    if (!agentId)
      return `Our advisor is now ${
        !availableAgents.length ? 'offline' : 'online'
      }
  `;
    return `Our advisor is now ${
      !availableAgents.find((agent) => agent.userId === agentId)
        ? 'offline'
        : 'online'
    }
    `;
  };
  return (
    <>
      <div id="chat-panel">
        <div
          className="header"
          onClick={() => {
            handleChatPanelToggle();
          }}
        >
          {config.chatbotAvatarUrl && (
            <img src={config.chatbotAvatarUrl} alt="profile-photo" />
          )}
          {isUsingMobile && <BsX />}
        </div>
        <div className={`chat-content ${open ? 'chatopen' : 'chatclose'}`}>
          {messages.map((item, i) => {
            const messageBody = decryptMessage(item?.content);
            const messageString = item?.content;
            return (
              <React.Fragment key={`${item.id}-${i}`}>
                {hasMore && i === chatIndex && (
                  <button
                    className="btn btn-load-more"
                    onClick={hanleLoadMoreMessage}
                  >
                    Load More
                  </button>
                )}
                <div
                  className={`message ${item.position}`}
                  ref={i === chatIndex ? startChatRef : null}
                >
                  <span style={item?.style || {}}>
                    {item.contentLangKey ? (
                      t(`vb:${item.contentLangKey}`)
                    ) : (
                      <ChatBubbleContainer
                        messageBody={messageBody}
                        messageString={messageString}
                        left={item.position === 'left'}
                        message={item}
                        onClickProduct={(productId: string) => {
                          dispatch(actionDidSelectProduct(productId));
                        }}
                        onClickStory={(storyId: string) => {
                          console.log(`did select story ${storyId}`);
                        }}
                        clientSide
                      />
                    )}
                    {renderMultiContent(item.multiContent)}
                    {item.options && (
                      <div className="options-container">
                        {item.options.map((option) => {
                          if (
                            option.contentLangKey ===
                              'chat_with_sales_advisor' &&
                            !availableAgents.length
                          )
                            return;
                          return (
                            <button
                              key={option.content}
                              className="option-button btn btn-sm"
                              onClick={() => {
                                handleOption(option);
                                logEvent(
                                  DID_SELECT_CHAT_OPTION,
                                  DID_SELECT_CHAT_OPTION,
                                  option
                                );
                                if (option.inlinePopup) {
                                  isUsingMobile
                                    ? dispatch(actionToggleChatPanel(false))
                                    : setOpen(false);
                                }
                              }}
                              style={option?.style || {}}
                            >
                              {option.contentLangKey
                                ? t(`vb:${option.contentLangKey}`)
                                : option.content}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </span>
                </div>
              </React.Fragment>
            );
          })}
          <div className="notice-status">{getNoticedAgentStatus()}</div>
          <div id="dummy-panel" ref={el}></div>
        </div>
        {conversationId && open && (
          <div className="chat-input">
            <input
              type="text"
              value={chatInput}
              placeholder="Write your message..."
              onKeyDown={_handleChatInputKeyDown}
              onChange={_handleChatInputChange}
            />
            <button className="send-button" onClick={_handleSendMessage}>
              Send
            </button>
          </div>
        )}
      </div>
      <style jsx global>
        {`
          #chat-panel {
            position: fixed;
            display: flex;
            flex-direction: column;
            bottom: 0;
            top: ${isUsingMobile ? '0' : 'unset'};
            right: ${isUsingMobile
              ? '0'
              : `calc(${!showSpotifyPlayer ? '0px' : '300px'} + 100px)`};
            width: ${isUsingMobile ? '100%' : '300px'};
            font-size: 0.875em;
            z-index: 9;
          }
          .chat-content {
            transition: all 0.5s ease;
          }
          .chat-content.chatopen {
            height: 350px;
          }

          .chat-content.chatclose {
            height: 0;
          }

          .chat-content * {
            color: #0a0909;
            font-family: 'Montserrat', sans-serif;
          }

          .chat-content .chatbot-name {
            text-align: left;
            margin: 5px 10px;
            font-weight: 600;
          }

          .options-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          #chat-panel .header {
            color: #fff;
            cursor: pointer;
            height: 60px;
            background: ${theme.header};
            line-height: 60px;
            text-align: center;
            vertical-align: middle;
            position: relative;
            direction: ltr;
            padding: 0 10px;
          }

          #chat-panel .header img {
            width: 120px;
          }

          #chat-panel .header span {
            flex-grow: 1;
            text-align: left;
          }

          #chat-panel .header svg {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
          }

          #chat-panel .option-button {
            display: block;
            color: white;
            width: 100%;
            margin-top: 10px;
            background: ${theme.optionButton};
            font-size: 12px;
          }

          #chat-panel .chat-content {
            flex: ${isUsingMobile ? '1' : 'unset'};
            background: #fff;
            overflow-y: scroll;
          }

          #chat-panel .message {
            display: flex;
            margin-bottom: 10px;
          }

          #chat-panel .message:last-child {
            margin-bottom: 20px;
          }

          #chat-panel .message > span {
            max-width: 80%;
            padding: 10px;
            margin: 10px;
            border-radius: 10px;
            font-size: 0.9rem;
          }

          #chat-panel .message {
            position: relative;
          }

          #chat-panel .message :global(.message-timestamp) {
            position: absolute;
            bottom: -10px;
            width: 200px;
            right: 10px;
            font-size: 11px;
            color: #666363;
          }

          #chat-panel .message.left :global(.message-timestamp) {
            left: 10px;
          }

          #chat-panel .left {
            flex-direction: row;
            text-align: left;
          }

          #chat-panel .left > span {
            background: ${theme.textBackground};
          }

          #chat-panel .right {
            flex-direction: row-reverse;
          }

          #chat-panel .right > span {
            background: #c4c4c4;
            text-align: right;
          }

          #chat-panel .multi-content p {
            font-size: 0.875em;
            margin: 0;
          }

          #chat-panel .multi-content .main-message {
            margin: 10px 0;
          }

          .chat-input {
            height: 100px;
            position: relative;
            width: 100%;
            background-color: #ffffff;
            border-top: 1px solid #c4c4c4;
            border-bottom: 1px solid #000000;
          }

          .chat-input input {
            padding: 15px 10px;
            width: 100%;
            font-size: 16px;
            border: none;
            font-family: 'Montserrat', sans-serif;
          }

          .chat-input input:focus {
            outline-width: 0;
          }

          .notice-status {
            font-size: 12px;
            padding: 5px 0;
          }

          .send-button {
            position: absolute;
            bottom: 10px;
            right: 10px;
            cursor: pointer;
            background-color: #666363;
            border-radius: 10px;
            display: block;
            color: white;
            padding: 5px 15px;
            font-size: 1rem;
            font-family: 'Montserrat', sans-serif;
          }

          .btn.btn-load-more {
            font-size: 13px;
            width: 100%;
          }

          .btn.btn-load-more:hover {
            background: #f5f5f5;
          }

          @media (min-width: ${mobileBreakPoint}px) {
            .chat-input input {
              font-size: 16px;
            }
          }
        `}
      </style>
    </>
  );
};

export default LiveChatPanel;

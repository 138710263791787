import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { fetchWishlist } from '../../../../clientSideServices/wishlist';
import { loubiAirwaysBoutiqueId } from '../../../../config';
import {
  beautyDemoVideoMobileUrl,
  beautyDemoVideoUrl,
  cockpitVideoMobileUrl,
  cockpitVideoUrl,
  exitVideoMobileUrl,
  exitVideoUrl,
  stylishDemoVideoMobileUrl,
  stylishDemoVideoUrl
} from '../../../../config/loubiAirways';
import {
  IMainState,
  MeetingRole,
  ParticipantMeetingState
} from '../../../../interfaces';
import {
  LoubiAirwaysAnimation,
  LoubiAirwaysFullScreenVideo
} from '../../../../interfaces/loubiairways';
import { actionLocalTrackAudioMuteDidChange } from '../../../../redux/actions';
import { actionUpdateLoubiAirwaysFullScreenVideo } from '../../../../redux/customActions/loubiAirways';
import {
  DID_CLICK_MUTE_VIDEO,
  DID_CLICK_UNMUTE_VIDEO
} from '../../../../utils/constants';
import {
  isUserOnMobile,
  isUserOnMobileOnly
} from '../../../../utils/deviceDetector';
import { getIdentityId } from '../../../../utils/identity';
import FullScreenVideo from '../../../FullScreenVideo';
import {
  isUserLoubiAirwaysVIP,
  useLoubiAirwaysState
} from '../../../hooks/loubiAirways';
import { canInteractWithAnimation } from '../../../hooks/meeting';
import PoweredByInspify from '../../../Legal/PoweredByInspify';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import AirplaneWindowWrapper from '../../../ThreejsAnimation/louboutin/AirplaneWindowWrapper';
import AnimationContextContainer from '../../../ThreejsAnimation/louboutin/AnimationContext';
import { ModelEnvironment } from '../../../ThreejsAnimation/louboutin/background';
import BeautyCartWrapper from '../../../ThreejsAnimation/louboutin/BeautyCartWrapper';
import LuggagePopWrapper from '../../../ThreejsAnimation/louboutin/LuggagePopWrapper';
import TarotCardWrapper from '../../../ThreejsAnimation/louboutin/TarotCardWrapper';
import TrolleyWrapper from '../../../ThreejsAnimation/louboutin/TrolleyWrapper';
import NonCockpitModeOnlyElements from '../../NonCockpitModeOnlyElements';
import { actionUpdateLoubiAirwaysWishlist } from './../../../../redux/customActions/loubiAirways';
import ContinueFlightButton from './Buttons/ContinueFlightButton';
import LoubiAirwaysWishlistAccessButton from './Buttons/LoubiAirwaysWishlistAccessButton';
import MuteButton from './Buttons/MuteButton';
import CompartmentXRayAnimation from './CompartmentXRay';
import LoubiAirwaysLandingVideo from './LandingVideo';
import LoubiAirwaysBackgroundMusic from './LoubiAirwaysBackgroundMusic';
import LoubiAirwaysExit from './LoubiAirwaysExit';
import LoubiAirwaysInstructionPopup from './LoubiAirwaysInstructionPopup';
import LoubiAirwaysSpotifyPlayer from './LoubiAirwaysSpotifyPlayer';
import InflightMagazine from './Magazine/InFlightMagazine';
import LoubiTimes from './NewsPaper/LoubiTimes';
import PlanePlan from './PlanePlan';
import OmgMagazine from './SinglePaper/OmgMagazine';
import StylishGuide from './SinglePaper/StylishGuide';
import Tray from './Tray';
import LoubiAirwaysWishlistPopup from './Wishlist/LoubiAirwaysWishlistPopup';
import { defaultLoubiAirwaysWishlistPayload } from './Wishlist/SendWishlistForm';

const LoubiAirwaysCustomComponent = () => {
  const [isOnMobile, setIsOnMobile] = React.useState<boolean>(null);
  const [isOnMobileOrTablet, setIsOnMobileOrTablet] = React.useState(false);
  const [muteVideo, setMuteVideo] = React.useState(false);
  const loubiAirwaysState = useLoubiAirwaysState();

  const { activeAnimation, fullScreenVideo, landingVideo } = loubiAirwaysState;
  const clientState = useSelector((state: IMainState) => state.clientState);
  const storeId = clientState?.store?.id;
  const meeting = clientState?.meeting;
  const meetingState = meeting?.state;
  const localTracks = meeting?.localUser?.tracks || [];
  const isLocalUserMuted = meeting?.localUser?.audioMuted;
  const audio = localTracks.find((t) => t.getType() === 'audio');
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      fullScreenVideo &&
      meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE &&
      fullScreenVideo !== LoubiAirwaysFullScreenVideo.COCKPIT &&
      !isLocalUserMuted
    ) {
      audio?.mute()?.then(() => {
        dispatch(actionLocalTrackAudioMuteDidChange(true));
      });
    }
    const shouldMute = () => {
      if (meetingState !== ParticipantMeetingState.ENTERED_FROM_LOUNGE) {
        return false;
      }
      if (isOnMobileOrTablet && !meeting?.isPresenter) {
        return true;
      }
      return false;
    };
    setMuteVideo(shouldMute());
  }, [fullScreenVideo, meetingState, audio, isLocalUserMuted, isOnMobile]);

  const canInteract = canInteractWithAnimation();

  const shouldShowToggleMuteButton = () => {
    if (meetingState !== ParticipantMeetingState.ENTERED_FROM_LOUNGE) {
      return false;
    }
    if (isOnMobileOrTablet && !meeting?.isPresenter) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    setIsOnMobile(isUserOnMobileOnly());
    setIsOnMobileOrTablet(isUserOnMobile());

    //TODO: move out to action async
    const identityId = getIdentityId();
    if (identityId) {
      fetchWishlist(identityId, storeId)
        .then((result) => {
          if (!result.data.errorMessage) {
            dispatch(actionUpdateLoubiAirwaysWishlist(result.data));
          } else {
            dispatch(
              actionUpdateLoubiAirwaysWishlist(
                defaultLoubiAirwaysWishlistPayload(storeId)
              )
            );
          }
        })
        .catch(() => {
          dispatch(
            actionUpdateLoubiAirwaysWishlist(
              defaultLoubiAirwaysWishlistPayload(storeId)
            )
          );
          console.log(`fetchWishlist failed for identityId ${identityId}`);
        });
    }
  }, []);

  const getVideoUrl = (fullScreenVideo: LoubiAirwaysFullScreenVideo) => {
    switch (fullScreenVideo) {
      case LoubiAirwaysFullScreenVideo.COCKPIT:
        return isOnMobile ? cockpitVideoMobileUrl : cockpitVideoUrl;
      case LoubiAirwaysFullScreenVideo.STYLISH_DEMO:
        return isOnMobile ? stylishDemoVideoMobileUrl : stylishDemoVideoUrl;
      case LoubiAirwaysFullScreenVideo.BEAUTY_DEMO:
        return isOnMobile ? beautyDemoVideoMobileUrl : beautyDemoVideoUrl;
      case LoubiAirwaysFullScreenVideo.EXIT:
        return isOnMobile ? exitVideoMobileUrl : exitVideoUrl;
      default:
        return;
    }
  };

  const getAnimation = () => {
    switch (activeAnimation) {
      case LoubiAirwaysAnimation.AIRPLANE_WINDOW:
        return (
          <AirplaneWindowWrapper modelEnvironment={ModelEnvironment.WORKSHOP} />
        );
      case LoubiAirwaysAnimation.LUGGAGE:
        return <LuggagePopWrapper />;
      case LoubiAirwaysAnimation.TAROT_CARD:
        return <TarotCardWrapper />;
      case LoubiAirwaysAnimation.TRAY_TABLE:
        return <Tray />;
      case LoubiAirwaysAnimation.OMG_MAGAZINE:
        return <OmgMagazine />;
      case LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE:
        return <InflightMagazine />;
      case LoubiAirwaysAnimation.STYLISH_GUIDE:
        return <StylishGuide />;
      case LoubiAirwaysAnimation.NEWSPAPER:
        return <LoubiTimes />;
      case LoubiAirwaysAnimation.TROLLEY:
        return <TrolleyWrapper />;
      case LoubiAirwaysAnimation.COMPARTMENT_XRAY:
        return <CompartmentXRayAnimation />;
      case LoubiAirwaysAnimation.BEAUTY_CART:
        return <BeautyCartWrapper />;
      default:
        return null;
    }
  };

  const shouldShowWishlist = isUserLoubiAirwaysVIP();

  return (
    <>
      <NonCockpitModeOnlyElements>
        {isOnMobile !== null && (
          <LoubiAirwaysLandingVideo isOnMobile={isOnMobile} storeId={storeId} />
        )}
        {landingVideo?.hidden && <LoubiAirwaysBackgroundMusic />}
        <LoubiAirwaysSpotifyPlayer />
        <LoubiAirwaysInstructionPopup />
      </NonCockpitModeOnlyElements>
      <FullScreenVideo
        url={getVideoUrl(fullScreenVideo)}
        show={!!fullScreenVideo}
        loop={fullScreenVideo === LoubiAirwaysFullScreenVideo.COCKPIT}
        playWithSound={!muteVideo}
        onEnded={() => {
          if (fullScreenVideo === LoubiAirwaysFullScreenVideo.EXIT) {
            window.close();
          }
        }}
        fullSize={true}
      >
        {canInteract && fullScreenVideo !== LoubiAirwaysFullScreenVideo.EXIT && (
          <ContinueFlightButton
            onClick={() => {
              dispatch(actionUpdateLoubiAirwaysFullScreenVideo(undefined));
              if (meeting?.localUser?.role === MeetingRole.ADVISOR) {
                const localTracks = meeting?.localUser?.tracks || [];
                const audio = localTracks.find((t) => t.getType() === 'audio');
                audio?.unmute()?.then(() => {
                  dispatch(actionLocalTrackAudioMuteDidChange(false));
                });
              }
            }}
          />
        )}
        {shouldShowToggleMuteButton() && (
          <MuteButton
            onClick={() => {
              if (muteVideo) {
                setMuteVideo(false);
                logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO, {
                  video: fullScreenVideo
                });
              } else {
                setMuteVideo(true);
                logEvent(DID_CLICK_MUTE_VIDEO, DID_CLICK_MUTE_VIDEO, {
                  video: fullScreenVideo
                });
              }
            }}
            mute={muteVideo}
            showSpotifyPlayer={false}
          />
        )}
        {fullScreenVideo === LoubiAirwaysFullScreenVideo.EXIT && (
          <div className="inspify-logo-container">
            <PoweredByInspify color="light" />
          </div>
        )}
      </FullScreenVideo>
      <PlanePlan />
      {storeId === loubiAirwaysBoutiqueId && <LoubiAirwaysExit />}
      
      {shouldShowWishlist && (
        <>
          <LoubiAirwaysWishlistAccessButton />
          <LoubiAirwaysWishlistPopup />
        </>
      )}
      <AnimationContextContainer key={activeAnimation}>
        {getAnimation()}
      </AnimationContextContainer>
      <style jsx global>{`
        .letter-box-container {
          position: fixed;
          bottom: 30px;
          width: 100%;
          text-align: center;
        }
        .in-meeting .letter-box-container {
          margin-bottom: 40px;
        }
        .in-meeting.SMPortrait .letter-box-container {
          margin-bottom: ${SMPortraitNormalSpec.contentArea.bottom + 40}px;
        }

        .in-meeting.MDPortrait .letter-box-container {
          margin-bottom: ${MDPortraitNormalSpec.contentArea.bottom + 40}px;
        }

        .in-meeting.SMLandscape .letter-box-container {
          width: calc(100% - ${SMLandscapeNormalSpec.contentArea.left}px);
          margin-left:${SMLandscapeNormalSpec.contentArea.left}px;
        }
    
        .in-meeting.SMLandscape .fixed-full {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }
        .in-meeting.SMLandscape .loubi-airways-animation #centertxt,
        .in-meeting.SMLandscape .loubi-airways-animation canvas {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }
        .in-meeting.SMLandscape .loubi-airways-animation #centertxt{
          margin-bottom:40px;
        }
        .in-meeting.SMPortrait .loubi-airways-animation #centertxt{
          margin-bottom:${SMPortraitNormalSpec.contentArea.bottom + 40}px;
        }
        .in-meeting.MDLandscape .letter-box-container {
          width: calc(100% - ${MDLandscapeNormalSpec.contentArea.left}px);
          margin-left:${MDLandscapeNormalSpec.contentArea.left}px;
        }

        .in-meeting.MDLandscape .fixed-full {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }
        .in-meeting.MDLandscape .loubi-airways-animation #centertxt,
        .in-meeting.MDLandscape .loubi-airways-animation canvas {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }
        .in-meeting.MDLandscape .loubi-airways-animation #centertxt{
          margin-bottom:40px;
        }
        .in-meeting.MDPortrait .loubi-airways-animation #centertxt{
          margin-bottom:${MDPortraitNormalSpec.contentArea.bottom + 40}px;
        }
        .express-icons-container {
          right: 120px !important;
        }

        @media (max-width: 400px) {
          .express-icons-container {
            right: 90px !important;
          }
        }
      `}</style>
      <style jsx>{`
        .inspify-logo-container {
          background-color: rgba(0, 0, 0, 0.2);
          background-size: contain;
          height: 50px;
          width: 120px;
          padding-left: 10px;
          border-radius: 30px 0 0 30px;
          right: 0;
          left: auto;
          position: absolute;
          bottom: 10px;
        }
        .inspify-logo-container :global(a) {
          margin-top: 0;
          background-position: center center;
        }
        .btn-light {
          position: fixed;
          bottom: 20px;
          right: 20px;
          opacity: 0.5;
        }

        :global(.inspify-logo-container) {
          position: fixed;
          bottom: 20px;
          right: 0;
          left: 0;
        }
        @media (max-width: 840px) {
          :global(.letter-box-container) {
            bottom: ${meetingState ===
            ParticipantMeetingState.ENTERED_FROM_LOUNGE
              ? 70
              : 30}px;
          }
        }
      `}</style>
    </>
  );
};

export default LoubiAirwaysCustomComponent;

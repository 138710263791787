import React from 'react';
import { vcaAssets } from '.';
import { zIndex } from '../../../../config';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import { MapContext } from '../../MapWrapper';

export const flyDuration = 1800;
const disappearDuration = 300;

export const butterflyEffectDuration = flyDuration + disappearDuration;

const VCAMapButton = () => {
  const { open, onToggleMap } = React.useContext(MapContext);
  const [disappear, setDisappear] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setDisappear(true);
      }, flyDuration);
    } else {
      setDisappear(false);
    }
  }, [open]);

  return (
    <>
      <div className={`VCAMapButton ${open ? 'flying' : 'stay'}`}>
        <div
          className={`butterfly  ${disappear && 'disappear'}`}
          onClick={() => onToggleMap(true)}
        >
          <img src={vcaAssets.leftWing} className="wing-left wing" />
          <img src={vcaAssets.rightWing} className="wing-right wing" />
        </div>
        <strong className="btn-label">VIEW FLOORPLAN</strong>
      </div>
      <style jsx>{`
        .VCAMapButton {
          position: fixed;
          cursor: pointer;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 998;
          user-select: none;
          text-align: center;
        }

        :global(.in-meeting) .VCAMapButton{
          z-index: ${zIndex.popupPage - 1};
        }

        :global(.in-meeting.MDLandscape) .VCAMapButton {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }

        :global(.in-meeting.SMLandscape) .VCAMapButton {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }

        .stay {
          bottom: calc(0% - 20px);
        }

        :global(.in-meeting.MDPortrait) .VCAMapButton {
          margin-bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
        }

        :global(.in-meeting.SMPortrait) .VCAMapButton {
          margin-bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
        }
        :global(.in-meeting.client) .stay{
          opacity:0;
        }
        .flying {
          opacity: 1;
          bottom: calc(50% - 80px);
          transition: all ${flyDuration / 1000}s ease-in-out;
        }
        .butterfly {
          position: relative;
          width: 50px;
          height: 50px;
          transition: all ${flyDuration / 1000}s ease-in-out;
          margin: auto;
        }

        .flying .butterfly {
          width: 80px;
          height: 80px;
        }
        .flying .butterfly.disappear {
          pointer-events: none;
        }
        .wing {
          position: absolute;
          width: calc(50% - 1px);
          top: 0;
          display: block;
        }
        .wing-right {
          left: calc(50% - 1px);
          animation: Wing ${open ? '0.4s' : '2s'} linear infinite;
          transform-origin: left;
        }

        .wing-left {
          right: 50%;
          animation: Wing ${open ? '0.4s' : '2s'} linear infinite;
          transform-origin: right;
        }

        .SIDE {
          margin-bottom: 135px;
        }
        .SIDE.flying {
          bottom: calc(50% - 235px);
        }
        .btn-label {
          display: block;
          font-size: 11px;
          color: #fff;
          margin: auto;
        }
        :global(.in-meeting) .btn-label{
          display:none;
        }
        .flying .btn-label {
          display: none;
        }
        @media (min-width: 768px) {
          .butterfly {
            position: relative;
            width: 80px;
            height: 80px;
          }
          .flying {
            bottom: calc(50% - 100px);
          }
          .flying .butterfly {
            width: 100px;
            height: 100px;
          }
          .stay {
            bottom: calc(0% - 30px);
          }
        }

        @keyframes Wing {
          0% {
            transform: rotateY(60deg);
          }
          50% {
            transform: rotateY(0deg);
          }
          100% {
            transform: rotateY(60deg);
          }
        }
      `}</style>
    </>
  );
};

export default VCAMapButton;

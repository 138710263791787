import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import {
  ILoubiFutureCanvasState,
  ILoubiFutureState,
  LFPopupContent,
  LoubiFutureScene
} from '../../../../interfaces/loubifuture';
import {
  actionHideCursorGuide,
  actionLFPlaySoundEffect,
  actionOpenLFPopup,
  actionOpenWishlistPopup,
  actionReplayMusicVideo,
  actionUpdateLoubiFutureCanvasState,
  actionUpdateLoubiFutureSelectedProduct
} from '../../../../redux/customActions/loubiFuture';
import {
  isUserOnMobile,
  isUserOnMobileOnly
} from '../../../../utils/deviceDetector';
import {
  shouldRenderMenu,
  useLoubiFutureState
} from '../../../hooks/loubiFuture';
import { canInteractWithAnimation } from '../../../hooks/meeting';
import { LFColor } from '../../../ThreejsAnimation/loubiFuture/assets';
import LoubiFutureBase from '../../../ThreejsAnimation/loubiFuture/LoubiFutureBase';
import NonCockpitModeOnlyElements from '../../NonCockpitModeOnlyElements';
import FavPage from './Favorites/FavPage';
import { actionFetchLFWishlistAsync } from './Favorites/LFFavoriteMiddleware';
import LF360Veil from './LF360Veil';
import LFExitButton from './LFExitButton';
import LFGyroControls from './LFGyroControls';
import LFLandingVideo from './LFLandingVideo';
import LFMap from './LFMap';
import LFMenu from './LFMenu';
import LFPopup from './LFPopup';
import NotifBanner from './LFPopup/NotifBanner';
import LFProductDetailOverlay from './LFProductDetailOverlay';
import LFSoundsEffect from './LFSoundsEffect';
import LoubiFutureFullScreenVideo from './LoubiFutureFullScreenVideo';
import { logEvent } from '../../../../analytics';
import {
  DID_CLICK_BACK_TO_VIDEO,
  DID_VIEW_PRODUCT
} from '../../../../utils/constants';
import LFStandaloneMusicVideo from './LFStandloneMusicVideo';
import ArpoadorInfoButton from './ArpoadorInfoButton';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';

const LoubiFutureCustomComponent = () => {
  const [isOnMobile, setIsOnMobile] = React.useState<boolean>(null);
  const [isOnMobileOrTablet, setIsOnMobileOrTablet] =
    React.useState<boolean>(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const hasNotification = router.query.utm_medium === 'popup';
  const showWishlist = router.query.utm_medium === 'wishlist';
  const allowLanguageSwitch = hasNotification || showWishlist;
  const isCockpitMode = router.query.mode === 'cockpit';
  const [openMenu, setOpenMenu] = React.useState(false);
  const [hasPassedGate, setHasPassedGate] = React.useState(false);
  const [isGyroscopeOn, setIsGyroscopeOn] = React.useState(false);
  const [selectedProductId, setSelectedProductId] =
    React.useState<string>(null);
  const [notification, setNotification] = React.useState({
    open: false,
    pristine: true
  });
  const [showExitButton, setshowExitButtonButton] = React.useState(false);

  const viewOnly = !canInteractWithAnimation();
  const state: ILoubiFutureState = useLoubiFutureState();
  const renderMenu = shouldRenderMenu() && !notification?.open;

  const updateRemoteState = (canvasState: ILoubiFutureCanvasState) => {
    dispatch(actionUpdateLoubiFutureCanvasState(canvasState));
    if (!state?.hideCursorGuide && canvasState?.focusItem?.rotation) {
      dispatch(actionHideCursorGuide());
    }
  };

  const onSelectProduct = (productId?: string) => {
    if (productId) {
      logEvent(DID_VIEW_PRODUCT, DID_VIEW_PRODUCT, {
        productId
      });
    }
    setSelectedProductId(productId);
    dispatch(actionUpdateLoubiFutureSelectedProduct(productId));
  };

  const replayMusicVideo = () => {
    logEvent(DID_CLICK_BACK_TO_VIDEO);
    dispatch(actionReplayMusicVideo(true));
  };

  let menuList = [
    {
      title: 'Map',
      src: 'map_white.svg',
      onClick: () => {
        dispatch(actionOpenLFPopup(LFPopupContent.MAP));
      }
    },
    {
      title: 'Info',
      src: 'cross_i_white.svg',
      onClick: () => {
        dispatch(actionOpenLFPopup(LFPopupContent.INFO));
      }
    }
  ];

  if (showWishlist) {
    menuList = [
      ...menuList,
      {
        title: 'Favourites',
        src: 'favorite.png',
        onClick: () => {
          dispatch(actionOpenWishlistPopup(true));
        }
      }
    ];
  }

  if (allowLanguageSwitch) {
    menuList = [
      ...menuList,
      {
        title: 'LanguageSwitch',
        src: 'language_icon_white.png',
        onClick: () => {
          dispatch(actionOpenLFPopup(LFPopupContent.LANGUAGE));
        }
      }
    ];
  }

  React.useEffect(() => {
    if (
      state?.canvasState?.currentScene ===
        LoubiFutureScene.ArpoadorToViewingroom &&
      !hasPassedGate
    ) {
      setHasPassedGate(true);
    }
    if (state?.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2) {
      if (hasNotification && notification.pristine) {
        setTimeout(() => {
          setNotification({ open: true, pristine: false });
        }, 30000);
      }
    }

    if (state?.canvasState?.currentScene === LoubiFutureScene.ViewingRoom) {
      dispatch(
        actionLFPlaySoundEffect({
          dome: true,
          elevatorUp: false,
          water: false,
          afterRainbow: false,
          rainbowLight: false
        })
      );

      if (!showExitButton) {
        setshowExitButtonButton(true);
      }
    }
  }, [state?.canvasState?.currentScene]);

  React.useEffect(() => {
    if (state.landingVideo?.hidden) {
      dispatch(
        actionUpdateLoubiFutureCanvasState({
          currentScene: LoubiFutureScene.ArpoadorA1
        })
      );
    }
  }, [state.landingVideo?.hidden]);

  React.useEffect(() => {
    setIsOnMobile(isUserOnMobileOnly());
    setIsOnMobileOrTablet(isUserOnMobile());
    if (!state?.wishlist?.id && showWishlist) {
      dispatch(actionFetchLFWishlistAsync());
    }
    return () => {
      dispatch(actionOpenLFPopup(undefined));
      onSelectProduct(undefined)
    }
  }, []);

  return (
    <>
      <LoubiFutureBase
        viewOnly={viewOnly}
        canvasState={state.canvasState}
        updateCanvasState={updateRemoteState}
        onSelectProduct={onSelectProduct}
        replayMusicVideo={replayMusicVideo}
        isGyroscopeOn={isGyroscopeOn}
        selectedProductId={selectedProductId}
      />
      {state.landingVideo?.hidden && <LoubiFutureFullScreenVideo />}
      <NonCockpitModeOnlyElements>
        {isOnMobile !== null && <LFLandingVideo isOnMobile={isOnMobile} />}
      </NonCockpitModeOnlyElements>
      <LFStandaloneMusicVideo isOnMobile={isOnMobile} disableSkip={viewOnly} />
      {renderMenu && (
        <LFMenu
          viewOnly={viewOnly}
          assetBase={`${LFAssetBaseUrl}/icons/v1`}
          menuList={menuList}
          open={openMenu}
          onClickHamburger={(status) => setOpenMenu(status)}
        />
      )}

      {state?.wishlist?.open && showWishlist && (
        <FavPage state={state.wishlist} />
      )}
      {state?.selectedProductId && (
        <LFProductDetailOverlay
          productId={state.selectedProductId}
          showGuide={!state?.hideCursorGuide}
          showWishlist={showWishlist}
          isOnMobileOrTablet={isOnMobileOrTablet}
          isInMeeting={isCockpitMode}
        />
      )}
      {notification.open && (
        <NotifBanner
          onClose={() => {
            setNotification({ open: false, pristine: false });
          }}
          onClickButton={() => {
            dispatch(actionOpenLFPopup(LFPopupContent.RECEIVE_NOTIFICATION));
          }}
        />
      )}

      {state?.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
        !state?.selectedProductId &&
        !viewOnly &&
        !openMenu && (
          <ArpoadorInfoButton
            inMeeting={isCockpitMode}
            onClick={() => {
              dispatch(actionOpenLFPopup(LFPopupContent.ARPOADOR_INFO));
            }}
            higherButton={showExitButton}
          />
        )}

      {state?.popup && state?.popup !== LFPopupContent.MAP && (
        <LFPopup
          popup={state.popup}
          showWishlist={showWishlist}
          onMobile={isOnMobile}
        />
      )}
      <LFMap
        open={state?.popup === LFPopupContent.MAP}
        onClose={() => dispatch(actionOpenLFPopup(undefined))}
        currentScene={state?.canvasState?.currentScene}
        hasPassedGate={hasPassedGate}
        onClickMap={(scene) => {
          dispatch(actionOpenLFPopup(undefined));
          dispatch(actionUpdateLoubiFutureCanvasState({ currentScene: scene }));
          if (scene === LoubiFutureScene.ArpoadorA2) {
            dispatch(actionLFPlaySoundEffect({ water: true, dome: false }));
          }
          if (scene === LoubiFutureScene.ViewingRoom) {
            dispatch(actionLFPlaySoundEffect({ water: false }));
          }
        }}
      />
      {!state?.selectedProductId && isOnMobileOrTablet && renderMenu && (
        <LFGyroControls isOn={isGyroscopeOn} onToggle={setIsGyroscopeOn} />
      )}
      <NonCockpitModeOnlyElements>
        <LF360Veil scene={state?.canvasState?.currentScene} />
      </NonCockpitModeOnlyElements>

      <div className="bottom-nav-container with-shadow">
        <LFSoundsEffect
          state={state?.soundEffect || {}}
          showButton={!state?.selectedProductId && state.landingVideo?.hidden}
        />
        {showExitButton && renderMenu && (
          <LFExitButton
            onClick={() =>
              dispatch(actionOpenLFPopup(LFPopupContent.LEAVE_PROMPT))
            }
          />
        )}
      </div>

      <style jsx global>{`
        * {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        input, textarea {
          -webkit-touch-callout: all;
          -webkit-user-select: all;
          -khtml-user-select: all;
          -moz-user-select: all;
          -ms-user-select: all;
          user-select: all;
        }
        .glow {
          filter: drop-shadow(0 0 3px #fff);
        }
        .LFTitle {
          font-family: 'LF-Bold', sans-serif;
          color: ${LFColor.blue};
          font-weight: normal;
          text-shadow: -1px -1px 0 ${LFColor.purple},
            1px -1px 0 ${LFColor.purple}, -1px 1px 0 ${LFColor.purple},
            1px 1px 0 ${LFColor.purple}, -3px 3px 0 ${LFColor.purpleDark};
        }
        .in-meeting.SMLandscape #c{
          margin-left:${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }
        .in-meeting.MDLandscape #c{
          margin-left:${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }
      `}</style>
      <style jsx>{`
        .bottom-nav-container {
          position: fixed;
          bottom: 10px;
          left: 2px;
          z-index: 11000;
        }
        .bottom-nav-container :global(img) {
          width: 55px;
          height: auto;
          cursor: pointer;
          margin-top: 20px;
          display: block;
        }
        @media (min-width: 768px) {
          .bottom-nav-container {
            left: 10px;
          }
          .bottom-nav-container :global(img) {
            width: 80px;
          }
        }
        @media (min-width: 840px) {
          .bottom-nav-container {
            bottom: 10px;
          }
        }
        @media (orientation: landscape) and (max-height: 760px) {
          .bottom-nav-container {
            left: 2px;
          }
          .bottom-nav-container :global(img) {
            width: 55px;
          }
        }

        :global(.in-meeting.SMLandscape) .bottom-nav-container{
          left:${SMLandscapeNormalSpec.contentArea.left + 2}px
        }
        :global(.in-meeting.MDLandscape) .bottom-nav-container{
          left:${MDLandscapeNormalSpec.contentArea.left + 10}px
        }
        :global(.in-meeting.SMPortrait) .bottom-nav-container{
          bottom:${SMPortraitNormalSpec.contentArea.bottom + 10}px
        }
        :global(.in-meeting.MDPortrait) .bottom-nav-container{
          bottom:${MDPortraitNormalSpec.contentArea.bottom + 10}px
        }
        :global(.in-meeting.tile-mode) .bottom-nav-container,
        :global(.in-meeting.has-popup) .bottom-nav-container{
          display:none !important;
        }
      `}</style>
    </>
  );
};

export default LoubiFutureCustomComponent;

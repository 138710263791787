import React from 'react';
import { Modal } from 'react-bootstrap';
import VirtualBackgroundPreview from './VirtualBackgroundPreview';
import { BsX } from 'react-icons/bs';
import { BiBlock, BiX } from 'react-icons/bi';
import {
  IMainState,
  JitsiLocalTrack,
  JitsiVideoTrackEffect
} from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionToggleVirtualDialog,
  actionToggleVirtualBackground
} from '../../../redux/actions';
import { resizeImage, toDataURL, virtualImages } from './setVirtualBackground';
import { mobileBreakPoint } from '../../../advisorHub/components/BrandStyle';
import { meetingColor } from '../../../utils/meeting';
import { BiPlus } from 'react-icons/bi';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { disposeTrackIfNeeded } from '../utils/track';
import { actionReplaceLocalTrackAsync } from '../../../redux/asyncActions';
import { useMeetingDebugLogger } from '../../hooks/meeting';
import { DID_APPLY_VIRTUAL_BACKGROUND } from '../../../utils/constants';

export const STORED_IMAGES = 'VITUAL_BACKGROUND.STORED_IMAGE';
export interface StorageImages {
  id: string;
  src: string;
}
const MAX_SIZE_STORED_IMAGES = 7;

function VirtualBackgroundDialog() {
  const defaultOptions = useSelector(
    (state: IMainState) =>
      state.clientState.meeting?.localUser?.videoEffect?.effect
  );
  const [previewTrack, setPreviewTrack] = React.useState<JitsiLocalTrack>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const backgrounds =
    useSelector(
      (state: IMainState) =>
        state.clientState.virtualBoutiqueConfig?.virtualBackgrounds
    ) || [];
  const [options, setOptions] =
    React.useState<JitsiVideoTrackEffect>(defaultOptions);
  const _localImages = localStorage.getItem(STORED_IMAGES);
  const [storedImages, setStoredImages] = React.useState<StorageImages[]>(
    (_localImages && JSON.parse(_localImages)) || []
  );

  const logEvent = useMeetingDebugLogger();
  const dispatch = useDispatch();
  const selectedThumbnail = options?.selectedThumbnail || 'none';
  React.useEffect(() => {
    localStorage.setItem(STORED_IMAGES, JSON.stringify(storedImages));
    if (storedImages.length > MAX_SIZE_STORED_IMAGES)
      setStoredImages(storedImages.slice(1));
  }, [storedImages]);

  const storeVirtualBackground = React.useMemo(
    () =>
      backgrounds.map((item, index) => ({
        id: `store-${index}`,
        src: item
      })),
    [backgrounds]
  );

  const setBlurBackground = (slight?: boolean) => {
    setOptions({
      backgroundType: 'blur',
      enabled: true,
      blurValue: slight ? 8 : 14,
      selectedThumbnail: slight ? 'slight-blur' : 'blur'
    });
  };

  const cancelBackground = () => {
    setOptions({
      ...options,
      selectedThumbnail: 'none',
      enabled: false
    });
  };

  const discardChange = () => {
    disposeTrackIfNeeded(previewTrack);
    dispatch(actionToggleVirtualDialog(false));
  };

  const setImageBackground = React.useCallback(
    async (e) => {
      const imageId = e.target.id;
      const image = virtualImages.find((img) => img.id === imageId);
      if (image) {
        const url = await toDataURL(image.src);
        setOptions({
          backgroundType: 'image',
          enabled: true,
          url,
          selectedThumbnail: image.id
        });
      }
    },
    [virtualImages]
  );

  const setUploadedImageBackground = React.useCallback(
    async (e) => {
      const imageId = e.target.id;
      const image = storedImages.find((img) => img.id === imageId);
      if (image) {
        setOptions({
          backgroundType: 'image',
          enabled: true,
          url: image.src,
          selectedThumbnail: image.id
        });
      }
    },
    [storedImages]
  );

  const setStoreImageBackground = React.useCallback(
    async (e) => {
      const imageId = e.target.id;
      const image = storeVirtualBackground.find((img) => img.id === imageId);
      if (image) {
        const url = await toDataURL(image.src);
        setOptions({
          backgroundType: 'image',
          enabled: true,
          url,
          selectedThumbnail: image.id
        });
      }
    },
    [storeVirtualBackground]
  );

  const applyVirtualBackground = () => {
    logEvent(DID_APPLY_VIRTUAL_BACKGROUND, {
      from: defaultOptions?.selectedThumbnail,
      to: selectedThumbnail
    });
    if (defaultOptions?.selectedThumbnail !== selectedThumbnail) {
      dispatch(
        actionToggleVirtualBackground({
          ...options,
          backgroundEffectEnabled: true
        })
      );
      dispatch(actionReplaceLocalTrackAsync(previewTrack));
    }
    dispatch(actionToggleVirtualDialog(false));
  };

  const handleOnFileChange = (e) => {
    const reader = new FileReader();
    const files = e.target.files;
    reader.readAsDataURL(files[0]);
    reader.onload = async () => {
      const { width, height } = previewTrack?.track?.getSettings?.();
      const url = await resizeImage(reader.result, width, height);
      const uuId = generateV4UUID();

      setStoredImages([
        ...storedImages,
        {
          id: uuId,
          src: url
        }
      ]);
      setOptions({
        backgroundType: 'image',
        enabled: true,
        url,
        selectedThumbnail: uuId
      });
    };
  };

  const handleDeleteStoredImage = (imageId: string) => {
    setStoredImages(storedImages.filter((item) => item.id !== imageId));
  };

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        size="lg"
        className="modal-vb"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Modal.Header className="header d-flex align-items-center justify-content-between border-bottom border-secondary">
          <span>Virtual backgrounds</span>
          <button className="btn btn-close" onClick={discardChange}>
            <BsX size={24} />
          </button>
        </Modal.Header>
        <Modal.Body className="content">
          <div className="virtual-bg-dialog">
            <div className="preview position-relative">
              <VirtualBackgroundPreview
                options={options}
                track={previewTrack}
                setTrack={setPreviewTrack}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            <div className="background-list mt-4">
              <h5 className="text-left">Add virtual background</h5>
              <ul>
                <li
                  className={`bg-item none ${
                    selectedThumbnail === 'none' && 'selected'
                  }`}
                  onClick={cancelBackground}
                >
                  <BiBlock size={24} />
                </li>
                <li className="bg-item upload-button">
                  <label
                    htmlFor="vb_upload"
                    className="d-flex align-items-center justify-content-center mb-0"
                  >
                    <BiPlus size={20} />
                  </label>
                  <input
                    type="file"
                    id="vb_upload"
                    accept="image/*"
                    onChange={handleOnFileChange}
                  />
                </li>
                <li
                  className={`bg-item slight-blur ${
                    selectedThumbnail === 'slight-blur' && 'selected'
                  }`}
                  onClick={() => setBlurBackground(true)}
                >
                  <span>Slight blur</span>
                </li>
                <li
                  className={`bg-item blur ${
                    selectedThumbnail === 'blur' && 'selected'
                  }`}
                  onClick={() => setBlurBackground()}
                >
                  <span>Blur</span>
                </li>
                {virtualImages.map((image) => (
                  <li
                    key={image.id}
                    className={`bg-item image ${
                      selectedThumbnail === image.id && 'selected'
                    }`}
                    onClick={setImageBackground}
                  >
                    <img
                      alt="bgc"
                      id={image.id}
                      src={image.src}
                      title={image.tooltip}
                    />
                  </li>
                ))}
                {storeVirtualBackground.map((image) => (
                  <li
                    key={image.id}
                    className={`bg-item image ${
                      selectedThumbnail === image.id && 'selected'
                    }`}
                    onClick={setStoreImageBackground}
                  >
                    <img alt="bgc" id={image.id} src={image.src} />
                  </li>
                ))}
                {storedImages.map((image) => (
                  <li
                    key={image.id}
                    className={`bg-item image uploaded ${
                      selectedThumbnail === image.id && 'selected'
                    }`}
                    onClick={setUploadedImageBackground}
                  >
                    <button
                      className="btn btn-light justify-content-center align-items-center"
                      onClick={() => handleDeleteStoredImage(image.id)}
                    >
                      <BiX size={16} />
                    </button>
                    <div className="img-container">
                      <img alt="bgc" id={image.id} src={image.src} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-right border-0">
          <button
            className="btn btn-dark mr-2 btn-sm btn-discard"
            onClick={discardChange}
          >
            Discard
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={applyVirtualBackground}
            disabled={!previewTrack || loading}
          >
            Apply Effect
          </button>
        </Modal.Footer>
      </Modal>
      <style jsx>
        {`
          :global(.modal-lg) {
            max-width: 650px;
          }
          :global(.modal-vb.modal.show) {
            z-index: 9999;
          }
          :global(.modal-vb .modal-content) {
            background: ${meetingColor.transparentDark};
            color: white;
            border-radius: 0;
            max-height: 98vh;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
          }
          :global(.modal-vb .modal-content .modal-body) {
            padding: 15px 15px 20px;
            flex: 1 1 auto;
            display: flex;
            overflow: hidden;
            flex-direction: column;
          }
          :global(.header span) {
            font-size: 1.6rem;
            font-weight: 500;
          }
          .virtual-bg-dialog {
            overflow: hidden;
            flex: 1;
            display: grid;
            grid-template-rows: auto 1fr;
          }
          .preview {
            height: 300px;
            max-height: 350px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          :global(.btn.btn-close svg) {
            fill: white;
          }
          .preview video {
            object-fit: center;
          }
          .background-list {
            display: grid;
            grid-template-rows: auto 1fr;
            overflow: hidden;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            list-style: none;
            gap: 10px;
            padding: 0;
            margin-bottom: 0;
          }
          .bg-item:not(.uploaded).selected {
            border: 2px solid #f1f1f1;
          }
          .bg-item:not(.uploaded):hover {
            border: 2px solid white;
          }
          .bg-item {
            position: relative;
            display: block;
            margin-top: 8px;
            border-radius: 6px;
            height: 60px;
            width: 107px;
            font-size: 0.8rem;
            color: white;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            cursor: pointer;
          }
          .bg-item img {
            width: 100%;
          }
          .bg-item.slight-blur {
            background: #f7f7f7;
            box-shadow: inset 0px 0px 47px 4px rgba(0, 0, 0, 0.7);
          }
          .bg-item.blur {
            background: #a0a0a0;
            box-shadow: inset 0px 0px 47px 4px rgba(0, 0, 0, 0.7);
          }
          .bg-item.none {
            background: #a0a0a0;
          }
          .upload-button input {
            display: none;
          }
          .upload-button {
            -webkit-box-shadow: 0px 10px 13px -7px #000000,
              inset 0px 0px 16px 0px rgba(255, 255, 255, 0.51);
            box-shadow: 0px 10px 13px -7px #000000,
              inset 0px 0px 16px 0px rgba(255, 255, 255, 0.51);
            text-align: center;
            line-height: 60px;
            position: relative;
          }
          .upload-button label {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
          }
          .bg-item.image:not(.selected):hover button {
            display: flex;
          }
          .bg-item.image.uploaded {
            overflow: unset;
          }
          .bg-item.image.uploaded .img-container {
            height: 60px;
            overflow: hidden;
            border-radius: 6px;
          }
          .bg-item.image.uploaded .img-container:hover {
            border: 2px solid white;
          }
          .bg-item.image.uploaded.selected .img-container {
            border: 2px solid white;
          }
          .bg-item.image button {
            position: absolute;
            padding: 0;
            margin: 0;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            top: -5px;
            right: -5px;
            display: none;
          }
          ul::-webkit-scrollbar {
            width: 6px;
          }
          ul::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }
          ul::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
          }
          ul::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          @media (max-width: ${mobileBreakPoint}px) {
            ul {
              display: grid;
              grid-template-columns: repeat(3, calc(33.33% - 10px));
            }
            .bg-item {
              width: unset;
            }
          }
        `}
      </style>
    </>
  );
}

export default VirtualBackgroundDialog;

import {
  actionDidGoToScene,
  actionPixelStreamDidFocusOnAnObject
} from './../../../redux/actions';
import { IMultilingualVideoUrls } from './../../../interfaces/index';
import { AnyAction, Dispatch } from 'redux';
import { logEvent } from '../../../analytics';
import {
  chopardBrandId,
  videoBaseUrl
} from '../../../config';
import {
  DID_RECEIVE_UNREAL_EVENT,
  PLAY_FULL_SCREEN_VIDEO
} from '../../../utils/constants';
import { getPopupAction, PageView } from '../../../utils/pageView';
import { actionShowFullScreenVideoWithUrl } from '../../../redux/actions';
import { CHScenes, IPSHotspotType } from '../PSInterface';
import { fullScrVideoMap, objectSpotMap, ueSceneMap } from './CHConfig';
import { sendTimezoneInfo } from '../CommandHandler/webToPSCommand';

const openPopupInspiration = (
  message: string,
  dispatch: Dispatch<AnyAction>,
  lang: string,
  storeId?: string
) => {
  const id = lang.includes('ko')
    ? objectSpotMap[message]?.idKo
    : objectSpotMap[message]?.id;
  const action = getPopupAction(
    `${location.protocol}//${location.host}`,
    objectSpotMap[message]?.type as PageView,
    id,
    chopardBrandId,
    storeId,
    lang
  );
  if (action) {
    dispatch(action);
  }
};

const playFullScreenVideo = (
  videoUrls: IMultilingualVideoUrls,
  dispatch: Dispatch<AnyAction>
) => {
  const convertedUrls: IMultilingualVideoUrls = Object.keys(videoUrls || {}).reduce(
    (urls, key) => {
      urls[key] = `${videoBaseUrl}/${videoUrls[key]}`;
      return urls;
    },
    {}
  );
  dispatch(
    actionShowFullScreenVideoWithUrl({
      show: true,
      url: convertedUrls
    })
  );
};

const getSpotType = (message: string): IPSHotspotType => {
  if (fullScrVideoMap[message]) {
    return 'video';
  }
  if (objectSpotMap[message]) {
    return objectSpotMap[message].type;
  }
  return 'text';
};

export const handleCommandFromUE = (
  dispatch: Dispatch<AnyAction>,
  message: string,
  lang: string,
  viewOnly?: boolean,
  storeId?: string
) => {
  //detect if ps focus on an object
  const msg = message?.toLowerCase().trim();

  //to-do: need a message for that
  if (ueSceneMap[msg]) {
    dispatch(actionDidGoToScene(ueSceneMap[msg]));
    dispatch(actionPixelStreamDidFocusOnAnObject(undefined));
    if (ueSceneMap[msg] !== CHScenes.SCENE_LOADING_CHAPTER && !viewOnly) {
      sendTimezoneInfo();
    }
  } else if (msg.includes('lefthotspot')) {
    dispatch(actionPixelStreamDidFocusOnAnObject(undefined));
  } else if (msg !== 'pressedback') {
    dispatch(
      actionPixelStreamDidFocusOnAnObject(
        msg
          ? {
              type: getSpotType(msg),
              id: msg
            }
          : undefined
      )
    );
  }
  if (objectSpotMap[msg]) {
    if (
      msg.includes('photo') ||
      msg.includes('text') ||
      msg.includes('story') ||
      msg.includes('c7_white')
    ) {
      openPopupInspiration(msg, dispatch, lang, storeId);
    }

    logEvent(DID_RECEIVE_UNREAL_EVENT, DID_RECEIVE_UNREAL_EVENT, {
      id: objectSpotMap[msg]?.id,
      type: objectSpotMap[msg]?.type,
      spot: msg
    });
    return;
  }

  const videoUrls = fullScrVideoMap[msg?.toLowerCase()?.trim()];
  if (videoUrls) {
    //wait 2 secs for UE move to that screen
    setTimeout(() => {
      playFullScreenVideo(videoUrls, dispatch);
    }, 2000);

    logEvent(DID_RECEIVE_UNREAL_EVENT, DID_RECEIVE_UNREAL_EVENT, {
      msg,
      type: PLAY_FULL_SCREEN_VIDEO,
      videoUrls
    });
  }
};

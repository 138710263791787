import React from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { getUserAgent, isUserOniOS } from '../../../utils/deviceDetector';

const WeChatPrompt = () => {
  const [isIOS, setIsIOS] = React.useState(false);
  const [isZh, setIsZh] = React.useState(false);

  const getChineseMessage = (isIOS) =>
    isIOS
      ? '请点击右上角的菜单,选择在Safari浏览器中打开'
      : '请点击右上角的菜单,选择在Chrome浏览器中打开';
  const getEnglishMessage = (isIOS) =>
    isIOS
      ? 'Please tap on the menu in upper right corner, and select Open in Safari.'
      : 'Please tap on the menu in upper right corner, and select Open with Browser.';

  const getMessageByLanguageAndDevice = (isZh, isIOS) => {
    return isZh ? getChineseMessage(isIOS) : getEnglishMessage(isIOS);
  };

  React.useEffect(() => {
    setIsIOS(isUserOniOS());
    setIsZh(/Language\/zh/i.test(getUserAgent()));
  }, []);

  return (
    <div className="prompt-container">
      <p className="message">{getMessageByLanguageAndDevice(isZh, isIOS)}</p>
      <BsArrowReturnRight />
      <style jsx>
        {`
          .prompt-container {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.8);
            z-index: 999;
          }

          .message {
            color: #fff;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            margin: 10px;
            font-size: 1.2rem;
            text-align: center;
          }

          :global(svg) {
            width: 50px;
            height: 50px;
            fill: #fff;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(-90deg);
          }
        `}
      </style>
    </div>
  );
};

export default WeChatPrompt;

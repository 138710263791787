import React from 'react';
import { ILanguageOption } from '../interfaces';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../analytics/index';
import { DID_SELECT_LANGUAGE } from '../utils/constants';
import { useDispatch } from 'react-redux';
import { actionUpdateLanguage } from '../redux/actions';

interface Props {
  languages: ILanguageOption[];
  theme?: 'dark' | 'light';
  onLangChange?: (language: string) => void;
}

const LanguageSwitcher = ({
  languages,
  theme = 'dark',
  onLangChange
}: Props) => {
  const { i18n } = useTranslation(['common', 'vb'], {
    bindI18n: 'languageChanged loaded'
  });

  const isDarkTheme = theme === 'dark';
  const dispatch = useDispatch();
  if (!languages.length) return null;

  return (
    <div className="LanguageSwitcher input-group input-group-sm">
      <label htmlFor="language-options">Language:</label>
      <select
        className="form-control"
        id="language-options"
        value={i18n.language}
        onChange={(e) => {
          const value = e.target.value;
          logEvent(DID_SELECT_LANGUAGE, DID_SELECT_LANGUAGE, {
            language: value,
            source: 'MENU'
          });
          i18n.changeLanguage(value);
          onLangChange?.(value);
          dispatch(actionUpdateLanguage(value));
        }}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.display.toUpperCase()}
          </option>
        ))}
      </select>
      <style jsx>{`
        .LanguageSwitcher {
          direction: ltr;
        }
        label {
          margin-right: 10px;
          font-size: 0.9em;
          margin-top: 5px;
        }

        #language-options {
          background: ${isDarkTheme ? 'transparent' : '#fff'};
          border-radius: 0;
          border: 1px solid ${isDarkTheme ? '#666' : '#000'};
          color: ${isDarkTheme ? '#fff' : '#000'};
          font-size: 16px;
        }
      `}</style>
      <style jsx global>
        {`
          .slidenav .LanguageSwitcher {
            padding: 15px 27px;
            border-top: 1px solid #666;
            margin-top: 20px;
            letter-spacing: 1px;
          }
          @media (min-width: 768px) {
            .slidenav .LanguageSwitcher {
              position: absolute;
              bottom: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LanguageSwitcher;

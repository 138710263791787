import React from 'react';
import {
  BsFillChatQuoteFill,
  BsHeartFill,
  BsList,
  BsNewspaper,
  BsPeopleFill,
  BsQuestionCircleFill,
  BsSearch,
  BsStarFill,
  BsX
} from 'react-icons/bs';
import { MdCompare } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import {
  IMainState,
  IStore,
  IVirtualBoutiqueConfig,
  VirtualBoutiqueMode
} from '../../interfaces';
import {
  DID_CLICK_CAMPAIGN,
  DID_CLICK_FEEDBACK_BUTTON,
  DID_CLICK_NEWS_LETTER,
  DID_CLICK_PICK_OF_THE_MONTH_BUTTON,
  DID_CLICK_PRODUCT_COMPARE_BUTTON,
  DID_CLICK_PRODUCT_SEARCH_BUTTON,
  DID_CLICK_TUTORIAL,
  DID_CLICK_WISHLIST_BUTTON
} from '../../utils/constants';
import { isUserOnMobile } from '../../utils/deviceDetector';
import LanguageSwitcher from '../LanguageSwitcher';
import { MDGlobalSpec } from '../Meeting/MeetingLayout/MDGlobal';
import { SMGlobalSpec } from '../Meeting/MeetingLayout/SMGlobal';
import {
  getConditionOfSaleButton,
  getSlideNavShareButton,
  getStoreLocationButton,
  getStoreLocationComponent
} from '../storeComponentFactory';

const SlideNav = ({
  onTutorial,
  config,
  store,
  onStartMeeting,
  isInMeeting,
  onProductSearch,
  onProductCompare,
  onWishlist,
  onFeedback,
  onPickOfTheMonth,
  isInWalkThrough = false,
  onCampaign
}: {
  onTutorial: () => void;
  config: IVirtualBoutiqueConfig;
  store: IStore;
  onStartMeeting: () => void;
  isInMeeting: boolean;
  onProductSearch: () => void;
  onProductCompare: () => void;
  onWishlist: () => void;
  onFeedback: () => void;
  onPickOfTheMonth?: () => void;
  isInWalkThrough?: boolean;
  onCampaign?: (pageId: string) => void;
}) => {
  const [isOnMobile, setIsOnMobile] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const clientState = useSelector((state: IMainState) => state.clientState);
  const vbMode = clientState.vb?.mode;
  const shouldShowLeftNav = vbMode === VirtualBoutiqueMode.PRESENTATION;
  const [showLocation, setShowLocation] = React.useState(false);
  React.useEffect(() => {
    setIsOnMobile(isUserOnMobile());
  }, []);

  const translateX = shouldShowLeftNav
    ? 'translateX(500px)'
    : 'translateX(-500px)';

  const { t } = useTranslation();

  const openLocation = () => {
    setShowLocation(true);
    setShow(false);
  };

  const closeLocation = () => {
    setShowLocation(false);
  };
  return (
    <nav className={`slidenav`}>
      <button
        className="slidenav-icon with-shadow btn-express"
        onClick={() => setShow(!show)}
      >
        <BsList color="fff" />
      </button>

      <div className={`nav-container ${show ? 'show' : ''}`}>
        <button
          type="button"
          className="nav-close btn-express"
          onClick={() => {
            setShow(false);
          }}
        >
          <span className="nav-item__icon">
            <BsX />
          </span>
        </button>

        {config.entitlement?.productSearch && (
          <button
            className="nav-item nav-search"
            type="button"
            onClick={() => {
              onProductSearch();
              logEvent(DID_CLICK_PRODUCT_SEARCH_BUTTON);
              setShow(false);
            }}
          >
            <span className="nav-item__icon">
              <BsSearch />
            </span>
            <span className="nav-item__label">{t('vb:product_search')}</span>
          </button>
        )}

        {config.entitlement?.productCompare && (
          <button
            className="nav-item nav-compare"
            type="button"
            onClick={() => {
              onProductCompare();
              logEvent(DID_CLICK_PRODUCT_COMPARE_BUTTON);
              setShow(false);
            }}
          >
            <span className="nav-item__icon">
              <MdCompare />
            </span>
            <span className="nav-item__label">{t('vb:product_compare')}</span>
          </button>
        )}

        {config.entitlement?.wishlist && (
          <button
            className="nav-item nav-wishlist"
            type="button"
            onClick={() => {
              onWishlist();
              logEvent(DID_CLICK_WISHLIST_BUTTON);
              setShow(false);
            }}
          >
            <span className="nav-item__icon">
              <BsHeartFill />
            </span>
            <span className="nav-item__label">
              {t('vb:wishlist_favorites')}
            </span>
          </button>
        )}

        {!isInMeeting &&
          !isInWalkThrough &&
          !isOnMobile &&
          config.entitlement?.friendsShopping && (
            <button
              className="nav-item start-meeting"
              type="button"
              onClick={() => {
                onStartMeeting();
                setShow(false);
              }}
            >
              <span className="nav-item__icon">
                <BsPeopleFill />
              </span>
              <span className="nav-item__label">
                {t('vb:discover_with_friends')}
              </span>
            </button>
          )}

        {config.tutorials && (
          <button
            className="nav-item nav-tutorials"
            type="button"
            onClick={() => {
              onTutorial();
              logEvent(DID_CLICK_TUTORIAL);
              setShow(false);
            }}
          >
            <span className="nav-item__icon">
              <BsQuestionCircleFill />
            </span>
            <span className="nav-item__label">{t('vb:tutorial')}</span>
          </button>
        )}

        {config.newsLetterSubscription && (
          <a
            href={config.newsLetterSubscription}
            target="_blank"
            className="nav-item nav-newsletter"
            onClick={() => {
              setShow(false);
              logEvent(DID_CLICK_NEWS_LETTER, config.newsLetterSubscription);
            }}
          >
            <span className="nav-item__icon">
              <BsNewspaper />
            </span>
            <span className="nav-item__label">
              {t('vb:newsletter_subscription')}
            </span>
          </a>
        )}

        {config.entitlement?.pickOfTheMonth && (
          <button
            className="nav-item nav-pickofthemonth"
            type="button"
            onClick={() => {
              onPickOfTheMonth();
              logEvent(DID_CLICK_PICK_OF_THE_MONTH_BUTTON);
              setShow(false);
            }}
          >
            <span className="nav-item__icon">
              <BsStarFill />
            </span>
            <span className="nav-item__label">{t('vb:pick_of_the_month')}</span>
          </button>
        )}
        {config.campaign?.imageCampaign?.popupPayload &&
          [
            'e0603ff4-2407-4416-b927-c4fc293a8e16',
            'bd9839d7-5747-458f-ba47-8d3f8ee09d63'
          ].includes(config.campaign.imageCampaign.popupPayload.pageId) && (
            <button
              className="nav-item nav-feedback"
              type="button"
              onClick={() => {
                onCampaign(config.campaign.imageCampaign.popupPayload.pageId);
                logEvent(DID_CLICK_CAMPAIGN, DID_CLICK_CAMPAIGN, {
                  showcasePage:
                    config.campaign.imageCampaign.popupPayload.pageId
                });
                setShow(false);
              }}
            >
              <span className="nav-item__icon">
                <BsStarFill />
              </span>
              <span className="nav-item__label">
                2021 Pilot’s Watches Collection
              </span>
            </button>
          )}

        {config.entitlement?.feedback && (
          <button
            className="nav-item nav-feedback hide-in-meeting"
            type="button"
            onClick={() => {
              onFeedback();
              logEvent(DID_CLICK_FEEDBACK_BUTTON);
              setShow(false);
            }}
          >
            <span className="nav-item__icon">
              <BsFillChatQuoteFill />
            </span>
            <span className="nav-item__label">{t('vb:feedback')}</span>
          </button>
        )}

        {getSlideNavShareButton(
          store?.id,
          config.defaultShareMessageKey
            ? t(config.defaultShareMessageKey)
            : store?.whatsAppShareMessage
        )}
        {getStoreLocationButton(store?.id, openLocation)}
        {getConditionOfSaleButton(store?.id, setShow)}

        {config.languages && config.languages.length > 1 && (
          <LanguageSwitcher languages={config.languages} />
        )}
      </div>

      {show && (
        <div className="nav-backdrop" onClick={() => setShow(!show)}></div>
      )}
      {showLocation && getStoreLocationComponent(store.id, closeLocation)}
      <style jsx>{`
        .nav-close {
          display: block;
          float: right;
          margin-top: 6px;
          background: transparent;
          color: #666;
        }
        .nav-close :global(svg) {
          width: 30px;
          height: auto;
        }

        .slidenav-icon {
          background: transparent;
          border: none;
          color: #fff;
          direction: ltr;
          margin-left: 10px;
        }
        .slidenav-icon :global(svg) {
          width: 28px;
          height: auto;
        }
        @media (max-width: 768px) {
          .start-meeting {
            display: none;
          }
          .slidenav-icon {
            background: transparent;
            border: none;
            color: #fff;
            direction: ltr;
            margin-left: 0px;
          }
        }

        .nav-container {
          position: fixed;
          right: ${shouldShowLeftNav ? 'auto' : '-500px'};
          left: ${shouldShowLeftNav ? '-500px' : 'auto'};
          top: 0;
          bottom: 0;
          text-align: left;
          background: #000;
          width: 300px;
          z-index: 41;
          overflow-y: auto;
          color: #fff;
          opacity: 0;
          transition-property: opacity, transform;
          transition-duration: 0.5s;
          transition-timing-function: ease-in-out;
        }

        :global(.in-meeting.SM) .nav-container {
          top: ${SMGlobalSpec.logoSectionHeight}px !important;
        }
        :global(.in-meeting.MD) .nav-container {
          top: ${MDGlobalSpec.logoSectionHeight}px !important;
        }

        .nav-container.show {
          transform: ${translateX};
          opacity: 1;
        }

        :global(.nav-backdrop) {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      `}</style>
    </nav>
  );
};

export default SlideNav;

import React, { useRef, useState } from 'react';
import { useFrame } from 'react-three-fiber';
import { Mesh } from 'three';
const SSPHotspot = ({
  name,
  position,
  radius,
  setAndHideDetail
}: {
  name?: string;
  position?: number[];
  radius?: number;
  setAndHideDetail: (detail: string) => void;
}) => {
  const hotspotRef: React.MutableRefObject<null> = useRef();
  const hotspotMesh: Mesh = hotspotRef?.current;
  const [zoomSpeed, setZoomSpeed] = useState(0.01);
  useFrame(() => {
    if (hotspotMesh?.scale) {
      hotspotMesh.scale.x += zoomSpeed;
      hotspotMesh.scale.y = hotspotMesh.scale.x;
      hotspotMesh.scale.z = hotspotMesh.scale.x;
      if (hotspotMesh.scale.x > 1.1) {
        setZoomSpeed(-0.01);
      }
      if (hotspotMesh.scale.x < 0.9) {
        setZoomSpeed(0.01);
      }
    }
  });
  return (
    <mesh
      name={name}
      ref={hotspotRef}
      onPointerDown={() => {
        setAndHideDetail(name);
      }}
      position={position ? [position[0], position[1], position[2]] : [0, 0, 0]}
    >
      <sphereGeometry
        attach="geometry"
        args={[radius ? radius : 0.015, 8, 8]}
      />
      <meshPhongMaterial
        attach="material"
        color="black"
        transparent={true}
        opacity={0.2}
      />
    </mesh>
  );
};

export default SSPHotspot;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHNavArrowIcon } from './CHIcons';
import {
  actionFocusItem,
  actionSelectAccessoriesSection
} from '../../../redux/actions';
import { CHScenes } from '../PSInterface';
import { chopardBrandId } from '../../../config';
import { getPopupAction, PageView } from '../../../utils/pageView';
import { IMainState } from '../../../interfaces';
import { logClickButton, logEvent } from '../../../analytics';
import { DID_SEND_UNREAL_EVENT } from '../../../utils/constants';
import { useTranslation } from '../../../i18n';
import { CHTranslation, objectSpotMap } from './CHConfig';
import { actionGetProductAccessoriesAsync } from '../../../redux/productAsyncAction';
import CHCustomizeOverlay from './CHCustomizeOverlay';
import { AnyAction } from 'redux';
import { wrapActionToBeMirroring } from '../../../utils/meeting';
import { useViewportInfo } from '../../hooks/ux';

let navigateTimeOut;

const CHOverlayButtons = ({
  onSendCommand,
  show = false,
  hide = false,
  currentScene,
  allowCustomize,
  viewOnly
}: {
  onSendCommand: (c: any) => void;
  show: boolean;
  currentScene?: string;
  hide?: boolean;
  allowCustomize?: boolean;
  viewOnly?: boolean;
}) => {
  const dispatch = useDispatch();
  const mirrorAction = (action: AnyAction) => {
    wrapActionToBeMirroring(dispatch, action, { to: '' });
  };
  const { i18n } = useTranslation();
  const [startShow, setStartShow] = React.useState(false);

  const [isNavigating, setIsNavigating] = React.useState(false);

  const { onMobile } = useViewportInfo();

  const customize =
    useSelector(
      (state: IMainState) => state.clientState?.focusedItem?.section
    ) === 'personalize';

  const storeId = useSelector(
    (state: IMainState) => state.clientState?.store?.id
  );
  const psState = useSelector(
    (state: IMainState) => state.clientState?.vb?.pixelStreaming
  );

  const popupState = useSelector(
    (state: IMainState) => state.clientState?.vb?.popup
  );

  const hotspot = psState?.focusedObject;

  const accessories = useSelector(
    (state: IMainState) => state.clientState?.productAccessories?.straps
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const isProductHotspot = hotspot?.type === PageView.PRODUCT;
  const isStoryHotspot =
    hotspot?.type === PageView.INSPIRATION ||
    hotspot?.type === PageView.STORY_JOURNEY;

  const isPopupHotSpot = isProductHotspot || isStoryHotspot;

  const shouldAutoBack = isStoryHotspot && !onMobile;
  const temporarilyHide = hide || customize || shouldAutoBack;

  const hideStatus = React.useRef<boolean>();

  const hidePrevNext =
    hotspot?.id === 'story4001' ||
    (currentScene !== CHScenes.SCENE_CHAPTER4 &&
      ((isProductHotspot
        ? objectSpotMap[hotspot?.id]?.hidePrevNext
        : !objectSpotMap[hotspot?.id]?.showPrevNext) ||
        (hotspot?.id === '278610-3001' &&
          currentScene === CHScenes.SCENE_CHAPTER3)));

  const { t } = useTranslation();

  const onBack = () => {
    onSendCommand('BACK');

    logEvent(DID_SEND_UNREAL_EVENT, DID_SEND_UNREAL_EVENT, {
      type: 'back'
    });
  };

  const handleClickBack = () => {
    logClickButton('BACK_BUTTON');
    onBack();
  };

  const handleCustomize = () => {
    logClickButton('CUSTOMIZE_BUTTON');
    mirrorAction(
      actionFocusItem({
        section: 'personalize',
        itemId: objectSpotMap[hotspot?.id]?.id
      })
    );
    mirrorAction(actionSelectAccessoriesSection('#strapSection'));
  };

  const handleNavigate = (move: boolean) => {
    if (navigateTimeOut) {
      clearTimeout(navigateTimeOut);
    }

    setIsNavigating(move);

    if (move) {
      navigateTimeOut = setTimeout(() => {
        setIsNavigating(false);
      }, 3400);
    }
  };

  const handleNextPrev = (type: 'next' | 'previous') => {
    handleNavigate(true);
    onSendCommand(type);
    logClickButton(type === 'next' ? 'NEXT_BUTTON' : 'PREV_BUTTON');
    logEvent(DID_SEND_UNREAL_EVENT, DID_SEND_UNREAL_EVENT, {
      type
    });
  };
  const btnNext = (
    <button
      className="btn-next btn-next-prev btn chopard-sans animated from-right"
      onClick={() => {
        handleNextPrev('next');
      }}
    >
      <CHNavArrowIcon direction="right" animated={true} />
    </button>
  );
  const btnPrevious = (
    <button
      className="btn-previous btn-next-prev btn chopard-sans animated from-left"
      onClick={() => {
        handleNextPrev('previous');
      }}
    >
      <CHNavArrowIcon direction="left" animated={true} />
    </button>
  );

  const handleClickDetail = (storeId: string) => {
    if (isPopupHotSpot) {
      const id =
        (hotspot?.type === PageView.INSPIRATION ||
          hotspot?.type === PageView.STORY_JOURNEY) &&
        i18n.language === 'ko'
          ? objectSpotMap[hotspot?.id]?.idKo
          : objectSpotMap[hotspot?.id]?.id;
      const action = getPopupAction(
        `${location.protocol}//${location.host}`,
        hotspot?.type as PageView,
        id,
        chopardBrandId,
        storeId,
        i18n.language || 'en'
      );
      if (action) {
        dispatch(action);
      }
    }
  };

  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        setStartShow(true);
      }, 2300);
    } else {
      setStartShow(false);
      mirrorAction(actionFocusItem(undefined));
    }
  }, [show]);

  React.useEffect(() => {
    if (!hide && hideStatus.current && shouldAutoBack) {
      onBack();
    }
    if (hideStatus) {
      hideStatus.current = hide;
    }
  }, [hide]);

  React.useEffect(() => {
    if (!hotspot?.id) return;
    setTimeout(() => handleNavigate(false), 1200);
    if (objectSpotMap[hotspot.id] && allowCustomize) {
      dispatch(
        actionGetProductAccessoriesAsync(
          objectSpotMap[hotspot.id].id,
          '#strapSection'
        )
      );
    }
    if (popupState?.open && objectSpotMap[hotspot?.id]) {
      handleClickDetail(storeId);
    }
  }, [hotspot?.id]);

  const btnTitle = objectSpotMap[hotspot?.id]?.title
    ? CHTranslation[objectSpotMap[hotspot?.id]?.title]?.[i18n.language]
    : undefined;

  const btnLabel = isProductHotspot
    ? btnTitle
      ? btnTitle
      : t('product_details')
    : t('more_details');

  const overlayButton = (
    <div
      className={`ps-nav-buttons ${
        !isPopupHotSpot ? 'hide-hotspot-btns' : ''
      } ${hidePrevNext ? 'hide-prev-next' : ''} ${
        !accessories ? 'hide-custom' : ''
      } ${isNavigating ? 'isNavigating' : ''} ${startShow ? 'show' : ''} ${
        temporarilyHide ? 'hide' : ''
      } noselect`}
      ref={containerRef}
    >
      <div className="nav-wrapper">
        <div className="hotspot-nav">
          {btnPrevious}
          <div className="hotspot-btns animated from-top">
            <button
              className="btn-detail btn chopard-sans"
              onClick={() => {
                handleClickDetail(storeId);
                logClickButton(btnLabel);
              }}
            >
              {btnLabel}
            </button>
            <button
              className="btn-customize btn chopard-sans"
              onClick={handleCustomize}
            >
              {t('customize')}
            </button>
          </div>
          {btnNext}
        </div>

        <div className="nav-back animated from-bottom">
          <button
            className="btn-back btn chopard-sans"
            onClick={handleClickBack}
          >
            <span>{t('vb:back')}</span>
            <CHNavArrowIcon direction="down" animated={true} />
          </button>
        </div>
      </div>
      <style jsx>{`
        .ps-nav-buttons :global(.animated) {
          transition: all 0.4s ease-in-out;
        }
        .ps-nav-buttons :global(.animated.from-top) {
          transform: translate(0, -100%);
        }
        .ps-nav-buttons :global(.animated.from-left) {
          transform: translate(-100%, 0);
        }
        .ps-nav-buttons :global(.animated.from-right) {
          transform: translate(100%, 0);
        }
        .ps-nav-buttons :global(.animated.from-bottom) {
          transform: translate(0, 100%);
        }
        .show :global(.animated) {
          transform: translate(0, 0) !important;
        }
        .show.hiding :global(.animated.from-top),
        .show.hiding :global(.animated.from-left),
        .show.hiding :global(.animated.from-right) {
          opacity: 0;
        }

        .ps-nav-buttons {
          left: 0;
          position: absolute;
          right: 0;
          bottom: 0px;
          padding: 1.5em 0;
          z-index: 3;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s ease-in-out;
        }
        .ps-nav-buttons :global(.btn:hover svg) {
          filter: ${!onMobile
            ? 'drop-shadow(0 0 1px rgb(255 255 255 / 1)) drop-shadow(0 0 3px rgb(255 255 255 / 1))'
            : 'none'};
        }
        .ps-nav-buttons.show {
          opacity: 1;
          pointer-events: all;
        }
        .ps-nav-buttons.hide {
          opacity: 0;
          pointer-events: none;
        }
        :global(.in-meeting) .ps-nav-buttons {
          padding-bottom: 4rem;
        }

        @media (max-width: 768px) {
          :global(.in-meeting) .ps-nav-buttons {
            padding-bottom: 1.5rem;
            font-size: 14px;
          }
        }

        .ps-nav-buttons :global(.btn):focus {
          box-shadow: none;
        }
        .nav-wrapper {
          width: min(600px, 100vw);
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          justify-content: center;
        }
        .nav-wrapper :global(.btn) {
          margin: 0 4px;
          border-radius: 0px;
          color: white;
          cursor: pointer;
        }

        .btn-back {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          gap: 7px;
          pointer-events: all;
          font-size: 14px;
          justify-content: center;
        }
        .hotspot-nav {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
        }
        .btn-customize {
          display: inline;
        }
        .hide-custom .btn-customize {
          display: none;
        }
        .icon-arrow {
          order: 1;
          width: 100%;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .hide-hotspot-btns .hotspot-btns {
          opacity: 0;
          pointer-events: none;
        }
        .show.isNavigating .hotspot-btns,
        .show.isNavigating :global(.btn-next-prev) {
          opacity: 0;
          pointer-events: none;
        }
        .hotspot-btns .btn {
          min-width: 170px;
          text-transform: uppercase;
          border: 1px solid #ffffff;
          background-color: rgba(172, 172, 172, 0.3);
          color: white;
          padding: 0.5rem 0.75rem;
          transition: background-color 0.2s ease-in-out;
        }
        .hotspot-btns .btn:hover {
          background-color: rgba(172, 172, 172, 0.7);
        }

        .hotspot-nav :global(.btn-next) {
          margin-left: 20px;
        }
        .hotspot-nav :global(.btn-previous) {
          margin-right: 20px;
        }
        .hide-prev-next :global(.btn-next),
        .hide-prev-next :global(.btn-previous) {
          opacity: 0;
        }
        @media (max-width: 768px) {
          .hotspot-btns .btn {
            margin: 5px 0px !important;
          }
          .hotspot-nav :global(.btn-next) {
            margin-left: 5px;
          }
          .hotspot-nav :global(.btn-previous) {
            margin-right: 5px;
          }
        }

        @media (orientation: landscape) and (max-height: 500px) {
          .nav-wrapper {
            width: 200px;
            position: relative;
            height: 100%;
            justify-content: flex-end;
          }
          .ps-nav-buttons {
            padding: 0.9em 0;
            top: 0;
            left: auto;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            background: linear-gradient(
              -90deg,
              rgba(0, 0, 0, 0.9) 0%,
              rgba(0, 0, 0, 0) 100%
            );
          }

          .hotspot-btns {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            flex-direction: column;
            display: flex;
          }
          .hotspot-btns .btn {
            margin: 5px 24px !important;
            min-width: 120px;
            font-size: 14px;
          }

          .hotspot-nav {
            margin-bottom: -20px;
            z-index: 1;
          }
        }
      `}</style>
    </div>
  );

  return (
    <>
      {!viewOnly && overlayButton}
      <CHCustomizeOverlay
        onBack={() => mirrorAction(actionFocusItem(undefined))}
        show={customize && !hide}
        viewOnly={viewOnly}
      />
    </>
  );
};

export default CHOverlayButtons;

import React from 'react';
import { logEvent } from '../../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../../utils/constants';

const LFNeonButton = ({
  children,
  name,
  onClick,
  size = 'normal',
  className = '',
  style = {},
  fullWidth,
  noBorder = false,
  disabled = false
}: {
  children: React.ReactNode;
  name: string;
  onClick: () => void;
  size?: 'sm' | 'normal' | 'lg';
  className?: string;
  style?: { [key: string]: any };
  fullWidth?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      className={`btn btn-${size} ${fullWidth ? 'w-100' : ''} ${className} ${
        noBorder && 'no-border'
      }`}
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: name });
        onClick();
      }}
      style={style}
    >
      <div className="inner-line" />
      <span>{children}</span>
      <style jsx>{`
        .btn {
          outline: none;
          box-shadow: none;
          text-transform: uppercase;
          border: 2px solid #13fff1;
          box-shadow: 0px 1px 5px #13fff1, #13fff1 0px 0px 5px inset;
          padding: 0;
          border-radius: 5px;
          position: relative;
        }
        .btn.no-border {
          border: none;
          box-shadow: none;
        }

        .btn.no-border .inner-line {
          display: none;
        }

        .inner-line {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: -1.2px;
          z-index: 1;
          border: 0.5px solid #b663e0;
          border-radius: inherit;
        }

        .btn span {
          display: block;
          font-size: 1rem;
          letter-spacing: 0.06em;
          font-family: 'Antique Olive', sans-serif;
        }
        .btn-sm span {
          padding: 0.3rem 0.6rem;
          font-size: 0.8rem;
        }
        .btn-lg span {
          padding: 0.6rem 0.75rem;
          font-size: 1.2rem;
        }
      `}</style>
    </button>
  );
};

export default LFNeonButton;

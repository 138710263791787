import LoubiButtonWrapper from './LoubiButtonWrapper';

const SkipVideoButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <LoubiButtonWrapper>
      <img
        src="/asset/louboutin/skip_film.png"
        alt="skip"
        onClick={onClick}
        className="loubi-circle-button bottom-right"
      />
    </LoubiButtonWrapper>
  );
};

export default SkipVideoButton;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState, ParticipantMeetingState } from '../../interfaces';
import { actionSetMeetingDate } from '../../redux/actions';
import { convertMilisToDuration } from '../../utils/string';

let interval;
const MeetingTimer = ({
  className = '',
  textColor = '#fff',
  background = 'rgba(0,0,0,.5)'
}: {
  className?: string;
  textColor?: string;
  background?: string;
}) => {
  const meeting =
    useSelector((state: IMainState) => state.clientState?.meeting) || {};
  const meetingState = meeting.state;
  const hasParticipant = Object.keys(meeting.remoteUsers || {}).length !== 0;
  const meetingDate = meeting.date;
  const dispatch = useDispatch();

  const shouldRestartTimer =
    !meetingDate &&
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE &&
    hasParticipant;

  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    if (shouldRestartTimer) {
      dispatch(actionSetMeetingDate(Date.now()));
    }
  }, [shouldRestartTimer]);

  React.useEffect(() => {
    if (hasParticipant && meetingDate && !interval) {
      interval = setInterval(() => {
        setCounter(Math.floor(Date.now() - meetingDate));
      }, 1000);
    }
  }, [hasParticipant, meetingDate]);

  React.useEffect(() => {
    return () => clearInterval(interval);
  }, []);

  const formatted = convertMilisToDuration(counter);

  if (!meetingDate) return null;
  return (
    <div className={`MeetingTimer ${className}`}>
      <div className="timer">
        <span>{formatted}</span>
      </div>
      <style jsx>
        {`
          .MeetingTimer {
            padding-top: 5px;
          }
          .MeetingTimer .timer {
            padding: 5px;
            text-align: center;
            min-width: 4.5rem;
            border-radius: 0.5rem;
            background: ${background};
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .timer span {
            color: ${textColor};
            font-family: 'Arial';
            font-weight: 600;
            font-size: 0.8rem;
          }
        `}
      </style>
    </div>
  );
};

export default MeetingTimer;

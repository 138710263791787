import React from 'react';
import { useSelector } from 'react-redux';
import { assetBaseUrl } from '../../../../config';
import { IMainState, ParticipantMeetingState } from '../../../../interfaces';
import { useScrollListenerForVoyage } from '../../../hooks/scroll';
import { MDGlobalSpec } from '../../../Meeting/MeetingLayout/MDGlobal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMGlobalSpec } from '../../../Meeting/MeetingLayout/SMGlobal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
const asset = `${assetBaseUrl}/loubiairways/pages/v2`;

const LoubiAirwaysPageContainer = ({
  children,
  className = '',
  headerComponent,
  footerComponent,
  reference
}: {
  children: React.ReactNode;
  className?: string;
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  reference?: React.MutableRefObject<any>;
}) => {
  const [addScrollListenForVoyage, removeScrollListenForVoyage] =
    useScrollListenerForVoyage();

  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting?.state
  );
  const isInMeeting =
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;

  React.useEffect(() => {
    addScrollListenForVoyage();
    return () => {
      removeScrollListenForVoyage();
    };
  }, []);

  return (
    <div
      className={`loubiPageContainer fixed-full ${className} ${
        isInMeeting ? 'meeting' : ''
      }`}
      id="voyage-scroll-listener"
    >
      <div className="container">
        <div className="header">
          <img
            src={`${asset}/LoubiAirways.png`}
            className="logo"
            alt="LOUBIAIRWAYS"
          />
          {headerComponent}
        </div>
        <div className="content" ref={reference}>
          {children}
        </div>
        <div className="footer">
          {footerComponent}
          <img src={`${asset}/sole.png`} className="sole" alt="" />
        </div>
        <img src={`${asset}/stamp.png`} alt="" className="stamp" />
      </div>
      <style jsx>{`
        .loubiPageContainer {
          background-color: #f3f3d1;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: auto;
        }
        :global(.in-meeting.SM) .loubiPageContainer {
          top: ${SMGlobalSpec.logoSectionHeight}px;
        }

        :global(.in-meeting.SMPortrait) .loubiPageContainer {
          bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
        }

        :global(.in-meeting.MD) .loubiPageContainer {
          top: ${MDGlobalSpec.logoSectionHeight}px;
        }
        :global(.in-meeting.MDPortrait) .loubiPageContainer {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
        }
        .header,
        .footer,
        .content {
          border-radius: 40px;
        }
        .header,
        .footer {
          background-color: #cf152d;
          padding: 40px;
          text-align: center;
          position: relative;
        }
        .footer {
          padding: 20px;
        }

        .container {
          width: 100%;
          max-width: 1200px;
          padding: 20px;
          position: relative;
          margin: auto;
        }

        .content {
          background: #73bee8 url('${asset}/cloud.jpg') center top no-repeat;
          background-size: cover;
          margin: 20px 0;
          padding: 60px 30px;
        }

        .header .logo {
          width: 100%;
          max-width: 380px;
          height: auto;
        }
        .footer img {
          height: 50px;
          width: auto;
        }

        .stamp {
          pointer-events: none;
          position: absolute;
          bottom: 2px;
          width: 25%;
          right: 2px;
          min-width: 150px;
          opacity: 0.7;
          filter: brightness(0.8);
        }
        @media (max-width: 1200px) {
          .header .logo {
            max-width: 280px;
          }
          .header,
          .footer,
          .content {
            border-radius: 4vw;
          }
          .content {
            margin: 15px 0;
          }
          .footer {
            padding: 10px;
          }
          .footer img {
            height: 30px;
          }
        }
        @media (orientation: landscape) and (max-height: 800px) {
          .header .logo {
            max-width: 180px;
          }
          .footer img {
            height: 25px;
          }
          .stamp {
            width: 18%;
          }
        }

        @media (orientation: landscape) and (max-height: 600px) {
          .header .logo {
            max-width: 100px;
          }
          .footer img {
            height: 20px;
          }
          .stamp {
            width: 18%;
          }
        }

        @media (min-width: 768px) {
          .header {
            position: sticky;
            top: -30px;
            z-index: 2;
          }
        }
        @media (max-width: 768px) {
          .container {
            padding: 10px;
            padding-top: 50px !important;
          }
          .content {
            margin: 10px 0;
            padding: 40px 20px;
          }
          .header {
            padding: 20px;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            border-radius: 0;
            z-index: 2;
          }
          .header .logo {
            max-width: 120px;
          }
          .footer {
            padding: 10px;
          }
          .footer img {
            height: 40px;
          }
        }
        :global(.in-meeting.SM) .header {
          top: ${SMGlobalSpec.logoSectionHeight}px;
        }
      `}</style>
    </div>
  );
};

export default LoubiAirwaysPageContainer;

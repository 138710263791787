import React from 'react';
import { CHScenes } from '../../PSInterface';
import CHDiamond from './CHDiamond';
import { mapAsset, mapSpot } from './CHMaps';

let clockInterval;

const CHMapDial = ({
  activeScene,
  setActiveScene,
  onClickSpot,
  isTouch
}: {
  activeScene?: CHScenes;
  setActiveScene: (s: CHScenes) => void;
  onClickSpot?: (scene: CHScenes) => void;
  isTouch?: boolean;
}) => {
  const [clock, setClock] = React.useState<{ h: number; m: number; s: number }>(
    {
      h: Math.round(
        (new Date().getTime() - new Date().setHours(0, 0, 0, 0)) / 1000 / 720
      ),
      m: Math.round(
        (new Date().getTime() - new Date().setHours(0, 0, 0, 0)) / 1000 / 60
      ),
      s: new Date().getTime() - new Date().setHours(0, 0, 0, 0)
    }
  );

  const updateClock = () => {
    const now = (new Date().getTime() - new Date().setHours(0, 0, 0, 0)) / 1000;
    setClock({
      h: Math.round(now / 720),
      m: Math.round(now / 60),
      s: now
    });
  };

  React.useEffect(() => {
    clockInterval = setInterval(() => {
      updateClock();
    }, 200);
    return () => {
      if (clockInterval) {
        clearInterval(clockInterval);
      }
    };
  }, []);
  return (
    <div className="CHMapDial">
      <img src={`${mapAsset}/ratio-keeper.png`} alt="map" />
      <img
        src={`${mapAsset}/watch-h.png`}
        className="dial dial-h"
        alt="hour"
        style={{ transform: `rotate(${clock.h * 6}deg)` }}
      />
      <img
        src={`${mapAsset}/watch-m.png`}
        className="dial dial-m"
        alt="minute"
        style={{ transform: `rotate(${clock.m * 6}deg)` }}
      />
      <img
        src={`${mapAsset}/watch-s.png`}
        className="dial dial-s"
        alt="second"
        style={{ transform: `rotate(${clock.s * 6}deg)` }}
      />
      {mapSpot.map((spot) => {
        if (spot.diamondX) {
          return (
            <CHDiamond
              {...spot}
              key={spot.command}
              active={activeScene === spot.command}
              isNotDiamond={
                spot.command === CHScenes.SCENE_LOBBY ||
                spot.command === CHScenes.SCENE_ENTRANCE
              }
              setActiveScene={setActiveScene}
              onClickSpot={onClickSpot}
              isTouch={isTouch}
            />
          );
        }
      })}
      <style jsx>{`
        .CHMapDial {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        img {
          width: 100%;
          height: auto;
          user-select:none;
        }
        .dial {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          -webkit-filter: drop-shadow(0 0.2vh 0.1vh rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0 0.2vh 0.1vh rgba(0, 0, 0, 0.5));
        }
      `}</style>
    </div>
  );
};

export default CHMapDial;

import React from 'react';
import { logEvent } from '../../analytics';
import { apProdHost } from '../../config';
import { useTranslation } from '../../i18n';
import {
  DID_START_MEETING,
  DID_TOGGLE_MEETING_CONTROLS
} from '../../utils/constants';
import CloseSvgIcon from './Icons/CloseSvgIcon';
import ExpandSvgIcon from './Icons/ExpandSvgIcon';

interface IMeetingContainerProps {
  showControls: boolean;
  meetingSubject: string;
  meetingId: string;
  toggleControls: () => void;
  onMeetingEnd: () => void;
}

const MeetingContainer = ({
  showControls,
  meetingSubject,
  toggleControls,
  onMeetingEnd,
  meetingId
}: IMeetingContainerProps) => {
  React.useEffect(() => {
    const options = {
      roomName: meetingId,
      width: '100%',
      height: '100%',
      parentNode: document.querySelector('#meet')
    };
    const api = new window['JitsiMeetExternalAPI'](apProdHost, options);
    api.executeCommand('subject', meetingSubject);
    api.addEventListener('readyToClose', () => {
      onMeetingEnd();
    });
    logEvent(DID_START_MEETING, meetingId);
  }, []);

  const joinMeetingUrl = `${apProdHost}/${meetingId}`;

  const { t } = useTranslation();

  return (
    <div className={`meet-container ${showControls ? 'show-controls' : ''}`}>
      <p className="message">
        <span>
          {t('vb:join_meeting_at')}:{' '}
          <span className="join-url">{joinMeetingUrl}</span>
        </span>
        <span
          className="controls"
          onClick={() => {
            toggleControls();
            logEvent(DID_TOGGLE_MEETING_CONTROLS);
          }}
        >
          {t('vb:meeting_controls')}
          {showControls ? (
            <CloseSvgIcon style={{ width: '20px', height: '20px' }} />
          ) : (
            <ExpandSvgIcon style={{ width: '24px', height: '24px' }} />
          )}
        </span>
      </p>
      <div id="meet"></div>
    </div>
  );
};

export default MeetingContainer;

import React from 'react';
import { JitsiDevice } from '../../../interfaces';
import { BiVideo } from 'react-icons/bi';
import { useTranslation } from '../../../i18n';
import { logEvent } from '../../../analytics/index';
import { DID_SELECT_CAMERA } from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';

export const Camera = ({
  deviceId,
  isActive,
  onClick,
  disabled
}: {
  deviceId: string;
  isActive: boolean;
  onClick: (deviceId: string) => void;
  disabled: boolean;
}) => {
  const videoRef = React.useRef<HTMLVideoElement>();
  const [videoLoaded, setVideoLoaded] = React.useState(false);
  const didMountRef = React.useRef(false);

  const unloadStream = (_stream?: MediaStream) => {
    const streams = _stream || (videoRef.current?.srcObject as MediaStream);
    if (streams) {
      const tracks = streams?.getTracks();
      tracks?.forEach((track) => track.stop());
    }
    if (videoRef.current) {
      videoRef.current?.pause?.();
      videoRef.current.srcObject = null;
      videoRef.current?.removeAttribute('src');
    }
  };
  React.useEffect(() => {
    const instance = videoRef.current;
    didMountRef.current = true;
    const constraints = {
      video: {
        deviceId,
        width: { min: 480 },
        height: { min: 480 }
      }
    };
    console.log('debug:: component mounted, loading stream');
    navigator.mediaDevices?.getUserMedia(constraints)?.then((stream) => {
      // if the component unmounted before the stream was loaded, unload the stream
      if (!didMountRef.current) {
        unloadStream(stream);
        return;
      }
      if (instance) {
        instance.srcObject = stream;
        instance.load();
        setVideoLoaded(true);
      }
    });
    return () => {
      unloadStream(instance?.srcObject as MediaStream);
      didMountRef.current = false;
    };
  }, []);

  return (
    <button
      className={`list-group-item list-group-item-action ${
        isActive ? 'list-group-item-secondary' : ''
      }`}
      onClick={() => {
        onClick(deviceId);
      }}
      disabled={disabled}
    >
      <video ref={videoRef} autoPlay playsInline muted />
      <style jsx>
        {`
          button {
            font-weight: 200;
          }
          .list-group-item-secondary {
            background-color: #efefef;
          }
          span {
            display: block;
          }
          video {
            width: 100%;
            height: ${videoLoaded ? 'auto' : '0'};
            margin: 0 auto;
            display: block;
            object-fit: cover;
          }
        `}
      </style>
    </button>
  );
};

export const CameraLabel = ({
  deviceId,
  isActive,
  label,
  onClick,
  disabled
}: {
  deviceId: string;
  isActive: boolean;
  label: string;
  onClick: (deviceId: string) => void;
  disabled: boolean;
}) => {
  return (
    <button
      className={`list-group-item list-group-item-action ${
        isActive ? 'list-group-item-secondary' : ''
      }`}
      onClick={() => {
        onClick(deviceId);
      }}
      disabled={disabled}
    >
      {label}
      <style jsx>
        {`
          button {
            font-weight: 200;
          }
          .list-group-item-secondary {
            background-color: #efefef;
          }
          span {
            display: block;
          }
        `}
      </style>
    </button>
  );
};

export const Cameras = ({
  devices,
  onSelect,
  activeDevice,
  allowChange = false
}: {
  devices: JitsiDevice[];
  onSelect: (device: string) => void;
  activeDevice: string;
  allowChange: boolean;
}) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    setIsMobile(isUserOnMobile());
  }, []);

  return (
    <>
      <span className="list-group-item device-title d-flex align-items-center">
        <BiVideo /> <strong>{t('cameras')}</strong>
      </span>
      {devices.map((device: JitsiDevice, i) => {
        return isMobile ? (
          <CameraLabel
            key={i + device.deviceId}
            deviceId={device.deviceId}
            isActive={device.deviceId === activeDevice}
            label={device.label}
            onClick={() => {
              onSelect(device.deviceId);
              logEvent(DID_SELECT_CAMERA, DID_SELECT_CAMERA, {
                deviceName: device.label
              });
            }}
            disabled={!allowChange}
          />
        ) : (
          <Camera
            key={i + device.deviceId}
            deviceId={device.deviceId}
            isActive={device.deviceId === activeDevice}
            onClick={() => {
              onSelect(device.deviceId);
              logEvent(DID_SELECT_CAMERA, DID_SELECT_CAMERA, {
                deviceName: device.label
              });
            }}
            disabled={!allowChange}
          />
        );
      })}

      <style jsx>{`
        li {
          text-align: left;
        }
        .device-title :global(svg) {
          margin-right: 10px;
        }
      `}</style>
    </>
  );
};

export default Cameras;

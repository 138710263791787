import React from 'react';
import { JitsiDevice } from '../../../interfaces';
import { FaVolumeUp } from 'react-icons/fa';
import { useTranslation } from '../../../i18n';
import { logEvent } from '../../../analytics/index';
import { DID_SELECT_SPEAKER } from '../../../utils/constants';

export const Speaker = ({
  deviceId,
  isActive,
  label,
  onClick,
  disabled
}: {
  deviceId: string;
  isActive: boolean;
  label: string;
  onClick: (deviceId: string) => void;
  disabled: boolean;
}) => {
  const audioRef: any = React.createRef();
  const onTest = async (e) => {
    e.stopPropagation();
    try {
      await audioRef?.current?.setSinkId(deviceId);
      audioRef?.current?.play();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <button
      className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
        isActive ? 'list-group-item-secondary' : ''
      }`}
      onClick={() => {
        onClick(deviceId);
      }}
      disabled={disabled}
    >
      <span>{label}</span>
      <span className="badge badge-secondary badge-pill" onClick={onTest}>
        TEST
      </span>
      <audio preload="auto" ref={audioRef} src="/asset/ring.wav" />
      <style jsx>
        {`
          button {
            font-weight: 200;
          }
          .list-group-item-secondary {
            background-color: #efefef;
          }
        `}
      </style>
    </button>
  );
};

const Speakers = ({
  devices,
  onSelect,
  activeDevice,
  allowChange = false
}: {
  devices: JitsiDevice[];
  onSelect: (device: string) => void;
  activeDevice: string;
  allowChange: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="list-group-item device-title d-flex align-items-center">
        <FaVolumeUp /> <strong>{t('speakers')}</strong>
      </span>
      {devices.map((device: JitsiDevice, i) => (
        <Speaker
          key={i + device.deviceId}
          deviceId={device.deviceId}
          isActive={device.deviceId === activeDevice}
          label={device.label}
          onClick={() => {
            logEvent(DID_SELECT_SPEAKER, DID_SELECT_SPEAKER, {
              deviceName: device.label
            });
            onSelect(device.deviceId);
          }}
          disabled={!allowChange}
        />
      ))}
      <style jsx>{`
        li {
          text-align: left;
        }
        .device-title :global(svg) {
          margin-right: 10px;
        }
      `}</style>
    </>
  );
};

export default Speakers;

import React from 'react';
import { useLoubiFutureState } from '../../../../hooks/loubiFuture';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';

const ARQRCode = () => {
  const productId = useLoubiFutureState().selectedProductId;
  return (
    <>
      <h3 className="LFTitle">
        SCAN QR CODE FOR
        <br />
        AUGMENTED REALITY
      </h3>
      <div className="qr-container">
        <img
          src={`${LFAssetBaseUrl}/qr/v1/${productId}.png`}
          alt="AR Experience URL"
        />
      </div>
      <style jsx>{`
        .LFTitle{
          font-size:1.1em !important;
        }
        .qr-container {
          display: inline-block;
          border-radius: 4px;
          margin-top: 20px;
        }
        img {
          display: block;
          width:100%;
        }
      `}</style>
    </>
  );
};

export default ARQRCode;

import React from 'react';
import { assetBaseUrl } from '../../../config';

const InverseDisconnected = ({ onReconnect }: { onReconnect: () => void }) => {
  const disconnectedBackground = `${assetBaseUrl}/inverse/landing_background.jpg`;
  const landingLogo = `${assetBaseUrl}/inverse/inspify_showroom.png`;
  return (
    <>
      <div className="wrapper">
        <div className={`inverse-disconnected`}>
          <div className="logo">
            <img src={landingLogo} alt="Inspify Showroom" />
          </div>
          <p>Experience has been disconnected</p>
          <button
            className="btn btn-action btn-outline-light"
            onClick={onReconnect}
          >
            Reconnect
          </button>
        </div>
      </div>
      <style jsx>{`
        .inverse-disconnected {
          background-image: url('${disconnectedBackground}');
          background-color: #000;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          flex-direction: column;
          gap: 25px;
          color: white;
          transition: opacity 1s ease-in-out;
        }
        .heading {
          font-size: 2rem;
          font-weight: 200;
          text-transform: uppercase;
          font-family: 'Poppins', sans-serif;
        }

        .logo {
          width: 430px;
          max-width: calc(100% - 100px);
        }
        .logo img {
          width: 100%;
          height: auto;
        }
        .btn {
          width: 200px;
        }
      `}</style>
    </>
  );
};

export default InverseDisconnected;

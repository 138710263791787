import React from 'react';
import { MeetingRole } from '../../interfaces';

import {
  IconCameraOff,
  IconInvisible,
  IconMicrophoneOff,
  IconPin,
  IconRaiseHand,
  IconSpeaking,
  IconStudio
} from './MeetingControls/MeetingIcons';

const ParticipantStatusIndicators = ({
  audioMuted,
  videoMuted,
  raiseHand,
  invisible,
  speaking,
  role,
  pinned,
  viewOnly
}: {
  audioMuted?: boolean;
  videoMuted?: boolean;
  raiseHand?: boolean;
  invisible?: boolean;
  role?: MeetingRole;
  speaking?: boolean;
  pinned?: boolean;
  viewOnly?: boolean;
}) => {
  const roleIcon = () => {
    if (role === MeetingRole.STUDIO) return <IconStudio />;
    return null;
  };
  return (
    <>
      {(audioMuted ||
        videoMuted ||
        raiseHand ||
        invisible ||
        role ||
        speaking) && (
        <div className="indicators-container with-shadow ">
          <div>
            {!viewOnly && pinned && (
              <div>
                <IconPin />
              </div>
            )}

            {speaking && (
              <div>
                <IconSpeaking />
              </div>
            )}
            {raiseHand && !invisible && (
              <div>
                <IconRaiseHand />
              </div>
            )}
            {roleIcon() && <div className="dark-icon">{roleIcon()}</div>}
            {!viewOnly && invisible && (
              <div>
                <IconInvisible />
              </div>
            )}

            {videoMuted && (
              <div>
                <IconCameraOff />
              </div>
            )}
            {audioMuted && (
              <div>
                <IconMicrophoneOff />
              </div>
            )}
          </div>

          <style jsx>{`
            .indicators-container {
              overflow: hidden;
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              z-index: 2;
              padding: 2px;
            }
            .indicators-container :global(svg) {
              width: 15px;
              height: auto;
              display: block;
            }
            :global(.enlarge-video) .indicators-container,
            :global(.tile-mode) .indicators-container {
              align-items: center;
            }
            :global(.tile-mode) .indicators-container > div,
            :global(.enlarge-video) .indicators-container > div {
              margin: auto;
            }
            :global(.MD) .indicators-container {
              padding: 5px;
              height: auto;
            }
            :global(.MD) .indicators-container :global(svg) {
              width: 25px;
              height: auto;
            }
            :global(.MD.has-presenter #presenter-wrapper + #participant-wrapper)
              .indicators-container {
              padding: 2px;
            }
            :global(.MD.has-presenter #presenter-wrapper + #participant-wrapper)
              .indicators-container
              :global(svg) {
              width: 12px;
              height: auto;
            }
          `}</style>
        </div>
      )}
    </>
  );
};

export default ParticipantStatusIndicators;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '../../../i18n';
import { logEvent } from '../../../analytics';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import CartTable from '../Cart/CartTable';
import { CheckoutStatus, IMainState, CheckoutStep } from '../../../interfaces';
import {
  actionCartUpdateCheckoutStatus,
  actionCartUpdateCheckoutStep,
  actionCartUpdateCheckoutToken
} from '../../../redux/actions';
import {
  DID_CLICK_PRIVACY_POLICY,
  DID_TOKENIZE_CARD_SUCCESSFULLY,
  DID_FAIL_TO_TOKENIZE_CARD,
  DID_CLICK_SUBMIT_CARD_DETAILS,
  DID_OPEN_FORM,
  DID_INPUT_FORM_FIELD
} from '../../../utils/constants';
import { isAllowedForName, isValidName } from '../../VirtualBoutique/Appointment/inputValidator';

interface IFieldsValid {
  'card-number': boolean;
  'expiry-date': boolean;
  cvv: boolean;
}

const IWCDubaiPaymentForm = ({
  checkoutdotcomPK
}: {
  checkoutdotcomPK: string
}) => {
  const { t } = useTranslation();
  const shoppingCartState = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState(false);
  const [valid, setValid] = React.useState<IFieldsValid>({
    'card-number': false,
    'expiry-date': false,
    cvv: false
  });
  const [cardholderName, setCardholderName] = React.useState<string>('')
  const [pristine, setPristine] = React.useState<boolean>(true);

  const invalidName =
    !pristine && !isValidName(cardholderName);

  const allowSubmit = Object.values(valid).every((i) => i) && !invalidName && cardholderName.trim().length > 0;


  let errorStack = [];
  const buttonText = submitting ? t('vb:processing') : t('vb:proceed');

  function onCardValidationChanged(event) {
    console.log('CARD_VALIDATION_CHANGED: %o', event);
    if (Frames.isCardValid()) {
      setValid({
        'card-number': true,
        'expiry-date': true,
        cvv: true
      });
    }
  }

  function onValidationChanged(event) {
    console.log('FRAME_VALIDATION_CHANGED: %o', event);

    const errorMessageElement = document.querySelector('.error-message');
    const hasError = !event.isValid && !event.isEmpty;

    if (hasError) {
      errorStack.push(event.element);
      setValid({
        ...valid,
        [event.element]: false
      });
    } else {
      setValid({
        ...valid,
        [event.element]: true
      });
      errorStack = errorStack.filter(function (element) {
        return element !== event.element;
      });
    }

    const errorMessage = errorStack.length
      ? getErrorMessage(errorStack[errorStack.length - 1])
      : '';
    errorMessageElement.textContent = errorMessage;
  }

  function getErrorMessage(element) {
    const errors = {
      'card-number': t('vb:enter_valid_card'),
      'expiry-date': t('vb:enter_valid_exp_date'),
      cvv: t('vb:enter_valid_cvv')
    };

    return errors[element];
  }

  function onCardTokenizationFailed(error) {
    logEvent(DID_FAIL_TO_TOKENIZE_CARD, DID_FAIL_TO_TOKENIZE_CARD, {
      error
    });
    Frames.enableSubmitForm();
    setSubmitting(false);
  }

  function onCardTokenized(event) {
    const token = event.token;
    logEvent(DID_TOKENIZE_CARD_SUCCESSFULLY);
    setCheckoutToken(token);
    dispatch(actionCartUpdateCheckoutStep(CheckoutStep.ORDER_SUMMARY));
    setSubmitting(false);
  }

  const setCheckoutStatus = (status: CheckoutStatus) => {
    dispatch(actionCartUpdateCheckoutStatus(status));
  };

  const setCheckoutToken = (token: string) => {
    dispatch(actionCartUpdateCheckoutToken(token));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setPristine(false);
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'Cardholder name',
      form: 'IWCDubaiPaymentForm'
    });
    if (isAllowedForName(value)) {
      setCardholderName(value);
    }
  };

  const onSubmit = () => {
    if (submitting && !allowSubmit) {
      return;
    }
    setCheckoutStatus(CheckoutStatus.PROCESSING);
    setSubmitting(true);
    logEvent(DID_CLICK_SUBMIT_CARD_DETAILS);
    Frames.cardholder = {
      name: cardholderName
    };
    Frames.submitCard();
  }

  React.useEffect(() => {
    logEvent(DID_OPEN_FORM, DID_OPEN_FORM, {
      form: 'IWCDubaiPaymentForm'
    });
    Frames.init({
      publicKey: checkoutdotcomPK
    });

    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      onCardValidationChanged
    );

    Frames.addEventHandler(
      Frames.Events.FRAME_VALIDATION_CHANGED,
      onValidationChanged
    );

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZATION_FAILED,
      onCardTokenizationFailed
    );

    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, onCardTokenized);

    return () => {
      Frames.removeEventHandler(
        Frames.Events.CARD_VALIDATION_CHANGED,
        onCardValidationChanged
      );

      Frames.removeEventHandler(
        Frames.Events.FRAME_VALIDATION_CHANGED,
        onValidationChanged
      );

      Frames.removeEventHandler(
        Frames.Events.CARD_TOKENIZATION_FAILED,
        onCardTokenizationFailed
      );

      Frames.removeEventHandler(Frames.Events.CARD_TOKENIZED, onCardTokenized);
    };
  }, []);

  return (
    <div className="payment-container">
      <h2 className="text-center">{t('vb:payment_method')}</h2>
      <CartTable items={shoppingCartState?.items} readonly />
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="input-group ">
            <label>CARDHOLDER NAME:</label>
            <input
              type="text"
              id="inputFirstName"
              name="firstName"
              value={cardholderName || ''}
              placeholder={t('vb:enter_cardholder_name')}
              className="customer-name-input"
              onChange={handleInputChange}
            />
            {invalidName && (
              <span className="error"> {t('vb:required')}</span>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="input-group">
            <label>{t('vb:card_number')}:</label>
            <div className="card-number-frame"></div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="input-group">
            <label>{t('vb:expiry_date')}:</label>
            <div className="expiry-date-frame"></div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="input-group">
            <label>{t('vb:cvv')}:</label>
            <div className="cvv-frame"></div>
          </div>
        </div>
      </div>
      <p className="error-message mb-1"></p>
      <button
        type="button"
        id="sendform"
        className="checkout-button"
        disabled={submitting || !allowSubmit}
        onClick={() => onSubmit()}
      >
        {buttonText}
      </button>
      <div className="cart-heading mt-5">
        {t('vb:iwc_dubai_further_info')}
        &nbsp;
        <a
          href="https://www.iwc.com/en/terms-and-legal/privacy-policy.html"
          target="_blank"
          onClick={() => logEvent(DID_CLICK_PRIVACY_POLICY)}
          className="purchase-terms-link"
        >
          {t('vb:privacy_policy')}
        </a>
      </div>
      <PoweredByInspify />
      <style jsx>
        {`
          .payment-container {
            width: 100%;
          }

          #payment-form {
            max-width: 31.5rem;
            margin: 0 auto;
          }

          iframe {
            width: 100%;
          }

          .one-liner {
            display: flex;
            flex-direction: column;
          }

          #pay-button {
            border: none;
            border-radius: 3px;
            color: #fff;
            font-weight: 500;
            height: 40px;
            width: 100%;
            background-color: #13395e;
            box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
          }

          #pay-button:active {
            background-color: #0b2a49;
            box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
          }

          #pay-button:hover {
            background-color: #15406b;
            box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
          }

          #pay-button:disabled {
            background-color: #697887;
            box-shadow: none;
          }

          #pay-button:not(:disabled) {
            cursor: pointer;
          }
          
          .customer-name-input,
          .card-number-frame,
          .expiry-date-frame,
          .cvv-frame {
            border: solid 1px #13395e;
            border-radius: 3px;
            width: 100%;
            margin-bottom: 8px;
            height: 40px;
            box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
            padding: 0 10px;
          }

          .card-number-frame.frame--rendered,
          .expiry-date-frame.frame--rendered,
          .cvv-frame.frame--rendered {
            opacity: 1; /* Prevents iFrame rendering issue */

            /* Reminder: consider removal of 'rendered' */
            /* event passing to Merchant page */
          }

          .card-number-frame.frame--rendered.frame--focus,
          .expiry-date-frame.frame--rendered.frame--focus,
          .cvv-frame.frame--rendered.frame--focus {
            border: solid 1px #13395e;
            box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
          }

          .card-number-frame.frame--rendered.frame--invalid,
          .expiry-date-frame.frame--rendered.frame--invalid,
          .cvv-frame.frame--rendered.frame--invalid {
            border: solid 1px #d96830;
            box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
          }

          .error-message {
            color: #c9501c;
            font-size: 0.9rem;
            margin: 8px 0 0 1px;
            font-weight: 300;
          }

          .success-payment-message {
            color: #13395e;
            line-height: 1.4;
          }
          .token {
            color: #b35e14;
            font-size: 0.9rem;
            font-family: monospace;
          }

          .purchase-terms-link {
            text-decoration: underline;
            color: blue;
          }

          .checkout-button {
            padding: 0 30px;
            align-self: center;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          :global(.input-group) {
            margin-bottom: 1em !important;
          }

          :global(.isRtl .cart-heading) {
            text-align: right !important;
          }

          @media screen and (min-width: 31rem) {
            .card-number-frame {
              width: 100%;
              margin-bottom: 0;
            }

            #pay-button {
              margin-top: 15px;
              width: 100%;
            }

            .checkout-button {
              width: 40% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default IWCDubaiPaymentForm;

import { useSelector } from 'react-redux';
import { IMainState, IShoppingCartItem } from '../../interfaces';

export const useCartItemState = (): IShoppingCartItem[] =>
  useSelector(
    (state: IMainState) => state.clientState.shoppingCart?.items || []
  );

export const useCartState = () =>
  useSelector((state: IMainState) => state.clientState.shoppingCart);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { mercedesBenzSiteId, mercedesBenzStoreId } from '../../../../config';
import { IMeeting } from '../../../../interfaces';
import {
  actionTeleportSelectSite,
  actionUpdateLanguage
} from '../../../../redux/actions';
import { actionUpdateCommon3DActiveAnimationInPano } from '../../../../redux/customActions/common3DAction';
import { DID_CLICK_TELEPORT_SITE } from '../../../../utils/constants';
import AdvisorAccessControls from '../../../Meeting/MeetingControls/AdvisorAccessControls';
import MeetingButtonWithIcon from '../../../Meeting/MeetingControls/MeetingButtonWithIcon';
import { ViewportContext } from '../../../Meeting/MeetingLayout';
import { IMainState } from '../../../../interfaces/index';
import { actionTeleportAsync } from '../../../../redux/teleportAsyncAction';
import { meetingColor } from '../../../../utils/meeting';
import { MercedesBenzIcon } from '../../../PixelStream/MercedesBenz/MercedesIcon';

const MercedesAdvisorMeetingControl = ({
  meeting,
  onCloseMenu
}: {
  meeting: IMeeting;
  onCloseMenu: () => void;
}) => {
  const dispatch = useDispatch();
  const { isSmallScreen } = React.useContext(ViewportContext);
  const shouldCloseMenu = () => {
    if (isSmallScreen) onCloseMenu();
  };

  const defaultStoreId = useSelector(
    (state: IMainState) => state?.clientState?.store?.id
  );
  const defaultBrandId = useSelector(
    (state: IMainState) => state?.clientState?.virtualBoutiqueConfig?.brandId
  );
  const { teleportTo } = useSelector(
    (state: IMainState) => state?.clientState?.teleport || {}
  );
  const teleportedStoreId = teleportTo?.store?.id;

  const popupState = useSelector(
    (state: IMainState) => state?.clientState?.meeting?.popupState || {}
  );

  const handleSelectSite = (id: string) => {
    logEvent(DID_CLICK_TELEPORT_SITE, DID_CLICK_TELEPORT_SITE, {
      meetingId: meeting.meetingId
    });
    dispatch(actionTeleportSelectSite(id));
    dispatch(actionUpdateLanguage('en'));
    shouldCloseMenu();
  };

  React.useEffect(() => {
    if (popupState?.url)
      dispatch(actionUpdateCommon3DActiveAnimationInPano(undefined));
  }, [popupState]);

  React.useEffect(() => {
    if (teleportTo?.siteId) {
      dispatch(actionTeleportAsync(teleportTo?.siteId));
    }
  }, [teleportTo?.siteId]);

  return (
    <>
      <MeetingButtonWithIcon
        id="teleportButton"
        onClick={() => {
          handleSelectSite(
            teleportTo?.store?.id ? mercedesBenzStoreId : mercedesBenzSiteId
          );
        }}
        className={teleportTo?.store?.id ? 'teleport-active' : ''}
      >
        <div className="icon-wrapper">
          <MercedesBenzIcon />
        </div>

        <span>Mercedes-Benz Experience</span>
      </MeetingButtonWithIcon>
      <AdvisorAccessControls
        storeId={teleportedStoreId || defaultStoreId}
        brandId={teleportTo?.store?.brands?.[0]?.brandId || defaultBrandId}
        meeting={meeting}
        onCloseMenu={onCloseMenu}
      />
      <style jsx>{`
        .icon-wrapper ::global(svg) {
          transform: scale(0.75);
        }
      `}</style>
      <style jsx global>{`
        .MD .teleport-active {
          background-color: ${meetingColor.info};
        }
      `}</style>
    </>
  );
};

export default MercedesAdvisorMeetingControl;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionMuteBgSound, actionUnmuteBgSound } from '../../../redux/actions';
import { IMainState } from '../../../interfaces/index';
import {
  DID_MUTE_BACKGROUND_SOUND,
  DID_UNMUTE_BACKGROUND_SOUND
} from '../../../utils/constants';
import { logEvent } from '../../../analytics';
import { IoIosVolumeOff, IoIosVolumeHigh } from 'react-icons/io';

const ToggleSoundButton = ({
  muteByDefault = false
}: {
  muteByDefault?: boolean;
}) => {
  const dispatch = useDispatch();
  const vbState = useSelector((state: IMainState) => state.clientState?.vb);

  const isMute = vbState?.muteBackgroundSound || false;
  const popup = vbState?.popup?.open;

  const [flagMuteByUser, setFlagMuteByUser] = React.useState(muteByDefault);
  React.useEffect(() => {
    if (typeof popup !== 'undefined') {
      if (popup && !isMute) dispatch(actionMuteBgSound());
      if (!popup && !flagMuteByUser) dispatch(actionUnmuteBgSound());
    }
  }, [popup]);

  React.useEffect(() => {
    if (muteByDefault) dispatch(actionMuteBgSound());
  }, []);

  return (
    <div
      className="ToggleSoundButton with-shadow"
      onClick={() => {
        if (isMute) {
          dispatch(actionUnmuteBgSound());
          logEvent(DID_UNMUTE_BACKGROUND_SOUND);
          setFlagMuteByUser(false);
        } else {
          dispatch(actionMuteBgSound());
          logEvent(DID_MUTE_BACKGROUND_SOUND);
          setFlagMuteByUser(true);
        }
      }}
    >
      {isMute ? <IoIosVolumeOff /> : <IoIosVolumeHigh />}

      <style jsx>{`
        .ToggleSoundButton {
          color: #fff;
          width: 35px;
          cursor: pointer;
          margin-top: -3px;
        }
        .ToggleSoundButton :global(svg) {
          width: 100%;
          height: auto;
        }
      `}</style>
    </div>
  );
};

export default ToggleSoundButton;

import React from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import {
  IMainState,
  IProduct,
  ParticipantMeetingState
} from '../../../../../interfaces';
import {
  ProductGender,
  ProductType
} from '../../../../../interfaces/loubiairways';
import {
  actionAddToWishlist,
  actionCloseCatalog,
  actionRemoveFromWishlist,
  actionStartFetchingCatalog
} from '../../../../../redux/customActions/loubiAirways';
import {
  DID_ADD_TO_WISHLIST,
  DID_CLICK_CATALOG_BUTTON,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';
import {
  getProductDisplayPrice,
  getProductsFilterByBlackAndWhitelist
} from '../../../../../utils/product';
import {
  shouldEnableLikeAndReservationForLoubiJp,
  useLoubiAirwaysState
} from '../../../../hooks/loubiAirways';
import { MDGlobalSpec } from '../../../../Meeting/MeetingLayout/MDGlobal';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMGlobalSpec } from '../../../../Meeting/MeetingLayout/SMGlobal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../../Meeting/MeetingLayout/SMPortraitNormal';
import LJProductListWrapper from '../LJProductListWrapper';
import LJNestedButton from './LJNestedButton';

const LJCatalogButtons = () => {
  const dispatch = useDispatch();
  const catalog = useLoubiAirwaysState()?.catalog || {};
  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting?.state
  );
  const vbConfig = useSelector(
    (state: IMainState) => state.clientState.virtualBoutiqueConfig
  );
  const isInMeeting =
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;
  const headerElement = (
    <>
      <img
        src={`${jpIconUrlBase}/back_with_cross_white.svg`}
        alt=""
        className="extra-logo"
        onClick={() => {
          dispatch(actionCloseCatalog());
        }}
      />
      <style jsx>{`
        img {
          position: absolute;
          height: 96px;
          top: 50%;
          transform: translateY(-50%);
          right: 43px;
          cursor: pointer;
        }
        @media (max-width: 1200px) {
          img {
            height: 80px;
          }
        }
        @media (max-width: 768px) {
          img {
            height: 60px;
            right: 30px;
          }
        }
        @media (max-width: 550px) {
          img {
            height: 50px;
            right: 20px;
          }
        }
      `}</style>
    </>
  );

  const loadMore = () => {
    dispatch(
      actionStartFetchingCatalog({
        type: catalog.type,
        gender: catalog.gender,
        index: catalog.index
      })
    );
  };

  const infiniteRef: React.MutableRefObject<any> = useInfiniteScroll({
    loading: catalog.loading,
    hasNextPage: catalog.hasMoreResults,
    onLoadMore: loadMore
  });
  const state = useLoubiAirwaysState();
  const wishlist = state?.wishlist?.wishlistItems || [];
  const showLike = shouldEnableLikeAndReservationForLoubiJp();
  const productActionElement = (product: IProduct) => {
    const alreadyInWishlist = wishlist.some((p) => p.itemId === product.id);
    return (
      <>
        <h3>{getProductDisplayPrice(product, 'JP')}</h3>
        {alreadyInWishlist ? (
          <img
            src="/asset/louboutin/wishlist_filled.svg"
            alt="Remove from Likes"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(actionRemoveFromWishlist(product));
              logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
                id: product.id,
                modelCode: product.modelCode,
                modelName: product.modelName
              });
            }}
          />
        ) : (
          <img
            src="/asset/louboutin/wishlist_empty.svg"
            alt="Add to Likes"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(actionAddToWishlist(product));
              logEvent(DID_ADD_TO_WISHLIST, DID_ADD_TO_WISHLIST, {
                id: product.id,
                modelCode: product.modelCode,
                modelName: product.modelName
              });
            }}
          />
        )}
        <style jsx>{`
          h3 {
            font-family: 'AntiqueOlive-Bold', Arial;
            font-size: 1.2em;
            color: #346168;
          }
          img {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 20px;
            height: 20px;
            display: ${showLike ? 'block' : 'none'};
          }
        `}</style>
      </>
    );
  };
  const performCatalogSearch = (type: ProductType, gender?: ProductGender) => {
    dispatch(
      actionStartFetchingCatalog({
        type,
        gender,
        index: 0
      })
    );
    logEvent(DID_CLICK_CATALOG_BUTTON, DID_CLICK_CATALOG_BUTTON, {
      gender,
      type
    });
  };
  const productsFilter = getProductsFilterByBlackAndWhitelist(
    vbConfig?.productBlacklist,
    vbConfig?.productWhitelist
  );
  const filteredProducts = productsFilter(catalog.products || []);
  return (
    <>
      <div
        className={`buttons-container hide-for-client-meeting ${
          isInMeeting ? 'meeting' : ''
        }`}
      >
        <LJNestedButton
          title="WOMEN"
          buttons={[
            {
              title: 'SHOES',
              onClick: () => {
                performCatalogSearch('shoes', 'women');
              }
            },
            {
              title: 'LEATHER GOODS',
              onClick: () => {
                performCatalogSearch('leather goods', 'women');
              }
            }
          ]}
        />

        <LJNestedButton
          title="MEN"
          buttons={[
            {
              title: 'SHOES',
              onClick: () => {
                performCatalogSearch('shoes', 'men');
              }
            },
            {
              title: 'LEATHER GOODS',
              onClick: () => {
                performCatalogSearch('leather goods', 'men');
              }
            }
          ]}
        />

        <button
          className="btn"
          onClick={() => {
            performCatalogSearch('beauty');
          }}
        >
          BEAUTY
        </button>
      </div>
      <LJProductListWrapper
        products={filteredProducts}
        productActionElement={productActionElement}
        open={catalog.show}
        headerElement={headerElement}
        reference={filteredProducts?.length ? infiniteRef : undefined}
      />
      <style jsx>
        {`
          .buttons-container {
            position: fixed;
            top: 20px;
            left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
          }

          :global(.in-meeting.SM) .buttons-container {
            top: ${SMGlobalSpec.logoSectionHeight + 5}px;
          }

          :global(.in-meeting.SMLandscape) .buttons-container {
            left: ${SMLandscapeNormalSpec.contentArea.left + 5}px;
          }

          :global(.in-meeting.SMPortrait) .buttons-container {
            left: ${SMPortraitNormalSpec.thumbnailSelf.width +
            SMPortraitNormalSpec.thumbnailSpacing * 2 +
            5}px;
          }

          :global(.in-meeting.MD) .buttons-container {
            top: ${MDGlobalSpec.logoSectionHeight + 20}px;
          }

          :global(.in-meeting.MDLandscape) .buttons-container {
            left: ${MDLandscapeNormalSpec.contentArea.left + 20}px;
          }

          :global(.in-meeting.MDPortrait) .buttons-container {
            left: ${MDPortraitNormalSpec.thumbnailSelf.width +
            MDPortraitNormalSpec.thumbnailSpacing * 2 +
            20}px;
          }

          .buttons-container > .btn {
            margin-right: 5px;
            font-size: 1rem;
            font-family: 'AntiqueOlive-Bold', Arial;
            color: #cf152d;
            background: #f3f3d1;
          }

          .buttons-container .btn:focus {
            outline: 0;
            box-shadow: none;
          }
          @media (max-width: 768px) {
            .buttons-container {
              top: 5px;
              left: 5px;
            }
            .buttons-container .btn {
              font-size: 0.9rem;
            }
          }
        `}
      </style>
    </>
  );
};

export default LJCatalogButtons;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState, ParticipantMeetingState } from '../../../../../interfaces';
import { actionFetchProductByIdAsync } from '../../../../../redux/productAsyncAction';
import { useProductStateById } from '../../../../hooks/product';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';

const LJProductCategory = ({ productId }: { productId?: string }) => {
  const dispatch = useDispatch();
  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting?.state
  );
  const isInMeeting =
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;
  const product = useProductStateById(productId);
  const gender = product?.gender?.toUpperCase();

  React.useEffect(() => {
    if (productId) dispatch(actionFetchProductByIdAsync(productId));
  }, [productId]);

  if (!productId || !gender) {
    return null;
  }
  return (
    <>
      <div className={isInMeeting ? 'meeting' : ''}>{gender}</div>
      <style jsx>{`
        div {
          position: fixed;
          left: 20px;
          top: 20px;
          color: #cf152d !important;
          background: #f3f3d1;
          font-family: 'AntiqueOlive-Bold', Arial;
          padding: 0.375rem 0.75rem;
          border-radius: 5px;
          z-index: 2;
        }
        :global(.in-meeting.MD) div{
          top:${MDLandscapeNormalSpec.contentArea.top + 20}px;
        }
        :global(.in-meeting.MDLandscape) div{
          left:${MDLandscapeNormalSpec.contentArea.left + 20}px;
        }
        :global(.in-meeting.SM) div{
          top:${SMLandscapeNormalSpec.contentArea.top + 20}px;
        }
        :global(.in-meeting.SMLandscape) div{
          left:${SMLandscapeNormalSpec.contentArea.left + 20}px;
        }
        @media (max-width: 768px) {
          .meeting {
            left: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default LJProductCategory;

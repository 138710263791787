import { debounce, first, take } from 'lodash';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import {
  getProductInspirations,
  getProductsByIds
} from '../../../../clientSideServices/products';
import { IProduct } from '../../../../interfaces';
import {
  actionCartAddProduct,
  actionOpenVBPopup
} from '../../../../redux/actions';
import {
  actionDidScrollProductDetails,
  actionShowChopardProductDetails,
  actionShowChopardStrapSelector
} from '../../../../redux/customActions/chopard';
import {
  DID_CLICK_CHAT_NOW,
  DID_CLICK_ORDER_NOW_BUTTON,
  DID_CLICK_PERSONALIZE_BUTTON
} from '../../../../utils/constants';
import { useChopardState } from '../../../hooks/chopard';
import { canInteractWithAnimation } from '../../../hooks/meeting';

const ChopardHappySportProductDetails = () => {
  const scrollAreaRef = React.useRef<HTMLDivElement>();
  const [inspirations, setInspirations] = React.useState([]);
  const [product, setProduct] = React.useState<IProduct>();
  const productId = '42853778-70af-4c03-8f46-be11c2d684a9';
  const chopardState = useChopardState();
  const dispatch = useDispatch();

  const canInteract = canInteractWithAnimation();

  const getVerticalScrollRange = () => {
    const scrollArea = scrollAreaRef.current;
    return scrollArea ? scrollArea.scrollHeight - scrollArea.clientHeight : 0;
  };

  const debouncedScrollListener = debounce(() => {
    const scrolledOffset = scrollAreaRef.current?.scrollTop || 0;
    console.log(`scrolled pixel ${scrolledOffset}`);
    const totalYScrollRange = getVerticalScrollRange();
    const scrolledRange =
      totalYScrollRange > 0 ? scrolledOffset / totalYScrollRange : 0;
    dispatch(actionDidScrollProductDetails(scrolledRange));
  }, 200);

  React.useEffect(() => {
    getProductInspirations({
      productId,
      language: 'en'
    }).then((result) => {
      setInspirations(take(result.data, 3));
    });
    getProductsByIds([productId]).then((result) => {
      setProduct(first(result.data));
    });
    scrollAreaRef.current?.addEventListener('scroll', debouncedScrollListener);
    return () => {
      scrollAreaRef.current?.removeEventListener(
        'scroll',
        debouncedScrollListener
      );
    };
  }, []);

  React.useEffect(() => {
    if (
      !canInteract &&
      chopardState.productDetailsScrolledPercentage &&
      scrollAreaRef.current
    ) {
      const totalYScrollRange = getVerticalScrollRange();
      scrollAreaRef.current.scroll({
        top: totalYScrollRange * chopardState.productDetailsScrolledPercentage,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [
    canInteract,
    chopardState.productDetailsScrolledPercentage,
    scrollAreaRef.current
  ]);
  return (
    <div
      ref={scrollAreaRef}
      className={`details-container ${
        chopardState.showProductDetails ? 'show' : ''
      }`}
    >
      {chopardState.showProductDetails && (
        <button
          className="close"
          onClick={() => {
            dispatch(actionShowChopardProductDetails(false));
          }}
        >
          <GrClose />
        </button>
      )}
      <section>
        <h1>HAPPY SPORT</h1>
        <p className="material">
          30 MM, QUARTZ, ROSE GOLD,
          <br />
          STAINLESS STEEL, DIAMONDS
        </p>
        <button
          className="btn btn-dark btn-action"
          onClick={() => {
            logEvent(DID_CLICK_ORDER_NOW_BUTTON, productId);
            dispatch(actionCartAddProduct(product));
          }}
        >
          ORDER NOW
        </button>
        <button
          className="btn btn-action"
          onClick={() => {
            logEvent(DID_CLICK_PERSONALIZE_BUTTON);
            dispatch(actionShowChopardProductDetails(false));
            dispatch(actionShowChopardStrapSelector(true));
          }}
        >
          PERSONALIZE
        </button>
        <button
          className="btn btn-action"
          onClick={() => {
            logEvent(DID_CLICK_CHAT_NOW);
          }}
        >
          CHAT NOW
        </button>
      </section>

      <section className="description">
        <h2>DESCRIPTION</h2>
        <p>
          The Happy Sport collection is the emblem of Chopard's creativity and
          boldness. Its playful, free-spinning gems, dancing between two
          sapphire crystals, have captured the imagination since 1993, when
          Chopard became the first Maison to combine steel and diamonds. Little
          Diamonds Go Great Things.
        </p>
      </section>

      <section className="specifications">
        <h2>SPECIFICATIONS</h2>
        <h3>CASE & DIAL</h3>
        <div className="specification-container">
          <div>
            <div className="title">METAL:</div>
            <div className="details">
              18-carat rose gold and stainless steel
            </div>
          </div>
          <div>
            <div className="title">WATER-RESISTANCE:</div>
            <div className="details">30 metres</div>
          </div>
          <div>
            <div className="title">FRONT GLASS:</div>
            <div className="details">
              glareproofed scratch-resistant sapphire crystal
            </div>
          </div>
          <div>
            <div className="title">CASE DIMENSION(S):</div>
            <div className="details">30.00 mm</div>
          </div>
          <div>
            <div className="title">CROWN MATERIAL:</div>
            <div className="details">18-carat rose gold</div>
          </div>
          <div>
            <div className="title">WHITE DIAMOND CARAT:</div>
            <div className="details">0.08</div>
          </div>
        </div>
        <h3>STRAP & BUCKLE</h3>
        <div className="specification-container">
          <div>
            <div className="title">STRAP:</div>
            <div className="details">
              black rubble (imitation steel), white rubber (imitation steel)
            </div>
          </div>
          <div>
            <div className="title">BUCKLE TYPE:</div>
            <div className="details">pin buckle</div>
          </div>
        </div>
        <h3>OTHER</h3>
        <div className="specification-container">
          <div>
            <div className="title">INDICATION(S):</div>
            <div className="details">seconds</div>
          </div>
          <div>
            <div className="title">DANCING DIAMONDS:</div>
            <div className="details">yes</div>
          </div>
          <div>
            <div className="title">WINDING:</div>
            <div className="details">quartz movement</div>
          </div>
          <div>
            <div className="title">COLOURED STONES:</div>
            <div className="details">yes</div>
          </div>
          <div>
            <div className="title">CERTIFICATIONS:</div>
            <div className="details">CE certified</div>
          </div>
          <div>
            <div className="title">JEWELS:</div>
            <div className="details">7</div>
          </div>
        </div>
      </section>

      <section className="stories">
        <h2>INSTAGRAM STORIES</h2>
        <div className="stories-container">
          {inspirations.map((i) => {
            return (
              <div
                className="story"
                key={i.id}
                onClick={() => {
                  dispatch(
                    actionOpenVBPopup(
                      `https://chopard.v-boutique.com/inspirations/${i.id}`
                    )
                  );
                }}
              >
                <img src={i.senderImgUrl} className="logo" alt="LOGO" />
                <img src={i.flipImageUrl} alt="Instagram Image" />
              </div>
            );
          })}
        </div>
      </section>

      <style jsx>
        {`
          .details-container {
            text-align: left;
            padding: 30px 60px;
            position: fixed;
            width: 550px;
            background: rgba(255, 255, 255, 1);
            top: 0;
            bottom: 0;
            right: -550px;
            overflow: scroll;
            transition: all 0.3s ease-in-out;
          }

          .details-container.show {
            right: 0;
          }

          button.close {
            position: absolute;
            top: 40px;
            right: 10px;
          }

          button.close :global(svg) {
            width: 25px;
            height: 25px;
          }

          h1 {
            font-size: 2rem;
            font-family: 'Walbaum', 'Garamond', 'Baskerville',
              'Baskerville Old Face', 'Hoefler Text', 'Times New Roman', serif;
            font-weight: 300;
            margin: 10px 0;
            letter-spacing: 1px;
          }

          .material {
            font-size: 0.875rem;
            color: #585858;
            letter-spacing: 2px;
          }

          .btn-dark {
            background: #000;
          }

          .btn-action {
            border: 1px solid #000;
          }

          section {
            padding-bottom: 40px;
            border-bottom: 2px solid #8c8c8c;
          }

          h2 {
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 2px;
            margin-top: 40px;
            margin-bottom: 20px;
          }

          .description p {
            font-size: 0.875rem;
            font-weight: 400;
            text-align: justify;
            margin: 0;
          }

          h3 {
            font-size: 1rem;
            padding-bottom: 5px;
            letter-spacing: 2px;
            border-bottom: 2px solid #8c8c8c;
            display: inline-block;
            margin-bottom: 10px;
          }

          .specifications {
            padding-bottom: 5px;
          }
          .specification-container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
          }

          .specification-container > div {
            width: 50%;
            padding-right: 10px;
            margin-bottom: 15px;
          }

          .title {
            font-weight: 600;
          }

          .details {
            color: #585858;
            font-size: 0.875rem;
          }

          .stories-container {
            justify-content: space-between;
            display: flex;
          }

          .story {
            position: relative;
            width: 32%;
            display: inline-block;
            cursor: pointer;
          }

          .story img {
            object-fit: cover;
            height: 300px;
            width: 100%;
          }

          .story .logo {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        `}
      </style>
    </div>
  );
};

export default ChopardHappySportProductDetails;

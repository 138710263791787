import React from 'react';
import { zIndex } from '../../../../config';
import { IMapSpot } from '../../../../interfaces';
import { butterflyEffectDuration } from './VCASVMapButton';
import VCASVMapLegend from './VCASVMapLegend';
import VCASVMapFloor from './VCASVMapFloor';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';

const VCASVMap = ({
  open,
  onToggleMap,
  onSelectSpot,
  visitedSpot,
  currentPosition,
  storeId
}: {
  open?: boolean;
  visitedSpot?: string[];
  currentPosition?: string;
  onSelectSpot?: (spot: IMapSpot) => void;
  onToggleMap?: (open: boolean) => void;
  storeId?: string;
}) => {
  const closingSpeed = 300;

  const [closing, setClosing] = React.useState(false);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShow(true);
      }, butterflyEffectDuration);
    } else {
      setClosing(true);
      setTimeout(() => {
        setShow(false);
        setClosing(false);
      }, closingSpeed);
    }
  }, [open]);
  React.useEffect(() => {
    onToggleMap(false);
    return () => {
      onToggleMap(false);
    };
  }, []);

  if (!show) return null;

  return (
    <>
      <div
        className={`VCASVMap fixed-full ${closing && 'closing'} ${
          show && 'show'
        }`}
      >
        <div className="backdrop fixed-full" />
        <div className="map-container">
          <VCASVMapFloor
            onSelectSpot={onSelectSpot}
            visitedSpot={visitedSpot}
            currentPosition={currentPosition}
            storeId={storeId}
          />
        </div>
        <VCASVMapLegend />
        <button
          onClick={() => onToggleMap(false)}
          className="btn btn-close hide-for-client-meeting"
        >
          &times;
        </button>

        <style jsx>{`
          .VCASVMap {
            z-index: ${zIndex.popupPage - 1};
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            user-select: none;
          }
          .map-container {
            z-index: 1;
            position: relative;
            margin: auto;
          }
          .backdrop {
            background: rgba(0, 0, 0, 0.8);
          }

          .VCASVMap .backdrop,
          .VCASVMap .map-container {
            animation: fadeIn 0.3s linear;
          }

          .VCASVMap.closing .backdrop,
          .VCASVMap.closing .map-container {
            animation: fadeOut 0.4s linear;
          }

          .btn-close {
            position: fixed;
            top: 10px;
            right: 15px;
            width: 40px;
            height: 40px;
            font-size: 40px;
            color: #fff;
            padding: 0;
            line-height: 1;
            outline: none !important;
            box-shadow: none !important;
            z-index: 4;
          }

          :global(.in-meeting.MDLandscape) .VCASVMap,
          :global(.in-meeting.MDLandscape) .backdrop {
            left: ${MDLandscapeNormalSpec.contentArea.left}px;
          }

          :global(.in-meeting.SMPortrait) .VCASVMap,
          :global(.in-meeting.MDPortrait) .VCASVMap {
            z-index: ${zIndex.meetingControls + 2};
          }

          :global(.in-meeting.SM) .btn-close {
            top: ${SMLandscapeNormalSpec.contentArea.top + 10}px;
          }

          :global(.in-meeting.MD) .btn-close {
            top: ${MDLandscapeNormalSpec.contentArea.top + 10}px;
          }

          @media (orientation: landscape) {
            .map-container {
              height: 85vh;
              width: 85vh;
            }
          }

          @media (orientation: portrait) {
            .map-container {
              height: 100vw;
              width: 100vw;
              max-height: 600px;
              max-width: 600px;
            }
          }
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @keyframes fadeOut {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default VCASVMap;

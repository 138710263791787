import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { assetBaseUrl } from '../../../../../config';
import {
  ILoubiAirwaysXRayAnimationState,
  LoubiAirwaysAnimation
} from '../../../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../../../redux/customActions/loubiAirways';
import { DID_UPDATE_ANIMATION_STATE } from '../../../../../utils/constants';
import { useRemoteLoubiAirwaysAnimationState } from '../../../../hooks/loubiAirways';
import { canInteractWithAnimation } from '../../../../hooks/meeting';
import { AnimationContext } from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import {
  aquajane,
  vidaTop,
  aquaria,
  kateSling
} from '../../../../ThreejsAnimation/louboutinJp/assets';
import { animationVoyageThrottleDurationInMs } from '../../LouboutinCustomComponent/animationUtils';
import LJContinueFlightButton from '../Buttons/LJContinueFlightButton';

let distance = 0;
let maskSize = 50;
let lastPosition = {
  x: 0,
  y: 0
};

const CompartmentXRayAnimation = () => {
  const { setLoaded, onCloseAnimation, setSelectedModel } =
    React.useContext(AnimationContext);
  const [showEndImage, setShowEndImage] = React.useState(false);
  const imageRef = React.createRef<HTMLImageElement>();
  const dispatch = useDispatch();
  const remoteState: ILoubiAirwaysXRayAnimationState =
    useRemoteLoubiAirwaysAnimationState(LoubiAirwaysAnimation.COMPARTMENT_XRAY);

  const canInteract = canInteractWithAnimation();

  const logEventThrottled = throttle(
    (state: ILoubiAirwaysXRayAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: LoubiAirwaysAnimation.COMPARTMENT_XRAY,
        state
      });
    },
    1000,
    { trailing: true }
  );

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirwaysXRayAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.COMPARTMENT_XRAY,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );

  const updateRemoteState = (state: ILoubiAirwaysXRayAnimationState) => {
    throttledUpdateRemoteState(state);
    logEventThrottled(state);
  };

  const getTraveledDistance = (x, y) => {
    const totalDistance = window.innerWidth * 10;
    const deltaX = Math.abs(lastPosition.x - x);
    const deltaY = Math.abs(lastPosition.y - y);
    distance = distance + deltaX + deltaY;
    lastPosition = { x, y };
    return Math.min(1.0, distance / totalDistance);
  };

  const handleCoordinates = (x: number, y: number) => {
    const traveledPercentage = getTraveledDistance(x, y);
    maskSize = traveledPercentage * window.innerWidth * 0.5;
    if (maskSize >= 200 && !showEndImage) {
      setShowEndImage(true);
      updateRemoteState({
        revealed: true
      });
    }
    if (imageRef.current) {
      imageRef.current.style['maskPosition'] = `${x}px ${y}px`;
      imageRef.current.style.webkitMaskPosition = `${x}px ${y}px`;
      imageRef.current.style['maskSize'] = `${maskSize}px`;
      imageRef.current.style.webkitMaskSize = `${maskSize}px`;
      if (!showEndImage) {
        updateRemoteState({
          maskCoordinatesInPercentage: {
            x: x / window.innerWidth,
            y: y / window.innerHeight
          },
          maskSize
        });
      }
    }
  };

  const moveHandler = (e) => {
    const x = e.pageX - maskSize / 2;
    const y = e.pageY - maskSize / 2;
    handleCoordinates(x, y);
  };

  const touchHandler = (e) => {
    if (!e.touches?.length || e.touches?.length === 0) {
      return;
    }
    const x = e.touches[0].clientX - maskSize / 2;
    const y = e.touches[0].clientY - maskSize / 2;
    handleCoordinates(x, y);
  };

  React.useEffect(() => {
    try {
      if (!canInteract && remoteState && imageRef) {
        const { maskCoordinatesInPercentage, maskSize, revealed } =
          remoteState || {};
        if (revealed) {
          setShowEndImage(true);
          return;
        }
        if (maskCoordinatesInPercentage?.x && maskCoordinatesInPercentage?.y) {
          const x = maskCoordinatesInPercentage.x * window.innerWidth;
          const y = maskCoordinatesInPercentage.y * window.innerHeight;
          if (imageRef.current) {
            imageRef.current.style['maskPosition'] = `${x}px ${y}px`;
            imageRef.current.style.webkitMaskPosition = `${x}px ${y}px`;
          }
        }
        if (maskSize && imageRef.current) {
          imageRef.current.style['maskSize'] = `${maskSize}px`;
          imageRef.current.style.webkitMaskSize = `${maskSize}px`;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [canInteract, remoteState, imageRef]);

  React.useEffect(() => {
    setTimeout(() => {
      setShowEndImage(true);
    }, 7000);
  }, []);

  React.useEffect(() => {
    setLoaded(true);
    if (imageRef && canInteract) {
      document.addEventListener('mousemove', moveHandler);
      document.addEventListener('touchmove', touchHandler);
      return () => {
        document.removeEventListener('mousemove', moveHandler);
        document.removeEventListener('touchmove', touchHandler);
      };
    }
  }, [imageRef, canInteract]);

  return (
    <div className="x-ray-container">
      <img
        src={`${assetBaseUrl}/loubiairways/animation-japan/xray/v1/cabin_compartment.jpg`}
        className="original"
      />
      {!showEndImage && (
        <img
          src={`${assetBaseUrl}/loubiairways/animation-japan/xray/v1/cabin_compartment_xray.jpg`}
          className="x-ray"
          ref={imageRef}
        />
      )}
      <img
        src={`${assetBaseUrl}/loubiairways/animation-japan/xray/v1/cabin_compartment_xray_revealed.jpg`}
        className={`x-ray-end ${showEndImage ? 'show' : ''}`}
      />

      {showEndImage && (
        <>
          <div
            className="product-click vidaTop"
            onClick={() => setSelectedModel(vidaTop)}
          ></div>
          <div
            className="product-click aquajane"
            onClick={() => setSelectedModel(aquajane)}
          ></div>
          <div
            className="product-click kateSling"
            onClick={() => setSelectedModel(kateSling)}
          ></div>
          <div
            className="product-click aquaria"
            onClick={() => setSelectedModel(aquaria)}
          ></div>
        </>
      )}
      <LJContinueFlightButton onClick={onCloseAnimation} />
      <style jsx>{`
        .x-ray-container {
          width: 100%;
          position: fixed;
          height: 100%;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          margin: 0;
          user-select: none;
        }

        img {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          margin: 0;
        }

        * {
          cursor: ${showEndImage ? 'initial' : 'none'};
        }

        .x-ray-end {
          opacity: 0;
          transition: opacity 1s ease-in;
        }

        .x-ray-end.show {
          opacity: 1;
        }

        .x-ray {
          mask-image: url(${`${assetBaseUrl}/loubiairways/luggage-animation/x-ray/v1/xray-machine.png`});
          mask-repeat: no-repeat;
          mask-size: 50px;
          -webkit-mask-position-x: 75px;
          -webkit-mask-position-y: 75px;
        }

        .product-click {
          position: absolute;
          height: 300px;
          width: 300px;
          top: 17vh;
          cursor: pointer;
        }

        .kateSling {
          right: 50%;
          transform: translateX(-43px);
          top: 7vh;
          height: 140px;
          width: 200px;
        }

        .aquajane {
          right: 50%;
          transform: translateX(340px);
          top: 8vh;
          height: 180px;
          width: 227px;
        }

        .vidaTop {
          right: 50%;
          transform: translateX(9px);
          top: 19vh;
          height: 144px;
          width: 243px;
        }

        .aquaria {
          right: 50%;
          transform: translateX(213px);
          top: 19vh;
          height: 144px;
          width: 196px;
        }

        @media (max-width: 768px) {
          .aquajane {
            width: 291px;
            height: 100px;
          }
          .kateSling {
            height: 100px;
          }
        }
      `}</style>
    </div>
  );
};

export default CompartmentXRayAnimation;

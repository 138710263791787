import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { useTranslation } from '../../i18n';

const CartSent = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="CartSent success text-center">
      <div className="icon-container">
        <MdCheckCircle color="green" />
      </div>
      <h2>{t('vb:thank_your_order')}</h2>
      <p>{t('vb:our_client_advisor_will_verify')}</p>
      <button
        className="btn-action"
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        {t('vb:close')}
      </button>
      <style jsx>{`
        .icon-container :global(svg) {
          width: 150px;
          height: auto;
        }

        .CartSent {
          margin: auto;
        }
      `}</style>
    </div>
  );
};

export default CartSent;

import { useTranslation } from 'next-i18next';
import { currentLanguage } from '../../utils/language';
import LinkForLanguage from './LinkForLanguage';

const addLanguageParamsToUrl = (url: string, language: string) => {
  const isHasOtherParams = url.indexOf('?') !== -1;
  const languageParam = `language=${language}`;
  return isHasOtherParams
    ? `${url}&${languageParam}`
    : `${url}?${languageParam}`;
};

export const Link = (props) => {
  const { i18n } = useTranslation();
  const language = currentLanguage(i18n);
  return (
    <LinkForLanguage
      {...props}
      href={addLanguageParamsToUrl(props.href as string, language)}
      as={addLanguageParamsToUrl(props.as as string, language)}
    />
  );
};

export default Link;

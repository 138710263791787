import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CartTable from '../Cart/CartTable';
import CheckoutAddress from './CheckoutAddress';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import { IMainState, CheckoutStep, CheckoutPaymentStatus } from '../../../interfaces';
import { i18n, useTranslation } from '../../../i18n';
import {
  actionCartUpdateCheckoutOrderId,
  actionCartUpdateCheckoutStep,
  actionCartUpdateCheckoutPaymentStatus
} from '../../../redux/actions';
import { performCheckout } from '../checkoutService';
import {
  getCartTotalPrice,
  mapItemToCartDisplay
} from '../../../utils/shoppingCart';
import {
  DID_CHECKOUT_SUCCESSFULLY,
  DID_FAIL_TO_CHECKOUT,
  DID_CLICK_CONFIRM_ORDER_AND_PAY
} from '../../../utils/constants';
import { logEvent } from '../../../analytics';

const CheckoutConfirmation = ({
  storeId,
  deliveryFee
}: {
  storeId: string;
  deliveryFee: number;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clientState = useSelector((state: IMainState) => state.clientState);
  const store = clientState?.store;
  const brandId = clientState?.virtualBoutiqueConfig?.brandId;
  const shoppingCartState = clientState?.shoppingCart;
  const formData = shoppingCartState?.checkoutFormData || {};
  const country = store?.countryCode;
  const token = shoppingCartState?.checkout?.token;

  const [submitting, setSubmitting] = React.useState(false);
  const CTAText = submitting
    ? t('vb:processing')
    : t('vb:confirm_order_and_pay');

  const setCheckoutOrderId = (orderId: string) => {
    dispatch(actionCartUpdateCheckoutOrderId(orderId));
  };

  const setCheckoutPaymentStatus = (status: CheckoutPaymentStatus) => {
    dispatch(actionCartUpdateCheckoutPaymentStatus(status));
  };

  const onConfirmOrder = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    const translatedFormData = {
      ...formData,
      salutation: t(formData?.salutation),
      addressForm: {
        ...formData.addressForm,
        area: t(formData?.addressForm?.area),
        city: t(formData?.addressForm?.city)
      },
      billingAddressForm: {
        ...formData.billingAddressForm,
        area: t(formData?.billingAddressForm?.area),
        city: t(formData?.billingAddressForm?.city)
      }
    };
    const cartItems = shoppingCartState.items.map((item) =>
      mapItemToCartDisplay(item, country, i18n.language)
    );

    const totalPrice = getCartTotalPrice(
      shoppingCartState.items,
      country,
      deliveryFee
    );

    const billingDescriptor = {
      name: store?.name,
      city: store?.city
    };

    logEvent(DID_CLICK_CONFIRM_ORDER_AND_PAY);

    performCheckout(
      translatedFormData,
      cartItems,
      totalPrice,
      billingDescriptor,
      storeId,
      brandId,
      token,
      i18n.language.toUpperCase()
    )
      .then((result) => {
        setSubmitting(false);
        const { approved, redirectLink, orderId } = result.data;

        if (approved && orderId) {
          setCheckoutOrderId(orderId);
          setCheckoutPaymentStatus(CheckoutPaymentStatus.ACCEPTED)
          dispatch(actionCartUpdateCheckoutStep(CheckoutStep.THANK_YOU));
          logEvent(DID_CHECKOUT_SUCCESSFULLY);
          return;
        }

        if (!approved && redirectLink?.href) {
          window.location.replace(redirectLink?.href);
          return;
        }

        setCheckoutPaymentStatus(CheckoutPaymentStatus.DECLINED)
        dispatch(actionCartUpdateCheckoutStep(CheckoutStep.THANK_YOU));
        logEvent(DID_FAIL_TO_CHECKOUT);
      })
      .catch((error) => {
        setSubmitting(false);
        logEvent(DID_FAIL_TO_CHECKOUT, DID_FAIL_TO_CHECKOUT, { error });
      });
  };

  return (
    <div className="container">
      <h2>{t('vb:order_details')}</h2>
      <div className="row">
        <div className="col col-12 col-lg-7 cart-container">
          <CartTable items={shoppingCartState?.items} readonly />
        </div>
        <div className="col col-12 col-lg-5">
          <CheckoutAddress details={formData} />
        </div>
      </div>
      <div className="input-group mt-5">
        <button
          disabled={submitting}
          onClick={() => onConfirmOrder()}
          id="sendform"
          type="button"
          className="checkout-button"
        >
          {CTAText}
        </button>
      </div>
      <PoweredByInspify />
      <style jsx>{`
        .container {
          padding-left: 0;
          padding-right: 0;
        }
        .checkout-button {
          padding: 0 30px;
          align-self: center;
          margin: auto;
        }
        @media (min-width: 992px) {
          .cart-container {
            padding-right: 40px;
            padding-top: 8px;
          }
          .checkout-button {
            width: 40% !important;
          }
        }
      `}</style>
    </div>
  );
};

export default CheckoutConfirmation;

import React from 'react';
import { useTranslation } from '../../../i18n';
import { MDLandscapeNormalSpec } from '../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../Meeting/MeetingLayout/SMLandscapeNormal';

const LoadingProgress = ({ loading }: { loading: Boolean }) => {
  const { t } = useTranslation();
  return (
    <div className="loading-wrapper fixed-full">
      <div className={`LoadingProgress ${loading ? 'show' : 'hide'}`}>
        <div className="shape-container">
          <div className="shape" id="round1"></div>
          <div className="shape" id="round2"></div>
          <div className="shape" id="round3"></div>
        </div>
        <div className="shape" id="round4">
          <span className="label">{t('please_wait')}...</span>
        </div>
      </div>

      <style jsx>{`
        .LoadingProgress {
          position: fixed;
          top: 50%;
          margin-top: -100px;
          left: 50%;
          margin-left: -100px;
          cursor: pointer;
        }

        :global(.in-meeting.MDLandscape) .LoadingProgress {
          left: calc(
            50% + ${MDLandscapeNormalSpec.contentArea.left / 2}px
          ) !important;
        }

        :global(.in-meeting.SMLandscape) .LoadingProgress {
          left: calc(
            50% + ${SMLandscapeNormalSpec.contentArea.left / 2}px
          ) !important;
        }

        .shape-container {
          width: 200px;
          height: 200px;
          position: relative;
          background-size: 100% 100%;
          background-position: 50% 50%;
        }

        .hide {
          -webkit-animation: swirl-out-fwd 0.8s ease-in both;
          animation: swirl-out-fwd 0.8s ease-in both;
        }

        .shape {
          position: absolute;
          left: 0;
          top: 0;
          width: 200px;
          height: 200px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
          background: radial-gradient(
            circle at center,
            transparent 70%,
            rgba(255, 255, 255, 0.7) 71%
          );
        }

        #round1 {
          border-radius: 50% 50% 50% 50% / 50% 50% 60% 60%;
          -webkit-animation: rotate-scale-up 3s linear infinite both;
          animation: rotate-scale-up 3s linear infinite both;
        }

        #round2 {
          border-radius: 50% 50% 50% 50% / 50% 60% 50% 60%;
          -webkit-animation: rotate-scale-up 6s linear infinite reverse both;
          animation: rotate-scale-up 6s linear infinite reverse both;
        }

        #round3 {
          border-radius: 50% 50% 50% 50% / 60% 50% 60% 50%;
          -webkit-animation: rotate-scale-up 5s linear infinite both;
          animation: rotate-scale-up 5s linear infinite both;
          background: radial-gradient(
            circle at center,
            transparent 70%,
            rgba(255, 255, 255, 0.3) 71%
          );
        }

        #round4 {
          border-radius: 100%;
          width: 186px;
          height: 186px;
          opacity: 1;
          left: 8px;
          top: 8px;
          text-align: center;
          display: flex;
          align-items: center;
          padding: 10px;
          box-sizing: border-box;
        }

        .label {
          padding: 0 10px;
          margin: auto;
          font-size: 0.8em;
          letter-spacing: 1px;
          font-weight: bold;
          line-height: 1.2;
          color: #fff;
          text-shadow: 0 1px 2px #000;
        }

        .hide .label {
          display: none;
        }

        @-webkit-keyframes rotate-scale-up {
          0% {
            -webkit-transform: scale(0.9) rotateZ(0);
            transform: scale(0.9) rotateZ(0);
          }

          50% {
            -webkit-transform: scale(1) rotateZ(180deg);
            transform: scale(1) rotateZ(180deg);
          }

          100% {
            -webkit-transform: scale(0.9) rotateZ(360deg);
            transform: scale(0.9) rotateZ(360deg);
          }
        }

        @keyframes rotate-scale-up {
          0% {
            -webkit-transform: scale(0.9) rotateZ(0);
            transform: scale(0.9) rotateZ(0);
          }

          50% {
            -webkit-transform: scale(1) rotateZ(180deg);
            transform: scale(1) rotateZ(180deg);
          }

          100% {
            -webkit-transform: scale(0.9) rotateZ(360deg);
            transform: scale(0.9) rotateZ(360deg);
          }
        }

        @-webkit-keyframes slide-in-top {
          0% {
            -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
            opacity: 0;
          }

          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
          }
        }

        @-webkit-keyframes swirl-out-fwd {
          0% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
            opacity: 1;
          }

          100% {
            -webkit-transform: rotate(1540deg) scale(7);
            transform: rotate(1540deg) scale(7);
            opacity: 0;
          }
        }

        @keyframes swirl-out-fwd {
          0% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
            opacity: 1;
          }

          100% {
            -webkit-transform: rotate(540deg) scale(7);
            transform: rotate(540deg) scale(7);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LoadingProgress;

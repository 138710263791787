import React from 'react';
import { BsLockFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { sceneByMenu } from '../../../../../config/rduSg';
import { RDUHudMenu } from '../../../../../interfaces';
import { DID_CLICK_HUD_MENU } from '../../../../../utils/constants';
import {
  actionDidClickHudMenu,
  actionOpenHud
} from './../../../../../redux/actions';

interface Props {
  activeMenu?: RDUHudMenu;
  restrictedNav?: RDUHudMenu[];
  onRemoveRestrictedNav: (nav: RDUHudMenu) => void;
}

enum AccessRequest {
  OPEN = 'OPEN',
  FAILED = 'FAILED'
}

const {
  LOBBY,
  FIVE_AXIS_MILLING,
  ELECTRO_EROSION,
  WHEELS_POLISHING_WORKSHOPS,
  PERLAGE,
  FLAT_MIRROR_POLISH_AND_DETOURING,
  DETOURING,
  GARNISH_WORKSHOPS_OPENING,
  WATCH_MAKING_WORKSHOPS,
  QLAB,
  ETIRAGE
} = RDUHudMenu;

const HudManufacture = ({
  activeMenu,
  restrictedNav = [],
  onRemoveRestrictedNav
}: Props) => {
  const dispatch = useDispatch();
  const [accessRequest, setAccessRequest] = React.useState<{
    nav: RDUHudMenu;
    status: AccessRequest;
  }>(null);

  const loginQlab = React.useRef<HTMLInputElement>();

  const dispatchAction = (menu: RDUHudMenu, close = true) => {
    if (close) dispatch(actionOpenHud(false));
    if (menu !== activeMenu) dispatch(actionDidClickHudMenu(menu));
  };

  const handleLogin = () => {
    const password = loginQlab.current.value;
    if (password === 'abc123') {
      onRemoveRestrictedNav(accessRequest.nav);
      dispatchAction(QLAB);
      setAccessRequest(null);
    } else {
      setAccessRequest({ ...accessRequest, status: AccessRequest.FAILED });
      loginQlab.current.value = '';
    }
  };

  const handleClickNav = (nav: RDUHudMenu, close = true) => {
    if (restrictedNav.includes(nav)) {
      setAccessRequest({ nav, status: AccessRequest.OPEN });
    } else {
      dispatchAction(nav, close);
      if (accessRequest) setAccessRequest(null);
    }

    logEvent(DID_CLICK_HUD_MENU, DID_CLICK_HUD_MENU, { menu: nav });
  };

  const getDisplayName = (menu: RDUHudMenu) => {
    return sceneByMenu[menu]?.displayName || '';
  };

  React.useEffect(() => {
    if (accessRequest?.status === AccessRequest.OPEN)
      loginQlab?.current?.focus();
  }, [accessRequest]);

  const hudNav = (
    <>
      <div className="hud-nav">
        <button
          className={`btn btn-main ${activeMenu === LOBBY && 'active'}`}
          onClick={() => handleClickNav(LOBBY)}
        >
          {getDisplayName(LOBBY)}
        </button>
      </div>

      <div className={`hud-nav`}>
        <button
          className={`btn btn-main ${
            activeMenu === FIVE_AXIS_MILLING && 'active'
          }`}
          onClick={() => handleClickNav(FIVE_AXIS_MILLING, false)}
        >
          micromechanics
        </button>

        <div
          className={`sub-hud-nav ${
            (activeMenu === FIVE_AXIS_MILLING ||
              activeMenu === ELECTRO_EROSION) &&
            'expanded'
          }`}
        >
          <button
            className={`btn btn-sub ${
              activeMenu === FIVE_AXIS_MILLING && 'active'
            }`}
            onClick={() => handleClickNav(FIVE_AXIS_MILLING)}
          >
            {getDisplayName(FIVE_AXIS_MILLING)}
          </button>
          <button
            className={`btn btn-sub ${
              activeMenu === ELECTRO_EROSION && 'active'
            }`}
            onClick={() => handleClickNav(ELECTRO_EROSION)}
          >
            {getDisplayName(ELECTRO_EROSION)}
          </button>
        </div>
      </div>

      <div className="hud-nav">
        <button
          className={`btn btn-main ${
            activeMenu === WHEELS_POLISHING_WORKSHOPS && 'active'
          }`}
          onClick={() => handleClickNav(WHEELS_POLISHING_WORKSHOPS)}
        >
          {getDisplayName(WHEELS_POLISHING_WORKSHOPS)}
        </button>
      </div>

      <div className={`hud-nav`}>
        <button
          className={`btn btn-main ${activeMenu === PERLAGE && 'active'}`}
          onClick={() => handleClickNav(PERLAGE, false)}
        >
          POLISHING WORKSHOPS
        </button>

        <div
          className={`sub-hud-nav ${
            (activeMenu === ETIRAGE ||
              activeMenu === DETOURING ||
              activeMenu === PERLAGE) &&
            'expanded'
          }`}
        >
          <button
            className={`btn btn-sub ${activeMenu === PERLAGE && 'active'}`}
            onClick={() => handleClickNav(PERLAGE)}
          >
            {getDisplayName(PERLAGE)}
          </button>
          <button
            className={`btn btn-sub ${
              activeMenu === ETIRAGE && 'active'
            }`}
            onClick={() => handleClickNav(ETIRAGE)}
          >
            {getDisplayName(ETIRAGE)}
          </button>

          <button
            className={`btn btn-sub ${
              activeMenu === DETOURING && 'active'
            }`}
            onClick={() => handleClickNav(DETOURING)}
          >
            {getDisplayName(FLAT_MIRROR_POLISH_AND_DETOURING)}
          </button>
        </div>
      </div>

      <div className="hud-nav">
        <button
          className={`btn btn-main ${
            activeMenu === GARNISH_WORKSHOPS_OPENING && 'active'
          }`}
          onClick={() => handleClickNav(GARNISH_WORKSHOPS_OPENING)}
        >
          {getDisplayName(GARNISH_WORKSHOPS_OPENING)}
        </button>
      </div>
      <div className="hud-nav">
        <button
          className={`btn btn-main ${
            activeMenu === WATCH_MAKING_WORKSHOPS && 'active'
          }`}
          onClick={() => handleClickNav(WATCH_MAKING_WORKSHOPS)}
        >
          {getDisplayName(WATCH_MAKING_WORKSHOPS)}
        </button>
      </div>

      <div className="hud-nav">
        <button
          className={`btn btn-main ${activeMenu === QLAB && 'active'}`}
          onClick={() => handleClickNav(QLAB)}
        >
          {restrictedNav.includes(QLAB) && (
            <span style={{ fontSize: '.7em' }}>
              <BsLockFill style={{ marginTop: '-7px' }} />
            </span>
          )}{' '}
          {getDisplayName(QLAB)}
        </button>
      </div>

      <style jsx>{`
        .sub-hud-nav {
          display: none;
        }
        .sub-hud-nav.expanded {
          display: block;
        }
        .btn {
          border-radius: 0;
          background: #fff;
          display: block;
          text-align: right;
          width: 300px;
          font-family: 'RockWell', Helvetica;
          letter-spacing: 0.5px;
          height: auto;
          margin-bottom: 15px;
          width: 210px;
          border: none;
          border-right: 10px solid #fff;
          position: relative;
          margin-right: 40px;
          text-transform: uppercase;
          font-weight: 0.8em;
        }
        .btn:after {
          content: '';
          display: block;
          width: 200px;
          height: 50px;
          background: url(/asset/rdu/manufacture-nav-line.png) right top
            no-repeat;
          position: absolute;
          right: -52px;
          width: 42px;
          top: 17px;
          background-size: auto 22px;
        }

        .btn:hover,
        .btn.active {
          border-color: #a51f25;
          color: #a51f25;
        }
        .btn-main {
          color: #a51f25;
          font-size: 0.9em;
          font-weight: bold;
          letter-spacing: 2px;
          background: url('/asset/rdu/manufacture-nav-box.png') left bottom
            no-repeat;
          background-size: auto 60px;
          padding-left: 24px;
          width: 220px;
        }

        .btn-sub {
          font-size: 0.8em;
          letter-spacing: 1px;
          width: 195px;
          margin-left: auto;
        }

        @media (max-width: 1100px) {
          .btn.btn-sub {
            font-size: 0.7em;
            height: 30px;
            margin-bottom: 10px;
          }
          .btn.btn-main {
            font-size: 0.65em;
            padding-bottom: 1px;
          }
        }
        @media (max-width: 840px),
          (max-height: 768px) and (orientation: landscape) {
          .btn {
            width: 285px;
          }
          .btn-sub {
            width: 260px;
            font-size: 0.7em;
            margin-bottom: 10px;
          }
          .btn.btn-main {
            font-size: 0.65em;
            padding-bottom: 1px;
          }
          .btn-sub,
          .btn-main {
            padding-top: 8px;
            padding-bottom: 5px;
            line-height: 20px;
            margin-bottom: 8px;
          }
        }
      `}</style>
    </>
  );

  const login = (
    <div className="login-container">
      <p style={{ fontSize: '2.5em' }}>
        <BsLockFill color="#fff" />
      </p>
      <div className="input-group input-group-lg">
        <input
          key="loginQlab"
          autoFocus
          type="password"
          ref={loginQlab}
          className={`form-control ${
            accessRequest?.status === AccessRequest.FAILED
          }`}
          placeholder="Enter access code"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
        />
        <div className="input-group-append">
          <button className="btn btn-dark" onClick={handleLogin}>
            SUBMIT
          </button>
        </div>
      </div>
      <p onClick={() => setAccessRequest(null)}>CANCEL</p>
      <style jsx>{`
        .login-container {
          width: 90%;
          max-width: 400px;
        }
        .btn-dark {
          background: #a51f25;
          border-color: #a51f25;
        }
        .form-control.true {
          background: #ffd4d4;
        }
        p {
          color: #fff;
          text-decoration: underline;
          text-align: center;
          font-size: 0.9em;
          margin-top: 40px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );

  return (
    <div className={`HudManufacture ${accessRequest && 'has-login'}`}>
      <div className="hud-nav-container">{accessRequest ? login : hudNav}</div>
      <style jsx global>{`
        .frame-manufacture .frame-c {
          background: none !important;
          border-left: 3px solid #fff;
        }
        .frame-manufacture .frame-c-inner {
          background: none !important;
          border-right: 3px solid #fff;
        }
        @media (max-width: 724px) {
          .frame-manufacture .frame-c,
          .frame-manufacture .frame-c-inner {
            border: none;
          }
        }
      `}</style>
      <style jsx>{`
        .HudManufacture {
          flex-grow: 1;
          padding-top: 30px;
        }
        .HudManufacture.has-login {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .hud-nav-container {
          text-align: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .has-login .hud-nav-container {
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default HudManufacture;

import { enc } from '../../utils/crypt';
import { generateV4UUID } from '../../utils/identityGenerator';
import {
  DeliveryMethod,
  IShoppingCartItemDisplay,
  ICheckoutFormData,
  IBillingDescriptor,
  ICheckoutPayload
} from '../../interfaces';
import { CartTotalPrice, getFormattedFullName } from '../../utils/shoppingCart';
import { i18n } from '../../i18n';
import {
  getPreferredContact,
  mapCartItemsToItemsPayload
} from './shoppingCartService';
import { getIdentityId } from '../../utils/identity';
import httpClient from '../../clientSideServices/http';

export const mapFormToPayload = (
  formData: ICheckoutFormData,
  items: IShoppingCartItemDisplay[],
  totalPrice: CartTotalPrice,
  billingDescriptor: IBillingDescriptor,
  storeId: string,
  brandId: string,
  token: string,
  languageCode: string
) => {
  const shippingAddress = {
    addressLine1: formData?.addressForm?.address || '',
    addressLine2: formData?.addressForm?.area || '',
    city: formData?.addressForm?.city || '',
    state: formData?.addressForm?.state || '',
    zip: formData?.addressForm?.zip || '',
    country: formData?.addressForm?.country || ''
  };
  const payload = {
    id: generateV4UUID(),
    storeId: storeId,
    brand: brandId,
    token: token,
    amount: totalPrice.total,
    currency: totalPrice.currency,
    customer: {
      email: formData.email,
      name: getFormattedFullName(
        formData?.firstName,
        formData?.lastName,
        formData?.salutation
      )
    },
    billingDescriptor,
    shipping: {
      name: getFormattedFullName(
        formData?.firstName,
        formData?.lastName,
        formData?.salutation
      ),
      address: shippingAddress,
      phone: {
        countryCode: formData.phoneCountryCode,
        number: formData.phone
      }
    },
    billing: {
      name: getFormattedFullName(
        formData?.firstName,
        formData?.lastName,
        formData?.salutation
      ),
      address: formData?.useShippingAsBillingAddress
        ? shippingAddress
        : {
          addressLine1: formData?.billingAddressForm?.address || '',
          addressLine2: formData?.billingAddressForm?.area || '',
          city: formData?.billingAddressForm?.city || '',
          state: formData?.billingAddressForm?.state || '',
          zip: formData?.billingAddressForm?.zip || '',
          country: formData?.billingAddressForm?.country || ''
        },
      phone: {
        countryCode: formData.phoneCountryCode,
        number: formData.phone
      }
    },
    subscribeNews: formData.subscribeNews,
    acceptedTermsOfUse: formData.acceptedTermsOfUse,
    checkoutContactId: '',
    deliveryFee: totalPrice.deliveryFee
      ? totalPrice.formattedDeliveryFee
      : i18n.t('vb:free'),
    cartItems: mapCartItemsToItemsPayload(items),
    preferredDelivery:
      formData.preferredDelivery === DeliveryMethod.PICKUP
        ? `${i18n.t('vb:pickup_at')} ${formData.shopNowContact?.nameKey
          ? i18n.t(`vb:${formData.shopNowContact.nameKey}`)
          : formData.shopNowContact.name
        }`
        : i18n.t('vb:delivery'),
    preferredContact: getPreferredContact(formData.preferredContact),
    preferredLanguage: languageCode,
    subTotal: totalPrice.subTotal,
    totalPrice: totalPrice.total,
    createdAt: new Date(),
    createdBy: getIdentityId(),
    modifiedAt: '',
    modifiedBy: '',
    giftWrapping: formData.giftWrapping,
    giftNote: formData.giftNote,
    shippingTax: ''
  };

  return payload;
};

export const performCheckout = (
  formData: ICheckoutFormData,
  items: IShoppingCartItemDisplay[],
  totalPrice: CartTotalPrice,
  billingDescriptor: IBillingDescriptor,
  storeId: string,
  brandId: string,
  token: string,
  languageCode = 'EN'
) => {
  const body: ICheckoutPayload = mapFormToPayload(
    formData,
    items,
    totalPrice,
    billingDescriptor,
    storeId,
    brandId,
    token,
    languageCode
  );

  return checkoutService(body);
};

const checkoutService = (body: ICheckoutPayload) => {
  const id = generateV4UUID();
  const encrypted = {
    id,
    content: enc(JSON.stringify(body), id)
  };
  return httpClient.post('/api/checkout', encrypted);
};

export const isProductCheckoutable = async (body: {
  productId: string;
  storeId: string;
}) => {
  const data = await httpClient.post('/api/getInventoryProductById', body);
  const availability = data?.data?.[0]?.availability;

  return availability?.[0].is_sellable && availability?.[0].quantity;
};

export const getCkoSessionById = async (sessionId: string) => {
  const body = {
    sessionId: sessionId
  };
  return httpClient.post('/api/getCkoSessionById', body);
};

export const getCheckoutFrameKey = async () => {
  return httpClient.get('/api/getCheckoutFrame');
};

import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import { IProduct, ProductType } from '../../interfaces';
import {
  DID_CLOSE_SPECIFICATIONS,
  DID_VIEW_SPECIFICATIONS
} from '../../utils/constants';
import {
  getProductSpecificationsByLang,
  getProductSpecsConfigForSameType,
  mapPreciousStoneSpec
} from '../../utils/product';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';
import { actionShowProductSpecifications } from '../../redux/actions';
import { first, last } from 'lodash';
import { isParentWindowInMeeting, jumpToAnchor } from '../../utils/window';

const renderOtherSpec = (spec: string) => {
  const components = spec.split(':');
  const title = first(components);
  let desc = last(components);

  if (title.toLowerCase().trim() === 'chopard boutique exclusivity') {
    if (Number(desc) === 1) desc = 'Yes';
    if (Number(desc) === 0) desc = 'No';
  }

  return (
    <li key={spec} className="other-spec">
      <span>{title}:</span>
      {desc}
    </li>
  );
};

const renderPreciousStonesSpec = (spec: { [key: string]: string | number }) => {
  const { t } = useTranslation();
  const mappedSpec = mapPreciousStoneSpec(spec);
  return (
    <>
      {Object.keys(mappedSpec || {}).map(
        (s) =>
          mappedSpec[s] && (
            <li className="sub-spec-item" key={s}>
              <span>{t(s).toLowerCase()}:&nbsp;</span>
              <span>{mappedSpec[s]}</span>
            </li>
          )
      )}
      <style jsx>{`
        .sub-spec-item span {
          display: inline-block;
        }
        .sub-spec-item span :first-letter {
          display: inline-block;
          text-transform: capitalize;
        }
      `}</style>
    </>
  );
};

export const renderSpec = (type: ProductType, spec, specName?: string) => {
  if (specName === 'other') {
    return spec?.map((s) => renderOtherSpec(s));
  }
  if (type === ProductType.HIGH_JEWELLERY && specName === 'preciousStones') {
    return (
      <li>
        {spec.map((specStones, i) => (
          <ul key={'specStones' + i} className="sub-specs">
            {renderPreciousStonesSpec(specStones)}
            <pre/><pre/>
          </ul>
        ))}
      </li>
    );
  }
  if (Array.isArray(spec)) {
    return spec.map((s: string) => <li key={s}>{s}</li>);
  }
  return <li>{spec}</li>;
};

const ProductSpecifications = ({ product }: { product: IProduct }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const showDetails =
    useSelector(
      (state: IMainState) =>
        state.clientState?.productDetails?.showSpecifications
    ) || false;
  const specifications = getProductSpecificationsByLang(product, language);
  const specsConfig = getProductSpecsConfigForSameType(product);
  const hasSpecItem = (key: string) =>
    specifications[key] ||
    (Array.isArray(specifications[key]) && specifications[key].length);

  React.useEffect(() => {
    dispatch(actionShowProductSpecifications(false));
  }, [product]);

  React.useEffect(() => {
    if (showDetails && !isParentWindowInMeeting()) {
      jumpToAnchor('#specification-anchor');
    }
  }, [showDetails]);

  if (!specifications) {
    return null;
  }

  const detailSpecConfig = specsConfig?.filter((c)=>hasSpecItem(c?.specs) && specifications[c?.specs]?.length)

  if (!detailSpecConfig?.length) {
    return null;
  }

  return (
    <Accordion className="details-accordion">
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="specifications"
          onClick={() => {
            if (!showDetails) {
              logEvent(DID_VIEW_SPECIFICATIONS);
              dispatch(actionShowProductSpecifications(true));
            } else {
              logEvent(DID_CLOSE_SPECIFICATIONS);
              dispatch(actionShowProductSpecifications(false));
            }
          }}
        >
          <span className={showDetails ? 'strong' : null}>
            {t('specifications')}
          </span>
          {showDetails ? <BsChevronDown /> : <BsChevronRight />}
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey="specifications"
          className={`${showDetails ? 'show' : 'hide'}`}
        >
          <Card.Body>
            <div className="specifications-container">
              {detailSpecConfig &&
                detailSpecConfig.map(
                  (c) =>
                    hasSpecItem(c.specs) && specifications[c.specs]?.length ? (
                      <div key={c.title} className="specifications-details">
                        <h4>{t(c.title)}</h4>
                        <ul>
                          {renderSpec(
                            product.type,
                            specifications[c.specs],
                            c.specs
                          )}
                        </ul>
                      </div>
                    ):null
                )}
            </div>
          </Card.Body>
        </Accordion.Collapse>
        <style jsx>
          {`
            h2 {
              font-size: 22px;
              text-align: center;
              width: 100%;
              margin: 0;
            }

            h4 {
              font-size: 14px;
              letter-spacing: 2px;
              text-transform: uppercase;
            }

            .specifications-details {
              margin-bottom: 20px;
            }

            .specifications-details:last-child {
              margin-bottom: 0;
            }

            .specifications-details ul {
              padding: 0;
              list-style-type: none;
            }

            li {
              color: #666;
            }

            .specifications-details :global(.other-spec span) {
              display: block;
              text-transform: capitalize;
              font-weight: 500;
              font-size: 1em;
              padding-top: 15px;
            }

            .specifications-details :global(.sub-specs) {
              padding: 20px;
              border: 1px solid #efefef;
              margin-top: 10px;
              border-radius: 5px;
            }
            .specifications-details :global(.sub-specs:nth-child(odd)) {
              background: #f7f7f7;
            }
            .specifications-details :global(.sub-spec-item) {
              padding: 5px 0;
              list-style-type: none;
            }

            .specifications-details :global(.sub-spec-item > span:first-child) {
              text-transform: capitalize;
              font-weight: 500;
              display: block;
            }

            @media (max-width: 768px) {
              h2 {
                font-size: 18px;
              }
              .specifications-details {
                width: 100%;
                margin-bottom: 10px;
                padding: 0;
              }
            }
          `}
        </style>
      </Card>
    </Accordion>
  );
};

export default ProductSpecifications;

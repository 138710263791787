import React from 'react';
import { ChopardAnimation } from '../../../../interfaces/chopard';
import { useChopardState } from '../../../hooks/chopard';
import AnimationContextContainer from '../../../ThreejsAnimation/chopard/AnimationContext';
import ArtisansWrapper from '../../../ThreejsAnimation/chopard/ArtisansWrapper';
import AtriumWrapper from '../../../ThreejsAnimation/chopard/AtriumWrapper';
import CircusWrapper from '../../../ThreejsAnimation/chopard/CircusWrapper';
import ChopardHappySportFullScreenVideo from './ChopardHappySportFullScreenVideo';
import ChopardHappySportProductDetails from './ChopardHappySportProductDetails';

const ChopardHappySportCustomComponent = () => {
  const chopardState = useChopardState();
  const { activeAnimation } = chopardState;
  const getAnimation = () => {
    switch (activeAnimation) {
      case ChopardAnimation.ARTISAN:
        return <ArtisansWrapper />;
      case ChopardAnimation.CIRCUS:
        return <CircusWrapper />;
      case ChopardAnimation.ATRIUM:
        return <AtriumWrapper />;
      default:
        return null;
    }
  };

  return (
    <>
      <AnimationContextContainer key={activeAnimation}>
        {getAnimation()}
      </AnimationContextContainer>

      <ChopardHappySportFullScreenVideo />
      <ChopardHappySportProductDetails />

      <style jsx>{`
        .btn-group {
          position: fixed;
          bottom: 20px;
          right: 20px;
        }
        :global(.in-meeting .slidenav){
          display:none !important;
        }
      `}</style>
    </>
  );
};

export default ChopardHappySportCustomComponent;

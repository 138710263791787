import React from 'react';
import { IHudState } from '../../../../../interfaces';
import { useDispatch } from 'react-redux';
import { actionOpenHud } from '../../../../../redux/actions';
import { logEvent } from '../../../../../analytics/index';
import { DID_CLICK_HUD_BUTTON } from '../../../../../utils/constants';
import { MDLandscapeNormalSpec } from './../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from './../../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MDPortraitNormalSpec } from './../../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMPortraitNormalSpec } from './../../../../Meeting/MeetingLayout/SMPortraitNormal';


const HudButton = ({
  hud,
  className = ''
}: {
  hud?: IHudState;
  className?: string;
}) => {
  const [pulsating, setPulsating] = React.useState(true);
  const dispatch = useDispatch();
  const show = !hud?.open && hud?.showButton;
  const handleClick = () => {
    dispatch(actionOpenHud(true));
    logEvent(DID_CLICK_HUD_BUTTON, DID_CLICK_HUD_BUTTON, {
      open: true
    });
    if (pulsating) setPulsating(false);
  };
  return (
    <>
      <img
        className={`hudButton ${className} ${show ? 'in' : 'out'} ${
          pulsating && 'pulsating'
        }`}
        src="/asset/rdu/hud-button.png"
        onClick={handleClick}
      />
      {pulsating && <span onClick={handleClick}>CLICK TO NAVIGATE</span>}
      <style jsx>{`
        span {
          background: #a51f25;
          color: #fff;
          display: inline-block;
          border-radius: 15px;
          padding: 5px 10px;
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: 2px;
          font-family: 'Helvetica', sans-serif;
          font-size: 0.7em;
          bottom: 7px;
          z-index: 211;
          opacity: 0;
          -webkit-animation: blink-fade 10s ease-in-out 2s infinite;
          animation: blink-fade 10s ease-in-out 2s infinite;
        }
        .hudButton {
          width: 90px;
          height: 90px;
          position: fixed;
          left: 50vw;
          margin-left: -45px;
          cursor: pointer;
          bottom: 40px;
          z-index: 210;
        }

        :global(*) {
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -o-user-select: none;
        }
        .in {
          -webkit-animation: swirl-in-fwd 0.6s ease-out 1s both;
          animation: swirl-in-fwd 0.6s ease-out 1s both;
        }
        .out {
          -webkit-animation: swirl-out-bck 0.4s ease-in both;
          animation: swirl-out-bck 0.4s ease-in both;
        }
        .pulsating {
          -webkit-animation: swirl-in-fwd 0.6s ease-out 1s both,
            blink-1 5s ease-out 2s infinite;
          animation: swirl-in-fwd 0.6s ease-out 1s both,
            blink-1 5s ease-out 2s infinite;
        }
        .show {
          display: block;
        }
        .hide {
          display: none;
        }

        @-webkit-keyframes swirl-in-fwd {
          0% {
            -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
            opacity: 0;
          }
          100% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
            opacity: 1;
          }
        }
        @keyframes swirl-in-fwd {
          0% {
            -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
            opacity: 0;
          }
          100% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
            opacity: 1;
          }
        }

        @-webkit-keyframes blink-fade {
          0%,
          30% {
            opacity: 0;
          }
          40%,
          80% {
            opacity: 0.7;
          }
          90%,
          100% {
            opacity: 0;
          }
        }
        @keyframes blink-fade {
          0%,
          30% {
            opacity: 0;
          }
          40%,
          80% {
            opacity: 0.7;
          }
          90%,
          100% {
            opacity: 0;
          }
        }

        @-webkit-keyframes blink-1 {
          0%,
          50%,
          100% {
            opacity: 1;
          }
          25%,
          75% {
            opacity: 0.3;
          }
        }
        @keyframes blink-1 {
          0%,
          50%,
          100% {
            opacity: 1;
          }
          25%,
          75% {
            opacity: 0.3;
          }
        }

        @-webkit-keyframes swirl-out-bck {
          0% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
            opacity: 1;
          }
          100% {
            -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
            opacity: 0;
          }
        }
        @keyframes swirl-out-bck {
          0% {
            -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
            opacity: 1;
          }
          100% {
            -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
            opacity: 0;
          }
        }
        @media (max-width: 724px) {
          .hudButton {
            width: 80px;
            height: 80px;
            margin-left: -40px;
            bottom: 40px;
          }
          span {
            font-size: 0.6em;
            bottom: 67px;
          }
        }

        @media (max-height: 724px) {
          .hudButton {
            width: 80px;
            height: 80px;
            margin-left: -40px;
          }
        }
        @media (max-height: 724px) and (orientation: landscape) {
          .hudButton {
            bottom: 20px;
          }
        }

        @media (min-width: 840px) {
          .hudButton.isPresentation {
          }

          .isPresentation + span,
          .isSide + span {
            display: none;
          }
        }
      `}</style>
      <style jsx global>{`
        .in-meeting .hudButton span{
          display:none;
        }
        .normal-mode.MDPortrait .hudButton {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom + 40}px;
        }
        .normal-mode.SMPortrait .hudButton {
          bottom: ${SMPortraitNormalSpec.contentArea.bottom + 40}px;
        }
        .normal-mode.MDLandscape .hudButton {
          left: calc(${MDLandscapeNormalSpec.contentArea.left / 2}px + 50vw);
        }
        .normal-mode.SMLandscape .hudButton {
          left: calc(${SMLandscapeNormalSpec.contentArea.left / 2}px + 50vw);
        }

      `}</style>
    </>
  );
};

export default HudButton;

import React from 'react';
import { BsMap } from 'react-icons/bs';
import { useTranslation } from '../../../i18n';

const StoreLocationButton = ({ openLocation }) => {
  const { t } = useTranslation();
  return (
    <>
      <button className="nav-item" onClick={openLocation}>
        <span className="nav-item__icon">
          <BsMap />
        </span>
        <span className="nav-item__label">{t('vb:store_location')}</span>
      </button>
    </>
  );
};

export default StoreLocationButton;

import { ICanvasTouchState, TouchType } from '../../../interfaces/common3D';
import * as THREE from 'three';
import { SharedCanvasContext } from 'react-three-fiber';

export function CanvasTouch({
  threeRef,
  onTouchEvent
}: {
  threeRef?: SharedCanvasContext;
  onTouchEvent: (e: any, canvasTouchState: ICanvasTouchState) => void;
}) {
  let initDistance = 0;
  let isPointerDown = false;
  const mouse = new THREE.Vector2();
  const mouseMultiTouch = new THREE.Vector2();

  const eventDown = (e) => {
    touchEvent(e, TouchType.touchDown);
  };
  const eventMove = (e) => {
    touchEvent(e, TouchType.touchMove);
  };
  const eventUp = (e) => {
    touchEvent(e, TouchType.touchUp);
  };
  const eventWheel = (event) => {
    onTouchEvent(event, {
      type: TouchType.wheel,
      isPointerDown: true
    });
  };

  const touchEvent = (e, type) => {
    if (type === TouchType.touchDown) {
      isPointerDown = true;
    }
    if (type === TouchType.touchUp) {
      isPointerDown = false;
    }
    if (e?.touches?.length > 0) {
      mouse.x = (e.touches[0].pageX / threeRef?.gl?.domElement.width) * 2 - 1;
      mouse.y = -(e.touches[0].pageY / threeRef?.gl?.domElement.height) * 2 + 1;
      if (e.touches.length === 2) {
        mouseMultiTouch.x =
          (e.touches[1].pageX / threeRef?.gl?.domElement.width) * 2 - 1;
        mouseMultiTouch.y =
          -(e.touches[1].pageY / threeRef?.gl?.domElement.height) * 2 + 1;
        if (type === TouchType.touchDown) {
          initDistance = mouseMultiTouch.distanceTo(mouse);
          onTouchEvent(e, {
            type: TouchType.pinchStart,
            scale: 1,
            isPointerDown: true
          });
        } else {
          const currentDistance = mouseMultiTouch.distanceTo(mouse);
          onTouchEvent(e, {
            type: TouchType.pinchMove,
            scale: currentDistance / initDistance,
            isPointerDown: true
          });
        }
        return;
      }
    }

    onTouchEvent(e, {
      type: type,
      isPointerDown: isPointerDown
    });
  };
  this.restoreContext = (renderer) => {
    const canvas = renderer?.domElement;
    canvas?.addEventListener(
      'webglcontextlost',
      function (event) {
        event?.preventDefault();
        const timeout = setTimeout(function () {
          clearTimeout(timeout);
          try {
            renderer?.forceContextRestore();
          } catch (error) {
            console.error('webglError~~', error);
          }
        }, 1);
      },
      false
    );
  };
  this.addEventListeners = (domElement?: HTMLCanvasElement) => {
    if (!domElement) return;
    domElement?.addEventListener('pointerdown', eventDown.bind(this));
    domElement?.addEventListener('touchstart', eventDown.bind(this));
    domElement?.addEventListener('pointermove', eventMove.bind(this));
    domElement?.addEventListener('touchmove', eventMove.bind(this));
    domElement?.addEventListener('pointerup', eventUp.bind(this));
    domElement?.addEventListener('touchend', eventUp.bind(this));
    domElement?.addEventListener('wheel', eventWheel.bind(this));
  };
  this.removeEventListeners = (domElement?: HTMLCanvasElement) => {
    if (!domElement) return;
    domElement?.removeEventListener('touchstart', eventDown);
    domElement?.removeEventListener('touchmove', eventMove);
    domElement?.removeEventListener('touchend', eventUp);
    domElement?.removeEventListener('mousedown', eventDown);
    domElement?.removeEventListener('mousemove', eventMove);
    domElement?.removeEventListener('mouseup', eventUp);
    domElement?.removeEventListener('wheel', eventWheel);
  };
}

import { failedToLoadImagePlaceholder } from '../../../config';
import { IProduct } from '../../../interfaces';
import {
  getProductDisplayName,
  getProductSpecificationsByLang
} from '../../../utils/product';
import Link from '../../Common/Link';

export const HighJewelryThumbnail = ({
  product,
  onClick,
  language,
  noLink
}: {
  product: IProduct;
  onClick: (product: IProduct) => void;
  language: string;
  noLink?: boolean;
}) => {
  const specifications = getProductSpecificationsByLang(product, language);
  const thumbnail = (
    <button className={`thumbnail `} onClick={() => onClick(product)}>
      <div className="img-container">
        <img
          src={product.previewImageUrl}
          onError={(ev) =>
            ((ev.target as any).src = failedToLoadImagePlaceholder)
          }
        />
      </div>
      <div className="label-group group1">
        <p className="main-shape">{specifications['mainStoneShape']}</p>
        <p className="main-carat">{specifications['mainStoneCarat']}</p>
      </div>
      <div className="label-group group2">
        <p className="prod-title">{getProductDisplayName(product, language)}</p>
        <p className="prod-model">{product.modelCode}</p>
      </div>
      <p className="prod-carat">{specifications['carat']}</p>
      <style jsx>{`
        .main-shape,
        .main-carat {
          font-weight: 300;
          font-size: 0.8em;
          text-transform: uppercase;
          height: 18px;
          margin: 0;
        }
        .label-group {
          margin-top: 20px;
          display: block;
        }
        .group1 {
          height: 40px;
        }
        .group2 {
          height: 50px;
        }
        .prod-carat {
          font-size: 0.9em;
          margin-top: 10px;
        }
        .main-shape,
        .main-carat,
        .prod-model,
        .prod-carat {
          letter-spacing: 0.1em;
        }
      `}</style>
    </button>
  );
  if (noLink) return thumbnail;
  return (
    <Link href="/products/[id]" as={`/products/${product.id}`}>
      {thumbnail}
    </Link>
  );
};

export default HighJewelryThumbnail;

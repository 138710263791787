import { AnyAction, Dispatch } from 'redux';
import { videoBaseUrl } from '../../../config';
import { UnrealCommandAction } from '../../../interfaces';
import { actionShowFullScreenVideoWithUrl } from '../../../redux/actions';
import { getPopupAction, PageView } from '../../../utils/pageView';

export enum PSActionType {
  IGNORE = 'ignore',
  REPLY = 'reply',
  POPUP = 'popup',
  VIDEO = 'video'
}
export const getPopupUrl = (
  id: string,
  type: PageView,
  storeId: string,
  brandId: string
) => {
  const action = getPopupAction(
    `${location.protocol}//${location.host}`,
    type,
    id,
    brandId,
    storeId
  );
  return action;
};

const reply = (feedback, sendCommand) => {
  if (feedback.reply) {
    setTimeout(() => {
      sendCommand(feedback.reply);
    }, feedback.delay || 0);
  }
};
export const actionOnCommandMessage = (
  feedback: UnrealCommandAction,
  storeId: string,
  brandId: string,
  dispatch: Dispatch<AnyAction>,
  onSendCommand: (command: string) => void
) => {
  if (!feedback) return;
  switch (feedback.action) {
    case PSActionType.REPLY: {
      reply(feedback, onSendCommand);
      break;
    }
    case PSActionType.POPUP: {
      if (feedback.id) {
        const action = getPopupUrl(
          feedback.id,
          feedback.type,
          storeId,
          brandId
        );
        action && dispatch(action);
      }
      if (feedback.videoUrl) {
        const convertedUrl = `${videoBaseUrl}/${feedback.videoUrl}`;
        dispatch(
          actionShowFullScreenVideoWithUrl({
            show: true,
            url: convertedUrl
          })
        );
      }
      break;
    }
    default:
      reply(feedback, onSendCommand);
  }
};

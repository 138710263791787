import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IProduct } from '../../interfaces';
import { getProductDisplayName, getProductEdition } from '../../utils/product';
import Placeholder from '../Placeholder';
import EyeballTimeLogger from '../EyeballTimeLogger';
import Header from '../Header';
import { PageView } from '../../utils/pageView';
import { useTranslation } from '../../i18n';
import { PotmContent } from './mockData';
import { parsePlainTextToRichText } from '../../utils/string';
import { currentLanguage } from '../../utils/language';
import Link from '../Common/Link';

export interface IPickedProductProp {
  product: IProduct;
  content: PotmContent;
}

const StripedPickedProduct = ({ product, content }: IPickedProductProp) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const language = currentLanguage(i18n);
  const { imageUrls, modelCode } = product;
  const edition = getProductEdition(product, language);
  const title = content.title[language] || content.title['en'] || '';
  const description =
    content.description[language] || content.description['en'] || '';
  const pickedAppearance = content.appearance;

  return (
    <>
      <Header
        title={t('pick_of_the_month').toUpperCase()}
        shareTitle={`${t(
          'pick_of_the_month'
        ).toUpperCase()}: ${getProductDisplayName(product, language)}`}
        shareImage={product.imageUrls.length > 0 && product.imageUrls[0]}
        type={PageView.PICK_OF_THE_MONTH}
        id={product.id}
      />
      <EyeballTimeLogger
        tracker={{ view: PageView.PICK_OF_THE_MONTH, id: product.id }}
      >
        {isLoading && <Placeholder />}
        <div className="section potm-section">
          <div className="container content-container">
            <div className="potm-title mobile">
              <div className="potm-title-month-mobile">{title}</div>
              {language === 'en' && (
                <div className="potm-title-desc">Pick of the month</div>
              )}
            </div>
            <Row className="align-items-center">
              <Col className="col col-12 col-lg-4" />
              <Col className="col col-12 col-lg-4 text-center">
                <Link
                  href="/products/[id]"
                  as={`/products/${product.id}`}
                  className="product-image-link"
                  onClick={() => setIsLoading(true)}
                >
                  <img
                    className="img-fluid"
                    src={imageUrls[0]}
                    alt={getProductDisplayName(product, language)}
                  />
                </Link>
              </Col>
              <div className="content col col-12 col-lg-4">
                <div className="potm-title desktop">
                  <div className="potm-title-month">{title}</div>
                  {language === 'en' && (
                    <div className="potm-title-desc">Pick of the month</div>
                  )}
                </div>
                <div className="inner-content">
                  <div className="model-details">
                    <p className="modelcode">{modelCode}</p>
                    {edition && <p className="product-edition">{edition}</p>}
                    <h1 className="title">
                      {getProductDisplayName(product, language)}
                    </h1>
                  </div>
                  <div className="desc">
                    {parsePlainTextToRichText(description)}
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </EyeballTimeLogger>
      <style jsx>
        {`
          .section {
            background: ${pickedAppearance?.bgColor || '#000'};
            color: ${pickedAppearance?.textColor || '#fff'};
          }
          .container {
            background-image: url(${pickedAppearance?.bgImageMobile || ''});
            background-size: 100% auto;
            background-position: center top;
            background-repeat: no-repeat;
            padding-top: 70%;
            padding-bottom: 30px;
            font-family: Montserrat;
          }
          :global(.mar2021 .container.content-container) {
            padding-top: 122vw;
          }
          .potm-title {
            margin-top: 20px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              rgba(46, 46, 46, 0),
              rgba(46, 46, 46, 1)
            );
            padding: 10px 40px;
          }

          .potm-title-month-mobile {
            font-size: 1.375em;
            color: #ffffff;
            font-weight: 800;
            font-style: italic;
            text-transform: uppercase;
          }

          .potm-title-month {
            font-size: 1.375em;
            color: #ffffff;
            font-weight: 800;
            font-style: italic;
            text-transform: uppercase;
          }

          .potm-title-desc {
            font-size: 1.1em;
            color: #ffffff;
            font-weight: 400;
            font-style: italic;
            text-transform: uppercase;
          }

          .model-details {
            margin-top: 10px;
          }

          :global(.isRtl .potm-page .potm-title i) {
            font-style: normal !important;
            font-size: 1.2em;
          }
          .title-separator {
            background: #fff;
            height: 1px;
            margin: 20px 0;
            position: relative;
          }
          .title-separator :global(svg) {
            height: 20px;
            transform: translate(-50%, -50%) rotate(90deg);
            position: absolute;
          }

          .title {
            text-transform: uppercase;
            font-size: 0.75em;
            letter-spacing: 0.34px;
            line-height: normal;
            font-style: normal;
            font-weight: 500;
          }

          :global(.isRtl .potm-page .title) {
            font-size: 1.4em;
          }

          .modelcode {
            text-transform: uppercase;
            font-size: 0.8em;
            letter-spacing: 0px;
            color: #ffffff;
            font-weight: 500;
            margin-bottom: 0;
          }

          :global(.isRtl .potm-page .modelcode) {
            font-size: 0.9em;
          }
          .desc {
            font-size: 0.8em;
            line-height: 18px;
            letter-spacing: 0px;
            color: #ffffff;
          }

          .desc :global(p:first-child) {
            font-weight: 300;
            font-style: italic;
          }

          .desc :global(strong) {
            letter-spacing: 0.07px;
          }

          :global(.isRtl .desc p:first-child) {
            font-style: normal;
          }

          :global(.isRtl .potm-page .desc p:first-child) {
            font-size: 1em;
            font-family: Helvetica;
            font-weight: 200;
            line-height: 1.2;
            direction: rtl;
          }

          .content {
            text-align: center;
            padding: 0;
          }

          .inner-content {
            padding: 0 20px;
          }
          :global(cite) {
            display: block;
            font-size: 0.8em;
          }
          :global(cite + cite) {
            font-size: 0.7em;
          }

          :global(.isRtl .potm-section cite + cite) {
            font-size: 0.6em;
            direction: rtl;
          }
          .img-fluid {
            margin: auto;
            max-width: 275px;
            width: 100%;
            height: auto;
          }

          .mobile {
            position: absolute;
            top: 50px;
            left: 0;
            background-image: linear-gradient(
              to right,
              rgba(46, 46, 46, 0),
              rgba(46, 46, 46, 1),
              rgba(46, 46, 46, 0)
            );
            width: 100%;
            text-align: center;
          }

          .desktop {
            display: none;
          }

          @media (min-width: 420px) {
            .img-fluid {
              max-width: 340px;
            }
          }

          @media (min-width: 576px) {
            :global(.mar2021 .container.content-container) {
              padding-top: 680px;
            }
          }

          @media (min-width: 768px) {
            .container {
              padding-top: 60%;
            }
            :global(.mar2021 .container.content-container) {
              padding-top: 890px;
            }
            .img-fluid {
              max-width: 530px;
            }
          }
          :global(.mar2021 .img-fluid) {
            display: none;
          }

          :global(.mar2021 .product-image-link) {
            position: static;
          }

          @media (max-width: 992px) {
            :global(.mar2021 .product-image-link) {
              position: fixed;
              height: 80vw;
              top: 200px;
              width: 100%;
              left: 0;
            }
          }

          @media (min-width: 992px) {
            .content {
              text-align: right;
            }
            .img-fluid {
              width: auto;
            }
            :global(.mar2021 .img-fluid) {
              display: block;
            }
            .potm-title {
              font-size: 1em;
            }
            .potm-title {
              margin-top: 20px;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                rgba(46, 46, 46, 0),
                rgba(46, 46, 46, 1)
              );
              padding: 10px 40px;
            }

            :global(.isRtl .potm-page .potm-title i) {
              font-style: normal !important;
              font-size: 1em;
            }
            .title {
              font-size: 1.1em;
              max-width: 300px;
              font-weight: 400;
              float: right;
            }

            :global(.isRtl .title) {
              max-width: initial;
              float: initial;
            }

            .container {
              background-image: url(${pickedAppearance?.bgImage || ''});
              padding-top: 0;
              padding-bottom: 0;
              background-size: auto 100%;
            }
            :global(.mar2021 .container.content-container) {
              padding-top: 0;
            }
            .img-fluid {
              max-width: none;
              margin-top: -53px;
              margin-bottom: -48px;
              height: 550px;
              margin-left: 50%;
              transform: translateX(-50%);
            }
            .section {
              margin-top: 40px;
              margin-bottom: 20px;
            }
            .inner-content {
              padding-right: 40px;
            }
            .desktop {
              display: block;
            }
            .desc :global(p:first-child) {
              font-weight: 200;
              color: #ffffff;
              font-family: Montserrat;
              font-size: 16px;
              font-weight: 300;
              font-style: italic;
              letter-spacing: 0.06px;
              line-height: 20px;
            }
            .modelcode {
              letter-spacing: 1.5px;
              font-size: 1.1em;
              margin-bottom: 4px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: 0.07px;
              line-height: normal;
            }
            .mobile {
              display: none;
            }
          }

          @media (min-width: 1200px) {
            .potm-title {
              font-size: 1.1em;
            }
            :global(.isRtl .potm-section) {
              font-size: 20px;
            }
          }
        `}
      </style>
    </>
  );
};

export default StripedPickedProduct;

import React from 'react';
import { logEvent } from '../../../../../analytics';
import { assetBaseUrl, zIndex } from '../../../../../config';
import {
  IPlanePlan,
  PlanSpotLight
} from '../../../../../interfaces/loubiairways';
import { DID_CLICK_PLANE_PLAN_SPOT } from '../../../../../utils/constants';
import { isUserOnMobile } from '../../../../../utils/deviceDetector';
import { getViewportSize } from '../../../../../utils/window';

const asset = `${assetBaseUrl}/loubiairways/luggage-animation/plane-plan/v1`;

const getPlanPlaneTitle = (plan: PlanSpotLight) => {
  switch (plan) {
    case PlanSpotLight.NEWSPAPER:
      return 'PRESS RELEASE';
    case PlanSpotLight.COCKPIT:
      return 'COCKPIT';
    case PlanSpotLight.STYLISH_VIDEO:
      return 'SAFETY VIDEO';
    case PlanSpotLight.TROLEY:
      return 'COZY';
    case PlanSpotLight.BEAUTY_CART:
      return 'BEAUTY';
    case PlanSpotLight.WINDOW:
      return 'LOUBI IN WONDERLAND';
    case PlanSpotLight.XRAY:
      return 'STRASS HEEL';
    case PlanSpotLight.TRAY:
      return 'TRAY';
    case PlanSpotLight.IN_FLIGHT_MAGAZINE:
      return 'INFLIGHT MAGAZINE';
    case PlanSpotLight.OMG_MAGAZINE:
      return 'OMG';
    case PlanSpotLight.STYLISH_GUIDE:
      return 'URBAN TWIST';
    case PlanSpotLight.PHONE:
      return 'BEAUTY CALL';
    case PlanSpotLight.HEADSET:
      return 'SPOTIFY PLAYLIST';
    case PlanSpotLight.TARROT:
      return 'TAROT';
  }
};

const LoubiAirwaysPlan = ({
  state,
  updateState
}: {
  state: IPlanePlan;
  updateState: (state: IPlanePlan) => void;
}) => {
  const [{ vh }, setViewport] = React.useState({ vh: 0, vw: 0 });
  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    setViewport(getViewportSize());
    setIsMobile(isUserOnMobile());
  }, []);

  const spotLight = (
    spot: PlanSpotLight,
    lTop: number,
    lLeft: number,
    pTop: number,
    pLeft: number,
    preview?: {
      position?: 'left' | 'right' | 'top';
      arrow?: 'left' | 'right' | 'top' | 'bottom';
      width?: number;
    }
  ) => {
    const updateSpot = () => {
      updateState({
        selectedSpot: spot
      });
      logEvent(DID_CLICK_PLANE_PLAN_SPOT, DID_CLICK_PLANE_PLAN_SPOT, {
        spot,
        event: 'click'
      });
    };

    return (
      <>
        <button className="btn btn-spotlight" onClick={updateSpot} />
        <div
          className={`spotPreview ${
            preview?.position ? 'position-' + preview.position : ''
          } ${preview?.arrow ? 'arrow-' + preview.arrow : ''}`}
          onClick={updateSpot}
        >
          {getPlanPlaneTitle(spot)}
        </div>
        <img
          src={`${asset}/${
            state?.visitedSpot?.includes(spot)
              ? 'CL-Hotspot-Red.gif'
              : 'CL-Hotspot.gif'
          }`}
        />
        <style jsx>{`
          .btn-spotlight,
          img {
            position: fixed;
            border: none;
            background: rgba(0, 0, 0, 0.3);
            top: ${vh / 2}px;
            left: 50vw;
            border-radius: 100%;
            transform: translate(-50%, -50%);
            padding: 0;
          }
          img {
            pointer-events: none;
          }
          .btn-spotlight:hover {
            background: #000;
            box-shadow: 0 0 10px #fff;
          }

          .spotPreview {
            color: #bf080e;
            background: #f3f3d1;
            font-family: 'AntiqueOlive-Bold', Arial;
            z-index: 2;
            position: fixed;
            cursor: pointer;
            display: ${isMobile ? 'block' : 'none'};
            transform: translate(-50%, -50%);
            top: ${vh / 2}px;
            left: 50vw;
          }

          .btn-spotlight:hover + .spotPreview {
            display: block;
          }

          .btn-go {
            background: #bf080e;
            color: #f3f3d1;
            border-radius: 8px;
          }

          @media (orientation: landscape) {
            .btn-spotlight,
            img {
              width: 3vw;
              height: 3vw;
              margin-top: ${lTop}vw;
              margin-left: ${lLeft}vw;
            }
            img {
              width: calc(3vw - 4px);
              height: auto;
            }

            .spotPreview {
              margin-top: calc(${lTop}vw - 2.5vw);
              margin-left: ${lLeft}vw;
              font-size: 0.9vw;
              padding: 0.25vw 0.5vw;
              border-radius: 0.5vw;
            }
            .line {
              display: none;
            }
          }

          @media (orientation: portrait) {
            .btn-spotlight,
            img {
              width: ${vh * 0.04}px;
              height: ${vh * 0.04}px;
              margin-top: ${pTop * 0.01 * vh}px;
              margin-left: ${pLeft * 0.01 * vh}px;
            }
            img {
              height: auto;
              width: ${vh * 0.04 - 2}px;
            }

            .spotPreview {
              margin-top: ${pTop * 0.01 * vh}px;
              font-size: 1.4vh;
              line-height: 1.6vh;
              padding: 0.25vh 0.5vh;
              border-radius: 0.5vh;
              right: 0;
              left: auto;
              transform: translate(0, -50%);
              display:block !important;
              width: ${preview?.width
                ? `${preview.width * 0.01 * vh}px`
                : 'auto'};
            }

            .spotPreview.position-top {
              margin-top: ${pTop * 0.01 * vh - 0.05 * vh}px;
              transform: translate(-50%, -50%);
              left: 50vw;
              right: auto;
            }
            .spotPreview.position-left {
              right: 50vw;
              margin-right: ${0.1 * vh}px;
              text-align: right;
              padding-left: 1.4vh;
              background:#f3f3d1 url('${asset}/arrow-left.png') left center no-repeat;
              background-size: ${0.01 * vh}px auto;
            }

            .spotPreview.position-right {
              left: 50vw;
              right: auto;
              margin-left: ${0.1 * vh}px;
              text-align: left;
              padding-right: 1.4vh;
              background:#f3f3d1 url('${asset}/arrow-right.png') right center no-repeat;
              background-size:${0.01 * vh}px auto;
            }

            .spotPreview.arrow-top {
              padding-top: 1.1vh;
              background:#f3f3d1 url('${asset}/arrow-top.png') center top no-repeat;
              background-size:${0.01 * vh}px auto;
            }

            .spotPreview.arrow-bottom {
              padding-bottom: 1.1vh;
              background:#f3f3d1 url('${asset}/arrow-bottom.png') center bottom no-repeat;
              background-size:${0.01 * vh}px auto;
            }

            .btn-go {
              display: block;
              width: 100%;
              max-width: 80px;
              margin: 10px auto 0;
            }

          
          }
        `}</style>
      </>
    );
  };
  return (
    <div className={`PlanePlan fixed-full ${!state?.open ? 'closing' : ''}`}>
      <div className="backdrop fixed-full" />
      <div className="plan-container fixed-full">
        <img
          src={`${asset}/plane-landscape.png`}
          alt="Plane landscape"
          className="plane plane-landscape"
        />
        <img
          src={`${asset}/plane-portrait_jp.png`}
          alt="Plane portrait"
          className="plane plane-portrait"
        />
        <img
          src={`${asset}/route-landscape.gif`}
          alt="route landscape"
          className="route route-landscape"
        />
        <img
          src={`${asset}/route-portrait.gif`}
          alt="route portrait"
          className="route route-portrait"
        />
      </div>
      <div className="spot-container fixed-full">
        {spotLight(PlanSpotLight.NEWSPAPER, 4.5, -25.5, -25, -4.5, {
          position: 'left',
          width: 10
        })}
        {spotLight(PlanSpotLight.COCKPIT, 0, -25.5, -25, 0, {
          position: 'top',
          arrow: 'top'
        })}
        {spotLight(PlanSpotLight.STYLISH_VIDEO, -4.5, -25.5, -25, 4.5, {
          position: 'right',
          width: 9
        })}
        {spotLight(PlanSpotLight.TROLEY, 5, -4.2, -4.2, -5, {
          position: 'left'
        })}
        {spotLight(PlanSpotLight.BEAUTY_CART, -5, -4.2, -4.2, 5, {
          position: 'right'
        })}
        {spotLight(PlanSpotLight.WINDOW, -7, -10, -11.2, 7, {
          position: 'right',
          width: 13
        })}
        {spotLight(PlanSpotLight.XRAY, 3, 4, 3.5, -2.7, {
          position: 'left',
          width: 9
        })}
        {spotLight(PlanSpotLight.TRAY, 5.7, 7, 7, -5.7, { position: 'left' })}
        {spotLight(PlanSpotLight.IN_FLIGHT_MAGAZINE, -3.4, 12, 12, 3.7, {
          position: 'right',
          width: 11
        })}
        {spotLight(PlanSpotLight.OMG_MAGAZINE, -7, 18, 18, 7, {
          position: 'right'
        })}
        {spotLight(PlanSpotLight.STYLISH_GUIDE, -7, 23.2, 23, 7, {
          position: 'right',
          width: 9
        })}
        {spotLight(PlanSpotLight.PHONE, 7, 22.5, 22.5, -7, {
          position: 'left',
          width: 9
        })}
        {spotLight(PlanSpotLight.HEADSET, -3.8, 33.8, 33.7, 3.5, {
          position: 'right',
          width: 10
        })}
        {spotLight(PlanSpotLight.TARROT, 0, 43.6, 43.6, 0, {
          position: 'top',
          arrow: 'bottom'
        })}
      </div>

      <style jsx>{`
        :global(.fixed-full){
          left:0  !important;
        }
        .backdrop {
          background: black;
          opacity: 0.7;
          z-index: 0;
          animation: fade-in 0.5s linear both;
        }
        .PlanePlan{
          z-index:${zIndex.meetingControls + 1};
        }
        .PlanePlan.closing {
          animation: fade-out 0.5s linear both;
        }
        .plan-container img {
          position: absolute;
          z-index: 1;
          display: none;
        }
        .spot-container {
          animation: spot-fade-in 0.5s linear both;
        }

        @keyframes spot-fade-in {
          0%,
          99% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 0.7;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 0.7;
          }
          100% {
            opacity: 0;
          }
        }

        @media (orientation: landscape) {
          .plane-landscape,
          .route-landscape {
            display: block !important;
            width: 100vw;
            top: ${vh / 2}px;
            left: 0;
            transform: translateY(-50%);
          }
          .plane-landscape {
            animation: plane-slide-left 0.5s ease-out both;
          }
          .route-landscape {
            margin-left: 24.7vw;
            width: 74.7vw;
            animation: route-slide-left 0.5s ease-out both;
            opacity: 0;
          }
          @keyframes plane-slide-left {
            0% {
              left: 100vw;
            }
            100% {
              left: 0;
            }
          }
          @keyframes route-slide-left {
            0%,
            70% {
              left: 100vw;
              opacity: 0;
            }

            100% {
              left: 0;
              opacity: 1;
            }
          }
        }

        @media (orientation: portrait) {
          .plane-portrait,
          .route-portrait {
            display: block !important;
            height: ${vh}px;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
          }
          .plane-portrait {
            animation: plane-slide-top 0.5s ease-out both;
          }
          .route-portrait {
            margin-top: ${0.247 * vh}px;
            height: ${0.747 * vh}px;
            animation: route-slide-top 0.5s ease-out both;
            opacity: 0;
          }
          @keyframes plane-slide-top {
            0% {
              top: ${vh}px;
            }
            100% {
              top: 0;
            }
          }
          @keyframes route-slide-top {
            0%,
            70% {
              top: ${vh}px;
              opacity: 0;
            }

            100% {
              top: 0;
              opacity: 1;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default LoubiAirwaysPlan;

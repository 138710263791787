import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { useTranslation } from '../../../../i18n';
import {
  LoubiAirwaysExitState,
  LoubiAirwaysFullScreenVideo
} from '../../../../interfaces/loubiairways';
import { actionMuteBgSound } from '../../../../redux/actions';
import {
  actionUpdateLoubiAirwaysExitState,
  actionUpdateLoubiAirwaysFullScreenVideo
} from '../../../../redux/customActions/loubiAirways';
import {
  DID_CLICK_CONTINUE_FLIGHT,
  DID_CLICK_EXIT_BUTTON,
  DID_CLICK_LEAVE_PLANE
} from '../../../../utils/constants';
import { useLoubiAirwaysState } from '../../../hooks/loubiAirways';
import LoubiButtonWrapper from './Buttons/LoubiButtonWrapper';
import LoubiAirwaysMiniPopupWrapper from './LoubiAirwaysMiniPopupWrapper';

const LoubiAirwaysExit = ({ imageUrl }: { imageUrl?: string }) => {
  const dispatch = useDispatch();
  const { exitState, showSpotifyPlayer } = useLoubiAirwaysState();
  const { t } = useTranslation();
  if (exitState === LoubiAirwaysExitState.SHOW_POPUP) {
    return (
      <LoubiAirwaysMiniPopupWrapper
        title={t('vb:confirm_leave_plane')}
        onClose={() => {
          dispatch(actionUpdateLoubiAirwaysExitState(undefined));
        }}
      >
        <>
          <button
            className="blue-button"
            onClick={() => {
              dispatch(actionUpdateLoubiAirwaysExitState(undefined));
              logEvent(DID_CLICK_CONTINUE_FLIGHT);
            }}
          >
            {t('vb:no_continue_flying')}
          </button>
          <button
            onClick={() => {
              dispatch(actionMuteBgSound());
              dispatch(actionUpdateLoubiAirwaysExitState(undefined));
              dispatch(
                actionUpdateLoubiAirwaysFullScreenVideo(
                  LoubiAirwaysFullScreenVideo.EXIT
                )
              );
              logEvent(DID_CLICK_LEAVE_PLANE);
            }}
          >
            {t('vb:yes_exit_experience')}
          </button>
          <style jsx>
            {`
              button {
                background: #cf152d;
                color: #f3f3d1;
                font-family: 'AntiqueOlive-Bold', Arial;
                font-size: 1.2em;
                font-style: italic;
                padding: 20px;
                margin: 10px 0;
                width: 100%;
                border-radius: 20px;
              }
              .blue-button {
                background: #73b4c8;
              }
            `}
          </style>
        </>
      </LoubiAirwaysMiniPopupWrapper>
    );
  }
  return (
    <>
      <LoubiButtonWrapper>
        <img
          src={imageUrl || '/asset/louboutin/exit_white.png'}
          alt="exit"
          className={`loubi-circle-button bottom-right button-exit hide-for-client-meeting ${
            showSpotifyPlayer ? 'spotify' : ''
          }`}
          onClick={() => {
            dispatch(
              actionUpdateLoubiAirwaysExitState(
                LoubiAirwaysExitState.SHOW_POPUP
              )
            );
            logEvent(DID_CLICK_EXIT_BUTTON);
          }}
        />
      </LoubiButtonWrapper>
    </>
  );
};

export default LoubiAirwaysExit;

import React from 'react';
import { logEvent } from '../../../analytics/index';
import { DID_CLICK_BADGE } from '../../../utils/constants';

const JLCWarrantyBadge = () => {
  const badgeUrl =
    'https://www.jaeger-lecoultre.com/au/en/customer-services/care-program.html';
  return (
    <a
      href={badgeUrl}
      target="_blank"
      className="JLCWarrantyBadge"
      onClick={() =>
        logEvent(DID_CLICK_BADGE, DID_CLICK_BADGE, {
          badgeUrl
        })
      }
    >
      <img
        src="https://panos.inspify.io/virtual_boutique/0bc6b25d-84a0-405d-bc46-1e7e816ba5c1/JLC_care_program.png"
        alt="Care Program"
      />
      <style jsx>{`
        img {
          width: 100%;
        }
        .JLCWarrantyBadge {
          width: 70px;
          margin: auto;
          bottom: 0;
          display: block;
        }
        @media (min-width: 768px) {
          .JLCWarrantyBadge {
            width: 80px;
            margin-top: 20px;
          }
        }
      `}</style>
    </a>
  );
};

export default JLCWarrantyBadge;

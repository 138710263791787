import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import { useTranslation } from '../../../../../i18n';
import { IProduct } from '../../../../../interfaces';
import { actionDidSelectProduct } from '../../../../../redux/actions';
import { DID_CLICK_SUBMIT_RESERVATION_BUTTON } from '../../../../../utils/constants';
import { getCartTotalPrice } from '../../../../../utils/shoppingCart';
import { useCartItemState } from '../../../../hooks/cart';
import LoubiAirwaysPageContainer from '../../LouboutinCustomComponent/LoubiAirwaysPageContainer';
import { getLoubiAirwaysProductImage } from '../TwoDProduct/LJTwoDProductsList';
import LJReservationEmptyPlaceholder from './LJReservationEmptyPlaceholder';

const LJReservedLikedProductList = ({
  open,
  reservedProducts,
  likedProducts,
  reservedProductControls,
  likedProductControls,
  onClickSubmit,
  onClose
}: {
  open: boolean;
  reservedProducts: IProduct[];
  likedProducts: IProduct[];
  onClickSubmit?: () => void;
  reservedProductControls?: (product: IProduct) => JSX.Element;
  likedProductControls?: (product: IProduct) => JSX.Element;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const cartItems = useCartItemState();
  React.useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [open]);
  const { t } = useTranslation();

  if (!show) return null;

  const headerElement = (
    <>
      <img
        src={`${jpIconUrlBase}/back_with_cross_white.svg`}
        alt=""
        className="extra-logo"
        onClick={onClose}
      />
      <style jsx>{`
        img {
          position: absolute;
          height: 96px;
          top: 50%;
          transform: translateY(-50%);
          right: 43px;
          cursor: pointer;
        }
        @media (max-width: 1200px) {
          img {
            height: 80px;
          }
        }
        @media (max-width: 768px) {
          img {
            height: 60px;
            right: 30px;
          }
        }
        @media (max-width: 550px) {
          img {
            height: 50px;
            right: 20px;
          }
        }
      `}</style>
    </>
  );

  const footerElement = reservedProducts?.length ? (
    <button
      className="btn btn-lg btn-submit-reservation"
      onClick={() => {
        onClickSubmit();
        logEvent(
          DID_CLICK_SUBMIT_RESERVATION_BUTTON,
          DID_CLICK_SUBMIT_RESERVATION_BUTTON
        );
      }}
    >
      {t('vb:submit_reservation_request')}
      <style jsx global>{`
        .btn-submit-reservation + img {
          display: none !important;
        }
        .LJProductList .footer {
          text-align: center !important;
        }
      `}</style>
      <style jsx>{`
        .btn-submit-reservation {
          font-family: 'AntiqueOlive-Bold', Arial;
          color: #bf080e;
          background: #fff;
          border-radius: 20px;
        }
      `}</style>
    </button>
  ) : null;

  return (
    <div className={`LJProductList fixed-full ${!open ? 'closing' : ''}`}>
      <div className="content">
        <LoubiAirwaysPageContainer
          className="LJProductListContainer"
          headerComponent={headerElement}
          footerComponent={footerElement}
        >
          {!!reservedProducts?.length && (
            <>
              <h1>{t('vb:reservation')}</h1>
              <div className="product-list">
                {reservedProducts.map((product) => (
                  <div className="product-box" key={product?.id}>
                    <div className="product-thumbnail">
                      <img
                        src={getLoubiAirwaysProductImage(product)}
                        onClick={() => {
                          dispatch(actionDidSelectProduct(product.id));
                        }}
                      />
                    </div>
                    <h4 className="product-name">{product?.modelName}</h4>
                    {reservedProductControls(product)}
                  </div>
                ))}
              </div>
              <p className="total">
                <strong>
                  {t('vb:total').toUpperCase()}:{' '}
                  {getCartTotalPrice(cartItems, 'JP').formattedTotal}
                </strong>
                <span>(incl. tax)</span>
              </p>
            </>
          )}
          {!!likedProducts?.length && !!reservedProducts?.length && (
            <div className="line" />
          )}
          {!!likedProducts?.length && (
            <>
              <h1>{t('vb:liked')}</h1>
              <div className="product-list">
                {likedProducts.map((product) => (
                  <div className="product-box" key={product?.id}>
                    <div className="product-thumbnail">
                      <img
                        src={getLoubiAirwaysProductImage(product)}
                        onClick={() => {
                          dispatch(actionDidSelectProduct(product.id));
                        }}
                      />
                    </div>
                    <h4 className="product-name">{product?.modelName}</h4>
                    {likedProductControls(product)}
                  </div>
                ))}
              </div>
            </>
          )}

          {!reservedProducts?.length && !likedProducts?.length && (
            <LJReservationEmptyPlaceholder onClose={onClose} />
          )}
        </LoubiAirwaysPageContainer>
      </div>

      <style jsx global>{`
        .LJProductList {
          z-index: 2;
        }
        .LJProductList .LJProductListContainer {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 30px;
        }
        .LJProductList .LJProductListContainer .container {
          background-color: #f3f3d1;
          border-radius: 50px;
        }
        @media (max-width: 1200px) {
          .LJProductList .LJProductListContainer .container {
            border-radius: 5vw;
            padding: 20px;
          }
        }
        @media (max-width: 768px) {
          .LJProductList .LJProductListContainer {
            padding: 0;
          }
          .LJProductList .LJProductListContainer .container {
            border-radius: 5vw;
            padding: 10px;
          }
        }

        .LJProductListContainer .footer {
          text-align: left;
          min-height: 83px;
        }
        .LJProductListContainer .content {
          text-align: center;
        }

        .LJProductListContainer .sole {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: 1200px) {
          .LJProductListContainer .footer {
            padding: 20px;
          }
        }

        @media (max-width: 980px) {
          .LJProductListContainer .footer {
            text-align: center;
            padding-bottom: 20px;
          }
          .LJProductListContainer .sole {
            position: relative;
            display: block;
            left: auto;
            top: auto;
            transform: none;
            margin: 10px auto 0;
          }
        }
        @media (max-width: 550px) {
          .LJProductListContainer .logo {
            max-width: 158px;
          }
        }
        @media (max-width: 450px) {
          .LJProductListContainer .header {
            text-align: left;
          }
        }
      `}</style>
      <style jsx>{`
        .LJProductList {
          animation: fade-in 0.5s ease-in both;
        }
        .LJProductList.closing {
          animation: fade-out 0.5s ease-out both;
        }
        .LJProductList :global(.twod-product) {
          z-index: 3;
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .line {
          border-top: 1px dashed #bf080e;
          width: 100%;
          display: block;
          margin: 20px 0 40px;
        }
        h1 {
          color: #fff;
          background: #bf080e;
          display: inline-block;
          border-radius: 10px;
          font-size: 2em;
          font-family: 'AntiqueOlive-Bold', Arial;
          margin-bottom: 2em;
          padding: 5px 20px;
        }

        .total {
          color: #bf080e;
          text-align: right;
          margin-top: 20px;
          padding-bottom: 30px;
        }

        .total strong {
          font-family: 'AntiqueOlive-Bold', Arial;
          font-size: 1em;
        }
        .total span {
          display: block;
          font-family: 'AntiqueOlive', Arial;
          font-size: 0.8em;
        }
        .product-list {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          padding: 0 40px;
          justify-content: space-between;
        }
        .product-box {
          position: relative;
          flex-basis: 27%;
          margin: 0 auto 50px;
          text-align: center;
        }
        .product-thumbnail {
          border-radius: 40px;
          background-color: #f3f3d1;
          box-shadow: 8px 8px #4b9fbb;
          text-align: center;
          margin-bottom: 20px;
          overflow: hidden;
        }
        .product-name {
          font-family: 'AntiqueOlive-Bold', Arial;
          top: 0;
          font-style: italic;
          color: #326167;
          letter-spacing: 0;
          line-height: 1.2;
          font-size: 1.2em;
        }

        .product-thumbnail img {
          width: 100%;
          cursor: pointer;
        }
        .btn-lg {
          background: #cf152d;
          color: #fff;
          border-radius: 15px;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-style: italic;
        }

        .back-button {
          position: absolute;
          height: 70px;
          z-index: 100;
          cursor: pointer;
          bottom: 27px;
          left: 28px;
          animation: pulsate-fwd 0.5s ease-in-out infinite both;
        }

        @-webkit-keyframes pulsate-fwd {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
        @keyframes pulsate-fwd {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }

        @media (max-width: 1200px) {
          .product-thumbnail {
            border-radius: 3.5vw;
          }
        }

        @media (max-width: 767px) {
          .back-button {
            bottom: 15px;
            left: 15px;
            right: auto;
          }
          .product-list {
            padding: 0;
          }
          .product-box {
            flex-basis: 40%;
            margin-bottom: 20px;
          }
          .product-name {
            font-size: 0.8em;
          }
          .total {
            text-align: center;
          }
        }
        @media (max-width: 450px) {
          .product-box {
            flex-basis: 100%;
          }
          .btn-lg {
            font-size: 0.8em;
          }
        }
      `}</style>
    </div>
  );
};

export default LJReservedLikedProductList;

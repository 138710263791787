import { assetBaseUrl } from '../../../../../config';
import { AnimationProductModel } from '../TwoDProduct/LJTwoDProductsList';
import TwoDProductHotSpot from './../TwoDProduct/LJTwoDProductHotSpot';

export const inflightAsset = `${assetBaseUrl}/loubiairways/luggage-animation/inflight-magazine/v7`;

const hotSpot = (model: string) => (
  <TwoDProductHotSpot model={model} width={80} height={80} type="overlay" />
);

const Page = ({ image, model }: { image: string; model?: string }) => (
  <div className="page">
    <img src={`${inflightAsset}/${image}`} alt={model} />
    {model && hotSpot(model)}
  </div>
);

const Page1 = () => <Page image="1.jpg" />;
const Page2 = () => (
  <Page image="2.jpg" model={AnimationProductModel.CLLOUBIAIRWAYS047} />
);
const Page3 = () => <Page image="3.jpg" />;
const Page4 = () => <Page image="4.jpg" />;
const Page5 = () => (
  <Page image="5.jpg" model={AnimationProductModel.CLLOUBIAIRWAYS045} />
);
const Page6 = () => <Page image="6.jpg" />;
const Page7 = () => (
  <Page image="7.jpg" model={AnimationProductModel.CLLOUBIAIRWAYS049} />
);

const Page8 = () => (
  <Page image="8_jp.jpg" model={AnimationProductModel._3205187BK01} />
);

const Page9 = () => <Page image="9_jp.jpg" />;

// const Page10 = () => (
//   <Page image="12.jpg" model={AnimationProductModel.CLLOUBIAIRWAYS044} />
// );
// const Page11 = () => <Page image="13.jpg" />;

const Page12 = () => <Page image="12_jp.jpg" />;

const Page13 = () => <Page image="13_jp.jpg" model={AnimationProductModel._3205349F507} />;
const Page14 = () => <Page image="back.jpg" />;

export const inFlightMagazinePages = [
  <Page1 />,
  <Page2 />,
  <Page3 />,
  <Page4 />,
  <Page5 />,
  <Page6 />,
  <Page7 />,
  <Page8 />,
  <Page9 />,
  <Page12 />,
  <Page13 />,
  <Page14 />
];

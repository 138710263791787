import React from 'react';
import { useDispatch } from 'react-redux';
import { JitsiDevice } from '../../../interfaces';
import Cameras from './Cameras';
import DeviceDropdown from './DeviceDropdown';
import { IconCameraOn } from './../MeetingControls/MeetingIcons';
import { actionChangeInputDeviceAsync } from '../../../redux/asyncActions';

const CameraControl = ({
  devices,
  activeDevice,
  allowChange,
  controlSelectorPrefix
}: {
  devices: JitsiDevice[];
  activeDevice: string;
  allowChange: boolean;
  controlSelectorPrefix?: string;
}) => {
  const dispatch = useDispatch();
  const activeLabel = devices.find((d) => d.deviceId === activeDevice);
  const [show, setShow] = React.useState(false);
  const id = controlSelectorPrefix
    ? `${controlSelectorPrefix}-CameraControl`
    : `CameraControl`;
  return (
    <DeviceDropdown
      label={activeLabel?.label || 'Camera'}
      icon={<IconCameraOn fill="#000" />}
      onToggle={setShow}
      show={show}
      showArrow={devices?.length > 1}
      id={id}
    >
      {show && (
        <Cameras
          devices={devices}
          onSelect={(deviceId: string) => {
            dispatch(actionChangeInputDeviceAsync(deviceId, 'video'));
            setShow(false);
          }}
          activeDevice={activeDevice}
          allowChange={allowChange}
        />
      )}
    </DeviceDropdown>
  );
};

export default CameraControl;

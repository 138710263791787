import React from 'react';
import * as THREE from 'three';
import { assetBaseUrl } from '../../../config';
import {
  IAnimationProduct,
  ILoubiAirwaysTarotCardAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import {
  isUserOnMobileOnly,
  isUserOnMobile
} from '../../../utils/deviceDetector';
import LJBackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/Buttons/LJBackToAnimationButton';
import {
  cardTopImage,
  modelWorkshopEnvironmentImagePath,
  tarotCardImage,
  tarotProducts,
  tarotTextures
} from './assets';
let tarotCard = undefined;
let backDiv;
const isMobile = isUserOnMobileOnly();
const isMobileOrTab = isUserOnMobile();
const touchType = {
  touchDown: 'touchDown',
  touchMove: 'touchMove',
  touchUp: 'touchUp'
};
let GLTFLoader, RGBELoader, TWEEN, ObjectControls;
interface Props {
  remoteState?: ILoubiAirwaysTarotCardAnimationState;
  updateRemoteState: (state: ILoubiAirwaysTarotCardAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: () => void;
  viewOnly?: boolean;
}
function TextureSingle(
  textureB,
  tilesHoriz,
  tilesVert,
  numTiles,
  tileDispDuration,
  check
) {
  this.tilesHorizontal = tilesHoriz;
  this.tilesVertical = tilesVert;
  this.numberOfTiles = numTiles;
  this.checkk = check;
  textureB.wrapS = textureB.wrapT = THREE.RepeatWrapping;
  textureB.repeat.set(1 / this.tilesHorizontal, 1 / this.tilesVertical);
  this.tileDisplayDuration = tileDispDuration;
  this.currentDisplayTime = 0;
  this.currentTile = -1;
  this.update = function (milliSec) {
    this.currentDisplayTime += milliSec;
    while (this.currentDisplayTime > this.tileDisplayDuration) {
      this.currentDisplayTime -= this.tileDisplayDuration;
      this.currentTile++;
      if (
        this.currentTile == this.numberOfTiles ||
        this.currentTile >= this.checkk
      ) {
        this.currentTile = 0;
      }
      const newone = this.numberOfTiles - this.currentTile - 1;
      const currentColumn = this.currentTile % this.tilesHorizontal;
      textureB.offset.x = currentColumn / this.tilesHorizontal;
      const currentRow = Math.floor(newone / this.tilesHorizontal);
      textureB.offset.y = currentRow / this.tilesVertical;
    }
  };
  this.reset = function (tile) {
    this.currentTile = tile || 0;
    const newone = this.numberOfTiles - this.currentTile - 1;
    const currentColumn = this.currentTile % this.tilesHorizontal;
    textureB.offset.x = currentColumn / this.tilesHorizontal;
    const currentRow = Math.floor(newone / this.tilesHorizontal);
    textureB.offset.y = currentRow / this.tilesVertical;
  };
}
class TarotCard extends React.Component<Props> {
  isDown: boolean;
  isTweening: boolean;
  isMouseDown: boolean;
  animationOnFocus: boolean;

  delta: number;
  ZoomIn: number;
  ZoomOut: number;
  maxScale: number;
  minScale: number;
  incScale: number;
  initScale: number;
  selectedItem: number;
  initDistance: number;
  loadingCount: number;

  mouse: THREE.Vector2;
  touchPointer: THREE.Vector2;
  mouseDown: THREE.Vector2;
  mouseDownPos: THREE.Vector2;

  rayCasterScale: THREE.Vector3;
  fucusObjectPosition: THREE.Vector3;

  controls = undefined;
  zoomModel = undefined;
  focusedModel = undefined;

  textureTop: any;
  elisaTextureMap: any;
  irizaTextureMap: any;
  noLimitTextureMap: any;
  transparent_Plane: any;
  sandaleTextureMap: any;
  caracabaTextureMap: any;
  elisaTopTextureMap: any;
  elisaBlueTextureMap: any;
  elisaTop2TextureMap: any;
  officialitoTextureMap: any;
  irizaTextureNormalMap: any;
  elisaTextureNormalMap: any;
  noLimitTextureNormalMap: any;
  sandaleTextureNormalMap: any;
  elisaTopTextureNormalMap: any;
  caracabaTextureNormalMap: any;
  elisaTop2TextureNormalMap: any;
  elisaBlueTextureNormalMap: any;
  officialitoTextureNormalMap: any;

  itemsArray: any[];
  itemsChildForCatchingClick: any[];
  itemsChildForCatchingClick2: any[];

  scene: THREE.Scene;
  clock: THREE.Clock;
  raycaster: THREE.Raycaster;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  transparent_Mat: THREE.MeshBasicMaterial;

  constructor(props) {
    super(props);
    tarotCard = this;

    this.isDown = false;
    this.isTweening = false;
    this.isMouseDown = false;
    this.animationOnFocus = false;

    const SCL = 4;
    this.delta = 0;
    this.ZoomOut = 4;
    this.ZoomIn = 1.5;
    this.initScale = 1;
    this.initDistance = 0;
    this.loadingCount = 0;
    this.selectedItem = -1;
    this.maxScale = 1.5 * SCL;
    this.minScale = 0.8 * SCL;
    this.incScale = 0.1 * SCL;

    this.controls = undefined;
    this.zoomModel = undefined;
    this.focusedModel = undefined;
    this.transparent_Plane = undefined;

    this.mouse = new THREE.Vector2();
    this.touchPointer = new THREE.Vector2();
    this.mouseDown = new THREE.Vector2();
    this.mouseDownPos = new THREE.Vector2();

    this.fucusObjectPosition = new THREE.Vector3(0, 0, 0);
    this.rayCasterScale = new THREE.Vector3(0.28, 0.001, 0.54);

    this.itemsArray = Array(tarotCardImage.length);
    this.itemsChildForCatchingClick = Array(tarotCardImage.length);
    this.itemsChildForCatchingClick2 = Array(tarotCardImage.length);

    this.scene = new THREE.Scene();
    this.clock = new THREE.Clock();
    this.raycaster = new THREE.Raycaster();
    this.transparent_Mat = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.01,
      color: 0x000000
    });
  }
  loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };

  componentDidMount() {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      30
    );
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.camera.position.set(0, 0, 10);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    this.loadingCount = 0;
    if (isMobileOrTab) {
      document.addEventListener('gesturestart', this.zoomDisable);
      document.addEventListener('gesturechange', this.zoomDisable);
      document.addEventListener('gestureend', this.zoomDisable);
    }
    this.loadThreeJsModulesAsync().then(() => {
      tarotCard.init();
    });
  }
  componentWillUnmount() {
    this.cleanUp();
  }
  componentDidUpdate(_prevProps, _prevState, _snapshot) {
    if (this.props?.remoteState?.flag)
      this.remoteObjectUpdate(this.props?.remoteState);
  }
  loadingManager = new THREE.LoadingManager(() => {
    this.callback();
  });
  loadZoom = (id) => {
    if (isMobileOrTab) return;
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    gltfLoader.load(tarotProducts[ItemId].highPolyModelUrl, (gltf) => {
      if (!this.scene) return;
      const group = new THREE.Group();
      const root = gltf.scene;
      this.setScalePivot(root, ItemId);
      group.add(root);
      this.scene.add(group);
      group.scale.set(0.05, 0.05, 0.05);
      if (this.zoomModel) {
        this.loadNew(false, 0);
      }
      this.zoomModel = group;
      this.zoomModel.ItemId = ItemId;
      if (!this.focusedModel || this.focusedModel?.ItemId != ItemId) {
        this.loadNew(false, 0);
      }
    });
  };
  loadNew = (isLoad, id) => {
    if (isLoad) {
      if (this.zoomModel === undefined) this.loadZoom(id);
    } else {
      if (this.zoomModel === undefined) return;
      this.zoomModel.traverse((object) => {
        if (!object.isMesh && !this.scene) return;
        this.scene.remove(object);
        if (object?.geometry) {
          object.geometry.dispose();
          if (object?.material?.isMaterial) {
            this.cleanMaterial(object.material);
          } else {
            // an array of materials
            for (const material of object.material)
              this.cleanMaterial(material);
          }
        }
        object.geometry = undefined;
        object = undefined;
      });
      this.scene.remove(this.zoomModel);
      this.zoomModel = undefined;
    }
  };

  updateObjectControlForModel = (model) => {
    if (
      this.props.viewOnly ||
      model === undefined ||
      this.camera === undefined ||
      this.renderer === undefined
    )
      return;
    if (!this.controls) {
      this.controls = new ObjectControls(
        this.camera,
        this.renderer.domElement,
        model
      );
    } else {
      this.controls.setObjectToMove(model);
    }
    this.controls.setDistance(2, 400); // set min - max distance for zoom
    this.controls.setZoomSpeed(0.5); // set zoom speed
    this.controls.enableVerticalRotation();
    this.controls.enableHorizontalRotation();
    this.controls.disableZoom();
    this.controls.setRotationSpeed(0.1);
  };
  updateRemoteObjectControlForModel = () => {
    if (!this.focusedModel) return;
    this.props.updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      focusItem: this.selectedItem,
      position: [
        this.focusedModel.position.x,
        this.focusedModel.position.y,
        this.focusedModel.position.z
      ],
      scale: [
        this.focusedModel.scale.x,
        this.focusedModel.scale.y,
        this.focusedModel.scale.z
      ],
      rotation: [
        this.focusedModel.rotation.x,
        this.focusedModel.rotation.y,
        this.focusedModel.rotation.z
      ]
    });
  };
  resetPositionForword = (model, _scale) => {
    if (!model) return;
    this.isTweening = true;
    this.focusedModel = model;
    this.transparent_Plane.visible = true;
    this.transparent_Mat.opacity = 0.01;
    this.animationOnFocus = false;
    new TWEEN.Tween(this.transparent_Mat)
      .to({ opacity: 0.7 }, 2000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(model.position)
      .to(
        {
          x: 0,
          y: -0.1,
          z: this.fucusObjectPosition.z + 5
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        this.resetZoomForword();
      })
      .start();

    new TWEEN.Tween(model.rotation)
      .to({ x: -Math.PI * 0.5, y: 0, z: 0 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  };
  resetZoomForword = () => {
    if (!this.focusedModel) return;
    new TWEEN.Tween(this.focusedModel.position)
      .to(
        {
          x: 0,
          y: -0.1,
          z: this.fucusObjectPosition.z + 6
        },
        2000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        this.resetRotForword();
      })
      .start();
  };
  resetRotForword = () => {
    if (!this.focusedModel?.getObjectByName('root')) return;
    this.animationOnFocus = true;
    this.focusedModel.getObjectByName('root').visible = true;
    new TWEEN.Tween(this.focusedModel.rotation)
      .to(
        {
          x: Math.PI * 0.05,
          y: -Math.PI * 0.5,
          z: 0
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();

    new TWEEN.Tween(this.focusedModel.position)
      .to(
        {
          x: 0,
          y: -0.5,
          z: this.fucusObjectPosition.z + 6
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        this.updateObjectControlForModel(this.focusedModel);
        this.isTweening = false;
      })
      .start();
  };
  ComeGoBack = () => {
    if (!this.focusedModel?.getObjectByName('root')) return;
    this.loadNew(false, this.selectedItem);
    this.isTweening = true;
    const model = this.focusedModel;
    this.props.didSelectModel(undefined);
    new TWEEN.Tween(model.position)
      .to(
        {
          x: model.startPostion.x,
          y: model.startPostion.y,
          z: model.startPostion.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.isTweening = false;
        if (model.getObjectByName('root'))
          model.getObjectByName('root').visible = false;
      })
      .start();
    new TWEEN.Tween(model.rotation)
      .to(
        {
          x: model.startRotation.x,
          y: model.startRotation.y,
          z: model.startRotation.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.In)
      .start();
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: this.ZoomOut,
          y: this.ZoomOut,
          z: this.ZoomOut
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(this.transparent_Mat)
      .to({ opacity: 0.01 }, 900)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.transparent_Plane.visible = false;
      })
      .start();
  };
  createRayCasterCatcher = () =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(this.rayCasterScale.x, 0.2, 0.3),
      new THREE.MeshPhongMaterial({})
    );
  createCardRayCastCatcher = (id) => {
    const texture = new THREE.TextureLoader(this.loadingManager).load(
      tarotCardImage[id].url
    );
    texture.encoding = THREE.sRGBEncoding;
    const tiles = new TextureSingle(
      texture,
      tarotCardImage[id].row,
      tarotCardImage[id].col,
      tarotCardImage[id].row * tarotCardImage[id].col,
      200,
      tarotCardImage[id].frames
    );
    const materialArray = [];
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    materialArray.push(new THREE.MeshBasicMaterial({ map: this.textureTop }));
    materialArray.push(new THREE.MeshBasicMaterial({ map: texture }));
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    const mesh = new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        this.rayCasterScale.x,
        this.rayCasterScale.y,
        this.rayCasterScale.z
      ),
      materialArray
    );
    mesh['tiles'] = tiles;
    return mesh;
  };
  setScalePivot = (mpdel, ItemId) => {
    if (!mpdel) return;
    mpdel.rotation.set(0, 0, 0);
    if (ItemId != 4 && ItemId != 5 && ItemId != 7) {
      mpdel.rotation.set(0, Math.PI * 0.5, 0);
    }
    mpdel.scale.set(this.ZoomIn, this.ZoomIn, this.ZoomIn);
    if (ItemId == 0 || ItemId == 2) {
      mpdel.scale.set(this.ZoomIn * 0.1, this.ZoomIn * 0.1, this.ZoomIn * 0.1);
    }
    if (ItemId == 6) {
      mpdel.scale.set(this.ZoomIn * 0.4, this.ZoomIn * 0.4, this.ZoomIn * 0.4);
    }
    if (ItemId == 8) {
      mpdel.position.set(0, 0.0, -0.034);
    }
    mpdel.name = 'root';
  };
  loadSneaker = (callback, id) => {
    const ItemId = id;
    const gltfLoader = new GLTFLoader(this.loadingManager);
    gltfLoader.load(tarotProducts[ItemId].lowPolyModelUrl, (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;
      this.setScalePivot(root, ItemId);
      if (ItemId == 8) {
        root.scale.set(this.ZoomIn * 0.1, this.ZoomIn * 0.1, this.ZoomIn * 0.1);
      }
      root.visible = true;
      this.itemsChildForCatchingClick[ItemId] =
        this.createCardRayCastCatcher(ItemId);
      this.itemsChildForCatchingClick2[ItemId] = this.createRayCasterCatcher();
      this.itemsChildForCatchingClick2[ItemId].position.set(0, 0.1, 0);
      this.itemsChildForCatchingClick2[ItemId].visible = false;
      this.itemsChildForCatchingClick[ItemId].position.set(0, -0.01, 0);
      this.itemsArray[ItemId] = group;
      group.name = tarotProducts[ItemId].modelName; // + id;
      group.add(this.itemsChildForCatchingClick[ItemId]);
      group.add(this.itemsChildForCatchingClick2[ItemId]);
      group.add(root);
      group['ItemId'] = ItemId;
      this.scene.add(group);
      group.scale.set(this.ZoomOut, this.ZoomOut, this.ZoomOut);
      group.scale.set(0.001, 0.001, 0.001);
      group.visible = true;
      if (callback) callback(id);
    });
  };
  setmaterial = (model, map, normal) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.name === 'Mexicaba_mat' && model?.name === 'ELISA MINI') {
          //Mexicaba_mat
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'ElisaTopHandle_mat') {
          //ElisaTopHandle_mat
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met.name === 'Mexicaba_mat' && model?.name === 'ELISA SMALL') {
          //ELISA SMALL
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met.name === 'lambert3') {
          //Elisa to handle nano
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Iriza_100_mat') {
          //Iriza
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Desert_mat') {
          //SANDALE_DU_DESERT_ALTA_130
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'lambert2') {
          //CARACABA_SMALL
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'lambert14') {
          //noLimit
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Officialito_mat') {
          //Officialito_mat
          met.map.image = map.image;
          met.map.needsUpdate = true;
        }
      }
    });
  };
  callback = () => {
    this.loadingCount++;
    this.selectedItem = -1;
    for (let i = 0; i < this.itemsArray.length; i++) {
      if (this.itemsArray[i] != undefined) {
        this.itemsArray[i].scale.set(this.ZoomOut, this.ZoomOut, this.ZoomOut);
        if (this.itemsArray[i].getObjectByName('root'))
          this.itemsArray[i].getObjectByName('root').visible = false;
        if (isMobile) {
          this.ZoomOut = 3;
          this.itemsArray[i].scale.set(
            this.ZoomOut,
            this.ZoomOut,
            this.ZoomOut
          );
          if (i < 4) {
            this.itemsArray[i].position.x = 0;
            this.itemsArray[i].position.y = -2.5 + i * 1.0;
            this.itemsArray[i].position.z = 0;
            this.itemsArray[i].rotation.set(-Math.PI * 0.5, -Math.PI * 0.5, 0);
          } else {
            const redian = (Math.PI / 180) * (-104 + (i - 4) * 52); //(i * 90);
            this.itemsArray[i].position.set(
              Math.sin(redian) * 1.8,
              0.33 + Math.cos(redian) * 1.8,
              0
            );
            this.itemsArray[i].rotation.set(-Math.PI * 0.5, redian, 0);
          }
        } else {
          if (i < 4) {
            this.itemsArray[i].position.x = -2.7 + i * 1.32;
            this.itemsArray[i].position.y = 0;
            this.itemsArray[i].position.z = 0;
            this.itemsArray[i].rotation.set(-Math.PI * 0.5, 0, 0);
          } else {
            const redian = (Math.PI / 180) * (-14 + (i - 4) * 52); //(i * 90);
            this.itemsArray[i].position.set(
              0.5 + Math.sin(redian) * 2.8,
              Math.cos(redian) * 2.8,
              0
            );
            this.itemsArray[i].rotation.set(-Math.PI * 0.5, redian, 0);
          }
        }
        this.itemsArray[i].visible = true;
        this.itemsArray[i]['startPostion'] = {
          x: this.itemsArray[i].position.x,
          y: this.itemsArray[i].position.y,
          z: this.itemsArray[i].position.z
        };
        this.itemsArray[i]['startRotation'] = {
          x: this.itemsArray[i].rotation.x,
          y: this.itemsArray[i].rotation.y,
          z: this.itemsArray[i].rotation.z
        };
      }
    }

    if (isMobileOrTab) {
      if (this.elisaTextureMap?.image && this.elisaTextureNormalMap?.image)
        this.setmaterial(
          this.itemsArray[0],
          this.elisaTextureMap,
          this.elisaTextureNormalMap
        );
      if (
        this.elisaTop2TextureMap?.image &&
        this.elisaTop2TextureNormalMap?.image
      )
        this.setmaterial(
          this.itemsArray[1],
          this.elisaTop2TextureMap,
          this.elisaTop2TextureNormalMap
        );
      if (
        this.elisaBlueTextureMap?.image &&
        this.elisaBlueTextureNormalMap?.image
      )
        this.setmaterial(
          this.itemsArray[2],
          this.elisaBlueTextureMap,
          this.elisaBlueTextureNormalMap
        );
      if (
        this.elisaTopTextureMap?.image &&
        this.elisaTopTextureNormalMap?.image
      )
        this.setmaterial(
          this.itemsArray[3],
          this.elisaTopTextureMap,
          this.elisaTopTextureNormalMap
        );
      if (this.irizaTextureMap?.image && this.irizaTextureNormalMap?.image)
        this.setmaterial(
          this.itemsArray[4],
          this.irizaTextureMap,
          this.irizaTextureNormalMap
        );

      if (this.sandaleTextureMap?.image && this.sandaleTextureNormalMap?.image)
        this.setmaterial(
          this.itemsArray[5],
          this.sandaleTextureMap,
          this.sandaleTextureNormalMap
        );
      if (
        this.caracabaTextureMap?.image &&
        this.caracabaTextureNormalMap?.image
      )
        this.setmaterial(
          this.itemsArray[6],
          this.caracabaTextureMap,
          this.caracabaTextureNormalMap
        );
      if (this.noLimitTextureMap?.image && this.noLimitTextureNormalMap?.image)
        this.setmaterial(
          this.itemsArray[7],
          this.noLimitTextureMap,
          this.noLimitTextureNormalMap
        );
      if (this.officialitoTextureMap?.image)
        this.setmaterial(
          this.itemsArray[8],
          this.officialitoTextureMap,
          this.officialitoTextureNormalMap
        );
    }
    this.props.onReady();
  };
  loadEnvironment = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }
    const textureLoader = new THREE.TextureLoader(this.loadingManager);

    if (isMobileOrTab) {
      this.elisaTextureMap = textureLoader.load(tarotTextures.elisaMap);
      this.elisaTextureNormalMap = textureLoader.load(
        tarotTextures.elisaNormalMap
      );

      this.elisaTop2TextureMap = textureLoader.load(tarotTextures.elisaTop2Map);
      this.elisaTop2TextureNormalMap = textureLoader.load(
        tarotTextures.elisaTop2NormalMap
      );

      this.elisaBlueTextureMap = textureLoader.load(tarotTextures.elisaBlueMap);
      this.elisaBlueTextureNormalMap = textureLoader.load(
        tarotTextures.elisaBlueNormalMap
      );

      this.elisaTopTextureMap = textureLoader.load(tarotTextures.elisaTopMap);
      this.elisaTopTextureNormalMap = textureLoader.load(
        tarotTextures.elisaTopNormalMap
      );

      this.irizaTextureMap = textureLoader.load(tarotTextures.irizaMap);
      this.irizaTextureNormalMap = textureLoader.load(
        tarotTextures.irizaNormalMap
      );

      this.sandaleTextureMap = textureLoader.load(tarotTextures.sandaleMap);
      this.sandaleTextureNormalMap = textureLoader.load(
        tarotTextures.sandaleNormalMap
      );

      this.caracabaTextureMap = textureLoader.load(tarotTextures.caracabaMap);
      this.caracabaTextureNormalMap = textureLoader.load(
        tarotTextures.caracabaNormalMap
      );

      this.noLimitTextureMap = textureLoader.load(tarotTextures.noLimitMap);
      this.noLimitTextureNormalMap = textureLoader.load(
        tarotTextures.noLimitNormalMap
      );

      this.officialitoTextureMap = textureLoader.load(
        tarotTextures.officialitoMap
      );
      // this.officialitoTextureNormalMap = textureLoader.load(tarotTextures.officialitoNormalMap);
    }

    this.transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(32, 16),
      this.transparent_Mat
    );
    this.transparent_Plane.rotation.set(0, 0, 0);
    this.transparent_Plane.position.set(0, 0, 1.21);
    this.scene.add(this.transparent_Plane);
    this.transparent_Plane.visible = false;

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    loader.load(
      modelWorkshopEnvironmentImagePath,
      (texture) => {
        let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator = undefined;
        this.scene.environment = envMap;
        // this.scene.background = envMap;
      },
      undefined,
      undefined
    );
  };
  detectFirstTouchedObject = (e, type, objects) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        this.mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = this.renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      this.mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      this.mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
    if (type === touchType.touchDown) {
      this.mouseDown.x = this.mouse.x;
      this.mouseDown.y = this.mouse.y;
    }
    if (
      type === touchType.touchUp &&
      this.mouseDown.x === this.mouse.x &&
      this.mouseDown.y === this.mouse.y
    ) {
      const objs = objects.filter((model) => model);
      const intersects = this.raycaster.intersectObjects(objs);
      if (intersects.length) {
        return intersects[0];
      }
    }
  };
  focusCall = (no) => {
    backDiv.style.display = 'block';
    if (this.selectedItem != no) this.ComeGoBack();
    this.loadNew(false, 0);
    this.selectedItem = no;
    if (!this.itemsArray[this.selectedItem]) return;
    if (!this.itemsArray[this.selectedItem]) return;
    this.resetPositionForword(this.itemsArray[this.selectedItem], this.ZoomOut); //Come forword
    this.loadNew(true, this.selectedItem);
    this.updateObjectControlForModel(this.focusedModel);
    this.props.didSelectModel(
      tarotProducts.find((p) => p.modelName === this.focusedModel.name)
    );
    this.fucusObjectPosition.set(
      this.focusedModel.position.x,
      this.focusedModel.position.y,
      this.focusedModel.position.z
    );
    if (this.controls) {
      this.controls.disableVerticalRotation();
      this.controls.disableHorizontalRotation();
    }
  };
  touchEvent = (e, type) => {
    if (this.props.remoteState || this.isTweening || !this.itemsArray[5])
      return;

    this.setTouchValues(e, type);
    const clickedModel = this.detectFirstTouchedObject(
      e,
      type,
      this.itemsChildForCatchingClick
    );
    if (touchType.touchDown === type) {
      this.isDown = true;
    }
    if (touchType.touchUp === type) {
      this.isDown = false;
    }
    if (this.focusedModel && this.isDown == true) {
      this.updateRemoteObjectControlForModel();
    }
    if (!this.focusedModel) {
      if (clickedModel) {
        for (
          let i = 0;
          i < this.itemsChildForCatchingClick.length &&
          this.isTweening == false;
          i++
        ) {
          if (
            this.itemsChildForCatchingClick[i] == clickedModel.object &&
            this.selectedItem != i &&
            this.itemsArray[i] &&
            i != 7
          ) {
            this.focusCall(i);
            this.props.updateRemoteState({
              flag: REMOTE_ACTION.FOCUS,
              focusItem: i
            });
          }
        }
      }
    } else if (type === touchType.touchUp) {
      const clickedModel2 = this.detectFirstTouchedObject(
        e,
        type,
        this.itemsChildForCatchingClick2
      );
      if (
        this.focusedModel &&
        this.mouseDown.x === this.mouse.x &&
        this.mouseDown.y === this.mouse.y &&
        this.mouse.y < 0.56 &&
        this.mouse.y > -0.675 &&
        this.itemsChildForCatchingClick2[this.selectedItem] !==
          clickedModel2?.object
      ) {
        this.unFoucosCall();
        this.props.updateRemoteState({
          flag: REMOTE_ACTION.UN_FOCUS,
          focusItem: this.selectedItem
        });
      }
    }
  };
  onwheelEvent = (event) => {
    if (
      this.selectedItem < 0 ||
      this.props.remoteState ||
      this.focusedModel === undefined
    )
      return;
    let modelScale = this.focusedModel.scale.x;
    if (event.deltaY > 0) {
      if (modelScale < this.maxScale) modelScale += this.incScale;
    } else {
      if (modelScale > this.minScale) modelScale -= this.incScale;
    }
    this.focusedModel.scale.set(modelScale, modelScale, modelScale);
    this.updateRemoteObjectControlForModel();
  };
  unFoucosCall = () => {
    if (!this.focusedModel?.getObjectByName('root')) return;
    backDiv.style.display = 'none';
    this.ComeGoBack();
    this.focusedModel.getObjectByName('root').visible = true;
    this.focusedModel = undefined;
    this.selectedItem = -1;
    if (this.controls) {
      this.controls.disableVerticalRotation();
      this.controls.disableHorizontalRotation();
    }
  };
  onPinch = (ev) => {
    if (this.props.remoteState) return;
    if (
      ev.type == 'doubletap' &&
      this.selectedItem >= 0 &&
      this.isTweening == false
    ) {
      this.props.updateRemoteState({
        flag: REMOTE_ACTION.UN_FOCUS,
        focusItem: this.selectedItem
      });
      this.unFoucosCall();
    }
    if (
      this.focusedModel &&
      this.selectedItem >= 0 &&
      (ev.type == 'pinchstart' || ev.type == 'pinchmove')
    ) {
      if (ev.type == 'pinchstart') {
        this.initScale = this.focusedModel.scale.x || 1;
      }
      this.focusedModel.scale.set(
        this.initScale * ev.scale,
        this.initScale * ev.scale,
        this.initScale * ev.scale
      );
      if (this.focusedModel.scale.x > this.maxScale)
        this.focusedModel.scale.set(
          this.maxScale,
          this.maxScale,
          this.maxScale
        );
      if (this.focusedModel.scale.x < this.minScale)
        this.focusedModel.scale.set(
          this.minScale,
          this.minScale,
          this.minScale
        );
      this.updateRemoteObjectControlForModel();
    }
  };
  setTouchValues = (e, type) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        this.mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          this.touchPointer.x =
            (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          this.touchPointer.y =
            -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            this.initDistance = this.touchPointer.distanceTo(this.mouse);
            this.onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = this.touchPointer.distanceTo(this.mouse);
            this.onPinch({
              type: 'pinchmove',
              scale: currentDistance / this.initDistance
            });
          }
        }
      }
    } else {
      const elem = this.renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      this.mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      this.mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      this.mouseDownPos.x = this.mouse.x;
      this.mouseDownPos.y = this.mouse.y;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
  };
  eventDown = (e) => {
    this.touchEvent(e, touchType.touchDown);
  };
  eventMove = (e) => {
    this.touchEvent(e, touchType.touchMove);
  };
  eventUp = (e) => {
    this.touchEvent(e, touchType.touchUp);
  };
  zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  addEventListeners = () => {
    document.addEventListener('mousedown', this.eventDown);
    document.addEventListener('touchstart', this.eventDown);
    document.addEventListener('mousemove', this.eventMove);
    document.addEventListener('touchmove', this.eventMove);
    document.addEventListener('mouseup', this.eventUp);
    document.addEventListener('touchend', this.eventUp);
    document.addEventListener('wheel', this.onwheelEvent);
    window.addEventListener('resize', this.onWindowResize, false);
  };
  cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };
  cleanUp = () => {
    this.itemsArray.length = 0;
    this.itemsChildForCatchingClick.length = 0;
    if (this.scene != undefined) {
      this.scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          this.cleanMaterial(object['material']);
        } else {
          for (const material of object['material'])
            this.cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      this.scene.children.forEach((model) => {
        this.scene.remove(model);
      });
    }
    if (this.renderer != undefined) {
      this.renderer.dispose();
      this.renderer && this.renderer.renderLists.dispose();
    }
    if (this.controls != undefined) {
      this.controls.disableVerticalRotation();
      this.controls.disableHorizontalRotation();
      this.controls.disableZoom();
    }

    this.elisaTextureMap = this.cleanTexture(this.elisaTextureMap);
    this.elisaTextureNormalMap = this.cleanTexture(this.elisaTextureNormalMap);
    this.elisaTop2TextureMap = this.cleanTexture(this.elisaTop2TextureMap);
    this.elisaTop2TextureNormalMap = this.cleanTexture(
      this.elisaTop2TextureNormalMap
    );
    this.elisaBlueTextureMap = this.cleanTexture(this.elisaBlueTextureMap);
    this.elisaBlueTextureNormalMap = this.cleanTexture(
      this.elisaBlueTextureNormalMap
    );
    this.elisaTopTextureMap = this.cleanTexture(this.elisaTopTextureMap);
    this.elisaTopTextureNormalMap = this.cleanTexture(
      this.elisaTopTextureNormalMap
    );
    this.irizaTextureMap = this.cleanTexture(this.irizaTextureMap);
    this.irizaTextureNormalMap = this.cleanTexture(this.irizaTextureNormalMap);
    this.sandaleTextureMap = this.cleanTexture(this.sandaleTextureMap);
    this.sandaleTextureNormalMap = this.cleanTexture(
      this.sandaleTextureNormalMap
    );
    this.caracabaTextureMap = this.cleanTexture(this.caracabaTextureMap);
    this.caracabaTextureNormalMap = this.cleanTexture(
      this.caracabaTextureNormalMap
    );
    this.noLimitTextureMap = this.cleanTexture(this.noLimitTextureMap);
    this.noLimitTextureNormalMap = this.cleanTexture(
      this.noLimitTextureNormalMap
    );
    this.officialitoTextureMap = this.cleanTexture(this.officialitoTextureMap);
    this.officialitoTextureNormalMap = this.cleanTexture(
      this.officialitoTextureNormalMap
    );

    document.removeEventListener('touchstart', this.eventDown);
    document.removeEventListener('touchmove', this.eventMove);
    document.removeEventListener('touchend', this.eventUp);
    document.removeEventListener('mousedown', this.eventDown);
    document.removeEventListener('mousemove', this.eventMove);
    document.removeEventListener('mouseup', this.eventUp);
    document.removeEventListener('wheel', this.onwheelEvent);
    window.removeEventListener('resize', this.onWindowResize);

    document.removeEventListener('gesturestart', this.zoomDisable);
    document.removeEventListener('gesturechange', this.zoomDisable);
    document.removeEventListener('gestureend', this.zoomDisable);

    this.controls = undefined;
    this.scene = undefined;
    this.renderer = undefined;
    this.camera = undefined;
  };
  remoteObjectUpdate = (remoteState: ILoubiAirwaysTarotCardAnimationState) => {
    if (remoteState?.flag && this.itemsArray[5] && this.loadingCount > 10) {
      if (remoteState.flag == REMOTE_ACTION.FOCUS) {
        this.focusCall(remoteState.focusItem);
      }
      if (remoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        this.unFoucosCall();
      }
      if (remoteState.flag == REMOTE_ACTION.MOUSE_CONTROL && !this.isTweening) {
        if (
          this.focusedModel?.ItemId != this.selectedItem &&
          remoteState?.focusItem >= 0 &&
          remoteState?.focusItem < this.itemsArray.length
        ) {
          this.callback();
          this.focusedModel = this.itemsArray[remoteState.focusItem];
          this.selectedItem = remoteState.focusItem;
        }
        if (this.focusedModel) {
          this.focusedModel.position.set(
            remoteState.position[0],
            remoteState.position[1],
            remoteState.position[2]
          );
          this.focusedModel.scale.set(
            remoteState.scale[0],
            remoteState.scale[1],
            remoteState.scale[2]
          );
          this.focusedModel.rotation.set(
            remoteState.rotation[0],
            remoteState.rotation[1],
            remoteState.rotation[2]
          );
          this.animationOnFocus = true;
          if (this.focusedModel?.getObjectByName('root') && !this.zoomModel)
            this.focusedModel.getObjectByName('root').visible = true;
        }
      }
      remoteState.flag = undefined;
    }
  };
  animate = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }
    this.delta = this.clock.getDelta();
    if (
      this.zoomModel &&
      this.focusedModel &&
      this.focusedModel?.ItemId === this.zoomModel?.ItemId
    ) {
      this.zoomModel.position.set(
        this.focusedModel.position.x,
        this.focusedModel.position.y,
        this.focusedModel.position.z
      );
      this.zoomModel.scale.set(
        this.focusedModel.scale.x,
        this.focusedModel.scale.y,
        this.focusedModel.scale.z
      );
      this.zoomModel.rotation.set(
        this.focusedModel.rotation.x,
        this.focusedModel.rotation.y,
        this.focusedModel.rotation.z
      );
      if (this.focusedModel?.getObjectByName('root'))
        this.focusedModel.getObjectByName('root').visible = false;
      this.zoomModel.visible = this.animationOnFocus;
    }
    if (this.loadingCount > 0) this.loadingCount++;

    this.itemsChildForCatchingClick
      .filter((model) => model)
      .forEach((model) => {
        model.tiles.update(this.delta * 1000);
      });

    this.renderer.render(this.scene, this.camera);
    TWEEN.update();
    requestAnimationFrame(this.animate);
  };

  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  };
  onClickBack = () => {
    if (!this.props.viewOnly && !this.isTweening) {
      this.unFoucosCall();
      this.props.updateRemoteState({
        flag: REMOTE_ACTION.UN_FOCUS,
        focusItem: this.selectedItem
      });
    }
  };
  init = () => {
    this.textureTop = new THREE.TextureLoader(this.loadingManager).load(
      cardTopImage
    );
    this.textureTop.encoding = THREE.sRGBEncoding;
    for (let i = 0; i < 9; i++) {
      this.loadSneaker(null, i);
    }
    this.loadEnvironment();
    if (!this.props.viewOnly) {
      this.addEventListeners();
    }
    this.animate();
  };
  render() {
    return (
      <div>
        <canvas id="c"></canvas>
        <p id="topright">
          <LJBackToAnimationButton onClick={this.onClickBack} />
        </p>
        <style jsx>
          {`
            div {
              background: url(${`${assetBaseUrl}/loubiairways/luggage-animation/tarot/background/v4/tarot_background.jpg`})
                no-repeat;
              background-position: center center;
              background-size: cover;
              background-attachment: fixed;
            }
            div,
            #c {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }
            a {
              position: fixed;
              top: 2vh;
              left: 2vh;
              font-size: 0.81em;
              text-align: left;
            }
          `}
        </style>
      </div>
    );
  }
}

export default TarotCard;

import { format } from 'date-fns';
import { getIdentityId } from '../../../utils/identity';
import { IAppointmentFormData } from './Appointment';
import { IGenericIWCAppointmentFormData } from './GenericIWCAppointmentForm';
import { IPiagetAppointmentFormData } from './PiagetAppointmentForm';
import {
  isValidEmail,
  isValidCountryCode,
  isValidPhoneNumber,
  isValidMessage
} from './inputValidator';
import { IRDUAppointmentFormData } from './RDUAppointmentForm';
import httpClient from '../../../clientSideServices/http';

export interface IAppointmentEmail {
  storeId: string;
  acceptedTermsOfUse: boolean;
  allowOffers: boolean | undefined;
  subscribeNews: boolean | undefined;
  date: string;
  time: string;
  name: string;
  appointmentContactId: string;
  email: string | undefined;
  message: string | undefined;
  phone: string | undefined;
  salutation: string | undefined;
  channel: string | undefined;
  cognitoId: string | undefined;
  preferredLanguage: string | undefined;
}

export const emailPayloadFromFormData = (
  formData: IAppointmentFormData,
  storeId: string
): IAppointmentEmail => {
  const identityId = getIdentityId();
  const fullPhoneNumber =
    isValidCountryCode(formData.countryCode) &&
      isValidPhoneNumber(formData.phone)
      ? `${formData.countryCode.trim()} ${formData.phone.trim()}`
      : undefined;
  const isPhysical = formData.channel === 'Physical';
  const email: IAppointmentEmail = {
    storeId,
    allowOffers: formData.allowOffers,
    date: format(formData.date, 'dd MMM, yyyy'),
    time: formData.time,
    appointmentContactId: formData.boutiqueContact.id,
    email: isValidEmail(formData.email) ? formData.email.trim() : undefined,
    message: isValidMessage(formData.message) ? formData.message : '',
    name: formData.name.trim(),
    phone: fullPhoneNumber,
    salutation: formData.salutation,
    acceptedTermsOfUse: formData.acceptedTermsOfUse,
    subscribeNews: formData.subscribeNews,
    channel: isPhysical ? 'Physical' : undefined,
    cognitoId: identityId,
    preferredLanguage: undefined
  };
  return email;
};

export const iwcSgEmailPayloadFromFormData = (
  formData: IGenericIWCAppointmentFormData,
  storeId: string
): IAppointmentEmail => {
  const identityId = getIdentityId();
  const fullPhoneNumber =
    isValidCountryCode(formData.countryCode) &&
      isValidPhoneNumber(formData.phone)
      ? `${formData.countryCode.trim()} ${formData.phone.trim()}`
      : undefined;
  const email: IAppointmentEmail = {
    storeId,
    allowOffers: formData.allowOffers,
    date: format(formData.date, 'dd MMM, yyyy'),
    time: formData.time,
    appointmentContactId: formData.boutiqueContact.id,
    name: formData.name.trim(),
    email: isValidEmail(formData.email) ? formData.email.trim() : undefined,
    phone: fullPhoneNumber,
    salutation: undefined,
    message: undefined,
    channel: formData.channel,
    acceptedTermsOfUse: formData.acceptedTermsOfUse,
    subscribeNews: formData.subscribeNews,
    cognitoId: identityId,
    preferredLanguage: undefined
  };
  return email;
};

export const piagetEmailPayloadFromFormData = (
  formData: IPiagetAppointmentFormData,
  storeId: string
): IAppointmentEmail => {
  const identityId = getIdentityId();
  const fullPhoneNumber =
    isValidCountryCode(formData.countryCode) &&
      isValidPhoneNumber(formData.phone)
      ? `${formData.countryCode.trim()} ${formData.phone.trim()}`
      : undefined;
  const isPhysical = formData.channel === 'Physical';
  const email: IAppointmentEmail = {
    storeId,
    date: format(formData.date, 'dd MMM, yyyy'),
    time: formData.time,
    appointmentContactId: formData.boutiqueContact.id,
    email: isValidEmail(formData.email) ? formData.email.trim() : undefined,
    message: isValidMessage(formData.message) ? formData.message : '',
    name: formData.name.trim(),
    phone: fullPhoneNumber,
    salutation: formData.salutation,
    acceptedTermsOfUse: formData.acceptedTermsOfUse,
    channel: isPhysical ? 'Physical' : undefined,
    allowOffers: formData.allowOffers,
    subscribeNews: undefined,
    cognitoId: identityId,
    preferredLanguage: undefined
  };
  return email;
};

export const rduSGEmailPayloadFromFormData = (
  formData: IRDUAppointmentFormData,
  storeId: string
): IAppointmentEmail => {
  const identityId = getIdentityId();
  const fullPhoneNumber =
    isValidCountryCode(formData.countryCode) &&
      isValidPhoneNumber(formData.phone)
      ? `${formData.countryCode.trim()} ${formData.phone.trim()}`
      : undefined;
  const email: IAppointmentEmail = {
    storeId,
    allowOffers: formData.allowOffers,
    date: format(formData.date, 'dd MMM, yyyy'),
    time: formData.time,
    appointmentContactId: formData.boutiqueContact.id,
    name: formData.name.trim(),
    email: isValidEmail(formData.email) ? formData.email.trim() : undefined,
    phone: fullPhoneNumber,
    salutation: formData.salutation,
    message: formData.message,
    channel: formData.channel,
    acceptedTermsOfUse: formData.acceptedTermsOfUse,
    subscribeNews: undefined,
    cognitoId: identityId,
    preferredLanguage: formData.preferredLanguage || 'English'
  };
  return email;
};

export const sendFormEmail = (
  formData: IAppointmentFormData,
  storeId: string
): Promise<any> => {
  const email: IAppointmentEmail = emailPayloadFromFormData(formData, storeId);
  return emailService(email);
};

export const sendIWCSGFormEmail = (
  formData: IGenericIWCAppointmentFormData,
  storeId: string
): Promise<any> => {
  const email: IAppointmentEmail = iwcSgEmailPayloadFromFormData(
    formData,
    storeId
  );
  return emailService(email);
};

export const sendPiagetFormEmail = (
  formData: IPiagetAppointmentFormData,
  storeId: string
): Promise<any> => {
  const email: IAppointmentEmail = piagetEmailPayloadFromFormData(
    formData,
    storeId
  );
  return emailService(email);
};

export const sendRDUSGFormEmail = (
  formData: IRDUAppointmentFormData,
  storeId: string
): Promise<any> => {
  const email: IAppointmentEmail = rduSGEmailPayloadFromFormData(
    formData,
    storeId
  );
  return emailService(email);
};

const emailService = (email: IAppointmentEmail) => {
  return httpClient.post('/api/sendEmail', email);
};

import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  IAnimationProduct,
  ILoubiBeautyCartAnimationState,
  LoubiAirwaysAnimation
} from '../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../redux/customActions/loubiAirways';
import { DID_UPDATE_ANIMATION_STATE } from '../../../utils/constants';
import { useRemoteLoubiAirwaysAnimationState } from '../../hooks/loubiAirways';
import { canInteractWithAnimation } from '../../hooks/meeting';
import { animationVoyageThrottleDurationInMs } from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/animationUtils';
import LJContinueFlightButton from '../../VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/Buttons/LJContinueFlightButton';
import { AnimationContext } from '../louboutin/AnimationContext';
import BeautyCart from './BeautyCart';

const BeautyCartWrapper = () => {
  const { setSelectedModel, setLoaded, onCloseAnimation } =
    React.useContext(AnimationContext);

  const remoteBeautyCartAnimationState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.BEAUTY_CART
  );
  const viewOnly = !canInteractWithAnimation();
  const dispatch = useDispatch();
  const logEventThrottled = throttle(
    (state: ILoubiBeautyCartAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: LoubiAirwaysAnimation.BEAUTY_CART,
        state
      });
    },
    1000,
    { trailing: true }
  );

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiBeautyCartAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.BEAUTY_CART,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );
  const updateRemoteState = (state: ILoubiBeautyCartAnimationState) => {
    throttledUpdateRemoteState(state);
    logEventThrottled(state);
  };
  let beautyCart: BeautyCart = null;
  return (
    <>
      <BeautyCart
        remoteState={remoteBeautyCartAnimationState}
        updateRemoteState={updateRemoteState}
        didSelectModel={(model: IAnimationProduct) => {
          setSelectedModel(model);
        }}
        onReady={(val) => setLoaded(val)}
        viewOnly={viewOnly}
        ref={(instance) => {
          beautyCart = instance;
        }}
      />
      <LJContinueFlightButton
        onClick={() => {
          const lipsticVisible = beautyCart?.imgLipstick?.find(
            (p) => p.visible === true
          );
          const perfumesVisible = beautyCart?.imgPerfumes?.find(
            (p) => p.visible === true
          );
          if (lipsticVisible || perfumesVisible) {
            beautyCart.onClickBack();
          } else {
            onCloseAnimation();
          }
        }}
      />
    </>
  );
};

export default BeautyCartWrapper;

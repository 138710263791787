import { useRouter } from 'next/router';
import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { actionToggleVirtualDialog } from '../../../redux/actions';
import { DID_CLICK_VIRTUAL_BACKGROUND } from '../../../utils/constants';
import { isUserOnSafari } from '../../../utils/deviceDetector';
import MeetingButtonWithIcon from '../MeetingControls/MeetingButtonWithIcon';
import { IconVirtualBackground } from '../MeetingControls/MeetingIcons';

function VirtualBackgroundButton({
  color,
  withWrapper,
  disabled
}: {
  color?: string;
  withWrapper?: boolean;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const meetingId = (router.query.meeting as string)?.toLowerCase() || '';
  const openVirtualBackgroundDialog = () => {
    dispatch(actionToggleVirtualDialog(true));
  };

  const [shouldShowButton, setShouldShowButton] = React.useState(false);

  React.useEffect(() => {
    setShouldShowButton(!isUserOnSafari());
  }, []);

  if (!shouldShowButton) return null;

  const actionButton = (
    <MeetingButtonWithIcon
      id="virtualBackground"
      onClick={() => {
        logEvent(DID_CLICK_VIRTUAL_BACKGROUND, DID_CLICK_VIRTUAL_BACKGROUND, {
          meetingId
        });
        openVirtualBackgroundDialog();
      }}
      disabled={disabled}
    >
      <IconVirtualBackground fill={color} />
      <span>Virtual Background</span>
    </MeetingButtonWithIcon>
  );

  return (
    <>
      {withWrapper ? (
        <div className="button-container">
          {actionButton}
          <BiChevronRight className="arrow" />
        </div>
      ) : (
        actionButton
      )}
    </>
  );
}

export default VirtualBackgroundButton;

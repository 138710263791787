import React from 'react';
import { IAnimationProduct } from '../../../../../interfaces/loubifuture';
import {
  getLFProductById,
  getLFProductImage,
  LFColor
} from '../../../../ThreejsAnimation/loubiFuture/assets';

const ProductBox = ({
  product,
  onClick,
  onRemove
}: {
  product: IAnimationProduct;
  onClick: () => void;
  onRemove: () => void;
}) => {
  let width = '380px';
  let top = '-190px';

  switch (product.modelCode) {
    case 'CLLOUBIFUTURE014': {
      top = '-70px';
      width = '270px';
      break;
    }
    case 'CLLOUBIFUTURE012':
    case 'CLLOUBIFUTURE011': {
      width = '270px';
      top = '-68px';
      break;
    }
    case 'CLLOUBIFUTURE015':
    case 'CLLOUBIFUTURE013': {
      width = '320px';
      top = '-136px';
      break;
    }
    case 'CLLOUBIFUTURE006': {
      top = '-180px';
      break;
    }
  }

  return (
    <div className="product-box">
      <div className="thumbnail" onClick={onClick}>
        <div className="circle" />
        <img src={getLFProductImage(product.id)} alt={product.id} />
      </div>
      <div className="content">
        <h3>{product.modelName}</h3>
        <span onClick={onRemove}>remove</span>
      </div>
      <style jsx>{`
        .product-box {
          font-family: 'LF-Regular', sans-serif;
          display: flex;
          border-radius: 0 20px 20px 0;
          align-items: center;
          margin-bottom: 20px;
          font-size: 14px;
          flex-wrap: wrap;
          background: rgba(34, 0, 61, 0.5);
          background-size: 60px auto;
          color: ${LFColor.blue};
        }
        span {
          text-decoration: underline;
          cursor: pointer;
          color: ${LFColor.purpleLight};
          font-size: 0.7em;
        }
        h3 {
          font-weight: normal;
          font-size: 1em;
          letter-spacing: 1.5px;
        }
        .content {
          text-align: left;
          padding: 0 40px 0 10px;
          width: calc(100% - 150px);
        }
        .thumbnail {
          position: relative;
          width: 150px;
          height: 150px;
          overflow: hidden;
        }
        .circle {
          border-radius: 100%;
          width: 120px;
          height: 120px;
          border: 2px solid ${LFColor.blue};
          position: absolute;
          left: 15px;
          top: 15px;
        }
        img {
          z-index: 1;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: ${width};
          top: ${top};
        }
        @media (min-width: 768px) {
          .product-box {
            border-radius: 20px;
            background-size: 70px auto;
            font-size: 16px;
            width: 100%;
          }
        }
        @media (min-width: 820px) {
          .product-box {
            width: 48%;
            margin: 1%;
          }
        }
      `}</style>
    </div>
  );
};

const FavList = ({
  items,
  onClickItem,
  onRemoveItem
}: {
  items: string[];
  onClickItem: (id: string) => void;
  onRemoveItem: (id: string) => void;
}) => {
  const products = items?.map((id) => getLFProductById(id));
  return (
    <div className="favList">
      {products?.length ? (
        <h4 className="LFTitle">FAVOURITES</h4>
      ) : (
        <div className="empty">
          <h4 className="LFTitle">NO ITEMS YET</h4>
          <p>
            When you find an item you like, click{' '}
            <strong>ADD TO FAVOURITES</strong>
          </p>
        </div>
      )}

      <div className="products-container">
        {products?.map((p) => (
          <ProductBox
            product={p}
            onRemove={() => onRemoveItem(p.id)}
            onClick={() => onClickItem(p.id)}
            key={p.id}
          />
        ))}
      </div>
      <style jsx>{`
        .LFTitle {
          letter-spacing: 1px;
          font-size: 24px;
          margin-bottom: 20px;
        }
        .products-container {
          margin-left: -20px;
        }
        .favList {
          padding: 30px 0 50px;
          text-align: left;
        }
        p {
          font-size: 12px;
        }
        strong {
          font-family: 'LF-Bold', sans-serif;
        }

        .empty {
          font-family: 'LF-Regular', sans-serif;
          border-radius: 0 20px 20px 0;
          border-left: 0;
          align-items: center;
          margin-bottom: 20px;
          font-size: 14px;
          flex-wrap: wrap;
          background: rgba(34, 0, 61, 0.5);
          background-size: 60px auto;
          margin-left: -20px;
          padding: 50px 40px 30px 20px;
        }

        @media (min-width: 768px) {
          h4 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
          .products-container {
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;
            justify-content: center;
            align-items: center;
          }
          .favList {
            padding-bottom: 80px;
            text-align: center;
          }
          .empty {
            border-radius: 20px;
            width: 80%;
            margin: auto;
          }
        }
        @media (min-width: 1100px) {
          .products-container {
            width: 90%;
            margin: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default FavList;

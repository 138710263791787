import React from 'react';
import { useTranslation } from '../../../i18n';
import {
  IVirtualBoutiqueConfig,
  IVirtualBoutiqueContact
} from '../../../interfaces';
import { isChatAvailable } from '../../../utils/stores';
import { getContactForToday } from '../../../utils/zaloLink';
import EmailContactButton from './EmailContactButton';
import ZaloContactButton from './ZaloContactButton';

const ContactButtonWithZalo = ({
  config
}: {
  config: IVirtualBoutiqueConfig;
  additionalContactsInChatFilter?: (c: IVirtualBoutiqueContact) => boolean;
  labelTextKey?: string;
}) => {
  const [showChat, setShowChat] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);

  const contacts = config.contacts || [];

  const { t } = useTranslation();

  const defaultChatMessage = t(config.defaultChatMessageKey);

  React.useEffect(() => {
    setShowChat(isChatAvailable(config));
  }, []);

  if (contacts.length === 0) return null;

  const chatButton = (
    <>
      <span className="full">
        <ZaloContactButton
          contact={getContactForToday(contacts)}
          defaultMessage={defaultChatMessage}
        >
          <span>{t('chat_via_zalo')}</span>
        </ZaloContactButton>
      </span>
      <span className="mobile">
        <ZaloContactButton
          contact={getContactForToday(contacts)}
          defaultMessage={defaultChatMessage}
        >
          <span>{t('vb:chat')}</span>
        </ZaloContactButton>
      </span>
    </>
  );

  const enquireButton = (
    <>
      <span className="full">
        <EmailContactButton contact={contacts[0]}>
          <span>{t('vb:send_enquiry_email')}</span>
        </EmailContactButton>
      </span>
      <span className="mobile">
        <EmailContactButton contact={contacts[0]}>
          <span>{t('vb:enquire')}</span>
        </EmailContactButton>
      </span>
    </>
  );

  return (
    <div
      className="contact-container"
      onMouseLeave={() => setShowDetails(false)}
      onClick={() => setShowDetails(!showDetails)}
    >
      <button className="action-button">
        {showChat ? chatButton : enquireButton}
      </button>
      <style global jsx>
        {`
          .contact-container {
            position: relative;
          }

          .contact-container .btn-contact a {
            color: #fff;
          }
          .contact-container .btn-contact a:hover {
            text-decoration: none;
          }
          .contact-container .mobile {
            display: none;
          }

          .contact-container .full {
            display: inline-block;
          }

          @media (max-width: 768px) {
            .contact-container .mobile {
              display: inline-block;
            }

            .contact-container .full {
              display: none;
            }
            .button-options-list a svg {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ContactButtonWithZalo;

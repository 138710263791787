import { FiSearch } from 'react-icons/fi';
import { logEvent } from '../../analytics';
import { DID_CLICK_PRODUCT_SEARCH_BUTTON } from '../../utils/constants';

const ExpressSearchButton = ({
  onProductSearch
}: {
  onProductSearch: () => void;
}) => {
  return (
    <div
      className="search-express btn-express with-shadow hide-in-meeting"
      onClick={() => {
        onProductSearch();
        logEvent(DID_CLICK_PRODUCT_SEARCH_BUTTON);
      }}
    >
      <FiSearch />
      <style jsx>
        {`
          .search-express {
            color: #fff;
          }
          .search-express :global(svg) {
            cursor: pointer;
            width: 24px;
            height: 24px;
          }
        `}
      </style>
    </div>
  );
};

export default ExpressSearchButton;

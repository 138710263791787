import React from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import {
  ContactMethod,
  DeliveryMethod,
  EmailSentState,
  IMainState,
  IShoppingFormData,
  IVirtualBoutiqueShopContact,
  TimeSlot
} from '../../interfaces';
import { DID_FAIL_TO_SEND_CART, DID_SEND_CART } from '../../utils/constants';
import { getCartTotalPrice } from '../../utils/shoppingCart';
import PoweredByInspify from '../Legal/PoweredByInspify';
import PrivacyPolicy from '../Legal/PrivacyPolicy';
import TermsOfUse from '../Legal/TermsOfUse';
import {
  getIsDateAllowedByBrand,
  getMinDate
} from '../VirtualBoutique/Appointment/AppointmentDateTimeCalculator';
import {
  isAllowedForEmail,
  isAllowedForName,
  isAllowedForPhone,
  isValidAddress,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from '../VirtualBoutique/Appointment/inputValidator';
import {
  actionCartClearProducts,
  actionCartUpdateFormData,
  actionCartUpdateSendStatus
} from './../../redux/actions';
import { mapItemToCartDisplay } from './../../utils/shoppingCart';
import { isAllowedForAddress } from './../VirtualBoutique/Appointment/inputValidator';
import { sendShoppingCart } from './shoppingCartService';

interface IShoppingFormPristine {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phone: boolean;
  address: boolean;
  preferredDelivery: boolean;
  time: boolean;
  date: boolean;
  preferredContact: boolean;
  acceptedTermsOfUse: boolean;
}

export interface IShoppingFormProps {
  shopContacts: IVirtualBoutiqueShopContact[];
  storeId: string;
  brandId: string;
  phoneCountryCode: string;
  deliveryFee: number;
}

const ChopardShoppingForm = ({
  shopContacts,
  storeId,
  brandId,
  phoneCountryCode,
  deliveryFee
}: IShoppingFormProps) => {
  const store = useSelector((state: IMainState) => state.clientState?.store);
  const country = store?.countryCode;
  const dispatch = useDispatch();
  const deliveryContact = shopContacts.find(
    (c) => c.deliveryMethod === DeliveryMethod.DELIVERY
  );
  const pickupContact = shopContacts.filter(
    (c) => c.deliveryMethod === DeliveryMethod.PICKUP
  );

  const { t, i18n } = useTranslation();
  const [pristine, setPristine] = React.useState<IShoppingFormPristine>({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    address: true,
    preferredDelivery: true,
    time: true,
    date: true,
    preferredContact: true,
    acceptedTermsOfUse: true
  });

  const shoppingCartState = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const formData = shoppingCartState?.formData || {};

  const emailSentState =
    shoppingCartState?.sendStatus || EmailSentState.INITIAL;

  const setEmailSentState = (status: EmailSentState) => {
    dispatch(actionCartUpdateSendStatus(status));
  };

  const setFormData = (data: IShoppingFormData) => {
    dispatch(actionCartUpdateFormData(data));
  };

  const mandatoryFields: (keyof IShoppingFormData)[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'preferredDelivery',
    'preferredContact',
    'acceptedTermsOfUse'
  ];

  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, firstName: false });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, firstName: value });
    }
  };

  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, lastName: false });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, lastName: value });
    }
  };

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });

    if (isAllowedForPhone(value)) {
      setFormData({ ...formData, phone: value });
    }
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    if (isAllowedForEmail(value)) {
      setFormData({ ...formData, email: value });
    }
  };

  const handleContactMethod = (preferredContact: ContactMethod) => {
    setPristine({ ...pristine, preferredContact: false });
    setFormData({ ...formData, preferredContact });
  };

  const isDelivery = formData?.preferredDelivery === DeliveryMethod.DELIVERY;
  const isPickup = formData?.preferredDelivery === DeliveryMethod.PICKUP;

  const handleDeliveryMethod = (
    preferredDelivery: DeliveryMethod,
    id: string
  ) => {
    setPristine({ ...pristine, preferredDelivery: false });
    const contact = shopContacts.find((c) => c.id === id);

    if (preferredDelivery === DeliveryMethod.DELIVERY && !formData?.address) {
      setFormData({ ...formData, preferredDelivery, shopNowContact: null });
    } else {
      setFormData({ ...formData, preferredDelivery, shopNowContact: contact });
    }
  };

  const handleAddress = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, address: false });
    if (isAllowedForAddress(value)) {
      setFormData({
        ...formData,
        address: value,
        shopNowContact: value.trim().length !== 0 ? deliveryContact : null
      });
    }
  };

  const handleDate = (date: Date) => {
    setFormData({ ...formData, date: date });
  };

  const handleTime = (time: TimeSlot) => {
    setPristine({ ...pristine, time: false });
    setFormData({ ...formData, time });
  };

  const handleTC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
  };

  const handleNewsletterSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({ ...formData, subscribeNews: value });
  };

  const removePristine = () => {
    const nonPristine: IShoppingFormPristine = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      address: false,
      preferredDelivery: false,
      time: false,
      date: false,
      preferredContact: false,
      acceptedTermsOfUse: false
    };
    setPristine(nonPristine);
  };

  const handleSend = () => {
    removePristine();
    if (
      mandatoryFields.some((field) => !formData?.[field]) ||
      (formData.preferredDelivery === DeliveryMethod.DELIVERY &&
        !isValidAddress(formData?.address))
    ) {
      return;
    }

    if (
      !isValidName(formData?.firstName) ||
      !isValidName(formData?.lastName) ||
      !isValidEmail(formData?.email) ||
      !isValidPhoneNumber(formData?.phone)
    ) {
      return;
    }
    setEmailSentState(EmailSentState.SENDING);
    const cartItems = shoppingCartState.items.map((item) =>
      mapItemToCartDisplay(item, country, i18n.language)
    );
    const totalPrice = getCartTotalPrice(
      shoppingCartState.items,
      country,
      deliveryFee
    );

    sendShoppingCart(
      formData,
      cartItems,
      totalPrice,
      storeId,
      i18n.language.toUpperCase()
    )
      .then(() => {
        setEmailSentState(EmailSentState.SENT);
        dispatch(actionCartClearProducts());
        logEvent(DID_SEND_CART);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(DID_FAIL_TO_SEND_CART, DID_FAIL_TO_SEND_CART, { error });
      });
  };
  const invalidEmail = !pristine.email && !isValidEmail(formData?.email);
  const invalidPhone = !pristine.phone && !isValidPhoneNumber(formData?.phone);
  const invalidFirstName =
    !pristine.firstName && !isValidName(formData?.firstName);
  const invalidLastName =
    !pristine.lastName && !isValidName(formData?.lastName);

  React.useEffect(() => {
    const currentPreferredContact = formData?.preferredContact;
    if (
      invalidPhone &&
      (currentPreferredContact === ContactMethod.TEXT ||
        currentPreferredContact === ContactMethod.CALL)
    ) {
      setFormData({ ...formData, preferredContact: null });
    }

    if (invalidEmail && currentPreferredContact === ContactMethod.EMAIL) {
      setFormData({ ...formData, preferredContact: null });
    }
    if (!invalidEmail && invalidPhone) {
      setFormData({ ...formData, preferredContact: ContactMethod.EMAIL });
      setPristine({ ...pristine, preferredContact: false });
    }
  }, [formData?.phone, formData?.email]);

  React.useEffect(() => {
    if (!formData?.date || !formData?.time || !formData?.phoneCountryCode) {
      setFormData({
        ...formData,
        date: getMinDate(brandId, 3),
        time: TimeSlot.AFTERNOON,
        phoneCountryCode: phoneCountryCode
      });
    }
  }, []);

  return (
    <div className="CartForm">
      <form id="emailform">
        <div className="two-col">
          <div className="input-group">
            <label>{t('vb:first_name')}:</label>
            <input
              type="text"
              id="inputFirstName"
              name="firstName"
              value={formData?.firstName || ''}
              placeholder={t('vb:enter_your_first_name')}
              onChange={handleFirstName}
            />
            {invalidFirstName && (
              <span className="error"> {t('vb:required')}</span>
            )}
          </div>{' '}
          <div className="input-group">
            <label>{t('vb:last_name')}:</label>
            <input
              type="text"
              id="inputLastName"
              name="lastName"
              value={formData?.lastName || ''}
              placeholder={t('vb:enter_your_last_name')}
              onChange={handleLastName}
            />
            {invalidLastName && (
              <span className="error"> {t('vb:required')}</span>
            )}
          </div>
        </div>{' '}
        <div className="input-group">
          <label>{t('vb:phone_number')}:</label>
          <div className="phone-number-input">
            <input
              type="tel"
              id="inputcountrycode"
              name="countrycode"
              placeholder={formData?.phoneCountryCode}
              disabled
            />
            <input
              type="tel"
              id="inputphone"
              name="phone"
              placeholder={t('vb:enter_phone_number')}
              value={formData?.phone || ''}
              onChange={handlePhoneNumber}
            />
          </div>

          {invalidPhone && (
            <span className="error">
              {' '}
              {t('vb:please_enter_valid_phone_number')}
            </span>
          )}
        </div>
        <div className="input-group">
          <label>{t('email')}:</label>
          <input
            type="email"
            id="inputemail"
            name="email"
            placeholder={t('vb:enter_your_email')}
            value={formData?.email || ''}
            onChange={handleEmail}
          />
          {invalidEmail && (
            <span className="error"> {t('vb:please_enter_valid_email')}</span>
          )}
        </div>
        <div className="input-group preferred-contact">
          <label>{t('vb:preferred_contact')}:</label>
          <div className="btn-group btn-options">
            <button
              type="button"
              className={`btn ${
                formData?.preferredContact === ContactMethod.TEXT
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleContactMethod(ContactMethod.TEXT)}
              disabled={invalidPhone}
            >
              {t('vb:sms_whatsapp')}
            </button>
            <button
              type="button"
              className={`btn ${
                formData?.preferredContact === ContactMethod.CALL
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleContactMethod(ContactMethod.CALL)}
              disabled={invalidPhone}
            >
              {t('vb:call')}
            </button>
            <button
              type="button"
              className={`btn ${
                formData?.preferredContact === ContactMethod.EMAIL
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleContactMethod(ContactMethod.EMAIL)}
              disabled={invalidEmail}
            >
              {t('vb:email')}
            </button>
          </div>
          {!pristine.preferredContact && !formData?.preferredContact && (
            <span className="error">{t('vb:required')}</span>
          )}
        </div>
        <div className="input-group payment-method">
          <label>
            {t('vb:payment_mode')}: <span>{t('vb:secure_online_payment')}</span>
          </label>
          <p>{t('vb:payment_link_separately')}.</p>
        </div>
        <div className="input-group ">
          <label>{t('vb:preferred_delivery')}:</label>
          <ul className="list-group delivery-method">
            <li
              className={`list-group-item btn ${
                isDelivery ? 'btn-secondary active' : ' btn-outline-secondary'
              }`}
              onClick={() =>
                handleDeliveryMethod(
                  DeliveryMethod.DELIVERY,
                  deliveryContact.id
                )
              }
            >
              {t('vb:delivery')}
              {isDelivery && (
                <textarea
                  name="address"
                  id="inputaddress"
                  rows={10}
                  placeholder={t('vb:please_enter_address')}
                  value={formData?.address || ''}
                  onChange={handleAddress}
                />
              )}
            </li>
            {pickupContact?.length > 0 &&
              pickupContact.map((c) => (
                <li
                  key={c.id}
                  className={`list-group-item btn ${
                    formData?.shopNowContact?.id === c.id && isPickup
                      ? 'btn-secondary active'
                      : ' btn-outline-secondary'
                  }`}
                  onClick={() =>
                    handleDeliveryMethod(DeliveryMethod.PICKUP, c.id)
                  }
                >
                  {t('vb:pickup_at')} {c.name}
                </li>
              ))}
          </ul>
          {!pristine.preferredDelivery &&
            (!formData?.shopNowContact?.id || !formData?.preferredDelivery) && (
              <span className="error">
                {!formData?.preferredDelivery
                  ? t('vb:required')
                  : t('vb:please_enter_address')}
              </span>
            )}
        </div>
        <div className="input-group">
          <label>{t('vb:preferred_date')}:</label>
          <DatePicker
            selected={formData?.date}
            filterDate={getIsDateAllowedByBrand(brandId)}
            minDate={getMinDate(brandId, 3)}
            onChange={handleDate}
            locale={i18n.language}
            dateFormat="MMMM d, yyyy"
          />
        </div>
        <div className="input-group">
          <label>{t('vb:preferred_timeslot')}</label>
          <div className="btn-group btn-options">
            <button
              type="button"
              className={`btn ${
                formData?.time === TimeSlot.MORNING
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleTime(TimeSlot.MORNING)}
            >
              {t('vb:morning')}
            </button>
            <button
              type="button"
              className={`btn ${
                formData?.time === TimeSlot.AFTERNOON
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleTime(TimeSlot.AFTERNOON)}
            >
              {t('vb:afternoon')}
            </button>
            <button
              type="button"
              className={`btn ${
                formData?.time === TimeSlot.EVENING
                  ? 'btn-secondary'
                  : 'btn-outline-secondary'
              }`}
              onClick={() => handleTime(TimeSlot.EVENING)}
            >
              {t('vb:evening')}
            </button>
          </div>

          {!pristine.time && !formData?.time && (
            <span className="error">{t('vb:please_select_time')}</span>
          )}
        </div>
        <div className="input-group tstc">
          <label>
            <input type="checkbox" id="inputtstc" onChange={handleTC} />
            {!pristine.acceptedTermsOfUse && !formData.acceptedTermsOfUse && (
              <span className="error">{t('vb:please_accept_tc')}</span>
            )}
            {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
            <PrivacyPolicy />
            {t('vb:tc_part_three')}
          </label>
          <label>
            <input
              type="checkbox"
              id="inputsubscribe"
              onChange={handleNewsletterSubscription}
              checked={formData?.subscribeNews || false}
            />
            {t('vb:confirm_receive_inspify_offers')} <PrivacyPolicy />
          </label>
        </div>
        <div className="input-group">
          <button id="sendform" type="button" onClick={() => handleSend()}>
            {t(`vb:${emailSentState}`)}
          </button>
        </div>
      </form>

      <PoweredByInspify />

      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
        `}
      </style>
      <style jsx>
        {`
          .preferred-contact .btn {
            text-transform: capitalize;
          }
          .payment-method p {
            font-size: 1em;
            margin-bottom: 0;
          }
          textarea {
            background: #fff;
          }
          .full {
            display: inline-block;
          }
          .btn-options,
          .delivery-method {
            width: 100%;
          }
          .delivery-method .btn {
            text-align: left;
          }
          .btn-outline-secondary {
            border-color: #ccc;
          }
          .btn {
            border-radius: 0;
          }
          :global(.sub-tc) {
            display: block;
            margin-left: 1em;
            text-indent: -0.6em;
            padding-left: 1em;
            margin-top: 8px;
          }
          .input-group {
            margin-bottom: 20px;
          }
          :global(.appointment label) {
            margin-top: 0;
          }
          .two-col .error {
            top: 0;
          }
          .tstc label {
            padding-top: 15px;
          }

          @media (max-width: 768px) {
            .full {
              display: none;
            }

            .tstc {
              margin-top: 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ChopardShoppingForm;

import React from 'react';
import VirtualBoutiqueLite from '.';
import { hasValidSession } from '../../../advisorHub/clientSideServices/login';
import { getCorrectHubHost } from '../../../advisorHub/utils/hubPagesRoute';
import {
  IStore,
  IVirtualBoutiqueConfig,
  VirtualBoutiqueMode
} from '../../../interfaces';
import { isEmbeddedInStreamingStudio } from '../../../utils/streamingstudio';
import { goToUrl } from '../../../utils/window';
import Placeholder from '../../Placeholder';

const PrivateVirtualBoutiqueLite = ({
  config,
  store,
  vbMode
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
  vbMode: VirtualBoutiqueMode;
}) => {
  const [shouldShowVB, setShouldShowVB] = React.useState(false);
  React.useEffect(() => {
    if (isEmbeddedInStreamingStudio()) {
      setShouldShowVB(true);
      return;
    }
    hasValidSession().then((isValid) => {
      const showContent = isValid || vbMode === VirtualBoutiqueMode.COCKPIT;
      setShouldShowVB(showContent);
      if (!showContent)
        goToUrl(`https://${getCorrectHubHost(config.brandId)}/hub`);
    });
  }, []);
  return shouldShowVB ? (
    <VirtualBoutiqueLite config={config} store={store} vbMode={vbMode} />
  ) : (
    <Placeholder />
  );
};

export default PrivateVirtualBoutiqueLite;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMeeting, Source } from '../../../interfaces';
import { actionOpenVBPopup } from '../../../redux/actions';
import {
  DID_CLICK_PRODUCT_COMPARE_BUTTON,
  DID_CLICK_PRODUCT_SEARCH_BUTTON,
  DID_CLICK_STORY_SEARCH_BUTTON
} from '../../../utils/constants';
import { getCurrentHost } from '../../../utils/window';
import { ViewportContext } from '../MeetingLayout';
import MeetingButtonWithIcon from './MeetingButtonWithIcon';
import { useTranslation } from '../../../i18n';
import {
  IconProductSearch,
  IconStorySearch,
  IconProductCompare
} from './MeetingIcons';
import {
  chopardHappySportGenevaHQ,
  chopardHappySportKorea,
  vanCleefArpelsUsKingOfPrussiaSiteId,
  vanCleefArpelsUsSiliconValleyFairSiteId
} from '../../../config';

const AdvisorAccessControls = ({
  meeting,
  brandId,
  storeId,
  onCloseMenu
}: {
  meeting: IMeeting;
  brandId: string;
  storeId: string;
  onCloseMenu: () => void;
}) => {
  const dispatch = useDispatch();
  const { isSmallScreen } = React.useContext(ViewportContext);
  const { i18n } = useTranslation();
  const language = `${i18n?.language || 'en'}`;

  const shouldCloseMenu = () => {
    if (isSmallScreen) onCloseMenu();
    if (storeId === chopardHappySportKorea || storeId === chopardHappySportGenevaHQ) {
      document.getElementById('chsps-map-close-btn')?.click();
    }
  };

  const isVCASiliconValley =
    storeId === vanCleefArpelsUsSiliconValleyFairSiteId ||
    storeId === vanCleefArpelsUsKingOfPrussiaSiteId;

  const { t } = useTranslation();
  const searchProduct = (
    <MeetingButtonWithIcon
      id="searchProduct"
      className="tile"
      onClick={() => {
        logEvent(
          DID_CLICK_PRODUCT_SEARCH_BUTTON,
          DID_CLICK_PRODUCT_SEARCH_BUTTON,
          { meetingId: meeting.meetingId }
        );
        dispatch(
          actionOpenVBPopup(
            `${getCurrentHost()}/productsearch/${brandId}?source=${
              Source.Showroom
            }&store=${storeId}&language=${language}`,
            true
          )
        );
        shouldCloseMenu();
      }}
    >
      <IconProductSearch />
      <span>{t('search_products').toUpperCase()}</span>
    </MeetingButtonWithIcon>
  );

  const compareProduct = (
    <MeetingButtonWithIcon
      id="compareProduct"
      className="tile"
      onClick={() => {
        logEvent(
          DID_CLICK_PRODUCT_COMPARE_BUTTON,
          DID_CLICK_PRODUCT_COMPARE_BUTTON,
          { meetingId: meeting.meetingId }
        );
        dispatch(
          actionOpenVBPopup(
            `${getCurrentHost()}/productsearch/${brandId}?compare=true&source=${
              Source.Showroom
            }&store=${storeId}&language=${language}`,
            true
          )
        );
        shouldCloseMenu();
      }}
    >
      <IconProductCompare />
      <span>{t('compare_products')}</span>
    </MeetingButtonWithIcon>
  );

  const searchStories = (
    <MeetingButtonWithIcon
      id="searchStories"
      className="tile"
      onClick={() => {
        logEvent(DID_CLICK_STORY_SEARCH_BUTTON, DID_CLICK_STORY_SEARCH_BUTTON, {
          meetingId: meeting.meetingId
        });
        dispatch(
          actionOpenVBPopup(
            `${getCurrentHost()}/storysearch/${brandId}?source=${
              Source.Showroom
            }&store=${storeId}&language=${language}`,
            true
          )
        );

        shouldCloseMenu();
      }}
    >
      <IconStorySearch />
      <span>{t('story_search')}</span>
    </MeetingButtonWithIcon>
  );

  return (
    <>
      {searchProduct}
      {!isVCASiliconValley && compareProduct}
      {searchStories}
    </>
  );
};

export default AdvisorAccessControls;

import React from 'react';
import { CgShoppingCart } from 'react-icons/cg';
import { useTranslation } from '../../i18n';

const CartEmpty = ({
  onClose,
  onProductSearch
}: {
  onClose: () => void;
  onProductSearch: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="CartEmpty success text-center">
      <div className="icon-container">
        <CgShoppingCart />
      </div>
      <h2>{t('vb:cart_empty')}</h2>
      <button
        className="btn-action"
        type="button"
        onClick={() => {
          onClose();
          onProductSearch();
        }}
      >
        {t('vb:browse_products')}
      </button>
      <style jsx>{`
        .icon-container :global(svg) {
          width: 150px;
          height: auto;
        }
        .btn-action {
          width: 300px !important;
        }
        .CartEmpty {
          margin: auto;
        }
      `}</style>
    </div>
  );
};

export default CartEmpty;

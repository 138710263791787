import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  IMainState,
  JitsiLocalTrack,
  JitsiVideoTrackEffect
} from '../../../interfaces';
import { disposeTrackIfNeeded, getVideoStreamEffects } from '../utils/track';
import { toggleBackgroundEffect } from './setVirtualBackground';

function VirtualBackgroundPreview({
  options,
  track,
  setTrack,
  loading,
  setLoading
}: {
  options: JitsiVideoTrackEffect;
  track: JitsiLocalTrack;
  setTrack: (track: JitsiLocalTrack) => void;
  loading: boolean;
  setLoading: (bool: boolean) => void;
}) {
  const localUser = useSelector(
    (state: IMainState) => state.clientState?.meeting?.localUser
  );
  const currentDevice = localUser?.activeDevices?.camera;
  const localVideoRef = React.useRef();
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    getVideoStreamEffects([options])
      .then((effects) =>
        JitsiMeetJS.createLocalTracks({
          devices: ['video'],
          cameraDeviceId: currentDevice || '',
          facingMode: 'user',
          effects
        })
      )
      .then((_track) => {
        if (!isMounted.current) disposeTrackIfNeeded(_track[0]);
        else setTrack(_track[0]);
      })
      .catch((err) => {
        console.log('error when creating jitsi track', err);
      });
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setVirtualBackground = async (videoTrack) => {
    setLoading(true);
    toggleBackgroundEffect(options, videoTrack).finally(() => {
      //FIXME: there is a time delay between the replace
      //current track with effected track
      //It causes the preview to not be updated when client hit apply bg
      //This is a hacky fix to prevent client click too fast
      setTimeout(() => setLoading(false), 1000);
    });
  };
  React.useEffect(() => {
    setVirtualBackground(track);
  }, [options]);

  React.useEffect(() => {
    if (track) {
      track?.attach(localVideoRef.current);
    }
    return () => {
      localVideoRef.current && track?.detach(localVideoRef.current);
    };
  }, [track]);

  return (
    <>
      <div className="vb-preview">
        {(loading || !track) && (
          <div className="loader d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" />
          </div>
        )}
        <video
          autoPlay
          playsInline
          ref={localVideoRef}
          id="local-video"
          className={`local-video ${loading && 'disabled'}`}
        ></video>
      </div>
      <style jsx>
        {`
          video {
            width: 100%;
            height: 100%;
          }
          video.disabled {
            opacity: 0;
          }
          .loader {
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 3;
            left: 0;
            right: 0;
            background: #c4c4c4;
          }
        `}
      </style>
    </>
  );
}

export default VirtualBackgroundPreview;

export const abuDhabi = 'iwc-dubai:cities.abuDhabi';
export const ajman = 'iwc-dubai:cities.ajman';
export const dubai = 'iwc-dubai:cities.dubai';
export const fujairah = 'iwc-dubai:cities.fujairah';
export const rasAlKhaimah = 'iwc-dubai:cities.rasAlKhaimah';
export const sharjah = 'iwc-dubai:cities.sharjah';
export const ummAlQuwain = 'iwc-dubai:cities.ummAlQuwain';

export const allCities = [
  abuDhabi,
  ajman,
  dubai,
  fujairah,
  rasAlKhaimah,
  sharjah,
  ummAlQuwain
];

export const abuDhabiAreas = [
  'iwc-dubai:areas.area-1',
  'iwc-dubai:areas.area-2',
  'iwc-dubai:areas.area-3',
  'iwc-dubai:areas.area-4',
  'iwc-dubai:areas.area-5',
  'iwc-dubai:areas.area-6',
  'iwc-dubai:areas.area-7',
  'iwc-dubai:areas.area-8',
  'iwc-dubai:areas.area-9',
  'iwc-dubai:areas.area-10',
  'iwc-dubai:areas.area-11',
  'iwc-dubai:areas.area-12',
  'iwc-dubai:areas.area-13',
  'iwc-dubai:areas.area-14',
  'iwc-dubai:areas.area-15',
  'iwc-dubai:areas.area-16',
  'iwc-dubai:areas.area-17',
  'iwc-dubai:areas.area-18',
  'iwc-dubai:areas.area-19',
  'iwc-dubai:areas.area-20',
  'iwc-dubai:areas.area-21',
  'iwc-dubai:areas.area-22',
  'iwc-dubai:areas.area-23',
  'iwc-dubai:areas.area-24',
  'iwc-dubai:areas.area-25',
  'iwc-dubai:areas.area-26',
  'iwc-dubai:areas.area-27',
  'iwc-dubai:areas.area-28',
  'iwc-dubai:areas.area-29',
  'iwc-dubai:areas.area-30',
  'iwc-dubai:areas.area-31',
  'iwc-dubai:areas.area-32',
  'iwc-dubai:areas.area-33',
  'iwc-dubai:areas.area-34',
  'iwc-dubai:areas.area-35',
  'iwc-dubai:areas.area-36',
  'iwc-dubai:areas.area-37',
  'iwc-dubai:areas.area-38',
  'iwc-dubai:areas.area-39',
  'iwc-dubai:areas.area-40',
  'iwc-dubai:areas.area-41',
  'iwc-dubai:areas.area-42',
  'iwc-dubai:areas.area-43',
  'iwc-dubai:areas.area-44',
  'iwc-dubai:areas.area-45',
  'iwc-dubai:areas.area-46',
  'iwc-dubai:areas.area-47',
  'iwc-dubai:areas.area-48',
  'iwc-dubai:areas.area-49',
  'iwc-dubai:areas.area-50',
  'iwc-dubai:areas.area-51',
  'iwc-dubai:areas.area-52',
  'iwc-dubai:areas.area-53',
  'iwc-dubai:areas.area-54',
  'iwc-dubai:areas.area-55',
  'iwc-dubai:areas.area-56',
  'iwc-dubai:areas.area-57',
  'iwc-dubai:areas.area-58',
  'iwc-dubai:areas.area-59',
  'iwc-dubai:areas.area-60',
  'iwc-dubai:areas.area-61',
  'iwc-dubai:areas.area-62',
  'iwc-dubai:areas.area-63',
  'iwc-dubai:areas.area-64',
  'iwc-dubai:areas.area-65'
];

export const ajmanAreas = [
  'iwc-dubai:areas.area-66',
  'iwc-dubai:areas.area-67',
  'iwc-dubai:areas.area-68',
  'iwc-dubai:areas.area-69',
  'iwc-dubai:areas.area-70'
];

export const dubaiAreas = [
  'iwc-dubai:areas.area-71',
  'iwc-dubai:areas.area-72',
  'iwc-dubai:areas.area-73',
  'iwc-dubai:areas.area-74',
  'iwc-dubai:areas.area-75',
  'iwc-dubai:areas.area-76',
  'iwc-dubai:areas.area-77',
  'iwc-dubai:areas.area-78',
  'iwc-dubai:areas.area-79',
  'iwc-dubai:areas.area-80',
  'iwc-dubai:areas.area-81',
  'iwc-dubai:areas.area-82',
  'iwc-dubai:areas.area-83',
  'iwc-dubai:areas.area-84',
  'iwc-dubai:areas.area-85',
  'iwc-dubai:areas.area-86',
  'iwc-dubai:areas.area-87',
  'iwc-dubai:areas.area-88',
  'iwc-dubai:areas.area-89',
  'iwc-dubai:areas.area-90',
  'iwc-dubai:areas.area-91',
  'iwc-dubai:areas.area-92',
  'iwc-dubai:areas.area-93',
  'iwc-dubai:areas.area-94',
  'iwc-dubai:areas.area-95',
  'iwc-dubai:areas.area-96',
  'iwc-dubai:areas.area-97',
  'iwc-dubai:areas.area-98',
  'iwc-dubai:areas.area-99',
  'iwc-dubai:areas.area-100',
  'iwc-dubai:areas.area-101',
  'iwc-dubai:areas.area-102',
  'iwc-dubai:areas.area-103',
  'iwc-dubai:areas.area-104',
  'iwc-dubai:areas.area-105',
  'iwc-dubai:areas.area-106',
  'iwc-dubai:areas.area-107',
  'iwc-dubai:areas.area-108',
  'iwc-dubai:areas.area-109',
  'iwc-dubai:areas.area-110',
  'iwc-dubai:areas.area-111',
  'iwc-dubai:areas.area-112',
  'iwc-dubai:areas.area-113',
  'iwc-dubai:areas.area-114',
  'iwc-dubai:areas.area-115',
  'iwc-dubai:areas.area-116',
  'iwc-dubai:areas.area-117',
  'iwc-dubai:areas.area-118',
  'iwc-dubai:areas.area-119',
  'iwc-dubai:areas.area-120',
  'iwc-dubai:areas.area-121',
  'iwc-dubai:areas.area-122',
  'iwc-dubai:areas.area-123',
  'iwc-dubai:areas.area-124',
  'iwc-dubai:areas.area-125',
  'iwc-dubai:areas.area-126',
  'iwc-dubai:areas.area-127',
  'iwc-dubai:areas.area-128',
  'iwc-dubai:areas.area-129',
  'iwc-dubai:areas.area-130',
  'iwc-dubai:areas.area-131',
  'iwc-dubai:areas.area-132',
  'iwc-dubai:areas.area-133',
  'iwc-dubai:areas.area-134',
  'iwc-dubai:areas.area-135',
  'iwc-dubai:areas.area-136',
  'iwc-dubai:areas.area-137',
  'iwc-dubai:areas.area-138',
  'iwc-dubai:areas.area-139',
  'iwc-dubai:areas.area-140',
  'iwc-dubai:areas.area-141',
  'iwc-dubai:areas.area-142',
  'iwc-dubai:areas.area-143',
  'iwc-dubai:areas.area-144',
  'iwc-dubai:areas.area-145',
  'iwc-dubai:areas.area-146',
  'iwc-dubai:areas.area-147',
  'iwc-dubai:areas.area-148',
  'iwc-dubai:areas.area-149',
  'iwc-dubai:areas.area-150',
  'iwc-dubai:areas.area-151',
  'iwc-dubai:areas.area-152',
  'iwc-dubai:areas.area-153',
  'iwc-dubai:areas.area-154',
  'iwc-dubai:areas.area-155',
  'iwc-dubai:areas.area-156',
  'iwc-dubai:areas.area-157',
  'iwc-dubai:areas.area-158',
  'iwc-dubai:areas.area-159',
  'iwc-dubai:areas.area-160',
  'iwc-dubai:areas.area-161',
  'iwc-dubai:areas.area-162',
  'iwc-dubai:areas.area-163',
  'iwc-dubai:areas.area-164',
  'iwc-dubai:areas.area-165',
  'iwc-dubai:areas.area-166',
  'iwc-dubai:areas.area-167',
  'iwc-dubai:areas.area-168',
  'iwc-dubai:areas.area-169',
  'iwc-dubai:areas.area-170',
  'iwc-dubai:areas.area-171',
  'iwc-dubai:areas.area-172',
  'iwc-dubai:areas.area-173',
  'iwc-dubai:areas.area-174',
  'iwc-dubai:areas.area-175',
  'iwc-dubai:areas.area-176',
  'iwc-dubai:areas.area-177',
  'iwc-dubai:areas.area-178',
  'iwc-dubai:areas.area-179',
  'iwc-dubai:areas.area-180',
  'iwc-dubai:areas.area-181',
  'iwc-dubai:areas.area-182',
  'iwc-dubai:areas.area-183',
  'iwc-dubai:areas.area-184',
  'iwc-dubai:areas.area-185',
  'iwc-dubai:areas.area-186',
  'iwc-dubai:areas.area-187',
  'iwc-dubai:areas.area-188',
  'iwc-dubai:areas.area-189',
  'iwc-dubai:areas.area-190',
  'iwc-dubai:areas.area-191',
  'iwc-dubai:areas.area-192',
  'iwc-dubai:areas.area-193',
  'iwc-dubai:areas.area-194'
];

export const fujairahAreas = [
  'iwc-dubai:areas.area-195',
  'iwc-dubai:areas.area-196',
  'iwc-dubai:areas.area-197'
];

export const rasAlKhaimahAreas = [
  'iwc-dubai:areas.area-198',
  'iwc-dubai:areas.area-199',
  'iwc-dubai:areas.area-200',
  'iwc-dubai:areas.area-201'
];

export const sharjahAreas = [
  'iwc-dubai:areas.area-202',
  'iwc-dubai:areas.area-203',
  'iwc-dubai:areas.area-204',
  'iwc-dubai:areas.area-205',
  'iwc-dubai:areas.area-206',
  'iwc-dubai:areas.area-207',
  'iwc-dubai:areas.area-208',
  'iwc-dubai:areas.area-209',
  'iwc-dubai:areas.area-210',
  'iwc-dubai:areas.area-211',
  'iwc-dubai:areas.area-212',
  'iwc-dubai:areas.area-213',
  'iwc-dubai:areas.area-214',
  'iwc-dubai:areas.area-215',
  'iwc-dubai:areas.area-216',
  'iwc-dubai:areas.area-217',
  'iwc-dubai:areas.area-218',
  'iwc-dubai:areas.area-219',
  'iwc-dubai:areas.area-220',
  'iwc-dubai:areas.area-221',
  'iwc-dubai:areas.area-222',
  'iwc-dubai:areas.area-223',
  'iwc-dubai:areas.area-224',
  'iwc-dubai:areas.area-225',
  'iwc-dubai:areas.area-226',
  'iwc-dubai:areas.area-227',
  'iwc-dubai:areas.area-228',
  'iwc-dubai:areas.area-229',
  'iwc-dubai:areas.area-230',
  'iwc-dubai:areas.area-231',
  'iwc-dubai:areas.area-232',
  'iwc-dubai:areas.area-233',
  'iwc-dubai:areas.area-234',
  'iwc-dubai:areas.area-235',
  'iwc-dubai:areas.area-236',
  'iwc-dubai:areas.area-237',
  'iwc-dubai:areas.area-238',
  'iwc-dubai:areas.area-239',
  'iwc-dubai:areas.area-240',
  'iwc-dubai:areas.area-241',
  'iwc-dubai:areas.area-242',
  'iwc-dubai:areas.area-243',
  'iwc-dubai:areas.area-244',
  'iwc-dubai:areas.area-245',
  'iwc-dubai:areas.area-246',
  'iwc-dubai:areas.area-247',
  'iwc-dubai:areas.area-248',
  'iwc-dubai:areas.area-249',
  'iwc-dubai:areas.area-250',
  'iwc-dubai:areas.area-251',
  'iwc-dubai:areas.area-252',
  'iwc-dubai:areas.area-253',
  'iwc-dubai:areas.area-254',
  'iwc-dubai:areas.area-255',
  'iwc-dubai:areas.area-256',
  'iwc-dubai:areas.area-257',
  'iwc-dubai:areas.area-258',
  'iwc-dubai:areas.area-259',
  'iwc-dubai:areas.area-260',
  'iwc-dubai:areas.area-261',
  'iwc-dubai:areas.area-262'
];

export const ummAlQuwainCities = [
  'iwc-dubai:areas.area-263',
  'iwc-dubai:areas.area-264',
  'iwc-dubai:areas.area-265'
];

export const allAreas = [
  ...abuDhabiAreas,
  ...ajmanAreas,
  ...dubaiAreas,
  ...fujairahAreas,
  ...rasAlKhaimahAreas,
  ...sharjahAreas,
  ...ummAlQuwainCities
].sort();

export const getCityForArea = (area: string) => {
  if (abuDhabiAreas.includes(area)) {
    return abuDhabi;
  }

  if (ajmanAreas.includes(area)) {
    return ajman;
  }

  if (dubaiAreas.includes(area)) {
    return dubai;
  }

  if (fujairahAreas.includes(area)) {
    return fujairah;
  }

  if (rasAlKhaimahAreas.includes(area)) {
    return rasAlKhaimah;
  }

  if (sharjahAreas.includes(area)) {
    return sharjah;
  }

  if (ummAlQuwain.includes(area)) {
    return ummAlQuwain;
  }

  return null;
};

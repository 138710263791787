import { useRouter } from 'next/router';
import React, { ChangeEvent } from 'react';
import { Spinner } from 'react-bootstrap';
import { BiVideo, BiVideoOff } from 'react-icons/bi';
import { BsChevronCompactDown } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import {
  DevicePermissionStatus,
  IDevicePermission,
  IMainState,
  IVirtualBoutiqueConfig,
  MeetingRole,
  ParticipantMeetingState
} from '../../../interfaces';
import { actionEnterMeeting } from '../../../redux/actions';
import { actionPlayIntroductionVideo } from '../../../redux/customActions/loubiFuture';
import {
  DID_CLICK_JOIN_NOW,
  DID_ENTER_MEETING_FROM_LOUNGE
} from '../../../utils/constants';
import {
  isUserOnMobileOnly,
  isUserOnMobileSafari15
} from '../../../utils/deviceDetector';
import {
  getMeetingRole,
  isUnsupportedBrowser,
  meetingColor
} from '../../../utils/meeting';
import { LoubiAirFontFamily } from '../../BrandStyle/LouboutinStyle';
import { useLoubiFutureState } from '../../hooks/loubiFuture';
import { getLoungeWelcomeMessage } from '../../storeComponentFactory';
import LFNeonButton from '../../VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/Common/LFNeonButton';
import LFLandingVideo from '../../VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/LFLandingVideo';
import LoubiFuturePageContainer from '../../VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/LoubiFuturePageContainer';
import DeviceControl from '../Controls/DevicesControl';
import LocalVideo from '../MeetingVideos/LocalVideo';
import { MeetingContext } from '../MeetingContext';
import { SessionContext } from '../../VirtualBoutique/SessionContextContainer';
import { useMeetingDebugLogger } from '../../hooks/meeting';
import { DID_LOAD_LOUNGE } from '../../../utils/events/meeting';
import BrowserPrompt from '../BrowserPrompt';
import LoungeParticipantsOverview from './LoungeParticipantsOverview';

const LoubiFutureLounge = ({
  config,
  storeId,
  permissionStatus,
  isBrowserSupported = true,
  onToggleMuteVideo,
  joinRoom
}: {
  config: IVirtualBoutiqueConfig;
  storeId: string;
  permissionStatus?: IDevicePermission;
  isBrowserSupported?: boolean;
  onToggleMuteVideo: (mute: boolean) => void;
  joinRoom: () => void;
}) => {
  const router = useRouter();
  const source = router.query?.source as string | undefined;
  const role = router.query?.role as string | undefined;
  const meetingRole = getMeetingRole(role);
  const inputRef = React.useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const clientState = useSelector((state: IMainState) => state.clientState);
  const user = clientState?.user;
  const meeting = clientState?.meeting;
  const devices = meeting?.localUser?.availableDevices;
  const shouldShowIntroVideos =
    useLoubiFutureState()?.playIntroductionVideosInMeeting;
  const landingVideoHidden = useLoubiFutureState()?.landingVideo?.hidden;
  const inspirations = clientState?.inspirationsList || [];
  const remoteUsers = meeting?.remoteUsers || {};
  const [pristine, setPristine] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isMobileSafari15, setIsMobileSafari15] = React.useState(false);
  const [isOnMobile, setIsOnMobile] = React.useState(false);
  const [isJoining, setIsJoining] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const shouldJoinRoomInstantly = !(
    meetingRole === MeetingRole.MC || meetingRole === MeetingRole.CLIENT
  );

  const [isReadyToJoin, setIsReadyToJoin] = React.useState(
    shouldJoinRoomInstantly
  );
  const shouldDisplayJoinButton =
    !isReadyToJoin &&
    (meetingRole === MeetingRole.MC || meetingRole === MeetingRole.CLIENT);

  const { room, connection, setUserName } = React.useContext(MeetingContext);
  const { shouldMuteVideo } = React.useContext(SessionContext);
  const loubiFutureFont = LoubiAirFontFamily();
  const isRejectCamera = React.useMemo(
    () => permissionStatus.video === DevicePermissionStatus.REJECTED,
    [permissionStatus.video]
  );
  const meetingDebugLogger = useMeetingDebugLogger();
  const permission = React.useMemo(() => {
    const isWaitingPermission = Object.values(permissionStatus).every(
      (p) => p === DevicePermissionStatus.WAITING
    );
    if (isWaitingPermission) {
      return DevicePermissionStatus.WAITING;
    }
    const isRejectedPermission = Object.values(permissionStatus).every(
      (p) => p === DevicePermissionStatus.REJECTED
    );
    if (isRejectedPermission) {
      return DevicePermissionStatus.REJECTED;
    }
    return DevicePermissionStatus.GRANTED;
  }, [permissionStatus]);

  const muteVideo = meeting?.localUser?.videoMuted || isRejectCamera;
  const shouldShowToggleMuteVideo = !shouldMuteVideo && !isRejectCamera;

  React.useEffect(() => {
    setIsOnMobile(isUserOnMobileOnly());
    setIsMobileSafari15(isUserOnMobileSafari15());
    meetingDebugLogger(DID_LOAD_LOUNGE);
  }, []);

  React.useEffect(() => {
    if (landingVideoHidden) {
      dispatch(actionEnterMeeting());
      logEvent(DID_ENTER_MEETING_FROM_LOUNGE, DID_ENTER_MEETING_FROM_LOUNGE, {
        meetingId: meeting.meetingId,
        participantId: room?.myUserId(),
        isPresenter: meeting.isPresenter
      });
    }
  }, [landingVideoHidden]);

  React.useEffect(() => {
    if (user?.alias && role !== 'studio') {
      setPristine(true);
      setLoading(false);
    }
  }, [user?.alias]);

  React.useEffect(() => {
    if (meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE) {
      room?.setDisplayName(user?.alias);
      setIsReadyToJoin(true);
      setIsJoining(false);
    }
  }, [meeting?.state]);

  const { t } = useTranslation();
  const btnJoinText = !isRejectCamera
    ? t('join_now')
    : t('join_now_without_camera');
  const btnStartText = !isRejectCamera ? 'START' : 'START WITHOUT CAMERA';

  const handleNameInput = (name: string) => {
    const hasInput = name && name.trim().length > 0;
    if (!hasInput) {
      inputRef.current.value = user?.alias || '';
      return;
    }
    if (hasInput && name === user?.alias) {
      setPristine(true);
    }
    if (hasInput && name !== user?.alias) {
      setLoading(true);
      setUserName(name);
    }
  };

  const handleDisplayNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    handleNameInput(name);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const name = inputRef.current?.value;
      handleNameInput(name);
    }
  };

  const isAdvisorReady =
    meeting?.localUser?.tracks?.length >= 1 &&
    meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE &&
    meeting?.localUser?.role === MeetingRole.ADVISOR;

  const handleToggleVideo = (mute: boolean) => {
    onToggleMuteVideo(mute);
  };

  const shouldRenderPrompt = React.useMemo(() => isUnsupportedBrowser(), []);

  if (shouldShowIntroVideos) {
    return (
      <LFLandingVideo
        isOnMobile={isOnMobile}
        disableSkip={role !== 'advisor'}
      />
    );
  }

  return (
    <div className={`lf-meeting-lounge ${meeting?.isPresenter && 'presenter'}`}>
      {meeting?.isPresenter && (
        <LoungeParticipantsOverview
          remoteUsers={remoteUsers}
          breakPoint={1023}
        />
      )}

      <LoubiFuturePageContainer
        className={`loubiFutureLounge ${meeting?.isPresenter && 'presenter'}`}
      >
        <div
          className={`meeting-lounge ${meeting?.isPresenter && 'presenter'}`}
        >
          <div className="content-container">
            <div className="content-inner">
              <h2>
                {getLoungeWelcomeMessage(
                  storeId,
                  config.brandName,
                  config.loungeTagLine,
                  source,
                  t
                )}
              </h2>

              {shouldRenderPrompt ? (
                <BrowserPrompt />
              ) : (
                <>
                  <div className="info-container mobile-control">
                    {!!devices?.length && (
                      <DeviceControl
                        controlSelectorPrefix="LF_Mobile"
                        devices={devices}
                      />
                    )}
                  </div>
                  <div className="cassete-container">
                    <div className="info-container">
                      {!!devices?.length && (
                        <DeviceControl
                          controlSelectorPrefix="LF_Desktop"
                          devices={devices}
                        />
                      )}
                    </div>

                    <div className="media-container">
                      {!isBrowserSupported &&
                      permission === DevicePermissionStatus.WAITING ? (
                        <div className="checking-message-container">
                          <p
                            className="checking-message text-center"
                            style={{ padding: '20px 40px' }}
                          >
                            <strong>{t('checking_devices')}</strong>
                            <span className="unsupported-message">
                              {' '}
                              {t('vb:walkthrough_browser_support.line1')}
                              {` `}
                              <a
                                style={{
                                  color: 'inherit',
                                  textDecoration: 'underline'
                                }}
                                href="https://www.google.com/chrome/"
                                target="_blank"
                              >
                                {t('vb:walkthrough_browser_support.line2')}
                              </a>
                            </span>
                          </p>
                        </div>
                      ) : (
                        <>
                          {permission === DevicePermissionStatus.GRANTED && (
                            <>
                              <div
                                className={`inner ${
                                  muteVideo ? 'video-muted' : ''
                                }`}
                              >
                                <div className="video-container">
                                  <LocalVideo />
                                </div>
                                <div className="media-text">
                                  <p className="reminder">
                                    {t('please_enter_your_name')}
                                  </p>
                                </div>
                              </div>
                              <div className="media-text">
                                <p className="reminder">
                                  {t('please_enter_your_name')}
                                </p>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="input-name"
                                    ref={inputRef}
                                    defaultValue={user?.alias}
                                    onBlur={handleDisplayNameChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder={t('enter_your_name')}
                                  />
                                  <LFNeonButton
                                    name="cameraToggleButton"
                                    className={`btn btn-sm ml-2 form-input-control  ${
                                      pristine ? '' : 'btn-dark'
                                    }`}
                                    onClick={() => null}
                                  >
                                    {loading && (
                                      <Spinner animation="border" size="sm" />
                                    )}
                                    {!loading && !pristine && t('ok')}
                                    {!loading && pristine && (
                                      <FaCheck color="green" />
                                    )}
                                  </LFNeonButton>
                                </div>
                                {shouldShowToggleMuteVideo && (
                                  <div className="btn-group toggle-buttons-wrapper">
                                    <LFNeonButton
                                      name="cameraToggleButton"
                                      className={`btn-toggle-camera btn-toggle-camera-margin-right ${
                                        muteVideo && 'active'
                                      }`}
                                      onClick={() => {
                                        handleToggleVideo(false);
                                      }}
                                      noBorder={muteVideo}
                                    >
                                      <BiVideo />
                                    </LFNeonButton>
                                    <LFNeonButton
                                      name="cameraToggleButton"
                                      className={`btn-toggle-camera ${
                                        !muteVideo && 'active'
                                      }`}
                                      onClick={() => {
                                        handleToggleVideo(true);
                                      }}
                                      noBorder={!muteVideo}
                                    >
                                      <BiVideoOff />
                                    </LFNeonButton>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                          {permission === DevicePermissionStatus.REJECTED && (
                            <p
                              className="input-error text-center"
                              style={{ padding: '20px 40px', color: '#fff' }}
                            >
                              {t('input_device_error')}
                            </p>
                          )}
                          {permission === DevicePermissionStatus.WAITING && (
                            <>
                              <div
                                className="checking-message text-center"
                                style={{ padding: '20px 40px', width: '100%' }}
                              >
                                <Spinner animation="border" />
                                <br />
                                {t('waiting_devices_permission')}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="cassete-actions">
                      {meeting?.isPresenter &&
                        permission === DevicePermissionStatus.GRANTED && (
                          <div className="meeting-start-button-container">
                            <LFNeonButton
                              disabled={!isAdvisorReady}
                              name="meetingStartButton"
                              className="meeting-start-button"
                              size="lg"
                              onClick={() => {
                                if (!user?.alias) {
                                  setShowError(true);
                                  return;
                                }
                                dispatch(actionPlayIntroductionVideo());
                                logEvent(
                                  DID_ENTER_MEETING_FROM_LOUNGE,
                                  DID_ENTER_MEETING_FROM_LOUNGE,
                                  {
                                    meetingId: meeting.meetingId,
                                    participantId: room?.myUserId(),
                                    isPresenter: meeting.isPresenter
                                  }
                                );
                              }}
                            >
                              {isAdvisorReady ? (
                                <span className="LFTitle">{btnStartText}</span>
                              ) : (
                                <Spinner animation="border" size="sm" />
                              )}
                            </LFNeonButton>
                          </div>
                        )}
                      {!meeting?.isPresenter && (
                        <div className="client-note-text">
                          {shouldDisplayJoinButton ? (
                            <div>
                              <LFNeonButton
                                disabled={!connection}
                                name="meetingStartButton"
                                className="meeting-start-button"
                                onClick={() => {
                                  if (!user?.alias) {
                                    setShowError(true);
                                    return;
                                  }
                                  logEvent(
                                    DID_CLICK_JOIN_NOW,
                                    DID_CLICK_JOIN_NOW,
                                    {
                                      meetingId: meeting.meetingId,
                                      participantId: room?.myUserId(),
                                      isPresenter: meeting.isPresenter
                                    }
                                  );
                                  joinRoom();
                                  setIsJoining(true);
                                }}
                              >
                                {isJoining || !connection ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <span>{btnJoinText}</span>
                                )}
                              </LFNeonButton>
                            </div>
                          ) : (
                            t('meeting_will_start_shortly')
                          )}
                        </div>
                      )}
                    </div>
                    {showError && (
                      <div
                        className="text-center"
                        style={{
                          color: meetingColor.warning
                        }}
                      >
                        {t('please_enter_your_name')}
                      </div>
                    )}
                  </div>

                  {isMobileSafari15 && (
                    <p className="lounge-prompt">
                      {t('please_use_headset_ios15')}
                    </p>
                  )}
                  {isRejectCamera && (
                    <p className="lounge-prompt">
                      {t('your_camera_is_not_accessible')}
                    </p>
                  )}
                  {!!inspirations.length && (
                    <div className="client-note">
                      <a href="#inspirationsSection">
                        <BsChevronCompactDown />
                      </a>
                    </div>
                  )}
                  <div>
                    <img
                      className="logo"
                      src={`https://inspify-panos.s3.ap-southeast-1.amazonaws.com/virtual_boutique/d430a5c8-0857-4398-9dba-622b42262f41/logo-light_v2.png`}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <style jsx global>{`
            .mobile-control {
              color: #fff;
            }
            .mobile-control .button-container .meeting-button,
            .mobile-control .button-container .meeting-button span {
              border-color: #fff !important;
              color: #fff !important;
            }
            .mobile-control .button-container .meeting-button svg path {
              fill: #fff !important;
            }
            .lf-meeting-lounge {
              background: #fff url(/asset/loubifuture/Lounge-BG.png) center
                center no-repeat fixed;
              background-size: cover;
              background-position: left bottom;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1000;
            }
            h1 {
              font-family: ${loubiFutureFont};
            }
            .loubiFutureLounge .content {
              padding-top: 0 !important;
              padding-bottom: 10px !important;
            }
            .loubiFutureLounge {
              overflow: auto;
              z-index: 2002;
            }
            .loubiFutureLounge .DeviceDropdown {
              border-bottom: 2px solid #13fff1;
            }

            .loubiFutureLounge .dropdown-toggle {
              background-color: transparent !important;
              border: none !important;
              color: #13fff1 !important;
              padding-right: 0;
            }
            .loubiFutureLounge .text-left.btn.btn-light {
              color: #13fff1;
              background-color: transparent;
              border: none;
              font-family: 'Antique Olive', sans-serif;
              padding-left: 0;
            }
            .loubiFutureLounge .dropdown-toggle:focus,
            .loubiFutureLounge .text-left.btn.btn-light:focus {
              box-shadow: none !important;
            }
            .video-container .display-name,
            .video-container .initialName {
              display: none;
            }

            .video-container .display-name {
              font-family: 'Antique Olive', sans-serif;
            }

            .btn-toggle-camera {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              border-radius: 30px !important;
            }

            .btn-toggle-camera-margin-right {
              margin-right: 10px;
            }

            .btn-toggle-camera span {
              flex-grow: 1;
              padding: 0;
            }
            .btn-toggle-camera span:hover {
              color: #fff;
            }
            .btn-toggle-camera span {
              color: #13fff1;
            }
            .btn-toggle-camera:focus {
              outline: none;
              box-shadow: none;
            }

            .btn-toggle-camera span.active {
              background: transparent;
              box-shadow: none;
              color: #fff;
            }

            .btn-toggle-camera :global(svg) {
              height: 25px;
              width: auto;
            }

            .meeting-start-button {
              border-radius: 30px !important;
              min-width: 80px;
              background: #3a0269;
            }

            .initialName {
              display: none !important;
            }

            .meeting-start-button span {
              color: #00ffee;
              text-shadow: 0 0 3px #b663e0, 0 0 3px #b663e0;
              padding: 0.4rem 0.3rem !important;
            }
            @media (orientation: portrait) {
              .loubiFutureLounge {
                justify-content: flex-start !important;
              }
            }
            @media (orientation: landscape) and (max-height: 1100px) {
              .loubiFutureLounge {
                justify-content: flex-start !important;
              }
            }
            @media (min-width: 768px) {
              .btn-toggle-camera span {
                padding: 0.4rem 0.8rem;
              }
            }
            @media (min-width: 1023px) {
              .loubiFutureLounge .video-thumbnail,
              .loubiFutureLounge .video-container {
                overflow: hidden !important;
              }
              .loubiFutureLounge.presenter {
                left: 280px;
              }
              .loubiFutureLounge .DeviceDropdown:last-child {
                margin-left: 30px;
              }

              .loubiFutureLounge .DeviceDropdown {
                border-bottom: 2px solid #000;
              }

              .loubiFutureLounge .dropdown-toggle {
                color: #000;
              }
              .loubiFutureLounge .text-left.btn.btn-light {
                color: #000;
              }
            }
          `}</style>

          <style jsx>
            {`
              .inner :global(.indicators-container.with-shadow) {
                display: none;
              }
              .video-muted .video-container {
                background: #000;
              }
              .video-muted :global(video) {
                visibility: hidden !important;
              }
              p {
                color: #13fff1;
                font-family: 'Antique Olive', sans-serif;
              }
              .reminder {
                color: #13fff1;
                font-size: 0.8em;
              }
              .unsupported-message {
                font-size: 0.9em;
                display: block;
                -webkit-animation: fade-in 1.2s linear 5s both;
                animation: fade-in 1.2s linear 5s both;
              }
              .input-error {
                background-color: rgba(0, 0, 0, 0.7);
                color: #cf152d;
                padding: 20px 10px;
                border-radius: 20px;
              }
              .input-error a {
                color: inherit;
                text-decoration: underline;
              }

              .logo {
                width: 80px;
              }

              .meeting-lounge h2 {
                margin: 10px 0 5px;
                font-family: 'LF-Bold', sans-serif;
                color: #13fff1;
                font-size: 1em;
              }
              .meeting-lounge :global(h2 span) {
                display: block;
              }
              .content-inner {
                overflow: visible;
                padding-top: 20px;
              }

              .cassete-container {
                background: url(/asset/loubifuture/cassette.png);
                background-size: contain;
                background-repeat: no-repeat;
                padding: 15px;
                margin-left: -15px;
                margin-right: -15px;
                position: relative;
                min-height: 300px;
              }

              .checking-message-container {
                padding: 15px;
                padding-top: 30px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 15px;
                width: 100%;
              }

              .cassete-container .info-container {
                display: none;
              }

              .media-container {
                display: flex;
                overflow: hidden;
                border-radius: 10px;
                color: #13fff1;
              }

              .media-container .reminder {
                text-align: left;
                text-transform: none;
              }

              .media-container .inner {
                display: flex;
                align-items: center;
                text-align: left;
                flex-wrap: nowrap;
                overflow: hidden;
                background: #000;
                border-radius: 10px;
                width: 40%;
              }

              .media-container > .media-text p {
              }

              .media-container p {
                margin: 0;
              }

              .inner .media-text {
                display: none;
              }

              .media-container :global(video) {
                width: 100%;
                height: auto;
              }

              .cassete-actions {
                margin-top: 10px;
              }

              .media-text {
                width: 60%;
                padding: 15px;
                padding-top: 30px;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 15px;
              }
              .form-group {
                margin: 0;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
              }
              .input-name {
                background: #fff;
                flex-grow: 1;
                border-radius: 5px;
                color: #949494;
                padding: 0.4rem;
              }
              .input-name,
              .input-name:focus,
              .input-name:active {
                font-size: 1em;
                border: none;
                outline: none;
              }

              .form-group :global(.form-input-control) {
                font-size: 0.875em;
                padding: 0px;
                color: #000;
                border-radius: 100%;
              }

              .form-group :global(.form-input-control span) {
                padding: 0.3rem 0.5rem;
                font-size: 10px;
                color: #00ffee;
                text-shadow: 0 0 3px #b663e0, 0 0 3px #b663e0;
              }

              .form-group .form-input-control :global(svg) {
                width: 16px;
                height: 16px;
              }

              .client-note-text {
                color: #000 !important;
                font-size: 12px;
                margin-top: 10px;
              }

              .toggle-buttons-wrapper {
                margin-top: 15px;
              }

              .client-note {
                padding: 30px;
              }
              .client-note :global(svg) {
                width: 50px;
                height: auto;
              }

              .client-note a {
                color: #000;
              }
              .btn-success {
                outline: none;
                box-shadow: none;
              }

              .lounge-prompt {
                font-size: 0.8em;
              }

              :global(.lf-meeting-lounge .remote-users) {
                background-color: rgba(0, 0, 0, 0.7);
              }

              :global(.btn-start) {
                margin-top: 40px;
                letter-spacing: 10;
                margin-bottom: 20px;
                font-family: ${loubiFutureFont};
                border-color: #cf152d;
                font-size: 25px;
                color: #000;
                min-width: 200px;
              }

              @-webkit-keyframes fade-in {
                0% {
                  opacity: 0;
                }

                100% {
                  opacity: 1;
                }
              }
              @keyframes fade-in {
                0% {
                  opacity: 0;
                }

                100% {
                  opacity: 1;
                }
              }

              @media (min-width: 470px) and (max-width: 1023px) {
                .cassete-container {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  height: auto;
                  padding: 12% 8% 30%;
                }
              }

              @media (min-width: 768px) {
                .content-inner {
                  margin: auto;
                  max-width: 800px;
                  padding-top: 0;
                }
                .media-container {
                }

                .media-container .inner {
                  border-radius: 15px;
                }
                .media-container .media-text .form-group {
                  margin: 20px 0;
                }
                :global(.form-input-control span) {
                  padding: 0.5rem 0.7rem;
                }
                .cassete-actions {
                  margin-bottom: 20px;
                }
              }

              @media (min-width: 1023px) {
                .logo {
                  width: 120px;
                  margin-top: 30px;
                }
                p {
                  font-size: 1.125em;
                }
                .meeting-lounge h2 {
                  font-size: 1.8em;
                  line-height: 1.4;
                  margin: 30px 0 10px;
                }
                .reminder {
                  font-size: 1.2em;
                }
                .meeting-lounge :global(h2 span > span) {
                  display: inline-block;
                }
                .info-container {
                  display: none;
                }

                .cassete-container .info-container {
                  display: block;
                }

                .cassete-container {
                  padding: 60px;
                  padding-top: 34px;
                  height: 540px;
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                }
                :global(.DeviceControl) {
                  padding: 0 20px !important;
                }
                .cassete-container .info-container {
                  flex: 0.25;
                  padding: 10px;
                  padding-bottom: 0;
                }

                .cassete-container .info-container .reminder {
                  padding-top: 10px;
                  margin-bottom: 0;
                  font-size: 18px;
                  color: #000;
                }

                .cassete-container .info-container :global(.mb-4) {
                  margin-bottom: 0 !important;
                }

                .media-container .inner :global(div:first-child) {
                  width: 100%;
                }
                .media-container .inner p {
                  display: none;
                }
                .media-container {
                  display: flex;
                  flex: 0.6;
                  align-items: center;
                  text-align: left;
                  flex-wrap: nowrap;
                  align-items: stretch;
                  background-color: transparent;
                }
                .media-container .inner,
                .media-container .media-text {
                  width: 50%;
                }
                .media-container > .media-text {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  background-color: rgba(0, 0, 0, 0.7);
                }
                .media-container > .media-text p {
                  display: block;
                  margin-bottom: 0;
                }

                .cassete-actions {
                  flex: 0.15;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                }

                .media-text {
                  padding: 0 35px;
                }
                .media-text .reminder {
                  font-size: 1.2em;
                }

                .form-group {
                  margin: 20px 0;
                }
                .input-name,
                .input-name:focus,
                .input-name:active {
                  font-size: 1.2em;
                }

                .client-note-text {
                  margin-top: 0;
                  font-size: 14px;
                }
              }
            `}
          </style>
        </div>
      </LoubiFuturePageContainer>
    </div>
  );
};

export default LoubiFutureLounge;

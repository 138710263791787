import React from 'react';
import { useTranslation } from '../../i18n';
import { IProduct, ProductType } from '../../interfaces';
import GenericThumbnail from './Thumbnail/GenericThumbnail';
import HighJewelryThumbnail from './Thumbnail/HighJewelryThumbnail';

const ProductThumbnail = ({
  product,
  onClick,
  noLink
}: {
  product: IProduct;
  onClick: (product: IProduct) => void;
  noLink?: boolean;
}) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  if (product.type === ProductType.HIGH_JEWELLERY) {
    return (
      <HighJewelryThumbnail
        product={product}
        onClick={onClick}
        language={language}
        noLink={noLink}
      />
    );
  }
  return (
    <GenericThumbnail
      product={product}
      onClick={onClick}
      language={language}
      noLink={noLink}
    />
  );
};

export default ProductThumbnail;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { jpIconUrlBase } from '../../../../config/loubiAirways';
import { useTranslation } from '../../../../i18n';
import { LoubiAirwaysPopupContentType } from '../../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysPopupContentType } from '../../../../redux/customActions/loubiAirways';
import { DID_CLOSE_CONTENT_POPUP } from '../../../../utils/constants';
import { LoubiAirFontFamily } from '../../../BrandStyle/LouboutinStyle';
import { useLoubiAirwaysState } from '../../../hooks/loubiAirways';
import LJMiniPopupWrapper from './LJMiniPopupWrapper';

const LJInstructionPopup = () => {
  const { popupContentType } = useLoubiAirwaysState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loubiFont = LoubiAirFontFamily();
  const getPopupContentByType = () => {
    const description = (
      <ul>
        <li>
          <img src={`${jpIconUrlBase}/hotspot_red.svg`} alt="Click Here" />
          {t('vb:hotspots_to_explore_collection')}
        </li>
        <li>
          <img src={`${jpIconUrlBase}/overview_red.svg`} alt="Overflow" />
          {t('vb:overview')}
        </li>
        <li>
          <img src={`${jpIconUrlBase}/like_no_text_red.svg`} alt="Liked" />
          {t('vb:like_product')}
        </li>
        <li>
          <img src={`${jpIconUrlBase}/shopping_bag_red.svg`} alt="Basket" />
          {t('vb:overview_of_reservations')}
        </li>
        <style jsx>{`
          li {
            font-size: 1.1em;
            color: #cf152d;
            text-transform: uppercase;
            margin: 0;
            font-family: ${loubiFont};
            text-align: left;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 10px 0;
          }
          ul {
            padding: 0;
          }
          img {
            width: 70px;
            height: auto;
            margin-left: 10px;
            float: left;
            margin-right: 20px;
          }
          @media (max-width: 768px) {
            li {
              font-size: 12px;
            }
          }
        `}</style>
      </ul>
    );
    return [
      t('vb:tips_for_flight'),
      description,
      t('vb:loubiairways_welcome_message')
    ];
  };

  const [title, children, subHeader] = getPopupContentByType();
  const onClose = () => {
    logEvent(DID_CLOSE_CONTENT_POPUP, DID_CLOSE_CONTENT_POPUP, {
      popupContentType
    });
    dispatch(actionUpdateLoubiAirwaysPopupContentType(undefined));
  };

  if (popupContentType === LoubiAirwaysPopupContentType.WELCOME_INSTRUCTION) {
    return (
      <LJMiniPopupWrapper
        title={title as string}
        subHeader={subHeader as string | undefined}
        onClose={() => {
          onClose();
        }}
      >
        {children}
        <button type="button" className="btn btn-lg" onClick={() => onClose()}>
          {t('vb:take_off')}
        </button>
        <style jsx>
          {`
            .btn {
              background: #cf152d;
              color: #fff;
              border-radius: 20px;
              font-family: ${loubiFont};
              letter-spacing: 2px;
              width: 200px;
            }
          `}
        </style>
      </LJMiniPopupWrapper>
    );
  }
  return null;
};

export default LJInstructionPopup;

import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { isIWCUaeDubai } from '../../../config';
import { useTranslation } from '../../../i18n';
import { IProduct, Source } from '../../../interfaces';
import { DID_CLICK_ORDER_NOW_BUTTON } from '../../../utils/constants';
import { inIframe } from '../../../utils/iframeDetector';
import { AppContext } from '../../AppContext';
import { isProductCheckoutable } from '../../ShoppingCart/checkoutService';
import { actionCartAddProductFromPopup } from './../../../redux/actions';

const OrderNowButton = ({
  product,
  ctaText
}: {
  product: IProduct;
  ctaText?: string;
}) => {
  const { t } = useTranslation();
  const { source, storeConfig, store } = useContext(AppContext);
  const [isSellable, setSellable] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isIWCUaeDubai(store?.id)) {
      isProductCheckoutable({
        productId: product?.id,
        storeId: store?.id
      })
        .then((isSellable) => setSellable(Boolean(isSellable)))
        .catch(() => setSellable(false));
    }
  }, []);

  if (
    !inIframe() ||
    !(source === Source.Nudge || source === Source.Showroom) ||
    !isSellable
  ) {
    return null;
  }

  if (
    storeConfig?.strictProductDisplay?.[product.modelCode]?.disableOrderButton
  ) {
    return (
      <button
        disabled
        className="btn btn-dark btn-action btn-sold-out"
        style={{ opacity: 0.5, cursor: 'default' }}
      >
        {t('sold_out').toUpperCase()}
      </button>
    );
  }

  return (
    <button
      className="btn btn-dark btn-action"
      onClick={() => {
        logEvent(DID_CLICK_ORDER_NOW_BUTTON, product.id);
        dispatch(actionCartAddProductFromPopup(product));
      }}
    >
      {t(`${ctaText ? ctaText : `order_now`}`)}
    </button>
  );
};

export default OrderNowButton;

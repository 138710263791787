import React from 'react';
import ToolTipWrapper from '../../Common/ToolTipWrapper';
import MeetingButtonWithIcon from '../MeetingControls/MeetingButtonWithIcon';
import { meetingColor } from '../../../utils/meeting';
import { BiChevronDown } from 'react-icons/bi';

const DeviceDropdown = ({
  icon,
  label,
  children,
  show,
  onToggle,
  id,
  showArrow = true
}: {
  icon: React.ReactNode;
  label?: string;
  children: React.ReactNode;
  show: boolean;
  onToggle: (b: boolean) => void;
  id: string;
  showArrow?: boolean;
}) => {
  return (
    <>
      <div className="button-container">
        <MeetingButtonWithIcon
          id={id}
          onClick={showArrow ? () => onToggle(true) : undefined}
        >
          {icon}
          <span>{label}</span>
        </MeetingButtonWithIcon>
        {showArrow && <BiChevronDown className="arrow" />}
      </div>
      {show && (
        <ToolTipWrapper
          parentId={id}
          width={250}
          onClose={() => onToggle(false)}
        >
          <div className="text-left control-menu">{children}</div>
        </ToolTipWrapper>
      )}

      <style jsx global>{`
        .control-menu {
          background: ${meetingColor.transparentDark};
          border-radius: 10px;
          overflow: hidden;
          padding: 10px 20px;
          max-height: 90vh;
          overflow: auto;
        }
        .control-menu .list-group-item-action {
          cursor: pointer;
        }
        .control-menu .list-group-item {
          background: transparent !important;
          color: #ccc;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.3px;
          line-height: 1.4;
          border: none;
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
          border-top: 0.5px solid ${meetingColor.border};
          white-space: pre-wrap;
          word-wrap: break-word;
        }
        .control-menu .list-group-item svg {
          height: auto;
          width: 25px;
        }
        .control-menu .list-group-item:first-child {
          border-top: none;
        }
        .control-menu .device-title {
          color: #ccc !important;
          font-size: 0.8em !important;
          letter-spacing: 1px !important;
          font-weight: normal !important;
        }

        .control-menu .device-title:hover {
          background: transparent !important;
        }
        .control-menu .list-group-item:hover {
          color: #fff !important;
        }
        .control-menu .list-group-item-secondary:hover,
        .control-menu .list-group-item-secondary {
          color: #fff !important;
        }
      `}</style>
    </>
  );
};

export default DeviceDropdown;

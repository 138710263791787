import React from 'react'
import { jpIconUrlBase } from '../../../../../config/loubiAirways';

const LJReservationEmptyPlaceholder = ({onClose}: {onClose:()=>void}) => (
    <div className="wishlist-empty">
      <p>NO ITEMS YET</p>
      <img
        src={jpIconUrlBase + '/back_with_cross_red.svg'}
        onClick={onClose}
        className="back-button"
        alt=""
      />
      <style jsx>
        {`
          .wishlist-empty {
            color: #346168;
            max-width: 400px;
            margin: 50px auto;
          }
          p {
            text-transform: uppercase;
            font-family: 'Antique-Olive', Arial;
          }
          .back-button {
            cursor: pointer;
            animation: pulsate-fwd 0.5s ease-in-out infinite both;
            width: 160px;
            height: auto;
          }
          @-webkit-keyframes pulsate-fwd {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
            50% {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
          @keyframes pulsate-fwd {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
            50% {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );

export default LJReservationEmptyPlaceholder

import React from 'react';
import { useTranslation } from '../../../../../i18n';
import { LFColor } from '../../../../ThreejsAnimation/loubiFuture/assets';

const LFTerms = ({
  onClick,
  checked
}: {
  onClick: () => void;
  checked?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div className={`LFTerms ${checked ? 'checked' : ''}`} onClick={onClick}>
      <div>
        <div className="checkbox">
          <div />
        </div>
      </div>
      <p className="noselect">
        {t('vb:consent_to_be_contacted')}{' '}
        <a
          href="https://eu.christianlouboutin.com/fr_fr/policy"
          target="_blank"
        >
          {t('vb:privacy_policy')?.toUpperCase()}
        </a>
      </p>
      <style jsx>{`
        .LFTerms {
          display: flex;
          text-align:left;
          font-size:11px;
        }
        .checkbox {
          border: 1px solid #fff;
          padding: 1px;
          display: inline-block;
          cursor:pointer;
        }
        .checkbox div {
          width: 15px;
          height: 15px;
        }
        .checked .checkbox div {
          background: ${LFColor.blue};
        }
        p{
            padding-left:10px;
            margin-top:-2px;
        }
        a{
            color:#fff;
            font-family:'LF-Bold', sans-serif;
            text-decoration:underline;
        }
      `}</style>
    </div>
  );
};

export default LFTerms;

import React from 'react';
import { Transition } from 'react-transition-group';

const Veil = ({ show }: { show: boolean }) => {
  return (
    <Transition in={show} timeout={1000} mountOnEnter unmountOnExit>
      {(state) => (
        <div className={`white-veil ${state}`}>

          <style jsx>{`
            .white-veil {
              z-index: 9999;
              position: fixed;
              left: 0;
              bottom: 0;
              right: 0;
              top: 0;
              background: black;
              display: flex;
              justify-content: center;
              align-item: center;
              flex-direction: column;
              opacity: 1;
              background: #000;
              transition: all 1s ease-in-out;
            }

            .white-veil.entering {
              opacity: 0;
            }
            .white-veil.entered {
              opacity: 1;
            }
            .white-veil.exiting {
              opacity: 0;
            }
            .white-veil.exited {
              opacity: 0;
            }

          `}</style>
        </div>
      )}
    </Transition>
  );
};

export default Veil;

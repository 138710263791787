import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionPixelStreamDidFocusOnAnObject,
  actionSetPixelStreamPlayerStatus,
  actionShowFullScreenVideoWithUrl
} from '../../redux/actions';
import { logEvent } from '../../analytics';
import { RECEIVED_COMMAND_FROM_STREAM } from './utils/connectionHandler';

import { psToWebCommand } from './CommandHandler/psToWebCommand';
import { PSEventType, StreamConnectionStatus } from './PSInterface';
import { useTranslation } from '../../i18n';
import { sendCommandToPS } from './CommandHandler/webToPSCommand';
import { IMainState, UnrealCommandConfig } from '../../interfaces';
import { IFRAME_ERROR, IFRAME_LOADED } from '../../utils/constants';
import { commandForAnalytics } from './Chopard/CHConfig';
import { last } from 'lodash';
import { inspifyInverseSiteId } from '../../config';
import { actionOnCommandMessage } from './utils/commandHandler';

const PixelStreamLayer = ({
  url,
  onUpdateConnectionStatus,
  storeId,
  viewOnly,
  commandConfig,
  onSendCommand,
  brandId
}: {
  url: string;
  onUpdateConnectionStatus: (status: StreamConnectionStatus) => void;
  storeId: string;
  viewOnly: boolean;
  commandConfig?: UnrealCommandConfig;
  onSendCommand?: (command: string) => void;
  brandId?: string;
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isPresenter = useSelector(
    (state: IMainState) => state.clientState?.meeting?.isPresenter
  );

  const visitedScene = useSelector(
    (state: IMainState) => state.clientState.vb?.sceneIds
  );
  const isInverse = storeId === inspifyInverseSiteId;
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const currentScene = last(visitedScene);

  const [isFocus, setIsFocus] = React.useState(false);
  const handleConnectionStatus = (message: string) => {
    onUpdateConnectionStatus(message as StreamConnectionStatus);
    if (message.includes('DISCONNECTED')) {
      dispatch(actionPixelStreamDidFocusOnAnObject(null));
    }
    if (message === 'CONNECTED') {
      if (isPresenter) {
        sendCommandToPS('BACK');
        setTimeout(() => {
          sendCommandToPS('RESET');
        }, 200);

        dispatch(
          actionShowFullScreenVideoWithUrl({
            show: false,
            url: undefined
          })
        );
      }
    }
  };

  const handleUeEvent = (message: string) => {
    const lang = i18n.language;
    psToWebCommand({ dispatch, message, storeId, lang, viewOnly });
    const feedback = commandConfig?.[message];
    actionOnCommandMessage(feedback, storeId, brandId, dispatch, onSendCommand);
  };

  const handleCommandForAnalytics = (msg: string) => {
    logEvent(msg, msg, { scene: currentScene });
  };

  const handleMessageEvent = (e: MessageEvent) => {
    if (e.data?.source !== 'pixelstreaming') return;

    const { message, type } = e.data;

    logEvent(RECEIVED_COMMAND_FROM_STREAM, RECEIVED_COMMAND_FROM_STREAM, {
      message,
      type
    });

    if (commandForAnalytics.includes(message?.toUpperCase()?.trim())) {
      handleCommandForAnalytics(message);
      return;
    }

    if (type === PSEventType.PLAYER_STATUS) {
      dispatch(actionSetPixelStreamPlayerStatus(message));
    }

    if (type === PSEventType.CONNECTION_STATUS) {
      handleConnectionStatus(message);
    }

    if (type === PSEventType.UE_EVENT) {
      handleUeEvent(message);
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    const detectActive = () => {
      const activeElement = document.activeElement;
      setIsFocus(activeElement === iframeRef.current);
    };
    const interval = setInterval(detectActive, 3000);
    return () => {
      clearInterval(interval);
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  const focusIframe = () => {
    iframeRef.current?.focus();
    setIsFocus(true);
  };
  return (
    <>
      {!isFocus && isInverse && (
        <div id="ps-focus" onMouseOver={focusIframe} className="focus"></div>
      )}
      <iframe
        className="pixel-stream-layer"
        src={url}
        onError={(e) => {
          logEvent(IFRAME_ERROR, IFRAME_ERROR, {
            error: e,
            iframeUrl: url
          });
        }}
        onLoadedData={(e) => {
          logEvent(IFRAME_LOADED, IFRAME_LOADED, {
            events: e,
            iframeUrl: url
          });
        }}
        ref={iframeRef}
        id="ps-iframe"
      />
      <style jsx>{`
        .focus {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          opacity: 0.0001;
          right: 0;
          z-index: 1;
        }
        iframe {
          border: none;
          width: 100%;
          height: 100%;
          transition: none;
          transition: all 0.5s ease-in-out;
        }
        :global(.client:not(.popup-page-opened)) iframe {
          transition: none;
        }
        @media (min-width: 840px) {
          :global(.MDLandscape.normal-mode.modal-pixel-layer) iframe {
            margin-right: min(350px, 20vw);
            margin-left: max(-350px, -20vw);
          }

          :global(.modal-pixel-layer) iframe {
            margin-right: min(500px, 25vw);
            margin-left: max(-500px, -25vw);
          }
        }
        @media (orientation: portrait), (max-aspect-ratio: 192/108) {
          iframe {
            width: calc(100vh * 1.7777);
            height: 100vh;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      `}</style>
    </>
  );
};

export default PixelStreamLayer;

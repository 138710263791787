import { uniqBy } from 'lodash';
import { assetBaseUrl, louboutinBrandId } from '../../../../../config';
import { IAnimationProduct } from '../../../../../interfaces/loubiairways';
import { all3DProducts } from '../../../../ThreejsAnimation/louboutinJp/assets';

export enum AnimationProductModel {
  CLLOUBIAIRWAYS004 = 'CLLOUBIAIRWAYS004',
  CLLOUBIAIRWAYS009 = 'CLLOUBIAIRWAYS009',
  CLLOUBIAIRWAYS012 = 'CLLOUBIAIRWAYS012',
  CLLOUBIAIRWAYS013 = 'CLLOUBIAIRWAYS013',
  CLLOUBIAIRWAYS018 = 'CLLOUBIAIRWAYS018',
  CLLOUBIAIRWAYS020 = 'CLLOUBIAIRWAYS020',
  CLLOUBIAIRWAYS022 = 'CLLOUBIAIRWAYS022',
  CLLOUBIAIRWAYS023 = 'CLLOUBIAIRWAYS023',
  CLLOUBIAIRWAYS024 = 'CLLOUBIAIRWAYS024',
  CLLOUBIAIRWAYS025 = 'CLLOUBIAIRWAYS025',
  CLLOUBIAIRWAYS026 = 'CLLOUBIAIRWAYS026',
  CLLOUBIAIRWAYS027 = 'CLLOUBIAIRWAYS027',
  CLLOUBIAIRWAYS028 = 'CLLOUBIAIRWAYS028',
  CLLOUBIAIRWAYS035 = 'CLLOUBIAIRWAYS035',
  CLLOUBIAIRWAYS036 = '_3210176W222',
  CLLOUBIAIRWAYS037 = 'CLLOUBIAIRWAYS037',
  CLLOUBIAIRWAYS038 = 'CLLOUBIAIRWAYS038',
  CLLOUBIAIRWAYS039 = 'CLLOUBIAIRWAYS039',
  CLLOUBIAIRWAYS040 = 'CLLOUBIAIRWAYS040',
  CLLOUBIAIRWAYS041 = 'CLLOUBIAIRWAYS041',
  CLLOUBIAIRWAYS042 = 'CLLOUBIAIRWAYS042',
  CLLOUBIAIRWAYS043 = 'CLLOUBIAIRWAYS043',
  CLLOUBIAIRWAYS044 = 'CLLOUBIAIRWAYS044',
  CLLOUBIAIRWAYS045 = 'CLLOUBIAIRWAYS045',
  CLLOUBIAIRWAYS046 = 'CLLOUBIAIRWAYS046',
  CLLOUBIAIRWAYS047 = 'CLLOUBIAIRWAYS047',
  CLLOUBIAIRWAYS048 = 'CLLOUBIAIRWAYS048',
  CLLOUBIAIRWAYS049 = 'CLLOUBIAIRWAYS049',
  CLLOUBIAIRWAYS050 = 'CLLOUBIAIRWAYS050',
  CLLOUBIAIRWAYS051 = 'CLLOUBIAIRWAYS051',
  CLLOUBIAIRWAYS052 = 'CLLOUBIAIRWAYS052',
  CLLOUBIAIRWAYS053 = 'CLLOUBIAIRWAYS053',
  CLLOUBIAIRWAYS115 = 'CLLOUBIAIRWAYS115',
  CLLOUBIAIRWAYS116 = 'CLLOUBIAIRWAYS116',
  CLLOUBIAIRWAYS117 = 'CLLOUBIAIRWAYS117',
  CLLOUBIAIRWAYS118 = 'CLLOUBIAIRWAYS118',
  CLLOUBIAIRWAYS119 = '3210244R492',
  CLLOUBIAIRWAYS120 = 'CLLOUBIAIRWAYS120',
  CLLOUBIAIRWAYS121 = 'CLLOUBIAIRWAYS121',
  CLLOUBIAIRWAYS122 = 'CLLOUBIAIRWAYS122',
  CLLOUBIAIRWAYS123 = 'CLLOUBIAIRWAYS123',
  CLLOUBIAIRWAYS021 = 'CLLOUBIAIRWAYS021',
  _3215053SV71 = '3215053SV71',
  _3215050M023 = '3215050M023',
  _3195051H661 = '3195051H661',
  _1205092B385 = '1205092B385',

  _3210147W360 = '3210147W360',
  _3210229C717 = '3210229C717',
  _3210229W222 = '3210229W222',
  _3210372J490 = '3210372J490',
  _3210486U727 = '3210486U727',
  _3210487U727 = '3210487U727',
  _3215052B558 = '3215052B558',
  _3215089H734 = '3215089H734',
  _3205189R251 = '_3205189R251',
  _1215060W372 = '1215060W372',
  _3205187BK01 = '3205187BK01',
  _3205349F507 = '3205349F507'
}

const animation2DProducts: IAnimationProduct[] = [
  {
    id: '31efdc75-f1ea-42d4-a8f6-d0e9a4751e98',
    modelName: 'LOOPINGA TOE PLUME',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS004
  },

  {
    id: '18100b8b-db63-4b30-9686-5bdcb5346609',
    modelName: 'OFFICIALITO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS009
  },

  {
    id: 'dcd1e514-c4e0-4a00-948a-d28e3beca37a',
    modelName: 'SANDALE DU DESERT',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS012
  },
  {
    id: 'c1bbe387-59cf-4df3-818e-b5a107c14680',
    modelName: 'CARACABA SMALL',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS013
  },

  {
    id: 'a651c8eb-9da2-4f85-98bb-380772162241',
    modelName: 'RANTULOW ORLATO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS018
  },

  {
    id: '8c290dd6-265c-4e47-a0bb-4f52dce8a1c3',
    modelName: 'NAVY POOL',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS021
  },

  {
    id: '4e2200fc-a597-4a4e-af62-a705449b26bd',
    modelName: 'NAVY COOLITO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS020
  },

  {
    id: '0f83e769-60d6-4e82-8065-9ae3d7b56874',
    modelName: 'MELON SPIKES',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS022
  },
  {
    id: '526a1e14-16a9-448b-bf12-36035bac06d1',
    modelName: 'BLASTER CROSSBODY',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS023
  },
  {
    id: '0b0c40e0-48a1-4fd1-88e1-7c7a19083f75',
    modelName: 'RED SOLE KEYRING',
    modelCode: AnimationProductModel._1205092B385
  },
  {
    id: '19cd8058-2935-48c6-b288-e88171e4ae47',
    modelName: 'ELISA BAGUETTE SMALL',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS025
  },
  {
    id: 'efb135a0-baf7-4295-a3a6-a661983d3c10',
    modelName: 'PANETTONE WALLET',
    modelCode: AnimationProductModel._3195051H661
  },
  {
    id: '33a8ac7c-f9ab-401d-b300-029f6e20c0e7',
    modelName: 'CRYSTAL BALL KEYRING',
    modelCode: AnimationProductModel._3215050M023
  },
  {
    id: '3c6f58d5-ae78-4cdb-b7c4-c93b2d5c68fb',
    modelName: 'LOUBILINK BRACELET 10',
    modelCode: AnimationProductModel._3215053SV71
  },

  {
    id: '404fbafb-a8a1-4de3-811b-5d0952526797',
    modelName: 'BLASTER CROSSBODY',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS035
  },
  {
    id: '623e06c1-ef5c-424e-bf78-5a08175877c3',
    modelName: 'LOCK WOODY',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS036
  },
  {
    id: 'e4d46622-1e96-4444-9080-4922751a8c10',
    modelName: 'VELCRISSIMO',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS037
  },
  {
    id: '5ccdd506-3daa-40b5-ae69-9715db074504',
    modelName: 'PATROUIAGOMA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS038
  },
  {
    id: '51524ed7-196d-4ec9-afef-4a3318d10bb9',
    modelName: 'DAME ANNE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS039
  },
  {
    id: '06e50809-b4cd-43de-85b7-5945abf87ed7',
    modelName: 'SUMMER LOUBISHARK',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS040
  },
  {
    id: 'cf7d04a9-90c0-4f49-9244-1b10fb6495fe',
    modelName: 'HINDOLUG',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS041
  },
  {
    id: '4fc884ab-4efc-4d70-9f9a-61a2c3f17446',
    modelName: 'LOUBISHARK',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS042
  },
  {
    id: 'dfe6cb70-4bf4-4567-938d-e12986384802',
    modelName: 'FUN LOUIS',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS043
  },
  {
    id: '042a66f2-52f9-49a0-b333-b33422812732',
    modelName: 'COOLITO SWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS044
  },
  {
    id: 'f4da1550-ba33-403e-a14a-419fcc2d5366',
    modelName: 'COOLITO SWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS045
  },
  {
    id: '568af18f-d667-48df-a59c-920accd443f5',
    modelName: 'MAYERSWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS046
  },
  {
    id: 'c169a58c-7b26-47df-8ed6-c76cb218a103',
    modelName: 'COOLITO SWING DONNA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS047
  },
  {
    id: '460f02e5-eacc-469a-8a2b-344577fd34df',
    modelName: 'CHARLETA SWING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS048
  },
  {
    id: '5b8a03b4-67c3-4189-bc8e-b284f78327c0',
    modelName: 'MAYERSWING DONNA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS049
  },
  {
    id: 'b2102701-cac6-4036-afcb-ccf8df2e2cff',
    modelName: 'AQUARIA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS050
  },
  // {
  //   id: '5c0b8e6b-c453-4ba4-9bcb-6a55766ed5d9',
  //   modelName: 'AQUALLIA',
  //   modelCode: AnimationProductModel.CLLOUBIAIRWAYS051
  // },
  {
    id: 'd6827518-4ad7-4f89-989e-471a78694bf1',
    modelName: 'AQUAJANE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS052
  },
  {
    id: 'e48207fc-e302-4697-b5bf-94921cef49eb',
    modelName: 'AQUARIA BOTTA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS053
  },
  {
    id: 'b14856c4-b24f-4f20-bb66-88dc26b588c8',
    modelName: 'KATE SLING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS121
  },
  {
    id: '9ca6c9dc-5082-49f0-b8ef-87a643f8b2e5',
    modelName: 'FUN LOUIS JUNIOR SPIKE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS115
  },
  {
    id: 'f73e04c8-04b2-4acd-bab9-5c2771778a84',
    modelName: 'FUN VIEIRA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS116
  },
  {
    id: '7f8bc3c4-bc9a-4865-8fef-aa149aa55fe1',
    modelName: 'MOCALAUREAT',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS117
  },
  {
    id: '2eac1b27-c0b8-436c-a669-614d52c94846',
    modelName: 'SO KATE',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS118
  },
  {
    id: 'b0d82384-cc5f-4042-a8ca-261e8e67ec29',
    modelName: 'VERNICA',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS119
  },
  {
    id: '2059851b-8d4c-4afa-9f74-fe406878df83',
    modelName: 'PALOMA BAGUETTE SMALL',
    modelCode: AnimationProductModel._3215089H734
  },

  {
    id: 'd401f8ee-d8c9-4192-a5b7-b25a10e3ce53',
    modelName: 'KATE SLING',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS121
  },
  {
    id: '907de8ee-e391-45b3-b0ed-845e17376295',
    modelName: 'MONTEZU LUG',
    modelCode: AnimationProductModel.CLLOUBIAIRWAYS122
   },

   {
    id: '5d616852-4501-4b81-a149-84d52a67a4df',
    modelName: 'ESPASNEAK',
    modelCode: AnimationProductModel._3210147W360
   },

   {
    id: '0a3640db-f853-4b8e-84de-d2b60b365ee0',
    modelName: 'ALMERIO',
    modelCode: AnimationProductModel._3210229C717
   },
   {
    id: '5dddece3-de65-4051-abe6-69a739b03193',
    modelName: 'ALMERIO',
    modelCode: AnimationProductModel._3210229W222
   },
   {
    id: '79feb78f-eba7-4e7b-bc88-ba1777fe7995',
    modelName: 'MILAGOO',
    modelCode: AnimationProductModel._3210372J490
   },
   {
    id: '4d47bdc9-cc08-474a-a456-2016f65b4fbd',
    modelName: 'ESPABOAT',
    modelCode: AnimationProductModel._3210486U727
   },
   
   {
    id: '9b2a887c-8d93-4958-a017-67c86fb425fc',
    modelName: 'ESPASNEAK',
    modelCode: AnimationProductModel._3210487U727
   },
   {
    id: 'b0ad1375-36d5-4e70-887b-a3c6db98a7ac',
    modelName: 'FRANGIBUS',
    modelCode: AnimationProductModel._3215052B558
   },
   {
    id: '91fd0b96-94e2-465e-98c7-0fba9ffed252',
    modelName: 'CABAROCK SMALL',
    modelCode: AnimationProductModel._3205189R251
   },

   {
    id: '763ef542-dba2-4e29-8568-e30d0d248957',
    modelName: 'KYPIPOUCH SMALL',
    modelCode: AnimationProductModel._1215060W372
   },

   {
    id: '1c08f78a-7feb-4e8d-83c2-2015b40f9501',
    modelName: 'CABAROCK LARGE',
     modelCode: AnimationProductModel._3205187BK01
   },

   {
     id: 'b5b3cc1f-e3e2-4aed-8e93-064bc68651f6',
     modelName: 'CL LOGO BELT 35',
     modelCode: AnimationProductModel._3205349F507
   }


  // {
  //   id: '49d0a5f0-5265-4313-bfc6-5054bf66e565',
  //   modelName: 'MORSETTO BRACELET DOUBLE 10',
  //   modelCode: AnimationProductModel.CLLOUBIAIRWAYS123
  // }
];

const allProducts = uniqBy([...animation2DProducts, ...all3DProducts], 'id');
export const get2DAnimationProductByModel = (
  modelCode: string
): IAnimationProduct =>
  animation2DProducts.find((p) => p.modelCode === modelCode);

export const getLoubiAirwaysProductById = (id: string): IAnimationProduct =>
  allProducts.find((p) => p.id === id);

export const get2DProductImage = (model: IAnimationProduct) => {
  const product = animation2DProducts.find((p) => p.id === model?.id);
  if (!product) {
    return getLoubiAirwaysProductImage(model);
  }
  return `${assetBaseUrl}/loubiairways/luggage-animation/products-2d/v12/${product?.modelCode}.png`;
};

export const getLoubiAirwaysProductImage = (model: IAnimationProduct) => {
  return `https://products.inspify.io/${louboutinBrandId}%2F${model?.id}%2F1000x1000%2F${model?.id}_001.png`;
};

import LoubiButtonWrapper from './LoubiButtonWrapper';

const MuteButton = ({
  onClick,
  showSpotifyPlayer,
  mute
}: {
  onClick: () => void;
  showSpotifyPlayer: boolean;
  mute: boolean;
}) => {
  return (
    <LoubiButtonWrapper>
      <img
        src={mute ? '/asset/louboutin/unmute.png' : '/asset/louboutin/mute.png'}
        alt="Toggle mute"
        onClick={onClick}
        className={`loubi-circle-button bottom-second-right ${
          showSpotifyPlayer ? 'spotify' : ''
        }`}
      />
    </LoubiButtonWrapper>
  );
};

export default MuteButton;

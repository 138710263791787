import React from 'react';
import { useSelector } from 'react-redux';

import { IMainState, CheckoutStep } from '../../interfaces';
import { IVirtualBoutiqueShopContact } from '../../interfaces';
import IWCDubaiCheckoutCart from './Cart/IWCDubaiCheckoutCart';
import IWCDubaiCheckoutForm from './IWCDubaiCheckoutForm';
import IWCDubaiPaymentForm from './Checkout/IWCDubaiPaymentForm';
import CheckoutConfirmation from './Checkout/CheckoutConfirmation';
import CheckoutSummary from './Checkout/CheckoutSummary';
import { getCheckoutFrameKey } from './checkoutService';
import { dec } from '../../utils/crypt';

interface Props {
  shopContacts: IVirtualBoutiqueShopContact[];
  storeId: string;
  phoneCountryCode: string;
  deliveryFee?: number;
  onClose?: () => void;
}

const IWCDubaiShoppingCartPage = ({
  shopContacts,
  storeId,
  phoneCountryCode,
  deliveryFee = 0,
  onClose
}: Props) => {
  const [checkoutdotcomPK, setCheckoutdotcomPK] = React.useState(null);
  const step =
    useSelector(
      (state: IMainState) => state.clientState?.shoppingCart?.checkout?.step
    ) || CheckoutStep.CART;
  let component: React.ReactNode = null;

  switch (step) {
    case CheckoutStep.CART:
      component = <IWCDubaiCheckoutCart deliveryFee={deliveryFee} />;
      break;

    case CheckoutStep.DELIVERY_ADDRESS:
      component = (
        <IWCDubaiCheckoutForm
          shopContacts={shopContacts}
          phoneCountryCode={phoneCountryCode}
          deliveryFee={deliveryFee}
        />
      );
      break;

    case CheckoutStep.PAYMENT:
      component = <IWCDubaiPaymentForm checkoutdotcomPK={checkoutdotcomPK} />;
      break;
    case CheckoutStep.ORDER_SUMMARY:
      component = (
        <CheckoutConfirmation storeId={storeId} deliveryFee={deliveryFee} />
      );
      break;

    case CheckoutStep.THANK_YOU:
      component = <CheckoutSummary onClose={onClose} />;
      break;

    default:
      component = <IWCDubaiCheckoutCart deliveryFee={deliveryFee} />;
      break;
  }

  React.useEffect(() => {
    getCheckoutFrameKey()
      .then((payload) => {
        const content = JSON.parse(dec(payload.data.content, payload.data.id));
        setCheckoutdotcomPK(content.publicKey);
      })
      .catch((error) => console.log('error is', error));
  }, []);

  return <>{component}</>;
};

export default IWCDubaiShoppingCartPage;

import React from 'react';
import { assetBaseUrl } from '../../config';
import { isUserOnMobile } from '../../utils/deviceDetector';

const VB360Veil = () => {
  const [show, setShow] = React.useState(false);
  const [isUsingMobile, setIsUsingMobile] = React.useState(false);
  React.useEffect(() => {
    setShow(true);
    setIsUsingMobile(isUserOnMobile());
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, []);

  if (!show) return null;

  return (
    <div className="fixed-full content-middle">
      {!isUsingMobile ? (
        <img src={`${assetBaseUrl}/icons/360_2.gif`} />
      ) : (
        <img src={`${assetBaseUrl}/icons/360_mobile.gif`} />
      )}
      <style jsx>{`
        .fixed-full {
          background: rgba(0, 0, 0, 0.5);
          animation: fade-in 4s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
          pointer-events: none;
        }
        img {
          width: 200px;
          height: auto;
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }
          20%,
          80% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          20%,
          80% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @media (min-width: 768px) {
          img {
            width: 300px;
          }
        }
      `}</style>
    </div>
  );
};

export default React.memo(VB360Veil);

import React from 'react';
import {vcaAssets } from '.';
import { IMapSpot } from '../../../../interfaces';
import VCAMapSpot, { SpotProps } from './VCAMapSpot';

const VCAMapLevel1 = ({
  onSelectSpot,
  onSelectFloor,
  active,
  visitedSpot,
  currentPosition
}: {
  onSelectSpot: (spot: IMapSpot) => void;
  onSelectFloor: () => void;
  active?: boolean;
  visitedSpot: string[];
  currentPosition?: string;
}) => {
  const spots: SpotProps[] = [
    {
      spot: { scene: 'scene_8' },
      left: 17.5,
      top: 13.5
    },
    {
      spot: { scene: 'scene_9' },
      left: 20.5,
      top: 3
    },
    {
      spot: { scene: 'scene_10' },
      left: 2.5,
      top: 15
    },
    {
      spot: { scene: 'scene_11' },
      left: 8.5,
      top: 21
    },
    {
      spot: { scene: 'scene_12' },
      left: 29.5,
      top: 7
    },

    {
      spot: { scene: 'scene_13' },
      left: 27.5,
      top: 21
    },

    {
      spot: { scene: 'scene_14' },
      left: 31,
      top: 29
    },

    {
      spot: { scene: 'scene_15' },
      left: 27.3,
      top: 41
    },
    {
      spot: { scene: 'scene_16' },
      left: 30.5,
      top: 52
    },
    {
      spot: { scene: 'scene_17' },
      left: 3,
      top: 31
    },

    {
      spot: { scene: 'scene_18' },
      left: 55,
      top: 20
    },
    {
      spot: { scene: 'scene_19' },
      left: 3,
      top: 50
    },
    {
      spot: { scene: 'scene_20' },
      left: 95,
      top: 14
    },
    {
      spot: { scene: 'scene_21' },
      left: 70,
      top: 2
    },
    {
      spot: { scene: 'scene_22' },
      left: 46,
      top: 41
    },
    {
      spot: { scene: 'scene_23' },
      left: 63,
      top: 41
    },
    {
      spot: { scene: 'scene_24' },
      left: 55,
      top: 35
    },
    {
      spot: { scene: 'scene_25' },
      left: 55,
      top: 47
    },
    {
      spot: { scene: 'scene_26' },
      left: 65,
      top: 56.5
    },

    {
      spot: { scene: 'scene_27' },
      left: 65,
      top: 74.5
    },
    {
      spot: { scene: 'scene_28' },
      left: 45,
      top: 74.5
    },
    {
      spot: { scene: 'scene_29' },
      left: 45,
      top: 56.5
    },
    {
      spot: { scene: 'scene_30' },
      left: 85,
      top: 26.5
    },
    {
      spot: { scene: 'scene_31' },
      left: 70,
      top: 13.5
    },
    {
      spot: { scene: 'scene_32' },
      left: 40,
      top: 21
    },
    {
      spot: { scene: 'scene_33' },
      left: 40,
      top: 5.5
    },
    {
      spot: { scene: 'scene_38' },
      left: 37,
      top: 57
    },
    {
      spot: { scene: 'scene_39' },
      left: 37,
      top: 74
    },
    {
      spot: { scene: 'scene_40' },
      left: 55,
      top: 95
    }
  ];
  return (
    <div className={`map-container ${active}`}>
      <div className="map-label vca-title">Ground Floor</div>
      <div className="inner">
        <img className="map" src={vcaAssets.level1Map} alt="" />
        <div className="to-floor" onClick={onSelectFloor}>
          <img src={vcaAssets.gotoLevel} alt="" />
          <div className="to-floor-label vca-title">
            <span>To</span>
            <span>Second</span>
            <span>Floor</span>
          </div>
        </div>

        {spots.map((spot) => (
          <VCAMapSpot
            key={spot.spot.scene}
            className={spot.spot.scene}
            visited={visitedSpot.includes(spot.spot.scene)}
            onClick={() => onSelectSpot(spot.spot)}
            left={spot.left}
            top={spot.top}
            active={currentPosition === spot.spot.scene}
          />
        ))}
      </div>

      <style jsx>{`
        .map-container {
          transform: translate(-50%, 200%);
        }
        .map-container.true {
          pointer-events: all;
          transform: translate(-50%, -50%) translateZ(0) scale(1, 1);
        }
        .map-container .map {
          max-width: 90vw;
          max-height: 85vh;
        }
        .to-floor {
          width: 30%;
          top: 33%;
          overflow: hidden;
        }
        .to-floor img {
          width: 100%;
          height: auto;
        }
        @media (min-width: 840px) {
          :global(.SIDE) .map-container .map {
            max-width: calc(90vw - 200px) !important;
          }
          :global(.PRESENTATION) .map-container .map {
            max-width: calc(
              90vw - ${100}px
            ) !important;
            max-height: calc(85vh - 150px) !important;
          }

          :global(.PRESENTATION) .map-container.true {
            transform: translate(-50%, -calc(50% - 75px)) !important;
          }
        }
      `}</style>
    </div>
  );
};

export default VCAMapLevel1;

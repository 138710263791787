import React from 'react';
import { logEvent } from '../../../analytics/index';
import { DID_CLICK_BADGE } from '../../../utils/constants';
import { useTranslation } from '../../../i18n';
import Link from '../../Common/Link';

const IWCWarrantyBadge = () => {
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'ar' ? (
        <Link
          href={`/inspirations/[id]`}
          as={`/inspirations/1d4eec10-d792-11ea-ac8b-31843d1833e0`}
          className="IWCWarrantyBadge"
          onClick={() =>
            logEvent(DID_CLICK_BADGE, DID_CLICK_BADGE, {
              badgeUrl: '/inspirations/1d4eec10-d792-11ea-ac8b-31843d1833e0'
            })
          }
        >
          <img
            src="https://panos.inspify.io/virtual_boutique/844cc92c-8783-48a5-bb06-e37a093e0bbd/iwc_warranty.png"
            alt="8 Years IWC Warranty"
          />
        </Link>
      ) : (
        <a
          href="https://www.iwc.com/ch/en/specials/my-iwc.html"
          target="_blank"
          className="IWCWarrantyBadge"
          onClick={() =>
            logEvent(DID_CLICK_BADGE, DID_CLICK_BADGE, {
              badgeUrl: 'https://www.iwc.com/ch/en/specials/my-iwc.html'
            })
          }
        >
          <img
            src="https://panos.inspify.io/virtual_boutique/844cc92c-8783-48a5-bb06-e37a093e0bbd/iwc_warranty.png"
            alt="8 Years IWC Warranty"
          />
        </a>
      )}

      <style jsx>{`
        img {
          width: 100%;
        }
        :global(.IWCWarrantyBadge) {
          width: 60px;
          margin: auto;
          bottom: 0;
          display: block;
        }
        @media (min-width: 768px) {
          :global(.IWCWarrantyBadge) {
            width: 70px;
            margin-top: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default IWCWarrantyBadge;

import React from 'react';
import LoadingProgress from '../../VirtualBoutique/Pano/LoadingProgress';

const GenericLanding = ({
  isPsLoading,
  isConnecting,
  isStreamPlaying
}: {
  isPsLoading?: boolean;
  isConnecting?: boolean;
  isStreamPlaying?: boolean;
}) => {
  const showLoading = isPsLoading || isConnecting || isStreamPlaying;

  return (
    <div className="inner">
      <LoadingProgress loading={showLoading} />
      {!showLoading && (
        <div className="btn btn-action btn-outline-light">ENTER</div>
      )}

      <style jsx>{`
        .inner {
          margin: auto;
          color: #fff;
        }
      `}</style>
    </div>
  );
};

export default GenericLanding;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { logEvent } from '../../../analytics';
import { ILanguageOption } from '../../../interfaces';
import { DID_CLICK_EXPLORE_MY_OWN } from '../../../utils/constants';
import { tryGetLocalStorage, tryRemoveFromLocalStorage, trySetLocalStorage } from '../../../utils/window';
import PrivacyPolicy from '../../Legal/PrivacyPolicy';
import TermsOfUse from '../../Legal/TermsOfUse';

const IWCSihh2020Landing = ({
  storeId,
  brandName,
  logoUrl,
  backgroundImageUrl,
  mobileBackgroundImageUrl,
  languages,
  onDismiss
}: {
  storeId: string;
  brandName: string;
  logoUrl: string;
  backgroundImageUrl: string;
  mobileBackgroundImageUrl: string;
  languages: ILanguageOption[];
  onDismiss: () => void;
}) => {
  const [showLanguageSelection, setShowLanguageSelection] = React.useState(
    languages.length > 1
  );
  const [showTerms, setShowTerms] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [alreadyAcceptedTerms, setAlreadyAcceptedTerms] = React.useState(false);
  const [alreadySelectedLanguage, setAlreadySelectedLanguage] = React.useState(
    false
  );
  const agreementKey = `${storeId}-agreementChecked`;
  const languageKey = `${storeId}-languageSelected`;
  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setAcceptedTerms(value);
    if (value) {
      trySetLocalStorage(agreementKey, 'true');
    } else {
      tryRemoveFromLocalStorage(agreementKey);
    }
  };

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setAlreadyAcceptedTerms(!!tryGetLocalStorage(agreementKey));
    setAlreadySelectedLanguage(!!tryGetLocalStorage(languageKey));
  }, []);

  const welcomeMessage = (
    <div className="welcome-container entry-selection">
      <div className="content">
        <h1>
          <span>{t('vb:welcome_message.line1')}</span>
          <span>
            {t('vb:welcome_message.line2', { brandName: t(`${brandName}`) })}
          </span>
          <span>{t('vb:welcome_message.line3')}</span>
        </h1>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="explore-own"
          onClick={() => {
            if (alreadyAcceptedTerms) {
              onDismiss();
            } else {
              setShowTerms(true);
            }
            logEvent(DID_CLICK_EXPLORE_MY_OWN);
          }}
        >
          {t('vb:start_exploring')}
        </button>
      </div>
      <style jsx>
        {`
          .btn-group {
            display: block;
          }

          .explore-own {
            padding: 0 25px;
          }

          h1 {
            text-transform: uppercase;
          }

          h1 > span {
            display: block;
          }

          .welcome-container .btn-group {
            justify-content: center;
          }

          @media (min-width: 1024px) {
            .btn-group {
              display: flex;
            }
            h1 > span {
              display: inline;
            }
            h1 > span:last-child {
              display: block;
            }
          }
        `}
      </style>
    </div>
  );

  const acceptTerms = (
    <div className="input-group tstc">
      <div className="checkbox-group">
        <label>
          <input
            type="checkbox"
            id="agreement"
            checked={acceptedTerms}
            onChange={handleAcceptTerms}
          />
          {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
          <PrivacyPolicy />
          {t('vb:tc_part_three')}
        </label>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="btn-back"
          onClick={() => setShowTerms(false)}
        >
          {t('vb:back')}
        </button>
        <button
          type="button"
          disabled={!acceptedTerms}
          className="btn-start"
          onClick={() => onDismiss()}
        >
          {t('vb:start_exploring')}
        </button>
      </div>
      <style jsx>{`
        .btn-group {
          width: 100%;
        }
      `}</style>
    </div>
  );

  const languageSelection = (
    <div className="welcome-container language-selection">
      <div className="content">
        <h1>
          <span>{t('vb:welcome_message.line1')}</span>
          <span>
            {t('vb:welcome_message.line2', { brandName: t(`${brandName}`) })}
          </span>
          <span>{t('vb:welcome_message.line3')}</span>
        </h1>
        <p>{t('vb:please_select_language')}</p>
      </div>
      <div className="btn-group">
        {languages.map((lang) => (
          <button
            key={lang.code}
            type="button"
            onClick={() => {
              setShowLanguageSelection(false);
              trySetLocalStorage(languageKey, 'true');
              i18n.changeLanguage(lang.code);
            }}
          >
            {lang.display.toUpperCase()}
          </button>
        ))}
      </div>
      <style jsx>
        {`
          h1 {
            text-transform: uppercase;
          }
          h1 > span {
            display: block;
          }

          @media (min-width: 1024px) {
            h1 > span {
              display: inline;
            }
            h1 > span:last-child {
              display: block;
            }
          }
        `}
      </style>
    </div>
  );

  const getComponent = () => {
    if (showLanguageSelection && !alreadySelectedLanguage) {
      return languageSelection;
    }
    return showTerms ? acceptTerms : welcomeMessage;
  };

  return (
    <div className="landing-page">
      <div className="inner">
        <img className="logo" src={logoUrl} />
        {getComponent()}
      </div>
      <style jsx>
        {`
          .landing-page {
            background: url(${mobileBackgroundImageUrl}) center center no-repeat;
            background-size: cover;
            background-color: #000000;
          }
          @media (min-width: 1024px) {
            .landing-page {
              background: url(${backgroundImageUrl}) center center no-repeat;
              background-size: cover;
              background-color: #000000;
            }
          }

          @media (max-width: 768px) {
            .landing-page .tstc .btn-group {
              flex-direction: column-reverse;
            }
          }
        `}
      </style>
    </div>
  );
};

export default IWCSihh2020Landing;

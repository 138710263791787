import React from 'react';
import { BiFile } from 'react-icons/bi';
import { logEvent } from '../../analytics';

import { useTranslation } from '../../i18n';
import { DID_CLICK_CONDITION_OF_SALE } from '../../utils/constants';
import { currentLanguage } from '../../utils/language';

const ConditionsOfSaleButton = ({ setShow }: { setShow: any }) => {
  const { t, i18n } = useTranslation();
  const languageCode = currentLanguage(i18n);
  return (
    <button
      className="nav-item"
      type="button"
      onClick={() => {
        setShow(false);
        logEvent(DID_CLICK_CONDITION_OF_SALE);
        window.open(
          `/conditionsofsale/dubai?language=${languageCode}`,
          '_blank'
        );
      }}
    >
      <span className="nav-item__icon">
        <BiFile />
      </span>
      <span className="nav-item__label">{t('vb:conditions_of_sale')}</span>
    </button>
  );
};

export default ConditionsOfSaleButton;

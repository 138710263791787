const EmailSvgIcon = ({
  style,
  classname
}: {
  style?: any;
  classname?: string;
}) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`${classname} icon icon-tabler icon-tabler-mail`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect x="3" y="5" width="18" height="14" rx="2" />
    <polyline points="3 7 12 13 21 7" />
  </svg>
);

export default EmailSvgIcon;

import React from 'react';
import { useDispatch } from 'react-redux';
import { LFPopupContent } from '../../../../../interfaces/loubifuture';
import {
  actionOpenLFPopup,
  actionUpdateLFWishlist
} from '../../../../../redux/customActions/loubiFuture';
import ARQRCode from './ARQRCode';
import LFPopupWrapper from './LFPopupWrapper';
import GetNotified from './GetNotified';
import GetNotified2 from './GetNotified2';
import LeavePrompt from './LeavePrompt';
import Info from './Info';
import ArpoadorInfo from './ArpoadorInfo';
import LFLanguageSwitcher from './LFLanguageSwitcher';

const LFPopup = ({
  popup,
  showWishlist,
  onMobile
}: {
  popup: LFPopupContent;
  showWishlist?: boolean;
  onMobile?: boolean;
}) => {
  const dispatch = useDispatch();

  const content = () => {
    switch (popup) {
      case LFPopupContent.QRCODE:
        return <ARQRCode />;
      case LFPopupContent.GET_NOTIFIED:
        return <GetNotified />;
      case LFPopupContent.RECEIVE_NOTIFICATION:
        return <GetNotified2 />;
      case LFPopupContent.LEAVE_PROMPT:
        return <LeavePrompt />;
      case LFPopupContent.INFO:
        return <Info showWishlist={showWishlist} onMobile={onMobile} />;
      case LFPopupContent.ARPOADOR_INFO:
        return <ArpoadorInfo />;
        case LFPopupContent.LANGUAGE:
        return <LFLanguageSwitcher />;
    }
  };

  const maxWidth = () => {
    switch (popup) {
      case LFPopupContent.LANGUAGE:
      case LFPopupContent.QRCODE:
        return 300;
      case LFPopupContent.GET_NOTIFIED:
      case LFPopupContent.RECEIVE_NOTIFICATION:
        return 350;
      case LFPopupContent.INFO:
        return 750;
      case LFPopupContent.ARPOADOR_INFO:
        return 650;
    }
  };

  return (
    <LFPopupWrapper
      popup={popup}
      maxWidth={maxWidth()}
      onClose={() => {
        dispatch(actionOpenLFPopup(undefined));
        if (popup === LFPopupContent.GET_NOTIFIED) {
          dispatch(actionUpdateLFWishlist({ dontShowPopup: true }));
        }
      }}
    >
      {content()}
    </LFPopupWrapper>
  );
};

export default LFPopup;

import React, { Fragment } from 'react';

import { DeliveryMethod, CheckoutDetails } from '../../../interfaces';
import { useTranslation } from '../../../i18n';

const CheckoutAddress = ({ details }: { details: CheckoutDetails }) => {
  const { t } = useTranslation();
  const isDelivery = details?.preferredDelivery === DeliveryMethod.DELIVERY;
  const isPickup = details?.preferredDelivery === DeliveryMethod.PICKUP;
  const pickupNameKey = details?.shopNowContact?.nameKey;
  const pickupName = details?.shopNowContact?.name;

  return (
    <Fragment>
      <div className="input-group">
        <label className="mt-8">{t('vb:first_name')}:</label>
        <div className="field-text first-name">{details?.firstName}</div>
      </div>
      <div className="input-group">
        <label>{t('vb:last_name')}:</label>
        <div className="field-text last-name">{details?.lastName}</div>
      </div>
      <div className="input-group">
        <label>{t('vb:phone_number')}:</label>
        <div className="field-text">
          {details.phoneCountryCode}
          {details.phone}
        </div>
      </div>
      <div className="input-group">
        <label>{t('email')}:</label>
        <div className="field-text">{details?.email}</div>
      </div>
      <div className="input-group">
        <label>{t('vb:preferred_contact')}:</label>
        <div className="field-text">{details?.preferredContact}</div>
      </div>
      {isPickup && (
        <div className="input-group">
          <label>{t('vb:preferred_delivery')}:</label>
          <div>
            {t('vb:pickup_at')}{' '}
            {pickupNameKey ? t(`vb:${pickupNameKey}`) : pickupName}
          </div>
        </div>
      )}
      {isDelivery && (
        <Fragment>
          <div className="input-group">
            <label>{t('vb:address')}:</label>
            <div className="field-text">{details?.addressForm?.address}</div>
          </div>
          <div className="input-group">
            <label>{t('vb:area')}:</label>
            <div className="field-text">{t(details?.addressForm?.area)}</div>
          </div>
          <div className="input-group">
            <label>{t('vb:emirates')}:</label>
            <div className="field-text">{t(details?.addressForm?.city)}</div>
          </div>
          <div className="input-group">
            <label>{t('vb:country')}:</label>
            <div className="field-text">{details?.addressForm?.country}</div>
          </div>
        </Fragment>
      )}
      <style jsx>
        {`
          .mt-8 {
            margin-top: 8px;
          }

          .input-group {
            margin-bottom: 0 !important;
          }

          .field-text {
            display: flex;
            width: 100%;
          }
        `}
      </style>
    </Fragment>
  );
};

export default CheckoutAddress;

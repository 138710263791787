import { rogerDubuisSgBoutiqueId } from '../../../config';

export const getInitialVariables = (
  storeId: string,
  source: string,
  go?: string
) => {
  if (storeId === rogerDubuisSgBoutiqueId) {
    if (go === 'secretroom') {
      return {
        showEmbargo: true,
        startscene: 'scene_rd_expsing_02'
      };
    }
    return {
      showEmbargo: true,
      startscene: source === 'ww2021' ? 'scene_pano_looby' : undefined
    };
  }
  return;
};

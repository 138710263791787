import React from 'react';
import {
  AppointmentType,
  IProduct,
  IVirtualBoutiqueContact
} from '../../../interfaces';
import {
  getProductDisplayName,
  getProductOriginalLink
} from '../../../utils/product';
import { isChatAvailable } from '../../../utils/stores';
import { AppContext } from '../../AppContext';
import AppointmentButton from './AppointmentButton';
import ChatButton from './ChatButton';
import EnquireButton, { EnquireNowContact } from './EnquireButton';
import LinkButton from './LinkButton';
import OrderNowButton from './OrderNowButton';

export const formatContact = (contact?: string) => {
  return contact?.replace('+', '')?.replace(/\s+/g, '');
};

const GenericProductCallToActionButton = ({
  product,
  appointmentTypes
}: {
  product: IProduct;
  appointmentTypes?: AppointmentType[];
}) => {
  const context = React.useContext(AppContext);

  const productLink = getProductOriginalLink(
    product,
    context.store?.countryCode || 'EN'
  );

  const storeContacts: IVirtualBoutiqueContact[] =
    context.storeConfig?.contacts || [];

  const enquireNowContacts: EnquireNowContact[] = storeContacts.map((c) => ({
    email: c.email,
    cc: c.cc,
    name: c.name,
    nameKey: c.nameKey,
    subject: `${getProductDisplayName(product, 'EN')} (Ref.${
      product.modelCode
    }) - ${c.name}`
  }));

  const renderButton = () => {
    if (context.storeConfig)
      return isChatAvailable(context.storeConfig) ? (
        <ChatButton contacts={storeContacts} />
      ) : (
        <EnquireButton contacts={enquireNowContacts} />
      );
    if (productLink) return <LinkButton url={productLink} />;
  };

  return (
    <div className="action-buttons">
      {renderButton()}
      <AppointmentButton
        product={product}
        appointmentTypes={appointmentTypes}
      />
      {context.storeConfig?.entitlement?.shoppingCart && (
        <OrderNowButton product={product} />
      )}
    </div>
  );
};

export default GenericProductCallToActionButton;

import React from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

const LJNestedButton = ({
  title,
  inlineButton,
  buttons
}: {
  title: string;
  inlineButton?: boolean;
  buttons: {
    title: string;
    onClick: () => void;
    active?: boolean;
  }[];
}) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div
      className={`button-container ${inlineButton ? 'inlineButton' : ''}`}
      onMouseLeave={() => setExpanded(false)}
    >
      <button
        className="btn nested-button"
        onClick={() => setExpanded(!expanded)}
      >
        {title}
        {expanded ? (
          <RiArrowUpSLine className="up" color="#cf152d" />
        ) : (
          <RiArrowDownSLine className="down" color="#cf152d" />
        )}
      </button>
      {expanded && (
        <div className="nested-container">
          {buttons.map((button, i) => (
            <button
              className={`btn ${button.active ? 'active' : ''}`}
              onClick={() => {
                button.onClick();
                setExpanded(false)
              }}
              key={button.title + i}
            >
              {button.title}
            </button>
          ))}
        </div>
      )}
      <style jsx>{`
        .button-container {
          margin-right: 5px;
          background: #f3f3d1;
          border-radius: 0.25rem;
          text-align:left;
        }
        .inlineButton{
          text-align:center;
        }
        .nested-button :global(svg) {
          width: 20px;
          height: 20px;
          margin-right:-4px;
        }
        .nested-button {
          font-family: 'AntiqueOlive-Bold', Arial;
          color: #cf152d;
          background: #f3f3d1;
        }

        .nested-container .btn {
          font-family: 'AntiqueOlive-Bold', Arial;
          color: #cf152d;
          width: ${inlineButton ? 'auto' : '100%'};
          border: ${inlineButton ? '2px solid #cf152d' : 'none'};
          border-top: 2px solid #cf152d;
          border-radius: 0;
          margin: ${inlineButton ? '4px' : '0'};
          display: ${inlineButton ? 'inline-block' : 'block'};
          background:transparent;
        }

        .nested-container .btn.active {
          background: #cf152d;
          color: #f3f3d1;
        }
        .btn{
          text-align:left;
          font-size: 1rem;
        }
        .btn:focus {
          outline: 0;
          box-shadow: none;
        }
        .inlineButton .nested-container .btn {
          border-radius: 5px;
        }

        @media (max-width: 768px) {
          .btn{
            font-size:0.9rem;
          }
          .nested-button :global(svg) {
          width: 16px;
          height: 16px;
        }
        }
      `}</style>
    </div>
  );
};

export default LJNestedButton;

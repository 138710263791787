import React from 'react';
import { Spinner } from 'react-bootstrap';
import { assetBaseUrl } from '../../../config';

const InverseLanding = ({
  isLoadingPS = false,
  isConnecting = false,
  isStreamPlaying = false
}: {
  isLoadingPS?: boolean;
  isConnecting?: boolean;
  isStreamPlaying?: boolean;
}) => {
  const landingBackground = `${assetBaseUrl}/inverse/landing_background.jpg`;
  const landingLogo = `${assetBaseUrl}/inverse/inspify_showroom.png`;

  const getIndicatorContent = () => {
    if (isStreamPlaying) return null;
    if (isLoadingPS)
      return (
        <div className="indicator loading">
          <Spinner animation="border" variant="light" />
          <span>Please wait we are building the world...</span>
        </div>
      );
    if (isConnecting)
      return (
        <div className="indicator connecting">
          <Spinner animation="border" variant="light" />
          <span>Loading...</span>
        </div>
      );
    return (
      <div className="indicator enter">
        <div className="border"></div>
        <div>Click to enter</div>
      </div>
    );
  };
  return (
    <>
      <div className="wrapper">
        <div className={`inverse-landing ${isStreamPlaying ? 'exiting' : ''}`}>
          <div className="welcome">
            <h5 className="heading">Welcome to</h5>
            <div className="logo">
              <img src={landingLogo} alt="Inspify Showroom" />
            </div>
          </div>
          <div className="status-indicator">{getIndicatorContent()}</div>
        </div>
      </div>

      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
        .status-indicator :global(.indicator) {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          font-size: 1.2rem;
          animation: appear 1s ease-in-out forwards;
        }
        .welcome {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 0.9rem;
          letter-spacing: 0.5rem;
        }
        .status-indicator {
          height: 250px;
          display: flex;
          align-items: center;
          opacity: 0;
          animation: slideUp 0.5s ease-in-out forwards;
          animation-delay: 1.6s;
        }
        .status-indicator :global(.enter) {
          width: 200px;
          height: 200px;
          position: relative;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.2);
          cursor: pointer;
        }

        .status-indicator :global(.enter .border) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 2px solid #fff;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.2);
          animation: breathe 1s ease-in-out infinite alternate;
        }
        .status-indicator :global(.enter:hover .border) {
          animation-play-state: paused;
          outline: none;
          color: rgba(143, 101, 153, 0.6);
        }
        .status-indicator :global(.enter div) {
          text-transform: uppercase;
          color: #fff;
          font-weight: 400;
        }
        .inverse-landing.exiting {
          opacity: 0;
        }
        .wrapper {
          animation: appear 1.3s ease-in-out forwards;
        }

        @keyframes background_scale {
          0% {
            opacity: 0;
            transform: scale(1.2);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
        @keyframes breathe {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.18);
          }
        }

        @keyframes appear {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes zoomIn {
          from {
            opacity: 1;
            transform: scale(0.7);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
        @keyframes slideUp {
          from {
            opacity: 1;
            transform: translateY(30px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .status-indicator :global(.indicator .spinner-border) {
          border-right-color: #8f6599;
        }
        .inverse-landing {
          background-image: url('${landingBackground}');
          background-color: #000;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          flex-direction: column;
          gap: 25px;
          color: white;
          transform-origin: center;
          animation: background_scale 1.2s ease-in-out forwards;
        }
        .heading,
        .logo {
          opacity: 0;
          transform-origin: center;
          animation-delay: 0.6s;
          animation-name: zoomIn;
          animation-timing-function: ease-out;
          animation-fill-mode: forwards;
        }
        .heading {
          font-size: 1.5rem;
          font-weight: 200;
          text-transform: uppercase;
          font-family: 'Poppins', sans-serif;
          animation-duration: 1s;
        }

        .logo {
          width: 430px;
          max-width: min(calc(100vw - 100px), calc(100% - 100px));
          animation-duration: 1.2s;
        }
        .logo img {
          width: 100%;
          height: auto;
        }

        @media (max-width: 768px) {
          .status-indicator :global(.enter) {
            width: 150px;
            height: 150px;
            font-size: 0.9rem;
          }
        }
        @media (max-height: 600px) {
          .status-indicator :global(.enter) {
            width: 150px;
            height: 150px;
            font-size: 0.9rem;
          }
        }
        @media (orientation: landscape) and (max-height: 450px) {
          .inverse-landing {
            display: grid;
            grid-template-columns: 50% 50%;
          }
          .status-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      `}</style>
    </>
  );
};

export default InverseLanding;

import React from 'react';
import { useTranslation } from '../../../../i18n';
import { parsePlainTextToRichText } from '../../../../utils/string';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { CHScenes } from '../../PSInterface';
import CHMapDial from './CHMapDial';
import { mapAsset, mapSpot } from './CHMaps';
const SpotNav = ({
  title,
  scene,
  command,
  setActiveScene,
  viewport,
  onClickSpot
}: {
  title: string;
  scene: string;
  command: CHScenes;
  setActiveScene: (scene: string) => void;
  viewport: { width: number; height: number };
  onClickSpot: (spot: string) => void;
}) => {
  const onMouseEnter = () => {
    setActiveScene(command);
  };
  const onMouseLeave = () => {
    setActiveScene(null);
  };

  const onClick = () => {
    onClickSpot(command);
  };
  return (
    <div
      className="spot"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="chopard-sans title">{title}</div>
      <div className="chopard-serif scene">
        {parsePlainTextToRichText(scene)}
      </div>
      <style jsx>{`
        .spot {
          text-transform: uppercase;
          text-align: left;
          font-size: ${viewport.height * 0.024}px;
          margin-bottom: ${viewport.height * 0.066}px;
          cursor: pointer;
          user-select: none;
        }
        .spot:last-child {
          margin-bottom: 0 !important;
        }

        @media (max-aspect-ratio: 22/10) {
          :global(.MDLandscape.normal-mode) .spot {
            font-size: ${viewport.width * 0.0132}px;
            margin-bottom: ${viewport.width * 0.0363}px;
          }
          :global(.MDLandscape.normal-mode) .spot {
            font-size: ${(viewport.width -
              MDLandscapeNormalSpec.contentArea.left) *
            0.0132}px;
            margin-bottom: ${(viewport.width -
              MDLandscapeNormalSpec.contentArea.left) *
            0.0363}px;
          }
        }

        @media (max-aspect-ratio: 18/10) {
          .spot {
            font-size: ${viewport.width * 0.0132}px;
            margin-bottom: ${viewport.width * 0.0363}px;
          }
        }
      `}</style>
    </div>
  );
};
const CHMapLandscapeLarge = ({
  activeScene,
  onClickSpot,
  setActiveScene,
  viewport
}: {
  activeScene: CHScenes;
  onClickSpot?: (scene: CHScenes) => void;
  setActiveScene?: (scene: CHScenes) => void;
  viewport: { width: number; height: number };
}) => {
  const { i18n } = useTranslation();
  const lang = i18n.language === 'ko' ? 'ko' : 'en';
  const SpotArrow = ({
    scene,
    active
  }: {
    scene: CHScenes;
    active?: boolean;
  }) => (
    <img
      src={`${mapAsset}/landscape_${scene}.png`}
      alt="spot-arrow"
      className={`spot-arrow ${active ? 'active' : ''}`}
    />
  );

  return (
    <div className="CHMap CHMapLandscapeLarge d-flex">
      <div className="col col1">
        <div>
          {mapSpot.slice(0, 4).map((spot) => {
            return (
              <SpotNav
                key={spot.command}
                title={spot.detail[lang]?.title}
                scene={spot.detail[lang]?.scene}
                command={spot.command}
                setActiveScene={setActiveScene}
                viewport={viewport}
                onClickSpot={onClickSpot}
              />
            );
          })}
        </div>
      </div>
      <div className="col col-watch">
        <img src={`${mapAsset}/watch-d.png`} alt="map" />
        <CHMapDial
          activeScene={activeScene}
          setActiveScene={setActiveScene}
          onClickSpot={onClickSpot}
        />
      </div>
      <div className="col col3">
        <div>
          <div>
            {mapSpot.slice(4, mapSpot.length).map((spot) => {
              return (
                <SpotNav
                  key={spot.command}
                  title={spot.detail[lang]?.title}
                  scene={spot.detail[lang]?.scene}
                  command={spot.command}
                  setActiveScene={setActiveScene}
                  viewport={viewport}
                  onClickSpot={onClickSpot}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="arrows">
        <div className="arrows-container d-flex flex-column h-100">
          {mapSpot.map((spot) => (
            <SpotArrow
              key={spot.command}
              scene={spot.command}
              active={activeScene === spot.command}
            />
          ))}
        </div>
      </div>
      <style jsx>{`
        .CHMap {
          position: relative;
          height: ${viewport.height}px;
          width: ${viewport.height * 1.9}px;
          margin: auto;
        }
        @media (max-aspect-ratio: 22/10) {
          :global(.MDLandscape.normal-mode) .CHMap {
            height: ${(viewport.width -
              MDLandscapeNormalSpec.contentArea.left) *
            0.53}px;
            width: ${viewport.width - MDLandscapeNormalSpec.contentArea.left}px;
          }
        }
        @media (max-aspect-ratio: 18/10) {
          .CHMap {
            height: ${viewport.width * 0.53}px;
            width: ${viewport.width}px;
          }
        }
        .col {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center;
          padding: 0 7vh;
          flex-wrap: wrap;
          margin: auto;
        }

        .col > div {
          margin: auto;
        }
        .col1 > div {
          margin-right: 0;
        }
        .col3 > div {
          margin-left: 0;
        }
        .col-watch {
          flex: 0 0 0;
          padding: 0 !important;
          position: relative;
        }
        .col-watch img {
          height: 100%;
          width: auto;
          display: block;
          margin: auto;
          pointer-events: none;
          user-select: none;
        }

        .arrows {
          top: 0;
          bottom: 0;
          width: 181vh;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          pointer-events: none;
        }

        .arrows :global(img) {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: auto;
          width: 100%;
          opacity: 0.008;
          user-select: none;
        }
        .arrows :global(img.active) {
          opacity: 1;
        }

        @media (max-aspect-ratio: 22/10) {
          :global(.MDLandscape.normal-mode) .col-watch img {
            height: ${viewport.width * 0.53}px;
          }
          :global(.MDLandscape.normal-mode) .col {
            padding: ${viewport.width * 0.0385}px;
          }
          :global(.MDLandscape.normal-mode) .col-watch img {
            height: ${(viewport.width -
              MDLandscapeNormalSpec.contentArea.left) *
            0.53}px;
          }
          :global(.MDLandscape.normal-mode) .col {
            padding: ${(viewport.width -
              MDLandscapeNormalSpec.contentArea.left) *
            0.0385}px;
          }
          :global(.MDLandscape.normal-mode) .arrows {
            top: 50%;
            bottom: auto;
            width: 100%;
            left: 0;
            right: 0;
            height: 99.66vw;
            transform: translateY(-50%);
          }
        }

        @media (max-aspect-ratio: 18/10) {
          .col-watch img {
            height: ${viewport.width * 0.53}px;
          }
          .col {
            padding: ${viewport.width * 0.0385}px;
          }
          .arrows {
            top: 50%;
            bottom: auto;
            width: 100%;
            left: 0;
            right: 0;
            height: 99.66vw;
            transform: translateY(-50%);
          }
        }
      `}</style>
    </div>
  );
};

export default CHMapLandscapeLarge;

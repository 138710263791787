import { last } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  IMainState,
  ParticipantMeetingState,
  VirtualBoutiqueState
} from '../../../../interfaces';
import BackgroundMusic from '../../../BackgroundMusic';
import { canInteractWithAnimation } from '../../../hooks/meeting';
import AvatarVideos from '../RDUSGCustomComponent/AvatarVideos';
import RDUSGHud from '../RDUSGCustomComponent/RDUSGHud';
import RDUWW21FullScreenVideo from './RDUWW21FullScreenVideo';
import RDUWW21HostControls from './RDUWW21HostControls';
const RDUWW21CustomComponent = () => {
  const router = useRouter();
  const isAdvisor = router.query.role === 'advisor';
  const isInCockpit = router.query.mode === 'cockpit';
  const canInteract = canInteractWithAnimation();
  const clientState = useSelector((state: IMainState) => state.clientState);
  const vb: VirtualBoutiqueState = clientState?.vb || {};
  return (
    <>
      {canInteract && <RDUWW21HostControls />}
      {(!isInCockpit ||
        (isInCockpit && isAdvisor) ||
        (isInCockpit &&
          clientState?.meeting?.state ===
            ParticipantMeetingState.LEFT_MEETING)) && (
        <RDUSGHud
          hud={vb?.hud}
          currentScene={last(vb?.sceneIds)}
          currentView={vb?.currentView}
          meetingLayout={
            clientState?.meeting?.state ===
              ParticipantMeetingState.ENTERED_FROM_LOUNGE &&
            clientState?.meeting?.layout?.mode
          }
        />
      )}
      {(!isInCockpit ||
        (isInCockpit &&
          clientState?.meeting?.state ===
            ParticipantMeetingState.LEFT_MEETING)) && (
        <AvatarVideos
          canPlay={!clientState?.vb?.fullScreenVideoWithUrl?.show}
          currentScene={last(vb?.sceneIds)}
        />
      )}
      <BackgroundMusic
        backgroundMusicUrl={clientState?.vb?.activeBackgroundMusicUrl}
        play={!clientState?.fileViewer?.playerControl}
      />
      <RDUWW21FullScreenVideo />
      );
    </>
  );
};

export default RDUWW21CustomComponent;

import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { AiOutlineZoomIn } from 'react-icons/ai';
import ReactImageMagnify from 'react-image-magnify';
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';
import { IMainState } from '../../interfaces';
import { actionDidSlideProductImagesSlider } from '../../redux/actions';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

interface Props {
  imageUrls: string[];
  allowZoom?: boolean;
}

export const CustomSliderArrow = ({ type, ...rest }) => {
  const { onClick } = rest;

  const shape =
    type === 'next' ? (
      <polyline points="9 6 15 12 9 18" />
    ) : (
      <polyline points="15 6 9 12 15 18" />
    );

  return (
    <>
      <button
        onClick={() => onClick()}
        className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--${
          type === 'next' ? 'right' : 'left'
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          {shape}
        </svg>
      </button>

      <style jsx>
        {`
          .react-multiple-carousel__arrow::before {
            display: none;
          }

          .react-multiple-carousel__arrow:hover {
            color: #fff;
          }

          .react-multiple-carousel__arrow {
            width: 46px;
            height: 46px;
          }
        `}
      </style>
    </>
  );
};

const ZoomHint = () => {
  const { t } = useTranslation();
  return (
    <div className="hint">
      <div className="hint-inner">
        <AiOutlineZoomIn className="hint-icon" />
        {isMobile || isTablet ? (
          <span className="hint-text">{t('tap_hold_to_zoom')}</span>
        ) : (
          <span className="hint-text">{t('hover_to_zoom')}</span>
        )}
      </div>
      <style jsx>{`
        .hint {
          position: absolute;
          bottom: 5px;
          right: 5px;
          color: #000;
        }
        :global(.hint-icon) {
          width: 20px;
          height: 20px;
        }
        .hint-inner {
          display: flex;
          align-items: center;
          padding: 3px 10px 3px 3px;
          background-color: #fff;
          border-radius: 40px;
          opacity: 0.4;
        }
        .hint-text {
          padding: 2px 0px 0px 5px;
          font-family: Arial, sans-serif;
          font-size: 12px;
        }

        @media (min-width: 768px) {
          .hint {
            right: 25px;
            bottom: 25px;
          }
          .hint-inner {
            padding: 5px 10px;
          }
        }
      `}</style>
    </div>
  );
};

const ImageMagnify = ({ src }: { src: string }) => {
  const [zoomHasLoaded, setZoomHasLoaded] = React.useState(false);
  const [hiresSize, setHiresSize] = React.useState<number>(0);

  const hiresUrl = (url: string) => {
    return url.replace('1000x1000', '2000x2000');
  };

  const loadingComplete = () => {
    setZoomHasLoaded(true);
    setHiresSize(isMobile ? 1500 : 2000);
  };

  return (
    <>
      <ReactImageMagnify
        {...{
          className: 'zoom-container',
          enlargedImageContainerClassName: `zoom-image ${
            zoomHasLoaded ? 'loaded' : 'loading'
          }`,
          smallImage: {
            alt: src,
            isFluidWidth: true,
            src: src
          },
          enlargedImageContainerStyle: {
            left: 0,
            marginLeft: 0
          },
          hintComponent: () => <ZoomHint />,
          largeImage: {
            src: hiresUrl(src),
            width: hiresSize,
            height: hiresSize,
            onLoad: (ev) => {
              if ((ev.target as any).complete) {
                loadingComplete();
              }
            },
            onError: (ev) => {
              loadingComplete();
              (ev.target as any).src = src;
            }
          },
          isHintEnabled: true,
          shouldHideHintAfterFirstActivation: false,
          lensStyle: { backgroundColor: 'transparent' }
        }}
      />
      <style jsx>
        {`
          :global(body) {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }
          :global(.zoom-image) {
            background: #fff;
          }
          :global(.zoom-image.loading) {
            background: transparent;
          }
          :global(.zoom-image.loading:after) {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: #fff url(${'/asset/loading-square.gif'}) center center
              no-repeat;
            opacity: 0.5;
          }
        `}
      </style>
    </>
  );
};

const Slider = ({ imageUrls, allowZoom = false }: Props) => {
  const sliderRef = React.useRef<Carousel>();
  const dispatch = useDispatch();
  const { remoteProductImageSliderIndex } = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  React.useEffect(() => {
    sliderRef.current?.goToSlide(0);
    dispatch(actionDidSlideProductImagesSlider(0));
  }, [imageUrls]);

  React.useEffect(() => {
    if (
      typeof remoteProductImageSliderIndex === 'number' &&
      remoteProductImageSliderIndex !== sliderRef.current?.state?.currentSlide
    )
      sliderRef.current?.goToSlide(remoteProductImageSliderIndex);
  }, [remoteProductImageSliderIndex]);

  return (
    <React.Fragment>
      {imageUrls.length > 0 && (
        <Carousel
          ref={sliderRef}
          responsive={responsive}
          ssr={false}
          removeArrowOnDeviceType={['mobile']}
          showDots={imageUrls.length > 1}
          renderButtonGroupOutside={true}
          renderDotsOutside={true}
          customRightArrow={<CustomSliderArrow type="next" />}
          customLeftArrow={<CustomSliderArrow type="prev" />}
          afterChange={(_, state) => {
            const currentSlide = state.currentSlide;
            dispatch(actionDidSlideProductImagesSlider(currentSlide));
          }}
        >
          {allowZoom
            ? imageUrls.map((src: string, index: number) => (
                <ImageMagnify key={index} src={src} />
              ))
            : imageUrls.map((src: string, index: number) => (
                <img key={index} src={src} alt={src} />
              ))}
        </Carousel>
      )}
    </React.Fragment>
  );
};

export default Slider;

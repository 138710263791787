import React from 'react';
import { useTranslation } from '../../../i18n';
import { IMeetingRemoteUsers, MeetingRole } from '../../../interfaces';

function LoungeParticipantsOverview({
  remoteUsers,
  breakPoint = 786
}: {
  remoteUsers: IMeetingRemoteUsers;
  breakPoint?: number;
}) {
  const readyRemoteUsersParticipantIds = Object.keys(remoteUsers || {}).filter(
    (participantId) => remoteUsers[participantId].ready
  );

  const participantInLounge = readyRemoteUsersParticipantIds.filter(
    (participantId) => !remoteUsers[participantId].shouldShowVideo
  );

  const participantInMeeting = readyRemoteUsersParticipantIds.filter(
    (participantId) => remoteUsers[participantId].shouldShowVideo
  );

  const inLoungeCount = participantInLounge.length;
  const inMeetingCount = participantInMeeting.length;
  const { t } = useTranslation();
  const inLoungeTKey =
    inLoungeCount === 1
      ? 'guest_waiting_in_lounge'
      : 'guest_waiting_in_lounge_plural';
  const inMeetingTKey =
    inMeetingCount === 1 ? 'guest_in_meeting' : 'guest_in_meeting_plural';
  return (
    <>
      <div className="remote-users">
        <div className="remote-users-list in-lounge">
          <p
            dangerouslySetInnerHTML={{
              __html: t(inLoungeTKey, {
                count: inLoungeCount,
                interpolation: { escapeValue: false }
              })
            }}
          ></p>
          <ul>
            {participantInLounge.map((participantId) => {
              const remoteUser = remoteUsers[participantId];
              return (
                <li key={participantId}>
                  {remoteUser?.role === MeetingRole.STUDIO
                    ? t('studio')
                    : remoteUser?.displayName || t('guest_user')}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="remote-users-list in-meeting">
          <p
            dangerouslySetInnerHTML={{
              __html: t(inMeetingTKey, {
                count: inMeetingCount,
                interpolation: { escapeValue: false }
              })
            }}
          ></p>
          <ul>
            {participantInMeeting.map((participantId) => {
              const remoteUser = remoteUsers[participantId];
              return (
                <li key={participantId}>
                  {remoteUser?.role === MeetingRole.STUDIO
                    ? t('studio')
                    : remoteUser?.displayName || t('guest_user')}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <style jsx>
        {`
          .remote-users {
            display: none;
            overflow: hidden;
            background-color: #191919;
            position: fixed;
            left: 0;
            bottom: 0;
            top: 0;
            flex-direction: column;
            align-items: center;
            text-align: left;
            height: 100vh;
          }
          @media screen and (orientation: landscape) and (max-height: 767px) {
            .remote-users {
              width: 136px;
              color: #fff;
              align-items: center;
              padding: 0 25px;
            }
            .remote-users .remote-users-list {
              margin: auto;
              padding: 20px 0;
            }
            .remote-users p {
              font-size: 0.625em;
              text-transform: uppercase;
              color: #ffffff;
              margin-bottom: 20px;
            }

            .remote-users ul {
              margin: 0;
              padding: 0;
              list-style: none;
            }
            .remote-users li {
              font-weight: bold;
              font-size: 0.625em;
              line-height: 0.875em;
              padding: 13px 0;
              line-break: anywhere;
              text-transform: capitalize;
            }
          }

          @media (min-width: 768px) and (min-height: 520px) {
            .remote-users {
              background: #191919;
              position: fixed;
              left: 0;
              bottom: 0;
              top: 0;
              width: 240px;
              color: #fff;
              padding: 0 40px;
            }
            .remote-users .remote-users-list {
              margin: auto;
              padding: 40px 0;
            }
            .remote-users p {
              font-size: 1em;
              line-height: 1.375em;
              text-transform: uppercase;
              color: #ffffff;
              margin-bottom: 32px;
              font-weight: 300;
            }

            .remote-users ul {
              margin: 0;
              padding: 0;
              list-style: none;
            }
            .remote-users li {
              font-weight: bold;
              font-size: 1em;
              line-height: 1.375em;
              padding: 20px 0;
              line-break: anywhere;
              text-transform: capitalize;
            }

            .remote-users .powered-by-wrapper {
              display: none;
            }
          }

          @media (min-width: ${breakPoint}px) {
            .remote-users {
              display: flex;
              padding: 0 48px;
              width: 275px;
              height: 100vh;
            }
          }
          .remote-users-list p ::global(span) {
            font-weight: bold;
          }
          .remote-users-list {
            flex: 0 0 50%;
            max-height: 50vh;
            display: flex;
            flex-direction: column;
          }
          .in-meeting {
            border-top: 1px solid #666666;
          }
          ul {
            flex: 1;
            overflow-y: auto;
          }
          ul::-webkit-scrollbar {
            width: 5px;
            border-radius: 5px;
          }

          ul::-webkit-scrollbar-track {
            background: #252525;
          }

          ul::-webkit-scrollbar-thumb {
            background: #515151;
            border-radius: 5px;
          }
        `}
      </style>
    </>
  );
}

export default LoungeParticipantsOverview;

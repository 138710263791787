import React from 'react';
import {
  isUserOnKakaotalk,
  isUserOnLineBrowser,
  isUserOnWeChat
} from '../../../utils/deviceDetector';
import KakaotalkPrompt from './KakaoTalkPrompt';
import LinePrompt from './LinePrompt';
import WeChatPrompt from './WeChatPrompt';

const BrowserPrompt = () => {
  const [isWeChat, setIsWeChat] = React.useState(false);
  const [isLine, setIsLine] = React.useState(false);
  const [isKakaoTalk, setIsKakaoTalk] = React.useState(false);
  React.useEffect(() => {
    setIsWeChat(isUserOnWeChat());
    setIsLine(isUserOnLineBrowser());
    setIsKakaoTalk(isUserOnKakaotalk());
  }, []);

  const getPrompt = () => {
    if (isWeChat) {
      return <WeChatPrompt />;
    }
    if (isLine) {
      return <LinePrompt />;
    }
    if (isKakaoTalk) {
      return <KakaotalkPrompt />;
    } else return null;
  };

  return getPrompt();
};

export default BrowserPrompt;

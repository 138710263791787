import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IProduct } from '../../interfaces';
import { getProductDisplayName, getProductEdition } from '../../utils/product';
import Placeholder from '../Placeholder';
import EyeballTimeLogger from '../EyeballTimeLogger';
import Header from '../Header';
import { PageView } from '../../utils/pageView';
import { useTranslation } from '../../i18n';
import { PotmContent } from './mockData';
import { parsePlainTextToRichText } from '../../utils/string';
import Link from '../Common/Link';

export interface IPickedProductProp {
  product: IProduct;
  content: PotmContent;
}

const GradientBgPickedProduct = ({ product, content }: IPickedProductProp) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { imageUrls, modelCode } = product;
  const edition = getProductEdition(product, language);
  const title = content.title[language] || content.title['en'] || '';
  const description =
    content.description[language] || content.description['en'] || '';
  const pickedAppearance = content.appearance;
  return (
    <>
      <Header
        title={t('pick_of_the_month').toUpperCase()}
        shareTitle={`${t(
          'pick_of_the_month'
        ).toUpperCase()}: ${getProductDisplayName(product, language)}`}
        shareImage={product.imageUrls.length > 0 && product.imageUrls[0]}
        type={PageView.PICK_OF_THE_MONTH}
        id={product.id}
      />
      <EyeballTimeLogger
        tracker={{ view: PageView.PICK_OF_THE_MONTH, id: product.id }}
      >
        {isLoading && <Placeholder />}
        <div className="section potm-section">
          <div className="container content-container">
            <div className="potm-title mobile">{title}</div>
            <Row className="align-items-center">
              <Col className="col col-12 col-lg-4" />
              <Col className="col col-12 col-lg-4 text-center">
                <Link
                  href="/products/[id]"
                  as={`/products/${product.id}`}
                  onClick={() => setIsLoading(true)}
                >
                  <img
                    className="img-fluid"
                    src={imageUrls[0]}
                    alt={getProductDisplayName(product, language)}
                  />
                </Link>
              </Col>
              <div className="content col col-12 col-lg-4 watch-details">
                <div className="potm-title desktop">{title}</div>
                <p className="modelcode">{modelCode}</p>
                {edition && <p className="product-edition">{edition}</p>}
                <p className="title">
                  {getProductDisplayName(product, language)}
                </p>
                <div className="desc">
                  {parsePlainTextToRichText(description)}
                </div>
              </div>
            </Row>
          </div>
        </div>
      </EyeballTimeLogger>
      <style jsx>
        {`
          .section {
            background: ${pickedAppearance?.bgColor || '#000'};
            color: ${pickedAppearance?.textColor || '#fff'};
          }
          .container {
            background-image: url(${pickedAppearance?.bgImageMobile || ''});
            background-size: 100% auto;
            background-position: center top;
            background-repeat: no-repeat;
            padding-top: 65%;
            padding-bottom: 30px;
          }
          .potm-title {
            font-weight: 500;
            display: inline-block;
            font-size: 1em;
          }

          :global(.isRtl .potm-page .potm-title i) {
            font-style: normal !important;
            font-size: 1.2em;
          }

          .mobile {
            position: absolute;
            top: 75px;
            left: 0;
            width: 100%;
            text-align: center;
          }

          .desktop {
            display: none;
          }

          .title {
            text-transform: uppercase;
            line-height: 1.4;
            font-size: 0.5em;
            font-weight: 500;
            word-wrap: break-word;
            margin-top: 0;
            padding: 0 70px;
          }

          :global(.isRtl .potm-page .title) {
            font-size: 0.7em;
            padding: 0 30%;
          }

          .modelcode {
            letter-spacing: 1.5px;
            font-size: 1.1em;
            font-weight: 500;
            margin-bottom: 0;
          }

          :global(.isRtl .potm-title) {
            direction: ltr;
          }

          :global(.isRtl .potm-page .modelcode) {
            font-size: 0.9em;
          }
          .desc :global(p) {
            font-size: 0.8em;
            letter-spacing: 0.5px;
            font-weight: 500;
          }

          .desc :global(p:first-child) {
            font-style: italic;
          }

          .desc :global(strong) {
            text-transform: uppercase;
            font-size: 0.6em;
          }

          .desc :global(p:last-child) {
            margin-bottom: 0;
            font-size: 0.6em;
          }

          :global(.isRtl .desc p:first-child) {
            font-style: normal;
          }

          :global(.isRtl .potm-page .desc p:first-child) {
            font-size: 1em;
            font-family: Helvetica;
            font-weight: 200;
            line-height: 1.2;
            direction: rtl;
          }

          .content {
            text-align: center;
          }
          :global(cite) {
            display: block;
            font-size: 0.8em;
          }
          :global(cite + cite) {
            font-size: 0.7em;
          }

          :global(.isRtl .potm-section cite + cite) {
            font-size: 0.6em;
            direction: rtl;
          }
          .img-fluid {
            margin: auto;
            max-width: 290px;
            width: 100%;
            height: auto;
          }

          @media (min-width: 768px) {
            .img-fluid {
              max-width: 500px;
            }

            .mobile {
              top: 100px;
            }

            .potm-title {
              font-size: 1.8em;
            }
          }

          @media (min-width: 992px) {
            .section {
              background-image: linear-gradient(
                to right,
                ${pickedAppearance?.gradientBgColors?.[0] || '#000'},
                ${pickedAppearance?.gradientBgColors?.[1] || '#000'}
              );
              color: ${pickedAppearance?.textColor || '#fff'};
            }
            .desktop {
              display: block;
            }
            .mobile {
              display: none;
            }
            .img-fluid {
              width: auto;
            }
            .potm-title {
              font-size: 1em;
            }
            :global(.isRtl .potm-page .potm-title i) {
              font-style: normal !important;
              font-size: 1em;
            }
            .title,
            :global(.isRtl .potm-page .title) {
              padding: 0 0 0 30%;
            }

            .container {
              background-image: url(${pickedAppearance?.bgImage || ''});
              padding: 0 50px;
              background-size: auto 100%;
            }
            .img-fluid {
              max-width: none;
              margin-top: -80px;
              margin-bottom: -60px;
              height: 520px;
              margin-left: 50%;
              transform: translateX(-50%);
            }
            .section {
              margin-top: 40px;
              margin-bottom: 20px;
            }
            .watch-details {
              text-align: right;
            }
            .modelcode {
              margin-top: 15px;
            }

            .desc :global(p:first-child) {
              margin: 30px 0;
            }

            .desc :global(strong) {
              font-size: 0.6em;
            }

            .desc :global(p:last-child) {
              font-size: 0.5em;
            }
          }

          @media (min-width: 1200px) {
            .potm-title {
              font-size: 1.1em;
              margin-top: 0;
            }
            :global(.isRtl .potm-section) {
              font-size: 20px;
            }

            .img-fluid {
              height: 570px;
            }

            .modelcode {
              margin-top: 30px;
            }
          }
        `}
      </style>
    </>
  );
};

export default GradientBgPickedProduct;

import { iwcBrandId, alsBrandId, piagetBrandId } from '../config';

const prefixForBrand = [
  {
    brandId: iwcBrandId,
    prefix: 'VJP'
  },
  {
    brandId: alsBrandId,
    prefix: 'NYF'
  },
  {
    brandId: piagetBrandId,
    prefix: 'CVN'
  }
];

const generateMeetingId = (length: number, brandId: string) => {
  const match = prefixForBrand.find(p => p.brandId === brandId);
  const prefix = match ? match.prefix : '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const randomId = Array(length)
    .fill('')
    .reduce(
      (prev) =>
        prev + characters.charAt(Math.floor(Math.random() * characters.length)),
      ''
    );
  return `${prefix}${randomId}`;
};

export default generateMeetingId;

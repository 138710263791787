import { last } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useChain, useSpring, useTrail } from 'react-spring';
import { logEvent } from '../../../analytics';
import {
  IMainState,
  PanoJourneyConfig,
  PanoJourneyOption
} from '../../../interfaces';
import {
  actionDisableVBJourney,
  actionGoBackToLastScene,
  actionHideVBJourney,
  actionOpenVBJourney
} from '../../../redux/actions';
import {
  DID_CLICK_JOURNEY_TO_SCENE,
  DID_DISABLE_JOURNEY_GUIDE
} from '../../../utils/constants';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { useTranslation } from '../../../i18n';
import { getBrandJourneyTheme } from '../../BrandStyle';

interface GuideTitle {
  message: string;
  subtitle?: string;
}

const PanoJourneyGuide = ({
  journeyConfig,
  goToScene,
  brandId
}: {
  journeyConfig: PanoJourneyConfig;
  goToScene: (sceneId: string) => void;
  brandId: string;
}) => {
  const [title, setTitle] = React.useState<GuideTitle>(null);
  const [optionsList, setOptionsList] = React.useState<PanoJourneyOption[][]>(
    []
  );
  const [showOptions, setShowOptions] = React.useState(true);
  const theme = getBrandJourneyTheme(brandId);
  const show = useSelector(
    (state: IMainState) =>
      state.clientState.showBoutiqueJourney &&
      !state.clientState.disableBoutiqueJourney
  );

  const sceneIds: string[] = useSelector(
    (state: IMainState) => state.clientState.vb?.sceneIds || []
  );

  const currentSceneId = last(sceneIds);

  const dispatch = useDispatch();

  const hideGuide = () => {
    dispatch(actionHideVBJourney());
  };

  const showGuide = () => {
    dispatch(actionOpenVBJourney());
  };

  const { t, i18n } = useTranslation();

  const controlButtons = (
    <div className="guide-option dismiss">
      <span
        onClick={() => {
          hideGuide();
          dispatch(actionDisableVBJourney());
          logEvent(DID_DISABLE_JOURNEY_GUIDE);
        }}
      >
        {t('vb:dismiss')}
      </span>
      {(sceneIds.length > 1 || optionsList.length > 1) && (
        <>
          <span className="separator"></span>
          <span
            onClick={() => {
              if (optionsList.length === 1) {
                const lastSceneId = sceneIds[sceneIds.length - 2];
                dispatch(actionGoBackToLastScene());
                goToScene(lastSceneId);
              } else if (optionsList.length > 1) {
                const copy = [...optionsList];
                copy.pop();
                updateOptionsList(copy);
              }
            }}
          >
            {t('vb:back')}
          </span>
        </>
      )}
    </div>
  );

  const updateOptions = (nextOptions: PanoJourneyOption[]) => {
    updateOptionsList([...optionsList, nextOptions]);
  };

  const updateOptionsList = (newList: PanoJourneyOption[][]) => {
    setShowOptions(false);
    setTimeout(() => {
      setOptionsList(newList);
      setShowOptions(true);
    }, 1000);
  };

  const renderOption = (option: PanoJourneyOption) => (
    <div
      key={option.label}
      className={`guide-option`}
      onClick={() => {
        if (option.sceneId) {
          goToScene(option.sceneId);
          logEvent(DID_CLICK_JOURNEY_TO_SCENE, option.sceneId);
        }
        if (option.options) {
          updateOptions(option.options);
        }
      }}
    >
      {option.labelLangKey ? t(`vb:${option.labelLangKey}`) : option.label}
    </div>
  );

  const renderOptionAtIndex = (index: number) => {
    const currentOptions = last(optionsList);
    if (!currentOptions) return null;
    if (index < currentOptions.length) {
      return renderOption(currentOptions[index]);
    }
    if (index === currentOptions.length) {
      return controlButtons;
    }
  };

  React.useEffect(() => {
    hideGuide();
    if (!journeyConfig[currentSceneId]) {
      return;
    }
    setTimeout(() => {
      setTitle({
        message: journeyConfig[currentSceneId].messageLangKey
          ? t(`vb:${journeyConfig[currentSceneId].messageLangKey}`)
          : journeyConfig[currentSceneId].message,
        subtitle: journeyConfig[currentSceneId].subtitleLangKey
          ? t(`vb:${journeyConfig[currentSceneId].subtitleLangKey}`)
          : journeyConfig[currentSceneId].subtitle
      });
      const journeyOptions = journeyConfig[currentSceneId].options || [];
      setOptionsList([journeyOptions]);
      showGuide();
    }, 2000);
  }, [currentSceneId, i18n.language]);

  React.useEffect(() => {
    if (isUserOnMobileOnly()) {
      dispatch(actionDisableVBJourney());
    }
  }, []);

  const getSpringConfig = (
    toggle: boolean,
    ref: React.MutableRefObject<any>
  ) => ({
    opacity: toggle ? 1 : 0,
    transform: toggle ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
    from: {
      opacity: toggle ? 0 : 1,
      transform: toggle ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, 0, 0)'
    },
    config: {
      tension: 300
    },
    ref: ref
  });

  const titleRef = React.useRef();
  const titleProps = useSpring(getSpringConfig(show, titleRef));

  const optionsRef = React.useRef();

  const trail = useTrail(20, getSpringConfig(show && showOptions, optionsRef));

  useChain([titleRef, optionsRef]);

  if (!title) {
    return null;
  }

  return (
    <>
      <div className={`pano-guide`}>
        <animated.div style={titleProps}>
          <div className="guide-title">
            <h3>{title.message}</h3>
            {title.subtitle && <h6>{title.subtitle}</h6>}
          </div>
        </animated.div>
        {trail.map((props, index) => {
          const currentOptions = last(optionsList);
          if (!currentOptions) {
            return null;
          }
          return (
            index <= currentOptions.length && (
              <animated.div
                className="guide-content-wrapper"
                style={props}
                key={index}
              >
                {renderOptionAtIndex(index)}
              </animated.div>
            )
          );
        })}
      </div>
      <style jsx global>
        {`
          .pano-guide {
            display: flex;
            flex-direction: column;
            pointer-events: none;
            text-align: left;
            position: fixed;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
          }

          .pano-guide .guide-content-wrapper {
            width: fit-content;
          }

          .pano-guide .guide-title {
            padding: ${theme.titlePadding};
            background: ${theme.titleBackground};
            border-radius: 10px;
            color: ${theme.titleTextColor};
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .pano-guide .guide-option {
            padding: ${theme.guidePadding};
            background: ${theme.guideOptionBackground};
            border-radius: 10px;
            color: ${theme.guideTextColor};
            text-transform: uppercase;
            margin-bottom: 10px;
            width: fit-content;
            cursor: pointer;
            pointer-events: auto;
            font-size: ${theme.guideTextSize};
          }

          .pano-guide .dismiss {
            background: ${theme.dismissBackground};
            color: ${theme.dismissTextColor};
          }

          .pano-guide h3 {
            letter-spacing: 2px;
            font-weight: 600;
            margin-bottom: 0;
            font-size: ${theme.titleTextSize};
          }

          .pano-guide h6 {
            font-size: 14px;
            margin: 10px 0 0 0;
          }

          .pano-guide p {
            font-size: 18px;
          }

          .pano-guide .guide-title p {
            margin: 10px 0 20px 0;
          }

          .pano-guide .buttons {
            display: flex;
            width: 100%;
          }

          .pano-guide button {
            cursor: pointer;
            pointer-events: auto;
            padding: 10px 15px;
            border-radius: 5px;
            background: transparent;
            width: 40%;
          }

          .pano-guide button:first-child {
            margin-right: 20px;
          }

          .pano-guide button.outline {
            border: 1px solid #fff;
            color: #fff;
          }

          .pano-guide button.solid {
            background: #fff;
          }

          .pano-guide .separator {
            display: inline-block;
            margin: 0 15px;
            line-height: 24px;
            background: black;
            width: 2px;
            height: 20px;
            transform: translateY(5px);
          }

          @media (max-width: 768px) {
            .pano-guide {
              max-width: 80vw;
            }

            .pano-guide h3 {
              letter-spacing: 0.5px;
              font-weight: 600;
              font-size: 12px;
            }

            .pano-guide h6 {
              font-size: 12px;
              margin: 5px 0 0 0;
            }

            .pano-guide p {
              font-size: 12px;
            }

            .pano-guide button {
              padding: 7px 10px;
              font-size: 12px;
            }

            .pano-guide .guide-title {
              padding: 8px 8px 8px 25px;
              margin-bottom: 8px;
            }

            .pano-guide .guide-title p {
              margin: 10px 0;
            }

            .pano-guide .guide-option {
              padding: 8px 8px 8px 25px;
              font-size: 12px;
              margin-bottom: 8px;
            }

            .pano-guide .separator {
              margin: 0 10px;
              height: 12px;
              transform: translateY(2px);
            }
          }
        `}
      </style>
    </>
  );
};

export default PanoJourneyGuide;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from '../../../../interfaces';
import {
  actionMuteBgSound,
  actionSultanateOfOmanPopup,
  actionUnmuteBgSound
} from '../../../../redux/actions';
import AskWatchmaker from './AskWatchmaker';
import SultanateOfOman from './SultanateOfOman';

const IWCDubaiCustomComponent = () => {
  const config = useSelector(
    (state: IMainState) => state.clientState.virtualBoutiqueConfig
  );
  const store = useSelector((state: IMainState) => state.clientState.store);
  const sultanateOfOmanPopupState = useSelector(
    (state: IMainState) => state.clientState.vb?.sultanateOfOmanPopup
  );
  const dispatch = useDispatch();
  const onClose = () =>
    dispatch(
      actionSultanateOfOmanPopup({
        ...(sultanateOfOmanPopupState || {}),
        open: false
      })
    );

  React.useEffect(() => {
    if (sultanateOfOmanPopupState?.open) {
      dispatch(actionMuteBgSound());
    } else if (sultanateOfOmanPopupState?.open === false) {
      dispatch(actionUnmuteBgSound());
    }
  }, [sultanateOfOmanPopupState?.open]);

  return (
    <>
      <AskWatchmaker />
      {config && (
        <SultanateOfOman
          logoUrl={config.logoUrl}
          storeId={store?.id}
          open={sultanateOfOmanPopupState?.open}
          phoneCountryCode={config.phoneCountryCode}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default IWCDubaiCustomComponent;

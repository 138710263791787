import { last } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import { IMainState, PageContentView } from '../../interfaces';
import { DID_GO_TO_SCENE, DID_GO_TO_SPOT } from '../../utils/constants';
import { PageView } from '../../utils/pageView';
import EyeballTimeLogger from '../EyeballTimeLogger';
import { objectSpotMap } from './Chopard/CHConfig';

const PSEyeballTimeLogger = () => {
  const { i18n } = useTranslation();
  const isKorean = i18n.language?.toLowerCase() === 'ko';
  const psState = useSelector(
    (state: IMainState) => state.clientState.vb?.pixelStreaming || {}
  );

  const activeSpot = psState.focusedObject;

  const contentType =
    activeSpot?.type === PageView.PRODUCT
      ? PageContentView.PRODUCT_SPOT
      : activeSpot?.type === PageView.INSPIRATION
      ? PageContentView.INSPIRATION_SPOT
      : PageContentView.SCENE_SPOT;

  const contentId = activeSpot?.id
    ? objectSpotMap[activeSpot.id]?.[
        contentType === PageContentView.INSPIRATION_SPOT && isKorean
          ? 'idKo'
          : 'id'
      ] || activeSpot.id
    : undefined;

  const visitedScene = useSelector(
    (state: IMainState) => state.clientState.vb?.sceneIds
  );

  const activeScene = last(visitedScene);

  React.useEffect(() => {
    if (activeScene) {
      logEvent(DID_GO_TO_SCENE, DID_GO_TO_SCENE, { id: activeScene });
    }
  }, [activeScene]);

  React.useEffect(() => {
    if (activeSpot) {
      logEvent(DID_GO_TO_SPOT, DID_GO_TO_SPOT, {activeSpot});
    }
  }, [activeSpot]);
  return (
    <>
      {activeSpot?.id && (
        <EyeballTimeLogger
          key={activeSpot.id}
          tracker={{
            view: contentType,
            id: contentId,
            spotId: activeSpot.id
          }}
        >
          {null}
        </EyeballTimeLogger>
      )}

      {activeScene && (
        <EyeballTimeLogger
          key={activeScene}
          tracker={{
            view: PageContentView.SCENE,
            id: activeScene
          }}
        >
          {null}
        </EyeballTimeLogger>
      )}
    </>
  );
};

export default PSEyeballTimeLogger;

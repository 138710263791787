import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { AppointmentType } from '../../../interfaces';
import { actionAppointmentPopup } from '../../../redux/actions';
import { DID_CLICK_APPOINTMENT } from '../../../utils/constants';

const AppointmentButton = ({
  label,
  appointmentTypes
}: {
  label?: string;
  appointmentTypes?: AppointmentType[];
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = React.useState(false);
  const reqAppointmentTypes = appointmentTypes || [
    AppointmentType.VIRTUAL_WALKTHROUGH,
    AppointmentType.PHYSICAL
  ];
  const handleOpenAppointment = (type: AppointmentType) => {
    const payload = { open: true, type };
    dispatch(actionAppointmentPopup(payload));
    logEvent(DID_CLICK_APPOINTMENT, DID_CLICK_APPOINTMENT, payload);
  };

  const translationForAppointmentType = (type: AppointmentType) => {
    switch (type) {
      case AppointmentType.VIRTUAL_WALKTHROUGH:
        return t('vb:virtual_walkthrough');
      case AppointmentType.PHYSICAL:
        return t('vb:physical_boutique_visit');
      default:
        return null;
    }
  };
  const handleAppointmentButton = (showDetails) => {
    if (reqAppointmentTypes?.length === 1) {
      handleOpenAppointment(reqAppointmentTypes[0]);
    } else {
      setShowDetails(showDetails);
    }
  };
  return (
    <div
      className="AppointmentButton"
      onMouseLeave={() => setShowDetails(false)}
      onClick={() => handleAppointmentButton(!showDetails)}
    >
      <button className="action-button" id="ap-open">
        {label || (
          <>
            <span className="full">{t('vb:make_appointment')}</span>
            <span className="mobile">{t('vb:appointment')}</span>
          </>
        )}
      </button>
      <div className={`button-options-list ${showDetails ? 'active' : null}`}>
        {reqAppointmentTypes.map((type, index) => (
          <span key={index} onClick={() => handleOpenAppointment(type)}>
            {translationForAppointmentType(type)}
          </span>
        ))}
      </div>

      <style jsx>
        {`
          .AppointmentButton {
            position: relative;
          }
          .mobile {
            display: none;
          }

          .full {
            display: inline-block;
          }

          .button-options-list {
            width: 200px;
          }

          @media (max-width: 768px) {
            .mobile {
              display: inline-block;
            }
            .button-options-list {
              width: auto;
            }

            .full {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AppointmentButton;

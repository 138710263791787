import React from 'react';
import { logEvent } from '../../../../analytics';
import { zIndex } from '../../../../config';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import { DID_CLICK_MENU_BUTTON } from './../../../../utils/constants';

interface MenuItem {
  title?: string;
  src: string;
  onClick?: () => void;
  disable?: boolean;
}
const LFMenu = ({
  assetBase,
  viewOnly,
  menuList,
  onClickHamburger,
  open
}: {
  viewOnly: boolean;
  menuList: MenuItem[];
  onClickHamburger: (open: boolean) => void;
  assetBase: string;
  open: boolean;
}) => {
  const menuButton = (title: string, src: string, onClick?: () => void) => (
    <img
      key={title}
      src={`${assetBase}/${src}`}
      alt={title}
      id={`menu-${title}`}
      className="menu-item menu-button"
      onClick={() => {
        onClickHamburger(!open);
        logEvent(DID_CLICK_MENU_BUTTON, DID_CLICK_MENU_BUTTON, {
          menu: title
        });
        if (onClick) {
          onClick();
        }
      }}
    />
  );

  if (viewOnly) return null;
  return (
    <div className="LFMenu noselect">
      <div className={`item ${open ? 'opened' : ''}`}>
        {menuList.map((menu) =>
          !menu?.disable ? menuButton(menu.title, menu.src, menu.onClick) : null
        )}
      </div>

      <div
        className="hamburger"
        onClick={() => {
          onClickHamburger(!open);
          logEvent(DID_CLICK_MENU_BUTTON, DID_CLICK_MENU_BUTTON, {
            menu: 'hamburger',
            openMenu: !open
          });
        }}
      >
        <img
          src={`${LFAssetBaseUrl}/menu/v1/${
            open ? 'close.png' : 'hamburger.png'
          }`}
          alt="Menu"
          className="menu-button"
        />
      </div>

      <style jsx>{`
        .LFMenu {
          position: fixed;
          left: 0;
          top: 0;
          z-index: 11000;
        }
        :global(.in-meeting) .LFMenu {
          z-index: ${zIndex.meetingVideosWrapper - 2};
        }
        :global(.in-meeting.SM) .LFMenu {
          top: ${SMPortraitNormalSpec.contentArea.top}px;
        }
        :global(.in-meeting.SMLandscape) .LFMenu {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }

        :global(.in-meeting.MD) .LFMenu {
          top: ${MDLandscapeNormalSpec.contentArea.top}px;
        }
        :global(.in-meeting.MDLandscape) .LFMenu {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }

        .LFMenu :global(.menu-button) {
          cursor: pointer;
          width: 100%;
          height: auto;
        }
        .LFMenu :global(#menu-Favourites),
        .LFMenu :global(#menu-LanguageSwitch) {
          width: 80%;
          margin-top: 0;
        }
        .LFMenu :global(#menu-Map) {
          margin-top: -40px;
        }
        .item,
        .hamburger {
          width: 60px;
          height: auto;
          position: fixed;
          left: 0;
          top: 0;
        }
        :global(.in-meeting.SM) .item,
        :global(.in-meeting.SM) .hamburger {
          top: ${SMLandscapeNormalSpec.contentArea.top}px;
        }
        :global(.in-meeting.MD) .item,
        :global(.in-meeting.MD) .hamburger {
          top: ${MDLandscapeNormalSpec.contentArea.top}px;
        }

        :global(.in-meeting.SMLandscape) .item,
        :global(.in-meeting.SMLandscape) .hamburger {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }
        :global(.in-meeting.MDLandscape) .item,
        :global(.in-meeting.MDLandscape) .hamburger {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }
        :global(.in-meeting.SMPortrait) .hamburger {
          top: ${SMLandscapeNormalSpec.contentArea.top +
          SMPortraitNormalSpec.thumbnailSelf.height +
          SMPortraitNormalSpec.thumbnailSpacing * 4}px;
        }
        :global(.in-meeting.MDPortrait) .hamburger {
          top: ${MDLandscapeNormalSpec.contentArea.top +
          MDPortraitNormalSpec.thumbnailSelf.height +
          SMPortraitNormalSpec.thumbnailSpacing * 4}px;
        }
        .hamburger {
          position: fixed;
        }
        .LFMenu :global(.menu-item) {
          margin: 10px auto;
          width: 90%;
        }
        .item {
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: rgba(0, 0, 0, 0.5);
          transform: translateX(-100%);
          transition: transform 0.2s ease-in;
        }
        .item.opened {
          transform: translateX(0);
        }
        @media (min-width: 768px) {
          .item,
          .hamburger {
            width: 100px;
          }
        }
        @media (orientation: landscape) and (max-height: 760px) {
          .item,
          .hamburger {
            width: 60px;
          }
        }
      `}</style>
    </div>
  );
};

export default LFMenu;

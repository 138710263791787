import { IHudState, RDUHudMenu } from './../../../../../interfaces';
import { MdClose, MdHome } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import {
  actionDidClickHudMenu,
  actionOpenHud
} from '../../../../../redux/actions';
import { logEvent } from './../../../../../analytics/index';
import {
  DID_CLICK_CLOSE_HUD,
  DID_CLICK_HUD_MENU
} from './../../../../../utils/constants';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDGlobalSpec } from '../../../../Meeting/MeetingLayout/MDGlobal';
import { SMGlobalSpec } from '../../../../Meeting/MeetingLayout/SMGlobal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../../Meeting/MeetingLayout/MDPortraitNormal';

const HudFrame = ({
  children,
  hud,
  className = ''
}: {
  children: React.ReactNode;
  hud?: IHudState;
  className?: string;
}) => {
  const open = hud?.open;
  const dispatch = useDispatch();
  const getExtraClass = () => {
    if (open === undefined || open === null) return className;
    if (open) return `${className} in`;
    return `${className} out`;
  };

  const isPresentation = className.includes('isPresentation');
  const hudHeight = isPresentation ? 'calc(100vh - 150px)' : '100vh';

  return (
    <>
      <div className={`backdrop ${getExtraClass()}`} />
      <div className={`HudFrame ${getExtraClass()}`}>
        <div className="frame">
          <div className="frame-t">
            <div className="frame-t-l" />
            <div className="frame-t-c">
              {className !== 'frame-main' && (
                <img className="frame-logo" src="/asset/rdu/logo-light.png" />
              )}
            </div>
            <div className="frame-t-r" />

            <button
              className="close"
              type="button"
              onClick={() => {
                dispatch(actionOpenHud(false));
                logEvent(DID_CLICK_CLOSE_HUD, DID_CLICK_CLOSE_HUD, {
                  frame: className
                });
              }}
            >
              <MdClose />
            </button>

            {className !== 'frame-pirelli' && (
              <button
                className="close exit"
                type="button"
                onClick={() => {
                  dispatch(actionOpenHud(false));
                  dispatch(actionDidClickHudMenu(RDUHudMenu.EXIT));
                  logEvent(DID_CLICK_HUD_MENU, DID_CLICK_HUD_MENU, {
                    menu: RDUHudMenu.EXIT
                  });
                }}
              >
                <MdHome />
              </button>
            )}
          </div>
          <div className="frame-c">
            <div className="frame-c-inner">{children}</div>
          </div>
          <div className="frame-b">
            <div className="frame-b-l" />
            <div className="frame-b-r" />
          </div>
        </div>
      </div>
      <style jsx>{`
        .close {
          position: absolute;
          color: #fff;
          opacity: 1;
          top: -10px;
          right: -10px;
          font-size: 2.2em;
        }
        .close.exit {
          left: 2px;
          right: auto;
        }

        .HudFrame,
        .backdrop {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 200;
          overflow: hidden;
        }
        :global(.in-meeting.SM) .HudFrame,
        :global(.in-meeting.SM) .backdrop {
          top: ${SMGlobalSpec.logoSectionHeight}px !important;
        }
        :global(.in-meeting.MD) .HudFrame,
        :global(.in-meeting.MD) .backdrop {
          top: ${MDGlobalSpec.logoSectionHeight}px !important;
        }
        :global(.normal-mode.MDPortrait) .HudFrame,
        :global(.normal-mode.MDPortrait) .backdrop {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom}px !important;
        }
        :global(.normal-mode.SMLandscape) .HudFrame,
        :global(.normal-mode.SMLandscape) .backdrop {
          left: ${SMLandscapeNormalSpec.contentArea.left}px !important;
        }
        :global(.normal-mode.MDLandscape) .HudFrame,
        :global(.normal-mode.MDLandscape) .backdrop {
          left: ${MDLandscapeNormalSpec.contentArea.left}px !important;
        }
        .HudFrame {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .HudFrame.in {
          -webkit-animation: HudFrame-in 0.5s linear both;
          animation: HudFrame-in 0.5s linear both;
        }
        .HudFrame.out {
          -webkit-animation: HudFrame-out 1s linear both;
          animation: HudFrame-out 1s linear both;
        }

        .backdrop.frame-main {
          background: rgba(0, 0, 0, 0.4);
        }
        .backdrop.in {
          -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
            both;
          animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
        .backdrop.out {
          -webkit-animation: fade-out 1s ease-out both;
          animation: fade-out 1s ease-out both;
        }

        .backdrop.frame-pirelli {
          -webkit-animation: none;
          animation: none;
        }
        .frame-logo {
          width: 160px;
        }
        .frame {
          position: relative;
          height: 80vh;
          width: 80vw;
          max-width: 1400px;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }
        :global(.normal-mode.MDPortrait) .frame {
          height: calc(80vh - ${MDPortraitNormalSpec.contentArea.bottom}px) !important;
        }
        :global(.normal-mode.SMLandscape) .frame {
          width: calc(95vw - ${SMLandscapeNormalSpec.videosWrapper.width}px) !important;
        }
        :global(.normal-mode.MDLandscape) .frame {
          width: calc(95vw - ${MDLandscapeNormalSpec.contentArea.left}px) !important;
        }

        .HudFrame.in .frame {
          -webkit-animation: frame-in 0.7s ease-in-out both;
          animation: frame-in 0.7s ease-in-out both;
        }

        .HudFrame.out .frame {
          -webkit-animation: frame-out 0.7s ease-in-out both;
          animation: frame-out 0.7s ease-in-out both;
        }

        .frame div {
          background-repeat: no-repeat;
        }
        .frame-c {
          flex-grow: 1;
          position: relative;
          display: flex;
          background-image: url('/asset/rdu/frame-left.png');
          background-position: center left;
          z-index: 1;
        }
        .frame-c-inner {
          flex-direction: column;
          justify-content: center;
          background-image: url('/asset/rdu/frame-right.png');
          background-position: center right;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }
        .frame-pirelli .frame-c-inner {
          justify-content: flex-end;
        }
        .frame-b,
        .frame-t {
          display: flex;
          align-items: stretch;
          position: relative;
        }
        .frame-t > div,
        .frame-b > div {
          background-size: auto 100%;
        }

        .frame-b {
          height: 110px;
        }

        .frame-pirelli .frame-b {
          position: relative;
          z-index: 1;
        }
        .frame-b-l {
          background-image: url('/asset/rdu/frame-bottom-left.png');
          background-position: bottom left;
          flex-grow: 1;
        }
        .frame-b-r {
          background-image: url('/asset/rdu/frame-bottom-right.png');
          background-position: bottom right;
          flex-grow: 1;
        }
        .frame-t {
          height: 89px;
        }
        .frame-t-l {
          background-image: url('/asset/rdu/frame-top-left.png');
          background-position: top left;
          max-width: 350px;
          flex-grow: 1;
        }
        .frame-t-r {
          background-image: url('/asset/rdu/frame-top-right.png');
          background-position: top right;
          max-width: 350px;
          flex-grow: 1;
        }
        .frame-t-c {
          background-image: url('/asset/rdu/frame-top-center.png');
          background-position: top center;
          flex-grow: 1;
          min-width: 400px;
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
            height: 0;
          }
          1% {
            opacity: 0;
            height: ${hudHeight};
          }
          100% {
            opacity: 1;
            height: ${hudHeight};
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
            height: 0;
          }
          1% {
            opacity: 0;
            height: ${hudHeight};
          }
          100% {
            opacity: 1;
            height: ${hudHeight};
          }
        }

        @-webkit-keyframes fade-out {
          0% {
            opacity: 1;
            height: ${hudHeight};
          }
          99% {
            opacity: 0;
            height: ${hudHeight};
          }
          100% {
            opacity: 0;
            height: 0;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
            height: ${hudHeight};
          }
          99% {
            opacity: 0;
            height: ${hudHeight};
          }
          100% {
            opacity: 0;
            height: 0;
          }
        }

        @-webkit-keyframes HudFrame-in {
          0% {
            height: 0;
          }
          1% {
            height: ${hudHeight};
          }
          100% {
            height: ${hudHeight};
          }
        }
        @keyframes HudFrame-in {
          0% {
            height: 0;
          }
          1% {
            height: ${hudHeight};
          }
          100% {
            height: ${hudHeight};
          }
        }

        @-webkit-keyframes HudFrame-out {
          0% {
            height: ${hudHeight};
          }
          99% {
            height: ${hudHeight};
          }
          100% {
            height: 0;
          }
        }
        @keyframes HudFrame-out {
          0% {
            height: ${hudHeight};
          }
          99% {
            height: ${hudHeight};
          }
          100% {
            height: 0;
          }
        }

        @-webkit-keyframes frame-in {
          0% {
            -webkit-transform: scale(0, 0.05);
            transform: scale(0, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          30% {
            -webkit-transform: scale(1, 0.05);
            transform: scale(1, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          60% {
            -webkit-transform: scale(1, 0.05);
            transform: scale(1, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
        }
        @keyframes frame-in {
          0% {
            -webkit-transform: scale(0, 0.05);
            transform: scale(0, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          30% {
            -webkit-transform: scale(1, 0.05);
            transform: scale(1, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          60% {
            -webkit-transform: scale(1, 0.05);
            transform: scale(1, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
        }

        @keyframes frame-out {
          0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          30% {
            -webkit-transform: scale(1, 0.05);
            transform: scale(1, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          60% {
            -webkit-transform: scale(1, 0.05);
            transform: scale(1, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
          100% {
            -webkit-transform: scale(0, 0.05);
            transform: scale(0, 0.05);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
          }
        }

        @media (max-width: 724px) {
          .close {
            font-size: 1.5em;
            right: 5px;
          }
          .close.exit {
            left: 2px;
            top: -8px;
          }
          .frame-logo {
            width: 90px;
          }
          .frame {
            width: 100vw;
            height: 72vh;
            margin-top: 80px;
          }
          .frame-c,
          .frame-c-inner {
            background: none;
          }
          .frame-t {
            height: 37px;
          }
          .frame-t > div {
            background-size: auto 50px;
          }
          .frame-b {
            height: 50px;
          }
          .frame-b > div {
            background-size: auto 80px;
          }
          .frame-t-c {
            min-width: 200px;
          }
        }
      `}</style>
    </>
  );
};

export default HudFrame;

import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import { IProduct } from '../../interfaces';
import {
  DID_CLOSE_PRODUCT_DESCRIPTION,
  DID_VIEW_PRODUCT_DESCRIPTION
} from '../../utils/constants';
import { getProductDescription } from '../../utils/product';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';
import { actionShowProductDescription } from '../../redux/actions';
import { isParentWindowInMeeting, jumpToAnchor } from '../../utils/window';

const ProductDescriptionAccordion = ({ product }: { product: IProduct }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const description = getProductDescription(product, language);

  const showDetails =
    useSelector(
      (state: IMainState) => state.clientState?.productDetails?.showDescription
    ) || false;

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(actionShowProductDescription(false));
  }, [product]);

  React.useEffect(() => {
    if (showDetails && isParentWindowInMeeting()) {
      jumpToAnchor('#description-anchor');
    }
  }, [showDetails]);

  if (!description) return null;

  const getDescriptionInHTML = () => {
    //replace /n -> <br/>
    return description?.replace(/\n/g, '<br/>');
  };
  return (
    <Accordion className="details-accordion">
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          onClick={() => {
            if (!showDetails) {
              logEvent(DID_VIEW_PRODUCT_DESCRIPTION);
              dispatch(actionShowProductDescription(true));
            } else {
              logEvent(DID_CLOSE_PRODUCT_DESCRIPTION);
              dispatch(actionShowProductDescription(false));
            }
          }}
        >
          <span className={showDetails ? 'strong' : null}>
            {t('description')}
          </span>
          {showDetails ? <BsChevronDown /> : <BsChevronRight />}
        </Accordion.Toggle>
        <Accordion.Collapse
          eventKey="0"
          className={`${showDetails ? 'show' : 'hide'}`}
        >
          <Card.Body>
            <div
              className="descriptions-container"
              dangerouslySetInnerHTML={{ __html: getDescriptionInHTML() }}
            ></div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <style jsx>{`
        .descriptions-container {
          text-align: justify;
        }

        .descriptions-container > p {
          margin-bottom: 5px;
        }
        .descriptions-container ::global(b) {
          font-weight: bold !important;
        }
      `}</style>
    </Accordion>
  );
};

export default ProductDescriptionAccordion;

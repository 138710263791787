import { useRouter } from 'next/router';
import React from 'react';

const NonCockpitModeOnlyElements = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const isInCockpit = router.query.mode === 'cockpit';
  if (isInCockpit) return null;
  return <>{children}</>;
};

export default NonCockpitModeOnlyElements;

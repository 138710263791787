import React from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { IProduct, ProductGrouping } from '../../interfaces';
import { actionDidViewPage } from '../../redux/actions';
import Header from '../Header';
import Placeholder from '../Placeholder';
import BrandHeader from './BrandHeader';
import ShowcaseProducts from './ShowcaseProducts';
import { PageView } from '../../utils/pageView';
import { useTranslation } from '../../i18n';
import { capitalize } from 'lodash';

interface IShowcaseProps {
  products: IProduct[];
  description: string;
  configurationId: string;
  productGrouping?: ProductGrouping[];
  showLogo?:boolean;
}

const Showcase = ({
  products,
  description,
  configurationId,
  productGrouping,
  showLogo
}: IShowcaseProps) => {
  const brandName: string | null =
    products.length > 0 ? products[0].brandName : null;

  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actionDidViewPage(PageView.SHOWCASE));
  }, []);

  const { t } = useTranslation();
  const showcase = capitalize(t('showcase'));

  if (products.length === 0) return null;

  return (
    <div className="products-landing">
      <Header
        title="&nbsp;"
        shareTitle={brandName ? `${brandName} ${showcase}` : showcase}
        type={PageView.SHOWCASE}
        id={configurationId}
      />
      {isLoading && <Placeholder />}
      <Container>
        <BrandHeader
          product={products.length > 0 ? products[0] : null}
          description={description}
          showLogo={showLogo}
        />
        <ShowcaseProducts
          products={products}
          onClick={() => {
            setIsLoading(true);
          }}
          productGrouping={productGrouping}
        />
      </Container>
      <style jsx>
        {`
          .products-landing :global(header) {
            position: absolute;
            top: -45px;
            background-color: transparent;
          }

          .products-landing :global(.share svg) {
            fill: #000;
          }

          .products-landing {
            position: relative;
          }
          .products-landing :global(.thumbnail) {
            text-align: center;
            width: 100%;
            display: block;
            padding: 10px 10px 40px;
            background: transparent;
            border: none;
            color: #000;
            text-decoration: none;
          }
          @media (min-width: 1023px) {
            .products-landing :global(.thumbnail) {
              padding-bottom: 60px;
            }
          }
          .products-landing :global(.thumbnail img) {
            object-fit: cover;
            height: 150px;
            max-width: 90%;
          }
          @media (min-width: 1023px) {
            .products-landing :global(.thumbnail img) {
              height: 200px;
            }
          }
          .products-landing :global(.prod-model) {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 0.75em;
            letter-spacing: 1.5px;
            color: #666;
            font-weight: 300;
          }
          @media (min-width: 1023px) {
            .products-landing :global(.prod-model) {
              font-size: 0.9em;
            }
          }
          .products-landing :global(.prod-title) {
            font-size: 0.8em;
            text-transform: uppercase;
            font-weight: 500;
            width: 100%;
            margin-top: 5px;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            letter-spacing: 0.5px;
          }
          @media (min-width: 1023px) {
            .products-landing :global(.prod-title) {
              padding: 0 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          .products-landing :global(.price) {
            margin-top: 10px;
            font-size: 0.8em;
          }
        `}
      </style>
    </div>
  );
};

export default Showcase;

import React from 'react';
import { BsMusicNoteBeamed, BsChevronDown, BsChevronUp } from 'react-icons/bs';

const AudioPlayer = ({
  playlist,
  open,
  onToggle
}: {
  playlist: string;
  open: boolean;
  onToggle: (b: boolean) => void;
}) => {
  return (
    <div className={`AudioPlayer ${open ? 'open' : 'minimize'}`}>
      <div className="toggle-container" onClick={() => onToggle(!open)}>
        <BsMusicNoteBeamed />
        {open ? <BsChevronDown /> : <BsChevronUp />}
      </div>
      <iframe
        src={playlist}
        frameBorder="0"
        allow="encrypted-media"
        className={open ? 'open' : 'minimize'}
      />
      <style jsx>{`
        .AudioPlayer {
          position: fixed;
          overflow: hidden;
          z-index: 1;
          right: 100px;
          bottom: 0;
          width: 300px;
          transition: all 0.3s ease-in-out;
        }
        iframe {
          width: 100%;
          height: 380px;
        }

        .AudioPlayer.open {
          height: 400px;
        }
        .AudioPlayer.minimize {
          height: 40px;
        }
        .toggle-container {
          height: 40px;
          width: 100%;
          background: #222;
          color: #fff;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
        }
        @media (max-width: 420px) {
          .AudioPlayer {
            right: 20px;
            left: 20px;
            width: auto;
          }
        }
      `}</style>
    </div>
  );
};

export default AudioPlayer;

import { format } from 'date-fns';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import { getUserByIdentityId } from '../../../clientSideServices/users';
import { tiffanyBrandId } from '../../../config';
import { useTranslation } from '../../../i18n';
import {
  INudge,
  IOpeningHoursByWeekday,
  IUser,
  IVirtualBoutiqueAppointmentContact
} from '../../../interfaces';
import { formatTo24HourTime } from '../../../utils/clock';
import {
  DID_BOOK_VIDEO_APPOINTMENT_SUCCESSFULLY,
  DID_FAIL_TO_BOOK_VIDEO_APPOINTMENT
} from '../../../utils/constants';
import { generateV4UUID } from '../../../utils/identityGenerator';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import {
  getAvailableSlotsByWeekdayForStore,
  getAvailableSlotsForStore,
  getIsDateAllowedByBrand,
  getStoreDateNow
} from './AppointmentDateTimeCalculator';
import { bookPrivateAppointment } from './appointmentService';
import { isValidName } from './inputValidator';

const animationDuration = 300;

interface IAppointmentFormPristine {
  name: boolean;
  time: boolean;
}

export interface IVideoAppointmentFormData {
  name: string;
  date: Date;
  time: string | null;
}

export interface IAppointmentProps {
  appointmentContacts: IVirtualBoutiqueAppointmentContact[];
  openingHours: string[];
  openingHoursByWeekday?: IOpeningHoursByWeekday;
  visitHours?: string[];
  logoUrl: string;
  brandId: string;
  storeId: string;
  open: boolean;
  timezoneGMTOffset: number;
  timezoneName: string;
  phoneCountryCode: string;
  onClose: () => void;
  advisorId: string;
  nudge: INudge;
}

const VideoAppointment = ({
  open,
  onClose,
  brandId,
  timezoneName,
  openingHours,
  openingHoursByWeekday,
  logoUrl,
  advisorId,
  nudge
}: IAppointmentProps) => {
  const { t, i18n } = useTranslation();
  const [salesHubAdvisor, setSalesHubAdvisor] = React.useState<IUser>(null);
  const [formData, setFormData] = React.useState<IVideoAppointmentFormData>({
    name: null,
    date: getStoreDateNow(timezoneName),
    time: null
  });
  const [pristine, setPristine] = React.useState<IAppointmentFormPristine>({
    name: true,
    time: true
  });
  const [submitting, setSubmitting] = React.useState(false);
  const [appointmentBooked, setAppointmentBooked] = React.useState(false);
  const CTAText = submitting ? t('SUBMITTING') : t('SUBMIT');

  const invalidName = !pristine.name && !isValidName(formData?.name);

  const invalidTime =
    !pristine.time && (!formData.time || formData.time.trim().length === 0);

  const handleInput = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setPristine({ ...pristine, [name]: false });
    setFormData({ ...formData, [name]: value });
  };

  const handleTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const time = event.target.value;
    setPristine({ ...pristine, time: false });
    setFormData({ ...formData, time });
  };

  const handleDate = (date: Date) => {
    console.log(date);
    if (!date) date = getStoreDateNow(timezoneName);
    setFormData({ ...formData, date });
  };

  const resetForm = () => {
    setSubmitting(false);
    setFormData({
      name: null,
      date: getStoreDateNow(timezoneName),
      time: null
    });
    setPristine({
      name: true,
      time: true
    });
  };

  const handleClose = () => {
    resetForm();
    setAppointmentBooked(false);
    onClose();
  };

  const renderSelectTime = () => {
    return openingHoursByWeekday
      ? getAvailableSlotsByWeekdayForStore(
          formData.date,
          openingHoursByWeekday,
          30
        ).map((s) => (
          <option value={s} key={s}>
            {s}
          </option>
        ))
      : getAvailableSlotsForStore(formData.date, openingHours).map((s) => (
          <option value={s} key={s}>
            {s}
          </option>
        ));
  };

  const removePristine = () => {
    setPristine({
      name: false,
      time: false
    });
  };

  const formatData = (data) => {
    const defaultValues = {
      id: generateV4UUID(),
      createdAt: '',
      createdBy: '',
      modifiedAt: '',
      modifiedBy: '',
      status: '',
      preferredLanguage: ''
    };
    const formatted = {
      ...defaultValues,
      ...data
    };

    if (formatted.date) {
      formatted.date = format(formatted.date, 'MM-dd-yyyy');
    }

    if (formatted.time) {
      const startTime = formatted.time.split('-')[0].trim();
      const endTime = formatted.time.split('-')[1].trim();

      formatted.startTime = formatTo24HourTime(startTime);
      formatted.endTime = formatTo24HourTime(endTime);
    }

    formatted.storeId = nudge?.storeId;
    formatted.customerId = nudge?.customerId;
    formatted.advisorId = nudge?.advisorId;
    formatted.inviteId = nudge?.id;
    formatted.preferredLanguage = nudge?.languageCode;
    formatted.advisorName = salesHubAdvisor?.alias;
    formatted.timezone = timezoneName;

    return formatted;
  };

  const handleSend = () => {
    removePristine();
    if (
      invalidName ||
      invalidTime ||
      !formData.name ||
      formData.name.trim().length === 0 ||
      !formData.time ||
      formData.time.trim().length === 0
    ) {
      return;
    }

    const data = formatData(formData);

    setSubmitting(true);
    bookPrivateAppointment(data)
      .then((result) => {
        setSubmitting(false);

        if (result.status === 200) {
          setAppointmentBooked(true);
          logEvent(DID_BOOK_VIDEO_APPOINTMENT_SUCCESSFULLY);
          return;
        }

        logEvent(DID_FAIL_TO_BOOK_VIDEO_APPOINTMENT);
      })
      .catch(() => {
        setSubmitting(false);
        logEvent(DID_FAIL_TO_BOOK_VIDEO_APPOINTMENT);
      });
  };

  React.useEffect(() => {
    if (advisorId) {
      getUserByIdentityId(advisorId)
        .then((result) => {
          if (!result.data['errorMessage']) {
            setSalesHubAdvisor(result.data);
          }
        })
        .catch((e) => {
          console.log(`cannot get user by id ${advisorId}`);
          console.log(e);
        });
    }
  }, []);

  return (
    <div className="appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className={`popup-container fadein ${
              appointmentBooked ? 'successful' : ''
            } ${state}`}
            id="ap-popup"
          >
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => handleClose()}
              >
                &times;
              </button>
              <div className="logo">
                <img src={logoUrl} alt="LOGO" />
              </div>
              {!appointmentBooked && (
                <form id="emailform">
                  <h2>{t('vb:book_your_private_session')}</h2>
                  <div className="input-group">
                    <label>
                      {brandId === tiffanyBrandId
                        ? t('vb:client_advisor_name')
                        : t('vb:sales_advisor_name')}
                      :
                    </label>
                    <p className="advisor-name mb-0">
                      {salesHubAdvisor?.alias}
                    </p>
                  </div>
                  <div className="">
                    <div className="input-group">
                      <label>{t('vb:name')}:</label>
                      <input
                        type="text"
                        id="inputFirstName"
                        name="name"
                        value={formData?.name || ''}
                        placeholder={t('vb:enter_your_name')}
                        onChange={handleInput}
                      />
                      {invalidName && (
                        <span className="error"> {t('vb:required')}</span>
                      )}
                    </div>{' '}
                  </div>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:appointment_date')}:</label>
                      <DatePicker
                        selected={formData.date}
                        filterDate={getIsDateAllowedByBrand(brandId)}
                        minDate={getStoreDateNow(timezoneName)}
                        onChange={handleDate}
                        dateFormat="MMMM d, yyyy"
                        locale={i18n.language}
                      />
                    </div>

                    <div className="input-group">
                      <label>
                        {t('vb:preferred_time', { timezone: timezoneName })}:
                      </label>
                      <select
                        id="inputtime"
                        name="time"
                        required
                        onChange={handleTime}
                      >
                        <option value="">{t('vb:select')}</option>
                        {renderSelectTime()}
                      </select>
                      {invalidTime && (
                        <span className="error time">
                          {t('vb:please_select_time')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-group mt-5">
                    <button
                      id="sendform"
                      type="button"
                      disabled={submitting}
                      onClick={() => handleSend()}
                    >
                      {CTAText}
                    </button>
                    <button
                      id="ap-cancel"
                      className="popup-close"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      {t('vb:close')}
                    </button>
                  </div>
                </form>
              )}
              {appointmentBooked && (
                <div className="success">
                  <h2>{t('vb:thank_you_for_your_request')}</h2>
                  <p>{t('vb:our_ca_will_contact_soon_private_session')}</p>
                  <button
                    className="popup-close"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('vb:close')}
                  </button>
                </div>
              )}
              <PoweredByInspify />
            </div>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
        `}
      </style>
      <style jsx>{`
        .advisor-name {
          width: 100%;
          text-transform: uppercase;
        }
        .two-col .error {
          top: 1px;
        }
      `}</style>
    </div>
  );
};

export default VideoAppointment;

import React from 'react';
import { Button } from 'react-bootstrap';
import { AiFillShopping } from 'react-icons/ai';
import { logEvent } from '../../../analytics';
import { IProduct } from '../../../interfaces';
import { DID_CLICK_SHOP_NOW_BUTTON } from '../../../utils/constants';
import { getProductDisplayName } from '../../../utils/product';
import { useTranslation } from '../../../i18n';

const ShopNowEmailButton = ({
  product,
  email,
  cc
}: {
  product: IProduct;
  email?: string;
  cc?: string;
}) => {
  const logAnalytics = () => {
    logEvent(DID_CLICK_SHOP_NOW_BUTTON);
  };

  const { t } = useTranslation();
  const emailSubject = `I am interested to purchase the ${getProductDisplayName(
    product,
    'EN'
  )} (${product.modelCode})`;
  const emailBody = `Dear Sir/Mdm,%0D%0AI am interested to purchase the ${getProductDisplayName(
    product,
    'EN'
  )} (${product.modelCode}).%0D%0AKindly contact me back at ________ (email/hp).%0D%0AThank you!`;

  const emailCC = cc ? `&cc=${cc}` : '';

  return (
    <Button
      className="btn-action shop-now"
      variant="dark"
      onClick={() => logAnalytics()}
    >
      <a
        target="_blank"
        rel="noopener"
        href={`mailto:${email || ''}?subject=${emailSubject}${emailCC}&body=${emailBody}`}
      >
        {' '}
        <AiFillShopping /> {t('shop_now')}
      </a>
      <style jsx>
        {`
          a :global(svg) {
            width: 24px;
            height: 24px;
            position: relative;
            top: -2px;
          }

          a {
            color: inherit;
          }
          a:hover {
            text-decoration: none;
          }
        `}
      </style>
    </Button>
  );
};

export default ShopNowEmailButton;

import React from 'react';
import { useSelector } from 'react-redux';
import {
  IMainState,
  MeetingRole,
  ParticipantMeetingState
} from '../../../interfaces';
import { getTwoLetterFromName } from '../../../utils/string';
import { MeetingContext } from '../MeetingContext';
import MeetingParticipantControlsButton from './../Controls/MeetingParticipantControlsButton';
import ParticipantStatusIndicators from './../ParticipantStatusIndicators';
import { useTranslation } from '../../../i18n';
import VirtualBackgroundDialog from '../SelectVirtualBg/VirtualBackgroundDialog';
import ParticipantConnectionIndicators from '../ParticipantConnectionIndicators';

const LocalVideo = ({ showGrid }: { showGrid?: boolean }) => {
  const localVideoRef = React.useRef();
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const displayName = useSelector(
    (state: IMainState) => state.clientState.user?.alias
  );
  const localTracks = meeting.localUser?.tracks || [];
  const isSharingScreen = meeting.localUser?.isSharingScreen;
  const videoEffect = meeting.localUser?.videoEffect;
  const localVideoTrack = localTracks.find(
    (track) => track.getType() === 'video'
  );

  const audioMuted = meeting?.localUser?.audioMuted;
  const videoMuted = meeting?.localUser?.videoMuted;

  const { room } = React.useContext(MeetingContext);

  const selected = meeting?.participantControls === room?.myUserId();
  const isEnlarged =
    meeting.layout?.enlargedVideoParticipantId &&
    room?.myUserId() === meeting.layout?.enlargedVideoParticipantId;
  const isInvisible = meeting.layout?.invisibleParticipantIds?.includes(
    room?.myUserId()
  );

  const isDominantSpeaker =
    meeting?.dominantSpeakerParticipantId === room?.myUserId();

  const isStudio = meeting.localUser?.role === MeetingRole.STUDIO;
  const { t } = useTranslation();
  React.useEffect(() => {
    console.log('attaching local track');
    localVideoTrack?.attach(localVideoRef.current);
    return () => {
      localVideoRef.current && localVideoTrack?.detach(localVideoRef.current);
    };
  }, [localVideoTrack]);

  const isUsingVirtualBackground = videoEffect?.effect?.enabled;
  return (
    <div
      className={`local-video-container video-thumbnail ${
        isEnlarged ? 'enlarge' : ''
      } ${isDominantSpeaker && !audioMuted ? 'isSpeaking' : ''} ${
        isInvisible ? 'isInvisible' : ''
      } ${selected ? 'isSelected' : ''}`}
      id={`video-${meeting.localUser?.participantId}`}
    >
      {!isStudio && (
        <span className="initialName notranslate">
          {getTwoLetterFromName(displayName)}
        </span>
      )}
      <video
        autoPlay
        playsInline
        ref={localVideoRef}
        id="local-video"
        className="local-video"
      ></video>

      <div className="display-name notranslate">
        {displayName || t('set_your_name')}
      </div>
      {videoEffect?.showDialog && (
        <div className="virtual-bg-dialog">
          <VirtualBackgroundDialog />
        </div>
      )}
      {!isStudio &&
        meeting.state === ParticipantMeetingState.ENTERED_FROM_LOUNGE && (
          <MeetingParticipantControlsButton
            participantId={meeting.localUser?.participantId}
            meeting={meeting}
            role={meeting.localUser?.role}
          />
        )}

      <div className="highlight-box" />
      {showGrid && (
        <>
          <div className="grid-line line-vertical" />
          <div className="grid-line line-horizontal" />
        </>
      )}
      <ParticipantStatusIndicators
        invisible={isInvisible}
        audioMuted={audioMuted}
        videoMuted={videoMuted}
      />
      {meeting?.statsMode && (
        <ParticipantConnectionIndicators
          isLocal={true}
          meetingStats={meeting?.stats}
          participantId={meeting?.localUser?.participantId}
        />
      )}
      <style jsx>
        {`
          video {
            background: ${localVideoTrack ? 'transparent' : '#333'};
            object-fit: ${isSharingScreen ? 'contain' : 'cover'} !important;
            visibility: ${videoMuted ? 'hidden' : 'visible'};
            transform: ${isStudio || isSharingScreen || isUsingVirtualBackground
              ? 'none'
              : 'scaleX(-1)'};
          }

          .display-name {
            display: block;
            text-decoration: ${!displayName ? 'underline' : 'none'};
          }

          .enlarge .display-name-edit,
          :global(.meeting-lounge) .display-name {
            display: none;
          }

          .initialName {
            z-index: ${videoMuted ? 2 : 0};
          }
          .local-video-container.highlight .initialName {
            opacity: 1;
          }

          .local-video-container:hover .display-name {
            overflow: visible;
            text-shadow: 0 0 2px #000;
          }
          .virtual-bg-dialog {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          .virtual-background-button {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 13;
          }
        `}
      </style>
    </div>
  );
};

export default LocalVideo;

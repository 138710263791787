import React from 'react';
import { useDispatch } from 'react-redux';
import {
  ILoubiAirwaysWishlist,
  REMOTE_ACTION
} from '../../../../../interfaces/loubiairways';
import {
  actionRemoveFromWishlist,
  actionUpdateLoubiAirwaysSelectedModel
} from '../../../../../redux/customActions/loubiAirways';
import LoubiAirwaysPageContainer from '../LoubiAirwaysPageContainer';
import {
  getLoubiAirwaysProductById,
  getLoubiAirwaysProductImage
} from '../TwoDProduct/TwoDProductsList';
import TwoDProductImageContainer from '../TwoDProduct/TwoDProductImageContainer';
import LoubiAirwaysWishlist from './LoubiAirwaysWishlist';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import { assetBaseUrl } from '../../../../../config';
import { logEvent } from '../../../../../analytics';
import { DID_REMOVE_FROM_WISHLIST } from '../../../../../utils/constants';
const LoubiAirwaysWishlistDisplay = ({
  wishlistState,
  onShare,
  onBack,
  viewOnly
}: {
  wishlistState: ILoubiAirwaysWishlist;
  onShare?: () => void;
  onBack?: () => void;
  viewOnly?: boolean;
}) => {
  const dispatch = useDispatch();
  const selectedModel = useLoubiAirwaysState()?.selectedModel?.model;
  const products = wishlistState?.wishlistItems?.map((item) =>
    getLoubiAirwaysProductById(item.itemId)
  );

  const headerComponent = (
    <>
      <img
        src={`${assetBaseUrl}/loubiairways/pages/v2/favourites-cream.png`}
        alt=""
        className="extra-logo"
      />
      <style jsx>{`
        img {
          position: absolute;
          height: 96px;
          top: 50%;
          transform: translateY(-50%);
          right: 43px;
        }
        @media (max-width: 1200px) {
          img {
            height: 80px;
          }
        }
        @media (max-width: 768px) {
          img {
            height: 60px;
            right: 30px;
          }
        }
        @media (max-width: 550px) {
          img {
            height: 50px;
            right: 20px;
          }
        }
      `}</style>
    </>
  );

  const footerComponent = (
    <>
      {onShare && products && products?.length !== 0 && (
        <button className="btn btn-lg" onClick={onShare}>
          SHARE WITH YOUR SALES CREW
        </button>
      )}
      <style jsx>{`
        .btn {
          background: #f3f3d1;
          color: #cf152d;
          font-family: 'AntiqueOlive-Bold', Arial;
          border-radius: 15px;
          font-size: 1em;
          margin-left: 100px;
        }
        @media (max-width: 980px) {
          .btn {
            margin: auto auto 10px;
          }
        }
      `}</style>
    </>
  );

  const wishlistEmpty = (
    <div className="wishlist-empty">
      <h1>NO ITEMS YET</h1>
      <p>
        When you find an item you like, click <strong>ADD TO FAVOURITES</strong>{' '}
        (
        <img
          src="/asset/louboutin/wishlist_filled.svg"
          className="heart"
          alt="Add to Wishlist"
        />
        ) to see it here.
      </p>
      <img
        src="/asset/louboutin/back_to_plane_red.svg"
        onClick={onBack}
        className="back-button"
        alt=""
      />
      <style jsx>
        {`
          .wishlist-empty {
            color: #346168;
            max-width: 400px;
            margin: 50px auto;
          }
          h1 {
            font-size: 2.4em;
          }
          h1,
          strong {
            font-family: 'AntiqueOlive-Bold', Arial;
          }

          p {
            text-transform: uppercase;
            font-family: 'Antique-Olive', Arial;
          }
          .heart {
            display: inline-block;
            height: 20px;
          }
          .back-button {
            cursor: pointer;
            animation: pulsate-fwd 0.5s ease-in-out infinite both;
            width: 160px;
            height: auto;
          }
          @-webkit-keyframes pulsate-fwd {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
            50% {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
          @keyframes pulsate-fwd {
            0% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
            50% {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
  return (
    <div className="LoubiAirwaysWishlistDisplay">
      <LoubiAirwaysPageContainer
        className="loubiWishlistContainer"
        headerComponent={headerComponent}
        footerComponent={footerComponent}
      >
        {viewOnly && wishlistState?.name && (
          <div className="owner-info text-center">
            <h1>
              {wishlistState?.name}{' '}
              {wishlistState?.surname && <>({wishlistState?.surname})</>}
            </h1>
            <h2>FAVOURITES LIST</h2>
          </div>
        )}
        {products?.length ? (
          <>
            <div className="loubi-wishlist">
              {products.map((product) => (
                <div className="product-box" key={product.id}>
                  <div className="product-thumbnail">
                    <img
                      src={getLoubiAirwaysProductImage(product)}
                      onClick={() => {
                        dispatch(
                          actionUpdateLoubiAirwaysSelectedModel({
                            model: product,
                            state: REMOTE_ACTION.OPEN
                          })
                        );
                      }}
                    />
                  </div>
                  <h4 className="product-name">{product.modelName}</h4>
                  {!viewOnly && (
                    <span
                      className="remove"
                      onClick={() => {
                        dispatch(actionRemoveFromWishlist(product));
                        logEvent(
                          DID_REMOVE_FROM_WISHLIST,
                          DID_REMOVE_FROM_WISHLIST,
                          {
                            id: product.id,
                            modelCode: product.modelCode,
                            modelName: product.modelName
                          }
                        );
                      }}
                    >
                      REMOVE
                    </span>
                  )}
                </div>
              ))}
            </div>
            {onBack && (
              <img
                src="/asset/louboutin/back_to_plane_cream.png"
                onClick={onBack}
                className="back-button"
                alt=""
              />
            )}
          </>
        ) : (
          wishlistEmpty
        )}
      </LoubiAirwaysPageContainer>
      <TwoDProductImageContainer />
      {selectedModel && !viewOnly && (
        <LoubiAirwaysWishlist product={selectedModel} />
      )}
      <style jsx global>{`
        .loubiWishlistContainer .footer {
          text-align: left;
          min-height: 83px;
        }
        .loubiWishlistContainer .content {
          text-align: center;
        }

        .loubiWishlistContainer .sole {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: 1200px) {
          .loubiWishlistContainer .footer {
            padding: 20px;
          }
        }

        @media (max-width: 980px) {
          .loubiWishlistContainer .footer {
            text-align: center;
            padding-bottom: 20px;
          }
          .loubiWishlistContainer .sole {
            position: relative;
            display: block;
            left: auto;
            top: auto;
            transform: none;
            margin: 10px auto 0;
          }
        }
        @media (max-width: 550px) {
          .loubiWishlistContainer .logo {
            max-width: 158px;
          }
        }
        @media (max-width: 450px) {
          .loubiWishlistContainer .header {
            text-align: left;
          }
        }
      `}</style>
      <style jsx>{`
        .remove {
          border-bottom: 1px dashed #cf152d;
          color: #cf152d;
          font-family: 'Antique-Olive', Arial;
          cursor: pointer;
          font-size: 0.8em;
        }
        .loubi-wishlist {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          padding: 0 40px;
          justify-content: space-between;
        }
        .product-box {
          flex-basis: 27%;
          margin: 0 auto 50px;
          text-align: center;
        }
        .product-thumbnail {
          border-radius: 40px;
          background-color: #f3f3d1;
          box-shadow: 8px 8px #4b9fbb;
          text-align: center;
          margin-bottom: 20px;
        }
        .product-name {
          font-family: 'AntiqueOlive-Bold', Arial;
          top: 0;
          font-style: italic;
          color: #326167;
          letter-spacing: 0;
          line-height: 1.2;
          font-size: 1.2em;
        }

        .owner-info {
          font-family: 'AntiqueOlive-Bold', Arial;
          color: #cf152d;
          border-bottom: 2px dashed #cf152d;
          padding: 20px 0 30px;
          margin: 0 auto 40px;
          display: inline-block;
          text-transform: uppercase;
        }
        .owner-info h2 {
          font-size: 1.2em;
        }

        .product-thumbnail img {
          width: 100%;
          cursor: pointer;
        }
        .btn-lg {
          background: #cf152d;
          color: #fff;
          border-radius: 15px;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-style: italic;
        }

        .back-button {
          position: absolute;
          height: 70px;
          z-index: 100;
          cursor: pointer;
          bottom: 27px;
          left: 28px;
          animation: pulsate-fwd 0.5s ease-in-out infinite both;
        }

        @-webkit-keyframes pulsate-fwd {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
        @keyframes pulsate-fwd {
          0% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          50% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }

        @media (max-width: 1200px) {
          .product-thumbnail {
            border-radius: 3.5vw;
          }
        }

        @media (max-width: 767px) {
          .back-button {
            bottom: 15px;
            left: 15px;
            right: auto;
          }
          .loubi-wishlist {
            padding: 0;
          }
          .product-box {
            flex-basis: 40%;
            margin-bottom: 20px;
          }
          .product-name {
            font-size: 0.8em;
          }
        }
        @media (max-width: 450px) {
          .btn-lg {
            font-size: 0.8em;
          }
        }
        :global(.letter-box-container) {
          position: fixed;
          bottom: 30px;
          width: 100%;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default LoubiAirwaysWishlistDisplay;

import { logEvent } from '../../analytics';
import { DID_SELECT_FILE } from '../../utils/constants';

const FileList = ({
  fileNames,
  onClickItem,
  appendedElements
}: {
  fileNames: string[];
  onClickItem?: (fileName: string) => void;
  appendedElements?: React.ReactNode[];
}) => {
  return (
    <div className="fileList">
      <div className="list-group">
        {fileNames.map((file, i) => (
          <button
            key={file}
            type="button"
            className={`list-group-item list-group-item list-group-item-action`}
            onClick={() => {
              if (onClickItem) {
                onClickItem(file);
                logEvent(DID_SELECT_FILE, DID_SELECT_FILE, {
                  file
                });
              }
            }}
          >
            {file}
            {appendedElements && appendedElements[i]}
          </button>
        ))}
      </div>
      <style jsx>{`
        .list-group-item {
          font-size: 0.8em;
          user-select: none;
        }
        .active {
          background-color: #efefef;
          border-color: #efefef;
          color: #000;
          font-weight: normal;
        }
        .fileList{
          max-height:300px;
          overflow:auto;
        }
      `}</style>
    </div>
  );
};

export default FileList;

import React from 'react';
import { useTranslation } from '../../i18n';
import { IProduct } from '../../interfaces';
import { PageView } from '../../utils/pageView';
import { parsePlainTextToRichText } from '../../utils/string';
import EyeballTimeLogger from '../EyeballTimeLogger';
import Header from '../Header';
import { PotmContent } from './mockData';
import Placeholder from '../Placeholder';
import { getProductDisplayName } from '../../utils/product';
import { currentLanguage } from '../../utils/language';
import Link from '../Common/Link';
export interface ISinglePickedProduct {
  product: IProduct;
  content: PotmContent;
}

function SinglePickedProduct({ product, content }: ISinglePickedProduct) {
  const pickedAppearance = content.appearance;
  const [isLoading, setIsLoading] = React.useState(false);
  const { imageUrls } = product;
  const { t, i18n } = useTranslation();
  const language = currentLanguage(i18n);
  const title = content.title[language] || content.title['en'] || '';
  const description =
    content.description[language] || content.description['en'] || '';
  const productDisplayName = getProductDisplayName(product, language);
  return (
    <>
      <Header
        title={t('pick_of_the_month').toUpperCase()}
        shareTitle={`${t(
          'pick_of_the_month'
        ).toUpperCase()}: ${productDisplayName}`}
        shareImage={product.imageUrls.length > 0 && product.imageUrls[0]}
        type={PageView.PICK_OF_THE_MONTH}
        id={product.id}
      />
      <EyeballTimeLogger
        tracker={{ view: PageView.PICK_OF_THE_MONTH, id: product.id }}
      >
        {isLoading && <Placeholder />}
        <div className="section potm-section">
          <div className="potm-container row align-items-center">
            <div className="potm-content col-12 col-md-5 col-lg-4">
              <div className="content">
                <div className="potm-title">
                  {parsePlainTextToRichText(title)}
                </div>
                <div className="potm-desc">
                  {parsePlainTextToRichText(description)}
                </div>
              </div>
            </div>
            <div className="potm-image col-12 col-md-5 col-lg-4 text-center">
              <Link
                href="/products/[id]"
                as={`/products/${product.id}`}
                onClick={() => setIsLoading(true)}
              >
                <img
                  className="img-fluid"
                  src={imageUrls?.[0]}
                  alt={productDisplayName}
                />
              </Link>
            </div>
            <div className="dummy col-0 col-md-2 col-lg-4"></div>
          </div>
        </div>
      </EyeballTimeLogger>
      <style jsx>{`
        .potm-container {
          background-image: url(${pickedAppearance.bgImage});
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          padding: 20px 60px;
        }
        .content {
          color: ${pickedAppearance.textColor};
          width: 100%;
        }
        .potm-content {
          display: flex;
          align-items: flex-end;
          height: 100%;
        }
        .potm-title {
          padding: 20px 0;
          display: inline-block;
          border-bottom: 2px white solid;
        }

        .potm-container ::global(p) {
          margin-bottom: 0;
        }
        .potm-title ::global(span:first-child) {
          font-size: 1em;
          display: block;
          margin-bottom: 10px;
        }
        .potm-title ::global(strong) {
          font-size: 1.3em;
          text-transform: uppercase;
          margin-top: 5px;
        }

        .potm-desc {
          margin-top: 20px;
          font-size: 1.2em;
        }
        .img-fluid {
          margin: auto;
          width: 100%;
          height: auto;
          max-width: 530px;
        }

        @media (max-width: 420px) {
          .img-fluid {
            max-width: 340px;
          }
        }
        @media (max-width: 1024px) {
          .potm-content {
            font-size: 13px;
          }
        }
        @media (max-width: 768px) {
          .potm-container {
            background-image: url(${pickedAppearance.bgImageMobile});
            padding: 30px;
            padding-top: 60%;
          }
          .potm-image {
            order: 1;
            max-width: 100%;
            flex: unset;
          }
          .img-fluid {
            max-width: 500px;
          }
          .potm-content {
            order: 2;
            font-size: 12px;
            max-width: 100%;
            flex: unset;
          }
          .dummy {
            display: none;
          }
          .content {
            text-align: center;
          }
        }
      `}</style>
    </>
  );
}

export default SinglePickedProduct;

import { first, last, throttle } from 'lodash';
import React from 'react';
import ReactPlayer from 'react-player';
import * as THREE from 'three';
import { logEvent } from '../../../analytics';
import { LFAssetBaseUrl } from '../../../config/loubiFuture';
import {
  ILoubiFutureAnimationState,
  ILoubiFutureCanvasState,
  LoubiFutureScene,
  ObjectValue,
  REMOTE_ACTION,
  ROOM_STATUS,
  touchType
} from '../../../interfaces/loubifuture';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import {
  DID_CLOSE_VIEW_PRODUCT,
  DID_SHOW_ANIMATION,
  DID_START_VB_SCENE,
  DID_UPDATE_ANIMATION_STATE
} from '../../../utils/constants';
import ColorChooser from '../../VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/ColorChooser';
import BackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Buttons/BackToAnimationButton';
import Arpoador from './Arpoador';
import {
  getViewingRoomColorByIndex,
  modelWorkshopEnvironmentImagePathLight,
  modelWorkshopEnvironmentImagePathSoft,
  mp3Zoom,
  urlVideo,
  viewingRoomEnvironmentImageForTorus,
  productBackgroundImage
} from './assets';
import ViewingRoom from './ViewingRoom';
import { LF_FADE_IN_SPEED } from '../../VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/LoubiFutureFullScreenVideo';
import CSRComponentWrapper from '../../CSRComponentWrapper';

let loubiFuture = undefined;

const isMobileOrTablet = isUserOnMobile();

let GLTFLoader,
  RGBELoader,
  TWEEN,
  TransformControls,
  OrbitControls,
  DeviceOrientationControls;

interface ILoubiFutureBaseProps {
  viewOnly?: boolean;
  canvasState?: ILoubiFutureCanvasState;
  updateCanvasState: (state: ILoubiFutureCanvasState) => void;
  replayMusicVideo: () => void;
  onSelectProduct: (productId?: string) => void;
  isGyroscopeOn?: boolean;
  selectedProductId?: string;
}
const canvasBasedVideoUrls = [urlVideo.arpoadorA2, urlVideo.viewingroom];

class LoubiFutureBase extends React.Component<ILoubiFutureBaseProps> {
  state = {
    isShowButton: false,
    videoUrl: first(canvasBasedVideoUrls)
  };

  isTweening: boolean;
  isPointerDown: boolean;
  delta: number;
  ZoomIn: number;
  ZoomOut: number;
  maxScale: number;
  minScale: number;
  incScale: number;
  initScale: number;
  initDistance: number;
  resizeScreen: number;
  mouse: THREE.Vector2;
  touchPointer: THREE.Vector2;
  mouseDown: THREE.Vector2;
  mouseDownPos: THREE.Vector2;
  hasLoggedViewingExplodingShoe: boolean;

  scene: THREE.Scene;
  clock: THREE.Clock;
  raycaster: THREE.Raycaster;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  mArpoador: Arpoador;
  mViewingRoom: ViewingRoom;
  orbitControls = undefined;
  objectControls = undefined;
  deviceOrientationControls = undefined;
  focusedModel = undefined;
  dom360VideoObj = undefined;
  dom360VideoMatarial = undefined;
  timeout = undefined;
  timeoutPlayer = undefined;
  refVideo: React.RefObject<ReactPlayer> = undefined;
  videoTexture = undefined;
  ambientLight = undefined;
  directionalLight = undefined;
  envMapLight = undefined;
  envMapSoft = undefined;
  transparent_Plane = undefined;
  transparent_Mat = undefined;
  isReady = false;
  lastFlag = undefined;
  lastColor = undefined;
  mp3Zoom: THREE.Audio;
  counter: number;
  lookAtPosition: THREE.Vector3;
  constructor(props) {
    super(props);
    loubiFuture = this;
    this.isTweening = false;
    const SCL = 1;
    this.maxScale = 1.5 * SCL;
    this.minScale = 0.8 * SCL;
    this.incScale = 0.1 * SCL;
    this.initDistance = 0;
    this.resizeScreen = 0;
    this.mouse = new THREE.Vector2();
    this.touchPointer = new THREE.Vector2();
    this.mouseDown = new THREE.Vector2();
    this.mouseDownPos = new THREE.Vector2();
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    this.clock = new THREE.Clock();
    this.raycaster = new THREE.Raycaster();
    this.refVideo = React.createRef();
    this.lookAtPosition = new THREE.Vector3();
    this.hasLoggedViewingExplodingShoe = false;
  }
  loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    TransformControls = (await import('../common/ObjectHandler'))
      .TransformControls;
    DeviceOrientationControls = (
      await import('../common/DeviceOrientationControls')
    ).DeviceOrientationControls;
    OrbitControls = (await import('three/examples/jsm/controls/OrbitControls'))
      .OrbitControls;
  };
  setOrbitControl() {
    if (this.orbitControls) {
      const direction = new THREE.Vector3();
      this.camera?.getWorldDirection(direction);
      this.camera?.position?.set(0, 0, 0.1);
      this.camera?.getWorldPosition(this.orbitControls.target);
      this.orbitControls?.target?.addScaledVector(direction, 0.1);
      this.orbitControls?.update();
    }
  }
  componentDidMount() {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    this.camera = new THREE.PerspectiveCamera(
      56,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: false
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.camera.position.set(0, 0, 0.1);
    if (isMobileOrTablet) {
      document.addEventListener('gesturestart', this.zoomDisable);
      document.addEventListener('gesturechange', this.zoomDisable);
      document.addEventListener('gestureend', this.zoomDisable);
    }
    this.restoreContext();
    this.loadThreeJsModulesAsync().then(() => {
      this.init();
      this.isReady = true;
    });
  }
  restoreContext() {
    const canvas = this.renderer.domElement;
    canvas.addEventListener(
      'webglcontextlost',
      function (event) {
        event.preventDefault();
        setTimeout(function () {
          try {
            loubiFuture?.renderer?.forceContextRestore();
          } catch (error) {
            console.error('webglError~~', error);
          }
        }, 1);
      },
      false
    );
  }
  componentWillUnmount() {
    this.cleanUp();
  }

  jumpToArpoador() {
    this.setState(
      {
        videoUrl: urlVideo.arpoadorA2
      },
      () => {
        this.updateVideoTexture();
        this.mViewingRoom?.clearObjects()?.then(() => {
          this.mViewingRoom.cleanUp();
          if (this.mArpoador?.productBox?.length === 0) {
            this.mArpoador.initObject();
          }
        });
      }
    );
  }

  jumpToViewingRoom() {
    this.setState(
      {
        videoUrl: urlVideo.viewingroom
      },
      () => {
        this.updateVideoTexture();
        this.mArpoador?.clearObjects()?.then(() => {
          this.mArpoador?.cleanUp();
          if (this.mViewingRoom?.productBox?.length === 0) {
            this.mViewingRoom.initObject();
          }
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.canvasState?.currentScene !==
        this.props.canvasState?.currentScene &&
      this.props.canvasState?.currentScene
    ) {
      this.setAppScene(this.props.canvasState.currentScene);
    }
    if (
      prevProps.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
      this.props.canvasState?.currentScene ===
        LoubiFutureScene.ArpoadorViewAdjustment
    ) {
      this.mArpoador.prepareViewForTransitionToViewingRoom();
    }
    if (
      prevProps.canvasState?.currentScene === LoubiFutureScene.ViewingRoom &&
      this.props.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2
    ) {
      this.jumpToArpoador();
    }
    if (
      prevProps.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
      this.props.canvasState?.currentScene === LoubiFutureScene.ViewingRoom
    ) {
      this.jumpToViewingRoom();
    }
    if (
      this.props?.canvasState?.currentScene &&
      this.props?.viewOnly === true
    ) {
      this.remoteObjectUpdate(this.props?.canvasState);
    }
    if (!prevProps.isGyroscopeOn && this.props.isGyroscopeOn) {
      this.startGyroscope();
    } else if (prevProps.isGyroscopeOn && !this.props.isGyroscopeOn) {
      this.stopGyroscope();
    }
  }
  startGyroscope() {
    this.deviceOrientationControls = new DeviceOrientationControls(this.camera);
    this.orbitControls.enabled = false;
  }
  stopGyroscope() {
    this.deviceOrientationControls = undefined;
    this.orbitControls.enabled = true;
  }
  isArpoador() {
    return this.props.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2;
  }

  getVideoElement = () =>
    document.querySelector('.canvas-video video') as HTMLVideoElement; // TODO: Use ref to get the video element
  setVideoTexture() {
    const video = this.getVideoElement();
    this.videoTexture = new THREE.VideoTexture(video);
    this.videoTexture.encoding = THREE.sRGBEncoding;

    this.videoTexture.center = new THREE.Vector2(0.5, 0.5);
    this.videoTexture.rotation = Math.PI;
    this.videoTexture.flipY = false;

    if (this.dom360VideoMatarial) {
      this.dom360VideoMatarial.map = this.videoTexture;
    }
  }
  updateObjectControlForModel(model) {
    if (
      model === undefined ||
      this.props.viewOnly ||
      this.camera === undefined ||
      this.renderer === undefined
    )
      return;
    this.objectControls = new TransformControls(
      this.camera,
      this.renderer.domElement
    );
    this.objectControls.attach(model);
    this.objectControls.setSize(20);
    this.objectControls.space = 'local';
    this.objectControls.setMode('rotate');
    this.scene.add(this.objectControls);
    this.objectControls.update();
  }
  RemoveObjectControl() {
    if (this.objectControls) {
      this.objectControls.detach();
      this.objectControls.dispose();
      this.scene.remove(this.objectControls);
    }
    this.objectControls = undefined;
  }
  loadVideoDom() {
    if (this.dom360VideoObj) {
      return;
    }
    const geometry = new THREE.SphereGeometry(200, 60, 40);
    this.dom360VideoMatarial = new THREE.MeshBasicMaterial({
      side: THREE.BackSide
    });
    this.dom360VideoObj = new THREE.Mesh(geometry, this.dom360VideoMatarial);
    this.dom360VideoObj.rotation.set(0, Math.PI * 0.466, 0);
    this.dom360VideoObj.rotation.set(0, Math.PI * 0.54, -Math.PI * 0.01);
    this.updateVideoTexture();
    this.scene.add(this.dom360VideoObj);
  }
  updateVideoTexture() {
    if (this.videoTexture) {
      this.cleanTexture(this.videoTexture);
    }
    const video = this.getVideoElement();
    this.videoTexture = new THREE.VideoTexture(video);
    this.videoTexture.encoding = THREE.sRGBEncoding;
    this.videoTexture.center = new THREE.Vector2(0.5, 0.5);
    this.videoTexture.rotation = Math.PI;
    this.videoTexture.flipY = false;

    if (this.dom360VideoMatarial) {
      this.dom360VideoMatarial.map = this.videoTexture;
    }
  }
  addLights() {
    const color = 0xeeeeee;
    this.ambientLight = new THREE.AmbientLight(color, 0.21);
    this.ambientLight.name = 'ambient_light';
    this.scene.add(this.ambientLight);
    this.directionalLight = new THREE.DirectionalLight(color, 0.21);
    this.directionalLight.position.set(1.5, 1.2, -0.5);
    this.directionalLight.name = 'main_light';
    this.camera.add(this.directionalLight);
  }
  setTransparent(_opacity) {
    const opacity = _opacity;
    new TWEEN.Tween(this.transparent_Mat)
      .to(
        {
          opacity: opacity
        },
        400
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        if (opacity < 0.2) this.transparent_Plane.visible = false;
      })
      .start();
  }
  setLightIntensity(ambientInc, directionalInc, envFile, distance) {
    const dist = distance;
    const cwd = new THREE.Vector3();
    this.camera.getWorldDirection(cwd);

    cwd.multiplyScalar(dist);
    cwd.add(this.camera.position);
    this.transparent_Plane.position.set(cwd.x, cwd.y, cwd.z);
    this.transparent_Plane.visible = true;
    this.transparent_Plane.setRotationFromQuaternion(this.camera.quaternion);

    this.ambientLight.intensity = ambientInc;
    this.directionalLight.intensity = directionalInc;

    if (envFile === modelWorkshopEnvironmentImagePathSoft) {
      this.scene.environment = this.envMapSoft;
    } else {
      this.scene.environment = this.envMapLight;
    }
  }
  getGltfobj(loadingManager: THREE.LoadingManager) {
    return new GLTFLoader(loadingManager);
  }
  getGltfObjForZoom() {
    return new GLTFLoader();
  }
  loadEnvForViewingRoom(material: THREE.MeshStandardMaterial) {
    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    loader.load(
      viewingRoomEnvironmentImageForTorus,
      (texture) => {
        let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        material.envMap = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator = undefined;
        material.needsUpdate = true;
      },
      undefined,
      undefined
    );
  }
  loadEnvironment = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(productBackgroundImage);
    this.transparent_Mat = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.01,
      color: 0x1e043a,
      map: texture
    });
    this.transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(84, 40),
      this.transparent_Mat
    );
    this.transparent_Plane.rotation.set(-Math.PI * 0.1246, 0, 0);
    this.transparent_Plane.position.set(0, 1.12, 2);
    this.scene.add(this.transparent_Plane);
    this.transparent_Plane.visible = false;

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    loader.load(
      modelWorkshopEnvironmentImagePathLight,
      (texture) => {
        if (!this.renderer || !this.scene) return;
        let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        this.envMapLight = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator = undefined;
        this.scene.environment = this.envMapLight;
      },
      undefined,
      undefined
    );
    loader.load(
      modelWorkshopEnvironmentImagePathSoft,
      (texture) => {
        if (!this.renderer || !this.scene) return;
        let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        this.envMapSoft = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator = undefined;
      },
      undefined,
      undefined
    );
  };
  detectFirstTouchedObject(objects) {
    if (this.isClicked(touchType.touchUp)) {
      const objs = objects.filter((model) => model);
      const intersects = this.raycaster?.intersectObjects(objs);
      if (intersects?.length) {
        return intersects[0];
      }
    }
  }
  detectObjectForHover(objects) {
    const objs = objects.filter((model) => model);
    const intersects = this.raycaster?.intersectObjects(objs);
    if (intersects?.length) {
      return intersects[0];
    }
  }
  setTouchValues = (e, type) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        this.mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          this.touchPointer.x =
            (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          this.touchPointer.y =
            -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            this.initDistance = this.touchPointer.distanceTo(this.mouse);
            this.onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = this.touchPointer.distanceTo(this.mouse);
            this.onPinch({
              type: 'pinchmove',
              scale: currentDistance / this.initDistance
            });
          }
        }
      }
    } else {
      const elem = this.renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      this.mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      this.mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      this.mouseDownPos.x = this.mouse.x;
      this.mouseDownPos.y = this.mouse.y;
      this.isPointerDown = true;
    }
    if (type === touchType.touchUp) {
      this.isPointerDown = false;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
  };
  isClicked(type) {
    if (type === touchType.touchUp) {
      if (
        Math.abs(this.mouseDownPos.x - this.mouse.x) < 0.01 &&
        Math.abs(this.mouseDownPos.y - this.mouse.y) < 0.01
      ) {
        return true;
      }
    }
    return false;
  }

  touchEvent(e, type) {
    if (this.isTweening || !this.renderer || !this.scene) return;
    this.setTouchValues(e, type);
    switch (this.props.canvasState?.currentScene) {
      case LoubiFutureScene.ArpoadorA2:
        if (this.mArpoador) this.mArpoador.touchEvent(e, type);
        break;
      case LoubiFutureScene.ViewingRoom:
        if (this.mViewingRoom) this.mViewingRoom.touchEvent(e, type);
        break;
      default:
        break;
    }
    if (this.isPointerDown) {
      this.updateRemoteOnChange(REMOTE_ACTION.PRODUCT, undefined);
    }
  }
  setCameraPositionForTransitionToViewingRoomVideo(onComplete?: () => void) {
    this.isTweening = true;
    const dist = 20;
    const cwd = new THREE.Vector3();
    this.camera.getWorldDirection(cwd);
    cwd.multiplyScalar(dist);
    cwd.add(this.camera.position);
    this.lookAtPosition = cwd;
    new TWEEN.Tween(this.lookAtPosition)
      .to(
        {
          x: -19.19251568008885,
          y: 0.019280555076332567,
          z: 5.258989458987308
        },
        500
      )
      .onUpdate(() => {
        if (this.lookAtPosition && this.camera) {
          this.camera?.lookAt(this.lookAtPosition);
        }
      })
      .onComplete(() => {
        this.isTweening = false;
        if (onComplete) {
          onComplete();
        }
      })
      .start();
  }

  setAppScene(sceneID) {
    if (sceneID) {
      logEvent(DID_START_VB_SCENE, DID_START_VB_SCENE, {
        sceneId: sceneID
      });
    }
    switch (sceneID) {
      case LoubiFutureScene.ArpoadorA2:
        this.camera.fov = 56;
        if (this.mArpoador) {
          if (this.mArpoador.productBox?.length === 0) {
            this.mArpoador.init();
          }
          this.mArpoador.setScreen();
          this.dom360VideoObj.rotation.set(0, Math.PI * 0.54, -Math.PI * 0.01);
          this.camera.position.set(0.4, 0, 0.1);
          if (this.mArpoador.productBox?.length) {
            this.camera.lookAt(
              new THREE.Vector3(
                this.mArpoador.productBox[2].position.x,
                this.mArpoador.productBox[2].position.y,
                this.mArpoador.productBox[2].position.z
              )
            );
          }
          if (this.orbitControls) {
            this.setOrbitControlBasedOnGyroscope();
            if (this.orbitControls.enabled) this.orbitControls.reset();
          }
        }
        break;
      case LoubiFutureScene.ArpoadorToViewingroom:
        setTimeout(() => {
          this.setState(
            {
              videoUrl: urlVideo.viewingroom
            },
            () => {
              this.updateVideoTexture();
            }
          );
          this.mArpoador.clearObjects();
          setTimeout(() => {
            if (this.mViewingRoom?.productBox?.length === 0) {
              this.mViewingRoom.init();
            }
            if (this.mArpoador?.productObj) {
              this.mArpoador.cleanUp();
            }
          }, 10);
        }, LF_FADE_IN_SPEED);

        break;
      case LoubiFutureScene.ViewingRoom:
        this.camera.fov = 80;
        if (this.mViewingRoom) {
          if (this.mViewingRoom.productBox?.length === 0) {
            this.mViewingRoom.init();
          }
          if (this.scene?.environment && this.envMapLight)
            this.scene.environment = this.envMapLight;
          this.mViewingRoom.setScreen();
          this.dom360VideoObj.rotation.set(0, Math.PI * 0.4, 0);
          this.camera.position.set(0.0, 0.1, 0.1);
          this.camera.lookAt(new THREE.Vector3(0, 0.1, -50));
          if (this.orbitControls) {
            this.setOrbitControlBasedOnGyroscope();
            if (this.orbitControls.enabled) this.orbitControls.reset();
          }
        }
        break;
      case LoubiFutureScene.ViewingroomToArpoador:
        this.mViewingRoom.cleanUp();
        if (this.mArpoador?.productBox?.length === 0) {
          this.mArpoador.initObject();
        }
        this.setState(
          {
            videoUrl: urlVideo.arpoadorA1
          },
          () => {
            this.updateVideoTexture();
          }
        );
        break;
      default:
        break;
    }
  }

  onwheelEvent(event) {
    if (this.isTweening && this.props?.viewOnly && !this.focusedModel) return;

    if (loubiFuture.focusedModel) {
      let modelScale = loubiFuture.focusedModel.scale.x;
      if (
        loubiFuture.props.canvasState?.currentScene ===
        LoubiFutureScene.ArpoadorA2
      ) {
        if (event.deltaY > 0) {
          if (modelScale < loubiFuture.maxScale)
            modelScale += loubiFuture.incScale;
        } else {
          if (modelScale > loubiFuture.minScale)
            modelScale -= loubiFuture.incScale;
        }
      } else {
        if (event.deltaY > 0) {
          if (modelScale < loubiFuture.maxScale * 0.5)
            modelScale += loubiFuture.incScale;
        } else {
          if (modelScale > loubiFuture.minScale * 0.5)
            modelScale -= loubiFuture.incScale;
        }
      }

      loubiFuture.focusedModel.scale.set(modelScale, modelScale, modelScale);
      loubiFuture.updateRemoteOnChange(REMOTE_ACTION.PRODUCT, {
        itemId: loubiFuture.focusedModel.itemId
      });
    }
  }
  onPinch = (ev) => {
    if (this.isTweening && this.props?.viewOnly && !this.focusedModel) return;

    if (
      this.focusedModel &&
      (ev.type == 'pinchstart' || ev.type == 'pinchmove')
    ) {
      if (ev.type == 'pinchstart') {
        this.initScale = this.focusedModel.scale.x || 1;
      }
      this.focusedModel.scale.set(
        this.initScale * ev.scale,
        this.initScale * ev.scale,
        this.initScale * ev.scale
      );
      if (
        loubiFuture?.props?.canvasState?.currentScene ===
        LoubiFutureScene.ArpoadorA2
      ) {
        if (this.focusedModel.scale.x > this.maxScale) {
          this.focusedModel.scale.set(
            this.maxScale,
            this.maxScale,
            this.maxScale
          );
        }
        if (this.focusedModel.scale.x < this.minScale) {
          this.focusedModel.scale.set(
            this.minScale,
            this.minScale,
            this.minScale
          );
        }
      } else {
        if (this.focusedModel.scale.x > this.maxScale * 0.5) {
          this.focusedModel.scale.set(
            this.maxScale,
            this.maxScale,
            this.maxScale
          );
        }
        if (this.focusedModel.scale.x < this.minScale * 0.5) {
          this.focusedModel.scale.set(
            this.minScale,
            this.minScale,
            this.minScale
          );
        }
      }
    }
    loubiFuture.updateRemoteOnChange(REMOTE_ACTION.PRODUCT, {
      itemId: loubiFuture.focusedModel?.itemId
    });
  };
  eventDown = (e) => {
    this.touchEvent(e, touchType.touchDown);
  };
  eventMove = (e) => {
    this.touchEvent(e, touchType.touchMove);
  };
  eventUp = (e) => {
    this.touchEvent(e, touchType.touchUp);
  };
  zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };

  addEventListeners = () => {
    this.orbitControls = new OrbitControls(
      this.camera,
      this.renderer.domElement
    );
    this.orbitControls.rotateSpeed = isMobileOrTablet ? -0.3 : -0.1;
    this.orbitControls.enableZoom = false && !this.props.viewOnly;
    this.orbitControls.enabled = !this.props.isGyroscopeOn;
    document
      .getElementById('c')
      .addEventListener('pointerdown', this.eventDown);
    document.getElementById('c').addEventListener('touchstart', this.eventDown);
    document
      .getElementById('c')
      .addEventListener('pointermove', this.eventMove);
    document.getElementById('c').addEventListener('touchmove', this.eventMove);
    document.getElementById('c').addEventListener('pointerup', this.eventUp);
    document.getElementById('c').addEventListener('touchend', this.eventUp);
    document.getElementById('c').addEventListener('wheel', this.onwheelEvent);
  };
  cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };
  cleanUp = () => {
    if (this.scene != undefined) {
      this.scene.traverse((object) => {
        if (!object['isMesh']) return;
        if (object['geometry']) object['geometry'].dispose();
        if (object['material']?.isMaterial) {
          this.cleanMaterial(object['material']);
        } else {
          for (const material of object['material'])
            this.cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      this.scene.children.forEach((model) => {
        this.scene.remove(model);
      });
    }
    if (this.renderer != undefined) {
      this.renderer.dispose();
      this.renderer && this.renderer.renderLists.dispose();
    }
    if (this.objectControls != undefined) {
      this.RemoveObjectControl();
    }

    document
      .getElementById('c')
      .removeEventListener('touchstart', this.eventDown);
    document
      .getElementById('c')
      .removeEventListener('touchmove', this.eventMove);
    document.getElementById('c').removeEventListener('touchend', this.eventUp);
    document
      .getElementById('c')
      .removeEventListener('mousedown', this.eventDown);
    document
      .getElementById('c')
      .removeEventListener('mousemove', this.eventMove);
    document.getElementById('c').removeEventListener('mouseup', this.eventUp);
    document
      .getElementById('c')
      .removeEventListener('wheel', this.onwheelEvent);
    window.removeEventListener('resize', this.onWindowResize);

    if (isMobileOrTablet) {
      document.removeEventListener('gesturestart', this.zoomDisable);
      document.removeEventListener('gesturechange', this.zoomDisable);
      document.removeEventListener('gestureend', this.zoomDisable);
    }

    this.objectControls = undefined;
    this.scene = undefined;
    this.renderer = undefined;
    this.camera = undefined;
  };

  updateRemoteOnChange(remoteFlag: REMOTE_ACTION, extra) {
    if (this.props?.viewOnly) return;

    let itemId = extra?.itemId;
    const colorId = extra?.colorId;
    const cPos = this.camera.position;
    const cRot = this.camera.rotation;
    let focusValues: ObjectValue = undefined;
    let cameraValues: ObjectValue = undefined;
    if (
      remoteFlag !== REMOTE_ACTION.FOCUS &&
      remoteFlag !== REMOTE_ACTION.UN_FOCUS
    ) {
      cameraValues = {
        position: [cPos.x, cPos.y, cPos.z],
        rotation: [cRot.x, cRot.y, cRot.z]
      };
    }

    if (
      this.focusedModel &&
      remoteFlag !== REMOTE_ACTION.FOCUS &&
      remoteFlag !== REMOTE_ACTION.UN_FOCUS
    ) {
      focusValues = {
        position: [
          this.focusedModel.position.x,
          this.focusedModel.position.y,
          this.focusedModel.position.z
        ],
        rotation: [
          this.focusedModel.rotation.x,
          this.focusedModel.rotation.y,
          this.focusedModel.rotation.z
        ],
        scale: [
          this.focusedModel.scale.x,
          this.focusedModel.scale.y,
          this.focusedModel.scale.z
        ]
      };
      itemId = this.focusedModel.itemId;
    }
    this.props.updateCanvasState({
      currentScene: this.props.canvasState?.currentScene,
      camera: cameraValues,
      focusItem: focusValues,
      focusItemID: itemId,
      focusColorID: colorId,
      flag: remoteFlag,
      newScene: extra?.newScene
    });
    if (itemId) {
      this.logEventThrottled({
        flag: remoteFlag,
        currentScene: this.props.canvasState?.currentScene,
        ...focusValues,
        focusItemID: itemId
      });
    }
  }

  logEventThrottled = throttle(
    (state: ILoubiFutureAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        state
      });
    },
    1000,
    { trailing: true }
  );

  remoteObjectUpdate(remoteState: ILoubiFutureCanvasState) {
    if (
      !this.isReady ||
      (this.mArpoador.roomStatus !== ROOM_STATUS.READY &&
        remoteState?.currentScene === LoubiFutureScene.ArpoadorA2) ||
      (this.mViewingRoom.roomStatus !== ROOM_STATUS.READY &&
        remoteState?.currentScene === LoubiFutureScene.ViewingRoom)
    ) {
      return;
    }
    if (remoteState?.flag) {
      if (remoteState?.camera?.position) {
        this.camera.position.set(
          remoteState.camera.position[0],
          remoteState.camera.position[1],
          remoteState.camera.position[2]
        );
        this.camera.rotation.set(
          remoteState.camera.rotation[0],
          remoteState.camera.rotation[1],
          remoteState.camera.rotation[2]
        );
      }
      if (remoteState?.focusItem?.position) {
        if (this.focusedModel === null) {
          if (remoteState?.currentScene === LoubiFutureScene.ArpoadorA2) {
            this.mArpoador.setFocusObject(remoteState?.focusItemID);
          }
          if (remoteState?.currentScene === LoubiFutureScene.ViewingRoom) {
            this.mViewingRoom.setFocusObject(remoteState?.focusItemID);
          }
        }
        if (this.focusedModel) {
          this.focusedModel.position.set(
            remoteState.focusItem.position[0],
            remoteState.focusItem.position[1],
            remoteState.focusItem.position[2]
          );
          this.focusedModel.rotation.set(
            remoteState.focusItem.rotation[0],
            remoteState.focusItem.rotation[1],
            remoteState.focusItem.rotation[2]
          );
          this.focusedModel.scale.set(
            remoteState.focusItem.scale[0],
            remoteState.focusItem.scale[1],
            remoteState.focusItem.scale[2]
          );
        }
      }
      if (
        remoteState?.flag === REMOTE_ACTION.FOCUS &&
        this.lastFlag !== REMOTE_ACTION.FOCUS
      ) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
          remoteState?.focusItemID !== undefined
        ) {
          this.mArpoador?.focusProduct(remoteState?.focusItemID);
        }
        if (
          remoteState?.currentScene === LoubiFutureScene.ViewingRoom &&
          remoteState?.focusItemID !== undefined
        ) {
          this.mViewingRoom?.focusProduct(remoteState?.focusItemID);
        }
      }
      if (
        remoteState?.flag === REMOTE_ACTION.UN_FOCUS &&
        this.lastFlag !== REMOTE_ACTION.UN_FOCUS
      ) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ArpoadorA2 &&
          remoteState?.focusItemID !== undefined
        ) {
          this.mArpoador?.unfocusProduct(remoteState?.focusItemID);
        }
        if (
          remoteState?.currentScene === LoubiFutureScene.ViewingRoom &&
          remoteState?.focusItemID !== undefined
        ) {
          this.mViewingRoom?.unfocusProduct(remoteState?.focusItemID);
        }
      }
      if (
        remoteState?.flag === REMOTE_ACTION.COLOR_CHANGE &&
        (this.lastFlag !== REMOTE_ACTION.COLOR_CHANGE ||
          remoteState?.focusColorID !== this.lastColor)
      ) {
        if (
          remoteState?.currentScene === LoubiFutureScene.ViewingRoom &&
          remoteState?.focusColorID !== undefined
        ) {
          if (!this.focusedModel) {
            this.mArpoador.setFocusObject(remoteState?.focusItemID);
          }
          this.mViewingRoom.changeColorZoomIn(
            this.focusedModel,
            remoteState?.focusColorID
          );
        }
      }
      this.lastFlag = remoteState.flag;
      this.lastColor = remoteState.focusColorID;
    }
  }

  checkIfExplodingShoeInView() {
    if (this.hasLoggedViewingExplodingShoe) {
      return;
    }
    const cwd = new THREE.Vector3();
    const dir = this.camera?.getWorldDirection(cwd);
    if (dir?.z > 0.8) {
      logEvent(DID_SHOW_ANIMATION, DID_SHOW_ANIMATION, {
        animation: 'ExplodingShoe'
      });
      this.hasLoggedViewingExplodingShoe = true;
    }
  }

  animate = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.animate);
    TWEEN.update();
    const updateDeviceOrientationControls = () => {
      if (
        this.deviceOrientationControls &&
        isMobileOrTablet &&
        !this.focusedModel &&
        !this.props?.viewOnly
      ) {
        this.deviceOrientationControls.update();
      }
    };

    switch (this.props.canvasState?.currentScene) {
      case LoubiFutureScene.ArpoadorA2:
        if (this.mArpoador) {
          this.mArpoador.draw();
          updateDeviceOrientationControls();
          this.checkIfExplodingShoeInView();
        }
        break;
      case LoubiFutureScene.ViewingRoom:
        if (this.mViewingRoom) {
          this.mViewingRoom.draw();
          updateDeviceOrientationControls();
        }
        break;
      default:
        break;
    }
    // TODO: Delete counter and use debounce
    if (!this.props?.viewOnly && this.counter % 10 === 0) {
      this.updateRemoteOnChange(REMOTE_ACTION.CURRENT, undefined);
    }
    if (this.focusedModel) {
      this.transparent_Plane.setRotationFromQuaternion(this.camera.quaternion);
    }
    this.counter++;
    if (this.resizeScreen < 5) {
      this.resizeScreen++;
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
  };
  setOrbitControlBasedOnGyroscope() {
    if (this.deviceOrientationControls) {
      this.orbitControls.enabled = false;
    } else {
      this.orbitControls.enabled = !this.props.viewOnly;
    }
  }
  setButton(visible) {
    this.setState({ isShowButton: visible && !this.props.viewOnly });
    if (!this.orbitControls) {
      return;
    }
    if (visible) {
      this.orbitControls.enabled = false;
    } else {
      this.setOrbitControlBasedOnGyroscope();
    }
  }
  onClickChangeColor(index) {
    if (
      this.focusedModel &&
      this.props.canvasState?.currentScene === LoubiFutureScene.ViewingRoom
    ) {
      this.mViewingRoom.changeColorZoomIn(this.focusedModel, index);
      this.updateRemoteOnChange(REMOTE_ACTION.COLOR_CHANGE, {
        itemId: index,
        colorId: index
      });
    }
  }
  onClickBack = () => {
    if (
      this.focusedModel &&
      this.props.canvasState?.currentScene === LoubiFutureScene.ArpoadorA2
    ) {
      this.mArpoador.unfocusProduct(this.focusedModel.itemId);
    }
    if (
      this.focusedModel &&
      this.props.canvasState?.currentScene === LoubiFutureScene.ViewingRoom
    ) {
      this.mViewingRoom.unfocusProduct(this.focusedModel.itemId);
    }
  };
  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.resizeScreen = 0;
  };
  private isInOrGoingToViewingRoom = (): boolean =>
    this.props.canvasState?.currentScene === LoubiFutureScene.ViewingRoom ||
    this.props.canvasState?.currentScene ===
      LoubiFutureScene.ArpoadorToViewingroom;
  init = () => {
    const listener = new THREE.AudioListener();
    const audioLoader = new THREE.AudioLoader();
    this.camera.add(listener);
    this.mp3Zoom = new THREE.Audio(listener);
    audioLoader.load(mp3Zoom, function (buffer) {
      loubiFuture.mp3Zoom.setBuffer(buffer);
      loubiFuture.mp3Zoom.setVolume(1.0);
    });

    this.loadVideoDom();
    this.mArpoador = new Arpoador(this, TWEEN, this.props.onSelectProduct);
    this.mViewingRoom = new ViewingRoom(
      this,
      TWEEN,
      this.props.onSelectProduct
    );
    if (this.isInOrGoingToViewingRoom()) {
      this.mViewingRoom.init();
    } else {
      this.mArpoador.init();
    }
    this.loadEnvironment();
    this.addLights();
    this.setAppScene(this.props.canvasState?.currentScene);
    this.setState(
      {
        videoUrl: this.isInOrGoingToViewingRoom()
          ? last(canvasBasedVideoUrls)
          : first(canvasBasedVideoUrls)
      },
      () => {
        this.updateVideoTexture();
      }
    );
    if (!this.props.viewOnly) {
      this.addEventListeners();
    }
    window.addEventListener('resize', this.onWindowResize, false);
    this.animate();
  };
  render() {
    return (
      <div>
        {this.state.videoUrl && (
          <CSRComponentWrapper>
            <ReactPlayer
              className="canvas-video"
              ref={this.refVideo}
              key={this.state.videoUrl}
              url={this.state.videoUrl}
              playing={true}
              playsinline
              loop={true}
              muted={true}
              width="0.1%"
              height="0.1%"
              onError={(e) => {
                console.log('VideoError:', e);
              }}
              onDuration={() => {
                this.loadVideoDom();
              }}
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'true'
                  }
                }
              }}
            />
          </CSRComponentWrapper>
        )}
        <canvas id="c"></canvas>
        <BackToAnimationButton
          onClick={this.onClickBack}
          position="top-second-right"
        />
        {this.state.isShowButton && (
          <>
            <ColorChooser
              options={getViewingRoomColorByIndex(this.focusedModel.itemId)}
              selectedColor={this.focusedModel.itemId}
              onSelectColor={(index) => {
                this.onClickChangeColor(index);
              }}
            />

            <button
              className="btn close-product close-button glow"
              onClick={() => {
                logEvent(DID_CLOSE_VIEW_PRODUCT, DID_CLOSE_VIEW_PRODUCT, {
                  productId: this.props.selectedProductId
                });
                this.onClickBack();
              }}
            >
              <img
                src={`${LFAssetBaseUrl}/icons/v1/back_with_cross_white.svg`}
              />
            </button>
          </>
        )}

        <style jsx>
          {`
            div {
              no-repeat;
              background-position: center center;
              background-size: cover;
              background-attachment: fixed;
            }
            div,
            #c {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }
            a {
              position: fixed;
              top: 2vh;
              left: 2vh;
              font-size: 0.81em;
              text-align: left;
            }
            #sectionA1{
              opacity: .10 ;pointer-events: none;
            }
      
            .canvas-video :global(video) {
              pointer-events: none;
              z-index: -1;
            }
            @media (min-width:768px){
              #color-container{
                bottom: calc(5vh + 140px);
              }
            }
          
          `}
        </style>
      </div>
    );
  }
}

export default LoubiFutureBase;

import React from 'react';
import { vcaAssets, vcaKOPAssets } from '.';
import { vanCleefArpelsUsKingOfPrussiaSiteId } from '../../../../config';

import { IMapSpot } from '../../../../interfaces';
export interface SpotProps {
  spot: IMapSpot;
  left: number;
  top: number;
}

export const activePositionIcon = (
  <svg
    width="31"
    height="34"
    viewBox="0 0 31 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 34L0.344554 0.250001L30.6554 0.250004L15.5 34Z"
      fill="white"
    />
    <style jsx>{`
      svg {
        width: 17px;
      }
      @media (min-width: 768px) {
        svg {
          width: 20px;
        }
      }
    `}</style>
  </svg>
);

const VCASVMapSpot = ({
  visited,
  onClick,
  left,
  top,
  active,
  className,
  storeId
}: {
  visited?: boolean;
  onClick: () => void;
  left: number;
  top: number;
  active?: boolean;
  className: string;
  storeId?: string;
}) => {
  const img =
    storeId === vanCleefArpelsUsKingOfPrussiaSiteId
      ? vcaKOPAssets.mapSpot
      : vcaAssets.mapSpot;
  return (
    <div
      onClick={onClick}
      className={`spot-${className} ${visited ? 'visited' : ''} ${
        active ? 'active' : ''
      }`}
    >
      <img className={`mapspot`} src={img} alt="" />
      {active && <span className="with-shadow">{activePositionIcon}</span>}
      <style jsx>{`
        div {
          width: 30px;
          height: 30px;
          padding: 4px;
          border-radius: 100%;
          cursor: pointer;
          transform: translate(-50%, -50%);
          position: absolute;
          left: ${left}%;
          top: ${top}%;
          border: 2px solid transparent;
        }

        .spot-legend {
          width: 30px;
          height: 30px;
        }
        .visited {
          border: 2px solid #fff;
        }
        .visited.active {
          z-index: 1;
        }
        .mapspot {
          width: 100%;
          height: auto;
          display: block;
          pointer-events: none;
        }
        span {
          display: block;
          width: 40px;
          padding: 0;
          margin-left: 50%;
          transform: translate(-50%, -51px);
          animation: blink 3s infinite both;
          pointer-events: none;
        }
        @media (max-height: 600px) {
          div {
            width: 30px;
            height: 30px;
          }
        }
        @media (min-width: 768px) and (min-height: 600px) {
          div {
            width: ${storeId === vanCleefArpelsUsKingOfPrussiaSiteId
              ? 60
              : 40}px;
            height: ${storeId === vanCleefArpelsUsKingOfPrussiaSiteId
              ? 60
              : 40}px;
          }
          .spot-legend {
            width: 40px;
            height: 40px;
          }
          span {
            transform: translate(-50%, -63px);
          }
        }

        @keyframes blink {
          0%,
          50%,
          100% {
            opacity: 1;
          }
          25%,
          75% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default VCASVMapSpot;

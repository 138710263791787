import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import * as translation from '../../../i18n';
import { IVirtualBoutiqueContact } from '../../../interfaces';
import {
  DID_CLICK_CHAT_NOW,
  DID_CLICK_ENQUIRE_NOW
} from '../../../utils/constants';
import { getShortenedUrl, ShareScenario } from '../../../utils/shareUrl';
import { getWhatsappLink } from '../../../utils/whatsappLink';
import { AppContext } from '../../AppContext';
import { EnquireNowContact, getHrefForContact } from './EnquireButton';

const ChatAndEmailButton = ({
  whatsappContacts,
  enquireNowContacts,
  defaultMessage,
  labelTextKey
}: {
  whatsappContacts: IVirtualBoutiqueContact[];
  enquireNowContacts: EnquireNowContact[];
  defaultMessage?: string;
  labelTextKey?: string;
}) => {
  const context = React.useContext(AppContext);

  const handleChat = async (contactNumber) => {
    try {
      const shareUrl = await getShortenedUrl(ShareScenario.CHAT_NOW, context);
      logEvent(DID_CLICK_CHAT_NOW, context.store?.id || 'Unknown Store', {
        shareUrl,
        store: context.store
      });
      const url = getWhatsappLink(contactNumber, defaultMessage || shareUrl);
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  const logEnquireAnalytics = (contact: EnquireNowContact) => {
    logEvent(DID_CLICK_ENQUIRE_NOW, contact.email, contact);
  };

  const { t } = translation.useTranslation();

  return (
    <DropdownButton
      className="btn-action whatsapp"
      variant="outline-dark"
      id="boutique-dropdown-button"
      title={t(labelTextKey || 'chat_now')}
    >
      {whatsappContacts
        .filter((c) => c.whatsappNumber)
        .map((contact, i) => (
          <Dropdown.Item
            key={`${contact.whatsappNumber}${i}`}
            onClick={() => handleChat(contact.whatsappNumber)}
          >
            {contact.nameKey ? t(`vb:${contact.nameKey}`) : contact.name}
          </Dropdown.Item>
        ))}
      {enquireNowContacts.map((contact) => (
        <Dropdown.Item
          key={contact.name}
          href={getHrefForContact(contact)}
          onClick={() => logEnquireAnalytics(contact)}
        >
          {contact.nameKey ? t(`vb:${contact.nameKey}`) : contact.name}
        </Dropdown.Item>
      ))}
      <style jsx>
        {`
          :global(.whatsapp .dropdown-item) {
            font-size: 0.8em;
            width: 100%;
          }
        `}
      </style>
    </DropdownButton>
  );
};

export default ChatAndEmailButton;

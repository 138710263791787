import React from 'react';
import { isAllowedForName } from '../../../Appointment/inputValidator';
import LoubiAirwaysMiniPopupWrapper from '../LoubiAirwaysMiniPopupWrapper';
import { useDispatch } from 'react-redux';

import { louboutinBrandId } from '../../../../../config';
import { saveWishlist } from '../../../../../clientSideServices/wishlist';
import { useRouter } from 'next/router';
import { logEvent } from '../../../../../analytics';
import {
  DID_CLICK_SEND_WISHLIST_BUTTON,
  DID_SEND_LOUBI_AIRWAYS_WISHLIST
} from '../../../../../utils/constants';
import { DID_FAILED_TO_SEND_LOUBI_AIRWAYS_WISHLIST } from './../../../../../utils/constants';
import { ILoubiAirwaysWishlist } from '../../../../../interfaces/loubiairways';
import { getIdentityId } from '../../../../../utils/identity';
import { actionUpdateLoubiAirwaysWishlist } from '../../../../../redux/customActions/loubiAirways';
import { generateV4UUID } from '../../../../../utils/identityGenerator';
import { IWishlistPayload } from '../../../../../interfaces';
enum ButtonState {
  ERROR = 'INVALID CHARACTER',
  INITIAL = 'SEND',
  SENDING = 'SENDING...',
  SENT = 'SENT!',
  FAILED = 'PLEASE TRY AGAIN',
  REQUIRED = 'ALL FIELD REQUIRED'
}

export const defaultLoubiAirwaysWishlistPayload = (storeId: string) => ({
  id: generateV4UUID(),
  identityId: getIdentityId(),
  description: '',
  name: '',
  surname: '',
  store: storeId,
  brand: louboutinBrandId,
  wishlistItems: [],
  createdAt: new Date(),
  createdBy: getIdentityId()
});

const SendWishlistForm = ({
  onClose,
  wishlistState
}: {
  onClose: () => void;
  wishlistState: ILoubiAirwaysWishlist;
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const region = encodeURIComponent(router.query.utm_source as string);

  const name = wishlistState?.name || '';
  const surname = wishlistState?.surname || '';

  const [buttonLabel, setButtonLabel] = React.useState(ButtonState.INITIAL);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isAllowedForName(value)) {
      setButtonLabel(ButtonState.INITIAL);
      dispatch(
        actionUpdateLoubiAirwaysWishlist({ ...wishlistState, name: value })
      );
    } else {
      setButtonLabel(ButtonState.ERROR);
    }
  };

  const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isAllowedForName(value)) {
      setButtonLabel(ButtonState.INITIAL);
      dispatch(
        actionUpdateLoubiAirwaysWishlist({ ...wishlistState, surname: value })
      );
    } else {
      setButtonLabel(ButtonState.ERROR);
    }
  };

  const sendWishlist = () => {
    if (!name || !surname) {
      setButtonLabel(ButtonState.REQUIRED);
      return;
    }
    setButtonLabel(ButtonState.SENDING);
    logEvent(DID_CLICK_SEND_WISHLIST_BUTTON);
    const payload = {
      ...wishlistState,
      region,
      modifiedBy: getIdentityId() || undefined,
      modifiedAt: new Date(),
      status: 'SUBMITTED',
      open: undefined
    } as IWishlistPayload;

    console.log('payload', JSON.stringify(payload));

    saveWishlist(payload)
      .then(() => {
        setButtonLabel(ButtonState.SENT);
        logEvent(
          DID_SEND_LOUBI_AIRWAYS_WISHLIST,
          DID_SEND_LOUBI_AIRWAYS_WISHLIST,
          { id: payload.id }
        );
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((error) => {
        setButtonLabel(ButtonState.FAILED);
        logEvent(
          DID_FAILED_TO_SEND_LOUBI_AIRWAYS_WISHLIST,
          DID_FAILED_TO_SEND_LOUBI_AIRWAYS_WISHLIST,
          { payload: payload, error }
        );
      });
  };

  React.useEffect(() => {
    if (buttonLabel === ButtonState.ERROR) {
      setTimeout(() => {
        setButtonLabel(ButtonState.INITIAL);
      }, 1000);
    }
  }, [buttonLabel]);

  return (
    <LoubiAirwaysMiniPopupWrapper
      onClose={onClose}
      title="SHARE WITH YOUR SALES CREW"
    >
      <input
        type="text"
        className="input-text input-name"
        placeholder="NAME"
        value={name}
        onChange={handleNameChange}
      />

      <input
        type="text"
        className="input-text  input-surname"
        placeholder="SURNAME"
        value={surname}
        onChange={handleSurnameChange}
      />
      <button className="btn btn-lg btn-send" onClick={sendWishlist}>
        {buttonLabel}
      </button>
      <style jsx>{`
        input,
        button {
          border-radius: 15px;
        }
        .input-name {
          margin: 30px auto 20px;
        }
        .input-surname {
          margin: 0 auto 20px;
        }
        .input-text {
          background: #fff;
          border: none;
          padding: 10px;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-size: 1.8em;
          text-align: center;

          width: 80%;
          color: #cf152d;
        }
        .input-text:active,
        .input-text:focus {
          box-shadow: none;
          outline: none;
        }
        .btn-send {
          display: block;
          background: #cf152d;
          color: #fff;

          font-family: 'AntiqueOlive-Bold', Arial;
          font-style: italic;
          min-width: 200px;
          max-width: 100%;
          margin: auto;
        }
        @media (max-width: 768px) {
          .input-name {
            font-size: 1.5em;
          }
          .btn-send {
            font-size: 1em;
          }
        }
      `}</style>
    </LoubiAirwaysMiniPopupWrapper>
  );
};

export default SendWishlistForm;

import React from 'react';
import { BsList, BsX, BsSearch, BsHeart } from 'react-icons/bs';
import { useTranslation } from '../../../i18n';
import ShareButton from '../../VirtualBoutique/ShareButton';
import {
  ILanguageOption,
  IStore,
  IVirtualBoutiqueConfig,
  Source
} from '../../../interfaces';
import { CHCinematicIcon, CHLanguageIcon } from './CHIcons';
import { useDispatch } from 'react-redux';
import {
  actionOpenVBPopup,
  actionUpdateLanguage
} from '../../../redux/actions';
import { getCurrentHost } from '../../../utils/window';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_POWERED_BY_INSPIFY,
  DID_CLICK_PRODUCT_SEARCH_BUTTON,
  DID_CLICK_WISHLIST_BUTTON,
  DID_SELECT_LANGUAGE
} from '../../../utils/constants';
const CHSlideNav = ({
  show,
  setShow,
  config,
  store,
  onLanguageChange
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
  onLanguageChange?: (langCode: string) => void;
  show?: boolean;
  setShow: (open: boolean) => void;
}) => {
  const [selectLang, setSelectLang] = React.useState(false);
  const { t, i18n } = useTranslation();
  const languageCode = i18n?.language || 'en';
  const language =
    config.languages.find((lang) => lang.code === languageCode)?.display ||
    'English';

  const dispatch = useDispatch();

  const handleToggleMenu = (open: boolean) => {
    setShow(open);
  };
  const selectLanguageLabel = React.useMemo(() => {
    if (!selectLang)
      return (
        <div onClick={() => setSelectLang(!selectLang)}>
          <span className="mr-2">{t('vb:language')}:</span>{' '}
          <span>{language}</span>
        </div>
      );
    return (
      <span onClick={() => setSelectLang(!selectLang)}>
        {t('vb:select_language')}
      </span>
    );
  }, [selectLang, language]);

  const onProductSearch = () => {
    logEvent(DID_CLICK_PRODUCT_SEARCH_BUTTON);
    handleToggleMenu(false);
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/productsearch/${config.brandId}?source=${
          Source.Showroom
        }&store=${store.id}&language=${languageCode}`,
        false
      )
    );
  };

  const onWishlist = () => {
    logEvent(DID_CLICK_WISHLIST_BUTTON);
    handleToggleMenu(false);
    dispatch(
      actionOpenVBPopup(
        `${getCurrentHost()}/wishlist?source=${Source.Showroom}&store=${
          store.id
        }&language=${languageCode}`,
        true
      )
    );
  };

  const onSwitchLanguageHandler = (language: ILanguageOption) => {
    const langCode = language?.code || 'en';
    logEvent(DID_SELECT_LANGUAGE, DID_SELECT_LANGUAGE, {
      language: langCode,
      source: 'MENU'
    });
    setSelectLang(false);
    onLanguageChange?.(langCode);
    i18n?.changeLanguage?.(langCode);
    dispatch(actionUpdateLanguage(langCode));
  };

  return (
    <>
      {show && (
        <div
          className="nav-backdrop"
          onClick={() => handleToggleMenu(!show)}
        ></div>
      )}

      <button
        className="slidenav-icon with-shadow btn-express"
        onClick={() => handleToggleMenu(!show)}
      >
        <BsList size={40} color="fff" />
      </button>

      <nav className="CHSlideNav">
        <div className={`nav-container ${show ? 'show' : ''}`}>
          <div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-close"
                onClick={() => handleToggleMenu(false)}
              >
                <span className="mr-1">{t('close')}</span>
                <BsX size={20} />
              </button>
            </div>
            <ul className="list-group main-menu">
              <li className="list-item nav-search" onClick={onProductSearch}>
                <span className="list-item__icon">
                  <BsSearch size={20} />
                </span>
                <span className="list-item__label">
                  {t('vb:product_search')}
                </span>
              </li>
              <li className="list-item nav-wishlist" onClick={onWishlist}>
                <span className="list-item__icon">
                  <BsHeart size={20} />
                </span>
                <span className="list-item__label">
                  {t('vb:wishlist_favorites')}
                </span>
              </li>
              <li className="list-item nav-share">
                <ShareButton
                  message={config.defaultShareMessageKey}
                  iconConfig={{ fill: true }}
                  title={t('vb:share_experience')}
                />
              </li>
              <li className="list-item nav-cinematic">
                <span className="list-item__icon">
                  <CHCinematicIcon color="#000" />
                </span>
                <span className="list-item__label">Cinematic</span>
              </li>
              <li className="list-item nav-language">
                <span className="list-item__icon">
                  <CHLanguageIcon color="#000" />
                </span>
                <div className="list-item__label">
                  {selectLanguageLabel}
                  {selectLang && (
                    <ul className="list-group language-menu">
                      {config.languages.map((lang) => (
                        <li
                          key={lang.code}
                          className={`language-item ${
                            lang.code === languageCode ? 'selected' : ''
                          }`}
                          onClick={() => onSwitchLanguageHandler(lang)}
                        >
                          {lang.display}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            </ul>
          </div>
          <div className="chopard-content copyright noselect">
            <span>&copy; 2022 Chopard - All rights reserved | Powered by</span>{' '}
            <a
              href={'http://inspify.com/'}
              target="_blank"
              onClick={() => logEvent(DID_CLICK_POWERED_BY_INSPIFY)}
            >
              INSPIFY
            </a>
          </div>
        </div>
      </nav>

      <style jsx>{`
        .nav-cinematic {
          display: none;
        }
        .nav-backdrop {
          position: fixed;
          z-index: 4;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }

        .nav-share ::global(.nav-item__label) {
          color: #000;
        }
        .CHSlideNav {
          padding: 5px 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 6;
          font-family: 'EngraversGothicBTW03-Rg';
        }
        .slidenav-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 6;
        }
        .main-menu {
          width: 300px;
          margin: 0 auto;
          list-style: none;
        }
        .language-menu {
          list-style: none;
        }
        .list-item {
          padding: 30px 0;
          cursor: pointer;
          border-bottom: 1px solid #606060;
        }
        .language-item {
          padding: 15px 0;
        }

        .language-item.selected {
          font-weight: 700;
        }

        .list-item__icon {
          margin-right: 40px;
        }

        .list-item__label {
          text-transform: uppercase;
          font-size: var(--label-size);
          font-size: 400;
        }

        .nav-language {
          display: flex;
        }
        .btn-close {
          display: inline-flex;
          align-items: center;
          color: #000;
          letter-spacing: 1pt;
          text-transform: uppercase;
          margin: 10px 5px 0px 0px;
          font-size: 0.8em;
        }
        .nav-container {
          position: absolute;
          right: 0;
          pointer-events: none;
          left: auto;
          top: 0;
          bottom: 0;
          text-align: left;
          background: #fff;
          width: 0;
          z-index: 41;
          overflow-y: auto;
          color: #000;
          opacity: 0;
          background-color: rgba(255, 255, 255, 0.9);
          transition-property: opacity, width;
          transition-duration: 0.5s;
          transition-timing-function: ease-in-out;
          --label-size: 1.1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .nav-container.show {
          pointer-events: all;
          opacity: 1;
          width: min(400px, 100vw);
        }
        .slidenav-icon {
          background: transparent;
          border: none;
          color: #fff;
          direction: ltr;
          margin-left: 10px;
          opacity: ${show ? 0 : 1};
          transition: opacity 0.2s ease-in-out;
        }
        .copyright {
          font-size: 12px;
          padding-bottom: 20px;
          text-align: center;
          color: #000;
        }
        .copyright a {
          color: #000;
          text-decoration: underline;
        }
        @media (orientation: landscape) and (max-height: 500px) {
          .copyright {
            bottom: initial;
            margin-top: 20px;
            text-align: right;
            padding-right: 20px;
          }
          .nav-container.show {
            width: 100vw;
          }
          .main-menu {
            width: auto;
            margin: 0 20px;
          }
          .nav-share {
            display: flex;
          }
        }
        @media (max-width: 560px) {
          .nav-container {
            width: 100vw;
            background-color: rgba(255, 255, 255, 1);
            --label-size: 1em;
          }
        }
      `}</style>
      <style jsx global>
        {`
          .CHSlideNav .nav-item {
            padding: 0;
            color: #000;
            display: block;
            background: transparent;
          }
          .CHSlideNav .nav-item__label {
            font-size: var(--label-size);
            font-size: 500;
          }
          .CHSlideNav .nav-item:hover {
            background: transparent;
            color: #000;
          }
          .CHSlideNav .nav-item__icon {
            margin-right: 40px;
          }
          .CHSlideNav .share-list a {
            margin-top: 30px;
            border: none;
          }
          .CHSlideNav .share-list a .resp-sharing-button__icon,
          .CHSlideNav .share-list a .resp-sharing-button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .CHSlideNav .share-list a.resp-sharing-button__link {
            color: #000;
            text-transform: uppercase;
            font-size: 1.2em;
          }
          .CHSlideNav .share-list a .resp-sharing-button__icon {
            width: 50px;
            height: 50px;
            border: 1px solid #000;
            border-radius: 50%;
          }

          .CHSlideNav .share-list a .resp-sharing-button__icon svg {
            fill: #000;
          }
          .CHSlideNav .share-list a:hover {
            background: transparent;
          }

          .CHSlideNav .nav-item__icon svg {
            fill: #000 !important;
            filter: none;
          }
          @media (orientation: landscape) and (max-height: 500px) {
            .CHSlideNav .share-list {
              flex-wrap: nowrap;
              transform: translateY(-20px);
              height: 0px;
            }
            .CHSlideNav .share-list a {
              margin: 0 10px;
              padding: 0px 0;
              border: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default CHSlideNav;

import React from 'react';
import { useTranslation } from '../../../i18n';
import {
  IVirtualBoutiqueConfig,
  IVirtualBoutiqueContact
} from '../../../interfaces';
import { isChatAvailable } from '../../../utils/stores';
import EmailSvgIcon from '../Icons/EmailSvgIcon';
import WhatsappSvgIcon from '../Icons/WhatsappSvgIcon';
import EmailContactButton from './EmailContactButton';
import WhatsappContactButton from './WhatsappContactButton';

const ContactButtonWithWhatsapp = ({
  config,
  additionalContactsInChatFilter,
  labelTextKey
}: {
  config: IVirtualBoutiqueConfig;
  additionalContactsInChatFilter?: (c: IVirtualBoutiqueContact) => boolean;
  labelTextKey?: string;
}) => {
  const [showChat, setShowChat] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);

  const contacts = config.contacts || [];

  const { t } = useTranslation();

  const defaultChatMessage = t(config.defaultChatMessageKey);

  React.useEffect(() => {
    setShowChat(isChatAvailable(config));
  }, []);

  const mapContactToEmailButton = (
    contact: IVirtualBoutiqueContact,
    index: number
  ) => (
    <EmailContactButton contact={contact} key={`${contact.email}-${index}`}>
      <>
        <EmailSvgIcon />
        <span>
          {t('email')}{' '}
          {contact.nameKey
            ? t(`vb:${contact.nameKey}`)
            : contact.name.toUpperCase()}
        </span>
      </>
    </EmailContactButton>
  );
  const emailContacts = contacts.map(mapContactToEmailButton);

  const whatsappContacts = contacts
    .filter((c) => c.whatsappNumber)
    .map((contact: IVirtualBoutiqueContact) => (
      <WhatsappContactButton
        contact={contact}
        defaultMessage={defaultChatMessage}
        key={contact.name}
      >
        <>
          <WhatsappSvgIcon />
          <span>
            {t('vb:chat_with')}{' '}
            {contact.nameKey
              ? t(`vb:${contact.nameKey}`)
              : contact.name.toUpperCase()}
          </span>
        </>
      </WhatsappContactButton>
    ));

  if (contacts.length === 0) return null;

  let chatButton = (
    <>
      <span className="full">
        {t(labelTextKey || 'vb:chat_now_via_whatsapp')}
      </span>
      <span className="mobile">{t('vb:chat')}</span>
    </>
  );

  let enquireButton = (
    <>
      <span className="full">{t('vb:send_enquiry_email')}</span>
      <span className="mobile">{t('vb:enquire')}</span>
    </>
  );

  if (contacts.length === 1) {
    chatButton = (
      <>
        <span className="full">
          <WhatsappContactButton
            contact={contacts[0]}
            defaultMessage={defaultChatMessage}
          >
            <span>{t('vb:chat_now_via_whatsapp')}</span>
          </WhatsappContactButton>
        </span>
        <span className="mobile">
          <WhatsappContactButton
            contact={contacts[0]}
            defaultMessage={defaultChatMessage}
          >
            <span>{t('vb:chat')}</span>
          </WhatsappContactButton>
        </span>
      </>
    );

    enquireButton = (
      <>
        <span className="full">
          <EmailContactButton contact={contacts[0]}>
            <span>{t('vb:send_enquiry_email')}</span>
          </EmailContactButton>
        </span>
        <span className="mobile">
          <EmailContactButton contact={contacts[0]}>
            <span>{t('vb:enquire')}</span>
          </EmailContactButton>
        </span>
      </>
    );
  }

  const chatContact = additionalContactsInChatFilter
    ? [
        ...whatsappContacts,
        contacts
          .filter(additionalContactsInChatFilter)
          .map(mapContactToEmailButton)
      ]
    : whatsappContacts;

  return (
    <div
      className="contact-container"
      onMouseLeave={() => setShowDetails(false)}
      onClick={() => setShowDetails(!showDetails)}
    >
      <button className="action-button">
        {showChat ? chatButton : enquireButton}
      </button>
      {contacts.length > 1 && (
        <div className={`button-options-list ${showDetails ? 'active' : null}`}>
          {showChat ? chatContact : emailContacts}
        </div>
      )}
      <style global jsx>
        {`
          .contact-container {
            position: relative;
          }

          .contact-container .btn-contact a {
            color: #fff;
          }
          .contact-container .btn-contact a:hover {
            text-decoration: none;
          }
          .contact-container .mobile {
            display: none;
          }

          .contact-container .full {
            display: inline-block;
          }

          @media (max-width: 768px) {
            .contact-container .mobile {
              display: inline-block;
            }

            .contact-container .full {
              display: none;
            }
            .button-options-list a svg {
              display: none;
            }
            .action-button {
              margin-left: -5px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ContactButtonWithWhatsapp;

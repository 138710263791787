import { isUserOnMobile } from './deviceDetector';

export const getWhatsappLink = (
  contactNumber: string | undefined,
  message: string
) => {
  const text = encodeURIComponent(message || '');
  return isUserOnMobile()
    ? `https://wa.me/${contactNumber || ''}?text=${text}`
    : `https://web.whatsapp.com/send?phone=${
        contactNumber || ''
      }&text=${text}`;
};

import React from 'react';
import { actionOpenAppointmentFromPopup } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { IProduct, AppointmentType } from '../../../interfaces';
import { getProductDisplayName } from '../../../utils/product';
import { Dropdown, DropdownButton, Button } from 'react-bootstrap';
import * as translation from '../../../i18n';
import { logEvent } from '../../../analytics';
import { DID_CLICK_PRODUCT_APPOINTMENT } from '../../../utils/constants';
import { AppContext } from '../../AppContext';
import { inIframe } from '../../../utils/iframeDetector';

interface Props {
  product: IProduct;
  appointmentTypes?: AppointmentType[];
}

const AppointmentButton = ({ product, appointmentTypes }: Props) => {
  const context = React.useContext(AppContext);
  const dispatch = useDispatch();
  const { t, i18n } = translation.useTranslation();
  const showAppointmentButton =
    inIframe() && !!context.storeConfig?.entitlement?.productAppointment;
  const reqAppointmentTypes = appointmentTypes || [
    AppointmentType.VIRTUAL_WALKTHROUGH,
    AppointmentType.PHYSICAL
  ];
  const handleMakeAppointment = (type: AppointmentType) => {
    dispatch(
      actionOpenAppointmentFromPopup({
        open: true,
        type,
        enquiry: `${getProductDisplayName(product, i18n.language)} (${
          product.modelCode
        }).`
      })
    );
    logEvent(DID_CLICK_PRODUCT_APPOINTMENT, type, { product: product.id });
  };
  const translationForAppointmentType = (type: AppointmentType) => {
    switch (type) {
      case AppointmentType.VIRTUAL_WALKTHROUGH:
        return t('vb:virtual_walkthrough');
      case AppointmentType.PHYSICAL:
        return t('vb:physical_boutique_visit');
      default:
        return null;
    }
  };

  if (!showAppointmentButton) {
    return null;
  }
  const multiAppointmentDropdown = (
    <DropdownButton
      className="btn-action product-appointment"
      variant="outline-dark"
      id="boutique-dropdown-button"
      title={<> {t('vb:make_appointment')}</>}
    >
      {reqAppointmentTypes.map((type, index) => (
        <Dropdown.Item key={index} onClick={() => handleMakeAppointment(type)}>
          {translationForAppointmentType(type)}
        </Dropdown.Item>
      ))}

      <style jsx global>{`
        .product-appointment .dropdown-item {
          font-size: 0.8em;
        }
        .product-appointment .dropdown-item:active {
          background: transparent;
        }
        .product-appointment .dropdown-menu {
          width: 100%;
        }
      `}</style>
    </DropdownButton>
  );

  const singleAppointmentButton = (
    <>
      <Button
        className="btn-action"
        variant="outline-dark"
        onClick={() => handleMakeAppointment(reqAppointmentTypes[0])}
      >
        {t('vb:make_appointment')}
      </Button>
      <style jsx>
        {`
          a {
            color: inherit;
          }
          a:hover {
            text-decoration: none;
          }
          p {
            margin: 10px 0;
            font-style: italic;
            color: #4c4c4c;
            font-size: 14px;
          }
        `}
      </style>
    </>
  );

  return reqAppointmentTypes?.length === 1
    ? singleAppointmentButton
    : multiAppointmentDropdown;
};

export default AppointmentButton;

import React from 'react';
import { IShoppingCartItem } from '../../../interfaces';
import CartItem from './CartItem';
import CartTotal from './CartTotal';
import { useTranslation } from '../../../i18n';

interface CartTableProps {
  onIncreaseQty?: (id: string) => void;
  onReduceQty?: (id: string) => void;
  items: IShoppingCartItem[];
  deliveryFee?: number;
  readonly?: boolean;
}

const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        <th className="th-product" scope="col">
          {t('vb:product')}
        </th>
        <th className="th-desc" scope="col"></th>
        <th className="th-qty text-center m-hide" scope="col">
          {t('vb:quantity')}
        </th>
        <th className="th-price text-right" scope="col">
          {t('vb:price')}
        </th>
      </tr>
      <style jsx>{`
        th {
          text-transform: uppercase;
          font-size: 0.8em;
          letter-spacing: 2px;
        }
        .th-product {
          width: 120px;
          padding-left: 0;
          padding-right: 0;
        }
        .th-price {
          width: 120px;
          padding-left: 0;
          padding-right: 0;
        }

        .th-qty {
          width: 110px;
        }
        :global(.isRtl .th-qty) {
          text-align: left !important;
        }
        :global(.isRtl .th-product) {
          text-align: right !important;
        }

        @media (max-width: 520px) {
          .m-hide {
            display: none !important;
          }
        }
      `}</style>
    </thead>
  );
};

const CartTable = ({
  items,
  onIncreaseQty,
  onReduceQty,
  deliveryFee = 0,
  readonly
}: CartTableProps) => {
  const { t } = useTranslation();
  return (
    <>
      <table className="table">
        <TableHeader />
        <tbody>
          {items &&
            items.map((p) => (
              <CartItem
                key={p.id}
                item={p}
                onIncreaseQty={onIncreaseQty}
                onReduceQty={onReduceQty}
                readonly={readonly}
              />
            ))}
        </tbody>
        <style jsx>
          {`
            table {
              font-size: 16px;
            }
          `}
        </style>
      </table>
      <CartTotal
        items={items}
        extraFee={deliveryFee}
        extraFeeLabel={t('vb:delivery')}
      />
    </>
  );
};

export default CartTable;

import React, { useState, useEffect } from 'react';
import ReactStars from 'react-rating-stars-component';
import { BsStarFill, BsStar, BsStarHalf } from 'react-icons/bs';
import { useTranslation } from '../i18n';
import { sendFeedback } from '../clientSideServices/feedback';
import { IMainState } from '../interfaces/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionFeedbackPopup,
  actionFeedbackTimerFired
} from '../redux/actions';
import { logEvent } from '../analytics/index';
import {
  DID_CLICK_SEND_FEEDBACK,
  DID_CLOSE_FEEDBACK_POPUP,
  DID_OPEN_FEEDBACK_POPUP
} from '../utils/constants';

interface RatingProps {
  onClick: (rate: number) => void;
  value: number;
  size: number;
}
export const StarsRating = ({ onClick, value, size }: RatingProps) => (
  <ReactStars
    size={30}
    value={size === 10 ? value / 2 : value}
    isHalf={size === 10 ? true : false}
    emptyIcon={<BsStar />}
    filledIcon={<BsStarFill />}
    halfIcon={<BsStarHalf />}
    color="#666"
    activeColor="#000"
    onChange={(val) => onClick(size === 10 ? val * 2 : val)}
  />
);

export const NumberRating = ({ onClick, value, size }: RatingProps) => {
  const { t, i18n } = useTranslation();

  const isRtl = i18n.language === 'ar';

  const arr = t('vb:rating_numbers').split(',');

  return (
    <div
      className={`number-rating btn-group btn-group-sm ${
        isRtl ? 'isRtl' : null
      }`}
    >
      {arr.map((v, i) => {
        if (i < size) {
          return (
            <button
              key={i}
              onClick={() => onClick(i + 1)}
              className={`btn ${
                value === i + 1 ? 'btn-dark' : 'btn-outline-secondary'
              }`}
            >
              {v}
            </button>
          );
        }
      })}
      <style jsx>{`
        .number-rating {
          width: 100%;
          margin: 18px auto;
        }
        .btn-group.isRtl .btn:last-child {
          border-radius: 0.2rem 0 0 0.2rem;
        }
        .btn-group.isRtl .btn:first-child {
          border-radius: 0 0.2rem 0.2rem 0;
          border-left: 0;
        }
      `}</style>
    </div>
  );
};

interface Props {
  ratingType?: 'stars' | 'number';
  maxRating?: 5 | 10;
  initialRating?: number;
  initialDelay?: number;
  initialOpen?: boolean;
}

const Feedback = ({
  ratingType = 'number',
  maxRating = 10,
  initialRating = 0,
  initialDelay = 0
}: Props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const open =
    useSelector(
      (state: IMainState) => state.clientState.vb?.openFeedbackPopup
    ) || false;

  const [rating, setRating] = useState(initialRating);
  const [comment, setComment] = useState('');
  const [buttonTitle, setButtonTitle] = useState();

  const handleError = (error: any) => {
    setButtonTitle(t('vb:PLEASE TRY AGAIN'));
    logEvent(DID_CLICK_SEND_FEEDBACK, DID_CLICK_SEND_FEEDBACK, {
      success: false,
      error
    });
  };

  useEffect(() => {
    if (open) {
      logEvent(DID_OPEN_FEEDBACK_POPUP, DID_OPEN_FEEDBACK_POPUP);
    }
  }, [open]);

  useEffect(() => {
    setButtonTitle(t('vb:SEND'));
  }, [i18n.language]);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => dispatch(actionFeedbackTimerFired()),
      initialDelay
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={`Feedback ${open ? 'open' : 'hide'}`}>
      <div className="rating-container">
        <h2>{t('vb:rate_your_experience')}</h2>
        {ratingType === 'stars' ? (
          <StarsRating onClick={setRating} value={rating} size={maxRating} />
        ) : (
          <NumberRating onClick={setRating} value={rating} size={maxRating} />
        )}
      </div>
      <div className="form-container">
        <textarea
          placeholder={t('vb:comments')}
          className="form-control"
          onBlur={(e) => setComment(e.target.value)}
        />
        <button
          className="btn btn-dark btn-action btn-full"
          onClick={() => {
            if (rating !== 0) {
              sendFeedback({
                maxRating,
                userRating: rating,
                comment
              })
                .then(() => {
                  dispatch(actionFeedbackPopup(false));
                  logEvent(DID_CLICK_SEND_FEEDBACK, DID_CLICK_SEND_FEEDBACK, {
                    success: true
                  });
                })
                .catch((e) => handleError(e));
            } else {
              handleError('Rating cannot empty');
            }
          }}
        >
          {buttonTitle}
        </button>
        <button
          className="btn btn-outline-dark btn-action btn-full"
          onClick={() => {
            dispatch(actionFeedbackPopup(false));
            logEvent(DID_CLOSE_FEEDBACK_POPUP, DID_CLOSE_FEEDBACK_POPUP);
          }}
        >
          {t('skip')}
        </button>
      </div>
      <style jsx>{`
        .Feedback :global(.react-stars) {
          outline: none;
          margin: auto auto 13px;
        }
        .Feedback {
          overflow: hidden;
          position: fixed;
          bottom: 0;
          right: 40px;
          width: 300px;
          background: #fff;
          height: 0;
          z-index: 10;
          text-align: center;
          transition: height 0.4s;
        }
        .Feedback.open {
          height: 390px;
        }
        .rating-container {
          background: #f7f7f7;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          padding: 25px 15px 0;
        }
        textarea {
          resize: none;
          border-radius: 0;
          font-weight: normal;
          height: 166px;
          padding-top: 15px;
          border: none;
          background: transparent;
        }
        textarea:focus {
          box-shadow: none;
          outline: none;
          background: transparent;
        }
        h2 {
          clear: both;
          font-size: 1em;
          margin: 0;
          font-weight: 600;
          letter-spacing: 2px;
        }
        .form-container {
          padding: 0 15px;
        }

        @media (max-width: 630px) {
          .Feedback {
            right: calc(50vw - 150px);
          }
        }
      `}</style>
    </div>
  );
};

export default Feedback;

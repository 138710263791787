import React from 'react';
import flatMap from 'lodash/flatMap';
import { useSelector, useDispatch } from 'react-redux';
import { logEvent } from '../../analytics';
import {
  actionDidLeaveMeeting,
  actionLocalTrackAudioMuteDidChange,
  actionLocalTrackVideoMuteDidChange,
  actionParticipantIsReconnecting,
  actionSetMeetingLayoutState,
  actionUpdateLocalUser
} from '../../redux/actions';
import { IMainState, IUser, MeetingRole } from '../../interfaces';
import { JitsiConnection, JitsiMeetingRoom } from '../../interfaces';
import {
  DID_UPDATE_MEETING_DISPLAY_NAME,
  MEETING_RECONNECT_STATE
} from '../../utils/constants';
import { createUser } from '../../clientSideServices/users';
import { getIdentityId } from '../../utils/identity';
import { getReconnectState } from '../../utils/window';
import { useRouter } from 'next/router';
import { getMeetingRole } from '../../utils/meeting';
import { disconnectExperience } from '../PixelStream/CommandHandler/webToPSCommand';
import {
  DisconnectReason,
  logDisconnect
} from '../PixelStream/utils/connectionHandler';
import { actionUpdateUserAttributesAsync } from '../../redux/asyncActions';

export const MeetingContext = React.createContext<{
  connection?: JitsiConnection;
  room?: JitsiMeetingRoom;
  setConnection: (connection: JitsiConnection) => void;
  setRoom: (room: JitsiMeetingRoom) => void;
  onDisconnect: (eventName: string, meetingId: string) => void;
  setUserName: (name: string) => void;
  networkState: {
    isOnline: boolean;
    offerReload: boolean;
  };
}>(undefined);

const MeetingContextContainer = ({
  children,
  isPixelStreamingExperience
}: {
  children: React.ReactNode;
  isPixelStreamingExperience?: boolean;
}) => {
  const dispatch = useDispatch();
  const [connection, setConnection] = React.useState(null);
  const [room, setRoom] = React.useState<JitsiMeetingRoom>(null);
  const [networkState, setNetworkState] = React.useState({
    isOnline: true,
    offerReload: false
  });

  const router = useRouter();
  const meetingId = (router.query.meeting || '') as string;
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  const role = getMeetingRole((router.query.role || '') as string);
  const user = useSelector((state: IMainState) => state.clientState?.user);
  const userType = user?.userType?.toLowerCase();

  const localTracks = meeting?.localUser?.tracks || [];

  React.useEffect(() => {
    const onlineStatusListener = () => {
      setNetworkState((network) => ({ ...network, isOnline: true }));
    };
    const offlineStatusListener = () => {
      setNetworkState((network) => ({ ...network, isOnline: false }));
    };
    const isSupported =
      window.addEventListener && typeof navigator.onLine !== 'undefined';
    if (isSupported) {
      window.addEventListener('online', onlineStatusListener);
      window.addEventListener('offline', offlineStatusListener);
    } else console.log('Browser does not support network API');
    return () => {
      window.removeEventListener('online', onlineStatusListener);
      window.removeEventListener('offline', offlineStatusListener);
    };
  }, []);

  React.useEffect(() => {
    const preState = getReconnectState(meetingId);
    if (preState) {
      const {
        layout,
        displayName,
        audioMuted,
        videoMuted,
        meetingState,
        participantId
      } = preState;
      if (role === MeetingRole.ADVISOR && layout)
        dispatch(actionSetMeetingLayoutState(layout));
      if (displayName)
        dispatch(actionUpdateLocalUser({ ...user, alias: displayName }));
      dispatch(actionLocalTrackAudioMuteDidChange(!!audioMuted));
      dispatch(actionLocalTrackVideoMuteDidChange(!!videoMuted));
      dispatch(
        actionParticipantIsReconnecting({
          meetingState: meetingState,
          oldParticipantId: participantId
        })
      );
      localStorage.removeItem(MEETING_RECONNECT_STATE);
    }
  }, []);

  const onConnectionFailed = () => {
    console.log('connection failed');
    setNetworkState((network) => ({ ...network, offerReload: true }));
  };

  const onConnectionDisconnected = () => {
    console.log('connection disconnected');
  };

  React.useEffect(() => {
    if (connection) {
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        onConnectionFailed
      );
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        onConnectionDisconnected
      );
    }
  }, [connection]);

  const disconnectCleanUp = () => {
    connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      onConnectionFailed
    );
    connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      onConnectionDisconnected
    );
    const remoteTracks = flatMap(
      Object.keys(meeting.remoteUsers || {}),
      (participantId) =>
        (meeting.remoteUsers || {})[participantId]?.tracks || []
    );
    remoteTracks.forEach((t) => t.detach(null));
    localTracks.forEach((t) => {
      t.dispose();
    });
  };

  const onDisconnect = (eventName: string, meetingId: string) => {
    room
      ?.leave()
      .then(() => disconnectCleanUp())
      .then(() => {
        return connection.disconnect();
      })
      .then(() => {
        const participantId = room?.myUserId();

        dispatch(actionDidLeaveMeeting());
        logEvent(eventName, eventName, {
          participantId,
          meetingId
        });
        if (isPixelStreamingExperience) {
          logDisconnect({
            reason: DisconnectReason.MEETING_END
          });
          setTimeout(() => {
            disconnectExperience();
            const winLoc = window.location;
            winLoc.replace(winLoc.href.split('?')[0]);
          }, 300);
        }
      })
      .catch((e) => {
        console.log('error when stopping conference');
        console.log(e);
      });
  };

  const setUserName = (name: string) => {
    const hasInput = name && name.trim().length > 0;

    room?.setDisplayName(name);
    const alias = hasInput ? name.trim() : undefined;
    const updatedUser: IUser = user
      ? {
          ...user,
          alias
        }
      : {
          ...createUser(getIdentityId(), name),
          alias
        };

    //update user name first for lounge joining
    dispatch(actionUpdateLocalUser(updatedUser));
    //save user info later
    if (userType === 'web' || !userType) {
      dispatch(actionUpdateUserAttributesAsync(updatedUser.id, updatedUser));
    }

    logEvent(DID_UPDATE_MEETING_DISPLAY_NAME, DID_UPDATE_MEETING_DISPLAY_NAME, {
      name,
      meetingId: meeting.meetingId,
      isPresenter: meeting.isPresenter,
      participantId: room?.myUserId()
    });
  };

  return (
    <MeetingContext.Provider
      value={{
        connection,
        room,
        setConnection,
        setRoom,
        onDisconnect,
        setUserName,
        networkState
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};

export default MeetingContextContainer;

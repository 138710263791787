import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  IVirtualBoutiqueConfig,
  IMainState,
  INudge
} from '../../../interfaces';
import NudgeMicroSite from '../../../advisorHub/components/Nudge/NudgeMicroSite';
import { useRouter } from 'next/router';
import { getNudgeWithContentById } from '../../../clientSideServices/nudge';

const LoungeNudge = ({ config }: { config: IVirtualBoutiqueConfig }) => {
  const router = useRouter();
  const nudgeId = router.query?.meeting as string | undefined;
  const [nudge, setNudge] = React.useState<INudge>(null);
  const [isLoadingNudge, setIsLoadingNudge] = React.useState<boolean>(false);

  const popupState = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );

  React.useEffect(() => {
    setIsLoadingNudge(true);
    getNudgeWithContentById(nudgeId)
      .then((result) => {
        setNudge(result.data);
      })
      .finally(() => setIsLoadingNudge(false))
      .catch((e) => console.log(e));
  }, [nudgeId]);

  return (
    <div className="LoungeNudge" id="inspirationsSection">
      {isLoadingNudge && (
        <div className="text-center placeholder">
          <Spinner animation="border" />
        </div>
      )}
      {nudge && (
        <NudgeMicroSite
          brandId={config.brandId}
          nudge={nudge}
          containPopup={false}
        />
      )}
      <style jsx>{`
        :global(body) {
          overflow: ${popupState?.open ? 'hidden' : 'auto'};
        }
        .placeholder {
          padding: 100px 0;
        }
        .LoungeNudge {
          background: #fff;
          position: relative;
        }
      `}</style>
      <style jsx global>{`
        .NudgeMicrosite {
          min-height: auto !important;
          padding-bottom: 0 !important;
          padding-top: 30px !important;
          border-top: 1px solid #efefef;
          background: #fff !important;
          position: relative !important;
        }
        .NudgeMicrosite .logo,
        .NudgeMicrosite .meeting {
          display: none !important;
        }
        .meeting-lounge .content-container {
          min-height: ${nudge ? 'auto' : '100vh'} !important;
        }
        .NudgeMicrosite .activeSlide {
          border: none !important;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
        }

        .slick-dots li button:before,
        .slick-dots li.slick-active button:before {
          color: #000;
        }
      `}</style>
    </div>
  );
};

export default LoungeNudge;

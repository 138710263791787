import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { assetBaseUrl } from '../../../../../config';
import {
  ILoubiAirways2dAnimationState,
  LoubiAirwaysAnimation
} from '../../../../../interfaces/loubiairways';
import {
  actionDidUpdateLoubiAirwaysAnimation,
  actionUpdateLoubiAirwaysSelectedModel
} from '../../../../../redux/customActions/loubiAirways';
import { useRemoteLoubiAirwaysAnimationState } from '../../../../hooks/loubiAirways';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../../Meeting/MeetingLayout/SMPortraitNormal';
import {
  AnimationContext,
  FADE_SPEED
} from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import { animationVoyageThrottleDurationInMs } from '../../LouboutinCustomComponent/animationUtils';
import ContinueFlightButton from '../Buttons/LJContinueFlightButton';
import TwoDProductHotSpot from '../TwoDProduct/LJTwoDProductHotSpot';
import { AnimationProductModel } from '../TwoDProduct/LJTwoDProductsList';
import NewsPaper from '../../LouboutinCustomComponent/NewsPaper/NewsPaper';

const assetPath = `${assetBaseUrl}/loubiairways/luggage-animation/newspaper/v3`;

const LoubiTimes = () => {
  const dispatch = useDispatch();
  const { setLoaded, onCloseAnimation } = React.useContext(AnimationContext);
  const [animateClose, setAnimateClose] = React.useState(false);

  const remoteState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.NEWSPAPER
  ) as ILoubiAirways2dAnimationState;

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirways2dAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.NEWSPAPER,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );

  const updateRemoteState = (state: ILoubiAirways2dAnimationState) => {
    throttledUpdateRemoteState(state);
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const content = (
    <div className="inner loubiTimesContent">
      <div className="img-wrapper">
        <img
          className="cover"
          src={`${assetPath}/cover_jp.jpg`}
          alt="Loubi Times"
          onMouseEnter={() =>
            dispatch(actionUpdateLoubiAirwaysSelectedModel(undefined))
          }
        />

        <TwoDProductHotSpot
          width={30.8}
          left={1.4}
          top={-20.4}
          rotate={8}
          model={AnimationProductModel.CLLOUBIAIRWAYS119}
        />
        <TwoDProductHotSpot
          width={33}
          left={0}
          top={-47}
          rotate={-31}
          model={AnimationProductModel._3215089H734}
        />

        <TwoDProductHotSpot
          width={41.8}
          left={18}
          top={-34.6}
          rotate={20}
          model={AnimationProductModel._3205189R251}
        />

        <TwoDProductHotSpot
          width={32}
          left={-39.5}
          top={9.6}
          model={AnimationProductModel.CLLOUBIAIRWAYS115}
        />
        <TwoDProductHotSpot
          width={36}
          left={-41}
          top={21}
          rotate={-179}
          model={AnimationProductModel.CLLOUBIAIRWAYS116}
        />
        <TwoDProductHotSpot
          width={23}
          left={11}
          top={46}
          model={AnimationProductModel._1215060W372}
        />

        <TwoDProductHotSpot
          width={29}
          height={8}
          left={16}
          top={-3}
          rotate={24}
          type="overlay"
          model={AnimationProductModel.CLLOUBIAIRWAYS119}
        />
        <TwoDProductHotSpot
          width={24}
          height={15}
          left={18}
          top={-28}
          rotate={-31}
          type="overlay"
          model={AnimationProductModel._3215089H734}
        />
        <TwoDProductHotSpot
          width={21.8}
          left={33}
          top={-9.6}
          rotate={20}
          height={16}
          type="overlay"
          model={AnimationProductModel._3205189R251}
        />

        <TwoDProductHotSpot
          width={36}
          height={8}
          left={-23}
          top={26}
          type="overlay"
          model={AnimationProductModel.CLLOUBIAIRWAYS115}
        />

        <TwoDProductHotSpot
          width={36}
          height={8}
          left={-23}
          top={39}
          type="overlay"
          model={AnimationProductModel.CLLOUBIAIRWAYS116}
        />

        <TwoDProductHotSpot
          width={44}
          left={24}
          top={57}
          height={15}
          type="overlay"
          model={AnimationProductModel._1215060W372}
        />
      </div>
      <style jsx>{`
        .loubiTimesContent :global(.img-spot.FOCUS) {
          opacity: 1 !important;
          filter: drop-shadow(0 0 0.2rem #9c0f21);
          z-index: 101 !important;
        }
        .loubiTimesContent :global(.img-spot.appear) {
          opacity: 0 !important;
        }
        .loubiTimesContent :global(.img-spot.appear.closing) {
          opacity: 1 !important;
        }
        .loubiTimesContent :global(.btn-spot) {
          z-index: 102 !important;
        }
        .inner {
          position: relative;
          width: 100vw;
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        :global(.in-meeting.MDLandscape) .inner {
          width: calc(100vw - ${MDLandscapeNormalSpec.contentArea.left}px) !important;
          height: calc(100vh - ${MDLandscapeNormalSpec.contentArea.top}px)  !important;
        }

        :global(.in-meeting.SMLandscape) .inner {
          width: calc(100vw - ${SMLandscapeNormalSpec.contentArea.left}px)  !important;
          height: calc(100vh - ${SMLandscapeNormalSpec.contentArea.top}px)  !important;
        }

        :global(.in-meeting.MDPortrait) .inner {
          height: calc(
            100vh -
              ${MDPortraitNormalSpec.contentArea.top +
              MDPortraitNormalSpec.contentArea.bottom}px
          );
        }

        :global(.in-meeting.SMPortrait) .inner {
          height: calc(
            100vh -
              ${SMPortraitNormalSpec.contentArea.top +
              SMPortraitNormalSpec.contentArea.bottom}px
          );
        }
        .img-wrapper {
          display: inline-block;
          position: relative;
        }
      `}</style>
    </div>
  );
  return (
    <>
      <NewsPaper
        remoteState={remoteState}
        updateRemoteState={updateRemoteState}
        delay={FADE_SPEED + 600}
        onClose={onCloseAnimation}
        close={remoteState?.close ?? animateClose}
        background={`${assetPath}/bg.jpg`}
        portraitBackground={`${assetPath}/bg-m.jpg`}
        coverTop={`${assetPath}/top_jp.jpg`}
        coverBottom={`${assetPath}/bottom_jp.jpg`}
        overlay={`${assetPath}/overlay.png`}
      >
        {content}
      </NewsPaper>
      <ContinueFlightButton
        onClick={() => {
          setAnimateClose(true);
        }}
      />
    </>
  );
};

export default LoubiTimes;

import { useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';

export const useRemoteCommon3DAnimationState = (animation: string) => {
  const remoteState = useSelector(
    (state: IMainState) =>
      (state.clientState.common3D?.animationState || {})[animation]
  );
  return remoteState;
};

export const useCommon3DState = () =>
  useSelector((state: IMainState) => state.clientState.common3D || {});

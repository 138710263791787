import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import {
  IVirtualBoutiqueConfig,
  IVirtualBoutiqueContact
} from '../../../interfaces';
import { isChatAvailable } from '../../../utils/stores';
import EmailSvgIcon from '../Icons/EmailSvgIcon';
import WhatsappSvgIcon from '../Icons/WhatsappSvgIcon';
import EmailContactButton from './EmailContactButton';
import WhatsappContactButton from './WhatsappContactButton';
import { useTranslation } from '../../../i18n';
import { IMainState } from '../../../interfaces/index';
import { actionShowContactPopup } from '../../../redux/actions';

const ContactPopupWithWhatsapp = ({
  config,
  additionalContactsInChatFilter
}: {
  config: IVirtualBoutiqueConfig;
  additionalContactsInChatFilter?: (c: IVirtualBoutiqueContact) => boolean;
}) => {
  const dispatch = useDispatch();
  const showDetails =
    useSelector(
      (state: IMainState) => state.clientState.vb?.openContactPopup
    ) || false;

  const [showChat, setShowChat] = React.useState(false);

  const contacts = config.contacts || [];

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const defaultChatMessage = t(config.defaultChatMessageKey);

  React.useEffect(() => {
    setShowChat(isChatAvailable(config));
  }, []);

  const mapContactToEmailButton = (
    contact: IVirtualBoutiqueContact,
    index: number
  ) => (
    <EmailContactButton contact={contact} key={`${contact.email}-${index}`}>
      <>
        <EmailSvgIcon />
        <span>
          {t('email')}{' '}
          {contact.nameKey
            ? t(`vb:${contact.nameKey}`)
            : contact.name.toUpperCase()}
        </span>
      </>
    </EmailContactButton>
  );

  const emailContacts = contacts.map(mapContactToEmailButton);

  const whatsappContacts = contacts
    .filter((c) => c.whatsappNumber)
    .map((contact: IVirtualBoutiqueContact) => (
      <div className="wabutton" key={contact.name}>
        <WhatsappContactButton
          contact={contact}
          defaultMessage={defaultChatMessage}
        >
          <>
            <WhatsappSvgIcon />
            <span>
              {t('vb:chat_with')}{' '}
              {contact.nameKey
                ? t(`vb:${contact.nameKey}`)
                : contact.name.toUpperCase()}
            </span>
          </>
        </WhatsappContactButton>
      </div>
    ));

  const chatContact = additionalContactsInChatFilter
    ? [
        ...whatsappContacts,
        contacts
          .filter(additionalContactsInChatFilter)
          .map(mapContactToEmailButton)
      ]
    : whatsappContacts;

  if (contacts.length === 0) return null;

  return (
    <div className="contact-container">
      <Transition in={showDetails} timeout={300} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`contact-list-popup fadein ${state}`}>
            {showChat ? chatContact : emailContacts}
            <button
              className="btn btn-dark btn-sm btn-close"
              type="button"
              onClick={() => dispatch(actionShowContactPopup(false))}
            >
              {t('vb:close')}
            </button>
          </div>
        )}
      </Transition>
      <style jsx>{`
        .contact-list-popup {
          background: #000;
          position: fixed;
          bottom: 0;
          right: 40px;
          width: 300px;
        }
        .contact-list-popup :global(a) {
          color: #fff;
          background: #343740;
          border-radius: 5px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          display:block;
          overflow:hidden;
          text-decoration: none;
          margin: 10px;
          text-align: ${isRtl ? 'right' : 'left'};
        }
        .contact-list-popup :global(a:hover) {
          background-color: #525252;
        }

        .contact-list-popup :global(a span){
          float: ${isRtl ? 'left' : 'right'};
          width:calc(100% - 34px);
        }

        .contact-list-popup :global(svg) {
          margin-right: ${isRtl ? '0' : '10px'};
          margin-left: ${isRtl ? '10px' : '0'};
          float: ${isRtl ? 'right' : 'left'}
          margin-top: -2px;
        }
        .btn-close {
          float: right;
          margin: 10px;
          font-size: 12px;
          letter-spacing: 1px;
        }

        .contact-list-popup :global(.wabutton svg) {
          fill: #fff;
          width:20px;
        }
        @media (max-width: 630px) {
          .contact-list-popup {
            right: calc(50vw - 150px);
          }
        }
      `}</style>
    </div>
  );
};

export default ContactPopupWithWhatsapp;

import React from 'react';
import * as THREE from 'three';
import {
  IAnimationProduct,
  ILoubiAirwaysAirplaneWindowAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import LJBackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/Buttons/LJBackToAnimationButton';
import { ModelEnvironment } from '../louboutin/background';
import {
  airplaneWindowProducts,
  animationAssetsBaseUrl,
  getCloudImage,
  modelCloudEnvironmentImagePath,
  modelWorkshopEnvironmentImagePath,
  skyBackgroundImagePath,
  windowTextures
} from './assets';

export const airplaneWindowImagePath = `${animationAssetsBaseUrl}airplane-window/background_JP/v2/airplane_window_background.png`;
const windowProduct: IAnimationProduct[] = [
  airplaneWindowProducts[0],
  airplaneWindowProducts[1],
  airplaneWindowProducts[2],
  airplaneWindowProducts[3],
  airplaneWindowProducts[4],
  airplaneWindowProducts[5]
];

let airportWindow = undefined;
let backDiv;
const isMobileOrTab = isUserOnMobile();
const touchType = {
  touchDown: 'touchDown',
  touchMove: 'touchMove',
  touchUp: 'touchUp'
};
const lowResModel = windowProduct.map((p) => p.lowPolyModelUrl);
const highResModel = windowProduct.map((p) => p.highPolyModelUrl);
let GLTFLoader, RGBELoader, TWEEN, ObjectControls;
interface Props {
  modelEnvironment: ModelEnvironment;
  remoteState?: ILoubiAirwaysAirplaneWindowAnimationState;
  updateRemoteState: (state: ILoubiAirwaysAirplaneWindowAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: () => void;
  viewOnly?: boolean;
}
class LubiAirplaneWindow extends React.Component<Props> {
  isDown: boolean;
  isTweening: boolean;
  isMouseDown: boolean;
  loadingNewItem: boolean;
  isChangingCenterItem: boolean;

  ScalBIG: number;
  maxScale: number;
  minScale: number;
  incScale: number;
  tweenTime: number;
  ScalSmall: number;
  initScale: number;
  hiddenScale: number;
  loadingCount: number;
  initDistance: number;
  neutralScale: number;
  zoomedInScale: number;
  xDiffPosition: number;
  centerItemIndex: number;
  neutralStateScale: number;

  controls: any;
  zoomModel: any;
  focusedModel: any;
  transparent_Mat: any;

  clouds: any[];
  itemsArray: any[];
  itemsChildForCatchingClick: any[];

  mouse: THREE.Vector2;
  mouseDownPos: THREE.Vector2;
  mouse_multiTuch: THREE.Vector2;

  rayCasterScale: THREE.Vector3;
  OtherItemPosition: THREE.Vector3;
  centerItemPosition: THREE.Vector3;

  scene: THREE.Scene;
  raycaster: THREE.Raycaster;
  renderer: THREE.WebGLRenderer;
  transparent_Plane: THREE.Mesh;
  camera: THREE.PerspectiveCamera;

  bagTextureMap: THREE.Texture;
  noorTextureMap: THREE.Texture;
  mariaTextureMap: THREE.Texture;
  relexTextureMap: THREE.Texture;
  papayaTextureMap: THREE.Texture;
  loopingaTextureMap: THREE.Texture;
  bagTextureNormalMap: THREE.Texture;
  dandelionTextureMap: THREE.Texture;
  noorTextureMetalMap: THREE.Texture;
  noorTextureNormalMap: THREE.Texture;
  mariaTextureMetalMap: THREE.Texture;
  papayaTextureMetalMap: THREE.Texture;
  relexTextureNormalMap: THREE.Texture;
  mariaTextureNormalMap: THREE.Texture;
  festividadeTextureMap: THREE.Texture;
  loopingaTextureMetalMap: THREE.Texture;
  dandelionTextureMetalMap: THREE.Texture;
  loopingaTextureNormalMap: THREE.Texture;
  festividadeTextureNormalMap: THREE.Texture;

  constructor(props) {
    super(props);
    airportWindow = this;

    this.isDown = false;
    this.isTweening = false;
    this.isMouseDown = false;
    this.loadingNewItem = true;
    this.isChangingCenterItem = false;

    const SCL = 0.12;
    this.initScale = 1;
    this.ScalBIG = 0.01;
    this.ScalSmall = 10;
    this.tweenTime = 800;
    this.neutralScale = 1;
    this.initDistance = 0;
    this.loadingCount = 0;
    this.centerItemIndex = 2;
    this.xDiffPosition = 2.5;
    this.minScale = 5 * SCL;
    this.maxScale = 16 * SCL;
    this.incScale = 0.5 * SCL;
    this.hiddenScale = 4 * SCL;
    this.zoomedInScale = 10 * SCL;
    this.neutralStateScale = 8 * SCL;

    this.clouds = Array(4);
    this.itemsArray = Array(windowProduct.length);
    this.itemsChildForCatchingClick = Array(windowProduct.length);

    this.mouse = new THREE.Vector2();
    this.mouseDownPos = new THREE.Vector2();
    this.mouse_multiTuch = new THREE.Vector2();

    this.rayCasterScale = new THREE.Vector3(1.5, 2, 2);
    this.centerItemPosition = new THREE.Vector3(0, 0.5, 0);
    this.OtherItemPosition = new THREE.Vector3(0, -1.3, -2);

    this.camera = undefined;
    this.renderer = undefined;
    this.controls = undefined;
    this.zoomModel = undefined;
    this.focusedModel = undefined;
    this.transparent_Plane = undefined;

    this.scene = new THREE.Scene();
    this.raycaster = new THREE.Raycaster();
    this.transparent_Mat = new THREE.MeshBasicMaterial({
      transparent: true,
      opacity: 0.01,
      color: 0x000000
    });
  }
  loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };

  componentDidMount() {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      30
    );
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.camera.position.set(0, 5, 12);
    this.camera.lookAt(new THREE.Vector3(0, 0.5, 0));

    this.loadingCount = 0;

    if (isMobileOrTab) {
      document.addEventListener('gesturestart', this.zoomDisable);
      document.addEventListener('gesturechange', this.zoomDisable);
      document.addEventListener('gestureend', this.zoomDisable);
    }
    this.loadThreeJsModulesAsync().then(() => {
      airportWindow.init();
    });
  }
  componentWillUnmount() {
    this.cleanUp();
  }
  componentDidUpdate() {
    if (this.props?.remoteState?.flag)
      this.remoteObjectUpdate(this.props?.remoteState);
  }
  loadingManager = new THREE.LoadingManager(() => {
    this.callback();
  });
  loadZoom = (id) => {
    if (isMobileOrTab) return;
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    gltfLoader.load(highResModel[ItemId], (gltf) => {
      if (!this.scene) return;
      const group = new THREE.Group();
      const root = gltf.scene;

      this.setScalePivot(root, ItemId);

      group.add(root);
      this.scene.add(group);
      group.scale.set(0.05, 0.05, 0.05);
      if (this.zoomModel) {
        this.loadNew(false, 0);
      }
      this.zoomModel = group;
      this.zoomModel.ItemId = ItemId;
      if (!this.focusedModel || this.focusedModel?.ItemId != ItemId) {
        this.loadNew(false, 0);
      }
    });
  };
  cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  item_Out_From_Screen = (model, _x, _y, _rx, _ry, _rz) => {
    this.isChangingCenterItem = true;
    model.visible = true;
    new TWEEN.Tween(model.position)
      .to(
        {
          x: _x,
          y: _y
        },
        this.tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.loadNewItemIfRequire(model);
      })
      .start();
    new TWEEN.Tween(model.rotation)
      .to(
        {
          x: Math.PI * _rx,
          y: Math.PI * _ry,
          z: Math.PI * _rz
        },
        this.tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };

  loadNewItemIfRequire = (model) => {
    if (this.itemsArray.length == 0) {
      return;
    }
    model.visible = false;
    this.isChangingCenterItem = false;
    if (this.loadingNewItem === false) {
      const leftItemFromCenter =
        (this.itemsArray.length + (this.centerItemIndex - 2)) %
        this.itemsArray.length;
      if (this.itemsArray[leftItemFromCenter] === undefined) {
        this.loadSneaker(null, leftItemFromCenter);
        this.loadingNewItem = true;
      }
      const rightItemFromCenter =
        (this.centerItemIndex + 2) % this.itemsArray.length;
      if (this.itemsArray[rightItemFromCenter] === undefined) {
        this.loadSneaker(null, rightItemFromCenter);
        this.loadingNewItem = true;
      }
    }
  };
  updateNewPosition = () => {
    this.showObjectInCenter();
    this.showLeftObject();
    this.showRightObject();
    if (this.controls !== undefined) {
      this.controls.disableVerticalRotation();
      this.controls.disableHorizontalRotation();
    }
    this.loadNew(false, 0);
    this.focusedModel = undefined;
  };

  showObjectInCenter = () => {
    if (this.itemsArray[this.centerItemIndex]) {
      this.resetPosition(
        this.itemsArray[this.centerItemIndex],
        this.neutralStateScale,
        this.centerItemPosition.x,
        this.centerItemPosition.y,
        this.centerItemPosition.z,
        0.0,
        0.0,
        -0.0
      );
      this.itemsArray[this.centerItemIndex].visible = true;
    }
  };

  showLeftObject = () => {
    const leftItem =
      (this.centerItemIndex + this.itemsArray.length - 1) %
      this.itemsArray.length;
    if (this.itemsArray[leftItem]) {
      this.resetPosition(
        this.itemsArray[leftItem],
        this.hiddenScale,
        -this.xDiffPosition,
        this.OtherItemPosition.y,
        this.OtherItemPosition.z,
        0.0,
        0.0,
        0.1
      );
      this.itemsArray[leftItem].visible = true;
    }
  };

  showRightObject = () => {
    const rightItem = (this.centerItemIndex + 1) % this.itemsArray.length;
    if (this.itemsArray[rightItem]) {
      this.resetPosition(
        this.itemsArray[rightItem],
        this.hiddenScale,
        this.xDiffPosition,
        this.OtherItemPosition.y,
        this.OtherItemPosition.z,
        0.0,
        0.0,
        -0.1
      );
      this.itemsArray[rightItem].visible = true;
    }
  };

  loadNew = (isLoad, id) => {
    if (isLoad) {
      if (this.zoomModel === undefined) this.loadZoom(id);
    } else {
      if (this.zoomModel === undefined) return;
      this.zoomModel.traverse((object) => {
        if (!object.isMesh) return;
        this.scene.remove(object);
        if (object?.geometry) {
          object.geometry.dispose();
          if (object?.material?.isMaterial) {
            this.cleanMaterial(object.material);
          } else {
            for (const material of object.material)
              this.cleanMaterial(material);
          }
        }
        object.geometry = undefined;
        object = undefined;
      });
      this.scene.remove(this.zoomModel);
      this.zoomModel = undefined;
    }
  };
  updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      this.camera === undefined ||
      this.renderer === undefined ||
      this.props.viewOnly
    )
      return;
    if (!this.controls) {
      this.controls = new ObjectControls(
        this.camera,
        this.renderer.domElement,
        model
      );
    } else {
      this.controls.setObjectToMove(model);
    }
    this.controls.setDistance(2, 400);
    this.controls.setZoomSpeed(0.5);
    this.controls.enableVerticalRotation();
    this.controls.enableHorizontalRotation();
    this.controls.disableZoom();
    this.controls.setRotationSpeed(0.1);
  };
  updateRemoteObjectControlForModel = () => {
    if (!this.focusedModel) return;
    this.props.updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      displayedProductIndices: [this.centerItemIndex],
      position: [
        this.focusedModel.position.x,
        this.focusedModel.position.y,
        this.focusedModel.position.z
      ],
      scale: [
        this.focusedModel.scale.x,
        this.focusedModel.scale.y,
        this.focusedModel.scale.z
      ],
      rotation: [
        this.focusedModel.rotation.x,
        this.focusedModel.rotation.y,
        this.focusedModel.rotation.z
      ]
    });
  };
  resetPosition = (model, _scale, _x, _y, _z, _rx, _ry, _rz) => {
    this.isTweening = true;
    if (!model) return;
    new TWEEN.Tween(model.rotation)
      .to(
        {
          x: Math.PI * _rx,
          y: Math.PI * _ry,
          z: Math.PI * _rz
        },
        this.tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: _scale,
          y: _scale,
          z: _scale
        },
        this.tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(model.position)
      .to(
        {
          x: _x,
          y: _y,
          z: _z
        },
        this.tweenTime
      )
      .onComplete(() => {
        this.isTweening = false;
      })
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  detectFirstTouchedObject = (type, objects) => {
    if (
      this.mouseDownPos.x === this.mouse.x &&
      this.mouseDownPos.y === this.mouse.y
    ) {
      const objs = objects.filter((model) => model);
      const intersects = this.raycaster.intersectObjects(objs);
      if (intersects.length) {
        return intersects[0];
      }
    }
  };
  onPinch = (ev) => {
    if (
      this.props.remoteState != undefined ||
      this.itemsArray[this.centerItemIndex] == undefined
    )
      return;
    if (ev.type === 'pinchstart') {
      this.initScale = this.itemsArray[this.centerItemIndex].scale.x || 1;
    }
    this.itemsArray[this.centerItemIndex].scale.set(
      this.initScale * ev.scale,
      this.initScale * ev.scale,
      this.initScale * ev.scale
    );
    if (this.itemsArray[this.centerItemIndex].scale.x > this.maxScale)
      this.itemsArray[this.centerItemIndex].scale.set(
        this.maxScale,
        this.maxScale,
        this.maxScale
      );
    if (this.itemsArray[this.centerItemIndex].scale.x < this.minScale)
      this.itemsArray[this.centerItemIndex].scale.set(
        this.minScale,
        this.minScale,
        this.minScale
      );
    this.updateRemoteObjectControlForModel();
  };

  setTouchValues = (e, type) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        this.mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          this.mouse_multiTuch.x =
            (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          this.mouse_multiTuch.y =
            -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            this.initDistance = this.mouse_multiTuch.distanceTo(this.mouse);
            this.onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = this.mouse_multiTuch.distanceTo(this.mouse);
            this.onPinch({
              type: 'pinchmove',
              scale: currentDistance / this.initDistance
            });
          }
        }
      }
    } else {
      const elem = this.renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      this.mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      this.mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      this.mouseDownPos.x = this.mouse.x;
      this.mouseDownPos.y = this.mouse.y;
      this.isMouseDown = true;
      if (e.touches?.length > 1) this.isMouseDown = false;
    }
    if (type === touchType.touchUp) {
      this.isMouseDown = false;
    }
    this.raycaster.setFromCamera(this.mouse, this.camera);
  };

  onwheelEvent = (event) => {
    if (
      this.props.remoteState != undefined ||
      this.itemsArray[this.centerItemIndex] == undefined
    )
      return;
    if (this.focusedModel != undefined) {
      let modelScale = this.itemsArray[this.centerItemIndex].scale.x;
      if (event.deltaY > 0) {
        if (modelScale < this.maxScale) modelScale += this.incScale;
      } else {
        if (modelScale > this.minScale) modelScale -= this.incScale;
      }
      this.itemsArray[this.centerItemIndex].scale.set(
        modelScale,
        modelScale,
        modelScale
      );
      this.updateRemoteObjectControlForModel();
    }
  };

  createRayCasterCatcher = () =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        this.rayCasterScale.x,
        this.rayCasterScale.y,
        this.rayCasterScale.z
      ),
      new THREE.MeshPhongMaterial({
        transparent: false,
        opacity: 0.001
      })
    );
  callback = () => {
    if (this.loadingCount === 0) {
      this.centerItemIndex = 4;
      this.itemsArray[this.centerItemIndex]?.scale.set(
        this.neutralStateScale,
        this.neutralStateScale,
        this.neutralStateScale
      );
      if (isMobileOrTab) {
        if (
          this.festividadeTextureMap?.image &&
          this.festividadeTextureNormalMap?.image
        )
          this.setmaterialFastivade(
            this.itemsArray[0],
            this.festividadeTextureMap,
            this.festividadeTextureNormalMap,
            undefined
          );
        if (
          this.noorTextureMap?.image &&
          this.noorTextureNormalMap?.image &&
          this.noorTextureMetalMap?.image
        )
          this.setmaterialFastivade(
            this.itemsArray[1],
            this.noorTextureMap,
            this.noorTextureNormalMap,
            this.noorTextureMetalMap
          );
        if (
          this.mariaTextureMap?.image &&
          this.mariaTextureNormalMap?.image &&
          this.mariaTextureMetalMap?.image
        )
          this.setmaterialFastivade(
            this.itemsArray[2],
            this.mariaTextureMap,
            this.mariaTextureNormalMap,
            this.mariaTextureMetalMap
          );
        if (
          this.loopingaTextureMap?.image &&
          this.loopingaTextureNormalMap?.image &&
          this.loopingaTextureMetalMap?.image
        )
          this.setmaterialFastivade(
            this.itemsArray[3],
            this.loopingaTextureMap,
            this.loopingaTextureNormalMap,
            this.loopingaTextureMetalMap
          );
        if (this.papayaTextureMetalMap?.image)
          this.setmaterialFastivade(
            this.itemsArray[4],
            undefined,
            undefined,
            this.papayaTextureMetalMap
          );
        if (this.relexTextureNormalMap?.image)
          this.setmaterialFastivade(
            this.itemsArray[5],
            undefined,
            this.relexTextureNormalMap,
            undefined
          );
        if (
          this.dandelionTextureMap?.image &&
          this.dandelionTextureMetalMap?.image
        )
          this.setmaterialFastivade(
            this.itemsArray[6],
            this.dandelionTextureMap,
            undefined,
            this.dandelionTextureMetalMap
          );
        if (this.bagTextureNormalMap?.image && this.bagTextureMap?.image)
          this.setmaterialFastivade(
            this.itemsArray[7],
            this.bagTextureMap,
            this.bagTextureNormalMap,
            undefined
          );
      }

      this.setPositionNewLoadedObjet();
      this.props.onReady();
    }
    this.loadingCount++;
  };

  setPositionNewLoadedObjet = () => {
    this.loadingNewItem = false;
    if (
      this.itemsArray[this.centerItemIndex] == undefined ||
      this.itemsArray[this.centerItemIndex - 1] == undefined ||
      this.itemsArray[this.centerItemIndex + 1] == undefined
    )
      return;
    this.itemsArray[this.centerItemIndex].position.set(
      this.centerItemPosition.x,
      this.centerItemPosition.y,
      this.centerItemPosition.z
    );
    this.itemsArray[this.centerItemIndex].visible = true;
    this.itemsArray[this.centerItemIndex - 1].position.set(
      -this.xDiffPosition,
      this.OtherItemPosition.y,
      this.OtherItemPosition.z
    );
    this.itemsArray[this.centerItemIndex - 1].visible = true;
    this.itemsArray[this.centerItemIndex].rotation.set(
      Math.PI * 0.0,
      Math.PI * 0.0,
      Math.PI * 0.0
    );
    this.itemsArray[this.centerItemIndex - 1].rotation.set(
      Math.PI * 0.0,
      Math.PI * 0.0,
      Math.PI * 0.1
    );
    this.itemsArray[this.centerItemIndex + 1].rotation.set(
      Math.PI * 0.0,
      Math.PI * 0.0,
      -Math.PI * 0.1
    );

    this.itemsArray[this.centerItemIndex + 1].position.set(
      this.xDiffPosition,
      this.OtherItemPosition.y,
      this.OtherItemPosition.z
    );
    this.itemsArray[this.centerItemIndex + 1].visible = true;
    this.itemsArray[this.centerItemIndex - 1].scale.set(
      this.hiddenScale,
      this.hiddenScale,
      this.hiddenScale
    );
    this.itemsArray[this.centerItemIndex + 1].scale.set(
      this.hiddenScale,
      this.hiddenScale,
      this.hiddenScale
    );
  };
  setScalePivot = (model, ItemId) => {
    const scl = this.ScalSmall * this.neutralScale;
    model.position.set(0, -0.6, 0);
    model.scale.set(scl, scl, scl);
    model.rotation.set(0, Math.PI * 0.5, 0);
    if (ItemId == 1 || ItemId == 3 || ItemId == 4) {
      model.position.set(0, -1.0, 0);
    }
  };
  setmaterialFastivade = (model, map, normal, matlic) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met?.name === 'Festividate_mat' && met?.map && met?.normalMap) {
          //FESTIVIDADE
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (
          met?.name === 'Solid_mat1' &&
          met?.map &&
          met?.normalMap &&
          met?.metalnessMap
        ) {
          //NOOR
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (
          met?.name === 'Base_mat4' &&
          met?.map &&
          met?.normalMap &&
          met?.metalnessMap
        ) {
          //MARIE ANNE
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met?.name === 'lambert15' && met?.map && met?.metalnessMap) {
          //LOOPINGA TOE PLUME
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met?.name === 'Papaya100_mat2' && met?.metalnessMap) {
          //PAPAGAYA LEVITA
          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met?.name === 'Relax_mat2' && met?.normalMap) {
          //RELAX MAX
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met?.name === 'DANDELION_mat' && met?.map && met?.metalnessMap) {
          //DANDELION SPIKES
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met?.name === 'Baguette_01_mat1' && met?.map) {
          //BAGUETTE_SMALL
          met.map.image = map.image;
          met.map.needsUpdate = true;
        }
        if (met?.name === 'Baguette_02_mat1' && met?.normalMap) {
          //BAGUETTE_SMALL
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
      }
    });
  };
  loadSneaker = (callback, id) => {
    const group = new THREE.Group();
    const gltfLoader = new GLTFLoader(this.loadingManager);
    const ItemId = id;
    gltfLoader.load(lowResModel[ItemId], (gltf) => {
      if (this.scene != undefined) {
        const root = gltf.scene;
        this.setScalePivot(root, ItemId);
        root.rotation.set(0, Math.PI * 0.5, 0);
        this.itemsChildForCatchingClick[ItemId] = this.createRayCasterCatcher();
        this.itemsChildForCatchingClick[ItemId].position.set(0, 0, 0);
        group.add(this.itemsChildForCatchingClick[ItemId]);
        this.itemsChildForCatchingClick[ItemId].visible = false;
        this.loadingNewItem = false;
        this.itemsArray[ItemId] = group;
        group.add(root);
        this.scene.add(group);
        group.visible = true;
        group.name = windowProduct[ItemId].modelName;
        group.scale.set(0.001, 0.001, 0.001);
        group.position.x = 4;
        group['ItemId'] = ItemId;
        if (callback) callback(id);
      }
    });
  };

  getModelBackground = (background: ModelEnvironment) => {
    switch (background) {
      case ModelEnvironment.WORKSHOP:
        return modelWorkshopEnvironmentImagePath;
      case ModelEnvironment.CLOUD:
        return modelCloudEnvironmentImagePath;
      default:
        return null;
    }
  };

  loadEnvironment = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }
    const textureLoader = new THREE.TextureLoader(this.loadingManager);
    if (isMobileOrTab) {
      // this.bagTextureMap = textureLoader.load(windowTextures.palomaBagMap);
      // this.bagTextureNormalMap = textureLoader.load(
      //   windowTextures.palomaBagNormalMap
      // );

      this.festividadeTextureMap = textureLoader.load(
        windowTextures.festividadeMap
      );
      this.festividadeTextureNormalMap = textureLoader.load(
        windowTextures.festividadeNormalMap
      );

      this.noorTextureMap = textureLoader.load(windowTextures.noorMap);
      this.noorTextureMetalMap = textureLoader.load(
        windowTextures.noorMetalMap
      );
      this.noorTextureNormalMap = textureLoader.load(
        windowTextures.noorNormalMap
      );

      this.mariaTextureMap = textureLoader.load(windowTextures.mariaMap);
      this.mariaTextureMetalMap = textureLoader.load(
        windowTextures.mariaMetalMap
      );
      this.mariaTextureNormalMap = textureLoader.load(
        windowTextures.mariaNormalMap
      );

      this.loopingaTextureMap = textureLoader.load(windowTextures.loopingaMap);
      this.loopingaTextureMetalMap = textureLoader.load(
        windowTextures.loopingaMetalMap
      );
      this.loopingaTextureNormalMap = textureLoader.load(
        windowTextures.loopingaNormalMap
      );

      // papayaTextureMap = textureLoader.load(windowTextures.palomaBagMap);
      this.papayaTextureMetalMap = textureLoader.load(
        windowTextures.papayaMetalMap
      );

      this.relexTextureMap = textureLoader.load(windowTextures.relexMap);
      this.relexTextureNormalMap = textureLoader.load(
        windowTextures.relexNormalMap
      );

      // this.dandelionTextureMap = textureLoader.load(
      //   windowTextures.dandelionMap
      // );
      // this.dandelionTextureMetalMap = textureLoader.load(
      //   windowTextures.dandelionMetalMap
      // );
    }

    const texture = textureLoader.load(airplaneWindowImagePath);
    texture.encoding = THREE.sRGBEncoding;
    const windowSize = isMobileOrTab ? 0.98 : 1.07;
    const airplaneWindow = new THREE.Mesh(
      new THREE.PlaneGeometry(20 * windowSize, 10 * windowSize),
      new THREE.MeshBasicMaterial({ transparent: true, map: texture })
    );
    airplaneWindow.rotation.set(-Math.PI * 0.115, 0, 0);
    airplaneWindow.position.set(0, windowSize - (isMobileOrTab ? 0.1 : 0), 1);
    this.scene.add(airplaneWindow);

    this.transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(22, 10),
      this.transparent_Mat
    );
    this.transparent_Plane.rotation.set(-Math.PI * 0.1246, 0, 0);
    this.transparent_Plane.position.set(0, 1.12, 2);
    this.scene.add(this.transparent_Plane);
    this.transparent_Plane.visible = true;
    const cloudBackground = new THREE.Mesh(
      new THREE.PlaneGeometry(40, 18),
      new THREE.MeshBasicMaterial({
        map: textureLoader.load(skyBackgroundImagePath)
      })
    );
    cloudBackground.rotation.set(-Math.PI * 0.1246, 0, 0);
    cloudBackground.position.set(0, -2, -8);
    this.scene.add(cloudBackground);

    for (let i = 0; i < 4; i++) {
      if (i < 2)
        this.clouds[i] = new THREE.Mesh(
          new THREE.PlaneGeometry(4, 2),
          new THREE.MeshBasicMaterial({
            opacity: 0.7,
            transparent: true,
            map: new THREE.TextureLoader().load(getCloudImage(i))
          })
        );
      else this.clouds[i] = this.clouds[i % 2].clone();
      this.scene.add(this.clouds[i]);
      this.clouds[i].position.set(-4.5 + 3 * i, -4 + i * 1.9, -5 + i * 0.71);
    }
    this.clouds[0].position.x = this.clouds[2].position.x;
    this.clouds[2].position.x = -4.5;

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = this.getModelBackground(
      this.props.modelEnvironment
    );
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (this.renderer && this.scene) {
            let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            this.scene.environment = envMap;
          }
        },
        undefined,
        () => null
      );
  };

  eventDown = (e) => {
    this.touchEvent(e, touchType.touchDown);
  };
  eventMove = (e) => {
    this.touchEvent(e, touchType.touchMove);
  };
  eventUp = (e) => {
    this.touchEvent(e, touchType.touchUp);
  };
  zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  addEventListeners = () => {
    document.addEventListener('mousedown', this.eventDown);
    document.addEventListener('touchstart', this.eventDown);

    document.addEventListener('mousemove', this.eventMove);
    document.addEventListener('touchmove', this.eventMove);

    document.addEventListener('mouseup', this.eventUp);
    document.addEventListener('touchend', this.eventUp);

    document.addEventListener('wheel', this.onwheelEvent);
    window.addEventListener('resize', this.onWindowResize, false);
  };

  touchEvent = (e, type) => {
    if (
      this.props.remoteState != undefined ||
      this.itemsArray[this.centerItemIndex] == undefined ||
      this.isTweening
    )
      return;
    this.setTouchValues(e, type);
    if (
      this.isMouseDown === true &&
      this.loadingNewItem === false &&
      !this.zoomModel &&
      !this.focusedModel &&
      this.isChangingCenterItem === false
    ) {
      const scl = window.innerWidth / window.innerHeight;
      if (Math.abs(this.mouseDownPos.x * scl - this.mouse.x * scl) > 0.2) {
        const leftItem_2 =
          (this.itemsArray.length + (this.centerItemIndex - 2)) %
          this.itemsArray.length;
        const leftItem_1 =
          (this.itemsArray.length + (this.centerItemIndex - 1)) %
          this.itemsArray.length;
        const rightItem_2 = (this.centerItemIndex + 2) % this.itemsArray.length;
        const rightItem_1 = (this.centerItemIndex + 1) % this.itemsArray.length;
        if (this.mouseDownPos.x < this.mouse.x) {
          if (this.itemsArray[leftItem_2] !== undefined) {
            this.updateReceiver(REMOTE_ACTION.CLICK_LEFT, leftItem_1);
            this.props.updateRemoteState({
              selectedProduct: this.itemsArray[this.centerItemIndex].name,
              flag: REMOTE_ACTION.CLICK_LEFT,
              displayedProductIndices: [this.centerItemIndex]
            });
          }
        } else {
          if (this.itemsArray[rightItem_2] !== undefined) {
            this.updateReceiver(REMOTE_ACTION.CLICK_RIGHT, rightItem_1);
            this.props.updateRemoteState({
              selectedProduct: this.itemsArray[this.centerItemIndex].name,
              flag: REMOTE_ACTION.CLICK_RIGHT,
              displayedProductIndices: [this.centerItemIndex]
            });
          }
        }
        this.isMouseDown = false;
      }
    }
    if (touchType.touchDown === type) {
      this.isDown = true;
    }
    if (touchType.touchUp === type && this.isChangingCenterItem == false) {
      this.isDown = false;
      const clickedModel = this.detectFirstTouchedObject(
        type,
        this.itemsChildForCatchingClick
      );
      if (
        this.itemsChildForCatchingClick[this.centerItemIndex] !==
          clickedModel?.object &&
        this.focusedModel !== undefined &&
        Math.abs(this.mouseDownPos.x - this.mouse.x) < 0.01 &&
        Math.abs(this.mouseDownPos.y - this.mouse.y) < 0.01 &&
        this.mouse.y < 0.56 &&
        this.mouse.y > -0.675
      ) {
        this.updateFocus(false);
        this.props.updateRemoteState({
          selectedProduct: this.itemsArray[this.centerItemIndex].name,
          flag: REMOTE_ACTION.UN_FOCUS,
          displayedProductIndices: [this.centerItemIndex]
        });
        return;
      }

      if (clickedModel && this.loadingNewItem === false) {
        if (
          this.itemsChildForCatchingClick[this.centerItemIndex] ===
            clickedModel.object &&
          this.focusedModel === undefined
        ) {
          this.updateFocus(true);
          this.props.updateRemoteState({
            selectedProduct: this.itemsArray[this.centerItemIndex].name,
            flag: REMOTE_ACTION.FOCUS,
            displayedProductIndices: [this.centerItemIndex]
          });
        }

        for (
          let i = 0;
          i < this.itemsChildForCatchingClick.length &&
          this.focusedModel === undefined;
          i++
        ) {
          if (this.itemsChildForCatchingClick[i] === clickedModel.object) {
            const leftItem_2 =
              (this.itemsArray.length + (this.centerItemIndex - 2)) %
              this.itemsArray.length;
            const leftItem_1 =
              (this.itemsArray.length + (this.centerItemIndex - 1)) %
              this.itemsArray.length;
            const rightItem_2 =
              (this.centerItemIndex + 2) % this.itemsArray.length;
            const rightItem_1 =
              (this.centerItemIndex + 1) % this.itemsArray.length;
            if (this.itemsArray[leftItem_2] !== undefined && i === leftItem_1) {
              this.updateReceiver(REMOTE_ACTION.CLICK_LEFT, i);

              this.props.updateRemoteState({
                selectedProduct: this.itemsArray[this.centerItemIndex].name,
                flag: REMOTE_ACTION.CLICK_LEFT,
                displayedProductIndices: [this.centerItemIndex]
              });
            }
            if (
              this.itemsArray[rightItem_2] !== undefined &&
              i === rightItem_1
            ) {
              this.updateReceiver(REMOTE_ACTION.CLICK_RIGHT, i);

              this.props.updateRemoteState({
                selectedProduct: this.itemsArray[this.centerItemIndex].name,
                flag: REMOTE_ACTION.CLICK_RIGHT,
                displayedProductIndices: [this.centerItemIndex]
              });
            }
          }
        }
      }
    }
    if (this.focusedModel != undefined && this.isDown == true) {
      this.updateRemoteObjectControlForModel();
    }
  };
  remoteObjectUpdate = (
    remoteState: ILoubiAirwaysAirplaneWindowAnimationState
  ) => {
    if (
      remoteState?.displayedProductIndices?.length &&
      remoteState?.flag &&
      this.itemsArray[this.centerItemIndex] &&
      this.loadingCount > 10
    ) {
      if (
        remoteState.flag == REMOTE_ACTION.CLICK_LEFT ||
        remoteState.flag == REMOTE_ACTION.CLICK_RIGHT
      )
        this.updateReceiver(
          remoteState.flag,
          remoteState.displayedProductIndices[0]
        );
      if (remoteState.flag == REMOTE_ACTION.FOCUS) {
        if (
          remoteState?.displayedProductIndices[0] &&
          remoteState?.displayedProductIndices[0] != this.centerItemIndex
        )
          this.setCenter(remoteState.displayedProductIndices[0]);
        this.updateFocus(true);
      }
      if (remoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        if (
          remoteState?.displayedProductIndices[0] &&
          remoteState?.displayedProductIndices[0] != this.centerItemIndex
        )
          this.setCenter(remoteState.displayedProductIndices[0]);
        this.updateFocus(false);
      }
      if (remoteState.flag == REMOTE_ACTION.MOUSE_CONTROL) {
        if (
          remoteState?.displayedProductIndices[0] &&
          remoteState?.displayedProductIndices[0] != this.centerItemIndex
        )
          this.setCenter(remoteState.displayedProductIndices[0]);
        if (this.itemsArray[this.centerItemIndex]) {
          this.itemsArray[this.centerItemIndex].position.set(
            remoteState.position[0],
            remoteState.position[1],
            remoteState.position[2]
          );
          this.itemsArray[this.centerItemIndex].scale.set(
            remoteState.scale[0],
            remoteState.scale[1],
            remoteState.scale[2]
          );
          this.itemsArray[this.centerItemIndex].rotation.set(
            remoteState.rotation[0],
            remoteState.rotation[1],
            remoteState.rotation[2]
          );
        }
      }
      remoteState.displayedProductIndices.length = 0;
      remoteState.flag = undefined;
    }
  };

  animate = () => {
    if (this.renderer == undefined || this.scene == undefined) {
      return;
    }
    if (
      this.isChangingCenterItem === false &&
      this.focusedModel === undefined &&
      this.isTweening == false
    ) {
      this.itemsArray
        .filter((model) => model)
        .forEach((model) => {
          if (
            model.position.x >= -this.xDiffPosition &&
            model.position.x <= this.xDiffPosition
          ) {
            model.rotation.y += 0.001;
          }
        });
    }
    this.clouds
      .filter((model) => model)
      .forEach((model) => {
        model.position.x += 0.003;
        if (model.position.x > 7) {
          model.position.x = -7;
          model.position.y = Math.random() * 6 - 4;
        }
      });

    if (
      this.zoomModel !== undefined &&
      this.itemsArray[this.centerItemIndex] !== undefined &&
      this.focusedModel?.ItemId === this.zoomModel?.ItemId
    ) {
      this.zoomModel.position.set(
        this.itemsArray[this.centerItemIndex].position.x,
        this.itemsArray[this.centerItemIndex].position.y,
        this.itemsArray[this.centerItemIndex].position.z
      );
      this.zoomModel.scale.set(
        this.itemsArray[this.centerItemIndex].scale.x,
        this.itemsArray[this.centerItemIndex].scale.y,
        this.itemsArray[this.centerItemIndex].scale.z
      );
      this.zoomModel.rotation.set(
        this.itemsArray[this.centerItemIndex].rotation.x,
        this.itemsArray[this.centerItemIndex].rotation.y,
        this.itemsArray[this.centerItemIndex].rotation.z
      );
      this.itemsArray[this.centerItemIndex].visible = false;
    } else {
      if (this.itemsArray[this.centerItemIndex])
        this.itemsArray[this.centerItemIndex].visible = true;
    }

    if (this.loadingCount > 0) this.loadingCount++;
    TWEEN.update();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.animate);
  };

  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  };

  cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };

  cleanUp = () => {
    this.itemsArray.length = 0;
    this.itemsChildForCatchingClick.length = 0;
    this.clouds.length = 0;
    if (this.scene != undefined) {
      this.scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          this.cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material'])
            this.cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      this.scene.children.forEach((model) => {
        this.scene.remove(model);
      });
    }
    {
      this.festividadeTextureMap = this.cleanTexture(
        this.festividadeTextureMap
      );
      this.festividadeTextureNormalMap = this.cleanTexture(
        this.festividadeTextureNormalMap
      );
      this.noorTextureMap = this.cleanTexture(this.noorTextureMap);
      this.noorTextureNormalMap = this.cleanTexture(this.noorTextureNormalMap);
      this.noorTextureMetalMap = this.cleanTexture(this.noorTextureMetalMap);
      this.mariaTextureMap = this.cleanTexture(this.mariaTextureMap);
      this.mariaTextureNormalMap = this.cleanTexture(
        this.mariaTextureNormalMap
      );
      this.mariaTextureMetalMap = this.cleanTexture(this.mariaTextureMetalMap);
      this.loopingaTextureMap = this.cleanTexture(this.loopingaTextureMap);
      this.loopingaTextureNormalMap = this.cleanTexture(
        this.loopingaTextureNormalMap
      );
      this.loopingaTextureMetalMap = this.cleanTexture(
        this.loopingaTextureMetalMap
      );
      this.papayaTextureMap = this.cleanTexture(this.papayaTextureMap);
      this.papayaTextureMetalMap = this.cleanTexture(
        this.papayaTextureMetalMap
      );
      this.relexTextureMap = this.cleanTexture(this.relexTextureMap);
      this.relexTextureNormalMap = this.cleanTexture(
        this.relexTextureNormalMap
      );
      this.dandelionTextureMap = this.cleanTexture(this.dandelionTextureMap);
      this.dandelionTextureMetalMap = this.cleanTexture(
        this.dandelionTextureMetalMap
      );
      this.bagTextureMap = this.cleanTexture(this.bagTextureMap);
      this.bagTextureNormalMap = this.cleanTexture(this.bagTextureNormalMap);
    }
    if (this.renderer != undefined) {
      this.renderer.dispose();
      this.renderer && this.renderer.renderLists.dispose();
    }
    if (this.controls) {
      this.controls.disableVerticalRotation();
      this.controls.disableHorizontalRotation();
      this.controls.disableZoom();
    }
    document.removeEventListener('touchstart', this.eventDown);
    document.removeEventListener('touchmove', this.eventMove);
    document.removeEventListener('touchend', this.eventUp);
    document.removeEventListener('mousedown', this.eventDown);
    document.removeEventListener('mousemove', this.eventMove);
    document.removeEventListener('mouseup', this.eventUp);
    document.removeEventListener('wheel', this.onwheelEvent);
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('gesturestart', this.zoomDisable);
    document.removeEventListener('gesturechange', this.zoomDisable);
    document.removeEventListener('gestureend', this.zoomDisable);
    this.controls = undefined;
    this.scene = undefined;
    this.renderer = undefined;
    this.camera = undefined;
  };

  findProductByName = (name) => windowProduct.find((p) => p.modelName === name);

  updateFocus = (isFocus) => {
    if (this.itemsArray[this.centerItemIndex] === undefined) return;
    if (isFocus == true) {
      this.setCenter(this.centerItemIndex);
      this.focusedModel = this.itemsArray[this.centerItemIndex];
      this.resetPosition(
        this.itemsArray[this.centerItemIndex],
        this.zoomedInScale,
        0,
        3,
        5,
        0,
        0.0,
        0
      );
      this.updateObjectControlForModel(this.itemsArray[this.centerItemIndex]);
      this.props.didSelectModel(
        this.findProductByName(this.itemsArray[this.centerItemIndex].name)
      );
      backDiv.style.display = 'block';
    } else {
      backDiv.style.display = 'none';
      this.focusedModel = undefined;
      if (this.controls) {
        this.controls.disableVerticalRotation();
        this.controls.disableHorizontalRotation();
      }
      this.resetPosition(
        this.itemsArray[this.centerItemIndex],
        this.neutralStateScale,
        this.centerItemPosition.x,
        this.centerItemPosition.y,
        this.centerItemPosition.z,
        0,
        0,
        0
      );
      this.props.didSelectModel(undefined);
    }
    const foc = isFocus;
    this.transparent_Plane.visible = true;
    this.transparent_Mat.opacity = foc == true ? 0.01 : 0.7;

    new TWEEN.Tween(this.transparent_Mat)
      .to({ opacity: foc === true ? 0.7 : 0.01 }, this.tweenTime)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        this.transparent_Plane.visible = foc;
      })
      .start();

    this.loadNew(isFocus, this.centerItemIndex);
  };
  onClickBack = () => {
    if (!this.props.viewOnly) {
      this.updateFocus(false);
      this.props.updateRemoteState({
        selectedProduct: this.itemsArray[this.centerItemIndex].name,
        flag: REMOTE_ACTION.UN_FOCUS,
        displayedProductIndices: [this.centerItemIndex]
      });
    }
  };
  setCenter = (center) => {
    if (!this.itemsArray[center]) return;
    this.centerItemIndex = center;
    this.itemsArray
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    const leftItem =
      (this.itemsArray.length + (center - 1)) % this.itemsArray.length;
    const rightItem = (center + 1) % this.itemsArray.length;
    this.itemsArray[center].position.set(
      this.centerItemPosition.x,
      this.centerItemPosition.y,
      this.centerItemPosition.z
    );
    this.itemsArray[center].scale.set(
      this.neutralStateScale,
      this.neutralStateScale,
      this.neutralStateScale
    );
    this.itemsArray[leftItem].position.set(
      -this.xDiffPosition,
      this.OtherItemPosition.y,
      this.OtherItemPosition.z
    );
    this.itemsArray[leftItem].scale.set(
      this.hiddenScale,
      this.hiddenScale,
      this.hiddenScale
    );
    this.itemsArray[rightItem].position.set(
      this.xDiffPosition,
      this.OtherItemPosition.y,
      this.OtherItemPosition.z
    );
    this.itemsArray[rightItem].scale.set(
      this.hiddenScale,
      this.hiddenScale,
      this.hiddenScale
    );
    this.itemsArray[center].visible =
      this.itemsArray[leftItem].visible =
      this.itemsArray[rightItem].visible =
        true;
  };

  updateReceiver = (action, newCenter) => {
    this.itemsArray
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });

    if (this.itemsArray[newCenter] === undefined) return;
    const leftItem_2 =
      (this.itemsArray.length + (newCenter - 2)) % this.itemsArray.length;
    const leftItem_1 =
      (this.itemsArray.length + (newCenter - 1)) % this.itemsArray.length;
    const rightItem_2 = (newCenter + 2) % this.itemsArray.length;
    const rightItem_1 = (newCenter + 1) % this.itemsArray.length;
    if (
      action == REMOTE_ACTION.CLICK_LEFT &&
      this.itemsArray[rightItem_2] !== undefined &&
      this.itemsArray[leftItem_1] !== undefined
    ) {
      this.item_Out_From_Screen(
        this.itemsArray[rightItem_2],
        2 * this.xDiffPosition,
        this.OtherItemPosition.y - 1,
        0.0,
        0.0,
        -0.1
      );
      this.itemsArray[leftItem_1].rotation.set(
        Math.PI * 0.0,
        Math.PI * 0.0,
        Math.PI * 0.2
      );
      this.itemsArray[leftItem_1].position.set(
        -2 * this.xDiffPosition,
        this.OtherItemPosition.y - 1,
        this.OtherItemPosition.z
      );
    }
    if (
      action == REMOTE_ACTION.CLICK_RIGHT &&
      this.itemsArray[leftItem_2] !== undefined &&
      this.itemsArray[rightItem_1] !== undefined
    ) {
      this.item_Out_From_Screen(
        this.itemsArray[leftItem_2],
        -2 * this.xDiffPosition,
        this.OtherItemPosition.y - 1,
        0.0,
        0.0,
        -0.1
      );
      this.itemsArray[rightItem_1].rotation.set(
        Math.PI * 0.0,
        Math.PI * 0.0,
        -Math.PI * 0.2
      );
      this.itemsArray[rightItem_1].position.set(
        2 * this.xDiffPosition,
        this.OtherItemPosition.y - 1,
        this.OtherItemPosition.z
      );
    }
    this.centerItemIndex = newCenter;
    this.updateNewPosition();
  };
  init = () => {
    this.loadEnvironment();
    for (let i = 0; i < windowProduct.length; i++) {
      this.loadSneaker(null, i);
    }
    if (!this.props.viewOnly) {
      this.addEventListeners();
    }
    this.animate();
  };
  render() {
    return (
      <div>
        <canvas id="c"></canvas>
        <p id="topright">
          <LJBackToAnimationButton onClick={this.onClickBack} />
        </p>

        <style jsx>
          {`
            #c {
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }
            a {
              position: fixed;
              top: 2vh;
              left: 2vh;
              font-size: 0.81em;
              text-align: left;
            }
          `}
        </style>
      </div>
    );
  }
}

export default LubiAirplaneWindow;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { IMainState, IShoppingCartItem } from '../../../interfaces';
import {
  getCartTotalPrice,
  getFormattedPrice
} from '../../../utils/shoppingCart';
import { getStoreFreeDeliveryText } from '../../storeComponentFactory';

interface Props {
  items: IShoppingCartItem[];
  extraFee?: number;
  extraFeeLabel?: string;
}

const CartTotal = ({ items, extraFee, extraFeeLabel }: Props) => {
  const store = useSelector((state: IMainState) => state.clientState?.store);
  const { t } = useTranslation();
  const { formattedTotal, formattedSubTotal, total, currency } =
    getCartTotalPrice(items, store?.countryCode, extraFee);

  if (!total)
    return (
      <table className="table">
        <tbody>
          <tr className="bordered ">
            <td colSpan={5} className="text-center td-empty">
              {t('vb:total_price_on_request')}
            </td>
          </tr>
        </tbody>
        <style jsx>{`
          .table {
            width: 100%;
          }
          .td-empty {
            font-weight: normal;
            text-transform: uppercase;
          }
        `}</style>
      </table>
    );

  return (
    <table className="table">
      <tbody>
        <tr className="bordered">
          <td className="text-right">{t('vb:subtotal')}</td>
          <td className="text-right td-price">{formattedTotal}</td>
        </tr>
        {(extraFee || extraFeeLabel) && (
          <tr>
            <td className="text-right">{extraFeeLabel}</td>
            <td className="text-right td-price">
              {extraFee
                ? getFormattedPrice(extraFee, currency)
                : getStoreFreeDeliveryText(store?.id, t)}
            </td>
          </tr>
        )}
        <tr>
          <td className="text-right td-total">{t('vb:total')}</td>
          <td className="text-right td-price td-total">{formattedSubTotal}</td>
        </tr>
      </tbody>
      <style jsx>{`
        .table {
          width: 100%;
        }
        :global(.isRtl) .text-right:first-child {
          text-align: left !important;
        }
        td {
          border: none;
          text-transform: uppercase;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .td-price {
          font-weight: normal;
          padding-left: 0;
          padding-right: 0;
          width: 150px;
        }
        .bordered td {
          border-top: 1px solid #a2a2a2;
          padding-top: 30px;
        }
        .td-total {
          font-size: 1.1em;
          padding-top: 4px;
        }
      `}</style>
    </table>
  );
};

export default CartTotal;

import React, { useState, useEffect } from 'react';

import { useTranslation } from '../../i18n';

const AutocompleteInput = ({
  list,
  value,
  placeholder,
  onSelect,
  translateOptions
}: {
  list: string[];
  value?: string;
  placeholder?: string;
  onSelect?: (val) => void;
  translateOptions?: boolean;
}) => {
  const { t } = useTranslation();
  const [showList, toggleShowList] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');

  useEffect(() => {
    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, []);

  useEffect(() => {
    setInput(translateOptions ? t(value) : value);
  }, [value]);

  const windowClick = () => {
    if (showList) {
      toggleShowList(false);
    }
  };

  const onChange = (event) => {
    const { value } = event.target;

    if (value && !showList) {
      toggleShowList(true);
    }

    if (!value && showList) {
      toggleShowList(false);
    }
    setInput(value);
    if (onSelect) {
      onSelect(value);
    }
  };

  const onSelectItem = (item: string) => {
    setInput(translateOptions ? t(item) : item);
    toggleShowList(false);

    if (onSelect) {
      onSelect(item);
    }
  };

  const FILTERED_LIST = list.filter((i) => {
    const item = translateOptions ? t(i) : i;
    return item.match(new RegExp(input, 'i'));
  });
  const inputValue = translateOptions ? t(input) : input;

  return (
    <div className="wrapper">
      <input
        value={inputValue}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="off"
      />
      {showList && FILTERED_LIST.length > 0 && (
        <div className="dropdown">
          {FILTERED_LIST.map((item, index) => (
            <div
              key={index}
              className={`dropdown-item ${input === item && 'selected'}`}
              onClick={() => onSelectItem(item)}
            >
              {translateOptions ? t(item) : item}
            </div>
          ))}
        </div>
      )}

      <style jsx>{`
        .wrapper {
          position: relative;
          width: 100%;
        }
        .dropdown {
          position: absolute;
          left: 0;
          right: 0;
          top: 24px;
          max-height: 200px;
          overflow: scroll;
          border: 1px solid rgb(223, 223, 223);
          background: #fff;
          z-index: 1;
        }
        .selected {
          background: rgba(0, 0, 0, 0.2);
        }
      `}</style>
    </div>
  );
};

export default AutocompleteInput;

import React from 'react';

const GenericDisconnected = ({ onReconnect }: { onReconnect: () => void }) => {
  return (
    <div className="inner">
      <div className="inner">
        <p className="chopard-sans">Experience has been disconnected</p>
        <button
          className="btn btn-action btn-outline-light"
          onClick={onReconnect}
        >
          RECONNECT
        </button>
      </div>
      <style jsx>{`
        .inner {
          margin: auto;
          color: #fff;
        }
        .btn {
          width: 200px;
        }
      `}</style>
    </div>
  );
};

export default GenericDisconnected;

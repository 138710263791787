import React from 'react';
import { IStore, IVirtualBoutiqueConfig } from '../../../interfaces';
import { getVBCustomComponent } from '../../storeComponentFactory';

const CanvasBasedExperience = ({
  store
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
}) => {
  return <div>{getVBCustomComponent(store?.id)}</div>;
};

export default CanvasBasedExperience;

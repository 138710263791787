import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { LoubiAirwaysPopupContentType } from '../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysPopupContentType } from '../../../redux/customActions/loubiAirways';
import {
  DID_CLICK_EXPLORE_MY_OWN,
  DID_CLICK_GUIDED_TOUR
} from '../../../utils/constants';
import { LoubiAirFontFamily } from '../../BrandStyle/LouboutinStyle';
import { useLoubiAirwaysState } from '../../hooks/loubiAirways';

const LoubiAirwaysJapanLanding = ({
  logoUrl,
  showAppointmentForm,
  onDismiss
}: {
  logoUrl?: string;
  showAppointmentForm: () => void;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation();
  const loubiFont = LoubiAirFontFamily();
  const dispatch = useDispatch();
  const { landingVideo } = useLoubiAirwaysState();

  const welcomeMessage = (
    <div className="welcome-container entry-selection">
      <div className="content">
        <h1>
          <span>{t('vb:welcome_to_loubiairways')}</span>
          <span>{t('vb:trunk_show')}</span>
        </h1>
        <p>{t('vb:select_following_options')}</p>
      </div>
      <div className="btn-group welcome-btn">
        <button
          type="button"
          className="open-contact"
          onClick={() => {
            showAppointmentForm();
            logEvent(DID_CLICK_GUIDED_TOUR);
          }}
        >
          {t('vb:virtual_walkthrough_with_sales_assistant')}
        </button>
        <button
          type="button"
          className="explore-own"
          onClick={() => {
            onDismiss();
            logEvent(DID_CLICK_EXPLORE_MY_OWN);
            dispatch(
              actionUpdateLoubiAirwaysPopupContentType(
                LoubiAirwaysPopupContentType.WELCOME_INSTRUCTION
              )
            );
          }}
        >
          {t('vb:option_explore_on_my_own')}
        </button>
      </div>
      <style jsx>
        {`
          .btn-group {
            display: block;
            width: 600px !important;
          }

          button {
            background: #cf152d !important;
            border: none !important;
            color: #f3f3d1;
            font-family: ${LoubiAirFontFamily()};
            margin: auto;
            margin-top: 10px;
            border-radius: 20px;
            width: calc(50% - 30px) !important;
            font-weight: 600;
          }

          .explore-own {
            padding: 0 25px;
          }

          h1 {
            text-transform: uppercase;
          }

          h1 > span {
            display: block;
          }

          @media (min-width: 1024px) {
            .btn-group {
              display: flex;
            }
            h1 > span {
              display: inline;
            }
            h1 > span:last-child {
              display: block;
            }
          }

          @media (max-width: 1024px) {
            .btn-group {
              display: block;
              width: auto !important;
            }

            button {
              width: 100% !important;
              display: block;
            }
          }
        `}
      </style>
    </div>
  );

  if (landingVideo?.hidden) {
    return (
      <div className="landing-page">
        <div className="inner">
          {logoUrl && <img className="logo" src={logoUrl} />}
          {welcomeMessage}
        </div>
        <style jsx>
          {`
            .landing-page {
              background: url('https://assets.inspify.com/loubiairways/pages/v2/cloud.jpg')
                center center no-repeat;
              background-size: cover;
              background-color: #000000;
              justify-content: center;
            }
            .inner {
              padding: 80px 100px !important;
              font-size: 1em;
              font-family: ${loubiFont};
              background-color: #f3f3d1;
              box-shadow: 8px 8px #346168;
              border-radius: 40px;
              margin: 20px 10px;
              color: #346168 !important;
            }

            @media (max-width: 1024px) {
              .inner {
                padding: 20px !important;
              }
            }
          `}
        </style>
      </div>
    );
  }

  return null;
};

export default LoubiAirwaysJapanLanding;

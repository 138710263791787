import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from '../../../interfaces';
import { useStateRef } from '../../hooks/common';
import { PSEventType } from '../PSInterface';
import {
  mbAssetsBaseUrl,
  CarPart,
  CarPartData,
  selectArray,
  menuContent,
  menuData,
  MenuItem,
  navigation,
  camData,
  CameraView,
  GET_CURRENT_MODIFICATION
} from './MercedesConfig';
const ModificationMapper = {
  PAINT: CarPart.Paint,
  RIM: CarPart.Rim,
  SEAT: CarPart.Seat,
  INTERIOR: CarPart.Interior
};
import { mercedesBenzSiteId, inspifyBrandId } from '../../../config';
import { actionOnCommandMessage } from '../utils/commandHandler';

const MercedesCustomComponent = ({
  onSendCommand
}: {
  onSendCommand: (command: string) => void;
}) => {
  const [isMOpen, setIsMOpen] = React.useState(false);
  const [isPlay, setIsPlay] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState<MenuItem>(null);
  const [isMute, setIsMute] = React.useState(false);
  const [selectedPart, setSelectedPart, selectedPartRef] = useStateRef(
    CarPart.Stop
  );
  const [dropUpOpen, setDropUpOpen] = React.useState(false);
  const [modification, setModification, modificationRef] =
    useStateRef(selectArray);
  const [modname, setModname] = React.useState('');
  const [camPos, setCamPos] = React.useState<string>(CameraView.Exterior);
  const meeting =
    useSelector((state: IMainState) => state.clientState?.meeting) || {};
  const isPopupOpen = useSelector(
    (state: IMainState) => state.clientState?.vb?.popup?.open
  );
  const isInMeeting = !!meeting.meetingId;
  const isPresenter = !isInMeeting || (isInMeeting && meeting.isPresenter);
  const dispatch = useDispatch();
  const toggleDropUp = () => setDropUpOpen(!dropUpOpen);

  // Updates the modification and UI
  const psModificationToState = (mod: {
    [key: string]: { focus: boolean; selected: number };
  }) => {
    // Replace current modification data with JSON data
    let newModification = modification;
    Object.entries(mod || {}).forEach(([key, value]) => {
      const carPart = ModificationMapper[key];
      newModification = [
        ...modification.slice(0, carPart),
        (modification[carPart] = value.selected),
        ...modification.slice(carPart + 1)
      ];
      // Set UI based on focus
      if (value.focus) {
        const modName = Object.values(menuContent[MenuItem.Configure]).find(
          (item) => item.number === carPart
        )?.content[value.selected]?.label;
        setModname(modName);
        setSelectedPart(carPart);
        setIsMOpen(true);
      }
    });
    setModification(newModification);
  };

  const handleUEMessage = (e) => {
    if (e.data?.source !== 'pixelstreaming') return;
    const { message, type } = e.data;
    if (type !== PSEventType.UE_EVENT) return;

    // Check if video is playing
    const shouldHideCarpart = ['VIDEO_ONGOING', 'VIDEO_ENDED'].includes(
      message
    );
    if (shouldHideCarpart) {
      const _isPlay = message === 'VIDEO_ONGOING';
      setIsPlay(_isPlay);
      setSelectedPart(_isPlay ? CarPart.Play : CarPart.Stop);
      setModname('');
      return;
    }
    if ('FOCUS_DEFAULT'.includes(message) && !isPresenter) {
      setDropUpOpen(false);
      // By clicking navcol1 UI, we trigger navcol2 logic
      setSelectedMenu(selectedMenu ? null : MenuItem.Configure);
      setSelectedPart(CarPart.Stop);
      setIsMOpen(false);
    }

    // Update UI based on JSON message in UE
    try {
      const mod = JSON.parse(message);
      if (mod.PAINT || mod.RIM || mod.SEAT || mod.INTERIOR) {
        psModificationToState(mod);
      }
    } catch (_) {
      Object.values(menuContent[MenuItem.Configure]).some((item) => {
        if (CarPartData[item.number]?.uecmd === message) {
          setSelectedMenu(MenuItem.Configure);
          setSelectedPart(item.number);
          const modName =
            item.content[modificationRef.current[item.number]]?.label;
          setModname(modName);
          setIsMOpen(true);
          return true;
        }
        return Object.entries(item.content).some(([key, value]: any) => {
          if (message === value.uecmd) {
            setSelectedPart(item.number);
            setModname(value.label);
            const currentModification = modificationRef.current;
            setModification(() => [
              ...currentModification.slice(0, selectedPartRef.current),
              (currentModification[selectedPartRef.current] = key),
              ...currentModification.slice(selectedPartRef.current + 1)
            ]);
            return true;
          }
        });
      });
    }
  };

  // Update UI after every render
  React.useEffect(() => {
    setSelectedMenu(MenuItem.Configure);
    window.addEventListener('message', handleUEMessage);
    onSendCommand(GET_CURRENT_MODIFICATION);
    return () => {
      window.removeEventListener('message', handleUEMessage);
    };
  }, [isPresenter]);

  const getMultilinesText = (text: string) => {
    const lines = text.split('\n');
    const spans = lines.map((line, index) => (
      <span key={index}>
        {line}
        {index !== line.length - 1 && <br />}
      </span>
    ));
    return {
      component: spans,
      text: lines.join(' ')
    };
  };
  return (
    <>
      <div /* This is only shown when in mobile view. */
        className={`mobile ${isMOpen ? 'active' : ''} ${
          isPlay ? 'playing' : ''
        }`}
      >
        {isPresenter && (
          <menu /* Vertical main menu*/>
            {!isPlay && (
              <button /* Show/Hide UI Button */
                className={`btn btn-sm ${isMOpen ? 'active' : ''}`}
                onClick={() => {
                  // This will show or hide mobile UI
                  setIsMOpen(!isMOpen);
                  setDropUpOpen(false);
                  onSendCommand('FOCUS_DEFAULT');
                }}
              >
                <img
                  className={`icons-filter ${isMOpen ? 'active' : ''}`}
                  src={mbAssetsBaseUrl + '/Icon_HiddenMenu.svg'}
                />
              </button>
            )}
            {isPlay && (
              <button /* Show/Hide UI Button will be replaced with stop button when video is playing */
                className={`btn btn-sm ${isMOpen ? 'active' : ''}`}
                onClick={() => {
                  setDropUpOpen(false);
                  onSendCommand('VID_STOP');
                  setSelectedMenu(null);
                }}
              >
                <img
                  className={`icons-filter`}
                  src={mbAssetsBaseUrl + '/Icon_Stop.svg'}
                />
              </button>
            )}
            {isMOpen && ( // The UI hidden by Show/Hide button
              <>
                {!isPlay && (
                  <button /*Configure Button */
                    onClick={() => {
                      setDropUpOpen(false);
                      // By clicking navcol1 UI, we trigger navcol2 logic
                      setSelectedMenu(selectedMenu ? null : MenuItem.Configure);
                      setSelectedPart(CarPart.Stop);
                      onSendCommand('FOCUS_DEFAULT');
                    }}
                    className={`btn btn-sm ${
                      selectedMenu === MenuItem.Configure ? 'active' : ''
                    }`}
                  >
                    <img
                      className={`icons-filter ${
                        selectedMenu === MenuItem.Configure ? 'active' : ''
                      }`}
                      src={menuData[MenuItem.Configure].icon}
                      alt={menuData[MenuItem.Configure].label}
                      title={menuData[MenuItem.Configure].label}
                    />
                  </button>
                )}
                <button /*Video Button (mobile)*/
                  onClick={() => {
                    setDropUpOpen(false);
                    // By clicking navcol1 UI, we trigger navcol2 logic
                    setSelectedMenu(MenuItem.Video);
                    // Acts as a play or pause button in mobile version

                    onSendCommand('VID_PLAY');
                    console.log('Sending command to UE: VID_PLAY');
                  }}
                  className={`btn btn-sm ${
                    selectedMenu === MenuItem.Video ? 'active' : ''
                  }`}
                >
                  <img
                    className={`icons-filter ${
                      selectedMenu === MenuItem.Video ? 'active' : ''
                    }`}
                    src={menuData[MenuItem.Video].icon}
                    alt={menuData[MenuItem.Video].label}
                    title={menuData[MenuItem.Video].label}
                  ></img>
                </button>
                <button /*Mute/Unmute Button */
                  onClick={() => {
                    setIsMute(!isMute);
                    console.log('AUDIO IS NOW: ', isMute ? 'Muted' : 'Unmuted');
                    onSendCommand(isMute ? 'AUDIO_UNMUTE' : 'AUDIO_MUTE');
                    setDropUpOpen(false);
                  }}
                  className={`btn btn-sm`}
                >
                  {
                    // Swap out the mute button UI on toggle
                    isMute ? (
                      <img
                        className={`icons-filter`}
                        src={mbAssetsBaseUrl + '/Icon_SoundsOff.svg'}
                      />
                    ) : (
                      <img
                        className={`icons-filter`}
                        src={mbAssetsBaseUrl + '/Icon_SoundsOn.svg'}
                      />
                    )
                  }
                </button>
                {!isPlay && (
                  <button /*Camera Button */
                    onClick={toggleDropUp}
                    className={`btn btn-sm`}
                  >
                    <img
                      className={`icons-filter ${dropUpOpen ? 'active' : ''}`}
                      src={mbAssetsBaseUrl + '/Icon_Camera.svg'}
                    />
                  </button>
                )}
              </>
            )}
            {
              // The camera view drop options
              isMOpen && dropUpOpen && (
                <ul className="drop-up">
                  {
                    // Drop down for all different camera view available
                    Object.entries(camData).map(([key, value]) => (
                      <li
                        // Set the camera view on click
                        className={`drop-up-item ${
                          key == camPos ? 'active' : ''
                        }`}
                        key={key}
                        onClick={() => {
                          setCamPos(key);
                          console.log('Camera is viewing:', value.uecmd);
                          onSendCommand(value.uecmd);
                          setDropUpOpen(false);
                        }}
                      >
                        {value.label}
                      </li>
                    ))
                  }
                </ul>
              )
            }
          </menu>
        )}
        {!dropUpOpen && (isMOpen || !isPresenter) && (
          <div className="submenu">
            {isPresenter && (
              <div className="nav-col2" /* Paint, Wheel, Interior, Seat layer*/>
                {
                  // Show nav2col only when an UI in navcol1 is selected
                  selectedMenu == MenuItem.Configure &&
                    // Here we detect what is selected in menu and iterate through selected menu
                    menuContent[selectedMenu].map((submenu: any) => (
                      <button /* Selection of different part of the Car */
                        key={submenu.title}
                        onClick={() => {
                          setDropUpOpen(false);
                          // When UI here is selected trigger popup UI
                          setSelectedPart(submenu.number);
                          // This will update camera to look at the car part
                          onSendCommand(CarPartData[submenu.number].uecmd);
                          setCamPos('');
                          // This is for updating Modification name in popup
                          submenu.content &&
                            Object.entries(submenu.content).map(
                              ([key, value]: any) =>
                                key == modification[submenu.number] &&
                                setModname(value.label)
                            );
                        }}
                        className={`btn btn-sm`}
                      >
                        <h6
                          className={`reveal ${
                            selectedPart === submenu.number ? 'active' : ''
                          }`}
                        >
                          {submenu.title}
                        </h6>
                        <img
                          className={`icons-filter ${
                            selectedPart === submenu.number ? 'active' : ''
                          }`}
                          src={submenu.icon}
                          alt={submenu.title}
                          title={submenu.title}
                        />
                      </button>
                    ))
                }
              </div>
            )}
            {selectedPart != 0 && selectedPart < 5 && (
              <>
                <div className="linelayer">
                  <div className="whiteline"></div>
                </div>
                <div className="popup">
                  {
                    // Only show if configure button is clicked
                    selectedMenu == MenuItem.Configure &&
                      menuContent[MenuItem.Configure].map(
                        (submenu) =>
                          // Here we only want to extract the content from the selected vehicle parts
                          submenu.number == selectedPart &&
                          Object.entries(submenu.content).map(
                            ([key, value]: any) => (
                              <button /* All the different modifications */
                                key={key}
                                className={`modifiers-btn  ${
                                  modification[selectedPart] == key
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => {
                                  setDropUpOpen(false);
                                  onSendCommand(value.uecmd);
                                  setModname(value.label);
                                  setModification((existingItems) => {
                                    return [
                                      ...existingItems.slice(0, selectedPart),
                                      (existingItems[selectedPart] = key),
                                      ...existingItems.slice(selectedPart + 1)
                                    ];
                                  });
                                }}
                              >
                                <img
                                  className={`modifiers`}
                                  src={value.icon}
                                  alt={value.label}
                                  title={value.label}
                                />
                              </button>
                            )
                          )
                      )
                  }
                </div>
                <div className="linelayer">
                  <div className="whiteline"></div>
                </div>
                <div className="linelayer">
                  <h6 className="mod_text">{modname}</h6>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <footer /* This footer is only visible for desktop view*/
        className={`${isPresenter ? 'presenter' : ''}${
          !isPresenter && isMOpen ? 'active' : ''
        }${isPopupOpen ? ' has-popup' : ''}`}
      >
        <div /* The bar above the nav bar that serve as a popup*/
          className="popup"
        >
          {
            // Only show if configure button is clicked
            selectedMenu == MenuItem.Configure &&
              menuContent[MenuItem.Configure].map(
                (submenu) =>
                  // Here we only want to extract the content from the selected vehicle parts
                  submenu.number == selectedPart &&
                  Object.entries(submenu.content).map(([key, value]: any) => (
                    <button /* Selection of different part of the Car */
                      key={key}
                      className={`modifiers-btn  ${
                        modification[selectedPart] == key ? 'active' : ''
                      }`}
                      onClick={() => {
                        setDropUpOpen(false);
                        onSendCommand(value.uecmd);
                        setModname(value.label);
                        setModification((existingItems) => {
                          return [
                            ...existingItems.slice(0, selectedPart),
                            (existingItems[selectedPart] = key),
                            ...existingItems.slice(selectedPart + 1)
                          ];
                        });
                      }}
                    >
                      <img
                        className={`modifiers`}
                        src={value.icon}
                        alt={value.label}
                        title={value.label}
                      />
                    </button>
                  ))
              )
          }

          {selectedPart != 0 && selectedPart < 5 && (
            <div className="linelayer">
              <h6 className="left"></h6>
              <div className="whiteline"></div>
              <h6 className="right">{modname}</h6>
            </div>
          )}
        </div>
        {isPresenter && (
          <nav /* There are 3 columns in nav, we manipulate it using flex */>
            {!isPlay && (
              <div
                className="navcol1 align-items-end" /*The leftmost UI in nav*/
              >
                {navigation.map((item) => (
                  <button /*Configure or Video */
                    key={item}
                    onClick={() => {
                      setDropUpOpen(false);
                      setSelectedMenu((state) =>
                        state === item ? null : item
                      );
                      setSelectedPart(CarPart.Stop);
                      if (selectedMenu === MenuItem.Configure) {
                        onSendCommand('FOCUS_DEFAULT');
                      }
                    }}
                    className={`btn btn-sm ${
                      selectedMenu === item ? 'active' : ''
                    }`}
                  >
                    <h6 className="hide">{menuData[item].label}</h6>
                    <img
                      className={`icons-filter ${
                        selectedMenu === item ? 'active' : ''
                      }`}
                      src={menuData[item].icon}
                      alt={menuData[item].label}
                      title={menuData[item].label}
                    ></img>
                  </button>
                ))}
              </div>
            )}
            <div className="navcol2 align-items-end" /*The center UI in nav*/>
              {
                // Show nav2col only when an UI in navcol1 is selected
                selectedMenu &&
                  // Here we detect what is selected in menu and iterate through selected menu
                  menuContent[selectedMenu].map((submenu: any) => {
                    const title = getMultilinesText(submenu.title);
                    return (
                      // Hide some of the UI if video is playing
                      (isPlay ? submenu.number <= CarPart.Stop : true) && (
                        <button
                          key={submenu.title}
                          onClick={() => {
                            setDropUpOpen(false);
                            setSelectedPart(submenu.number);
                            onSendCommand(CarPartData[submenu.number].uecmd);
                            setCamPos('');
                            submenu.content &&
                              Object.entries(submenu.content).map(
                                ([key, value]: any) =>
                                  key == modification[submenu.number] &&
                                  setModname(value.label)
                              );
                            submenu.action &&
                              actionOnCommandMessage(
                                submenu.action,
                                mercedesBenzSiteId,
                                inspifyBrandId,
                                dispatch,
                                onSendCommand
                              );
                          }}
                          className={`btn btn-sm`}
                        >
                          <h6
                            className={`reveal ${
                              selectedPart === submenu.number ? 'active' : ''
                            }`}
                          >
                            {title.component}
                          </h6>
                          <img
                            className={`icons-filter ${
                              selectedPart === submenu.number ? 'active' : ''
                            }`}
                            src={submenu.icon}
                            alt={submenu.title}
                            title={title.text}
                          />
                        </button>
                      )
                    );
                  })
              }
            </div>
            {!isPlay && (
              <div
                className="navcol3 align-items-end" /* The rightmost UI in nav*/
              >
                {isPresenter && (
                  <>
                    <button /* The mute button */
                      onClick={() => {
                        setDropUpOpen(false);
                        setIsMute(!isMute);
                        onSendCommand(isMute ? 'AUDIO_UNMUTE' : 'AUDIO_MUTE');
                      }}
                      className={`btn btn-sm`}
                    >
                      <h6 className="hide">Audio</h6>
                      {
                        // Swap out the mute button UI on toggle
                        isMute ? (
                          <img
                            className={`icons-filter`}
                            src={mbAssetsBaseUrl + '/Icon_SoundsOff.svg'}
                          />
                        ) : (
                          <img
                            className={`icons-filter`}
                            src={mbAssetsBaseUrl + '/Icon_SoundsOn.svg'}
                          />
                        )
                      }
                    </button>

                    <button /*The cameraview button */
                      onClick={toggleDropUp}
                      className={`btn btn-sm`}
                    >
                      <h6 className="hide">Cam</h6>
                      <img
                        className={`icons-filter ${dropUpOpen ? 'active' : ''}`}
                        src={mbAssetsBaseUrl + '/Icon_Camera.svg'}
                      />
                    </button>

                    {dropUpOpen && (
                      <ul className="drop-up">
                        {
                          // Drop down for all different camera view available
                          Object.entries(camData).map(([key, value]) => (
                            <li
                              // Set the camera view on click
                              className={`drop-up-item ${
                                key == camPos ? 'active' : ''
                              }`}
                              key={key}
                              onClick={() => {
                                setCamPos(key);
                                console.log('Camera is viewing:', value.uecmd);
                                onSendCommand(value.uecmd);
                                setDropUpOpen(false);
                              }}
                            >
                              {value.label}
                            </li>
                          ))
                        }
                      </ul>
                    )}
                  </>
                )}
              </div>
            )}
          </nav>
        )}
      </footer>

      <div className="branding">
        <img className="logo" src={mbAssetsBaseUrl + '/Logo.svg'}></img>

        <span className="font-merc d-flex align-items-end">
          Mercedes-AMG EQE Saloon
        </span>

        <img className="eqe" src={mbAssetsBaseUrl + '/eqe 350.svg'}></img>
      </div>

      <style jsx>{`
        .mod_text {
          width: 280px;
          text-align: left;
        }
        .hide {
          opacity: 0;
        }
        .reveal {
          opacity: 0;
        }
        .reveal.active {
          opacity: 1;
        }
        .icons-filter {
          width: 50px;
          filter: invert(0%) sepia(79%) saturate(2476%) hue-rotate(180deg)
            brightness(118%) contrast(119%);
        }
        .icons-filter:hover {
          filter: invert(0%) sepia(79%) saturate(2476%) hue-rotate(180deg)
            brightness(118%) contrast(119%);
        }
        .icons-filter.active {
          filter: invert(50%) sepia(90%) saturate(1245%) hue-rotate(140deg)
            brightness(128%) contrast(100%);
        }
        .font-merc {
          font-family: 'Daimler CAC', 'sans-serif';
          font-size: 25.4825px;
          color: white;
          grid-area: B;
        }

        .branding {
          position: absolute;
          top: 10px;
          left: 10px;
          right: auto;
          z-index: 1;
          display: grid;
          grid-auto-flow: column;
          grid-template-areas:
            'A B'
            'A C';
          grid-template-rows: 1fr 1fr;
          --logo-width: 66px;
          transform: 0;
        }

        .logo {
          align-self: top;
          width: 42px;
          margin: 12px;
          grid-area: A;
        }
        .branding span {
          align-self: end;
          font-size: 12px;
          white-space: nowrap;
        }
        .branding .eqe {
          align-self: start;
          width: 50px;
        }
        .mobile {
          position: absolute;
          z-index: 0;
          bottom: 0;
          width: 60px;
          display: flex;
          flex-direction: row;
          align-content: left;
          gap: 0;
          height: 60px;
          padding-bottom: ${isPresenter ? '0px' : '60px'};
        }
        .mobile.active {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 1)
          );
          height: 312px;
          width: 100%;
        }

        .mobile.active.playing {
          background: none;
        }
        menu {
          height: 100%;
          width: 60px;
          display: flex;
          flex-direction: column-reverse;
          padding: 0;
          padding-bottom: 10px;
        }
        .submenu {
          height: 100%;
          display: flex;
          flex-direction: column-reverse;
          text-align: center;
          flex: 1;
          padding-bottom: 10px;
        }

        footer {
          visibility: hidden;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 1)
          );
          opacity: 0;
          position: absolute;
          z-index: 1;
          bottom: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: ${isInMeeting ? 80 : 10}px;
        }
        footer.active {
          opacity: 1;
        }
        footer.presenter {
          opacity: 0;
          animation: fadeOut 10s linear;
          transition: opacity 0.5s;
          transition-delay: 5s;
        }
        footer.has-popup,
        footer.has-popup:hover {
          opacity: 0 !important;
          transition-delay: 1s;
        }
        footer:hover {
          opacity: 1;
          transition-delay: 0s;
        }

        footer .popup {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          order: 1;
          flex-wrap: wrap;
        }
        .linelayer {
          width: 100%;
          min-height: 20px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        h6 {
          color: white;
        }

        .left {
          flex: 1;
          text-align: right;
        }
        .whiteline {
          width: 280px;
          height: 2px;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 23.8%,
            #ffffff 50.52%,
            #ffffff 74.83%,
            rgba(255, 255, 255, 0) 100%
          );
          margin-top: 10px;
        }
        .right {
          text-align: left;
          flex: 1;
        }
        nav {
          flex-wrap: wrap;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          order: 2;
          align-items: flex-end;
          margin: 0 20px 10px 20px;
        }

        footer .navcol1 {
          position: relative;
          display: flex;
          order: 1;
        }
        footer .navcol2 {
          position: relative;
          display: flex;
          order: 2;
          justify-content: center;
          flex: 1;
        }
        .navcol2 .btn {
          flex-basis: 1;
          flex-basis: 0;
        }
        .reveal ::global(span) {
          white-space: nowrap;
        }
        footer .navcol3 {
          position: relative;
          display: flex;
          order: 3;
        }
        .btn {
          box-shadow: none;
        }
        .modifiers {
          box-shadow: none;
          background: none;
          border: 1px solid transparent;
          width: 50px;
        }
        .modifiers-btn {
          box-shadow: none;
          background: none;
          padding: 4px;
          border: 1px solid transparent;
        }
        .modifiers-btn:hover {
          border: 1px solid white;
        }
        .modifiers-btn.active {
          font-weight: strong;
          border: 1px solid cyan;
          color: #000;
        }
        .drop-up {
          position: absolute;
          bottom: 40px;
          left: 100px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
          border-bottom: 1px solid cyan;
          padding: 0;
        }
        .drop-up-item {
          color: #e1e1e1;
          height: 40px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
          text-transform: uppercase;
        }
        .drop-up-item:hover {
          color: white;
        }
        .drop-up-item.active {
          color: cyan;
          border-right: 1px solid cyan;
        }
        @media (orientation: landscape) {
          .mobile.active {
            background: linear-gradient(
              270deg,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.8),
              rgba(0, 0, 0, 1)
            );
            width: 400px;
            height: 100%;
          }
          .mobile {
            width: 60px;
            height: 60px;
          }
        }

        @media (min-width: ${isInMeeting && isPresenter ? 1007 : 916}px) {
          .mobile {
            visibility: hidden;
            height: 0;
          }
          footer {
            visibility: visible;
          }
          .drop-up {
            left: -30px;
            right: 0px;
            bottom: 64px;
          }
          .whiteline {
            margin: 15px;
          }
          .whiteline {
            width: 300px;
          }
          .linelayer {
            height: 30px;
          }
          .icons-filter {
            width: 50px;
            filter: invert(50%) sepia(0%) saturate(1245%) hue-rotate(145deg)
              brightness(121%) contrast(57%);
          }
        }

        @media only screen (min-width: 959px) {
          .branding span {
            font-size: 24px;
          }
          .branding .logo {
            align-self: center;
            width: 50px;
          }
          .branding .eqe {
            width: 70px;
          }
        }

        ::global(.in-meeting:is(.MDPortrait, .SMPortrait, .SMLandscape))
          .branding {
          left: 50%;
          transform: translateX(calc(var(--logo-width) / 2 * -1));
        }

        ::global(.in-meeting:is(.MDLandscape, .MDPortrait)) .mobile {
          padding-bottom: 60px;
        }

        @media only screen and (orientation: landscape) and (max-height: 916px) {
          menu,
          .submenu {
            padding-bottom: 10px;
          }

          footer {
            padding-bottom: ${isPresenter ? 0 : 80}px;
          }
          .drop-up-item {
            height: 32px;
            font-size: 12px;
          }
          .branding {
            left: 100px !important;
          }
        }

        @media (min-width: ${isInMeeting ? 1300 : 992}px) {
          .whiteline {
            margin: 20px;
          }
          .icons-filter {
            width: 74px;
          }
          .modifiers {
            width: 74px;
          }

          .linelayer {
            height: 60px;
          }
          footer {
            padding-bottom: ${isInMeeting ? 80 : 10}px;
          }

          footer .popup {
            display: flex;
            width: 100%;
            justify-content: center;
            order: 1;
          }

          nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            order: 2;
            margin: 0 40px 20px 40px;
          }
          .drop-up {
            bottom: 100px;
            right: 0;
            left: 0;
          }
        }
        @media (min-width: 1921px) {
          .left,
          .right {
            font-size: 30px;
          }
          .reveal ::global(span) {
            font-size: 27px;
          }
          .icons-filter {
            width: 90px;
          }
          .modifiers {
            width: 90px;
          }
          .whiteline {
            width: 404px;
          }
        }
        @keyframes fadeOut {
          from {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      `}</style>
    </>
  );
};

export default MercedesCustomComponent;

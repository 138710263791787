import { last } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import { IMainState } from '../../../interfaces';
import { VCAPopupContent } from '../../../interfaces/vca';
import { actionDidShowVBCampaign } from '../../../redux/actions';
import {
  actionCloseVCAPopup,
  actionOpenVCAPopup
} from '../../../redux/customActions/vca';
import {
  DID_CLOSE_CAMPAIGN,
  DID_OPEN_EXTERNAL_LINK
} from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import InvitationPopup from '../CustomComponent/VCACustomComponent/InvitationPopup';

const VanCleefArpelsValleyFairCampaign = ({
  onClose,
  inWalkThrough = false
}: {
  onClose: () => void;
  inWalkThrough?: boolean;
}) => {
  const clientState = useSelector(
    (state: IMainState) => state.clientState || {}
  );
  const { sceneIds } = clientState.vb || {};
  const popup = clientState.vca?.popup;
  const showInvitationPopup = popup === VCAPopupContent.INVITATION;
  const [isUsingMobile, setIsUsingMobile] = React.useState(false);
  const dispatch = useDispatch();
  const currentSceneId = last(sceneIds);

  //not auto show after 3s if in walk through
  const shouldShowNow = currentSceneId === 'scene_04' && !inWalkThrough;

  React.useEffect(() => {
    setIsUsingMobile(isUserOnMobile());
  }, []);

  React.useEffect(() => {
    let timeoutId;
    if (shouldShowNow) {
      timeoutId = setTimeout(() => {
        dispatch(actionOpenVCAPopup(VCAPopupContent.INVITATION));
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [shouldShowNow]);

  const handleEmbeddedCampaignClick = () => {
    //not open the link on desktop / only QR code
    if (!isUsingMobile) return;
    const url = 'https://www.vancleefarpels-event.com/two-butterfly/en/';
    window.open(url, '_blank');
    logEvent(DID_OPEN_EXTERNAL_LINK, DID_OPEN_EXTERNAL_LINK, {
      externalUrl: url
    });
  };

  const handleOnClose = () => {
    dispatch(actionCloseVCAPopup());
    setTimeout(() => {
      dispatch(actionDidShowVBCampaign());
      onClose();
    }, 500);
    logEvent(DID_CLOSE_CAMPAIGN, DID_CLOSE_CAMPAIGN, {
      campaign: {
        linkUrl: 'https://www.vancleefarpels-event.com/two-butterfly/en/'
      }
    });
  };

  return (
    <>
      <Transition
        in={showInvitationPopup}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className={`campaign ${state} ${
              inWalkThrough ? 'popup-page-content' : ''
            }`}
          >
            <div className="campaign-content">
              <InvitationPopup
                onClick={handleEmbeddedCampaignClick}
                onClose={handleOnClose}
              />
            </div>
          </div>
        )}
      </Transition>

      <style jsx>
        {`
          .campaign {
            position: fixed;
            z-index: 999;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            transition: all 0.3s ease-in-out;
          }

          .campaign-content {
            position: relative;
            margin: auto;
            display: block;
            text-align: center;
            overflow: hidden;
          }

          .campaign .close {
            position: absolute;
            right: 0px;
            top: 0px;
            color: #fff;
            background-color: transparent;
            padding: 0;
            width: 40px;
            height: 40px;
            font-size: 24px;
            opacity: 0.01;
          }

          .campaign.entering {
            opacity: 0;
          }
          .campaign.entered {
            opacity: 1;
          }
          .campaign.exiting {
            opacity: 0;
          }
          .campaign.exited {
            opacity: 0;
          }
        `}
      </style>
    </>
  );
};

export default VanCleefArpelsValleyFairCampaign;

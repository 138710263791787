import { last } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BoutiqueCampaignType,
  IBoutiqueCampaign,
  IMainState
} from '../../../interfaces';
import { getPageViewFromPath } from '../../../utils/pageView';
import campaignData from './campaignData';
import ImageCampaign from './ImageCampaign';
import PopupCampaign from './PopupCampaign';

const Campaign = ({
  campaign,
  onClose,
  brandId,
  storeId
}: {
  campaign: IBoutiqueCampaign;
  onClose: () => void;
  brandId: string;
  storeId: string;
}) => {
  const delay = campaign.secondsBeforeShow
    ? campaign.secondsBeforeShow * 1000
    : 2000;
  const router = useRouter();
  const campaignType = router.query.campaignType as string;
  const campaignId = router.query.campaignId as string;
  const { sceneIds, hasShownCampaign } = useSelector(
    (state: IMainState) => state.clientState.vb || {}
  );

  const currentSceneId = last(sceneIds);
  const shouldShowNow =
    !hasShownCampaign &&
    (campaign.showOnSceneId ? campaign.showOnSceneId === currentSceneId : true);

  if (campaignType && campaignId && campaignType.toLowerCase() !== 'image') {
    return (
      <PopupCampaign
        pageView={getPageViewFromPath(campaignType)}
        pageId={campaignId}
        brandId={brandId}
        storeId={storeId}
        secondsBeforeShow={delay}
      />
    );
  }

  if (
    campaignType &&
    campaignType.toLowerCase() === 'image' &&
    campaignId &&
    campaignData[campaignId]?.imageCampaign
  ) {
    return (
      <ImageCampaign
        campaign={campaignData[campaignId].imageCampaign}
        onClose={onClose}
        brandId={brandId}
        storeId={storeId}
        secondsBeforeShow={delay}
      />
    );
  }

  if (
    campaign.type === BoutiqueCampaignType.IMAGE &&
    campaign.imageCampaign &&
    shouldShowNow
  ) {
    return (
      <ImageCampaign
        campaign={campaign.imageCampaign}
        onClose={onClose}
        brandId={brandId}
        storeId={storeId}
        secondsBeforeShow={delay}
      />
    );
  }
  if (
    campaign.type === BoutiqueCampaignType.POPUP &&
    campaign.popupCampaign &&
    shouldShowNow
  ) {
    return (
      <PopupCampaign
        pageView={campaign.popupCampaign?.pageView}
        pageId={campaign.popupCampaign?.pageId}
        brandId={brandId}
        storeId={storeId}
        secondsBeforeShow={delay}
      />
    );
  }

  return null;
};

export default Campaign;

import { logEvent } from '../../analytics';
import { DID_CLICK_CART_ICON } from '../../utils/constants';
import { CgShoppingCart } from 'react-icons/cg';

const CartButton = ({
  onClick,
  itemsQuantity
}: {
  onClick: () => void;
  itemsQuantity: number;
}) => {
  return (
    <div
      className="CartButton with-shadow"
      onClick={() => {
        onClick();
        logEvent(DID_CLICK_CART_ICON);
      }}
    >
      <CgShoppingCart color="#fff" />
      {!!itemsQuantity && <div className="notification" />}
      <style jsx>
        {`
          .CartButton {
            color: #fff;
            margin-left: 16px;
            position: relative;
          }
          .CartButton :global(svg) {
            cursor: pointer;
            width: 26px;
            height: auto;
            fill: #fff;
          }
          .notification {
            background: red;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            top: 3px;
            right: -2px;
            position: absolute;
            border: 2px solid #fff;
          }
          @media (max-width: 400px) {
            .CartButton {
              margin-left: 2px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default CartButton;

import React from 'react';
import { IPopupState, PanoView } from '../../../../interfaces';
import { MdClose } from 'react-icons/md';
import { isUserOnMobile } from '../../../../utils/deviceDetector';
import { first } from 'lodash';

enum Guide {
  TURN = 'guideTurn',
  ZOOM = 'guideZoom',
  MOVE = 'guideMove',
  DISCOVER = 'guideDiscover'
}

interface Props {
  touch: boolean;
}

const GuideTurn = () => (
  <>
    <span>
      Drag to turn
      <strong>360&deg;</strong>
    </span>
    <style jsx>{`
      strong {
        font-size: 2.2em !important;
      }
      span {
        line-height: 1.8em !important;
      }
    `}</style>
  </>
);

const GuideZoom = ({ touch }: Props) => (
  <>
    {touch ? (
      <span>
        <strong>Pinch to Zoom</strong>
        into the item
      </span>
    ) : (
      <span>
        <strong>Scroll Mouse Wheel</strong>
        to zoom into the item
      </span>
    )}
  </>
);

const GuideMove = ({ touch }: Props) => (
  <span>
    <strong>{touch ? 'Touch the floor' : 'Click the floor'}</strong>
    to move around
  </span>
);

const GuideDiscover = ({ touch }: Props) => (
  <span>
    <strong>{touch ? 'Tap on the object' : 'Click on the object'}</strong>
    to discover more
  </span>
);

const UserGuide = ({
  currentScene,
  currentView,
  popup,
  handleClose
}: {
  currentScene: string;
  currentView?: PanoView;
  popup: IPopupState;
  handleClose: () => void;
}) => {
  const [sequence, setSequence] = React.useState([
    Guide.TURN,
    Guide.MOVE,
    Guide.ZOOM,
    Guide.DISCOVER
  ]);

  const currentGuide = first(sequence);

  const [isTouch, setIsTouch] = React.useState(false);
  const [initialView, setInitialView] = React.useState<PanoView>();
  const [initialScene, setInitialScene] = React.useState('');

  const isTurning =
    Math.abs(Math.abs(currentView?.viewH) - initialView?.viewH) > 5 ||
    Math.abs(Math.abs(currentView?.viewV) - initialView?.viewV) > 5;

  const isZooming = Math.abs(currentView?.fov - initialView?.fov) > 10;
  const isMoving = currentScene !== initialScene;
  const isDiscovering = popup?.open;

  React.useEffect(() => {
    setIsTouch(isUserOnMobile());
  }, []);

  React.useEffect(() => {
    if (!initialScene) {
      setInitialScene(currentScene);
    }
    if (!initialView?.viewH) {
      setInitialView({
        viewH: Math.abs(currentView?.viewH),
        viewV: Math.abs(currentView?.viewV),
        fov: Math.abs(currentView?.fov)
      });
    }
  }, [currentView]);

  React.useEffect(() => {
    if (isTurning) setSequence(sequence?.filter((s) => s !== Guide.TURN));
  }, [isTurning]);

  React.useEffect(() => {
    if (isZooming) setSequence(sequence?.filter((s) => s !== Guide.ZOOM));
  }, [isZooming]);

  React.useEffect(() => {
    if (isMoving && initialScene) {
      setTimeout(() => {
        setSequence(sequence?.filter((s) => s !== Guide.MOVE));
      }, 1000);
    }
  }, [isMoving]);

  React.useEffect(() => {
    if (isDiscovering) handleClose();
  }, [isDiscovering]);

  return (
    <>
      <div
        className={`guide-container ${currentGuide} ${
          isTouch ? 'touch' : 'click'
        }`}
      >
        {currentGuide === Guide.TURN && <GuideTurn />}
        {currentGuide === Guide.ZOOM && <GuideZoom touch={isTouch} />}
        {currentGuide === Guide.MOVE && <GuideMove touch={isTouch} />}
        {currentGuide === Guide.DISCOVER && <GuideDiscover touch={isTouch} />}

        <div className="animate" />
      </div>
      <button className="close with-shadow" type="button" onClick={handleClose}>
        <MdClose color="#fff" />
      </button>
      <style jsx>{`
        .close {
          position: fixed;
          z-index: 100;
          bottom: 200px;
          left: 50%;
          margin-left: 60px;
        }
        .animate,
        .guide-container,
        .guide-container :global(span) {
          width: 140px;
          height: 140px;
          border-radius: 100%;
          position: fixed;
        }
        .guide-container {
          display: flex;
          align-content: center;
          justify-content: center;
          bottom: 80px;
          left: 50%;
          margin-left: -70px;
          pointer-events: none;
        }

        .animate {
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          z-index: 0;
          position: relative;
          bottom: 0;
          left: 0;
          margin-left: -140px;
        }
        .guide-container :global(span) {
          display: flex;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          margin: auto;
          font-size: 14px;
          line-height: 1.2;
          position: relative;
          z-index: 2;
          -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
            both;
          animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
        .guide-container :global(strong) {
          display: block;
        }

        .guideTurn .animate,
        .guideTurn :global(span) {
          -webkit-animation: flip-vertical-right 5s
            cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms infinite both;
          animation: flip-vertical-right 5s
            cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms infinite both;
        }

        .guideZoom.click .animate {
          -webkit-animation: shake-vertical 3s linear infinite both;
          animation: shake-vertical 3s linear infinite both;
        }

        .guideZoom.touch .animate {
          -webkit-animation: scale-in-center 4s ease-in-out infinite both;
          animation: scale-in-center 4s ease-in-out infinite both;
        }

        .guideMove .animate,
        .guideMove :global(span) {
          -webkit-animation: slide-rotate-hor-b-bck 4s ease-in-out infinite both;
          animation: slide-rotate-hor-b-bck 4s ease-in-out infinite both;
        }

        .guideDiscover .animate {
          -webkit-animation: heartbeat 2s ease-in-out infinite both;
          animation: heartbeat 2s ease-in-out infinite both;
        }

        @-webkit-keyframes heartbeat {
          from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          10% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          33% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
        }
        @keyframes heartbeat {
          from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          10% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
          33% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
          }
          45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
          }
        }

        @-webkit-keyframes slide-rotate-hor-b-bck {
          0%,
          40%,
          100% {
            -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
            -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
          }
          50%,
          90% {
            -webkit-transform: translateY(40px) translateZ(0) rotateX(60deg)
              scale(0.8);
            transform: translateY(40px) translateZ(0) rotateX(60deg) scale(0.8);
            -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
          }
        }
        @keyframes slide-rotate-hor-b-bck {
          0%,
          40%,
          100% {
            -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
            -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
          }
          50%,
          90% {
            -webkit-transform: translateY(40px) translateZ(0) rotateX(60deg)
              scale(0.8);
            transform: translateY(40px) translateZ(0) rotateX(60deg) scale(0.8);
            -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
          }
        }

        @-webkit-keyframes scale-in-center {
          0%,
          40%,
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }

          50%,
          90% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
          }
        }
        @keyframes scale-in-center {
          0%,
          40%,
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }

          50%,
          90% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
          }
        }

        @-webkit-keyframes shake-vertical {
          0%,
          50%,
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
          }

          60% {
            -webkit-transform: translateY(-18px);
            transform: translateY(-18px);
          }

          80% {
            -webkit-transform: translateY(18px);
            transform: translateY(18px);
          }
        }

        @keyframes shake-vertical {
          0%,
          50%,
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
          }

          60% {
            -webkit-transform: translateY(-18px);
            transform: translateY(-18px);
          }

          80% {
            -webkit-transform: translateY(18px);
            transform: translateY(18px);
          }
        }

        @-webkit-keyframes flip-vertical-right {
          0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
          }
          30% {
            -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
          }
          60%,
          100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
          }
        }
        @keyframes flip-vertical-right {
          0% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
          }
          30% {
            -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
          }
          60%,
          100% {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
          }
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @media (min-width: 768px) {
          .close {
            bottom: 240px;
            margin-left: 90px;
          }

          .animate,
          .guide-container,
          .guide-container :global(span) {
            width: 200px;
            height: 200px;
          }
          .guide-container {
            margin-left: -100px;
          }
          .animate {
            margin-left: -200px;
          }
          .guide-container :global(span) {
            font-size: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default UserGuide;

import React from 'react';
import { IVirtualBoutiqueContact } from '../../../interfaces';
import { logClickContact } from './common';
import { useDispatch } from 'react-redux';
import { actionClickContact } from '../../../redux/actions';


const EmailContactButton = ({
  contact,
  children
}: {
  contact: IVirtualBoutiqueContact;
  children: React.ReactNode;
  }) => {
  const dispatch = useDispatch();
  return (
    <a
      data-channel="email"
      href={`mailto:${contact.email}?${contact.cc ? `cc=${contact.cc}&` : ''}${
        contact.bcc ? `bcc=${contact.bcc}&` : ''
      }subject=${
        contact.emailSubject ? encodeURIComponent(contact.emailSubject) : ''
      }`}
      target="_blank"
      rel="noopener"
      aria-label="Enquire Now"
      key={contact.name}
      onClick={() => {
        logClickContact('email', contact);
        dispatch(actionClickContact());
      }}
    >
      {children}
    </a>
  );
};

export default EmailContactButton;

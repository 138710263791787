import React from 'react';
import { vcaAssets } from '.';
import { IMapSpot } from '../../../../interfaces';
import VCAMapSpot, { SpotProps } from './VCAMapSpot';
const spots: SpotProps[] = [
  {
    spot: { scene: 'scene_34' },
    left: 4,
    top: 25
  },
  {
    spot: { scene: 'scene_35' },
    left: 43,
    top: 9
  },
  {
    spot: { scene: 'scene_36' },
    left: 43,
    top: 41.5
  },
  {
    spot: { scene: 'scene_37' },
    left: 18,
    top: 96
  }
];

export const level2Scenes = spots.map((spot) => spot.spot.scene);

const VCAMapLevel2 = ({
  onSelectSpot,
  onSelectFloor,
  active,
  visitedSpot,
  currentPosition
}: {
  onSelectSpot: (spot: IMapSpot) => void;
  onSelectFloor: () => void;
  active?: boolean;
  visitedSpot: string[];
  currentPosition?: string;
}) => {
  return (
    <div className={`map-container ${active}`}>
      <div className="map-label vca-title">Second Floor</div>
      <div className="inner">
        <img className="map" src={vcaAssets.level2Map} alt="" />
        <div className="to-floor" onClick={onSelectFloor}>
          <img src={vcaAssets.gotoLevel} alt="" />
          <div className="to-floor-label vca-title">
            <span>To</span>
            <span>Ground</span>
            <span>Floor</span>
          </div>
        </div>
        {spots.map((spot) => (
          <VCAMapSpot
            key={spot.spot.scene}
            className={spot.spot.scene}
            visited={visitedSpot.includes(spot.spot.scene)}
            onClick={() => onSelectSpot(spot.spot)}
            left={spot.left}
            top={spot.top}
            active={currentPosition === spot.spot.scene}
          />
        ))}
      </div>
      <style jsx>{`
        .map-container {
          transform: translate(-50%, -300%);
        }
        .map-container.true {
          pointer-events: all;
          transform: translate(-50%, -50%);
        }
        .map-container .map {
          max-width: 90vw;
          max-height: 80vh;
        }
        .to-floor {
          max-width: 197px;
          bottom: auto;
          top: 58%;
          right: 17%;
          transform: translate(50%);
          width: 32%;
          overflow: hidden;
        }

        .to-floor img {
          width: 100%;
          height: auto;
        }
        @media (min-width: 1140px) {
          .map-container .map {
            max-width: 50vw;
            max-height: 65vh;
          }
        }

        @media (min-width: 840px) {
          :global(.SIDE) .map-container .map {
            max-width: calc(90vw - 200px) !important;
          }
          :global(.PRESENTATION) .map-container .map {
            max-width: calc(80vw - ${100}px) !important;
            max-height: calc(65vh - 150px) !important;
          }
          :global(.PRESENTATION) .map-container.true {
            transform: translate(-50%, -calc(50% - 75px)) !important;
          }
        }
      `}</style>
    </div>
  );
};

export default VCAMapLevel2;

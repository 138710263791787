import * as THREE from 'three';
import React, { useRef } from 'react';
import {
  useFrame,
  useThree,
  useLoader,
  SharedCanvasContext
} from 'react-three-fiber';
import {
  modelWorkshopEnvironmentImagePath,
  SSPHotspotButton,
  sspProModelPath
} from './assets';
import SSPHotspot from './SSPHotspot';
import {
  COMMON3D_ANIMATION,
  ICanvasTouchState,
  ICommon3DAnimationState,
  ICommonProductDetail,
  ITransformValue,
  REMOTE_ACTION,
  TouchType
} from '../../../interfaces/common3D';
import { CanvasTouch } from './CanvasTouch';
import { logEvent } from '../../../analytics';
import { DID_UPDATE_ANIMATION_STATE } from '../../../utils/constants';
const SSPProCube = ({
  remoteState,
  viewOnly,
  TWEEN,
  updateRemoteState,
  setFocus,
  setDetail
}: {
  remoteState?: ICommon3DAnimationState;
  viewOnly: boolean;
  TWEEN;
  updateRemoteState: (state: ICommon3DAnimationState) => void;
  setFocus: (
    model: THREE.Mesh,
    threeRef: SharedCanvasContext,
    transform: ITransformValue,
    isFocused: boolean
  ) => void;
  setDetail: (detail: string) => void;
}) => {
  const [timer, seTimer] = React.useState(undefined);
  const sspProRef: React.MutableRefObject<null> = useRef();
  const threeRef: SharedCanvasContext = useThree();
  /* eslint-disable @typescript-eslint/no-var-requires */
  const RGBELoader =
    require('three/examples/jsm/loaders/RGBELoader').RGBELoader;
  const GLTFLoader =
    require('three/examples/jsm/loaders/GLTFLoader').GLTFLoader;
  /* eslint-enable @typescript-eslint/no-var-requires */
  const sspProGltf = useLoader(GLTFLoader, sspProModelPath) as any;

  let initScale = 0;
  let canvasTouch;
  const maxScale = 2;
  const minScale = 0.5;
  const incScale = 0.03;

  useFrame(() => {
    TWEEN?.update();
  });
  const setAndHideDetail = (detailID: string) => {
    setDetail(detailID);
    logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
      animation: COMMON3D_ANIMATION,
      state: { detailID }
    });
    if (timer) clearTimeout(timer);
    seTimer(setTimeout(() => setDetail(undefined), 5000));
    if (!viewOnly) {
      const obj: THREE.Mesh = sspProRef?.current;
      let focusValues: ITransformValue = undefined;
      if (obj) {
        focusValues = {
          position: [obj.position.x, obj.position.y, obj.position.z],
          scale: [obj.scale.x, obj.scale.y, obj.scale.z],
          rotation: [obj.rotation.x, obj.rotation.y, obj.rotation.z]
        };
      }
      const model: ICommonProductDetail = {
        subCode: detailID,
        subName: detailID
      };
      updateRemoteState({
        flag: REMOTE_ACTION.DETAIL,
        modelDetail: model,
        focusedModel: focusValues
      });
    }
  };

  const loadEnvironment = () => {
    if (!threeRef?.gl || !threeRef?.scene) return;
    threeRef.gl.outputEncoding = THREE.sRGBEncoding;
    const pmremGenerator = new THREE.PMREMGenerator(threeRef.gl);
    pmremGenerator.compileEquirectangularShader();
    new RGBELoader().setDataType(THREE.UnsignedByteType).load(
      modelWorkshopEnvironmentImagePath,
      (texture) => {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator.dispose();
        threeRef.scene.environment = envMap;
      },
      undefined,
      (e) => {
        console.error(e);
      }
    );
    if (!remoteState?.flag || !viewOnly) {
      setFocus(
        sspProRef.current,
        threeRef,
        { position: [0, 0, 4], rotation: [0, 0, 0], scale: [1, 1, 1] },
        true
      );
    }
  };
  const updateRemoteObjectControlForModel = (obj: THREE.Mesh) => {
    const focusValues: ITransformValue = {
      position: [obj.position.x, obj.position.y, obj.position.z],
      scale: [obj.scale.x, obj.scale.y, obj.scale.z],
      rotation: [obj.rotation.x, obj.rotation.y, obj.rotation.z]
    };
    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      focusedModel: focusValues
    });
  };
  const onwheelEvent = (event) => {
    const obj: THREE.Mesh = sspProRef?.current;
    if (!obj || viewOnly) return;
    let modelScale = obj.scale.x;
    if (event.deltaY > 0) {
      if (modelScale < maxScale) modelScale += incScale;
    } else {
      if (modelScale > minScale) modelScale -= incScale;
    }
    obj.scale.set(modelScale, modelScale, modelScale);
  };
  const onPinch = (ev: ICanvasTouchState, obj: THREE.Mesh) => {
    if (!obj || !ev?.scale || viewOnly) return;

    if (ev.type === TouchType.pinchStart) {
      initScale = obj.scale.x || 1;
    }
    obj.scale.set(
      initScale * ev.scale,
      initScale * ev.scale,
      initScale * ev.scale
    );
    if (obj.scale.x > maxScale) obj.scale.set(maxScale, maxScale, maxScale);
    if (obj.scale.x < minScale) obj.scale.set(minScale, minScale, minScale);
  };
  const onTouchEvent = (e: any, canvasTouchState: ICanvasTouchState) => {
    const obj: THREE.Mesh = sspProRef?.current;
    if (!obj || viewOnly) return;
    if (canvasTouchState.isPointerDown) {
      updateRemoteObjectControlForModel(obj);
    }
    if (
      canvasTouchState.type === TouchType.pinchStart ||
      canvasTouchState.type === TouchType.pinchMove
    ) {
      onPinch(canvasTouchState, obj);
    }
    if (canvasTouchState.type === TouchType.wheel) {
      onwheelEvent(e);
    }
  };

  const setMaterialForFrame = (model: THREE.Mesh) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (
        object['material'].isMaterial &&
        object?.name === 'Difference_014_1'
      ) {
        if (object['material'] !== undefined) {
          object['material'].color = new THREE.Color(0x080808);
        }
      }
    });
  };
  React.useEffect(() => {
    loadEnvironment();
    canvasTouch = new CanvasTouch({ threeRef, onTouchEvent });
    canvasTouch.restoreContext(threeRef?.gl);
    setMaterialForFrame(sspProRef?.current);
    if (!viewOnly) canvasTouch.addEventListeners(threeRef?.gl?.domElement);
    return () => {
      updateRemoteState({});
      canvasTouch.removeEventListeners(threeRef?.gl?.domElement);
    };
  }, []);
  React.useEffect(() => {
    const obj: THREE.Mesh = sspProRef?.current;
    if (!obj || !remoteState) return;

    if (
      (remoteState?.flag === REMOTE_ACTION.MOUSE_CONTROL ||
        remoteState?.flag === REMOTE_ACTION.DETAIL) &&
      remoteState?.focusedModel &&
      viewOnly
    ) {
      if (remoteState?.focusedModel?.position)
        obj.position.set(
          remoteState?.focusedModel.position[0],
          remoteState?.focusedModel.position[1],
          remoteState?.focusedModel.position[2]
        );
      if (remoteState?.focusedModel?.rotation)
        obj.rotation.set(
          remoteState?.focusedModel.rotation[0],
          remoteState?.focusedModel.rotation[1],
          remoteState?.focusedModel.rotation[2]
        );
      if (remoteState?.focusedModel?.scale)
        obj.scale.set(
          remoteState?.focusedModel.scale[0],
          remoteState?.focusedModel.scale[1],
          remoteState?.focusedModel.scale[2]
        );
    }
    if (remoteState?.flag === REMOTE_ACTION.DETAIL && viewOnly) {
      setAndHideDetail(remoteState?.modelDetail?.subName);
    }
  }, [remoteState]);
  return (
    <group
      name="sspProGltf"
      ref={sspProRef}
      position={[0, 0, 0]}
      dispose={null}
    >
      <mesh position={[0, 0, 0]}>
        <boxGeometry attach="geometry" args={[0.4, 0.5, 0.4]} />
        <meshPhongMaterial
          attach="material"
          color="red"
          transparent={true}
          opacity={0.5}
          visible={false}
        />
      </mesh>
      <SSPHotspot
        name={SSPHotspotButton.frontLightConfigButton}
        setAndHideDetail={setAndHideDetail}
        position={[0.16, 0.054, -0.0456]}
      />
      <SSPHotspot
        name={SSPHotspotButton.trayLightConfigButton}
        setAndHideDetail={setAndHideDetail}
        position={[0.16, 0.054, -0.091]}
      />
      <SSPHotspot
        name={SSPHotspotButton.onOffButton}
        setAndHideDetail={setAndHideDetail}
        position={[-0.16, 0.013, -0.127]}
      />
      <SSPHotspot
        name={SSPHotspotButton.fullHDFrontCamera}
        setAndHideDetail={setAndHideDetail}
        position={[0, 0.2195, 0.029]}
      />
      <SSPHotspot
        name={SSPHotspotButton.videoEncoder}
        setAndHideDetail={setAndHideDetail}
        position={[0, -0.05, 0.08]}
      />
      <SSPHotspot
        name={SSPHotspotButton.touchScreenDisplay}
        setAndHideDetail={setAndHideDetail}
        position={[0, 0.07, 0.02]}
      />

      <SSPHotspot
        name={SSPHotspotButton.presenterLedLight}
        setAndHideDetail={setAndHideDetail}
        position={[-0.151, 0.1, 0.038]}
        radius={0.008}
      />
      <SSPHotspot
        name={SSPHotspotButton.microphone}
        setAndHideDetail={setAndHideDetail}
        position={[0, -0.025, 0.23]}
        radius={0.01}
      />
      <SSPHotspot
        name={SSPHotspotButton.productTray}
        setAndHideDetail={setAndHideDetail}
        position={[0, -0.215, 0.08]}
      />
      <primitive
        position={[0, -0.23, 0.08]}
        object={sspProGltf.scene}
        dispose={null}
      />
    </group>
  );
};
export default SSPProCube;

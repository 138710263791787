import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { assetBaseUrl } from '../../../../../config';
import {
  ILoubiAirways2dAnimationState,
  LoubiAirwaysAnimation
} from '../../../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../../../redux/customActions/loubiAirways';
import { useRemoteLoubiAirwaysAnimationState } from '../../../../hooks/loubiAirways';
import {
  AnimationContext,
  FADE_SPEED
} from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import { animationVoyageThrottleDurationInMs } from '../../LouboutinCustomComponent/animationUtils';
import ContinueFlightButton from '../Buttons/LJContinueFlightButton';
import TwoDProductHotSpot from '../TwoDProduct/LJTwoDProductHotSpot';
import { AnimationProductModel } from '../TwoDProduct/LJTwoDProductsList';
import SinglePaper from '../../LouboutinCustomComponent/SinglePaper/SinglePaper';

const StylishGuide = () => {
  const dispatch = useDispatch();
  const { setLoaded, onCloseAnimation } = React.useContext(AnimationContext);
  const [animateClose, setAnimateClose] = React.useState(false);

  const remoteState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE
  ) as ILoubiAirways2dAnimationState;

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirways2dAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );

  const updateRemoteState = (state: ILoubiAirways2dAnimationState) => {
    throttledUpdateRemoteState(state);
  };

  const close = remoteState?.close || animateClose;

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  React.useEffect(() => {
    if (close) {
      updateRemoteState({ close: true });
      setTimeout(() => {
        updateRemoteState(undefined);

        onCloseAnimation();
      }, FADE_SPEED + 100);
    }
  }, [close]);

  const asset = `${assetBaseUrl}/loubiairways/luggage-animation/stylish-guide/jp`;

  const content = (
    <>
      <img src={`${asset}/stylish-empty.png`} alt="" className="img-content" />
      <TwoDProductHotSpot
        left={-38.8}
        top={-15.3}
        width={13}
        model={AnimationProductModel.CLLOUBIAIRWAYS042}
      />
      <TwoDProductHotSpot
        left={-47.5}
        top={7.3}
        width={20}
        model={AnimationProductModel.CLLOUBIAIRWAYS036}
      />

      <TwoDProductHotSpot
        left={-12.5}
        top={-22.2}
        width={11.4}
        model={AnimationProductModel.CLLOUBIAIRWAYS122}
      />

      <TwoDProductHotSpot
        left={-15.6}
        top={3.3}
        width={14.3}
        model={AnimationProductModel.CLLOUBIAIRWAYS039}
      />

      <TwoDProductHotSpot
        left={2.3}
        top={-16.4}
        width={13}
        model={AnimationProductModel.CLLOUBIAIRWAYS037}
      />

      <TwoDProductHotSpot
        left={14.25}
        top={12}
        width={9.4}
        model={AnimationProductModel.CLLOUBIAIRWAYS038}
      />

      <TwoDProductHotSpot
        left={35.8}
        top={-14.3}
        width={12.5}
        model={AnimationProductModel.CLLOUBIAIRWAYS035}
      />
      <TwoDProductHotSpot
        left={34.2}
        top={-2.9}
        width={15.2}
        model={AnimationProductModel.CLLOUBIAIRWAYS040}
      />

      <TwoDProductHotSpot
        left={26.7}
        top={11}
        width={14.6}
        model={AnimationProductModel.CLLOUBIAIRWAYS043}
      />
      <style jsx global>{`
        .stylishGuide .img-spot.closing {
          opacity: 1 !important;
        }
        .stylishGuide .img-spot.closing.FOCUS {
          transform: scale(1.2);
          filter: drop-shadow(0 0 0.75rem #9c0f21);
        }
        .stylishGuide .img-content {
          animation: fade-appear 1.2s steps(1) both !important;
        }
        @keyframes fade-appear {
          0%,
          90% {
            opacity: 0.01;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
  return (
    <>
      <SinglePaper
        background={`${asset}/bg.jpg`}
        portraitBackground={`${asset}/bg-m.jpg`}
        cover={`${asset}/stylish.jpg`}
        close={close}
        delay={FADE_SPEED + 600}
        className="stylishGuide"
      >
        {content}
      </SinglePaper>
      <ContinueFlightButton
        onClick={() => {
          setAnimateClose(true);
        }}
      />
      <style jsx global>
        {`
          .stylishGuide .SinglePaperContent .img-content,
          .stylishGuide .paperNav {
            width: calc(100vw - 200px) !important;
            height: auto !important;
            max-height: none !important;
            max-width: calc(100vh * 1.941 - 200px) !important;
          }
          .stylishGuide .paperNav {
            transform: translate(0, 0) !important;
          }
          .stylishGuide .paperNav.closing {
            transform: translate(24.5vh, -7vh) rotate(1deg) !important;
            width: 20vh !important;
            height: auto;
            opacity: 0.6;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.8) !important;
          }

          @media (orientation: portrait) {
            .stylishGuide .paperNav.closing {
              transform: translate(0, -7vh) rotate(1deg) !important;
              width: 20vh !important;
              height: auto !important;
              max-width: none !important;
            }
            .stylishGuide .SinglePaperContent .img-content,
            .stylishGuide .paperNav {
              max-width: none !important;
              width: 100vw !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default StylishGuide;

const ExpandSvgIcon = ({ style }: { style: any }) => (
  <svg
    style={style}
    enableBackground="new 0 0 64 64"
    height="512"
    viewBox="0 0 64 64"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m26.586 34.586-14.586 14.586v-5.172c0-1.104-.896-2-2-2s-2 .896-2 2v10c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2s-.896-2-2-2h-5.172l14.586-14.586c.781-.781.781-2.047 0-2.828-.78-.781-2.048-.781-2.828 0z" />
    <path d="m54 8h-10c-1.104 0-2 .896-2 2s.896 2 2 2h5.172l-14.586 14.586c-.781.781-.781 2.047 0 2.828.391.391.902.586 1.414.586s1.023-.195 1.414-.586l14.586-14.586v5.172c0 1.104.896 2 2 2s2-.896 2-2v-10c0-1.104-.896-2-2-2z" />
  </svg>
);

export default ExpandSvgIcon;

import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import axios from 'axios';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { BsChevronRight } from 'react-icons/bs';
import { logEvent } from '../../../analytics';
import { failedToLoadImagePlaceholder, isChopard } from '../../../config';
import { useTranslation } from '../../../i18n';
import { IProduct, Source, WishlistType } from '../../../interfaces';
import { DID_CLICK_DISCOVER_MORE } from '../../../utils/constants';
import { shouldShowCompare } from '../../../utils/entitlements';
import { getBrandAvatarUrl } from '../../../utils/image';
import { PageView } from '../../../utils/pageView';
import {
  getProductDisplayName,
  getProductDisplayPrice,
  getProductEdition
} from '../../../utils/product';
import { getNudgeCurrencyCountryCode } from '../../../utils/window';
import { AppContext } from '../../AppContext';
import { ChatContentSharingContext } from '../../ChatContentSharingContext';
import Avatar from '../../Avatar';
import EyeballTimeLogger from '../../EyeballTimeLogger';
import Header from '../../Header';
import { getBadge, getProductCTAButton } from '../../storeComponentFactory';
import WishlistButton from '../../Wishlist/WishlistButton';
import CompareButton from '../Compare/CompareButton';
import ProductDescriptionAccordion from '../ProductDescriptionAccordion';
import ProductSpecifications from '../ProductSpecifications';
import Slider from '../Slider';
import { currentLanguage } from '../../../utils/language';
import Link from '../../Common/Link';

export interface IProductDetailsProp {
  colors: string[];
  product: IProduct;
  handleColorClick: (index: number) => void;
  isCustomizable?: boolean;
  hideVariations?: boolean;
  hideReferences?: boolean;
}

const ProductDetails = ({
  product,
  colors,
  handleColorClick,
  isCustomizable,
  hideVariations,
  hideReferences
}: IProductDetailsProp) => {
  const { t, i18n } = useTranslation();
  const { country, store, storeConfig } = React.useContext(AppContext);
  const { isChatContentSelection, getSendToShareButton } = React.useContext(
    ChatContentSharingContext
  );
  const language = currentLanguage(i18n);
  const hidePersonalize = isChopard(product.brandId);
  const [isError, setIsError] = React.useState(false);

  const displayCurrencyCountryCode =
    getNudgeCurrencyCountryCode() || (store && store.countryCode) || country;

  const price = getProductDisplayPrice(
    product,
    displayCurrencyCountryCode,
    storeConfig?.strictProductDisplay
  );
  const { imageUrls, brandId, modelCode, productFamily } = product;

  const edition = getProductEdition(product, language);

  const router = useRouter();
  const source = router.query.source as string | undefined;

  const handleDiscoverMore = () => {
    logEvent(DID_CLICK_DISCOVER_MORE);
    parent.postMessage('did_click_discover_more', '*');
  };

  useEffect(() => {
    axios
      .post('/api/checkUrl', {
        url: getBrandAvatarUrl(brandId)
      })
      .then((res) => {
        setIsError(!res.data.accessible);
      })
      .catch(() => setIsError(true));
  }, [brandId]);

  return (
    <>
      <Header
        title={t('product_details').toUpperCase()}
        shareTitle={getProductDisplayName(product, language)}
        shareImage={product.imageUrls.length > 0 && product.imageUrls[0]}
        type={PageView.PRODUCT}
        id={product.id}
      />
      <EyeballTimeLogger tracker={{ view: PageView.PRODUCT, id: product.id }}>
        <Container className="product-landing">
          {router.query['discover_more'] && (
            <>
              <img
                className="discover-more"
                src="/asset/discover_white.gif"
                onClick={handleDiscoverMore}
              />
              <div
                className="discover-more-mobile-click-area"
                onClick={handleDiscoverMore}
              ></div>
            </>
          )}

          <Row>
            <Col xs={12} sm={6} md={7} className="slider-container">
              <div className="slider-inner sticky-top">
                {!isError && <Avatar url={getBrandAvatarUrl(brandId)} />}
                {imageUrls && imageUrls.length > 0 ? (
                  <Slider
                    key={product.id}
                    imageUrls={imageUrls}
                    allowZoom={storeConfig?.entitlement?.productImageZoom}
                  />
                ) : (
                  <img src={failedToLoadImagePlaceholder} />
                )}
              </div>
            </Col>
            <Col xs={12} sm={6} md={5} lg={4}>
              <Row>
                <Col xs={12}>
                  <p className="modelcode">
                    {productFamily && (
                      <>
                        {productFamily}
                        {` `}
                        {!hideReferences && (
                          <BsChevronRight style={{ marginTop: '-3px' }} />
                        )}
                        {` `}
                      </>
                    )}
                    {!hideReferences && modelCode}
                  </p>
                  <h1 className="title">
                    {getProductDisplayName(product, language)}
                  </h1>
                  {edition && <p className="product-edition">{edition}</p>}
                  <p className="price">{price}</p>
                </Col>
              </Row>

              <Row className="separator"></Row>

              <Row className="section-container">
                <Col xs={12}>
                  {!hideVariations && colors.length > 1 && (
                    <>
                      <p className="light-text">{t('colors')}</p>
                      <div className="colors">
                        {colors.map((color: string, index: number) => (
                          <Image
                            className={
                              (product.productColorImageURL === color &&
                                'active') ||
                              ''
                            }
                            src={color}
                            key={index}
                            alt={color}
                            onError={(ev) =>
                              ((ev.target as any).src =
                                failedToLoadImagePlaceholder)
                            }
                            onClick={() => handleColorClick(index)}
                          />
                        ))}
                      </div>
                    </>
                  )}
                  <div id="description-anchor" />
                  <ProductDescriptionAccordion product={product} />
                  <div id="specification-anchor" />
                  <ProductSpecifications product={product} />
                  {!hidePersonalize && isCustomizable && (
                    <Link
                      as={`/personalize/${product.id}`}
                      href={`/personalize/[id]`}
                      className="btn btn-action btn-personalize hide-for-client-meeting"
                    >
                      {t('personalize')}
                    </Link>
                  )}

                  {source !== Source.AdvisorHub &&
                    source !== Source.Connect &&
                    !isChatContentSelection &&
                    getProductCTAButton(product, store?.id, storeConfig)}

                  <div className="compare-wishlist hide-for-client-meeting">
                    {shouldShowCompare(storeConfig?.entitlement) && (
                      <CompareButton product={product} brandId={brandId} />
                    )}

                    <WishlistButton
                      item={{
                        itemId: product.id,
                        itemType: WishlistType.PRODUCT
                      }}
                      addLabel={t('add_to_wishlist')}
                      successLabel={t('in_my_wishlist')}
                    />
                  </div>

                  {getBadge(store?.id)}
                </Col>
              </Row>
            </Col>
          </Row>
          {isChatContentSelection &&
            getSendToShareButton(PageView.PRODUCT, product.id)}
          <div id="product-slider-anchor" />
        </Container>
      </EyeballTimeLogger>
      <style jsx>
        {`
          :global(.btn-personalize) {
            border-color: #000;
          }

          .discover-more {
            cursor: pointer;
            position: absolute;
            top: 40px;
            right: 0;
            width: 150px;
            height: 195px;
          }
          .colors {
            border-bottom: 1px dotted;
          }

          .discover-more-mobile-click-area {
            cursor: pointer;
            position: absolute;
            top: 40px;
            right: 0;
            width: 150px;
            height: 195px;
            z-index: 99;
          }

          .compare-wishlist {
            display: flex;
            justify-content: space-between;
          }

          .compare-wishlist > :global(p),
          .compare-wishlist > :global(div) {
            margin-left: auto;
            margin-right: auto;
            font-size: ${language === 'fr' ? '0.7' : '0.8'}em;
          }

          :global(.de) .compare-wishlist > :global(p),
          :global(.de) .compare-wishlist > :global(div) {
            font-size: 0.7em;
          }

          :global(.product-landing .accordion .card) {
            border: none;
            border-bottom: 1px dotted;
            border-radius: 0;
          }

          :global(.product-landing .card-header) {
            cursor: pointer;
            background: none;
            border: none;

            padding: 0.75rem 0;
          }

          :global(.product-landing .card-header svg) {
            float: right;
            margin-top: 3px;
          }

          :global(.product-landing .details-accordion) {
            border: none;
            border-radius: 0;
          }

          :global(.product-landing .card-body) {
            padding: 0.9rem 0 1.45rem;
            font-size: 0.9em;
          }
          @media (max-width: 576px) {
            .slider-inner :global(.react-multi-carousel-list) {
              min-height: calc(100vw - 30px);
            }
          }
          @media (min-width: 576px) {
            .slider-inner {
              top: 69px;
            }
            :global(.product-landing) {
              padding-top: 30px;
              padding-bottom: 30px;
            }
            .compare-wishlist :global(p),
            .compare-wishlist :global(div) {
              margin-left: 0;
              margin-right: 0;
            }

            :global(.de) .compare-wishlist {
              display: block;
            }
          }

          @media (min-width: 768px) {
            .slider-inner {
              top: 109px;
            }

            :global(.product-landing .price) {
              font-size: 1.4em;
              margin-top: 20px;
            }

            :global(.product-landing) {
              padding-top: 70px;
              padding-bottom: 70px;
            }

            .discover-more {
              position: absolute;
              top: 50px;
              right: 0;
              width: 278px;
              height: 360px;
            }

            .discover-more-mobile-click-area {
              display: none;
            }
          }

          @media (min-width: 1024px) {
            :global(.de) .compare-wishlist {
              display: flex;
            }
            :global(.de) .compare-wishlist > :global(p),
            :global(.de) .compare-wishlist > :global(div) {
              font-size: 0.75em;
            }
          }

          .product-landing {
            position: relative;
          }

          .product-edition {
            font-size: 14px;
            margin: 10px 0;
            font-style: italic;
          }

          .description-link {
            margin: 10px 0 20px;
            font-weight: 400;
          }

          .description-link :global(a) {
            color: #000;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
};

export default ProductDetails;

import React from 'react';
import {
  FileViewerLayout,
  IMainState,
  MeetingLayout,
  MeetingLayoutMode,
  ViewportType
} from '../../../interfaces';
import { getViewportSize } from '../../../utils/window';
import {
  composeMeetingParentClassList,
  getViewportType,
  meetingColor
} from '../../../utils/meeting';

import SMLandscapeNormal from './SMLandscapeNormal';
import MDLandscapeNormal from './MDLandscapeNormal';
import SMGlobal, { SMGlobalSpec } from './SMGlobal';
import MDGlobal from './MDGlobal';
import { useSelector } from 'react-redux';
import { zIndex } from '../../../config';
import MeetingTimer from '../MeetingTimer';
import { lightOrDark } from '../../../utils/color';
import { logEvent } from '../../../analytics';
import { DID_UPDATE_LAYOUT_MODE } from '../../../utils/constants';
export const ViewportContext = React.createContext<{
  viewport?: ViewportType;
  isSmallScreen?: boolean;
  isPresentingContent?: boolean;
}>(undefined);

const MeetingLayoutWrapper = ({
  meetingLayout = {},
  children,
  logoUrl = '',
  darkUrl = '',
  isPresenter,
  primaryColor = '#000'
}: {
  meetingLayout?: MeetingLayout;
  children: React.ReactNode;
  logoUrl?: string;
  isPresenter?: boolean;
  primaryColor?: string;
  darkUrl?: string;
}) => {
  const [{ vh, vw }, setViewport] = React.useState({ vh: 0, vw: 0 });

  const isPopupOpen = useSelector(
    (state: IMainState) => state.clientState.vb?.popup?.open
  );
  const participantId = useSelector(
    (state: IMainState) => state.clientState.meeting?.localUser?.participantId
  );
  const isDocumentOpen = !!useSelector(
    (state: IMainState) => state.clientState.fileViewer?.currentPage
  );
  const isDocumentController =
    useSelector(
      (state: IMainState) => state.clientState.fileViewer?.controller
    ) === participantId;
  const documentViewLayout = useSelector(
    (state: IMainState) => state.clientState.fileViewer?.layoutMode
  );

  const viewportType = getViewportType({ height: vh, width: vw });
  const theme = React.useMemo(() => lightOrDark(primaryColor), [primaryColor]);

  React.useEffect(() => {
    const isFullContent = documentViewLayout === FileViewerLayout.FULLSCREEN;
    const parentClassName = composeMeetingParentClassList(
      meetingLayout,
      viewportType,
      isPopupOpen,
      isDocumentOpen,
      isDocumentController,
      isPresenter,
      isFullContent,
      theme
    );
    document.body.className = parentClassName;
    logEvent(DID_UPDATE_LAYOUT_MODE, DID_UPDATE_LAYOUT_MODE, {
      parentClassName
    });
  }, [
    meetingLayout,
    viewportType,
    isPopupOpen,
    isDocumentOpen,
    isDocumentController,
    isPresenter,
    documentViewLayout,
    theme
  ]);

  React.useEffect(() => {
    const onViewPortResize = () => {
      setViewport(getViewportSize());
    };
    onViewPortResize();
    window.addEventListener('resize', onViewPortResize);
    return () => {
      document.body.className = '';
      window.removeEventListener('resize', onViewPortResize);
    };
  }, []);

  return (
    <>
      <div className="logo-section">
        <img src={theme === 'light' ? darkUrl : logoUrl} alt="" />
      </div>
      {isPresenter && <MeetingTimer className="timer-section" />}
      <ViewportContext.Provider
        value={{
          viewport: viewportType,
          isSmallScreen:
            viewportType === ViewportType.SM_LANDSCAPE ||
            viewportType === ViewportType.SM_PORTRAIT,
          isPresentingContent:
            isDocumentOpen ||
            isPopupOpen ||
            !!meetingLayout?.enlargedVideoParticipantId ||
            meetingLayout?.mode === MeetingLayoutMode.ONE_THIRD
        }}
      >
        {children}
      </ViewportContext.Provider>

      <style jsx global>
        {`
          body {
            background: #0e0e0e;
          }
          .logo-section {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            height: ${SMGlobalSpec.logoSectionHeight}px;
            background: ${primaryColor};
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: ${zIndex.meetingVideosWrapper + 1};
          }
          .timer-section {
            position: fixed;
            left: 0;
            right: 0;
            top: ${SMGlobalSpec.logoSectionHeight}px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            z-index: ${zIndex.meetingVideosWrapper + 1};
            pointer-events: none;
          }
          .logo-section img {
            margin: auto;
            height: 60%;
            max-width: calc(100% - 220px);
          }
          .relative-wrapper {
            position: relative;
          }
          .text-success {
            color: ${meetingColor.success} !important;
          }
          .text-danger {
            color: ${meetingColor.warning} !important;
          }

          .video-thumbnail {
            display: inline-block;
            overflow: hidden;
            position: relative;
            background: #333;
            font-size: 13px;
            z-index: 2;
            writing-mode: initial;
          }

          .video-thumbnail .grid-line {
            position: absolute;
            opacity: 0.8;
            background: #fff;
            z-index: 10;
          }

          .video-thumbnail .line-vertical {
            top: 0;
            bottom: 0;
            left: 50%;
            width: 1px;
          }
          .video-thumbnail .line-horizontal {
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
          }

          .video-thumbnail video {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .video-thumbnail .initialName {
            font-family: Arial;
            font-weight: bold;
            font-size: 40px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            align-items: center;
            justify-content: center;
            color: #fff;
            opacity: 0.3;
            display: flex;
          }

          .video-thumbnail .display-name {
            position: absolute;
            bottom: 0;
            line-height: 1.2;
            left: 0;
            right: 0;
            padding: 5px;
            text-align: left;
            z-index: 12;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background: rgba(0, 0, 0, 0.4);
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 90%
            );
            font-weight: 400;
            letter-spacing: 0.3px;
            color: #ccc;
          }

          .tile-mode .video-thumbnail .display-name {
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(0, 0, 0, 0) 50%
            );
          }

          .enlarge .display-name {
            display: none;
          }

          .video-thumbnail:hover .display-name {
            overflow: visible;
            text-shadow: 0 0 2px #000;
          }

          .highlight-box {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 8;
          }
          .isSpeaking .highlight-box {
            box-shadow: inset 0 0 5px ${meetingColor.info} !important;
            border: 1px solid ${meetingColor.info} !important;
          }

          .isInvisible .highlight-box {
            box-shadow: inset 0 0 5px ${meetingColor.warning};
            border: 1px solid ${meetingColor.warning};
          }

          .isRaiseHand .highlight-box {
            box-shadow: inset 0 0 5px ${meetingColor.success};
          }

          .isSelected .highlight-box {
            box-shadow: inset 0 0 10px #fff;
            border: 1px solid #fff;
          }

          .in-meeting .vb-page-wrapper {
            height: auto !important;
            width: auto !important;
          }

          .in-meeting .vb-page-wrapper .logo-top,
          .in-meeting .vb-page-wrapper .logo-middle {
            display: none !important;
          }

          #presenter-wrapper {
            display: none;
          }
          #videos-wrapper {
            display: flex;
            align-items: center;
            overflow: auto;
          }

          #videos-container > .scroll-container {
            margin: auto;
          }

          /* DOCUMENT VIEWER */
          .in-meeting .document-fullscreen {
            top: ${SMGlobalSpec.logoSectionHeight}px !important;
          }
          .enlarge-video .btn-close {
            position: fixed;
            color: #fff;
            right: 0;
            border-radius: 100%;
            background: rgba(0, 0, 0, 0.8);
            font-size: 20px;
            line-height: 20px;
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 10px;
            border: none;
            top: ${SMGlobalSpec.logoSectionHeight}px;
            z-index: 2;
          }
          .in-meeting .popup-page-content {
            width: auto !important;
            border-radius: 0 !important;
          }

          .in-meeting .iframe-container {
            width: 100% !important;
          }

          .video-wrapper-bg {
            background: #0e0e0e;
            z-index: ${zIndex.meetingVideosWrapper - 1};
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
          }
          .SM .video-wrapper-bg {
            background: #000;
          }
          .MDPortrait .video-wrapper-bg {
            background: #000;
          }
          .tile-mode .video-wrapper-bg {
            background: #0e0e0e;
            top: ${SMGlobalSpec.logoSectionHeight}px;
          }
          .tile-mode #participant-wrapper {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: ${SMGlobalSpec.logoSectionHeight}px;
            left: 0;
            overflow: auto;
          }

          .tile-mode .scroll-container {
            width: 100%;
            margin: auto;
            font-size: 0;
          }

          .tile-mode .video-thumbnail {
            border-radius: 0;
            display: inline-block;
            border: 0.5px solid #000;
          }
          .tile-mode .video-thumbnail video {
            border-radius: 0;
          }

          .tile-mode #local-wrapper {
            z-index: 3;
          }

          .tile-mode #local-wrapper .video-thumbnail {
            border: none;
          }

          .video-content-area video {
            object-fit: cover;
          }
          body.light-theme .control-hide-button,
          body.light-theme .fsButton.meet-fullscreen,
          body.light-theme .notification-bell {
            color: #000 !important;
          }
          .normal-mode.one-third.MD .popup-page-content,
          .normal-mode.one-third.MD .popup-page,
          .normal-mode.one-third.MD .SBDocumentViewer {
            left: var(--onethird-left) !important;
          }
        `}
      </style>
      <SMGlobal />
      <SMLandscapeNormal />
      <MDGlobal />
      <MDLandscapeNormal />
    </>
  );
};

export default MeetingLayoutWrapper;

import { useTranslation } from '../../../../i18n';
import VCAMapSpot, { activePositionIcon } from './VCAMapSpot';
const VCAMapLegend = () => {
  const { t } = useTranslation();
  return (
    <div className="map-legend">
      <div className="content text-left">
        <div className="legend-item">
          <div className="icon">{activePositionIcon}</div>{' '}
          <span>{t('you_are_here')}</span>
        </div>
        <div className="legend-item">
          <div className="icon">
            <VCAMapSpot
              top={0}
              left={0}
              onClick={() => null}
              className="legend"
              visited
            />
          </div>{' '}
          <span>{t('explored')}</span>
        </div>
        <div className="legend-item">
          <div className="icon">
            <VCAMapSpot
              top={0}
              left={0}
              onClick={() => null}
              className="legend"
            />
          </div>{' '}
          <span>{t('unexplored')}</span>
        </div>
      </div>
      <style jsx>{`
        .map-legend {
          background: rgba(255, 255, 255, 0.4);
          border-radius: 10px;
          display: flex;
          align-items: center;
          padding: 8px 0 5px;
          color: #fff;
          position: fixed;
          right: 10px;
          width: 120px;
          bottom: 10px;
          animation: Visible 2s linear;
        }
        @keyframes Visible {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .map-legend :global(.spot-legend) {
          position: relative !important;
          top: auto !important;
          left: auto !important;
          transform: none !important;
          margin: auto;
        }
        .content {
          margin: auto;
        }
        .legend-item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-left: -3px;
          font-size: 12px;
          pointer-events:none;
        }
        .icon {
          width: 35px;
          display: flex;
          align-items: center;
        
        }
        .icon :global(svg) {
          margin: auto;
          height: auto;
        }
        @media (min-width: 768px) {
          .map-legend {
            padding: 25px 0 20px;
            width: 200px;
          }
          .legend-item {
            font-size: 1em;
            margin-left: -14px;
            padding: 5px 0;
          }
          .icon {
            width: 35px;
            width: 50px;
          }
        }
      `}</style>
    </div>
  );
};

export default VCAMapLegend;

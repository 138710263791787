import React, { useCallback } from 'react';
import Slider from 'react-slick';
import { useTranslation } from '../../../../i18n';
import { parsePlainTextToRichText } from '../../../../utils/string';
import { CHScenes } from '../../PSInterface';
import { CHArrowIcon } from '../CHIcons';
import CHMapDial from './CHMapDial';
import { mapAsset, mapSpot } from './CHMaps';

export const CHNextArrow = ({ onClick }: { onClick?: () => any }) => (
  <div onClick={onClick} className="slick-arrow slick-next">
    <CHArrowIcon direction="right" />
  </div>
);

export const CHPrevArrow = ({ onClick }: { onClick?: () => any }) => (
  <div onClick={onClick} className="slick-arrow slick-prev">
    <CHArrowIcon direction="left" />
  </div>
);

const CHMapMobile = ({
  activeScene,
  currentScene,
  onClickSpot,
  setActiveScene,
  landscape
}: {
  activeScene: CHScenes;
  currentScene: CHScenes;
  onClickSpot?: (scene: CHScenes) => void;
  setActiveScene?: (scene: CHScenes) => void;
  landscape?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'ko' ? 'ko' : 'en';
  const pagingRefs = mapSpot.map(() => React.useRef<HTMLDivElement>());

  const findScenceIndex = useCallback((s: CHScenes) => {
    return mapSpot.indexOf(mapSpot.find(({ command }) => command === s));
  }, []);

  const gotoScenceSlide = useCallback((s: CHScenes) => {
    const sIndex = findScenceIndex(s);

    pagingRefs?.[sIndex]?.current?.click();
  }, []);

  const sliderSettings = {
    nextArrow: <CHNextArrow />,
    prevArrow: <CHPrevArrow />,
    dots: true,
    customPaging: (i) => <div ref={pagingRefs[i]} />,
    afterChange: (index) => {
      setActiveScene(mapSpot[index].command);
    }
  };

  React.useEffect(() => {
    gotoScenceSlide(currentScene);
  }, [currentScene]);

  React.useEffect(() => {
    if (currentScene !== activeScene) {
      gotoScenceSlide(activeScene);
    }
  }, [activeScene, currentScene]);

  const SpotArrow = ({
    scene,
    active
  }: {
    scene: CHScenes;
    active?: boolean;
  }) => (
    <img
      src={`${mapAsset}/${landscape ? 'ml' : 'portrait'}_${scene}.png`}
      alt="spot-arrow"
      className={`spot-arrow ${active ? 'active' : ''}`}
    />
  );
  const SpotNav = ({ title, scene }: { title: string; scene: string }) => (
    <div className="spot">
      <div className="chopard-sans title">{title}</div>
      <div className="chopard-serif scene">
        {parsePlainTextToRichText(scene)}
      </div>
      <style jsx>{`
        .spot {
          text-transform: uppercase;
          text-align: center;
          font-size: 4vw;
          height: 18vw;
        }
        .title {
          font-size: 0.8em !important;
        }
        .spot:last-child {
          margin-bottom: 0 !important;
        }
        @media (min-aspect-ratio: 68/130) {
          .spot {
            font-size: ${landscape ? '4vh' : '2vh'};
            height: ${landscape ? '17vh' : '8vh'};
          }
        }
      `}</style>
    </div>
  );

  return (
    <div className="CHMap CHMapMobile d-flex flex-column h-100">
      <div className="inner">
        <div className="col-main">
          <div className="col-watch">
            <img
              src={`${mapAsset}/${
                landscape ? 'watch-m-l' : 'watch-portrait'
              }.png`}
              alt="map"
            />
            <CHMapDial
              activeScene={activeScene}
              setActiveScene={(s) => {
                gotoScenceSlide(s);
                setActiveScene(s);
              }}
              onClickSpot={onClickSpot}
              isTouch={true}
            />
          </div>
          <div className="col-item">
            <Slider {...sliderSettings}>
              {mapSpot.map((spot) => (
                <SpotNav key={spot.command} {...spot.detail[lang]} />
              ))}
            </Slider>
          </div>

          <div className="arrows">
            <div className="arrows-container d-flex flex-column h-100">
              {mapSpot.map((spot) => (
                <SpotArrow
                  key={spot.command}
                  scene={spot.command}
                  active={activeScene === spot.command}
                />
              ))}
            </div>
          </div>
        </div>

        <button
          className="btn btn-outline-light chopard-sans"
          onClick={() => onClickSpot(activeScene)}
        >
          {t('enter')}
        </button>
      </div>

      <style jsx>{`
        .col-main {
          position: relative;
        }
        .CHMap {
          position: relative;
        }
        .CHMap :global(.slick-arrow) {
          width: 15vw;
          height: 15vw;
          z-index: 2;
        }

        .CHMap :global(.slick-next) {
          right: -5vw;
        }

        .CHMap :global(.slick-prev) {
          left: -5vw;
        }

        .CHMap :global(.slick-arrow:before) {
          display: none !important;
        }

        .CHMap :global(.slick-arrow svg) {
          width: 100%;
          height: auto;
        }

        .inner {
          margin: auto;
          width: 96%;
        }

        :global(.in-meeting) .inner {
          width: 70%;
        }
        .col-watch {
          position: relative;
        }
        .col-watch img {
          width: 100%;
          height: auto;
          user-select: none;
        }
        .btn {
          position: relative;
          z-index: 5;
          border-radius: 0;
          padding: 0;
          line-height: 1;
          font-size: min(4vw, 20px);
          letter-spacing: 0.04em;
          width: 30vw;
          height: min(10vw, 50px);
        }
        :global(.client) .btn {
          display: none;
        }
        .arrows,
        .arrows :global(img) {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          pointer-events: none;
        }
        .arrows :global(img) {
          opacity: 0.008;
          width: 100%;
          height: auto;
        }
        .arrows :global(img.active) {
          opacity: 1;
        }

        @media (min-aspect-ratio: 68/130) {
          .inner {
            max-width: ${landscape ? '60vh' : '48vh'};
          }
          :global(.in-meeting) .inner {
            max-width: ${landscape ? '60vh' : '43vh'};
          }

          .CHMap :global(.slick-arrow) {
            width: ${landscape ? '15vh' : '8vh'};
            height: ${landscape ? '15vh' : '8vh'};
            z-index: 2;
          }

          .CHMap :global(.slick-next) {
            right: ${landscape ? '-16vh' : '-2vh'};
          }

          .CHMap :global(.slick-prev) {
            left: ${landscape ? '-16vh' : '-2vh'};
          }

          .col-item {
            margin-top: ${landscape ? '-10vh' : '0'};
          }

          .btn {
            font-size: ${landscape ? 'min(4vh, 20px)' : 'min(2vh, 16px)'};
            letter-spacing: 0.04em;
            width: ${landscape ? '30vh' : '15vh'};
            height: ${landscape ? 'min(10vh, 50px)' : 'min(3.8vh, 50px)'};
            max-width: 200px;
            max-height: 50px;
            margin-top: 2px;
          }
        }
      `}</style>
    </div>
  );
};

export default CHMapMobile;

import React from 'react';
import { useDispatch } from 'react-redux';
import { LoubiAirwaysPopupContentType } from '../../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysPopupContentType } from '../../../../redux/customActions/loubiAirways';
import { useLoubiAirwaysState } from '../../../hooks/loubiAirways';
import LoubiAirwaysPopupContent from '../../../ThreejsAnimation/louboutin/LoubiAirwaysPopupContent';

const LoubiAirwaysInstructionPopup = () => {
  const { landingVideo, popupContentType } = useLoubiAirwaysState();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (landingVideo?.hidden) {
      dispatch(
        actionUpdateLoubiAirwaysPopupContentType(
          LoubiAirwaysPopupContentType.WELCOME_INSTRUCTION
        )
      );
    }
  }, [landingVideo?.hidden]);
  if (popupContentType === LoubiAirwaysPopupContentType.WELCOME_INSTRUCTION) {
    return <LoubiAirwaysPopupContent />;
  }
  return null;
};

export default LoubiAirwaysInstructionPopup;

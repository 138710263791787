import { useRouter } from 'next/router';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { BiVideo, BiVideoOff } from 'react-icons/bi';
import { BsChevronCompactDown } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { assetBaseUrl } from '../../../config';
import { useTranslation } from '../../../i18n';
import {
  DevicePermissionStatus,
  IDevicePermission,
  IMainState,
  IVirtualBoutiqueConfig,
  MeetingRole,
  ParticipantMeetingState
} from '../../../interfaces';
import { actionEnterMeeting } from '../../../redux/actions';
import { actionPlayIntroductionVideo } from '../../../redux/customActions/loubiAirways';
import {
  DID_CLICK_JOIN_NOW,
  DID_ENTER_MEETING_FROM_LOUNGE
} from '../../../utils/constants';
import {
  isUserOnMobileOnly,
  isUserOnMobileSafari15
} from '../../../utils/deviceDetector';
import { DID_LOAD_LOUNGE } from '../../../utils/events/meeting';
import {
  getMeetingRole,
  isUnsupportedBrowser,
  meetingColor
} from '../../../utils/meeting';
import { LoubiAirFontFamily } from '../../BrandStyle/LouboutinStyle';
import { useLoubiAirwaysState } from '../../hooks/loubiAirways';
import { useMeetingDebugLogger } from '../../hooks/meeting';
import { getLoungeWelcomeMessage } from '../../storeComponentFactory';
import LoubiAirwaysLandingVideo from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/LandingVideo';
import LoubiAirwaysPageContainer from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/LoubiAirwaysPageContainer';
import { SessionContext } from '../../VirtualBoutique/SessionContextContainer';
import DeviceControl from '../Controls/DevicesControl';
import { MeetingContext } from '../MeetingContext';
import LoungeLocalVideo from './LoungeLocalVideo';
import LoungeNameInput from './LoungeNameInput';
import BrowserPrompt from '../BrowserPrompt';
import LoungeParticipantsOverview from './LoungeParticipantsOverview';

const LoubiAirwaysLounge = ({
  config,
  storeId,
  permissionStatus,
  isBrowserSupported = true,
  onToggleMuteVideo,
  joinRoom
}: {
  config: IVirtualBoutiqueConfig;
  storeId: string;
  permissionStatus?: IDevicePermission;
  isBrowserSupported?: boolean;
  onToggleMuteVideo: (mute: boolean) => void;
  joinRoom: () => void;
}) => {
  const router = useRouter();
  const source = router.query?.source as string | undefined;
  const role = router.query?.role as string | undefined;
  const meetingRole = getMeetingRole(role);
  const dispatch = useDispatch();
  const clientState = useSelector((state: IMainState) => state.clientState);
  const user = clientState?.user;
  const meeting = clientState?.meeting;
  const devices = meeting?.localUser?.availableDevices;
  const inspirations = clientState?.inspirationsList || [];
  const remoteUsers = meeting?.remoteUsers || {};
  const landingVideoHidden = useLoubiAirwaysState()?.landingVideo?.hidden;
  const shouldShowIntroVideos =
    useLoubiAirwaysState()?.playIntroductionVideosInMeeting;
  const [isMobileSafari15, setIsMobileSafari15] = React.useState(false);
  const [isOnMobile, setIsOnMobile] = React.useState(false);
  const [isJoining, setIsJoining] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const { room, connection } = React.useContext(MeetingContext);
  const { shouldMuteVideo } = React.useContext(SessionContext);

  const loubiFont = LoubiAirFontFamily();
  const advisorName = meeting?.advisorName;

  const shouldJoinRoomInstantly = !(
    meetingRole === MeetingRole.MC || meetingRole === MeetingRole.CLIENT
  );

  const [isReadyToJoin, setIsReadyToJoin] = React.useState(
    shouldJoinRoomInstantly
  );

  const shouldDisplayJoinButton =
    !isReadyToJoin &&
    (meetingRole === MeetingRole.MC || meetingRole === MeetingRole.CLIENT);

  const meetingDebugLogger = useMeetingDebugLogger();
  const permission = React.useMemo(() => {
    const isWaitingPermission = Object.values(permissionStatus).every(
      (p) => p === DevicePermissionStatus.WAITING
    );
    if (isWaitingPermission) {
      return DevicePermissionStatus.WAITING;
    }
    const isRejectedPermission = Object.values(permissionStatus).every(
      (p) => p === DevicePermissionStatus.REJECTED
    );
    if (isRejectedPermission) {
      return DevicePermissionStatus.REJECTED;
    }
    return DevicePermissionStatus.GRANTED;
  }, [permissionStatus]);

  const isRejectCamera = React.useMemo(
    () => permissionStatus.video === DevicePermissionStatus.REJECTED,
    [permissionStatus.video]
  );

  const muteVideo = meeting?.localUser?.videoMuted || isRejectCamera;

  const shouldShowToggleMuteVideo = !shouldMuteVideo && !isRejectCamera;

  React.useEffect(() => {
    setIsOnMobile(isUserOnMobileOnly());
    setIsMobileSafari15(isUserOnMobileSafari15());
    meetingDebugLogger(DID_LOAD_LOUNGE);
  }, []);

  React.useEffect(() => {
    if (landingVideoHidden) {
      enterMeeting();
    }
  }, [landingVideoHidden]);

  React.useEffect(() => {
    if (meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE) {
      room?.setDisplayName(user?.alias);
      setIsReadyToJoin(true);
      setIsJoining(false);
    }
  }, [meeting?.state]);

  const { t } = useTranslation();

  const btnJoinText = !isRejectCamera
    ? t('join_now')
    : t('join_now_without_camera');

  const enterMeeting = () => {
    dispatch(actionEnterMeeting());
    logEvent(DID_ENTER_MEETING_FROM_LOUNGE, DID_ENTER_MEETING_FROM_LOUNGE, {
      meetingId: meeting.meetingId,
      participantId: room?.myUserId(),
      isPresenter: meeting.isPresenter
    });
  };

  const isAdvisorReady =
    meeting?.localUser?.tracks?.length >= 1 &&
    meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE &&
    meeting?.localUser?.role === MeetingRole.ADVISOR;

  const handleToggleVideo = (mute: boolean) => {
    onToggleMuteVideo(mute);
  };

  const shouldRenderPrompt = React.useMemo(() => isUnsupportedBrowser(), []);

  if (shouldShowIntroVideos) {
    return (
      <LoubiAirwaysLandingVideo
        isOnMobile={isOnMobile}
        disableSkip={role !== 'advisor'}
        storeId={clientState?.store?.id}
      />
    );
  }

  return (
    <>
      {meeting?.isPresenter && (
        <LoungeParticipantsOverview
          remoteUsers={remoteUsers}
          breakPoint={1023}
        />
      )}

      <LoubiAirwaysPageContainer
        className={`loubiLounge ${meeting?.isPresenter && 'presenter'}`}
      >
        <div
          className={`meeting-lounge ${meeting?.isPresenter && 'presenter'}`}
        >
          <div className="content-container">
            <div className="content-inner">
              <img
                className="logo"
                src={`${assetBaseUrl}/loubiairways/pages/v2/cl_logo.png`}
              />
              <h2>
                {getLoungeWelcomeMessage(
                  storeId,
                  config.brandName,
                  config.loungeTagLine,
                  source,
                  t
                )}
                {meeting?.isPresenter && (
                  <span>{t('vb:you_are_captain_of_flight')}</span>
                )}
                {!meeting?.isPresenter &&
                  advisorName &&
                  source !== 'medialaunch' && (
                    <span>
                      YOUR CAPTAIN OF THIS FLIGHT IS {advisorName.toUpperCase()}
                    </span>
                  )}
              </h2>

              {shouldRenderPrompt ? (
                <BrowserPrompt />
              ) : (
                <>
                  <p className="reminder">{t('ensure_mic_camera_working')}</p>

                  {!!devices?.length && (
                    <DeviceControl
                      controlSelectorPrefix="LoubiAirwaysLounge"
                      devices={devices}
                    />
                  )}

                  <div className="media-container">
                    {!isBrowserSupported &&
                    permission === DevicePermissionStatus.WAITING ? (
                      <p
                        className="checking-message text-center"
                        style={{ padding: '20px 40px' }}
                      >
                        <strong>{t('checking_devices')}</strong>
                        <span className="unsupported-message">
                          {' '}
                          {t('vb:walkthrough_browser_support.line1')}
                          {` `}
                          <a
                            style={{
                              color: 'inherit',
                              textDecoration: 'underline'
                            }}
                            href="https://www.google.com/chrome/"
                            target="_blank"
                          >
                            {t('vb:walkthrough_browser_support.line2')}
                          </a>
                        </span>
                      </p>
                    ) : (
                      <>
                        {permission === DevicePermissionStatus.GRANTED && (
                          <>
                            <div
                              className={`inner ${
                                muteVideo ? 'video-muted' : ''
                              }`}
                            >
                              <div className="video-container">
                                <LoungeLocalVideo mute={muteVideo} />
                              </div>
                            </div>

                            <div className="media-text">
                              <LoungeNameInput
                                source={source}
                                setShowError={setShowError}
                                role={role}
                              />

                              {shouldShowToggleMuteVideo && (
                                <div className="btn-group">
                                  <button
                                    className={`btn btn-outline-danger btn-toggle-camera ${
                                      !muteVideo && 'active'
                                    }`}
                                    onClick={() => {
                                      handleToggleVideo(false);
                                    }}
                                  >
                                    <BiVideo />
                                  </button>
                                  <button
                                    className={`btn btn-outline-danger btn-toggle-camera ${
                                      muteVideo && 'active'
                                    }`}
                                    onClick={() => {
                                      handleToggleVideo(true);
                                    }}
                                  >
                                    <BiVideoOff />
                                  </button>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {permission === DevicePermissionStatus.REJECTED && (
                          <p
                            className="input-error text-center"
                            style={{ padding: '20px 40px', color: '#cf152d' }}
                          >
                            {t('input_device_error')}
                          </p>
                        )}
                        {permission === DevicePermissionStatus.WAITING && (
                          <>
                            <div
                              className="checking-message text-center"
                              style={{ padding: '20px 40px', width: '100%' }}
                            >
                              <Spinner animation="border" />
                              <br />
                              {t('waiting_devices_permission')}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {showError && (
                    <div
                      className="text-center"
                      style={{
                        color: meetingColor.warning,
                        marginTop: '10px',
                        marginBottom: '-10px'
                      }}
                    >
                      {t('please_enter_your_name')}
                    </div>
                  )}

                  {isMobileSafari15 && (
                    <p className="lounge-prompt">
                      {t('please_use_headset_ios15')}
                    </p>
                  )}
                  {isRejectCamera && (
                    <p className="lounge-prompt">
                      {t('your_camera_is_not_accessible')}
                    </p>
                  )}

                  {meeting?.isPresenter &&
                    permission === DevicePermissionStatus.GRANTED && (
                      <button
                        disabled={!isAdvisorReady}
                        className="btn btn-dark btn-start btn-lg"
                        onClick={() => {
                          if (!user?.alias) {
                            setShowError(true);
                            return;
                          }
                          dispatch(actionPlayIntroductionVideo());
                        }}
                      >
                        {isAdvisorReady ? (
                          t('take_off_exclamation')
                        ) : (
                          <Spinner animation="border" size="sm" />
                        )}
                      </button>
                    )}

                  {!meeting?.isPresenter && (
                    <div className="client-note">
                      {shouldDisplayJoinButton ? (
                        <div>
                          <button
                            disabled={!connection}
                            className="btn btn-dark btn-start btn-client"
                            onClick={() => {
                              if (!user?.alias) {
                                setShowError(true);
                                return;
                              }
                              logEvent(DID_CLICK_JOIN_NOW, DID_CLICK_JOIN_NOW, {
                                meetingId: meeting.meetingId,
                                participantId: room?.myUserId(),
                                isPresenter: meeting.isPresenter
                              });
                              joinRoom();
                              setIsJoining(true);
                            }}
                          >
                            {isJoining || !connection ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              btnJoinText
                            )}
                          </button>
                        </div>
                      ) : (
                        <p>{t('meeting_will_start_shortly')}</p>
                      )}
                      {!!inspirations.length && (
                        <a href="#inspirationsSection">
                          <BsChevronCompactDown />
                        </a>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <style jsx global>{`
            .loubiLounge .content {
              padding-top: 0 !important;
              padding-bottom: 10px !important;
            }
            .loubiLounge {
              overflow: auto;
              z-index: 2002;
            }
            .loubiLounge .dropdown-toggle {
              background-color: #cf152d !important;
              border-color: #cf152d !important;
              color: #fff !important;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
            .loubiLounge .button-container {
              background-color: #f3f3d1;
              border-radius: 10px;
            }
            .loubiLounge .button-container .meeting-button {
              color: #cf152d !important;
            }
            .loubiLounge .button-container svg path {
              fill: #cf152d !important;
            }
            .loubiLounge .input-name,
            .loubiLounge .input-name:focus,
            .loubiLounge .input-name:active {
              font-size: 1em;
              background: transparent;
              border: none;
              outline: none;
              color: #cf152d;
              font-family: ${loubiFont};
            }

            p {
              font-family: ${loubiFont};
              color: #cf152d;
            }
            .lounge-name-input p {
              font-size: 0.7em !important;
            }
            .reminder {
              color: #41747f;
              text-transform: uppercase;
              font-size: 0.8em;
            }
            @media (orientation: portrait) {
              .loubiLounge {
                justify-content: flex-start !important;
              }
            }
            @media (orientation: landscape) and (max-height: 1100px) {
              .loubiLounge {
                justify-content: flex-start !important;
              }
            }
            @media (min-width: 540px) {
              .loubiLounge .video-thumbnail,
              .loubiLounge .video-container {
                border-radius: 20px !important;
                overflow: hidden !important;
              }
              .loubiLounge .video-thumbnail video {
                border-radius: 20px !important;
              }
              .local-video-container {
                height: 200px !important;
              }
            }
            @media (min-width: 1023px) {
              .lounge-name-input p {
                font-size: 1.2em !important;
              }
              .loubiLounge .video-thumbnail,
              .loubiLounge .video-container {
                border-radius: 40px !important;
                overflow: hidden !important;
              }
              .loubiLounge .video-thumbnail video {
                border-radius: 40px !important;
              }
              .loubiLounge.presenter {
                left: 280px;
              }
              .local-video-container {
                height: 240px !important;
              }
            }
          `}</style>

          <style jsx>
            {`
              .unsupported-message {
                font-size: 0.9em;
                display: block;
                -webkit-animation: fade-in 1.2s linear 5s both;
                animation: fade-in 1.2s linear 5s both;
              }
              .input-error {
                background-color: #f3f3d1;
                color: #cf152d;
                padding: 20px 10px;
                border-radius: 20px;
              }
              .input-error a {
                color: inherit;
                text-decoration: underline;
              }

              .logo {
                width: 100px;
                margin-top: 20px;
              }

              .meeting-lounge h2 {
                margin: 10px 0 5px;
                font-family: ${loubiFont};
                color: #41747f;
                font-size: 1em;
              }
              .meeting-lounge :global(h2 span) {
                display: block;
              }
              .content-inner {
                overflow: visible;
              }
              .media-container {
                display: block;
                overflow: hidden;
                border-radius: 10px;
                color: #cf152d !important;
                background: #f3f3d1;
                margin-top: 20px;
              }

              :global(.reminder) {
                color: #cf152d;
                text-transform: none;
              }

              .media-container .inner {
                display: flex;
                align-items: center;
                text-align: left;
                flex-wrap: nowrap;
                overflow: hidden;
                color: #cf152d;
                border-bottom: 1px solid #fff;
              }

              .media-container p {
                margin: 0;
              }

              .inner > :global(div:first-child) {
                width: 100%;
              }
              .media-text {
                padding: 20px;
              }
              .form-group {
                margin: 0;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px dashed #cf152d;
                padding-bottom: 5px;
              }

              .client-note {
                padding: 30px;
              }
              .client-note :global(svg) {
                width: 50px;
                height: auto;
              }

              .client-note a {
                color: #000;
              }
              .btn-success {
                outline: none;
                box-shadow: none;
              }

              :global(.remote-users) {
                background-color: #cf152d !important;
                width: 280px !important;
              }

              :global(.btn-start) {
                margin-top: 40px;
                letter-spacing: 10;
                margin-bottom: 20px;
                background-color: #cf152d;
                font-family: ${loubiFont};
                border-color: #cf152d;
                font-style: italic;
                font-size: 25px;
                border-radius: 15px;
              }
              :global(.btn-client) {
                margin-top: 0 !important;
              }
              .btn-toggle-camera {
                border-color: #cf152d;
                color: #cf152d;
                margin-top: 20px;
              }
              .btn-toggle-camera:hover {
                color: #fff;
              }
              .btn-toggle-camera.active {
                background-color: #cf152d !important;
              }

              .btn-toggle-camera :global(svg) {
                height: 25px;
                width: auto;
              }

              @-webkit-keyframes fade-in {
                0% {
                  opacity: 0;
                }

                100% {
                  opacity: 1;
                }
              }
              @keyframes fade-in {
                0% {
                  opacity: 0;
                }

                100% {
                  opacity: 1;
                }
              }

              @media (min-width: 540px) {
                .media-container .inner {
                  display: block;
                  border: none;
                }
                .media-container .inner :global(div:first-child) {
                  width: 100%;
                }
                .media-container .inner p {
                  display: none;
                }
                .media-container {
                  display: flex;
                  align-items: center;
                  text-align: left;
                  flex-wrap: nowrap;
                  align-items: stretch;
                  background-color: transparent;
                }
                .media-container > .media-text {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  background-color: #f3f3d1;
                  border-radius: 20px;
                }
                .media-container > .media-text p {
                  display: block;
                  margin-bottom: 0;
                }
                .media-container > :global(div:first-child) {
                  width: 50%;
                }

                .media-container > :global(div + div) {
                  width: 50%;
                }
              }

              @media (min-width: 768px) {
                .content-inner {
                  margin: auto;
                  max-width: 800px;
                }
              }

              @media (min-width: 1023px) {
                .logo {
                  width: 150px;
                  margin-top: 30px;
                }
                p {
                  font-size: 1.125em;
                }
                .meeting-lounge h2 {
                  font-size: 1.8em;
                  line-height: 1.4;
                  margin: 30px 0 10px;
                }
                :global(.reminder) {
                  font-size: 1.2em !important;
                }
                .meeting-lounge :global(h2 span > span) {
                  display: inline-block;
                }

                :global(.reminder) {
                  font-size: 1.2em !important;
                }

                .form-group {
                  margin: 40px 0 20px;
                }
                .input-name,
                .input-name:focus,
                .input-name:active {
                  font-size: 1.2em;
                }

                .media-container > .media-text {
                  border-radius: 40px;
                }
              }

              .lounge-prompt {
                margin: 15px 0 0 0;
                color: #212529;
                font-size: 0.8em;
                font-family: 'Source Sans Pro', sans-serif;
              }
            `}
          </style>
        </div>
      </LoubiAirwaysPageContainer>
    </>
  );
};

export default LoubiAirwaysLounge;

import React from 'react';
import { PageView } from '../../../utils/pageView';
import { DID_CLICK_VB_DISCOVER_MORE } from '../../../utils/constants';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { PanoDiscoverMore } from '../../../interfaces';

const DiscoverButton = ({
  openPopup,
  config
}: {
  openPopup: (type: PageView, id: string) => void;
  config: PanoDiscoverMore;
}) => {
  const [render, setRender] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const type = config?.type;
  const id = config?.id;
  const open = config.open;

  React.useEffect(() => {
    setShow(open);
    let timer;
    if (open) {
      setRender(true);
    } else {
      timer = setTimeout(() => setRender(false), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open]);

  const { t } = useTranslation();

  if (render) {
    return (
      <>
        <div
          className={`discoverbutton ${show ? 'show' : 'hide'}`}
          onClick={() => {
            openPopup(type, id);
            logEvent(DID_CLICK_VB_DISCOVER_MORE, id, { pageType: type });
          }}
        >
          <div className="shape-container">
            <div className="shape" id="round1"></div>
            <div className="shape" id="round2"></div>
            <div className="shape" id="round3"></div>
            <div className="shape" id="round4">
              <span className="label">{t('vb:discover_more')}</span>
            </div>
          </div>
        </div>

        <style jsx>{`
          .discoverbutton {
            position: absolute;
            top: 18%;
            margin-top: -15px;
            right: 10px;
            cursor: pointer;
            z-index: 1;
          }

          @media (min-width: 768px) {
            .discoverbutton {
              right: 24px;
              top: 25%;
            }
          }

          .shape-container {
            width: 90px;
            height: 90px;
            position: relative;
          }
          .show {
            -webkit-animation: slide-in-top 0.7s
              cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
            animation: slide-in-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              0.5s both;
          }

          .hide {
            -webkit-animation: slide-out-bottom 0.5s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
            animation: slide-out-bottom 0.5s
              cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
          }

          .shape {
            opacity: 0.3;
            position: absolute;
            left: 0;
            top: 0;
            background: #fff;
            width: 90px;
            height: 90px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          }

          #round1 {
            border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
            -webkit-animation: rotate-scale-up 3s linear infinite both;
            animation: rotate-scale-up 3s linear infinite both;
          }

          #round2 {
            border-radius: 40% 50% 50% 50% / 40% 60% 40% 60%;
            -webkit-animation: rotate-scale-up 6s linear infinite reverse both;
            animation: rotate-scale-up 6s linear infinite reverse both;
            background-color: #000;
          }

          #round3 {
            border-radius: 50% 40% 50% 50% / 60% 50% 60% 50%;
            -webkit-animation: rotate-scale-up 5s linear infinite both;
            animation: rotate-scale-up 5s linear infinite both;
          }

          #round4 {
            border-radius: 100%;
            width: 76px;
            height: 76px;
            opacity: 1;
            left: 8px;
            top: 8px;
            text-align: center;
            display: flex;
            align-items: center;
          }

          .label {
            padding: 0 10px;
            margin: auto;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.2;
          }

          @-webkit-keyframes rotate-scale-up {
            0% {
              -webkit-transform: scale(0.9) rotateZ(0);
              transform: scale(0.9) rotateZ(0);
            }

            50% {
              -webkit-transform: scale(1.1) rotateZ(180deg);
              transform: scale(1.1) rotateZ(180deg);
            }

            100% {
              -webkit-transform: scale(0.9) rotateZ(360deg);
              transform: scale(0.9) rotateZ(360deg);
            }
          }

          @keyframes rotate-scale-up {
            0% {
              -webkit-transform: scale(0.9) rotateZ(0);
              transform: scale(0.9) rotateZ(0);
            }

            50% {
              -webkit-transform: scale(1.1) rotateZ(180deg);
              transform: scale(1.1) rotateZ(180deg);
            }

            100% {
              -webkit-transform: scale(0.9) rotateZ(360deg);
              transform: scale(0.9) rotateZ(360deg);
            }
          }

          @-webkit-keyframes slide-in-top {
            0% {
              -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
              opacity: 0;
            }

            100% {
              -webkit-transform: translateY(0);
              transform: translateY(0);
              opacity: 1;
            }
          }

          @keyframes slide-in-top {
            0% {
              -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
              opacity: 0;
            }

            100% {
              -webkit-transform: translateY(0);
              transform: translateY(0);
              opacity: 1;
            }
          }

          @-webkit-keyframes slide-out-bottom {
            0% {
              -webkit-transform: translateY(0);
              transform: translateY(0);
            }

            100% {
              -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
            }
          }

          @keyframes slide-out-bottom {
            0% {
              -webkit-transform: translateY(0);
              transform: translateY(0);
            }

            100% {
              -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
            }
          }
        `}</style>
      </>
    );
  }

  return null;
};

export default DiscoverButton;

import React from 'react';
import { IoIosVolumeOff, IoIosVolumeHigh } from 'react-icons/io';
import { logEvent } from '../../../../analytics';
import {
  DID_MUTE_BACKGROUND_SOUND,
  DID_UNMUTE_BACKGROUND_SOUND
} from './../../../../utils/constants';
import { isUserOnMobile } from './../../../../utils/deviceDetector';

const LoungeAudio = ({ allowToPlay }: { allowToPlay: boolean }) => {
  const audioRef: React.RefObject<HTMLAudioElement> = React.createRef();
  const [play, setPlay] = React.useState(true);

  React.useEffect(() => {
    setPlay(!isUserOnMobile());
  }, []);

  React.useEffect(() => {
    if (play && allowToPlay) {
      audioRef.current.play();
    }
    if ((play && !allowToPlay) || !play) {
      audioRef.current.pause();
    }
  }, [play, allowToPlay]);

  return (
    <>
      <audio
        ref={audioRef}
        autoPlay
        loop
        src="https://panos.inspify.io/virtual_boutique/0bc6b25d-84a0-405d-bc46-1e7e816ba5c1/jlcwaitingroom.mp3"
      />
      <div className="toggle-audio with-shadow">
        {play ? (
          <IoIosVolumeHigh
            color="#fff"
            onClick={() => {
              setPlay(false);
              logEvent(DID_MUTE_BACKGROUND_SOUND);
            }}
          />
        ) : (
          <IoIosVolumeOff
            color="#fff"
            onClick={() => {
              setPlay(true);
              logEvent(DID_UNMUTE_BACKGROUND_SOUND);
            }}
          />
        )}
      </div>
      <style jsx>{`
        .toggle-audio {
          position: fixed;
          bottom: 10px;
          right: 10px;
          z-index: 1001;
          cursor: pointer;
        }
        .toggle-audio :global(svg) {
          width: 35px;
          height: auto;
        }
      `}</style>
    </>
  );
};

export default LoungeAudio;

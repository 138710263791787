import React from 'react';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../analytics';
import { DID_SHARE_PAGE } from '../../utils/constants';
import { getShortenedUrl, ShareScenario } from '../../utils/shareUrl';
import { getWhatsappLink } from '../../utils/whatsappLink';
import FaceBookSvgIcon from './Icons/FaceBookSvgIcon';
import ShareSvgIcon from './Icons/ShareSvgIcon';
import WhatsappSvgIcon from './Icons/WhatsappSvgIcon';
import { useTranslation } from '../../i18n';
import { performNativeShare } from '../../utils/window';
import { RiWhatsappFill } from 'react-icons/ri';
import KakaoTalkSvgIcon from './Icons/KakaoTalkSvgIcon';

interface IconConfig {
  fill?: boolean;
  color?: string;
}

const ShareButton = ({
  message,
  iconConfig = {
    fill: false,
    color: '#000'
  },
  title
}: {
  message: string;
  iconConfig?: IconConfig;
  title?: string;
}) => {
  const [showShareList, setShowShareList] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState<string>(null);

  React.useEffect(() => {
    getShortenedUrl(ShareScenario.GENERAL).then(setShareUrl);
  }, []);

  const { t } = useTranslation();

  const whatsappText = message || shareUrl || '';

  return (
    <>
      <button
        className="nav-item"
        onClick={() => {
          const onNativeShare = () => {
            logEvent(DID_SHARE_PAGE, 'native');
          };
          const onFailedNativeShare = () => {
            setShowShareList(!showShareList);
          };
          performNativeShare(
            {
              title: 'INSPIFY',
              text: message,
              url: shareUrl
            },
            onNativeShare,
            onFailedNativeShare
          );
        }}
      >
        <span className="nav-item__icon">
          <ShareSvgIcon classname="with-shadow" />
        </span>
        <span className="nav-item__label">
          {title || t('vb:share_vboutique')}
        </span>
      </button>

      <Transition in={showShareList} timeout={200} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`share-list fadein ${state}`}>
            <a
              className="resp-sharing-button__link"
              data-channel="whatsapp"
              href={getWhatsappLink('', whatsappText)}
              target="_blank"
              rel="noopener"
              aria-label="WhatsApp"
              onClick={() => logEvent(DID_SHARE_PAGE, 'whatsapp')}
            >
              <div className="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--large">
                <div
                  aria-hidden="true"
                  className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                >
                  {!iconConfig.fill ? (
                    <WhatsappSvgIcon />
                  ) : (
                    <RiWhatsappFill size={24} />
                  )}
                </div>
                {t('whatsapp')}
              </div>
            </a>
            <a
              className="resp-sharing-button__link"
              data-channel="facebook"
              href={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                shareUrl
              )}`}
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              onClick={() => logEvent(DID_SHARE_PAGE, 'facebook')}
            >
              <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large">
                <div
                  aria-hidden="true"
                  className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                >
                  <FaceBookSvgIcon />
                </div>
                {t('facebook')}
              </div>
            </a>
            <a
              className="resp-sharing-button__link"
              data-channel="kakaotalk"
              href={`https://story.kakao.com/s/share?url=${encodeURIComponent(
                shareUrl
              )}`}
              target="_blank"
              rel="noopener"
              aria-label="KakaoTalk"
              onClick={() => logEvent(DID_SHARE_PAGE, 'kakaotalk')}
            >
              <div className="resp-sharing-button resp-sharing-button--kakaotalk resp-sharing-button--large">
                <div
                  aria-hidden="true"
                  className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                >
                  <KakaoTalkSvgIcon />
                </div>
                {t('kakaotalk')}
              </div>
            </a>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          @media (max-height: 800px) {
            .copyright {
              bottom: ${showShareList ? 'unset' : '20px'} !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default ShareButton;

import React from 'react';
import { useSelector } from 'react-redux';
import { JitsiDevice, IMainState } from '../../../interfaces';
import MicrophoneControl from './MicrophoneControl';
import CameraControl from './CameraControl';
import SpeakerControl from './SpeakerControl';
import VirtualBackgroundButton from '../SelectVirtualBg/VirtualBackgroundButton';
import { isUserOnSafari } from '../../../utils/deviceDetector';

const DeviceControl = ({
  devices,
  controlSelectorPrefix
}: {
  devices: JitsiDevice[];
  controlSelectorPrefix?: string;
}) => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState?.meeting
  );

  const [hasVirtualBgOption, setHasVirtualBgOption] = React.useState(false);
  const microphones = devices?.filter((d) => d.kind === 'audioinput') || [];
  const speakers = devices?.filter((d) => d.kind === 'audiooutput') || [];
  const cameras = devices?.filter((d) => d.kind === 'videoinput') || [];

  React.useEffect(() => {
    setHasVirtualBgOption(!isUserOnSafari());
  }, []);

  const numberOfControls =
    (Number(microphones.length > 1) | 0) +
    (Number(speakers.length > 1) | 0) +
    (Number(cameras.length > 1) | 0) +
    (Number(cameras.length !== 0 && hasVirtualBgOption) | 0);

  if (!numberOfControls) {
    return null;
  }

  return (
    <div className={`DeviceControl`}>
      {microphones.length > 1 && (
        <MicrophoneControl
          devices={microphones}
          controlSelectorPrefix={controlSelectorPrefix}
          activeDevice={meeting?.localUser?.activeDevices?.microphone || ''}
          allowChange={meeting?.localUser?.isInputChangeAvailable || false}
        />
      )}
      {speakers.length > 1 && (
        <SpeakerControl
          devices={speakers}
          controlSelectorPrefix={controlSelectorPrefix}
          activeDevice={meeting?.localUser?.activeDevices?.speaker || ''}
          allowChange={meeting?.localUser?.isOutputChangeAvailable || false}
        />
      )}
      {cameras.length > 1 && (
        <CameraControl
          devices={cameras}
          controlSelectorPrefix={controlSelectorPrefix}
          activeDevice={meeting?.localUser?.activeDevices?.camera || ''}
          allowChange={meeting?.localUser?.isInputChangeAvailable || false}
        />
      )}
      {cameras.length !== 0 && hasVirtualBgOption && (
        <VirtualBackgroundButton color="#000" withWrapper />
      )}

      <style jsx global>{`
        .button-container {
          position: relative;
          padding-bottom: 5px;
          text-align: left;
          overflow: hidden;
          border-bottom: 1px solid #000;
          width: ${numberOfControls === 1 ? '100%' : '45%'};
          margin: 10px 0 auto;
        }
        .button-container:last-child {
          width: ${numberOfControls % 2 !== 0 ? '100%' : '45%'};
        }
        .button-container span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 68%;
          font-size: 0.8em;
          line-height: 1.4;
          color: #000000;
        }
        .button-container :global(.meeting-button) {
          color: #000 !important;
        }
        .button-container :global(.meeting-button svg) {
          width: 18px;
          height: auto;
          margin-right: 5px;
        }
        .button-container :global(.arrow) {
          position: absolute;
          right: 0;
          top: 2px;
        }
        @media (min-width: 768px) {
          .button-container span {
            width: 70%;
            font-size: 0.875em;
            line-height: 1.188em;
          }
        }
        @media (min-width: 1023px) {
          .button-container span {
            width: 75%;
            font-size: 1em;
            line-height: 1.25em;
          }

          .button-container :global(.meeting-button svg) {
            width: 30px;
            height: auto;
          }

          .button-container :global(.arrow) {
            right: 10px;
            top: 8px;
          }

          .button-container {
            margin: 1%;
            width: 48%;
          }
        }

        .DeviceControl {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        @media screen and (orientation: landscape) and (max-height: 767px) {
          .DeviceControl {
            margin-top: 12px !important;
          }
          .DeviceControl.two-child :global(.button-container) {
            width: 31%;
          }

          .DeviceControl.take-full :global(.button-container) {
            width: 80%;
          }

          .DeviceControl.take-full :global(.button-container span) {
            width: 80%;
          }
        }

        @media (min-width: 768px) and (min-height: 520px) {
          .DeviceControl {
            margin-top: 20px;
          }

          .DeviceControl.take-full :global(.button-container) {
            width: 80%;
          }

          .DeviceControl.take-full :global(.button-container span) {
            width: 80%;
          }
        }

        @media (min-width: 1023px) {
          .DeviceControl.two-child :global(.button-container) {
            width: 31%;
          }
          .DeviceControl.take-full :global(.button-container) {
            width: 80%;
          }
        }
      `}</style>
    </div>
  );
};

export default DeviceControl;

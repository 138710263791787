import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from '../../../interfaces';
import {
  DID_TOGGLE_CAMERA_IN_LOUNGE,
  DID_TOGGLE_MIC_IN_LOUNGE
} from '../../../utils/constants';
import { useMeetingDebugLogger } from '../../hooks/meeting';
import MeetingButtonWithIcon from '../MeetingControls/MeetingButtonWithIcon';
import {
  IconCameraOff,
  IconCameraOn,
  IconMicrophoneOff,
  IconMicrophoneOn
} from '../MeetingControls/MeetingIcons';
import { actionToggleMuteLocalTrackAsync } from '../../../redux/asyncActions';
import { hasAvailableDevices } from '../utils/track';

function LoungeControls({
  shouldMuteVideo = false,
  shouldMuteAudio = false
}: {
  shouldMuteVideo?: boolean;
  shouldMuteAudio?: boolean;
}) {
  const dispatch = useDispatch();
  const localUser = useSelector(
    (state: IMainState) => state.clientState.meeting?.localUser || {}
  );

  const isDisabledCam = React.useMemo(() => {
    return !hasAvailableDevices(localUser?.availableDevices, 'videoinput');
  }, [localUser?.availableDevices]);

  const isDisableMic = React.useMemo(() => {
    return !hasAvailableDevices(localUser?.availableDevices, 'audioinput');
  }, [localUser?.availableDevices]);

  const isVideoMuted = localUser.videoMuted;
  const isAudioMuted = localUser.audioMuted;
  const meetingLogger = useMeetingDebugLogger();
  const handleToggleVideo = async () => {
    dispatch(actionToggleMuteLocalTrackAsync('video'));
    const shouldMute = !isVideoMuted;
    meetingLogger(DID_TOGGLE_CAMERA_IN_LOUNGE, {
      mute: shouldMute
    });
  };

  const handleToggleAudio = async () => {
    dispatch(actionToggleMuteLocalTrackAsync('audio'));
    const shouldMute = !isAudioMuted;
    meetingLogger(DID_TOGGLE_MIC_IN_LOUNGE, {
      mute: shouldMute
    });
  };

  if (shouldMuteVideo && shouldMuteAudio) return null;
  return (
    <>
      <div className="media-controls btn-toggle">
        {!shouldMuteVideo && (
          <MeetingButtonWithIcon
            onClick={handleToggleVideo}
            disabled={isDisabledCam}
            className={`camera ${isVideoMuted ? 'muted' : 'unmuted'}`}
          >
            {isVideoMuted ? (
              <IconCameraOff fill="#fff" />
            ) : (
              <IconCameraOn fill="#666666" />
            )}
          </MeetingButtonWithIcon>
        )}
        {!shouldMuteAudio && (
          <MeetingButtonWithIcon
            disabled={isDisableMic}
            onClick={handleToggleAudio}
            className={`microphone ${isAudioMuted ? 'muted' : 'unmuted'}`}
          >
            {isAudioMuted ? (
              <IconMicrophoneOff fill="#fff" />
            ) : (
              <IconMicrophoneOn fill="#666666" />
            )}
          </MeetingButtonWithIcon>
        )}
      </div>
      <style jsx>{`
        .btn-toggle {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: 0 20px 10px;
        }
        .btn-toggle :global(.meeting-button.muted) {
          background-color: #f00;
        }
        .btn-toggle :global(.meeting-button) {
          text-align: center;
          border-radius: 50%;
          padding: 5px;
          background-color: #efefef;
        }
        .btn-toggle :global(.meeting-button.active) {
          background: #666;
        }
        .btn-toggle :global(svg) {
          width: 30px;
          height: auto;
          margin: auto;
        }
      `}</style>
    </>
  );
}

export default LoungeControls;

import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { saveWishlist } from '../../../../../clientSideServices/wishlist';
import { loubiFutureSiteId, louboutinBrandId } from '../../../../../config';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';
import { useTranslation } from '../../../../../i18n';

import {
  actionOpenLFPopup,
  actionUpdateLFWishlist
} from '../../../../../redux/customActions/loubiFuture';
import {
  DID_CLICK_SEND_WISHLIST_BUTTON,
  DID_FAILED_TO_SEND_WISHLIST,
  DID_SEND_WISHLIST
} from '../../../../../utils/constants';
import { getIdentityId } from '../../../../../utils/identity';
import { generateV4UUID } from '../../../../../utils/identityGenerator';
import { useLoubiFutureState } from '../../../../hooks/loubiFuture';
import { LFColor } from '../../../../ThreejsAnimation/loubiFuture/assets';
import {
  isAllowedForName,
  isAllowedForPhone
} from '../../../Appointment/inputValidator';
import LFButton from '../Common/LFButton';
import LFTerms from '../Common/LFTerms';
import LFTextInput from '../Common/LFTextInput';
import { mapWishlistStateToPayload } from '../Favorites/LFFavoriteMiddleware';

export enum Status {
  LOADING = 'LOADING',
  INITIAL = 'INITIAL',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}
export const saveLabel = (status: Status) => {
  switch (status) {
    case Status.FAILED:
      return 'PLEASE TRY AGAIN';
    case Status.LOADING:
      return 'SUBMITTING...';
    default:
      return 'SUBMIT';
  }
};

const GetNotified = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const wishlist = useLoubiFutureState().wishlist;

  const region = encodeURIComponent(router.query.utm_campaign as string);
  const [phone, setPhone] = React.useState('');
  const [country, setCountry] = React.useState('+33');
  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [saveStatus, setSaveStatus] = React.useState<Status>(Status.INITIAL);
  const [error, setError] = React.useState({
    name: false,
    surname: false,
    phone: false,
    country: false
  });
  const [agree, setAgree] = React.useState(false);

  React.useEffect(() => {
    const phone = wishlist?.phone?.split(' ') || [];
    setName(wishlist?.name || '');
    setCountry(phone[0] || '+33');
    setPhone(phone[1] || '');
    setSurname(wishlist?.surname || '');
    setAgree(wishlist?.acceptedTermsOfUse || false);
  }, [wishlist]);

  const { t } = useTranslation();
  const handleSubmit = () => {
    if (Object.keys(error).some((e) => error[e])) {
      return;
    }

    if (!name.trim() || !agree || !phone.trim() || !surname.trim()) {
      setSaveStatus(Status.ERROR);
      return;
    }

    const payload = {
      ...mapWishlistStateToPayload(wishlist),
      name,
      surname,
      region,
      phone: `${country} ${phone}`,
      acceptedTermsOfUse: agree,
      id: wishlist?.id || generateV4UUID(),
      identityId: wishlist?.identityId || getIdentityId(),
      description: 'User Journey 1',
      store: loubiFutureSiteId,
      brand: louboutinBrandId,
      status: 'WISHLIST_SUBMITTED',
      createdAt: wishlist?.createdAt || new Date(),
      createdBy: getIdentityId()
    };

    setSaveStatus(Status.LOADING);
    logEvent(DID_CLICK_SEND_WISHLIST_BUTTON);

    saveWishlist(payload)
      .then(() => {
        logEvent(DID_SEND_WISHLIST, DID_SEND_WISHLIST, { id: payload.id });
        setSaveStatus(Status.SUCCESS);
        dispatch(actionUpdateLFWishlist({ ...payload, dontShowPopup: true }));
      })
      .catch((e) => {
        console.log(e);
        setSaveStatus(Status.FAILED);
        logEvent(DID_FAILED_TO_SEND_WISHLIST, DID_FAILED_TO_SEND_WISHLIST, {
          payload: {
            ...payload,
            name: undefined,
            surname: undefined,
            phone: undefined,
            email: undefined
          },
          error
        });
      });
  };

  const form = (
    <>
      <h3 className="LFTitle">
        {t('vb:receive_notification')?.toUpperCase()}
      </h3>
      <p>{t('vb:get_notified_when_available')}</p>
      <div className="form-container">
        <LFTextInput
          placeHolder={t('vb:name')?.toUpperCase()}
          name="name"
          value={name}
          error={error.name ? 'Invalid character' : ''}
          onChange={(value) => {
            setName(value);
            setSaveStatus(Status.INITIAL);
            setError({ ...error, name: !isAllowedForName(value) });
          }}
        />
        <LFTextInput
          placeHolder={t('vb:surname')?.toUpperCase()}
          name="surname"
          value={surname}
          error={error.surname ? 'Invalid' : ''}
          onChange={(value) => {
            setSurname(value);
            setSaveStatus(Status.INITIAL);
            setError({ ...error, surname: !isAllowedForName(value) });
          }}
        />
        <div className="row">
          <div className="col-3 mr-0 pr-0">
            <LFTextInput
              placeHolder="00"
              name="country"
              type="tel"
              value={country}
              onChange={(value) => {
                setCountry(value);
                setSaveStatus(Status.INITIAL);
                setError({ ...error, country: !isAllowedForPhone(value) });
              }}
              error={error.country ? 'Invalid' : ''}
            />
          </div>
          <div className="col-9">
            <LFTextInput
              placeHolder={t('vb:phone_number')?.toUpperCase()}
              type="tel"
              name="phone"
              value={phone}
              onChange={(value) => {
                setPhone(value);
                setSaveStatus(Status.INITIAL);
                setError({ ...error, phone: !isAllowedForPhone(value) });
              }}
              error={error.phone ? 'Invalid phone number' : ''}
            />
          </div>
        </div>
        <LFTerms
          checked={agree}
          onClick={() => {
            setAgree(!agree);
            setSaveStatus(Status.INITIAL);
          }}
        />

        <div className="text-center">
          {saveStatus === Status.ERROR && (
            <div className="error">
              {!name && 'name, '}
              {!surname && 'surname, '}
              {(!phone || !country) && 'phone number, '}
              {!agree && 'agreement approval '}required
            </div>
          )}
          <div className={`button-wrap`}>
            <LFButton
              name="submitGetNotified"
              disabledDefaultLog
              onClick={handleSubmit}
            >
              {saveLabel(saveStatus)}
            </LFButton>
          </div>
        </div>
      </div>
    </>
  );

  const success = (
    <>
      <h3 className="LFTitle">
        {t('vb:receive_notification')?.toUpperCase()}
      </h3>
      <img src={`${LFAssetBaseUrl}/icons/v1/success.png`} />
      <p>
        {t('vb:we_will_contact_when_available')}
      </p>
      <div className="text-center">
        <LFButton
          name="closeGetNotified"
          onClick={() => dispatch(actionOpenLFPopup(undefined))}
        >
          {t('vb:quit')?.toUpperCase()}
        </LFButton>
      </div>
      <style jsx>{`
        img {
          width: 60px;
          height: auto;
          margin: 20px auto;
        }
        p {
          color: ${LFColor.blue};
        }
      `}</style>
    </>
  );

  return (
    <div className="GetNotified">
      {saveStatus === Status.SUCCESS ? success : form}
    </div>
  );
};

export default GetNotified;

import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  IAnimationProduct,
  ILoubiAirwaysAirplaneWindowAnimationState,
  LoubiAirwaysAnimation
} from '../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../redux/customActions/loubiAirways';
import { DID_UPDATE_ANIMATION_STATE } from '../../../utils/constants';
import { useRemoteLoubiAirwaysAnimationState } from '../../hooks/loubiAirways';
import { canInteractWithAnimation } from '../../hooks/meeting';
import { animationVoyageThrottleDurationInMs } from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/animationUtils';
import LJContinueFlightButton from '../../VirtualBoutique/CustomComponent/LouboutinJpCustomComponent/Buttons/LJContinueFlightButton';
import { AnimationContext } from '../louboutin/AnimationContext';
import { ModelEnvironment } from '../louboutin/background';
import AirplaneWindow from './AirplaneWindow';

const AirplaneWindowWrapper = ({
  modelEnvironment
}: {
  modelEnvironment: ModelEnvironment;
}) => {
  const { setSelectedModel, setLoaded, onCloseAnimation } =
    React.useContext(AnimationContext);

  const viewOnly = !canInteractWithAnimation();
  const remoteAirplaneWindowAnimationState =
    useRemoteLoubiAirwaysAnimationState(LoubiAirwaysAnimation.AIRPLANE_WINDOW);
  const dispatch = useDispatch();
  const logEventThrottled = throttle(
    (state: ILoubiAirwaysAirplaneWindowAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: LoubiAirwaysAnimation.AIRPLANE_WINDOW,
        state
      });
    },
    1000,
    { trailing: true }
  );

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirwaysAirplaneWindowAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.AIRPLANE_WINDOW,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );

  const updateRemoteState = (
    state: ILoubiAirwaysAirplaneWindowAnimationState
  ) => {
    throttledUpdateRemoteState(state);
    logEventThrottled(state);
  };

  return (
    <>
      <AirplaneWindow
        modelEnvironment={modelEnvironment}
        remoteState={remoteAirplaneWindowAnimationState}
        updateRemoteState={updateRemoteState}
        didSelectModel={(model: IAnimationProduct) => {
          setSelectedModel(model);
        }}
        onReady={() => setLoaded(true)}
        viewOnly={viewOnly}
      />
      <LJContinueFlightButton onClick={onCloseAnimation} />
    </>
  );
};

export default AirplaneWindowWrapper;

import React from 'react';
import { assetBaseUrl } from '../../../../config';
import MapWrapper from '../../MapWrapper';
import VCAMap from './VCAMap';
import VCAMapButton from './VCAMapButton';

const assetPath = `${assetBaseUrl}/vca`;
export const vcaAssets = {
  leftWing: assetPath + '/butterfly_left.png',
  rightWing: assetPath + '/butterfly_right.png',
  level1Map: assetPath + '/Level_1.png',
  level2Map: assetPath + '/Level_2.png',
  gotoLevel: assetPath + '/rotating_butterflies.gif',
  mapSpot: assetPath + '/map_spot.gif',
  bounce: assetPath + '/map_bounce.png'
};

const VCACustomComponent = () => (
  <MapWrapper>
    <VCAMapButton />
    <VCAMap />
  </MapWrapper>
);

export default VCACustomComponent;

import React from 'react';
import { IProduct } from '../../../interfaces';
import { getProductOriginalLink } from '../../../utils/product';
import { AppContext } from '../../AppContext';
import LinkButton from './LinkButton';

const IWCChZurichProductCallToActionButton = ({ product }: { product: IProduct }) => {
  const context = React.useContext(AppContext);

  const productLink = getProductOriginalLink(
    product,
    context.store?.countryCode || 'CH'
  );

  if (!productLink) return null;

  return (
    <div className="action-buttons">
      <LinkButton url={productLink} />
    </div>
  );
};

export default IWCChZurichProductCallToActionButton;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMeeting, MeetingRole } from '../../../interfaces';
import {
  actionOpenParticipantControls,
  actionToggleEnlargeVideo
} from '../../../redux/actions';
import {
  DID_ENLARGE_VIDEO,
  DID_HIDE_MEETING_STREAMING_STUDIO_FULL_SCREEN,
  DID_SHOW_MEETING_STREAMING_STUDIO_FULL_SCREEN,
  DID_TOGGLE_PARTICIPANT_ACTIONS_MENU
} from '../../../utils/constants';
import { IconThreeDot } from '../MeetingControls/MeetingIcons';

const MeetingParticipantControlsButton = ({
  participantId,
  meeting,
  role
}: {
  participantId: string;
  meeting: IMeeting;
  role: MeetingRole;
}) => {
  const dispatch = useDispatch();

  const meetingId = meeting?.meetingId;
  const meetingLayout = meeting?.layout;
  const shouldOpen = !meeting?.participantControls;
  const isRemoteStudio = role === MeetingRole.STUDIO;

  const isEnlarged =
    meetingLayout?.enlargedVideoParticipantId === participantId;

  const onToggleEnlarge = () => {
    const shouldEnlarge =
      meetingLayout?.enlargedVideoParticipantId !== participantId;
    dispatch(
      actionToggleEnlargeVideo({
        participantId,
        shouldEnlarge
      })
    );

    logEvent(DID_ENLARGE_VIDEO, DID_ENLARGE_VIDEO, {
      participantId,
      meetingId,
      isEnlarged: shouldEnlarge
    });

    if (isRemoteStudio) {
      const event = isEnlarged
        ? DID_HIDE_MEETING_STREAMING_STUDIO_FULL_SCREEN
        : DID_SHOW_MEETING_STREAMING_STUDIO_FULL_SCREEN;
      logEvent(event, event, { meetingId, participantId });
    }
  };
  const handleToggleMenu = () => {
    if (isRemoteStudio) {
      onToggleEnlarge();
    } else {
      dispatch(actionOpenParticipantControls(participantId));
      logEvent(
        DID_TOGGLE_PARTICIPANT_ACTIONS_MENU,
        DID_TOGGLE_PARTICIPANT_ACTIONS_MENU,
        {
          participantId,
          open: shouldOpen ? true : false
        }
      );
    }
  };

  return (
    <div
      className="ClientControlsButton with-shadow"
      onClick={handleToggleMenu}
    >
      <IconThreeDot />
      <style jsx>{`
        .ClientControlsButton {
          position: absolute;
          z-index: 14;
          cursor: pointer;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .ClientControlsButton :global(svg) {
          position: absolute;
          right: 3px;
          top: 4px;
          height: 15px;
          width: auto;
        }

        :global(.enlarge-video-container .ClientControlsButton) {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default MeetingParticipantControlsButton;

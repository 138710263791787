import React from 'react';
import { useDispatch } from 'react-redux';
import { actionDidShowVBCampaign } from '../../../redux/actions';
import { getPopupAction, PageView } from '../../../utils/pageView';

const PopupCampaign = ({
  pageView,
  pageId,
  brandId,
  storeId,
  secondsBeforeShow
}: {
  pageView?: PageView;
  pageId?: string;
  brandId: string;
  storeId: string;
  secondsBeforeShow: number;
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (pageView && pageId) {
        const action = getPopupAction(
          `${location.protocol}//${location.host}`,
          pageView,
          pageId,
          brandId,
          storeId
        );
        if (action) {
          dispatch(action);
          dispatch(actionDidShowVBCampaign());
        }
      }
    }, secondsBeforeShow);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return null;
};

export default PopupCampaign;

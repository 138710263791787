import { first, last } from 'lodash';
import React from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import {
  ChatConfig,
  ChatMessageConfig,
  ChatMessageOption,
  ChatPopupType,
  PanoView
} from '../../../interfaces';
import {
  actionAppointmentPopup,
  actionShowContactPopup
} from '../../../redux/actions';
import {
  DID_HIDE_CHAT_PANEL,
  DID_OPEN_APPOINTMENT_POPUP,
  DID_OPEN_CONTACT_POPUP,
  DID_OPEN_EXTERNAL_LINK,
  DID_OPEN_VB_CONTENT_POPUP,
  DID_SELECT_CHAT_OPTION,
  DID_SHOW_CHAT_MESSAGE,
  DID_SHOW_CHAT_PANEL
} from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { getPopupAction, PageView } from '../../../utils/pageView';
import { getBrandChatTheme } from '../../BrandStyle';
import { getChatPanelExtraLogo } from '../../storeComponentFactory';

interface ChatMessage extends ChatMessageConfig {
  position: 'left' | 'right';
}

const ChatPanel = ({
  storeId,
  config,
  goToScene,
  openPopup,
  brandId,
  lookToView
}: {
  storeId?: string;
  config: ChatConfig;
  goToScene: (sceneId: string) => void;
  openPopup: (type: PageView, id: string) => void;
  brandId: string;
  lookToView: ({ viewH, viewV, fov }: PanoView) => void;
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const [isUsingMobile, setIsUsingMobile] = React.useState(false);
  const el = React.useRef(null);
  const theme = getBrandChatTheme(brandId);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const showNextMessage = (id: string | number, delayTime: number) => {
    const nextMessage = config.messages.find((m) => m.id === id);
    if (nextMessage) {
      setTimeout(() => {
        showMessage({ ...nextMessage, position: 'left' });
      }, delayTime);
    }
  };

  const showMessage = (message: ChatMessage) => {
    setMessages([...messages, message]);
    logEvent(DID_SHOW_CHAT_MESSAGE, DID_SHOW_CHAT_MESSAGE, message);
    const { popupId, popupType } = message;
    if (popupId && popupType) {
      openPopup(popupType, popupId);
    }
  };

  React.useEffect(() => {
    el.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
    const lastMessage = last(messages);
    if (lastMessage?.nextId) {
      showNextMessage(lastMessage.nextId, lastMessage?.nextDelayInMS || 1000);
    }
    setIsUsingMobile(isUserOnMobile());
  }, [messages]);

  React.useEffect(() => {
    const firstMessage = first(config.messages);
    if (firstMessage) {
      setTimeout(() => {
        showMessage({
          ...firstMessage,
          position: 'left'
        });
      }, 1000);
    }
  }, []);

  React.useEffect(() => {
    if (isUsingMobile) setOpen(false);
  }, [isUsingMobile]);

  const openExternalLink = (payload: any) => {
    const url = (payload || {}).url;
    if (url) {
      window.open(url, '_blank');
      logEvent(DID_OPEN_EXTERNAL_LINK, DID_OPEN_EXTERNAL_LINK, payload);
    }
  };

  const openPhysicalVisitAppointmentForm = (payload: any) => {
    dispatch(actionAppointmentPopup(payload));
    logEvent(DID_OPEN_APPOINTMENT_POPUP, '', payload);
  };

  const openVBPopup = (payload: any) => {
    if (payload?.action) {
      dispatch(payload.action);
    } else {
      const pageViewType = payload?.type;
      const idByLang = payload?.id || {};
      const id = idByLang[i18n.language];
      const action = getPopupAction(
        `${location.protocol}//${location.host}`,
        pageViewType,
        id,
        brandId,
        storeId
      );
      if (action) {
        dispatch(action);
      }
    }
    logEvent(DID_OPEN_VB_CONTENT_POPUP, DID_OPEN_VB_CONTENT_POPUP, payload);
  };

  const openBoutiqueContactPopup = () => {
    setTimeout(() => dispatch(actionShowContactPopup(true)), 1000);
    logEvent(DID_OPEN_CONTACT_POPUP, DID_OPEN_CONTACT_POPUP);
  };

  const openInlinePopup = (popupType: ChatPopupType, payload?: any) => {
    switch (popupType) {
      case ChatPopupType.VISIT_APPOINTMENT:
        openPhysicalVisitAppointmentForm(payload);
        break;
      case ChatPopupType.VB_POPUP_CONTENT:
        openVBPopup(payload);
        break;
      case ChatPopupType.CONTACT:
        openBoutiqueContactPopup();
        break;
      case ChatPopupType.EXTERNAL_LINK:
        openExternalLink(payload);
        break;
      default:
        break;
    }
  };
  const handleOption = (option: ChatMessageOption) => {
    showMessage({
      id: generateV4UUID(),
      content: option.content,
      contentLangKey: option.contentLangKey,
      position: 'right',
      nextId: option.nextId,
      popupId: option.popupId,
      popupType: option.popupType,
      nextDelayInMS: option.nextDelayInMS
    });
    if (option.sceneId) {
      goToScene(option.sceneId);
      if (isUsingMobile) setOpen(false);
    }
    if (option.inlinePopup) {
      openInlinePopup(option.inlinePopup, option?.payload);
    }
    if (option.viewAngle) {
      lookToView({
        viewH: option.viewAngle.viewH,
        viewV: option.viewAngle.viewV,
        fov: option.viewAngle.fov
      });
    }
  };

  const renderMultiContent = (multiContent: {
    top: string;
    topLangKey?: string;
    center: string;
    centerLangKey?: string;
    bottom: string;
    bottomLangKey?: string;
  }) => {
    if (!multiContent) {
      return null;
    }
    return (
      <div className="multi-content">
        <p>
          {multiContent.topLangKey
            ? t(`vb:${multiContent.topLangKey}`)
            : multiContent.top}
        </p>
        <p className="main-message">
          {multiContent.centerLangKey
            ? t(`vb:${multiContent.centerLangKey}`)
            : multiContent.center}
        </p>
        <p>
          {multiContent.bottomLangKey
            ? t(`vb:${multiContent.bottomLangKey}`)
            : multiContent.bottom}
        </p>
      </div>
    );
  };
  return (
    <>
      <div id="chat-panel">
        {getChatPanelExtraLogo(storeId)}
        <div
          className="header"
          onClick={() => {
            open
              ? logEvent(DID_HIDE_CHAT_PANEL)
              : logEvent(DID_SHOW_CHAT_PANEL);
            setOpen(!open);
          }}
        >
          <img src={config.chatbotAvatarUrl} alt="profile-photo" />
          <span>{config.chatbotName}</span>
          {open ? <BsChevronDown /> : <BsChevronUp />}
        </div>
        <div className={`chat-content ${open ? 'chatopen' : 'chatclose'}`}>
          {messages.map((item, i) => (
            <div
              ref={item.id === last(messages).id ? el : null}
              key={`${item.id}-${i}`}
              className={`message ${item.position}`}
            >
              <span style={item?.style || {}}>
                {item.contentLangKey
                  ? t(`vb:${item.contentLangKey}`)
                  : item.content}
                {renderMultiContent(item.multiContent)}
                {item.options && (
                  <div className="options-container">
                    {item.options.map((option) => (
                      <button
                        key={option.content}
                        className="option-button btn btn-sm"
                        onClick={() => {
                          handleOption(option);
                          logEvent(
                            DID_SELECT_CHAT_OPTION,
                            DID_SELECT_CHAT_OPTION,
                            option
                          );
                        }}
                        style={option?.style || {}}
                      >
                        {option.contentLangKey
                          ? t(`vb:${option.contentLangKey}`)
                          : option.content}
                      </button>
                    ))}
                  </div>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
      <style jsx global>
        {`
          #chat-panel {
            position: fixed;
            bottom: 0;
            right: 40px;
            width: 300px;
            font-size: 0.875em;
          }
          .chat-content {
            transition: all 0.5s ease;
          }
          .chat-content.chatopen {
            height: 350px;
          }

          .chat-content.chatclose {
            height: 0;
          }

          @media (min-width: 768px) {
            #chat-panel {
              font-size: ${isRtl ? '16px' : 'inherit'};
            }
          }

          .options-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          @media (max-width: 630px) {
            #chat-panel {
              right: calc(50vw - 150px);
            }
            #chat-panel .extra-logo {
              width: 80px;
              bottom: ${!open ? '50px' : '400px'};
              left: calc(50vw - 150px + 20px);
              transition: all 0.5s ease;
            }
            #chat-panel .extra-logo img {
              width: 100%;
            }
          }

          #chat-panel .header {
            color: #fff;
            cursor: pointer;
            height: 40px;
            background: ${theme.header};
            line-height: 40px;
            text-align: left;
            position: relative;
            direction: ltr;
          }

          #chat-panel .header img {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            margin: 0 10px;
          }

          #chat-panel .header span {
            flex-grow: 1;
            text-align: left;
          }

          #chat-panel .header svg {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 8px;
            right: 10px;
          }

          #chat-panel .option-button {
            display: block;
            color: white;
            width: 100%;
            border-radius: 5px;
            margin-top: 10px;
            background: ${theme.optionButton};
          }

          #chat-panel .chat-content {
            background: #fff;
            overflow-y: scroll;
          }

          #chat-panel .message {
            display: flex;
          }

          #chat-panel .message:last-child {
            padding-bottom: 20px;
          }

          #chat-panel .message > span {
            max-width: 80%;
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
          }

          #chat-panel .left {
            flex-direction: row;
          }

          #chat-panel .left > span {
            background: ${theme.textBackground};
            text-align: ${isRtl ? 'right' : 'left'};
          }

          #chat-panel .right {
            flex-direction: row-reverse;
          }

          #chat-panel .right > span {
            background: #f2f2f2;
            text-align: right;
          }

          #chat-panel .multi-content p {
            font-size: 0.875em;
            margin: 0;
          }

          #chat-panel .multi-content .main-message {
            font-weight: 600;
            margin: 10px 0;
          }
          .extra-logo {
            position: fixed;
            width: 110px;
            left: 10px;
            bottom: 20px;
          }
          .extra-logo img {
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default ChatPanel;

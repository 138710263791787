import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { loubiAirwaysBoutiqueId, loubiFutureSiteId } from '../../../../config';
import { IMeeting } from '../../../../interfaces';
import { actionOpenTooltip } from '../../../../redux/actions';
import { actionUpdateCommon3DActiveAnimationInPano } from '../../../../redux/customActions/common3DAction';
import { DID_CLICK_TELEPORT_SITE } from '../../../../utils/constants';
import AdvisorAccessControls from '../../../Meeting/MeetingControls/AdvisorAccessControls';
import MeetingButtonWithIcon from '../../../Meeting/MeetingControls/MeetingButtonWithIcon';
import { IconTeleport } from '../../../Meeting/MeetingControls/MeetingIcons';
import { ViewportContext } from '../../../Meeting/MeetingLayout';
import { IMainState } from './../../../../interfaces/index';

const InspifyAdvisorMeetingControls = ({
  meeting,
  onCloseMenu
}: {
  meeting: IMeeting;
  onCloseMenu: () => void;
}) => {
  const dispatch = useDispatch();
  const shouldCloseMenu = () => {
    if (isSmallScreen) onCloseMenu();
  };

  const { isSmallScreen } = React.useContext(ViewportContext);
  const defaultStoreId = useSelector(
    (state: IMainState) => state?.clientState?.store?.id
  );
  const defaultBrandId = useSelector(
    (state: IMainState) => state?.clientState?.virtualBoutiqueConfig?.brandId
  );
  const { teleportTo } = useSelector(
    (state: IMainState) => state?.clientState?.teleport || {}
  );
  const teleportedStoreId = teleportTo?.store?.id;

  const dontShowSearch =
    teleportedStoreId === loubiFutureSiteId ||
    teleportedStoreId === loubiAirwaysBoutiqueId;

  const popupState = useSelector(
    (state: IMainState) => state?.clientState?.meeting?.popupState || {}
  );
  React.useEffect(() => {
    if (popupState?.url)
      dispatch(actionUpdateCommon3DActiveAnimationInPano(undefined));
  }, [popupState]);

  return (
    <>
      <MeetingButtonWithIcon
        id="teleportButton"
        onClick={() => {
          logEvent(DID_CLICK_TELEPORT_SITE, DID_CLICK_TELEPORT_SITE, {
            meetingId: meeting.meetingId
          });
          dispatch(actionOpenTooltip('teleportButton'));
          shouldCloseMenu();
        }}
      >
        <IconTeleport />
        <span>Teleport</span>
      </MeetingButtonWithIcon>
      {!dontShowSearch && (
        <AdvisorAccessControls
          storeId={teleportedStoreId || defaultStoreId}
          brandId={teleportTo?.store?.brands?.[0]?.brandId || defaultBrandId}
          meeting={meeting}
          onCloseMenu={onCloseMenu}
        />
      )}
    </>
  );
};

export default InspifyAdvisorMeetingControls;

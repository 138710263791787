import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  iwcNlAmsterdamSiteId,
  vanCleefArpelsUsKingOfPrussiaSiteId,
  vanCleefArpelsUsSiliconValleyFairSiteId
} from '../../../config';
import { AppointmentType, ILanguageOption } from '../../../interfaces';
import { actionUnmuteBgSound } from '../../../redux/actions';
import {
  DID_CLICK_EXPLORE_MY_OWN,
  DID_CLICK_GUIDED_TOUR,
  DID_CLICK_PRIVACY_POLICY,
  DID_CLICK_TERMS_OF_USE
} from '../../../utils/constants';
import { krpanoInvoke } from '../../../utils/krpano';
import {
  tryGetLocalStorage,
  tryRemoveFromLocalStorage,
  trySetLocalStorage
} from '../../../utils/window';
import PrivacyPolicy from '../../Legal/PrivacyPolicy';
import TermsOfUse from '../../Legal/TermsOfUse';
import { getLandingContactButton } from '../../storeComponentFactory';

const VCALanding = ({
  storeId,
  brandName,
  logoUrl,
  backgroundImageUrl,
  mobileBackgroundImageUrl,
  languages,
  showAppointmentForm,
  externalAppointment,
  onDismiss,
  openDelay
}: {
  storeId: string;
  brandName: string;
  logoUrl?: string;
  backgroundImageUrl: string;
  mobileBackgroundImageUrl: string;
  languages: ILanguageOption[];
  showAppointmentForm: () => void;
  externalAppointment?: string;
  onDismiss: () => void;
  openDelay?: number;
}) => {
  const [showLanguageSelection, setShowLanguageSelection] = React.useState(
    languages.length > 1
  );
  const dispatch = useDispatch();
  const [renderDelay, setRenderDelay] = React.useState(true);
  const [showTerms, setShowTerms] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [alreadyAcceptedTerms, setAlreadyAcceptedTerms] = React.useState(false);
  const [alreadySelectedLanguage, setAlreadySelectedLanguage] =
    React.useState(false);
  const agreementKey = `${storeId}-agreementChecked`;
  const languageKey = `${storeId}-languageSelected`;
  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setAcceptedTerms(value);
    if (value) {
      trySetLocalStorage(agreementKey, 'true');
    } else {
      tryRemoveFromLocalStorage(agreementKey);
    }
  };

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setAlreadyAcceptedTerms(!!tryGetLocalStorage(agreementKey));
    setAlreadySelectedLanguage(!!tryGetLocalStorage(languageKey));
    if (openDelay) {
      setTimeout(() => setRenderDelay(false), openDelay);
    }
  }, []);

  const languageSelection = (
    <div className="welcome-container language-selection">
      <div className="content">
        <h1>
          <span>{t('vb:welcome_message.line1')}</span>
          <span>
            {t('vb:welcome_message.line2', { brandName: t(`${brandName}`) })}
          </span>
          {storeId === iwcNlAmsterdamSiteId ? (
            <span>{t('vb:iwc_nl_e_boutique')}</span>
          ) : (
            <span>{t('vb:welcome_message.line3')}</span>
          )}
        </h1>
        <p>{t('vb:please_select_language')}</p>
      </div>
      <div className="btn-group">
        {languages.map((lang) => (
          <button
            key={lang.code}
            type="button"
            onClick={() => {
              setShowLanguageSelection(false);
              trySetLocalStorage(languageKey, 'true');
              i18n.changeLanguage(lang.code);
            }}
          >
            {lang.display.toUpperCase()}
          </button>
        ))}
      </div>
      <style jsx>
        {`
          h1 {
            text-transform: uppercase;
          }
          h1 > span {
            display: block;
          }
          @media (min-width: 1024px) {
            h1 > span {
              display: inline;
            }
            h1 > span:last-child {
              display: block;
            }
          }
        `}
      </style>
    </div>
  );

  const guidedButton = externalAppointment ? (
    <button
      type="button"
      className="open-contact external-appointment"
      onClick={() => {
        window.open(externalAppointment);
        logEvent(DID_CLICK_GUIDED_TOUR, DID_CLICK_GUIDED_TOUR, {
          type: AppointmentType.EXTERNAL_URL,
          url: externalAppointment
        });
      }}
    >
      {getLandingContactButton(storeId, t)}
    </button>
  ) : (
    <button
      type="button"
      className="open-contact popup-appointment"
      onClick={() => {
        showAppointmentForm();
        logEvent(DID_CLICK_GUIDED_TOUR);
      }}
    >
      {getLandingContactButton(storeId, t)}
    </button>
  );

  const welcomeMessage = (
    <div className="welcome-container entry-selection">
      <div className="content">
        {storeId === vanCleefArpelsUsSiliconValleyFairSiteId ||
        storeId === vanCleefArpelsUsKingOfPrussiaSiteId ? (
          <h1>
            <span>Discover the Enchanting World of Van Cleef &amp; Arpels</span>
            <span className="small">
              Spring Installation at{' '}
              {storeId === vanCleefArpelsUsSiliconValleyFairSiteId
                ? 'Valley Fair'
                : 'King of Prussia'}{' '}
              Mall
            </span>
          </h1>
        ) : (
          <>
            <h1>
              <span>{t('vb:welcome_message.line1')}</span>
              <span>
                {t('vb:welcome_message.line2', {
                  brandName: t(`${brandName}`)
                })}
              </span>
              {storeId === iwcNlAmsterdamSiteId ? (
                <span>{t('vb:iwc_nl_e_boutique')}</span>
              ) : (
                <span>{t('vb:welcome_message.line3')}</span>
              )}
            </h1>
            <p>{t('vb:select_following_options')}</p>
          </>
        )}
      </div>
      {storeId === vanCleefArpelsUsSiliconValleyFairSiteId ||
      storeId === vanCleefArpelsUsKingOfPrussiaSiteId ? (
        <>
          <button
            type="button"
            className="explore-own"
            onClick={() => {
              onDismiss();
              if (storeId === vanCleefArpelsUsKingOfPrussiaSiteId) {
                dispatch(actionUnmuteBgSound());
              }

              logEvent(DID_CLICK_EXPLORE_MY_OWN);
              krpanoInvoke(`switchSoundOn()`);
            }}
          >
            {t('vb:explore_now')}
          </button>
          <div className="tncforvcavalleyfair">
            <p>By entering the experience, you agree to the</p>
            <p>
              following{' '}
              <a
                href={'https://www.inspify.com/terms-of-use/'}
                target="_blank"
                onClick={() => logEvent(DID_CLICK_TERMS_OF_USE)}
              >
                {t('terms_of_use')}
              </a>{' '}
              and{' '}
              <a
                href={'https://www.inspify.com/privacy-policy/'}
                target="_blank"
                onClick={() => logEvent(DID_CLICK_PRIVACY_POLICY)}
              >
                {t('privacy_policy')}
              </a>
              .
            </p>
          </div>
        </>
      ) : (
        <div className="btn-group welcome-btn">
          {guidedButton}
          <button
            type="button"
            className="explore-own"
            onClick={() => {
              if (alreadyAcceptedTerms) {
                onDismiss();
              } else {
                setShowTerms(true);
              }
              logEvent(DID_CLICK_EXPLORE_MY_OWN);
            }}
          >
            {t('vb:option_explore_on_my_own')}
          </button>
        </div>
      )}
      <style jsx>
        {`
          .tncforvcavalleyfair {
            font-size: 0.65em;
            color: #ccc;
          }
          .tncforvcavalleyfair a {
            text-decoration: underline;
            color: #ccc;
          }
          .btn-group {
            display: block;
          }

          .explore-own {
            padding: 0 25px;
          }

          h1 {
            text-transform: uppercase;
          }

          h1 > span {
            display: block;
          }
          h1 > .small {
            text-transform: none !important;
            margin-top: 10px;
            font-weight: normal !important;
          }

          @media (min-width: 1024px) {
            .btn-group {
              display: flex;
            }
            h1 > span {
              display: inline;
            }
            h1 > span:last-child {
              display: block;
            }
          }
        `}
      </style>
    </div>
  );

  const acceptTerms = (
    <div className="input-group tstc">
      <div className="checkbox-group">
        <label>
          <input
            type="checkbox"
            id="agreement"
            checked={acceptedTerms}
            onChange={handleAcceptTerms}
          />
          {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
          <PrivacyPolicy />
          {t('vb:tc_part_three')}
        </label>
      </div>
      <div className="btn-group">
        <button
          type="button"
          className="btn-back"
          onClick={() => setShowTerms(false)}
        >
          {t('vb:back')}
        </button>
        <button
          type="button"
          disabled={!acceptedTerms}
          className="btn-start"
          onClick={() => onDismiss()}
        >
          {t('vb:start_exploring')}
        </button>
      </div>
      <style jsx>{`
        .btn-group {
          width: 100%;
        }
      `}</style>
    </div>
  );

  const getComponent = () => {
    if (showLanguageSelection && !alreadySelectedLanguage) {
      return languageSelection;
    }
    return showTerms ? acceptTerms : welcomeMessage;
  };
  if (renderDelay && openDelay) return null;

  return (
    <div className="landing-page">
      <div className="inner">
        {logoUrl && <img className="logo" src={logoUrl} />}
        {getComponent()}
      </div>
      <style jsx>
        {`
          .landing-page {
            background: url(${mobileBackgroundImageUrl}) center center no-repeat;
            background-size: cover;
            background-color: #000000;
          }
          @media (min-width: 1024px) {
            .landing-page {
              background: url(${backgroundImageUrl}) center center no-repeat;
              background-size: cover;
              background-color: #000000;
            }
          }

          @media (max-width: 768px) {
            .landing-page .tstc .btn-group {
              flex-direction: column-reverse;
            }
          }
        `}
      </style>
    </div>
  );
};

export default VCALanding;

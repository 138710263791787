import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { MeetingLayoutMode } from '../../../../interfaces';
import { COMMON3D_ANIMATION } from '../../../../interfaces/common3D';
import {
  actionOpenTooltip,
  actionTeleportSelectSite,
  actionUpdateLanguage,
  actionUpdateMeetingLayout
} from '../../../../redux/actions';
import { actionUpdateCommon3DActiveAnimationInPano } from '../../../../redux/customActions/common3DAction';
import { DID_UPDATE_ANIMATION_STATE } from '../../../../utils/constants';
import ToolTipWrapper from '../../../Common/ToolTipWrapper';

const Inspify3DMenu = ({ siteId }: { siteId: string }) => {
  const dispatch = useDispatch();
  return (
    <ToolTipWrapper
      parentId="threeDButton"
      onClose={() => {
        dispatch(actionOpenTooltip(undefined));
        dispatch(actionUpdateCommon3DActiveAnimationInPano(undefined));
      }}
      width={250}
      distanceFromParent={30}
    >
      <div className="control-menu teleport-menu">
        <button
          className="list-group-item list-group-item-action"
          onClick={(e) => {
            e?.stopPropagation();
            dispatch(actionUpdateMeetingLayout(MeetingLayoutMode.NORMAL));
            dispatch(actionOpenTooltip(undefined));
            dispatch(actionTeleportSelectSite(siteId));
            dispatch(
              actionUpdateCommon3DActiveAnimationInPano(COMMON3D_ANIMATION)
            );
            dispatch(actionUpdateLanguage('en'));
            logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
              animation: COMMON3D_ANIMATION,
              state: 'START'
            });
          }}
        >
          SSP Pro
        </button>
      </div>
    </ToolTipWrapper>
  );
};

export default Inspify3DMenu;

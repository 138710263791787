import React from 'react';
import { logEvent } from '../../analytics';
import {
  DID_MUTE_BACKGROUND_SOUND,
  DID_UNMUTE_BACKGROUND_SOUND
} from '../../utils/constants';
import FadeInAndOutMusic from '../BackgroundMusic/FadeInAndOutMusic';
import { chMusics } from './Chopard/CHConfig';
import { CHMusicIcon } from './Chopard/CHIcons';
import { CHScenes } from './PSInterface';

const PSBackgroundMusic = ({
  currentScene,
  play,
  showButton
}: {
  currentScene: string;
  play?: boolean;
  showButton?: boolean;
}) => {
  const [mute, setMute] = React.useState(false);

  return (
    <>
      {showButton && (
        <div
          className="CHNav NavSound with-shadow noselect"
          onClick={() => {
            setMute(!mute);
            if (mute) {
              logEvent(DID_UNMUTE_BACKGROUND_SOUND);
            } else {
              logEvent(DID_MUTE_BACKGROUND_SOUND);
            }
          }}
        >
          <CHMusicIcon paused={mute} />
        </div>
      )}

      {chMusics.map((sceneMusic) => (
        <FadeInAndOutMusic
          key={sceneMusic.scenes[0]}
          backgroundMusicUrl={sceneMusic.url}
          loop={true}
          play={
            play &&
            !mute &&
            sceneMusic.scenes.includes(currentScene as CHScenes)
          }
          volume={sceneMusic.volume || 0.1}
          delay={sceneMusic.delay || 2000}
        />
      ))}
      <style jsx>{`
        .NavSound {
          left: 0;
          bottom: 0;
          top: auto;
        }
        @media (orientation: landscape) and (max-height: 600px) {
          :global(.copyright) {
            left: ${showButton ? '130px' : '10px'} !important;
          }
        }
      `}</style>
    </>
  );
};

export default PSBackgroundMusic;

import React from 'react';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { assetBaseUrl } from '../../../config';
import { IMainState } from '../../../interfaces';
import { DID_SHOW_TUTORIAL } from '../../../utils/constants';

enum TutorialStep {
  Step1 = 'step1',
  Step2 = 'step2',
  Step3 = 'step3'
}

const videoSize = {
  [TutorialStep.Step1]: { m: { p: 'portrait', l: 'portrait' }, d: 'portrait' },
  [TutorialStep.Step2]: {
    m: { p: 'portrait', l: 'landscape' },
    d: 'landscape'
  },
  [TutorialStep.Step3]: { m: { p: 'portrait', l: 'landscape' }, d: 'landscape' }
};

const assetPath = `${assetBaseUrl}/chopard/chsps/tutorial/v5`;

const CHTutorial = ({
  onClose,
  onMobile,
  onPortrait,
  scene,
  lang
}: {
  onClose: () => void;
  onMobile?: boolean;
  onPortrait?: boolean;
  scene: string;
  lang: string;
}) => {
  const { focusedObject } =
    useSelector((state: IMainState) => state.clientState?.vb?.pixelStreaming) ||
    {};

  const isFocusObject = !!focusedObject?.type;

  const [currentStep, setCurrentStep] = React.useState<TutorialStep>(null);
  const [show, setShow] = React.useState(false);
  const [canClick, setCanClick] = React.useState(false);
  const [completedStep, setCompletedStep] = React.useState<TutorialStep[]>([]);

  const videoUrlPath = `${assetPath}/${lang}-${currentStep}-${
    onMobile ? 'mobile' : 'desktop'
  }${
    onMobile && currentStep !== TutorialStep.Step1
      ? onPortrait
        ? '-portrait'
        : '-landscape'
      : ''
  }`;

  const onCompletedStep = (step: TutorialStep) => {
    setCanClick(false);
    if (!completedStep.includes(step)) {
      if (completedStep.length === 2) {
        onClose();
      } else {
        setCompletedStep([...completedStep, step]);
      }
    }
  };

  const handlePlay = (step: TutorialStep | null) => {
    setCurrentStep(step);
    if (step) {
      setTimeout(() => {
        setShow(true);
      }, 100);
      setTimeout(() => {
        setCanClick(true);
      }, 1000);
    }
  };

  const handleEnd = () => {
    setShow(false);
    setTimeout(() => {
      handlePlay(null);
      onCompletedStep(currentStep);
    }, 1000);
  };

  const handleClose = () => {
    if (canClick) {
      handleEnd();
    }
  };

  React.useEffect(() => {
    handlePlay(TutorialStep.Step1);
  }, []);

  React.useEffect(() => {
    if (currentStep) {
      logEvent(DID_SHOW_TUTORIAL, DID_SHOW_TUTORIAL, {
        tutorialStep: currentStep,
        tutorialUrl: videoUrlPath
      });
    }
  }, [currentStep]);

  React.useEffect(() => {
    const isFocusProduct = focusedObject?.type === 'PRODUCT';
    const isFocusElement = !isFocusProduct && !!focusedObject;

    if (isFocusProduct && !completedStep.includes(TutorialStep.Step2)) {
      setTimeout(() => handlePlay(TutorialStep.Step2), 2500);
    }

    if (isFocusElement && !completedStep.includes(TutorialStep.Step3)) {
      onCompletedStep(TutorialStep.Step3);
    }

    if (
      !isFocusObject &&
      !completedStep.includes(TutorialStep.Step3) &&
      completedStep.includes(TutorialStep.Step1)
    ) {
      handlePlay(TutorialStep.Step3);
    }
  }, [isFocusObject]);

  React.useEffect(() => {
    if (completedStep.includes(TutorialStep.Step1)) {
      onClose();
    }
  }, [scene]);

  const isPortraitVideo = onMobile
    ? videoSize[currentStep]?.m?.[onPortrait ? 'p' : 'l'] === 'portrait'
    : videoSize[currentStep]?.d === 'portrait';

  if (!currentStep) return null;

  return (
    <>
      {onMobile && (
        <div
          onTouchStart={handleClose}
          onTouchMove={handleClose}
          onTouchEnd={handleClose}
          className="fixed-full touch-actions"
        />
      )}
      <div
        className={`fixed-full d-flex ${show ? 'show' : ''} ${
          onMobile ? 'mobile' : 'desktop'
        }`}
        onClick={handleClose}
      >
        <video
          key={videoUrlPath}
          className={`${currentStep} ${
            isPortraitVideo ? 'portrait-video' : 'landscape-video'
          }`}
          muted
          playsInline
          autoPlay
          loop
        >
          <source src={`${videoUrlPath}.mp4`} type="video/mp4;codecs=hvc1" />
          <source src={`${videoUrlPath}.webm`} type="video/webm" />
        </video>
      </div>
      <style jsx>{`
        .fixed-full {
          z-index: 10;
          color: #fff;
          align-items: center;
          justify-content: center;
          height: 100%;
          opacity: 0;
          transition: opacity 1s ease;
        }
        .touch-actions {
          z-index: 11;
        }
        .fixed-full.show {
          opacity: 1;
        }
        video {
          margin: auto;
          max-height: 90%;
          max-width: 90%;
        }
        .desktop video {
          height: 486px;
          width: auto;
        }

        @media (orientation: portrait) {
          .mobile video {
            width: 90%;
            max-width: 246px;
            height: auto;
          }
        }
        @media (orientation: landscape) {
          .mobile video {
            height: 90%;
            width: auto;
          }
        }
      `}</style>
    </>
  );
};

export default CHTutorial;

import React from 'react';
import { useDispatch } from 'react-redux';
import { JitsiDevice } from '../../../interfaces';
import Microphones from './Microphones';
import DeviceDropdown from './DeviceDropdown';
import { IconMicrophoneOn } from '../MeetingControls/MeetingIcons';
import { transformDefaultDeviceLabel } from '../utils/track';
import { useTranslation } from 'react-i18next';
import { actionChangeInputDeviceAsync } from '../../../redux/asyncActions';

const MicrophoneControl = ({
  devices,
  activeDevice,
  allowChange,
  extraElements = null,
  controlSelectorPrefix
}: {
  devices: JitsiDevice[];
  activeDevice: string;
  allowChange: boolean;
  extraElements?: React.ReactNode;
  controlSelectorPrefix?: string;
}) => {
  const dispatch = useDispatch();
  const activeLabel = devices.find((d) => d.deviceId === activeDevice) || {};
  const [show, setShow] = React.useState(false);
  const id = controlSelectorPrefix
    ? `${controlSelectorPrefix}-MicrophoneControl`
    : `MicrophoneControl`;
  const { t } = useTranslation();
  const currentLabel = transformDefaultDeviceLabel(
    activeLabel.deviceId,
    activeLabel.label,
    t
  );
  return (
    <DeviceDropdown
      label={currentLabel || 'Microphone'}
      icon={<IconMicrophoneOn fill="#000" />}
      onToggle={setShow}
      show={show}
      id={id}
      showArrow={devices?.length > 1}
    >
      <Microphones
        devices={devices}
        onSelect={(deviceId: string) => {
          dispatch(actionChangeInputDeviceAsync(deviceId, 'audio'));
          setShow(false);
        }}
        activeDevice={activeDevice}
        allowChange={allowChange}
      />
      {extraElements}
    </DeviceDropdown>
  );
};

export default MicrophoneControl;

import { useRouter } from 'next/router';
import React from 'react';
import { RiPlayCircleLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { assetBaseUrl } from '../../../../config';
import { actionUpdateLandingVideoState } from '../../../../redux/customActions/loubiFuture';
import {
  DID_CLICK_VIDEO_FULL_SCREEN,
  DID_SKIP_VIDEO,
  DID_START_VIDEO,
  VIDEO_PAUSED
} from '../../../../utils/constants';
import { isUserOniOS, isUserOnWeChat } from '../../../../utils/deviceDetector';
import { useLoubiFutureState } from '../../../hooks/loubiFuture';
import {
  musicVideoUrl,
  welcomeMobileVideoUrl,
  welcomeVideoUrl
} from '../../../ThreejsAnimation/loubiFuture/assets';
import LFCookieDisclaimer from './LFCookieDisclaimer';

const LFLandingVideo = ({
  isOnMobile,
  disableSkip
}: {
  isOnMobile?: boolean;
  disableSkip?: boolean;
}) => {
  const [videoUrl, setVideoUrl] = React.useState<string>(
    isOnMobile ? welcomeMobileVideoUrl : welcomeVideoUrl
  );
  const router = useRouter();
  const videoRef = React.useRef<ReactPlayer>();
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [paused, setPaused] = React.useState(false);
  const [showFullScreenButton, setShowFullScreenButton] = React.useState(false);
  const [showCookie, setShowCookie] = React.useState(false);
  const [isWeChat, setIsWeChat] = React.useState(false);
  const { hidden, finishedPlaying } = useLoubiFutureState().landingVideo || {};

  const dispatch = useDispatch();

  const updateFinishedPlaying = () => {
    dispatch(
      actionUpdateLandingVideoState({
        hidden: true,
        finishedPlaying: true
      })
    );
  };

  const canFullScreen = () => {
    if (!isOnMobile) {
      return false;
    }
    if (router.query.mode === 'cockpit') {
      return false;
    }
    if (isUserOniOS()) {
      const v = document.createElement('video');
      return !!v['webkitEnterFullScreen'];
    }
    return true;
  };

  const enterFullScreenVideo = () => {
    logEvent(DID_CLICK_VIDEO_FULL_SCREEN, DID_CLICK_VIDEO_FULL_SCREEN, {
      videoUrl: musicVideoUrl
    });
    const elem = document.querySelector('#loubi video') as any;
    if (isUserOniOS()) {
      elem.webkitEnterFullScreen();
    } else {
      const requestFullScreen =
        elem.requestFullscreen ||
        elem.mozRequestFullScreen ||
        elem.webkitRequestFullScreen ||
        elem.msRequestFullscreen;
      if (requestFullScreen) {
        requestFullScreen.call(elem);
      }
    }
  };

  const enterExperience = () => {
    updateFinishedPlaying();
  };

  const actedOnCookie = () => {
    setShowCookie(false);
    setVideoUrl(musicVideoUrl);
    setShowFullScreenButton(canFullScreen());
  };

  React.useEffect(() => {
    setIsWeChat(isUserOnWeChat());
  }, []);

  if (hidden) return null;
  return (
    <div
      className={`loubi${finishedPlaying ? ' disappearing' : ''}`}
      id="loubi"
    >
      {!finishedPlaying && (
        <>
          <ReactPlayer
            ref={videoRef}
            playing={isPlaying}
            width="100%"
            height="100%"
            autoPlay
            playsinline
            controls={isWeChat}
            muted={videoUrl !== musicVideoUrl}
            url={videoUrl}
            onPause={() => {
              setPaused(true);
              logEvent(VIDEO_PAUSED, VIDEO_PAUSED, {
                second: videoRef.current?.getCurrentTime(),
                videoUrl
              });
            }}
            onPlay={() => {
              setPaused(false);
            }}
            onStart={() => {
              logEvent(DID_START_VIDEO, DID_START_VIDEO, {
                videoUrl
              });
            }}
            onEnded={() => {
              if (videoUrl === musicVideoUrl) {
                enterExperience();
              } else {
                setShowCookie(true);
              }
            }}
          />
          {paused && isPlaying && videoUrl === musicVideoUrl && (
            <button
              className="btn-play with-shadow"
              onClick={() => {
                setIsPlaying(false);
                setTimeout(() => {
                  setIsPlaying(true);
                }, 100);
              }}
            >
              <RiPlayCircleLine color="#fff" />
            </button>
          )}
        </>
      )}
      {showCookie && (
        <LFCookieDisclaimer
          onAccept={() => {
            actedOnCookie();
          }}
          onReject={() => {
            actedOnCookie();
          }}
        />
      )}
      {videoUrl === musicVideoUrl && !finishedPlaying && !disableSkip && (
        <img
          src={`${assetBaseUrl}/loubifuture/icons/v1/skip_mv_white.svg`}
          alt="skip"
          onClick={() => {
            setTimeout(() => {
              enterExperience();
            }, 100);
            logEvent(DID_SKIP_VIDEO, DID_SKIP_VIDEO, {
              video: 'Welcome Video'
            });
          }}
          className="bottom-right"
        />
      )}
      {showFullScreenButton && (
        <img
          src={`${assetBaseUrl}/loubifuture/icons/v1/fullscreen.png`}
          alt="enter fullscreen"
          onClick={() => {
            enterFullScreenVideo();
          }}
          className="full-screen-button"
        />
      )}
      <style jsx>{`
        .btn-light {
          position: fixed;
          bottom: 20px;
          right: 20px;
          opacity: 0.5;
        }
        .loubi {
          z-index: 9999;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background: black;
          display: flex;
          justify-content: center;
          align-item: center;
          flex-direction: column;
          opacity: 1;
        }
        .bottom-right {
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 80px;
          height: 80px;
          cursor: pointer;
        }

        .full-screen-button {
          position: fixed;
          top: 20px;
          right: 20px;
          width: 60px;
          height: 60px;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .bottom-right {
            bottom: 10px;
            right: 10px;
            width: 60px;
            height: 60px;
          }
          .full-screen-button {
            top: 10px;
            right: 10px;
            width: 50px;
            height: 50px;
          }
        }
        .loubi.disappearing {
          -webkit-animation: fade-out 1.5s ease-out 1s both;
          animation: fade-out 1.5s ease-out 1s both;
        }
        video {
          width: 100%;
        }
        :global(.loubi video) {
          object-fit: ${isOnMobile && videoUrl !== musicVideoUrl
            ? 'cover'
            : 'contain'};
        }
        .input-container {
          max-width: 350px;
          margin: 0 auto;
        }
        .input-group {
          border: 4px solid #efefef;
          border-radius: 10px;
        }
        .alert {
          padding: 2px;
          border-radius: 10px;
        }

        .loubi :global(svg) {
          position: fixed;
          bottom: 20px;
          left: 20px;
          fill: #fff;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .btn-play {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: transparent;
        }

        .btn-play :global(svg) {
          width: 150px;
          height: auto;
          pointer-events: none;
          position: relative;
          bottom: 0;
          left: 0;
        }

        @-webkit-keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LFLandingVideo;

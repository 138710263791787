import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import {
  IVirtualBoutiqueAppointmentContact,
  IMainState,
  AppointmentType
} from '../../../interfaces';
import { actionUpdateUserAttributesAsync } from '../../../redux/asyncActions';
import {
  DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
  DID_SEND_APPOINTMENT_EMAIL
} from '../../../utils/constants';
import { getIdentityId } from '../../../utils/identity';
import { getAppointmentMessageHeader } from '../../storeComponentFactory';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import PrivacyPolicy from '../../Legal/PrivacyPolicy';
import TermsOfUse from '../../Legal/TermsOfUse';
import {
  getAvailableSlots,
  getDefaultDate,
  getIsDateAllowedByBrand
} from './AppointmentDateTimeCalculator';
import { sendFormEmail } from './emailService';
import {
  isValidCountryCode,
  isValidEmail,
  isValidMessage,
  isValidName,
  isValidPhoneNumber
} from './inputValidator';
import { useTranslation } from '../../../i18n';
import { actionAppointmentPopup } from '../../../redux/actions';
import { arSA } from 'date-fns/locale';

export interface IAppointmentFormData {
  salutation: string | null;
  name: string | null;
  email: string | null;
  countryCode: string;
  phone: string | null;
  message: string | null;
  boutiqueContact: IVirtualBoutiqueAppointmentContact;
  date: Date;
  time: string | null;
  acceptedTermsOfUse: boolean;
  allowOffers: boolean;
  subscribeNews: boolean;
  channel: string;
}

interface IAppointmentFormPristine {
  salutation: boolean;
  name: boolean;
  email: boolean;
  phone: boolean;
  time: boolean;
  boutiqueContact: boolean;
  acceptedTermsOfUse: boolean;
  appointmentType: boolean;
}

export interface IAppointmentProps {
  appointmentContacts: IVirtualBoutiqueAppointmentContact[];
  openingHours: string[];
  visitHours?: string[];
  logoUrl: string;
  brandId: string;
  storeId: string;
  open: boolean;
  timezoneGMTOffset: number;
  timezoneName: string;
  phoneCountryCode: string;
  onClose: () => void;
}

enum EmailSentState {
  INITIAL = 'SEND',
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

registerLocale('ar', arSA);

const Appointment = ({
  appointmentContacts,
  openingHours,
  visitHours,
  logoUrl,
  brandId,
  storeId,
  open,
  timezoneGMTOffset,
  timezoneName,
  phoneCountryCode,
  onClose
}: IAppointmentProps) => {
  const appointmentPopupState = useSelector(
    (state: IMainState) => state.clientState.vb?.appointmentPopup
  );

  const appointmentContactState = appointmentPopupState?.contact || '';

  const appointmentType = appointmentPopupState?.type;
  const appointmentEnquiry = appointmentPopupState?.enquiry;

  const isVirtualWalkthrough =
    appointmentType === AppointmentType.VIRTUAL_WALKTHROUGH;

  const [emailSent, setEmailSent] = React.useState(false);
  const [emailSentState, setEmailSentState] = React.useState<EmailSentState>(
    EmailSentState.INITIAL
  );
  const [pristine, setPristine] = React.useState<IAppointmentFormPristine>({
    salutation: true,
    name: true,
    email: true,
    phone: true,
    time: true,
    boutiqueContact: true,
    acceptedTermsOfUse: true,
    appointmentType: true
  });

  const [formData, setFormData] = React.useState<IAppointmentFormData>({
    salutation: null,
    name: null,
    email: null,
    countryCode: phoneCountryCode,
    phone: null,
    channel: 'Desktop',
    message: null,
    boutiqueContact:
      appointmentContacts.length === 1 ? appointmentContacts[0] : null,
    date: getDefaultDate(brandId),
    time: null,
    acceptedTermsOfUse: false,
    allowOffers: false,
    subscribeNews: false
  });

  const mandatoryFields: (keyof IAppointmentFormData)[] = [
    'salutation',
    'name',
    'email',
    'phone',
    'boutiqueContact',
    'date',
    'time',
    'acceptedTermsOfUse'
  ];

  const salutations = ['Dr.', 'Mr.', 'Ms.', 'Mrs.'];

  const animationDuration = 300;
  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setEmailSent(false);
    }, animationDuration);
  };

  const handleSalutation = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, salutation: false });
    setFormData({ ...formData, salutation: value });
  };

  const handleName = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, name: false });
    setFormData({ ...formData, name: value });
  };

  const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    setFormData({ ...formData, email: value });
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, countryCode: value });
  };

  const handlePhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });
    setFormData({ ...formData, phone: value });
  };

  const handleBoutique = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, boutiqueContact: false });
    const contact = appointmentContacts.find((c) => c.name === value);
    setFormData({
      ...formData,
      boutiqueContact: contact
    });
  };

  const handleMessage = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, message: value });
  };

  const handleTC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
  };

  const handleOffer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setFormData({ ...formData, allowOffers: value });
  };

  const handleNewsletterSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({ ...formData, subscribeNews: value });
  };

  const handleTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const time = event.target.value;
    setPristine({ ...pristine, time: false });
    setFormData({ ...formData, time });
  };

  const handleDate = (date: Date) => {
    console.log(date);
    if (!date) date = getDefaultDate(brandId);
    setFormData({ ...formData, date });
  };

  const handleAppointmentType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const type = event.target.value as AppointmentType;
    let channel = 'Desktop';
    setPristine({ ...pristine, appointmentType: false });

    dispatch(actionAppointmentPopup({ ...appointmentPopupState, type }));

    if (type === AppointmentType.PHYSICAL) {
      channel = 'Physical';
    }

    setFormData({
      ...formData,
      channel,
      time: null
    });
  };

  const removePristine = () => {
    const nonPristine: IAppointmentFormPristine = {
      salutation: false,
      name: false,
      email: false,
      phone: false,
      time: false,
      boutiqueContact: false,
      acceptedTermsOfUse: false,
      appointmentType: false
    };
    setPristine(nonPristine);
  };

  const dispatch = useDispatch();

  const handleSend = () => {
    console.log(formData);
    removePristine();
    if (mandatoryFields.some((field) => !formData[field])) {
      return;
    }
    if (
      !isValidEmail(formData.email) ||
      !isValidCountryCode(formData.countryCode) ||
      !isValidName(formData.name) ||
      !isValidPhoneNumber(formData.phone)
    ) {
      return;
    }
    setEmailSentState(EmailSentState.SENDING);
    sendFormEmail(formData, storeId)
      .then(() => {
        setEmailSentState(EmailSentState.INITIAL);
        setEmailSent(true);
        logEvent(DID_SEND_APPOINTMENT_EMAIL);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(
          DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
          DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
          { error }
        );
      })
      .finally(() => {
        dispatch(
          actionUpdateUserAttributesAsync(getIdentityId(), {
            alias: formData.name || '',
            email: formData.email || '',
            mobilePhoneCountryCode: formData.countryCode || '',
            mobileNumber: formData.phone || ''
          })
        );
      });
  };

  const { t, i18n } = useTranslation();

  const renderTitle = () => {
    if (appointmentType === AppointmentType.PHYSICAL) {
      return t('vb:book_your_visit');
    }
    if (appointmentType === AppointmentType.VIRTUAL_WALKTHROUGH) {
      return t('vb:book_your_walkthrough_experience');
    }
    return t('vb:book_your_appointment');
  };

  const renderSelectTime = () => {
    if (isVirtualWalkthrough) {
      return getAvailableSlots(
        formData.date,
        openingHours,
        timezoneGMTOffset
      ).map((s) => (
        <option value={s} key={s}>
          {s}
        </option>
      ));
    }

    if (!isVirtualWalkthrough && visitHours?.length) {
      return getAvailableSlots(
        formData.date,
        visitHours,
        timezoneGMTOffset
      ).map((s) => (
        <option value={s} key={s}>
          {s}
        </option>
      ));
    }

    return (
      <>
        <option value="Morning">{t('vb:morning')}</option>
        <option value="Afternoon">{t('vb:afternoon')}</option>
      </>
    );
  };

  React.useEffect(() => {
    let boutiqueContact =
      appointmentContacts.length === 1 ? appointmentContacts[0] : null;
    let message = formData.message || null;

    if (appointmentContactState) {
      setPristine({ ...pristine, boutiqueContact: false });
      boutiqueContact =
        appointmentContacts.find((c) => c.id === appointmentContactState) ||
        null;
    }

    if (appointmentEnquiry) {
      message = appointmentEnquiry;
    }

    setFormData({
      ...formData,
      boutiqueContact,
      channel: isVirtualWalkthrough ? 'Desktop' : 'Physical',
      message
    });
  }, [appointmentContactState, appointmentType, appointmentEnquiry]);

  return (
    <div className="appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className={`popup-container fadein ${
              emailSent ? 'successful' : ''
            } ${state}`}
            id="ap-popup"
          >
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => handleClose()}
              >
                &times;
              </button>
              <div className="logo">
                <img src={logoUrl} alt="LOGO" />
              </div>
              {!emailSent && (
                <form id="emailform">
                  <h2>{renderTitle()}</h2>
                  <h3>{t('vb:general_information')}</h3>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:salutation')}:</label>
                      <select
                        id="inputsalutation"
                        name="salutation"
                        required
                        onChange={handleSalutation}
                      >
                        <option value="">{t('vb:select')}</option>
                        {salutations.map((s) => (
                          <option key={s} value={s}>
                            {s}
                          </option>
                        ))}
                      </select>
                      {!pristine.salutation &&
                        !salutations.includes(formData.salutation) && (
                          <span className="error">
                            {t('vb:please_select_your_salutation')}
                          </span>
                        )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:name')}:</label>
                      <input
                        type="text"
                        id="inputname"
                        name="name"
                        placeholder={t('vb:enter_your_name')}
                        onBlur={handleName}
                      />
                      {!pristine.name && !isValidName(formData.name) && (
                        <span className="error">
                          {t('vb:please_enter_valid_name')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('email')}:</label>
                      <input
                        type="email"
                        id="inputemail"
                        name="email"
                        placeholder={t('vb:enter_your_email')}
                        onBlur={handleEmail}
                      />
                      {!pristine.email && !isValidEmail(formData.email) && (
                        <span className="error">
                          {t('vb:please_enter_valid_email')}
                        </span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:phone_number')}:</label>
                      <div className="phone-number-input">
                        <input
                          type="tel"
                          id="inputcountrycode"
                          name="countrycode"
                          value={formData.countryCode}
                          onChange={handleCountryCode}
                        />
                        <input
                          type="tel"
                          id="inputphone"
                          name="phone"
                          placeholder={t('vb:enter_phone_number')}
                          onBlur={handlePhoneNumber}
                        />
                      </div>
                      {!pristine.phone &&
                        (!isValidCountryCode(formData.countryCode) ||
                          !isValidPhoneNumber(formData.phone)) && (
                          <span className="error">
                            {t('vb:please_enter_valid_phone_number')}
                          </span>
                        )}
                    </div>
                  </div>

                  <h3>{t('vb:appointment_detail')}</h3>

                  {!appointmentType && (
                    <div className="input-group">
                      <label>{t('vb:book_your_appointment_for')}:</label>
                      <select
                        id="input-appointment-type"
                        name="appointment-type"
                        required
                        onChange={handleAppointmentType}
                        value={appointmentType}
                      >
                        <option value="">{t('vb:select')}</option>
                        <option value={AppointmentType.VIRTUAL_WALKTHROUGH}>
                          {t('vb:virtual_walkthrough')}
                        </option>
                        <option value={AppointmentType.PHYSICAL}>
                          {t('vb:physical_boutique_visit')}
                        </option>
                      </select>
                      {!pristine.appointmentType && !appointmentType && (
                        <span className="error">
                          {t('vb:please_select_appointment_type')}
                        </span>
                      )}
                    </div>
                  )}

                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:appointment_date')}:</label>
                      <DatePicker
                        selected={formData.date}
                        filterDate={getIsDateAllowedByBrand(brandId)}
                        minDate={getDefaultDate(brandId)}
                        onChange={handleDate}
                        dateFormat="MMMM d, yyyy"
                        locale={i18n.language}
                      />
                    </div>

                    <div className="input-group">
                      <label>
                        {t('vb:preferred_time', { timezone: timezoneName })}:
                      </label>
                      <select
                        id="inputtime"
                        name="time"
                        required
                        onChange={handleTime}
                      >
                        <option value="">{t('vb:select')}</option>
                        {renderSelectTime()}
                      </select>
                      {!pristine.time &&
                        (!formData.time ||
                          formData.time.trim().length === 0) && (
                          <span className="error time">
                            {t('vb:please_select_time')}
                          </span>
                        )}
                    </div>
                  </div>

                  {appointmentContacts.length > 1 && (
                    <div className="input-group">
                      <label>{t('vb:preferred_boutique')}:</label>
                      <select
                        id="inputboutique"
                        name="boutiqueemail"
                        required
                        onChange={handleBoutique}
                        value={formData.boutiqueContact?.name || ''}
                      >
                        <option value="">{t('vb:select')}</option>
                        {appointmentContacts.map((c) => (
                          <option value={c.name} key={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                      {!pristine.boutiqueContact &&
                        !formData.boutiqueContact && (
                          <span className="error">
                            {t('vb:please_select_boutique')}
                          </span>
                        )}
                    </div>
                  )}

                  <div className="input-group">
                    <label>{t(`vb:${getAppointmentMessageHeader()}`)}</label>
                    <textarea
                      name="message"
                      id="inputmessage"
                      cols={30}
                      rows={10}
                      placeholder={t('vb:message')}
                      value={formData.message || ''}
                      onChange={handleMessage}
                    ></textarea>
                    {!isValidMessage(formData.message) && (
                      <span className="error">
                        {t('vb:please_input_message')}
                      </span>
                    )}
                  </div>

                  <div className="input-group tstc">
                    <label>
                      <input
                        type="checkbox"
                        id="inputtstc"
                        onChange={handleTC}
                      />
                      {!pristine.acceptedTermsOfUse &&
                        !formData.acceptedTermsOfUse && (
                          <span className="error">
                            {t('vb:please_accept_tc')}
                          </span>
                        )}
                      {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
                      <PrivacyPolicy />
                      {t('vb:tc_part_three')}
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="inputoffers"
                        onChange={handleOffer}
                      />
                      {t('vb:confirm_receive_inspify_offers')}
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="inputsubscribe"
                        onChange={handleNewsletterSubscription}
                      />
                      {t('vb:confirm_subscribe_newsletter')}
                    </label>
                  </div>
                  {isVirtualWalkthrough && (
                    <div className="additional-disclaimer">
                      <p>{t('vb:minimum_browser_requirements')}:</p>
                      <ul>
                        <li>
                          {t('vb:chrome_min_version', { minVersion: '70' })}
                        </li>
                        <li>
                          {t('vb:opera_min_version', { minVersion: '23' })}
                        </li>
                        <li>
                          {t('vb:firefox_min_version', { minVersion: '53' })}
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="input-group">
                    <button
                      id="sendform"
                      type="button"
                      onClick={() => handleSend()}
                    >
                      {t(`vb:${emailSentState}`)}
                    </button>
                    <button
                      id="ap-cancel"
                      className="popup-close"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      {t('vb:close')}
                    </button>
                  </div>
                </form>
              )}
              {emailSent && (
                <div className="success">
                  <h2>{t('vb:thank_you_for_your_request')}</h2>
                  <p>
                    {isVirtualWalkthrough
                      ? t('vb:our_ca_will_contact_soon')
                      : t('vb:our_ca_will_contact_soon_visit')}
                  </p>
                  <button
                    className="popup-close"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('vb:close')}
                  </button>
                </div>
              )}
              <PoweredByInspify />
            </div>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
        `}
      </style>
      <style jsx>
        {`
          .mobile {
            display: none;
          }

          .full {
            display: inline-block;
          }

          @media (max-width: 768px) {
            .mobile {
              display: inline-block;
            }

            .full {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Appointment;

import React from 'react';
import { vcaAssets, vcaKOPAssets } from '.';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import { canInteractWithAnimation } from '../../../hooks/meeting';
import { vanCleefArpelsUsKingOfPrussiaSiteId } from '../../../../config';
export const flyDuration = 0;
const disappearDuration = 100;

export const butterflyEffectDuration = flyDuration + disappearDuration;

const VCAMapButton = ({
  onToggleMap,
  storeId
}: {
  onToggleMap: (open: boolean) => void;
  storeId?: string;
}) => {
  const imgUniqueId = React.useMemo(() => Math.random().toString(), []);
  const viewOnly = !canInteractWithAnimation();
  if (viewOnly) return null;
  const gif =
    storeId === vanCleefArpelsUsKingOfPrussiaSiteId
      ? vcaKOPAssets.mapIcon
      : vcaAssets.mapIcon;
  return (
    <>
      <div className={`VCAMapButton`}>
        <img
          src={`${gif}?${imgUniqueId}`}
          className="img-icon"
        />
        <strong className="btn-label">VIEW FLOORPLAN</strong>
      </div>
      <div onClick={() => onToggleMap(true)} className="clickable" />
      <style jsx>{`
        .VCAMapButton,
        .clickable {
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          z-index: 998;
          user-select: none;
          text-align: center;
          bottom: 10px;
          width: 300px;
          pointer-events: none;
        }

        .clickable {
          width: 120px;
          height: 90px;
          cursor: pointer;
          z-index: 999;
          pointer-events: all;
        }

        .img-icon {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }

        .btn-label {
          display: block;
          font-size: 11px;
          color: #fff;
          margin: auto;
          margin-top: -70px;
        }
        :global(.in-meeting.MDLandscape) .VCAMapButton,
        :global(.in-meeting.MDLandscape) .clickable {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }

        :global(.in-meeting.SMLandscape) .VCAMapButton,
        :global(.in-meeting.SMLandscape) .clickable {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }

        :global(.in-meeting.MDPortrait) .VCAMapButton,
        :global(.in-meeting.MDPortrait) .clickable {
          margin-bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
        }

        :global(.in-meeting.SMPortrait) .VCAMapButton,
        :global(.in-meeting.SMPortrait) .clickable {
          margin-bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
        }
        @media (min-width: 800px) and (min-height: 600px) {
          .VCAMapButton {
            width: 400px;
          }
          .clickable {
            height: 120px;
            width: 120px;
          }
          .btn-label {
            margin-top: -80px;
          }
          .img-icon {
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default VCAMapButton;

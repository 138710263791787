import { IInspiration } from '../../interfaces';
import Avatar from '../Avatar';
import React from 'react';
import Placeholder from '../Placeholder';
import { getInspirationIcon } from './common';
import FocusButton from '../Common/FocusButton';
import Link from '../Common/Link';

interface ThumbnailProps {
  inspiration: IInspiration;
  index: number;
  title?: string;
  onClickThumbnail?: (inspirationId: string) => void | null;
  onFocusItem?: (id: string) => void;
  focusedItem?: string;
}

export interface ListProps {
  inspirations: IInspiration[];
  onClickThumbnail?: (inspirationId: string) => void | null;
  onFocusItem?: (id: string) => void;
  focusedItem?: string;
}

export const InspirationThumbnail = ({
  inspiration,
  index,
  title,
  onClickThumbnail,
  onFocusItem,
  focusedItem
}: ThumbnailProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const icon = getInspirationIcon(inspiration);
  const focusButton = onFocusItem ? (
    <FocusButton
      onFocus={onFocusItem}
      itemId={`${index}`}
      focusedItem={focusedItem}
      className="focus-btn-inspiration with-shadow"
    />
  ) : null;
  return (
    <>
      {onClickThumbnail && typeof onClickThumbnail === 'function' ? (
        <div
          key={index}
          className="thumbnail"
          id={`inspiration-${index}`}
          style={{
            backgroundImage: `url(${inspiration.flipImageUrl})`
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
            onClick={() => {
              try {
                sessionStorage.setItem(location.pathname, `${index}`);
              } catch (e) {
                console.log(e);
              }
              onClickThumbnail(inspiration.id);
            }}
          >
            <Avatar url={inspiration.senderImgUrl} />
            {icon}
            <div className="title-container">
              <p className="title">{title || inspiration.title}</p>
            </div>
          </div>
          {focusButton}
        </div>
      ) : (
        <div
          key={index}
          className="thumbnail"
          id={`inspiration-${index}`}
          style={{
            backgroundImage: `url(${inspiration.flipImageUrl})`
          }}
        >
          {isLoading && <Placeholder />}
          <Link
            as={`/inspirations/${
              inspiration.id && encodeURIComponent(inspiration.id)
            }?inspiration=${index}`}
            href={`/inspirations/[id]`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
          >
            <span
              onClick={() => {
                try {
                  sessionStorage.setItem(location.pathname, `${index}`);
                } catch (e) {
                  console.log(e);
                }
                setIsLoading(true);
              }}
            >
              <Avatar url={inspiration.senderImgUrl} />
              {icon}

              <div className="title-container">
                <p className="title">{title || inspiration.title}</p>
              </div>
            </span>
          </Link>
          {focusButton}
        </div>
      )}
    </>
  );
};

const InspirationList = ({
  inspirations,
  onClickThumbnail,
  onFocusItem,
  focusedItem
}: ListProps) => (
  <>
    {inspirations.map((inspiration: IInspiration, index: number) => (
      <InspirationThumbnail
        inspiration={inspiration}
        index={index}
        key={index}
        onClickThumbnail={onClickThumbnail}
        onFocusItem={onFocusItem}
        focusedItem={focusedItem}
      />
    ))}
  </>
);

export default InspirationList;

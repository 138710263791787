import React from 'react';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiAirwaysWishlistDisplay from './LoubiAirwaysWishlistDisplay';
import { useDispatch } from 'react-redux';
import {
  actionOpenLoubiAirwaysWishlistPopup
} from '../../../../../redux/customActions/loubiAirways';
import { logEvent } from '../../../../../analytics';
import { DID_CLOSE_WISHLIST_POPUP } from '../../../../../utils/constants';
import SendWishlistForm from './SendWishlistForm';

const LoubiAirwaysWishlistPopup = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const wishlist = useLoubiAirwaysState()?.wishlist || {};

  const { open } = wishlist;

  const [openForm, setOpenForm] = React.useState(false);
  const closePopup = () => {
    dispatch(actionOpenLoubiAirwaysWishlistPopup(false));
    logEvent(DID_CLOSE_WISHLIST_POPUP, DID_CLOSE_WISHLIST_POPUP);
  };
  React.useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [open]);

  if (!show) return null;
  return (
    <div
      className={`LoubiAirwaysWishlistPopup fixed-full ${
        !open ? 'closing' : ''
      }`}
    >
      <div className="content">
        <LoubiAirwaysWishlistDisplay
          wishlistState={wishlist}
          onShare={() => setOpenForm(true)}
          onBack={closePopup}
        />
      </div>

      {openForm && (
        <SendWishlistForm
          onClose={() => setOpenForm(false)}
          wishlistState={wishlist}
        />
      )}
      <style jsx global>{`
        .LoubiAirwaysWishlistPopup .loubiWishlistContainer {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 30px;
        }
        .LoubiAirwaysWishlistPopup .loubiWishlistContainer .container {
          background-color: #f3f3d1;
          border-radius: 50px;
        }
        @media (max-width: 1200px) {
          .LoubiAirwaysWishlistPopup .loubiWishlistContainer .container {
            border-radius: 5vw;
            padding: 20px;
          }
        }
        @media (max-width: 768px) {
          .LoubiAirwaysWishlistPopup .loubiWishlistContainer {
            padding: 0;
          }
          .LoubiAirwaysWishlistPopup .loubiWishlistContainer .container {
            border-radius: 5vw;
            padding: 10px;
          }
        }
      `}</style>
      <style jsx>{`
        .LoubiAirwaysWishlistPopup {
          animation: fade-in 0.5s ease-in both;
        }
        .LoubiAirwaysWishlistPopup.closing {
          animation: fade-out 0.5s ease-out both;
        }
        .LoubiAirwaysWishlistPopup :global(.twod-product) {
          z-index: 3;
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LoubiAirwaysWishlistPopup;

import { useRouter } from 'next/router';
import React from 'react';
import {
  IMainState,
  IStore,
  IVirtualBoutiqueConfig,
  ParticipantMeetingState
} from '../../../interfaces';
import CHLanding from './CHLanding';
import PixelStreamLayer from './../PixelStreamLayer';
import {
  DisconnectReason,
  getPlayerServer,
  logDisconnect,
  statusToDisconnectedReasonMap,
  STREAM_SERVER_FAILED_TO_LOAD,
  STREAM_SERVER_FOUND
} from './../utils/connectionHandler';
import {
  CHScenes,
  EmitType,
  PSServer,
  StreamConnectionStatus,
  StreamPlayerStatus
} from './../PSInterface';
import TestCommandButton from './../TestCommandButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionCloseVBPopup,
  actionDidGoToScene,
  actionSetPixelStreamConnectionStatus,
  actionSetPixelStreamPlayerStatus,
  actionSetPixelStreamServer,
  actionUpdateMap
} from '../../../redux/actions';
import { canInteractWithAnimation } from '../../hooks/meeting';
import CHOverlayButtons from './CHOverlayButtons';
import CHMaps from './CHMaps/CHMaps';
import CHNav from './CHNav';
import { logClickButton, logEvent } from '../../../analytics';
import {
  DID_ENTER_EXPERIENCE,
  DID_SELECT_MAP_SPOT,
  DID_TOGGLE_MAP
} from '../../../utils/constants';
import { zIndex } from '../../../config';
import CHSlideNav from './CHSlideNav';
import {
  disablePSIdle,
  enablePSIdle,
  sendCommandToPS,
  sendVoyageModeInfo
} from './../CommandHandler/webToPSCommand';
import { useTranslation } from '../../../i18n';
import PSBackgroundMusic from './../PSBackgroundMusic';
import { last } from 'lodash';
import PSEyeballTimeLogger from './../PSEyeballTimeLogger';
import { MDPortraitNormalSpec } from '../../Meeting/MeetingLayout/MDPortraitNormal';
import { storyWithVideo } from './CHConfig';
import CHDisconnectScreen from './CHDisconnectScreen';
import { getVBCustomComponent } from '../../storeComponentFactory';
import { SMPortraitNormalSpec } from '../../Meeting/MeetingLayout/SMPortraitNormal';
import CHTutorial from './CHTutorial';
import CHFullScreenButton from './CHFullScreenButton';
import { isUserOnAndroid, isUserOniOS } from '../../../utils/deviceDetector';

let popupIdleTime;
let serverFoundTimeOut;
const serverFoundTimeOutMs = 5000;
const LIMIT_RETRY = 4;
let landingPageTimeOut;
const landingPageTimeOutMs = 104000;

const CHPixelStreaming = ({
  store,
  config
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
}) => {
  const dispatch = useDispatch();

  const router = useRouter();
  const pshost = router.query.pshost as string;
  const psprot = router.query.psprot as string;
  const debug = router.query.debug as string;
  const pslanding = router.query.pslanding as string;
  const popupIdle = router.query.popupIdle as string;
  const popupCloseIdleMinutes = popupIdle ? parseInt(popupIdle) : 4;
  const meetingId = router.query.meeting as string;

  const [firstTimeConnected, setFirstTimeConnected] = React.useState(true);
  const [showSlideNav, setShowSlideNav] = React.useState(false);
  const [sceneBeforeDisconnected, setSceneBeforeDisconnected] =
    React.useState<string>(null);
  const [serverBeforeDisconnected, setServerBeforeDisconnected] =
    React.useState<string>(null);
  const [loadPageAttempt, setLoadPageAttempt] = React.useState(0);
  const [reconnectAttempt, setReconnectAttempt] = React.useState(0);
  const [showTutorial, setShowTutorial] = React.useState(true);
  const lastActiveTimestamp = React.useRef(Date.now());
  const floorplanState = useSelector(
    (state: IMainState) => state.clientState?.vb?.map || {}
  );
  const floorplan = floorplanState?.open;

  const setFloorplan = (open: boolean) => {
    dispatch(actionUpdateMap({ ...floorplanState, open }));
  };

  const { connectionStatus, playerStatus, server, focusedObject } =
    useSelector((state: IMainState) => state.clientState?.vb?.pixelStreaming) ||
    {};
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);

  const openingVideo = useSelector(
    (state: IMainState) => state.clientState?.vb?.fullScreenVideoWithUrl?.show
  );

  const { width, height, onMobile } = useSelector(
    (state: IMainState) => state.clientState.viewport || { width: 0, height: 0 }
  );

  const orientation =
    width > height ? 'viewport_landscape ' : 'viewport_portrait';

  const popupState = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );

  const visitedScene = useSelector(
    (state: IMainState) => state.clientState.vb?.sceneIds
  );

  const currentScene = last(visitedScene);

  const streamConnectionStatus =
    connectionStatus || StreamConnectionStatus.INITIAL;

  const setStreamConnectionStatus = (status: StreamConnectionStatus) => {
    const isDisconnected = [
      StreamConnectionStatus.DISCONNECTED,
      StreamConnectionStatus.DISCONNECTED_BY_AFK,
      StreamConnectionStatus.DISCONNECTED_BY_PING_TIMEOUT,
      StreamConnectionStatus.ICE_DISCONNECTED
    ].includes(status);

    if (isDisconnected) {
      logDisconnect({
        server: serverBeforeDisconnected,
        reason:
          statusToDisconnectedReasonMap[status] || DisconnectReason.UNKNOWN
      });
    }

    dispatch(
      actionSetPixelStreamConnectionStatus(
        isDisconnected ? StreamConnectionStatus.DISCONNECTED : status
      )
    );
  };
  const streamAddress = server || null;
  const setStreamAddress = (address: PSServer) => {
    const host = {
      host: address.host + `${window.location.search || ''}`,
      protocol: address.protocol,
      storeId: store.id
    };
    setServerBeforeDisconnected(address.host);
    dispatch(actionSetPixelStreamServer(host));
  };
  const isPlaying = playerStatus === StreamPlayerStatus.PLAYING;
  const viewOnly = !canInteractWithAnimation();

  const inMeeting = !!meetingId;

  const skipLanding = inMeeting || pslanding === 'false';

  const isInTheLounge =
    inMeeting &&
    ![
      ParticipantMeetingState.ENTERED_FROM_LOUNGE,
      ParticipantMeetingState.SHOW_WELCOME_VIDEO,
      ParticipantMeetingState.LEFT_MEETING
    ].includes(meeting?.state);

  const [showLanding, setShowLanding] = React.useState(true);

  const wrapperRef = React.useRef<HTMLDivElement>();

  const connectToStream = (attempt = 1) => {
    if (attempt === 1) {
      window['start_connect_time'] = Date.now();
    }
    setStreamConnectionStatus(StreamConnectionStatus.FINDING_SERVER);
    dispatch(actionDidGoToScene(CHScenes.SCENE_LANDING));
    if (pshost) {
      setStreamAddress({
        host: pshost,
        protocol: psprot || window.location.protocol
      });
      logEvent(STREAM_SERVER_FOUND, STREAM_SERVER_FOUND, {
        server,
        message: 'Static host',
        urlQuery: router.query
      });
      return;
    }

    getPlayerServer({
      onSuccess: (server) => {
        setStreamAddress(server);
        setReconnectAttempt(0);
      },
      onFailed: () => {
        setTimeout(() => {
          if (attempt < LIMIT_RETRY) {
            connectToStream(attempt + 1);
          } else {
            setStreamConnectionStatus(StreamConnectionStatus.SERVER_NOT_FOUND);
          }
        }, 2000);
      },
      meetingID: meetingId,
      spaceId: store?.id
    });
  };

  const retryConnect = () => {
    setLoadPageAttempt(0);
    connectToStream();
  };

  const disconnectPs = () => {
    if (firstTimeConnected) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } else {
      setStreamConnectionStatus(StreamConnectionStatus.DISCONNECTED);
    }
  };

  React.useEffect(() => {
    if (skipLanding) connectToStream();
    if (!viewOnly) setFloorplan(false);
  }, [skipLanding]);

  React.useEffect(() => {
    if (streamConnectionStatus === StreamConnectionStatus.SERVER_FOUND) {
      if (loadPageAttempt < LIMIT_RETRY) {
        serverFoundTimeOut = setTimeout(() => {
          setLoadPageAttempt(loadPageAttempt + 1);
          logEvent(STREAM_SERVER_FAILED_TO_LOAD, STREAM_SERVER_FAILED_TO_LOAD, {
            server: streamAddress?.host
          });
          connectToStream();
        }, serverFoundTimeOutMs);
      } else {
        setStreamConnectionStatus(StreamConnectionStatus.SERVER_NOT_FOUND);
        logEvent(STREAM_SERVER_FAILED_TO_LOAD, STREAM_SERVER_FAILED_TO_LOAD, {
          server: streamAddress?.host
        });
      }
    }

    if (
      streamConnectionStatus === StreamConnectionStatus.SERVER_NOT_FOUND &&
      firstTimeConnected &&
      !skipLanding
    ) {
      setTimeout(() => window.location.reload(), 300);
    }

    if (
      streamConnectionStatus === StreamConnectionStatus.PAGE_LOADED &&
      serverFoundTimeOut
    ) {
      clearTimeout(serverFoundTimeOut);
      if (!inMeeting) {
        landingPageTimeOut = setTimeout(() => {
          disconnectPs();
          logDisconnect({
            reason: DisconnectReason.LANDING_TIMEOUT,
            server: streamAddress?.host,
            sceneBeforeDisconnected: currentScene
          });
        }, landingPageTimeOutMs);
      }
    }

    if (streamConnectionStatus === StreamConnectionStatus.DISCONNECTED) {
      closePopup();
      setSceneBeforeDisconnected(currentScene);
      if (inMeeting && reconnectAttempt < 1) {
        retryConnect();
      }
    }

    return () => {
      if (inMeeting) return;

      if (serverFoundTimeOut) {
        clearTimeout(serverFoundTimeOut);
      }
      if (landingPageTimeOut) {
        clearTimeout(landingPageTimeOut);
      }
    };
  }, [streamConnectionStatus, inMeeting]);

  React.useEffect(() => {
    return () => {
      dispatch(actionSetPixelStreamPlayerStatus(undefined));
    };
  }, []);

  const sendCommand = (descriptor: any, type?: EmitType) => {
    if (!viewOnly) {
      sendCommandToPS(descriptor, type);
    }
  };

  const disableAfk =
    isPlaying &&
    (popupState?.open ||
      openingVideo ||
      floorplan ||
      showSlideNav ||
      showLanding);

  const closePopup = () => {
    clearTimeout(popupIdleTime);
    dispatch(actionCloseVBPopup());
    setFloorplan(false);
    setShowSlideNav(false);
  };

  React.useEffect(() => {
    if (inMeeting) return;

    function clearPopupIdle() {
      if (popupIdleTime) {
        clearTimeout(popupIdleTime);
      }
    }

    window['resetPopupTimer'] = () => {
      clearPopupIdle();
      popupIdleTime = setTimeout(closePopup, popupCloseIdleMinutes * 60000);
    };

    const handlePopupIdle = () => {
      if (disableAfk) {
        window['resetPopupTimer']?.();
      } else {
        clearPopupIdle();
      }
    };

    if (disableAfk) {
      disablePSIdle();
      handlePopupIdle();
    } else {
      enablePSIdle();
      clearTimeout(popupIdleTime);
    }

    document.addEventListener('mousemove', handlePopupIdle);
    return () => {
      document.removeEventListener('mousemove', handlePopupIdle);
    };
  }, [disableAfk]);

  const shouldShowPixelStreamLayer =
    streamAddress?.host &&
    ![
      StreamConnectionStatus.DISCONNECTED,
      StreamConnectionStatus.SERVER_NOT_FOUND,
      StreamConnectionStatus.FINDING_SERVER
    ].includes(streamConnectionStatus);

  React.useEffect(() => {
    if (isPlaying) {
      logEvent(DID_ENTER_EXPERIENCE, DID_ENTER_EXPERIENCE, {
        server: streamAddress?.host
      });
      setFirstTimeConnected(false);
      if (!viewOnly && !skipLanding) {
        sendCommand(firstTimeConnected ? 'START_EXPERIENCE' : 'BACK');
        dispatch(actionDidGoToScene(CHScenes.SCENE_CHAPTER1));
      }
      if (sceneBeforeDisconnected) {
        setSceneBeforeDisconnected(null);
        if (inMeeting) {
          dispatch(actionDidGoToScene(sceneBeforeDisconnected));
        } else {
          sendCommand(sceneBeforeDisconnected);
        }
      }
      sendLanguageChangeCommand(i18n.language || 'en');
      if (isInTheLounge) {
        viewOnly
          ? document.getElementById('joinMeetingButton')?.click()
          : document.getElementById('startMeeting')?.click();
      }

      setTimeout(() => {
        sendLanguageChangeCommand(i18n.language || 'en');
      }, 2000);
      if (inMeeting && !viewOnly) {
        setTimeout(() => {
          sendVoyageModeInfo();
        }, 1000);
      }
    }
  }, [isPlaying]);

  React.useEffect(() => {
    if (isPlaying) {
      sendCommand(orientation);
    }
  }, [orientation, isPlaying]);

  const { i18n } = useTranslation();

  const sendLanguageChangeCommand = (code: string) => {
    sendCommand(
      `LANGUAGE_SWITCH_TO_${
        code?.toUpperCase() === 'KO' ? 'KR' : code?.toUpperCase()
      }`
    );
  };

  React.useEffect(() => {
    const isOnIOS = isUserOniOS();
    const isOnAndroid = isUserOnAndroid();
    if (inMeeting || !isPlaying || (!isOnIOS && !isOnAndroid)) return;
    const onVisibilityChange = () => {
      if (document.visibilityState === 'hidden')
        lastActiveTimestamp.current = Date.now();
      else {
        const timeInActivity = Date.now() - lastActiveTimestamp.current;
        console.log('debug:: time inactivity', timeInActivity);
        if (timeInActivity > 45000) {
          console.log('debug:: disconnected due to inactivity');
          logDisconnect({
            reason: DisconnectReason.USER_INACTIVITY,
            server: streamAddress?.host,
            sceneBeforeDisconnected: currentScene
          });
          setStreamConnectionStatus(StreamConnectionStatus.DISCONNECTED);
        }
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [inMeeting, isPlaying]);

  const showDisconnectScreen = [
    StreamConnectionStatus.DISCONNECTED,
    StreamConnectionStatus.SERVER_NOT_FOUND
  ].includes(streamConnectionStatus);

  const shouldShowLandingScreen = !showDisconnectScreen && !isPlaying;

  React.useEffect(() => {
    if (shouldShowLandingScreen) {
      setShowLanding(true);
    }
  }, [shouldShowLandingScreen]);

  return (
    <>
      <div
        ref={wrapperRef}
        id="pixelStreamWrapper"
        className={`ps-container show-controls d-flex vb-page-wrapper ${streamConnectionStatus} ${playerStatus} ${
          viewOnly ? 'viewOnly' : ''
        } ${isInTheLounge ? 'inTheLounge' : ''}`}
      >
        {shouldShowPixelStreamLayer && (
          <PixelStreamLayer
            url={`${streamAddress?.protocol}//${streamAddress?.host}`}
            onUpdateConnectionStatus={setStreamConnectionStatus}
            storeId={store.id}
            viewOnly={viewOnly}
          />
        )}

        {showLanding && !isInTheLounge && (
          <CHLanding
            connectToStream={() => {
              connectToStream();
            }}
            entrance={() => sendCommand(CHScenes.SCENE_CHAPTER1)}
            onClose={() => setShowLanding(false)}
            streamStatus={streamConnectionStatus}
            playerStatus={playerStatus}
            skipTutorial={!firstTimeConnected || skipLanding}
            isPortrait={orientation === 'viewport_portrait'}
            delayFadeOut={!!sceneBeforeDisconnected}
          />
        )}

        {showDisconnectScreen && (
          <CHDisconnectScreen
            isPortrait={orientation === 'viewport_portrait'}
            onReconnect={retryConnect}
            isDisconnected={
              streamConnectionStatus === StreamConnectionStatus.DISCONNECTED
            }
          />
        )}

        {!showLanding && isPlaying && (
          <>
            {!viewOnly && (
              <>
                <CHNav
                  onClickMap={() => {
                    setFloorplan(true);
                    logEvent(DID_TOGGLE_MAP, DID_TOGGLE_MAP, { open: true });
                    if (popupState?.open) {
                      document.getElementById('popup-close-btn')?.click();
                    }
                  }}
                  currentScene={currentScene}
                  onClickBoutique={() => {
                    sendCommand(CHScenes.SCENE_LOBBY);
                    dispatch(actionDidGoToScene(CHScenes.SCENE_LOBBY));
                    logClickButton('BOUTIQUE');
                  }}
                />
                <CHSlideNav
                  config={config}
                  store={store}
                  onLanguageChange={sendLanguageChangeCommand}
                  show={showSlideNav}
                  setShow={setShowSlideNav}
                />
              </>
            )}

            <CHOverlayButtons
              onSendCommand={sendCommand}
              show={!!focusedObject}
              hide={popupState?.open}
              currentScene={currentScene}
              allowCustomize={true}
              viewOnly={viewOnly}
            />
          </>
        )}
        {!showLanding &&
          isPlaying &&
          !meeting?.isOverlayImgVisible &&
          getVBCustomComponent(store?.id, sendCommand)}
        {!inMeeting && (
          <>
            {currentScene &&
              currentScene !== CHScenes.SCENE_LANDING &&
              streamConnectionStatus !== StreamConnectionStatus.DISCONNECTED &&
              streamConnectionStatus !==
                StreamConnectionStatus.SERVER_NOT_FOUND && (
                <CHFullScreenButton />
              )}

            <PSBackgroundMusic
              play={
                currentScene &&
                !(
                  popupState?.open && storyWithVideo.includes(focusedObject?.id)
                ) &&
                !openingVideo &&
                currentScene !== CHScenes.SCENE_LANDING &&
                streamConnectionStatus !==
                  StreamConnectionStatus.DISCONNECTED &&
                streamConnectionStatus !==
                  StreamConnectionStatus.SERVER_NOT_FOUND
              }
              currentScene={currentScene}
              showButton={
                currentScene &&
                currentScene !== CHScenes.SCENE_LANDING &&
                streamConnectionStatus !==
                  StreamConnectionStatus.DISCONNECTED &&
                streamConnectionStatus !==
                  StreamConnectionStatus.SERVER_NOT_FOUND
              }
            />
          </>
        )}
      </div>
      {floorplan && (
        <CHMaps
          width={width}
          height={height}
          onClose={() => {
            setFloorplan(false);
            logEvent(DID_TOGGLE_MAP, DID_TOGGLE_MAP, { open: false });
          }}
          onSelect={(s) => {
            sendCommand(s);
            logEvent(DID_SELECT_MAP_SPOT, DID_SELECT_MAP_SPOT, {
              scene: s
            });
          }}
        />
      )}

      {debug && (
        <TestCommandButton
          sendCommand={sendCommand}
          onReconnect={retryConnect}
          interactions={[
            'START_EXPERIENCE',
            'START_ANIMATION',
            'BACK',
            'RESET',
            'SCENE_LOBBY',
            'SCENE_ENTRANCE',
            'SCENE_CHAPTER1',
            'SCENE_CHAPTER2',
            'SCENE_CHAPTER3',
            'SCENE_CHAPTER4',
            'SCENE_CHAPTER5',
            'SCENE_CHAPTER6',
            'next',
            'previous'
          ]}
        />
      )}
      {!showLanding && isPlaying && !inMeeting && showTutorial && (
        <CHTutorial
          onClose={() => setShowTutorial(false)}
          onMobile={onMobile}
          scene={currentScene}
          onPortrait={orientation === 'viewport_portrait'}
          lang={i18n.language || 'en'}
        />
      )}

      <PSEyeballTimeLogger />
      <style jsx global>{`
        .CHNav {
          cursor: pointer;
          position: absolute;
          z-index: 4;
          top: 0;
        }
        .canControl.MDPortrait.normal-mode .CHNav {
          left: ${MDPortraitNormalSpec.thumbnailSelf.width + 20}px;
        }

        .canControl.SMPortrait.normal-mode .CHNav {
          left: ${SMPortraitNormalSpec.thumbnailSelf.width + 10}px;
        }
        .CHNav svg,
        .CHNav img {
          width: 60px;
          height: auto;
          transition: opacity 0.3s ease-in-out;
        }
      `}</style>
      <style jsx>{`
        .btn-sm {
          position: absolute;
          z-index: 20;
          bottom: 0;
          right: 0;
        }
        .disconnect {
          z-index: 200;
          position: fixed;
          top: 0;
        }
        .ps-container {
          position: fixed;
          overflow: hidden;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: black;
          z-index: ${isPlaying
            ? 'auto'
            : isInTheLounge &&
              connectionStatus === StreamConnectionStatus.PAGE_LOADED
            ? zIndex.preventActionVeil + 1
            : 'auto'};
          opacity: ${isInTheLounge && !isPlaying ? '0.008' : '1'};
        }
        .ps-container,
        .ps-container * {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .ps-container :global(.pixel-stream-layer) {
          opacity: ${!isPlaying ? '0.008' : '1'};
        }
        .SERVER_NOT_FOUND,
        .FINDING_SERVER {
          z-index: auto;
        }
      `}</style>
    </>
  );
};

export default CHPixelStreaming;

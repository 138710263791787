import React, { useState, Suspense } from 'react';
import { Canvas } from 'react-three-fiber';
import { MDLandscapeNormalSpec } from '../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../Meeting/MeetingLayout/SMLandscapeNormal';
import { Common3DContext } from './Common3DContext';
import SSPProCube from './SSPProCube';
const SSP3DCubeAnimation = () => {
  const [showDetail, setDetail] = useState(undefined);
  const {
    remoteState,
    viewOnly,
    TWEEN,
    updateRemoteState,
    setFocus,
    closeAnimation
  } = React.useContext(Common3DContext);
  return (
    <div>
      <Canvas
        id="canvas-fiber"
        style={{ position: 'fixed' }}
        gl={{ antialias: true }}
      >
        <color attach="background" args={[0, 0, 0]} />
        <ambientLight intensity={0.8} />
        <pointLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
          <SSPProCube
            remoteState={remoteState}
            viewOnly={viewOnly}
            TWEEN={TWEEN}
            updateRemoteState={updateRemoteState}
            setFocus={setFocus}
            setDetail={setDetail}
          />
        </Suspense>
      </Canvas>
      {!viewOnly && (
        <button
          id="close-button"
          onClick={() => {
            closeAnimation();
          }}
        >
          X
        </button>
      )}
      <div id="detail">
        <img id="imgSSPText" src={showDetail} />
      </div>
      <style jsx global>
        {`
          .normal-mode.MDLandscape #canvas-fiber {
            margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
          }
          .normal-mode.SMLandscape #canvas-fiber {
            margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
          }
          .normal-mode.MDLandscape #detail {
            margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
          }
          .normal-mode.SMLandscape #detail {
            margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
          }
        `}
      </style>
      <style jsx>
        {`
          #detail {
            position: fixed;
            left: 20%;
            width: 60%;
            text-align: center;
            bottom: 150px;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 2em;
            padding: 4px;
          }
          #p {
            background: rgba(0, 0, 0, 0.55);
            padding-left: 10px;
            padding-right: 10px;
          }
          #close-button {
            position: fixed;
            top: 60px;
            right: 10px;
            color: white;
            background: #000;
            border: none;
            padding: 16px;
          }
          #imgSSPText {
            width: ${showDetail ? 500 : 0}px;
            height: auto;
            opacity: ${showDetail ? 1 : 0};
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5 ease-in-out;
          }
        `}
      </style>
    </div>
  );
};
export default SSP3DCubeAnimation;

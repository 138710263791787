import React from 'react';
import { logEvent } from '../../../analytics';
import { DID_CLICK_CONTINUE_FLIGHT_BUTTON } from '../../../utils/constants';
import LoubiButtonWrapper from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Buttons/LoubiButtonWrapper';
import { AnimationContext } from '../louboutin/AnimationContext';
import { jpIconUrlBase } from '../../../config/loubiAirways';
const TrolleyWrapper = () => {
  const { selectedModel, setSelectedModel, setLoaded } = React.useContext(
    AnimationContext
  );

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return selectedModel ? (
    <LoubiButtonWrapper>
      <img
        src={`${jpIconUrlBase}/back_with_cross_white.svg`}
        onClick={() => {
          if (setSelectedModel) {
            setSelectedModel(undefined);
          }
          logEvent(DID_CLICK_CONTINUE_FLIGHT_BUTTON);
        }}
        className="loubi-circle-button top-right"
      />

      <style jsx>{`
        img {
          z-index: 2;
        }
      `}</style>
    </LoubiButtonWrapper>
  ) : null;
};

export default TrolleyWrapper;
import React from 'react';
import { alsSgIonShopNowCC } from '../../../config';
import { IProduct, IVirtualBoutiqueContact, Source } from '../../../interfaces';
import { getProductOriginalLink } from '../../../utils/product';
import { isChatAvailable } from '../../../utils/stores';
import { AppContext } from '../../AppContext';
import AppointmentButton from './AppointmentButton';
import ChatButton from './ChatButton';
import EnquireButton, { EnquireNowContact } from './EnquireButton';
import LinkButton from './LinkButton';
import ShopNowEmailButton from './ShopNowEmailButton';

const ALSProductCallToActionButton = ({ product }: { product: IProduct }) => {
  const context = React.useContext(AppContext);

  const productLink = getProductOriginalLink(
    product,
    context.store?.countryCode || 'SG'
  );

  const storeContacts: IVirtualBoutiqueContact[] =
    context.storeConfig?.contacts || [];

  const enquireNowContacts: EnquireNowContact[] = storeContacts.map((c) => ({
    email: c.email,
    name: c.name,
    nameKey: c.nameKey
  }));

  const renderButton = () => {
    if (context.source === Source.Showroom)
      return isChatAvailable(context.storeConfig) ? (
        <ChatButton contacts={storeContacts} />
      ) : (
        <EnquireButton contacts={enquireNowContacts} />
      );
    if (productLink) return <LinkButton url={productLink} />;
  };

  return (
    <div className="action-buttons">
      {renderButton()}
      <AppointmentButton product={product} />
      <ShopNowEmailButton
        product={product}
        email={context.store?.email}
        cc={alsSgIonShopNowCC}
      />
    </div>
  );
};

export default ALSProductCallToActionButton;

import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import * as translation from '../../../i18n';
import { DID_CLICK_CHAT_NOW } from '../../../utils/constants';
import { getShortenedUrl, ShareScenario } from '../../../utils/shareUrl';
import { getContactForToday, getZaloLink } from '../../../utils/zaloLink';
import { AppContext } from '../../AppContext';
import { IVirtualBoutiqueContact } from '../../../interfaces';

const ZaloChatButton = ({
  contacts,
  defaultMessage,
  labelTextKey
}: {
  contacts: IVirtualBoutiqueContact[];
  defaultMessage?: string;
  labelTextKey?: string;
}) => {
  const context = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const handleChat = (contactNumber) => {
    setLoading(true);
    getShortenedUrl(ShareScenario.CHAT_NOW, context)
      .then((shareUrl) => {
        const url = getZaloLink(contactNumber, defaultMessage || shareUrl);
        window.open(url, '_blank');
        logEvent(DID_CLICK_CHAT_NOW, context.store?.id || 'Unknown Store', {
          shareUrl,
          channel: 'zalo',
          store: context.store
        });
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const { t } = translation.useTranslation();

  if (contacts.length < 1) return null;

  const singleContactButton = (
    <>
      <Button
        disabled={loading}
        className="btn-action whatsapp"
        variant="outline-dark"
        onClick={() => handleChat(getContactForToday(contacts)?.zaloNumber)}
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          t(labelTextKey || 'chat_via_zalo')
        )}
      </Button>
      <style jsx>
        {`
          p {
            margin: 10px 0;
            font-style: italic;
            color: #4c4c4c;
            font-size: 14px;
          }

          :global(.whatsapp:hover svg) {
            fill: #fff;
          }

          :global(.dark-mode .whatsapp svg) {
            fill: #fff;
          }
        `}
      </style>
    </>
  );

  return singleContactButton;
};

export default ZaloChatButton;

import { useRouter } from 'next/router';
import React from 'react';
import { logEvent } from '../../../../analytics';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { DID_CLICK_EXIT_BUTTON } from '../../../../utils/constants';

const LFExitButton = ({
  onClick
}: {
  onClick: () => void;
}) => {
  const isInMeeting = useRouter().query.mode === 'cockpit';
  if (isInMeeting) return null;
  return (
    <>
      <img
        className="noselect exit-button hide-for-client-meeting"
        style={{marginLeft:'4px'}}
        onClick={() => {
          onClick();
          logEvent(DID_CLICK_EXIT_BUTTON, DID_CLICK_EXIT_BUTTON);
        }}
        src={`${LFAssetBaseUrl}/icons/v1/exit_white.png`}
      />
    </>
  );
};

export default LFExitButton;

import { failedToLoadImagePlaceholder } from '../../../config';
import { IProduct } from '../../../interfaces';
import { getProductDisplayName } from '../../../utils/product';
import Link from '../../Common/Link';

export const GenericThumbnail = ({
  product,
  onClick,
  language,
  noLink
}: {
  product: IProduct;
  onClick: (product: IProduct) => void;
  language: string;
  noLink?: boolean;
}) => {
  const thumbnail = (
    <button className={`thumbnail `} onClick={() => onClick(product)}>
      <div className="img-container">
        <img
          src={product.previewImageUrl}
          onError={(ev) =>
            ((ev.target as any).src = failedToLoadImagePlaceholder)
          }
        />
      </div>
      <p className="prod-model">{product.modelCode}</p>
      <p className="prod-title">{getProductDisplayName(product, language)}</p>
    </button>
  );
  if (noLink) return thumbnail;
  return (
    <Link href="/products/[id]" as={`/products/${product.id}`}>
      {thumbnail}
    </Link>
  );
};

export default GenericThumbnail;

import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect, useState } from 'react';
import { logEvent } from '../../analytics';
import { allAreas, allCities, getCityForArea } from '../../config/iwcDubai';
import { useTranslation } from '../../i18n';
import { IAddressForm } from '../../interfaces';
import { DID_INPUT_FORM_FIELD, DID_OPEN_FORM } from '../../utils/constants';
import AutocompleteInput from '../Common/AutocompleteInput';

interface IFormPristine {
  address: boolean;
  area: boolean;
  city: boolean;
}

interface IForm {
  [key: string]: string;
}

const mandatoryFields = ['address', 'area', 'city'];

const IWCDubaiAddressForm = ({
  isPristine,
  handleAddress,
  setIsAddressValid,
  formData
}: {
  isPristine: boolean;
  setIsAddressValid: (val: boolean) => void;
  handleAddress: (address: IAddressForm) => void;
  formData?: IAddressForm;
}) => {
  const { t } = useTranslation();
  const addressFormData = formData || {};
  const [address, setAddress] = useState<IForm>(addressFormData);
  const [pristine, setPristine] = React.useState<IFormPristine>({
    address: true,
    area: true,
    city: true
  });

  const invalidAddress = !pristine.address && !address?.address;
  const invalidArea = !pristine.area && !address?.area;
  const invalidCity = !pristine.city && !address?.city;

  const removePristine = () => {
    const nonPristine: IFormPristine = {
      address: false,
      area: false,
      city: false
    };
    setPristine(nonPristine);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event?.target;
    setPristine({ ...pristine, [name]: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: value,
      form: 'IWCDubaiAddressForm'
    });
    setAddress({
      ...address,
      [name]: value
    });
  };

  const handleAreaChange = (value: string) => {
    const city = getCityForArea(value) || address?.city;
    setPristine({ ...pristine, area: false, city: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'area',
      value: t(city),
      form: 'IWCDubaiAddressForm'
    });
    setAddress({
      ...address,
      city,
      area: value
    });
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event?.target;
    setPristine({ ...pristine, city: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'city',
      value: t(value),
      form: 'IWCDubaiAddressForm'
    });
    setAddress({
      ...address,
      city: value
    });
  };

  useEffect(() => {
    if (mandatoryFields.every((field) => !isEmpty(address?.[field]))) {
      handleAddress({ ...address, country: 'UAE' });
      setIsAddressValid(true);
    }
  }, [address]);

  useEffect(() => {
    if (!isPristine) {
      removePristine();
    }
  }, [isPristine]);
  React.useEffect(() => {
    logEvent(DID_OPEN_FORM, DID_OPEN_FORM, {
      form: 'IWCDubaiAddressForm'
    });
  }, []);

  return (
    <Fragment>
      <div className="two-col">
        <div className="input-group">
          <label>{t('vb:address')}:</label>
          <input
            type="text"
            name="address"
            value={address?.address || ''}
            placeholder={t('vb:address')}
            onChange={handleAddressChange}
          />
          {invalidAddress && <span className="error"> {t('vb:required')}</span>}
        </div>
        <div className="input-group">
          <label>{t('vb:area')}:</label>
          <AutocompleteInput
            placeholder={t('vb:area')}
            onSelect={handleAreaChange}
            value={address?.area}
            list={allAreas}
            translateOptions
          />
          {invalidArea && <span className="error"> {t('vb:required')}</span>}
        </div>
      </div>
      <div className="two-col">
        <div className="input-group">
          <label>{t('vb:emirates')}:</label>
          <select
            id="language-options"
            value={address?.city || ''}
            onChange={handleCityChange}
          >
            <option value="" disabled defaultValue="">
              {t('vb:select_city')}
            </option>
            {allCities.map((cities) => (
              <option key={cities} value={cities}>
                {t(cities)}
              </option>
            ))}
          </select>
          {invalidCity && <span className="error"> {t('vb:required')}</span>}
        </div>
        <div className="input-group">
          <label>{t('vb:country')}:</label>
          <input type="text" name="country" value="UAE" disabled />
        </div>
      </div>
    </Fragment>
  );
};

export default IWCDubaiAddressForm;

import { logEvent } from './../../../analytics/index';
import {
  WEBSOCKET_CLOSED,
  WEBSOCKET_OPEN,
  WEBSOCKET_ERROR
} from '../../../utils/constants';
import { differenceInMilliseconds } from 'date-fns';
import { defaultWsMatchMaker, matchMakerBySpaceId } from '../../../config';
import { StreamConnectionStatus } from '../PSInterface';

const FINDING_STREAM_SERVER = 'FINDING_STREAM_SERVER';
export const STREAM_SERVER_FOUND = 'STREAM_SERVER_FOUND';
export const STREAM_SERVER_FAILED_TO_LOAD = 'STREAM_SERVER_FAILED_TO_LOAD';
const STREAM_SERVER_NOT_FOUND = 'STREAM_SERVER_NOT_FOUND';

export const STREAM_DISCONNECTED = 'STREAM_DISCONNECTED';
export const RECEIVED_COMMAND_FROM_STREAM = 'RECEIVED_COMMAND_FROM_STREAM';
export const SEND_COMMAND_TO_STREAM = 'SEND_COMMAND_TO_STREAM';

export const getPlayerServer = ({
  onSuccess,
  onFailed,
  onClose,
  onOpen,
  meetingID,
  spaceId,
  serverAddress
}: {
  onSuccess?: (server: { host: string; protocol: string }) => void;
  onFailed?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  meetingID?: string;
  spaceId?: string;
  serverAddress?: string;
}) => {
  const wsMatchMaker = `wss://${
    spaceId
      ? matchMakerBySpaceId[spaceId] || defaultWsMatchMaker
      : serverAddress || defaultWsMatchMaker
  }/available`;

  const url = wsMatchMaker + (meetingID ? '?meetingId=' + meetingID : '');
  const ws = new WebSocket(url);
  ws.onmessage = (e) => {
    if (e?.data) {
      const { message, status, server } = JSON.parse(e.data);

      if (!server && status !== 'error') {
        logEvent(FINDING_STREAM_SERVER, FINDING_STREAM_SERVER, {
          matchMaker: wsMatchMaker,
          message
        });
        return;
      }

      if (status === 'success') {
        logEvent(STREAM_SERVER_FOUND, STREAM_SERVER_FOUND, {
          matchMaker: wsMatchMaker,
          server,
          status,
          message,
          matchMakerQuery: {
            meetingId: meetingID
          },
          timeTakenMs: window['start_connect_time']
            ? differenceInMilliseconds(new Date(), window['start_connect_time'])
            : undefined
        });
        onSuccess?.({ host: server, protocol: window.location.protocol });
      }
      if (status === 'error') {
        logEvent(
          STREAM_SERVER_NOT_FOUND,
          STREAM_SERVER_NOT_FOUND,
          {
            matchMaker: wsMatchMaker,
            server,
            status,
            message,
            matchMakerQuery: {
              meetingId: meetingID
            }
          },
          true
        );
        onFailed?.();
        ws.close();
      }
    }
  };
  ws.onopen = () => {
    onOpen?.();
    logEvent(WEBSOCKET_OPEN, WEBSOCKET_OPEN, {
      wsAddress: wsMatchMaker,
      matchMakerQuery: {
        meetingId: meetingID
      }
    });
  };
  ws.onclose = () => {
    logEvent(WEBSOCKET_CLOSED, WEBSOCKET_CLOSED, {
      wsAddress: wsMatchMaker,
      matchMakerQuery: {
        meetingId: meetingID
      }
    });
    onClose?.();
  };
  ws.onerror = (error) => {
    onFailed?.();
    logEvent(WEBSOCKET_ERROR, WEBSOCKET_ERROR, {
      wsAddress: wsMatchMaker,
      error,
      matchMakerQuery: {
        meetingId: meetingID
      }
    });
  };

  return () => {
    console.log('debug:: close matchmaker connection', url);
    ws.close();
  };
};

export enum DisconnectReason {
  LANDING_TIMEOUT = 'LANDING_TIMEOUT',
  USER_INACTIVITY = 'USER_INACTIVITY',
  PING_TIMEOUT = 'PING_TIMEOUT',
  MEETING_END = 'MEETING_END',
  UNKNOWN = 'UNKNOWN',
  ICE_DISCONNECTED = 'ICE_DISCONNECTED'
}

export const logDisconnect = ({
  reason,
  server,
  sceneBeforeDisconnected
}: {
  reason: DisconnectReason;
  server?: string;
  sceneBeforeDisconnected?: string;
}) => {
  logEvent(
    STREAM_DISCONNECTED,
    STREAM_DISCONNECTED,
    {
      server,
      sceneBeforeDisconnected,
      reason
    },
    true
  );
};

export const statusToDisconnectedReasonMap = {
  [StreamConnectionStatus.DISCONNECTED_BY_AFK]:
    DisconnectReason.USER_INACTIVITY,
  [StreamConnectionStatus.DISCONNECTED_BY_PING_TIMEOUT]:
    DisconnectReason.PING_TIMEOUT,
  [StreamConnectionStatus.ICE_DISCONNECTED]: DisconnectReason.ICE_DISCONNECTED
};

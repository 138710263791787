import React from 'react';
import { logEvent } from '../../../../../analytics';
import { zIndex } from '../../../../../config';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import { DID_CLICK_CONTINUE_FLIGHT_BUTTON } from '../../../../../utils/constants';
import { AnimationContext } from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import LoubiButtonWrapper from '../../LouboutinCustomComponent/Buttons/LoubiButtonWrapper';

const LJContinueFlightButton = ({
  onClick,
  theme = 'default'
}: {
  onClick: () => void;
  theme?: 'default' | 'red';
}) => {
  const { selectedModel, setSelectedModel } =
    React.useContext(AnimationContext) || {};
  if (selectedModel) {
    return null;
  }
  return (
    <LoubiButtonWrapper>
      <img
        src={
          theme === 'red'
            ? `${jpIconUrlBase}/back_with_cross_red.svg`
            : `${jpIconUrlBase}/back_with_cross_white.svg`
        }
        onClick={() => {
          if (setSelectedModel) {
            setSelectedModel(undefined);
          }
          onClick();
          logEvent(DID_CLICK_CONTINUE_FLIGHT_BUTTON);
        }}
        className="loubi-circle-button top-right"
      />

      <style jsx>{`
        img {
          z-index: ${zIndex.meetingControls + 2};
        }
      `}</style>
    </LoubiButtonWrapper>
  );
};

export default LJContinueFlightButton;

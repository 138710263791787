import { assetBaseUrl, staticBaseUrl, videoBaseUrl } from '../../../config';
import { LFAssetBaseUrl } from '../../../config/loubiFuture';
import { IAnimationProduct } from '../../../interfaces/loubifuture';
export const animationAssetsBaseUrl = `${LFAssetBaseUrl}/`;
// export const animationAssetsBaseUrl = 'http://192.168.1.12:3000/luggage-animation/loubifuture/';
export const arpoadorBaseUrl = animationAssetsBaseUrl + 'arpoador/v3/';
export const viewingroomBaseUrl = animationAssetsBaseUrl + 'viewingroom/v2/';
export const animationVideoAssetsBaseUrl = animationAssetsBaseUrl + 'video/';
export const arBaseUrl = `${staticBaseUrl}/loubifuture/ar`;
export const colorBaseUrl = `${animationAssetsBaseUrl}product/color_v1/`;

export const musicVideoUrl = `${videoBaseUrl}/content/converted/284063a7-de95-4bf6-870b-c03628815652/music video trimmed.mp4/index_high.m3u8`;
export const welcomeVideoUrl = `${videoBaseUrl}/content/converted/7e0a6661-bcc0-4e01-b427-4ab259b26087/web-landing.mp4/index_high.m3u8`;
export const welcomeMobileVideoUrl = `${videoBaseUrl}/content/converted/7e0a6661-bcc0-4e01-b427-4ab259b26087/mobile-landing.mp4/index_high.m3u8`;
export const exitVideoLandscape = `${videoBaseUrl}/content/converted/7e0a6661-bcc0-4e01-b427-4ab259b26087/web-exit.mp4/index_high.m3u8`;
export const exitVideoPortrait = `${videoBaseUrl}/content/converted/7e0a6661-bcc0-4e01-b427-4ab259b26087/mobile-exit.mp4/index_high.m3u8`;

export const testImg = animationVideoAssetsBaseUrl + 'test.jpg';
export const urlVideo = {
  arpoadorA1: `${videoBaseUrl}/content/converted/7843254b-8b98-44ef-abaf-f20cd374069d/section a intro - v8.mp4/index.m3u8`,
  arpoadorA2: `${videoBaseUrl}/content/converted/a8587dad-3883-478f-b807-6ba2eefab02e/zonea 360.m4v/index.m3u8`,
  arpoadorToViewingroom: `${videoBaseUrl}/content/converted/105a82e2-2503-4146-9163-bb50434f4d29/section a outro to elevator_v2.mp4/index.m3u8`,
  viewingroom: `${videoBaseUrl}/content/converted/4934e8a9-f5f7-4535-a4fc-f3196cc5d290/section d-v2.mp4/index.m3u8`,
  viewingroomToArpoador: `${videoBaseUrl}/content/converted/e9fb1a4e-dcde-480a-85c8-f9aadb704919/sectionc.m4v/index_high.m3u8`
};
export const mp3Zoom = animationAssetsBaseUrl + 'mp3/zoom.mp3';
export const arpoadordom = arpoadorBaseUrl + 'A002.png';
export const minigraine = arpoadorBaseUrl + 'MINIGRAINE.glb';
export const minigraineglow = arpoadorBaseUrl + 'minigraine.png';
export const minigraineBaseTexture = arpoadorBaseUrl + 'v02.jpg';
export const cylenderTrans = arpoadorBaseUrl + 'transprent.png';
export const xrayImage = animationAssetsBaseUrl + 'arpoador/xray/xray5.png';
export const productBackgroundImage =
  animationAssetsBaseUrl + 'arpoador/xray/Purple2.png';
export const arpoadorImage =
  animationAssetsBaseUrl + 'arpoador/xray/Arpoador.png';
export const continueImage =
  animationAssetsBaseUrl + 'arpoador/xray/continue.png';
export const backToVideoImage =
  animationAssetsBaseUrl + 'arpoador/xray/back.png';
export const christianLouboutinImage =
  animationAssetsBaseUrl + 'arpoador/xray/Christian-Louboutin.png';

export const modelWorkshopEnvironmentImagePathLight =
  animationAssetsBaseUrl + 'hdr/v1/aircraft_workshop_01_1k_BW.hdr';
export const modelWorkshopEnvironmentImagePathSoft =
  animationAssetsBaseUrl + 'hdr/v1/interior.hdr';
export const viewingRoomEnvironmentImageForTorus =
  animationAssetsBaseUrl + 'hdr/v1/viewingroom.hdr';

export const blackcr: IAnimationProduct = {
  id: '3437d287-a7f7-478c-a127-5c1beeb52bf5',
  modelCode: 'CLLOUBIFUTURE002',
  modelName: 'ARPOADOR BLACK',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR_BLACK_NEW/high/ARPOADOR_BLACK_NEW.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR_BLACK_NEW/Inspify/GLB/ARPOADOR_BLACK_NEW.glb`,
  ambientLight: 0.5,
  directionalLight: 0.5,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathSoft,
  arPath: `${arBaseUrl}/ARPOADOR_BLACK_NEW/ARPOADOR_BLACK_NEW`
};
export const ecume: IAnimationProduct = {
  id: '9b55e50e-3277-4365-abf5-84dba79a6b25',
  modelCode: 'CLLOUBIFUTURE003',
  modelName: 'ARPOADOR ECUME US EXCLUSIVE',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR ECUME/high/ARPOADOR_ECUME.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR ECUME/Inspify/GLB/ARPOADOR ECUME.glb`,
  ambientLight: 1,
  directionalLight: 0.6,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathSoft,
  arPath: `${arBaseUrl}/ARPOADOR_ECUME/ARPOADOR_ECUME`
};
export const flatcrfiesta: IAnimationProduct = {
  id: '988916f1-bdac-4708-85e0-1bb86ea6c111',
  modelCode: 'CLLOUBIFUTURE004',
  modelName: 'ARPOADOR FIESTA APAC EXCLUSIVE',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR FLAT CR FIESTA/high/ARPOADOR FLAT CR FIESTA.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR FLAT CR FIESTA/Inspify/GLB/ARPOADOR FLAT CR FIESTA.glb`,
  ambientLight: 0.5,
  directionalLight: 1,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  arPath: `${arBaseUrl}/ARPOADOR_FLAT_CR_FIESTA/ARPOADOR_FLAT_CR_FIESTA`
};
export const flatSueClfMailNapLamLoubi: IAnimationProduct = {
  id: 'd84fd2e4-8a7b-4204-b932-5b2213cf2a5a',
  modelCode: 'CLLOUBIFUTURE001',
  modelName: 'ARPOADOR LOUBI',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR_FLAT_SUE_CLF_MAIL_NAP_LAM_LOUBI/high/ARPOADOR_FLAT_SUE_CLF_MAIL_NAP_LAM_LOUBI.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR_FLAT_SUE_CLF_MAIL_NAP_LAM_LOUBI/Inspify/GLB/ARPOADOR_FLAT_SUE_CLF_MAIL_NAP_LAM_LOUBI.glb`,
  ambientLight: 0.5,
  directionalLight: 0.4,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathSoft,
  arPath: `${arBaseUrl}/ARPOADOR_FLAT_SUE_CLF_MAIL_NAP_LAM_LOUBI/ARPOADOR_FLAT_SUE_CLF_MAIL_NAP_LAM_LOUBI`
};
export const flatSueClfMshNapLamSand: IAnimationProduct = {
  id: '583abe39-21ea-48df-acfd-a7143d1b4a51',
  modelCode: 'CLLOUBIFUTURE005',
  modelName: 'ARPOADOR SAND EMEI EXCLUSIVE',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR_FLAT_SUE_CLF_MSH_NAP_LAM_SAND/high/ARPOADOR_FLAT_SUE_CLF_MSH_NAP_LAM_SAND.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/ARPOADOR_FLAT_SUE_CLF_MSH_NAP_LAM_SAND/Inspify/GLB/ARPOADOR_FLAT_SUE_CLF_MSH_NAP_LAM_SAND.glb`,
  ambientLight: 1,
  directionalLight: 0.6,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathSoft,
  arPath: `${arBaseUrl}/ARPOADOR_FLAT_SUE_CLF_MSH_NAP_LAM_SAND/ARPOADOR_FLAT_SUE_CLF_MSH_NAP_LAM_SAND`
};

export const arpoadorProducts: IAnimationProduct[] = [
  blackcr,
  ecume,
  flatSueClfMailNapLamLoubi,
  flatSueClfMshNapLamSand,
  flatcrfiesta
];

export const viewingroomTranceTexture = viewingroomBaseUrl + 'trans.png';

export const backparisCalfEmpireGommeClStrap: IAnimationProduct = {
  id: '71dde3e6-8c10-4d4a-b7aa-9c31f550d5b2',
  modelCode: 'CLLOUBIFUTURE011',
  modelName: 'BACKPARIS BLACK & LOUBI',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP/high/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP/Inspify/GLB/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP.glb`,
  ambientLight: 1,
  directionalLight: 1,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  colorImgUrl: colorBaseUrl + '9.jpg',
  arPath: `${arBaseUrl}/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP/res/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP/res/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP/res/BACKPARIS_CALF_EMPIRE_GOMME_CL_STRAP_occlusionRoughnessMetallic.jpg`
};
export const backparisCalfEmpireGommeClStrapSilver: IAnimationProduct = {
  id: '9253c0bd-6daa-434f-a5d8-47f810c299d5',
  modelCode: 'CLLOUBIFUTURE012',
  modelName: 'BACKPARIS SILVER',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/BACKPARIS_DOUDOUNE_SILVER/high/BACKPARIS_DOUDOUNE_SILVER.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/BACKPARIS_DOUDOUNE_SILVER/Inspify/GLB/BACKPARIS_DOUDOUNE_SILVER.glb`,
  ambientLight: 0.5,
  directionalLight: 1,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  colorImgUrl: colorBaseUrl + '0.jpg',
  arPath: `${arBaseUrl}/BACKPARIS_DOUDOUNE_SILVER/BACKPARIS_DOUDOUNE_SILVER`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/BACKPARIS_DOUDOUNE_SILVER/res/BACKPARIS_DOUDOUNE_SILVER_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/BACKPARIS_DOUDOUNE_SILVER/res/BACKPARIS_DOUDOUNE_SILVER_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/BACKPARIS_DOUDOUNE_SILVER/res/BACKPARIS_DOUDOUNE_SILVER_occlusionRoughnessMetallic.jpg`
};
export const loubitownCalfEmpireGommeClStrapBlackLoubiBlack: IAnimationProduct =
  {
    id: '40687a07-9c89-488a-970d-c361f71e4e3b',
    modelCode: 'CLLOUBIFUTURE013',
    modelName: 'LOUBITOWN BLACK & LOUBI',
    modelUrl: `${assetBaseUrl}/loubifuture/latest/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/high/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK.glb`,
    lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/Inspify/GLB/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK.glb`,
    ambientLight: 1,
    directionalLight: 1,
    hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
    colorImgUrl: colorBaseUrl + '9.jpg',
    arPath: `${arBaseUrl}/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK`,
    mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/res/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK_baseColor.jpg`,
    normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/res/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK_normal.jpg`,
    metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/res/LOUBITOWN_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK_occlusionRoughnessMetallic.jpg`
  };
export const loubitownCalfEmpireGommeClStrapBlackLoubiSilver: IAnimationProduct =
  {
    id: '4fb21516-a242-4f16-a312-eb8db0979d0f',
    modelCode: 'CLLOUBIFUTURE015',
    modelName: 'LOUBITOWN SILVER',
    modelUrl: `${assetBaseUrl}/loubifuture/latest/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA/high/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA.glb`,
    lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA/Inspify/GLB/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA.glb`,
    ambientLight: 0.6,
    directionalLight: 0.3,
    hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
    colorImgUrl: colorBaseUrl + '0.jpg',
    arPath: `${arBaseUrl}/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA`,
    mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA/res/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA_baseColor.jpg`,
    normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA/res/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA_normal.jpg`,
    metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA/res/LOUBITOWN_CALF_CAMOU_LOUBI_GOMME_CL_STRA_occlusionRoughnessMetallic.jpg`
  };
export const ourGeorgesCalfAbrasivatoBlack: IAnimationProduct = {
  id: 'e069c6c2-ce34-428c-9b5a-91a1689edf06',
  modelCode: 'CLLOUBIFUTURE007',
  modelName: 'OUR GEORGES BLACK',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_CALF_ABRASIVATO_BLACK/high/OUR_GEORGES_CALF_ABRASIVATO_BLACK.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_CALF_ABRASIVATO_BLACK/Inspify/GLB/OUR_GEORGES_CALF_ABRASIVATO_BLACK.glb`,
  ambientLight: 1,
  directionalLight: 0.5,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  colorImgUrl: colorBaseUrl + '7.jpg',
  arPath: `${arBaseUrl}/OUR_GEORGES_CALF_ABRASIVATO_BLACK/OUR_GEORGES_CALF_ABRASIVATO_BLACK`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_CALF_ABRASIVATO_BLACK/res/OUR_GEORGES_CALF_ABRASIVATO_BLACK_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_CALF_ABRASIVATO_BLACK/res/OUR_GEORGES_CALF_ABRASIVATO_BLACK_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_CALF_ABRASIVATO_BLACK/res/OUR_GEORGES_CALF_ABRASIVATO_BLACK_occlusionRoughnessMetallic.jpg`
};
export const ourGeorgesSpecchioSilver: IAnimationProduct = {
  id: '9870bd68-0e1c-42c2-b563-100ec5bc3636',
  modelCode: 'CLLOUBIFUTURE010',
  modelName: 'OUR GEORGES SPECCHIO',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_SPECCHIO_SILVER/high/OUR_GEORGES_SPECCHIO_SILVER.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_SPECCHIO_SILVER/Inspify/GLB/OUR_GEORGES_SPECCHIO_SILVER.glb`,
  ambientLight: 0.2,
  directionalLight: 0.2,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  colorImgUrl: colorBaseUrl + '2.jpg',
  arPath: `${arBaseUrl}/OUR_GEORGES_SPECCHIO_SILVER/OUR_GEORGES_SPECCHIO_SILVER`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_SPECCHIO_SILVER/res/OUR_GEORGES_SPECCHIO_SILVER_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_SPECCHIO_SILVER/res/OUR_GEORGES_SPECCHIO_SILVER_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_SPECCHIO_SILVER/res/OUR_GEORGES_SPECCHIO_SILVER_occlusionRoughnessMetallic.jpg`
};

export const ourGeorgesBAntispecchioDoudoneApSilver: IAnimationProduct = {
  id: 'bbd2e157-817e-4ccd-a8da-88d45ef05430',
  modelCode: 'CLLOUBIFUTURE009',
  modelName: 'OUR GEORGES BOOT SPECCHIO DOUDOUNE',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_B ANTISPECCHIO-DOUDONE_SP_SILVER/high/OUR_GEORGES_B_ANTISPECCHIO-DOUDOUNE_SP_SILVER.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_B ANTISPECCHIO-DOUDONE_SP_SILVER/Inspify/GLB/OUR_GEORGES_B_ANTISPECCHIO-DOUDOUNE_SP_SILVER.glb`,
  ambientLight: 0.5,
  directionalLight: 0.3,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  colorImgUrl: colorBaseUrl + '6.jpg',
  arPath: `${arBaseUrl}/OUR_GEORGES_B_ANTISPECCHIO-DOUDONE_SP_SILVER/OUR_GEORGES_B_ANTISPECCHIO-DOUDOUNE_SP_SILVER`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_B ANTISPECCHIO-DOUDONE_SP_SILVER/res/OUR_GEORGES_B_ANTISPECCHIO-DOUDOUNE_SP_SILVER_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_B ANTISPECCHIO-DOUDONE_SP_SILVER/res/OUR_GEORGES_B _ANTISPECCHIO-DOUDOUNE_SP_SILVER_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR_GEORGES_B ANTISPECCHIO-DOUDONE_SP_SILVER/res/OUR_GEORGES_B_ANTISPECCHIO-DOUDOUNE_SP_SILVER_occlusionRoughnessMetallic.jpg`
};
export const ourGeorgesBCalfMinigraineBrushBlack: IAnimationProduct = {
  id: 'a83eac28-1cf7-443a-a5e1-2ad34b1bafd8',
  modelCode: 'CLLOUBIFUTURE006',
  modelName: 'OUR GEORGES BOOT BLACK',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK/high/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK/Inspify/GLB/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK.glb`,
  ambientLight: 1,
  directionalLight: 1,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  colorImgUrl: colorBaseUrl + '3.jpg',
  arPath: `${arBaseUrl}/OUR_GEORGES_B__CALF_MINIGRAINE_BRUSH_BLACK/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR GEORGES B  CALF MINIGRAINE BRUSH BLACK/res/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR GEORGES B  CALF MINIGRAINE BRUSH BLACK/res/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR GEORGES B  CALF MINIGRAINE BRUSH BLACK/res/OUR_GEORGES_B_CALF_MINIGRAINE_BRUSH_BLACK_occlusionRoughnessMetallic.jpg`
};

export const ourGeorgesLCalfAbrasivatoBlack: IAnimationProduct = {
  id: 'bb3830f1-1813-4c98-b762-afbdb5a6ed6f',
  modelCode: 'CLLOUBIFUTURE008',
  modelName: 'OUR GEORGES LACE UP BLACK',
  modelUrl: `${assetBaseUrl}/loubifuture/latest/OUR GEORGES L CALF ABRASIVATO BLACK/high/OUR_GEORGES_L_CALF_ABRASIVATO_BLACK.glb`,
  lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/OUR GEORGES L CALF ABRASIVATO BLACK/Inspify/GLB/OUR_GEORGES_L_CALF_ABRASIVATO_BLACK (1).glb`,
  ambientLight: 1,
  directionalLight: 1,
  hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
  arPath: `${arBaseUrl}/OUR_GEORGES_L_CALF_ABRASIVATO_BLACK/OUR_GEORGES_L_CALF_ABRASIVATO_BLACK`,
  mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR GEORGES L CALF ABRASIVATO BLACK/res/Abrasivato_mat_baseColor.jpg`,
  normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR GEORGES L CALF ABRASIVATO BLACK/res/Abrasivato_mat_normal.jpg`,
  metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/OUR GEORGES L CALF ABRASIVATO BLACK/res/Abrasivato_mat_occlusionRoughnessMetallic.jpg`
};

export const ruistoteCalfEmpireGommeClStrapBlackLoubiBlack: IAnimationProduct =
  {
    id: 'c3c2306d-fccd-4346-9211-eaacf79f791c',
    modelCode: 'CLLOUBIFUTURE014',
    modelName: 'RUISTOTE BLACK',
    modelUrl: `${assetBaseUrl}/loubifuture/latest/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/high/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK.glb`,
    lowpolyModelUrl: `${assetBaseUrl}/loubifuture/latest/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/Inspify/GLB/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK.glb`,
    ambientLight: 1.01,
    directionalLight: 1,
    hdrSavedVersion: modelWorkshopEnvironmentImagePathLight,
    arPath: `${arBaseUrl}/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK`,
    mapTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/res/cl_bag_baseColor.jpg`,
    normalTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/res/cl_bag_normal.jpg`,
    metallicTextureUrl: `${assetBaseUrl}/loubifuture/latest/low/RUISTOTE_CALF_EMPIRE_GOMME_CL_STRAP_BLACK_LOUBI_BLACK/res/cl_bag_occlusionRoughnessMetallic.jpg`
  };

export const viewingroomProducts: IAnimationProduct[] = [
  backparisCalfEmpireGommeClStrapSilver,
  loubitownCalfEmpireGommeClStrapBlackLoubiBlack,
  ourGeorgesSpecchioSilver,
  ourGeorgesBCalfMinigraineBrushBlack,
  ruistoteCalfEmpireGommeClStrapBlackLoubiBlack,
  ourGeorgesLCalfAbrasivatoBlack,
  ourGeorgesBAntispecchioDoudoneApSilver,
  ourGeorgesCalfAbrasivatoBlack,
  loubitownCalfEmpireGommeClStrapBlackLoubiSilver,
  backparisCalfEmpireGommeClStrap
];

export const allLFProducts = [...arpoadorProducts, ...viewingroomProducts];

export const getLFProductById = (id: string) =>
  allLFProducts.find((model) => model.id === id);

export const getARUrlByProductId = (id: string) => {
  return `${staticBaseUrl}/3d-model-viewer/ar.html?id=${id}`;
};

export const LF_YELLOW = '#FFDE49';

export const LFColor = {
  blue: '#00FFEE',
  purple: '#B663E0',
  purpleLight: '#CC45CC',
  purpleDark: '#22003D'
};

export const getLFProductImage = (id: string) =>
  `https://products.inspify.io/476bd411-03e9-4e6f-8c8b-8c51d1e0f5e1%2F${id}%2F1000x1000%2F${id}_001.png`;

export const hasColorOptions = (productId: string) =>
  allLFProducts.find((p) => p.id === productId).colorImgUrl;

export const getViewingRoomColorByIndex = (
  index: number
): {
  colorUrl: string;
  itemId: number;
}[] => {
  switch (index) {
    case 0:
    case 9:
      return [
        { colorUrl: viewingroomProducts[9].colorImgUrl || '', itemId: 9 },
        { colorUrl: viewingroomProducts[0].colorImgUrl || '', itemId: 0 }
      ];
    case 7:
    case 2:
      return [
        { colorUrl: viewingroomProducts[7].colorImgUrl || '', itemId: 7 },
        { colorUrl: viewingroomProducts[2].colorImgUrl || '', itemId: 2 }
      ];
    case 6:
    case 3:
      return [
        { colorUrl: viewingroomProducts[6].colorImgUrl || '', itemId: 6 },
        { colorUrl: viewingroomProducts[3].colorImgUrl || '', itemId: 3 }
      ];

    case 8:
    case 1:
      return [
        { colorUrl: viewingroomProducts[8].colorImgUrl || '', itemId: 8 },
        { colorUrl: viewingroomProducts[1].colorImgUrl || '', itemId: 1 }
      ];
    default:
      return [];
  }
};

export const ARIcon = (productId: string) => {
  const asset = `${LFAssetBaseUrl}/icons/v1`;
  if (arpoadorProducts.map((p) => p.id).includes(productId)) {
    return `${asset}/ARarpoador.png`;
  }

  switch (productId) {
    case backparisCalfEmpireGommeClStrap.id:
    case backparisCalfEmpireGommeClStrapSilver.id:
      return `${asset}/ARbackparis.png`;
    case loubitownCalfEmpireGommeClStrapBlackLoubiBlack.id:
    case loubitownCalfEmpireGommeClStrapBlackLoubiSilver.id:
      return `${asset}/ARloubitowncalf.png`;
    case ourGeorgesBAntispecchioDoudoneApSilver.id:
    case ourGeorgesBCalfMinigraineBrushBlack.id:
      return `${asset}/ARourgeorge.png`;
    case ourGeorgesSpecchioSilver.id:
    case ourGeorgesCalfAbrasivatoBlack.id:
      return `${asset}/ARourgeorgeflat.png`;
    case ourGeorgesLCalfAbrasivatoBlack.id:
      return `${asset}/ARourgeorgelace.png`;
    case ruistoteCalfEmpireGommeClStrapBlackLoubiBlack.id:
      return `${asset}/ARruistote.png`;
  }
};

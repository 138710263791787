import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { getNudgeWithContentById } from '../../../../clientSideServices/nudge';
import { loubiAirwaysJpBoutiqueId } from '../../../../config';
import {
  beautyDemoVideoMobileUrl,
  beautyDemoVideoUrl,
  cockpitVideoMobileUrl,
  cockpitVideoUrl,
  exitVideoMobileUrl,
  exitVideoUrl,
  jpIconUrlBase,
  stylishDemoVideoMobileUrl,
  stylishDemoVideoUrl
} from '../../../../config/loubiAirways';
import {
  IMainState,
  INudgePayload,
  ParticipantMeetingState
} from '../../../../interfaces';
import {
  LoubiAirwaysAnimation,
  LoubiAirwaysFullScreenVideo
} from '../../../../interfaces/loubiairways';
import { actionCartOpenPopup } from '../../../../redux/actions';
import { actionUpdateUserAttributesAsync } from '../../../../redux/asyncActions';
import {
  actionUpdateLoubiAirwaysFullScreenVideo,
  actionUpdateLoubiAirwaysInvite
} from '../../../../redux/customActions/loubiAirways';
import {
  DID_CLICK_MUTE_VIDEO,
  DID_CLICK_UNMUTE_VIDEO
} from '../../../../utils/constants';
import {
  isUserOnMobile,
  isUserOnMobileOnly
} from '../../../../utils/deviceDetector';
import { getIdentityId } from '../../../../utils/identity';
import ChatConnectionContextContainer from '../../../ChatConnectionContext';
import FullScreenVideo from '../../../FullScreenVideo';
import { useCartState } from '../../../hooks/cart';
import {
  shouldEnableLikeAndReservationForLoubiJp,
  useLoubiAirwaysState
} from '../../../hooks/loubiAirways';
import {
  canInteractWithAnimation,
  useMeetingLocalAudio
} from '../../../hooks/meeting';
import PoweredByInspify from '../../../Legal/PoweredByInspify';
import { MDGlobalSpec } from '../../../Meeting/MeetingLayout/MDGlobal';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMGlobalSpec } from '../../../Meeting/MeetingLayout/SMGlobal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import { ModelEnvironment } from '../../../ThreejsAnimation/louboutin/background';
import LoubiAirwaysJapanAnimationContextContainer from '../../../ThreejsAnimation/louboutin/LoubiAirwaysJapanAnimationContext';
import AirplaneWindowWrapper from '../../../ThreejsAnimation/louboutinJp/AirplaneWindowWrapper';
import BeautyCartWrapper from '../../../ThreejsAnimation/louboutinJp/BeautyCartWrapper';
import TarotCardWrapper from '../../../ThreejsAnimation/louboutinJp/TarotCardWrapper';
import TrolleyWrapper from '../../../ThreejsAnimation/louboutinJp/TrolleyWrapper';
import NonCockpitModeOnlyElements from '../../NonCockpitModeOnlyElements';
import MuteButton from '../LouboutinCustomComponent/Buttons/MuteButton';
import LoubiAirwaysLandingVideo from '../LouboutinCustomComponent/LandingVideo';
import LoubiAirwaysBackgroundMusic from '../LouboutinCustomComponent/LoubiAirwaysBackgroundMusic';
import LoubiAirwaysExit from '../LouboutinCustomComponent/LoubiAirwaysExit';
import LoubiAirwaysSpotifyPlayer from '../LouboutinCustomComponent/LoubiAirwaysSpotifyPlayer';
import CompartmentXRayAnimation from '../LouboutinJpCustomComponent/CompartmentXRay';
import LJCalendarButton from './Buttons/LJCalendarButton';
import LJCatalogButtons from './Buttons/LJCatalogButtons';
import LJChatButton from './Buttons/LJChatButton';
import LJContinueFlightButton from './Buttons/LJContinueFlightButton';
import LJShoppingBagButton from './Buttons/LJShoppingBagButton';
import ClientChatContextContainer from './LiveChatPanel/ClientChatContext';
import liveChatConfig from './LiveChatPanel/liveChatConfig';
import LiveChatPanel from './LiveChatPanel/LiveChatPanel';
import LJInstructionPopup from './LJInstructionPopup';
import LJProductDetails from './LJProductDetails';
import InflightMagazine from './Magazine/InFlightMagazine';
import LoubiTimes from './NewsPaper/LoubiTimes';
import PlanePlan from './PlanePlan';
import LJReservedProducts from './Reservation/LJReservedProducts';
import OmgMagazine from './SinglePaper/OmgMagazine';
import StylishGuide from './SinglePaper/StylishGuide';
import Tray from './Tray';

const LoubiAirwaysJpCustomComponent = () => {
  const [isOnMobile, setIsOnMobile] = React.useState<boolean>(null);
  const [isOnMobileOrTablet, setIsOnMobileOrTablet] = React.useState(false);
  const [muteVideo, setMuteVideo] = React.useState(false);

  const showReservations = useCartState()?.open;
  const loubiAirwaysState = useLoubiAirwaysState();
  const router = useRouter();
  const { activeAnimation, fullScreenVideo, landingVideo } = loubiAirwaysState;
  const clientState = useSelector((state: IMainState) => state.clientState);
  const storeId = clientState?.store?.id;
  const brandId = clientState?.store?.brands?.[0]?.brandId;
  const meeting = clientState?.meeting;
  const meetingState = meeting?.state;
  const dispatch = useDispatch();
  const { muteAudioAndRestoreLater, restoreAudio } = useMeetingLocalAudio();
  const renderReservation = shouldEnableLikeAndReservationForLoubiJp();

  React.useEffect(() => {
    const shouldMute = () => {
      if (meetingState !== ParticipantMeetingState.ENTERED_FROM_LOUNGE) {
        return false;
      }
      if (isOnMobileOrTablet && !meeting?.isPresenter) {
        return true;
      }
      return false;
    };
    setMuteVideo(shouldMute());
  }, [fullScreenVideo, meetingState, isOnMobileOrTablet]);

  React.useEffect(() => {
    if (meetingState !== ParticipantMeetingState.ENTERED_FROM_LOUNGE) return;
    if (
      fullScreenVideo &&
      fullScreenVideo !== LoubiAirwaysFullScreenVideo.COCKPIT
    ) {
      muteAudioAndRestoreLater();
    } else {
      restoreAudio();
    }
  }, [fullScreenVideo, meetingState]);

  const canInteract = canInteractWithAnimation();

  const shouldShowToggleMuteButton = () => {
    if (meetingState !== ParticipantMeetingState.ENTERED_FROM_LOUNGE) {
      return false;
    }
    if (isOnMobileOrTablet && !meeting?.isPresenter) {
      return true;
    }
    return false;
  };

  const updateUserNameIfNeeded = (userName: string, email: string) => {
    const identityId = getIdentityId();
    dispatch(
      actionUpdateUserAttributesAsync(identityId, {
        alias: userName,
        email
      })
    );
  };

  const fetchInvite = (id: string) => {
    getNudgeWithContentById(id)
      .then((result) => {
        const data = result.data as INudgePayload;
        dispatch(actionUpdateLoubiAirwaysInvite(data));
        updateUserNameIfNeeded(data?.name, data?.email);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    setIsOnMobile(isUserOnMobileOnly());
    setIsOnMobileOrTablet(isUserOnMobile());

    if (router.query.utm_source === 'invite' && router.query.utm_campaign) {
      fetchInvite(router.query.utm_campaign as string);
    }
  }, []);

  const getVideoUrl = React.useCallback(
    (fullScreenVideo: LoubiAirwaysFullScreenVideo) => {
      switch (fullScreenVideo) {
        case LoubiAirwaysFullScreenVideo.COCKPIT:
          return isOnMobile ? cockpitVideoMobileUrl : cockpitVideoUrl;
        case LoubiAirwaysFullScreenVideo.STYLISH_DEMO:
          return isOnMobile ? stylishDemoVideoMobileUrl : stylishDemoVideoUrl;
        case LoubiAirwaysFullScreenVideo.BEAUTY_DEMO:
          return isOnMobile ? beautyDemoVideoMobileUrl : beautyDemoVideoUrl;
        case LoubiAirwaysFullScreenVideo.EXIT:
          return isOnMobile ? exitVideoMobileUrl : exitVideoUrl;
        default:
          return;
      }
    },
    []
  );

  const getAnimation = () => {
    switch (activeAnimation) {
      case LoubiAirwaysAnimation.AIRPLANE_WINDOW:
        return (
          <AirplaneWindowWrapper modelEnvironment={ModelEnvironment.WORKSHOP} />
        );
      case LoubiAirwaysAnimation.TAROT_CARD:
        return <TarotCardWrapper />;
      case LoubiAirwaysAnimation.TRAY_TABLE:
        return <Tray />;
      case LoubiAirwaysAnimation.OMG_MAGAZINE:
        return <OmgMagazine />;
      case LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE:
        return <InflightMagazine />;
      case LoubiAirwaysAnimation.STYLISH_GUIDE:
        return <StylishGuide />;
      case LoubiAirwaysAnimation.NEWSPAPER:
        return <LoubiTimes />;
      case LoubiAirwaysAnimation.TROLLEY:
        return <TrolleyWrapper />;
      case LoubiAirwaysAnimation.COMPARTMENT_XRAY:
        return <CompartmentXRayAnimation />;
      case LoubiAirwaysAnimation.BEAUTY_CART:
        return <BeautyCartWrapper />;
      default:
        return null;
    }
  };

  return (
    <>
      <NonCockpitModeOnlyElements>
        {isOnMobile !== null && (
          <LoubiAirwaysLandingVideo isOnMobile={isOnMobile} storeId={storeId} />
        )}
        <LoubiAirwaysSpotifyPlayer />
        <LJInstructionPopup />
        <ChatConnectionContextContainer storeId={storeId}>
          <ClientChatContextContainer brandId={brandId}>
            <LiveChatPanel config={liveChatConfig} brandId={brandId} />
          </ClientChatContextContainer>
        </ChatConnectionContextContainer>
      </NonCockpitModeOnlyElements>

      <div className="circle-nav-container">
        <div className="nav-top">
          <PlanePlan />
          {renderReservation && storeId === loubiAirwaysJpBoutiqueId && (
            <LJShoppingBagButton
              onClick={() => dispatch(actionCartOpenPopup(true))}
            />
          )}
        </div>
        <div className="nav-bottom">
          <NonCockpitModeOnlyElements>
            <LJCalendarButton />
            <LJChatButton />
            {landingVideo?.hidden && <LoubiAirwaysBackgroundMusic />}
          </NonCockpitModeOnlyElements>
          {storeId === loubiAirwaysJpBoutiqueId && (
            <LoubiAirwaysExit imageUrl={`${jpIconUrlBase}/exit_white.svg`} />
          )}
        </div>
      </div>

      <FullScreenVideo
        url={getVideoUrl(fullScreenVideo)}
        show={!!fullScreenVideo}
        loop={fullScreenVideo === LoubiAirwaysFullScreenVideo.COCKPIT}
        playWithSound={!muteVideo}
        onEnded={() => {
          if (fullScreenVideo === LoubiAirwaysFullScreenVideo.EXIT) {
            window.close();
          }
        }}
        fullSize={true}
      >
        {canInteract && fullScreenVideo !== LoubiAirwaysFullScreenVideo.EXIT && (
          <LJContinueFlightButton
            onClick={() => {
              dispatch(actionUpdateLoubiAirwaysFullScreenVideo(undefined));
            }}
          />
        )}
        {shouldShowToggleMuteButton() && (
          <MuteButton
            onClick={() => {
              if (muteVideo) {
                setMuteVideo(false);
                logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO, {
                  video: fullScreenVideo
                });
              } else {
                setMuteVideo(true);
                logEvent(DID_CLICK_MUTE_VIDEO, DID_CLICK_MUTE_VIDEO, {
                  video: fullScreenVideo
                });
              }
            }}
            mute={muteVideo}
            showSpotifyPlayer={false}
          />
        )}
        {fullScreenVideo === LoubiAirwaysFullScreenVideo.EXIT && (
          <div className="inspify-logo-container">
            <PoweredByInspify color="light" />
          </div>
        )}
      </FullScreenVideo>

      <LJCatalogButtons />

      {renderReservation && (
        <LJReservedProducts
          open={showReservations}
          onClose={() => dispatch(actionCartOpenPopup(false))}
        />
      )}

      <LoubiAirwaysJapanAnimationContextContainer key={activeAnimation}>
        {getAnimation()}
      </LoubiAirwaysJapanAnimationContextContainer>

      <LJProductDetails />

      <style jsx global>{`
        .express-icons-container {
          right: 120px !important;
        }

        @media (max-width: 400px) {
          .express-icons-container {
            right: 90px !important;
          }
        }

        .in-meeting .letter-box-container {
          margin-bottom: 10px;
        }
        .in-meeting.SMPortrait .letter-box-container {
          margin-bottom: ${SMPortraitNormalSpec.contentArea.bottom + 40}px;
        }

        .in-meeting.MDPortrait .letter-box-container {
          margin-bottom: ${MDPortraitNormalSpec.contentArea.bottom + 40}px;
        }

        .in-meeting.SMLandscape .letter-box-container {
          width: calc(100% - ${SMLandscapeNormalSpec.contentArea.left}px);
          margin-left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }

        .in-meeting.SMLandscape .fixed-full {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }
        .in-meeting.SMLandscape .loubi-airways-animation #centertxt,
        .in-meeting.SMLandscape .loubi-airways-animation canvas {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }
        .in-meeting.SMLandscape .loubi-airways-animation #centertxt {
          margin-bottom: 40px;
        }
        .in-meeting.SMPortrait .loubi-airways-animation #centertxt {
          margin-bottom: ${SMPortraitNormalSpec.contentArea.bottom + 40}px;
        }
        .in-meeting.MDLandscape .letter-box-container {
          width: calc(100% - ${MDLandscapeNormalSpec.contentArea.left}px);
          margin-left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }

        .in-meeting.MDLandscape .fixed-full {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }
        .in-meeting.MDLandscape .loubi-airways-animation #centertxt,
        .in-meeting.MDLandscape .loubi-airways-animation canvas {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }
        .in-meeting.MDLandscape .loubi-airways-animation #centertxt {
          margin-bottom: 40px;
        }
        .in-meeting.MDPortrait .loubi-airways-animation #centertxt {
          margin-bottom: ${MDPortraitNormalSpec.contentArea.bottom + 40}px;
        }
      `}</style>
      <style jsx>{`
        .circle-nav-container {
          position: fixed;
          right: 0;
          bottom: 0;
          top: 0;
          left: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px;
          overflow: auto;
        }
        :global(.in-meeting.SM) .circle-nav-container {
          top: ${SMGlobalSpec.logoSectionHeight}px;
        }
        :global(.in-meeting.SMPortrait) .circle-nav-container {
          bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
        }
        :global(.in-meeting.MD) .circle-nav-container {
          top: ${MDGlobalSpec.logoSectionHeight}px;
        }
        :global(.in-meeting.MDPortrait) .circle-nav-container {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
        }
        .circle-nav-container > div {
          display: flex;
          flex-direction: column;
        }
        .circle-nav-container :global(.loubi-circle-button) {
          position: relative !important;
          top: auto !important;
          left: auto !important;
          bottom: auto !important;
          right: auto !important;
          transform: none !important;
          width: 70px;
          margin: 5px;
        }
        .circle-nav-container
          :global(.loubi-circle-button .loubi-circle-button) {
          margin: 0;
        }
        .inspify-logo-container {
          background-color: rgba(0, 0, 0, 0.2);
          background-size: contain;
          height: 50px;
          width: 120px;
          padding-left: 10px;
          border-radius: 30px 0 0 30px;
          right: 0;
          left: auto;
          position: absolute;
          bottom: 10px;
        }
        .inspify-logo-container :global(a) {
          margin-top: 0;
          background-position: center center;
        }
        .btn-light {
          position: fixed;
          bottom: 20px;
          right: 20px;
          opacity: 0.5;
        }
        :global(*) {
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        }
        :global(input),
        :global(textarea) {
          -webkit-touch-callout: all; /* iOS Safari */
          -webkit-user-select: all; /* Safari */
          -khtml-user-select: all; /* Konqueror HTML */
          -moz-user-select: all; /* Old versions of Firefox */
          -ms-user-select: all; /* Internet Explorer/Edge */
          user-select: all;
        }
        :global(.letter-box-container) {
          position: fixed;
          bottom: 90px;
          width: 100%;
          text-align: center;
        }

        :global(.letter-box-container + .LJProductDetailsButton) {
          background: #d03c3e !important;
        }
        :global(.LJProductDetailsButton) {
          position: fixed;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);
        }
        :global(.in-meeting.MDLandscape .LJProductDetailsButton) {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
          margin-bottom: 30px;
        }
        :global(.in-meeting.MDPortrait .LJProductDetailsButton) {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom + 30}px;
        }
        :global(.in-meeting.SMLandscape .LJProductDetailsButton) {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
          margin-bottom: 30px;
        }
        :global(.in-meeting.SMPortrait .LJProductDetailsButton) {
          bottom: ${SMPortraitNormalSpec.contentArea.bottom + 30}px;
        }
        :global(.inspify-logo-container) {
          position: fixed;
          bottom: 20px;
          right: 0;
          left: 0;
        }
        @media (max-width: 768px) {
          .circle-nav-container {
            justify-content: flex-end;
            padding: 2px;
          }
          .circle-nav-container :global(.loubi-circle-button) {
            width: 56px;
            margin: 2px;
          }
        }
        @media (max-width: 840px) {
          :global(.letter-box-container) {
            bottom: ${meetingState ===
            ParticipantMeetingState.ENTERED_FROM_LOUNGE
              ? 30
              : 90}px;
          }
        }
      `}</style>
    </>
  );
};

export default LoubiAirwaysJpCustomComponent;

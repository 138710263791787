import React from 'react';
import { vcaAssets, vcaKOPAssets } from '.';
import { vanCleefArpelsUsKingOfPrussiaSiteId } from '../../../../config';
import { IMapSpot, PanoView } from '../../../../interfaces';
import { getPanoView } from '../../../../utils/krpano';
import VCASVMapSpot, { SpotProps } from './VCASVMapSpot';

const VCASVMapFloor = ({
  onSelectSpot,
  visitedSpot,
  currentPosition,
  storeId
}: {
  onSelectSpot: (spot: IMapSpot) => void;
  visitedSpot: string[];
  currentPosition?: string;
  storeId?: string;
}) => {
  const spotsSV: SpotProps[] = [
    {
      spot: { scene: 'scene_01' },
      left: 6,
      top: 52
    },
    {
      spot: { scene: 'scene_02' },
      left: 73,
      top: 94
    },
    {
      spot: { scene: 'scene_03' },
      left: 84,
      top: 19
    },
    {
      spot: { scene: 'scene_04' },
      left: 27,
      top: 15
    },
    {
      spot: { scene: 'scene_05' },
      left: 39,
      top: 51
    },
    {
      spot: { scene: 'scene_06' },
      left: 53.5,
      top: 36
    },
    {
      spot: { scene: 'scene_07' },
      left: 53.5,
      top: 66
    },
    {
      spot: { scene: 'scene_07', viewH: 442.63, viewV: 2.1, fov: 131.619 },
      left: 53.5,
      top: 81
    },
    {
      spot: { scene: 'scene_08' },
      left: 33,
      top: 36
    },
    {
      spot: { scene: 'scene_09' },
      left: 41.5,
      top: 29
    },
    {
      spot: { scene: 'scene_10' },
      left: 64.5,
      top: 29
    },
    {
      spot: { scene: 'scene_11' },
      left: 73,
      top: 36
    },
    {
      spot: { scene: 'scene_12' },
      left: 73.5,
      top: 70.5
    }
  ];

  const spotsKOP: SpotProps[] = [
    {
      spot: { scene: 'scene_01' },
      left: 6,
      top: 52
    },
    {
      spot: { scene: 'scene_02' },
      left: 72,
      top: 92
    },
    {
      spot: { scene: 'scene_04' },
      left: 38.5,
      top: 13
    },
    {
      spot: { scene: 'scene_05' },
      left: 44.5,
      top: 51
    },
    {
      spot: { scene: 'scene_06' },
      left: 56.5,
      top: 39.5
    },
    {
      spot: { scene: 'scene_07' },
      left: 56.2,
      top: 62.5
    },
    {
      spot: { scene: 'scene_07', viewH: 188, viewV: 13, fov: 128 },
      left: 50.0,
      top: 80
    },
    {
      spot: { scene: 'scene_08' },
      left: 34.5,
      top: 32
    },
    {
      spot: { scene: 'scene_09' },
      left: 43.5,
      top: 25.1
    },
    {
      spot: { scene: 'scene_10' },
      left: 68.5,
      top: 25.0
    },
    {
      spot: { scene: 'scene_11' },
      left: 78.4,
      top: 32.5
    },
    {
      spot: { scene: 'scene_12' },
      left: 84.0,
      top: 61.2
    },
    {
      spot: { scene: 'scene_13' },
      left: 79.5,
      top: 69.6
    },
    {
      spot: { scene: 'scene_14' },
      left: 72.2,
      top: 76.3
    }
  ];
  const spots =
    storeId === vanCleefArpelsUsKingOfPrussiaSiteId ? spotsKOP : spotsSV;
  const [view, setView] = React.useState<PanoView>({});

  const getActiveStatus = (scene: string, viewH: number) => {
    if (currentPosition === 'scene_07' && scene === 'scene_07') {
      if (viewH) {
        return view.viewH > viewH - 100 && view.viewH < viewH + 100;
      } else {
        return view.viewH > 200 && view.viewH < 315;
      }
    }
    return currentPosition === scene;
  };

  React.useEffect(() => {
    setView(getPanoView());
  }, []);
  return (
    <div className="inner">
      <img
        className="map"
        src={
          storeId === vanCleefArpelsUsKingOfPrussiaSiteId
            ? vcaKOPAssets.mapFloor
            : vcaAssets.mapFloor
        }
        alt=""
      />
      {spots.map((spot) => (
        <VCASVMapSpot
          key={spot.spot.scene + spot.left + spot.top}
          className={spot.spot.scene}
          visited={visitedSpot.includes(spot.spot.scene)}
          onClick={() => onSelectSpot(spot.spot)}
          left={spot.left}
          top={spot.top}
          active={getActiveStatus(spot.spot.scene, spot.spot.viewH)}
          storeId={storeId}
        />
      ))}
      <div
        className="static label-1"
        onClick={() => onSelectSpot({ scene: 'scene_04' })}
      >
        Send a virtual butterfly
      </div>
      <div
        className="static label-2"
        onClick={() => onSelectSpot({ scene: 'scene_02' })}
      >
        Enchanting Spring Photobooth
      </div>
      <style jsx>{`
        .map {
          width: 100%;
          height: auto;
        }
        .static {
          background: #fff;
          font-family: 'Janson Text', Times, 'Times New Roman', serif !important;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;
        }
        .label-2 {
          left: 73%;
          top: 109%;
          width: 160px;
        }
        .label-1 {
          left: 27%;
          top: 1%;
          width: 120px;
        }
        @media (orientation: landscape) and (max-height: 600px) {
          .label-2 {
            width: 240px;
            top: 94%;
            left: 117%;
          }
          .label-1 {
            width: 190px;
            left: -10%;
            top: 15%;
          }
        }
        @media (min-width: 768px) and (min-height: 600px) {
          .static {
            font-size: 1.2em;
          }
          .label-2 {
            left: 73%;
            top: 101.6%;
            width: 280px;
          }
          .label-1 {
            left: 27%;
            top: 7.5%;
            width: 220px;
          }
        }
      `}</style>
    </div>
  );
};

export default VCASVMapFloor;

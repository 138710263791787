import httpClient from '../../../../../clientSideServices/http';

import {
  INudgePayload,
  IReservationItems,
  IReservationPayload,
  IShoppingCartItem
} from '../../../../../interfaces';
import { generateV4UUID } from '../../../../../utils/identityGenerator';
import {
  getCartTotalPrice,
  getItemTotalPrice
} from '../../../../../utils/shoppingCart';

const mapReservationItemsToPayload = (
  item: IShoppingCartItem
): IReservationItems => {
  const price = getItemTotalPrice(item, 'JP');

  return {
    productId: item.id,
    size: item.size || '',
    quantity: item.quantity,
    currency: price.currency,
    itemPrice: price.price,
    totalPrice: price.totalPrice
  };
};

export interface ReservationFormPayload {
  items: IShoppingCartItem[];
  invitation?: INudgePayload;
  identityId: string;
  sessionId: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  sessionStoreId?: string;
  currentSiteId?: string;
}

export const mapReservationsToPayload = ({
  items,
  invitation,
  identityId,
  sessionId,
  name,
  surname,
  email,
  phone,
  sessionStoreId,
  currentSiteId
}: ReservationFormPayload): IReservationPayload => {
  const cartTotalPrice = getCartTotalPrice(items, 'JP');
  const today = new Date();
  return {
    id: generateV4UUID(),
    reservationDate: today,
    reservationStatus: 'SUBMITTED_RESERVATION',
    storeId: invitation?.storeId || sessionStoreId || currentSiteId,
    reservedItems:
      items?.map((item) => mapReservationItemsToPayload(item)) || [],
    subTotal: cartTotalPrice?.subTotal || 0,
    totalPrice: cartTotalPrice?.total || 0,
    createdAt: today,
    createdBy: identityId,
    modifiedAt: '',
    modifiedBy: '',
    status: 'SUBMITTED',
    advisorId: invitation?.advisorId || '',
    inviteId: invitation?.id || '',
    sessionId: sessionId || '',
    customerInfo: {
      customerId: identityId,
      firstName: name,
      lastName: surname,
      phoneNumber: phone,
      email,
      phoneCountryCode: '+81',
      preferredLanguage: invitation?.languageCode || 'ja'
    },
    currency: 'JPY'
  };
};

export const sendReservations = (payload: IReservationPayload) =>
  httpClient.post('/api/sendReservations', payload);

import React from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { loubiAirwaysJpBoutiqueId, videoBaseUrl } from '../../../../config';
import { actionUpdateLandingVideoState } from '../../../../redux/customActions/loubiAirways';
import { DID_SKIP_VIDEO } from '../../../../utils/constants';
import { useLoubiAirwaysState } from '../../../hooks/loubiAirways';
import SkipVideoButton from './Buttons/SkipVideoButton';
import CookieDisclaimer from './CookieDisclaimer';
import { VIDEO_PAUSED } from './../../../../utils/constants';
import { RiPlayCircleLine } from 'react-icons/ri';

const euBoardingPassVideo = `${videoBaseUrl}/streaming/loubiairways/landing_desktop/v2/Landing_Page_Animation-Desktop_v2.m3u8`;
const euBoardingPassMobileVideo = `${videoBaseUrl}/streaming/loubiairways/landing_mobile/v2/Landing_Page_Animation-Mobile_v2.m3u8`;

const jpBoardingPassVideo = `${videoBaseUrl}/content/converted/be262c6d-96b2-4e1c-9c11-e3ac528876b4/landing_page_animation_desktop.mp4/index.m3u8`;
const jpBoardingPassMobileVideo = `${videoBaseUrl}/streaming/loubiairwaysjp/boarding_pass/Landing_Page_Animation_Stub.m3u8`;

const welcomeVideo = `${videoBaseUrl}/streaming/loubiairways/main/v3/LoubiAirways_MAIN.m3u8`;

const LoubiAirwaysLandingVideo = ({
  storeId,
  isOnMobile,
  disableSkip
}: {
  storeId?: string;
  isOnMobile?: boolean;
  disableSkip?: boolean;
}) => {
  const isLoubiJapan = storeId === loubiAirwaysJpBoutiqueId;
  const boardingPassVideo = isLoubiJapan
    ? jpBoardingPassVideo
    : euBoardingPassVideo;
  const boardingPassMobileVideo = isLoubiJapan
    ? jpBoardingPassMobileVideo
    : euBoardingPassMobileVideo;
  const [showCookieDisclaimer, setShowCookieDisclaimer] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(
    isOnMobile ? boardingPassMobileVideo : boardingPassVideo
  );
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [paused, setPaused] = React.useState(false);

  const [isMuted, setIsMuted] = React.useState(true);
  const videoRef = React.useRef<ReactPlayer>();

  const { hidden, finishedPlaying } =
    useLoubiAirwaysState()?.landingVideo || {};
  const dispatch = useDispatch();
  const hideLandingVideo = () => {
    dispatch(
      actionUpdateLandingVideoState({
        hidden: true,
        finishedPlaying
      })
    );
  };

  const updateFinishedPlaying = () => {
    dispatch(
      actionUpdateLandingVideoState({
        hidden,
        finishedPlaying: true
      })
    );
  };

  const onTimeUpdate = () => {
    const currentTime = videoRef.current?.getCurrentTime();
    const secondsBeforeStamp = 14.3;
    if (currentTime > secondsBeforeStamp && !window['ACTED_ON_COOKIE']) {
      setIsPlaying(false);
      setPaused(false);
      setShowCookieDisclaimer(true);
    }
  };

  const actedOnCookie = () => {
    setIsPlaying(true);
    setPaused(false);
    setShowCookieDisclaimer(false);
    window['ACTED_ON_COOKIE'] = true;
  };

  const showPlane = (delayInMs = 3000) => {
    updateFinishedPlaying();
    setTimeout(() => {
      hideLandingVideo();
    }, delayInMs);
  };

  if (hidden) return null;
  return (
    <div className={`loubi${finishedPlaying ? ' disappearing' : ''}`}>
      {!finishedPlaying && (
        <>
          <ReactPlayer
            ref={videoRef}
            playing={isPlaying}
            width="100%"
            height="100%"
            autoPlay
            playsinline
            muted={isMuted}
            url={videoUrl}
            onTimeUpdate={onTimeUpdate}
            onPause={() => {
              setPaused(true);
              logEvent(VIDEO_PAUSED, VIDEO_PAUSED, {
                second: videoRef.current?.getCurrentTime(),
                videoUrl
              });
            }}
            onPlay={() => {
              setPaused(false);
            }}
            onEnded={() => {
              if (
                videoUrl === boardingPassVideo ||
                videoUrl === boardingPassMobileVideo
              ) {
                setVideoUrl(welcomeVideo);
                setIsMuted(false);
              } else {
                showPlane();
              }
            }}
          />
          {paused && isPlaying && (
            <button
              className="btn-play with-shadow"
              onClick={() => {
                setIsPlaying(false);
                setTimeout(() => {
                  setIsPlaying(true);
                }, 100);
              }}
            >
              <RiPlayCircleLine color="#fff" />
            </button>
          )}
        </>
      )}
      {showCookieDisclaimer && (
        <CookieDisclaimer
          onAccept={() => {
            actedOnCookie();
          }}
          onReject={() => {
            actedOnCookie();
          }}
        />
      )}
      {videoUrl === welcomeVideo && !finishedPlaying && !disableSkip && (
        <SkipVideoButton
          onClick={() => {
            showPlane(1000);
            logEvent(DID_SKIP_VIDEO, DID_SKIP_VIDEO, {
              video: 'Welcome Video'
            });
          }}
        />
      )}
      <style jsx>{`
        .btn-light {
          position: fixed;
          bottom: 20px;
          right: 20px;
          opacity: 0.5;
        }
        .loubi {
          z-index: 9999;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background: black;
          display: flex;
          justify-content: center;
          align-item: center;
          flex-direction: column;
          opacity: 1;
        }
        .loubi.disappearing {
          -webkit-animation: fade-out 1.5s ease-out 1s both;
          animation: fade-out 1.5s ease-out 1s both;
        }
        video {
          width: 100%;
        }
        .input-container {
          max-width: 350px;
          margin: 0 auto;
        }
        .input-group {
          border: 4px solid #efefef;
          border-radius: 10px;
        }
        .alert {
          padding: 2px;
          border-radius: 10px;
        }

        .loubi :global(svg) {
          position: fixed;
          bottom: 20px;
          left: 20px;
          fill: #fff;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .btn-play {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: transparent;
        }

        .btn-play :global(svg) {
          width: 150px;
          height: auto;
          pointer-events: none;
          position: relative;
          bottom: 0;
          left: 0;
        }

        @-webkit-keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LoubiAirwaysLandingVideo;

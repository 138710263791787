import {
  fetchWishlist,
  saveWishlist
} from '../../../../../clientSideServices/wishlist';
import { IWishlistPayload, WishlistType } from '../../../../../interfaces';
import { ILoubiFutureWishlist } from '../../../../../interfaces/loubifuture';
import { actionUpdateLFWishlist } from '../../../../../redux/customActions/loubiFuture';
import { uniqBy } from 'lodash';
import { logEvent } from '../../../../../analytics';
import {
  DID_ADD_TO_WISHLIST,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';
import { loubiFutureSiteId } from '../../../../../config';
import { getIdentityId } from '../../../../../utils/identity';

export const mapWishlistStateToPayload = (
  state: ILoubiFutureWishlist
): IWishlistPayload => ({
  id: state?.id || '',
  identityId: state?.identityId || '',
  description: state?.description || '',
  name: state?.name || '',
  store: state?.store || '',
  brand: state?.brand || '',
  wishlistItems: state?.wishlistItems || [],
  createdAt: state?.createdAt || '',
  surname: state?.surname || '',
  status: state?.status,
  region: state?.region,
  email: state?.email || '',
  phone: state?.phone || '',
  acceptedTermsOfUse: state?.acceptedTermsOfUse || false
});

export const actionAddToWishlistAsync = (
  productId: string,
  state: ILoubiFutureWishlist
) => {
  return (dispatch) => {
    const updatedWishlist = {
      ...(state || {}),
      wishlistItems: uniqBy(
        [
          ...(state?.wishlistItems || []),
          { itemId: productId, itemType: WishlistType.PRODUCT }
        ],
        'itemId'
      ),
      status: 'WISHLIST_SUBMITTED'
    };
    return saveWishlist(mapWishlistStateToPayload(updatedWishlist)).then(() => {
      logEvent(DID_ADD_TO_WISHLIST, DID_ADD_TO_WISHLIST, {
        id: productId
      });
      return dispatch(actionUpdateLFWishlist(updatedWishlist));
    });
  };
};

export const actionRemoveFromWishlistAsync = (
  productId: string,
  state: ILoubiFutureWishlist
) => {
  return (dispatch) => {
    const updatedWishlist = {
      ...(state || {}),
      wishlistItems: (state?.wishlistItems || []).filter(
        (item) => item.itemId !== productId
      ),
      status: 'WISHLIST_SUBMITTED'
    };

    return saveWishlist(mapWishlistStateToPayload(updatedWishlist)).then(() => {
      logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
        id: productId
      });
      return dispatch(actionUpdateLFWishlist(updatedWishlist));
    });
  };
};

export const actionFetchLFWishlistAsync = () => {
  return (dispatch) => {
    const identityId = getIdentityId();
    fetchWishlist(identityId, loubiFutureSiteId)
      .then((result) => {
        if (!result.data.errorMessage)
          return dispatch(
            actionUpdateLFWishlist({
              ...result.data,
              dontShowPopup:
                !result.data?.name || !result.data?.phone ? false : true
            })
          );
      })
      .catch(() => {
        console.log(`fetchWishlist failed for identityId ${identityId}`);
      });
  };
};

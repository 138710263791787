import * as Sentry from '@sentry/node';
import { last } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import {
  IMainState,
  IStore,
  IVirtualBoutiqueConfig,
  PanoView,
  VirtualBoutiqueMode
} from '../../../interfaces';
import {
  actionDidStartPlayingVideo,
  actionMuteBgSound,
  actionPanoLoaded
} from '../../../redux/actions';
import {
  actionFetchChatConfig,
  actionFetchJourneyConfig
} from '../../../redux/asyncActions';
import { DID_OPEN_POPUP_IN_VB } from '../../../utils/constants';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import { shouldHideSoundButton } from '../../../utils/entitlements';
import {
  getVoyageMovementType,
  krpanoInvoke,
  updatePanoBaseUrlByRegionIfRequired
} from '../../../utils/krpano';
import { currentLanguage } from '../../../utils/language';
import { getPopupAction, PageView } from '../../../utils/pageView';
import { isInWalkThrough } from '../../../utils/stores';
import ChatPanel from './ChatPanel';
import DiscoverButton from './DiscoverButton';
import ErrorMessage from './ErrorMessage';
import IndicatorPanel from './IndicatorPanel';
import KRPanoCallback, { IKRPanoCallback } from './KRPanoCallback';
import LoadingProgress from './LoadingProgress';
import PanoJourneyGuide from './PanoJourneyGuide';
import { getInitialVariables } from './storeInitialState';

/* eslint-disable no-var */
declare global {
  var krpanoCB: IKRPanoCallback;
  var embedpano: Function;
  var removepano: Function;
}
/* eslint-enable no-var */

const KRPano = ({
  store,
  config,
  classname
}: {
  store: IStore;
  config: IVirtualBoutiqueConfig;
  classname: string;
}) => {
  const [isUsingMobile, setIsUsingMobile] = React.useState(false);
  const [isKrpanoError, setIsKrpanoError] = React.useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    journeyConfig,
    chatConfig,
    showZoomIndicator,
    discoverMore,
    mode: vbMode,
    muteBackgroundSound,
    panoLoaded,
    backgroundVideoPlayed,
    sceneIds,
    hud
  } = useSelector((state: IMainState) => state.clientState.vb || {});

  const isCockpitMode = vbMode === VirtualBoutiqueMode.COCKPIT;

  const openPopup = (type: PageView, id: string) => {
    console.log(`will open popup for type ${type} with id ${id}`);
    logEvent(DID_OPEN_POPUP_IN_VB, type, { id });
    const action = getPopupAction(
      `${location.protocol}//${location.host}`,
      type,
      id,
      config?.brandId,
      store.id,
      currentLanguage(i18n)
    );
    if (action) {
      dispatch(action);
    }
  };

  const {
    remoteSceneId,
    remoteView,
    remoteShowHotSpotName,
    remoteHideHotSpotName
  } = useSelector((state: IMainState) => state.clientState.meeting || {});

  const { i18n } = useTranslation();

  const changeLangInKrPano = (lang: string) => {
    console.log(`changed to language ${lang}`);
    krpanoInvoke(`onChangeLanguage('${lang}')`);
  };

  const languageChangeHandler = (lang: string) => {
    changeLangInKrPano(lang);
  };

  const toggleSound = (mute: boolean) => {
    if (mute === undefined) return;
    if (mute) {
      krpanoInvoke(`switchSoundOff()`);
    } else {
      krpanoInvoke(`switchSoundOn()`);
    }
    if (htmlVideoElement()) {
      htmlVideoElement().muted = mute || false;
    }
  };

  const goToScene = (sceneId: string) => {
    krpanoInvoke(
      `loadscene('${sceneId}', null, ${getVoyageMovementType(
        store.id
      )}, BLEND(1))`
    );
  };

  const showHotSpot = (name: string) => {
    console.log(`didMouseOverHotspot('${name}')`);
    krpanoInvoke(`didMouseOverHotspot('${name}')`);
  };

  const hideHotSpot = (name: string) => {
    console.log(`didMouseOutHotspot('${name}')`);
    krpanoInvoke(`didMouseOutHotspot('${name}')`);
  };

  const lookToView = ({ viewH, viewV, fov }: PanoView) => {
    krpanoInvoke(`lookto(${viewH}, ${viewV}, ${fov}, smooth())`);
  };

  const zoomScene = () => {
    krpanoInvoke(`zoomto(80, smooth());`);
  };

  const isNotInWalkThrough = !isInWalkThrough(vbMode);

  React.useEffect(() => {
    if (remoteView && panoLoaded) {
      setTimeout(() => lookToView(remoteView), 100);
    }
  }, [remoteView, panoLoaded]);

  React.useEffect(() => {
    if (remoteSceneId && panoLoaded) {
      goToScene(remoteSceneId);
    }
  }, [remoteSceneId, panoLoaded]);

  React.useEffect(() => {
    if (remoteShowHotSpotName) {
      showHotSpot(remoteShowHotSpotName);
    }
  }, [remoteShowHotSpotName]);

  React.useEffect(() => {
    if (remoteHideHotSpotName) {
      hideHotSpot(remoteHideHotSpotName);
    }
  }, [remoteHideHotSpotName]);

  React.useEffect(() => {
    toggleSound(muteBackgroundSound);
  }, [muteBackgroundSound, panoLoaded]);

  React.useEffect(() => {
    if (isCockpitMode && backgroundVideoPlayed) {
      dispatch(actionMuteBgSound());
    }
  }, [isCockpitMode, backgroundVideoPlayed]);

  const htmlVideoElement = (): HTMLVideoElement => {
    return document.querySelector('#embedframe video');
  };

  React.useEffect(() => {
    if (htmlVideoElement()) {
      dispatch(actionDidStartPlayingVideo(true));
    }
  }, [panoLoaded]);

  React.useEffect(() => {
    if (shouldHideSoundButton(store.id, last(sceneIds || [])))
      dispatch(actionDidStartPlayingVideo(false));
  }, [sceneIds]);

  React.useEffect(() => {
    i18n.on('languageChanged', languageChangeHandler);
    return () => {
      i18n.off('languageChanged', languageChangeHandler);
    };
  }, []);

  const initializeKrPano = () => {
    if (window['embedpano']) {
      const updatedPano = updatePanoBaseUrlByRegionIfRequired(
        store.id,
        config.pano
      );
      embedpano({
        swf: updatedPano?.swf,
        xml: updatedPano?.xml,
        target: 'embedframe',
        html5: 'auto',
        consolelog: location.hostname === 'localhost',
        mobilescale: 1.0,
        passQueryParameters: true,
        basepath: updatedPano?.basepath,
        initvars: getInitialVariables(
          store.id,
          router.query.source as string,
          router.query.go as string
        ),
        onready: () => {
          console.log('KRPano ready');
          changeLangInKrPano(i18n.language);
          setTimeout(() => {
            dispatch(actionPanoLoaded(true));
          }, 5000);
        },
        onerror: (error) => {
          setIsKrpanoError(true);
          console.log(`KRPano error`);
          console.log(error);
          Sentry.captureException(error);
        }
      });
    } else {
      setTimeout(initializeKrPano, 1000);
    }
  };

  React.useEffect(() => {
    setIsUsingMobile(isUserOnMobile());
    window.krpanoCB = new KRPanoCallback(dispatch, logEvent, openPopup);
    initializeKrPano();
    if (config.entitlement?.journey && store.id) {
      dispatch(actionFetchJourneyConfig(store.id));
    }
    if (config.entitlement?.chat && store.id) {
      dispatch(actionFetchChatConfig(store.id));
    }
  }, []);

  if (isKrpanoError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div id="embedframe" className={classname}></div>

      {!router.query.go && !panoLoaded && (
        <LoadingProgress loading={!panoLoaded} />
      )}

      {isNotInWalkThrough && panoLoaded && (
        <IndicatorPanel
          zoomScene={zoomScene}
          open={showZoomIndicator && !hud?.open}
        />
      )}

      {panoLoaded && discoverMore && (
        <DiscoverButton openPopup={openPopup} config={discoverMore} />
      )}

      {isNotInWalkThrough &&
        journeyConfig &&
        panoLoaded &&
        config.entitlement?.journey && (
          <PanoJourneyGuide
            goToScene={goToScene}
            journeyConfig={journeyConfig}
            brandId={config.brandId}
          />
        )}

      {isNotInWalkThrough &&
        chatConfig &&
        config.entitlement?.chat &&
        panoLoaded && (
          <ChatPanel
            storeId={store?.id}
            goToScene={goToScene}
            config={chatConfig}
            openPopup={openPopup}
            brandId={config.brandId}
            lookToView={lookToView}
          />
        )}

      <style jsx global>
        {`
          #embedframe div[style*='575px'],
          #embedframe video {
            pointer-events: ${isUsingMobile ? 'none !important' : 'auto'};
          }
        `}
      </style>
    </>
  );
};

export default KRPano;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import {
  DID_CLOSE_WISHLIST_POPUP,
  DID_CLICK_REMOVE_FROM_RESERVATION,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';
import { useProductState } from '../../../../hooks/product';
import { IProduct } from '../../../../../interfaces';
import { getProductDisplayPrice } from '../../../../../utils/product';
import { useCartItemState } from '../../../../hooks/cart';
import {
  actionCartClearProducts,
  actionCartRemoveProduct,
  actionCartUpdateSize
} from '../../../../../redux/actions';
import ReservationForm from './ReservationsForm';
import LJSizeNestedOptions from './LJSizeNestedOptions';
import LJReservedLikedProductList from './LJReservedLikedProductList';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import {
  actionRemoveFromWishlist,
  actionUpdateWishlistItemSize
} from '../../../../../redux/customActions/loubiAirways';
import AddToReservationButton from './AddToReservationButton';
import { getLJProductOption } from '../LJProductDetails';

export const LJReservedProducts = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);

  const [openForm, setOpenForm] = React.useState(false);

  const cartItems = useCartItemState();
  const wishlist = useLoubiAirwaysState()?.wishlist || {};

  const { loadedProducts } = useProductState();

  const reservedProducts = cartItems?.map((item) =>
    loadedProducts?.find((p) => p.id === item.id)
  );

  const likedProducts = wishlist?.wishlistItems
    ?.map((item) => loadedProducts?.find((p) => p.id === item.itemId))
    ?.filter(Boolean);

  const getReservedProductById = (id: string) =>
    cartItems?.find((item) => item.id === id);

  const getWishlistItemSizeById = (id: string) =>
    wishlist?.wishlistItems?.find((item) => item.itemId === id).size;

  const closePopup = () => {
    onClose();
    logEvent(DID_CLOSE_WISHLIST_POPUP, DID_CLOSE_WISHLIST_POPUP);
  };

  React.useEffect(() => {
    if (open) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [open]);

  const reservedProductControls = (product: IProduct) => {
    const size = getReservedProductById(product.id).size;
    return (
      <>
        <h3>{getProductDisplayPrice(product, 'JP')}</h3>
        <LJSizeNestedOptions
          product={product}
          onClickOption={(id, option) => {
            dispatch(actionCartUpdateSize(id, option));
          }}
          selectedOption={size}
        />
        <span
          className="remove"
          onClick={() => {
            dispatch(actionCartRemoveProduct(product?.id));
            logEvent(
              DID_CLICK_REMOVE_FROM_RESERVATION,
              DID_CLICK_REMOVE_FROM_RESERVATION,
              {
                id: product?.id
              }
            );
          }}
        >
          REMOVE
        </span>
        <style jsx>{`
          .remove {
            border-bottom: 1px dashed #cf152d;
            color: #cf152d;
            font-family: 'Antique-Olive', Arial;
            cursor: pointer;
            font-size: 0.8em;
          }
          h3 {
            font-family: 'AntiqueOlive-Bold', Arial;
            font-size: 1.2em;
            color: #346168;
          }
          .btn-danger {
            background: #bf080e;
            font-family: 'AntiqueOlive-Bold', Arial;
          }
        `}</style>
      </>
    );
  };

  const likedProductControls = (product: IProduct) => {
    const size = getWishlistItemSizeById(product.id);
    return (
      <>
        <h3>{getProductDisplayPrice(product, 'JP')}</h3>
        <LJSizeNestedOptions
          selectedOption={size}
          product={product}
          onClickOption={(id, option) => {
            dispatch(actionUpdateWishlistItemSize(id, option));
          }}
        />
        <br />
        <AddToReservationButton
          product={product}
          productSize={size}
          buttonSize="small"
          autoOpenPopup={false}
          disabled={
            !getLJProductOption(product)?.options?.length ? false : !size
          }
          onAdd={() => {
            dispatch(actionRemoveFromWishlist({ id: product?.id }));
          }}
        />
        <br />
        <span
          className="remove"
          onClick={() => {
            dispatch(actionRemoveFromWishlist({ id: product?.id }));
            logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
              itemId: product?.id
            });
          }}
        >
          REMOVE
        </span>
        <style jsx>{`
          .remove {
            border-bottom: 1px dashed #cf152d;
            color: #cf152d;
            font-family: 'Antique-Olive', Arial;
            cursor: pointer;
            font-size: 0.8em;
          }
          h3 {
            font-family: 'AntiqueOlive-Bold', Arial;
            font-size: 1.2em;
            color: #346168;
          }
          .btn-danger {
            background: #bf080e;
            font-family: 'AntiqueOlive-Bold', Arial;
          }
        `}</style>
      </>
    );
  };

  if (!show) return null;

  return (
    <>
      <LJReservedLikedProductList
        reservedProducts={reservedProducts}
        likedProducts={likedProducts}
        reservedProductControls={reservedProductControls}
        likedProductControls={likedProductControls}
        open={open}
        onClose={closePopup}
        onClickSubmit={() => {
          setOpenForm(true);
        }}
      />
      {openForm && (
        <ReservationForm
          onClose={() => {
            setOpenForm(false);
          }}
          items={cartItems}
          onSuccess={()=>{
            if(!likedProducts?.length){
              closePopup();
            }
            setOpenForm(false);
            dispatch(actionCartClearProducts())
          }}
        />
      )}
    </>
  );
};

export default LJReservedProducts;

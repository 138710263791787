import { useSelector } from 'react-redux';
import { IMainState, IProduct } from '../../interfaces';

export const useProductState = (): {
  loadedProducts: IProduct[];
  selectedProductId: string;
  selectedProduct: IProduct;
} => {
  const { loadedProducts, selectedProductId } = useSelector(
    (state: IMainState) => state.clientState.product || {}
  );

  return {
    loadedProducts,
    selectedProductId,
    selectedProduct: loadedProducts?.find((p) => p.id === selectedProductId)
  };
};

export const useProductStateById = (id: string): IProduct => {
  const { loadedProducts } = useProductState();
  return loadedProducts?.find((p) => p.id === id);
};

export const isShowingDescriptionForProduct = (id: string): boolean => {
  const { showingDescriptionForProductId } = useSelector(
    (state: IMainState) => state.clientState.product || {}
  );
  return id && id === showingDescriptionForProductId;
};

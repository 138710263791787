import React from 'react';
import { BsX } from 'react-icons/bs';
import { useTranslation } from '../../../i18n';

function StoreLocation({ onClose }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="piaget-location-container">
        <button
          type="button"
          className="nav-close btn-express"
          onClick={onClose}
        >
          <span className="nav-item__icon">
            <BsX />
          </span>
        </button>
        <div className="piaget-location">
          <div className="location-cover"></div>
          <div className="location-text">
            <h2>{t('vb:piaget_boutique_hanoi')}</h2>
            <address>
              {t('vb:piaget_vn_address.line1')}
              <br />
              {t('vb:piaget_vn_address.line2')}
              <br />
              {t('vb:piaget_vn_address.line3')}
              <br />
              +84 24 3934 7666
            </address>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .piaget-location-container {
            background-color: rgba(0, 0, 0, 0.7);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 60px;
            z-index: 50;
          }
          .piaget-location {
            border-radius: 10px;
            overflow: hidden;
            height: 100%;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .piaget-location-container .nav-close {
            position: absolute;
            right: 60px;
            top: 30px;
            background: transparent;
            color: #fff;
          }

          .location-cover {
            height: 60vh;
            width: 100%;
            background-image: url('https://panos2.inspify.io/virtual_boutique/bc57587b-9ce9-43de-90c3-e035192d7739/piaget_vn_tamson.jpeg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .location-text {
            max-width: 100%;
            width: 600px;
            height: 300px;
            padding: 20px;
            margin-top: -60px;
            background-color: white;
            text-align: center;
          }
          .location-text h2 {
            font-family: 'Piaget PlayFair', serif;
          }
          .location-text address {
            font-family: 'Neutraface', sans-serif;
            font-size: 18px;
          }
          @media (max-width: 768px) {
            .piaget-location-container {
              padding: 60px 0 0 0;
            }
            .piaget-location-container .nav-close {
              right: 10px;
            }
            .piaget-location {
              border-radius: 0px;
            }
          }
        `}
      </style>
    </>
  );
}

export default StoreLocation;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { IMainState } from '../../../interfaces';
import { actionSetActivePage, actionShowOutro } from '../../../redux/actions';
import {
  DID_CLICK_MUTE_VIDEO,
  DID_CLICK_UNMUTE_VIDEO
} from '../../../utils/constants';
import FullScreenVideo from '../../FullScreenVideo';
import { useAutoPlayVideoMuteControl } from '../../hooks/meeting';
import { CSSTransition } from 'react-transition-group';
import { generateVideoUrl } from '../../../utils/video';

const GenericClosingVideo = ({
  closingVideoUrl,
  showOutro,
  children,
  onEnd
}: {
  closingVideoUrl: string;
  showOutro: boolean;
  children?: React.ReactNode;
  onEnd?: () => void;
}) => {
  const dispatch = useDispatch();
  const [playWithSound, setPlayWithSound] = useAutoPlayVideoMuteControl();
  const [ended, setEnded] = React.useState(false);
  const videoUrl = generateVideoUrl(closingVideoUrl);
  const meeting = useSelector(
    (state: IMainState) => state?.clientState?.meeting
  );
  const advisorEndsMeeting = meeting?.advisorEndsMeeting;
  const activeAudioOutput = meeting?.localUser?.activeDevices?.speaker;
  React.useEffect(() => {
    if (advisorEndsMeeting && closingVideoUrl) {
      dispatch(actionShowOutro(true));
      dispatch(actionSetActivePage(null));
    }
  }, [advisorEndsMeeting]);

  return (
    <>
      <FullScreenVideo
        url={videoUrl}
        show={showOutro}
        playWithSound={playWithSound}
        setPlayWithSound={(playWithSound: boolean) => {
          setPlayWithSound(playWithSound);
        }}
        onEnded={() => {
          onEnd?.();
          setEnded(true);
        }}
        activeAudioOutput={activeAudioOutput}
        fullSize={true}
      >
        <button
          onClick={() => {
            if (playWithSound) {
              logEvent(DID_CLICK_MUTE_VIDEO, DID_CLICK_MUTE_VIDEO, {
                videoUrl: videoUrl
              });
            } else {
              logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO, {
                videoUrl: videoUrl
              });
            }
            setPlayWithSound(!playWithSound);
          }}
        >
          {playWithSound ? 'MUTE' : 'UNMUTE'}
        </button>

        <style jsx>
          {`
            button {
              position: fixed;
              bottom: 10px;
              left: 10px;
              padding: 20px;
              background-color: rgba(37, 37, 37, 0.5);
              border: 1px solid #fff;
              color: #fff;
              z-index: 2;
            }
          `}
        </style>
      </FullScreenVideo>
      {ended && children && showOutro ? (
        <CSSTransition in={ended} timeout={1000} classNames="fade-in-wrapper">
          <div className="children">
            {children}
            <style jsx>{`
              .children {
                position: fixed;
                z-index: ${ended ? 3000 : -1};
              }
            `}</style>
            <style jsx global>{`
              .fade-in-wrapper-enter {
                opacity: 0;
              }
              .fade-in-wrapper-enter-active {
                opacity: 1;
                transition: opacity 1000ms;
              }
              .fade-in-wrapper-exit {
                opacity: 1;
              }
              .fade-in-wrapper-exit-active {
                opacity: 0;
                transition: opacity 1000ms;
              }
            `}</style>
          </div>
        </CSSTransition>
      ) : null}
    </>
  );
};

export default GenericClosingVideo;

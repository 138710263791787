import { format } from 'date-fns';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import {
  iwcBrandId,
  iwcBrazilSiteId,
  iwcNlAmsterdamSiteId,
  iwcThaiBangkokBoutiqueId
} from '../../../config';
import {
  IVirtualBoutiqueAppointmentContact,
  AppointmentType,
  IMainState,
  IOpeningHoursByWeekday
} from '../../../interfaces';
import {
  DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
  DID_SEND_APPOINTMENT_EMAIL
} from '../../../utils/constants';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import PrivacyPolicy from '../../Legal/PrivacyPolicy';
import TermsOfUse from '../../Legal/TermsOfUse';
import {
  getDefaultDate,
  getIsDateAllowedByBrand,
  getAvailableSlotsWithConcurrentLimitation,
  getAvailableSlots,
  getAvailableSlotsByWeekday
} from './AppointmentDateTimeCalculator';
import { sendIWCSGFormEmail } from './emailService';
import {
  isValidName,
  isValidPhoneNumber,
  isValidEmail,
  isValidCountryCode
} from './inputValidator';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../../clientSideServices/users';
import { getIdentityId } from '../../../utils/identity';
import { actionUpdateUserAttributesAsync } from '../../../redux/asyncActions';
import { useTranslation } from '../../../i18n';
import { actionAppointmentPopup } from '../../../redux/actions';
import { arSA } from 'date-fns/locale';
import { getAvailableSlotsByDateAndStore } from '../../../clientSideServices/store';

export interface IGenericIWCAppointmentFormData {
  name: string | null;
  email: string | null;
  countryCode: string;
  phone: string | null;
  channel: string;
  boutiqueContact: IVirtualBoutiqueAppointmentContact;
  date: Date;
  time: string | null;
  acceptedTermsOfUse: boolean;
  allowOffers: boolean;
  subscribeNews: boolean;
}

interface IGenericIWCAppointmentFormPristine {
  name: boolean;
  email: boolean;
  phone: boolean;
  time: boolean;
  boutiqueContact: boolean;
  acceptedTermsOfUse: boolean;
  appointmentType: boolean;
}

export interface IGenericIWCAppointmentFormProps {
  appointmentContacts: IVirtualBoutiqueAppointmentContact[];
  logoUrl: string;
  storeId: string;
  open: boolean;
  timezoneGMTOffset: number;
  timezoneName: string;
  phoneCountryCode: string;
  onClose: () => void;
  visitHours?: string[];
  openingHoursByWeekday?: IOpeningHoursByWeekday;
  appointmentSlotDurationInMinutes?: number;
}

export interface IConcurrentAvailableSlot {
  time: string;
  slotNr: number;
}

enum EmailSentState {
  INITIAL = 'SEND',
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

registerLocale('ar', arSA);

const GenericIWCAppointmentForm = ({
  appointmentContacts,
  logoUrl,
  storeId,
  open,
  timezoneGMTOffset,
  timezoneName,
  phoneCountryCode,
  onClose,
  visitHours,
  openingHoursByWeekday,
  appointmentSlotDurationInMinutes
}: IGenericIWCAppointmentFormProps) => {
  const appointmentPopupState = useSelector(
    (state: IMainState) => state.clientState.vb?.appointmentPopup
  );

  const appointmentContactState = appointmentPopupState?.contact || '';

  const appointmentType = appointmentPopupState?.type;

  const isVirtualWalkthrough =
    appointmentType === AppointmentType.VIRTUAL_WALKTHROUGH;

  const [emailSent, setEmailSent] = React.useState(false);

  const [emailSentState, setEmailSentState] = React.useState<EmailSentState>(
    EmailSentState.INITIAL
  );

  const { t, i18n } = useTranslation();
  const [pristine, setPristine] = React.useState<IGenericIWCAppointmentFormPristine>(
    {
      name: true,
      email: true,
      phone: true,
      time: true,
      boutiqueContact: true,
      acceptedTermsOfUse: true,
      appointmentType: true
    }
  );

  const [formData, setFormData] = React.useState<IGenericIWCAppointmentFormData>({
    name: null,
    email: null,
    countryCode: phoneCountryCode,
    phone: null,
    channel: 'Desktop',
    boutiqueContact: null,
    date: getDefaultDate(iwcBrandId),
    time: null,
    acceptedTermsOfUse: false,
    allowOffers: false,
    subscribeNews: false
  });

  const [availableSlots, setAvailableSlots] = React.useState<
    IConcurrentAvailableSlot[]
  >([]);

  React.useEffect(() => {
    const formattedDate = format(formData.date, 'dd MMM, yyyy');
    getAvailableSlotsByDateAndStore({ date: formattedDate, storeId }).then(
      (result) => {
        setAvailableSlots(result.data);
      }
    );
  }, [formData.date]);

  React.useEffect(() => {
    let boutiqueContact =
      appointmentContacts.length === 1 ? appointmentContacts[0] : null;

    if (appointmentContactState) {
      setPristine({ ...pristine, boutiqueContact: false });
      boutiqueContact =
        appointmentContacts.find((c) => c.id === appointmentContactState) ||
        null;
    }

    setFormData({
      ...formData,
      boutiqueContact,
      channel: isVirtualWalkthrough ? 'Desktop' : 'Physical'
    });
  }, [appointmentContactState, appointmentType]);

  const mandatoryFields: (keyof IGenericIWCAppointmentFormData)[] = [
    'name',
    'channel',
    'boutiqueContact',
    'date',
    'time',
    'acceptedTermsOfUse'
  ];

  const atLeastOneMandatoryFields: (keyof IGenericIWCAppointmentFormData)[] = [
    'email',
    'phone'
  ];

  const animationDuration = 300;
  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setEmailSent(false);
    }, animationDuration);
  };

  const handleName = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, name: false });
    setFormData({ ...formData, name: value });
  };

  const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    setFormData({ ...formData, email: value });
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, countryCode: value });
  };

  const handlePhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });
    setFormData({ ...formData, phone: value });
  };

  const handleBoutique = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, boutiqueContact: false });
    const contact = appointmentContacts.find((c) => c.name === value);
    setFormData({
      ...formData,
      boutiqueContact: contact
    });
  };

  const handleChannel = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      channel: value
    });
  };

  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setFormData({ ...formData, acceptedTermsOfUse: value });
  };

  const handleTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const time = event.target.value;
    setPristine({ ...pristine, time: false });
    setFormData({ ...formData, time });
  };

  const handleDate = (date: Date) => {
    console.log(date);
    if (!date) date = getDefaultDate(iwcBrandId);
    setAvailableSlots([]);
    setFormData({ ...formData, date });
  };

  const handleAppointmentType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const type = event.target.value as AppointmentType;
    let channel = 'Desktop';
    setPristine({ ...pristine, appointmentType: false });

    dispatch(actionAppointmentPopup({ ...appointmentPopupState, type }));

    if (type === AppointmentType.PHYSICAL) {
      channel = 'Physical';
    }

    setFormData({
      ...formData,
      channel,
      time: null
    });
  };

  const removePristine = () => {
    const nonPristine: IGenericIWCAppointmentFormPristine = {
      name: false,
      email: false,
      phone: false,
      time: false,
      boutiqueContact: false,
      acceptedTermsOfUse: false,
      appointmentType: false
    };
    setPristine(nonPristine);
  };

  const dispatch = useDispatch();

  const handleSend = () => {
    removePristine();
    if (
      mandatoryFields.some((field) => !formData[field]) ||
      atLeastOneMandatoryFields.every((field) => !formData[field])
    ) {
      return;
    }
    const isEmailValid = isValidEmail(formData.email);
    const isPhoneNumberValid =
      isValidCountryCode(formData.countryCode) &&
      isValidPhoneNumber(formData.phone);

    if (!isValidName(formData.name) || !(isEmailValid || isPhoneNumberValid)) {
      return;
    }
    setEmailSentState(EmailSentState.SENDING);
    sendIWCSGFormEmail(formData, storeId)
      .then(() => {
        setEmailSentState(EmailSentState.INITIAL);
        setEmailSent(true);
        logEvent(DID_SEND_APPOINTMENT_EMAIL);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(
          DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
          DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
          { error }
        );
      })
      .finally(() => {
        const user = createUser(
          getIdentityId(),
          formData.name,
          formData.email,
          formData.countryCode,
          formData.phone
        );
        dispatch(
          actionUpdateUserAttributesAsync(user.id, {
            alias: user.alias,
            email: user.email,
            mobilePhoneCountryCode: user.mobilePhoneCountryCode,
            mobileNumber: user.mobileNumber
          })
        );
      });
  };

  const invalidEmail = !pristine.email && !isValidEmail(formData.email);

  const invalidPhone =
    !pristine.phone &&
    (!isValidPhoneNumber(formData.phone) ||
      !isValidCountryCode(formData.countryCode));

  const renderTitle = () => {
    if (appointmentType === AppointmentType.PHYSICAL) {
      return t('vb:book_your_visit');
    }
    if (appointmentType === AppointmentType.VIRTUAL_WALKTHROUGH) {
      return t('vb:book_your_walkthrough_experience');
    }
    return t('vb:book_your_appointment');
  };

  const renderSelectTime = () => {
    if (storeId === iwcBrazilSiteId) {
      return getAvailableSlotsByWeekday(
        formData.date,
        openingHoursByWeekday,
        appointmentSlotDurationInMinutes,
        timezoneGMTOffset
      ).map((s) => (
        <option value={s} key={s}>
          {s}
        </option>
      ));
    }

    if (isVirtualWalkthrough) {
      return getAvailableSlotsWithConcurrentLimitation(
        formData.date,
        availableSlots,
        timezoneGMTOffset
      ).map((s) => (
        <option value={s.time} key={s.time} disabled={s.slotNr === 0}>
          {s.time}
        </option>
      ));
    }

    if (!isVirtualWalkthrough && visitHours?.length) {
      return getAvailableSlots(
        formData.date,
        visitHours,
        timezoneGMTOffset
      ).map((s) => (
        <option value={s} key={s}>
          {s}
        </option>
      ));
    }

    return (
      <>
        <option value="Morning">{t('vb:morning')}</option>
        <option value="Afternoon">{t('vb:afternoon')}</option>
      </>
    );
  };

  const getTscHour = () => {
    if (storeId === iwcThaiBangkokBoutiqueId)
      return t('vb:iwc_thai_boutique_opening_hours_disclaimer');
    if (storeId === iwcNlAmsterdamSiteId)
      return t('vb:iwc_nl_boutique_opening_hours_disclaimer');
    if (storeId === iwcBrazilSiteId)
      return t('vb:iwc_br_boutique_opening_hours_disclaimer');

    return t('vb:iwc_boutique_opening_hours_disclaimer');
  };

  return (
    <div className="appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className={`popup-container fadein ${
              emailSent ? 'successful' : ''
            } ${state}`}
            id="ap-popup"
          >
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => handleClose()}
              >
                &times;
              </button>
              <div className="logo">
                <img src={logoUrl} alt="LOGO" />
              </div>
              {!emailSent && (
                <form id="emailform">
                  <h2>{renderTitle()}</h2>
                  <h3>{t('vb:appointment_detail')}</h3>
                  {!appointmentType && (
                    <div className="input-group">
                      <label>{t('vb:book_your_appointment_for')}:</label>
                      <select
                        id="input-appointment-type"
                        name="appointment-type"
                        required
                        onChange={handleAppointmentType}
                        value={appointmentType}
                      >
                        <option value="">{t('vb:select')}</option>
                        <option value={AppointmentType.VIRTUAL_WALKTHROUGH}>
                          {t('vb:virtual_walkthrough')}
                        </option>
                        <option value={AppointmentType.PHYSICAL}>
                          {t('vb:physical_boutique_visit')}
                        </option>
                      </select>
                      {!pristine.appointmentType && !appointmentType && (
                        <span className="error">
                          {t('vb:please_select_appointment_type')}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:appointment_date')}:</label>
                      <DatePicker
                        selected={formData.date}
                        filterDate={getIsDateAllowedByBrand(iwcBrandId)}
                        minDate={getDefaultDate(iwcBrandId)}
                        onChange={handleDate}
                        locale={i18n.language}
                        dateFormat="MMMM d, yyyy"
                      />
                    </div>

                    <div className="input-group">
                      <label>
                        {t('vb:preferred_time', { timezone: timezoneName })}:
                      </label>
                      <select
                        id="inputtime"
                        name="time"
                        required
                        onChange={handleTime}
                      >
                        <option value="">{t('vb:select')}</option>
                        {renderSelectTime()}
                      </select>
                      {!pristine.time &&
                        (!formData.time ||
                          formData.time.trim().length === 0) && (
                          <span className="error time">
                            {t('vb:please_select_time')}
                          </span>
                        )}
                    </div>
                  </div>
                  {appointmentContacts.length > 1 && (
                    <div className="input-group">
                      <label>{t('vb:preferred_boutique')}:</label>
                      <select
                        id="inputboutique"
                        name="boutiqueemail"
                        required
                        onChange={handleBoutique}
                        value={formData.boutiqueContact?.name || ''}
                      >
                        <option value="">{t('vb:select')}</option>
                        {appointmentContacts.map((c) => (
                          <option value={c.name} key={c.name}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                      {!pristine.boutiqueContact &&
                        !formData.boutiqueContact && (
                          <span className="error">
                            {t('vb:please_select_boutique')}
                          </span>
                        )}
                    </div>
                  )}
                  <h3>{t('vb:general_information')}</h3>
                  <div className="input-group">
                    <label>{t('vb:name')}:</label>
                    <input
                      type="text"
                      id="inputname"
                      name="name"
                      placeholder={t('vb:enter_your_name')}
                      onBlur={handleName}
                    />
                    {!pristine.name && !isValidName(formData.name) && (
                      <span className="error">
                        {t('vb:please_enter_valid_name')}
                      </span>
                    )}
                  </div>{' '}
                  <div className="subtitle">
                    {t('vb:Id_like_to_be_contacted_via')}:
                  </div>
                  <div className="two-col contact">
                    <div className="input-group">
                      <label>{t('email')}:</label>
                      <input
                        type="email"
                        id="inputemail"
                        name="email"
                        placeholder={t('vb:enter_your_email')}
                        onBlur={handleEmail}
                      />
                      {invalidEmail && invalidPhone && (
                        <span className="error">
                          {t('vb:please_enter_valid_email')}
                        </span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:phone_number')}:</label>
                      <div className="phone-number-input">
                        <input
                          type="tel"
                          id="inputcountrycode"
                          name="countrycode"
                          value={formData.countryCode}
                          onChange={handleCountryCode}
                        />
                        <input
                          type="tel"
                          id="inputphone"
                          name="phone"
                          placeholder={t('vb:enter_phone_number')}
                          onBlur={handlePhoneNumber}
                        />
                      </div>

                      {invalidEmail && invalidPhone && (
                        <span className="error">
                          {t('vb:please_enter_valid_phone_number')}
                        </span>
                      )}
                    </div>
                  </div>
                  {isVirtualWalkthrough ? (
                    <div className="input-group">
                      <label>{t('vb:Ill_do_walkthrough_on')}:</label>
                      <select
                        id="input-walkthrough-channel"
                        name="walkthrough-channel"
                        required
                        onChange={handleChannel}
                        value={formData.channel}
                      >
                        <option value="Desktop">{t('vb:desktop')}</option>
                        <option value="Mobile">{t('vb:mobile')}</option>
                      </select>
                    </div>
                  ) : null}
                  <div className="input-group tstc">
                    <label>
                      <input
                        type="checkbox"
                        id="inputsubscribe"
                        onChange={handleAcceptTerms}
                      />
                      {!pristine.acceptedTermsOfUse &&
                        !formData.acceptedTermsOfUse && (
                          <span className="error" style={{ top: '-8px' }}>
                            {t('vb:please_accept_tc')}
                          </span>
                        )}
                      {t(
                        storeId === iwcThaiBangkokBoutiqueId
                          ? 'vb:tc_iwc_thailand.part_one'
                          : 'vb:tc_iwc_v2.part_one'
                      )}{' '}
                      <TermsOfUse termsOfUseUrl="https://www.iwc.com/en/terms-and-legal/terms-of-use.html" />{' '}
                      {t('vb:tc_iwc_v2.part_two')}{' '}
                      <PrivacyPolicy privacyUrl="https://www.iwc.com/en/terms-and-legal/privacy-policy.html" />{' '}
                      {t('vb:tc_iwc_v2.part_three')}
                      <span className="sub-tc">
                        - {t('vb:tc_iwc_v2.part_four')}
                      </span>
                      <span className="sub-tc">
                        - {t('vb:tc_iwc_v2.part_five')}
                        <br />
                        {t('vb:tc_iwc_v2.part_six')}{' '}
                        <PrivacyPolicy privacyUrl="https://www.iwc.com/en/terms-and-legal/privacy-policy.html" />
                      </span>
                    </label>
                  </div>
                  {isVirtualWalkthrough && (
                    <div className="additional-disclaimer">
                      <p>
                        {getTscHour()}{' '}
                        <a target="_blank" href="https://stores.iwc.com/">
                          {t('vb:here')}
                        </a>
                        .
                      </p>
                    </div>
                  )}
                  <div className="input-group">
                    <button
                      id="sendform"
                      type="button"
                      onClick={() => handleSend()}
                    >
                      {t(`vb:${emailSentState}`)}
                    </button>
                    <button
                      id="ap-cancel"
                      className="popup-close"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      {t('vb:close')}
                    </button>
                  </div>
                </form>
              )}
              {emailSent && (
                <div className="success">
                  <h2>{t('vb:thank_you_for_your_request')}</h2>
                  <p>
                    {isVirtualWalkthrough
                      ? t('vb:our_ca_will_contact_soon')
                      : t('vb:our_ca_will_contact_soon_visit')}
                  </p>
                  <button
                    className="popup-close"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('vb:close')}
                  </button>
                </div>
              )}
              <PoweredByInspify />
            </div>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
        `}
      </style>
      <style jsx>
        {`
          .subtitle {
            margin-top: 40px;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 2px;
            font-size: 0.7em;
          }

          .subtitle + .two-col > .input-group {
            margin-top: 0;
          }

          .mobile {
            display: none;
          }

          .full {
            display: inline-block;
          }

          .sub-tc {
            display: block;
            margin-left: 1em;
            text-indent: -0.6em;
            padding-left: 1em;
            margin-top: 8px;
          }

          @media (max-width: 768px) {
            .subtitle {
              margin-top: 20px;
            }

            .mobile {
              display: inline-block;
            }

            .full {
              display: none;
            }

            .tstc {
              margin-top: 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default GenericIWCAppointmentForm;

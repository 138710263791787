import { first } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  AiOutlineClose,
  AiOutlineFileText,
  AiOutlineVideoCamera
} from 'react-icons/ai';
import { IoMdArrowDropright } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { assetBaseUrl, videoBaseUrl, zIndex } from '../../../../config';
import {
  IFile,
  IMainState,
  MeetingLayoutMode,
  MeetingNotification
} from '../../../../interfaces';
import {
  actionDidLoadFileList,
  actionSetActiveFile,
  actionSetActivePage,
  actionSetFileController,
  actionShowMeetingNotification,
  actionUpdateMeetingLayout
} from '../../../../redux/actions';
import { DID_CLICK_FLOWCHART_DASHBOARD_ITEM } from '../../../../utils/constants';
import { getPdfFile } from '../../../../utils/file';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';

import NestedMenuButton from './NestedMenuButton';

const RDUWW21FlowChart = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const myId = useSelector(
    (state: IMainState) => state?.clientState?.meeting?.localUser?.participantId
  );
  const brandMovie: IFile = {
    id: 'Brand Movie.mp4',
    title: 'Brand Movie.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/brand movie.mp4/index.m3u8`
    ]
  };
  const nicolasSpeech: IFile = {
    id: "Nicola's Speech.mp4",
    title: "Nicola's Speech.mp4",
    pages: [
      `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/speech_nicola.mp4/index.m3u8`
    ]
  };
  const brandMovieAndNicolasSpeech: IFile = {
    id: "Brand Movie and Nicola's Speech.mp4",
    title: "Brand Movie and Nicola's Speech.mp4",
    pages: [
      `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/brandmovie nicolaspeech.mp4/index.m3u8`
    ]
  };
  const speechProductMusic: IFile = {
    id: 'Speech Product - Music.mp4',
    title: 'Speech Product - Music.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/latest_rdu_content/speech product - music - en.mp4/index.m3u8`
    ]
  };
  const speechArtistMusic: IFile = {
    id: 'Speech Artist - Music.mp4',
    title: 'Speech Artist - Music.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/latest_rdu_content/speech artist - music - en.mp4/index.m3u8`
    ]
  };
  const recordedVisit: IFile = {
    id: 'Recorded Visit.mp4',
    title: 'Recorded Visit.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/d667d85b-8141-4c42-a902-30b76cd3bc66/1. virtual booth visit - music.mp4/index.m3u8`
    ]
  };
  const recordedPressConferenceCN: IFile = {
    id: 'Recorded Press Conference China.mp4',
    title: 'Recorded Press Conference China.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/83d3b84b-6309-4fab-af7b-a4f5dd101f9b/globale_chi2.mp4/index.m3u8`
    ]
  };
  const recordedPressConferenceJP: IFile = {
    id: 'Recorded Press Conference Japan.mp4',
    title: 'Recorded Press Conference Japan.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/83d3b84b-6309-4fab-af7b-a4f5dd101f9b/globale_jp2.mp4/index.m3u8`
    ]
  };
  const recordedPressConferenceKR: IFile = {
    id: 'Recorded Press Conference Korea.mp4',
    title: 'Recorded Press Conference Korea.mp4',
    pages: [
      `${videoBaseUrl}/content/converted/83d3b84b-6309-4fab-af7b-a4f5dd101f9b/globale_ko2.mp4/index.m3u8`
    ]
  };
  const recordedPresentationProductOnly = `${assetBaseUrl}/content/converted/eca49b48-92a0-4583-84f6-699e786c01c2/recorded_ww_presspresentation_product_only.pdf/`;
  const recordedPresentationProductOnlyFile = getPdfFile(
    recordedPresentationProductOnly,
    27,
    'Recorded WW Press Presentation Product Only'
  );

  recordedPresentationProductOnlyFile.pages.splice(
    5,
    0,
    `${videoBaseUrl}/content/converted/latest_rdu_content/final sft video.mov/index.m3u8`
  );
  recordedPresentationProductOnlyFile.thumbnails.splice(
    5,
    0,
    `${videoBaseUrl}/content/converted/latest_rdu_content/final sft video.mov/index.m3u8`
  );

  recordedPresentationProductOnlyFile.pages.splice(
    11,
    0,
    `${videoBaseUrl}/content/converted/latest_rdu_content/2. video star_hd.mp4/index.m3u8`
  );
  recordedPresentationProductOnlyFile.thumbnails.splice(
    11,
    0,
    `${videoBaseUrl}/content/converted/latest_rdu_content/2. video star_hd.mp4/index.m3u8`
  );

  const artisticTribeOnly = `${assetBaseUrl}/content/converted/eca49b48-92a0-4583-84f6-699e786c01c2/w&w digital press presentation- artistic tribe only only.pdf/`;
  const artisticTribeOnlyFile = getPdfFile(
    artisticTribeOnly,
    7,
    'WW Digital Press Presentation - Artistic Tribe Only'
  );

  React.useEffect(() => {
    dispatch(
      actionDidLoadFileList({
        files: [recordedPresentationProductOnlyFile, artisticTribeOnlyFile]
      })
    );
  }, []);

  const showFile = (file: IFile) => {
    dispatch(actionSetActiveFile(file.id));
    dispatch(actionSetFileController(myId));
    dispatch(
      actionSetActivePage({
        id: file.id,
        file: file.title,
        url: first(file.pages),
        setting: first(file.pagesSetting)
      })
    );
    logEvent(
      DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
      DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
      {
        item: file.title,
        itemUrl: first(file.pages)
      }
    );
    onClose();
  };

  const showBrandMovie = () => {
    showFile(brandMovie);
  };

  const showNicolaSpeech = () => {
    showFile(nicolasSpeech);
  };

  const showBrandMovieNicolaSpeech = () => {
    showFile(brandMovieAndNicolasSpeech);
  };

  const showRecordedVisit = () => {
    showFile(recordedVisit);
  };

  const showPressPresentationProductOnly = () => {
    showFile(recordedPresentationProductOnlyFile);
  };

  const showSpeechProductMusic = () => {
    showFile(speechProductMusic);
  };

  const showSpeechArtistMusic = () => {
    showFile(speechArtistMusic);
  };

  const handleBoothDiscovery = () => {
    dispatch(actionShowMeetingNotification(MeetingNotification.LEAVE_MEETING));
    logEvent(
      DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
      DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
      {
        item: 'Booth Discovery'
      }
    );
  };

  const productVideos = [
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/blacklight 1min no comment.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/blacklight green.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/latest_rdu_content/speech product - music - en.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/qrs strap sft cobalt chrome conclusion.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/sft cobalt 1min no comment.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/sft cobalt chrome.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/sft eon gold 1min no comment.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/sft eon gold.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/sft titanium 1min no comment.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/sft titanium prise 2.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/ex0949 no comment.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/68f1c557-3873-4069-83e7-ec6b0e7a7985/ex0949 with commentdnprise 2.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/58a7c874-3f3e-4871-9ed6-f75efac9b8e1/ex0826 last no comment 1min.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/58a7c874-3f3e-4871-9ed6-f75efac9b8e1/ex0826 last with comments.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/4396b7c8-a1d0-4277-8bf4-84bfc482c9e4/ex0963 glow me up_no comment.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/4396b7c8-a1d0-4277-8bf4-84bfc482c9e4/ex0963 glow me up_with comments.mp4/index.m3u8`,
    `${videoBaseUrl}/content/converted/84099b52-2a85-48c8-9a0c-ad06ec487015/manufacture_roger_dubuis_video.mp4/index.m3u8`
  ];

  const pirelliMovies: IFile[] = [
    {
      id: 'HD_Roger_Dubuis_3D-MOVIE.mp4',
      title: 'HD_Roger_Dubuis_3D-MOVIE.mp4',
      pages: [
        `${videoBaseUrl}/content/converted/be00b28a-23c1-4af1-a3d2-58572583a343/hd_roger_dubuis_3d-movie.mp4/index.m3u8`
      ]
    },
    {
      id: 'HD_Roger_Dubuis_PIRELLI-MOVIE.mp4',
      title: 'HD_Roger_Dubuis_PIRELLI-MOVIE.mp4',
      pages: [
        `${videoBaseUrl}/content/converted/be00b28a-23c1-4af1-a3d2-58572583a343/hd_roger_dubuis_pirelli-movie.mp4/index.m3u8`
      ]
    },
    {
      id: 'HD_Roger_Dubuis_SOUL-FLYER.mp4',
      title: 'HD_Roger_Dubuis_SOUL-FLYER.mp4',
      pages: [
        `${videoBaseUrl}/content/converted/be00b28a-23c1-4af1-a3d2-58572583a343/hd_roger_dubuis_soul-flyer.mp4/index.m3u8`
      ]
    }
  ];

  return (
    <div className="container flow-chart-container">
      <Row>
        <div className="header">Presenter Dashboard</div>
        <button
          className="close"
          onClick={() => {
            onClose();
          }}
        >
          <AiOutlineClose color="fff" />
        </button>
      </Row>
      <Row>
        <Col>
          <h4>FULLY LIVE</h4>
          <button
            className="btn"
            onClick={() => {
              logEvent(
                DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
                DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
                {
                  item: 'Live Visit'
                }
              );
              onClose();
            }}
          >
            Live Visit
          </button>
          <button className="btn" onClick={showNicolaSpeech}>
            Nicola's Speech
          </button>
          <button className="btn" onClick={showBrandMovie}>
            Brand Movie
          </button>
          <button
            className="btn"
            onClick={() => {
              showFile(artisticTribeOnlyFile);
            }}
          >
            Artists at Work
          </button>
          <button
            className="btn"
            onClick={() => {
              logEvent(
                DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
                DID_CLICK_FLOWCHART_DASHBOARD_ITEM,
                { item: 'Q&A: Live Feed' }
              );
              dispatch(actionUpdateMeetingLayout(MeetingLayoutMode.TILE));
              onClose();
            }}
          >
            Q&A: Live Feed
          </button>
          <NestedMenuButton
            title={
              <>
                Product/Marketing <IoMdArrowDropright />
              </>
            }
            top={-300}
            right={-50}
          >
            <button
              className="nested"
              onClick={showPressPresentationProductOnly}
            >
              <AiOutlineFileText />
              Recorded_WW_PressPresentation_ProductOnly.pdf
            </button>

            {productVideos.map((url) => (
              <button
                key={url}
                className="nested"
                onClick={() => {
                  showFile({
                    id: url.split('/')[6],
                    title: url.split('/')[6],
                    pages: [url]
                  });
                }}
              >
                <AiOutlineVideoCamera />
                {url.split('/')[6]}
              </button>
            ))}
          </NestedMenuButton>
          <button className="btn" onClick={handleBoothDiscovery}>
            Booth Discovery
          </button>
        </Col>
        <Col>
          <h4>FULLY RECORDED</h4>
          <button className="btn" onClick={showRecordedVisit}>
            Recorded Visit
          </button>
          <button className="btn" onClick={showBrandMovieNicolaSpeech}>
            Nicola's Speech and Brand Movie
          </button>
          <button className="btn" onClick={showSpeechProductMusic}>
            Product/Marketing
          </button>
          <button className="btn" onClick={showSpeechArtistMusic}>
            Artists at Work
          </button>
          <NestedMenuButton
            title={
              <>
                Recorded Press Conference <IoMdArrowDropright />
              </>
            }
            top={-40}
            right={-50}
          >
            <button
              className="nested"
              onClick={() => {
                showFile(recordedPressConferenceCN);
              }}
            >
              <AiOutlineVideoCamera />
              China
            </button>
            <button
              className="nested"
              onClick={() => {
                showFile(recordedPressConferenceJP);
              }}
            >
              <AiOutlineVideoCamera />
              Japan
            </button>
            <button
              className="nested"
              onClick={() => {
                showFile(recordedPressConferenceKR);
              }}
            >
              <AiOutlineVideoCamera />
              Korea
            </button>
          </NestedMenuButton>
          <button className="btn" onClick={handleBoothDiscovery}>
            Booth Discovery
          </button>
          <NestedMenuButton
            title={
              <>
                2021 Spider Pirelli videos <IoMdArrowDropright />
              </>
            }
            top={-40}
            right={-50}
          >
            {pirelliMovies.map((m) => (
              <button
                key={m.title}
                className="nested"
                onClick={() => {
                  showFile(m);
                }}
              >
                <AiOutlineVideoCamera />
                {m.title}
              </button>
            ))}
          </NestedMenuButton>
        </Col>
      </Row>

      <style jsx>
        {`
          .flow-chart-container {
            background: #000;
            color: #fff;
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 20px;
            left: 10vw;
            right: 10vw;
            border-radius: 5px;
            padding-bottom: 40px;
            box-shadow: 0 0 50px rgb(165 31 37 / 70%);
            max-width: 1024px;
            width: 80vw;
            z-index: ${zIndex.notification + 1};
            max-height: 90vh;
            overflow: auto;
          }

          .flow-chart-container .header {
            border-bottom: 1px solid #fff;
            text-align: center;
            padding: 10px;
            width: 100%;
            font-size: 1.25rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-top: 10px;
            font-weight: 600;
          }

          .flow-chart-container h4 {
            margin: 20px 0;
            font-size: 1.2rem;
            letter-spacing: 1px;
          }

          .flow-chart-container :global(.btn) {
            color: #fff;
            border: 1px solid #fff;
            border-radius: 5px;
            width: 80%;
            margin-bottom: 20px;
          }

          .flow-chart-container :global(.btn:hover) {
            color: #ccc;
          }

          .flow-chart-container .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 60px;
            opacity: 1;
          }

          .flow-chart-container .close svg {
            color: #fff;
          }

          .flow-chart-container :global(.nested) {
            display: block;
            color: #000;
            padding: 10px 20px;
            border-bottom: 1px solid #000;
            background: #fff;
            width: 100%;
            text-align: left;
            white-space: nowrap;
          }

          .flow-chart-container :global(.nested:hover) {
            background: #eee;
          }

          .flow-chart-container :global(.nested:last-child) {
            border: none;
          }

          .flow-chart-container :global(.nested svg) {
            display: inline-block;
            margin-right: 5px;
            position: relative;
            bottom: 1px;
          }
          .flow-chart-container :global(.nested-content) {
            max-height: 340px;
            overflow: auto;
          }
        `}
      </style>
      <style jsx global>{`
        .in-meeting.MDLandscape .flow-chart-container {
          left: calc(${MDLandscapeNormalSpec.contentArea.left}px + 5vw);
          right: 5vw;
          width: calc(90vw - ${MDLandscapeNormalSpec.contentArea.left}px);
          transform: translateY(-60%);
        }
      `}</style>
    </div>
  );
};

export default RDUWW21FlowChart;

import React from 'react';
import { MdPause, MdPlayCircleFilled, MdReplay } from 'react-icons/md';
import {
  VscChromeMaximize,
  VscChromeMinimize,
  VscChromeRestore
} from 'react-icons/vsc';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../../analytics';
import { avatarVideoList } from '../../../../config/rduSg';
import { PanoView, RDUHudMenu } from '../../../../interfaces';
import {
  actionDidClickHudMenu,
  actionOpenHud
} from '../../../../redux/actions';
import {
  DID_CLICK_UNMUTE_VIDEO,
  DID_START_AVATAR_VIDEO
} from '../../../../utils/constants';
import { krpanoInvoke } from '../../../../utils/krpano';

import { useAutoPlayVideoMuteControl } from '../../../hooks/meeting';

let timeout;
const AvatarVideos = ({
  currentScene,
  canPlay
}: {
  currentScene?: string;
  canPlay?: boolean;
}) => {
  const dispatch = useDispatch();
  const [videoList, setVideoList] = React.useState<
    { url: string; next?: RDUHudMenu; view?: PanoView; end?: boolean }[]
  >([]);
  const [retryCount, setRetryCount] = React.useState(0);
  const [activeVideo, setActiveVideo] = React.useState<number>();
  const [ended, setEnded] = React.useState(false);
  const [playWithSound, setPlayWithSound] = useAutoPlayVideoMuteControl();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [layout, setLayout] = React.useState('');

  const lookToView = (view: PanoView) => {
    setTimeout(() => {
      krpanoInvoke(
        `lookto(${view.viewH || 0}, ${view.viewV || 0}, ${
          view.fov || 140
        }, smooth())`
      );
    }, 100);
  };

  const handleActivateNewVideo = (index: number) => {
    setEnded(false);
    if (timeout) {
      clearTimeout(timeout);
    }
    setActiveVideo(undefined);
    timeout = setTimeout(() => {
      setActiveVideo(index);
    }, 300);
  };

  const handleNext = () => {
    const next = videoList[activeVideo]?.next;
    dispatch(actionOpenHud(false));
    if (next && typeof next !== 'number') {
      dispatch(actionDidClickHudMenu(next));
    }

    if (!next && videoList[activeVideo + 1]) {
      handleActivateNewVideo(activeVideo + 1);
    }
  };

  React.useEffect(() => {
    const videoInCurrentScene = avatarVideoList
      .filter((video) => video.scene === currentScene)
      .map((video) => ({
        url: video.url,
        next: video.next,
        view: video.view,
        end: video.end
      }));

    if (canPlay && videoInCurrentScene?.length) {
      setVideoList(videoInCurrentScene);
      handleActivateNewVideo(0);
    }
    if (canPlay && !videoInCurrentScene?.length) {
      setVideoList([]);
      handleActivateNewVideo(undefined);
    }
  }, [currentScene, canPlay]);

  React.useEffect(() => {
    if (videoList[activeVideo]?.view) {
      lookToView(videoList[activeVideo]?.view);
    }
  }, [videoList, activeVideo]);

  return (
    <Transition
      in={!!videoList[activeVideo]?.url}
      timeout={100}
      onEntered={() => {
        setIsPlaying(true);
      }}
    >
      {(state) => (
        <div
          className={`avatarVideo ${state} ${layout}`}
          onClick={() => {
            if (layout === 'minimize') {
              setLayout('');
            }
          }}
        >
          <ReactPlayer
            playing={isPlaying}
            width="100%"
            height="100%"
            autoPlay
            playsinline
            muted={!playWithSound}
            url={videoList[activeVideo]?.url}
            onStart={() => {
              logEvent(DID_START_AVATAR_VIDEO, DID_START_AVATAR_VIDEO, {
                url: activeVideo
              });
            }}
            onEnded={() => {
              setEnded(true);
              setIsPlaying(false);
            }}
            onError={(e) => {
              console.log(e);
              if (e !== 'hlsError') {
                if (setPlayWithSound) {
                  setIsPlaying(false);
                  setPlayWithSound(false);
                  if (retryCount < 10) {
                    setIsPlaying(true);
                    setRetryCount(retryCount + 1);
                  }
                }
              }
            }}
          />

          {!playWithSound && (
            <button
              className="btn mute-btn"
              onClick={() => {
                logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO, {
                  url: activeVideo
                });
                setPlayWithSound(true);
              }}
            >
              UNMUTE
            </button>
          )}

          {!ended && !isPlaying && (
            <div className="nav-video">
              <div>
                <button className="btn" onClick={() => setIsPlaying(true)}>
                  <MdPlayCircleFilled /> PLAY
                </button>
              </div>
            </div>
          )}

          {isPlaying && !ended && (
            <button
              className="btn pause-button with-shadow"
              onClick={() => setIsPlaying(false)}
            >
              <MdPause />
            </button>
          )}

          {isPlaying && !ended && !videoList[activeVideo]?.end && (
            <button
              className="btn skip-button with-shadow"
              onClick={handleNext}
            >
              SKIP
            </button>
          )}

          {ended && !isPlaying && (
            <div className="nav-video">
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    setEnded(false);
                    setIsPlaying(true);
                  }}
                >
                  <MdReplay /> REPLAY
                </button>
                <button className="btn" onClick={handleNext}>
                  <MdPlayCircleFilled />{' '}
                  {videoList[activeVideo]?.end ? 'BACK TO LOBBY' : 'NEXT'}
                </button>
              </div>
            </div>
          )}

          <div className="nav-layout">
            <button
              className="btn with-shadow"
              onClick={() => {
                setLayout('minimize');
              }}
            >
              <VscChromeMinimize color="#fff" />
            </button>

            <button
              className="btn maximize-button"
              onClick={() => {
                setLayout(layout === 'maximize' ? '' : 'maximize');
              }}
            >
              {layout === 'maximize' ? (
                <VscChromeRestore color="#fff" />
              ) : (
                <VscChromeMaximize color="#fff" />
              )}
            </button>
          </div>

          <style jsx>{`
            .avatarVideo {
              z-index: -1;
              position: fixed;
              background: black;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              opacity: 1;
              transition: all 0.1s ease-in-out;
              bottom: 10px;
              left: 10px;
              overflow: hidden;
              border: 2px solid #fff;
              width: 376px;
            }
            .avatarVideo.maximize {
              border: none;
            }
            .avatarVideo.minimize {
              transform: scale(0.3);
              transform-origin: 0% 100%;
              cursor: pointer;
            }

            .avatarVideo.minimize button {
              pointer-events: none;
            }
            .avatarVideo > :global(div:first-child) {
              width: 390px !important;
            }

            .avatarVideo.entering {
              z-index: 2002;
              opacity: 0;
            }
            .avatarVideo.entered {
              z-index: 2002;
              opacity: 1;
            }
            .avatarVideo.exiting {
              z-index: 2002;
              opacity: 0;
            }
            .avatarVideo.exited {
              z-index: -1;
              opacity: 0;
            }

            video {
              width: 390px;
            }

            .nav-video {
              background: rgba(0, 0, 0, 0.6);
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .nav-video button {
              margin: 5px auto;
            }
            .nav-video div {
              margin: auto;
              width: 100%;
              text-align: center;
            }

            .nav-video :global(svg) {
              margin-top: -2px;
            }

            .btn {
              padding: 10px;
              background: transparent;
              border: 1px solid #fff;
              color: #fff;
              display: block;
              width: 90%;
              border-radius: 0;
              outline: none !important;
              box-shadow: none !important;
            }

            .btn:hover {
              color: #fff;
            }

            .mute-btn {
              position: absolute;
              bottom: 20px;
              left: 20px;
              right: 20px;
            }
            .nav-layout {
              position: absolute;
              opacity: 0.5;
              right:.5%;
              top: 1.5%;
              display: flex;
            }
            .nav-layout .btn {
              border: none;
              padding: 0 2px;
              width: auto;
            }
            .pause-button {
              border: none;
              width: auto;
              left: 0;
              bottom: 0;
              position: absolute;
              opacity: 0.5;
            }
            .avatarVideo:hover .nav-layout,
            .avatarVideo:hover .pause-button,
            .avatarVideo:hover .skip-button {
              opacity: 1;
            }
            .nav-layout .btn :global(svg) {
              width: 30px;
              height: auto;
            }

            .pause-button :global(svg) {
              width: 30px;
              height: auto;
            }
            .skip-button{
              position:absolute;
              bottom:0;
              right:0;
              width:auto;
              font-size:20px;
              padding-top:10px;
              opacity:.5;
              font-weight:bold;
              border:none;
              font-family:Arial;
              letter-spacing:1px;
            }
            @media (max-width: 400px) {
              .maximize-button {
                display: none;
              }
              .avatarVideo {
                right: 10px;
                width: auto;
              }
              .avatarVideo.minimize {
                right: auto;
              }

              .avatarVideo > :global(div:first-child) {
                margin: -4px -12px;
                width: calc(100% + 24px) !important;
              }
            }
            @media (min-width: 400px) {
              .avatarVideo.maximize {
                right: 10px;
                width: auto;
              }

              .avatarVideo.maximize > :global(div:first-child) {
                margin: 0 -1vw;
                width: 98vw !important;
              }
            }

            @media (min-width: 768px) {
              .avatarVideo.maximize {
                right: auto;
                width: 45vw;
              }

              .avatarVideo.maximize > :global(div:first-child) {
                margin: 0 -1vw;
                width: 47vw !important;
              }
            }
          `}</style>
        </div>
      )}
    </Transition>
  );
};

export default AvatarVideos;

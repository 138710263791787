import { logEvent } from '../../../analytics';
import { DID_CLICK_LINK_BUTTON } from '../../../utils/constants';
import { useTranslation } from '../../../i18n';

const LinkButton = ({ url }: { url: string }) => {
  const { t } = useTranslation();
  if (url === 'NA') return null;
  return (
    <a
      className="btn btn-dark btn-action"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        logEvent(DID_CLICK_LINK_BUTTON, url);
      }}
    >
      {t('shop_now')}
    </a>
  );
};

export default LinkButton;

import React from 'react';
import { Transition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import {
  EmailSentState,
  IMainState,
  IVirtualBoutiqueShopContact
} from './../../interfaces';
import CartEmpty from './CartEmpty';
import CartSent from './CartSent';
import { logEvent } from '../../analytics';
import { DID_CLICK_CART_CLOSE_BUTTON } from '../../utils/constants';
import {
  actionCartUpdateSendStatus,
  actionCartUpdateCheckoutStep
} from './../../redux/actions';
import { getShoppingCartPage } from '../storeComponentFactory';

interface Props {
  onClose: () => void;
  open: boolean;
  onProductSearch: () => void;
  shopContacts: IVirtualBoutiqueShopContact[];
  storeId: string;
  phoneCountryCode: string;
  deliveryFee?: number;
}

const ShoppingCartPopup = ({
  onClose,
  open,
  onProductSearch,
  shopContacts,
  storeId,
  phoneCountryCode,
  deliveryFee = 0
}: Props) => {
  const dispatch = useDispatch();
  const animationDuration = 300;
  const shoppingCart = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const cartItems = shoppingCart?.items || [];
  const sendStatus = shoppingCart?.sendStatus || EmailSentState.INITIAL;
  const orderSent = sendStatus === EmailSentState.SENT;

  const emailSentClose = () => {
    setTimeout(
      () => dispatch(actionCartUpdateSendStatus(EmailSentState.INITIAL)),
      300
    );
  };

  const handleClose = () => {
    onClose();
    dispatch(actionCartUpdateCheckoutStep(null));
    if (orderSent) {
      emailSentClose();
    }
  };
  return (
    <div className="ShoppingCartPopup appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div className={`popup-container fadein ${state}`} id="ap-popup">
            <div
              className={`form-container ${!cartItems.length ? 'empty' : ''}`}
            >
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => {
                  handleClose();
                  logEvent(DID_CLICK_CART_CLOSE_BUTTON);
                }}
              >
                &times;
              </button>
              {orderSent && <CartSent onClose={handleClose} />}
              {!!cartItems.length &&
                getShoppingCartPage(
                  shopContacts,
                  storeId,
                  phoneCountryCode,
                  deliveryFee,
                  onClose
                )}
              {!cartItems.length && !orderSent && (
                <CartEmpty
                  onClose={onClose}
                  onProductSearch={onProductSearch}
                />
              )}
            </div>
          </div>
        )}
      </Transition>
      <style jsx>{`
        .empty {
          display: flex;
          align-items: center;
        }
        @media (min-width: 1024px) {
          .appointment #ap-close {
            width: 95%;
            max-width: 1200px;
            transform: translateX(-50%);
            padding-right: 30px;
            margin-left: 0;
            text-align: right;
          }
          .appointment :global(.input-group) {
            margin-top: 0;
            margin-bottom: 40px;
          }
          .appointment :global(.CartForm) {
            padding-top: 30px;
          }
          .appointment .form-container {
            width: 95%;
            max-width: 1200px;
            padding: 70px 50px 30px;
          }
        }
      `}</style>
    </div>
  );
};

export default ShoppingCartPopup;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdCheckCircle } from 'react-icons/md';

import { CheckoutPaymentStatus, IMainState } from '../../../interfaces';
import { useTranslation } from '../../../i18n';
import {
  actionCartUpdateCheckoutFormData,
  actionCartUpdateCheckoutOrderId,
  actionCartUpdateCheckoutStep,
  actionCartClearProducts
} from '../../../redux/actions';
import { DID_CLICK_CLOSE_BTN } from '../../../utils/constants';
import { getFormattedFullName } from '../../../utils/shoppingCart';
import { RiErrorWarningFill } from 'react-icons/ri';
import { logEvent } from '../../../analytics';
import { isEmpty } from 'lodash';

const CheckoutConfirmationInfo = ({
  onClose,
  checkoutEmail,
  checkoutName,
  checkoutOrderId,
  cardDeclined
}: {
  onClose?: () => void;
  checkoutEmail?: string;
  checkoutName?: string;
  checkoutOrderId?: string;
  cardDeclined?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const shoppingCart = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart
  );
  const formData = shoppingCart?.checkoutFormData;
  const orderId = shoppingCart?.checkout?.orderId || checkoutOrderId;
  const email = formData?.email || checkoutEmail;
  const paymentStatus = shoppingCart?.checkout?.paymentStatus;
  const isCardDeclined = paymentStatus === CheckoutPaymentStatus.DECLINED || cardDeclined;

  const onClickClose = () => {
    logEvent(DID_CLICK_CLOSE_BTN, DID_CLICK_CLOSE_BTN, {
      page: 'Checkout Thank you page'
    });
    dispatch(actionCartUpdateCheckoutFormData(null));
    dispatch(actionCartUpdateCheckoutStep(null));
    dispatch(actionCartUpdateCheckoutOrderId(null));
    dispatch(actionCartClearProducts());
    onClose();
  };

  if (!orderId || isCardDeclined) {
    return (
      <div className="CartSent success text-center">
        <div className="icon-container">
          <RiErrorWarningFill color="red" />
        </div>
        <h2>{isCardDeclined ? t('vb:payment_declined') : t('vb:something_went_wrong')}</h2>
        <button
          className="btn-action"
          type="button"
          onClick={() => {
            onClickClose();
          }}
        >
          {t('vb:close')}
        </button>

        <style jsx>
          {`
            .CartSent {
              margin: auto;
            }
            .icon-container :global(svg) {
              width: 80px;
              height: auto;
            }
          `}
        </style>
      </div>
    );
  }

  return (
    <div className="CartSent success text-center">
      <div className="icon-container">
        <MdCheckCircle color="green" />
      </div>
      <h2 className="name-and-lastname">
        {t('vb:dear')}{' '}
        {!isEmpty(formData)
          ? getFormattedFullName(
              formData?.firstName,
              formData?.lastName,
              t(formData?.salutation)
            )
          : checkoutName}
      </h2>
      <h2>{t('vb:thank_your_order')}</h2>
      <p>
        {t('vb:order_confirmation_message_line1', {
          orderNumber: orderId
        })}
      </p>
      <p>
        {t('vb:order_confirmation_message_line2', {
          emailID: email
        })}
      </p>
      <button
        className="btn-action"
        type="button"
        onClick={() => {
          onClickClose();
        }}
      >
        {t('vb:close')}
      </button>

      <style jsx>
        {`
          .CartSent {
            margin: auto;
          }
          .name-and-lastname {
            margin-bottom: 0;
            text-transform: uppercase;
          }
          p {
            margin-bottom: 0;
          }
          .icon-container :global(svg) {
            width: 80px;
            height: auto;
          }
        `}
      </style>
    </div>
  );
};

export default CheckoutConfirmationInfo;

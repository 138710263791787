import React from 'react';
import useSound from 'use-sound';
import { useMeetingLocalAudio } from '../hooks/meeting';
import { usePageVisibility } from '../hooks/ux';

const FadeInAndOutMusic = ({
  backgroundMusicUrl,
  loop,
  play,
  volume = 1,
  delay = 10,
  fade = 2000
}: {
  backgroundMusicUrl?: string;
  loop?: boolean;
  play?: boolean;
  volume?: number;
  delay?: number;
  fade?: number;
}) => {
  const { muteAudio } = useMeetingLocalAudio();
  const isPageVisible = usePageVisibility();


  const [playMusic, { sound, duration, stop }] = useSound(backgroundMusicUrl, {
    volume,
    loop
  });

  const shouldPlay = play && isPageVisible && duration;

  React.useEffect(() => {
    if (shouldPlay) {
      setTimeout(() => {
        muteAudio();
        playMusic?.();
        sound?.fade?.(0, volume, fade);
      }, delay);
    } else {
      sound?.fade(volume, 0, fade);
      setTimeout(() => {
        stop?.();
      }, fade);
    }
  }, [shouldPlay]);

  return null;
};

export default FadeInAndOutMusic;

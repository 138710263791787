import React from 'react';
import { IVirtualBoutiqueContact } from '../../../interfaces';
import { logClickContact } from './common';
import { getWhatsappLink } from '../../../utils/whatsappLink';
import { useDispatch } from 'react-redux';
import { actionClickContact } from '../../../redux/actions';
import { Spinner } from 'react-bootstrap';
import { useChatMessage } from '../../hooks/common';

const WhatsappContactButton = ({
  contact,
  defaultMessage,
  children
}: {
  contact: IVirtualBoutiqueContact;
  defaultMessage: string;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const { loading, getMessage, message } = useChatMessage(defaultMessage);

  const handleClick = async () => {
    const url = getWhatsappLink(
      contact.whatsappNumber,
      message || (await getMessage())
    );
    window.open(url, '_blank');
    logClickContact('whatsapp', contact);
    dispatch(actionClickContact());
  };

  return (
    <a
      className='whats-app-button'
      key={contact.name}
      data-channel="whatsapp"
      target="_blank"
      rel="noopener"
      aria-label="Chat Now"
      onClick={handleClick}
    >
      {loading ? <Spinner animation="border" size="sm" /> : children}
    </a>
  );
};

export default WhatsappContactButton;

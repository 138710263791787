import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState, VirtualBoutiqueState } from '../../../../interfaces';
import { actionOpenMusicPlaylist } from '../../../../redux/actions';
import AudioPlayer from '../RDUSGCustomComponent/AudioPlayer';

const LoubiAirwaysSpotifyPlayer = () => {
  const clientState = useSelector((state: IMainState) => state.clientState);
  const vb: VirtualBoutiqueState = clientState?.vb || {};
  const dispatch = useDispatch();

  if (clientState.loubiAirways?.showSpotifyPlayer)
    return (
      <AudioPlayer
        playlist="https://open.spotify.com/embed/playlist/2JX5293txfgySvhbhXp6UL"
        open={vb?.openMusicPlaylist}
        onToggle={(open) => dispatch(actionOpenMusicPlaylist(open))}
      />
    );

  return null;
};

export default LoubiAirwaysSpotifyPlayer;

import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import {
  COMMON3D_ANIMATION,
  ICommon3DAnimationState,
  ITransformValue
} from '../../../interfaces/common3D';
import {
  actionDidUpdateCommon3DAnimation,
  actionUpdateCommon3DActiveAnimationInPano
} from '../../../redux/customActions/common3DAction';
import { DID_UPDATE_ANIMATION_STATE } from '../../../utils/constants';
import { useRemoteCommon3DAnimationState } from '../../hooks/common3D';
import { canInteractWithAnimation } from '../../hooks/meeting';
import { animationVoyageThrottleDurationInMs } from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/animationUtils';
import * as THREE from 'three';
import { SharedCanvasContext } from 'react-three-fiber';
export interface IAnimationContext {
  remoteState: ICommon3DAnimationState;
  viewOnly: boolean;
  TWEEN;
  closeAnimation: () => void;
  updateRemoteState: (state: ICommon3DAnimationState) => void;
  setFocus: (
    model: THREE.Mesh,
    threeRef: SharedCanvasContext,
    transform: ITransformValue,
    isFocused: boolean
  ) => void;
}
let ObjectControls;
let TWEEN;
export const Common3DContext =
  React.createContext<IAnimationContext>(undefined);
const Common3DAnimationContextContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const remoteState = useRemoteCommon3DAnimationState(COMMON3D_ANIMATION);
  const viewOnly = !canInteractWithAnimation();
  const dispatch = useDispatch();

  const closeAnimation = () => {
    dispatch(actionUpdateCommon3DActiveAnimationInPano(undefined));
    logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
      animation: COMMON3D_ANIMATION,
      state: 'CLOSE'
    });
  };

  const loadThreeJsModulesAsync = async () => {
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };
  React.useEffect(() => {
    loadThreeJsModulesAsync();
  }, []);

  const logEventThrottled = throttle(
    (state: ICommon3DAnimationState) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: COMMON3D_ANIMATION,
        state
      });
    },
    100,
    { trailing: true }
  );

  const throttledUpdateRemoteState = throttle(
    (state: ICommon3DAnimationState) => {
      dispatch(actionDidUpdateCommon3DAnimation(COMMON3D_ANIMATION, state));
    },
    animationVoyageThrottleDurationInMs
  );
  const updateRemoteState = (state: ICommon3DAnimationState) => {
    throttledUpdateRemoteState(state);
    logEventThrottled(state);
  };

  /********************************** */
  let controls;
  const updateObjectControlForModel = (
    model: THREE.Mesh,
    threeRef: SharedCanvasContext
  ) => {
    if (!threeRef?.camera || !threeRef?.gl?.domElement || !model || viewOnly)
      return;
    if (!controls) {
      controls = new ObjectControls(
        threeRef.camera,
        threeRef.gl.domElement,
        model
      );
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400); // set min - max distance for zoom
    controls.setZoomSpeed(0.5); // set zoom speed
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.05);
  };
  const setFocus = (
    model: THREE.Mesh,
    threeRef: SharedCanvasContext,
    transform: ITransformValue,
    isFocused: boolean
  ) => {
    if (!TWEEN || !model) return;
    const tweenTime = 1000;
    const obj = model;
    if (transform?.rotation?.length === 3) {
      new TWEEN.Tween(obj.rotation)
        .to(
          {
            x: Math.PI * transform?.rotation[0],
            y: Math.PI * transform?.rotation[1],
            z: Math.PI * transform?.rotation[2]
          },
          tweenTime
        )
        .easing(TWEEN.Easing.Exponential.InOut)
        .start();
    }
    if (transform?.scale?.length === 3) {
      new TWEEN.Tween(obj.scale)
        .to(
          {
            x: transform?.scale[0],
            y: transform?.scale[1],
            z: transform?.scale[2]
          },
          tweenTime
        )
        .easing(TWEEN.Easing.Exponential.InOut)
        .start();
    }
    if (transform?.position?.length === 3) {
      new TWEEN.Tween(obj.position)
        .to({
          x: transform?.position[0],
          y: transform?.position[1],
          z: transform?.position[2]
        })
        .easing(TWEEN.Easing.Exponential.InOut)
        .onComplete(() => {
          if (isFocused) {
            updateObjectControlForModel(obj, threeRef);
          } else {
            controls?.disableVerticalRotation();
            controls?.disableHorizontalRotation();
          }
        })
        .start();
    }
  };

  /*********************************** */

  return (
    <Common3DContext.Provider
      value={{
        remoteState,
        viewOnly,
        TWEEN,
        closeAnimation,
        updateRemoteState,
        setFocus
      }}
    >
      {children}
    </Common3DContext.Provider>
  );
};

export default Common3DAnimationContextContainer;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { EnquiryType } from '../../../../interfaces';
import { actionUpdateUserAttributesAsync } from '../../../../redux/asyncActions';
import {
  DID_FAIL_TO_SEND_SULTANATE_OF_OMAN_EMAIL,
  DID_INPUT_FORM_FIELD,
  DID_SEND_SULTANATE_OF_OMAN_EMAIL
} from '../../../../utils/constants';
import { getIdentityId } from '../../../../utils/identity';
import PoweredByInspify from '../../../Legal/PoweredByInspify';
import PrivacyPolicy from '../../../Legal/PrivacyPolicy';
import TermsOfUse from '../../../Legal/TermsOfUse';
import { useTranslation } from '../../../../i18n';
import { logEvent } from '../../../../analytics';
import {
  isAllowedForEmail,
  isAllowedForName,
  isValidCountryCode,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from '../../Appointment/inputValidator';
import { createUser } from '../../../../clientSideServices/users';
import { sendSultanateOfOmanEmail } from '../../Appointment/enquiryService';

export interface ISultanateOfOmanFormData {
  salutation: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  countryCode: string;
  phone: string | null;
  city: string | null;
  country: string | null;
  ownsIwcTimepiece?: boolean;
  iwcTimepieceNote?: string | null;
  likeAboutSultanateOfOman?: string[];
  acceptedTermsOfUse: boolean;
  subscribeNews?: boolean;
}

interface ISultanateOfOmanFormPristine {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  countryCode: boolean;
  phone: boolean;
  city: boolean;
  country: boolean;
  acceptedTermsOfUse: boolean;
}

export interface ISultanateOfOmanProps {
  logoUrl: string;
  storeId: string;
  open: boolean;
  phoneCountryCode: string;
  onClose: () => void;
}

enum EmailSentState {
  INITIAL = 'SEND',
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

enum HighlightsAboutSultanateOfOman {
  DIAL_COLOR_BLUE = 'DIAL COLOR BLUE',
  CASE_MATERIAL_BRONZE = 'CASE MATERIAL BRONZE',
  SULTANATE_OF_OMAN_ENGRAVING = 'SULTANATE OF OMAN ENGRAVING',
  BLUE_TEXTILE_STRAP = 'BLUE TEXTILE STRAP',
  LIMITATION_100_PIECES = 'LIMITATION 100 PIECES',
  I_JUST_LIKE_IT = 'I JUST LIKE IT'
}

const ANIMATION_DURATION = 300;

const SultanateOfOman = ({
  logoUrl,
  storeId,
  open,
  phoneCountryCode,
  onClose
}: ISultanateOfOmanProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [emailSent, setEmailSent] = React.useState(false);
  const [emailSentState, setEmailSentState] = React.useState<EmailSentState>(
    EmailSentState.INITIAL
  );
  const [pristine, setPristine] = React.useState<ISultanateOfOmanFormPristine>({
    firstName: true,
    lastName: true,
    email: true,
    countryCode: true,
    phone: true,
    city: true,
    country: true,
    acceptedTermsOfUse: true
  });

  const [formData, setFormData] = React.useState<ISultanateOfOmanFormData>({
    salutation: 'vb:mr',
    firstName: null,
    lastName: null,
    email: null,
    countryCode: phoneCountryCode,
    phone: null,
    city: null,
    country: null,
    ownsIwcTimepiece: null,
    iwcTimepieceNote: null,
    likeAboutSultanateOfOman: [],
    acceptedTermsOfUse: false,
    subscribeNews: false
  });

  const mandatoryFields: (keyof ISultanateOfOmanFormData)[] = [
    'firstName',
    'lastName',
    'email',
    'countryCode',
    'phone',
    'city',
    'country',
    'acceptedTermsOfUse'
  ];

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setEmailSent(false);
    }, ANIMATION_DURATION);
  };

  const handleSalutionSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event?.target;

    setFormData({ ...formData, salutation: value });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'salutation',
      form: 'SultanateOfOmanInquireForm'
    });
  };

  const handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, firstName: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'firstName',
      form: 'SultanateOfOmanInquireForm'
    });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, firstName: value });
    }
  };

  const handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, lastName: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'lastName',
      form: 'SultanateOfOmanInquireForm'
    });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, lastName: value });
    }
  };

  const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'email',
      form: 'SultanateOfOmanInquireForm'
    });
    if (isAllowedForEmail(value)) {
      setFormData({ ...formData, email: value });
    }
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, countryCode: value });
  };

  const handlePhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });
    setFormData({ ...formData, phone: value });
  };

  const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, city: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'city',
      form: 'SultanateOfOmanInquireForm'
    });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, city: value });
    }
  };

  const handleCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, country: false });
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'country',
      form: 'SultanateOfOmanInquireForm'
    });
    if (isAllowedForName(value)) {
      setFormData({ ...formData, country: value });
    }
  };

  const handleOwnsIwcTimepiece = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target;
    const value = id === 'yes' && checked ? true : false;
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'ownsIwcTimepiece',
      form: 'SultanateOfOmanInquireForm'
    });
    setFormData({ ...formData, ownsIwcTimepiece: value });
  };

  const handleIwcTimepieceNote = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'iwcTimepieceNote',
      form: 'SultanateOfOmanInquireForm'
    });
    setFormData({ ...formData, iwcTimepieceNote: value });
  };

  const handleWhatDoYouLikeAboutSultanateOfOman = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target;

    logEvent(DID_INPUT_FORM_FIELD, DID_INPUT_FORM_FIELD, {
      field: 'likeAboutSultanateOfOman',
      id,
      checked,
      form: 'SultanateOfOmanInquireForm'
    });

    if (id === HighlightsAboutSultanateOfOman.I_JUST_LIKE_IT) {
      setFormData({
        ...formData,
        likeAboutSultanateOfOman: checked
          ? [HighlightsAboutSultanateOfOman.I_JUST_LIKE_IT]
          : []
      });
      return;
    }

    const updatedLikeAboutSultanateOfOman =
      formData.likeAboutSultanateOfOman.includes(id)
        ? formData.likeAboutSultanateOfOman.filter((item) => item !== id)
        : [...formData.likeAboutSultanateOfOman, id];

    setFormData({
      ...formData,
      likeAboutSultanateOfOman: updatedLikeAboutSultanateOfOman.filter(
        (like) => like !== HighlightsAboutSultanateOfOman.I_JUST_LIKE_IT
      )
    });
  };

  const handleTC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
  };

  const handleNewsletterSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    setFormData({ ...formData, subscribeNews: value });
  };

  const removePristine = () => {
    const nonPristine: ISultanateOfOmanFormPristine = {
      firstName: false,
      lastName: false,
      email: false,
      countryCode: false,
      phone: false,
      city: false,
      country: false,
      acceptedTermsOfUse: false
    };
    setPristine(nonPristine);
  };

  const handleSend = () => {
    removePristine();
    if (mandatoryFields.some((field) => !formData[field])) {
      return;
    }
    if (
      !isValidEmail(formData.email) ||
      !isValidCountryCode(formData.countryCode) ||
      !isValidName(formData.firstName) ||
      !isValidPhoneNumber(formData.phone)
    ) {
      return;
    }
    const formattedData = {
      ...formData,
      salutation: t(formData?.salutation)
    };
    setEmailSentState(EmailSentState.SENDING);

    sendSultanateOfOmanEmail(
      formattedData,
      EnquiryType.SULTANATE_OF_OMAN,
      storeId
    )
      .then(() => {
        setEmailSentState(EmailSentState.INITIAL);
        setEmailSent(true);
        logEvent(DID_SEND_SULTANATE_OF_OMAN_EMAIL);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(
          DID_FAIL_TO_SEND_SULTANATE_OF_OMAN_EMAIL,
          DID_FAIL_TO_SEND_SULTANATE_OF_OMAN_EMAIL,
          { error }
        );
      })
      .finally(() => {
        const user = createUser(
          getIdentityId(),
          formData.firstName,
          formData.email,
          formData.countryCode,
          formData.phone
        );
        dispatch(
          actionUpdateUserAttributesAsync(user.id, {
            alias: user.alias,
            email: user.email,
            mobilePhoneCountryCode: user.mobilePhoneCountryCode,
            mobileNumber: user.mobileNumber
          })
        );
      });
  };

  const invalidFirstName =
    !pristine.firstName && !isValidName(formData?.firstName);
  const invalidLastName =
    !pristine.lastName && !isValidName(formData?.lastName);
  const invalidEmail = !pristine.email && !isValidEmail(formData?.email);
  const invalidCity = !pristine.city && !isValidName(formData?.city);
  const invalidCountry = !pristine.country && !isValidName(formData?.country);

  return (
    <div className="appointment">
      <Transition
        in={open}
        timeout={ANIMATION_DURATION}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <div
            className={`popup-container fadein ${
              emailSent ? 'successful' : ''
            } ${state}`}
            id="ap-popup"
          >
            <div className="form-container">
              <button
                id="ap-close"
                className="popup-close"
                type="button"
                onClick={() => handleClose()}
              >
                &times;
              </button>
              <div className="logo">
                <img id="LOGO" src={logoUrl} alt="LOGO" />
              </div>
              {!emailSent && (
                <form id="emailform">
                  {isArabic ? (
                    <>
                      <h2 className="no-mb">
                        {t('vb:enquiry_sultanate_of_oman_title1')}
                      </h2>
                      <h2 className="no-mt">
                        {t('vb:enquiry_sultanate_of_oman_title2')}
                      </h2>
                    </>
                  ) : (
                    <h2>{t('vb:enquiry_sultanate_of_oman_title')}</h2>
                  )}
                  <h3>{t('vb:general_information')}</h3>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:first_name')}:</label>
                      <div className="phone-number-input no-ltr">
                        <select
                          className="salutation-select"
                          value={formData?.salutation || ''}
                          onChange={handleSalutionSelect}
                        >
                          <option value="vb:mr">{t('vb:mr')}</option>
                          <option value="vb:mrs">{t('vb:mrs')}</option>
                          <option value="vb:ms">{t('vb:ms')}</option>
                        </select>
                        <input
                          type="text"
                          id="inputFirstName"
                          name="firstName"
                          value={formData?.firstName || ''}
                          placeholder={t('vb:enter_your_first_name')}
                          onChange={handleFirstName}
                        />
                      </div>
                      {invalidFirstName && (
                        <span className="error"> {t('vb:required')}</span>
                      )}
                    </div>{' '}
                    <div className="input-group">
                      <label>{t('vb:last_name')}:</label>
                      <input
                        type="text"
                        id="inputLastName"
                        name="lastName"
                        value={formData?.lastName || ''}
                        placeholder={t('vb:enter_your_last_name')}
                        onChange={handleLastName}
                      />
                      {invalidLastName && (
                        <span className="error"> {t('vb:required')}</span>
                      )}
                    </div>
                  </div>
                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('email')}:</label>
                      <input
                        type="email"
                        id="inputemail"
                        name="email"
                        placeholder={t('vb:enter_your_email')}
                        onBlur={handleEmail}
                      />
                      {invalidEmail && (
                        <span className="error">
                          {t('vb:please_enter_valid_email')}
                        </span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:phone_number')}:</label>
                      <div className="phone-number-input no-ltr">
                        <input
                          type="tel"
                          id="inputcountrycode"
                          name="countrycode"
                          placeholder="+00"
                          value={formData.countryCode}
                          onChange={handleCountryCode}
                        />
                        <input
                          type="tel"
                          id="inputphone"
                          name="phone"
                          placeholder={t('vb:enter_phone_number')}
                          onBlur={handlePhoneNumber}
                        />
                      </div>
                      {!pristine.phone &&
                        (!isValidCountryCode(formData.countryCode) ||
                          !isValidPhoneNumber(formData.phone)) && (
                          <span className="error">
                            {t('vb:please_enter_valid_phone_number')}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="two-col">
                    <div className="input-group">
                      <label>{t('vb:city')}:</label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        placeholder={t('vb:city')}
                        onBlur={handleCity}
                      />
                      {invalidCity && (
                        <span className="error">{t('vb:required')}</span>
                      )}
                    </div>
                    <div className="input-group">
                      <label>{t('vb:country')}:</label>
                      <input
                        type="text"
                        id="country"
                        name="country"
                        placeholder={t('vb:country')}
                        onChange={handleCountry}
                      />
                      {invalidCountry && (
                        <span className="error">{t('vb:required')}</span>
                      )}
                    </div>
                  </div>

                  <h3>{t('vb:inquiry_questions')}</h3>

                  <div className="input-group">
                    <label>{t('vb:do_you_own_iwc_piece')}</label>
                    <div className="input-group mt-0">
                      <div className="option-checkbox mr-5">
                        {t('vb:yes')}
                        <input
                          type="checkbox"
                          id="yes"
                          checked={formData?.ownsIwcTimepiece}
                          onChange={handleOwnsIwcTimepiece}
                        />
                      </div>
                      <div className="option-checkbox">
                        {t('vb:no')}
                        <input
                          type="checkbox"
                          id="no"
                          checked={formData?.ownsIwcTimepiece === false}
                          onChange={handleOwnsIwcTimepiece}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input-group">
                    <label>{t('vb:if_yes_which_one')}</label>
                    <textarea
                      name="iwcTimepieceNote"
                      id="iwcTimepieceNote"
                      rows={4}
                      onBlur={handleIwcTimepieceNote}
                    />
                  </div>

                  <div className="input-group">
                    <label>
                      {t('vb:what_do_you_like_about_sultanate_of_oman')}
                    </label>
                    <div className="input-group tstc no-margin-top">
                      <label>
                        <input
                          type="checkbox"
                          id={HighlightsAboutSultanateOfOman.DIAL_COLOR_BLUE}
                          checked={formData?.likeAboutSultanateOfOman.includes(
                            HighlightsAboutSultanateOfOman.DIAL_COLOR_BLUE
                          )}
                          onChange={handleWhatDoYouLikeAboutSultanateOfOman}
                        />
                        {t('vb:dial_color_blue')}
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          id={
                            HighlightsAboutSultanateOfOman.CASE_MATERIAL_BRONZE
                          }
                          checked={formData?.likeAboutSultanateOfOman.includes(
                            HighlightsAboutSultanateOfOman.CASE_MATERIAL_BRONZE
                          )}
                          onChange={handleWhatDoYouLikeAboutSultanateOfOman}
                        />
                        {t('vb:case_material_bronze')}
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          id={
                            HighlightsAboutSultanateOfOman.SULTANATE_OF_OMAN_ENGRAVING
                          }
                          checked={formData?.likeAboutSultanateOfOman.includes(
                            HighlightsAboutSultanateOfOman.SULTANATE_OF_OMAN_ENGRAVING
                          )}
                          onChange={handleWhatDoYouLikeAboutSultanateOfOman}
                        />
                        {t('vb:sultanate_of_oman_engraving')}
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          id={HighlightsAboutSultanateOfOman.BLUE_TEXTILE_STRAP}
                          checked={formData?.likeAboutSultanateOfOman.includes(
                            HighlightsAboutSultanateOfOman.BLUE_TEXTILE_STRAP
                          )}
                          onChange={handleWhatDoYouLikeAboutSultanateOfOman}
                        />
                        {t('vb:blue_textile_strap')}
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          id={
                            HighlightsAboutSultanateOfOman.LIMITATION_100_PIECES
                          }
                          checked={formData?.likeAboutSultanateOfOman.includes(
                            HighlightsAboutSultanateOfOman.LIMITATION_100_PIECES
                          )}
                          onChange={handleWhatDoYouLikeAboutSultanateOfOman}
                        />
                        {t('vb:limitation_100_pieces')}
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          id={HighlightsAboutSultanateOfOman.I_JUST_LIKE_IT}
                          checked={formData?.likeAboutSultanateOfOman.includes(
                            HighlightsAboutSultanateOfOman.I_JUST_LIKE_IT
                          )}
                          onChange={handleWhatDoYouLikeAboutSultanateOfOman}
                        />
                        {t('vb:i_just_like_it')}
                      </label>
                    </div>
                  </div>

                  <div className="input-group tstc terms-and-conditions">
                    <label>
                      <input
                        type="checkbox"
                        id="inputtstc"
                        onChange={handleTC}
                      />
                      {!pristine.acceptedTermsOfUse &&
                        !formData.acceptedTermsOfUse && (
                          <span className="error">
                            {t('vb:please_accept_tc')}
                          </span>
                        )}
                      {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
                      <PrivacyPolicy />
                      {t('vb:tc_part_three')}
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="inputsubscribe"
                        onChange={handleNewsletterSubscription}
                      />
                      {t('vb:confirm_subscribe_newsletter')}
                    </label>
                  </div>
                  <div className="input-group">
                    <button
                      id="sendform"
                      type="button"
                      onClick={() => handleSend()}
                    >
                      {t(`vb:${emailSentState}`)}
                    </button>
                    <button
                      id="ap-cancel"
                      className="popup-close"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      {t('vb:close')}
                    </button>
                  </div>
                </form>
              )}
              {emailSent && (
                <div className="success">
                  <h2>{t('vb:thank_you_for_your_request')}</h2>
                  <button
                    className="popup-close"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('vb:close')}
                  </button>
                </div>
              )}
              <PoweredByInspify />
            </div>
          </div>
        )}
      </Transition>
      <style jsx global>
        {`
          .isRtl .no-ltr {
            direction: rtl;
          }

          .isRtl .option-checkbox {
            margin-right: 0 !important;
            margin-left: 3rem !important;
          }

          .isRtl .option-checkbox input {
            margin-left: 0;
            margin-right: 10px;
          }

          .isRtl .appointment label {
            font-size: 0.9em;
          }

          .isRtl .appointment input,
          .isRtl .appointment select,
          .isRtl .appointment textarea {
            font-size: 1.1em;
          }

          .isRtl .appointment h3 {
            font-size: 1.2em;
          }

          .isRtl #inputcountrycode {
            direction: ltr;
            text-align: right;
            margin-right: 0;
            margin-left: 15px;
          }
        `}
      </style>
      <style jsx>
        {`
          .mobile {
            display: none;
          }

          .no-mb {
            margin-bottom: 0;
          }

          .no-mt {
            margin-top: 0;
          }

          .full {
            display: inline-block;
          }

          .salutation-select {
            width: 100px !important;
            margin-right: 10px;
          }

          .option-checkbox input {
            width: inherit;
            margin-left: 10px;
          }

          .no-margin-top {
            margin-top: 0 !important;
          }

          .no-margin-top label {
            padding-left: 30px;
            margin: 5px 0;
          }

          .terms-and-conditions {
            margin-top: 60px;
          }

          @media (max-width: 768px) {
            .mobile {
              display: inline-block;
            }

            .no-margin-top label {
              padding-left: 0;
            }

            .full {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SultanateOfOman;

import React from 'react';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';

const icons = `${LFAssetBaseUrl}/icons/v1`;
const Info = ({
  showWishlist,
  onMobile
}: {
  showWishlist?: boolean;
  onMobile?: boolean;
}) => {
  return (
    <div className="Info">
      <h3 className="LFTitle">INFORMATION</h3>
      <p>
        For an easier flow through this experience, please be guided by these
        instructions:
      </p>
      <div className="info-container">
        <div className="info-item">
          <div className="info-item_left">
            <img src={`${icons}/map_white.png`} />
          </div>
          <div className="info-item_right">
            <p>Access the map to view interactive areas.</p>
          </div>
        </div>
        {showWishlist && (
          <div className="info-item">
            <div className="info-item_left">
              <img src={`${icons}/favorite.png`} />
            </div>
            <div className="info-item_right">
              <p>View a list of products you’ve added to your favourites.</p>
            </div>
          </div>
        )}
        <div className="info-item">
          <div className="info-item_left">
            <img src={`${icons}/360_white.png`} />
          </div>
          <div className="info-item_right">
            <p>Interact with the virtual environment when this icon appears.</p>
          </div>
        </div>
        <div className="info-item">
          <div className="info-item_left">
            <img src={`${icons}/AR.png`} />
          </div>
          <div className="info-item_right">
            <p>
              Click to experience Augmented Reality (AR) in your physical
              surroundings.
            </p>
          </div>
        </div>

        {onMobile ? (
          <div className="info-item">
            <div className="info-item_left">
              <img src={`${icons}/finger_hover_white.png`} />
            </div>
            <div className="info-item_right">
              <p>
                Move your finger on screen to explore 3D object in the virtual
                experience.
              </p>
            </div>
          </div>
        ) : (
          <div className="info-item">
            <div className="info-item_left">
              <img src={`${icons}/cursor_hover_white.png`} />
            </div>
            <div className="info-item_right">
              <p>
                Move your mouse on screen to explore 3D object in the virtual
                experience.
              </p>
            </div>
          </div>
        )}

        <div className="info-item">
          <div className="info-item_left">
            <img src={`${icons}/back_with_cross_white.png`} />
          </div>
          <div className="info-item_right">
            <p>Go back to previous page.</p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .Info {
          text-align: center;
          color: #00ffee;
        }
        p {
          font-size: 14px !important;
        }
        .info-container {
          margin-top: 35px;
          text-align: left;
        }

        .info-item {
          display: flex;
          align-items: center;
          text-align: left;
          margin-bottom: 10px;
          flex-wrap: nowrap;
        }
        .info-item_left {
          width: 70px;
        }

        .info-item_right {
          width: calc(100% - 70px);
          padding-left: 10px;
        }

        .info-item_left img {
          width: 100%;
        }
        .info-item_left .placeholder {
          height: 50px;
          width: 50px;
          background-color: #c4c4c4;
        }
        @media (min-width: 768px) {
          .info-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
          .info-item {
            width: 45%;
            margin: 2.5%;
          }

          .info-item_left {
            width: 80px;
          }

          .info-item_right {
            width: calc(100% - 80px);
            padding-left: 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default Info;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IMainState } from './../../../interfaces';
import { last } from 'lodash';
import { actionShowWelcomeToScene } from '../../../redux/actions';

const Manufacture = ({ onClose }: { onClose: () => void }) => {
  React.useEffect(() => {
    setTimeout(() => onClose(), 0);
  }, []);
  return (
    <div>
      <img src="/asset/rdu/welcome_manufacture.png" />
      <style jsx>{`
        div {
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          -webkit-animation: fade-out 1s ease-out 3.5s both;
          animation: fade-out 1s ease-out 3.5s both;
          background: rgba(0, 0, 0, 0.4);
          display: none;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 90%;
          max-width: 800px;
          height: auto;
        }
        @-webkit-keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

const RDUSGWelcome = () => {
  const dispatch = useDispatch();
  const vb = useSelector((state: IMainState) => state.clientState?.vb);
  const open = vb?.showWelcomeToScene;
  const sceneId = last(vb?.sceneIds);
  if (!open) return null;

  if (sceneId === 'scene_pano_looby') {
    return (
      <Manufacture onClose={() => dispatch(actionShowWelcomeToScene(false))} />
    );
  }

  return null;
};

export default RDUSGWelcome;

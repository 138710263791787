import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState } from './../../../../interfaces';
import InspifyTeleportMenu from './InspifyTeleportMenu';
import InspifyTeleportSite from './InspifyTeleportSite';
import { actionTeleportAsync } from '../../../../redux/teleportAsyncAction';
import {
  actionTeleportSelectSite,
  actionUpdateLanguage
} from './../../../../redux/actions';
import Inspify3DMenu from './Inspify3DMenu';
import Common3DAnimationContextContainer from '../../../ThreejsAnimation/Common3D/Common3DContext';
import SSP3DCubeAnimation from '../../../ThreejsAnimation/Common3D/SSP3DCubeAnimation';
import { COMMON3D_ANIMATION } from '../../../../interfaces/common3D';
import { useCommon3DState } from '../../../hooks/common3D';
import { actionUpdateCommon3DActiveAnimationInPano } from '../../../../redux/customActions/common3DAction';
import CHSPSTeleportMenu from './CHSPSTeleportMenu';
import {
  chopardHappySportGenevaHQTraining,
  mercedesBenzStoreId
} from '../../../../config';
import MercedesBenzTeleportMenu from './MercedesBenzTeleportMenu';

const InspifyCustomComponent = ({ siteId }: { siteId: string }) => {
  const dispatch = useDispatch();

  const common3D = useCommon3DState();
  const { name: activeAnimation } = common3D;
  const getAnimation = () => {
    switch (activeAnimation) {
      case COMMON3D_ANIMATION:
        return <SSP3DCubeAnimation />;
      default:
        return null;
    }
  };

  const { teleportTo } = useSelector(
    (state: IMainState) => state?.clientState?.teleport || {}
  );

  const tooltip = useSelector(
    (state: IMainState) => state?.clientState?.tooltip
  );

  React.useEffect(() => {
    if (teleportTo?.siteId) {
      dispatch(actionTeleportAsync(teleportTo?.siteId));
    }
  }, [teleportTo?.siteId]);

  const shouldShowTeleportSite = () => {
    const teleportStore = teleportTo?.store?.id;
    if (!teleportStore) {
      return false;
    }
    return teleportStore !== siteId;
  };

  const getTeleportMenu = () => {
    const props = {
      siteId,
      activeSite: teleportTo?.store?.id,
      onSelectSite: (id) => {
        dispatch(actionUpdateCommon3DActiveAnimationInPano(undefined));
        dispatch(actionTeleportSelectSite(id));
        dispatch(actionUpdateLanguage('en'));
      }
    };
    switch (siteId) {
      case chopardHappySportGenevaHQTraining:
        return <CHSPSTeleportMenu {...props} />;
      case mercedesBenzStoreId:
        return <MercedesBenzTeleportMenu {...props} />;
      default:
        return <InspifyTeleportMenu {...props} />;
    }
  };
  return (
    <>
      {tooltip === 'teleportButton' && getTeleportMenu()}
      {tooltip === 'threeDButton' && <Inspify3DMenu siteId={siteId} />}
      {shouldShowTeleportSite() && (
        <InspifyTeleportSite
          config={teleportTo.config}
          store={teleportTo.store}
        />
      )}

      <Common3DAnimationContextContainer key={activeAnimation}>
        {getAnimation()}
      </Common3DAnimationContextContainer>
    </>
  );
};

export default InspifyCustomComponent;

import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { chopardHappySportGenevaHQ } from '../../../../config';
import { actionOpenTooltip } from '../../../../redux/actions';
import ToolTipWrapper from '../../../Common/ToolTipWrapper';
import { TeleportSite } from './InspifyTeleportMenu';

const metaverse: TeleportSite[] = [
  {
    label: 'Happy Sport',
    id: chopardHappySportGenevaHQ,
    type: 'pixelStream'
  }
];

const CHSPSTeleportMenu = ({
  siteId,
  activeSite,
  onSelectSite
}: {
  siteId: string;
  activeSite?: string;
  onSelectSite: (id: string) => void;
}) => {
  const dispatch = useDispatch();

  return (
    <ToolTipWrapper
      parentId="teleportButton"
      onClose={() => dispatch(actionOpenTooltip(undefined))}
      width={250}
      distanceFromParent={30}
    >
      <div className="control-menu teleport-menu">
        <div className="list-group-item list-group-item-action title">
          METAVERSE:
        </div>
        {metaverse.map((site) => (
          <button
            key={site.id}
            className="list-group-item list-group-item-action"
            onClick={() => onSelectSite(site.id)}
            disabled={activeSite === site.id}
          >
            <BiChevronRight color={activeSite === site.id ? '#fff' : '#333'} />
            {site.label}
          </button>
        ))}

        {activeSite && (
          <button
            className="list-group-item list-group-item-action text-danger"
            onClick={() => onSelectSite(siteId)}
          >
            BACK
          </button>
        )}
      </div>
      <style jsx>{`
        .title {
          letter-spacing: 2px;
          color: #999;
          font-size: 12px;
          border: none;
        }
        .title:first-child {
          margin-top: 0;
        }
        .text-danger {
          font-size: 12px;
          letter-spacing: 2px;
          border: none;
        }
        button:disabled {
          opacity: 1;
        }
      `}</style>
    </ToolTipWrapper>
  );
};

export default CHSPSTeleportMenu;

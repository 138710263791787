import LeftChevronSvgIcon from '../Icons/LeftChevronSvgIcon';
import RightChevronSvgIcon from '../Icons/RightChevronSvgIcon';
import React from 'react';
import { ITutorialStep } from '../../../interfaces';
import { logEvent } from '../../../analytics';
import { DID_SKIP_TUTORIAL_IN_FUTURE } from '../../../utils/constants';
import { useTranslation } from '../../../i18n';
import { trySetLocalStorage } from '../../../utils/window';

export interface ITutorialDetails {
  onClose: () => void;
  steps: ITutorialStep[];
  transitionState?: string;
}

const TutorialDetails = ({
  onClose,
  steps,
  transitionState
}: ITutorialDetails) => {
  const [index, setIndex] = React.useState(0);

  const { t } = useTranslation();

  if (steps.length === 0) return null;

  return (
    <div className={`howto fadein ${transitionState}`}>
      <div className="content-container">
        <button className="howto-close" onClick={() => onClose()}>
          &times;
        </button>
        <div className="content">
          <video loop autoPlay playsInline muted key={index}>
            <source src={steps[index].videoPath} type="video/mp4" />
          </video>
          <p>
            <strong>
              {t('vb:step')} {index + 1}:
            </strong>{' '}
            {steps[index].instruction}
          </p>
        </div>
        {index > 0 && (
          <button
            className="howto-nav howto-prev"
            type="button"
            onClick={() => {
              setIndex(index - 1);
            }}
          >
            <LeftChevronSvgIcon />
          </button>
        )}
        {index < steps.length - 1 && (
          <button
            className="howto-nav howto-next"
            type="button"
            onClick={() => {
              setIndex(index + 1);
            }}
          >
            <RightChevronSvgIcon />
          </button>
        )}
        <div
          className="howto-close2"
          onClick={() => {
            trySetLocalStorage('skipIntroduction', 'true');
            logEvent(DID_SKIP_TUTORIAL_IN_FUTURE);
            onClose();
          }}
        >
          <a href="#">{t('vb:skip_do_not_show_again')}</a>
        </div>
        <style jsx>
          {`
            p {
              margin: 15px 0 10px 0;
              line-height: 1.5;
            }

            .howto-close2 a {
              display: inline-block;
              line-height: 1.5;
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default TutorialDetails;

import React from 'react';
import { logEvent } from '../../../../../analytics';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';
import { useTranslation } from '../../../../../i18n';
import {
  DID_CLICK_KEEP_ME_NOTIFIED,
  DID_CLOSE_POPUP,
  DID_OPEN_POPUP
} from '../../../../../utils/constants';
import LFButton from '../Common/LFButton';
const asset = `${LFAssetBaseUrl}/icons/v1`;

const NotifBanner = ({
  onClose,
  onClickButton
}: {
  onClose: () => void;
  onClickButton: () => void;
}) => {
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setShow(false);
    setTimeout(onClose, 300);
    logEvent(DID_CLOSE_POPUP, DID_CLOSE_POPUP, {
      popup: 'Notification Banner'
    });
  };

  React.useEffect(() => {
    setShow(true);
    logEvent(DID_OPEN_POPUP, DID_OPEN_POPUP, { popup: 'Notification Banner' });
  }, []);

  return (
    <div
      className={`NotifBanner noselect ${show ? 'show-popup' : 'close-popup'}`}
    >
      <div className="popup-box">
        <div className="content-container">
          <div className="content">
            <p>{t('vb:do_you_wish_to_be_notified_of_collection')}</p>
            <LFButton
              name="YES, KEEP ME NOTIFIED"
              disabledDefaultLog
              size="sm"
              onClick={() => {
                logEvent(
                  DID_CLICK_KEEP_ME_NOTIFIED,
                  DID_CLICK_KEEP_ME_NOTIFIED
                );
                handleClose();
                onClickButton();
              }}
            >
              <span>{t('vb:keep_me_notified')}</span>
            </LFButton>
          </div>
        </div>
        <button className="btn btn-close" onClick={handleClose}>
          <img src={`${asset}/close.png`} alt="" />
        </button>
      </div>
      <style jsx>{`
        .NotifBanner {
          transition: transform 0.3s ease-in;
          z-index: 2001;
          left: 0;
          position: fixed;
          top: 20px;
          transform: translateX(-100%);
        }
        .show-popup {
          transform: translateX(0);
        }
        .close-popup {
          transform: translateX(-100%);
        }
        .content-container {
          padding: 20px 60px 20px 20px;
          font-family: 'LF-Regular', sans-serif;
          text-align: left;
          color: #fff;
        }
        .content-container :global(p) {
          font-size: 0.9em;
          letter-spacing: 0.5px;
        }
        .popup-box {
          position: relative;
          margin: auto;
          max-width: 310px;
          border-radius: 0 20px 20px 0;
          background: rgba(34, 0, 61, 0.85);
        }
        .btn-close {
          position: absolute;
          top: 10px;
          outline: none;
          box-shadow: none;
          width: 24px;
          height: 24px;
          padding: 0;
          font-size: 0;
          line-height: 1;
          right: 10px;
        }
        .btn-close img {
          width: 100%;
          height: auto;
        }
        .NotifBanner :global(.LFTitle) {
          letter-spacing: 1px;
          font-size: 1.2em;
          margin-bottom: 0;
        }
        @media (min-width: 768px) {
          .NotifBanner {
            font-size: 18px;
          }
          .NotifBanner :global(.LFTitle) {
            font-size: 1.3em;
          }
        }
      `}</style>
    </div>
  );
};

export default NotifBanner;

import { last } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { assetBaseUrl, zIndex } from '../../../../config';
import { IMainState, ViewportType } from '../../../../interfaces';
import {
  actionCloseVBPopup,
  actionDidGoToScene,
  actionUpdateMap
} from '../../../../redux/actions';
import { DID_SELECT_MAP_SPOT } from '../../../../utils/constants';
import { isUserOnMobileOnly } from '../../../../utils/deviceDetector';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import { CHScenes } from '../../PSInterface';
import { CHCloseIcon } from '../CHIcons';
import CHMapLandscapeLarge from './CHMapLandscapeLarge';
import CHMapMobile from './CHMapMobile';

export const mapAsset = `${assetBaseUrl}/chopard/chsps/map/v6`;
// export const mapAsset = `/asset/ch`;

export interface CHMapDetail {
  [key: string]: { title: string; scene: string };
}

export interface CHMapSpot {
  detail: CHMapDetail;
  command: CHScenes;
  diamondX?: number;
  diamondY?: number;
  diamondDeg?: number;
  diamondShadow?: string;
}

export const mapSpot: CHMapSpot[] = [
  {
    detail: {
      en: {
        title: 'Boutique',
        scene: `CHOPARD HAPPY SPORT -<br />COLLECTION EXPERIENCE`
      },
      ko: {
        title: 'Boutique',
        scene: `쇼파드 해피 스포츠 -<br />컬렉션`
      }
    },
    command: CHScenes.SCENE_LOBBY,
    diamondShadow: '#84ADFF',
    diamondX: 50,
    diamondY: 50
  },
  {
    detail: {
      en: {
        title: 'Chapter 1',
        scene: 'Diamonds are happier<br />when they are free'
      },
      ko: {
        title: 'Chapter 1',
        scene: '다이아몬드는 자유로울<br />때 더 행복하다'
      }
    },
    command: CHScenes.SCENE_CHAPTER1,
    diamondX: 66.5,
    diamondY: 51,
    diamondShadow: '#0C2B68'
  },
  {
    detail: {
      en: { title: 'Chapter 2', scene: 'Little Diamonds do<br />great things' },
      ko: {
        title: 'Chapter 2',
        scene: '엄청난 일을 해내는<br />작은 다이아몬드'
      }
    },
    command: CHScenes.SCENE_CHAPTER2,
    diamondX: 58.5,
    diamondY: 64,
    diamondShadow: '#C24B31'
  },
  {
    detail: {
      en: {
        title: 'Chapter 3',
        scene: 'Free Spirited Women'
      },
      ko: {
        title: 'Chapter 3',
        scene: '자유로운 여성'
      }
    },
    command: CHScenes.SCENE_CHAPTER3,
    diamondX: 41.8,
    diamondY: 64,
    diamondShadow: '#733083'
  },
  {
    detail: {
      en: {
        title: 'Chapter 4',
        scene: 'Forever Happy Sport -<br />The Endless Creativity'
      },
      ko: {
        title: 'Chapter 4',
        scene: '영원한 해피 스포츠 -<br />무한한 창의성'
      }
    },
    command: CHScenes.SCENE_CHAPTER4,
    diamondX: 33.9,
    diamondY: 51.2,
    diamondShadow: '#3CAACC'
  },
  {
    detail: {
      en: {
        title: 'Chapter 5',
        scene: 'Happy Sport The First'
      },
      ko: {
        title: 'Chapter 5',
        scene: '해피 스포츠 THE FIRST '
      }
    },
    command: CHScenes.SCENE_CHAPTER5,
    diamondX: 41.8,
    diamondY: 38,
    diamondShadow: '#DFAB24'
  },
  {
    detail: {
      en: {
        title: 'Chapter 6',
        scene: 'Happy Diamonds Jewelry'
      },
      ko: {
        title: 'Chapter 6',
        scene: '해피다이아몬드 주얼리'
      }
    },
    command: CHScenes.SCENE_CHAPTER6,
    diamondX: 58.5,
    diamondY: 38,
    diamondShadow: '#DB93BE'
  }
];

const CHMaps = ({
  onSelect,
  width,
  height,
  onClose
}: {
  onSelect: (spot: CHScenes) => void;
  width: number;
  height: number;
  onClose: () => void;
}) => {
  const [viewportType, setViewPortType] = React.useState(
    ViewportType.SM_PORTRAIT
  );
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();

  const mapState = useSelector(
    (state: IMainState) => state.clientState?.vb?.map || {}
  );
  const visitedScene = useSelector(
    (state: IMainState) => state.clientState?.vb?.sceneIds || []
  );
  const currentScene = last(visitedScene) as CHScenes;
  const activeScene = (mapState.selectedRoom || null) as CHScenes;

  const setActiveScene = (scene: CHScenes) => {
    dispatch(actionUpdateMap({ ...mapState, selectedRoom: scene }));
  };
  const handleClose = () => {
    setShow(false);
    setTimeout(() => onClose(), 1000);
  };

  const handleSelect = (s: CHScenes) => {
    handleClose();
    onSelect(s);
    logEvent(DID_SELECT_MAP_SPOT, DID_SELECT_MAP_SPOT, { scene: s });
    dispatch(
      actionDidGoToScene(
        s === CHScenes.SCENE_ENTRANCE ? CHScenes.SCENE_CHAPTER1 : s
      )
    );
    dispatch(actionCloseVBPopup());
  };

  React.useEffect(() => {
    const isPortrait = height > width;
    if (!isPortrait) {
      if (isUserOnMobileOnly()) {
        setViewPortType(ViewportType.SM_LANDSCAPE);
      } else {
        setViewPortType(ViewportType.MD_LANDSCAPE);
      }
    } else {
      setViewPortType(ViewportType.SM_PORTRAIT);
    }
  }, [width, height]);

  React.useEffect(() => {
    setShow(true);
    setActiveScene(currentScene);
  }, []);

  return (
    <div
      className={`CHMaps fixed-full d-flex flex-column ${viewportType} ${
        !show ? 'exiting' : 'entering'
      }`}
    >
      {viewportType === ViewportType.SM_PORTRAIT && (
        <CHMapMobile
          currentScene={currentScene}
          activeScene={activeScene}
          onClickSpot={handleSelect}
          setActiveScene={setActiveScene}
        />
      )}

      {viewportType === ViewportType.SM_LANDSCAPE && (
        <CHMapMobile
          activeScene={activeScene}
          onClickSpot={handleSelect}
          setActiveScene={setActiveScene}
          landscape={true}
          currentScene={currentScene}
        />
      )}

      {viewportType === ViewportType.MD_LANDSCAPE && (
        <CHMapLandscapeLarge
          activeScene={activeScene}
          onClickSpot={handleSelect}
          setActiveScene={setActiveScene}
          viewport={{ width, height }}
        />
      )}

      <div className="close-map" id="chsps-map-close-btn" onClick={handleClose}>
        <CHCloseIcon />
      </div>
      <style jsx>{`
        .close-map {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
        }
        .close-map :global(svg) {
          width: 60px;
          height: auto;
        }
        .CHMaps {
          background: rgba(0, 0, 0, 0.8);
          z-index: ${zIndex.popupPage - 2};
          opacity: 0;
          position: fixed;
          height: 100%;
        }
        :global(.MDLandscape.normal-mode) .CHMaps {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
          top: ${MDLandscapeNormalSpec.contentArea.top}px;
          height: calc(100% - ${MDLandscapeNormalSpec.contentArea.top}px);
        }
        :global(.SMLandscape.normal-mode) .CHMaps {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
          top: ${SMLandscapeNormalSpec.contentArea.top}px;
          height: calc(100% - ${SMLandscapeNormalSpec.contentArea.top}px);
        }
        :global(.MDPortrait.normal-mode) .CHMaps {
          height: calc(
            100% -
              ${MDPortraitNormalSpec.contentArea.bottom +
              MDPortraitNormalSpec.contentArea.top}px
          ) !important;
          bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
          top: ${MDPortraitNormalSpec.contentArea.top}px;
        }

        :global(.SMPortrait.normal-mode) .CHMaps {
          height: calc(
            100% -
              ${SMPortraitNormalSpec.contentArea.bottom +
              SMPortraitNormalSpec.contentArea.top}px
          ) !important;
          bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
          top: ${SMPortraitNormalSpec.contentArea.top}px;
        }
        :global(.client) .CHMaps {
          pointer-events: none;
        }
        :global(.client .slick-arrow),
        :global(.client .close-map) {
          display: none !important;
        }
        .CHMaps.SM_LANDSCAPE {
          background: #000;
        }
        .CHMaps :global(.CHMap) {
          color: #fff;
        }
        .CHMaps :global(.spot .title) {
          font-size: 0.6em;
          margin-bottom: 0.3em;
        }
        .CHMaps.MD_LANDSCAPE :global(.spot .title) {
          transform: translate(25%, -10%) scale(1.5);
        }
        .CHMaps :global(.spot .scene) {
          font-size: 0.9em;
          letter-spacing: 0.05em;
          line-height: 1.3em;
        }
        .entering {
          animation: fade 1s linear both;
        }
        .exiting {
          animation: fadeOut 1s linear both;
        }
        .close-map {
          color: #fff;
        }
        @keyframes fade {
          0% {
            opacity: 0;
            pointer-events: none;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fadeOut {
          0% {
            opacity: 1;
            pointer-events: none;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default CHMaps;

import { first } from 'lodash';
import React from 'react';
import { useTranslation } from '../../../i18n';
import { IVirtualBoutiqueConfig } from '../../../interfaces';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { isChatAvailable } from '../../../utils/stores';
import CallNowButton from './CallNowButton';
import EmailContactButton from './EmailContactButton';

const ContactButtonWithCallNow = ({
  config,
  showCallNowForMobile
}: {
  config: IVirtualBoutiqueConfig;
  showCallNowForMobile?: boolean;
}) => {
  const [showChat, setShowChat] = React.useState(false);
  const [isMobilePhone, setIsMobilePhone] = React.useState(true);
  const contact = first(config.contacts);
  const emailUsContact = first(config.appointmentContacts);

  const { t } = useTranslation();

  React.useEffect(() => {
    setShowChat(isChatAvailable(config));
    setIsMobilePhone(isUserOnMobileOnly());
  }, []);

  const callButton =
    isMobilePhone || showCallNowForMobile ? (
      <>
        {contact && (
          <>
            <span className="full callNowButton">
              <CallNowButton contact={contact}>
                <span>{t('vb:call_now')}</span>
              </CallNowButton>
            </span>
            <span className="mobile callNowButton">
              <CallNowButton contact={contact}>
                <span>{t('vb:call_now')}</span>
              </CallNowButton>
            </span>
          </>
        )}
      </>
    ) : (
      <>
        {emailUsContact && (
          <span className="emailUsButton">
            <EmailContactButton contact={contact}>
              <span>{t('vb:email_us')}</span>
            </EmailContactButton>
          </span>
        )}
      </>
    );

  const enquireButton = (
    <>
      <span className="full enquiryEmailButton">
        <EmailContactButton contact={contact}>
          <span>{t('vb:send_enquiry_email')}</span>
        </EmailContactButton>
      </span>
      <span className="mobile enquiryEmailButton">
        <EmailContactButton contact={contact}>
          <span>{t('vb:enquire')}</span>
        </EmailContactButton>
      </span>
    </>
  );
  return (
    <div className="contact-container">
      <button className="action-button">
        {showChat ? callButton : enquireButton}
      </button>
      <style global jsx>
        {`
          .contact-container {
            position: relative;
          }

          .contact-container .btn-contact a {
            color: #fff;
          }
          .contact-container .btn-contact a:hover {
            text-decoration: none;
          }
          .contact-container .mobile {
            display: none;
          }

          .contact-container .full {
            display: inline-block;
          }

          @media (max-width: 768px) {
            .contact-container .mobile {
              display: inline-block;
            }

            .contact-container .full {
              display: none;
            }
            .button-options-list a svg {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ContactButtonWithCallNow;

import { useRouter } from 'next/router';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { AiOutlineSetting } from 'react-icons/ai';
import { BsChevronCompactDown } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import {
  DevicePermissionStatus,
  IDevicePermission,
  IMainState,
  IMeetingVideo,
  IOverlay,
  MeetingRole,
  ParticipantMeetingState
} from '../../../interfaces';
import {
  actionEnterMeeting,
  actionPlayWelcomeVideoFromLounge,
  actionTeleportSelectSite,
  actionUpdateLanguage
} from '../../../redux/actions';
import {
  DID_CLICK_JOIN_NOW,
  DID_ENTER_MEETING_FROM_LOUNGE,
  DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE
} from '../../../utils/constants';
import { getMeetingRole, onMeetingOverlay } from '../../../utils/meeting';
import { getElementPosition, tryGetLocalStorage } from '../../../utils/window';
import {
  isAdvisorReadyForMeeting,
  useMeetingDebugLogger
} from '../../hooks/meeting';
import { StreamConnectionStatus } from '../../PixelStream/PSInterface';
import { MeetingContext } from '../MeetingContext';
import { allBrands } from './../../../config/index';
import { DID_CLICK_ON_HEALTH_CHECK_BUTTON } from './../../../utils/constants';
import { SessionContext } from './../../VirtualBoutique/SessionContextContainer';

const LoungeFooterActions = ({
  permissionStatus,
  joinRoom,
  showError,
  role,
  brandId,
  meetingVideo,
  hasInspirations,
  overlay,
  setBtnClicked
}: {
  permissionStatus?: IDevicePermission;
  joinRoom: () => void;
  role?: string;
  brandId?: string;
  meetingVideo?: IMeetingVideo;
  showError?: boolean;
  hasInspirations?: boolean;
  overlay?: IOverlay;
  setBtnClicked?: (btnClicked: boolean) => void;
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const skipIntro = useRouter().query.skipIntro;
  const { session } = React.useContext(SessionContext);
  const clientState = useSelector((state: IMainState) => state.clientState);
  const user = clientState?.user;
  const meeting = clientState?.meeting;
  const psState = clientState?.vb?.pixelStreaming;
  const inspirations = clientState?.inspirationsList || [];

  const psFindingServer =
    psState?.connectionStatus === StreamConnectionStatus.FINDING_SERVER;

  const psNotAvailable =
    psState?.connectionStatus === StreamConnectionStatus.SERVER_NOT_FOUND;

  const psConnectingToServer =
    psState?.connectionStatus === StreamConnectionStatus.CONNECTING;

  const shouldShowPixelStreamLayer =
    psState?.connectionStatus === StreamConnectionStatus.PAGE_LOADED;

  const isAdvisorReady = isAdvisorReadyForMeeting();

  const meetingRole = getMeetingRole(role);

  const shouldJoinRoomInstantly = !(
    meetingRole === MeetingRole.MC || meetingRole === MeetingRole.CLIENT
  );
  const [isReadyToJoin, setIsReadyToJoin] = React.useState(
    shouldJoinRoomInstantly
  );
  const [isJoining, setIsJoining] = React.useState(false);

  const { room, connection } = React.useContext(MeetingContext);
  const meetingDebugLogger = useMeetingDebugLogger();

  const shouldDisplayJoinButton =
    !isReadyToJoin &&
    (meetingRole === MeetingRole.MC || meetingRole === MeetingRole.CLIENT);

  const urlHost = allBrands.find(
    (config) => config.id === (brandId || session?.brand)
  )?.host;

  const region = session?.region ? `&region=${session.region}` : '';

  React.useEffect(() => {
    if (meeting?.languageCode) i18n.changeLanguage(meeting?.languageCode);
  }, [meeting?.languageCode]);
  React.useEffect(() => {
    if (meeting?.isPresenter && !meeting?.languageCode)
      dispatch(actionUpdateLanguage(i18n.language));
  }, [meeting?.isPresenter]);

  React.useEffect(() => {
    if (meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE) {
      room?.setDisplayName(user?.alias);
      setIsReadyToJoin(true);
      setIsJoining(false);
    }
  }, [meeting?.state]);

  const btnJoinText =
    permissionStatus?.video === DevicePermissionStatus.GRANTED
      ? t('join_now')
      : t('join_now_without_camera');
  const startWalkThroughText =
    permissionStatus?.video === DevicePermissionStatus.GRANTED
      ? t('start_walkthrough')
      : t('start_walkthrough_without_camera');

  const showAdvisorButton = meeting?.isPresenter;
  const showClientButton = !meeting?.isPresenter && shouldDisplayJoinButton;

  const advisorButtonRef = React.useRef<HTMLButtonElement>(null);
  const clientButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    const toggleDisableInputName = (disable: boolean) => {
      const inputEl = document.getElementById('lounge-name-input');
      if (inputEl) {
        disable
          ? inputEl.setAttribute('disabled', '')
          : inputEl.removeAttribute('disabled');
      }
    };

    if (showAdvisorButton || showClientButton) {
      toggleDisableInputName(false);
      if (shouldShowPixelStreamLayer) {
        setTimeout(() => {
          const buttonEl = advisorButtonRef.current || clientButtonRef.current;
          const streamEl = document.getElementById('pixelStreamWrapper');
          if (streamEl && buttonEl) {
            const { top, middleFromLeft } = getElementPosition(buttonEl);
            streamEl.style.left = `${middleFromLeft}px`;
            streamEl.style.width = `300px`;
            streamEl.style.marginLeft = `-150px`;
            streamEl.style.top = `${top}px`;
            streamEl.style.height = `50px`;
            streamEl.style.position = `absolute`;
            streamEl.style.pointerEvents = null;
          }
        }, 100);
      }
    }

    if (!meeting?.isPresenter && !shouldDisplayJoinButton) {
      toggleDisableInputName(true);
    }

    return () => {
      const streamEl = document.getElementById('pixelStreamWrapper');
      if (streamEl) {
        streamEl.style.left = null;
        streamEl.style.width = null;
        streamEl.style.marginLeft = null;
        streamEl.style.top = null;
        streamEl.style.height = null;
        streamEl.style.position = null;
        streamEl.style.pointerEvents = null;
      }
    };
  }, [
    shouldShowPixelStreamLayer,
    showAdvisorButton,
    showClientButton,
    showError,
    user?.alias,
    hasInspirations
  ]);

  return (
    <div className="lounge-footer">
      <div className="actions">
        {urlHost && (
          <a
            className="btn btn-check-device"
            href={`https://${urlHost}/meeting/healthcheck?transport=wss${region}`}
            target="_blank"
            onClick={() => {
              logEvent(DID_CLICK_ON_HEALTH_CHECK_BUTTON);
            }}
          >
            <AiOutlineSetting />
            Device Check
          </a>
        )}
        {showAdvisorButton && (
          <button
            ref={advisorButtonRef}
            disabled={!isAdvisorReady || psFindingServer || psNotAvailable}
            id="startMeeting"
            className="btn btn-dark btn-lg btn-start"
            onClick={() => {
              setBtnClicked?.(true);
              if (showError) return;

              const overlayFlag = tryGetLocalStorage(
                meeting?.meetingId + 'overlayImg'
              );
              if (
                (overlay?.initShow && !overlayFlag) ||
                overlayFlag === 'true'
              ) {
                onMeetingOverlay(dispatch, meeting, true);
              }
              if (meetingVideo?.welcomeVideoUrl && !skipIntro) {
                dispatch(actionPlayWelcomeVideoFromLounge());
                dispatch(actionTeleportSelectSite(undefined));
                meetingDebugLogger(DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE);
                logEvent(
                  DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE,
                  DID_SHOW_WELCOME_VIDEO_FROM_LOUNGE,
                  {
                    meetingId: meeting.meetingId,
                    participantId: room?.myUserId(),
                    isPresenter: meeting.isPresenter
                  }
                );
              } else {
                dispatch(actionEnterMeeting());
                meetingDebugLogger(DID_ENTER_MEETING_FROM_LOUNGE);
                logEvent(
                  DID_ENTER_MEETING_FROM_LOUNGE,
                  DID_ENTER_MEETING_FROM_LOUNGE,
                  {
                    meetingId: meeting.meetingId,
                    participantId: room?.myUserId(),
                    isPresenter: meeting.isPresenter
                  }
                );
              }
            }}
          >
            {!isAdvisorReady || psFindingServer ? (
              <Spinner animation="border" size="sm" />
            ) : (
              startWalkThroughText
            )}
          </button>
        )}

        {showClientButton && (
          <button
            ref={clientButtonRef}
            id="joinMeetingButton"
            disabled={
              isJoining || !connection || psFindingServer || psNotAvailable
            }
            className="btn btn-dark btn-lg btn-start btn-client"
            onClick={() => {
              setBtnClicked?.(true);
              if (showError) return;

              meetingDebugLogger(DID_CLICK_JOIN_NOW);
              logEvent(DID_CLICK_JOIN_NOW, DID_CLICK_JOIN_NOW, {
                meetingId: meeting.meetingId,
                participantId: room?.myUserId(),
                isPresenter: meeting.isPresenter
              });
              setIsJoining(true);
              joinRoom();
            }}
          >
            {isJoining || !connection || psFindingServer ? (
              <Spinner animation="border" size="sm" />
            ) : (
              btnJoinText
            )}
          </button>
        )}
      </div>

      {psFindingServer && <div>{t('pleaseWait')}</div>}
      {psConnectingToServer && <div>{t('connecting')}</div>}
      {psNotAvailable && <div>{t('experience_not_available')}</div>}

      <div className="client-note">
        {!meeting?.isPresenter && !shouldDisplayJoinButton && (
          <p>{t('meeting_will_start_shortly')}</p>
        )}

        {!!inspirations.length && (
          <a href="#inspirationsSection">
            <BsChevronCompactDown />
          </a>
        )}
      </div>
      <style jsx>
        {`
          .lounge-footer {
            margin-top: 15px;
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
          }

          .btn {
            max-width: 100%;
            margin-bottom: 10px;
            min-width: 250px;
            word-break: break-word;
          }

          .btn:focus {
            outline: 0;
            box-shadow: none;
          }

          .btn-check-device {
            border-radius: 100px;
            background: #ffffff;
            color: #8d8d8d;
            border: 1px solid #8d8d8d;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
          }

          :global(.btn-check-device > svg) {
            margin-right: 10px;
          }

          .btn-start {
            border-radius: 100px;
            background: #131313;
          }

          .client-note p {
            margin-top: 15px;
            margin-bottom: 0;
          }
          .client-note :global(svg) {
            width: 50px;
            height: auto;
          }

          .client-note a {
            color: #000;
          }

          @media screen and (orientation: landscape) and (max-height: 767px) {
            .lounge-footer {
              margin-top: 10px;
            }
            .actions {
              flex-direction: row;
            }
            .btn {
              min-width: 250px;
              margin-bottom: 0;
            }
            .btn-check-device {
              margin-right: 5px;
            }

            .client-note p {
              margin-top: 10px;
              margin-bottom: 0;
            }
          }

          @media (min-width: 768px) and (min-height: 520px) {
            .lounge-footer {
              margin-top: 28px;
            }

            p {
              font-size: 0.875em;
              line-height: 1.188em;
            }
          }

          @media (min-width: 1023px) {
            p {
              font-size: 1em;
              line-height: 1.375em;
            }

            .actions {
              flex-direction: row;
            }
            .btn {
              min-width: 300px;
              margin-bottom: 0;
            }
            .btn-check-device {
              margin-right: 10px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoungeFooterActions;

import React from 'react';
import { useTranslation } from '../../i18n';
import { IVirtualBoutiqueShopContact } from '../../interfaces';
import { getShopNowForm } from '../storeComponentFactory';
import Cart from './Cart';

interface Props {
  shopContacts: IVirtualBoutiqueShopContact[];
  storeId: string;
  phoneCountryCode: string;
  deliveryFee?: number;
}

const ShoppingCartPage = ({
  shopContacts,
  storeId,
  phoneCountryCode,
  deliveryFee = 0
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-7 cart-container">
          <div className="position-sticky">
            <h2>{t('vb:order_request')}</h2>
            <Cart deliveryFee={deliveryFee} storeId={storeId} />
          </div>
        </div>
        <div className="col col-12 col-lg-5">
          {getShopNowForm(shopContacts, storeId, phoneCountryCode, deliveryFee)}
        </div>
      </div>
      <style jsx>{`
        .container {
          padding-left: 0;
          padding: right;
        }
        :global(.appointment) h2 {
          text-align: left;
        }
        :global(.isRtl .appointment) h2 {
          text-align: right;
        }
        @media (min-width: 992px) {
          .cart-container {
            padding-right: 40px;
            padding-top: 8px;
          }
          .position-sticky {
            top: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default ShoppingCartPage;


import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';
import { EmitType, PSConfigCommand } from './PSInterface';

const TestCommandButton = ({
  sendCommand,
  onReconnect,
  interactions
}: {
  sendCommand: (descriptor: any, type: EmitType) => void;
  onReconnect: () => void;
  interactions?: string[];
}) => {
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState<EmitType>(EmitType.UIInteraction);
  const [command, setCommand] = React.useState('');
  const handleSendCommand = (command) => {
    sendCommand(command, type);
  };

  const psState = useSelector(
    (state: IMainState) => state.clientState?.vb?.pixelStreaming
  );

  const sendInteractionCommand = (command) => {
    sendCommand(command, EmitType.UIInteraction);
  };

  const sendConfigCommand = (command) => {
    sendCommand(command, EmitType.Config);
  };

  const config = [
    PSConfigCommand.ENABLE_AFK,
    PSConfigCommand.DISABLE_AFK,
    PSConfigCommand.SHOW_GRID,
    PSConfigCommand.HIDE_GRID,
    PSConfigCommand.SHOW_CONFIG,
    PSConfigCommand.SHOW_VERSION,
    PSConfigCommand.DISCONNECT
  ];

  return (
    <div className="btn-commands">
      {show && (
        <>
          <div className="list-group text-left">
            <div className="list-group-item">
              {psState?.server?.host || 'null'}
            </div>
            {interactions && (
              <>
                <strong className="list-group-item heading">
                  Interaction:
                </strong>
                {interactions.map((c) => (
                  <button
                    key={c}
                    onClick={() => sendInteractionCommand(c)}
                    className="list-group-item list-group-item-action"
                  >
                    {c}
                  </button>
                ))}
              </>
            )}

            <strong className="list-group-item heading">Config:</strong>
            {config.map((c) => (
              <button
                key={c}
                onClick={() => sendConfigCommand(c)}
                className="list-group-item list-group-item-action"
              >
                {c === PSConfigCommand.SHOW_VERSION ? 'PS Version' : c}
              </button>
            ))}
            <button
              onClick={onReconnect}
              className="list-group-item list-group-item-action"
            >
              RECONNECT
            </button>
          
            <div className="list-group-item list-group-item-action">
              <div>Custom:</div>
              <select
                className="form-control"
                value={type}
                onChange={(e) => setType(e.target.value as EmitType)}
              >
                <option value={EmitType.UIInteraction}>Interaction</option>
                <option value={EmitType.Config}>Config</option>
              </select>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Command"
                value={command}
                onChange={(e) => setCommand(e.target.value)}
              />
              <button
                onClick={() => handleSendCommand(command)}
                className="btn btn-sm btn-secondary"
              >
                Send Command
              </button>
            </div>
          </div>
        </>
      )}

      <button className="btn btn-dark" onClick={() => setShow(!show)}>
        {show ? 'Hide Commands' : 'Show Commands'}
      </button>
      <style jsx>{`
        .btn-commands {
          width: 200px;
          position: fixed;
          left: 100px;
          bottom: 0;
          z-index: 1000000;
        }
        .list-group {
          max-height: 90vh;
          overflow: auto;
        }
        .btn {
          width: 100%;
        }
        .heading {
          font-weight: bold;
          background: #ccc;
        }
      `}</style>
    </div>
  );
};

export default TestCommandButton;

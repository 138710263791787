import React from 'react';

import { logClickButton, logEvent } from '../../analytics';
import { isUserOniOS, isUserOnMobileOnly } from '../../utils/deviceDetector';
import {
  exitFullScreen,
  isFullScreen,
  requestFullScreen
} from '../../utils/window';

const DID_FULLSCREEN = 'DID_FULLSCREEN';
const REQUEST_FULLSCREEN_ERROR = 'REQUEST_FULLSCREEN_ERROR';
const EXIT_FULLSCREEN = 'EXIT_FULLSCREEN';
const EXIT_FULLSCREEN_ERROR = 'EXIT_FULLSCREEN_ERROR';

const MinimizeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
  >
    <path d="M52 28.375H40.9024V17" stroke="currentColor" strokeWidth="3" />
    <path
      d="M17.8537 28.375H28.9512V17"
      stroke="currentColor"
      strokeWidth="3"
    />
    <path d="M52 40.625H40.9024V52" stroke="currentColor" strokeWidth="3" />
    <path d="M17 40.625H28.0976V52" stroke="currentColor" strokeWidth="3" />
  </svg>
);

const MaximizeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
  >
    <path d="M43.1087 17H53V25.7022" stroke="currentColor" strokeWidth="3" />
    <path d="M27.8913 17H18V25.7022" stroke="currentColor" strokeWidth="3" />
    <path d="M43.1087 52H53V43.2978" stroke="currentColor" strokeWidth="3" />
    <path d="M27.8913 52H18V43.2978" stroke="currentColor" strokeWidth="3" />
  </svg>
);

const FullScreenButton = ({
  onToggle,
  showInIos = false,
  maximizeIcon = MaximizeIcon,
  minimizeIcon = MinimizeIcon,
  className = '',
  size = 40
}: {
  onToggle?: (shouldFullScreen: boolean, isIos: boolean) => void;
  showInIos?: boolean;
  maximizeIcon?: React.ReactNode;
  minimizeIcon?: React.ReactNode;
  className?: string;
  size?: number;
}) => {
  const [show, setShow] = React.useState(false);
  const [isIphone, setIsIphone] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false);

  const goToFullScreen = () => {
    onToggle?.(true, isIphone);
    if (isIphone) return;
    requestFullScreen({
      onSuccess: () => {
        logEvent(DID_FULLSCREEN, DID_FULLSCREEN);
      },
      onError: (e) => {
        setShow(false);
        logEvent(REQUEST_FULLSCREEN_ERROR, REQUEST_FULLSCREEN_ERROR, {
          error: e
        });
      }
    });
  };

  const exitFromFullScreen = () => {
    onToggle?.(false, isIphone);
    if (isIphone) return;
    exitFullScreen({
      onSuccess: () => {
        logEvent(EXIT_FULLSCREEN, EXIT_FULLSCREEN);
      },
      onError: (e) => {
        logEvent(EXIT_FULLSCREEN_ERROR, EXIT_FULLSCREEN_ERROR, {
          error: e
        });
      }
    });
  };

  const toggleFullScreen = () => {
    if (isFullScreen()) {
      logClickButton('exit-fullscreen');
      exitFromFullScreen();
    } else {
      logClickButton('fullscreen');
      goToFullScreen();
    }
  };

  React.useEffect(() => {
    const inIphone = isUserOniOS() && isUserOnMobileOnly();
    setIsIphone(inIphone);
    if (showInIos) {
      setShow(true);
    } else {
      setShow(!inIphone);
    }
    //listen if user is in fullscreen mode or not
    const handleFullScreenChange = () => {
      setFullScreen(isFullScreen());
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  if (!show) return null;

  return (
    <span
      className={`fsButton noselect ${className}`}
      onClick={toggleFullScreen}
    >
      {fullScreen ? minimizeIcon : maximizeIcon}
      <style jsx>{`
        .fsButton {
          cursor: pointer;
          color: white;
        }
        .fsButton :global(svg) {
          width: ${size}px;
          height: auto;
        }
      `}</style>
    </span>
  );
};

export default FullScreenButton;

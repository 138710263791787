import React from 'react';
import LocalVideo from '../MeetingVideos/LocalVideo';

const LoungeLocalVideo = ({ mute }: { mute?: boolean }) => {
  return (
    <div className="lounge-local-video">
      <LocalVideo />
      <style jsx>
        {`
          .lounge-local-video :global(.video-thumbnail) {
            overflow: hidden;
            height: 120px;
            position: relative;
            background: #333;
          }
          .lounge-local-video :global(.video-thumbnail),
          .lounge-local-video :global(.video-thumbnail video) {
            border-radius: 10px;
            width: 100%;
          }
          .lounge-local-video :global(.video-thumbnail video) {
            opacity: ${mute ? 0 : 1};
          }

          .lounge-local-video :global(.video-thumbnail .initialName) {
            font-family: Arial;
            font-weight: bold;
            font-size: 40px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            align-items: center;
            justify-content: center;
            color: #fff;
            opacity: 0.3;
            display: flex;
          }
          .lounge-local-video :global(.indicators-container.with-shadow) {
            display: none;
          }
          .lounge-local-video :global(.video-thumbnail video) {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media (min-width: 540px) {
            .lounge-local-video {
              height: 100%;
              width: 50%;
            }
            .lounge-local-video :global(.video-thumbnail) {
              height: 140px;
            }
          }
          @media (min-width: 900px) {
            .lounge-local-video :global(.video-thumbnail) {
              height: 180px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoungeLocalVideo;

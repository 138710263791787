import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { IMainState, IProduct, WishlistType } from '../../../interfaces';
import {
  actionAddProductToCompare,
  actionShowProductDescription
} from '../../../redux/actions';
import { DID_ADD_PRODUCT_TO_COMPARE } from '../../../utils/constants';
import { currentLanguage } from '../../../utils/language';
import {
  getProductDescription,
  getProductSpecificationsByLang,
  getProductSpecsConfigForSameType
} from '../../../utils/product';
import Link from '../../Common/Link';
import WishlistButton from '../../Wishlist/WishlistButton';
import { renderSpec } from '../ProductSpecifications';

const ChopardDetailSpecifications = ({ product }: { product: IProduct }) => {
  const [showContact, setShowContact] = React.useState(false);

  const onToggleContact = () => {
    setShowContact(!showContact);
  };

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = currentLanguage(i18n);
  const showDetails = useSelector(
    (state: IMainState) => state.clientState?.productDetails?.showDescription
  );
  const storeId = useSelector(
    (state: IMainState) => state.clientState?.store?.id
  );

  const isKorean = language === 'ko';

  const description = getProductDescription(product, language);
  const specifications = getProductSpecificationsByLang(
    product,
    language,
    storeId
  );
  const specsConfig = getProductSpecsConfigForSameType(product, storeId);
  const hasSpecItem = (key: string) =>
    specifications?.[key] ||
    (Array.isArray(specifications?.[key]) && specifications?.[key].length);
  const detailSpecConfig = specsConfig?.filter(
    (c) => hasSpecItem(c?.specs) && specifications[c?.specs]?.length
  );

  React.useEffect(() => {
    dispatch(actionShowProductDescription(true));
  }, []);

  const showDetail = (open: boolean) => {
    dispatch(actionShowProductDescription(open));
  };

  return (
    <>
      {showContact ? (
        <div className="contact-us hide-in-meeting">
          <p className="chopard-content">{t('contact_us')}</p>
          {isKorean ? (
            <>
              <p className="contact-us-phone-number">
                전화번호 : +82 (2) 6905-3390
              </p>
              <p className="contact-us-address">
                주소 : 서울특별시 압구정동 515 갤러리아 백화점 EAST B1F, 쇼파드
                부티크
              </p>
            </>
          ) : (
            <>
              <p className="contact-us-phone-number">
                Phone Number : +82 (2) 6905-3390
              </p>
              <p className="contact-us-address">
                Address: CHOPARD BOUTIQUE SEOUL GALLERIA EAST <br />
                Galleria East B1F, 515 Apgujung-dong, Gangnam-Gu <br />
                135-898 Seoul South Korea
              </p>
            </>
          )}

          <div className="compare-wishlist-row noselect">
            <div
              className="compare-wishlist-child btn noselect"
              onClick={onToggleContact}
            >
              <span>{t('back')}</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="compare-wishlist-row noselect hide-for-client-meeting">
            <div
              className="compare-wishlist-child btn "
              onClick={() => {
                dispatch(
                  actionAddProductToCompare({
                    id: product.id,
                    type: product.type,
                    brandId: product?.brandId
                  })
                );
                logEvent(DID_ADD_PRODUCT_TO_COMPARE, product.id);
              }}
            >
              <span>
                <Link
                  href={`/compare/[id]`}
                  as={`/compare/${product.brandId}`}
                >
                  {t('compare_product')}
                </Link>
              </span>
            </div>
            <div className="space hide-for-client-meeting">&nbsp;&nbsp;</div>
            <div className="compare-wishlist-child btn noselect hide-for-client-meeting">
              <WishlistButton
                item={{
                  itemId: product.id,
                  itemType: WishlistType.PRODUCT
                }}
                addLabel={t('add_to_wishlist')}
                successLabel={t('in_my_wishlist')}
                showIcon={false}
              />
            </div>
            <div className="space hide-for-client-meeting">&nbsp;&nbsp;</div>
            <div
              className="compare-wishlist-child btn noselect hide-in-meeting"
              onClick={onToggleContact}
            >
              <span>{t('contact_us')}</span>
            </div>
          </div>
          <br className="hide-for-client-meeting" />
          <Row className="product-title noselect">
            <Col xs={6} className="tab-chsps" onClick={() => showDetail(true)}>
              {t('description')}
              {showDetails && <hr className="hr-select" />}
            </Col>
            <Col xs={6} className="tab-chsps" onClick={() => showDetail(false)}>
              {t('specifications')}
              {!showDetails && <hr className="hr-select" />}
            </Col>
          </Row>
          <div className="hr-plane">
            {showDetails && (
              <div className="detail-container descriptions-container noselect">
                {description?.includes('\n')
                  ? description
                      .split('\n')
                      .map((d, i) => <p key={`${d}-${i}`}>{d}</p>)
                  : description}
              </div>
            )}
            {!showDetails && (
              <div className="detail-container specifications-container noselect">
                {detailSpecConfig &&
                  detailSpecConfig.map((c) =>
                    hasSpecItem(c.specs) && specifications[c.specs]?.length ? (
                      <div key={c.title} className="specifications-details">
                        <h4>{t(c.title)}</h4>
                        <ul>
                          {renderSpec(
                            product.type,
                            specifications[c.specs],
                            c.specs
                          )}
                        </ul>
                      </div>
                    ) : null
                  )}
              </div>
            )}
          </div>
        </>
      )}
      <style jsx global>{`
        .contact-us .contact-us-phone-number,
        .contact-us .contact-us-address {
          font-size: 0.85rem;
          line-height: 1.8;
        }
        .detail-container {
          padding: 20px 0 30px;
          line-height: 1.8;
          text-align: justify;
        }
        .compare-wishlist-row {
          display: flex;
          flex-direction: row;
          flex-shrink: 0;
          font-weight: 300;
        }
        .compare-wishlist-child {
          width: 100%;
          text-align: center;
        }
        .btn :global(a) {
          color: inherit;
        }
        .btn {
          border: 1px solid #000;
          margin: inherit;
          margin-bottom: 10px;
          text-transform: uppercase;
          border-radius: 0;
        }
        .product-title {
          border-bottom: 1px solid #ccc;
          margin-bottom: 10px;
        }
        .tab-chsps {
          text-align: center;
          text-transform: uppercase;
        }
        .hr-plane {
          transform: translateY(0px);
          text-align: left;
        }
        .hr-select {
          background-color: #000;
          border: 1px solid #000;
          width: 50%;
          margin-bottom: 0;
        }
        h2 {
          font-size: 22px;
          text-align: center;
          width: 100%;
          margin: 0;
        }

        h4 {
          font-size: 14px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        .specifications-details {
          margin-bottom: 20px;
        }

        .specifications-details:last-child {
          margin-bottom: 0;
        }

        .specifications-details ul {
          padding: 0;
          list-style-type: none;
        }

        li {
          color: #666;
        }

        .specifications-details :global(.other-spec span) {
          display: block;
          text-transform: capitalize;
          font-weight: 500;
          font-size: 1em;
          padding-top: 15px;
        }

        .specifications-details :global(.sub-specs) {
          padding: 20px;
          border: 1px solid #efefef;
          margin-top: 10px;
          border-radius: 5px;
        }
        .specifications-details :global(.sub-specs:nth-child(odd)) {
          background: #f7f7f7;
        }
        .specifications-details :global(.sub-spec-item) {
          padding: 5px 0;
          list-style-type: none;
        }

        .specifications-details :global(.sub-spec-item > span:first-child) {
          text-transform: capitalize;
          font-weight: 500;
          display: block;
        }
        .contact-us {
          padding-bottom: 30px;
        }
        .chopard-content {
          text-transform: uppercase;
        }

        @media (max-width: 576px) {
          .compare-wishlist-row {
            flex-direction: column;
          }
          .space {
            display: none;
          }
          .hr-plane {
            font-size: 0.85rem;
            transform: translateY(0px);
          }
          .product-title {
            font-size: 0.85rem;
          }
          .btn {
            font-size: 0.85rem;
          }
        }

        @media (max-width: 768px) {
          h2 {
            font-size: 18px;
          }
          .specifications-details {
            width: 100%;
            margin-bottom: 10px;
            padding: 0;
          }
        }
        @media (max-height: 600px) and (orientation: landscape) {
          .hr-plane {
            font-size: 0.65rem;
            transform: translateY(0px);
          }
          .product-title {
            font-size: 0.65rem;
          }
          .btn {
            font-size: 0.65rem;
          }
        }
      `}</style>
    </>
  );
};

export default ChopardDetailSpecifications;

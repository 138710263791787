import { IVirtualBoutiqueContact } from '../../../interfaces';
import { logEvent } from '../../../analytics';
import { DID_CLICK_CONTACT } from '../../../utils/constants';

export const logClickContact = (
  channel: string,
  contact: IVirtualBoutiqueContact
) => {
  logEvent(DID_CLICK_CONTACT, channel, {
    channel,
    ...contact
  });
};
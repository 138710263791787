import { isNaN } from 'lodash';
import React from 'react';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { failedToLoadImagePlaceholder } from '../../../../config';
import { useTranslation } from '../../../../i18n';
import { IProduct } from '../../../../interfaces';
import {
  actionDidSelectProduct,
  actionUpdateShowingDescriptionForProductId
} from '../../../../redux/actions';
import {
  DID_CLICK_PRODUCT_SIZE_BUTTON,
  DID_CLOSE_PRODUCT_DESCRIPTION,
  DID_VIEW_PAGE,
  DID_VIEW_PRODUCT_DESCRIPTION
} from '../../../../utils/constants';
import { PageView } from '../../../../utils/pageView';
import {
  getProductDisplayName,
  getProductDisplayPrice
} from '../../../../utils/product';
import { parsePlainTextToRichText } from '../../../../utils/string';
import {
  isShowingDescriptionForProduct,
  useProductState
} from '../../../hooks/product';
import Slider from '../../../Product/Slider';
import { actionFetchProductByIdAsync } from './../../../../redux/productAsyncAction';
import LoubiAirwaysWishlist from './Buttons/LJLikeButton';
import LJPopupWrapper from './LJPopupWrapper';
import AddToReservationButton from './Reservation/AddToReservationButton';

export const getLJProductOption = (product: IProduct) => {
  const spec =
    product?.watchSpecifications?.JP || product?.watchSpecifications?.EN;
  const optionsField = spec?.featuresSpecs?.find((item) =>
    item.includes('Sizerun')
  );
  const optionsArr = optionsField
    ?.split(':')?.[1]
    ?.split(',')
    ?.map((size) => size?.trim())
    ?.filter((size) => !!size);


  return {
    title: 'SIZE OVERVIEW',
    options: optionsArr || []
  };
};

const LJProductDetails = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const dispatch = useDispatch();

  const { selectedProduct: product, selectedProductId } = useProductState();
  const [selectedOption, setSelectedOption] = React.useState<string>();

  const isShowingDescription =
    isShowingDescriptionForProduct(selectedProductId);

  React.useEffect(() => {
    if (!product && selectedProductId) {
      dispatch(actionFetchProductByIdAsync(selectedProductId));
      logEvent(DID_VIEW_PAGE, DID_VIEW_PAGE, {
        pageType: PageView.PRODUCT,
        productId: selectedProductId
      });
    }
    setSelectedOption(null);
  }, [product, selectedProductId]);

  if (!product) return null;

  const spec =
    product?.watchSpecifications?.JP || product?.watchSpecifications?.EN;

  const price = getProductDisplayPrice(product, 'JP');

  const { title: optionTitle, options } = getLJProductOption(product);

  const heightSpec = spec?.featuresSpecs
    ?.find((item) => item.includes('height'))
    ?.split(':')?.[1]
    ?.trim()
    ?.replace('mm', '')
    ?.replace('MM', '');

  const volumeSpec = product?.watchSpecifications?.EN?.featuresSpecs
    ?.find((item) => item.includes('olume'))
    ?.split(':')?.[1]
    ?.trim();

  const heading = (
    <>
      <h1>{getProductDisplayName(product, language)}</h1>
      {heightSpec && (
        <h3>
          {heightSpec}
          {!isNaN(Number(heightSpec)) ? 'mm' : ''}
        </h3>
      )}
    </>
  );

  return (
    <LJPopupWrapper
      maxWidth={1200}
      className="product-popup"
      onClose={() => {
        dispatch(actionDidSelectProduct(undefined));
      }}
    >
      <div className="product-details">
        <div className="row">
          <div className="col col-12 col-lg-7 order-lg-2">
            <div className="heading show-m hide-d">{heading}</div>
            <div className="img-container">
              {product.imageUrls[0] ? (
                <Slider key={product.id} imageUrls={product.imageUrls} />
              ) : (
                <img src={failedToLoadImagePlaceholder} />
              )}
            </div>
          </div>
          <div className="col col-12 col-lg-5 order-lg-1">
            <div className="heading hide-m show-d">{heading}</div>

            {spec?.description && (
              <p className="desc">
                {parsePlainTextToRichText(spec.description)}
              </p>
            )}

            <p className="price">
              {price}
              {!price?.includes('request') && <span>incl. tax</span>}
            </p>

            <div className="spec">
              <h4
                onClick={() => {
                  if (isShowingDescription) {
                    dispatch(
                      actionUpdateShowingDescriptionForProductId(undefined)
                    );
                    logEvent(
                      DID_CLOSE_PRODUCT_DESCRIPTION,
                      DID_CLOSE_PRODUCT_DESCRIPTION,
                      {
                        product: selectedProductId
                      }
                    );
                  } else {
                    dispatch(
                      actionUpdateShowingDescriptionForProductId(
                        selectedProductId
                      )
                    );
                    logEvent(
                      DID_VIEW_PRODUCT_DESCRIPTION,
                      DID_VIEW_PRODUCT_DESCRIPTION,
                      {
                        product: selectedProductId
                      }
                    );
                  }
                }}
              >
                DESCRIPTION{' '}
                {isShowingDescription ? (
                  <RiArrowDownSLine className="right" color="#bf080e" />
                ) : (
                  <RiArrowRightSLine className="right" color="#bf080e" />
                )}
              </h4>

              {isShowingDescription && (
                <>
                  <div>
                    <strong>Reference:</strong>
                    {` `}
                    {product?.modelCode}
                  </div>
                  {spec?.color && (
                    <div>
                      <strong>Color:</strong>
                      {` `}
                      {spec.color}
                    </div>
                  )}
                  {spec?.material && (
                    <div>
                      <strong>Material:</strong>
                      {` `}
                      {spec.material}
                    </div>
                  )}
                  {volumeSpec && (
                    <div>
                      <strong>Volume:</strong>
                      {` `}
                      {volumeSpec}
                    </div>
                  )}
                </>
              )}
            </div>

            {optionTitle && options.length !== 0 && (
              <div className="options">
                {optionTitle && <h4>{optionTitle}</h4>}
                {options.map((option) => (
                  <button
                    className={`btn btn-option ${
                      selectedOption === option ? 'active' : ''
                    }`}
                    key={option}
                    onClick={() => {
                      setSelectedOption(option);
                      logEvent(
                        DID_CLICK_PRODUCT_SIZE_BUTTON,
                        DID_CLICK_PRODUCT_SIZE_BUTTON,
                        {
                          product: product.id,
                          size: option
                        }
                      );
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}

            <div className="actions">
              <div className="like-button">
                <LoubiAirwaysWishlist
                  product={{
                    id: product.id,
                    modelCode: product.modelCode,
                    modelName: product.modelName
                  }}
                />
              </div>
              <AddToReservationButton
                buttonSize="large"
                product={product}
                productSize={selectedOption}
                disabled={!selectedOption && !!options?.length}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        
        .react-multiple-carousel__arrow,
        .react-multiple-carousel__arrow:hover {
          color: #bf080e !important;
          background: rgba(255, 255, 255, 0.4) !important;
        }

        .react-multi-carousel-dot button {
          border-color: #bf080e;
          border-width: 1px;
        }

        .react-multi-carousel-dot--active button {
          background: #bf080e;
        }
        @media (max-width: 400px) {
          .product-popup .container {
            width: 100% !important;
          }
        }
        @media (min-width: 991px){
          .react-multi-carousel-list img{
            width:100%;
            height:auto;
          }
        }
      `}</style>
      <style jsx>{`
        .img-container {
          position: relative;
        }
        .product-image {
          width: 100%;
        }
        .product-details {
          color: #346168;
          text-align: left;
        }
        .like-button {
          position: relative;
          display: block;
          width: 100%;
          border-radius: 20px;
          height: 70px;
        }
        .product-details :global(.favorite-button) {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          border: 2px solid #bf080e;
          background: #bf080e;
          border-radius: 20px;
          padding: 10px;
          height: 48px;
          display: flex;
          transform: none;
          align-items: center;
          justify-content: center;
        }

        .product-details :global(.remove-from-wishlist) {
          background: transparent !important;
        }

        .product-details :global(.favorite-button img) {
          width: 56px;
        }

        .product-details :global(.favorite-button p) {
          margin: 0 0 0 10px;
          font-family: 'Noto Sans JP', sans-serif;
          font-size: 0.85rem;
        }

        .product-details :global(.btn-reservation) {
          width: 100%;
        }

        .product-details :global(h1),
        .price,
        .spec strong,
        h4,
        .btn {
          font-family: 'AntiqueOlive-Bold', Arial;
        }
        h2,
        .spec div {
          font-family: 'AntiqueOlive', Arial;
        }
        .spec div {
          font-size: 0.9em;
        }
        .desc {
          font-family: 'Noto Sans JP', sans-serif;
          font-size: 1em;
        }
        .spec h4 {
          cursor: pointer;
        }
        .price span {
          display: block;
          font-size: 0.7em;
          font-family: 'AntiqueOlive', Arial;
        }
        .product-details :global(h1),
        .product-details :global(h3),
        h4,
        .btn-option {
          color: #bf080e;
        }
        .product-details :global(h3) {
          font-size: 1.7em;
        }
        .btn-option {
          border: 2px solid #bf080e;
        }

        .btn-option.active {
          background-color: #bf080e;
          color: #fff;
        }

        .product-details :global(h1) {
          margin-bottom: 0;
          font-size: 2.2em;
        }
        .heading {
          margin-bottom: 2em;
        }
        .spec,
        .options {
          margin-top: 1.5em;
        }
        .actions {
          margin-top: 2em;
        }
        .btn-option {
          margin: 0 0.3em 0.3em 0;
        }
        .hide-d {
          display: none;
        }
        .show-d {
          display: block;
        }
        @media (min-width: 1000px) {
          .product-details {
            padding: 0 50px 40px;
          }
        }
        @media (max-width: 990px) {
          .hide-m {
            display: none;
          }
          .show-m {
            display: block;
          }
          .heading {
            font-size: 12px;
          }
          h4 {
            font-size: 1em;
          }
          .desc {
            font-size: 0.8em;
          }
          .img-container {
            margin-bottom: 30px;
          }
        }
      `}</style>
    </LJPopupWrapper>
  );
};

export default LJProductDetails;

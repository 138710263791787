import { GiJourney } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionOpenVBJourney,
  actionEnableVBJourney
} from '../../redux/actions';
import { logEvent } from '../../analytics';
import { DID_ENABLE_JOURNEY_GUIDE } from '../../utils/constants';
import { IMainState } from '../../interfaces';

const ShowJourneyGuideButton = ({ entitled }: { entitled?: boolean }) => {
  const dispatch = useDispatch();

  const showJourneyGuide = useSelector(
    (state: IMainState) => !state.clientState.disableBoutiqueJourney
  );

  if (!entitled) {
    return null;
  }

  return (
    <div
      className="show-guide with-shadow"
      onClick={() => {
        dispatch(actionEnableVBJourney());
        dispatch(actionOpenVBJourney());
        logEvent(DID_ENABLE_JOURNEY_GUIDE);
      }}
    >
      <GiJourney />

      <style global jsx>
        {`
          .show-guide {
            color: #fff;
            background: rgba(0, 0, 0, 0.6);
            position: fixed;
            top: 50%;
            padding: 10px 20px 10px 30px;
            border-radius: 10px;
            left: ${showJourneyGuide ? '-100px' : '-15px'};
            cursor: pointer;
            transform: translateY(-50%);
            transition: all 0.3s ease-in;
          }
          .show-guide svg {
            cursor: pointer;
            width: 28px;
            height: 28px;
          }

          @media (max-width: 768px) {
            .show-guide {
              padding: 8px 15px 8px 20px;
            }

            .show-guide svg {
              cursor: pointer;
              width: 24px;
              height: 24px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ShowJourneyGuideButton;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { LFPopupContent } from '../../../../interfaces/loubifuture';
import {
  actionLFPlaySoundEffect,
  actionOpenLFPopup
} from '../../../../redux/customActions/loubiFuture';
import { DID_CLICK_AR_BUTTON } from '../../../../utils/constants';
import { isUserOniOS } from '../../../../utils/deviceDetector';
import { MDGlobalSpec } from '../../../Meeting/MeetingLayout/MDGlobal';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { MDPortraitNormalSpec } from '../../../Meeting/MeetingLayout/MDPortraitNormal';
import { SMGlobalSpec } from '../../../Meeting/MeetingLayout/SMGlobal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { SMPortraitNormalSpec } from '../../../Meeting/MeetingLayout/SMPortraitNormal';
import {
  ARIcon,
  ecume,
  flatcrfiesta,
  flatSueClfMshNapLamSand,
  getARUrlByProductId,
  getLFProductById,
  hasColorOptions
} from '../../../ThreejsAnimation/loubiFuture/assets';
import NonCockpitModeOnlyElements from '../../NonCockpitModeOnlyElements';
import LFARPopup from './Common/LFARPopup';
import FavButton from './Favorites/FavButton';

const titleAsset = `${LFAssetBaseUrl}/product/title_v4`;
const icons = `${LFAssetBaseUrl}/icons/v1`;

let timeOut;
const LFProductDetailOverlay = ({
  productId,
  showGuide,
  showWishlist,
  isOnMobileOrTablet
}: {
  productId?: string;
  showGuide?: boolean;
  showWishlist?: boolean;
  isOnMobileOrTablet?: boolean;
  isInMeeting?: boolean;
}) => {
  const dispatch = useDispatch();
  const [isOniOS, setIsOniOS] = React.useState(false);
  const [arUrl, setArUrl] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [openAR, setOpenAR] = React.useState(false);
  const [animatedTitle, setAnimatedTitle] = React.useState(false);
  const hasColor = hasColorOptions(productId);
  const product = getLFProductById(productId);

  const badge =
    productId === ecume.id
      ? `${LFAssetBaseUrl}/product/badge_v1/US.png`
      : productId === flatcrfiesta.id
      ? `${LFAssetBaseUrl}/product/badge_v1/APAC.png`
      : productId === flatSueClfMshNapLamSand.id
      ? `${LFAssetBaseUrl}/product/badge_v1/EMEI.png`
      : null;

  React.useEffect(() => {
    setIsOniOS(isUserOniOS());
    if (timeOut) {
      clearTimeout(timeOut);
      setAnimatedTitle(false);
    }
    if (productId) {
      const url = getARUrlByProductId(productId);
      setArUrl(url);

      setTimeout(() => {
        setAnimatedTitle(true);
      }, 100);

      timeOut = setTimeout(() => {
        setAnimatedTitle(false);
      }, 7600);

      setTimeout(() => {
        setShow(true);
      }, 1400);
    }
    if (!productId) {
      setShow(false);
    }
  }, [productId]);

  const productUsdzUrl = product?.arPath + '.usdz';

  const iosSupportsAr = () => {
    const a = document.createElement('a');
    return a.relList.supports('ar');
  };

  const openArInQuickLook = () => {
    const a = document.createElement('a');
    a.setAttribute('rel', 'ar');
    const img = document.createElement('img');
    a.appendChild(img);
    a.setAttribute('href', productUsdzUrl);
    a.click();
  };

  const iosButton = (
    <button
      className="btn arButton glow"
      onClick={() => {
        logEvent(DID_CLICK_AR_BUTTON, DID_CLICK_AR_BUTTON, {
          productId
        });
        dispatch(actionLFPlaySoundEffect({ arClick: true }));
        openArInQuickLook();
      }}
    >
      <img src={ARIcon(productId)} />
    </button>
  );

  const defaultButton = (
    <button
      className="btn arButton glow"
      onClick={() => {
        logEvent(DID_CLICK_AR_BUTTON, DID_CLICK_AR_BUTTON, {
          productId
        });
        dispatch(actionLFPlaySoundEffect({ arClick: true }));
        isOnMobileOrTablet
          ? setOpenAR(true)
          : dispatch(actionOpenLFPopup(LFPopupContent.QRCODE));
      }}
    >
      <img src={ARIcon(productId)} />
    </button>
  );

  const shouldUseIosAr = isOniOS && productUsdzUrl && iosSupportsAr();

  const arButton = () => {
    if (shouldUseIosAr) {
      return iosButton;
    }
    return defaultButton;
  };

  return (
    <>
      <div
        className={`product-detail product-${productId} noselect ${
          show ? 'show' : 'hide'
        }`}
      >
        <div className="fav-ar-container">
          {showWishlist && <FavButton productId={productId} />}
          {arButton()}
        </div>
        <div
          className={`ar-title-container  ${animatedTitle ? 'animated' : ''}`}
        >
          <div className="title-box with-shadow">
            <img
              src={`${titleAsset}/${productId}.png`}
              alt={product.modelName}
              className="prod-title"
            />
            {badge && <img src={badge} className="exclusive" />}
          </div>
        </div>
      </div>

      <NonCockpitModeOnlyElements>
        {showGuide && (
          <div
            className={`cursor-guide fixed-full noselect content-middle with-shadow ${
              show ? 'show' : 'hide'
            }`}
          >
            <img
              src={`${icons}/${
                isOnMobileOrTablet
                  ? 'finger_hover_white.svg'
                  : 'cursor_hover_white.svg'
              }`}
            />
          </div>
        )}
      </NonCockpitModeOnlyElements>
      {openAR && (
        <LFARPopup
          arUrl={arUrl}
          onClose={() => {
            setOpenAR(false);
          }}
        />
      )}
      <style jsx global>{`
        .color-container {
          display: ${hasColor ? 'block' : 'none'};
        }

        .close-product,
        .color-container {
          opacity: ${show ? 1 : 0};
          transition: opacity 0.3s ease-in;
        }

        .close-product {
          position: fixed;
          margin: 0 auto;
          padding: 0;
          top: 10px;
          right: 0;
          outline: none !important;
          box-shadow: none !important;
        }
        .in-meeting.SM .close-product {
          top: ${SMGlobalSpec.logoSectionHeight + 10}px;
        }
        .in-meeting.MD .close-product {
          top: ${MDGlobalSpec.logoSectionHeight + 10}px;
        }
        .close-product img {
          height: 7vh;
          width: auto;
        }
        @media (min-width: 1400px) {
          .close-product {
            left: 50%;
            right: auto;
            top: 4vh;
            transform: translateX(600px);
          }
        }
      `}</style>
      <style jsx>{`
        .product-detail {
          z-index: 20;
          position: fixed;
          transition: opacity 0.3s ease-in;
        }
        .hide {
          opacity: 0;
        }
        .show {
          opacity: 1;
        }
        .fav-ar-container {
          position: fixed;
          left: 50%;
          transform: translateX(-50%);
          top: 4vh;
          display: flex;
        }

        :global(.in-meeting.SM) .fav-ar-container {
          top: calc(4vh + ${SMGlobalSpec.logoSectionHeight}px);
        }

        :global(.in-meeting.MD) .fav-ar-container {
          top: calc(4vh + ${MDGlobalSpec.logoSectionHeight}px);
        }
        :global(.in-meeting.MDLandscape) .fav-ar-container {
          left: calc(50% + ${MDLandscapeNormalSpec.contentArea.left / 2}px);
        }
        :global(.in-meeting.SMLandscape) .fav-ar-container {
          left: calc(50% + ${SMLandscapeNormalSpec.contentArea.left / 2}px);
        }

        .ar-title-container {
          text-align: center;
          color: #ddbc55;
          position: fixed;
          left: 0;
          right: 0;
          bottom: ${hasColor ? `calc(3vh + 60px)` : '3vh'};
          font-size: 24px;
          line-height: 1;
          font-weight: bold;
          margin: auto;
          width: 100%;
          pointer-events: none;
          opacity: 0;
        }

        :global(.in-meeting.MDLandscape) .ar-title-container {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
          bottom: ${hasColor ? `120px` : '60px'};
          width: calc(100% - ${MDLandscapeNormalSpec.contentArea.left}px);
        }

        :global(.in-meeting.MDPortrait) .ar-title-container {
          bottom: ${hasColor
            ? `${MDPortraitNormalSpec.contentArea.bottom + 120}px`
            : `${MDPortraitNormalSpec.contentArea.bottom + 60}px`};
        }

        :global(.in-meeting.SMLandscape) .ar-title-container {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
          bottom: ${hasColor ? `60px` : '10px'};
          width: calc(100% - ${SMLandscapeNormalSpec.contentArea.left}px);
        }

        :global(.in-meeting.SMPortrait) .ar-title-container {
          bottom: ${hasColor
            ? `${SMPortraitNormalSpec.contentArea.bottom + 120}px`
            : `${MDPortraitNormalSpec.contentArea.bottom + 60}px`};
        }

        .animated {
          opacity: 1;
        }
        .title-box {
          clear: both;
          position: relative;
        }
        .ar-title-container img {
          width: 100%;
        }

        :global(.in-meeting.SMLandscape) .ar-title-container img {
          width: 50%;
        }

        .btn {
          outline: none !important;
          box-shadow: none !important;
        }
        .product-detail :global(.arButton) {
          outline: none;
          box-shadow: none;
        }
        .product-detail :global(.arButton img) {
          height: 14vh;
          width: auto;
          max-height: 100px;
        }
        .product-c3c2306d-fccd-4346-9211-eaacf79f791c :global(.arButton img) {
          max-height: 80px;
        }
        .show-d {
          display: none;
        }
        .show-m {
          display: block;
        }

        .cursor-guide {
          position: fixed;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          pointer-events: none;
        }

        .cursor-guide img {
          width: 100px;
          height: auto;
          -webkit-animation: slide 2s linear 1.4s both;
          animation: slide 2s linear 1.4s both;
        }
        .product-583abe39-21ea-48df-acfd-a7143d1b4a51 .prod-title,
        .product-9b55e50e-3277-4365-abf5-84dba79a6b25 .prod-title {
          transform: translateX(-5vw);
        }
        .product-988916f1-bdac-4708-85e0-1bb86ea6c111 .prod-title {
          transform: translateX(-6vw);
        }
        .exclusive {
          height: 6vw;
          width: auto !important;
          position: absolute;
          top: 0;
          right: 10px;
        }

        @-webkit-keyframes slide {
          0% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
          }
          50% {
            -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
          }
          80% {
            opacity: 1;
          }
          100% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 0;
          }
        }
        @keyframes slide {
          0% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
          }
          50% {
            -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
          }
          80% {
            opacity: 1;
          }
          100% {
            -webkit-transform: translateX(100px);
            transform: translateX(100px);
            opacity: 0;
          }
        }

        @-webkit-keyframes fade-in {
          0% {
            opacity: 0;
          }
          7%,
          93% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          7%,
          93% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        @media (min-width: 768px) {
          .cursor-guide img {
            width: 150px;
            height: auto;
          }
          .show-m {
            display: none;
          }
          .show-d {
            display: block;
          }
          .ar-title-container {
            max-width: 600px;
          }
          .product-detail :global(.arButton img) {
            height: 13vh;
            max-height: 134px;
          }
          .product-c3c2306d-fccd-4346-9211-eaacf79f791c :global(.arButton img) {
            height: 12vh;
          }
          .exclusive {
            height: 40px;
          }
          .product-583abe39-21ea-48df-acfd-a7143d1b4a51 .prod-title {
            transform: translateX(-29px);
          }
          .product-988916f1-bdac-4708-85e0-1bb86ea6c111 .prod-title {
            transform: translateX(-42px);
          }
          .product-9b55e50e-3277-4365-abf5-84dba79a6b25 .prod-title {
            transform: translateX(-40px);
          }
        }
        @media (min-width: 840px) {
          .ar-title-container {
            bottom: ${hasColor ? `calc(3vh + 60px)` : '3vh'};
          }
        }
      `}</style>
    </>
  );
};

export default LFProductDetailOverlay;

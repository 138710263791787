export const COMMON3D_ANIMATION = 'COMMON3D';
export interface ICommon3DState {
  name?: string;
  animationState?: ICommon3DAnimationState;
}
export enum REMOTE_ACTION {
  FOCUS = 'FOCUS',
  UN_FOCUS = 'UN_FOCUS',
  MOUSE_CONTROL = 'MOUSE_CONTROL',
  DETAIL = 'DETAIL'
}
export interface ITransformValue {
  position?: number[];
  rotation?: number[];
  scale?: number[];
}
export interface ICommonProductDetail {
  id?: string;
  name?: string;
  code?: string;
  subName?: string;
  subCode?: string;
}
export interface ICommon3DAnimationState {
  flag?: REMOTE_ACTION;
  focusedModel?: ITransformValue;
  modelDetail?: ICommonProductDetail;
}

export const TouchType = {
  touchDown: 'touchDown',
  touchMove: 'touchMove',
  touchUp: 'touchUp',
  pinchStart: 'pinchStart',
  pinchMove: 'pinchMove',
  wheel: 'wheel',
};

export interface ICanvasTouchState {
  type?: string;
  scale?: number;
  isPointerDown?: boolean;
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IProduct } from '../../interfaces';
import { getProductDisplayName, getProductEdition } from '../../utils/product';
import Placeholder from '../Placeholder';
import EyeballTimeLogger from '../EyeballTimeLogger';
import Header from '../Header';
import { PageView } from '../../utils/pageView';
import { useTranslation } from '../../i18n';
import { PotmContent } from './mockData';
import { parsePlainTextToRichText } from '../../utils/string';
import { ImDiamonds } from 'react-icons/im';
import { currentLanguage } from '../../utils/language';
import Link from '../Common/Link';

export interface IPickedProductProp {
  product: IProduct;
  content: PotmContent;
}

const PickedProduct = ({ product, content }: IPickedProductProp) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const language = currentLanguage(i18n);
  const { imageUrls, modelCode } = product;
  const edition = getProductEdition(product, language);
  const title = content.title[language] || content.title['en'] || '';
  const description =
    content.description[language] || content.description['en'] || '';
  const pickedAppearance = content.appearance;

  return (
    <>
      <Header
        title={t('pick_of_the_month').toUpperCase()}
        shareTitle={`${t(
          'pick_of_the_month'
        ).toUpperCase()}: ${getProductDisplayName(product, language)}`}
        shareImage={product.imageUrls.length > 0 && product.imageUrls[0]}
        type={PageView.PICK_OF_THE_MONTH}
        id={product.id}
      />
      <EyeballTimeLogger
        tracker={{ view: PageView.PICK_OF_THE_MONTH, id: product.id }}
      >
        {isLoading && <Placeholder />}
        <div className="section potm-section">
          <div className="container content-container">
            <Row className="align-items-center">
              <Col className="col col-12 col-lg-4" />
              <Col className="col col-12 col-lg-4 text-center">
                <Link
                  href="/products/[id]"
                  as={`/products/${product.id}`}
                  onClick={() => setIsLoading(true)}
                >
                  <img
                    className="img-fluid"
                    src={imageUrls[0]}
                    alt={getProductDisplayName(product, language)}
                  />
                </Link>
              </Col>
              <div className="content col col-12 col-lg-4">
                <div className="potm-title">
                  <i>{title}</i>
                  <div className="title-separator">
                    <ImDiamonds />
                  </div>
                </div>
                <p className="modelcode">{modelCode}</p>
                {edition && <p className="product-edition">{edition}</p>}
                <h1 className="title">
                  {getProductDisplayName(product, language)}
                </h1>
                <div className="desc">
                  {parsePlainTextToRichText(description)}
                </div>
              </div>
            </Row>
          </div>
        </div>
      </EyeballTimeLogger>
      <style jsx>
        {`
          .section {
            background: ${pickedAppearance?.bgColor || '#000'};
            color: ${pickedAppearance?.textColor || '#fff'};
          }
          .container {
            background-image: url(${pickedAppearance?.bgImageMobile || ''});
            background-size: 100% auto;
            background-position: center top;
            background-repeat: no-repeat;
            padding-top: 140px;
            padding-bottom: 30px;
          }
          .potm-title {
            margin-top: 20px;
            display: inline-block;
          }

          :global(.isRtl .potm-page .potm-title i) {
            font-style: normal !important;
            font-size: 1.2em;
          }
          .title-separator {
            background: #fff;
            height: 1px;
            margin: 20px 0;
            position: relative;
          }
          .title-separator :global(svg) {
            height: 20px;
            transform: translate(-50%, -50%) rotate(90deg);
            position: absolute;
          }

          .title {
            font-weight: normal;
            text-transform: uppercase;
            font-size: 1.1em;
            margin: 12px 0 20px;
            line-height: 1.4;
            font-weight: bold;
          }

          :global(.isRtl .potm-page .title) {
            font-size: 1.4em;
          }

          .modelcode {
            letter-spacing: 1.5px;
            font-size: 1.1em;
            margin-bottom: 4px;
          }

          :global(.isRtl .potm-page .modelcode) {
            font-size: 0.9em;
          }
          .desc :global(p:first-child) {
            font-size: 0.9em;
            font-style: italic;
            text-align: center;
            letter-spacing: 0.5px;
            font-weight: 200;
          }

          :global(.isRtl .desc p:first-child) {
            font-style: normal;
          }

          :global(.isRtl .potm-page .desc p:first-child) {
            font-size: 1em;
            font-family: Helvetica;
            font-weight: 200;
            line-height: 1.2;
            direction: rtl;
          }

          .content {
            text-align: center;
          }
          :global(cite) {
            display: block;
            font-size: 0.8em;
          }
          :global(cite + cite) {
            font-size: 0.7em;
          }

          :global(.isRtl .potm-section cite + cite) {
            font-size: 0.6em;
            direction: rtl;
          }
          .img-fluid {
            margin: auto;
            max-width: 290px;
            width: 100%;
            height: auto;
          }

          @media (min-width: 768px) {
            .img-fluid {
              max-width: 500px;
            }
          }

          @media (min-width: 992px) {
            .img-fluid {
              width: auto;
            }
            .potm-title {
              font-size: 1em;
            }
            :global(.isRtl .potm-page .potm-title i) {
              font-style: normal !important;
              font-size: 1em;
            }
            .title {
              font-size: 0.9em;
            }

            .container {
              background-image: url(${pickedAppearance?.bgImage || ''});
              padding-top: 0;
              padding-bottom: 0;
              background-position: -20px top;
              background-size: auto 100%;
            }
            .img-fluid {
              max-width: none;
              margin-top: -110px;
              margin-bottom: -86px;
              height: 520px;
              margin-left: 50%;
              transform: translateX(-50%);
            }
            .section {
              margin-top: 40px;
              margin-bottom: 20px;
            }
          }

          @media (min-width: 1200px) {
            .potm-title {
              font-size: 1.1em;
            }
            :global(.isRtl .potm-section) {
              font-size: 20px;
            }

            .title {
              font-size: 1em;
            }

            .img-fluid {
              height: 590px;
            }
          }
        `}
      </style>
    </>
  );
};

export default PickedProduct;

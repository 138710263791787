import React from 'react';
import { RiPlayCircleLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { assetBaseUrl } from '../../../../config';
import {
  actionLFPlaySoundEffect,
  actionReplayMusicVideo
} from '../../../../redux/customActions/loubiFuture';
import {
  DID_SKIP_VIDEO,
  DID_START_VIDEO,
  VIDEO_PAUSED
} from '../../../../utils/constants';
import { isUserOnWeChat } from '../../../../utils/deviceDetector';
import { useLoubiFutureState } from '../../../hooks/loubiFuture';
import { useMeetingLocalAudio } from '../../../hooks/meeting';
import { musicVideoUrl } from '../../../ThreejsAnimation/loubiFuture/assets';

const LFStandaloneMusicVideo = ({
  isOnMobile,
  disableSkip
}: {
  isOnMobile?: boolean;
  disableSkip?: boolean;
}) => {
  const videoRef = React.useRef<ReactPlayer>();
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [paused, setPaused] = React.useState(false);
  const [isWeChat, setIsWeChat] = React.useState(false);
  const show = useLoubiFutureState().replayMusicVideo;

  const { muteAudioAndRestoreLater } = useMeetingLocalAudio();

  const dispatch = useDispatch();

  const removeVideo = () => {
    dispatch(actionReplayMusicVideo(false));
  };

  const muteBackgroundSound = () => {
    dispatch(actionLFPlaySoundEffect({ muted: true }));
  }; 
  
  React.useEffect(() => {
    setIsWeChat(isUserOnWeChat());
  }, []);

  if (!show) return null;
  return (
    <div className="loubi">
      {
        <>
          <ReactPlayer
            ref={videoRef}
            playing={isPlaying}
            width="100%"
            height="100%"
            autoPlay
            playsinline
            url={musicVideoUrl}
            controls={isOnMobile || isWeChat}
            onPause={() => {
              setPaused(true);
              logEvent(VIDEO_PAUSED, VIDEO_PAUSED, {
                second: videoRef.current?.getCurrentTime(),
                videoUrl: musicVideoUrl
              });
            }}
            onPlay={() => {
              setPaused(false);
            }}
            onStart={() => {
              logEvent(DID_START_VIDEO, DID_START_VIDEO, {
                videoUrl: musicVideoUrl
              });
              muteAudioAndRestoreLater();
              muteBackgroundSound();
            }}
            onEnded={() => {
              removeVideo();
            }}
          />
          {paused && isPlaying && (
            <button
              className="btn-play with-shadow"
              onClick={() => {
                setIsPlaying(false);
                setTimeout(() => {
                  setIsPlaying(true);
                }, 100);
              }}
            >
              <RiPlayCircleLine color="#fff" />
            </button>
          )}
        </>
      }
      {!disableSkip && (
        <img
          src={`${assetBaseUrl}/loubifuture/icons/v1/skip_mv_white.svg`}
          alt="skip"
          onClick={() => {
            logEvent(DID_SKIP_VIDEO, DID_SKIP_VIDEO, {
              video: 'Welcome Video'
            });
            setTimeout(() => {
              removeVideo();
            }, 100);
          }}
          className="bottom-right"
        />
      )}
      <style jsx>{`
        .btn-light {
          position: fixed;
          bottom: 20px;
          right: 20px;
          opacity: 0.5;
        }
        .loubi {
          z-index: 11001;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background: black;
          display: flex;
          justify-content: center;
          align-item: center;
          flex-direction: column;
          opacity: 1;
        }
        .bottom-right {
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 80px;
          height: 80px;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .bottom-right {
            bottom: 35px;
          }
        }
        .loubi.disappearing {
          -webkit-animation: fade-out 1.5s ease-out 1s both;
          animation: fade-out 1.5s ease-out 1s both;
        }
        video {
          width: 100%;
        }
        .input-container {
          max-width: 350px;
          margin: 0 auto;
        }
        .input-group {
          border: 4px solid #efefef;
          border-radius: 10px;
        }
        .alert {
          padding: 2px;
          border-radius: 10px;
        }

        .loubi :global(svg) {
          position: fixed;
          bottom: 20px;
          left: 20px;
          fill: #fff;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .btn-play {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: transparent;
        }

        .btn-play :global(svg) {
          width: 150px;
          height: auto;
          pointer-events: none;
          position: relative;
          bottom: 0;
          left: 0;
        }

        @-webkit-keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default LFStandaloneMusicVideo;

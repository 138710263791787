import { format } from 'date-fns';
import {
  IShoppingFormData,
  IShoppingCartItemDisplay,
  IShoppingFormPayload,
  DeliveryMethod,
  ContactMethod
} from '../../interfaces';

import { CartTotalPrice } from '../../utils/shoppingCart';
import { generateV4UUID } from '../../utils/identityGenerator';
import { enc } from '../../utils/crypt';
import { getIdentityId } from './../../utils/identity';
import httpClient from '../../clientSideServices/http';
import { i18n } from '../../i18n';
import { drawI18nt } from '../../utils/language';
export const mapCartItemsToItemsPayload = (
  items: IShoppingCartItemDisplay[]
) => {
  return items.map((item) => ({
    currency: item.currencyCode,
    itemPrice: item.price,
    productId: item.id,
    quantity: item.quantity,
    totalPrice: item.totalPrice
  }));
};

export const getPreferredContact = (preferred: ContactMethod) => {
  if (preferred === ContactMethod.CALL) return drawI18nt(i18n, 'vb:call');
  if (preferred === ContactMethod.TEXT)
    return drawI18nt(i18n, 'vb:sms_whatsapp');
  if (preferred === ContactMethod.EMAIL) return drawI18nt(i18n, 'email');
};

export const mapFormToPayload = (
  formData: IShoppingFormData,
  items: IShoppingCartItemDisplay[],
  totalPrice: CartTotalPrice,
  storeId: string,
  languageCode: string
): IShoppingFormPayload => {
  const payload: IShoppingFormPayload = {
    acceptedTermsOfUse: !!formData.acceptedTermsOfUse,
    address: formData.address,
    shopNowContactId: formData.shopNowContact.id,
    cartItems: mapCartItemsToItemsPayload(items),
    createdAt: '',
    createdBy: getIdentityId(),
    currency: totalPrice.currency,
    date: formData.date ? format(formData.date, 'dd MMM, yyyy') : null,
    deliveryFee: totalPrice.deliveryFee
      ? totalPrice.formattedDeliveryFee
      : drawI18nt(i18n, 'vb:free'),
    email: formData.email,
    id: generateV4UUID(),
    firstName: formData.firstName,
    lastName: formData.lastName,
    phone: `${formData.phoneCountryCode} ${formData.phone}`,
    preferredContact: getPreferredContact(formData.preferredContact),
    preferredDelivery:
      formData.preferredDelivery === DeliveryMethod.PICKUP
        ? `${drawI18nt(i18n, 'vb:pickup_at')} ${
            formData.shopNowContact?.nameKey
              ? drawI18nt(i18n, `vb:${formData.shopNowContact.nameKey}`)
              : formData.shopNowContact.name
          }`
        : drawI18nt(i18n, 'vb:delivery'),
    storeId: storeId,
    subscribeNews: !!formData.subscribeNews,
    subTotal: totalPrice.subTotal,
    time: formData?.time,
    totalPrice: totalPrice.total,
    languageCode
  };

  if (formData.preferredDelivery === DeliveryMethod.PICKUP) {
    delete payload.address;
  }
  if (!formData.time) {
    delete payload.time;
  }
  if (!formData.date) {
    delete payload.date;
  }

  return payload;
};

export const sendShoppingCart = (
  formData: IShoppingFormData,
  items: IShoppingCartItemDisplay[],
  totalPrice: CartTotalPrice,
  storeId: string,
  languageCode = 'EN'
) => {
  const body: IShoppingFormPayload = mapFormToPayload(
    formData,
    items,
    totalPrice,
    storeId,
    languageCode
  );
  return shoppingCartService(body);
};

const shoppingCartService = (body: IShoppingFormPayload) => {
  const email = {
    id: body.id,
    content: enc(JSON.stringify(body), body.id)
  };
  return httpClient.post('/api/sendShoppingCart', email);
};

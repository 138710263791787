import React from 'react';
import ChopardHappySportFullScreenVideo from '../ChopardHappySportCustomComponent/ChopardHappySportFullScreenVideo';

export const ChopardHSKoreanCustomComponent = ({
  sendPSCommand
}: {
  sendPSCommand: (command: string) => void;
}) => {
  return (
    <>
      <ChopardHappySportFullScreenVideo
        shouldAbsolute={true}
        onClose={() => sendPSCommand('BACK')}
        showMuteButton={true}
      />
    </>
  );
};

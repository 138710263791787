import { EmitType, PSConfigCommand } from '../PSInterface';

export const sendCommandToPS = (descriptor: any, type?: EmitType) => {
  const iframeEl = document.getElementById('ps-iframe') as HTMLIFrameElement;
  const preferredType = type || EmitType.UIInteraction;
  if (iframeEl) {
    iframeEl.contentWindow.postMessage(
      { type: preferredType, descriptor },
      '*'
    );
  }
};

export const sendTimezoneInfo = () => {
  sendCommandToPS('TIMEZONE_INFO');
};

export const sendVoyageModeInfo = () => {
  sendCommandToPS('VOYAGE_MODE');
};

export const disconnectExperience = () => {
  sendCommandToPS(PSConfigCommand.DISCONNECT, EmitType.Config);
};

export const disablePSIdle = () => {
  sendCommandToPS(PSConfigCommand.DISABLE_AFK, EmitType.Config);
};

export const enablePSIdle = () => {
  sendCommandToPS(PSConfigCommand.ENABLE_AFK, EmitType.Config);
};

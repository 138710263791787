import { assetBaseUrl } from '../../../config';
export const modelWorkshopEnvironmentImagePath = `${assetBaseUrl}/loubifuture/hdr/v1/interior.hdr`;
export const animationAssetsBaseUrl = `${assetBaseUrl}/animation/streaming_cube/`;
export const sspProModelPath =
  animationAssetsBaseUrl + 'pro/v2/Pro_210917.gltf';
export const sspProMaxModelPath =
  animationAssetsBaseUrl + 'pro_max/v1/GLTF_ProMax.gltf';
export const cloudsPath = animationAssetsBaseUrl + 'clouds.png';
export const animationTextUrl = `${animationAssetsBaseUrl}text/`;
export const SSPHotspotButton = {
  fullHDFrontCamera:`${animationTextUrl}front_camera.png`,
  presenterLedLight:`${animationTextUrl}front_led_light.png`,
  touchScreenDisplay:`${animationTextUrl}13inch_touchscreen.png`,
  microphone:`${animationTextUrl}integrated_microphone.png`,
  onOffButton:`${animationTextUrl}on_off_button.png`,
  productTray:`${animationTextUrl}product_tray.png`,
  videoEncoder:`${animationTextUrl}4k_camera.png`,
  speakers:`${animationTextUrl}speakers.png`,
  frontLightConfigButton:`${animationTextUrl}front_light_config.png`,
  trayLightConfigButton:`${animationTextUrl}tray_light_config.png`,
};

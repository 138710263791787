const ShareSvgIcon = ({ classname }: { classname: string }) => (
  <svg
    className={classname}
    height="24px"
    width="24px"
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    x="0px"
    y="0px"
  >
    <g data-name="Layer 2">
      <path d="M18,10a4,4,0,1,0-3.91-3.16L9,9.38a4,4,0,1,0,0,5.24l5.09,2.55A4.06,4.06,0,1,0,15,15.38L9.91,12.84a3.89,3.89,0,0,0,0-1.67L15,8.62A4,4,0,0,0,18,10Zm0,6a2,2,0,1,1-2,2A2,2,0,0,1,18,16ZM6,14a2,2,0,1,1,2-2A2,2,0,0,1,6,14ZM18,4a2,2,0,1,1-2,2A2,2,0,0,1,18,4Z"></path>
    </g>
  </svg>
);

export default ShareSvgIcon;

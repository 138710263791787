import { IWishlistItem } from '.';

export enum LoubiFutureScene {
  ArpoadorA1 = 'ArpoadorA1',
  ArpoadorA2 = 'ArpoadorA2',
  ArpoadorViewAdjustment = 'ArpoadorViewAdjustment',
  ArpoadorToViewingroom = 'arpoadorToViewingroom',
  ViewingRoom = 'ViewingRoom',
  ViewingroomToArpoador = 'viewingroomToArpoador'
}

export enum ROOM_STATUS {
  NOT_START = 'NOT_START',
  LOADING = 'LOADING',
  READY = 'READY'
}

export const touchType = {
  touchDown: 'touchDown',
  touchMove: 'touchMove',
  touchUp: 'touchUp'
};
export enum REMOTE_ACTION {
  MOUSE_CONTROL = 'MOUSE_CONTROL',
  PRODUCT = 'DOMMOVE',
  FOCUS = 'FOCUS',
  CURRENT = 'CURRENT',
  UN_FOCUS = 'UN_FOCUS',
  COLOR_CHANGE = 'COLOR_CHANGE'
}

export enum LFPopupContent {
  QRCODE = 'QRCODE',
  INFO = 'INFO',
  GET_NOTIFIED = 'GET_NOTIFIED',
  RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION',
  LEAVE_PROMPT = 'LEAVE_PROMPT',
  MAP = 'MAP',
  ARPOADOR_INFO = 'ARPOADOR_INFO',
  LANGUAGE = 'LANGUAGE'
}

export interface ILoubiFutureWishlist {
  id?: string;
  identityId?: string;
  description?: string;
  name?: string;
  store?: string;
  brand?: string;
  phone?: string;
  email?: string;
  acceptedTermsOfUse?: boolean;
  wishlistItems?: IWishlistItem[];
  createdAt?: string | Date;
  modifiedAt?: string | Date;
  surname?: string;
  status?: string;
  region?: string;
  open?: boolean;
  dontShowPopup?: boolean;
}

export interface IAnimationProduct {
  id?: string;
  modelCode?: string;
  modelName?: string;
  modelUrl?: string;
  lowpolyModelUrl?: string;
  ambientLight?: number;
  directionalLight?: number;
  hdrSavedVersion?: string;
  colorImgUrl?: string;
  arPath?: string;
  mapTextureUrl?: string;
  normalTextureUrl?: string;
  metallicTextureUrl?: string;
}
export interface ObjectValue {
  position?: number[];
  rotation?: number[];
  scale?: number[];
}

export interface ILoubiFutureCanvasState {
  currentScene: LoubiFutureScene;
  camera?: ObjectValue;
  focusItem?: ObjectValue;
  focusItemID?: number;
  focusColorID?: number;
  flag?: REMOTE_ACTION;
  newScene?: LoubiFutureScene;
}

export interface ILoubiFutureAnimationState {
  flag?: REMOTE_ACTION;
  position?: number[];
  scale?: number[];
  rotation?: number[];
  focusItemID?: number;
  currentScene?: LoubiFutureScene;
}

export interface ISoundEffect {
  enteringSectionA?: boolean;
  zoomingLightingStrips?: boolean;
  water?: boolean;
  focusProduct?: boolean;
  rainbowLight?: boolean;
  elevatorUp?: boolean;
  dome?: boolean;
  arClick?: boolean;
  afterRainbow?: boolean;
  muted?: boolean;
}

export interface IMapState {
  open?: boolean;
  selectedSpot?: string;
}

export interface ILoubiFutureState {
  canvasState?: ILoubiFutureCanvasState;
  map?: IMapState;
  selectedProductId?: string;
  wishlist?: ILoubiFutureWishlist;
  popup?: LFPopupContent;
  soundEffect?: ISoundEffect;
  landingVideo?: {
    finishedPlaying?: boolean;
    hidden?: boolean;
  };
  exit?: boolean;
  hideCursorGuide?: boolean;
  replayMusicVideo?: boolean;
  playIntroductionVideosInMeeting?: boolean;
}

import { first } from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MdCall } from 'react-icons/md';
import { logEvent } from '../../../analytics';
import * as translation from '../../../i18n';
import { IVirtualBoutiqueContact } from '../../../interfaces';
import { DID_CLICK_CALL_NOW } from '../../../utils/constants';
import { AppContext } from '../../AppContext';

const CallNowButton = ({
  contacts
}: {
  contacts: IVirtualBoutiqueContact[];
}) => {
  const context = React.useContext(AppContext);
  const { t } = translation.useTranslation();
  const contact = first(contacts || []);

  return (
    <>
      <Button className="btn-action whatsapp" variant="outline-dark">
        <a
          key={contact.name}
          data-channel="call"
          href={`tel:${contact.callNumber}`}
          target="_blank"
          rel="noopener"
          aria-label="Call Now"
          onClick={() => {
            logEvent(
              DID_CLICK_CALL_NOW,
              context.store?.id || 'Unknown Store',
              contact
            );
          }}
        >
          <MdCall /> {t('call_now')}
        </a>
      </Button>
      <style jsx>
        {`
          a {
            margin: 10px 0;
            color: #4c4c4c;
            font-size: 14px;
          }
          a:hover {
            text-decoration: none;
            color: #fff;
          }

          :global(.whatsapp:hover a) {
            color: #fff;
          }

          :global(.whatsapp:hover svg) {
            fill: #fff;
          }
        `}
      </style>
    </>
  );
};

export default CallNowButton;

import { useRouter } from 'next/router';
import React from 'react';
import {
  IMainState,
  IStore,
  IVirtualBoutiqueConfig,
  ParticipantMeetingState
} from '../../interfaces';
import PixelStreamLayer from './PixelStreamLayer';
import {
  DisconnectReason,
  getPlayerServer,
  logDisconnect,
  statusToDisconnectedReasonMap,
  STREAM_SERVER_FAILED_TO_LOAD,
  STREAM_SERVER_FOUND
} from './utils/connectionHandler';
import {
  CHScenes,
  EmitType,
  PSServer,
  StreamConnectionStatus,
  StreamPlayerStatus
} from './PSInterface';
import TestCommandButton from './TestCommandButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionCloseVBPopup,
  actionDidGoToScene,
  actionSetPixelStreamConnectionStatus,
  actionSetPixelStreamPlayerStatus,
  actionSetPixelStreamServer,
  actionUpdateMap
} from '../../redux/actions';
import { canInteractWithAnimation } from '../hooks/meeting';

import { logEvent } from '../../analytics';
import { DID_ENTER_EXPERIENCE } from '../../utils/constants';
import { zIndex } from '../../config';
import {
  disablePSIdle,
  enablePSIdle,
  sendCommandToPS,
  sendVoyageModeInfo
} from './CommandHandler/webToPSCommand';
import { useTranslation } from '../../i18n';
import { last } from 'lodash';
import PSEyeballTimeLogger from './PSEyeballTimeLogger';
import { MDPortraitNormalSpec } from '../Meeting/MeetingLayout/MDPortraitNormal';
import {
  getPSCustomComponent,
  getVBCustomComponent
} from '../storeComponentFactory';
import { SMPortraitNormalSpec } from '../Meeting/MeetingLayout/SMPortraitNormal';
import PSLanding from './PSLanding';
import PSDisconnected from './PSDisconnected';
import FullScreenButton from '../Common/FullScreenButton';
import { GET_CURRENT_MODIFICATION } from './MercedesBenz/MercedesConfig';
import { isUserOniOS } from '../../utils/deviceDetector';

let popupIdleTime;
let serverFoundTimeOut;
const serverFoundTimeOutMs = 5000;
const LIMIT_RETRY = 4;
const landingPageTimeOutMs = 104000;

const PixelStream = ({
  store,
  config
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
}) => {
  const dispatch = useDispatch();

  const router = useRouter();
  const pshost = router.query.pshost as string;
  const psprot = router.query.psprot as string;
  const debug = router.query.debug as string;
  const pslanding = router.query.pslanding as string;
  const popupIdle = router.query.popupIdle as string;
  const popupCloseIdleMinutes = popupIdle ? parseInt(popupIdle) : 4;
  const meetingId = router.query.meeting as string;

  const [firstTimeConnected, setFirstTimeConnected] = React.useState(true);
  const [showSlideNav, setShowSlideNav] = React.useState(false);
  const [sceneBeforeDisconnected, setSceneBeforeDisconnected] =
    React.useState<string>(null);
  const [serverBeforeDisconnected, setServerBeforeDisconnected] =
    React.useState<string>(null);
  const [loadPageAttempt, setLoadPageAttempt] = React.useState(0);
  const [reconnectAttempt, setReconnectAttempt] = React.useState(0);

  const floorplanState = useSelector(
    (state: IMainState) => state.clientState?.vb?.map || {}
  );

  const lastActiveTimestamp = React.useRef(Date.now());
  const floorplan = floorplanState?.open;

  const setFloorplan = (open: boolean) => {
    dispatch(actionUpdateMap({ ...floorplanState, open }));
  };

  const { connectionStatus, playerStatus, server } =
    useSelector((state: IMainState) => state.clientState?.vb?.pixelStreaming) ||
    {};
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);

  const openingVideo = useSelector(
    (state: IMainState) => state.clientState?.vb?.fullScreenVideoWithUrl?.show
  );

  const { width, height } = useSelector(
    (state: IMainState) => state.clientState.viewport || { width: 0, height: 0 }
  );

  const orientation =
    width > height ? 'viewport_landscape ' : 'viewport_portrait';

  const popupState = useSelector(
    (state: IMainState) => state.clientState.vb?.popup
  );

  const visitedScene = useSelector(
    (state: IMainState) => state.clientState.vb?.sceneIds
  );

  const currentScene = last(visitedScene);

  const streamConnectionStatus =
    connectionStatus || StreamConnectionStatus.INITIAL;

  const setStreamConnectionStatus = (status: StreamConnectionStatus) => {
    const isDisconnected = [
      StreamConnectionStatus.DISCONNECTED,
      StreamConnectionStatus.DISCONNECTED_BY_AFK,
      StreamConnectionStatus.DISCONNECTED_BY_PING_TIMEOUT,
      StreamConnectionStatus.ICE_DISCONNECTED
    ].includes(status);

    if (isDisconnected) {
      logDisconnect({
        server: serverBeforeDisconnected,
        reason:
          statusToDisconnectedReasonMap[status] || DisconnectReason.UNKNOWN
      });
    }

    dispatch(
      actionSetPixelStreamConnectionStatus(
        isDisconnected ? StreamConnectionStatus.DISCONNECTED : status
      )
    );
  };
  const streamAddress = server || null;
  const setStreamAddress = (address: PSServer) => {
    const host = {
      host: address.host + `${window.location.search || ''}`,
      protocol: address.protocol,
      storeId: store.id
    };
    setServerBeforeDisconnected(address.host);
    dispatch(actionSetPixelStreamServer(host));
  };
  const isPlaying = playerStatus === StreamPlayerStatus.PLAYING;
  const viewOnly = !canInteractWithAnimation();

  const inMeeting = !!meetingId;

  const skipLanding = inMeeting || pslanding === 'false';

  const isInTheLounge =
    inMeeting &&
    ![
      ParticipantMeetingState.ENTERED_FROM_LOUNGE,
      ParticipantMeetingState.SHOW_WELCOME_VIDEO,
      ParticipantMeetingState.LEFT_MEETING
    ].includes(meeting?.state);

  const [showLanding, setShowLanding] = React.useState(true);

  const wrapperRef = React.useRef<HTMLDivElement>();

  const connectToStream = (attempt = 1) => {
    if (attempt === 1) {
      window['start_connect_time'] = Date.now();
    }
    setStreamConnectionStatus(StreamConnectionStatus.FINDING_SERVER);
    dispatch(actionDidGoToScene(CHScenes.SCENE_LANDING));
    if (pshost) {
      setStreamAddress({
        host: pshost,
        protocol: psprot || window.location.protocol
      });
      logEvent(STREAM_SERVER_FOUND, STREAM_SERVER_FOUND, {
        server,
        message: 'Static host',
        urlQuery: router.query
      });
      return;
    }

    return getPlayerServer({
      onSuccess: (server) => {
        setStreamAddress(server);
        setReconnectAttempt(0);
      },
      onFailed: () => {
        setTimeout(() => {
          if (attempt < LIMIT_RETRY) {
            connectToStream(attempt + 1);
          } else {
            setStreamConnectionStatus(StreamConnectionStatus.SERVER_NOT_FOUND);
          }
        }, 2000);
      },
      meetingID: meetingId,
      serverAddress: config.matchMakerAddress
    });
  };

  const retryConnect = () => {
    setLoadPageAttempt(0);
    connectToStream();
  };

  React.useEffect(() => {
    const closeMMConnection = connectToStream();
    return () => {
      closeMMConnection?.();
    };
  }, []);

  React.useEffect(() => {
    if (streamConnectionStatus === StreamConnectionStatus.SERVER_FOUND) {
      if (loadPageAttempt < LIMIT_RETRY) {
        serverFoundTimeOut = setTimeout(() => {
          setLoadPageAttempt(loadPageAttempt + 1);
          logEvent(STREAM_SERVER_FAILED_TO_LOAD, STREAM_SERVER_FAILED_TO_LOAD, {
            server: streamAddress?.host
          });
          connectToStream();
        }, serverFoundTimeOutMs);
      } else {
        setStreamConnectionStatus(StreamConnectionStatus.SERVER_NOT_FOUND);
        logEvent(STREAM_SERVER_FAILED_TO_LOAD, STREAM_SERVER_FAILED_TO_LOAD, {
          server: streamAddress?.host
        });
      }
    }
    if (
      streamConnectionStatus === StreamConnectionStatus.PAGE_LOADED &&
      serverFoundTimeOut
    ) {
      clearTimeout(serverFoundTimeOut);
    }
    if (
      streamConnectionStatus === StreamConnectionStatus.SERVER_NOT_FOUND &&
      firstTimeConnected &&
      !skipLanding
    ) {
      setTimeout(() => window.location.reload(), 300);
    }

    if (streamConnectionStatus === StreamConnectionStatus.DISCONNECTED) {
      closePopup();
      setSceneBeforeDisconnected(currentScene);
      if (inMeeting && reconnectAttempt < 1) {
        retryConnect();
      }
    }

    return () => {
      if (inMeeting) return;
      if (serverFoundTimeOut) {
        clearTimeout(serverFoundTimeOut);
      }
    };
  }, [streamConnectionStatus, inMeeting]);

  React.useEffect(() => {
    return () => {
      dispatch(actionSetPixelStreamPlayerStatus(undefined));
    };
  }, []);

  const sendCommand = (descriptor: any, type?: EmitType) => {
    if (!viewOnly || descriptor === GET_CURRENT_MODIFICATION) {
      sendCommandToPS(descriptor, type);
    }
  };

  const disableAfk =
    isPlaying &&
    (popupState?.open ||
      openingVideo ||
      floorplan ||
      showSlideNav ||
      showLanding);

  const closePopup = () => {
    clearTimeout(popupIdleTime);
    dispatch(actionCloseVBPopup());
    setFloorplan(false);
    setShowSlideNav(false);
  };

  React.useEffect(() => {
    if (inMeeting) return;

    function clearPopupIdle() {
      if (popupIdleTime) {
        clearTimeout(popupIdleTime);
      }
    }

    window['resetPopupTimer'] = () => {
      clearPopupIdle();
      popupIdleTime = setTimeout(closePopup, popupCloseIdleMinutes * 60000);
    };

    const handlePopupIdle = () => {
      if (disableAfk) {
        window['resetPopupTimer']?.();
      } else {
        clearPopupIdle();
      }
    };

    if (disableAfk) {
      disablePSIdle();
      handlePopupIdle();
    } else {
      enablePSIdle();
      clearTimeout(popupIdleTime);
    }

    document.addEventListener('mousemove', handlePopupIdle);
    return () => {
      document.removeEventListener('mousemove', handlePopupIdle);
    };
  }, [disableAfk]);

  const shouldShowPixelStreamLayer =
    streamAddress?.host &&
    ![
      StreamConnectionStatus.DISCONNECTED,
      StreamConnectionStatus.SERVER_NOT_FOUND,
      StreamConnectionStatus.FINDING_SERVER
    ].includes(streamConnectionStatus);

  React.useEffect(() => {
    if (isPlaying) {
      logEvent(DID_ENTER_EXPERIENCE, DID_ENTER_EXPERIENCE, {
        server: streamAddress?.host
      });
      setFirstTimeConnected(false);
      if (!viewOnly && !skipLanding) {
        sendCommand(firstTimeConnected ? 'START_EXPERIENCE' : 'BACK');
        dispatch(actionDidGoToScene(CHScenes.SCENE_CHAPTER1));
      }
      if (sceneBeforeDisconnected) {
        setSceneBeforeDisconnected(null);
        if (inMeeting) {
          dispatch(actionDidGoToScene(sceneBeforeDisconnected));
        } else {
          sendCommand(sceneBeforeDisconnected);
        }
      }
      sendLanguageChangeCommand(i18n.language || 'en');
      if (isInTheLounge) {
        viewOnly
          ? document.getElementById('joinMeetingButton')?.click()
          : document.getElementById('startMeeting')?.click();
      }

      setTimeout(() => {
        sendLanguageChangeCommand(i18n.language || 'en');
      }, 2000);
      if (inMeeting && !viewOnly) {
        setTimeout(() => {
          sendVoyageModeInfo();
        }, 1000);
      }
    }
  }, [isPlaying]);

  React.useEffect(() => {
    if (isPlaying) {
      sendCommand(orientation);
    }
  }, [orientation, isPlaying]);

  const { i18n } = useTranslation();

  const sendLanguageChangeCommand = (code: string) => {
    sendCommand(
      `LANGUAGE_SWITCH_TO_${
        code?.toUpperCase() === 'KO' ? 'KR' : code?.toUpperCase()
      }`
    );
  };

  const showDisconnectScreen = [
    StreamConnectionStatus.DISCONNECTED,
    StreamConnectionStatus.SERVER_NOT_FOUND
  ].includes(streamConnectionStatus);

  const shouldShowLandingScreen = !showDisconnectScreen && !isPlaying;

  React.useEffect(() => {
    if (inMeeting) return;
    let landingPageTimeOut;
    const connectedStatus = [
      StreamConnectionStatus.CONNECTED,
      StreamConnectionStatus.PAGE_LOADED
    ];
    //detect user has not click enter experience button
    if (connectedStatus.includes(streamConnectionStatus) && !isPlaying) {
      console.log(`debug:: will be disconnected in ${landingPageTimeOutMs}ms`);
      landingPageTimeOut = setTimeout(() => {
        console.log(`debug:: disconnected due to landing page timeout`);
        setStreamConnectionStatus(StreamConnectionStatus.DISCONNECTED);
        logDisconnect({
          reason: DisconnectReason.LANDING_TIMEOUT,
          server: streamAddress?.host,
          sceneBeforeDisconnected: currentScene
        });
      }, landingPageTimeOutMs);
    }
    return () => {
      console.log('debug:: clear timeout for disconnect');
      landingPageTimeOut && clearTimeout(landingPageTimeOut);
    };
  }, [streamConnectionStatus, isPlaying, inMeeting]);

  React.useEffect(() => {
    const isUserOnIOS = isUserOniOS();
    if (inMeeting || !isPlaying || !isUserOnIOS) return;
    const onVisibilityChange = () => {
      if (document.visibilityState === 'hidden')
        lastActiveTimestamp.current = Date.now();
      else {
        const timeInActivity = Date.now() - lastActiveTimestamp.current;
        console.log('debug:: time inactivity', timeInActivity);
        if (timeInActivity > 45000) {
          console.log('debug:: disconnected due to inactivity');
          logDisconnect({
            reason: DisconnectReason.USER_INACTIVITY,
            server: streamAddress?.host,
            sceneBeforeDisconnected: currentScene
          });
          setStreamConnectionStatus(StreamConnectionStatus.DISCONNECTED);
        }
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [inMeeting, isPlaying]);

  React.useEffect(() => {
    if (shouldShowLandingScreen) {
      setShowLanding(true);
    }
  }, [shouldShowLandingScreen]);

  return (
    <>
      <div
        ref={wrapperRef}
        id="pixelStreamWrapper"
        className={`ps-container show-controls d-flex vb-page-wrapper ${streamConnectionStatus} ${playerStatus} ${
          viewOnly ? 'viewOnly' : ''
        } ${isInTheLounge ? 'inTheLounge' : ''}`}
      >
        {shouldShowPixelStreamLayer && (
          <PixelStreamLayer
            url={`${streamAddress?.protocol}//${streamAddress?.host}`}
            onUpdateConnectionStatus={setStreamConnectionStatus}
            storeId={store.id}
            viewOnly={viewOnly}
            commandConfig={config.unRealCommands}
            onSendCommand={sendCommand}
            brandId={config.brandId}
          />
        )}

        {showLanding && !isInTheLounge && (
          <PSLanding
            entrance={() =>
              sendCommand(config.unRealInitialScene || 'ENTER_EXPERIENCE')
            }
            onClose={() => setShowLanding(false)}
            streamStatus={streamConnectionStatus}
            playerStatus={playerStatus}
            storeId={store.id}
          />
        )}

        {showDisconnectScreen && (
          <PSDisconnected onReconnect={retryConnect} storeId={store.id} />
        )}

        {!showLanding &&
          isPlaying &&
          !meeting?.isOverlayImgVisible &&
          getVBCustomComponent(store?.id, sendCommand)}

        {streamConnectionStatus !== StreamConnectionStatus.DISCONNECTED &&
          streamConnectionStatus !==
            StreamConnectionStatus.SERVER_NOT_FOUND && (
            <div className="fullscreen">
              <FullScreenButton />
            </div>
          )}
        {!showLanding &&
          isPlaying &&
          getPSCustomComponent(store.id, sendCommand)}
      </div>
      {debug && (
        <TestCommandButton
          sendCommand={sendCommand}
          onReconnect={retryConnect}
        />
      )}

      <PSEyeballTimeLogger />
      <style jsx global>{`
        .CHNav {
          cursor: pointer;
          position: absolute;
          z-index: 4;
          top: 0;
        }
        .canControl.MDPortrait.normal-mode .CHNav {
          left: ${MDPortraitNormalSpec.thumbnailSelf.width + 20}px;
        }

        .canControl.SMPortrait.normal-mode .CHNav {
          left: ${SMPortraitNormalSpec.thumbnailSelf.width + 10}px;
        }
        .CHNav svg,
        .CHNav img {
          width: 60px;
          height: auto;
          transition: opacity 0.3s ease-in-out;
        }
        .client .veil {
          display: ${shouldShowLandingScreen ? 'none' : 'block'};
      `}</style>
      <style jsx>{`
        .fullscreen {
          position: fixed;
          right: 5px;
          top: 5px;
          display: ${inMeeting ? 'none' : 'block'};
        }
        .btn-sm {
          position: absolute;
          z-index: 20;
          bottom: 0;
          right: 0;
        }
        .disconnect {
          z-index: 200;
          position: fixed;
          top: 0;
        }
        .ps-container {
          position: fixed;
          overflow: hidden;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: black;
          z-index: ${isPlaying
            ? 'auto'
            : isInTheLounge &&
              connectionStatus === StreamConnectionStatus.PAGE_LOADED
            ? zIndex.preventActionVeil + 1
            : 'auto'};
          opacity: ${isInTheLounge && !isPlaying ? '0.008' : '1'};
        }
        .ps-container,
        .ps-container * {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .ps-container :global(.pixel-stream-layer) {
          opacity: ${!isPlaying ? '0.008' : '1'};
        }
        .ps-container :global(#ps-focus) {
          display: ${!isPlaying ? 'none' : 'block'};
        }
        .SERVER_NOT_FOUND,
        .FINDING_SERVER {
          z-index: auto;
        }
      `}</style>
    </>
  );
};

export default PixelStream;

import {
  getDay,
  getMinutes,
  add,
  parse,
  addMinutes,
  format,
  getHours,
  isToday,
  startOfTomorrow,
  addDays
} from 'date-fns';
import { convertToTimeZone, formatToTimeZone } from 'date-fns-timezone';
import {
  iwcNlAmsterdamSiteId,
  jlcBrandId,
  loubiAirwaysJpBoutiqueId,
  sgPublicHolidays2022,
  vacMarinaBaySandsBoutiqueId,
  vacSgBoutiqueId
} from '../../../config';
import {
  getNow,
  getWeekday,
  isDateSameDay,
  isDateToday
} from '../../../utils/clock';
import { IConcurrentAvailableSlot } from './GenericIWCAppointmentForm';
import { iwcBrandId } from './../../../config/index';
import { IOpeningHoursByWeekday } from '../../../interfaces';

export const getDefaultDate = (storeId: string, days?: number) => {
  if (storeId === iwcNlAmsterdamSiteId) {
    return startOfTomorrow();
  }
  return addDays(getNow(), days || 0);
};

export const getDefaultDateByStore = (storeId: string) => {
  if (storeId === loubiAirwaysJpBoutiqueId) {
    const now = getNow();
    const loubiAirwaysJapanGoLiveDate = new Date('2021-05-20T00:00:00.000Z');
    return loubiAirwaysJapanGoLiveDate > now
      ? loubiAirwaysJapanGoLiveDate
      : now;
  }
  return getNow();
};

export const getStoreDateNow = (timezoneName: string) => {
  return convertToTimeZone(new Date(), { timeZone: timezoneName });
};

export const isSunday = (date: Date) => {
  const day = getDay(date);
  return day === 0;
};

export const isMonday = (date: Date) => {
  const day = getDay(date);
  return day === 1;
};

const isWeekday = (date: Date) => {
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

const isWeekend = (date: Date) => !isWeekday(date);

const isPublicHoliday = (date: Date) => {
  return sgPublicHolidays2022.includes(
    formatToTimeZone(date, 'YYYY-MM-DD', { timeZone: 'Asia/Singapore' })
  );
};

export const isDateAllowed = (date: Date) =>
  isWeekday(date) && !isPublicHoliday(date);

export const getIsDateAllowedByBrand = (brandId: string, storeId?: string) => {
  if (brandId === jlcBrandId) {
    return (date: Date) => !isSunday(date);
  }
  if (storeId === iwcNlAmsterdamSiteId) {
    return (date: Date) => !isToday(date);
  }
  if (storeId === vacSgBoutiqueId || storeId === vacMarinaBaySandsBoutiqueId) {
    return (date: Date) => isDateAllowed(date) && !isSunday(date);
  }
  return () => true;
};

export const getDateListForFewDaysForward = (days: number) => {
  const dates = [];
  for (let i = 0; i < days; i++) {
    dates[i] = add(getNow(), { days: i });
  }
  return dates;
};

export const getAllowedDateFromNow = (days: number) => {
  const dateList = getDateListForFewDaysForward(days);
  const dates = dateList.map((d) => {
    if (isDateAllowed(d)) return d;
  });
  return dates.filter((d) => d !== undefined || null);
};

export const getMinDate = (brandId: string, earliestDay: number) => {
  if (brandId !== iwcBrandId) return getDefaultDate(brandId);
  const allowedList = getAllowedDateFromNow(10);
  return allowedList[earliestDay || allowedList.length];
};

const isSlotAvailable = (
  timeSlot: string,
  date: Date,
  timezoneGMTOffset: number
): boolean => {
  const startTime = timeSlot.split('-')[0];
  let startHour = parseInt(startTime.split(':')[0]);
  const startMinutes = startTime.split(':')[1].substring(0, 2);

  if (startTime.indexOf('PM') > -1 && startHour !== 12) {
    startHour = startHour + 12;
  }
  const localHour = (date.getUTCHours() + timezoneGMTOffset) % 24;
  const minutes = getMinutes(date);

  if (minutes > 30) {
    return startHour > localHour + 1;
  }

  if (startHour === localHour + 1) {
    return startMinutes === '30';
  }

  return startHour > localHour + 1;
};

export const getAvailableSlotsWithConcurrentLimitation = (
  date: Date,
  availableSlots: IConcurrentAvailableSlot[],
  timezoneGMTOffset: number
) => {
  if (isDateToday(date)) {
    return availableSlots.filter((slot) =>
      isSlotAvailable(slot.time, date, timezoneGMTOffset)
    );
  }
  return availableSlots;
};

export const getAvailableSlots = (
  date: Date,
  openingHours: string[],
  timezoneGMTOffset: number,
  weekendOpeningHours: string[] | undefined = undefined,
  publicHolidayOpeningHours: string[] | undefined = undefined,
  additionalHours?: number
) => {
  let applicableOpeningHours = openingHours;
  if (isWeekend(date) && weekendOpeningHours) {
    applicableOpeningHours = weekendOpeningHours;
  } else if (isPublicHoliday(date) && publicHolidayOpeningHours) {
    applicableOpeningHours = publicHolidayOpeningHours;
  }
  if (additionalHours && isDateSameDay(date, additionalHours)) {
    return applicableOpeningHours.filter((slot) =>
      isSlotAvailable(slot, date, timezoneGMTOffset)
    );
  }
  if (isDateToday(date)) {
    return applicableOpeningHours.filter((slot) =>
      isSlotAvailable(slot, date, timezoneGMTOffset)
    );
  }
  return applicableOpeningHours;
};

export const getAvailableSlotsForStore = (
  storeDate: Date,
  openingHours: string[],
  weekendOpeningHours: string[] | undefined = undefined,
  publicHolidayOpeningHours: string[] | undefined = undefined
) => {
  let applicableOpeningHours = openingHours;
  if (isWeekend(storeDate) && weekendOpeningHours) {
    applicableOpeningHours = weekendOpeningHours;
  } else if (isPublicHoliday(storeDate) && publicHolidayOpeningHours) {
    applicableOpeningHours = publicHolidayOpeningHours;
  }
  if (isDateToday(storeDate)) {
    return applicableOpeningHours.filter((slot) =>
      isSlotAvailableForStore(slot, storeDate)
    );
  }
  return applicableOpeningHours;
};

const isSlotAvailableForStore = (
  timeSlot: string,
  storeDate: Date
): boolean => {
  const startTime = timeSlot.split('-')[0];
  let startHour = parseInt(startTime.split(':')[0]);
  const startMinutes = startTime.split(':')[1].substring(0, 2);

  if (startTime.indexOf('PM') > -1 && startHour !== 12) {
    startHour = startHour + 12;
  }
  const storeHour = getHours(storeDate);
  const minutes = getMinutes(storeDate);

  if (minutes > 30) {
    return startHour > storeHour + 1;
  }

  if (startHour === storeHour + 1) {
    return startMinutes === '30';
  }

  return startHour > storeHour + 1;
};

export const getAvailableSlotsByWeekday = (
  date: Date,
  openingHoursByWeekday: IOpeningHoursByWeekday,
  appointmentSlotDurationInMinutes: number,
  timezoneGMTOffset: number
) => {
  const weekday = getWeekday(date);
  const startEndTime = openingHoursByWeekday[weekday]
    ?.split('-')
    ?.map((s) => parse(s.trim(), 'h:mmaa', date));
  if (!startEndTime || !startEndTime.length) {
    return [];
  }
  const [startTime, endTime] = startEndTime;
  if (endTime < startTime || appointmentSlotDurationInMinutes < 0) {
    return [];
  }
  const slots = [];
  let slotStart = startTime;
  while (addMinutes(slotStart, appointmentSlotDurationInMinutes) <= endTime) {
    const slotEnd = addMinutes(slotStart, appointmentSlotDurationInMinutes);
    slots.push(`${format(slotStart, 'h:mmaa')} - ${format(slotEnd, 'h:mmaa')}`);
    slotStart = slotEnd;
  }
  if (isDateToday(date)) {
    return slots.filter((slot) =>
      isSlotAvailable(slot, date, timezoneGMTOffset)
    );
  }
  return slots;
};

export const getAvailableSlotsByWeekdayForStore = (
  date: Date,
  openingHoursByWeekday: IOpeningHoursByWeekday,
  appointmentSlotDurationInMinutes: number
) => {
  const weekday = getWeekday(date);
  const startEndTime = openingHoursByWeekday[weekday]
    ?.split('-')
    ?.map((s) => parse(s.trim(), 'h:mmaa', date));
  if (!startEndTime || !startEndTime.length) {
    return [];
  }
  const [startTime, endTime] = startEndTime;
  if (endTime < startTime || appointmentSlotDurationInMinutes < 0) {
    return [];
  }
  const slots = [];
  let slotStart = startTime;
  while (addMinutes(slotStart, appointmentSlotDurationInMinutes) <= endTime) {
    const slotEnd = addMinutes(slotStart, appointmentSlotDurationInMinutes);
    slots.push(`${format(slotStart, 'h:mmaa')} - ${format(slotEnd, 'h:mmaa')}`);
    slotStart = slotEnd;
  }
  if (isDateToday(date)) {
    return slots.filter((slot) => isSlotAvailableForStore(slot, date));
  }
  return slots;
};

export const getMonToSatAvailableSlots = (
  date: Date,
  openingHours: string[],
  timezoneGMTOffset: number
) => {
  if (isSunday(date)) {
    return [];
  }
  if (isDateToday(date)) {
    return openingHours.filter((slot) =>
      isSlotAvailable(slot, date, timezoneGMTOffset)
    );
  }
  return openingHours;
};

import { format } from 'date-fns';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import { getAvailableSlotsByDateAndStore } from '../../../clientSideServices/store';
import { getUserByIdentityId } from '../../../clientSideServices/users';
import { useTranslation } from '../../../i18n';
import { formatTo24HourTime } from '../../../utils/clock';
import {
  DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
  DID_OPEN_FORM,
  DID_SEND_APPOINTMENT_EMAIL
} from '../../../utils/constants';
import { getIdentityId } from '../../../utils/identity';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { useLoubiAirwaysState } from '../../hooks/loubiAirways';
import PrivacyPolicy from '../../Legal/PrivacyPolicy';
import TermsOfUse from '../../Legal/TermsOfUse';
import LJPopupWrapper from '../CustomComponent/LouboutinJpCustomComponent/LJPopupWrapper';
import { LoubiAirFontFamily } from './../../BrandStyle/LouboutinStyle';
import {
  getAvailableSlots,
  getAvailableSlotsWithConcurrentLimitation,
  getDefaultDateByStore,
  getIsDateAllowedByBrand
} from './AppointmentDateTimeCalculator';
import { bookPrivateAppointment } from './appointmentService';
import {
  isValidCountryCode,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from './inputValidator';
import { IConcurrentAvailableSlot } from './GenericIWCAppointmentForm';

export interface IAppointmentFormData {
  name?: string;
  email?: string;
  countryCode: string;
  phone?: string;
  date: Date;
  time: string;
  acceptedTermsOfUse: boolean;
}

interface IAppointmentFormPristine {
  name: boolean;
  email: boolean;
  phone: boolean;
  time: boolean;
  acceptedTermsOfUse: boolean;
}

export interface IAppointmentProps {
  openingHours: string[];
  brandId: string;
  storeId: string;
  open: boolean;
  timezoneGMTOffset: number;
  timezoneName: string;
  phoneCountryCode: string;
  onClose: () => void;
}

enum EmailSentState {
  INITIAL = 'SEND',
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'PLEASE TRY AGAIN'
}

const LoubiAirwaysAppointment = ({
  openingHours,
  brandId,
  storeId,
  open,
  timezoneGMTOffset,
  timezoneName,
  phoneCountryCode,
  onClose
}: IAppointmentProps) => {
  const { invite } = useLoubiAirwaysState();
  const [emailSent, setEmailSent] = React.useState(false);
  const [emailSentState, setEmailSentState] = React.useState<EmailSentState>(
    EmailSentState.INITIAL
  );
  const [pristine, setPristine] = React.useState<IAppointmentFormPristine>({
    name: !invite,
    email: !invite,
    phone: !invite,
    time: true,
    acceptedTermsOfUse: true
  });

  const [formData, setFormData] = React.useState<IAppointmentFormData>({
    name: undefined,
    email: undefined,
    countryCode: phoneCountryCode,
    phone: undefined,
    date: getDefaultDateByStore(storeId),
    time: undefined,
    acceptedTermsOfUse: false
  });
  const [availableSlots, setAvailableSlots] = React.useState<
    IConcurrentAvailableSlot[]
  >([]);

  const mandatoryFields: (keyof IAppointmentFormData)[] = invite
    ? ['date', 'time', 'acceptedTermsOfUse']
    : ['name', 'email', 'phone', 'date', 'time', 'acceptedTermsOfUse'];

  const animationDuration = 300;
  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setEmailSent(false);
    }, animationDuration);
  };

  const handleName = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, name: false });
    setFormData({ ...formData, name: value });
  };

  const handleEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, email: false });
    setFormData({ ...formData, email: value });
  };

  const handleCountryCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFormData({ ...formData, countryCode: value });
  };

  const handlePhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPristine({ ...pristine, phone: false });
    setFormData({ ...formData, phone: value });
  };

  const handleTC = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setPristine({ ...pristine, acceptedTermsOfUse: false });
    setFormData({ ...formData, acceptedTermsOfUse: value });
  };

  const handleTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const time = event.target.value;
    setPristine({ ...pristine, time: false });
    setFormData({ ...formData, time });
  };

  const handleDate = (date: Date) => {
    if (!date) date = getDefaultDateByStore(storeId);
    setFormData({ ...formData, date });
  };

  const removePristine = () => {
    const nonPristine: IAppointmentFormPristine = {
      name: false,
      email: false,
      phone: false,
      time: false,
      acceptedTermsOfUse: false
    };
    setPristine(nonPristine);
  };

  const defaultAdvisorIdForUnknownGuests =
    'ap-southeast-1:ef72bfbf-0935-4a97-b48f-545bc7f7a3f8'; // ecomm japan

  const handleSend = () => {
    removePristine();
    if (mandatoryFields.some((field) => !formData[field])) {
      return;
    }
    const startTime = formData.time.split('-')[0].trim();
    const endTime = formData.time.split('-')[1].trim();
    const advisorId = invite?.advisorId || defaultAdvisorIdForUnknownGuests;
    const name = invite?.name || formData.name;
    const languageCode = invite?.languageCode || 'JA';
    getUserByIdentityId(advisorId)
      .then((response) => {
        return bookPrivateAppointment({
          id: generateV4UUID(),
          storeId,
          date: format(formData.date, 'MM-dd-yyyy'),
          time: formData.time,
          startTime: formatTo24HourTime(startTime),
          endTime: formatTo24HourTime(endTime),
          timezone: timezoneName,
          name,
          customerId: invite?.customerId || getIdentityId(),
          customerEmail: formData.email,
          customerPhoneCountryCode: phoneCountryCode,
          customerPhoneNumber: formData.phone,
          advisorId,
          advisorName: response.data.alias,
          inviteId: invite?.id,
          preferredLanguage: languageCode
        });
      })
      .then(() => {
        logEvent(DID_SEND_APPOINTMENT_EMAIL);
        setEmailSentState(EmailSentState.INITIAL);
        setEmailSent(true);
      })
      .catch((error) => {
        setEmailSentState(EmailSentState.FAILED);
        logEvent(
          DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
          DID_FAIL_TO_SEND_APPOINTMENT_EMAIL,
          { error }
        );
      });
  };

  const { t, i18n } = useTranslation();

  const renderTitle = () => {
    return t('vb:book_your_walkthrough_experience');
  };

  React.useEffect(() => {
    if (invite?.advisorId) {
      const formattedDate = format(formData.date, 'dd MMM, yyyy');
      getAvailableSlotsByDateAndStore({
        date: formattedDate,
        storeId: storeId,
        advisorId: invite?.advisorId
      }).then((result) => {
        setAvailableSlots(result.data);
      });
    }
  }, [formData.date, invite?.advisorId, storeId]);

  const renderSelectTime = () => {
    if (invite) {
      return getAvailableSlotsWithConcurrentLimitation(
        formData.date,
        availableSlots,
        timezoneGMTOffset
      ).map((s) => (
        <option value={s.time} key={s.time} disabled={s.slotNr === 0}>
          {s.time}
        </option>
      ));
    }
    return getAvailableSlots(
      formData.date,
      openingHours,
      timezoneGMTOffset
    ).map((s) => (
      <option value={s} key={s}>
        {s}
      </option>
    ));
  };

  const loubiFont = LoubiAirFontFamily();

  React.useEffect(() => {
    if (open) {
      logEvent(DID_OPEN_FORM, DID_OPEN_FORM, {
        form: 'appointment'
      });
    }
  }, [open]);

  return (
    <div className="appointment">
      <Transition
        in={open}
        timeout={animationDuration}
        mountOnEnter
        unmountOnExit
      >
        {() => (
          <LJPopupWrapper onClose={handleClose}>
            {!emailSent && (
              <form id="emailform">
                <h2>{renderTitle()}</h2>
                <h3>{t('vb:appointment_detail')}</h3>

                <div className="two-col">
                  <div className="input-group">
                    <label>{t('vb:appointment_date')}:</label>
                    <DatePicker
                      selected={formData.date}
                      filterDate={getIsDateAllowedByBrand(brandId)}
                      minDate={getDefaultDateByStore(storeId)}
                      onChange={handleDate}
                      dateFormat="MMMM d, yyyy"
                      locale={i18n.language}
                    />
                  </div>

                  <div className="input-group">
                    <label>
                      {t('vb:preferred_time', { timezone: timezoneName })}:
                    </label>
                    <select
                      id="inputtime"
                      name="time"
                      required
                      onChange={handleTime}
                    >
                      <option value="">{t('vb:select')}</option>
                      {renderSelectTime()}
                    </select>
                    {!pristine.time &&
                      (!formData.time || formData.time.trim().length === 0) && (
                        <span className="error time">
                          {t('vb:please_select_time')}
                        </span>
                      )}
                  </div>
                </div>

                {!invite && (
                  <>
                    <h3>{t('vb:general_information')}</h3>
                    <div className="input-group">
                      <label>{t('vb:name')}:</label>
                      <input
                        type="text"
                        id="inputname"
                        name="name"
                        placeholder={t('vb:enter_your_name')}
                        onBlur={handleName}
                      />
                      {!pristine.name && !isValidName(formData.name) && (
                        <span className="error">
                          {t('vb:please_enter_valid_name')}
                        </span>
                      )}
                    </div>
                    <div className="two-col">
                      <div className="input-group">
                        <label>{t('email')}:</label>
                        <input
                          type="email"
                          id="inputemail"
                          name="email"
                          placeholder={t('vb:enter_your_email')}
                          onBlur={handleEmail}
                        />
                        {!pristine.email && !isValidEmail(formData.email) && (
                          <span className="error">
                            {t('vb:please_enter_valid_email')}
                          </span>
                        )}
                      </div>
                      <div className="input-group">
                        <label>{t('vb:phone_number')}:</label>
                        <div className="phone-number-input">
                          <input
                            type="tel"
                            id="inputcountrycode"
                            name="countrycode"
                            value={formData.countryCode}
                            onChange={handleCountryCode}
                          />
                          <input
                            type="tel"
                            id="inputphone"
                            name="phone"
                            placeholder={t('vb:enter_phone_number')}
                            onBlur={handlePhoneNumber}
                          />
                        </div>
                        {!pristine.phone &&
                          (!isValidCountryCode(formData.countryCode) ||
                            !isValidPhoneNumber(formData.phone)) && (
                            <span className="error">
                              {t('vb:please_enter_valid_phone_number')}
                            </span>
                          )}
                      </div>
                    </div>
                  </>
                )}

                <div className="input-group tstc">
                  <label>
                    <input type="checkbox" id="inputtstc" onChange={handleTC} />
                    {!pristine.acceptedTermsOfUse &&
                      !formData.acceptedTermsOfUse && (
                        <span className="error">
                          {t('vb:please_accept_tc')}
                        </span>
                      )}
                    {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
                    <PrivacyPolicy />
                    {t('vb:tc_part_three')}
                  </label>
                </div>
                <div className="additional-disclaimer">
                  <p>{t('vb:minimum_browser_requirements_with_mobile')}:</p>
                  <ul>
                    <li>{t('vb:chrome_min_version', { minVersion: '70' })}</li>
                    <li>{t('vb:opera_min_version', { minVersion: '23' })}</li>
                    <li>{t('vb:firefox_min_version', { minVersion: '53' })}</li>
                    <li>{t('vb:safari_on_ios')}</li>
                    <li>{t('vb:chrome_on_android')}</li>
                  </ul>
                </div>
                <div className="input-group">
                  <button
                    id="sendform"
                    type="button"
                    className="btn btn-lg btn-send"
                    onClick={() => handleSend()}
                  >
                    {t(`vb:${emailSentState}`)}
                  </button>
                  <button
                    id="ap-cancel"
                    className="btn btn-lg popup-close"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('vb:close')}
                  </button>
                </div>
              </form>
            )}
            {emailSent && (
              <div className="success text-center">
                <h2>{t('vb:thank_you_for_your_request')}</h2>
                <p>{t('vb:our_ca_will_contact_soon')}</p>
                <button
                  className="btn btn-lg popup-close"
                  type="button"
                  onClick={() => handleClose()}
                >
                  {t('vb:close')}
                </button>
              </div>
            )}
          </LJPopupWrapper>
        )}
      </Transition>
      <style jsx global>
        {`
          .react-datepicker {
            display: flex;
          }
          .react-datepicker__month-container,
          .react-datepicker__time-container {
            float: none;
          }

          .react-datepicker-wrapper {
            display: block;
            width: 100%;
          }
          .success > h2 {
            color: rgb(65, 116, 127) !important;
          }
        `}
      </style>
      <style jsx>
        {`
          /* APPOINTMENT */

          button:disabled {
            opacity: 0.2;
            cursor: not-allowed;
          }

          .action-button:hover {
            background: #fff;
            color: #000;
            text-shadow: none;
          }

          h2,
          h3 {
            text-align: center;
          }
          h2 {
            margin: 25px 0 20px 0;
            padding: 0;
            font-size: 1.2em;
            letter-spacing: 3px;
            color: #cf152d;
            font-family: ${loubiFont};
          }

          h3 {
            font-size: 0.8em;
            letter-spacing: 5px;
            color: #346168;
            margin: 30px 0 20px;
            font-family: ${loubiFont};
          }

          .input-group {
            position: relative;
          }
          label {
            display: block;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 0.7em;
            margin-top: 15px;
            margin-bottom: 5px;
            font-family: ${loubiFont};
            color: #346168;
          }

          :global(input),
          :global(select),
          :global(textarea) {
            border: none;
            border-bottom: 1px solid #346168;
            width: 100%;
            background: transparent;
            font-size: 1em;
            outline: none;
            border-radius: 0;
            box-sizing: border-box;
            color: #346168;
          }

          .phone-number-input {
            display: flex;
            width: 100%;
          }

          .phone-number-input {
            direction: ltr;
          }

          .phone-number-input #inputcountrycode {
            width: 60px;
            margin-right: 15px;
          }

          .phone-number-input #inputphone {
            flex-grow: 1;
            text-align: left;
          }

          .tstc label {
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0.3px;
            color: #346168;
            text-align: justify;
            line-height: 1.6;
            width: 100%;
            font-family: inherit;
          }

          .tstc input {
            width: auto;
            margin-left: 0;
            margin-right: 5px;
            border: 1px solid rgb(223, 223, 223);
          }

          select,
          select option {
            color: #000000;
          }

          select:invalid,
          select option[value=''] {
            color: #666;
          }

          /*Added for browser compatibility*/
          [hidden] {
            display: none;
          }

          textarea {
            border: 1px solid rgb(223, 223, 223);
            box-sizing: border-box;
            padding: 5px;
          }
          .error {
            font-size: 0.56em;
            position: absolute;
            right: 0;
            left: auto;
            top: 1px;
            color: rgb(177, 0, 0);
          }
          #inputtime + .error {
            top: -10px;
          }

          .btn {
            width: 100%;
            border-radius: 20px;
            font-family: ${loubiFont};
            letter-spacing: 2px;
            margin-top: 0 !important;
          }

          .success .popup-close,
          .btn-send {
            background: #cf152d !important;
            color: #fff;
            margin-bottom: 20px;
          }

          #emailform button {
            font-size: 1.25rem;
            height: auto;
          }

          #ap-cancel,
          .success button {
            border: 2px solid #cf152d;
            background-color: transparent;
            color: #cf152d;
          }
          .success .btn {
            width: 200px;
          }

          .logo {
            text-align: center;
            margin-bottom: 40px;
          }

          .logo img {
            margin: auto;
            width: 140px;
          }

          .successful {
            text-align: center;
            display: block;
          }
          .successful :global(.popup-content) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
          }

          .successful .logo {
            margin-bottom: 50px;
          }
          .successful .success {
            display: block;
            text-align: center;
            margin: 0 auto auto auto;
            padding: 0 30px;
          }

          #altdateinput {
            display: flex;
            justify-content: space-between;
          }

          #altdateinput select {
            width: 32%;
          }

          .additional-disclaimer {
            text-transform: none;
            font-weight: normal;
            letter-spacing: 0.3px;
            color: #666;
            text-align: justify;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          .additional-disclaimer p {
            font-size: 0.7em;
          }

          .additional-disclaimer ul {
            list-style-position: inside;
            padding: 0;
            font-size: 0.7em;
            margin-bottom: 0;
          }

          @media (min-width: 1024px) {
            h3 {
              margin-top: 60px;
              margin-bottom: 0;
            }
            .input-group {
              margin-top: 40px;
            }

            .error {
              font-size: 0.625em;
            }
            .two-col {
              display: flex;
              justify-content: space-between;
            }
            .two-col .input-group {
              width: 46%;
            }
            .two-col .error {
              top: 18px;
            }

            .logo {
              text-align: center;
              margin-bottom: 60px;
            }

            .logo img {
              margin: auto;
              width: 200px;
            }
            .successful .success {
              padding: 0 120px 30px;
              margin-top: 50px;
            }

            .additional-disclaimer {
              margin-top: 30px;
              margin-bottom: 0;
            }
          }
          /* END APPOINTMENT */
        `}
      </style>
    </div>
  );
};

export default LoubiAirwaysAppointment;

import React from 'react';

const MeetingButtonWithIcon = ({
  onClick,
  className,
  children,
  id,
  disabled
}: {
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
  id?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={`meeting-button ${className} ${disabled ? 'disabled' : ''}`}
    >
      {children}

      <style jsx>{`
        .meeting-button {
          cursor: ${onClick ? 'pointer' : 'initial'};
          position:relative;
        }
        .meeting-button :global(svg) {
          display: block;
        }
        .meeting-button {
          color: #fff;
          display: flex;
          align-items: center;
        }
        .meeting-button.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

export default MeetingButtonWithIcon;

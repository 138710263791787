import React from 'react';

interface Props {
  value?: string;
  type?: 'text' | 'email' | 'tel';
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeHolder?: string;
  error?: string;
  name: string;
}

const LFTextInput = ({
  value = '',
  type = 'text',
  className = '',
  onChange = () => null,
  onBlur = () => null,
  placeHolder = '',
  error,
  name
}: Props) => {
  return (
    <div
      className={`input-container ${className} ${error ? 'input-error' : ''}`}
    >
      <input
        type={type}
        name={name}
        placeholder={placeHolder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={(e) => onBlur(e.target.value)}
        value={value}
        className="form-control"
      />
      {error && <div className="error">{error}</div>}
      <style jsx>{`
        .input-container {
          margin-bottom: 15px;
        }
        .input-container input {
          user-select: auto !important;
        }
        .form-control {
          border: 1px solid #fff;
          border-radius: 4px;
        }
        .input-error .form-control {
          border: 1px solid #ff7272;
        }
        .error {
          color: #ff7272;
          font-size: 0.6em;
          padding: 2px;
        }
      `}</style>
    </div>
  );
};

export default LFTextInput;

import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { actionOpenLoubiAirwaysWishlistPopup } from '../../../../../redux/customActions/loubiAirways';
import { DID_CLICK_WISHLIST_BUTTON } from '../../../../../utils/constants';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiButtonWrapper from './LoubiButtonWrapper';
const LoubiAirwaysWishlistAccessButton = () => {
  const dispatch = useDispatch();
  const state = useLoubiAirwaysState() || {};

  if (
    state.wishlist?.open ||
    state.selectedModel?.model ||
    state.planePlan?.open
  )
    return null;

  return (
    <LoubiButtonWrapper>
      <img
        src="/asset/louboutin/wishlist_access.svg"
        alt="open wishlist"
        className="loubi-circle-button top-second-right"
        onClick={() => {
          dispatch(actionOpenLoubiAirwaysWishlistPopup(true));
          logEvent(DID_CLICK_WISHLIST_BUTTON, DID_CLICK_WISHLIST_BUTTON);
        }}
      />
    </LoubiButtonWrapper>
  );
};

export default LoubiAirwaysWishlistAccessButton;

import React from 'react';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';

const asset = `${LFAssetBaseUrl}/common/v1`;
const LFARPopup = ({
  onClose,
  arUrl
}: {
  onClose: () => void;
  arUrl: string;
}) => {
  const [close, setClose] = React.useState(false);

  React.useEffect(() => {
    if (close) {
      setTimeout(onClose, 300);
    }
  }, [close]);
  return (
    <div className={`LFARPopup fixed-full noselect ${close && 'closing'}`}>
      <iframe
        className="iframe-container"
        allow="xr-spatial-tracking; fullscreen"
        src={arUrl}
      />
      <button className="btn btn-close" onClick={() => setClose(true)}>
        <img src={`${asset}/x.png`} alt="" />
      </button>
      <style jsx>{`
        .LFARPopup {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: opacity 0.3s ease-in;
          opacity: 1;
          background: #fff;
          z-index: 21;
        }
        .closing {
          opacity: 0;
        }
        .btn-close {
          position: absolute;
          top: 10px;
          outline: none;
          box-shadow: none;
          border-radius: 100%;
          padding: 5px;
          background: #000;
          width: 30px;
          height: 30px;
          padding: 0;
          font-size: 0;
          line-height: 1;
          right: 12px;
          z-index: 99;
        }
        .btn-close img {
          width: 12px;
          height: 12px;
        }
        .iframe-container {
          background: #000;
          border: none;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </div>
  );
};

export default LFARPopup;

import React from 'react';
import { CHScenes } from '../PSInterface';

const CHNav = ({
  onClickMap,
  onClickBoutique,
  currentScene
}: {
  onClickMap: () => void;
  onClickBoutique: () => void;
  currentScene?: string;
}) => {
  const [show, setShow] = React.useState(true);
  const [rendered, setRendered] = React.useState(false);
  const isInBoutique = currentScene === CHScenes.SCENE_LOBBY;

  React.useEffect(() => {
    setShow(false);
    setTimeout(() => setShow(true), rendered ? 5000 : 0);
  }, [currentScene]);

  React.useEffect(() => {
    setRendered(true);
  }, []);

  if (!show) return null;

  return (
    <div className={`CHNav NavButton ${show ? 'show' : ''}`}>
      <div
        className={`with-shadow btn btn-outline-light chopard-sans noselect`}
        onClick={onClickMap}
      >
        {isInBoutique ? 'OTHER CHAPTERS' : `CHAPTERS`}
      </div>
      {!isInBoutique && (
        <div
          className={`with-shadow btn btn-outline-light chopard-sans noselect`}
          onClick={onClickBoutique}
        >
          BOUTIQUE
        </div>
      )}
      <style jsx>{`
        .NavButton {
          left: 8px;
          top: 8px;
        }
        .btn {
          background: rgba(255, 255, 255, 0.2);
        }
        .btn:hover {
          background: #fff;
        }
      `}</style>
    </div>
  );
};

export default CHNav;

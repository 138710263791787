import { INudge } from './../interfaces/index';
import axios, { AxiosResponse } from 'axios';
import httpClient from './http';
import { mapCreateNudgeToPayload } from '../advisorHub/utils/nudge';

const baseUrl = `https://api.inspify.io`;

export const getNudgeWithContentById = (
  id: string
): Promise<AxiosResponse<INudge>> =>
  httpClient.post(`/api/nudgeWithContentById`, {
    id
  });

export const createNudge = async (nudge: INudge, apiKey: string) => {
  try {
    const payload = mapCreateNudgeToPayload(nudge);
    const response = await axios.post(
      `${baseUrl}/content/createNudge`,
      payload,
      {
        headers: {
          'x-api-key': apiKey
        }
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response);
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMainState,
  ParticipantMeetingState
} from '../../../../interfaces/index';
import { actionShowFullScreenVideoWithUrl } from '../../../../redux/actions';
import FullScreenVideo from '../../../FullScreenVideo';
import {
  canInteractWithAnimation,
  useAutoPlayVideoMuteControl,
  useMeetingLocalAudio
} from '../../../hooks/meeting';

const ChopardHappySportFullScreenVideo = ({
  shouldAbsolute = false,
  showMuteButton = false,
  onClose
}: {
  shouldAbsolute?: boolean;
  showMuteButton?: boolean;
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();
  const { muteAudioAndRestoreLater, restoreAudio } = useMeetingLocalAudio();
  const canInteract = canInteractWithAnimation();
  const [playWithSound, setPlayWithSound] = useAutoPlayVideoMuteControl();
  const clientState =
    useSelector((state: IMainState) => state.clientState) || {};

  const { url, show } = clientState?.vb?.fullScreenVideoWithUrl || {};
  const activeAudioOutput =
    clientState?.meeting?.localUser?.activeDevices?.speaker;
  const inMeeting = clientState?.meeting?.meetingId;

  const enteredFromLounge =
    clientState?.meeting?.state === ParticipantMeetingState.ENTERED_FROM_LOUNGE;

  const showVideo = show && (!inMeeting || (inMeeting && enteredFromLounge));

  React.useEffect(() => {
    const veil = document.querySelector('.veil');
    if (showVideo) {
      muteAudioAndRestoreLater();
      veil?.classList.add('video-full-screen');
    } else {
      restoreAudio();
    }
    return () => {
      veil?.classList.remove('video-full-screen');
    };
  }, [showVideo]);

  const handleCloseVideo = () => {
    dispatch(
      actionShowFullScreenVideoWithUrl({
        show: false,
        url: videoUrl
      })
    );
    setTimeout(
      () =>
        dispatch(
          actionShowFullScreenVideoWithUrl({
            show: false,
            url: undefined
          })
        ),
      2000
    );
    onClose?.();
  };

  const { t, i18n } = useTranslation();
  const videoUrl =
    typeof url === 'string' ? url : url?.[i18n.language || 'en'] || url?.['en'];

  const onToggleMute = () => {
    setPlayWithSound(!playWithSound);
  };

  const muteBtnTitle = playWithSound
    ? t('vb:mute_audio')
    : t('vb:unmute_audio');
  return (
    <FullScreenVideo
      url={videoUrl}
      show={showVideo}
      playWithSound={playWithSound}
      setPlayWithSound={(playWithSound: boolean) => {
        setPlayWithSound(playWithSound);
      }}
      onEnded={() => {
        handleCloseVideo();
      }}
      className="chps-full-screen-video"
      activeAudioOutput={activeAudioOutput}
    >
      {canInteract && (
        <button className="close" onClick={handleCloseVideo}>
          <AiOutlineClose color="#fff" />
        </button>
      )}
      {showMuteButton && (
        <button
          className="btn btn-mute"
          title={muteBtnTitle}
          onClick={onToggleMute}
        >
          {playWithSound ? (
            <BsFillVolumeUpFill size={24} />
          ) : (
            <BsFillVolumeMuteFill size={24} />
          )}
        </button>
      )}
      <style jsx>
        {`
          ::global(.veil.video-full-screen) {
            z-index: -1;
          }
          button.close {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 5px;
            background: transparent;
            border: 1px solid #fff;
            color: #fff;
          }

          .btn-mute {
            position: absolute;
            bottom: ${inMeeting ? '80px' : '40px'};
            right: 10px;
            padding: 10px;
            border: 1px solid #fff;
            border-radius: 0px;
            color: #fff;
            background-color: rgba(37, 37, 37, 0.5);
            text-transform: uppercase;
            transition: all 0.3s ease;
          }

          @media (hover: hover) {
            .btn-mute {
              opacity: 0;
            }
            .btn-mute:hover {
              background: rgba(255, 255, 255, 0.7);
              color: #000;
            }
            ::global(.full-screen-video:hover) .btn-mute {
              opacity: 1;
            }
          }

          :global(.close svg) {
            height: 30px;
            width: 30px;
          }

          :global(.full-screen-video.chps-full-screen-video) {
            z-index: ${show ? '10000 !important' : 'inherit'};
          }

          :global(.full-screen-video.chps-full-screen-video) {
            position: ${shouldAbsolute ? 'absolute !important' : 'fixed'};
          }
        `}
      </style>
    </FullScreenVideo>
  );
};

export default ChopardHappySportFullScreenVideo;

import React from 'react';
import { IMeetingStats, IRemoteUserStats } from '../../interfaces';
import {
  MdSignalWifi1Bar,
  MdSignalWifi2Bar,
  MdSignalWifi4Bar
} from 'react-icons/md';
import ConnectionStatsTable from './ConnectionStatsTable';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { zIndex } from '../../config';
const QualityScale = [
  {
    percent: 100,
    label: 'Good',
    colorClass: 'status-high',
    icon: MdSignalWifi4Bar
  },
  {
    percent: 10,
    label: 'Medium',
    colorClass: 'status-med',
    icon: MdSignalWifi2Bar
  },
  {
    percent: 0,
    label: 'Low',
    colorClass: 'status-low',
    icon: MdSignalWifi1Bar
  }
];

function ParticipantConnectionIndicators({
  remoteStats,
  meetingStats,
  participantId,
  isLocal = false
}: {
  meetingStats: IMeetingStats;
  participantId;
  isLocal: boolean;
  remoteStats?: IRemoteUserStats;
}) {
  const [showTable, setShowTable] = React.useState(false);
  const connectionQuality = isLocal
    ? meetingStats?.connectionQuality
    : remoteStats?.connectionQuality;
  const quality = React.useMemo(
    () =>
      QualityScale?.find((q) => connectionQuality >= q.percent) ||
      QualityScale[0],
    [connectionQuality]
  );
  const statsProps = {
    isLocal,
    participantId,
    connectionSummary: quality?.label,
    bandwidth: isLocal ? meetingStats?.bandwidth : null,
    bitrate: isLocal ? meetingStats?.bitrate : remoteStats?.bitrate,
    bridgeCount: meetingStats?.bridgeCount,
    codec: meetingStats?.codec?.[participantId],
    framerate: meetingStats?.framerate?.[participantId],
    resolution: meetingStats?.resolution?.[participantId],
    packetLoss: isLocal ? meetingStats?.packetLoss : remoteStats?.packetLoss,
    maxEnabledResolution: isLocal
      ? meetingStats?.maxEnabledResolution
      : remoteStats?.maxEnabledResolution
  };

  const popover = (
    <Popover
      onMouseEnter={() => setShowTable(true)}
      onMouseLeave={() => setShowTable(false)}
      id="connection-stats-overlay"
    >
      <Popover.Content
        style={{
          backgroundColor: '#1e1e1e',
          color: 'white'
        }}
      >
        <ConnectionStatsTable {...statsProps} />
      </Popover.Content>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        show={showTable}
        placement="auto-end"
        overlay={popover}
        delay={{ show: 250, hide: 500 }}
      >
        <div
          className="connection-indicators"
          id="connectionIndicator"
          onMouseEnter={() => setShowTable(true)}
          onMouseLeave={() => setShowTable(false)}
        >
          <div className={`status-icon ${quality?.colorClass || ''}`}>
            {quality && <quality.icon size={14} color="white" />}
          </div>
        </div>
      </OverlayTrigger>
      <style jsx>{`
        :global(#connection-stats-overlay) {
          z-index: ${zIndex.chatWindow - 1};
        }
        :global(.enlarge-video .connection-indicators) {
          top: 50px !important;
          right: 10px !important;
          bottom: unset !important;
        }
        :global(.enlarge-video .connection-indicators .status-icon) {
          width: 30px;
          height: 30px;
        }
        :global(.local-video-container:hover .connection-indicators),
        :global(.remote-video-container:hover .connection-indicators) {
          display: block;
        }
        .connection-indicators {
          position: absolute;
          bottom: 2px;
          z-index: 100;
          right: 2px;
          cursor: pointer;
          display: ${showTable ? 'block' : 'none'};
        }
        .status-icon {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px white solid;
        }
        .status-icon.status-high {
          background: green;
        }
        .status-icon.status-med {
          background: #ffd740;
        }
        .status-icon.status-low {
          background: #bf2117;
        }
        .connection-table-wrapper {
          background: rgba(0, 0, 0, 0.8);
          color: white;
        }
      `}</style>
    </>
  );
}

export default ParticipantConnectionIndicators;

import axios from 'axios';
import Avatar from '../Avatar';
import { IInspiration } from '../../interfaces';
import React from 'react';
import Placeholder from '../Placeholder';
import { getInspirationIcon } from './common';
import Link from '../Common/Link';
import { useRouter } from 'next/router';

interface ThumbnailProps {
  inspiration: IInspiration;
  index: number;
  title?: string;
  onClickThumbnail?: (inspirationId: string) => void | null;
}

const InspirationThumbnail = ({
  inspiration,
  index,
  title,
  onClickThumbnail
}: ThumbnailProps) => {
  const [isLoading, setIsLoading] = React.useState(null);
  const [isUrlImageError, setIsUrlImageError] = React.useState(false);
  const icon = getInspirationIcon(inspiration);
  const router = useRouter();
  const { nudgeId } = router.query || {};

  React.useEffect(() => {
    if (!inspiration.senderImgUrl) {
      setIsUrlImageError(true);
      return;
    }

    axios
      .post('/api/checkUrl', {
        url: inspiration.senderImgUrl
      })
      .then((res) => {
        setIsUrlImageError(!res.data.accessible);
      })
      .catch(() => setIsUrlImageError(true));
  }, [inspiration.senderImgUrl]);

  return (
    <>
      {isLoading && <Placeholder />}
      {onClickThumbnail && typeof onClickThumbnail === 'function' ? (
        <div
          onClick={() => {
            try {
              sessionStorage.setItem(location.pathname, `${index}`);
            } catch (e) {
              console.log(e);
            }
            onClickThumbnail(inspiration.id);
          }}
          className="thumbnail thumbnail-product-stories"
          id={`inspiration-${inspiration.id}`}
          style={{
            position: 'relative',
            backgroundImage: `url(${inspiration.flipImageUrl})`
          }}
        >
          {!isUrlImageError && <Avatar url={inspiration.senderImgUrl} />}
          {icon}
          <div className="title-container">
            <p className="title">{title || inspiration.title}</p>
          </div>
        </div>
      ) : (
        <Link
          key={inspiration.id}
          href={`/inspirations/[id]${nudgeId ? `?nudgeId=${nudgeId}` : ''}`}
          as={`/inspirations/${
            inspiration.id && encodeURIComponent(inspiration.id)
          }${nudgeId ? `?nudgeId=${nudgeId}` : ''}`}
          onClick={() => {
            try {
              sessionStorage.setItem(location.pathname, `${index}`);
            } catch (e) {
              console.log(e);
            }
            setIsLoading(true);
          }}
          className="thumbnail thumbnail-product-stories"
          id={`inspiration-${inspiration.id}`}
          style={{
            position: 'relative',
            backgroundImage: `url(${inspiration.flipImageUrl})`
          }}
        >
          {!isUrlImageError && <Avatar url={inspiration.senderImgUrl} />}
          {icon}
          <div className="title-container">
            <p className="title">{title || inspiration.title}</p>
          </div>
        </Link>
      )}

      <style jsx global>
        {`
          .inspirations-landing .col-6,
          .inspirations-landing .col-sm-4 {
            padding: 5px;
          }
          .inspirations-list .placeholder-container {
            width: 100%;
            height: 100%;
            left: 20px;
          }
          @media (min-width: 768px) {
            .inspirations-landing .col-6,
            .inspirations-landing .col-sm-4 {
              padding: 10px;
            }
          }

          .thumbnail .avatar {
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 2;
            width: 35px !important;
          }
          @media (min-width: 768px) {
            .thumbnail .avatar {
              width: 60px !important;
            }
          }
        `}
      </style>
      <style jsx>
        {`
          :global(.thumbnail-product-stories) {
            text-align: center;
            background-color: #000;
            background-size: cover;
            background-position: center center;
            width: calc(100vw - 60px);
            margin-right: 10px;
            color: #000;
            text-decoration: none;
            overflow: hidden;
            position: relative;
            height: calc((100vw - 60px) * 1.42);
            cursor: pointer;
            width: 100%;
            height: 0;
            padding-top: 130%;
            display: block;
            float: left;
          }
          .title-container {
            padding: 15px;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: left;
            background-color: rgba(0, 0, 0, 0.7);
            margin: 0;
          }
          .title {
            font-size: 0.8em;
            text-transform: uppercase;
            font-weight: 600;
            width: 100%;
            margin: 0;
            color: #fff;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          @media (min-width: 768px) {
            .title {
              font-size: 0.9em;
              font-weight: 300;
            }
          }
        `}
      </style>
    </>
  );
};

export default InspirationThumbnail;

import React from 'react';
import LoungeAudio from './LoungeAudio';
import { useSelector } from 'react-redux';
import { IMainState, ParticipantMeetingState } from '../../../../interfaces';

const JLCMelbourneCustomComponent = () => {
  const clientState =
    useSelector((state: IMainState) => state.clientState) || {};
  const popup = clientState?.vb?.popup;
  const isPopupOpen = popup?.open;
  const isInLounge =
    clientState?.meeting?.state === ParticipantMeetingState.IN_THE_LOUNGE;

  if (isInLounge) {
    return <LoungeAudio allowToPlay={!isPopupOpen} />;
  }

  return null;
};

export default JLCMelbourneCustomComponent;

import React from 'react';
import {
  manufactureScenes,
  pirelliScenes,
  pirelliScenesRange
} from '../../../../../config/rduSg';
import { IHudState, MeetingLayoutMode, PanoView, RDUHudMenu } from '../../../../../interfaces';
import HudButton from './HudButton';
import HudFrame from './HudFrame';
import HudMain from './HudMain';
import HudManufacture from './HudManufacture';
import HudPirelli from './HudPirelli';
import { sceneAndViewInRange } from './../../../../../utils/stores';
import PreventMobileZoomContainer from '../../../../PreventMobileZoomContainer';

const Hud = ({
  hud,
  currentScene,
  currentView,
  meetingLayout
}: {
  hud?: IHudState;
  currentScene?: string;
  currentView?: PanoView;
  meetingLayout?: MeetingLayoutMode;
}) => {
  const [showFrame, setShowFrame] = React.useState(false);
  const [restrictedNav, setRestricted] = React.useState([RDUHudMenu.QLAB]);
  const isPresentation = meetingLayout === MeetingLayoutMode.PRESENTATION;
  const isSide = meetingLayout === MeetingLayoutMode.NORMAL;

  const handleRemoveRestrictedNav = (nav: RDUHudMenu) => {
    const copy = [...restrictedNav];
    copy.splice(copy.indexOf(nav), 1);
    setRestricted(copy);
  };

  const pirelliInRange =
    pirelliScenes.includes(currentScene) &&
    sceneAndViewInRange(currentView?.viewH, pirelliScenesRange, currentScene);

  const getHud = () => {
    if (manufactureScenes.includes(currentScene)) {
      return {
        extraClass: 'frame-manufacture',
        content: (
          <HudManufacture
            activeMenu={hud?.activeMenu}
            restrictedNav={restrictedNav}
            onRemoveRestrictedNav={(nav) => handleRemoveRestrictedNav(nav)}
          />
        )
      };
    }
    if (pirelliInRange) {
      return {
        extraClass: 'frame-pirelli',
        content: <HudPirelli activeMenu={hud?.activeMenu} />
      };
    }
    return {
      extraClass: 'frame-main',
      content: <HudMain currentScene={currentScene} />
    };
  };

  React.useEffect(() => {
    let timer;
    if (!hud?.open) {
      if (pirelliInRange) {
        window['pirelliHasOpened'] = true;
      }
      timer = setTimeout(() => {
        setShowFrame(false);
      }, 1000);
    } else {
      setShowFrame(true);
      clearTimeout(timer);
    }
  }, [hud?.open]);

  if (!hud?.showButton) return null;
  return (
    <PreventMobileZoomContainer>
      <HudButton hud={hud} className={`${isPresentation ? 'isPresentation' : ''} ${isSide ? 'isSide' : ''}`} />
      {showFrame && (
        <HudFrame
          hud={hud}
          className={`${getHud().extraClass} ${
            isPresentation ? 'isPresentation' : ''
          } ${isSide ? 'isSide' : ''}`}
        >
          {getHud().content}
        </HudFrame>
      )}
    </PreventMobileZoomContainer>
  );
};

export default Hud;

import React from 'react';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { AppointmentType } from '../../../interfaces';
import { DID_CLICK_APPOINTMENT } from '../../../utils/constants';

const AppointmentButtonLink = ({
  label,
  url
}: {
  label?: string;
  url: string;
}) => {
  const { t } = useTranslation();

  const handleOpenAppointment = () => {
    window.open(url);
    logEvent(DID_CLICK_APPOINTMENT, DID_CLICK_APPOINTMENT, {
      type: AppointmentType.EXTERNAL_URL,
      externalUrl: url
    });
  };

  return (
    <div className="AppointmentButton">
      <button
        className="action-button"
        id="ap-open"
        onClick={handleOpenAppointment}
      >
        {label || (
          <>
            <span className="full">{t('vb:make_appointment')}</span>
            <span className="mobile">{t('vb:appointment')}</span>
          </>
        )}
      </button>

      <style jsx>
        {`
          .AppointmentButton {
            position: relative;
          }
          .mobile {
            display: none;
          }

          .full {
            display: inline-block;
          }

          .button-options-list {
            width: 200px;
          }

          @media (max-width: 768px) {
            .mobile {
              display: inline-block;
            }
            .button-options-list {
              width: auto;
            }

            .full {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AppointmentButtonLink;

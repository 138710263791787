import React from 'react';
import { useSelector } from 'react-redux';
import {
  assetBaseUrl,
  vanCleefArpelsUsKingOfPrussiaSiteId
} from '../../../../config';
import { IMainState } from '../../../../interfaces';
import BackgroundMusic from '../../../BackgroundMusic';
import MapWrapper, { MapContext } from '../../MapWrapper';
import VCASVMap from './VCASVMap';
import VCASVMapButton from './VCASVMapButton';
const mp3URL = `${assetBaseUrl}/vca/spring_v1.mp3`;
const assetPath = `${assetBaseUrl}/vca/silicon_valley/v1`;
const assetKOPPath = `${assetBaseUrl}/vca/kingofprussia/v1`;
export const vcaAssets = {
  mapIcon: assetPath + '/vca_map_icon.gif',
  mapFloor: assetPath + '/vca-map.png',
  mapSpot: assetPath + '/map_spot3.png'
};
export const vcaKOPAssets = {
  mapIcon: assetKOPPath + '/vca_map_icon1.gif',
  mapFloor: assetKOPPath + '/VCA_map-min.png',
  mapSpot: assetKOPPath + '/map_spot2.png'
};

const VCASiliconValleyCustomComponent = ({ storeId }: { storeId?: string }) => {
  const { muteBackgroundSound } = useSelector(
    (state: IMainState) => state.clientState.vb || {}
  );

  const isMuted = muteBackgroundSound ?? true;


  return (
    <MapWrapper>
      <MapContext.Consumer>
        {({
          open,
          onToggleMap,
          onSelectSpot,
          visitedSpot,
          currentPosition
        }) => (
          <>
            {storeId === vanCleefArpelsUsKingOfPrussiaSiteId && (
              <BackgroundMusic
                backgroundMusicUrl={mp3URL}
                play={!isMuted}
                loop={!isMuted}
                volume={.1}
              />
            )}
            {!open && (
              <VCASVMapButton onToggleMap={onToggleMap} storeId={storeId} />
            )}
            <VCASVMap
              {...{
                open,
                onToggleMap,
                onSelectSpot,
                visitedSpot,
                currentPosition,
                storeId
              }}
            />
          </>
        )}
      </MapContext.Consumer>
    </MapWrapper>
  );
};

export default VCASiliconValleyCustomComponent;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { IMainState, ParticipantMeetingState } from '../../interfaces';
import { actionEnterMeetingFromWelcomeVideo } from '../../redux/actions';
import {
  DID_CLICK_MUTE_VIDEO,
  DID_CLICK_UNMUTE_VIDEO,
  DID_ENTER_MEETING_FROM_LOUNGE
} from '../../utils/constants';
import FullScreenVideo from '../FullScreenVideo';
import { useAutoPlayVideoMuteControl } from '../hooks/meeting';

const WelcomeVideo = ({
  url,
  allowSkip
}: {
  url: string;
  allowSkip?: boolean;
}) => {
  const [playWithSound, setPlayWithSound] = useAutoPlayVideoMuteControl();
  const meeting = useSelector((state: IMainState) => state.clientState.meeting);
  const meetingState = meeting?.state;
  const dispatch = useDispatch();
  const activeAudioOutput = meeting?.localUser?.activeDevices?.speaker;
  if (meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE) {
    return null;
  }

  const onEnded = () => {
    dispatch(actionEnterMeetingFromWelcomeVideo());
    logEvent(DID_ENTER_MEETING_FROM_LOUNGE, DID_ENTER_MEETING_FROM_LOUNGE, {
      meetingId: meeting?.meetingId,
      participantId: meeting?.localUser?.participantId,
      isPresenter: meeting?.isPresenter
    });
  };
  return (
    <FullScreenVideo
      url={url}
      show={meetingState === ParticipantMeetingState.SHOW_WELCOME_VIDEO}
      playWithSound={playWithSound}
      setPlayWithSound={(playWithSound) => setPlayWithSound(playWithSound)}
      onEnded={onEnded}
      activeAudioOutput={activeAudioOutput}
      fullSize={true}
    >
      <div className="btn-group">
        <button
          className="welcome-mute"
          onClick={() => {
            if (playWithSound) {
              logEvent(DID_CLICK_MUTE_VIDEO, DID_CLICK_MUTE_VIDEO, {
                videoUrl: url
              });
            } else {
              logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO, {
                videoUrl: url
              });
            }
            setPlayWithSound(!playWithSound);
          }}
        >
          {playWithSound ? 'MUTE' : 'UNMUTE'}
        </button>
        {allowSkip && (
          <button className="welcome-skip" onClick={onEnded}>
            SKIP
          </button>
        )}
      </div>
      <style jsx>
        {`
          .btn-group {
            position: fixed;
            bottom: 10px;
            right: 10px;
            width: auto !important;
          }
          button {
            padding: 15px 20px;
            background-color: rgba(37, 37, 37, 0.5);
            border: 1px solid #fff;
            color: #fff;
          }
          .welcome-skip {
            margin-left: -1px;
          }
        `}
      </style>
    </FullScreenVideo>
  );
};

export default WelcomeVideo;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMainState, CheckoutStep } from '../../../interfaces';
import CartTable from './CartTable';
import { useTranslation } from '../../../i18n';
import {
  actionCartAddProductQty,
  actionCartReduceProductQty,
  actionCartUpdateCheckoutStep
} from '../../../redux/actions';
import PoweredByInspify from '../../Legal/PoweredByInspify';
import { logEvent } from '../../../analytics';
import { DID_CLICK_PROCEED_TO_CHECKOUT_BUTTON } from '../../../utils/constants';

const IWCDubaiCheckoutCart = ({ deliveryFee }: { deliveryFee: number }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector(
    (state: IMainState) => state.clientState?.shoppingCart?.items || []
  );
  return (
    <div className="container">
      <div className="cart-container">
        <h2>{t('vb:shopping_bag')}</h2>
        <div className="cart-heading">
          {t('vb:iwc_dubai_purchase_terms')}
          &nbsp;
          <a
            href="https://stores.iwc.com/"
            target="_blank"
            className="purchase-terms-link"
          >
            {t('vb:here')}
          </a>
        </div>
        <CartTable
          items={items}
          onIncreaseQty={(id) => dispatch(actionCartAddProductQty(id))}
          onReduceQty={(id) => dispatch(actionCartReduceProductQty(id))}
          deliveryFee={deliveryFee}
        />
      </div>
      <div className="input-group">
        <button
          onClick={() => {
            logEvent(DID_CLICK_PROCEED_TO_CHECKOUT_BUTTON);
            dispatch(
              actionCartUpdateCheckoutStep(CheckoutStep.DELIVERY_ADDRESS)
            );
          }}
          id="sendform"
          type="button"
          className="checkout-button"
        >
          {t(`vb:proceed_to_checkout`)}
        </button>
      </div>
      <div className="additional-disclaimer">
        <p>{t('vb:products_timeslot_confirmed')}</p>
      </div>
      <PoweredByInspify />
      <style jsx>{`
        .container {
          padding-left: 0;
          padding-right: 0;
        }
        .cart-heading {
          text-align: justify;
        }
        .purchase-terms-link {
          text-decoration: underline;
          color: blue;
        }
        .checkout-button {
          padding: 0 30px;
          align-self: center;
          margin: auto;
        }
        .additional-disclaimer {
          text-align: center;
        }
        :global(.isRtl .cart-heading) {
          text-align: right !important;
        }
        :global(.appointment) h2 {
          text-align: left;
        }
        :global(.isRtl .appointment) h2 {
          text-align: right;
        }
        @media (min-width: 992px) {
          .cart-container {
            padding-right: 40px;
            padding-top: 8px;
          }
          .position-sticky {
            top: 0;
          }
          .cart-heading {
            margin-bottom: 30px;
          }
          .checkout-button {
            width: 40% !important;
          }
        }
      `}</style>
    </div>
  );
};

export default IWCDubaiCheckoutCart;

import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { useTranslation } from '../../../../../i18n';
import { IAnimationProduct } from '../../../../../interfaces/loubiairways';
import {
  actionAddToWishlist,
  actionRemoveFromWishlist
} from '../../../../../redux/customActions/loubiAirways';
import {
  DID_ADD_TO_WISHLIST,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';
import { LoubiAirFontFamily } from '../../../../BrandStyle/LouboutinStyle';
import {
  shouldEnableLikeAndReservationForLoubiJp,
  useLoubiAirwaysState
} from '../../../../hooks/loubiAirways';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';

const LoubiAirwaysWishlist = ({ product }: { product: IAnimationProduct }) => {
  const loubiState = useLoubiAirwaysState();
  const wishlist = loubiState?.wishlist?.wishlistItems || [];
  const showWishlist = shouldEnableLikeAndReservationForLoubiJp();
  const { t } = useTranslation();
  const loubiFont = LoubiAirFontFamily();
  const dispatch = useDispatch();
  const alreadyInWishlist = wishlist.some((p) => p.itemId === product.id);

  if (!showWishlist) return null;
  return (
    <>
      <div
        className={`favorite-button add-to-wishlist ${
          alreadyInWishlist ? 'hidden' : ''
        }`}
        onClick={() => {
          dispatch(actionAddToWishlist(product));
          logEvent(DID_ADD_TO_WISHLIST, DID_ADD_TO_WISHLIST, {
            id: product.id,
            modelCode: product.modelCode,
            modelName: product.modelName
          });
        }}
      >
        <img src="/asset/louboutin/wishlist_empty.svg" alt="Add to Wishlist" />
        <p>{t('vb:add_to_favourites')}</p>
      </div>
      <div
        className={`favorite-button remove-from-wishlist ${
          !alreadyInWishlist ? 'hidden' : ''
        }`}
        onClick={() => {
          dispatch(actionRemoveFromWishlist(product));
          logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
            id: product.id,
            modelCode: product.modelCode,
            modelName: product.modelName
          });
        }}
      >
        <img
          src="/asset/louboutin/wishlist_filled.svg"
          alt="Remove from Wishlist"
        />
        <p className="red">{t('vb:added_to_favourites')}</p>
      </div>

      <style jsx>{`
        .favorite-button {
          position: fixed;
          top: 38px;
          left: 50%;
          cursor: pointer;
          transform: translateX(-50%);
          z-index: 3;
          text-align: center;
        }
        
        :global(.in-meeting.MD .loubi-airways-animation) .favorite-button,
        :global(.in-meeting.MD .twod-product) + .favorite-button{
          top:${MDLandscapeNormalSpec.contentArea.top + 28}px;
        }
        :global(.in-meeting.MDLandscape .loubi-airways-animation) .favorite-button,
        :global(.in-meeting.MDLandscape .twod-product) + .favorite-button{
          margin-left:${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }
        :global(.in-meeting.SM .loubi-airways-animation) .favorite-button,
        :global(.in-meeting.SM .twod-product) + .favorite-button{
          top:${SMLandscapeNormalSpec.contentArea.top + 18}px;
        }
        :global(.in-meeting.SMLandscape .loubi-airways-animation) .favorite-button
        :global(.in-meeting.SMLandscape .twod-product) + .favorite-button{
          margin-left:${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }
        .favorite-button.hidden {
          z-index: 2;
          opacity: 0.01;
        }
        .favorite-button.hidden p {
          display: none;
        }
        p {
          color: #fff;
          font-family: ${loubiFont};
          font-size: 0.7em;
          margin-top: 5px;
        }
        .red {
          color: #cf152d;
        }
        img {
          width: 72px;
          height: auto;
        }
        .favorite-button:active {
          transform: translate(-50%, 10%);
        }
        @media (max-width: 768px) {
          .favorite-button {
            top: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default LoubiAirwaysWishlist;

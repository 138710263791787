import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { failedToLoadImagePlaceholder } from '../../../config';
import { useTranslation } from '../../../i18n';
import { IProduct } from '../../../interfaces';
import { PageView } from '../../../utils/pageView';
import {
  getProductDescription,
  getProductDisplayName,
  getProductDisplayPrice
} from '../../../utils/product';
import { getNudgeCurrencyCountryCode } from '../../../utils/window';
import { AppContext } from '../../AppContext';
import EyeballTimeLogger from '../../EyeballTimeLogger';
import Header from '../../Header';
import { EOLProducts } from '../../PixelStream/Chopard/CHConfig';
import Slider from '../Slider';
import ChopardDetailSpecifications from './ChopardDetailSpecifications';

export interface IChopardProductDetailsProp {
  colors: string[];
  product: IProduct;
  handleColorClick: (index: number) => void;
}

const ChopardProductDetails = ({
  product,
  colors,
  handleColorClick
}: IChopardProductDetailsProp) => {
  const { t, i18n } = useTranslation();
  const { country, store, storeConfig } = React.useContext(AppContext);
  const language = i18n.language;

  const displayCurrencyCountryCode =
    getNudgeCurrencyCountryCode() || (store && store.countryCode) || country;


  const price = getProductDisplayPrice(
    product,
    displayCurrencyCountryCode,
    storeConfig?.strictProductDisplay
  );
  const { imageUrls, modelCode } = product;

  const description = getProductDescription(product, language);
  const EOLProduct = EOLProducts.includes(product.id);

  return (
    <>
      <Header
        title={t('product_details').toUpperCase()}
        shareTitle={getProductDisplayName(product, language)}
        shareImage={product.imageUrls.length > 0 && product.imageUrls[0]}
        type={PageView.PRODUCT}
        id={product.id}
        storeId={store.id}
        product={product}
      />
      <EyeballTimeLogger tracker={{ view: PageView.PRODUCT, id: product.id }}>
        <Container
          className={`product-landing ${!EOLProduct ? 'product-sale' : ''}`}
        >
          <Row>
            <Col sm={12} className="slider-container">
              <div className="slider-inner sticky-top">
                {imageUrls && imageUrls.length > 0 ? (
                  <Slider
                    key={product.id}
                    imageUrls={imageUrls}
                    allowZoom={storeConfig?.entitlement?.productImageZoom}
                  />
                ) : (
                  <img src={failedToLoadImagePlaceholder} />
                )}
              </div>
            </Col>

            <Col sm={12} className="product-info">
              <div className="modelcode">
                <h1 className="chopard-title chopard-serif">
                  {getProductDisplayName(product, language)}
                </h1>
                {EOLProduct && (
                  <div className="descriptions-container chopard-sans noselect">
                    {description?.includes('\n')
                      ? description
                          .split('\n')
                          .map((d, i) => <p key={`${d}-${i}`}>{d}</p>)
                      : description}
                  </div>
                )}
              </div>
              {!EOLProduct && (
                <>
                  <div className="price-container chopard-sans">
                    <p className="price">{price}</p>
                    <p className="ref_number">
                      {t('ref_code')}: {modelCode}
                    </p>
                  </div>
                  <Row className="colors-row">
                    <Col xs={12}>
                      {colors.length > 1 && (
                        <>
                          <p className="light-text">{t('colors')}</p>
                          <div className="colors">
                            {colors.map((color: string, index: number) => (
                              <Image
                                className={
                                  (product.productColorImageURL === color &&
                                    'active') ||
                                  ''
                                }
                                src={color}
                                key={index}
                                alt={color}
                                onError={(ev) =>
                                  ((ev.target as any).src =
                                    failedToLoadImagePlaceholder)
                                }
                                onClick={() => handleColorClick(index)}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>

                  <ChopardDetailSpecifications product={product} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </EyeballTimeLogger>
      <style jsx global>
        {`
          .price-container {
            border-top: 1px solid #c4c4c4;
            border-bottom: 1px solid #c4c4c4;
            padding: 10px 0 15px 0;
            margin: 20px 0;
            text-align: center;
          }
          .price {
            margin: 0 auto;
            font-size: 1.5em;
          }
          .ref_number {
            color: #666;
            margin: 0 auto;
          }
          .section-loading {
            display: none;
          }
          header {
            left: 0;
          }
          .content-wrapper > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }

          .content-wrapper > div > div {
            margin: auto;
            width: 100%;
          }

          .product-page,
          .product-page .modelcode {
            color: #000 !important;
          }

          .modelcode .descriptions-container {
            color: #666 !important;
            margin: auto;
            max-width: 460px;
            text-transform: uppercase;
          }
          .chopard-title {
            font-weight: 400;
            text-transform: uppercase;
            margin: 12px 0 10px;
            font-size: 1.85rem;
            letter-spacing: 0.1em;
            text-align: center;
          }
          .descriptions-container {
            font-size: 0.85rem;
          }
          .slider-inner .react-multi-carousel-list img {
            width: 100%;
            max-width: min(800px, 70vh);
            height: auto;
          }
          .product-info {
            text-align: justify;
          }
          .product-info .descriptions-container {
            text-align-last: center;
            padding-bottom: 20px;
          }
          .btn-personalize {
            border-color: #000;
          }

          .discover-more {
            cursor: pointer;
            position: absolute;
            top: 40px;
            right: 0;
            width: 150px;
            height: 195px;
          }
          .colors-row {
            text-align: center;
            display: none;
          }
          .discover-more-mobile-click-area {
            cursor: pointer;
            position: absolute;
            top: 40px;
            right: 0;
            width: 150px;
            height: 195px;
            z-index: 99;
          }

          .compare-wishlist {
            display: flex;
            justify-content: space-between;
          }

          .compare-wishlist > :global(p),
          .compare-wishlist > :global(div) {
            margin-left: auto;
            margin-right: auto;
          }

          :global(.de) .compare-wishlist > :global(p),
          :global(.de) .compare-wishlist > :global(div) {
            font-size: 0.7em;
          }

          :global(.product-landing .accordion .card) {
            border: none;
            border-bottom: 1px dotted;
            border-radius: 0;
          }

          :global(.product-landing .card-header) {
            cursor: pointer;
            background: none;
            border: none;

            padding: 0.75rem 0;
          }

          :global(.product-landing .card-header svg) {
            float: right;
            margin-top: 3px;
          }

          :global(.product-landing .details-accordion) {
            border: none;
            border-radius: 0;
          }

          :global(.product-landing .card-body) {
            padding: 0.9rem 0 1.45rem;
            font-size: 0.9em;
          }
          :global(.product-landing) {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 20px;
          }
          @media (max-width: 576px) {
            .slider-inner :global(.react-multi-carousel-list) {
              min-height: calc(100vw - 30px);
            }
            :global(.product-landing) {
              padding-top: 20px;
            }
          }
          @media (min-width: 576px) {
            .slider-inner {
              top: 69px;
            }
            :global(.product-landing) {
              padding-top: 20px;
              padding-bottom: 30px;
            }
            .compare-wishlist :global(p),
            .compare-wishlist :global(div) {
              margin-left: 0;
              margin-right: 0;
            }

            :global(.de) .compare-wishlist {
              display: block;
            }
          }

          @media (min-width: 768px) {
            .slider-inner {
              top: 0px;
            }

            :global(.product-landing) {
              padding-bottom: 70px;
            }

            .discover-more {
              position: absolute;
              top: 50px;
              right: 0;
              width: 278px;
              height: 360px;
            }

            .discover-more-mobile-click-area {
              display: none;
            }
          }

          @media (min-width: 576px) {
            :global(.product-landing) {
              max-width: 1200px !important;
            }
          }

          @media (min-width: 1200px) {
            :global(.product-landing) {
              max-width: 1200px !important;
            }
          }

          @media (min-width: 1024px) {
            .product-sale .slider-container {
              width: 50%;
              max-width: 50%;
            }
            .product-sale .chopard-title,
            .price-container {
              text-align: left;
            }
            .product-sale .product-info {
              text-align: left;
              width: 50%;
              max-width: 50%;
            }
            .product-sale .modelcode .descriptions-container {
              max-width: none;
            }
            :global(.product-landing) {
              padding-left: 10px;
              padding-right: 10px;
              padding-top: 20px;
            }
            :global(.de) .compare-wishlist {
              display: flex;
            }
            :global(.de) .compare-wishlist > :global(p),
            :global(.de) .compare-wishlist > :global(div) {
              font-size: 0.75em;
            }
          }

          .product-landing {
            position: relative;
          }

          .product-edition {
            font-size: 14px;
            margin: 10px 0;
            font-style: italic;
          }

          .description-link {
            margin: 10px 0 20px;
            font-weight: 400;
          }

          .description-link :global(a) {
            color: #000;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
};

export default ChopardProductDetails;

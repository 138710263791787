import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../i18n';
import { IStore, IVirtualBoutiqueConfig } from '../../../../interfaces';
import { actionPanoLoaded } from '../../../../redux/actions';
import { getVirtualBoutiqueMode } from '../../../../utils/stores';
import { getVBLite } from '../../../storeComponentFactory';
import CanvasBasedExperience from '../../CanvasBasedExperience';
import { getInitialVariables } from '../../Pano/storeInitialState';
import VirtualBoutiqueContent from './../../index';
import { allTeleportSites } from './InspifyTeleportMenu';
import * as Sentry from '@sentry/node';
import PixelStreamExperience from '../../../PixelStream';

const InspifyTeleportSite = ({
  config,
  store
}: {
  config: IVirtualBoutiqueConfig;
  store: IStore;
}) => {
  const dispatch = useDispatch()
  const router = useRouter();
  const [isPreviousPano, setIsPreviousPano] = React.useState(false);
  const vbMode = getVirtualBoutiqueMode(router.query.mode as string);
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const pano = config?.pano;
  const currentStore = store?.id;
  const isPano = (id:string) => allTeleportSites.find(site => site.id === id)?.type === 'pano';
  const isCurrentStorePano = isPano(currentStore)

  React.useEffect(()=>{
    setIsPreviousPano(isCurrentStorePano)
    if(isPreviousPano && isCurrentStorePano){
      removepano('krpanoSWFObject');
      embedpano({
        swf: pano?.swf,
        xml: pano?.xml,
        target: 'embedframe',
        html5: 'auto',
        consolelog: location.hostname === 'localhost',
        mobilescale: 1.0,
        passQueryParameters: true,
        basepath: pano?.basepath,
        initvars: getInitialVariables(
          store.id,
          router.query.source as string,
          router.query.go as string
        ),
        onready: () => {
          console.log('KRPano ready');
          setTimeout(() => {
            dispatch(actionPanoLoaded(true));
          }, 5000);
        },
        onerror: (error) => {
          console.log(`KRPano error`);
          console.log(error);
          Sentry.captureException(error);
        }
      })
    }

  },[currentStore])

  const getPanoBoutique = () => {
    return (
      <VirtualBoutiqueContent
        config={config}
        store={store}
        vbMode={vbMode}
        className={`${i18n.language || 'en'} ${isRtl ? 'isRtl' : ''}`}
      />
    );
  };

  if (config.canvasBasedExperience) {
    return <CanvasBasedExperience config={config} store={store} />;
  }
  if (config.unRealBasedExperience) {
    return <PixelStreamExperience config={config} store={store} />;
  }
  return (
    <>{config.pano ? getPanoBoutique() : getVBLite(config, store, vbMode)}</>
  );
};

export default InspifyTeleportSite;

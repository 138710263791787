import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { useTranslation } from '../../../../../i18n';
import { IProduct } from '../../../../../interfaces';
import {
  DID_CLICK_ADD_TO_RESERVATION,
  DID_CLICK_REMOVE_FROM_RESERVATION
} from '../../../../../utils/constants';
import { useCartItemState } from '../../../../hooks/cart';
import {
  shouldEnableLikeAndReservationForLoubiJp
} from '../../../../hooks/loubiAirways';
import {
  actionCartAddProduct,
  actionCartRemoveProduct
} from './../../../../../redux/actions';

const AddToReservationButton = ({
  product,
  productSize,
  buttonSize,
  disabled,
  autoOpenPopup = true,
  onAdd = () => null,
  onCancel = () => null
}: {
  product: IProduct;
  productSize?: string;
  buttonSize?: 'large' | 'small';
  disabled?: boolean;
  autoOpenPopup?: boolean;
  onAdd?: () => void;
  onCancel?: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reservationItems = useCartItemState();
  const showReservation = shouldEnableLikeAndReservationForLoubiJp();

  const hasAdded = reservationItems.some((item) => item.id === product.id);

  const isSoldOut = product?.id === 'd8fcc728-838e-47a5-ace4-7e0b206d47f4';

  const disableButton = isSoldOut || (hasAdded ? false : disabled);

  const buttonText = () => {
    if (isSoldOut) return  t('vb:sold out').toLocaleUpperCase();

    return hasAdded ? 'CANCEL RESERVATION' : t('vb:add_to_reservation');
  };

  const handleReservation = () => {
    if (hasAdded) {
      onCancel();
      dispatch(actionCartRemoveProduct(product.id));
      logEvent(
        DID_CLICK_REMOVE_FROM_RESERVATION,
        DID_CLICK_REMOVE_FROM_RESERVATION,
        {
          id: product.id
        }
      );
    } else {
      onAdd();
      dispatch(actionCartAddProduct(product, productSize, autoOpenPopup));
      logEvent(DID_CLICK_ADD_TO_RESERVATION, DID_CLICK_ADD_TO_RESERVATION, {
        id: product.id,
        size: productSize
      });
    }
  };
  if (!showReservation) return null;
  return (
    <button
      className={`btn btn-reservation ${
        buttonSize === 'large' ? 'btn-lg' : ''
      } ${buttonSize === 'small' ? 'btn-sm' : ''} ${
        hasAdded ? 'btn-outline-danger' : 'btn-danger'
      }`}
      onClick={handleReservation}
      disabled={disableButton}
    >
      {buttonText()}
      <style jsx>{`
        .btn-reservation {
          font-family: 'AntiqueOlive-Bold', Arial;
          border-radius: ${buttonSize === 'large' ? 20 : 10}px;
        }
        .btn-danger {
          background-color: #bf080e;
        }
        .btn-outline-danger {
          border-color: #bf080e;
          color: #bf080e;
          border-width: 2px;
        }
        .btn-outline-danger:hover {
          color: #fff;
        }
      `}</style>
    </button>
  );
};

export default AddToReservationButton;

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { IMainState, MeetingRole, LoadingStatus } from '../../../interfaces';
import { getMeetingRole } from '../../../utils/meeting';
import { SessionContext } from '../../VirtualBoutique/SessionContextContainer';
import { MeetingContext } from '../MeetingContext';

const LoungeNameInput = ({
  setShowError,
  role,
  source
}: {
  setShowError: (boolean: boolean) => void;
  role: string;
  source: string;
}) => {
  const clientState = useSelector((state: IMainState) => state.clientState);
  const user = clientState?.user;
  const userLoadState = clientState?.userLoadState;
  const { t } = useTranslation();

  const isAdvisor = getMeetingRole(role) === MeetingRole.ADVISOR;

  const [name, setName] = React.useState(isAdvisor ? user?.alias || '' : '');

  const { setUserName } = React.useContext(MeetingContext);
  const { session } = React.useContext(SessionContext);

  const handleNameInput = (name: string) => {
    const hasInput = name && name.trim().length > 0;
    setShowError(!hasInput);
    setUserName(name);
  };

  React.useEffect(() => {
    if (session && source === 'session' && isAdvisor) {
      if (userLoadState === LoadingStatus.LOADED) return;
      if (userLoadState === LoadingStatus.FAILED) {
        const host = session.participants?.find(
          (p) => p.role.toLowerCase() === 'host'
        );
        if (host?.name) {
          setUserName(host.name);
        }
      }
    }
  }, [session, userLoadState]);

  React.useEffect(() => {
    if (user?.alias) {
      setName(user.alias);
      setShowError(false);
    }
  }, [user?.alias]);

  return (
    <div className="lounge-name-input">
      <p className="reminder">{t('please_enter_your_name')}</p>
      <div className="form-group">
        <input
          id="lounge-name-input"
          type="text"
          className="input-alias"
          name="loungeinput"
          value={name}
          data-lpignore="true"
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => handleNameInput(e.target.value)}
          placeholder={t('enter_your_name')}
          autoComplete="name"
          role="combobox"
        />
      </div>
      <style jsx>
        {`
          .lounge-name-input {
            padding: 20px 20px 10px;
          }

          p {
            text-align: left;
            font-size: 0.9em;
            line-height: 1.4;
            margin-bottom: 14px;
          }

          .form-group {
            margin: 0;
            border-bottom: 1px dashed #666;
            padding-bottom: 5px;
          }
          .input-alias {
            width: 100%;
          }
          .input-alias,
          .input-alias:focus,
          .input-alias:active {
            font-size: 16px;
            line-height: 1.4;
            background: transparent;
            border: none;
            outline: none;
          }

          @media screen and (orientation: landscape) and (max-height: 767px) {
            .lounge-name-input {
              padding: 14px 18px;
            }
          }

          @media (min-width: 768px) and (min-height: 520px) {
            p {
              font-size: 0.875em;
              line-height: 1.188em;
              margin-bottom: 25px;
            }
          }

          @media (min-width: 1023px) {
            p {
              font-size: 1em;
              line-height: 1.375em;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoungeNameInput;

import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSessionById } from '../../clientSideServices/sessions';
import { ISessionDetails, ISessionPayload } from '../../interfaces';
import {
  actionDidLoadFileList,
  actionSetMeetingAutoAdmit
} from '../../redux/actions';
import { INS_SESSION_ID } from '../../utils/constants';
import {
  getHostAuthRedirect,
  getSessionAttachedFiles,
  getSessionStorybookFiles,
  getSessionUrlWithRegion
} from '../../utils/session';
import { goToUrl } from '../../utils/window';
import Placeholder from '../Placeholder';

export enum SessionLoadingState {
  LOADING,
  LOADED,
  FAILED
}

export interface ISessionContext {
  shouldMuteAudio: boolean;
  shouldMuteVideo: boolean;
  allowLateComerAutoJoin: boolean;
  session: ISessionDetails;
  sessionLoadingStatus: SessionLoadingState;
  loadSession: () => void;
}

export const SessionContext = React.createContext<ISessionContext>(undefined);

const SessionContextContainer = ({
  brandId,
  source,
  meetingId,
  role,
  children
}: {
  brandId: string;
  source?: string;
  meetingId?: string;
  role?: string;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const [session, setSession] = React.useState<ISessionDetails>();
  const [loadState, setLoadState] = React.useState<SessionLoadingState>(
    SessionLoadingState.LOADING
  );
  const router = useRouter();
  const isHost =
    role?.toLowerCase() === 'advisor' || role?.toLowerCase() === 'mc';
  const isParticipant = role?.toLowerCase() === 'client' || !role;

  const handleSessionFiles = (session: ISessionPayload) => {
    const storybookFiles = getSessionStorybookFiles(session);
    const attachedFiles = getSessionAttachedFiles(session);
    const allFiles = [...storybookFiles, ...attachedFiles];
    dispatch(actionDidLoadFileList({ files: allFiles }));
  };

  const loadSession = () => {
    if (source === 'session' && meetingId) {
      setLoadState(SessionLoadingState.LOADING);
      getSessionById(meetingId)
        .then((result) => {
          const session = result.data;
          const redirectUrl = getSessionUrlWithRegion(
            location.href,
            session.region,
            router.query.region
          );
          if (redirectUrl) {
            goToUrl(redirectUrl);
            return;
          }
          setSession(session);
          dispatch(actionSetMeetingAutoAdmit(!!session.joinAnytime));
          setLoadState(SessionLoadingState.LOADED);
          handleSessionFiles(session);
          return getHostAuthRedirect(role, brandId);
        })
        .then((redirectUrl) => {
          if (redirectUrl) {
            goToUrl(redirectUrl);
          }
        })
        .catch((e) => {
          setLoadState(SessionLoadingState.FAILED);
          console.error(e);
        });
    } else {
      setLoadState(SessionLoadingState.FAILED);
    }
  };

  React.useEffect(() => {
    if (source === 'session' && meetingId) {
      window[INS_SESSION_ID] = meetingId;
    }
    loadSession();
  }, []);

  const contextValue: ISessionContext = {
    shouldMuteVideo: isHost
      ? !!session?.hostMuteVideo
      : !!session?.participantMuteVideo,
    shouldMuteAudio: isParticipant && !!session?.muteUponEntry,
    allowLateComerAutoJoin: !!session?.joinAnytime,
    session,
    sessionLoadingStatus: loadState,
    loadSession
  };

  const showLoading = () => {
    if (source === 'session' && meetingId) {
      return !session && loadState === SessionLoadingState.LOADING;
    }
    return false;
  };

  // TODO: Pending refactoring of `loadSession` and its caller. Should be `refreshSessionFiles`
  return (
    <SessionContext.Provider value={contextValue}>
      {showLoading() ? <Placeholder /> : children}
    </SessionContext.Provider>
  );
};

export default SessionContextContainer;

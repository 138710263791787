import React from 'react';
import { logEvent } from '../../../analytics';
import { vanCleefArpelsUsKingOfPrussiaSiteId } from '../../../config';
import { useTranslation } from '../../../i18n';
import { IProduct } from '../../../interfaces';
import { DID_CLICK_ORDER_NOW_BUTTON } from '../../../utils/constants';

const VCAKingOfPrussiaProductActionButton = ({
  product,
  storeId
}: {
  product: IProduct;
  storeId: string;
}) => {
  const { t } = useTranslation();
  const productsOrderUrl = {
    VCARA42800: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcara42800---vintage-alhambra-necklace-10-motifs.html'
    },
    VCARA45900: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcara45900---vintage-alhambra-pendant.html'
    },
    VCARF69100: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarf69100---sweet-alhambra-pendant.html'
    },
    VCARA44800: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcara44800---sweet-alhambra-earstuds.html'
    },
    VCARA41800: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcara41800---vintage-alhambra-bracelet-5-motifs.html'
    },
    VCARD22000: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/alhambra-watches/vcard22000---alhambra-watch-small-model.html'
    },
    VCARP6LA00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarp6la00---magic-alhambra-long-necklace-1-motif.html'
    },
    VCARP34900: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarp34900---vintage-alhambra-bracelet-5-motifs.html'
    },
    VCARO56300: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcaro56300---perlee-clovers-bracelet-large-model.html'
    },
    VCARP3K900: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarp3k900---perlee-signature-bracelet-large-model.html'
    },
    VCARO3Y649: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcaro3y600---perlee-signature-ring.html'
    },
    VCARP4E154: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarp4e100---perlee-diamonds-ring-1-row.html'
    },
    VCARP7SO00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/other-collections/zodiaque/vcarp7so00---zodiaque-medal-leonis-leo.html'
    },
    VCARP7SZ00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/other-collections/zodiaque/vcarp7sz00---zodiaque-medal-scorpii-scorpio.html'
    },
    VCARP7U600: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/other-collections/zodiaque/vcarp7u600---trace-chain-70-cm.html'
    },
    VCARN25400: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarn25400---frivole-necklace-9-flowers.html'
    },
    VCARB65800: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarb65800---frivole-earrings-small-model.html'
    },
    VCARP6L700: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarp6l700---frivole-bracelet-5-flowers.html'
    },
    VCARB67553: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarb67500---frivole-between-the-finger-ring.html'
    },
    VCARP6L049: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarp6l000---frivole-between-the-finger-ring.html'
    },
    VCARP2DW00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarp2dw00---frivole-clip-pendant-very-large-model.html'
    },
    VCARP0J300: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarp0j300---frivole-earrings-mini-model.html'
    },
    VCARP6L100: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarp6l100---frivole-bracelet-7-flowers-small-model.html'
    },
    VCARB67751: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/frivole/vcarb67700---frivole-ring-8-flowers.html'
    },
    VCARD30300: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/rose-de-noel/vcard30300---rose-de-noel-clip-pendant-small-model.html'
    },
    VCARA55600: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/rose-de-noel/vcara55600---rose-de-noel-earrings-small-model.html'
    },
    VCARN18900: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarn18900---magic-alhambra-necklace-6-motifs.html'
    },
    VCARL62400: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarl62400---magic-alhambra-bracelet-5-motifs.html'
    },
    VCARN9MS00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarn9ms00---magic-alhambra-pendant.html'
    },
    VCARA46100: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcara46100---vintage-alhambra-pendant.html'
    },
    VCARN9WU00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarn9wu00---magic-alhambra-ring.html'
    },
    VCARF48600: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarf48600---vintage-alhambra-earrings.html'
    },
    VCARF48400: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarf48400---vintage-alhambra-bracelet-5-motifs.html'
    },
    VCARF48952: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarf48900---vintage-alhambra-ring.html'
    },
    VCARP6XA00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarp6xa00---perlee-sweet-clovers-bracelet-medium-model.html'
    },
    VCARO7A900: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcaro7a900---perlee-perles-dor-bracelet.html'
    },
    VCARN9Q051: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarn9q000---perlee-diamonds-ring-3-rows.html'
    },
    VCARP6MN53: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarp6mn00---perlee-sweet-clovers-ring.html'
    },
    VCARN9PC60: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarn9pc00---perlee-pearls-of-gold-ring-medium-model.html'
    },
    VCARP4KM00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarp4km00---vintage-alhambra-long-necklace-20-motifs.html'
    },
    VCARA44200: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcara44200---vintage-alhambra-earrings.html'
    },
    VCARP7UM00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/fauna/lucky-animals/vcarp7um00---lucky-animals-cat-clip.html'
    },
    VCARO8ZV00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/alhambra-watches/vcaro8zv00---alhambra-secret-pendant-watch.html'
    },
    VCARP2AT00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/fauna/lucky-animals/vcarp2at00---lucky-animals-squirrel-clip.html'
    },
    VCARN59K00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarn59k00---sweet-alhambra-bracelet.html'
    },
    VCARN59L00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarn59l00---sweet-alhambra-heart-bracelet.html'
    },
    VCARP6I200: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarp6i200---magic-alhambra-long-necklace-1-motif.html'
    },
    VCARP2R100: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarp2r100---vintage-alhambra-bracelet-5-motifs.html'
    },
    VCARP7UO00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/fauna/lucky-animals/vcarp7uo00---lucky-animals-pig-clip.html'
    },
    VCARO2AF00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcaro2af00---magic-alhambra-long-necklace-16-motifs.html'
    },
    VCARL80900: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarl80900---vintage-alhambra-bracelet-5-motifs.html'
    },
    VCARP5LK00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/fauna/lucky-animals/vcarp5lk00---lucky-animals-turtle-clip.html'
    },
    VCARO6P700: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/lotus/vcaro6p700---lotus-clip-pendant-large-model.html'
    },
    VCARO8O400: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/poetic-complications/vcaro8o400---lady-arpels-day-and-night-fee-ondine-watch.html'
    },
    VCARP05000: {
      url: 'https://www.vancleefarpels.com/us/en/collections/high-jewelry/classic-high-jewelry/flowerlace/vcarp05000---flowerlace-necklace.html'
    },
    VCARO8O000: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/poetic-complications/vcaro8o000---lady-jour-des-fleurs-watch.html'
    },
    VCARP40C50: {
      url: 'https://www.vancleefarpels.com/us/en/collections/engagement/engagement-rings/vcarg34100---icone-solitaire-100-ct-dif.html'
    },
    VCARO1WO50: {
      url: 'https://www.vancleefarpels.com/us/en/collections/engagement/wedding-bands/creative-and-paved-w/vcaro1wo00---romance-wedding-band.html'
    },
    VCARO5BV00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/flora/cosmos/vcaro5bv00---cosmos-clip-pendant-medium-model.html'
    },
    VCARO8FN00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/fauna/two-butterfly/vcaro8fn00---two-butterfly-earrings.html'
    },
    VCARO7AL49: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/fauna/two-butterfly/vcaro7al00---two-butterfly-between-the-finger-ring.html'
    },
    VCARO8SQ00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/high-jewelry-watches/vcaro8sq00---primerose-secrete-watch.html'
    },
    VCARO8SO00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/high-jewelry-watches/vcaro8so00---cosmos-secret-watch.html'
    },
    VCARO3RO00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/high-jewelry/classic-high-jewelry/snowflake/vcaro3ro00---snowflake-pendant.html'
    },
    VCARP39700: {
      url: 'https://www.vancleefarpels.com/us/en/collections/high-jewelry/classic-high-jewelry/snowflake/vcarp39700---snowflake-noeud-clip.html'
    },
    VCARO3RM50: {
      url: 'https://www.vancleefarpels.com/us/en/collections/high-jewelry/classic-high-jewelry/snowflake/vcaro3rm00---snowflake-ring.html'
    },
    VCARO8TY00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/poetic-complications/vcaro8ty00---lady-arpels-pont-des-amoureux-watch.html'
    },
    VCARO8ZD00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/poetic-complications/vcaro8zd00---lady-feerie-watch.html'
    },
    VCARN25800: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/poetic-complications/vcarn25800---lady-arpels-day-and-night-watch.html'
    },
    VCARO4IK00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/watches/cadenas/vcaro4ik00---cadenas-watch.html'
    },
    VCARP27J00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/perlee/vcarp27j00---perlee-diamond-bracelet-1-row.html'
    },
    VCARN9WU52: {
      url: 'https://www.vancleefarpels.com/us/en/collections/jewelry/alhambra/vcarn9wu00---magic-alhambra-ring.html'
    },
    VCARG34100: {
      url: 'https://www.vancleefarpels.com/us/en/collections/engagement/engagement-rings/vcarg34100---icone-solitaire-100-ct-dif.html'
    },
    VCARO3RM00: {
      url: 'https://www.vancleefarpels.com/us/en/collections/high-jewelry/classic-high-jewelry/snowflake/vcaro3rm00---snowflake-ring.html'
    }
  };
  return productsOrderUrl[product.modelCode] &&
    storeId === vanCleefArpelsUsKingOfPrussiaSiteId ? (
    <div className="action-buttons">
      <button
        className="btn btn-dark btn-action"
        onClick={() => {
          logEvent(DID_CLICK_ORDER_NOW_BUTTON, product.id);
          window.open(
            productsOrderUrl[product.modelCode].url +
              '?utm_source=vancleefarpels&utm_medium=virtualpop&utm_campaign=kingofprussia'
          );
        }}
      >
        {t(`order_now`)}
      </button>
    </div>
  ) : null;
};

export default VCAKingOfPrussiaProductActionButton;

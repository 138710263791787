import React from 'react';
import { IProduct, IVirtualBoutiqueContact } from '../../../interfaces';
import {
  getProductDisplayName,
  getProductOriginalLink
} from '../../../utils/product';
import { isChatAvailable } from '../../../utils/stores';
import { AppContext } from '../../AppContext';
import {
  getPiagetBoutiqueChatButton,
  getPiagetBoutiqueEnquireButton
} from '../../storeComponentFactory';
import AppointmentButton from './AppointmentButton';
import { EnquireNowContact } from './EnquireButton';
import LinkButton from './LinkButton';
import OrderNowButton from './OrderNowButton';

const PiagetVnProductCallToActionButton = ({
  product
}: {
  product: IProduct;
}) => {
  const context = React.useContext(AppContext);

  const productLink = getProductOriginalLink(
    product,
    context.store?.countryCode || 'SG'
  );

  const storeContacts: IVirtualBoutiqueContact[] =
    context.storeConfig?.contacts || [];

  const enquireNowContacts: EnquireNowContact[] = storeContacts.map((c) => ({
    email: c.email,
    cc: c.cc,
    name: c.name,
    nameKey: c.nameKey,
    subject: `${getProductDisplayName(product, 'EN')} (Ref.${
      product.modelCode
    }) - ${c.name}`
  }));

  const renderButton = () => {
    return isChatAvailable(context.storeConfig)
      ? getPiagetBoutiqueChatButton(context?.store?.id, storeContacts)
      : getPiagetBoutiqueEnquireButton(context?.store?.id, enquireNowContacts);
  };
  return (
    <div className="action-buttons">
      {renderButton()}
      {productLink && <LinkButton url={productLink} />}
      <AppointmentButton product={product} />
      {context.storeConfig?.entitlement?.shoppingCart && (
        <OrderNowButton product={product} />
      )}
    </div>
  );
};

export default PiagetVnProductCallToActionButton;

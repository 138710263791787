import React from 'react';
import MercedesFullScreenVideo from './MercedesFullScreenVideo';

function MercedesBenzCustomComponent() {
  return (
    <>
      <MercedesFullScreenVideo showMuteButton={true} shouldAbsolute={true} />
    </>
  );
}

export default MercedesBenzCustomComponent;

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { LFAssetBaseUrl } from '../../../../../config/loubiFuture';
import {
  ILoubiFutureWishlist,
  LFPopupContent
} from '../../../../../interfaces/loubifuture';
import {
  actionOpenLFPopup,
  actionOpenWishlistPopup,
  actionRemoveFromWishlist
} from '../../../../../redux/customActions/loubiFuture';
import {
  DID_CLICK_SEND_WISHLIST_BUTTON,
  DID_CLOSE_POPUP,
  DID_OPEN_POPUP,
  DID_REMOVE_FROM_WISHLIST
} from '../../../../../utils/constants';
import LFButton from '../Common/LFButton';
import { LFColor } from './../../../../ThreejsAnimation/loubiFuture/assets';
import FavList from './FavList';
import { actionRemoveFromWishlistAsync } from './LFFavoriteMiddleware';

const asset = `${LFAssetBaseUrl}/common/v2`;

const FavPage = ({ state }: { state: ILoubiFutureWishlist }) => {
  const [show, setShow] = React.useState(false);

  const dispatch = useDispatch();

  const items = state?.wishlistItems?.map((item) => item.itemId) || [];

  const onClickSend = () => {
    logEvent(DID_CLICK_SEND_WISHLIST_BUTTON);
    dispatch(actionOpenLFPopup(LFPopupContent.GET_NOTIFIED));
  };

  React.useEffect(() => {
    setShow(true);
    logEvent(DID_OPEN_POPUP, DID_OPEN_POPUP, { popup: 'Favorites List' });
  }, []);

  return (
    <div
      className={`FavPage fixed-full noselect ${
        show ? 'show-popup' : 'close-popup'
      }`}
    >
      <div className="heading container">
        <img src={`${asset}/logo.png`} className="logo" alt="Loubi Future" />
        <button
          className="btn btn-close"
          onClick={() => {
            setShow(false);
            setTimeout(() => {
              dispatch(actionOpenWishlistPopup(false));
            }, 300);
            logEvent(DID_CLOSE_POPUP, DID_CLOSE_POPUP, {
              popup: 'Favorites List'
            });
          }}
        >
          <img
            src={`${LFAssetBaseUrl}/icons/v1/back_with_cross_white.svg`}
            alt=""
          />
        </button>
      </div>
      <div className="content container">
        <FavList
          items={items}
          onClickItem={() => null}
          onRemoveItem={(id) => {
            if (state?.id) {
              dispatch(actionRemoveFromWishlistAsync(id, state));
            } else {
              logEvent(DID_REMOVE_FROM_WISHLIST, DID_REMOVE_FROM_WISHLIST, {
                id
              });
              dispatch(actionRemoveFromWishlist(id));
            }
          }}
        />
        {!!items?.length && (
          <>
            {!state?.id ? (
              <>
                <div className="btn-container mobile">
                  <LFButton
                    name="KEEP ME POSTED ON THE PRODUCT RELEASE"
                    size="sm"
                    style={{ fontSize: '12px' }}
                    onClick={onClickSend}
                    fullWidth
                  >
                    KEEP ME POSTED ON THE PRODUCT RELEASE
                  </LFButton>
                </div>
                <div className="btn-container desktop">
                  <LFButton
                    name="KEEP ME POSTED ON THE PRODUCT RELEASE"
                    onClick={onClickSend}
                  >
                    KEEP ME POSTED ON THE PRODUCT RELEASE
                  </LFButton>
                </div>
              </>
            ) : (
              <div className="text-center">
                <p>We will contact you when the products are available.</p>
                <LFButton
                  name="CLICK HERE TO UPDATE YOUR DETAILS"
                  size="sm"
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    dispatch(actionOpenLFPopup(LFPopupContent.GET_NOTIFIED));
                  }}
                >
                  CLICK HERE TO UPDATE YOUR DETAILS
                </LFButton>
              </div>
            )}
          </>
        )}
      </div>

      <style jsx>{`
        .FavPage {
          transition: opacity 0.3s ease-in;
          opacity: 1;
          background: ${LFColor.purpleLight}
            url('${LFAssetBaseUrl}/common/v2/bg.png') center center no-repeat;
          background-size: cover;
          z-index: 2001;
          opacity: 0;
          display: flex;
          flex-direction: column;
        }
        .container {
          padding: 0;
          width: 100%;
          max-width: 1280px;
        }
        .show-popup {
          opacity: 1;
        }
        .close-popup {
          opacity: 0;
        }
        .btn-close {
          padding: 0;
          outline: none;
          box-shadow: none;
        }
        .btn-close img {
          width: 42px;
          height: auto;
        }
        .heading {
          position: relative;
          color: ${LFColor.blue};
          border-bottom: 2px solid ${LFColor.purpleLight};
          text-align: left;
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: 'LF-Bold', sans-serif;
          font-size: 1.2em;
          letter-spacing: 1px;
        }
        .content {
          color: #fff;
          font-family: 'LF-Regular', sans-serif;
          height: 100%;
          overflow: auto;
          padding: 20px;
        }
        .desktop {
          display: none;
        }
        .logo {
          width: 120px;
          height: auto;
        }

        :global(.content::-webkit-scrollbar) {
          width: 0;
        }

        :global(.content::-webkit-scrollbar-thumb) {
          box-shadow: none;
        }

        @media (min-width: 768px) {
          .mobile {
            display: none;
          }
          .desktop {
            display: block;
          }
          .container {
            width: 90%;
          }
          .heading {
            text-align: center;
            font-size: 2em;
            position: relative;
            justify-content: center;
            height: 180px;
            align-items: flex-end;
          }
          .btn-close {
            position: absolute;
            right: 0;
            bottom: 24px;
          }
          .btn-close img {
            width: 60px;
          }
          .logo {
            width: 220px;
            height: auto;
          }
        }

        @media (min-width: 820px) {
          :global(.content::-webkit-scrollbar) {
            width: 3px;
          }
          :global(.content::-webkit-scrollbar-thumb) {
            box-shadow: inset 0 0 20px rgba(204, 69, 204, 0.7);
          }
          :global(.content::-webkit-scrollbar-track) {
            box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.1);
          }
        }
      `}</style>
    </div>
  );
};

export default FavPage;

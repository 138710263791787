import { last } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isProd } from '../../../../config';
import {
  IMainState,
  ParticipantMeetingState,
  VirtualBoutiqueState
} from '../../../../interfaces';
import {
  actionAccessRequest,
  actionOpenMusicPlaylist
} from '../../../../redux/actions';
import { ALLOW_ENTER_TUNNEL } from '../../../../utils/constants';
import FullScreenVideo from '../../../FullScreenVideo';
import AudioPlayer from './AudioPlayer';
import AvatarVideos from './AvatarVideos';
import RDUSGHud from './RDUSGHud';
import RequestAccessForm from './RequestAccessForm';
import UserGuide from './UserGuide';
import Veil from './Veil';

let timeout;

const RDUSGCustomComponent = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isAdvisor = router.query.role === 'advisor';
  const isInCockpit = router.query.mode === 'cockpit';
  const guest = (router.query.guest as string) || '';
  const clientState = useSelector((state: IMainState) => state.clientState);
  const vb: VirtualBoutiqueState = clientState?.vb || {};
  const [showGuide, setShowGuide] = React.useState(true);

  React.useEffect(() => {
    //bypass request access
    const shouldByPass = guest === 'rdu' || isInCockpit || router.query.go === 'secretroom';

    if (shouldByPass) window[ALLOW_ENTER_TUNNEL] = true;

    if (
      !window[ALLOW_ENTER_TUNNEL] &&
      !shouldByPass &&
      router.query.source !== 'ww2021'
    ) {
      timeout = setTimeout(() => {
        dispatch(actionAccessRequest({ open: true, muteVideo: false }));
      }, 45000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  React.useEffect(() => {
    if (vb.accessRequest?.open && timeout) {
      clearTimeout(timeout);
    }
  }, [vb.accessRequest?.open]);

  return (
    <>
      {(!isInCockpit ||
        (isInCockpit && isAdvisor) ||
        (isInCockpit &&
          clientState?.meeting?.state ===
            ParticipantMeetingState.LEFT_MEETING)) && (
        <RDUSGHud
          hud={vb?.hud}
          currentScene={last(vb?.sceneIds)}
          currentView={vb?.currentView}
          meetingLayout={clientState?.meeting?.state ===
            ParticipantMeetingState.ENTERED_FROM_LOUNGE && clientState?.meeting?.layout?.mode}
        />
      )}
      <FullScreenVideo
        url={vb.fullScreenVideoWithUrl?.url}
        show={vb.fullScreenVideoWithUrl?.show}
        className="video-content-area"
        fullSize={true}
      />
      <Veil show={vb.showVeil} />
      {!isInCockpit && (
        <AudioPlayer
          playlist="https://open.spotify.com/embed/playlist/2fRn0va5I8pr5LfvyQXTjR"
          open={vb?.openMusicPlaylist}
          onToggle={(open) => dispatch(actionOpenMusicPlaylist(open))}
        />
      )}
      {!vb.accessRequest?.granted && (
        <RequestAccessForm accessRequest={vb.accessRequest} />
      )}
      {(!isInCockpit ||
        (isInCockpit &&
          clientState?.meeting?.state ===
            ParticipantMeetingState.LEFT_MEETING)) && (
        <AvatarVideos
          canPlay={!clientState?.vb?.fullScreenVideoWithUrl?.show}
          currentScene={last(vb?.sceneIds)}
        />
      )}
      {!isInCockpit && showGuide && !isProd && (
        <UserGuide
          currentView={vb?.currentView}
          currentScene={last(vb?.sceneIds) || ''}
          popup={vb?.popup || { open: false, url: null }}
          handleClose={() => setShowGuide(false)}
        />
      )}
    </>
  );
};

export default RDUSGCustomComponent;

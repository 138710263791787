import React from 'react';
import {
  IMainState,
  IShoppingCartItem,
  IShoppingCartItemDisplay
} from '../../../interfaces';
import { BsChevronRight } from 'react-icons/bs';
import { mapItemToCartDisplay } from '../../../utils/shoppingCart';
import * as translation from '../../../i18n';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_INCREASE_PRODUCT_QTY_CART,
  DID_CLICK_REDUCE_PRODUCT_QTY_CART
} from '../../../utils/constants';
import { useSelector } from 'react-redux';

interface ItemProps {
  onIncreaseQty: (id: string) => void;
  onReduceQty: (id: string) => void;
  item: IShoppingCartItem;
  readonly?: boolean;
}

interface QuantityProps {
  onIncreaseQty: (id: string) => void;
  onReduceQty: (id: string) => void;
  itemDisplay: IShoppingCartItemDisplay;
  readonly?: boolean;
}
export const QuantityControl = ({
  onReduceQty,
  onIncreaseQty,
  itemDisplay,
  readonly
}: QuantityProps) => (
  <div className={`input-group ${readonly && 'no-border'}`}>
    {!readonly && (
      <div className="input-group-prepend">
        <button
          className="btn input-group-text reduce-qty"
          onClick={() => {
            onReduceQty(itemDisplay.id);
            logEvent(DID_CLICK_REDUCE_PRODUCT_QTY_CART, itemDisplay.id, {
              quantity: itemDisplay.quantity - 1
            });
          }}
        >
          -
        </button>
      </div>
    )}

    <input
      disabled={readonly}
      type="text"
      value={itemDisplay.quantity}
      onChange={() => null}
      className="form-control text-center"
    />
    {!readonly && (
      <div className="input-group-append">
        <button
          className="btn input-group-text increase-qty"
          onClick={() => {
            onIncreaseQty(itemDisplay.id);
            logEvent(DID_CLICK_INCREASE_PRODUCT_QTY_CART, itemDisplay.id, {
              quantity: itemDisplay.quantity + 1
            });
          }}
        >
          +
        </button>
      </div>
    )}
    <style jsx>{`
      .input-group.no-border {
        border: none;
      }
      .input-group-prepend .btn {
        border-right: none;
      }
      .input-group-append .btn {
        border-left: none;
      }
      .form-control {
        outline: none !important;
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        border: none;
      }
      .form-control:disabled {
        background-color: #fff;
      }
      :global(.appointment .td-qty .form-control) {
        border: none;
      }
      .input-group {
        border-radius: 4px;
        border: 1px solid #ccc;
        margin-bottom: 0 !important;
        max-width: 150px;
      }
      .btn {
        background: #fff;
        outline: none !important;
        box-shadow: none;
        background: transparent;
        border: none;
      }
    `}</style>
  </div>
);

const CartItem = ({
  item,
  onIncreaseQty,
  onReduceQty,
  readonly
}: ItemProps) => {
  const store = useSelector((state: IMainState) => state.clientState?.store);
  const { i18n } = translation.useTranslation();
  const itemDisplay = mapItemToCartDisplay(
    item,
    store?.countryCode,
    i18n.language
  );

  return (
    <tr>
      <td className="td-img" valign="middle">
        <img
          className="img-fluid"
          src={itemDisplay.imageUrl}
          alt={itemDisplay.modelName}
        />
      </td>
      <td className="td-desc">
        {(itemDisplay.modelCode || itemDisplay.productFamily) && (
          <p className="modelCode">
            {itemDisplay.productFamily && (
              <>
                {itemDisplay.productFamily}
                {` `}
                <BsChevronRight style={{ marginTop: '-3px' }} />
                {` `}
              </>
            )}
            {itemDisplay.modelCode}
          </p>
        )}
        <p className="modelName">{itemDisplay.modelName}</p>
        <p className="priceDisplay">{itemDisplay.formattedPrice}</p>
        <div className="m-show">
          <QuantityControl
            itemDisplay={itemDisplay}
            onIncreaseQty={onIncreaseQty}
            onReduceQty={onReduceQty}
            readonly={readonly}
          />
        </div>
      </td>
      <td className="td-qty m-hide">
        <QuantityControl
          itemDisplay={itemDisplay}
          onIncreaseQty={onIncreaseQty}
          onReduceQty={onReduceQty}
          readonly={readonly}
        />
      </td>
      <td className="td-price text-right">{itemDisplay.formattedTotalPrice}</td>

      <style jsx>{`
        td {
          vertical-align: middle;
        }

        .td-img,
        .td-price {
          padding-right: 0;
          padding-left: 0;
        }
        p {
          margin-bottom: 8px;
        }
        .brandName,
        .modelCode {
          text-transform: uppercase;
          font-size: 0.7em;
          letter-spacing: 0.3px;
        }
        .modelName {
          font-weight: normal;
          font-size: 1.2em;
          line-height: 1.3;
        }
        .modelCode {
          color: #666;
        }

        .close {
          font-weight: normal;
          font-size: 1em;
        }
        .priceDisplay {
          font-size: 0.8em;
        }
        .m-show {
          display: none;
        }
        :global(.isRtl .td-desc) {
          text-align: right !important;
        }
        @media (max-width: 520px) {
          .modelName {
            font-size: 1em;
            line-height: 1.2;
          }
          .m-hide {
            display: none !important;
          }
          .m-show {
            display: block;
          }
        }
      `}</style>
    </tr>
  );
};

export default CartItem;

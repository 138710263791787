import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { useTranslation } from '../../../../../i18n';
import { actionOpenLFPopup } from '../../../../../redux/customActions/loubiFuture';
import { DID_SELECT_LANGUAGE } from '../../../../../utils/constants';
import LFButton from '../Common/LFButton';

const LFLanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const switchLanguage = (lang: string) => {
    logEvent(DID_SELECT_LANGUAGE, DID_SELECT_LANGUAGE, {
      language: lang,
      source: 'MENU'
    });
    i18n.changeLanguage(lang);
    dispatch(actionOpenLFPopup(undefined));
  };
  return (
    <div className="LFLanguageSwitcher">
      <h3 className="LFTitle">SELECT LANGUAGE</h3>
      <div className="mt-2">
        <div className="text-center">
          <LFButton
            name="en"
            className="lf-prompt-button"
            onClick={() => {
              switchLanguage('en');
            }}
            fullWidth
          >
            ENGLISH
          </LFButton>
          <LFButton
            name="fr"
            className="lf-prompt-button"
            onClick={() => {
              switchLanguage('fr');
            }}
            fullWidth
          >
            FRANCAISE
          </LFButton>
          <LFButton
            name="ja"
            className="lf-prompt-button"
            onClick={() => {
              switchLanguage('ja');
            }}
            fullWidth
          >
            日本語
          </LFButton>
        </div>
      </div>
      <style jsx>{`
        .LFLanguageSwitcher {
          text-align: center;
        }
        :global(.lf-prompt-button) {
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default LFLanguageSwitcher;

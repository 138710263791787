import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  rduSgPanoBasePath,
  redDoorMobileUrl,
  redDoorUrl,
  redDoorMobileForRetailAndWholeSaleUrl,
  redDoorForRetailAndWholeSaleUrl
} from '../../../../config/rduWW2021';
import { IMainState } from '../../../../interfaces/index';
import {
  actionLoadNewPano,
  actionShowFullScreenVideoWithUrl
} from '../../../../redux/actions';
import FullScreenVideo from '../../../FullScreenVideo';
import {
  useAutoPlayVideoMuteControl,
  useMeetingLocalAudio
} from '../../../hooks/meeting';

const RDUWW21FullScreenVideo = () => {
  const isPlayingExitVideo = (url: string) =>
    url === redDoorMobileUrl ||
    url === redDoorUrl ||
    url === redDoorMobileForRetailAndWholeSaleUrl ||
    url === redDoorForRetailAndWholeSaleUrl;
  const dispatch = useDispatch();
  const { muteAudio } = useMeetingLocalAudio();
  const [playWithSound, setPlayWithSound] = useAutoPlayVideoMuteControl();
  const { url, show } =
    useSelector(
      (state: IMainState) => state.clientState?.vb?.fullScreenVideoWithUrl
    ) || {};

  React.useEffect(() => {
    if (isPlayingExitVideo(url)) {
      muteAudio();
      setTimeout(() => {
        dispatch(
          actionLoadNewPano({
            swf: `${rduSgPanoBasePath}tour.swf`,
            xml: `${rduSgPanoBasePath}tour.xml`,
            target: 'embedframe',
            html5: 'auto',
            consolelog: location.hostname === 'localhost',
            mobilescale: 1.0,
            passQueryParameters: true,
            basepath: `${rduSgPanoBasePath}`,
            initvars: {
              startscene: 'scene_pano_looby'
            }
          })
        );
      }, 3000);
    }
  }, [url]);

  return (
    <div className="rdu-ww21-full-screen-video">
      <FullScreenVideo
        url={url}
        show={show}
        playWithSound={playWithSound}
        setPlayWithSound={(playWithSound: boolean) => {
          setPlayWithSound(playWithSound);
        }}
        onEnded={() => {
          dispatch(
            actionShowFullScreenVideoWithUrl({
              show: false,
              url: undefined
            })
          );
        }}
        fullSize={true}
        className="video-content-area"
      ></FullScreenVideo>
      <style jsx>
        {`
          @media (max-width: 768px) {
            :global(.rdu-ww21-full-screen-video video) {
              object-fit: cover;
            }
          }
        `}
      </style>
    </div>
  );
};

export default RDUWW21FullScreenVideo;

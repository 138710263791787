import { createVirtualBackgroundEffect } from '../VideoBackground';
import {
  JitsiVideoTrackEffect,
  JitsiLocalTrack
} from './../../../interfaces/index';

export const toggleBackgroundEffect = async (
  options: JitsiVideoTrackEffect,
  jitsiTrack: JitsiLocalTrack
) => {
  if (jitsiTrack) {
    try {
      if (options?.enabled) {
        const newEffect = await createVirtualBackgroundEffect(options);
        return await jitsiTrack.setEffect(newEffect);
      }
      return await jitsiTrack.setEffect(undefined);
    } catch (error) {
      console.error('Error on apply background effect:', error);
      return Promise.reject(error);
    }
  }
};

export const toDataURL = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const resData = await blobToData(blob);
  return resData;
};

export const resizeImage = (
  base64image: any,
  width = 1920,
  height = 1080
): Promise<string> => {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    img.onload = function () {
      // Create an off-screen canvas.
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const scale = Math.max(
        canvas.width / img.width,
        canvas.height / img.height
      );
      const x = canvas.width / 2 - (img.width / 2) * scale;
      const y = canvas.height / 2 - (img.height / 2) * scale;
      // Set its dimension to target size.
      const context = canvas.getContext('2d');
      const imgWidth = img.width * scale;
      const imgHeight = img.height * scale;

      // Draw source image into the off-screen canvas.
      context.drawImage(img, x, y, imgWidth, imgHeight);
      // Encode image to data-uri with base64 version of compressed image.
      resolve(canvas.toDataURL('image/jpeg', 1));
    };
    img.src = base64image;
  });
};

export const blobToData = (blob: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result.toString());
    reader.readAsDataURL(blob);
  });

export const virtualImages = [
  {
    tooltip: 'Beach',
    id: '1',
    src: '/asset/virtual_images/beach.jpeg'
  },
  {
    tooltip: 'Office',
    id: '2',
    src: '/asset/virtual_images/office.jpeg'
  },
  {
    tooltip: 'Room',
    id: '3',
    src: '/asset/virtual_images/room.jpg'
  },
  {
    tooltip: 'Clock',
    id: '4',
    src: '/asset/virtual_images/clock.jpeg'
  }
];

import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import { actionToggleChatPanel } from '../../../../../redux/customActions/loubiAirways';
import { DID_CLICK_ON_CHAT_BUTTON } from '../../../../../utils/constants';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiButtonWrapper from '../../LouboutinCustomComponent/Buttons/LoubiButtonWrapper';

const LJChatButton = () => {
  const { openChatPanel, showSpotifyPlayer } = useLoubiAirwaysState();
  const dispatch = useDispatch();
  const openChat = () => {
    dispatch(actionToggleChatPanel(true));
  };
  return (
    <LoubiButtonWrapper>
      <img
        src={`${jpIconUrlBase}/chat_white.svg`}
        alt="open live chat"
        className={`loubi-circle-button bottom-third-right ${
          showSpotifyPlayer ? 'spotify' : ''
        }`}
        onClick={() => {
          logEvent(DID_CLICK_ON_CHAT_BUTTON);
          openChatPanel ? null : openChat();
        }}
      />
    </LoubiButtonWrapper>
  );
};

export default LJChatButton;

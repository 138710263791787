import { first } from 'lodash';
import React from 'react';
import { MdCall } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { useTranslation } from '../../../i18n';
import {
  IVirtualBoutiqueAppointmentContact,
  IVirtualBoutiqueConfig,
  IVirtualBoutiqueContact
} from '../../../interfaces';
import { IMainState } from '../../../interfaces/index';
import { actionShowContactPopup } from '../../../redux/actions';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { isChatAvailable } from '../../../utils/stores';
import EmailSvgIcon from '../Icons/EmailSvgIcon';
import CallNowButton from './CallNowButton';
import EmailContactButton from './EmailContactButton';

const ContactPopupWithCallNow = ({
  config,
  showCallNowForMobile
}: {
  config: IVirtualBoutiqueConfig;
  showCallNowForMobile?: boolean;
}) => {
  const dispatch = useDispatch();
  const showDetails =
    useSelector(
      (state: IMainState) => state.clientState.vb?.openContactPopup
    ) || false;

  const [showChat, setShowChat] = React.useState(false);
  const [isMobilePhone, setIsMobilePhone] = React.useState(true);
  const contacts = config.contacts || [];
  const emailUsContacts = config.appointmentContacts || [];

  const { t } = useTranslation();

  React.useEffect(() => {
    setShowChat(isChatAvailable(config));
    setIsMobilePhone(isUserOnMobileOnly());
  }, []);

  const mapContactToEmailButton = (
    contact: IVirtualBoutiqueContact,
    index: number
  ) => (
    <EmailContactButton contact={contact} key={`${contact.email}-${index}`}>
      <>
        <EmailSvgIcon />
        <span>
          {t('email')}{' '}
          {contact.nameKey
            ? t(`vb:${contact.nameKey}`)
            : contact.name.toUpperCase()}
        </span>
      </>
    </EmailContactButton>
  );

  const emailContacts = contacts.map(mapContactToEmailButton);

  const phoneContacts = contacts
    .filter((c) => c.callNumber)
    .map((contact: IVirtualBoutiqueContact) => (
      <div className="wabutton" key={contact.name}>
        <CallNowButton contact={contact}>
          <>
            <MdCall />
            <span>{t('vb:call_now')}</span>
          </>
        </CallNowButton>
      </div>
    ));

  const mapAppointmentContactToEmailButton = (
    contact: IVirtualBoutiqueAppointmentContact,
    index: number
  ) => (
    <EmailContactButton contact={contact} key={`${contact.email}-${index}`}>
      <>
        <EmailSvgIcon />
        <span>
          {t('email')}{' '}
          {contact.nameKey
            ? t(`vb:${contact.nameKey}`)
            : contact.name.toUpperCase()}
        </span>
      </>
    </EmailContactButton>
  );

  const emailUsContact =
    emailUsContacts.length === 1 ? (
      <EmailContactButton contact={first(emailUsContacts)}>
        <>
          <EmailSvgIcon />
          <span>{t('vb:email_us')}</span>
        </>
      </EmailContactButton>
    ) : (
      emailUsContacts.map(mapAppointmentContactToEmailButton)
    );

  const callNowContacts =
    isMobilePhone || showCallNowForMobile ? phoneContacts : emailUsContact;

  return (
    <div className="contact-container">
      <Transition in={showDetails} timeout={300} mountOnEnter unmountOnExit>
        {(state) => (
          <div className={`contact-list-popup fadein ${state}`}>
            {showChat ? callNowContacts : emailContacts}
            <button
              className="btn btn-dark btn-sm btn-close"
              type="button"
              onClick={() => dispatch(actionShowContactPopup(false))}
            >
              {t('vb:close')}
            </button>
          </div>
        )}
      </Transition>
      <style jsx>{`
        .contact-list-popup {
          background: #000;
          position: fixed;
          bottom: 0;
          right: 40px;
          width: 300px;
        }
        .contact-list-popup :global(a) {
          color: #fff;
          background: #343740;
          border-radius: 5px;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          display: block;
          overflow: hidden;
          text-decoration: none;
          margin: 10px;
        }
        .contact-list-popup :global(a:hover) {
          background-color: #525252;
        }

        .contact-list-popup :global(a span) {
          width: calc(100% - 34px);
        }

        .contact-list-popup :global(svg) {
          margin-top: -2px;
        }
        .btn-close {
          float: right;
          margin: 10px;
          font-size: 12px;
          letter-spacing: 1px;
        }

        .contact-list-popup :global(.wabutton svg) {
          fill: #fff;
          width: 20px;
        }
        @media (max-width: 630px) {
          .contact-list-popup {
            right: calc(50vw - 150px);
          }
        }
      `}</style>
    </div>
  );
};

export default ContactPopupWithCallNow;

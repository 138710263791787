import { assetBaseUrl } from '../../../config/index';
import { PageView } from '../../../utils/pageView';
//uploaded assets
export const mbAssetsBaseUrl = `${assetBaseUrl}/mercedesbenz/v1`;

export enum MenuItem {
  Configure = 'configure',
  Video = 'video'
}

export const menuData = {
  [MenuItem.Configure]: {
    label: 'Configure',
    icon: mbAssetsBaseUrl + '/Icon_Configs.svg'
  },
  [MenuItem.Video]: {
    label: 'Video',
    icon: mbAssetsBaseUrl + '/Icon_Comercials.svg'
  }
};

export enum CarPart {
  default,
  Paint,
  Rim,
  Interior,
  Seat,
  Play,
  Pause,
  Stop,
  Hotspot4,
  Hotspot5
}

export const CarPartData = {
  [CarPart.default]: {
    label: 'default',
    icon: '',
    uecmd: ''
  },
  [CarPart.Paint]: {
    label: 'Paint',
    icon: mbAssetsBaseUrl + '/Icon_Paint.svg',
    uecmd: 'FOCUS_EXTERIOR'
  },
  [CarPart.Rim]: {
    label: 'Wheel',
    icon: mbAssetsBaseUrl + '/Icon_Rim.svg',
    uecmd: 'FOCUS_WHEEL'
  },
  [CarPart.Interior]: {
    label: 'Interior',
    icon: mbAssetsBaseUrl + '/Icon_Interior.svg',
    uecmd: 'FOCUS_DRIVER'
  },
  [CarPart.Seat]: {
    label: 'Seat',
    icon: mbAssetsBaseUrl + '/Icon_Seat.svg',
    uecmd: 'FOCUS_INTERIOR_FRONT'
  },
  [CarPart.Play]: {
    label: 'Play',
    icon: mbAssetsBaseUrl + '/Icon_Play.svg',
    uecmd: 'VID_PLAY'
  },
  [CarPart.Pause]: {
    label: 'Pause',
    icon: mbAssetsBaseUrl + '/Icon_Pause.svg',
    uecmd: 'VID_PAUSE'
  },
  [CarPart.Stop]: {
    label: 'Stop',
    icon: mbAssetsBaseUrl + '/Icon_StopButton.svg',
    uecmd: 'VID_STOP'
  },
  [CarPart.Hotspot4]: {
    label: 'Video',
    icon: mbAssetsBaseUrl + '/Icon_Player.svg',
    uecmd: ''
  },
  [CarPart.Hotspot5]: {
    label: 'Gallery',
    icon: mbAssetsBaseUrl + '/Icon_Gallery.svg',
    uecmd: ''
  }
};

enum ExtColor {
  blackPaint,
  whitePaint,
  greyPaint,
  bluePaint,
  redPaint
}

const extData = {
  [ExtColor.blackPaint]: {
    label: 'Metallic Obsidian Black',
    icon: mbAssetsBaseUrl + '/Metallic Obsidian Black.png',
    uecmd: 'EXT_BLACK'
  },
  [ExtColor.whitePaint]: {
    label: 'Standard Polar White',
    icon: mbAssetsBaseUrl + '/Standard Polar White.png',
    uecmd: 'EXT_WHITE'
  },
  [ExtColor.greyPaint]: {
    label: 'Metallic High-Tech Silver',
    icon: mbAssetsBaseUrl + '/Metallic High-Tech Silver.png',
    uecmd: 'EXT_GREY'
  },
  [ExtColor.bluePaint]: {
    label: 'Metallic Spectral Blue',
    icon: mbAssetsBaseUrl + '/Metallic Spectral Blue.png',
    uecmd: 'EXT_BLUE'
  },
  [ExtColor.redPaint]: {
    label: 'Designo Manufaktur Hyacinth Red Metallic',
    icon: mbAssetsBaseUrl + '/Designo Manufaktur Hyacinth Red Metallic.png',
    uecmd: 'EXT_RED'
  }
};

enum RimItem {
  rim01,
  rim02,
  rim03
}

const rimData = {
  [RimItem.rim01]: {
    label: '21 Inch AMG Night Edition Wheels Set Multi Spoke',
    icon:
      mbAssetsBaseUrl + '/21 Inch AMG Night Edition Wheels Set Multi Spoke.png',
    uecmd: 'RIM_01'
  },
  [RimItem.rim02]: {
    label: '21 Inch AMG Black Forged Rims Cross Spoke',
    icon: mbAssetsBaseUrl + '/21 Inch AMG Black Forged Rims Cross Spoke.png',
    uecmd: 'RIM_02'
  },
  [RimItem.rim03]: {
    label: '20 Inch AMG Alloy Rims Set Multi Spoke Black',
    icon: mbAssetsBaseUrl + '/20 Inch AMG Alloy Rims Set Multi Spoke Black.png',
    uecmd: 'RIM_03'
  }
};

enum IntColor {
  black,
  white
}

const intData = {
  [IntColor.black]: {
    label: 'Space Grey',
    icon: mbAssetsBaseUrl + '/Interior_Black.png',
    uecmd: 'INT_BLACK'
  },
  [IntColor.white]: {
    label: 'Biscay Blue',
    icon: mbAssetsBaseUrl + '/Interior_White.png',
    uecmd: 'INT_WHITE'
  }
};

enum SeatColor {
  black_leather,
  white_leather
}

const seatData = {
  [SeatColor.black_leather]: {
    label: 'Black',
    icon: mbAssetsBaseUrl + '/Black.png',
    uecmd: 'SEAT_BLACK'
  },
  [SeatColor.white_leather]: {
    label: 'Neva Grey',
    icon: mbAssetsBaseUrl + '/Neva Grey.png',
    uecmd: 'SEAT_WHITE'
  }
};

export enum CameraView {
  Exterior = 'Exterior',
  SideView = 'Side View',
  FrontView = 'Front View',
  Wheel = 'Wheel',
  BackView = 'Back View',
  Driver = 'Driver',
  Passenger = 'Passenger',
  IntFront = 'Interior Front',
  IntBack = 'Interior Back'
}

export const camData = {
  [CameraView.Exterior]: {
    label: 'Exterior',
    uecmd: 'FOCUS_EXTERIOR'
  },
  [CameraView.SideView]: {
    label: 'Side View',
    uecmd: 'FOCUS_SIDE'
  },
  [CameraView.FrontView]: {
    label: 'Front View',
    uecmd: 'FOCUS_FRONT'
  },
  [CameraView.Wheel]: {
    label: 'Wheel',
    uecmd: 'FOCUS_WHEEL'
  },
  [CameraView.BackView]: {
    label: 'Back View',
    uecmd: 'FOCUS_BACK'
  },
  [CameraView.Driver]: {
    label: 'Driver',
    uecmd: 'FOCUS_DRIVER'
  },
  [CameraView.Passenger]: {
    label: 'Passenger',
    uecmd: 'FOCUS_PASSENGER'
  },
  [CameraView.IntFront]: {
    label: 'Interior Front',
    uecmd: 'FOCUS_INTERIOR_FRONT'
  },
  [CameraView.IntBack]: {
    label: 'Interior Back',
    uecmd: 'FOCUS_INTERIOR_BACK'
  }
};

export const navigation = [MenuItem.Configure, MenuItem.Video];

export const selectArray = [0, 0, 0, 0, 0, 2, 0];

export const menuContent = {
  [MenuItem.Configure]: [
    {
      number: CarPart.Paint,
      title: CarPartData[CarPart.Paint].label,
      icon: CarPartData[CarPart.Paint].icon,
      description: extData[selectArray[CarPart.Paint]].label,
      content: extData
    },
    {
      number: CarPart.Rim,
      title: CarPartData[CarPart.Rim].label,
      icon: CarPartData[CarPart.Rim].icon,
      description: rimData[selectArray[CarPart.Rim]].label,
      content: rimData
    },
    {
      number: CarPart.Interior,
      title: CarPartData[CarPart.Interior].label,
      icon: CarPartData[CarPart.Interior].icon,
      description: intData[selectArray[CarPart.Interior]].label,
      content: intData
    },
    {
      number: CarPart.Seat,
      title: CarPartData[CarPart.Seat].label,
      icon: CarPartData[CarPart.Seat].icon,
      description: seatData[selectArray[CarPart.Seat]].label,
      content: seatData
    }
  ],
  [MenuItem.Video]: [
    {
      number: CarPart.Play,
      title: 'Play',
      icon: mbAssetsBaseUrl + '/Icon_Play.svg',
      description: 'Play',
      content: undefined,
      uecmd: 'VID_PLAY'
    },
    {
      number: CarPart.Pause,
      title: 'Pause',
      icon: mbAssetsBaseUrl + '/Icon_Pause.svg',
      description: 'Pause',
      content: undefined,
      uecmd: 'VID_PAUSE'
    },
    {
      number: CarPart.Stop,
      title: 'Stop',
      icon: mbAssetsBaseUrl + '/Icon_Stop.svg',
      description: 'Stop',
      content: undefined,
      uecmd: 'VID_STOP'
    },
    {
      number: CarPart.Hotspot4,
      title: 'All Electric\nLand. Sea. Air.',
      icon: mbAssetsBaseUrl + '/Icon_Player.svg',
      description: 'All Electric\nLand. Sea. Air.',
      content: undefined,
      action: {
        action: 'popup',
        type: 'VIDEO',
        videoUrl:
          'content/converted/libraries/editor-8a3604fb-e822-4b58-a102-997664a415b4/2023-02-27t09:35:52.754z the all-electric eqe sedan_ this is for all scene-1080p-v2.mp4/index.m3u8'
      },
      uecmd: ''
    },
    {
      number: CarPart.Hotspot5,
      title: 'Gallery',
      icon: mbAssetsBaseUrl + '/Icon_Gallery.svg',
      description: 'Gallery',
      content: undefined,
      action: {
        action: 'popup',
        type: PageView.INSPIRATION,
        id: 'd67c9d90-b28c-11ed-926f-c5ecf03176cd'
      },
      uecmd: ''
    }
  ]
};

export const GET_CURRENT_MODIFICATION = 'GET_CURRENT_MODIFICATION';

export const MercedesBenzIcon = () => (
  <svg
    width="2280"
    height="2280"
    viewBox="0 0 2280 2280"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.294922 1140C0.294922 1769.42 510.564 2279.71 1140 2279.71C1769.45 2279.71 2279.71 1769.42 2279.71 1140C2279.71 510.564 1769.45 0.294922 1140 0.294922C510.564 0.294922 0.294922 510.564 0.294922 1140ZM214.996 1695.14L1140.31 1312.16L2069.57 1689.22C1876.59 2017.77 1524.28 2219.67 1144.25 2220.65C763.254 2222.63 409.958 2022.74 214.996 1695.14ZM1127.48 64.9103L980.031 1041.68L201.197 1671.09C14.1467 1339.55 16.131 933.807 207.124 604.258C397.131 274.709 747.457 69.8518 1127.48 64.9103ZM2083.41 1664.24L1295.68 1054.63L1155.14 64.9881C1341.28 67.835 1523.52 118.825 1684.11 212.994C1844.7 307.164 1978.18 441.306 2071.55 602.364C2262.53 929.929 2266.5 1332.72 2083.41 1664.24Z"
      fill="white"
    />
  </svg>
);

import React from 'react';
import { useSelector } from 'react-redux';
import { assetBaseUrl } from '../../../../config';
import { IMainState, ParticipantMeetingState } from '../../../../interfaces';
import { MDLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../Meeting/MeetingLayout/SMLandscapeNormal';

const asset = `${assetBaseUrl}/loubiairways/pages/v2`;
const jpAsset = `${assetBaseUrl}/loubiairways/pages/japan`;
const LJPopupWrapper = ({
  children,
  onClose,
  title,
  subHeader,
  className,
  maxWidth = 900
}: {
  children: React.ReactNode;
  onClose: () => void;
  title?: string;
  subHeader?: string;
  className?:string;
  maxWidth?: number;
}) => {
  const [show, setShow] = React.useState(true);

  const meetingState = useSelector(
    (state: IMainState) => state.clientState.meeting?.state
  );
  const isInMeeting =
    meetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;

  React.useEffect(() => {
    if (!show) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  }, [show]);
  return (
    <div className={`LJPopupWrapper fixed-full ${!show ? 'closing' : ''} ${isInMeeting ? 'meeting' : ''} ${className}`}>
      <div className="container">
        <div className="header">
          <img
            src={`${jpAsset}/icons/close_red.svg`}
            className="close-button"
            onClick={(e) => {
              e.stopPropagation();
              setShow(false);
            }}
            alt=""
          />
        </div>
        <div className="content">
          {subHeader && <h1>{subHeader}</h1>}
          {title && <h2>{title}</h2>}
          {children}
        </div>
      </div>

      <style jsx>{`
        .LJPopupWrapper {
          display: flex;
          background: url('${asset}/cloud.jpg');
          background-size: cover;
          animation: fade-in 0.5s ease-in both;
          z-index: 1000;
          flex-direction: column;
          align-items: center;
          overflow: auto;
          padding-bottom:80px;
        }
        :global(.in-meeting.MD) .LJPopupWrapper{
          top:${MDLandscapeNormalSpec.contentArea.top}px;
        }
        :global(.in-meeting.SM) .LJPopupWrapper{
          top:${SMLandscapeNormalSpec.contentArea.top}px;
        }
        .LJPopupWrapper.closing {
          animation: fade-out 0.5s ease-in both;
        }

        .header {
          position: relative;
          padding: 20px;
        }
        .header img {
          height: auto;
        }
        .sole {
          width: 30px;
        }
        .close-button {
          position: absolute;
          right: 0;
          top: 15px;
          width: 40px;
          cursor: pointer;
        }

        .container {
          background-color: #f3f3d1;
          box-shadow: 8px 8px #346168;
          border-radius: 40px;
          margin: auto;
          max-width: ${maxWidth}px;
          width: 90%;
        }
        :global(.in-meeting) .container{
          border-radius:0;
          width:100%;
        }
        .content {
          padding: 20px 20px 40px;
        }
        h1 {
          color: #41747f;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-size: 1.2em;
          font-style: italic;
          margin-bottom: 30px;
          margin-top: 0;
        }
        h2 {
          color: #cf152d;
          font-family: 'AntiqueOlive-Bold', Arial;
          font-size: 2em;
          font-weight: 600;
          margin-bottom: 20px;
        }
        @media (max-width: 768px) {
          h2 {
            font-size: 1em;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

      `}</style>
    </div>
  );
};

export default LJPopupWrapper;

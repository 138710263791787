import React from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import {
  iwcNlAmsterdamSiteId,
  iwcUaeDubaiBoutiqueId,
  jlcAuMelbourneBoutiqueId,
  loubiAirwaysBoutiqueId,
  loubiFutureSiteId,
  piagetSgBoutiqueId,
  vanCleefArpelsUsBeverlyHillsSiteId,
  loubiAirwaysJpBoutiqueId,
  vanCleefArpelsUsSiliconValleyFairSiteId,
  chopardHappySportKorea,
  inspifyInverseSiteId,
  mercedesBenzSiteId
} from '../../../../config';
import { actionOpenTooltip } from '../../../../redux/actions';
import ToolTipWrapper from '../../../Common/ToolTipWrapper';

export interface TeleportSite {
  label: string;
  id: string;
  type: 'pano' | 'canvas' | 'lite' | 'pixelStream';
}
const metaverse: TeleportSite[] = [
  {
    label: 'CHOPARD Inverse',
    id: chopardHappySportKorea,
    type: 'pixelStream'
  },
  {
    label: 'INSPIFY Showroom',
    id: inspifyInverseSiteId,
    type: 'pixelStream'
  },
  {
    label: 'Mercedes-Benz',
    id: mercedesBenzSiteId,
    type: 'pixelStream'
  }
];
const vb: TeleportSite[] = [
  {
    label: 'VCA Beverly Hills',
    id: vanCleefArpelsUsBeverlyHillsSiteId,
    type: 'pano'
  },
  {
    label: 'VCA Valley Fair',
    id: vanCleefArpelsUsSiliconValleyFairSiteId,
    type: 'pano'
  },
  { label: 'IWC Amsterdam', id: iwcNlAmsterdamSiteId, type: 'pano' },
  { label: 'IWC Dubai', id: iwcUaeDubaiBoutiqueId, type: 'pano' },
  { label: 'Piaget', id: piagetSgBoutiqueId, type: 'pano' },
  { label: 'JLC', id: jlcAuMelbourneBoutiqueId, type: 'pano' }
];
const threeD: TeleportSite[] = [
  { label: 'Loubi Airways', id: loubiAirwaysBoutiqueId, type: 'pano' },
  { label: 'Loubi Airways JP', id: loubiAirwaysJpBoutiqueId, type: 'pano' },
  { label: 'Loubi Future', id: loubiFutureSiteId, type: 'canvas' }
];
const salesLoft: TeleportSite[] = [];

export const allTeleportSites = [...vb, ...threeD, ...salesLoft];

const InspifyTeleportMenu = ({
  siteId,
  activeSite,
  onSelectSite
}: {
  siteId: string;
  activeSite?: string;
  onSelectSite: (id: string) => void;
}) => {
  const dispatch = useDispatch();

  return (
    <ToolTipWrapper
      parentId="teleportButton"
      onClose={() => dispatch(actionOpenTooltip(undefined))}
      width={250}
      distanceFromParent={30}
    >
      <div className="control-menu teleport-menu">
        <div className="list-group-item list-group-item-action title">
          METAVERSE:
        </div>
        {metaverse.map((site) => (
          <button
            key={site.id}
            className="list-group-item list-group-item-action"
            onClick={() => onSelectSite(site.id)}
            disabled={activeSite === site.id}
          >
            <BiChevronRight color={activeSite === site.id ? '#fff' : '#333'} />
            {site.label}
          </button>
        ))}

        <div className="list-group-item list-group-item-action title">
          VIRTUAL BOUTIQUE:
        </div>
        {vb.map((site) => (
          <button
            key={site.id}
            className="list-group-item list-group-item-action"
            onClick={() => onSelectSite(site.id)}
            disabled={activeSite === site.id}
          >
            <BiChevronRight color={activeSite === site.id ? '#fff' : '#333'} />
            {site.label}
          </button>
        ))}
        <div className="list-group-item list-group-item-action title">
          3D EXPERIENCE:
        </div>
        {threeD.map((site) => (
          <button
            key={site.id}
            className="list-group-item list-group-item-action"
            onClick={() => onSelectSite(site.id)}
            disabled={activeSite === site.id}
          >
            <BiChevronRight color={activeSite === site.id ? '#fff' : '#333'} />
            {site.label}
          </button>
        ))}
        {!!salesLoft.length && (
          <div className="list-group-item list-group-item-action title">
            SALES LOFT:
          </div>
        )}
        {salesLoft.map((site) => (
          <button
            key={site.id}
            className="list-group-item list-group-item-action"
            onClick={() => onSelectSite(site.id)}
            disabled={activeSite === site.id}
          >
            <BiChevronRight color={activeSite === site.id ? '#fff' : '#333'} />
            {site.label}
          </button>
        ))}

        {activeSite && (
          <button
            className="list-group-item list-group-item-action text-danger"
            onClick={() => onSelectSite(siteId)}
          >
            BACK TO INSPIFY
          </button>
        )}
      </div>
      <style jsx>{`
        .title {
          letter-spacing: 2px;
          color: #999;
          font-size: 12px;
          border: none;
        }
        .title:first-child {
          margin-top: 0;
        }
        .text-danger {
          font-size: 12px;
          letter-spacing: 2px;
          border: none;
        }
        button:disabled {
          opacity: 1;
        }
      `}</style>
    </ToolTipWrapper>
  );
};

export default InspifyTeleportMenu;

import React from 'react';
import { useDispatch } from 'react-redux';
import { jpIconUrlBase } from '../../../../../config/loubiAirways';
import { IPlanePlan } from '../../../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysPlanePlan } from '../../../../../redux/customActions/loubiAirways';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiAirwaysPlanMapAccessButton from '../../LouboutinCustomComponent/Buttons/LoubiAirwaysPlanMapAccessButton';
import LJContinueFlightButton from '../Buttons/LJContinueFlightButton';
import LoubiAirwaysPlan from './LoubiAirwaysPlan';
import { zIndex } from '../../../../../config';
const PlanePlan = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [showBack, setShowBack] = React.useState(false);
  const state = useLoubiAirwaysState()?.planePlan;
  const updateState = (s: IPlanePlan) => {
    dispatch(
      actionUpdateLoubiAirwaysPlanePlan({ ...state, ...s, open: false })
    );
  };

  React.useEffect(() => {
    if (state?.open) {
      setShow(true);
      setTimeout(() => {
        setShowBack(true);
      }, 500);
    } else {
      setShowBack(false);
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [state]);

  return (
    <>
      {show && <LoubiAirwaysPlan state={state} updateState={updateState} />}
      {showBack && (
        <LJContinueFlightButton
          onClick={() => {
            updateState({});
          }}
        />
      )}
      {!showBack && (
        <LoubiAirwaysPlanMapAccessButton
          imageUrl={`${jpIconUrlBase}/overview_white.svg`}
        />
      )}
      <style jsx global>{`
        .circle-nav-container{
          z-index:${show ? zIndex.meetingControls + 1 : 0}
        }
      `}</style>
    </>
  );
};

export default PlanePlan;

import React from 'react';
import { useMeetingLocalAudio } from '../hooks/meeting';
import { usePageVisibility } from '../hooks/ux';

const BackgroundMusic = ({
  backgroundMusicUrl,
  loop,
  play = true,
  volume = 1
}: {
  backgroundMusicUrl?: string;
  loop?: boolean;
  play?: boolean;
  volume?: number;
}) => {
  const audioRef = React.createRef<HTMLAudioElement>();
  const { muteAudio } = useMeetingLocalAudio();

  const isPageVisible = usePageVisibility();
  const shouldPlay = play && isPageVisible;
  const shouldLoop = loop && play;

  React.useEffect(() => {
    if (shouldPlay) {
      audioRef?.current?.play();
      if (audioRef?.current?.volume) audioRef.current.volume = volume;
    } else {
      audioRef?.current?.pause();
    }
  }, [shouldPlay]);

  React.useEffect(() => {
    try {
      if (audioRef?.current?.volume) audioRef.current.volume = volume;
    } catch (e) {
      console.log('music player error', e);
    }
  }, [backgroundMusicUrl]);

  if (!backgroundMusicUrl) {
    return null;
  }

  return (
    <audio
      key={backgroundMusicUrl}
      ref={audioRef}
      loop={shouldLoop}
      autoPlay
      onPlay={() => {
        muteAudio();
      }}
    >
      <source src={backgroundMusicUrl} type="audio/mpeg" />
    </audio>
  );
};

export default BackgroundMusic;

import React from 'react';
import { logEvent } from '../../../analytics';
import { isUserOniOS, isUserOnMobileOnly } from '../../../utils/deviceDetector';
import {
  exitFullScreen,
  isFullScreen,
  requestFullScreen
} from '../../../utils/window';
import { FullScreenIcon } from './CHIcons';

const DID_FULLSCREEN = 'DID_FULLSCREEN';
const REQUEST_FULLSCREEN_ERROR = 'REQUEST_FULLSCREEN_ERROR';
const EXIT_FULLSCREEN = 'EXIT_FULLSCREEN';
const EXIT_FULLSCREEN_ERROR = 'EXIT_FULLSCREEN_ERROR';

const CHFullScreenButton = () => {
  const [show, setShow] = React.useState(false);

  const goToFullScreen = () => {
    requestFullScreen({
      onSuccess: () => {
        logEvent(DID_FULLSCREEN, DID_FULLSCREEN);
      },
      onError: (e) => {
        setShow(false);
        logEvent(REQUEST_FULLSCREEN_ERROR, REQUEST_FULLSCREEN_ERROR, {
          error: e
        });
      }
    });
  };

  const exitFromFullScreen = () => {
    exitFullScreen({
      onSuccess: () => {
        logEvent(EXIT_FULLSCREEN, EXIT_FULLSCREEN);
      },
      onError: (e) => {
        logEvent(EXIT_FULLSCREEN_ERROR, EXIT_FULLSCREEN_ERROR, {
          error: e
        });
      }
    });
  };

  const toggleFullScreen = () => {
    if (isFullScreen()) {
      exitFromFullScreen();
    } else {
      goToFullScreen();
    }
  };

  React.useEffect(() => {
    setShow(!(isUserOniOS() && isUserOnMobileOnly()));
  }, []);

  if (!show) return null;

  return (
    <div
      className="CHNav NavFullScreen with-shadow noselect"
      onClick={toggleFullScreen}
    >
      <FullScreenIcon />
      <style jsx>{`
        .NavFullScreen {
          top: auto;
          bottom: 0;
        }
        @media (orientation: landscape) {
          .NavFullScreen {
            left: 60px;
          }
        }
        @media (orientation: portrait) {
          .NavFullScreen {
            right: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default CHFullScreenButton;

import { last } from 'lodash';
import { getStoreConfig, getStoreById } from '../clientSideServices/store';
import { loubiFutureSiteId } from '../config';
import { ClientState, IMainState, MeetingLayoutMode } from '../interfaces';
import {
  actionCloseVBPopup,
  actionPanoLoaded,
  actionSetActivePage,
  actionTeleportTo,
  actionUpdateMeetingLayout
} from './actions';
import {
  actionLFPlaySoundEffect,
  actionUpdateLandingVideoState
} from './customActions/loubiFuture';

const clearLoubiFutureSoundAction = actionLFPlaySoundEffect({
  enteringSectionA: false,
  zoomingLightingStrips: false,
  water: false,
  focusProduct: false,
  rainbowLight: false,
  elevatorUp: false,
  dome: false,
  arClick: false,
  afterRainbow: false,
  muted: true
});

const teleportPreActions = (
  siteId: string,
  clientState: ClientState,
  dispatch: any
) => {
  const history = clientState?.teleport?.history || [];
  const meeting = clientState?.meeting;
  const inMeeting = !!meeting?.meetingId;
  const isPresenter = meeting?.isPresenter;
  dispatch(actionPanoLoaded(false));

  if (inMeeting && isPresenter) {
    dispatch(actionCloseVBPopup());
    dispatch(actionSetActivePage(null));
  }
  if (last(history) === loubiFutureSiteId) {
    dispatch(clearLoubiFutureSoundAction);
  }
};

const teleportPostActions = (
  siteId: string,
  clientState: ClientState,
  dispatch: any
) => {
  const meeting = clientState?.meeting;
  const inMeeting = !!meeting?.meetingId;
  const isPresenter = meeting?.isPresenter;

  if (inMeeting) {
    dispatch(actionUpdateMeetingLayout(MeetingLayoutMode.NORMAL));
  }

  if (siteId === loubiFutureSiteId) {
    if ((inMeeting && isPresenter) || !inMeeting) {
      dispatch(
        actionUpdateLandingVideoState({
          hidden: true,
          finishedPlaying: true
        })
      );
    }
  }
};

const backToHome = (dispatch: any) => {
  setTimeout(() => {
    dispatch(actionTeleportTo(undefined));
  }, 100);
};

export const actionTeleportAsync = (siteId: string) => {
  return (dispatch, getState) => {
    const clientState = (getState() as IMainState).clientState;

    teleportPreActions(siteId, clientState, dispatch);

    if (siteId === clientState?.store?.id) {
      backToHome(dispatch);
      return;
    }

    return Promise.all([getStoreConfig(siteId), getStoreById(siteId)])
      .then(([config, store]) => {
        dispatch(
          actionTeleportTo({
            siteId,
            config: config.data,
            store: store.data
          })
        );
        teleportPostActions(siteId, clientState, dispatch);
        return;
      })
      .catch((e) => {
        backToHome(dispatch);
        console.log(`error fetch store config`, e);
      });
  };
};

import React from 'react';
import { CgPlayButtonO, CgPlayPauseO } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import {
  panoBaseUrl,
  rogerDubuisRetailId,
  rogerDubuisWW2021Id,
  rogerDubuisWholeSaleId
} from '../../../../config';
import { IMainState } from '../../../../interfaces';
import { last } from 'lodash';
import {
  actionReloadStoreDefaultPano,
  actionSwitchOffLights,
  actionUpdateBackgroundMusicUrl
} from '../../../../redux/actions';
import {
  DID_CLICK_HOME_BUTTON,
  DID_CLICK_LIGHT_SWITCH_BUTTON,
  DID_CLICK_MUSIC_LIST_BUTTON,
  DID_HIDE_FLOWCHART_DASHBOARD,
  DID_SHOW_FLOWCHART_DASHBOARD
} from '../../../../utils/constants';
import FileList from '../../../DocumentsViewer/FileList';
import {
  useLocalUserMeetingEntitlement,
  useMeetingLocalAudio
} from '../../../hooks/meeting';

import RDUWW21FlowChart from './RDUWW21FlowChart';
import { TRANSITIONED_FROM_WW2021_TO_MANUFACTURE } from '../../../../config/rduWW2021';
import { BiHome } from 'react-icons/bi';
import { isOnClientSide } from '../../../../utils/window';
import { MDGlobalSpec } from '../../../Meeting/MeetingLayout/MDGlobal';
import { SMGlobalSpec } from '../../../Meeting/MeetingLayout/SMGlobal';

const RDUWW21HostControls = () => {
  const [showFlowChart, setShowFlowChart] = React.useState(false);
  const [showHomeButton, setShowHomeButton] = React.useState(false);
  const [showMusicSelection, setShowMusicSelection] = React.useState(false);
  const vb = useSelector((state: IMainState) => state.clientState.vb);
  const store = useSelector((state: IMainState) => state.clientState.store);
  const teleportStore = useSelector(
    (state: IMainState) => state.clientState?.teleport?.teleportTo?.store
  );
  const storeId = teleportStore?.id || store?.id;
  const dispatch = useDispatch();
  const { unmuteAudio } = useMeetingLocalAudio();
  const [canControlMeeting] = useLocalUserMeetingEntitlement();

  React.useEffect(() => {
    setShowHomeButton(window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE]);
    return () => {
      dispatch(actionUpdateBackgroundMusicUrl(undefined));
      unmuteAudio();
    }
  }, [isOnClientSide() && window[TRANSITIONED_FROM_WW2021_TO_MANUFACTURE]]);

  const musicBaseUrl = `${panoBaseUrl}/virtual_boutique/68f1c557-3873-4069-83e7-ec6b0e7a7985/`;
  const scenesToHideLightbulb = (() => {
    if (storeId === rogerDubuisWW2021Id) {
      return [
        'scene_lounge_010000',
        'scene_lounge_010000_night',
        'scene_lounge_020000',
        'scene_lounge_020000_night',
        'scene_lounge_030000',
        'scene_lounge_030000_night',
        'scene_lounge_040000',
        'scene_lounge_040000_night',
        'scene_lounge_050000',
        'scene_lounge_050000_night',
        'scene_lounge_060000',
        'scene_lounge_060000_night',
        'scene_lounge_070000',
        'scene_lounge_070000_night',
        'scene_lounge_080000',
        'scene_lounge_080000_night',
        'scene_lounge_090000',
        'scene_lounge_090000_night'
      ];
    }
    if (storeId === rogerDubuisRetailId || storeId === rogerDubuisWholeSaleId) {
      return [
        'scene_lounge_010000',
        'scene_lounge_020000',
        'scene_lounge_030000',
        'scene_lounge_040000',
        'scene_lounge_050000',
        'scene_lounge_060000',
        'scene_lounge_070000',
        'scene_lounge_080000',
        'scene_rd080000',
        'scene_rd090000',
        'scene_newlounge_100000',
        'scene_newlounge_110000',
        'scene_newlounge_120000',
        'scene_newlounge_130000',
        'scene_newlounge_140000',
        'scene_lounge_150000',
        'scene_lounge_160000',
        'scene_lounge_170000',
        'scene_lounge_180000'
      ];
    }
    return [];
  })();
  const currentSceneId = last(vb?.sceneIds);
  const musicList = [
    { id: 'dorothy', name: 'Dorothy', url: `${musicBaseUrl}dorothy_v2.mp3` },
    {
      id: 'fall_in_deep',
      name: 'Fall In Deep',
      url: `${musicBaseUrl}fall_in_deep_v3.mp3`
    },
    {
      id: 'highway_to_hell',
      name: 'Highway To Hell',
      url: `${musicBaseUrl}highway_to_hell_v2.mp3`
    },
    {
      id: 'come_together',
      name: 'Come Together',
      url: `${musicBaseUrl}come_together_v2.mp3`
    }
  ];

  const musicSelectionButton = (
    <>
      <button
        onClick={() => {
          logEvent(DID_CLICK_MUSIC_LIST_BUTTON, DID_CLICK_MUSIC_LIST_BUTTON, {
            show: showMusicSelection
          });
          setShowMusicSelection(!showMusicSelection);
        }}
        className="btn-express"
      >
        <img
          className="icon with-shadow"
          src="/asset/rdu/Music.svg"
          alt="Music"
        />
      </button>
      {showMusicSelection && (
        <div className="music-list">
          <FileList
            fileNames={musicList.map((f) => f.name)}
            onClickItem={(fileName) => {
              const f = musicList.find((f) => f.name === fileName);
              if (!f) {
                return;
              }
              if (f.url !== vb?.activeBackgroundMusicUrl) {
                dispatch(actionUpdateBackgroundMusicUrl(f.url));
              } else {
                dispatch(actionUpdateBackgroundMusicUrl(undefined));
                unmuteAudio();
              }
            }}
            appendedElements={musicList.map((f) => {
              if (f.url === vb?.activeBackgroundMusicUrl) {
                return <CgPlayPauseO />;
              }
              return <CgPlayButtonO />;
            })}
          />
          <style jsx>
            {`
              .music-list {
                position: absolute;
                top: 45px;
                left: 10px;
                min-width: 200px;
              }
            `}
          </style>
        </div>
      )}
      {scenesToHideLightbulb.includes(currentSceneId) ? null : (
        <button
          onClick={() => {
            logEvent(
              DID_CLICK_LIGHT_SWITCH_BUTTON,
              DID_CLICK_LIGHT_SWITCH_BUTTON,
              {
                switchLightsOff: !vb?.lightsOff
              }
            );
            dispatch(actionSwitchOffLights(!vb?.lightsOff));
          }}
          className="btn-express"
        >
          <img
            className="icon with-shadow"
            src={`/asset/rdu/${
              vb?.lightsOff ? 'Lights-On.svg' : 'Lights-Off.svg'
            }`}
            alt="Light Switch"
          />
        </button>
      )}
      {showHomeButton && (
        <button
          onClick={() => {
            logEvent(DID_CLICK_HOME_BUTTON);
            dispatch(actionReloadStoreDefaultPano());
          }}
          className="btn-express"
        >
          <BiHome className="icon with-shadow" color="#fff" />
        </button>
      )}
    </>
  );

  return (
    <>
      <div className="control-buttons rdu-control-buttons">
        {canControlMeeting && (
          <button
            onClick={() => {
              if (!showFlowChart) {
                logEvent(DID_SHOW_FLOWCHART_DASHBOARD);
              } else {
                logEvent(DID_HIDE_FLOWCHART_DASHBOARD);
              }
              setShowFlowChart(!showFlowChart);
            }}
            className="btn-express"
          >
            <img
              className="icon with-shadow"
              src="/asset/rdu/Dashboard.svg"
              alt="Dashboard"
            />
          </button>
        )}
        {musicSelectionButton}
      </div>
      {showFlowChart && (
        <RDUWW21FlowChart
          onClose={() => {
            setShowFlowChart(false);
            logEvent(DID_HIDE_FLOWCHART_DASHBOARD);
          }}
        />
      )}
      <style jsx global>{`
        .in-meeting .rdu-control-buttons {
          top: ${SMGlobalSpec.logoSectionHeight}px !important;
          left: auto !important;
          right: 50px;
        }
        .in-meeting.MD .rdu-control-buttons {
          top: ${MDGlobalSpec.logoSectionHeight}px !important;
        }
      `}</style>
      <style jsx>
        {`
          .rdu-control-buttons {
            position: fixed;
            top: 0;
            margin-top: 13px;
            left: 0;
            margin-left: 10px;
            display: flex;
            justify-content: flex-start;
            z-index: 2;
          }

          :global(.rdu-control-buttons > button) {
            background: transparent;
          }

          :global(.rdu-control-buttons .icon) {
            height: 22px;
            width: 22px;
            margin-right: 10px;
            user-select: none;
          }

          :global(.rdu-control-buttons .list-group-item svg) {
            display: inline-block;
            margin-left: 5px;
          }
        `}
      </style>
    </>
  );
};

export default RDUWW21HostControls;
